import { datetime, RRule } from 'rrule';
export const createRRule = (recurringFormula) => {
    const dtstart = datetime(recurringFormula.startDate.getFullYear(), recurringFormula.startDate.getMonth() + 1, recurringFormula.startDate.getDate());
    const until = recurringFormula.untilDate
        ? datetime(recurringFormula.untilDate.getFullYear(), recurringFormula.untilDate.getMonth() + 1, recurringFormula.untilDate.getDate())
        : undefined;
    switch (recurringFormula.frequency.repeat) {
        case 'never': {
            const rule = new RRule({
                freq: RRule.DAILY,
                count: 1,
                dtstart
            });
            return rule;
        }
        case 'daily': {
            const rule = new RRule({
                freq: RRule.DAILY,
                dtstart,
                until
            });
            return rule;
        }
        case 'weekly': {
            const rule = new RRule({
                freq: RRule.WEEKLY,
                dtstart,
                until,
                byweekday: recurringFormula.frequency.days
            });
            return rule;
        }
        case 'monthly': {
            const rule = new RRule({
                freq: RRule.MONTHLY,
                dtstart,
                until,
                bymonthday: recurringFormula.frequency.days
            });
            return rule;
        }
        case 'yearly': {
            const rule = new RRule({
                freq: RRule.YEARLY,
                dtstart,
                until,
                byyearday: recurringFormula.frequency.days
            });
            return rule;
        }
        case 'custom': {
            const { interval, value } = recurringFormula.frequency;
            switch (value.repeat) {
                case 'daily': {
                    const rule = new RRule({
                        freq: RRule.DAILY,
                        dtstart,
                        until,
                        interval
                    });
                    return rule;
                }
                case 'weekly': {
                    const rule = new RRule({
                        freq: RRule.WEEKLY,
                        dtstart,
                        until,
                        byweekday: value.days,
                        interval
                    });
                    return rule;
                }
                case 'monthly': {
                    const rule = new RRule({
                        freq: RRule.MONTHLY,
                        dtstart,
                        until,
                        bymonthday: value.days,
                        interval
                    });
                    return rule;
                }
                case 'yearly': {
                    const rule = new RRule({
                        freq: RRule.YEARLY,
                        dtstart,
                        until,
                        byyearday: value.days,
                        interval
                    });
                    return rule;
                }
                default:
                    console.error('failed to create custom recurring formula, fallback to null');
                    return null;
            }
        }
        default:
            console.error('failed to create recurring formula, fallback to null');
            return null;
    }
};
