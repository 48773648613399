import { useCallback, useRef } from 'react';
/**
 * Source: https://medium.com/swlh/using-a-debounced-callback-in-react-ade57d31ca6b
 */
/**
 * Returns a memoized function that will only call the passed function when it hasn't been called for the wait period
 * @param func The function to be called
 * @param wait Wait period after function hasn't been called for
 * @returns A memoized function that is debounced
 */
export const useDebounceCallback = (func, wait, deps) => {
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeout = useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(function () {
        const later = () => {
            clearTimeout(timeout.current);
            func(...arguments);
        };
        clearTimeout(timeout.current);
        timeout.current = setTimeout(later, wait);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [func, wait, ...deps]);
};
