import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { useMapMultiSelect } from '../../hooks/useMapMultiSelect';
import { useAppSelector } from '../../state/hooks';
import { CreatorHintBox } from '../../uikit/CreatorHintBox/CreatorHintBox';
export function HintboxContainer() {
    const { showMultiSelectHint } = useMapMultiSelect();
    const { draftPinContent } = useAppSelector((state) => state.mainMap);
    if (showMultiSelectHint) {
        return (_jsx(CreatorHintBox, { text: _jsx(Ditto, { componentId: "map.holdctrlcmdandclicktoselectmultipleitems" }) }));
    }
    else if ((draftPinContent === null || draftPinContent === void 0 ? void 0 : draftPinContent.type) === 'marker' || (draftPinContent === null || draftPinContent === void 0 ? void 0 : draftPinContent.type) === 'asset') {
        return _jsx(CreatorHintBox, { text: _jsx(Ditto, { componentId: "tasks.updateposition" }) });
    }
    return _jsx(_Fragment, {});
}
