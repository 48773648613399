var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { removeEmptyKeys } from '../../common/utils/object';
import { getLightMarkers } from '../../services/marker';
import { batchMarkAsRead, countUnreadNotifications, getUserNotifications, getUsersProfile, markAllAsRead } from '../../services/notifications';
const initialState = {
    messages: [],
    notificationsFetchStatus: 'idle',
    notificationList: [],
    markerMap: {},
    userMap: {},
    unreadNotificationCount: 0
};
export const fetchUnreadNotificationCount = createAppAsyncThunk('notification/fetchUnreadNotificationCount', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield countUnreadNotifications();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchUserNotificationsTk = createAppAsyncThunk('notification/fetchUserNotificationsTk', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getUserNotifications();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchNotificationMarkers = createAppAsyncThunk('notification/fetchNotificationMarkers', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getLightMarkers({ ids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchNotificationUserProfiles = createAppAsyncThunk('notification/fetchNotificationUserProfiles', (uuids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getUsersProfile({ uuids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const batchMarkAsReadTk = createAppAsyncThunk('notification/batchMarkAsReadTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield batchMarkAsRead({ ids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(fetchUserNotificationsTk());
        dispatch(fetchUnreadNotificationCount());
    }
    return { res };
}));
export const markAllAsReadTk = createAppAsyncThunk('notification/markAllAsReadTk', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield markAllAsRead();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(fetchUserNotificationsTk());
        dispatch(fetchUnreadNotificationCount());
    }
    return res;
}));
export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        reset: () => initialState,
        removeNotificationMessage: (state, action) => {
            state.messages = state.messages.filter((m) => m.id !== action.payload);
        },
        addNotificationMessage: (state, action) => {
            if (action.payload.clearBeforeAdd) {
                state.messages = [];
            }
            // Remove previous generic errors (only id and type defined),
            // to avoid cluttering the screen.
            if (isGenericError(action.payload) &&
                state.messages.find((msg) => msg.type === 'error')) {
                state.messages = state.messages.filter((msg) => msg.type !== 'error' || !isGenericError(msg));
            }
            state.messages.push(Object.assign({ id: uuidv4() }, action.payload));
        },
        setNotificationMessage: (state, action) => {
            state.messages = [Object.assign({ id: uuidv4() }, action.payload)];
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchUnreadNotificationCount.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.unreadNotificationCount = action.payload.result.count;
            }
        });
        builder.addCase(fetchUserNotificationsTk.fulfilled, (state, action) => {
            state.notificationsFetchStatus = 'completed';
            if (action.payload.result) {
                state.notificationList = action.payload.result.notifications;
            }
        });
        builder.addCase(fetchNotificationMarkers.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.markerMap = Object.fromEntries(action.payload.result.markers.map((t) => [t.id, t]));
            }
        });
        builder.addCase(fetchNotificationUserProfiles.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.userMap = Object.fromEntries(action.payload.result.users.map((t) => [t.uuid, t]));
            }
        });
    }
});
function isGenericError(msg) {
    return (msg.type === 'error' &&
        Object.keys(removeEmptyKeys(msg)).every((key) => key === 'id' || key === 'type'));
}
export const { removeNotificationMessage, addNotificationMessage, setNotificationMessage, reset: resetNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
