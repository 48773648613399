import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsPresent } from 'framer-motion';
import { getCommonParentElement } from '../common/utils/commonParent';
import { rafSchd } from '../common/utils/rafSchd';
import { useIntersectionObserver } from './useIntersectionObserver';
import { useMountDelay } from './useMountDelay';
// Delay of 500 is equal to the transition time of detailPanel.
// In TabBar, scrollIntoView is used and is invoked on mount automatically.
// It causes all layout elements to jump / play weird transitions, while
// detailPanel is either entering or exiting the view.
export const APP_LAYOUT_DETAIL_PANEL_MOUNT_DELAY_MS = 500;
/**
 * tabsCount: number of tabs
 * scrollToCurrent: whether to auto scroll to current tab ref when it's selected
 * initTabIndex: tab that will be selected as initial
 */
export function useTabBar({ initTabIndex = 0, tabsCount, scrollToCurrent, scrollBehavior = 'smooth' }) {
    const refs = Array(tabsCount)
        .fill(null)
        .map(() => React.createRef());
    const scrollTarget = useRef(null);
    const prevTab = useRef(initTabIndex);
    const [currentTab, setCurrentTab] = useState(initTabIndex);
    const scrolling = useRef(false);
    const { mounting } = useMountDelay(APP_LAYOUT_DETAIL_PANEL_MOUNT_DELAY_MS);
    const { intersectRatios } = useIntersectionObserver({ refs });
    const { scrollFn } = useScrollToRef({
        scrollToCurrent,
        scrollBehavior,
        refs,
        mounting,
        scrolling,
        scrollTarget,
        prevTab,
        currentTab
    });
    useScrolling({
        refs,
        intersectRatios,
        scrollTarget,
        scrolling,
        currentTab,
        setCurrentTab
    });
    return { currentTab, setCurrentTab, refs, scrollTo: scrollFn };
}
const useScrollToRef = ({ scrollToCurrent, scrollBehavior, refs, mounting, scrolling, scrollTarget, prevTab, currentTab }) => {
    const isPresent = useIsPresent();
    const scrollFn = useMemo(() => refs.map((ref, index) => {
        const func = () => {
            if (ref.current === null || mounting.current) {
                return;
            }
            if (currentTab !== prevTab.current && !scrolling.current) {
                scrollTarget.current = index;
                ref.current.scrollIntoView({
                    behavior: scrollBehavior !== null && scrollBehavior !== void 0 ? scrollBehavior : 'smooth'
                });
            }
            prevTab.current = currentTab;
        };
        return rafSchd(func);
    }), [refs, mounting, currentTab, prevTab, scrolling, scrollTarget, scrollBehavior]);
    useEffect(() => {
        if (!scrollToCurrent || !isPresent) {
            return;
        }
        scrollFn[currentTab]();
    }, [scrollToCurrent, isPresent, scrollFn, currentTab]);
    return { scrollFn };
};
const useScrolling = ({ refs, intersectRatios, scrollTarget, scrolling, currentTab, setCurrentTab }) => {
    // Use requestAnimationFrame "scheduler" to throttle
    // the callback to the screen's refresh rate.
    // Also used in other places for synchronization.
    const scrollListener = rafSchd(useCallback(() => {
        scrolling.current = true;
        if (scrollTarget.current !== null) {
            return;
        }
        // We use delta (difference) to set the current tab to
        // the child closest to the center of the parent (measured by
        // the difference between child's top offset and the parent's center).
        // This is useful when there is no consensus among intersectRatios,
        // which can happen when multiple children are within the boundaries
        // of the parent, i.e., multiple ratios of 1.0. In those cases using min delta
        // chooses the closest child to the viewport center.
        const deltas = Array(refs.length).fill(0);
        for (const [index, ref] of refs.entries()) {
            if (!ref.current) {
                return;
            }
            const parent = ref.current.parentElement;
            const delta = Math.abs(ref.current.offsetTop - parent.scrollTop - parent.offsetHeight / 2);
            deltas[index] = delta;
        }
        // First sort by intersection ratio, discard zeroes
        const candidates = intersectRatios.current
            .map((ratio, index) => [ratio, deltas[index], index])
            .filter(([ratio]) => ratio > 0)
            .sort((a, b) => a[0] - b[0])
            .reverse();
        // Then choose the one with the min delta
        let minDelta = candidates.shift();
        if (minDelta) {
            for (const delta of candidates) {
                if (delta[1] < minDelta[1]) {
                    minDelta = delta;
                }
            }
            setCurrentTab(minDelta[2]);
        }
    }, [refs, intersectRatios, scrollTarget, scrolling, setCurrentTab]));
    const scrollEndListener = rafSchd(useCallback(() => {
        if (scrollTarget.current === currentTab) {
            scrollTarget.current = null;
        }
        scrolling.current = false;
    }, [scrollTarget, currentTab, scrolling]));
    useEffect(() => {
        if (refs.some((ref) => ref.current === null)) {
            return;
        }
        const parent = getCommonParentElement(refs);
        if (!parent) {
            throw new ReferenceError('No common parent element found');
        }
        parent.addEventListener('scroll', scrollListener, { passive: true });
        parent.addEventListener('scrollend', scrollEndListener, { passive: true });
        return () => {
            parent.removeEventListener('scroll', scrollListener);
            parent.removeEventListener('scrollend', scrollEndListener);
        };
    }, [refs, scrollListener, scrollEndListener]);
};
