var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDefaultHeaders } from '../common/fetch';
import { getApiUrl } from '../common/url';
import { AccountsAppClient } from './accountsApp/gen';
import { FileManagerClient } from './fileManager/gen';
import { MailServiceClient } from './mailService/gen';
import { MilkyWayApiClient } from './milkyWayApi/gen';
import { PlanningToolClient } from './planningTool/gen';
import { RoadSafetyClient } from './roadSafety/gen';
import { StreetartClient } from './streetart/gen';
export let cancelablePromises = {};
export const roadSafetyClient = new RoadSafetyClient({
    BASE: `${getApiUrl(true)}/road-safety`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
export const streetartClient = new StreetartClient({
    BASE: `${getApiUrl(true)}/streetart`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
export const accountsAppClient = new AccountsAppClient({
    BASE: `${getApiUrl(true)}/accounts-app`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
export const milkyWayApiClient = new MilkyWayApiClient({
    BASE: `${getApiUrl(true)}/milky-way-api`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
export const fileManagerClient = new FileManagerClient({
    BASE: `${getApiUrl(true)}/file-manager`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
export const planningToolClient = new PlanningToolClient({
    BASE: `${getApiUrl(true)}/planning-tool`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
export const mailServiceClient = new MailServiceClient({
    BASE: `${getApiUrl(true)}/mail`,
    HEADERS: () => __awaiter(void 0, void 0, void 0, function* () {
        return getDefaultHeaders();
    })
});
