import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export function FilterPreview({ selectedValues, PreviewIcon, labelFunction }) {
    if (selectedValues.length === 1) {
        return (_jsxs(_Fragment, { children: [_jsx(PreviewIcon, { value: selectedValues[0] }), _jsx("span", { children: labelFunction(selectedValues[0]) })] }));
    }
    if (selectedValues.length > 1) {
        return (_jsx(_Fragment, { children: selectedValues.map((v) => (_jsx(PreviewIcon, { value: v }, v))) }));
    }
    return _jsx(_Fragment, {});
}
