import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { PLAN_PLANNED_FOR_ID, PLAN_PROPERTY_ID, PLAN_TREATMENT_CATEGORY_ID } from '../../common/consts/plan';
import { toggleItemInArray } from '../../common/utils/array';
import { removeEmptyKeys } from '../../common/utils/object';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setPlanFilter, setTableBudgetTabYear } from '../../state/slices/leftPanelPlan';
export function usePlanFilterHandlers() {
    const { filter, table: { budgetTabYear } } = useAppSelector((state) => state.leftPanelPlan);
    const { planFilters, planTreatmentsList } = useAppSelector((state) => state.shared);
    const dispatch = useAppDispatch();
    function mixpanelTrack(value) {
        mixpanel.track(MIXPANEL_EVENT_NAME.setPlanFilter, {
            [MIXPANEL_EVENT_PROPERTY.filter]: value
        });
    }
    function handleStatusGroupClick() {
        var _a, _b;
        dispatch(setPlanFilter({
            statusIds: ((_a = filter.statusIds) === null || _a === void 0 ? void 0 : _a.length) === 0 ? planFilters.status.map((item) => item.id) : []
        }));
        if (((_b = filter.statusIds) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            planFilters.status.forEach((status) => {
                mixpanelTrack(`Status: ${status.name}`);
            });
        }
    }
    function handleStatusFilterClick(value) {
        var _a;
        const newList = toggleItemInArray(filter.statusIds || [], value);
        dispatch(setPlanFilter({
            statusIds: newList
        }));
        if (newList.includes(value)) {
            const statusName = (_a = planFilters.status.find((status) => status.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Status: ${statusName}`);
        }
    }
    function handleCategoryGroupClick() {
        var _a, _b;
        dispatch(setPlanFilter({
            categoryIds: ((_a = filter.categoryIds) === null || _a === void 0 ? void 0 : _a.length) === 0
                ? planFilters.category.map((item) => item.id)
                : []
        }));
        if (((_b = filter.categoryIds) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            planFilters.category.forEach((category) => {
                mixpanelTrack(`Category: ${category.name}`);
            });
        }
    }
    function handleCategoryFilterClick(value) {
        var _a;
        const newList = toggleItemInArray(filter.categoryIds || [], value);
        dispatch(setPlanFilter({
            categoryIds: newList
        }));
        if (newList.includes(value)) {
            const categoryName = (_a = planFilters.category.find((category) => category.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Category: ${categoryName}`);
        }
    }
    function handleBudgetGroupClick() {
        var _a, _b;
        dispatch(setPlanFilter({
            budgetIds: ((_a = filter.budgetIds) === null || _a === void 0 ? void 0 : _a.length) === 0 ? planFilters.budget.map((item) => item.id) : []
        }));
        if (((_b = filter.budgetIds) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            planFilters.budget.forEach((budget) => {
                mixpanelTrack(`Budget: ${budget.id === 0 ? 'Unplanned Date' : budget.name}`);
            });
        }
    }
    function handleBudgetFilterClick(value) {
        var _a;
        let newList;
        if (Array.isArray(value)) {
            newList = value;
            value = value[0];
        }
        else {
            newList = toggleItemInArray(filter.budgetIds || [], value);
        }
        dispatch(setPlanFilter({
            budgetIds: newList
        }));
        if (budgetTabYear) {
            dispatch(setTableBudgetTabYear(undefined));
        }
        if (newList.includes(value)) {
            const budgetName = (_a = planFilters.budget.find((budget) => budget.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Budget: ${value === 0 ? 'Unplanned Date' : budgetName}`);
        }
    }
    function handleBudgetFilterResetClick() {
        dispatch(setPlanFilter({
            budgetIds: []
        }));
        dispatch(setTableBudgetTabYear(undefined));
    }
    function handleTreatmentGroupClick() {
        var _a, _b;
        dispatch(setPlanFilter({
            treatmentIds: ((_a = filter.treatmentIds) === null || _a === void 0 ? void 0 : _a.length) === 0
                ? planFilters.treatment.map((item) => item.id)
                : []
        }));
        if (((_b = filter.treatmentIds) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            planFilters.treatment.forEach((treatment) => {
                mixpanelTrack(`Treatment: ${treatment.name}`);
            });
        }
    }
    function handleTreatmentFilterClick(value) {
        var _a;
        const newList = toggleItemInArray(filter.treatmentIds || [], value);
        dispatch(setPlanFilter({
            treatmentIds: newList
        }));
        if (newList.includes(value)) {
            const treatmentName = (_a = planFilters.treatment.find((treatment) => treatment.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Treatment: ${treatmentName}`);
        }
    }
    function handleTreatmentCategoryFilterClick(categoryId) {
        var _a;
        const categoryTreatments = planTreatmentsList.filter((treatment) => treatment.categoryTypeId === categoryId);
        let newList = filter.treatmentIds || [];
        const hasAnyItemFromCategory = (_a = filter.treatmentIds) === null || _a === void 0 ? void 0 : _a.some((id) => { var _a; return ((_a = categoryTreatments.find((item) => item.id === id)) === null || _a === void 0 ? void 0 : _a.id) === id; });
        if (hasAnyItemFromCategory) {
            newList = newList.filter((id) => !categoryTreatments.some((item) => item.id === id));
        }
        else {
            newList = [...newList, ...categoryTreatments.map((item) => item.id)];
        }
        dispatch(setPlanFilter({
            treatmentIds: newList
        }));
        if (!hasAnyItemFromCategory && categoryId) {
            const treatmentCategoryName = Object.keys(PLAN_TREATMENT_CATEGORY_ID)[categoryId];
            mixpanelTrack(`Treatment Category: ${treatmentCategoryName}`);
        }
    }
    function handleTreatmentNotSpecifiedFilterClick() {
        dispatch(setPlanFilter({
            treatmentIdsIncludeNotSet: !filter.treatmentIdsIncludeNotSet ? true : undefined
        }));
        if (!filter.treatmentIdsIncludeNotSet) {
            mixpanelTrack('Treatment: Not specified');
        }
    }
    function handlePriorityGroupClick() {
        var _a, _b;
        dispatch(setPlanFilter({
            priorityIds: ((_a = filter.priorityIds) === null || _a === void 0 ? void 0 : _a.length) === 0
                ? planFilters.priority.map((item) => item.id)
                : []
        }));
        if (((_b = filter.priorityIds) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            planFilters.priority.forEach((priority) => {
                mixpanelTrack(`Priority: ${priority.name}`);
            });
        }
    }
    function handlePriorityFilterClick(value) {
        var _a;
        const newList = toggleItemInArray(filter.priorityIds || [], value);
        dispatch(setPlanFilter({
            priorityIds: newList
        }));
        if (newList.includes(value)) {
            const priorityName = (_a = planFilters.priority.find((priority) => priority.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Priority: ${priorityName}`);
        }
    }
    function handleFirmlyPlannedGroupClick() {
        dispatch(setPlanFilter({
            thirdParty: filter.thirdParty === undefined ? true : undefined
        }));
        if (filter.thirdParty === undefined) {
            mixpanelTrack(`Firmly Planned: true`);
        }
    }
    function handleFirmlyPlannedFilterClick(value) {
        dispatch(setPlanFilter({
            thirdParty: filter.thirdParty === value ? undefined : value
        }));
        if (filter.thirdParty !== value) {
            mixpanelTrack(`Firmly Planned: ${value}`);
        }
    }
    function handlePlannedForGroupClick() {
        var _a;
        const plannedForId = PLAN_PLANNED_FOR_ID.overdue;
        dispatch(setPlanFilter({
            completionYearAndMonth: filter.completionYearAndMonth === undefined ? plannedForId : undefined
        }));
        if (filter.completionYearAndMonth === undefined) {
            const plannedForName = (_a = planFilters.plannedFor.find((item) => item.id === plannedForId)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Planned For: ${plannedForName}`);
        }
    }
    function handlePlannedForFilterClick(value) {
        var _a;
        const plannedForFilters = {
            completionYearAndMonth: filter.completionYearAndMonth === value ? undefined : value
        };
        if (value !== PLAN_PLANNED_FOR_ID.custom) {
            plannedForFilters.customCompletionYearAndMonth = undefined;
        }
        dispatch(setPlanFilter(plannedForFilters));
        if (filter.completionYearAndMonth !== value) {
            const plannedForName = value !== PLAN_PLANNED_FOR_ID.custom
                ? (_a = planFilters.plannedFor.find((item) => item.id === value)) === null || _a === void 0 ? void 0 : _a.name
                : 'Custom';
            mixpanelTrack(`Planned For: ${plannedForName}`);
        }
    }
    function handleCustomPlannedForFilterClick(value) {
        dispatch(setPlanFilter({
            completionYearAndMonth: PLAN_PLANNED_FOR_ID.custom,
            customCompletionYearAndMonth: value
        }));
    }
    function handleAreaGroupClick() {
        dispatch(setPlanFilter(filter.areaMin !== undefined || filter.areaMax !== undefined
            ? {
                areaMin: undefined,
                areaMax: undefined,
                areaIncludeNotSet: undefined
            }
            : {
                areaMin: planFilters.area.min,
                areaMax: planFilters.area.max
            }));
        if (!(filter.areaMin !== undefined || filter.areaMax !== undefined)) {
            mixpanelTrack('Area: Range');
        }
    }
    function handleAreaRangeClick() {
        dispatch(setPlanFilter(filter.areaMin !== undefined || filter.areaMax !== undefined
            ? {
                areaMin: undefined,
                areaMax: undefined
            }
            : {
                areaMin: planFilters.area.min,
                areaMax: planFilters.area.max
            }));
        if (!(filter.areaMin !== undefined || filter.areaMax !== undefined)) {
            mixpanelTrack('Area: Range');
        }
    }
    function handleAreaMinFilterChange(value) {
        dispatch(setPlanFilter({
            areaMin: value
        }));
        if (filter.areaMin !== undefined) {
            mixpanelTrack('Area: Range');
        }
    }
    function handleAreaMaxFilterChange(value) {
        dispatch(setPlanFilter({
            areaMax: value
        }));
        if (filter.areaMax === undefined) {
            mixpanelTrack('Area: Range');
        }
    }
    function handleAreaNotSpecifiedFilterClick() {
        dispatch(setPlanFilter({
            areaIncludeNotSet: !filter.areaIncludeNotSet ? true : undefined
        }));
        if (!filter.areaIncludeNotSet) {
            mixpanelTrack('Area: Not specified');
        }
    }
    function handleCostsGroupClick() {
        dispatch(setPlanFilter(filter.costsMin !== undefined || filter.costsMax !== undefined
            ? {
                costsMin: undefined,
                costsMax: undefined,
                costsIncludeNotSet: undefined
            }
            : {
                costsMin: planFilters.costs.min,
                costsMax: planFilters.costs.max
            }));
        if (!(filter.costsMin !== undefined || filter.costsMax !== undefined)) {
            mixpanelTrack('Costs: Range');
        }
    }
    function handleCostsRangeClick() {
        dispatch(setPlanFilter(filter.costsMin !== undefined || filter.costsMax !== undefined
            ? {
                costsMin: undefined,
                costsMax: undefined
            }
            : {
                costsMin: planFilters.costs.min,
                costsMax: planFilters.costs.max
            }));
        if (!(filter.costsMin !== undefined || filter.costsMax !== undefined)) {
            mixpanelTrack('Costs: Range');
        }
    }
    function handleCostsMinFilterChange(value) {
        dispatch(setPlanFilter({
            costsMin: value
        }));
        if (filter.costsMin === undefined) {
            mixpanelTrack('Costs: Range');
        }
    }
    function handleCostsMaxFilterChange(value) {
        dispatch(setPlanFilter({
            costsMax: value
        }));
        if (filter.costsMax === undefined) {
            mixpanelTrack('Costs: Range');
        }
    }
    function handleCostsNotSpecifiedFilterClick() {
        dispatch(setPlanFilter({
            costsIncludeNotSet: !filter.costsIncludeNotSet ? true : undefined
        }));
        if (!filter.costsIncludeNotSet) {
            mixpanelTrack('Costs: Not specified');
        }
    }
    function handleYearsOfUseGroupClick() {
        dispatch(setPlanFilter(filter.yearsOfUseMin !== undefined || filter.yearsOfUseMax !== undefined
            ? {
                yearsOfUseMin: undefined,
                yearsOfUseMax: undefined,
                yearsOfUseIncludeNotSet: undefined
            }
            : {
                yearsOfUseMin: planFilters.yearsOfUse.min,
                yearsOfUseMax: planFilters.yearsOfUse.max
            }));
        if (!(filter.yearsOfUseMax !== undefined || filter.yearsOfUseMax !== undefined)) {
            mixpanelTrack('Life Extension: Range');
        }
    }
    function handleYearsOfUseRangeClick() {
        dispatch(setPlanFilter(filter.yearsOfUseMin !== undefined || filter.yearsOfUseMax !== undefined
            ? {
                yearsOfUseMin: undefined,
                yearsOfUseMax: undefined
            }
            : {
                yearsOfUseMin: planFilters.yearsOfUse.min,
                yearsOfUseMax: planFilters.yearsOfUse.max
            }));
        if (!(filter.yearsOfUseMax !== undefined || filter.yearsOfUseMax !== undefined)) {
            mixpanelTrack('Life Extension: Range');
        }
    }
    function handleYearsOfUseMinFilterChange(value) {
        dispatch(setPlanFilter({
            yearsOfUseMin: value
        }));
        if (filter.yearsOfUseMin === undefined) {
            mixpanelTrack('Life Extension: Range');
        }
    }
    function handleYearsOfUseMaxFilterChange(value) {
        dispatch(setPlanFilter({
            yearsOfUseMax: value
        }));
        if (filter.yearsOfUseMax === undefined) {
            mixpanelTrack('Life Extension: Range');
        }
    }
    function handleYearsOfUseNotSpecifiedFilterClick() {
        dispatch(setPlanFilter({
            yearsOfUseIncludeNotSet: !filter.yearsOfUseIncludeNotSet ? true : undefined
        }));
        if (!filter.yearsOfUseIncludeNotSet) {
            mixpanelTrack('Life Extension: Range');
        }
    }
    function handleEfficiencyGroupClick() {
        var _a, _b;
        dispatch(setPlanFilter({
            efficiencyIds: ((_a = filter.efficiencyIds) === null || _a === void 0 ? void 0 : _a.length) === 0
                ? planFilters.efficiency.map((item) => item.id)
                : []
        }));
        if (((_b = filter.efficiencyIds) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            planFilters.efficiency.forEach((efficiency) => {
                mixpanelTrack(`Efficiency: ${efficiency.name}`);
            });
        }
    }
    function handleEfficiencyFilterClick(value) {
        var _a;
        const newList = toggleItemInArray(filter.efficiencyIds || [], value);
        dispatch(setPlanFilter({
            efficiencyIds: newList
        }));
        if (newList.includes(value)) {
            const efficiencyName = (_a = planFilters.efficiency.find((efficiency) => efficiency.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Efficiency: ${efficiencyName}`);
        }
    }
    function removeEmptyCustomDataFilters(customData) {
        Object.keys(customData).forEach((columnKey) => {
            var _a;
            // Remove undefined values from object
            const value = removeEmptyKeys(customData[columnKey]);
            // Remove filter object if it is empty (has only propertyTypeId)
            const shouldDelete = ((value.propertyTypeId === PLAN_PROPERTY_ID.text ||
                value.propertyTypeId === PLAN_PROPERTY_ID.numeric ||
                value.propertyTypeId === PLAN_PROPERTY_ID.date) &&
                Object.keys(value).length === 1) ||
                (value.propertyTypeId === PLAN_PROPERTY_ID.list && !((_a = value.options) === null || _a === void 0 ? void 0 : _a.length));
            if (shouldDelete) {
                delete customData[columnKey];
            }
        });
    }
    function handleCustomDataGroupClick(customData, fields) {
        const { columnKey, title, propertyTypeId } = customData;
        const customFilterData = filter.customData && filter.customData[columnKey];
        const fieldsArray = Object.values(fields);
        let state = false;
        if (customFilterData) {
            Object.keys(customFilterData).forEach((item) => {
                if (fieldsArray.includes(item) &&
                    customFilterData[item] !== undefined) {
                    state = true;
                }
            });
        }
        const newCustomDataList = Object.assign(Object.assign({}, filter.customData), { [columnKey]: Object.assign({ propertyTypeId: propertyTypeId }, fieldsArray.reduce((acc, field) => {
                var _a, _b;
                if (propertyTypeId === PLAN_PROPERTY_ID.date &&
                    !state &&
                    field === 'isNotSet') {
                    acc['isNotSet'] = false;
                    if (!state) {
                        mixpanelTrack(`(Custom Property) ${title}: Custom`);
                    }
                }
                if (field === 'isSet') {
                    acc[field] = !state ? true : undefined;
                    if (!state) {
                        mixpanelTrack(`(Custom Property) ${title}: Is added`);
                    }
                }
                if (['min', 'max', 'from', 'to'].includes(field)) {
                    acc[field] = !state
                        ? customData[field]
                        : undefined;
                    if (!state &&
                        propertyTypeId === PLAN_PROPERTY_ID.numeric &&
                        field === 'min') {
                        mixpanelTrack(`(Custom Property) ${title}: Range`);
                    }
                }
                if (field === 'options') {
                    acc[field] = !state
                        ? ((_a = customData.options) === null || _a === void 0 ? void 0 : _a.map((option) => option.id)) || []
                        : undefined;
                    if (!state) {
                        (_b = customData.options) === null || _b === void 0 ? void 0 : _b.forEach((option) => {
                            mixpanelTrack(`(Custom Property) ${title}: ${option.title}`);
                        });
                    }
                }
                return acc;
            }, {})) });
        removeEmptyCustomDataFilters(newCustomDataList);
        dispatch(setPlanFilter({
            customData: newCustomDataList
        }));
    }
    function handleCustomDataFilterClick(customData, value) {
        var _a, _b, _c;
        const { columnKey, title, propertyTypeId } = customData;
        const customDataFilter = (filter.customData && filter.customData[columnKey]) || {};
        const newCustomDataList = Object.assign(Object.assign({}, filter.customData), { [columnKey]: Object.assign(Object.assign({ propertyTypeId }, customDataFilter), value) });
        removeEmptyCustomDataFilters(newCustomDataList);
        dispatch(setPlanFilter({
            customData: newCustomDataList
        }));
        // Mixpanel tracking
        if (value.isSet) {
            // Click on Is added
            mixpanelTrack(`(Custom Property) ${title}: Is added`);
        }
        else if (value.isNotSet) {
            // Click on Not specified
            mixpanelTrack(`(Custom Property) ${title}: Not specified`);
        }
        else if (value.options) {
            // Click on Select list item
            const newValues = value.options.filter((obj1) => { var _a; return !((_a = customDataFilter.options) === null || _a === void 0 ? void 0 : _a.includes(obj1)); });
            if (newValues.length > 0) {
                const selectName = (_c = (_b = (_a = planFilters.customData
                    .find((cd) => cd.columnKey === columnKey)) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.find((opt) => opt.id === newValues[0])) === null || _c === void 0 ? void 0 : _c.title;
                mixpanelTrack(`(Custom Property) ${title}: ${selectName !== null && selectName !== void 0 ? selectName : 'Not specified'}`);
            }
        }
        else if (customData.propertyTypeId === PLAN_PROPERTY_ID.numeric &&
            customDataFilter.min === undefined &&
            customDataFilter.max === undefined) {
            // Click on Range slider
            mixpanelTrack(`(Custom Property) ${title}: Range`);
        }
        else if (customData.propertyTypeId === PLAN_PROPERTY_ID.date &&
            customDataFilter.isNotSet === false) {
            // Click on Custom timeframe
            mixpanelTrack(`(Custom Property) ${title}: Custom`);
        }
    }
    function handleCustomDataRangeClick(customData) {
        const { columnKey, title, propertyTypeId, min, max } = customData;
        const customDataFilter = filter.customData && filter.customData[columnKey];
        const newCustomDataList = Object.assign(Object.assign({}, filter.customData), { [columnKey]: Object.assign(Object.assign({ propertyTypeId }, customDataFilter), (customDataFilter &&
                (customDataFilter.min !== undefined || customDataFilter.max !== undefined)
                ? {
                    min: undefined,
                    max: undefined
                }
                : {
                    min,
                    max
                })) });
        removeEmptyCustomDataFilters(newCustomDataList);
        dispatch(setPlanFilter({
            customData: newCustomDataList
        }));
        if (!customDataFilter) {
            mixpanelTrack(`(Custom Property) ${title}: Range`);
        }
    }
    return {
        handleStatusGroupClick,
        handleStatusFilterClick,
        handleCategoryGroupClick,
        handleCategoryFilterClick,
        handleBudgetGroupClick,
        handleBudgetFilterClick,
        handleBudgetFilterResetClick,
        handlePriorityGroupClick,
        handlePriorityFilterClick,
        handleTreatmentGroupClick,
        handleTreatmentFilterClick,
        handleTreatmentCategoryFilterClick,
        handleTreatmentNotSpecifiedFilterClick,
        handlePlannedForGroupClick,
        handlePlannedForFilterClick,
        handleFirmlyPlannedGroupClick,
        handleFirmlyPlannedFilterClick,
        handleCustomPlannedForFilterClick,
        handleAreaGroupClick,
        handleAreaRangeClick,
        handleAreaMinFilterChange,
        handleAreaMaxFilterChange,
        handleAreaNotSpecifiedFilterClick,
        handleCostsGroupClick,
        handleCostsRangeClick,
        handleCostsMinFilterChange,
        handleCostsMaxFilterChange,
        handleCostsNotSpecifiedFilterClick,
        handleYearsOfUseGroupClick,
        handleYearsOfUseRangeClick,
        handleYearsOfUseMinFilterChange,
        handleYearsOfUseMaxFilterChange,
        handleYearsOfUseNotSpecifiedFilterClick,
        handleEfficiencyGroupClick,
        handleEfficiencyFilterClick,
        handleCustomDataGroupClick,
        handleCustomDataFilterClick,
        handleCustomDataRangeClick
    };
}
