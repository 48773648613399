// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i8LroHVmapapi4Z6STp9{border-top:1px solid rgba(0,0,0,.05);padding:12px 12px 0 12px;width:100%;display:flex;flex-direction:column}.MDoxqyurR11GTzP9nL6B{display:flex;align-items:center;padding:8px 16px 8px 8px}.wzoudJVaJv63MVRa34ZZ{display:flex;align-items:center;gap:4px;width:100%;font-size:16px;font-weight:600}.hL_OBvbBIAw1sHn3qhw8{display:grid;grid-template-columns:1fr 1fr;gap:2px}.pht2hbejziDBjhanJQuP{margin-top:auto;margin-right:-12px;margin-bottom:-12px}.reiy4AXlqCQQvnAqAa7e{grid-column:span 2;padding:12px;color:var(--primary-dark-blue);opacity:.6}`, "",{"version":3,"sources":["webpack://./src/components/CustomPropertiesForm/CustomPropertiesForm.module.css"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,wBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CACA,OAAA,CAGJ,sBACI,eAAA,CACA,kBAAA,CACA,mBAAA,CAGJ,sBACI,kBAAA,CACA,YAAA,CACA,8BAAA,CACA,UAAA","sourcesContent":[".container {\n    border-top: 1px solid rgba(0, 0, 0, 0.05);\n    padding: 12px 12px 0 12px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.headline {\n    display: flex;\n    align-items: center;\n    padding: 8px 16px 8px 8px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    width: 100%;\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.properties-form {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 2px;\n}\n\n.customize-wrapper {\n    margin-top: auto;\n    margin-right: -12px;\n    margin-bottom: -12px;\n}\n\n.no-properties {\n    grid-column: span 2;\n    padding: 12px;\n    color: var(--primary-dark-blue);\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `i8LroHVmapapi4Z6STp9`,
	"headline": `MDoxqyurR11GTzP9nL6B`,
	"title": `wzoudJVaJv63MVRa34ZZ`,
	"properties-form": `hL_OBvbBIAw1sHn3qhw8`,
	"customize-wrapper": `pht2hbejziDBjhanJQuP`,
	"no-properties": `reiy4AXlqCQQvnAqAa7e`
};
export default ___CSS_LOADER_EXPORT___;
