import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getLocalStorageAll } from '../../common/localStorage';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
export function ErrorPageContainer({ variant }) {
    function handleButtonClick() {
        // if it is in iframe, then skip redirecting
        if (window !== window.parent) {
            return;
        }
        const { targetSchema } = getLocalStorageAll();
        const redirectUri = targetSchema
            ? `${window.location.origin.toString()}?targetSchema=${targetSchema}`
            : `${window.location.origin.toString()}`;
        window.location.href = redirectUri;
    }
    return _jsx(ErrorPage, { variant: variant, onButtonClick: handleButtonClick });
}
