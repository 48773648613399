import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
export const ASSET_LAYER_ID = {
    default: `${LAYER_PREFIX}-asset`,
    hover: `${LAYER_PREFIX}-asset-hover`,
    highlight: `${LAYER_PREFIX}-asset-highlight`,
    selected: `${LAYER_PREFIX}-asset-selected`
};
export const ASSET_PROPERTY_ICON_ID = 'iconID';
const MIN_SCALE = 0.5;
const MAX_SCALE = 1;
export const assetLayer = {
    id: ASSET_LAYER_ID.default,
    type: 'symbol',
    layout: {
        'icon-image': ['get', ASSET_PROPERTY_ICON_ID],
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-anchor': 'center'
    }
};
export const assetHoverLayer = Object.assign(Object.assign({}, assetLayer), { id: ASSET_LAYER_ID.hover, layout: Object.assign(Object.assign({}, assetLayer.layout), { 'icon-image': ['concat', ['get', ASSET_PROPERTY_ICON_ID], '-hover'] }) });
export const assetHighlightLayer = Object.assign(Object.assign({}, assetLayer), { id: ASSET_LAYER_ID.highlight, layout: Object.assign(Object.assign({}, assetLayer.layout), { 'icon-image': ['concat', ['get', ASSET_PROPERTY_ICON_ID], '-hover'] }) });
export const assetSelectedLayer = Object.assign(Object.assign({}, assetLayer), { id: ASSET_LAYER_ID.selected, layout: Object.assign(Object.assign({}, assetLayer.layout), { 'icon-image': ['concat', ['get', ASSET_PROPERTY_ICON_ID], '-selected'] }) });
