import mixpanel from 'mixpanel-browser';
import { createReconstructionFilter, toggleDamage, toggleExcludeDamage, updateDamageRange } from '../../common/conditionDamages';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { ConditionSelectedFeatureType } from '../../common/consts/panelTab';
import { DEBOUNCE_FILTER_MIXPANEL } from '../../common/consts/time';
import { toggleItemInArray } from '../../common/utils/array';
import { roundTo } from '../../common/utils/number';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearSelectedFeature } from '../../state/slices/feature';
import { clearConditionMultiSelectedFeatures, DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN, setConditionFilter, setConditionIsMultiSelectOn, setConditionIsPointsSelectedAll, setConditionIsSegmentsSelectedAll, setConditionSorting, setSelectedFeatureType } from '../../state/slices/leftPanelCondition';
import { useDebounceCallback } from '../useDebounceCallback';
import { useLayerChecker } from '../useLayerChecker';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SEGMENT_LENGTH_ROUND_RANGE = [50, 100, 150, 200, 300, 400, 500, 1000];
export function useConditionFilterHandlers() {
    const dispatch = useAppDispatch();
    const { filter, selectedFeatureType, sort, reconstructionProposals } = useAppSelector((state) => state.leftPanelCondition);
    const { conditionFilters: { segmentLength, types: { surfaceTypes, streetTypes, roadDamages } } } = useAppSelector((state) => state.shared);
    const { shouldHideSurfaceTypes } = useLayerChecker();
    const reconstructionProposalPoint = [1];
    function mixpanelTrack(value) {
        mixpanel.track(MIXPANEL_EVENT_NAME.setConditionFilter, {
            [MIXPANEL_EVENT_PROPERTY.filter]: value
        });
    }
    const debouncedMixpanelTrack = useDebounceCallback(mixpanelTrack, DEBOUNCE_FILTER_MIXPANEL, []);
    function handleReconstructionProposalChange(value) {
        var _a;
        const filter = Object.assign(Object.assign({}, createReconstructionFilter(reconstructionProposals, { surfaceTypes, roadDamages, segmentLength }, value)), { reconstructionProposalId: value });
        if (shouldHideSurfaceTypes) {
            filter.surfaceType = [];
        }
        dispatch(setConditionFilter(filter));
        if (reconstructionProposalPoint.includes(value)) {
            dispatch(setSelectedFeatureType(ConditionSelectedFeatureType.point));
        }
        else {
            dispatch(setSelectedFeatureType(ConditionSelectedFeatureType.segment));
        }
        const reconstructionProposalsName = (_a = reconstructionProposals.find((proposal) => proposal.id === value)) === null || _a === void 0 ? void 0 : _a.name;
        mixpanelTrack(`Reconstruction proposals: ${reconstructionProposalsName}`);
    }
    function handleSelectedFeatureTypeChange(value) {
        if (selectedFeatureType !== ConditionSelectedFeatureType.both) {
            // Cannot unselect everything
            if (selectedFeatureType === value) {
                return;
            }
            dispatch(setSelectedFeatureType(ConditionSelectedFeatureType.both));
            mixpanelTrack(`View: ${ConditionSelectedFeatureType.both}`);
            return;
        }
        // points don't support sorting by length, in this case we need to change the sorting
        if (value === ConditionSelectedFeatureType.segment && sort.sortBy === 'length') {
            dispatch(setConditionSorting({ sortBy: 'grade', sortOrder: 'desc' }));
        }
        const newSelectedFeatureType = value === ConditionSelectedFeatureType.point
            ? ConditionSelectedFeatureType.segment
            : ConditionSelectedFeatureType.point;
        dispatch(setSelectedFeatureType(newSelectedFeatureType));
        mixpanelTrack(`View: ${newSelectedFeatureType}`);
    }
    function handleGradeValueChange(value) {
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            grade: value
        }));
        debouncedMixpanelTrack(`Grading: ${value.min} - ${value.max}`);
    }
    function handleSegmentLengthChange(value) {
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            segmentLengthMin: value.min,
            segmentLengthMax: value.max
        }));
        debouncedMixpanelTrack(`Segment Length: > ${roundTo(value.min, SEGMENT_LENGTH_ROUND_RANGE, 'floor')} - < ${roundTo(value.max, SEGMENT_LENGTH_ROUND_RANGE, 'ceil')}`);
    }
    function handleStreetTypeChange(value) {
        var _a;
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            streetType: toggleItemInArray(filter.streetType, value)
        }));
        if (!filter.streetType.includes(value)) {
            const statusTypeName = (_a = streetTypes.find((street) => street.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Road Classification: ${statusTypeName}`);
        }
    }
    function handleSurfaceTypeChange(value) {
        var _a;
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            surfaceType: toggleItemInArray(filter.surfaceType, value)
        }));
        if (!filter.surfaceType.includes(value)) {
            const surfaceTypeName = (_a = surfaceTypes.find((surface) => surface.id === value)) === null || _a === void 0 ? void 0 : _a.name;
            mixpanelTrack(`Surface Type: ${surfaceTypeName}`);
        }
    }
    function handleDamageTypeChange(value) {
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            damageType: toggleDamage(filter.damageType, value)
        }));
        if (!filter.damageType.find((type) => type.id === value.id)) {
            mixpanelTrack(`Damage Type: ${value.name} (${DEFAULT_GRADE_MIN} - ${DEFAULT_GRADE_MAX})`);
        }
    }
    function handleExcludeDamageChange(value) {
        const damageTypeArray = toggleExcludeDamage(filter.damageType, value);
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            damageType: damageTypeArray
        }));
        const damageType = damageTypeArray.find((type) => type.id === value);
        if (damageType) {
            mixpanelTrack(`Damage Type: ${damageType.name} (${damageType.isExcluded ? 'Exclude: ' : ''}${damageType.range.min} - ${damageType.range.max})`);
        }
    }
    function handleFilterDamageRangeChange(damageTypeId, value) {
        var _a;
        dispatch(setConditionFilter({
            reconstructionProposalId: undefined,
            damageType: updateDamageRange(filter.damageType, damageTypeId, value)
        }));
        const damageTypeName = (_a = filter.damageType.find((type) => type.id === damageTypeId)) === null || _a === void 0 ? void 0 : _a.name;
        debouncedMixpanelTrack(`Damage Type: ${damageTypeName} (${value.min} - ${value.max})`);
    }
    function handleResetSelection() {
        dispatch(setConditionIsMultiSelectOn(false));
        dispatch(setConditionIsPointsSelectedAll(false));
        dispatch(setConditionIsSegmentsSelectedAll(false));
        dispatch(clearConditionMultiSelectedFeatures());
        dispatch(clearSelectedFeature());
    }
    return {
        handleReconstructionProposalChange,
        handleSelectedFeatureTypeChange,
        handleGradeValueChange,
        handleSegmentLengthChange,
        handleStreetTypeChange,
        handleSurfaceTypeChange,
        handleDamageTypeChange,
        handleExcludeDamageChange,
        handleFilterDamageRangeChange,
        handleResetSelection
    };
}
