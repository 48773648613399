var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Children, useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { ActionModal } from '../ActionModal/ActionModal';
import { FormInputText } from '../FormInputText/FormInputText';
import { Loading, NoMatch } from '../Searchbar';
import styles from './AddressSearch.module.css';
export function AddressSearch(_a) {
    var { width, children, value, direction = 'down', onValueChange, status, infobox } = _a, props = __rest(_a, ["width", "children", "value", "direction", "onValueChange", "status", "infobox"]);
    const ref = useRef(null);
    const scrollRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [localValue, setLocalValue] = useState(value);
    const addressLabel = useDittoWrapper({ componentId: 'address' });
    useOnClickOutside(ref, () => setIsOpen(false));
    useEffect(() => {
        var _a;
        (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollRef.current]);
    useEffect(() => {
        setLocalValue(value);
    }, [value]);
    return (_jsxs("div", Object.assign({ ref: ref, className: classes(styles['container']) }, { children: [_jsx(FormInputText, Object.assign({}, props, { label: addressLabel, value: localValue, width: width, onValueChange: (v) => {
                    setIsOpen(true);
                    setLocalValue(v);
                    onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(v);
                } })), !isOpen && infobox && _jsxs(ActionModal, Object.assign({ width: width }, { children: [" ", infobox, " "] })), isOpen && (_jsx("div", Object.assign({ ref: scrollRef, className: classes(styles['anchor'], styles['anchor__' + direction]), onClick: () => setIsOpen(false) }, { children: _jsxs(ActionModal, Object.assign({ width: width }, { children: [infobox, (function () {
                            if (status === 'loading') {
                                return _jsx(Loading, {});
                            }
                            if (!children || !Children.count(children)) {
                                return _jsx(NoMatch, {});
                            }
                            return children;
                        })()] })) })))] })));
}
