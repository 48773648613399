import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { Avatar } from '../../../../uikit/Avatar/Avatar';
import { Button } from '../../../../uikit/Button/Button';
import { Close, Mail, Pending } from '../../../icons';
import { TitleSubtitle } from '../../../TitleSubtitle/TitleSubtitle';
import { CoWorkerRoleSelect } from '../CoWorkerRoleSelect/CoWorkerRoleSelect';
import styles from './CoWorkerTableItem.module.css';
export function CoWorkerTableItem({ className, data, isAdmin, roleList, onChangeUserRole, onDeleteInvite, onResendInvite, onRoleAndPermissionsClick }) {
    const youLabel = useDittoWrapper({ componentId: 'coworkermanagement.table.you' });
    const invitePendingLabel = useDittoWrapper({
        componentId: 'coworkermanagement.pending'
    });
    return (_jsxs("tr", Object.assign({ className: classes(styles['container'], className), "data-test": "coworker-table-item" }, { children: [_jsx("td", Object.assign({ className: styles['user'], "data-test": "table-item-user" }, { children: _jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles['user-data'] }, { children: [!data.isInviteType && (_jsx(Avatar, { firstName: data.firstName, lastName: data.lastName, src: data.profileImageUrl, size: 24 })), data.isInviteType && _jsx(Pending, {}), _jsx(TitleSubtitle, { className: styles['user-name'], title: !data.isInviteType
                                        ? `${data.firstName} ${data.lastName}${data.isCurrentUser ? ` ${youLabel}` : ''}`
                                        : invitePendingLabel, subtitle: data.email || data.phoneNumber || '' })] })), data.isInviteType && (_jsxs("div", Object.assign({ className: styles['invite-buttons'] }, { children: [_jsx(Button, { className: styles['invite-button'], icon: _jsx(Mail, {}), color: "yellow", variant: "slender", onClick: () => onResendInvite === null || onResendInvite === void 0 ? void 0 : onResendInvite(data.email, data.phoneNumber) }), _jsx(Button, { className: styles['invite-button'], icon: _jsx(Close, {}), color: "gray", variant: "slender", onClick: () => onDeleteInvite === null || onDeleteInvite === void 0 ? void 0 : onDeleteInvite(data.email, data.phoneNumber) })] })))] }) })), _jsx("td", Object.assign({ className: styles['department'] }, { children: data.departmentNameId && _jsx(Ditto, { componentId: data.departmentNameId }) })), _jsxs("td", Object.assign({ className: classes(styles['role'], isAdmin && styles['role-editable']) }, { children: [!data.isInviteType && !data.isCurrentUser && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles['role-text'], "data-test": "item-role-text" }, { children: data.roleId && _jsx(Ditto, { componentId: `role.${data.roleName}` }) })), isAdmin && (_jsx("div", Object.assign({ className: styles['role-select'] }, { children: _jsx(CoWorkerRoleSelect, { list: roleList, className: styles['role-field'], onChange: (value) => onChangeUserRole &&
                                        value &&
                                        onChangeUserRole(data.uuid, value), onRoleAndPermissionsClick: onRoleAndPermissionsClick, showRemoveAccessItem: true, userIsAdmin: isAdmin, value: data.roleId }) })))] })), (data.isInviteType || data.isCurrentUser) && data.roleId && (_jsx("span", Object.assign({ className: styles['role'], "data-test": "read-only-user-role" }, { children: _jsx(Ditto, { componentId: `role.${data.roleName}` }) })))] }))] })));
}
