var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCustomer } from '../common/customer';
import { post } from '../common/fetch';
const ADDRESS_REVERSE_GEOCODING_ENDPOINT = 'core-api/v1/address/by-coordinates-and-city-name';
const ADDRESS_GEOCODING_ENDPOINT = 'core-api/v1/address/geocoding';
export function getAddressForPosition(position) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(ADDRESS_REVERSE_GEOCODING_ENDPOINT, {
            latitude: position.lat,
            longitude: position.lng,
            cityName: getCustomer()
        }, undefined, true);
    });
}
export function getPositionForAddress(query) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(ADDRESS_GEOCODING_ENDPOINT, query, undefined, true);
    });
}
