var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { Ditto } from 'ditto-react';
import { getMarkerPriorityLabel, MarkerPriorityIcon } from '../../common/convert';
import { isFormulaRecurring, parseRecurringFormula } from '../../common/recurringRules';
import { getDateFnsLocale } from '../../common/utils/locale';
import { getDatePickerDateFormat } from '../../common/utils/time';
import { AssigneePreview } from '../../components/AppliedFilterBar/AssigneePreview/AssigneePreview';
import { RepeatBadge } from '../../components/DatePickerContent/RepeatBadge/RepeatBadge';
import { SelectedFrequency } from '../../components/DatePickerContent/RepeatForm/SelectedFrequency';
import { CategoryItem } from '../../components/EditorForms/CategoryItem/CategoryItem';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { MarkerStatusField, MarkerStatusSelectedValue } from '../../components/MarkerLowerForm/fields/MarkerStatusField';
import { BulkEditModal } from '../../components/Modals/BulkEditModal/BulkEditModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetBulkEditor, updateFormValue, updateMarkers } from '../../state/slices/bulkEditor';
import { invalidateCachedLinkedMarkers } from '../../state/slices/linkedMarkers';
export function BulkEditMarkerContainer({ onClose }) {
    var _a;
    const { removeModal, addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector((state) => state.app);
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { selectedIdList } = useAppSelector((state) => state.leftPanelMarker);
    const { markerPriorityList, markerCategoryList, markerStatusList, taskAssigneeList } = useAppSelector((state) => state.shared);
    const { form: formData, status: bulkStatus } = useAppSelector((state) => state.bulkEditor);
    const { reloadAssetTilesData } = useTilesUpdator();
    const priority = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return markerPriorityList.find((priority) => formData.priority === priority.priorityId);
    }, [formData, markerPriorityList]);
    const category = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return markerCategoryList.find((category) => formData.categoryId === category.categoryId);
    }, [formData, markerCategoryList]);
    const status = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return markerStatusList.find((status) => formData.statusId === status.statusId);
    }, [formData, markerStatusList]);
    function onChange(field, value) {
        dispatch(updateFormValue({ field: field, value }));
    }
    function handleCloseClick() {
        dispatch(resetBulkEditor());
        removeModal('BulkEditMarkerModal');
        onClose && onClose();
    }
    function handleSubmitClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!formData || selectedIdList.length === 0) {
                return;
            }
            yield dispatch(updateMarkers(Object.assign({ markerIds: selectedIdList }, formData)));
            dispatch(invalidateCachedLinkedMarkers());
            reloadAssetTilesData();
            removeModal('BulkEditMarkerModal');
            onClose && onClose();
        });
    }
    function handleCategorySelectClick() {
        return addModal({
            id: 'CategorySelectModalContainer',
            props: {
                onSelect: (id) => onChange('categoryId', id)
            }
        });
    }
    function handleAssigneeSelectClick() {
        var _a;
        return addModal({
            id: 'AssigneeSelectModalContainer',
            props: {
                initialValues: (_a = formData === null || formData === void 0 ? void 0 : formData.assignees) === null || _a === void 0 ? void 0 : _a.assignees,
                onConfirm: (ids) => {
                    onChange('assignees', ids.length > 0 ? { assignees: ids } : undefined);
                }
            }
        });
    }
    function handleDueDateSelectClick() {
        return addModal({
            id: 'DueDatePickerModalContainer',
            props: {
                recurringFormula: formData === null || formData === void 0 ? void 0 : formData.recurringFormula,
                onConfirm: (newFormula) => {
                    if (!newFormula) {
                        onChange('recurringFormula', undefined);
                        return;
                    }
                    onChange('recurringFormula', newFormula);
                }
            }
        });
    }
    useEffect(() => {
        if (selectedIdList.length === 0) {
            handleCloseClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdList]);
    const content = [
        {
            key: 'status',
            label: _jsx(Ditto, { componentId: "marker.status" }),
            items: markerStatusList.map(({ statusId, statusName }) => (_jsx(MarkerStatusField, { status: {
                    statusId,
                    statusName
                }, isSelected: (formData === null || formData === void 0 ? void 0 : formData.statusId) === statusId, onClick: () => {
                    onChange('statusId', statusId);
                    dispatch(invalidateCachedLinkedMarkers());
                    reloadAssetTilesData();
                } }, `status-${statusId}`))),
            current: (formData === null || formData === void 0 ? void 0 : formData.statusId) && status ? (_jsx(MarkerStatusSelectedValue, { status: status })) : undefined
        },
        {
            key: 'priority',
            label: _jsx(Ditto, { componentId: "marker.priority" }),
            items: markerPriorityList.map(({ priorityId }) => (_jsx(SelectboxEntry, { onClick: () => {
                    onChange('priority', priorityId);
                    dispatch(invalidateCachedLinkedMarkers());
                    reloadAssetTilesData();
                }, isChecked: (formData === null || formData === void 0 ? void 0 : formData.priority) === priorityId, text: getMarkerPriorityLabel(priorityId), leadingIcon: _jsx(MarkerPriorityIcon, { value: priorityId }), "data-test": `dropdown-priority-${priorityId}` }, `priority-${priorityId}`))),
            current: (formData === null || formData === void 0 ? void 0 : formData.priority) && priority ? (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(MarkerPriorityIcon, { value: formData.priority }), text: getMarkerPriorityLabel(formData.priority) })) : undefined
        },
        {
            key: 'category',
            label: _jsx(Ditto, { componentId: "marker.category" }),
            onClick: handleCategorySelectClick,
            current: (formData === null || formData === void 0 ? void 0 : formData.categoryId) && category ? (_jsx(CategoryItem, { selected: true, category: category }, category.categoryId)) : undefined
        },
        {
            key: 'assignee',
            label: _jsx(Ditto, { componentId: "assignee" }),
            onClick: handleAssigneeSelectClick,
            current: ((_a = formData === null || formData === void 0 ? void 0 : formData.assignees) === null || _a === void 0 ? void 0 : _a.assignees) ? (_jsx(FormInputCurrentSelectedValue, { text: _jsx(AssigneePreview, { options: taskAssigneeList, selectedValues: formData.assignees.assignees }) })) : undefined
        },
        {
            key: 'duedate',
            label: _jsx(Ditto, { componentId: "duedate" }),
            onClick: handleDueDateSelectClick,
            current: (formData === null || formData === void 0 ? void 0 : formData.recurringFormula) ? (_jsx(FormInputCurrentSelectedValue, { text: (function () {
                    const formula = parseRecurringFormula(formData.recurringFormula);
                    if (!formula) {
                        return;
                    }
                    const datePickerFormat = getDatePickerDateFormat(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode);
                    const inputValue = format(formula.startDate, datePickerFormat, {
                        locale: getDateFnsLocale(locale, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode)
                    });
                    return (_jsxs(_Fragment, { children: [inputValue, _jsx("span", { children: isFormulaRecurring(formula) && (formula === null || formula === void 0 ? void 0 : formula.frequency) && (_jsx(RepeatBadge, { text: _jsx(SelectedFrequency, { frequency: formula.frequency }) })) })] }));
                })() })) : undefined
        }
    ];
    return (_jsx(BulkEditModal, { isChanged: !!formData, content: content, loading: bulkStatus === 'loading', onSubmitClick: handleSubmitClick, onCloseClick: handleCloseClick }));
}
