import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { toggleItemInArray } from '../../../common/utils/array';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Avatar } from '../../../uikit/Avatar/Avatar';
import { FilterSelectbox } from '../../FilterSelectbox/FilterSelectbox';
import { InfoBox } from '../../InfoBox/InfoBox';
import { Search } from '../../Search/Search';
import { AssigneeName } from './AssigneeName/AssigneeName';
import styles from './AssigneeSelect.module.css';
export function AssigneeSelect({ options, selectedValues, onSelect }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'search' });
    const [searchValue, setSearchValue] = useState('');
    function handleSelectboxClick(id) {
        const newSelectedValue = toggleItemInArray(selectedValues, id);
        onSelect(newSelectedValue);
    }
    function assigneeName(assignee) {
        return (assignee.firstName + ' ' + assignee.lastName).toLowerCase();
    }
    function sortOptions(a, b) {
        return (assigneeName(a).indexOf(searchValue.toLowerCase()) -
            assigneeName(b).indexOf(searchValue.toLowerCase()));
    }
    const filterdOptions = searchValue
        ? options
            .filter((o) => o.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            o.lastName.toLowerCase().includes(searchValue.toLowerCase()))
            .sort(sortOptions)
        : options;
    return (_jsxs("div", Object.assign({ className: styles['modal-search'] }, { children: [_jsx(Search, { placeholder: placeholderLabel, value: searchValue, onChange: (value) => setSearchValue(value), variant: "field" }), _jsx("div", { className: styles['modal-search-divider'] }), _jsx("div", Object.assign({ className: styles['modal-search-results'] }, { children: filterdOptions.length === 0 ? (_jsx(InfoBox, { content: _jsx(Ditto, { componentId: "assignee.noresults" }) })) : (_jsx(_Fragment, { children: filterdOptions.map((o) => (_jsx("div", Object.assign({ className: styles['search-result'] }, { children: _jsx(FilterSelectbox, { content: _jsx(AssigneeName, { firstName: o.firstName, lastName: o.lastName }), icon: _jsx(Avatar, { firstName: o.firstName, lastName: o.lastName, src: o.profileURL, size: 24 }), isChecked: selectedValues.includes(o.id), onClick: () => handleSelectboxClick(o.id), height: 56, color: "black" }) }), o.id))) })) }))] })));
}
