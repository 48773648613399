export const MIXPANEL_GROUP_KEY = {
    customerId: 'customer_id',
    assetTypeId: 'asset_type_id',
    assetPropertyId: 'asset_property_id'
};
export const MIXPANEL_EVENT_NAME = {
    // global
    selectLayer: 'Select Layer',
    selectPreset: 'Select Preset',
    activateNewLayerSelector: 'Activate New Layer Selector',
    deactivateNewLayerSelector: 'Deactivate New Layer Selector',
    // condition
    viewConditionImage: 'View Condition Image',
    setConditionFilter: 'Set Condition Filter',
    // task
    viewTaskFile: 'View Task File',
    // task - costs
    clickOnTeasedCostsTab: 'Click On Teased Costs Tab in web system',
    clickOnTeasedCostsSurvey: 'Open Costs Teaser Survey In Web System',
    addCustomCostsProperty: 'Add Custom Costs Property',
    // asset
    showAssetIntroPopup: 'Show Assets Intro Popup',
    showABTMIntroPopup: 'Show ABTM Introduction Popup',
    showSignInventoryIntroPopup: 'Show Sign Inventory Introduction Popup',
    clickABTMBanner: 'Click On ABTM Focus Webinar Banner',
    startTaskToAssetConverter: 'Start Task To Asset Converter',
    finishTaskToAssetConverter: 'Finish Task To Asset Converter',
    filterAssetType: 'Filter For Asset Type',
    deleteDefaultAssetProperty: 'Delete Default Asset Property',
    deleteDefaultAssetType: 'Delete Default Asset Type',
    exportSelectedAsset: 'Export Selected Asset',
    printSelectedAsset: 'Print Selected Asset',
    emailSelectedAsset: 'Share Selected Asset Via Mail',
    linkNewTaskToExistingAsset: 'Link New Task To Existing Asset',
    linkExistingTaskToExistingAsset: 'Link Existing Task To Existing Asset',
    linkNewAssetToExistingTask: 'Link New Asset To Existing Task',
    linkExistingAssetToExistingTask: 'Link Existing Asset To Existing Task',
    bulkCreateTasksFromAssets: 'Bulk-Create Tasks From Assets',
    createAssetFromImageViewer: 'Create Asset From Image Viewer',
    createTaskWhenCreatingAsset: 'Create New Task During Asset Creation Process',
    createAssetWhenCreatingTask: 'Create Asset During Task Creation Process',
    viewAssetFile: 'View Asset File',
    // plan
    setPlanFilter: 'Set Plan Filter',
    showDefaultTreatmentsIntroPopup: 'Show Default Treatments and default width Introduction Popup',
    showRemainingServiceLifeIntroPopup: 'Show Remaining Service Life Introduction Popup',
    // manhole/storm drain
    createTaskFromObject: 'Create Task From Manhole/Storm drain',
    filterObject: 'Filter For Manhole/Storm Drain',
    // traffic signs
    reportTrafficSign: 'Report Sign',
    createSign: 'Create Sign',
    createTaskFromSign: 'Create Task From Sign',
    setTrafficSignFilter: 'Set Traffic Sign Filter',
    openTrafficSignDetails: 'Open Traffic Sign Details',
    openBudgetTooltip: 'Open Budget Tooltip'
};
export const MIXPANEL_EVENT_PROPERTY = {
    layerName: 'Layer Name',
    presetName: 'Preset Name',
    pointUUID: 'Point UUID',
    assetTypeName: 'Asset Type Name',
    assetPropertyName: 'Asset Property Name',
    filter: 'Filter',
    objectType: 'Manhole/Storm Drain Object Type',
    taskCreatorSource: 'Task Creator Source',
    taskCostsPropertyName: 'Costs Property Name',
    taskIntervall: 'Task Intervall',
    signCreatedBy: 'Sign Created By',
    signCountry: 'Sign Country',
    signType: 'Sign Type Name',
    signFilterType: 'Sign Filter Type',
    openTSDetailsFrom: 'Open Traffic Sign Details From'
};
export const MIXPANEL_EVENT_VALUE = {
    list: 'List',
    map: 'Map',
    multiselect: 'Multiselect',
    details: 'Details',
    overflowMenu: 'Overflow-Menu',
    oneTime: 'One Time',
    recurring: 'Recurring',
    signCreatedByImageViewer: 'Image Viewer',
    signCreatedBySignCreator: 'Sign Creator'
};
