import { FEATURE_STATE_FILTERED_OUT, FEATURE_STATE_HIGHLIGHT, FEATURE_STATE_HOVER, FEATURE_STATE_SELECTED } from '../featureStates';
import { DEFAULT_ZOOM, FILTERED_OUT_LINE_FADE_OUT_ZOOM, LAYER_PREFIX, MAX_ZOOM, MIN_ZOOM } from '../settings';
import { MAP_ID_FIELD_NAME, SEGMENT_JUNCTIONS_SOURCE_ID, SEGMENTS_SOURCE_ID } from '../sources';
export const SEGMENTS_LAYER_ID = `${LAYER_PREFIX}-segments`;
export const SEGMENTS_SELECTED_LAYER_ID = `${LAYER_PREFIX}-segments-selected`;
export const SEGMENTS_FILTERED_OUT_LAYER_ID = `${LAYER_PREFIX}-segments-filtered-out`;
export const SEGMENTS_JUNCTIONS_LAYER_ID = `${LAYER_PREFIX}-segments-junctions`;
const DEFAULT_MIN_LINE_WIDTH = 1;
const DEFAULT_LINE_WIDTH = 4;
const DEFAULT_MAX_LINE_WIDTH = 5;
const HOVER_LINE_WIDTH = 8;
const HIGHLIGHT_LINE_WIDTH = 8;
const SELECTED_LINE_WIDTH = 16;
const FILTERED_OUT_LINE_WIDTH = 2;
const SELECTED_LINE_MIN_WIDTH = 6;
const SELECTED_LINE_STROKE_COLOR = '#1556EB';
const JUNCTION_COLOR = '#686E72';
const JUNCTION_SELECTED_COLOR = '#1556EB';
const JUNCTION_DEFAULT_RADUIS = 4;
const JUNCTION_FILTERED_OUT_RADUIS = 3;
const getLineWidthExpressionBySelectedAll = (selectedAll) => {
    return [
        'interpolate',
        ['linear'],
        ['zoom'],
        // zoom is MIN_ZOOM (or less) -> circle radius will be 2px
        MIN_ZOOM,
        [
            'case',
            ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
            HIGHLIGHT_LINE_WIDTH,
            DEFAULT_MIN_LINE_WIDTH
        ],
        // zoom is DEFAULT_ZOOM (or less) -> circle radius will be 2px
        DEFAULT_ZOOM,
        [
            'case',
            ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
            HIGHLIGHT_LINE_WIDTH,
            DEFAULT_LINE_WIDTH
        ],
        // zoom is MAX_ZOOM (or greater) -> circle radius will be 8px (11px if hovered)
        MAX_ZOOM,
        [
            'case',
            ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
            selectedAll ? DEFAULT_MAX_LINE_WIDTH : SELECTED_LINE_WIDTH,
            ['boolean', ['feature-state', FEATURE_STATE_HOVER], false],
            HOVER_LINE_WIDTH,
            ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
            HIGHLIGHT_LINE_WIDTH,
            ['boolean', ['feature-state', FEATURE_STATE_FILTERED_OUT], false],
            FILTERED_OUT_LINE_WIDTH,
            selectedAll ? SELECTED_LINE_WIDTH : DEFAULT_MAX_LINE_WIDTH
        ]
    ];
};
export const segmentsLayer = {
    id: SEGMENTS_LAYER_ID,
    source: SEGMENTS_SOURCE_ID,
    type: 'line',
    paint: {
        'line-color': ['get', 'color'],
        'line-width': getLineWidthExpressionBySelectedAll(false)
    },
    layout: {
        'line-cap': 'round',
        'line-join': 'miter'
    }
};
export const segmentsSelectedAllLayer = Object.assign(Object.assign({}, segmentsLayer), { paint: Object.assign(Object.assign({}, segmentsLayer.paint), { 'line-width': getLineWidthExpressionBySelectedAll(true) }) });
export const segmentsSelectedLayer = {
    id: SEGMENTS_SELECTED_LAYER_ID,
    source: SEGMENTS_SOURCE_ID,
    type: 'line',
    filter: ['==', MAP_ID_FIELD_NAME.segment, 0],
    paint: {
        'line-color': SELECTED_LINE_STROKE_COLOR,
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            SELECTED_LINE_MIN_WIDTH,
            MAX_ZOOM,
            SELECTED_LINE_WIDTH
        ]
    },
    layout: {
        'line-cap': 'round',
        'line-join': 'miter'
    }
};
export const segmentsFilteredOutLayer = {
    id: SEGMENTS_FILTERED_OUT_LAYER_ID,
    source: SEGMENTS_SOURCE_ID,
    type: 'line',
    paint: {
        'line-color': ['get', 'inactive_color'],
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            MIN_ZOOM,
            DEFAULT_MIN_LINE_WIDTH,
            DEFAULT_ZOOM,
            DEFAULT_LINE_WIDTH,
            MAX_ZOOM,
            FILTERED_OUT_LINE_WIDTH
        ],
        'line-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            FILTERED_OUT_LINE_FADE_OUT_ZOOM,
            0,
            MAX_ZOOM,
            1
        ]
    },
    layout: {
        'line-cap': 'round',
        'line-join': 'miter'
    }
};
export const segmentsJunctionsLayer = {
    id: SEGMENTS_JUNCTIONS_LAYER_ID,
    source: SEGMENT_JUNCTIONS_SOURCE_ID,
    type: 'circle',
    paint: {
        'circle-color': [
            'case',
            ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
            JUNCTION_SELECTED_COLOR,
            JUNCTION_COLOR
        ],
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            0,
            MAX_ZOOM,
            [
                'case',
                ['boolean', ['feature-state', FEATURE_STATE_FILTERED_OUT], false],
                JUNCTION_FILTERED_OUT_RADUIS,
                JUNCTION_DEFAULT_RADUIS
            ]
        ]
    }
};
