// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gUtoQRo9rYB2668gBSFD{width:auto;height:48px;display:inline-block;padding:4px;background:var(--primary-white);box-shadow:var(--box-shadow-dropdown);border-radius:16px}.dDhyxLxQemGm8ZmBJjzP{background:var(--primary-dark-blue);color:var(--primary-white)}.SfoBBlcfWFMwUuyE8myA{display:flex;align-items:center;justify-content:center}.SfoBBlcfWFMwUuyE8myA>*:not(:last-child){margin-right:4px}.s8bAwDrod0KmK6hMrMG2{display:flex;align-items:center}.s8bAwDrod0KmK6hMrMG2>*:not(:last-child){margin-right:4px}`, "",{"version":3,"sources":["webpack://./src/components/Actionbar/Actionbar.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,oBAAA,CACA,WAAA,CACA,+BAAA,CAEA,qCAAA,CACA,kBAAA,CAGJ,sBACI,mCAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,yCACI,gBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CAGJ,yCACI,gBAAA","sourcesContent":[".container {\n    width: auto;\n    height: 48px;\n    display: inline-block;\n    padding: 4px;\n    background: var(--primary-white);\n    /* Elevated 02 */\n    box-shadow: var(--box-shadow-dropdown);\n    border-radius: 16px;\n}\n\n.dark {\n    background: var(--primary-dark-blue);\n    color: var(--primary-white);\n}\n\n.inner {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.inner > *:not(:last-child) {\n    margin-right: 4px;\n}\n\n.button-content {\n    display: flex;\n    align-items: center;\n}\n\n.button-content > *:not(:last-child) {\n    margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gUtoQRo9rYB2668gBSFD`,
	"dark": `dDhyxLxQemGm8ZmBJjzP`,
	"inner": `SfoBBlcfWFMwUuyE8myA`,
	"button-content": `s8bAwDrod0KmK6hMrMG2`
};
export default ___CSS_LOADER_EXPORT___;
