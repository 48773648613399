import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { BetaBadge } from '../../BetaBadge/BetaBadge';
import { Modal } from '../../Modal/Modal';
import { YoutubeEmbed } from '../../YoutubeEmbed/YoutubeEmbed';
import styles from './InventoryTeaserModal.module.css';
export function InventoryTeaserModal({ onYesClick, onNoClick, videoEmbedId }) {
    return (_jsx(Modal, { className: styles['modal'], top: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "badgenewlocked" }) }), title: _jsx(Ditto, { componentId: "teaser.immodule.headline" }), content: _jsxs("div", Object.assign({ className: styles['content'] }, { children: [_jsx("span", Object.assign({ className: styles['description-text'] }, { children: _jsx(Ditto, { componentId: "teaser.immodule.description" }) })), _jsx(YoutubeEmbed, { embedId: videoEmbedId })] })), footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: () => onNoClick && onNoClick() }, { children: _jsx(Ditto, { componentId: "prompt.notinterested" }) })), _jsx(Button, Object.assign({ variant: "modal-action", color: "yellow", onClick: () => onYesClick && onYesClick() }, { children: _jsx(Ditto, { componentId: "prompt.findoutmore" }) }))] }), width: 720 }));
}
