// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QB5ifi6Ck76nJoeFz5LU{text-align:center}.ZHuESNBR2ncIoWGFS0eD{display:block;text-align:center;white-space:nowrap}.cnsas1OZwaev8l0Bhn3_{opacity:.6;text-align:left}.cnsas1OZwaev8l0Bhn3_ p{margin:6px 0}.UClH1pKpsWYhvEuJCQbS{font-size:12px;text-decoration:none}.UClH1pKpsWYhvEuJCQbS:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/MileYearsTooltipContent/MileYearsTooltipContent.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,aAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CAGJ,wBACI,YAAA,CAGJ,sBACI,cAAA,CACA,oBAAA,CAGJ,4BACI,yBAAA","sourcesContent":[".wrapper {\n    text-align: center;\n}\n\n.top {\n    display: block;\n    text-align: center;\n    white-space: nowrap;\n}\n\n.bottom {\n    opacity: 0.6;\n    text-align: left;\n}\n\n.bottom p {\n    margin: 6px 0;\n}\n\n.link {\n    font-size: 12px;\n    text-decoration: none;\n}\n\n.link:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `QB5ifi6Ck76nJoeFz5LU`,
	"top": `ZHuESNBR2ncIoWGFS0eD`,
	"bottom": `cnsas1OZwaev8l0Bhn3_`,
	"link": `UClH1pKpsWYhvEuJCQbS`
};
export default ___CSS_LOADER_EXPORT___;
