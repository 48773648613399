import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { classes } from 'classifizer';
import { useStateCallback } from '../../hooks/useStateCallback';
import { FilterGroup } from '../FilterGroup/FilterGroup';
import { FilterSelectbox } from '../FilterSelectbox/FilterSelectbox';
import { CaretCloseSmall, CaretOpenSmall } from '../icons';
import styles from './FilterGroupWrapper.module.css';
export function FilterGroupWrapper({ content, description, icon, link, children, checkState, onCheckboxClick, onToggle, isHeader = true, hideBottomBar, count, isInitiallyCollapsed = false, disabled = false }) {
    const [isOpen, setIsOpen] = useStateCallback(!isInitiallyCollapsed);
    const containerRef = useRef(null);
    const caret = isOpen ? _jsx(CaretOpenSmall, {}) : _jsx(CaretCloseSmall, {});
    function handleCheckboxClick() {
        onCheckboxClick && onCheckboxClick();
        if (!isOpen) {
            handleToggle(true);
        }
    }
    function handleToggle(state) {
        setIsOpen(state, () => {
            onToggle && onToggle(state, containerRef);
        });
    }
    return (_jsx(FilterGroup, Object.assign({ className: styles['container'], isOpen: disabled ? false : isOpen, isHeader: isHeader, ref: containerRef, header: _jsx(FilterSelectbox, { onClick: isHeader ? () => handleToggle(!isOpen) : onCheckboxClick, onCheckboxClick: handleCheckboxClick, isChecked: checkState === 'checked' || checkState === 'indeterminate', isIndeterminate: checkState === 'indeterminate', content: content, icon: icon, color: "black", trailingElement: isHeader ? (_jsxs(_Fragment, { children: [link && (_jsx("button", Object.assign({ className: styles['edit-button'], onClick: (event) => {
                            event.stopPropagation();
                            link.onClick();
                        } }, { children: link.text }))), count, ' ', _jsx("span", Object.assign({ className: classes(styles['arrow'], disabled && styles['disabled']) }, { children: caret }))] })) : (count), variant: isHeader ? 'light-header' : 'light', wrapContent: true, defaultCursor: disabled }), description: description, hideBottomBar: hideBottomBar }, { children: children })));
}
