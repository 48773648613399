// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qkcjBUnii0s5iaEmBHZv{padding:16px 16px 16px 24px;background:var(--primary-white, #fff);width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:var(--gap-gap-l, 8px);flex:1 0 0}.qkcjBUnii0s5iaEmBHZv>p{margin:0}.OGVoTj40BCFJ7M0itmQ2{font-size:16px;font-weight:600}.VSeJTk3QkaLUhA0NYP0v{color:var(--root-dark-blue-4, #7a8188);text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/NotificationOverlay/NotificationEmpty/NotificationEmpty.module.css"],"names":[],"mappings":"AAAA,sBACI,2BAAA,CACA,qCAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,UAAA,CAGJ,wBACI,QAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CAGJ,sBACI,sCAAA,CACA,iBAAA","sourcesContent":[".container {\n    padding: 16px 16px 16px 24px;\n    background: var(--primary-white, #fff);\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: var(--gap-gap-l, 8px);\n    flex: 1 0 0;\n}\n\n.container > p {\n    margin: 0;\n}\n\n.title {\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.description {\n    color: var(--root-dark-blue-4, #7a8188);\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `qkcjBUnii0s5iaEmBHZv`,
	"title": `OGVoTj40BCFJ7M0itmQ2`,
	"description": `VSeJTk3QkaLUhA0NYP0v`
};
export default ___CSS_LOADER_EXPORT___;
