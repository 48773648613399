import { useEffect } from 'react';
import { usePresence } from 'framer-motion';
import { useMountDelay } from './useMountDelay';
/**
 * For child components of `AnimatePresence`, this hook indicates
 * whether the component is in transition (either mounting or unmounting),
 * given the transition delay / duration.
 * @param transitionDuration The duration of the transition in ms, default is 300.
 * @returns Whether the transition is in progress.
 */
export const useIsInMountTransition = (transitionDuration = 300) => {
    const [isPresent, safeToRemove] = usePresence();
    const { mounted } = useMountDelay(transitionDuration);
    useEffect(() => {
        requestAnimationFrame(() => {
            if (safeToRemove) {
                safeToRemove();
            }
        });
    }, [isPresent, safeToRemove, transitionDuration]);
    return !isPresent || !mounted;
};
