export const isDeepEqual = (object1, object2) => {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);
    if (objKeys1.length !== objKeys2.length)
        return false;
    for (var key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];
        const isObjects = isObject(value1) && isObject(value2);
        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false;
        }
    }
    return true;
};
export function isDeepEqualByStringify(object1, object2) {
    const str1 = JSON.stringify(object1);
    const str2 = JSON.stringify(object2);
    return str1 === str2;
}
export const isObject = (object) => {
    return object != null && typeof object === 'object' && !Array.isArray(object);
};
function trimObjectInner(object) {
    const newObject = {};
    Object.entries(object).forEach(([key, value]) => {
        if (isObject(value)) {
            if (Object.keys(value).length > 0) {
                newObject[key] = trimObjectInner(value);
            }
        }
        else if (Array.isArray(value)) {
            if (value.length > 0) {
                newObject[key] = value;
            }
        }
        else {
            newObject[key] = value;
        }
    });
    return newObject;
}
export function trimObject(object) {
    return trimObjectInner(trimObjectInner(trimObjectInner(trimObjectInner(trimObjectInner(object)))));
}
export function update(object, update) {
    return Object.assign(Object.assign({}, object), update);
}
export function removeEmptyKeys(object) {
    const newObject = Object.assign({}, object);
    Object.keys(object).forEach((key) => (object[key] === undefined || object[key] === null) && delete newObject[key]);
    return newObject;
}
export function subset(obj, properties) {
    const result = Object.assign({}, obj);
    for (const key of Object.keys(obj)) {
        if (!properties.includes(key)) {
            delete result[key];
        }
    }
    return result;
}
export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}
