import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { NOT_SPECIFIED_CATEGORY_ID } from '../../../services/marker';
import { CategoryBadge } from '../../CategoryBadge/CategoryBadge';
export function CategoryPreivew({ options, selectedValues }) {
    if (selectedValues.length > 1) {
        return _jsx(_Fragment, { children: selectedValues.length });
    }
    const categoryId = selectedValues[0];
    const category = options.find((i) => i.id === categoryId);
    if (!category) {
        return null;
    }
    if (category.id === NOT_SPECIFIED_CATEGORY_ID) {
        return _jsx(Ditto, { componentId: "notspecified" });
    }
    return _jsx(CategoryBadge, { category: category.name, backgroundColor: category.color });
}
