import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CategoryBadge } from '../../CategoryBadge/CategoryBadge';
import { FilterSelectbox } from '../../FilterSelectbox/FilterSelectbox';
import styles from './CategoryItem.module.css';
export function CategoryItem({ category, onClick, selected = false, variant }) {
    if (selected) {
        return (_jsx(CategoryBadge, { category: category.categoryName, backgroundColor: category.color, variant: variant }));
    }
    return (_jsx(FilterSelectbox, { className: styles['container'], content: _jsx(CategoryBadge, { category: category.categoryName, backgroundColor: category.color }), onClick: onClick, noCheckbox: true }));
}
