import { createSlice } from '@reduxjs/toolkit';
import { DEBOUNCE_GEOCODE_API_MS } from '../../common/consts/time';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getMapboxReverseGeocoding } from '../../services/mapbox';
const initialState = {
    isLoading: false,
    fetchedSuggestions: []
};
// NOTE: Do NOT use this function for addresses we will store permanently (TOS issue)
export const fetchMapboxSuggestions = createDebouncedAsyncThunk('actionbar/fetchMapboxSuggestions', (query) => {
    return getMapboxReverseGeocoding(query);
}, DEBOUNCE_GEOCODE_API_MS);
export const actionbarSlice = createSlice({
    name: 'actionbar',
    initialState,
    reducers: {
        reset: () => initialState,
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setHorizontalOffset: (state, action) => {
            state.horizontalOffset = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMapboxSuggestions.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.fetchedSuggestions = action.payload.result.features;
            }
        });
    }
});
export const { reset: resetActionbar, setIsLoading: setIsLoadingActionbar, setHorizontalOffset: setActionbarHorizontalOffset } = actionbarSlice.actions;
export default actionbarSlice.reducer;
