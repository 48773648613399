var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { submitTrackingEvent } from '../../services/mail';
import { createSignInventory } from '../../services/signInventory';
import { setIsLoadingActionbar } from './actionbar';
import { setCurrentAction } from './app';
import { fetchSignInventoryList } from './leftPanelSignInventory';
import { clearDraftPin } from './mainMap';
import { addNotificationMessage } from './notification';
import { fetchSignDamageTypes, fetchSignInventoryTreeWithCounts } from './shared';
const initialState = {
    createStatus: 'idle',
    shouldSubmit: false
};
export const createSignTk = createAppAsyncThunk('signCreator/createSign', (body, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    dispatch(setIsLoadingActionbar(true));
    const res = yield createSignInventory(body);
    dispatch(setIsLoadingActionbar(false));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.successfullycreated' }
            }
        }));
        dispatch(setCurrentAction('none'));
        dispatch(fetchSignInventoryList());
        dispatch(fetchSignDamageTypes());
        dispatch(fetchSignInventoryTreeWithCounts());
        dispatch(clearDraftPin());
        const state = getState();
        submitTrackingEvent(MIXPANEL_EVENT_NAME.createSign, {
            [MIXPANEL_EVENT_PROPERTY.signCreatedBy]: body.tracking.signCreatedBy,
            [MIXPANEL_EVENT_PROPERTY.signCountry]: ((_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode) || 'Unknown',
            [MIXPANEL_EVENT_PROPERTY.signType]: body.subSpecifiedClass || 'Unknown'
        });
    }
    return res;
}));
export const signCreatorSlice = createSlice({
    name: 'signCreator',
    initialState,
    reducers: {
        reset: () => initialState,
        setPointSource: (state, action) => {
            state.source = action.payload;
        },
        setShouldSubmit: (state, action) => {
            state.shouldSubmit = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createSignTk.pending, (state) => {
            state.createStatus = 'loading';
        })
            .addCase(createSignTk.rejected, (state) => {
            state.createStatus = 'failed';
        })
            .addCase(createSignTk.fulfilled, (state) => {
            state.createStatus = 'succeeded';
        });
    }
});
export const { reset: resetSignCreator, setPointSource: setTrafficSignCreatorPointSource, setShouldSubmit: submitSignCreator } = signCreatorSlice.actions;
export default signCreatorSlice.reducer;
