import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { LngLat } from 'mapbox-gl';
import { MAP_POPOVER_ANCHOR, MAP_POPOVER_BEHAVIOR, MAP_VERTICAL_POPOVER_SPLIT } from '../../common/consts/map';
import { MapPopoverClickContainer } from '../../containers/MapPopoverClickContainer';
import { MapPopoverHoverContainer } from '../../containers/MapPopoverHoverContainer';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearClickPopoverData, clearHoverPopoverData, fetchClickPopoverData, fetchHoverPopoverData } from '../../state/slices/mapPopover';
export function useMapPopup() {
    const { main: map } = useMap();
    const dispatch = useAppDispatch();
    const { hoveredFeature, clickedFeature } = useAppSelector((state) => state.feature);
    const { modals } = useModalContext();
    const [mouseCoords, setMouseCoords] = useState(new LngLat(0, 0));
    const listener = useCallback((e) => {
        if (!hoveredFeature) {
            return;
        }
        setMouseCoords(e.lngLat);
    }, [hoveredFeature]);
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('mousemove', listener);
        return () => {
            map.off('mousemove', listener);
        };
    }, [map, listener]);
    useEffect(() => {
        if (!hoveredFeature) {
            dispatch(clearHoverPopoverData());
            return;
        }
        if (modals.length === 0) {
            dispatch(fetchHoverPopoverData());
        }
    }, [dispatch, hoveredFeature, modals.length]);
    useEffect(() => {
        if (!clickedFeature) {
            dispatch(clearClickPopoverData());
            return;
        }
        if (modals.length === 0) {
            dispatch(fetchClickPopoverData());
        }
    }, [dispatch, clickedFeature, modals.length]);
    const getPopupPosition = useCallback((feature) => {
        var _a;
        let anchor = 'bottom';
        // NOTE: Default popover behavior is follow mouse
        let latitude = mouseCoords.lat;
        let longitude = mouseCoords.lng;
        if (feature) {
            anchor = (_a = MAP_POPOVER_ANCHOR[feature.feature.type]) !== null && _a !== void 0 ? _a : anchor;
            if (MAP_POPOVER_BEHAVIOR[feature.feature.type] === 'fixed' && feature.location) {
                latitude = feature.location.lat;
                longitude = feature.location.lng;
            }
        }
        let popoverDirection = 'up';
        if (map) {
            const windowPosition = map.project({ lng: longitude, lat: latitude });
            const pointDown = windowPosition.y / map.getCanvas().getBoundingClientRect().height <
                MAP_VERTICAL_POPOVER_SPLIT;
            popoverDirection = pointDown ? 'down' : 'up';
            if (popoverDirection === 'down' && anchor === 'bottom') {
                anchor = 'top';
            }
        }
        return { anchor, latitude, longitude, popoverDirection };
    }, [map, mouseCoords.lat, mouseCoords.lng]);
    const getPopupData = useCallback((feature, children) => {
        const { latitude, longitude, anchor } = getPopupPosition(feature);
        return {
            latitude,
            longitude,
            anchor,
            children,
            closeButton: false
        };
    }, [getPopupPosition]);
    if (modals.length > 0) {
        return {
            hoverPopup: undefined,
            clickPopup: undefined
        };
    }
    return {
        hoverPopup: hoveredFeature
            ? getPopupData(hoveredFeature, _jsx(MapPopoverHoverContainer, { direction: getPopupPosition(hoveredFeature).popoverDirection }, 'map-popover-hover-container'))
            : undefined,
        clickPopup: clickedFeature
            ? getPopupData(clickedFeature, _jsx(MapPopoverClickContainer, { direction: getPopupPosition(clickedFeature).popoverDirection }, 'map-popover-click-container'))
            : undefined
    };
}
