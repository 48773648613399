// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SlIB6pB5koPkzfLWK4m3{min-width:24px;height:24px;font-size:14px;font-weight:600;color:var(--primary-dark-blue);display:inline-flex;flex-direction:row;justify-content:center;align-items:center;padding:0 4px;border-radius:6px;background:var(--primary-dark-blue-2)}`, "",{"version":3,"sources":["webpack://./src/components/AppliedFilterBar/AssigneePreview/AssigneePreview.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,8BAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CACA,iBAAA,CACA,qCAAA","sourcesContent":[".assignees-wrap {\n    min-width: 24px;\n    height: 24px;\n    font-size: 14px;\n    font-weight: 600;\n    color: var(--primary-dark-blue);\n    display: inline-flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 0 4px;\n    border-radius: 6px;\n    background: var(--primary-dark-blue-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assignees-wrap": `SlIB6pB5koPkzfLWK4m3`
};
export default ___CSS_LOADER_EXPORT___;
