var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getEdgesByIdsList, getPointsByUuidList } from '../../services/condition';
import { getPlanDetailsByIdList } from '../../services/plan';
import { getSignInfoByIdList } from '../../services/sign';
import { getSignInventoryInfoByIdList } from '../../services/signInventory';
const initialState = {
    isLoading: false,
    data: {
        type: null,
        results: null
    }
};
const getEdgePrintData = (ids) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const edgesResponse = yield getEdgesByIdsList(ids);
    if (edgesResponse.errorCode || !edgesResponse.result) {
        throw new Error(edgesResponse.errorMessage);
    }
    return (_a = edgesResponse.result.list) === null || _a === void 0 ? void 0 : _a.map((edge) => {
        return {
            feature: { type: 'node-edge', value: edge },
            geojson: edge.featureCollection,
            photoUrls: [edge.imageThumbnailUrl]
        };
    });
});
export const getPointPrintData = (uuids) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const pointsResponse = yield getPointsByUuidList(uuids);
    if (pointsResponse.errorCode || !pointsResponse.result) {
        throw new Error(pointsResponse.errorMessage);
    }
    return (_b = pointsResponse.result.list) === null || _b === void 0 ? void 0 : _b.map((point) => {
        return {
            feature: { type: 'point', value: point },
            geojson: point.featureCollection,
            photoUrls: [point.imageThumbnailUrl]
        };
    });
});
const getSignPrintData = (ids) => __awaiter(void 0, void 0, void 0, function* () {
    const signData = yield getSignInfoByIdList({ ids });
    if (signData.errorCode || !signData.result) {
        throw new Error(signData.errorMessage);
    }
    if (!signData.result.sign) {
        return [];
    }
    const results = signData.result.sign.map((sign) => {
        return {
            feature: { type: 'sign', value: sign },
            photoUrls: [sign.imageUrl],
            geojson: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [sign.offsetPosition[0], sign.offsetPosition[1]]
                },
                properties: {
                    icon: sign.damageType
                }
            }
        };
    });
    return results;
});
const getSignInventoryPrintData = (ids) => __awaiter(void 0, void 0, void 0, function* () {
    const signData = yield getSignInventoryInfoByIdList({ ids });
    if (signData.errorCode || !signData.result) {
        throw new Error(signData.errorMessage);
    }
    const results = signData.result.sign.map((sign) => {
        return {
            feature: { type: 'sign-inventory', value: sign },
            photoUrls: [sign.imageUrl],
            geojson: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [sign.offsetPosition[0], sign.offsetPosition[1]]
                },
                properties: {
                    sign_id: sign.id,
                    icon: sign.damageType,
                    sub_specified_class: sign.tag.subSpecifiedClass
                }
            }
        };
    });
    return results;
});
const getPlanPrintData = ({ countryCode, ids }) => __awaiter(void 0, void 0, void 0, function* () {
    const planData = yield getPlanDetailsByIdList({ countryCode, planIds: ids });
    if (planData.errorCode || !planData.result || !planData.result.plans) {
        throw new Error(planData.errorMessage);
    }
    return planData.result.plans.map((plan) => ({
        feature: { type: 'plan', value: plan },
        geojson: plan.lineGeom,
        photoUrls: []
    }));
});
export const fetchPrintData = createAppAsyncThunk('print/fetchPrintData', (params, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const state = getState();
    const countryCode = (_c = state.auth.teamAccount) === null || _c === void 0 ? void 0 : _c.countryCode;
    switch (params.type) {
        case 'segment': {
            return getEdgePrintData({ ids: params.ids });
        }
        case 'point': {
            return getPointPrintData({ uuids: params.uuids });
        }
        case 'plan': {
            return getPlanPrintData({ countryCode, ids: params.ids });
        }
        case 'sign': {
            return getSignPrintData(params.ids);
        }
        case 'sign-inventory': {
            return getSignInventoryPrintData(params.ids);
        }
    }
}));
export const printSlice = createSlice({
    name: 'print',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPrintData.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                Object.assign(state.data, {
                    type: action.meta.arg.type,
                    results: action.payload
                });
            }
        })
            .addCase(fetchPrintData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            console.error('failed to fetch print data', action.error);
        })
            .addCase(fetchPrintData.pending, (state) => {
            state.isLoading = true;
            state.error = undefined;
        });
    }
});
export const { reset: resetPrint } = printSlice.actions;
export default printSlice.reducer;
