// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hhXODMehkfni7vnlJEPq{display:flex;flex-direction:row;justify-content:center;align-items:center;padding:4px;position:absolute;width:31px;height:30px;right:0;bottom:0;background:rgba(34,45,57,.6);border-radius:4px 0 4px 0;color:var(--primary-white);cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/Result/ImageEnlarge/ImageEnlarge.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,OAAA,CACA,QAAA,CACA,4BAAA,CACA,yBAAA,CACA,0BAAA,CACA,cAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 4px;\n    position: absolute;\n    width: 31px;\n    height: 30px;\n    right: 0;\n    bottom: 0;\n    background: rgba(34, 45, 57, 0.6);\n    border-radius: 4px 0 4px 0;\n    color: var(--primary-white);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hhXODMehkfni7vnlJEPq`
};
export default ___CSS_LOADER_EXPORT___;
