// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IJjiVaDZOY1JjAqMNs6G{display:flex;width:40px;height:40px;padding:8px;justify-content:center;align-items:center;border-radius:6px}.i9DuREvUltR6NdMgtbSh{cursor:pointer}.i9DuREvUltR6NdMgtbSh:hover{background:var(--status-hover-hover, rgba(129, 129, 129, 0.1))}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/AssetTypesSettingPanel/IconSelect/IconSelect.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,sBACI,cAAA,CAGJ,4BACI,8DAAA","sourcesContent":[".icon {\n    display: flex;\n    width: 40px;\n    height: 40px;\n    padding: 8px;\n    justify-content: center;\n    align-items: center;\n    border-radius: 6px;\n}\n\n.icon__default {\n    cursor: pointer;\n}\n\n.icon__default:hover {\n    background: var(--status-hover-hover, rgba(129, 129, 129, 0.1));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `IJjiVaDZOY1JjAqMNs6G`,
	"icon__default": `i9DuREvUltR6NdMgtbSh`
};
export default ___CSS_LOADER_EXPORT___;
