// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RxuNBeQoqEiXP5NSr7hB{display:flex;flex-direction:column;align-items:center;position:absolute;transform-origin:center center;width:112px;height:112px;top:-46px;left:-56px;z-index:5}.TxBNBKd9GS3e5wF89SKw{position:absolute;transform-origin:center center}.qXGlvWAWVPDf11fMX99g{position:absolute;top:2px;height:24px;width:24px;cursor:move}`, "",{"version":3,"sources":["webpack://./src/components/TrafficSignRotator/TrafficSignRotator.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,8BAAA,CACA,WAAA,CACA,YAAA,CACA,SAAA,CACA,UAAA,CACA,SAAA,CAGJ,sBACI,iBAAA,CACA,8BAAA,CAGJ,sBACI,iBAAA,CACA,OAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: absolute;\n    transform-origin: center center;\n    width: 112px;\n    height: 112px;\n    top: -46px;\n    left: -56px;\n    z-index: 5;\n}\n\n.sign-orientation {\n    position: absolute;\n    transform-origin: center center;\n}\n\n.rotation-handle {\n    position: absolute;\n    top: 2px;\n    height: 24px;\n    width: 24px;\n    cursor: move;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RxuNBeQoqEiXP5NSr7hB`,
	"sign-orientation": `TxBNBKd9GS3e5wF89SKw`,
	"rotation-handle": `qXGlvWAWVPDf11fMX99g`
};
export default ___CSS_LOADER_EXPORT___;
