var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Marker, Source, useMap } from 'react-map-gl';
import { booleanPointInPolygon, buffer, point } from '@turf/turf';
import { MAP_TRAFFIC_SIGN_ICON_HORIZONTAL_OFFSET, MAP_TRAFFIC_SIGN_ICON_VERTICAL_OFFSET, MAP_VIA_LAT, MAP_VIA_LNG } from '../../common/consts/map';
import { MIXPANEL_EVENT_VALUE } from '../../common/consts/mixpanel';
import { SIGN_DAMAGE_ID, SIGN_DAMAGE_NAMES, SIGN_INVENTORY_SIGNS_TO_SHOW } from '../../common/consts/trafficSign';
import { ASSET_CREATOR_FORM_WIDTH } from '../../common/consts/width';
import { getBastId } from '../../common/convert/signInventory';
import { createInvertedPolygon } from '../../common/map';
import { createRequestTransformerFunc, createSignInventorySource, DEFAULT_SOURCE_LAYER, signInventoryLayer, signInventoryPoleLayer, signInventoryResctrictedAreaLayer } from '../../common/mapbox';
import { getApiHost } from '../../common/url';
import { AddressSearch } from '../../components/AddressSearch/AddressSearch';
import { Minimap } from '../../components/Minimap/Minimap';
import { OrientationField } from '../../components/OrientationField/OrientationField';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { TrafficSignCreator } from '../../components/TrafficSignCreator/TrafficSignCreator';
import { TrafficSignMarker } from '../../components/TrafficSignMarker';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useSignFields } from '../../hooks/forms/useSignFields';
import { useMainMap, useTiles } from '../../hooks/mainMap';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { useMapInit } from '../../hooks/useMapInit';
import { useSignInventoryFiles } from '../../hooks/useSignInventoryFiles';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setDraftPinContent } from '../../state/slices/mainMap';
import { fetchAddress } from '../../state/slices/sharedDataHolder';
import { createSignTk, resetSignCreator } from '../../state/slices/signCreator';
const mapId = 'ImageViewerTrafficSignCreatorContainer-Map';
export function RightPanelTrafficSignCreatorContainer() {
    var _a, _b;
    const dispatch = useAppDispatch();
    const { center, draftPin } = useAppSelector((state) => state.mainMap);
    const { targetSchema } = useAppSelector((state) => state.auth);
    const rootRef = useRef(null);
    const { source, createStatus } = useAppSelector((state) => state.signCreator);
    const { signTree, signInventoryDamageTypes } = useAppSelector((state) => state.shared);
    const [matchingSignTree, setMatchingSignTree] = useState(signTree);
    const [selectedType, setSelectedType] = useState('');
    const { updateSignFields, signFields, getSignDraft, getCustomPropertiesFields, updateSignFiles } = useSignFields({
        position: {
            lng: ((_a = source === null || source === void 0 ? void 0 : source.coordinates) === null || _a === void 0 ? void 0 : _a.longitude) || MAP_VIA_LNG,
            lat: ((_b = source === null || source === void 0 ? void 0 : source.coordinates) === null || _b === void 0 ? void 0 : _b.latitude) || MAP_VIA_LAT
        },
        condition: {
            id: SIGN_DAMAGE_ID.not_damaged,
            name: SIGN_DAMAGE_NAMES.not_damaged
        }
    });
    const { filesSection } = useSignInventoryFiles({
        mode: 'create',
        signFields,
        onGetUuids: handleGetUuids
    });
    const { reloadSignInventoryTilesData } = useTilesUpdator();
    const { addModal } = useModalContext();
    const { formatAddress } = useFormatter();
    const handleMapInit = useMapInit();
    const { signInventoryTiles } = useTiles();
    const { getBeforeId } = useMainMap();
    const { [mapId]: map } = useMap();
    const [bastId, setBastId] = useState('');
    const maxRadius = 100;
    const restrictedArea = useRef(buffer(point([center.lng, center.lat]), maxRadius, { units: 'meters' }));
    const invertedRestrictedArea = useRef(createInvertedPolygon(restrictedArea.current.geometry));
    const lastPosition = useRef(signFields.position);
    const { handleAddressSearchChange, handleAddressSuggestionClick, fetchedAddress, fetchedSuggestions, addressSearchStatus } = useAddressSearch();
    const { isAdmin } = useUserRole();
    const signDamageTypesToShow = signInventoryDamageTypes.filter((t) => SIGN_INVENTORY_SIGNS_TO_SHOW.includes(t.id));
    const [isRotating, setIsRotating] = useState(false);
    function handleSelectType(type) {
        setSelectedType(type);
        updateSignFields({ subSpecifiedClass: type });
        setBastId(getBastId(signTree, type));
        dispatch(setDraftPinContent({ type: 'sign', icon: type }));
    }
    useEffect(() => {
        if (!source) {
            return;
        }
        const { longitude, latitude } = source.coordinates;
        dispatch(fetchAddress({ lat: latitude, lng: longitude }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);
    useEffect(() => {
        if (draftPin &&
            draftPin.lat !== signFields.position.lat &&
            draftPin.lng !== signFields.position.lng) {
            updateSignFields({
                position: draftPin
            });
            dispatch(fetchAddress(draftPin));
        }
    }, [dispatch, draftPin, signFields.position, updateSignFields]);
    function handleSignCreateClick() {
        return __awaiter(this, void 0, void 0, function* () {
            const signDraft = getSignDraft();
            dispatch(createSignTk(Object.assign(Object.assign({}, signDraft), { address: fetchedAddress, tracking: {
                    signCreatedBy: MIXPANEL_EVENT_VALUE.signCreatedByImageViewer
                } }))).then(() => {
                reloadSignInventoryTilesData();
            });
            dispatch(resetSignCreator());
        });
    }
    function handleClickClose() {
        dispatch(resetSignCreator());
    }
    function handleSearchValueChange(value) {
        if (!value) {
            setMatchingSignTree(signTree);
            return;
        }
        const matchingGroups = [];
        for (const group of signTree) {
            if (!group.items) {
                continue;
            }
            const matchingItems = [];
            for (const signType of group.items) {
                if (signType.abbreviation.toLowerCase().includes(value.toLowerCase()) ||
                    signType.name.toLowerCase().includes(value.toLowerCase()) ||
                    signType.bastId.toLowerCase().includes(value.toLowerCase())) {
                    matchingItems.push(signType);
                }
            }
            if (matchingItems.length > 0) {
                matchingGroups.push({
                    id: group.id,
                    name: group.name,
                    items: matchingItems
                });
            }
        }
        setMatchingSignTree(matchingGroups);
    }
    function handleSignTypeSelectFieldClick() {
        addModal({
            id: 'SignTypeSelectModalContainer',
            props: { onSelectedTypeChange: handleSelectType }
        });
    }
    const draftPinMarker = useMemo(() => {
        function handleTrafficSignDragEnd(e) {
            const { lng, lat } = e.lngLat;
            updateSignFields({ position: { lng, lat } });
            dispatch(fetchAddress({ lat, lng }));
            if (map) {
                map.flyTo({ center: [lng, lat] });
            }
        }
        function handleTrafficSignDrag(e) {
            const { lng, lat } = e.lngLat;
            if (!booleanPointInPolygon(point([lng, lat]), restrictedArea.current)) {
                e.target.setLngLat(lastPosition.current);
                return;
            }
            lastPosition.current = { lng, lat };
        }
        const content = (_jsx(TrafficSignMarker, { subSpecifiedClass: signFields.subSpecifiedClass, variant: "with-rotator", orientation: signFields.orientation || 0, isRotatable: isRotating, onOrientationChange: (orientation) => updateSignFields({ orientation: Math.round(orientation) }) }));
        const offset = [
            MAP_TRAFFIC_SIGN_ICON_HORIZONTAL_OFFSET,
            MAP_TRAFFIC_SIGN_ICON_VERTICAL_OFFSET
        ];
        return (_jsx(Marker, Object.assign({ offset: offset, draggable: !isRotating, longitude: signFields.position.lng, latitude: signFields.position.lat, onDragEnd: handleTrafficSignDragEnd, onDrag: handleTrafficSignDrag, anchor: "center" }, { children: content })));
    }, [
        signFields.subSpecifiedClass,
        signFields.orientation,
        signFields.position.lng,
        signFields.position.lat,
        isRotating,
        updateSignFields,
        dispatch,
        map
    ]);
    function mapContent(id) {
        if (!targetSchema) {
            return;
        }
        return (_jsxs(Minimap, Object.assign({ ref: handleMapInit, transformRequest: createRequestTransformerFunc(targetSchema, getApiHost()), size: "large", noOutline: true, id: id, variant: "image-viewer", initialViewState: {
                latitude: signFields.position.lat,
                longitude: signFields.position.lng,
                zoom: 18
            }, interactive: true }, { children: [draftPinMarker, _jsxs(Source, Object.assign({}, createSignInventorySource(targetSchema), { tiles: signInventoryTiles }, { children: [_jsx(Layer, Object.assign({}, signInventoryPoleLayer, { "source-layer": DEFAULT_SOURCE_LAYER, beforeId: getBeforeId(signInventoryPoleLayer.id) })), _jsx(Layer, Object.assign({}, signInventoryLayer, { "source-layer": DEFAULT_SOURCE_LAYER }))] })), _jsx(Source, Object.assign({ type: "geojson", data: invertedRestrictedArea.current }, { children: _jsx(Layer, Object.assign({}, signInventoryResctrictedAreaLayer)) }))] })));
    }
    function handleGetUuids(folderId, uuids) {
        updateSignFiles(folderId, uuids);
    }
    const minimap = mapContent(mapId);
    function handleOrientationClick() {
        if (!map) {
            return;
        }
        if (map.getBearing() !== 0) {
            map.rotateTo(0, { duration: 500 });
        }
        setIsRotating(true);
    }
    function handleOrientationBlur(newOrientation) {
        setIsRotating(false);
        updateSignFields({ orientation: newOrientation });
    }
    const orientationField = (_jsx(OrientationField, { orientation: signFields.orientation, onClick: handleOrientationClick, onBlur: handleOrientationBlur, isRotating: isRotating }));
    return (_jsx(TrafficSignCreator, { ref: rootRef, onClickCreate: handleSignCreateClick, onClickClose: handleClickClose, onSearchValueChange: handleSearchValueChange, searchResults: matchingSignTree, selectedType: selectedType, onSelectType: handleSelectType, filesSection: filesSection, propertiesSection: getCustomPropertiesFields('create'), addressField: _jsx(_Fragment, { children: _jsx(AddressSearch, Object.assign({ value: formatAddress(fetchedAddress), width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleAddressSearchChange, status: addressSearchStatus }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatAddress(s.address, { isShort: true }), secondaryText: formatAddress(s.address, {
                        cityAndCountry: true
                    }), onClick: () => handleAddressSuggestionClick(s) }, `${s.position.lat}-${s.position.lng}`))) })) }), orientationField: orientationField, conditionField: { items: signDamageTypesToShow, current: signFields.condition }, onChange: updateSignFields, variant: "image-viewer", minimap: minimap, onSignTypeSelectFieldClick: handleSignTypeSelectFieldClick, bastId: bastId, disableCreateButton: createStatus === 'loading', isAdmin: isAdmin }));
}
