import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchAlertTypes, fetchAssetFolderTk, fetchAssetTypes, fetchConditionFilters, fetchFilterTypes, fetchGradePercentages, fetchMarkerAttributeSchemaTk, fetchMarkerFilters, fetchMarkerFolderTk, fetchObjectTypes, fetchPlanCategory, fetchPlanFilters, fetchPlanStatus, fetchPlanTreatmentsList, fetchPlanUnitTypes, fetchRoadPropsDict, fetchSignDamageTypes, fetchSignDamageTypesWithCounts, fetchSignInventoryDamageTypes, fetchSignInventoryDamageTypesWithCounts, fetchSignInventoryPropertySettings, fetchSignInventoryStatuses, fetchSignInventoryTree, fetchSignInventoryTreeWithCounts, fetchTrackControlAgeFilters, fetchUnitsTk, fetchUserAccountList, fetchWinterServiceAgeFilters, fetchWinterServiceFilters } from '../state/slices/shared';
import { useLayerChecker } from './useLayerChecker';
import { useUserRole } from './useUserRole';
export const useSharedData = () => {
    const { isSignAuthorized, isSettingsLoaded, isSignInventoryAuthorized } = useLayerChecker();
    const { isEditor } = useUserRole();
    const { selectedOption } = useAppSelector((state) => state.presetsPanel.timepicker);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!isSettingsLoaded) {
            return;
        }
        dispatch(fetchConditionFilters());
        dispatch(fetchFilterTypes());
        dispatch(fetchAlertTypes());
        dispatch(fetchObjectTypes());
        dispatch(fetchRoadPropsDict());
        dispatch(fetchPlanFilters());
        dispatch(fetchPlanStatus());
        dispatch(fetchPlanCategory());
        dispatch(fetchPlanTreatmentsList());
        dispatch(fetchPlanUnitTypes());
        dispatch(fetchUserAccountList());
        dispatch(fetchTrackControlAgeFilters());
        dispatch(fetchWinterServiceAgeFilters());
        dispatch(fetchWinterServiceFilters());
        dispatch(fetchAssetTypes());
        dispatch(fetchAssetFolderTk());
        dispatch(fetchUnitsTk());
        if (isEditor) {
            dispatch(fetchMarkerFilters());
            dispatch(fetchMarkerFolderTk());
            dispatch(fetchMarkerAttributeSchemaTk());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isSettingsLoaded]);
    useEffect(() => {
        if (!isSettingsLoaded) {
            return;
        }
        dispatch(fetchGradePercentages());
        if (isSignInventoryAuthorized) {
            dispatch(fetchSignInventoryDamageTypes()).then(() => dispatch(fetchSignInventoryDamageTypesWithCounts()));
            dispatch(fetchSignInventoryTree()).then(() => dispatch(fetchSignInventoryTreeWithCounts()));
            dispatch(fetchSignInventoryStatuses());
            dispatch(fetchSignInventoryPropertySettings());
        }
        if (isSignAuthorized) {
            dispatch(fetchSignDamageTypes()).then(() => dispatch(fetchSignDamageTypesWithCounts()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedOption, isSettingsLoaded, isSignAuthorized, isSignInventoryAuthorized]);
};
