import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { SIGN_PROPERTY_TYPE } from '../../common/consts/trafficSign';
import { isDeepEqual } from '../../common/utils/object';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { saveSignInventoryPropertySettings } from '../../state/slices/globalSetting';
import { useDittoWrapper } from '../useDittoWrapper';
export const useSignInventoryPropertiesSettings = () => {
    const dispatch = useAppDispatch();
    const { signProperties } = useAppSelector((state) => state.shared);
    const { addModal } = useModalContext();
    const [signInventoryPropertyList, setSignInventoryPropertyList] = useState(signProperties);
    const [deletedItemsList, setDeletedItemsList] = useState([]);
    useEffect(() => setSignInventoryPropertyList(signProperties), [signProperties]);
    const textFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.text'
    });
    const numberFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.numeric'
    });
    const selectFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.select'
    });
    const dateFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.date'
    });
    function getSignCustomPropertyDefaultName(propertyType, index) {
        let name = '';
        switch (propertyType) {
            case SIGN_PROPERTY_TYPE.text:
                name = textFieldDefaultTitle;
                break;
            case SIGN_PROPERTY_TYPE.number:
                name = numberFieldDefaultTitle;
                break;
            case SIGN_PROPERTY_TYPE.select:
                name = selectFieldDefaultTitle;
                break;
            case SIGN_PROPERTY_TYPE.date:
                name = dateFieldDefaultTitle;
                break;
            default:
                name = '';
                break;
        }
        return name.replace('1', index.toString());
    }
    function addSignPropertyItemToDeletedList(item) {
        setDeletedItemsList([...deletedItemsList, item]);
    }
    function handleSignInventoryPropertySettingsSaveClick(items, callback) {
        const itemsToSave = items.map((item, index) => {
            var _a;
            return (Object.assign(Object.assign({}, item), { name: item.name || getSignCustomPropertyDefaultName(item.type, index + 1), selectOptions: (_a = item.selectOptions) === null || _a === void 0 ? void 0 : _a.map((option, optionIndex) => (Object.assign(Object.assign({}, option), { name: option.name || `Option ${optionIndex + 1}` }))) }));
        });
        dispatch(saveSignInventoryPropertySettings(itemsToSave));
        callback();
    }
    function handleSignInventoryPropertySettingsCancelClick(list, callback) {
        if (!signProperties)
            return;
        const isPlanPropertySettingsChanged = !isDeepEqual(signProperties, list);
        if (list && isPlanPropertySettingsChanged) {
            const hasDeletedItems = deletedItemsList.length > 0;
            const modalDescriptionCopyId = hasDeletedItems ? (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "prompt.savechanges.copydeletions" }), `
`, deletedItemsList.map((item) => `- ${item}`).join(`,
`)] })) : (_jsx(Ditto, { componentId: "prompt.savechanges.copy" }));
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handleSignInventoryPropertySettingsSaveClick(list, callback);
                    },
                    onCancel: () => {
                        setSignInventoryPropertyList(signProperties);
                        callback();
                    },
                    description: modalDescriptionCopyId
                }
            });
            return;
        }
        callback();
    }
    return {
        signInventoryPropertyList,
        addSignPropertyItemToDeletedList,
        getSignCustomPropertyDefaultName,
        setSignInventoryPropertyList,
        handleSignInventoryPropertySettingsSaveClick,
        handleSignInventoryPropertySettingsCancelClick
    };
};
