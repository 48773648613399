// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lFffq8NkLFFJNTCwVMb9{display:flex;align-items:stretch;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/components/AddressForm/AddressForm.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,mBAAA,CACA,qBAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: stretch;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lFffq8NkLFFJNTCwVMb9`
};
export default ___CSS_LOADER_EXPORT___;
