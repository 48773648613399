import { MAP_VIA_LAT, MAP_VIA_LNG } from './consts/map';
const BOUNDS_ARRAY_LENGTH = 4;
export function convertBoundsDirectionsToCorners(bounds) {
    return {
        sw: {
            lng: bounds.west,
            lat: bounds.south
        },
        ne: {
            lng: bounds.east,
            lat: bounds.north
        }
    };
}
export function parseBoundsArray(bounds) {
    if (!bounds) {
        return null;
    }
    if (bounds.length < BOUNDS_ARRAY_LENGTH) {
        return null;
    }
    return {
        west: bounds[0],
        south: bounds[1],
        east: bounds[2],
        north: bounds[3]
    };
}
export function toBoundsArray(bounds) {
    return [bounds.west, bounds.south, bounds.east, bounds.north];
}
export function getDefaultMapCenter(lat, lng) {
    if (lat && lng) {
        return { lat, lng };
    }
    return { lat: MAP_VIA_LAT, lng: MAP_VIA_LNG };
}
export function createInvertedPolygon(polygon) {
    const world = [
        [0, 90],
        [180, 90],
        [180, -90],
        [0, -90],
        [-180, -90],
        [-180, 0],
        [-180, 90],
        [0, 90]
    ];
    return {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Polygon',
                    coordinates: [world, ...polygon.coordinates]
                }
            }
        ]
    };
}
