import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { TypedDitto } from '../../../TypedDitto';
import styles from './AssigneeName.module.css';
export function AssigneeName({ firstName, lastName, isYou = false, truncate, wrapContent }) {
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || '';
    const fullNameYou = (_jsx(TypedDitto, { componentId: "assignee.examplename(you)", variables: { Assignee: fullName } }));
    return (_jsx("span", Object.assign({ className: classes(styles['text'], truncate && styles['truncate'], wrapContent && styles['wrap']), "data-test": "assignee-name" }, { children: isYou ? fullNameYou : fullName })));
}
