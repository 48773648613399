import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Ditto } from 'ditto-react';
import { Button } from '../../../../uikit/Button/Button';
import { FormInputText } from '../../../FormInputText/FormInputText';
import styles from './TextEditor.module.css';
export function TextEditor({ value, unit, onConfirm, decimalSeparator, isNumber }) {
    const [localValue, setLocalValue] = useState(value);
    useEffect(() => {
        setLocalValue(value);
    }, [value]);
    function handleConfirmClick() {
        onConfirm(localValue);
    }
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [isNumber ? (_jsx(NumericFormat, { customInput: FormInputText, onValueChange: (values) => {
                    setLocalValue(values.value);
                }, decimalSeparator: decimalSeparator, suffix: unit ? ` ${unit}` : undefined, placeholder: unit, value: localValue, variant: "table-inline", height: "small", valueIsNumericString: true, autoFocus: true })) : (_jsx(FormInputText, { onValueChange: (v) => {
                    setLocalValue(v);
                }, value: localValue, variant: "table-inline", height: "small", autoFocus: true })), _jsx("div", Object.assign({ className: styles['button-row'] }, { children: _jsx(Button, Object.assign({ color: "dark", onClick: handleConfirmClick }, { children: _jsx(Ditto, { componentId: "done" }) })) }))] })));
}
