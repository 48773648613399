import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { AssetIcon } from '../../common/icon';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../components/FormInputSelect/FormInputSelect';
import { Check, Plus, Tree2 } from '../../components/icons';
import { Customize } from '../Customize/Customize';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import styles from './AssetLowerForm.module.css';
const DROP_MENU_MAX_HEIGHT = 250;
export function AssetLowerForm({ title, address, assetTypeValue, assetTypesList, assetTypeDisabled, onAssetTypeChange, onAddNewTypeClick, onCustomizeAttributesClick, description, attributeForm, width = '100%', variant, isReadOnly }) {
    const assetTypesItems = assetTypesList
        ? assetTypesList === null || assetTypesList === void 0 ? void 0 : assetTypesList.map((assetType) => (_jsx(SelectboxEntry, { text: assetType.name, leadingIcon: _jsx(AssetIcon, { iconId: assetType.iconId }), trailingIcon: (assetTypeValue === null || assetTypeValue === void 0 ? void 0 : assetTypeValue.id) === assetType.id && _jsx(Check, {}), onClick: () => {
                onAssetTypeChange === null || onAssetTypeChange === void 0 ? void 0 : onAssetTypeChange(assetType);
            } }, assetType.id)))
        : [];
    const addAssetTypeItem = assetTypesList && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Plus, {}), text: _jsx(Ditto, { componentId: "assets.addassettype" }), onClick: () => onAddNewTypeClick === null || onAddNewTypeClick === void 0 ? void 0 : onAddNewTypeClick(), withUnderline: true }, 'add-asset-type'));
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles[`container__${variant}`]), style: { width } }, { children: [title, _jsx(FormInputSelect, { label: _jsx(Ditto, { componentId: "assets.assettype" }), width: width, menuMaxHeight: DROP_MENU_MAX_HEIGHT, menuItems: [...assetTypesItems, addAssetTypeItem], required: variant !== 'ghost' && !assetTypeDisabled, value: assetTypeValue ? (_jsx(FormInputCurrentSelectedValue, { text: assetTypeValue.name, icon: _jsx(AssetIcon, { iconId: assetTypeValue.iconId }), bold: assetTypeDisabled }, assetTypeValue.id)) : (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(Tree2, {}), text: _jsx("span", Object.assign({ className: styles['placeholder-text'] }, { children: _jsx(Ditto, { componentId: "notspecified" }) })) })), variant: variant, disabled: isReadOnly || assetTypeDisabled, canFloat: true }), address, description, _jsx("div", Object.assign({ className: styles['divider'] }, { children: _jsx("div", { className: styles['divider-separator'] }) })), _jsxs("div", Object.assign({ className: styles['attribute-form'] }, { children: [_jsx("div", Object.assign({ className: styles['attribute-form-headline'] }, { children: _jsx(Ditto, { componentId: "assets.attributes" }) })), attributeForm, Array.isArray(attributeForm) && attributeForm.length === 0 && (_jsx("div", Object.assign({ className: styles['attribute-form-empty'] }, { children: _jsx(Ditto, { componentId: "customproperties.nopropertiesspecified" }) })))] })), !isReadOnly && assetTypeValue && (_jsx(Customize, { onClick: () => onCustomizeAttributesClick === null || onCustomizeAttributesClick === void 0 ? void 0 : onCustomizeAttributesClick(), className: styles['customize'], properties: true }))] })));
}
