import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { FormInputText } from '../FormInputText/FormInputText';
import styles from './AddressForm.module.css';
export function AddressForm({ formData, formatter, width: fieldWidth = 342, onChange, onSubmit, variant }) {
    var _a, _b, _c, _d;
    const addressStartLabel = useDittoWrapper({ componentId: 'winterservice.startaddress' });
    const addressEndLabel = useDittoWrapper({ componentId: 'winterservice.endaddress' });
    const placeholderText = useDittoWrapper({ componentId: 'notspecified' });
    const textInputPlaceholder = variant === 'ghost' ? placeholderText : undefined;
    const addressStart = (formData === null || formData === void 0 ? void 0 : formData.addressStart)
        ? formatter.formatAddress(formData === null || formData === void 0 ? void 0 : formData.addressStart)
        : '';
    const addressEnd = (formData === null || formData === void 0 ? void 0 : formData.addressEnd) ? formatter.formatAddress(formData === null || formData === void 0 ? void 0 : formData.addressEnd) : '';
    const userModifiedAddressStart = (_a = formData === null || formData === void 0 ? void 0 : formData.userModifiedAddressStart) !== null && _a !== void 0 ? _a : (((_b = formData === null || formData === void 0 ? void 0 : formData.addressStart) === null || _b === void 0 ? void 0 : _b.userModifiedAddress) || addressStart);
    const userModifiedAddressEnd = (_c = formData === null || formData === void 0 ? void 0 : formData.userModifiedAddressEnd) !== null && _c !== void 0 ? _c : (((_d = formData === null || formData === void 0 ? void 0 : formData.addressEnd) === null || _d === void 0 ? void 0 : _d.userModifiedAddress) || addressEnd);
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", Object.assign({ className: styles['row'] }, { children: _jsx(FormInputText, { name: "userModifiedAddressStart", label: addressStartLabel, width: fieldWidth, value: userModifiedAddressStart, onBlur: onSubmit, onValueChange: (value) => onChange('userModifiedAddressStart', value), "data-test": "segment-form-address_start", variant: variant, placeholder: addressStart || textInputPlaceholder }) })), _jsx("div", Object.assign({ className: styles['row'] }, { children: _jsx(FormInputText, { name: "userModifiedAddressEnd", label: addressEndLabel, width: fieldWidth, value: userModifiedAddressEnd, onBlur: onSubmit, onValueChange: (value) => onChange('userModifiedAddressEnd', value), "data-test": "segment-form-address_end", variant: variant, placeholder: addressEnd || textInputPlaceholder }) }))] })));
}
