import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Assets, Bicycle, ConditionAlt4Outline, Inventory, Manhole, Notes, Referencing, SegmentCustom, Signs, Signs2, Task, TrackControl, Warning3, WinterService } from '../components/icons';
export const LAYER_PATH = {
    condition: 'condition',
    referencing: 'referencing-flag',
    note: 'note',
    plan: 'plan',
    marker: 'marker',
    object: 'object',
    alert: 'alert',
    trackControl: 'track-control',
    sign: 'sign',
    winterService: 'winter-service',
    asset: 'asset',
    signInventory: 'sign-inventory'
};
export const MAP_LAYER_LIST = [
    {
        id: 'condition',
        isEnabled: true
    },
    {
        id: 'plan',
        isEnabled: true
    },
    {
        id: 'marker',
        isEnabled: true
    },
    {
        id: 'alert',
        isEnabled: true
    },
    {
        id: 'asset',
        isEnabled: false
    },
    {
        id: 'object',
        isEnabled: false
    },
    {
        id: 'sign-inventory',
        isEnabled: false,
        isBeta: true
    },
    {
        id: 'track-control',
        isEnabled: false
    },
    {
        id: 'winter-service',
        isEnabled: false
    },
    {
        id: 'note',
        isEnabled: true
    },
    {
        id: 'sign',
        isEnabled: false,
        isBeta: true
    },
    {
        id: 'referencing-flag',
        isEnabled: true
    }
];
export const LAYERS_PRESETS = {
    condition: ['condition', 'plan', 'marker', 'alert', 'note'],
    plan: ['plan', 'condition', 'note'],
    marker: ['marker', 'alert', 'note'],
    alert: ['alert', 'note'],
    asset: ['asset', 'object', 'sign-inventory', 'note'],
    object: ['object', 'note'],
    'sign-inventory': ['sign-inventory', 'note'],
    'track-control': ['track-control', 'note'],
    'winter-service': ['winter-service', 'note'],
    note: ['note']
};
export function getMapLayerIcon(id) {
    switch (id) {
        case 'condition':
            return _jsx(ConditionAlt4Outline, {});
        case 'referencing-flag':
            return _jsx(Referencing, {});
        case 'note':
            return _jsx(Notes, {});
        case 'bicycle':
            return _jsx(Bicycle, {});
        case 'plan':
            return _jsx(SegmentCustom, {});
        case 'marker':
            return _jsx(Task, {});
        case 'sign':
            return _jsx(Signs, {});
        case 'sign-inventory':
            return _jsx(Signs2, {});
        case 'inventory':
            return _jsx(Inventory, {});
        case 'object':
            return _jsx(Manhole, {});
        case 'alert':
            return _jsx(Warning3, {});
        case 'track-control':
            return _jsx(TrackControl, {});
        case 'winter-service':
            return _jsx(WinterService, {});
        case 'asset':
            return _jsx(Assets, {});
    }
}
export function getMapLayerText(id) {
    switch (id) {
        case 'condition':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.conditionstreets" });
        case 'referencing-flag':
            return _jsx(Ditto, { componentId: "referencing" });
        case 'note':
            return _jsx(Ditto, { componentId: "layer.mapnotes" });
        case 'bicycle':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.conditionbike" });
        case 'plan':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.plannedsegments" });
        case 'marker':
            return _jsx(Ditto, { componentId: "layer.marker" });
        case 'sign':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.signs" });
        case 'sign-inventory':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.signs" });
        case 'inventory':
            return 'Inventory';
        case 'object':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.objects" });
        case 'alert':
            return _jsx(Ditto, { componentId: "layerpanel.maplayermodal.roadsafetyalerts" });
        case 'track-control':
            return _jsx(Ditto, { componentId: "trackcontrols" });
        case 'winter-service':
            return _jsx(Ditto, { componentId: "filter.category.winterservice" });
        case 'asset':
            return _jsx(Ditto, { componentId: "assets" });
    }
}
