import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { pointToMarkerSource } from '../../common/convert';
import { pointToTrafficSignSource } from '../../common/convert/signInventorySource';
import { LAYER_PATH } from '../../common/layer';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { Assets, Plus, Signs2, Task } from '../../components/icons';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { Navigation } from '../../components/ImageViewer/Navigation/Navigation';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDelayedLoader } from '../../hooks/useDelayedLoader';
import { useImageViewerFetcher } from '../../hooks/useImageViewerFetcher';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetAssetCreator, setAssetCreatorSource, setAssetCreatorType } from '../../state/slices/assetCreator';
import { fetchPointImgData, resetImageViewerCondition } from '../../state/slices/imageViewerCondition';
import { resetMarkerCreator, setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { fetchConditionNavigation } from '../../state/slices/navigation';
import { resetSignCreator, setTrafficSignCreatorPointSource } from '../../state/slices/signCreator';
import { Button } from '../../uikit/Button/Button';
import { AssetCreatorContainer } from '../AssetCreatorContainer/AssetCreatorContainer';
import { ConditionDetailContainer } from '../ConditionDetailContainer';
import { RightPanelMarkerCreatorContainer } from '../RightPanelMarkerCreatorContainer/RightPanelMarkerCreatorContainer';
import { RightPanelTrafficSignCreatorContainer } from '../RightPanelTrafficSignCreatorContainer/RightPanelTrafficSignCreatorContainer';
export function ImageViewerConditionContainer() {
    const dispatch = useAppDispatch();
    const { removeModal, modals } = useModalContext();
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    const { imageURL } = useAppSelector((state) => state.imageViewerCondition);
    const { feature } = useAppSelector((state) => state.conditionDetail);
    const { navigation, isLoading } = useAppSelector((state) => state.navigation);
    const { targetSchema } = useAppSelector((state) => state.auth);
    const { selectImageViewerPoint } = useImageViewerFetcher();
    const { source: trafficSignCreatorSource } = useAppSelector((state) => state.signCreator);
    const { isEditableTrafficSignsEnabled } = useLayerChecker();
    const { isEditor } = useUserRole();
    const pointInfo = feature.type === 'point' ? feature.value : undefined;
    const loader = useDelayedLoader({ isLoading: !!isLoading });
    useEffect(() => {
        const pointUUID = pointInfo === null || pointInfo === void 0 ? void 0 : pointInfo.systems_point_id;
        if (pointUUID) {
            dispatch(fetchPointImgData(pointUUID));
            dispatch(fetchConditionNavigation(pointUUID));
            mixpanel.track(MIXPANEL_EVENT_NAME.viewConditionImage, {
                [MIXPANEL_EVENT_PROPERTY.pointUUID]: pointUUID
            });
        }
    }, [dispatch, pointInfo]);
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    });
    function onKeyDown(event) {
        if (isLoading) {
            return;
        }
        switch (event.key) {
            case 'ArrowUp':
                handleNavFwdClick();
                break;
            case 'ArrowDown':
                handleNavBwdClick();
                break;
            case 'ArrowLeft':
                handleNavLeftClick();
                break;
            case 'ArrowRight':
                handleNavRightClick();
                break;
        }
    }
    function handleNavFwdClick() {
        if (navigation === null || navigation === void 0 ? void 0 : navigation.fwd) {
            selectImageViewerPoint(navigation.fwd.id);
        }
    }
    function handleNavBwdClick() {
        if (navigation === null || navigation === void 0 ? void 0 : navigation.bwd) {
            selectImageViewerPoint(navigation.bwd.id);
        }
    }
    function handleNavLeftClick() {
        if (navigation === null || navigation === void 0 ? void 0 : navigation.left) {
            selectImageViewerPoint(navigation.left.id);
        }
    }
    function handleNavRightClick() {
        if (navigation === null || navigation === void 0 ? void 0 : navigation.right) {
            selectImageViewerPoint(navigation.right.id);
        }
    }
    function handleAddMarkerButtonClick() {
        if (!pointInfo) {
            return;
        }
        dispatch(setMarkerCreatorPointSource(pointToMarkerSource(pointInfo)));
    }
    function handleAddAssetButtonClick() {
        if (!pointInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.createAssetFromImageViewer, {
            [MIXPANEL_EVENT_PROPERTY.layerName]: LAYER_PATH.condition
        });
        dispatch(setAssetCreatorType('right-panel'));
        dispatch(setAssetCreatorSource({ point: pointInfo }));
    }
    function handleAddTrafficSignButtonClick() {
        if (!pointInfo) {
            return;
        }
        dispatch(setTrafficSignCreatorPointSource(pointToTrafficSignSource(pointInfo)));
    }
    function handleBackButtonClick(type) {
        switch (type) {
            case 'marker':
                dispatch(resetMarkerCreator());
                break;
            case 'asset':
                dispatch(resetAssetCreator());
                break;
            case 'sign':
                dispatch(resetSignCreator());
                break;
        }
    }
    if (!targetSchema) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    function handleClickClose() {
        dispatch(resetImageViewerCondition());
        removeModal('ImageViewerCondition');
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'ImageViewerCondition') {
            return;
        }
        handleClickClose();
    }
    const navControls = navigation && (_jsx(Navigation, { isLoading: !!loader, fwdClick: navigation.fwd && handleNavFwdClick, bwdClick: navigation.bwd && handleNavBwdClick, leftClick: navigation.left && handleNavLeftClick, rightClick: navigation.right && handleNavRightClick }));
    const imageElement = _jsx("img", { src: imageURL, alt: "Street View" });
    const topButtonsCreate = (_jsx(FloatingDropdownWrapper, { control: _jsx(Button, { color: "dark", variant: "square", icon: _jsx(Plus, {}) }), topOffset: 4, menu: _jsxs(ActionModal, Object.assign({ extraRounded: true }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "createmarker" }), leadingIcon: _jsx(Task, {}), onClick: handleAddMarkerButtonClick, size: "medium", extraRounded: true }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "actionmodal.createinventory" }), leadingIcon: _jsx(Assets, {}), onClick: handleAddAssetButtonClick, size: "medium", extraRounded: true }), isEditableTrafficSignsEnabled && isEditor && (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "trafficsigns.createtrafficsign" }), leadingIcon: _jsx(Signs2, {}), onClick: handleAddTrafficSignButtonClick, size: "medium", extraRounded: true }))] })) }));
    if (markerCreatorSource) {
        return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Condition", singleImage: imageElement, onBackButtonClick: () => handleBackButtonClick('marker'), onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate }), creator: _jsx(RightPanelMarkerCreatorContainer, {}) }));
    }
    if (assetCreatorSource) {
        return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Condition", singleImage: imageElement, onBackButtonClick: () => handleBackButtonClick('asset'), onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate }), creator: _jsx(AssetCreatorContainer, {}) }));
    }
    if (trafficSignCreatorSource) {
        const creator = isEditor && isEditableTrafficSignsEnabled ? (_jsx(RightPanelTrafficSignCreatorContainer, {})) : undefined;
        return (_jsx(ImageViewerModal, { onClickOutside: handleClickOutside, imageViewer: _jsx(ImageViewer, { variant: "Condition", singleImage: imageElement, onBackButtonClick: () => handleBackButtonClick('sign'), onDoneClick: handleClickOutside }), creator: creator }));
    }
    if (!targetSchema || !pointInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Condition", onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate, singleImage: imageElement, navigation: navControls, details: pointInfo && _jsx(ConditionDetailContainer, { variant: "image-viewer" }) }) }));
}
