import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../uikit/Button/Button';
import { NotificationEmpty } from './NotificationEmpty/NotificationEmpty';
import { NotificationEmptyUnread } from './NotificationEmptyUnread/NotificationEmptyUnread';
import styles from './NotificationOverlay.module.css';
export function NotificationOverlay({ onReadAllClick, onShowReadClick, children, hasReadNotifications, hasUnReadNotifications, isEmpty, showReadNotifications }) {
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['header'] }, { children: [_jsx("div", Object.assign({ className: styles['left'] }, { children: _jsx(Ditto, { componentId: "notifications" }) })), _jsx(Button, Object.assign({ variant: "slender", color: "gray", onClick: onReadAllClick, disabled: !hasUnReadNotifications }, { children: _jsx(Ditto, { componentId: "notifications.markallasread" }) }))] })), isEmpty && !hasReadNotifications && _jsx(NotificationEmpty, {}), isEmpty && hasReadNotifications && (_jsx(NotificationEmptyUnread, { onShowReadClick: onShowReadClick })), !isEmpty && (_jsxs("div", Object.assign({ className: styles['content'] }, { children: [children, !showReadNotifications && hasReadNotifications && (_jsx("div", Object.assign({ className: styles['bottom'] }, { children: _jsx("div", Object.assign({ className: styles['link'], onClick: onShowReadClick }, { children: _jsx(Ditto, { componentId: "notifications.showreadnotifications" }) })) })))] })))] })));
}
