export class PlanningToolServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * for k8s tcp probes, prevents flooding logs with 404
     * @returns any A successful response.
     * @throws ApiError
     */
    planningToolServiceRoot() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/'
        });
    }
    /**
     * =========================API UTILITY SCRIPTS=========================
     * @param body
     * @returns any A successful response.
     * @throws ApiError
     */
    planningToolServiceAddMissingAccounts(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/api-utility-scripts/add-missing-accounts',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BudgetsBreakdownResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBudgetsBreakdown(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/budgets/breakdown',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BudgetsDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBudgetsDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/budgets/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BudgetsListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBudgetsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/budgets/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BudgetsSaveResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBudgetsSave(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/budgets/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NetworkResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceNetworkGet(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/network/get',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NetworkResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceNetworkUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/network/update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NetworkUpdatePreviewResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceNetworkUpdatePreview(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/network/update-preview',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2CategoryTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceCategoryTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/category-type/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanChangelogListByIdResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanChangelogListById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/changelog/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanCountResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanCount(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/count',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanCreateResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanCreateRecords(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/create/records',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanCreateResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanCreateStreets(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/create/streets',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BulkPlanDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBulkPlanDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/delete-bulk',
            body: body
        });
    }
    /**
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    planningToolServicePlanExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    planningToolServicePlanExportByIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/export-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanGetResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanGet(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/get',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanFilterListResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanFilterList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/get-filters',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanListResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanListByIdsResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanListByIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/list-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanMapResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanMap(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/map',
            body: body
        });
    }
    /**
     * TODO: add points in response after points are saved again
     * @param body
     * @returns v2PlanMapByIdsResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanMapByIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/map-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanMapPreviewResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanMapPreview(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/map-preview',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MeasureTypeAddResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceMeasureTypeAdd(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/measure-type/add',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MeasureTypeDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceMeasureTypeDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/measure-type/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MeasureTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceMeasureTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/measure-type/list',
            body: body
        });
    }
    /**
     * not used anymore, remove if still not needed after release
     * @param body
     * @returns v2PlanMinMaxValuesResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanMinMaxValues(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/min-max-values',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanPreviewResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanPreview(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/preview',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2StatusTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceStatusTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/status-type/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanTableSumLineResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanTableSumLine(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/table-sum-line',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2PlanUpdateResponse A successful response.
     * @throws ApiError
     */
    planningToolServicePlanUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BulkPlanUpdateResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBulkPlanUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/update-bulk',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BulkPlanUpdatePreviewResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceBulkPlanUpdatePreview(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plan/update-bulk-preview',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TableSettingsDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTableSettingsDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TableSettingsListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTableSettingsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TablePropertyTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTablePropertyTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/property-types/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TableSettingsSaveResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTableSettingsSave(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TableSelectOptionDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTableSelectOptionDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/select-option/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TableUnitTypeAddResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTableUnitTypeAdd(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/unit-types/add',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TableUnitTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTableUnitTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/table-settings/unit-types/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentCategoryTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentCategoryTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/category-types/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentsDeleteResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentsDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentsListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentsSaveResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentsSave(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentsSavePreviewResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentsSavePreview(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/save-preview',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentUnitTypeAddResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentUnitTypeAdd(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/unit-types/add',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2TreatmentUnitTypeListResponse A successful response.
     * @throws ApiError
     */
    planningToolServiceTreatmentUnitTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/treatments/unit-types/list',
            body: body
        });
    }
}
