// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ROHSh2uwfvxVeOqOwpLw{display:flex;align-items:center;padding:4px 6px;width:100%;height:48px;font-size:14px;cursor:pointer;border-radius:4px}.Msdcx8lYysxNCSjJPCGA{height:40px}.cQniGcC9dO78odcYSfRe{background:rgba(129,129,129,.1)}.OLblKERvq_xgmzyUWGJM{pointer-events:none;cursor:none}`, "",{"version":3,"sources":["webpack://./src/components/RootSelectbox/RootSelectbox.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAEJ,sBACI,WAAA,CAEJ,sBACI,+BAAA,CAEJ,sBACI,mBAAA,CACA,WAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    padding: 4px 6px;\n    width: 100%;\n    height: 48px;\n    font-size: 14px;\n    cursor: pointer;\n    border-radius: 4px;\n}\n.container__small {\n    height: 40px;\n}\n.container__hover {\n    background: rgba(129, 129, 129, 0.1);\n}\n.container__disabled {\n    pointer-events: none;\n    cursor: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ROHSh2uwfvxVeOqOwpLw`,
	"container__small": `Msdcx8lYysxNCSjJPCGA`,
	"container__hover": `cQniGcC9dO78odcYSfRe`,
	"container__disabled": `OLblKERvq_xgmzyUWGJM`
};
export default ___CSS_LOADER_EXPORT___;
