import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { classes } from 'classifizer';
import { NUMBER_REGEX } from '../../common/utils/number';
import { FormInputText } from '../FormInputText/FormInputText';
import { Minus } from '../icons';
import styles from './RangeSlider.module.css';
export function RangeSlider({ indent, min, max, step, captions, value, color, onChange, isExcluded, width, showInputs, minLabel, maxLabel, unit, unitFormatter, 'data-test': dataTest }) {
    const redColor = '#ff3e3e';
    const blackColor = '#000000';
    const currentColor = color && color === 'red' ? redColor : blackColor;
    const unitValue = (unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.unit) || unit;
    const sliderOne = useRef(null);
    const sliderTwo = useRef(null);
    const fieldOne = useRef(null);
    const fieldTwo = useRef(null);
    const sliderTrack = useRef(null);
    const sliderMinGap = 0;
    const minValue = (value === null || value === void 0 ? void 0 : value.min) && value.min > min ? value.min : min;
    const maxValue = (value === null || value === void 0 ? void 0 : value.max) && value.max < max ? value.max : max;
    const [leftPosition, setLeftPosition] = useState(minValue);
    const [rightPosition, setRightPosition] = useState(maxValue);
    const [leftInputValue, setLeftInputValue] = useState(leftPosition.toString());
    const [rightInputValue, setRightInputValue] = useState(rightPosition.toString());
    const calculatedStep = useMemo(() => {
        function greatestCommonDivisor(a, b) {
            return b === 0 ? a : greatestCommonDivisor(b, a % b);
        }
        const minStepValue = Math.abs(min === 0 ? 1 : min);
        const maxStepValue = Math.abs(max === 0 ? 1 : max);
        return (greatestCommonDivisor(Math.round(minStepValue * 100), Math.round(maxStepValue * 100)) /
            100);
    }, [min, max]);
    useEffect(() => {
        fillColor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatedStep]);
    useEffect(() => {
        setLeftPosition(minValue);
        setRightPosition(maxValue);
        setLeftInputValue(minValue.toString());
        setRightInputValue(maxValue.toString());
    }, [minValue, maxValue]);
    function slideOne() {
        if (sliderOne.current && sliderTwo.current) {
            if (parseFloat(sliderTwo.current.value) - parseFloat(sliderOne.current.value) <=
                sliderMinGap) {
                sliderOne.current.value = (parseFloat(sliderTwo.current.value) - sliderMinGap).toString();
            }
        }
        fillColor();
    }
    function slideTwo() {
        if (sliderOne.current && sliderTwo.current) {
            if (parseFloat(sliderTwo.current.value) - parseFloat(sliderOne.current.value) <=
                sliderMinGap) {
                sliderTwo.current.value = (parseFloat(sliderOne.current.value) + sliderMinGap).toString();
            }
        }
        fillColor();
    }
    function getPercentageSliderValue(value) {
        value = Math.min(Math.max(value, min), max);
        const percentage = ((value - min) / (max - min)) * 100;
        return percentage || 0;
    }
    function fillColor() {
        if (sliderOne.current && sliderTwo.current) {
            const value1 = parseFloat(sliderOne.current.value);
            const value2 = parseFloat(sliderTwo.current.value);
            let percent1 = getPercentageSliderValue(value1);
            let percent2 = getPercentageSliderValue(value2);
            if (sliderTrack.current) {
                sliderTrack.current.style.background = `linear-gradient(to right, transparent ${percent1}% , ${currentColor} ${percent1}% , ${currentColor} ${percent2}%, transparent ${percent2}%)`;
            }
            setLeftPosition(value1);
            setRightPosition(value2);
        }
    }
    function moveTo(newValue) {
        const leftDiff = Math.abs(minValue - newValue);
        const rightDiff = Math.abs(maxValue - newValue);
        if ((leftDiff <= rightDiff || maxValue === newValue) &&
            minValue !== newValue &&
            newValue <= maxValue) {
            setLeftPosition(newValue);
            onChange({ min: newValue, max: maxValue });
        }
        else {
            setRightPosition(newValue);
            onChange({ min: minValue, max: newValue });
        }
    }
    function handleLeftSliderChange(event) {
        const newValue = event.target.value;
        if (newValue !== leftInputValue) {
            onChange({ min: parseFloat(newValue), max: maxValue });
            setLeftInputValue(newValue);
            slideOne();
        }
    }
    function handleRightSliderChange(event) {
        let newValue = event.target.value;
        if (newValue !== rightInputValue) {
            if (step && parseFloat(newValue) === Math.floor(max / step) * step) {
                newValue = max.toString();
            }
            onChange({ max: parseFloat(newValue), min: minValue });
            setRightInputValue(newValue);
            slideTwo();
        }
    }
    function handleLeftFieldChange() {
        if (NUMBER_REGEX.test(leftInputValue)) {
            let newValue = leftInputValue ? parseFloat(leftInputValue) : min;
            if (step && newValue % step !== 0) {
                newValue = Math.round(newValue / step) * step;
            }
            if (newValue < min) {
                newValue = min;
            }
            else if (newValue > rightPosition) {
                newValue = rightPosition;
            }
            else if (newValue > max) {
                newValue = max;
            }
            const valueString = newValue.toString();
            setLeftPosition(newValue);
            setLeftInputValue(valueString);
            onChange({ min: newValue, max: maxValue });
        }
    }
    function handleRightFieldChange() {
        if (NUMBER_REGEX.test(rightInputValue)) {
            let newValue = rightInputValue ? parseFloat(rightInputValue) : max;
            if (step && newValue % step !== 0) {
                newValue = Math.round(newValue / step) * step;
            }
            if (newValue < min) {
                newValue = min;
            }
            else if (newValue < leftPosition) {
                newValue = leftPosition;
            }
            else if (newValue > max) {
                newValue = max;
            }
            const valueString = newValue.toString();
            setRightPosition(newValue);
            setRightInputValue(valueString);
            onChange({ max: newValue, min: minValue });
        }
    }
    function onKeyPressLeftInput(event) {
        var _a;
        if (event.key === 'Enter') {
            (_a = fieldOne.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    }
    function onKeyPressRightInput(event) {
        var _a;
        if (event.key === 'Enter') {
            (_a = fieldTwo.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    }
    useEffect(() => {
        if (sliderOne.current && !minValue) {
            sliderOne.current.value = sliderMinGap.toString();
        }
        slideOne();
        slideTwo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leftPosition, rightPosition, sliderOne, sliderTwo, isExcluded]);
    return (_jsxs("div", Object.assign({ className: classes(styles['wrapper']), style: { width, marginLeft: indent }, "data-test": dataTest }, { children: [_jsxs("div", Object.assign({ className: classes(styles['container']), "data-test": "left-panel-slider-container" }, { children: [_jsx("div", { className: classes(styles['background-slider-track']) }), _jsx("div", { className: classes(styles['slider-track']), ref: sliderTrack }), _jsx("div", { className: classes(styles['slider-base']) }), _jsx("input", { className: classes(styles['slider-range'], rightPosition === max && styles['slider-range-top']), type: "range", min: min, max: max, step: step || calculatedStep, value: leftPosition, ref: sliderOne, onInput: slideOne, onChange: handleLeftSliderChange, "data-test": "slider-left" }), _jsx("input", { className: classes(styles['slider-range']), type: "range", min: min, max: max, step: step || calculatedStep, value: rightPosition, ref: sliderTwo, onInput: slideTwo, onChange: handleRightSliderChange, "data-test": "slider-right" })] })), captions && (_jsx("div", Object.assign({ className: classes(styles['numbers']) }, { children: captions.map((caption, index) => {
                    const label = typeof caption === 'number' ? caption : caption.label;
                    const value = typeof caption === 'number' ? caption : caption.value;
                    return (_jsx("div", Object.assign({ className: classes(styles['number']), onClick: () => moveTo(value) }, { children: label }), `caption-${index}`));
                }) }))), showInputs && (_jsxs("div", Object.assign({ className: classes(styles['inputs'], (!minLabel || !maxLabel) && styles['inputs--small']) }, { children: [_jsx(NumericFormat, { onKeyDown: onKeyPressLeftInput, "data-test": "slider-left-input", customInput: FormInputText, onBlur: handleLeftFieldChange, onValueChange: (values) => {
                            if (NUMBER_REGEX.test(values.value)) {
                                setLeftInputValue(values.value);
                            }
                        }, thousandSeparator: unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.thousandSeparator, label: minLabel, decimalSeparator: unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.decimalSeparator, decimalScale: unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.decimals, fixedDecimalScale: true, allowNegative: minValue < 0, suffix: unitValue ? ` ${unitValue}` : undefined, getInputRef: fieldOne, value: leftInputValue, valueIsNumericString: true }), _jsx(Minus, {}), _jsx(NumericFormat, { onKeyDown: onKeyPressRightInput, "data-test": "slider-right-input", customInput: FormInputText, onBlur: handleRightFieldChange, onValueChange: (values) => {
                            if (NUMBER_REGEX.test(values.value)) {
                                setRightInputValue(values.value);
                            }
                        }, thousandSeparator: unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.thousandSeparator, label: maxLabel, decimalSeparator: unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.decimalSeparator, decimalScale: unitFormatter === null || unitFormatter === void 0 ? void 0 : unitFormatter.decimals, fixedDecimalScale: true, allowNegative: minValue < 0, suffix: unitValue ? ` ${unitValue}` : undefined, getInputRef: fieldTwo, value: rightInputValue, valueIsNumericString: true })] })))] })));
}
