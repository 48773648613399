import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { format } from 'date-fns';
import { ArrowLeft, ArrowRight } from '../../icons';
import styles from './CustomHeader.module.css';
export function CustomHeader({ locale, monthDate, decreaseYear, increaseYear }) {
    return (_jsxs("div", Object.assign({ className: styles['custom-header'] }, { children: [_jsx("button", Object.assign({ "aria-label": "Previous Month", onClick: decreaseYear, className: styles['nav-button'] }, { children: _jsx(ArrowLeft, {}) })), _jsx("span", Object.assign({ className: styles['current-date-wrapper'] }, { children: _jsx("span", Object.assign({ className: styles['current-month'], "data-test": "current-date" }, { children: format(monthDate, 'yyyy', {
                        locale: locale
                    }) })) })), _jsx("button", Object.assign({ "aria-label": "Next Month", onClick: increaseYear, className: styles['nav-button'] }, { children: _jsx(ArrowRight, {}) }))] })));
}
