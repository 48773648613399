import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { toggleItemInArray, xorify } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { submitTrackingEvent } from '../../services/mail';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setSignInventoryFilter } from '../../state/slices/leftPanelSignInventory';
import { setSignTreeGroupItemsVisible, setSignTreeGroupsVisible, setSignTreeItemVisible } from '../../state/slices/shared';
export function useSignInventoryFilterHandlers() {
    const { filter, folderList } = useAppSelector((state) => state.leftPanelSignInventory);
    const { signProperties, signTree } = useAppSelector((state) => state.shared);
    const dispatch = useAppDispatch();
    const signPropertiesForFilter = signProperties.filter((p) => p.amountInUse && p.type !== 'date');
    function handleDamageFilterClick(value) {
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { damageTypeIds: toggleItemInArray(filter.damageTypeIds, value) })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Damage'
        });
    }
    function handleSignTypeFilterClick(value) {
        if (!value) {
            return;
        }
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { signTypeIds: toggleItemInArray(filter.signTypeIds, value) })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Sign Type'
        });
    }
    function handleSignStatusFilterClick(value) {
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { signStatuses: toggleItemInArray(filter.signStatuses, value) })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Status'
        });
    }
    function handleSignTypeClick() {
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Sign Type'
        });
        const all = signTree.flatMap((group) => group.items.map((item) => item.id));
        if (getCheckboxCheckState(all.length, filter.signTypeIds.length) === 'checked') {
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { signTypeIds: [] })));
            return;
        }
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { signTypeIds: all })));
    }
    function handleSignTypeGroupClick(value) {
        var _a;
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Sign Type'
        });
        if (!value.items) {
            return;
        }
        if (getCheckboxCheckState((_a = value.items.length) !== null && _a !== void 0 ? _a : 0, filter.signTypeIds.filter((t) => { var _a; return (_a = value.items) === null || _a === void 0 ? void 0 : _a.map((i) => i.id).includes(t); }).length) === 'checked') {
            const idsToRemove = value.items.map((t) => t.id);
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { signTypeIds: [...filter.signTypeIds.filter((t) => !idsToRemove.includes(t))] })));
            return;
        }
        const updatedSignTypeIds = [...filter.signTypeIds];
        for (const signTypeId of value.items) {
            if (!signTypeId.id) {
                continue;
            }
            if (updatedSignTypeIds.includes(signTypeId.id)) {
                continue;
            }
            updatedSignTypeIds.push(signTypeId.id);
        }
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { signTypeIds: updatedSignTypeIds })));
    }
    function handleSignInventoryFolderFilterClick(folder) {
        const xorified = xorify(filter.filesAttached, filter.filesNotAttached, folder.id);
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { filesAttached: xorified.list1, filesNotAttached: xorified.list2 })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Files'
        });
    }
    function handleSignInventoryFolderAttachedFilterClick(folderId) {
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { filesAttached: toggleItemInArray(filter.filesAttached, folderId) })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Files'
        });
    }
    function handleSignInventoryFolderNotAttachedFilterClick(folderId) {
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { filesNotAttached: toggleItemInArray(filter.filesNotAttached, folderId) })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Files'
        });
    }
    function handleSignInventoryAllFoldersFilterClick() {
        const filteredFoldersCount = filter.filesAttached.length + filter.filesNotAttached.length;
        const filteredFolders = filteredFoldersCount === folderList.length * 2 ? [] : folderList.map((f) => f.id);
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { filesAttached: filteredFolders, filesNotAttached: filteredFolders })));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Files'
        });
    }
    function handleSignInventoryAllPropertiesFilterClick() {
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Properties'
        });
        let filteredProperties = [];
        if (filter.properties.length !== signPropertiesForFilter.length) {
            for (const property of signPropertiesForFilter) {
                switch (property.type) {
                    case 'select': {
                        filteredProperties.push({
                            id: property.id,
                            type: property.type,
                            selectValue: property.selectOptions.map((option) => option.id),
                            showNotSpecified: true
                        });
                        break;
                    }
                    case 'number': {
                        filteredProperties.push({
                            id: property.id,
                            type: property.type,
                            numberValue: { min: property.numberMin, max: property.numberMax },
                            showNotSpecified: true
                        });
                        break;
                    }
                }
            }
        }
        dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filteredProperties })));
    }
    function handlePropertyFilterClick(id, value, notSpecified) {
        submitTrackingEvent(MIXPANEL_EVENT_NAME.setTrafficSignFilter, {
            [MIXPANEL_EVENT_PROPERTY.signFilterType]: 'Properties'
        });
        const propertyFilter = filter.properties.find((i) => i.id === id);
        if (!propertyFilter) {
            const newPropertyFilter = {
                id,
                type: 'select',
                selectValue: value ? [value] : [],
                showNotSpecified: notSpecified !== null && notSpecified !== void 0 ? notSpecified : false
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.concat(newPropertyFilter) })));
            return;
        }
        if (propertyFilter.type === 'select' && value) {
            const newSelectValue = toggleItemInArray(propertyFilter.selectValue, value);
            if (newSelectValue.length === 0 && !propertyFilter.showNotSpecified) {
                dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.filter((at) => at.id !== id) })));
                return;
            }
            const newPropertyFilter = {
                id,
                type: 'select',
                selectValue: newSelectValue,
                showNotSpecified: propertyFilter.showNotSpecified
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.map((at) => at.id === id ? newPropertyFilter : at) })));
        }
        if (propertyFilter.type === 'select' && notSpecified) {
            // if all real options are deselected and we uncheck not specified, we remove the filter
            if (propertyFilter.selectValue.length === 0 && propertyFilter.showNotSpecified) {
                dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.filter((at) => at.id !== id) })));
                return;
            }
            const newPropertyFilter = {
                id,
                type: 'select',
                selectValue: propertyFilter.selectValue,
                showNotSpecified: !propertyFilter.showNotSpecified
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.map((at) => at.id === id ? newPropertyFilter : at) })));
        }
    }
    function isPropertySelectOptionChecked(id, value, notSpecified) {
        const filterProperty = filter.properties.find((fa) => fa.id === id);
        if (!filterProperty) {
            return false;
        }
        if (filterProperty.type === 'select' && value) {
            return !!filterProperty.selectValue.find((selectedOption) => selectedOption === value);
        }
        else if (filterProperty.type === 'select' && notSpecified) {
            return filterProperty.showNotSpecified;
        }
    }
    function countPropertySelectedOptions(id) {
        const filterProperty = filter.properties.find((fa) => fa.id === id);
        if (!filterProperty) {
            return;
        }
        if (filterProperty.type === 'select') {
            return filterProperty.selectValue.length;
        }
    }
    function handlePropertyGroupFilterClick(id) {
        const filterProperty = filter.properties.find((fa) => fa.id === id);
        if (filterProperty) {
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.filter((at) => at.id !== id) })));
            return;
        }
    }
    function handlePropertyFilterChange(id, range) {
        const propertyFilter = filter.properties.find((i) => i.id === id);
        if (!propertyFilter) {
            const newPropertyFilter = {
                id,
                type: 'number',
                numberValue: range,
                showNotSpecified: false
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.concat(newPropertyFilter) })));
            return;
        }
        if (propertyFilter.type === 'number') {
            const newPropertyFilter = {
                id,
                type: 'number',
                numberValue: Object.assign(Object.assign({}, propertyFilter.numberValue), range),
                showNotSpecified: propertyFilter.showNotSpecified
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.map((at) => at.id === id ? newPropertyFilter : at) })));
        }
    }
    function handleNumberPropertyNotSpecifiedClick(id) {
        var _a, _b;
        const propertyFilter = filter.properties.find((i) => i.id === id);
        if (!propertyFilter) {
            const newPropertyFilter = {
                id,
                type: 'number',
                numberValue: { min: undefined, max: undefined },
                showNotSpecified: true
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.concat(newPropertyFilter) })));
            return;
        }
        if (propertyFilter.type === 'number') {
            // if we uncheck not specified and no range was chosen, we remove the filter
            if (!((_a = propertyFilter.numberValue) === null || _a === void 0 ? void 0 : _a.min) &&
                !((_b = propertyFilter.numberValue) === null || _b === void 0 ? void 0 : _b.max) &&
                propertyFilter.showNotSpecified) {
                dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.filter((at) => at.id !== id) })));
                return;
            }
            const newPropertyFilter = {
                id,
                type: 'number',
                numberValue: propertyFilter.numberValue,
                showNotSpecified: !propertyFilter.showNotSpecified
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.map((at) => at.id === id ? newPropertyFilter : at) })));
        }
    }
    function handleDatePropertyFilterClick(id, from, to, showNotSpecified) {
        const propertyFilter = filter.properties.find((i) => i.id === id);
        if (!propertyFilter) {
            const newPropertyFilter = {
                id,
                type: 'date',
                dateValue: { from, to },
                showNotSpecified
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.concat(newPropertyFilter) })));
            return;
        }
        if (propertyFilter.type === 'date') {
            if (!from && !to && !showNotSpecified) {
                dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.filter((at) => at.id !== id) })));
                return;
            }
            const newPropertyFilter = {
                id,
                type: 'date',
                dateValue: { from, to },
                showNotSpecified
            };
            dispatch(setSignInventoryFilter(Object.assign(Object.assign({}, filter), { properties: filter.properties.map((at) => at.id === id ? newPropertyFilter : at) })));
        }
    }
    function isPropertyNumberNotSpecifiedChecked(id) {
        const filterProperty = filter.properties.find((fa) => fa.id === id);
        if (!filterProperty || filterProperty.type !== 'number') {
            return false;
        }
        return filterProperty.showNotSpecified;
    }
    function getPropertyFilterNumberValue(id) {
        const filterProperty = filter.properties.find((fa) => fa.id === id);
        if (!filterProperty) {
            return;
        }
        if (filterProperty.type === 'number') {
            return filterProperty.numberValue;
        }
        return;
    }
    function getPropertyFilter(id) {
        return filter.properties.find((fa) => fa.id === id);
    }
    function handleSetSignTreeGroupItemsVisible(groupId) {
        dispatch(setSignTreeGroupItemsVisible(groupId));
    }
    function handleSetSignTreeGroupsVisible() {
        dispatch(setSignTreeGroupsVisible());
    }
    function handleSetSignTreeItemVisible(groupId, itemId) {
        dispatch(setSignTreeItemVisible({ groupId, itemId }));
    }
    return {
        handleDamageFilterClick,
        handleSignStatusFilterClick,
        handleSignTypeFilterClick,
        handleSignTypeGroupClick,
        handleSignTypeClick,
        handleSignInventoryFolderFilterClick,
        handleSignInventoryFolderAttachedFilterClick,
        handleSignInventoryFolderNotAttachedFilterClick,
        handleSignInventoryAllFoldersFilterClick,
        handlePropertyFilterClick,
        isPropertySelectOptionChecked,
        countPropertySelectedOptions,
        handlePropertyGroupFilterClick,
        handlePropertyFilterChange,
        handleNumberPropertyNotSpecifiedClick,
        isPropertyNumberNotSpecifiedChecked,
        getPropertyFilterNumberValue,
        handleDatePropertyFilterClick,
        getPropertyFilter,
        handleSignInventoryAllPropertiesFilterClick,
        handleSetSignTreeGroupItemsVisible,
        handleSetSignTreeGroupsVisible,
        handleSetSignTreeItemVisible
    };
}
