import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { keycloakLogout } from '../../common/keycloak';
import { ROOT_PATH } from '../../common/url';
import { needToRegister } from '../../common/user';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { AccountSelectorLoader } from '../../components/AccountSelector/AccountSelectorLoader/AccountSelectorLoader';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchAccessList, fetchCurrentUser } from '../../state/slices/auth';
import { persistor } from '../../state/store';
export function AccountSelectorContainer() {
    const dispatch = useAppDispatch();
    const { isAuthenticated, userAccount, accountsAccessList } = useAppSelector((state) => state.auth);
    function handleSelectCity(targetSchema) {
        persistor.purge();
        if (needToRegister(userAccount)) {
            window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.register}?targetSchema=${targetSchema}`;
            return;
        }
        else if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.titleId) || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.departmentId)) {
            window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.update}?targetSchema=${targetSchema}`;
            return;
        }
        window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.main}?targetSchema=${targetSchema}`;
    }
    function handleLogout() {
        keycloakLogout();
    }
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCurrentUser());
            dispatch(fetchAccessList());
        }
    }, [isAuthenticated, dispatch]);
    useEffect(() => {
        if (isAuthenticated && userAccount && accountsAccessList.length === 1) {
            handleSelectCity(accountsAccessList[0].username);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, userAccount, accountsAccessList]);
    if (!userAccount || accountsAccessList.length <= 1) {
        return _jsx(AccountSelectorLoader, { onLogoutClick: handleLogout });
    }
    return (_jsx(AccountSelector, { accountList: accountsAccessList.map((item) => ({
            id: item.username,
            name: item.displayName
        })), onSelectAccount: handleSelectCity, onLogoutClick: handleLogout }));
}
