export const getViewState = (map) => {
    const center = map.getCenter();
    return {
        zoom: map.getZoom(),
        latitude: center.lat,
        longitude: center.lng,
        bearing: map.getBearing(),
        pitch: map.getPitch(),
        padding: map.getPadding()
    };
};
