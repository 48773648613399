var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { IconResizer } from '../IconResizer/IconResizer';
import { SparkleMagicAddFilled, SparkleMagicApplied, SparkleMagicAvailable } from '../icons';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { TypedDitto } from '../TypedDitto';
import styles from './FormInputTextarea.module.css';
export const FormInputTextarea = forwardRef((_a, ref) => {
    var { width, height = 64, label, value, onValueChange, onFocusChange, info, className, onFocus, onBlur, isDark, variant, magicValue, placeholder, disabled, autosize = false } = _a, props = __rest(_a, ["width", "height", "label", "value", "onValueChange", "onFocusChange", "info", "className", "onFocus", "onBlur", "isDark", "variant", "magicValue", "placeholder", "disabled", "autosize"]);
    const [isFocus, setIsFocus] = useState(false);
    const textareaRef = useRef(null);
    const isLabelSmall = variant !== 'ghost' && variant !== 'editor-field' && isFocus;
    useImperativeHandle(ref, () => textareaRef.current, []);
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '0px';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [textareaRef, value]);
    return (_jsxs("div", Object.assign({ className: classes(styles['field'], isFocus && styles['field__focus'], isDark && styles['field__dark'], variant && styles[`field__${variant}`], disabled && styles['disabled'], className), style: { width, height: !autosize ? height : undefined } }, { children: [(label || info) && (_jsxs("span", Object.assign({ className: classes(styles['label'], isLabelSmall && styles['label__small']) }, { children: [label && _jsx("span", Object.assign({ className: styles['label-text'] }, { children: label })), info && _jsx(InfoTooltip, { tooltip: info.tooltip })] }))), magicValue && (_jsx("div", Object.assign({ className: classes(styles['icon'], styles['magic-icon']), onClick: () => onValueChange && onValueChange(magicValue) }, { children: _jsx(TooltipWrapper, Object.assign({ size: "small", text: _jsx(TypedDitto, { componentId: "taskcreator.magicinputtooltipautofillbasedoncondition" }), position: 'end', onClick: () => onValueChange && onValueChange(magicValue), arrow: 'down' }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsxs(_Fragment, { children: [value && magicValue === value && _jsx(SparkleMagicAddFilled, {}), value && magicValue != value && _jsx(SparkleMagicApplied, {}), !value && _jsx(SparkleMagicAvailable, {})] }) })) })) }))), _jsx("textarea", Object.assign({}, props, { ref: textareaRef, className: styles['input'], disabled: disabled, placeholder: placeholder, value: value, onChange: (e) => onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(e.target.value), onFocus: (e) => {
                    setIsFocus(true);
                    onFocusChange && onFocusChange(true);
                    onFocus && onFocus(e);
                }, onBlur: (e) => {
                    setIsFocus(false);
                    onFocusChange && onFocusChange(false);
                    onBlur && onBlur(e);
                } }))] })));
});
FormInputTextarea.displayName = 'FormInputTextarea';
