// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aDeq0SJFuJ34FoFq1WgO{font-family:"Roboto Flex",sans-serif;background:#222d39;padding:8px;border-radius:8px;position:relative;display:inline-flex;width:fit-content;max-width:260px}.aDeq0SJFuJ34FoFq1WgO::before{content:"";position:absolute;bottom:-13px;left:50%;transform:translateX(-50%);border-width:7px;border-style:solid;border-color:#222d39 rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}.hBwSUSMY7SnxguoVEqjE::before{position:absolute;top:-14px;bottom:auto;border-color:rgba(0,0,0,0) rgba(0,0,0,0) #222d39 rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/MapPopover.module.css"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,mBAAA,CACA,iBAAA,CACA,eAAA,CAMJ,8BACI,UAAA,CACA,iBAAA,CACA,YAAA,CACA,QAAA,CACA,0BAAA,CACA,gBAAA,CACA,kBAAA,CACA,8DAAA,CAMJ,8BACI,iBAAA,CACA,SAAA,CACA,WAAA,CACA,8DAAA","sourcesContent":[".container {\n    font-family: 'Roboto Flex', sans-serif;\n    background: rgba(34, 45, 57, 1);\n    padding: 8px;\n    border-radius: 8px;\n    position: relative;\n    display: inline-flex;\n    width: fit-content;\n    max-width: 260px;\n}\n\n/*\n    Downwards pointing arrow below container\n*/\n.container::before {\n    content: '';\n    position: absolute;\n    bottom: -13px;\n    left: 50%;\n    transform: translateX(-50%);\n    border-width: 7px;\n    border-style: solid;\n    border-color: #222d39 transparent transparent transparent;\n}\n\n/*\n    Put arrow on top of container and point it upwards\n*/\n.container__down::before {\n    position: absolute;\n    top: -14px;\n    bottom: auto;\n    border-color: transparent transparent #222d39 transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aDeq0SJFuJ34FoFq1WgO`,
	"container__down": `hBwSUSMY7SnxguoVEqjE`
};
export default ___CSS_LOADER_EXPORT___;
