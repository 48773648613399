import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { AnimatePresence, motion } from 'framer-motion';
import { layerSelectorTitleTransition, layerSelectorTitleVariants } from '../../../common/consts/animations';
import { getMapLayerIcon, getMapLayerText } from '../../../common/layer';
import { Checkbox } from '../../../uikit/Checkbox/Checkbox';
import { BetaBadge } from '../../BetaBadge/BetaBadge';
import { IconResizer } from '../../IconResizer/IconResizer';
import { CloseAlt } from '../../icons';
import { LayerFocus } from '../../icons/LayerFocus';
import { TooltipWrapper } from '../../TooltipWrapper/TooltipWrapper';
import styles from './SidebarLayerButton.module.css';
export function SidebarLayerButton({ layer, isActive, onClick, onClickExcludeOtherLayers, onCheckboxClick, isOverlay, label, 'data-test': dataTest }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], isActive && styles['container--active']), onClick: onClick, "data-test": dataTest }, { children: [_jsx("div", Object.assign({ className: classes(styles['button-container'], !layer.isEnabled && !isActive && styles['button-container--disabled']) }, { children: _jsx(TooltipWrapper, Object.assign({ text: !isOverlay && (_jsxs(_Fragment, { children: [isActive && (_jsxs("span", Object.assign({ className: styles['button-container-tooltip-disable'] }, { children: [_jsx(CloseAlt, {}), _jsx(Ditto, { componentId: "layerpanel.disable" })] }))), _jsx("span", { children: getMapLayerText(layer.id) }), (function () {
                                if (!layer.isAuthorized) {
                                    return (_jsx("span", Object.assign({ className: styles['button-container-tooltip-badge'] }, { children: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "badgenewlocked" }), color: "yellow" }) })));
                                }
                                if (layer.isBeta) {
                                    return (_jsx("span", Object.assign({ className: styles['button-container-tooltip-badge'] }, { children: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), color: "dark" }) })));
                                }
                                if (layer.isNew) {
                                    return (_jsx("span", Object.assign({ className: styles['button-container-tooltip-badge'] }, { children: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "badgenew" }), color: "default" }) })));
                                }
                            })()] })), position: "start", className: styles['button-container-tooltip'] }, { children: _jsx("div", Object.assign({ className: styles['button-container-inner'] }, { children: getMapLayerIcon(layer.id) })) }), `tooltip-${isOverlay}`) })), _jsx(AnimatePresence, { children: isOverlay && (_jsxs(motion.div, Object.assign({ transition: layerSelectorTitleTransition, variants: layerSelectorTitleVariants, initial: "initial", animate: "overlay", exit: "initial", className: styles['tail'] }, { children: [_jsx("div", Object.assign({ className: styles['checkbox'], onClick: (event) => {
                                event.stopPropagation();
                                onCheckboxClick === null || onCheckboxClick === void 0 ? void 0 : onCheckboxClick();
                            }, "data-test": `checkbox${layer.isEnabled ? '-checked' : ''}` }, { children: _jsx(Checkbox, { isChecked: layer.isEnabled, color: 'white' }) })), _jsxs("div", Object.assign({ className: styles['title'] }, { children: [_jsx("span", Object.assign({ className: styles['title-main'] }, { children: getMapLayerText(layer.id) })), label && _jsx("span", Object.assign({ className: styles['title-label'] }, { children: label }))] })), _jsx("div", Object.assign({ className: classes(styles['exclude-others'], isActive && styles['exclude-others--active']), onClick: (event) => {
                                event.stopPropagation();
                                onClickExcludeOtherLayers === null || onClickExcludeOtherLayers === void 0 ? void 0 : onClickExcludeOtherLayers(layer);
                            }, "data-test": "exclude-others" }, { children: _jsx(TooltipWrapper, Object.assign({ text: _jsx(Ditto, { componentId: "enableonlythislayer" }), className: styles['exclude-others-tooltip'], size: "small" }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(LayerFocus, {}) })) })) }))] }), 'tail')) })] })));
}
