// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o4JirOi3xE701ZnyyYna{height:100%}.om_R8JnShK5h59pLLECn{display:flex;flex-direction:row;align-items:center;gap:6px;width:100%;height:100%;white-space:pre-line}.et1F3sfeQZrgMoHOYrHL,.cBxnenZ5jxvmO9xr6KA6{justify-content:center}.Wsw3laVYz0Z4JCSXqb_Q{justify-content:flex-end}.RkBdOeoCBIsQ1tAnY6dT{padding:6px 8px}.o4JirOi3xE701ZnyyYna p{margin:0}.Op4swQW2L49oKTiJINR6{flex:1}.eOPgqDSNOVN2baJWlaBR{display:flex;height:24px}.BcD0shVSPe6a_70hAhVd{flex:1}.GoBFhDoVVRqvGfqfeJyH>div:first-child{word-break:break-all}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTableRow/DataGridTableRowItemContent/DataGridTableRowItemContent.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,oBAAA,CAGJ,4CAEI,sBAAA,CAGJ,sBACI,wBAAA,CAGJ,sBACI,eAAA,CAGJ,wBACI,QAAA,CAGJ,sBACI,MAAA,CAGJ,sBACI,YAAA,CACA,WAAA,CAGJ,sBACI,MAAA,CAGJ,sCACI,oBAAA","sourcesContent":[".container {\n    height: 100%;\n}\n\n.content {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 6px;\n    width: 100%;\n    height: 100%;\n    white-space: pre-line;\n}\n\n.size-xs,\n.content--center {\n    justify-content: center;\n}\n\n.content--right {\n    justify-content: flex-end;\n}\n\n.content--padding {\n    padding: 6px 8px;\n}\n\n.container p {\n    margin: 0;\n}\n\n.text {\n    flex: 1;\n}\n\n.magic-icon {\n    display: flex;\n    height: 24px;\n}\n\n.magic-icon--empty-field {\n    flex: 1;\n}\n\n.text-overflow > div:first-child {\n    word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `o4JirOi3xE701ZnyyYna`,
	"content": `om_R8JnShK5h59pLLECn`,
	"size-xs": `et1F3sfeQZrgMoHOYrHL`,
	"content--center": `cBxnenZ5jxvmO9xr6KA6`,
	"content--right": `Wsw3laVYz0Z4JCSXqb_Q`,
	"content--padding": `RkBdOeoCBIsQ1tAnY6dT`,
	"text": `Op4swQW2L49oKTiJINR6`,
	"magic-icon": `eOPgqDSNOVN2baJWlaBR`,
	"magic-icon--empty-field": `BcD0shVSPe6a_70hAhVd`,
	"text-overflow": `GoBFhDoVVRqvGfqfeJyH`
};
export default ___CSS_LOADER_EXPORT___;
