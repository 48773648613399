// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lzyhznhqEZRUmHZ9vgjh{margin-top:24px;min-height:213px;background:#f1f2f4;border-radius:6px;border:none;flex-grow:1;resize:none;padding:24px 12px;font-weight:400;font-size:14px;line-height:130%;color:var(--primary-black);opacity:.6}.REiT8JGB6xU1Qw5KEqYO{display:flex;padding:8px 12px 8px 8px;background:rgba(238,109,108,.3);border-radius:12px;font-size:12px;line-height:24px;color:#ff3e3e;gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ReportIssue/ReportIssue.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,gBAAA,CAEA,kBAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CAEA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,0BAAA,CAEA,UAAA,CAGJ,sBACI,YAAA,CAEA,wBAAA,CAEA,+BAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,OAAA","sourcesContent":[".content {\n    margin-top: 24px;\n    min-height: 213px;\n\n    background: #f1f2f4;\n    border-radius: 6px;\n    border: none;\n    flex-grow: 1;\n    resize: none;\n    padding: 24px 12px;\n\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 130%;\n\n    color: var(--primary-black);\n\n    opacity: 0.6;\n}\n\n.error {\n    display: flex;\n\n    padding: 8px 12px 8px 8px;\n\n    background: rgba(238, 109, 108, 0.3);\n    border-radius: 12px;\n    font-size: 12px;\n    line-height: 24px;\n    color: #ff3e3e;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `lzyhznhqEZRUmHZ9vgjh`,
	"error": `REiT8JGB6xU1Qw5KEqYO`
};
export default ___CSS_LOADER_EXPORT___;
