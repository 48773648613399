import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { signInventoryToMarkerSource } from '../../common/convert';
import { getSignFromSignTree } from '../../common/convert/signInventory';
import { LAYER_PATH } from '../../common/layer';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { Assets, Plus, Signs2, Task } from '../../components/icons';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMarkerCreatorOpener } from '../../hooks/useMarkerCreatorOpener';
import { useSignInventoryInfoFetcher } from '../../hooks/useSignInventoryInfoFetcher';
import { useUserRole } from '../../hooks/useUserRole';
import { submitTrackingEvent } from '../../services/mail';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetAssetCreator, setAssetCreatorSource, setAssetCreatorType } from '../../state/slices/assetCreator';
import { resetMarkerCreator, setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { setTrafficSignCreatorPointSource } from '../../state/slices/signCreator';
import { Button } from '../../uikit/Button/Button';
import { AssetCreatorContainer } from '../AssetCreatorContainer/AssetCreatorContainer';
import { RightPanelMarkerCreatorContainer } from '../RightPanelMarkerCreatorContainer/RightPanelMarkerCreatorContainer';
import { RightPanelTrafficSignCreatorContainer } from '../RightPanelTrafficSignCreatorContainer/RightPanelTrafficSignCreatorContainer';
import { SignInventoryDetailContainer } from '../SignInventoryDetailContainer/SignInventoryDetailContainer';
export function ImageViewerSignInventoryContainer() {
    const dispatch = useAppDispatch();
    const { removeModal, modals } = useModalContext();
    const { targetSchema } = useAppSelector((state) => state.auth);
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    const { source: trafficSignCreatorSource } = useAppSelector((state) => state.signCreator);
    const { signInfo } = useAppSelector((state) => state.signInventoryDetail);
    const { getMagicValuesForSignInventoryTask } = useMarkerCreatorOpener();
    const { signTree } = useAppSelector((state) => state.shared);
    const { isEditableTrafficSignsEnabled } = useLayerChecker();
    const { isEditor } = useUserRole();
    useSignInventoryInfoFetcher();
    function handleClickClose() {
        removeModal('ImageViewerSignInventory');
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'ImageViewerSignInventory') {
            return;
        }
        handleClickClose();
    }
    function handleAddMarkerButtonClick() {
        if (!signInfo) {
            return;
        }
        const { taskTitle, taskDescription, taskPriorityId } = getMagicValuesForSignInventoryTask(signInfo.damageType);
        const sign = getSignFromSignTree(signTree, signInfo.tag.subSpecifiedClass);
        const fullTaskDescription = `${taskDescription} "${sign === null || sign === void 0 ? void 0 : sign.name} (${sign === null || sign === void 0 ? void 0 : sign.bastId})"`;
        dispatch(setMarkerCreatorPointSource(signInventoryToMarkerSource(signInfo, taskTitle, fullTaskDescription, taskPriorityId)));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.createTaskFromSign);
    }
    function handleAddAssetButtonClick() {
        if (!signInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.createAssetFromImageViewer, {
            [MIXPANEL_EVENT_PROPERTY.layerName]: LAYER_PATH.signInventory
        });
        dispatch(setAssetCreatorType('right-panel'));
        dispatch(setAssetCreatorSource({
            signInventory: signInfo
        }));
    }
    function handleAddTrafficSignButtonClick() {
        if (!signInfo) {
            return;
        }
        dispatch(setTrafficSignCreatorPointSource({
            pointUuid: signInfo.pointUuid,
            address: signInfo.displayName,
            coordinates: {
                latitude: signInfo.offsetPosition[1],
                longitude: signInfo.offsetPosition[0]
            }
        }));
    }
    function handleBackButtonClick(type) {
        if (type === 'marker') {
            dispatch(resetMarkerCreator());
        }
        else if (type === 'asset') {
            dispatch(resetAssetCreator());
        }
    }
    if (!targetSchema || !signInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    const topButtonsCreate = (_jsx(FloatingDropdownWrapper, { control: _jsx(Button, { color: "dark", variant: "square", icon: _jsx(Plus, {}) }), topOffset: 4, menu: _jsxs(ActionModal, Object.assign({ extraRounded: true }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "createmarker" }), leadingIcon: _jsx(Task, {}), onClick: handleAddMarkerButtonClick, size: "medium", extraRounded: true }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "actionmodal.createinventory" }), leadingIcon: _jsx(Assets, {}), onClick: handleAddAssetButtonClick, size: "medium", extraRounded: true }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "trafficsigns.createtrafficsign" }), leadingIcon: _jsx(Signs2, {}), onClick: handleAddTrafficSignButtonClick, size: "medium", extraRounded: true })] })) }));
    if (markerCreatorSource) {
        return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "SignInventory", singleImage: _jsx("img", { src: signInfo.imageUrl }), onBackButtonClick: () => handleBackButtonClick('marker'), onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate, sign: signInfo, details: _jsx(SignInventoryDetailContainer, {}) }), creator: _jsx(RightPanelMarkerCreatorContainer, {}) }));
    }
    if (assetCreatorSource) {
        return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "SignInventory", singleImage: _jsx("img", { src: signInfo.imageUrl }), onBackButtonClick: () => handleBackButtonClick('asset'), onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate, sign: signInfo }), creator: _jsx(AssetCreatorContainer, {}) }));
    }
    if (trafficSignCreatorSource) {
        const creator = isEditor && isEditableTrafficSignsEnabled ? (_jsx(RightPanelTrafficSignCreatorContainer, {})) : undefined;
        return (_jsx(ImageViewerModal, { onClickOutside: handleClickOutside, imageViewer: _jsx(ImageViewer, { variant: "SignInventory", onDoneClick: handleClickOutside, singleImage: _jsx("img", { src: signInfo.imageUrl }), sign: signInfo }), creator: creator }));
    }
    return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "SignInventory", onDoneClick: handleClickOutside, singleImage: _jsx("img", { src: signInfo.imageUrl }), sign: signInfo, topButtonsCreate: topButtonsCreate, details: _jsx(SignInventoryDetailContainer, {}) }) }));
}
