import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { InfoBox } from '../../InfoBox/InfoBox';
import { Modal } from '../../Modal/Modal';
import { AutoUpdateModalRowItem } from './AutoUpdateModalRowItem/AutoUpdateModalRowItem';
import styles from './AutoUpdateModal.module.css';
export function AutoUpdateModal({ className, items, isBulk, onCancelClick, onSubmitClick }) {
    return (_jsx(Modal, { className: classes(styles['container'], className), "data-test": "auto-update-modal", title: _jsx(Ditto, { componentId: "plannedsegments.treatment.autoupdate" }), content: _jsxs(_Fragment, { children: [_jsx(InfoBox, { content: _jsx(Ditto, { componentId: "plannedsegments.autoupdate.info2" }) }), _jsx("div", { className: styles['space'] }), items.map((item, index) => (_jsx(AutoUpdateModalRowItem, Object.assign({ isBulk: isBulk }, item), `auto-update-item-${index}`)))] }), footer: _jsxs("div", Object.assign({ className: styles['gap'] }, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: () => onCancelClick && onCancelClick(), "data-test": "cancel-btn" }, { children: _jsx(Ditto, { componentId: "modal.decline" }) })), _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => onSubmitClick && onSubmitClick() }, { children: _jsx(Ditto, { componentId: "modal.confirm" }) }))] })) }));
}
