var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pathParams, post } from '../common/fetch';
import { createConditionTiles } from '../common/tiles';
const NAVIGATION_ENDPOINT = `v1/streetview/navigation`;
export function getNavigation(currentPointId, params) {
    return __awaiter(this, void 0, void 0, function* () {
        if (params.planId) {
            return yield post(pathParams(NAVIGATION_ENDPOINT, { planId: params.planId }), {
                currentPointId
            });
        }
        return yield post(
        // Backend uses same filter parsing as tiles
        createConditionTiles(NAVIGATION_ENDPOINT, params.timepicker, params.filter)[0], {
            currentPointId
        });
    });
}
