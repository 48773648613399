import { DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN } from '../state/slices/leftPanelCondition';
export function toggleDamage(array, value) {
    if (array.find((item) => item.id === value.id)) {
        return array.filter((item) => item.id !== value.id);
    }
    return array.concat([
        {
            id: value.id,
            name: value.name,
            isExcluded: false,
            range: {
                min: DEFAULT_GRADE_MIN,
                max: DEFAULT_GRADE_MAX
            }
        }
    ]);
}
export function toggleExcludeDamage(array, value) {
    const found = array.find((item) => item.id === value);
    if (!found) {
        return array;
    }
    const newArray = array.filter((item) => item.id !== value);
    newArray.push(Object.assign(Object.assign({}, found), { isExcluded: !found.isExcluded }));
    return newArray;
}
export function updateDamageRange(array, damageTypeId, value) {
    var _a, _b;
    const found = array.find((item) => item.id === damageTypeId);
    if (!found) {
        return array;
    }
    const newArray = array.filter((item) => item.id !== damageTypeId);
    newArray.push(Object.assign(Object.assign({}, found), { range: {
            min: (_a = value.min) !== null && _a !== void 0 ? _a : found.range.min,
            max: (_b = value.max) !== null && _b !== void 0 ? _b : found.range.max
        } }));
    return newArray;
}
export function combineDamages(filterDamages) {
    const not = filterDamages.filter((item) => item.isExcluded);
    const or = filterDamages.filter((item) => !item.isExcluded);
    const combined = {};
    if (not.length > 0) {
        combined.$and = not.map((item) => {
            return {
                [item.name]: {
                    $not: {
                        $gt: item.range.min,
                        $lte: item.range.max
                    }
                }
            };
        });
    }
    if (or.length > 0) {
        combined.$or = or.map((item) => {
            return {
                [item.name]: {
                    $gt: item.range.min,
                    $lte: item.range.max
                }
            };
        });
    }
    return combined;
}
export function createReconstructionFilter(proposals, { surfaceTypes, roadDamages, segmentLength }, reconstructionProposalId) {
    const proposal = proposals.find((item) => item.id === reconstructionProposalId);
    if (!proposal) {
        return {};
    }
    const filter = {};
    filter.segmentLengthMin = proposal.filterObject.segmentLength;
    filter.segmentLengthMax = segmentLength.max;
    if (proposal.filterObject.grades.length > 0) {
        filter.grade = {
            min: Math.min.apply(Math, proposal.filterObject.grades),
            max: Math.max.apply(Math, proposal.filterObject.grades)
        };
    }
    filter.streetType = [];
    if (proposal.filterObject.streetTypes.length > 0) {
        // TODO: no street types in reconstruction proposals
    }
    filter.surfaceType = [];
    if (proposal.filterObject.surfaceTypes.length > 0) {
        for (const surface of proposal.filterObject.surfaceTypes) {
            const surfaceType = surfaceTypes.find((item) => item.name === surface);
            if (!surfaceType) {
                continue;
            }
            filter.surfaceType.push(surfaceType.id);
        }
    }
    filter.damageType = [];
    if (proposal.filterObject.damages.or.length > 0) {
        for (const damage of proposal.filterObject.damages.or) {
            const damageType = roadDamages.find((item) => item.name === damage.selected);
            if (!damageType) {
                continue;
            }
            const damageRange = {
                id: damageType.id,
                name: damageType.name,
                isExcluded: false,
                range: {
                    min: damage.value.min,
                    max: damage.value.max
                }
            };
            filter.damageType.push(damageRange);
        }
    }
    if (proposal.filterObject.damages.and.length > 0) {
        for (const damage of proposal.filterObject.damages.and) {
            const damageType = roadDamages.find((item) => item.name === damage.selected);
            if (!damageType) {
                continue;
            }
            const damageRange = {
                id: damageType.id,
                name: damageType.name,
                isExcluded: true,
                range: {
                    min: damage.value.min,
                    max: damage.value.max
                }
            };
            filter.damageType.push(damageRange);
        }
    }
    return filter;
}
