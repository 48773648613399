import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { SIGN_DAMAGE_ID, SIGN_STATUS_CODE } from '../../../common/consts/trafficSign';
import { getSignIcon } from '../../../common/convert';
import { getSignTypeIcon } from '../../../common/convert/signInventory';
import { IconResizer } from '../../IconResizer/IconResizer';
import { SparkleMagicAdd } from '../../icons';
import styles from './Tag.module.css';
const OFFSET = 30;
const RATIO_WIDTH = 4;
const RATIO_HEIGHT = 3;
export function Tag({ currentHeight, currentWidth, tag, damageType, signIcon, statusCode }) {
    if (!currentHeight || !currentWidth) {
        return null;
    }
    const { height: maxHeight, width: maxWidth, xmax, xmin, ymax, ymin } = tag;
    const innerImgWidht = (RATIO_WIDTH * currentHeight) / RATIO_HEIGHT;
    const xMarginWidth = (currentWidth - innerImgWidht) / 2;
    const innerImgHeight = (RATIO_HEIGHT * currentWidth) / RATIO_WIDTH;
    const yMarginWidth = (currentHeight - innerImgHeight) / 2;
    const currentYmax = yMarginWidth <= 0
        ? (currentHeight * ymax) / maxHeight
        : (innerImgHeight * ymax) / maxHeight;
    const currentYmin = yMarginWidth <= 0
        ? (currentHeight * ymin) / maxHeight
        : (innerImgHeight * ymin) / maxHeight;
    const currentXmax = xMarginWidth <= 0 ? (currentWidth * xmax) / maxWidth : (innerImgWidht * xmax) / maxWidth;
    const currentXmin = xMarginWidth <= 0 ? (currentWidth * xmin) / maxWidth : (innerImgWidht * xmin) / maxWidth;
    const tagHeight = Math.round(currentYmax - currentYmin + OFFSET);
    const tagWidth = Math.round(currentXmax - currentXmin + OFFSET);
    const top = xMarginWidth <= 0
        ? Math.round(currentYmin + yMarginWidth - OFFSET / 2)
        : Math.round(currentYmin - OFFSET / 2);
    const left = xMarginWidth <= 0
        ? Math.round(currentXmin - OFFSET / 2)
        : Math.round(currentXmin + xMarginWidth - OFFSET / 2);
    const tagPosition = {
        top,
        left
    };
    const frameSize = {
        height: tagHeight,
        width: tagWidth
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: classes(styles['container']), style: tagPosition }, { children: [_jsx("div", { className: classes(styles['frame']), style: frameSize }), _jsxs("div", Object.assign({ className: classes(styles['tag-caption']) }, { children: [_jsx(IconResizer, Object.assign({ size: 18 }, { children: getSignTypeIcon(signIcon) })), statusCode === SIGN_STATUS_CODE.predicted && (_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(SparkleMagicAdd, {}) }))), damageType !== SIGN_DAMAGE_ID.not_damaged && (_jsx(IconResizer, Object.assign({ size: 18 }, { children: getSignIcon(damageType) })))] }))] })) }));
}
