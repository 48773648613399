// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdbVEQLR80RYJ2uVL00e{height:476px;overflow:hidden;display:flex;flex-direction:column;flex-shrink:0}.cpqmVxfMC0I6Wn3nyZsg{display:flex;flex-direction:column;align-items:center;padding-top:12px;padding-bottom:12px;scroll-padding-bottom:20px;scroll-behavior:smooth;overflow-y:auto}.QOBcAqHDFJsDDQueJFnw{width:100%;height:56px;padding:8px;margin-bottom:8px}.g7TuEVLftmbCa3lFbRKq{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-top:12px;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/components/EditorForms/CategorySelect/CategorySelect.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CACA,sBAAA,CACA,eAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[".container {\n    height: 476px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    flex-shrink: 0;\n}\n\n.items {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    scroll-padding-bottom: 20px;\n    scroll-behavior: smooth;\n    overflow-y: auto;\n}\n\n.create-container {\n    width: 100%;\n    height: 56px;\n    padding: 8px;\n    margin-bottom: 8px;\n}\n\n.divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-top: 12px;\n    flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pdbVEQLR80RYJ2uVL00e`,
	"items": `cpqmVxfMC0I6Wn3nyZsg`,
	"create-container": `QOBcAqHDFJsDDQueJFnw`,
	"divider": `g7TuEVLftmbCa3lFbRKq`
};
export default ___CSS_LOADER_EXPORT___;
