import { isMapSourceLoaded } from './sources';
export const extractFeatureFromEvent = (e) => {
    var _a;
    if (!e.features || e.features.length === 0) {
        return null;
    }
    // Markers have a toggleable "hidden" state, make sure we ignore those
    // when their status is set to "hidden"
    const visible = e.features.filter((item) => { var _a; return !((_a = item.state) === null || _a === void 0 ? void 0 : _a.hidden); });
    return (_a = visible.at(0)) !== null && _a !== void 0 ? _a : null;
};
export function getFeatureLocation(mapFeature) {
    if (mapFeature.geometry.type !== 'Point') {
        return undefined;
    }
    return {
        lat: mapFeature.geometry.coordinates[1],
        lng: mapFeature.geometry.coordinates[0]
    };
}
export const isSameFeatureIdentifier = (a, b) => {
    return (!!a.id &&
        !!b.id &&
        a.id === b.id &&
        a.source === b.source &&
        a.sourceLayer === b.sourceLayer);
};
export function isSameSelectedFeature(a, b) {
    return (a === null || a === void 0 ? void 0 : a.type) === (b === null || b === void 0 ? void 0 : b.type) && (a === null || a === void 0 ? void 0 : a.id) === (b === null || b === void 0 ? void 0 : b.id);
}
export function safeRemoveFeatureState(map, target, key) {
    if (isMapSourceLoaded(map, target.source)) {
        map.removeFeatureState(target, key);
    }
}
export function safeSetFeatureState(map, feature, state) {
    if (isMapSourceLoaded(map, feature.source)) {
        map.setFeatureState(feature, state);
    }
}
