import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Check, External, Warning1 } from '../../../icons';
import styles from './DeleteModelInfo.module.css';
export function DeleteModalInfo({ affectedCount, onLinkClick }) {
    if (!affectedCount) {
        return (_jsxs("div", Object.assign({ className: styles['delete-info'] }, { children: [_jsx("div", Object.assign({ className: styles['okay'] }, { children: _jsx(Check, {}) })), _jsx("span", { children: _jsx(Ditto, { componentId: "prompt.delete.filecontainer.link_not_used" }) })] })));
    }
    const affectedText = onLinkClick ? (_jsxs("a", Object.assign({ className: styles['affected'], onClick: onLinkClick }, { children: [_jsx(Ditto, { componentId: "prompt.delete.filecontainer.link", count: affectedCount, variables: { count: affectedCount } }), _jsx("div", Object.assign({ className: styles['external'] }, { children: _jsx(External, {}) }))] }))) : (_jsx(Ditto, { componentId: "prompt.delete.filecontainer.link", count: affectedCount, variables: { count: affectedCount } }));
    return (_jsxs("div", Object.assign({ className: styles['delete-info'] }, { children: [_jsx("div", Object.assign({ className: styles['warning'] }, { children: _jsx(Warning1, {}) })), affectedText] })));
}
