// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JOs8GlVha_oDxXyiYsGV{width:100%}.juMFr1JKr_3tkUxNU1lM{width:100%;display:flex;justify-content:flex-end;gap:12px}.CDsRqCT9w8PRbXH4ocBL{width:120px;margin-right:auto}.yLIG52NV_1NZESt5SCjQ{width:120px}.HOGP71YqrRLJuCY6glqg{width:120px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/DueDatePickerModal/DueDatePickerModal.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA,CAGJ,sBACI,WAAA,CACA,iBAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,WAAA","sourcesContent":[".footer {\n    width: 100%;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n}\n\n.button-reset {\n    width: 120px;\n    margin-right: auto;\n}\n\n.button-cancel {\n    width: 120px;\n}\n\n.button-confirm {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `JOs8GlVha_oDxXyiYsGV`,
	"buttons": `juMFr1JKr_3tkUxNU1lM`,
	"button-reset": `CDsRqCT9w8PRbXH4ocBL`,
	"button-cancel": `yLIG52NV_1NZESt5SCjQ`,
	"button-confirm": `HOGP71YqrRLJuCY6glqg`
};
export default ___CSS_LOADER_EXPORT___;
