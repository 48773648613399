// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qEcUQIalXMeLYvg0H67z{border-radius:4px;cursor:pointer;filter:grayscale(1)}.qEcUQIalXMeLYvg0H67z:hover{background:var(--status-hover-hover);filter:grayscale(0)}`, "",{"version":3,"sources":["webpack://./src/components/FormInputMagicValueIcon/FormInputMagicValueIcon.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,cAAA,CACA,mBAAA,CAGJ,4BACI,oCAAA,CACA,mBAAA","sourcesContent":[".icon-hover {\n    border-radius: 4px;\n    cursor: pointer;\n    filter: grayscale(1);\n}\n\n.icon-hover:hover {\n    background: var(--status-hover-hover);\n    filter: grayscale(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-hover": `qEcUQIalXMeLYvg0H67z`
};
export default ___CSS_LOADER_EXPORT___;
