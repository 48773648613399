import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ASSET_CHANGELOG_OPERATION } from '../../../common/consts/asset';
import { getUserIcon, MarkerPriorityIcon } from '../../../common/convert';
import { getAssetSourceLabel } from '../../../common/convert/asset';
import { getAttributeValue } from '../../../common/convert/attribute';
import { AssetIcon } from '../../../common/icon';
import { ChangelogItem } from '../../DetailsPanel/Changelog/ChangelogItem/ChangelogItem';
import { Asterisk, Attachments, Edit, MapPin, Minus, NoAttachments, Plus } from '../../icons';
export function AssetChangelog({ changelogList, formatter, userMap, assetTypeMap, lookupMarkerMap, unitMap }) {
    const notspecified = _jsx(Ditto, { componentId: "notspecified" });
    const notitle = _jsx(Ditto, { componentId: "tasktitle.notitle" });
    const typeAttributes = Object.values(assetTypeMap).flatMap((t) => t.attributes);
    const typeAttributeMap = Object.fromEntries(typeAttributes.map((t) => [t.id, t]));
    const changelogElements = changelogList.map((changelog) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        const dateTime = formatter.formatDate(changelog.createdAt);
        const trailingIcon = getUserIcon(userMap, changelog.user);
        switch (changelog.operation) {
            case ASSET_CHANGELOG_OPERATION.create:
                return (_jsx(ChangelogItem, { icon: _jsx(Asterisk, {}), to: getAssetSourceLabel((_a = changelog.newValue) === null || _a === void 0 ? void 0 : _a.sourceId), description: _jsx(Ditto, { componentId: "rightpanel.titletype.inventory" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case ASSET_CHANGELOG_OPERATION.updateTitle:
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: ((_b = changelog.oldValue) === null || _b === void 0 ? void 0 : _b.title) || notspecified, to: ((_c = changelog.newValue) === null || _c === void 0 ? void 0 : _c.title) || notspecified, description: _jsx(Ditto, { componentId: "assets.assettitle" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case ASSET_CHANGELOG_OPERATION.updateAddress:
                return (_jsx(ChangelogItem, { icon: _jsx(MapPin, {}), from: formatter.formatAddress((_d = changelog.oldValue) === null || _d === void 0 ? void 0 : _d.address) || notspecified, to: formatter.formatAddress((_e = changelog.newValue) === null || _e === void 0 ? void 0 : _e.address) || notspecified, description: _jsx(Ditto, { componentId: "address" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case ASSET_CHANGELOG_OPERATION.updateNotes:
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: ((_f = changelog.oldValue) === null || _f === void 0 ? void 0 : _f.notes) || notspecified, to: ((_g = changelog.newValue) === null || _g === void 0 ? void 0 : _g.notes) || notspecified, description: _jsx(Ditto, { componentId: "taskdescription" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case ASSET_CHANGELOG_OPERATION.udpateType: {
                const oldId = (_h = changelog.oldValue) === null || _h === void 0 ? void 0 : _h.inventoryTypeId;
                const newId = (_j = changelog.newValue) === null || _j === void 0 ? void 0 : _j.inventoryTypeId;
                const oldType = oldId ? assetTypeMap[oldId] : undefined;
                const newType = newId ? assetTypeMap[newId] : undefined;
                return (_jsx(ChangelogItem, { icon: _jsx(AssetIcon, { iconId: newType === null || newType === void 0 ? void 0 : newType.iconId }), from: oldType === null || oldType === void 0 ? void 0 : oldType.name, to: newType === null || newType === void 0 ? void 0 : newType.name, description: _jsx(Ditto, { componentId: "assets.assettype" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.updateAttribute: {
                const inventoryTypeAttributeId = ((_l = (_k = changelog.oldValue) === null || _k === void 0 ? void 0 : _k.manualInventoryAttribute) === null || _l === void 0 ? void 0 : _l.inventoryTypeAttributeId) ||
                    ((_o = (_m = changelog.newValue) === null || _m === void 0 ? void 0 : _m.manualInventoryAttribute) === null || _o === void 0 ? void 0 : _o.inventoryTypeAttributeId);
                if (!inventoryTypeAttributeId) {
                    return;
                }
                const typeAttribute = typeAttributeMap[inventoryTypeAttributeId];
                if (!typeAttribute) {
                    return;
                }
                const unit = typeAttribute.unitId
                    ? (_p = unitMap[typeAttribute.unitId]) === null || _p === void 0 ? void 0 : _p.abbreviation
                    : undefined;
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: getAttributeValue(typeAttribute, formatter, (_q = changelog.oldValue) === null || _q === void 0 ? void 0 : _q.manualInventoryAttribute, unit) || notspecified, to: getAttributeValue(typeAttribute, formatter, (_r = changelog.newValue) === null || _r === void 0 ? void 0 : _r.manualInventoryAttribute, unit) || notspecified, description: typeAttribute.name, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.addPhotos: {
                const count = ((_t = (_s = changelog.newValue) === null || _s === void 0 ? void 0 : _s.photoIds) === null || _t === void 0 ? void 0 : _t.length) || 0;
                return (_jsx(ChangelogItem, { icon: _jsx(Plus, {}), to: _jsx(Ditto, { componentId: "changelogmarker.changelog.addedximages", count: count, variables: { Amount_Images: count } }), description: _jsx(Ditto, { componentId: "marker.changelog.image" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.removePhotos: {
                const count = ((_v = (_u = changelog.oldValue) === null || _u === void 0 ? void 0 : _u.photoIds) === null || _v === void 0 ? void 0 : _v.length) || 0;
                return (_jsx(ChangelogItem, { icon: _jsx(Minus, {}), to: _jsx(Ditto, { componentId: "changelog.removedximages", count: count, variables: { Amount_Images: count } }), description: _jsx(Ditto, { componentId: "marker.changelog.image" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.linkMarker: {
                const markerId = (_w = changelog.newValue) === null || _w === void 0 ? void 0 : _w.markerId;
                if (!markerId) {
                    return;
                }
                const marker = lookupMarkerMap[markerId];
                if (marker === undefined) {
                    return;
                }
                return (_jsx(ChangelogItem, { icon: _jsx(MarkerPriorityIcon, { value: marker === null || marker === void 0 ? void 0 : marker.priorityTypeId }), to: marker.title || notitle, description: _jsx(Ditto, { componentId: "tasks.tasklinked" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.unlinkMarker: {
                const markerId = (_x = changelog.oldValue) === null || _x === void 0 ? void 0 : _x.markerId;
                if (!markerId) {
                    return;
                }
                const marker = lookupMarkerMap[markerId];
                if (marker === undefined) {
                    return;
                }
                return (_jsx(ChangelogItem, { icon: _jsx(MarkerPriorityIcon, { value: marker.priorityTypeId }), to: marker.title || notitle, description: _jsx(Ditto, { componentId: "tasks.taskunlinked" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.createFile: {
                return (_jsx(ChangelogItem, { icon: _jsx(Attachments, {}), to: (_y = changelog.newValue) === null || _y === void 0 ? void 0 : _y.filename, description: _jsx(Ditto, { componentId: "changelog.fileadded" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, ellipsis: true }, changelog.id));
            }
            case ASSET_CHANGELOG_OPERATION.deleteFile: {
                return (_jsx(ChangelogItem, { icon: _jsx(NoAttachments, {}), to: (_z = changelog.oldValue) === null || _z === void 0 ? void 0 : _z.filename, description: _jsx(Ditto, { componentId: "changelog.fileremoved" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, ellipsis: true }, changelog.id));
            }
        }
    });
    return _jsx(_Fragment, { children: changelogElements });
}
