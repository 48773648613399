import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { NOTIFICATION_CLIENT_DATA_KEY, NOTIFICATION_TYPE_ID } from '../../common/consts/notification';
import { DEFAULT_DATE_SELECT_START, TIME_PICKER_CUSTOM_ID, TIME_PICKER_LATEST_ID, TIME_PICKER_RANGE_FORMAT } from '../../common/consts/timepicker';
import { ACCOUNT_TYPE } from '../../common/consts/user';
import { getMarkerCluster, getTextByOptionId, MarkerPriorityIcon } from '../../common/convert';
import { Icon } from '../../common/icon';
import { LAYERS_PRESETS } from '../../common/layer';
import { uniqueV2 } from '../../common/utils/array';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { BetaBadge } from '../../components/BetaBadge/BetaBadge';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { DemoBadge } from '../../components/DemoBadge/DemoBadge';
import { DropMenu } from '../../components/DropMenu/DropMenu';
import { GradeBadge } from '../../components/GradeBadge/GradeBadge';
import { ShieldCheck } from '../../components/icons';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { MarkerCluster } from '../../components/MarkerCluster/MarkerCluster';
import { MarkerPreview } from '../../components/NotificationOverlay/MarkerPreview/MarkerPreview';
import { NotificationItem } from '../../components/NotificationOverlay/NotificationItem/NotificationItem';
import { NotificationOverlay } from '../../components/NotificationOverlay/NotificationOverlay';
import { Pill } from '../../components/Pill/Pill';
import { PresetsPanel } from '../../components/PresetsPanel/PresetsPanel';
import { Timepicker } from '../../components/PresetsPanel/Timepicker/Timepicker';
import { TabButton } from '../../components/TabButton/TabButton';
import { TimePickerOverlay } from '../../components/TimePickerOverlay/TimePickerOverlay';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearSelectedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setMapLayers, setSelectedLayerId } from '../../state/slices/layer';
import { setAssetActiveTabKey } from '../../state/slices/leftPanelAsset';
import { setConditionActiveTabKey } from '../../state/slices/leftPanelCondition';
import { resetMarkerFilter, setMarkerActiveTabKey } from '../../state/slices/leftPanelMarker';
import { resetPlanFilter, setPlanActiveTabKey } from '../../state/slices/leftPanelPlan';
import { addNotificationMessage, batchMarkAsReadTk, fetchNotificationMarkers, fetchNotificationUserProfiles, fetchUnreadNotificationCount, fetchUserNotificationsTk, markAllAsReadTk } from '../../state/slices/notification';
import { fetchTimepickerDistance, fetchTimepickerGrade, fetchTimepickerMarkerCount, fetchTimepickerOption, setSelectedGrade, setSelectedGradePaser, setSelectedGradePCI, setSelectedKm, setSelectedMarker, setSelectedTimeOption } from '../../state/slices/presetsPanel';
import { Avatar } from '../../uikit/Avatar/Avatar';
import { DateTimeFormatContainer } from '../Formatters/DateTimeFormatContainer/DateTimeFormatContainer';
import { UnitFormatContainer } from '../Formatters/UnitFormatContainer/UnitFormatContainer';
export function PresetsPanelContainer() {
    const dispatch = useAppDispatch();
    const { notificationList, markerMap, userMap, unreadNotificationCount } = useAppSelector((state) => state.notification);
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { markerCategoryMap } = useAppSelector((state) => state.shared);
    const { layerList } = useAppSelector((state) => state.layer);
    const { resultList } = useAppSelector((state) => state.leftPanelPlan);
    const { timepicker: { options, selectedOption, selectedGrade, selectedKm, selectedMarkers } } = useAppSelector((state) => state.presetsPanel);
    const { isConditionVisible, isMarkerVisible, isSignInventoryVisible } = useLayerChecker();
    const formatter = useFormatter();
    const { addModal } = useModalContext();
    const { isEditor, isAdmin } = useUserRole();
    const [isTimepickerOpen, setIsTimepickerOpen] = useState(false);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [showReadNotifications, setShowReadNotifications] = useState(false);
    const latestOption = options.find((item) => item.id === TIME_PICKER_LATEST_ID);
    const showGradeBadge = isConditionVisible || (!isConditionVisible && !isMarkerVisible);
    const showGradePCIBadge = showGradeBadge && (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci);
    const showGradePaserBadge = showGradeBadge && (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser);
    const unreadNotificationList = notificationList.filter((i) => !i.markedAsRead);
    const notificationListToShow = showReadNotifications
        ? notificationList
        : unreadNotificationList;
    const readNotificationExists = notificationList.length > unreadNotificationCount;
    function handleCustomDateClick() {
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                rangeSelector: true,
                showDateInputs: true,
                startDate: format(selectedOption.id !== TIME_PICKER_LATEST_ID
                    ? selectedOption.from
                    : DEFAULT_DATE_SELECT_START, 'yyyy-MM-dd'),
                endDate: format(selectedOption.id !== TIME_PICKER_LATEST_ID ? selectedOption.to : new Date(), 'yyyy-MM-dd'),
                onConfirm: (value) => {
                    if (!(value === null || value === void 0 ? void 0 : value.startDate) || !value.untilDate) {
                        handleClickTimepickerOption(TIME_PICKER_LATEST_ID);
                        return;
                    }
                    handleConfirmCustomTimeframe(value.startDate, value.untilDate);
                }
            }
        });
    }
    function handleClickPreset(presetId) {
        mixpanel.track(MIXPANEL_EVENT_NAME.selectPreset, {
            [MIXPANEL_EVENT_PROPERTY.presetName]: presetId
        });
        switch (presetId) {
            case 'condition':
                dispatch(setMapLayers(layerList.map((item) => (Object.assign(Object.assign({}, item), { isEnabled: LAYERS_PRESETS[presetId].includes(item.id) })))));
                dispatch(setSelectedLayerId('condition'));
                dispatch(setConditionActiveTabKey('filter'));
                break;
            case 'plan':
                dispatch(setMapLayers(layerList.map((item) => (Object.assign(Object.assign({}, item), { isEnabled: LAYERS_PRESETS[presetId].includes(item.id) })))));
                dispatch(setSelectedLayerId('plan'));
                if (resultList !== null) {
                    dispatch(setPlanActiveTabKey('table'));
                }
                break;
            case 'marker':
                dispatch(setMapLayers(layerList.map((item) => (Object.assign(Object.assign({}, item), { isEnabled: LAYERS_PRESETS[presetId].includes(item.id) })))));
                dispatch(setSelectedLayerId('marker'));
                dispatch(setMarkerActiveTabKey('list'));
                break;
            case 'asset':
                dispatch(setMapLayers(layerList.map((item) => (Object.assign(Object.assign({}, item), { isEnabled: LAYERS_PRESETS[presetId].includes(item.id) })))));
                dispatch(setSelectedLayerId('asset'));
                dispatch(setAssetActiveTabKey('list'));
                break;
        }
        dispatch(resetMarkerFilter());
        dispatch(resetPlanFilter());
        dispatch(clearSelectedFeature());
        dispatch(addNotificationMessage({
            ditto: { title: { componentId: 'toast.appliedlayerandfilterpreset' } },
            type: 'success',
            closeAfterSeconds: 5,
            action: {
                type: 'link',
                label: { componentId: 'undo' },
                content: window.location.href
            }
        }));
    }
    function handleClickTimepickerOption(id) {
        setIsTimepickerOpen(false);
        const clickedOption = options.find((item) => item.id === id);
        if (clickedOption) {
            dispatch(setSelectedTimeOption({
                id: clickedOption.id,
                from: clickedOption.from,
                to: clickedOption.to
            }));
            dispatch(setSelectedGrade(clickedOption.grade));
            dispatch(setSelectedGradePCI(clickedOption.gradePCI));
            dispatch(setSelectedGradePaser(clickedOption.gradePaser));
            dispatch(setSelectedKm(clickedOption.kilometers));
            dispatch(setSelectedMarker(clickedOption.markers));
        }
    }
    function handleConfirmCustomTimeframe(from, to) {
        setIsTimepickerOpen(false);
        dispatch(setSelectedTimeOption({
            id: TIME_PICKER_CUSTOM_ID,
            from: moment(from).format(TIME_PICKER_RANGE_FORMAT),
            to: moment(to).format(TIME_PICKER_RANGE_FORMAT)
        }));
    }
    function handleNotificationReadAllClick() {
        dispatch(markAllAsReadTk());
    }
    function handleShowReadNotificationClick() {
        setShowReadNotifications(true);
    }
    function handleNotificationDropControlClick(isOpen) {
        setIsNotificationsOpen(isOpen);
        if (isOpen) {
            dispatch(fetchUserNotificationsTk());
            dispatch(fetchUnreadNotificationCount());
        }
    }
    function renderNotificationItem(n) {
        const fromUser = userMap[n.clientData[NOTIFICATION_CLIENT_DATA_KEY.fromUserUuid]];
        const fromUserFullname = fromUser ? (`${fromUser === null || fromUser === void 0 ? void 0 : fromUser.firstName} ${fromUser === null || fromUser === void 0 ? void 0 : fromUser.lastName}`) : (_jsx(Ditto, { componentId: "notifications.someone" }));
        const marker = markerMap[n.clientData[NOTIFICATION_CLIENT_DATA_KEY.markerId]];
        const category = (marker === null || marker === void 0 ? void 0 : marker.categoryId) ? markerCategoryMap[marker.categoryId] : undefined;
        return (_jsx(NotificationItem, { isRead: n.markedAsRead, title: (function () {
                switch (n.typeId) {
                    case NOTIFICATION_TYPE_ID.statusClosed:
                        return (_jsxs(_Fragment, { children: [_jsxs("strong", { children: [fromUserFullname, " "] }), _jsx(Ditto, { componentId: "notifications.johndoesetyourtasktodone" })] }));
                    case NOTIFICATION_TYPE_ID.dueDateOverdue:
                        return _jsx(Ditto, { componentId: "notifications.taskoverdue" });
                    case NOTIFICATION_TYPE_ID.assigneeChanged:
                        return (_jsxs(_Fragment, { children: [_jsxs("strong", { children: [fromUserFullname, " "] }), _jsx(Ditto, { componentId: "notifications.johndoeassignedyouthistask" })] }));
                }
            })(), type: _jsx(Ditto, { componentId: "tasklabel" }), time: formatter.formatDate(n.createdAt).dateTime, avatar: fromUser ? (_jsx(Avatar, { firstName: fromUser.firstName, lastName: fromUser.lastName, src: fromUser.profileImageUrl, size: 24 })) : undefined, content: marker ? (_jsx(MarkerPreview, { icon: _jsx(MarkerPriorityIcon, { value: marker.priorityTypeId }), title: marker.title, subtitle: formatter.formatAddress(marker.addressJson), badge: category ? (_jsx(CategoryBadge, { category: category.name, backgroundColor: category.color, variant: "list" })) : undefined })) : undefined, onClick: () => {
                if (marker) {
                    dispatch(setSelectedFeature({
                        type: 'marker',
                        id: marker.id
                    }));
                }
                setIsNotificationsOpen(false);
                dispatch(batchMarkAsReadTk([n.id]));
            } }, n.id));
    }
    useEffect(() => {
        const markerIds = uniqueV2(notificationList
            .map((i) => i.clientData[NOTIFICATION_CLIENT_DATA_KEY.markerId])
            .filter((i) => i));
        if (markerIds.length > 0) {
            dispatch(fetchNotificationMarkers(markerIds));
        }
        const userUUIDs = uniqueV2(notificationList
            .map((i) => i.clientData[NOTIFICATION_CLIENT_DATA_KEY.fromUserUuid])
            .filter((i) => i));
        if (userUUIDs.length > 0) {
            dispatch(fetchNotificationUserProfiles(userUUIDs));
        }
    }, [dispatch, notificationList]);
    useEffect(() => {
        dispatch(fetchUnreadNotificationCount());
        dispatch(fetchTimepickerOption());
    }, [dispatch]);
    useEffect(() => {
        if (selectedOption.id === TIME_PICKER_CUSTOM_ID) {
            dispatch(fetchTimepickerGrade());
            dispatch(fetchTimepickerDistance());
            dispatch(fetchTimepickerMarkerCount());
        }
    }, [dispatch, selectedOption.id]);
    return (_jsxs(PresetsPanel, Object.assign({ banner: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.accountTypeId) === ACCOUNT_TYPE.test ? (_jsx(DemoBadge, { icon: _jsx(ShieldCheck, {}), text: _jsx(Ditto, { componentId: "demoenvironment" }) })) : undefined, timePicker: _jsx(DropMenu, { direction: "down-left", control: selectedOption.id === TIME_PICKER_LATEST_ID ? (_jsx(Timepicker, { variant: "default", boldText: _jsx("span", { children: _jsx(Ditto, { componentId: "timepicker.latest" }) }), normalText: _jsx(UnitFormatContainer, { type: "largeLength", value: (latestOption === null || latestOption === void 0 ? void 0 : latestOption.kilometers) || 0 }), trailingElement: _jsxs(_Fragment, { children: [isMarkerVisible && (_jsx(MarkerCluster, { items: getMarkerCluster(latestOption === null || latestOption === void 0 ? void 0 : latestOption.markers) })), showGradePCIBadge && (_jsx(GradeBadge, { type: "pci", grade: latestOption === null || latestOption === void 0 ? void 0 : latestOption.gradePCI, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.pci" }), ':'] }), suffix: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), color: "dark-blue" }), tooltip: _jsx(Ditto, { componentId: "grading.pcibeta" }) })), showGradePaserBadge && (_jsx(GradeBadge, { type: "paser", grade: latestOption === null || latestOption === void 0 ? void 0 : latestOption.gradePaser, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.paser" }), ':'] }), suffix: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), color: "dark-blue" }), tooltip: _jsx(Ditto, { componentId: "grading.paserbeta" }) })), showGradeBadge &&
                            !(showGradePCIBadge || showGradePaserBadge) && (_jsx(GradeBadge, { type: "emi", grade: latestOption === null || latestOption === void 0 ? void 0 : latestOption.grade, prefix: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US' ? (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.emi" }), ':'] })) : undefined }))] }) })) : (_jsx(Timepicker, { variant: "applied", boldText: _jsxs(_Fragment, { children: [_jsx(DateTimeFormatContainer, { type: "date", timestamp: selectedOption.from }), _jsx("span", { children: ' - ' }), _jsx(DateTimeFormatContainer, { type: "date", timestamp: selectedOption.to })] }), normalText: _jsx(UnitFormatContainer, { type: "largeLength", value: selectedKm }), trailingElement: _jsxs(_Fragment, { children: [isMarkerVisible && (_jsx(MarkerCluster, { items: getMarkerCluster(selectedMarkers) })), showGradePCIBadge && (_jsx(GradeBadge, { type: "pci", grade: selectedGrade === null || selectedGrade === void 0 ? void 0 : selectedGrade.gradePci, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.pci" }), ':'] }), suffix: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), color: "dark-blue" }), tooltip: _jsx(Ditto, { componentId: "grading.pcibeta" }) })), showGradePaserBadge && (_jsx(GradeBadge, { type: "paser", grade: selectedGrade === null || selectedGrade === void 0 ? void 0 : selectedGrade.gradePaser, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.paser" }), ':'] }), suffix: _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), color: "dark-blue" }), tooltip: _jsx(Ditto, { componentId: "grading.paserbeta" }) })), showGradeBadge &&
                            !(showGradePCIBadge || showGradePaserBadge) && (_jsx(GradeBadge, { type: "emi", grade: selectedGrade === null || selectedGrade === void 0 ? void 0 : selectedGrade.grade, prefix: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US' ? (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.emi" }), ':'] })) : undefined }))] }), onResetClick: () => handleClickTimepickerOption(TIME_PICKER_LATEST_ID) })), menu: _jsx(TimePickerOverlay, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, formatter: formatter, onCustomTimeFrameClick: handleCustomDateClick, onOptionClick: handleClickTimepickerOption, latestOption: {
                    id: TIME_PICKER_LATEST_ID,
                    title: _jsx(Ditto, { componentId: "timepicker.latest" }),
                    formattedLength: (_jsx(UnitFormatContainer, { type: "largeLength", value: (latestOption === null || latestOption === void 0 ? void 0 : latestOption.kilometers) || 0 })),
                    grade: showGradeBadge && !(showGradePCIBadge || showGradePaserBadge)
                        ? (latestOption === null || latestOption === void 0 ? void 0 : latestOption.grade) || 0
                        : undefined,
                    gradePCI: showGradePCIBadge ? latestOption === null || latestOption === void 0 ? void 0 : latestOption.gradePCI : undefined,
                    gradePaser: showGradePaserBadge
                        ? latestOption === null || latestOption === void 0 ? void 0 : latestOption.gradePaser
                        : undefined,
                    markerCluster: isMarkerVisible
                        ? getMarkerCluster(latestOption === null || latestOption === void 0 ? void 0 : latestOption.markers)
                        : undefined
                }, optionsHeader: isSignInventoryVisible && (_jsx(InfoBox, { content: _jsx(Ditto, { componentId: "timepicker.infotrafficsignlayernotsupported." }), width: "100%" })), options: options
                    .filter((item) => item.id !== TIME_PICKER_LATEST_ID)
                    .map((item) => {
                    return {
                        id: item.id,
                        formattedLength: (_jsx(UnitFormatContainer, { type: "largeLength", value: item.kilometers || 0 })),
                        grade: showGradeBadge &&
                            !(showGradePCIBadge || showGradePaserBadge)
                            ? item.grade
                            : undefined,
                        gradePCI: showGradePCIBadge ? item.gradePCI : undefined,
                        gradePaser: showGradePaserBadge
                            ? item.gradePaser
                            : undefined,
                        title: getTextByOptionId(item.id),
                        markerCluster: isMarkerVisible
                            ? getMarkerCluster(item.markers)
                            : undefined
                    };
                }), selectedOption: selectedOption }), isOpen: isTimepickerOpen, setIsOpen: (isOpen) => setIsTimepickerOpen(isOpen) }), notification: _jsx(DropMenu, { isOpen: isNotificationsOpen, setIsOpen: handleNotificationDropControlClick, direction: "down-left", control: _jsx(ActionButton, { size: "large", icon: _jsx(Icon, { name: "Log" }), badge: unreadNotificationCount ? (_jsx(Pill, { number: unreadNotificationCount, size: "small", color: "red" })) : undefined, isHover: isNotificationsOpen }), menu: _jsx(NotificationOverlay, Object.assign({ hasReadNotifications: readNotificationExists, hasUnReadNotifications: unreadNotificationCount > 0, isEmpty: notificationListToShow.length === 0, showReadNotifications: showReadNotifications, onReadAllClick: handleNotificationReadAllClick, onShowReadClick: handleShowReadNotificationClick }, { children: notificationListToShow.map((n) => renderNotificationItem(n)) })) }) }, { children: [_jsx(TabButton, { text: _jsx(Ditto, { componentId: "presetspanel.overview" }), onClick: () => handleClickPreset('condition'), "data-test": "btn-preset-overview" }), isAdmin && (_jsx(TabButton, { text: _jsx(Ditto, { componentId: "presetspanel.planningtable" }), onClick: () => handleClickPreset('plan'), "data-test": "btn-preset-planning-table" })), isEditor && (_jsx(TabButton, { text: _jsx(Ditto, { componentId: "presetspanel.markerlist" }), onClick: () => handleClickPreset('marker'), "data-test": "btn-preset-tasks" })), _jsx(TabButton, { text: _jsx(Ditto, { componentId: "presetspanel.inventory" }), onClick: () => handleClickPreset('asset'), "data-test": "btn-preset-inventory", label: _jsx(Ditto, { componentId: "betalabel" }) })] })));
}
