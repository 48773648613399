import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { classes } from 'classifizer';
import { endOfDay, endOfMonth, format, isBefore, isValid, lastDayOfMonth, parse } from 'date-fns';
import { getDueDatesForCalendarView, isForever, MIN_YEAR, parseRecurringFormula } from '../../common/recurringRules';
import { getCalendarViewEndDate, getCalendarViewStartDate } from '../../common/recurringRules/utils';
import { getDateFnsLocale } from '../../common/utils/locale';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { CustomDayWrapper } from '../DatePickerContent/CustomDay';
import { FormInputText } from '../FormInputText/FormInputText';
import { TypedDitto } from '../TypedDitto';
import { CustomHeader } from './CustomHeader';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './MonthPickerContent.module.css';
export const MonthPickerContent = forwardRef(({ onConfirm, locale: localeSupported, countryCode, startMonth, startYear, rangeSelector = false, endMonth, endYear, month, year, setInputValue, setIsOpen }, ref) => {
    const dateFormat = 'MMMM YYYY';
    const dateFormatNumber = 'M.YYYY';
    const locale = getDateFnsLocale(localeSupported, countryCode);
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    startMonth = month || startMonth;
    startYear = year || startYear;
    // NOTE: react-datepicker uses date-fns library internally, formatting symbols different than momentjs
    const datePickerFormat = dateFormat.replaceAll('Y', 'y').replaceAll('D', 'd');
    const datePickerFormatNumber = dateFormatNumber.replaceAll('Y', 'y').replaceAll('D', 'd');
    const [isChoosingStartDate, setIsChoosingStartDate] = useState(true);
    const [formula, setFormula] = useState(getFormula());
    const [startDateStrValue, setStartDateStrValue] = useState((formula === null || formula === void 0 ? void 0 : formula.startDate) ? format(formula.startDate, datePickerFormat, { locale }) : '');
    const [startDateError, setStartDateError] = useState('');
    const [untilDateError, setUntilDateError] = useState('');
    const [calendarViewMonthDate, setCalendarViewMonthDate] = useState(null);
    const setCalendarViewRangeDates = useCallback((formula, date) => {
        const monthViewStart = getCalendarViewStartDate(date, locale);
        const monthViewEnd = getCalendarViewEndDate(date, locale);
        setRangeDates(getDueDatesForCalendarView(formula, monthViewStart, monthViewEnd));
    }, [locale]);
    const noUntilDateLabel = useDittoWrapper({
        componentId: 'timepicker.customtimeframe.enddate.never'
    });
    let untilDateInitialValue = '';
    useImperativeHandle(ref, () => ({
        submit: handleConfirmButtonClick
    }));
    useEffect(() => {
        setCalendarViewRangeDates(formula, calendarViewMonthDate !== null && calendarViewMonthDate !== void 0 ? calendarViewMonthDate : undefined);
    }, [calendarViewMonthDate, formula, setCalendarViewRangeDates]);
    if (formula) {
        if (formula.untilDate && !isForever(formula.untilDate)) {
            untilDateInitialValue = format(formula.untilDate, datePickerFormat, { locale });
        }
        else {
            untilDateInitialValue = noUntilDateLabel;
        }
    }
    const [untilDateStrValue, setUntilDateStrValue] = useState(untilDateInitialValue);
    const [rangeDates, setRangeDates] = useState(getDueDatesForCalendarView(formula, getCalendarViewStartDate(calendarViewMonthDate ? calendarViewMonthDate : formula === null || formula === void 0 ? void 0 : formula.startDate, locale), getCalendarViewEndDate(calendarViewMonthDate ? calendarViewMonthDate : formula === null || formula === void 0 ? void 0 : formula.startDate, locale)));
    const startDateLabel = useDittoWrapper({
        componentId: 'timepicker.customtimeframe.startmonth'
    });
    const endDateLabel = useDittoWrapper({
        componentId: 'timepicker.customtimeframe.endmonth'
    });
    const invalidDateFormatError = useDittoWrapper({ componentId: 'invalidformat' });
    const endDateError = useDittoWrapper({ componentId: 'timepicker.enddatecantbeinthepast' });
    useEffect(() => {
        if (setInputValue) {
            setInputValue(rangeSelector
                ? `${startDateStrValue}${untilDateStrValue ? ` - ${untilDateStrValue}` : ''}`
                : startDateStrValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeSelector, setInputValue]);
    function getFormula() {
        let formula = null;
        if (startMonth && startYear) {
            formula = `DTSTART:${startYear}${startMonth.toString().padStart(2, '0')}01T000000Z
            RRULE:FREQ=MONTHLY`;
            if (rangeSelector && endMonth && endYear) {
                const lastDay = format(lastDayOfMonth(new Date(endYear, endMonth - 1)), 'dd');
                formula += `;UNTIL=${endYear}${endMonth
                    .toString()
                    .padStart(2, '0')}${lastDay}T000000Z;INTERVAL=1;WKST=MO`;
            }
        }
        return (formula && parseRecurringFormula(formula)) || null;
    }
    function handleCalendarMonthChange(date) {
        setCalendarViewMonthDate(date);
    }
    function handleMonthSelect(date) {
        setCalendarViewMonthDate(date);
        if (rangeSelector) {
            if (isChoosingStartDate) {
                handleStartDateCalendarChange(date);
                handleUntilDateCalendarChange(null);
                setIsChoosingStartDate(false);
            }
            else {
                if (date && (formula === null || formula === void 0 ? void 0 : formula.startDate) && isBefore(date, formula === null || formula === void 0 ? void 0 : formula.startDate)) {
                    handleStartDateCalendarChange(date);
                }
                else {
                    handleUntilDateCalendarChange(date ? endOfMonth(date) : null);
                    setIsChoosingStartDate(true);
                }
            }
        }
        else {
            handleStartDateCalendarChange(date);
        }
    }
    function handleStartDateClick() {
        setIsChoosingStartDate(true);
    }
    function handleUntilDateClick() {
        setIsChoosingStartDate(false);
    }
    function handleStartDateCalendarChange(date) {
        if (!date) {
            setStartDateStrValue('');
            setFormula(null);
            return;
        }
        if (formula) {
            setFormula(Object.assign(Object.assign({}, formula), { startDate: date, untilDate: undefined }));
            setUntilDateStrValue('');
        }
        else {
            setFormula({
                frequency: {
                    repeat: 'never'
                },
                startDate: date
            });
            setStartDateError('');
        }
        setStartDateStrValue(format(date, datePickerFormat, { locale }));
        if (rangeSelector) {
            setIsChoosingStartDate(false);
        }
    }
    function handleUntilDateCalendarChange(date) {
        if (!date) {
            setUntilDateStrValue('');
            return;
        }
        if (formula) {
            setFormula(Object.assign(Object.assign({}, formula), { untilDate: date }));
            if (isBefore(date, formula.startDate)) {
                setUntilDateError(endDateError);
            }
            else {
                setUntilDateError('');
            }
        }
        else {
            setFormula({
                frequency: {
                    repeat: 'never'
                },
                startDate: date,
                untilDate: date
            });
            setUntilDateError('');
        }
        setUntilDateStrValue(format(date, datePickerFormat, { locale }));
        setIsChoosingStartDate(true);
    }
    function handleStartDateFieldChange(value) {
        if (!value) {
            setStartDateStrValue('');
            setStartDateError('');
            setFormula(null);
            return;
        }
        let newStartDate = parse(value, datePickerFormat, new Date(), {
            locale
        });
        if (!isValid(newStartDate)) {
            newStartDate = parse(value, datePickerFormatNumber, new Date(), { locale });
        }
        if (isValid(newStartDate) && newStartDate.getFullYear() > MIN_YEAR) {
            if ((formula === null || formula === void 0 ? void 0 : formula.untilDate) && isBefore(formula.untilDate, newStartDate)) {
                setStartDateStrValue(value);
                setStartDateError(endDateError);
                return;
            }
            setStartDateStrValue(value);
            handleStartDateCalendarChange(newStartDate);
            setStartDateError('');
            return;
        }
        else {
            setStartDateError(invalidDateFormatError);
        }
        setStartDateStrValue(value);
    }
    function handleUntilDateFieldChange(value) {
        if (!formula) {
            setUntilDateError('');
            return;
        }
        if (!value) {
            setUntilDateStrValue('');
            setUntilDateError('');
            setFormula(Object.assign(Object.assign({}, formula), { untilDate: undefined }));
            return;
        }
        let newUntilDate = parse(value, datePickerFormat, new Date(), {
            locale
        });
        if (!isValid(newUntilDate)) {
            newUntilDate = parse(value, datePickerFormatNumber, new Date(), { locale });
        }
        if (isValid(newUntilDate) && newUntilDate.getFullYear() > MIN_YEAR) {
            const newUntilDateWithEndOfDay = endOfDay(newUntilDate);
            if (isBefore(newUntilDateWithEndOfDay, formula.startDate)) {
                setUntilDateStrValue(value);
                setUntilDateError(endDateError);
                return;
            }
            setUntilDateStrValue(value);
            handleUntilDateCalendarChange(newUntilDateWithEndOfDay);
            setUntilDateError('');
            return;
        }
        else {
            setUntilDateError(invalidDateFormatError);
        }
        setUntilDateStrValue(value);
    }
    function handleClearButtonClick() {
        if (setIsOpen) {
            setIsOpen(false);
        }
        onConfirm(null);
    }
    function handleConfirmButtonClick() {
        if (setIsOpen) {
            setIsOpen(false);
        }
        if (formula) {
            onConfirm(formula);
            return;
        }
        onConfirm(null);
    }
    const singleDate = (_jsx("div", Object.assign({ className: styles['datepicker-container'] }, { children: _jsx(CustomDatePicker, { showMonthYearPicker: true, calendarClassName: styles['task-datepicker'], startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, selected: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: formula === null || formula === void 0 ? void 0 : formula.startDate, onChange: handleMonthSelect, locale: locale, dateFormat: datePickerFormat, renderCustomHeader: (params) => _jsx(CustomHeader, Object.assign({ locale: locale }, params)), renderDayContents: (dayOfMonth, date) => (_jsx(CustomDayWrapper, { dayOfMonth: dayOfMonth, date: date, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: (formula === null || formula === void 0 ? void 0 : formula.untilDate) && isForever(formula.untilDate)
                    ? undefined
                    : formula === null || formula === void 0 ? void 0 : formula.untilDate, selectedDate: formula === null || formula === void 0 ? void 0 : formula.startDate, highlightedDates: rangeDates })), inline: true, disabledKeyboardNavigation: true, onMonthChange: handleCalendarMonthChange }) })));
    const dateRange = (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles['content'] }, { children: [_jsx("span", Object.assign({ className: styles['content-half'] }, { children: _jsx(FormInputText, { className: classes(styles['date-input'], !startDateStrValue && styles['date-input--empty']), label: startDateLabel, value: startDateStrValue, placeholder: notSpecifiedLabel, error: startDateError, onValueChange: handleStartDateFieldChange, onClick: handleStartDateClick, variant: "editor-field" }) })), _jsx("span", Object.assign({ className: styles['content-half'] }, { children: _jsx(FormInputText, { className: classes(styles['date-input'], !untilDateStrValue && styles['date-input--empty']), label: endDateLabel, value: untilDateStrValue, placeholder: notSpecifiedLabel, onValueChange: handleUntilDateFieldChange, error: untilDateError, disabled: !startDateStrValue, onClick: handleUntilDateClick, variant: "editor-field" }) }))] })), _jsx("div", Object.assign({ className: styles['datepicker-container'] }, { children: _jsx(CustomDatePicker, { showMonthYearPicker: true, calendarClassName: styles['task-datepicker'], selected: formula === null || formula === void 0 ? void 0 : formula.startDate, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: formula === null || formula === void 0 ? void 0 : formula.untilDate, onChange: handleMonthSelect, locale: locale, dateFormat: datePickerFormat, renderCustomHeader: (params) => (_jsx(CustomHeader, Object.assign({ locale: locale }, params))), renderDayContents: (dayOfMonth, date) => (_jsx(CustomDayWrapper, { dayOfMonth: dayOfMonth, date: date, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: (formula === null || formula === void 0 ? void 0 : formula.untilDate) && isForever(formula.untilDate)
                            ? undefined
                            : formula === null || formula === void 0 ? void 0 : formula.untilDate, selectedDate: formula === null || formula === void 0 ? void 0 : formula.startDate, highlightedDates: rangeDates })), inline: true, disabledKeyboardNavigation: true, onMonthChange: handleCalendarMonthChange }) }))] }));
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [!rangeSelector ? singleDate : dateRange, _jsxs("div", Object.assign({ className: classes(styles['content'], styles['footer']) }, { children: [_jsx(Button, Object.assign({ color: "gray", onClick: handleClearButtonClick }, { children: _jsx(TypedDitto, { componentId: "timepicker.clear" }) })), _jsx(Button, Object.assign({ color: "dark", disabled: !!startDateError || !!untilDateError, onClick: handleConfirmButtonClick }, { children: _jsx(TypedDitto, { componentId: "modal.confirm" }) }))] }))] })));
});
MonthPickerContent.displayName = 'MonthPickerContent';
