import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { getTitles, TITLE } from '../../../common/consts/user';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Avatar } from '../../../uikit/Avatar/Avatar';
import { Button } from '../../../uikit/Button/Button';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../FormInputSelect/FormInputSelect';
import { FormInputText } from '../../FormInputText/FormInputText';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { UserDepartmentSelect } from '../../UserDepartmentSelect/UserDepartmentSelect';
import styles from './UserDataForm.module.css';
export function UserDataForm({ currentLocale, departmentList, languageList, user, values: { firstName, lastName, titleId, departmentId, image, email, phoneNumber, locale } = {
    firstName: '',
    lastName: '',
    email: '',
    titleId: null,
    departmentId: null,
    locale: undefined
}, hideAvatar, hideLanguage, onClickUpload, onChange }) {
    const firstNameLabel = useDittoWrapper({ componentId: 'register.firstname' });
    const lastNameLabel = useDittoWrapper({ componentId: 'register.lastname' });
    const wikiUrl = useDittoWrapper({ componentId: 'wikilink.changedeleteemail' });
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['email'], "data-test": "email-value" }, { children: [email || phoneNumber, _jsx(InfoTooltip, { tooltip: _jsx("a", Object.assign({ href: wikiUrl, target: "_blank", rel: "noreferrer" }, { children: _jsx(Ditto, { componentId: "infobutton.useraccount" }) })) })] })), !hideAvatar && (_jsxs("div", Object.assign({ className: styles['input-upload'] }, { children: [_jsx(Avatar, { firstName: firstName, lastName: lastName, src: image || (user === null || user === void 0 ? void 0 : user.profileImageUrl) }), _jsx(Button, Object.assign({ color: "gray", onClick: onClickUpload }, { children: _jsx(Ditto, { componentId: "uploadprofilpic" }) }))] }))), _jsxs("div", Object.assign({ className: styles['input-group'] }, { children: [_jsx(FormInputText, { required: true, label: firstNameLabel, value: firstName, onValueChange: (value) => onChange('firstName', value), width: "100%", "data-test": "first-name" }), _jsx(FormInputText, { required: true, label: lastNameLabel, value: lastName, onValueChange: (value) => onChange('lastName', value), width: "100%", "data-test": "last-name" }), _jsx(FormInputSelect, { required: true, label: _jsx(Ditto, { componentId: "register.title" }), menuItems: getTitles()
                            .filter((title) => currentLocale === 'fr' && title[0] === 'mx' ? false : true)
                            .map((title) => (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: `register.${title[0]}` }), onClick: () => onChange && onChange('titleId', title[1]), "data-test": `title-${Number(title[1])}` }, `title-${title[0]}`))), menuWidth: 300, menuMaxHeight: 320, value: titleId ? (_jsx(FormInputCurrentSelectedValue, { text: _jsx(Ditto, { componentId: `register.${TITLE[titleId]}` }) || '' })) : undefined, width: "100%", canFloat: true, "data-test": "title-input" }), _jsx(UserDepartmentSelect, { required: true, list: departmentList, value: departmentId, onChange: (value) => onChange && onChange('departmentId', value) }), !hideLanguage && locale && languageList && (_jsx("div", Object.assign({ className: styles['input-group--full'], "data-test": "language-list" }, { children: _jsx(FormInputSelect, { label: _jsx(Ditto, { componentId: "useraccount.language" }), value: languageList.current, menuItems: languageList.items, canFloat: true }) })))] }))] })));
}
