import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { isFrequencyWithDays } from './types';
export function getCalendarViewStartDate(date, locale) {
    return startOfDay(startOfWeek(startOfMonth(date !== null && date !== void 0 ? date : new Date()), { locale }));
}
export function getCalendarViewEndDate(date, locale) {
    return endOfDay(endOfWeek(endOfMonth(date !== null && date !== void 0 ? date : new Date()), { locale }));
}
export function getFrequencyDays(frequency) {
    if (frequency.repeat === 'custom') {
        if (isFrequencyWithDays(frequency.value)) {
            return frequency.value.days;
        }
    }
    else {
        if (isFrequencyWithDays(frequency)) {
            return frequency.days;
        }
    }
}
export function getFrequencyRepeat(frequency) {
    if (frequency.repeat === 'custom') {
        return frequency.value;
    }
    else {
        return frequency;
    }
}
