import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { NOTIFICATION_CLOSE_DURATION_SECONDS } from '../../common/consts/config';
import { abortControllers } from '../../common/fetch';
import { Loader } from '../../components/Loader/Loader';
import { Toast } from '../../components/Toast/Toast';
import { cancelablePromises } from '../../openapi';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { removeNotificationMessage } from '../../state/slices/notification';
export function NotificationContainer() {
    const dispatch = useAppDispatch();
    const { messages } = useAppSelector((state) => state.notification);
    function closeMessage(id) {
        dispatch(removeNotificationMessage(id));
    }
    function handleClickAction(action) {
        if (!action) {
            return;
        }
        switch (action.type) {
            case 'link':
                window.location.href = action.content;
                break;
            case 'dispatch':
                action.content.forEach((act) => dispatch(act));
                break;
            case 'fetch-abort':
                if (abortControllers[action.signalId])
                    abortControllers[action.signalId].abort();
                else if (typeof cancelablePromises[action.signalId] !== 'undefined')
                    cancelablePromises[action.signalId].cancel();
                break;
        }
    }
    return (_jsx(_Fragment, { children: messages.map((m) => (_jsx(Toast, { color: m.type === 'error' ? 'red' : 'dark', title: (function () {
                var _a;
                if (m.title) {
                    return m.title;
                }
                if ((_a = m.ditto) === null || _a === void 0 ? void 0 : _a.title) {
                    return _jsx(Ditto, Object.assign({}, m.ditto.title));
                }
                return _jsx(Ditto, { componentId: "toast.somethingwentwrong" });
            })(), subtitle: (function () {
                var _a;
                if (m.description) {
                    return m.description;
                }
                if ((_a = m.ditto) === null || _a === void 0 ? void 0 : _a.description) {
                    return _jsx(Ditto, Object.assign({}, m.ditto.description));
                }
                return undefined;
            })(), innerContent: m.type === 'loading' && _jsx(Loader, { color: "white" }), closeAfterSecond: m.closeAfterSeconds ||
                (m.type === 'success' ? NOTIFICATION_CLOSE_DURATION_SECONDS : undefined), onClose: () => closeMessage(m.id), button: m.action !== undefined
                ? {
                    onClick: () => handleClickAction(m.action),
                    buttonText: _jsx(Ditto, Object.assign({}, m.action.label)),
                    buttonColor: m.action.color
                }
                : undefined }, m.id))) }));
}
