// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ikfoy5wLeO6bpP9_hfhu{width:100%;border-collapse:collapse}.ikfoy5wLeO6bpP9_hfhu thead td{padding:12px;color:rgba(34,45,57,.6);font-size:14px;font-weight:600}.ikfoy5wLeO6bpP9_hfhu thead td.Y5eFtpHEW5DYiPmXIoEB{width:240px;padding-left:10px}.ikfoy5wLeO6bpP9_hfhu thead td.oOWQsBwfXpWUzEZIfvWd{width:196px;padding-left:21px;padding-right:12px}.ikfoy5wLeO6bpP9_hfhu tbody{display:block;height:calc(100vh - 274px);overflow:auto}.ikfoy5wLeO6bpP9_hfhu thead tr,.ikfoy5wLeO6bpP9_hfhu tbody tr{display:table;table-layout:fixed;width:100%;max-width:800px;margin:0 auto}`, "",{"version":3,"sources":["webpack://./src/components/Modals/CoWorkerModal/CoWorkerTable/CoWorkerTable.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,wBAAA,CAGJ,+BACI,YAAA,CACA,uBAAA,CACA,cAAA,CACA,eAAA,CAGJ,oDACI,WAAA,CACA,iBAAA,CAGJ,oDACI,WAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,4BACI,aAAA,CAEA,0BAAA,CACA,aAAA,CAGJ,8DAEI,aAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[".table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.table thead td {\n    padding: 12px;\n    color: rgba(34, 45, 57, 0.6);\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.table thead td.department {\n    width: 240px;\n    padding-left: 10px;\n}\n\n.table thead td.role {\n    width: 196px;\n    padding-left: 21px;\n    padding-right: 12px;\n}\n\n.table tbody {\n    display: block;\n\n    height: calc(100vh - 274px);\n    overflow: auto;\n}\n\n.table thead tr,\n.table tbody tr {\n    display: table;\n    table-layout: fixed;\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ikfoy5wLeO6bpP9_hfhu`,
	"department": `Y5eFtpHEW5DYiPmXIoEB`,
	"role": `oOWQsBwfXpWUzEZIfvWd`
};
export default ___CSS_LOADER_EXPORT___;
