var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createPlanningItem, createStreetItem, getPlanMapGeoJSONByStreetId } from '../../services/plan';
import { setIsLoadingActionbar } from './actionbar';
import { setCurrentAction } from './app';
import { setSelectedFeature } from './feature';
import { fetchPlans } from './leftPanelPlan';
import { fetchPlanGeoJSON } from './mainMap';
import { addNotificationMessage } from './notification';
import { updatePlan } from './plan';
import { resetSegmentDetail } from './segmentDetail';
const initialState = {
    form: {
        priority: 0,
        statusId: 1,
        categoryId: 3,
        thirdParty: false
    },
    shouldSubmit: false,
    createStatus: 'idle'
};
export const createSegment = createAppAsyncThunk('segmentForm/createSegment', (data, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    dispatch(setIsLoadingActionbar(true));
    const state = getState();
    const countryCode = (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode;
    const result = yield createPlanningItem(Object.assign(Object.assign({}, data), { countryCode }));
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.result !== undefined && result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setCurrentAction('none'));
        dispatch(setIsLoadingActionbar(false));
        dispatch(resetSegmentForm());
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.successfullycreated' }
            },
            action: {
                type: 'dispatch',
                label: {
                    componentId: 'toast.plannedsegment'
                },
                content: [
                    setSelectedFeature({
                        type: 'plan',
                        id: result.result.planId
                    })
                ]
            }
        }));
        dispatch(fetchPlanGeoJSON());
        dispatch(fetchPlans());
        dispatch(resetSegmentDetail());
    }
    return result;
}));
export const createStreet = createAppAsyncThunk('segmentForm/createStreet', (data, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    dispatch(setIsLoadingActionbar(true));
    const state = getState();
    const countryCode = (_b = state.auth.teamAccount) === null || _b === void 0 ? void 0 : _b.countryCode;
    const result = yield createStreetItem(Object.assign(Object.assign({}, data), { countryCode }));
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.result !== undefined && result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setCurrentAction('none'));
        dispatch(setIsLoadingActionbar(false));
        dispatch(resetSegmentForm());
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.successfullycreated' }
            },
            action: {
                type: 'dispatch',
                label: {
                    componentId: 'toast.plannedsegment'
                },
                content: [
                    setSelectedFeature({
                        type: 'plan',
                        id: result.result.planId
                    })
                ]
            }
        }));
        dispatch(fetchPlanGeoJSON());
    }
    // dispatch(clearSelectedFeature());
    return result;
}));
export const fetchStreetGeoJSON = createAppAsyncThunk('segmentForm/fetchStreetGeoJSON', ({ streetIds }, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getPlanMapGeoJSONByStreetId({ streetIds });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const selectedSegmentSlice = createSlice({
    name: 'segmentForm',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            var _a, _b;
            const formData = Object.assign({}, action.payload);
            if ((_a = formData.addressStart) === null || _a === void 0 ? void 0 : _a.userModifiedAddress) {
                formData.userModifiedAddressStart = formData.addressStart.userModifiedAddress;
            }
            if ((_b = formData.addressEnd) === null || _b === void 0 ? void 0 : _b.userModifiedAddress) {
                formData.userModifiedAddressEnd = formData.addressEnd.userModifiedAddress;
            }
            state.form = formData;
        },
        setGeojson: (state, action) => {
            state.geojson = action.payload;
        },
        setShouldSubmit: (state, action) => {
            state.shouldSubmit = action.payload;
        },
        setValue: (state, action) => {
            var _a, _b;
            state.form = Object.assign(Object.assign({}, state.form), { thirdParty: (_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.thirdParty) !== null && _b !== void 0 ? _b : false, [action.payload.field]: action.payload.value });
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        // Individual handlers
        builder
            .addCase(fetchStreetGeoJSON.fulfilled, (state, action) => {
            if (action.payload && action.payload.result) {
                state.geojson = {
                    type: 'segments',
                    collection: action.payload.result.geojson // type casting because of v2GeoFeature type is not compatible with FeatureCollection
                };
            }
        })
            .addCase(updatePlan.fulfilled, (state, action) => {
            var _a, _b;
            const { result } = action.payload;
            if ((result === null || result === void 0 ? void 0 : result.newData) && result.newData.planId === ((_a = state.form) === null || _a === void 0 ? void 0 : _a.planId)) {
                state.form = (_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.newData;
            }
        })
            // Actions that affect entire container
            .addCase(createSegment.fulfilled, (state) => {
            state.createStatus = 'succeeded';
        })
            .addCase(createSegment.pending, (state) => {
            state.createStatus = 'loading';
        })
            .addCase(createSegment.rejected, (state) => {
            state.createStatus = 'failed';
        });
    }
});
export const { setFormData, setGeojson, setValue, setShouldSubmit: submitSegmentForm, reset: resetSegmentForm } = selectedSegmentSlice.actions;
export default selectedSegmentSlice.reducer;
