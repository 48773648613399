// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Piao_3_Tt69BZRRd0LU0{cursor:pointer;height:24px;width:24px}`, "",{"version":3,"sources":["webpack://./src/uikit/Radio/Radio.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".container {\n    cursor: pointer;\n    height: 24px;\n    width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Piao_3_Tt69BZRRd0LU0`
};
export default ___CSS_LOADER_EXPORT___;
