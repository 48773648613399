var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getAlertList, getSortParams } from '../../services/alert';
import { addNotificationMessage } from './notification';
export const alertInitialFilter = {
    typeId: []
};
const alertParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.alert;
const initialState = {
    resultList: [],
    totalCountList: 0,
    resultFetchStatus: 'idle',
    sort: (alertParams === null || alertParams === void 0 ? void 0 : alertParams.sort) || 'created_at_server-desc',
    filter: Object.assign(Object.assign({}, alertInitialFilter), alertParams === null || alertParams === void 0 ? void 0 : alertParams.filter),
    selectedIdList: [],
    currentPage: PAGE_NUMBER_FIRST,
    activeTabKey: 'filter'
};
export const fetchAlerts = createDebouncedAsyncThunk('leftPanelAlert/alert/fetchList', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { bounds } = state.mainMap;
    const { sort, filter, useMapBounds, currentPage } = state.leftPanelAlert;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const mapBoundFilter = useMapBounds ? bounds : undefined;
    const res = yield getAlertList(Object.assign(Object.assign(Object.assign(Object.assign({}, getSortParams(sort)), filter), mapBoundFilter), { limit: ITEMS_PER_PAGE, offset: (currentPage - 1) * ITEMS_PER_PAGE, from: selectedOption.from, to: selectedOption.to }));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.userError) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const leftPanelAlertSlice = createSlice({
    name: 'leftPanelAlert',
    initialState,
    reducers: {
        reset: () => initialState,
        resetAlertFilter: (state) => {
            state.filter = alertInitialFilter;
        },
        setAlertFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setAlertSorting: (state, action) => {
            state.sort = action.payload;
        },
        setAlertSelectedIdList: (state, action) => {
            state.selectedIdList = action.payload;
        },
        setAlertUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        setAlertActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setAlertCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAlerts.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchAlerts.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.list;
                state.totalCountList = action.payload.result.totalCount;
            }
        });
    }
});
export const { reset: resetLeftPanelAlert, setAlertFilter, setAlertSorting, resetAlertFilter, setAlertSelectedIdList, setAlertCurrentPage, setAlertActiveTabKey, setAlertUseMapBounds } = leftPanelAlertSlice.actions;
export default leftPanelAlertSlice.reducer;
