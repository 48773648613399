import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Ditto } from 'ditto-react';
import { PLAN_DATE_VARIANT_ID, PLAN_PLANNED_FOR_ID, PLAN_PROPERTY_ID, PLAN_TREATMENT_CATEGORY_ID } from '../../common/consts/plan';
import { getPlanCategoryVignette, getPlanEfficiencyIcon, getPlanPriorityIcon, getPlanStatusVignette, getPlanThirdPartyIcon, getPlanThirdPartyLabel, getPlanTreatmentCategoryLabel } from '../../common/convert';
import { TreatmentIcon } from '../../common/icon';
import { toggleItemInArray } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { getCompletionYearMonthString, getDateInUTC, parseDateInUTC } from '../../common/utils/time';
import { getUnitTypeById } from '../../common/utils/units';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { FormInputSelect } from '../../components/FormInputSelect/FormInputSelect';
import { CalendarBlank } from '../../components/icons';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import { TextOverflow } from '../../components/TextOverflow/TextOverflow';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
import { RadioSelectbox } from '../../uikit/RadioSelectbox/RadioSelectbox';
import { useFilterWrapperHandlers } from '../filters/useFilterWrapperHandlers';
import { usePlanFilterHandlers } from '../filters/usePlanFilterHandlers';
import { useFormatter } from '../formatting/useFormatter';
import { useUnitFormatter } from '../formatting/useUnitFormatter';
import { useDittoWrapper } from '../useDittoWrapper';
const RANGE_SLIDER_APPLIED_FILTERS_WIDTH = 332;
const RANGE_SLIDER_LEFT_PANEL_WIDTH = 294;
export function usePlanFilters() {
    const { locale } = useAppSelector((state) => state.app);
    const { filter } = useAppSelector((state) => state.leftPanelPlan);
    const { planFilters, planTreatmentsList, planUnitTypes } = useAppSelector((state) => state.shared);
    const { addModal } = useModalContext();
    const { handleFilterGroupWrapperToggle } = useFilterWrapperHandlers();
    const { handleStatusFilterClick, handleCategoryFilterClick, handlePriorityFilterClick, handleBudgetFilterClick, handleTreatmentFilterClick, handleTreatmentCategoryFilterClick, handleTreatmentNotSpecifiedFilterClick, handleFirmlyPlannedFilterClick, handlePlannedForFilterClick, handleCustomPlannedForFilterClick, handleAreaRangeClick, handleAreaMinFilterChange, handleAreaMaxFilterChange, handleAreaNotSpecifiedFilterClick, handleCostsRangeClick, handleCostsMinFilterChange, handleCostsMaxFilterChange, handleCostsNotSpecifiedFilterClick, handleYearsOfUseRangeClick, handleYearsOfUseMinFilterChange, handleYearsOfUseMaxFilterChange, handleYearsOfUseNotSpecifiedFilterClick, handleEfficiencyFilterClick, handleCustomDataFilterClick, handleCustomDataRangeClick } = usePlanFilterHandlers();
    const { formatDate } = useFormatter();
    const unitFormatter = useUnitFormatter();
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    const customPlannedFor = useMemo(() => {
        var _a, _b, _c, _d;
        return {
            startMonth: ((_a = filter.customCompletionYearAndMonth) === null || _a === void 0 ? void 0 : _a.from)
                ? parseDateInUTC(filter.customCompletionYearAndMonth.from).getMonth() + 1
                : undefined,
            startYear: ((_b = filter.customCompletionYearAndMonth) === null || _b === void 0 ? void 0 : _b.from)
                ? parseDateInUTC(filter.customCompletionYearAndMonth.from).getFullYear()
                : undefined,
            endMonth: ((_c = filter.customCompletionYearAndMonth) === null || _c === void 0 ? void 0 : _c.to)
                ? parseDateInUTC(filter.customCompletionYearAndMonth.to).getMonth() + 1
                : undefined,
            endYear: ((_d = filter.customCompletionYearAndMonth) === null || _d === void 0 ? void 0 : _d.to)
                ? parseDateInUTC(filter.customCompletionYearAndMonth.to).getFullYear()
                : undefined
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.completionYearAndMonth, filter.customCompletionYearAndMonth]);
    function getPlanPriorityFilterOptions() {
        return planFilters.priority.map((priority) => {
            var _a;
            return (_jsx(FilterSelectbox, { onClick: () => handlePriorityFilterClick(priority.id), isChecked: (_a = filter.priorityIds) === null || _a === void 0 ? void 0 : _a.includes(priority.id), content: priority.name, icon: getPlanPriorityIcon(priority.id), color: "black", trailingElement: priority.count }, `priority-${priority.id}`));
        });
    }
    function getPlanStatusFilterOptions() {
        return planFilters.status.map((status) => {
            var _a;
            return (_jsx(FilterSelectbox, { onClick: () => handleStatusFilterClick(status.id), isChecked: (_a = filter.statusIds) === null || _a === void 0 ? void 0 : _a.includes(status.id), content: status.name, color: "black", icon: getPlanStatusVignette(status.id), trailingElement: status.count }, `status${status.id}`));
        });
    }
    function getPlanCategoryFilterOptions() {
        return planFilters.category.map((category) => {
            var _a;
            return (_jsx(FilterSelectbox, { onClick: () => handleCategoryFilterClick(category.id), isChecked: (_a = filter.categoryIds) === null || _a === void 0 ? void 0 : _a.includes(category.id), content: category.name, color: "black", icon: getPlanCategoryVignette(category.id), trailingElement: category.count }, `category-${category.id}`));
        });
    }
    function getPlanBudgetFilterOptions() {
        return planFilters.budget.map((budget) => {
            var _a;
            return (_jsx(FilterSelectbox, { onClick: () => handleBudgetFilterClick(budget.id), isChecked: (_a = filter.budgetIds) === null || _a === void 0 ? void 0 : _a.includes(budget.id), content: budget.name, color: "black", trailingElement: budget.count }, `budget-${budget.id}`));
        });
    }
    function getPlanTreatmentFilterOptions({ panelContainerRef } = { target: 'leftPanel' }) {
        function getOptionsByCategory(array, id) {
            return array.filter((o) => o.treatmentCategoryId === id);
        }
        const notspecified = planFilters.treatment.find((treatment) => treatment.id === 0);
        return (_jsxs(_Fragment, { children: [Object.values(PLAN_TREATMENT_CATEGORY_ID).map((id) => {
                    const filteredOptionsByCategory = getOptionsByCategory(planFilters.treatment, id).filter((treatment) => {
                        const treatmentDetails = planTreatmentsList.find((t) => t.id === treatment.id);
                        return (treatment.id !== 0 &&
                            (!(treatmentDetails === null || treatmentDetails === void 0 ? void 0 : treatmentDetails.hidden) ||
                                (treatmentDetails.hidden && treatment.count > 0)));
                    });
                    const selected = filteredOptionsByCategory.filter((item) => { var _a; return (_a = filter.treatmentIds) === null || _a === void 0 ? void 0 : _a.includes(item.id); }).length;
                    return filteredOptionsByCategory.length > 0 ? (_jsx(FilterGroupWrapper, Object.assign({ content: _jsxs("div", Object.assign({ style: { display: 'flex', alignItems: 'center', gap: 6 } }, { children: [TreatmentIcon(id), getPlanTreatmentCategoryLabel(id)] })), checkState: getCheckboxCheckState(filteredOptionsByCategory.length, selected), onCheckboxClick: () => handleTreatmentCategoryFilterClick(id), onToggle: panelContainerRef
                            ? (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef)
                            : undefined, isInitiallyCollapsed: filteredOptionsByCategory.some((t) => { var _a; return !((_a = filter.treatmentIds) === null || _a === void 0 ? void 0 : _a.includes(t.id)); }), hideBottomBar: true }, { children: filteredOptionsByCategory.map((treatment) => {
                            var _a;
                            return (_jsx(FilterSelectbox, { onClick: () => handleTreatmentFilterClick(treatment.id), isChecked: (_a = filter.treatmentIds) === null || _a === void 0 ? void 0 : _a.includes(treatment.id), content: _jsx("div", Object.assign({ style: { whiteSpace: 'initial' } }, { children: _jsx(TextOverflow, { text: treatment.name, maxLines: 1, tooltip: "default", width: 190 }) })), color: "black", trailingElement: treatment.count }, `treatment-${treatment.id}`));
                        }) }), `treatment-category-${id}`)) : null;
                }), notspecified && (_jsx(FilterSelectbox, { onClick: () => handleTreatmentNotSpecifiedFilterClick(), isChecked: filter.treatmentIdsIncludeNotSet, content: notspecified.name, color: "black", trailingElement: notspecified.count }, `treatment-not-specified`))] }));
    }
    function getPlanFirmlyPlannedFilterOptions() {
        return (_jsxs(_Fragment, { children: [_jsx(RadioSelectbox, { onClick: () => handleFirmlyPlannedFilterClick(true), isChecked: filter.thirdParty === true, content: getPlanThirdPartyLabel(true), icon: getPlanThirdPartyIcon(true), color: "black", count: planFilters.firmlyPlanned[0]
                        ? planFilters.firmlyPlanned[0].count
                        : undefined }), _jsx(RadioSelectbox, { onClick: () => handleFirmlyPlannedFilterClick(false), isChecked: filter.thirdParty === false, content: getPlanThirdPartyLabel(false), icon: getPlanThirdPartyIcon(false), color: "black", count: planFilters.firmlyPlanned[1]
                        ? planFilters.firmlyPlanned[1].count
                        : undefined })] }));
    }
    function handlePlannedForClick() {
        addModal({
            id: 'MonthPickerModalContainer',
            props: Object.assign(Object.assign({ rangeSelector: true }, customPlannedFor), { onConfirm: (value) => {
                    if (value) {
                        const startDate = (value === null || value === void 0 ? void 0 : value.startDate)
                            ? getDateInUTC(value === null || value === void 0 ? void 0 : value.startDate).toISOString()
                            : undefined;
                        const untilDate = (value === null || value === void 0 ? void 0 : value.untilDate)
                            ? getDateInUTC(value === null || value === void 0 ? void 0 : value.untilDate).toISOString()
                            : undefined;
                        handleCustomPlannedForFilterClick({
                            from: startDate,
                            to: untilDate !== null && untilDate !== void 0 ? untilDate : startDate
                        });
                    }
                    else {
                        handlePlannedForFilterClick(undefined);
                    }
                } })
        });
    }
    function getPlanPlannedForFilterOptions() {
        return (_jsxs(_Fragment, { children: [planFilters.plannedFor.map((plannedFor) => {
                    var _a;
                    return (_jsx(RadioSelectbox, { onClick: () => handlePlannedForFilterClick(plannedFor.id), isChecked: filter.completionYearAndMonth === plannedFor.id, content: plannedFor.name, color: "black", count: (_a = planFilters.plannedFor.find((item) => item.id === plannedFor.id)) === null || _a === void 0 ? void 0 : _a.count }, 'planned-for-' + plannedFor.id));
                }), _jsx(RadioSelectbox, { onClick: () => {
                        if (filter.completionYearAndMonth === PLAN_PLANNED_FOR_ID.custom) {
                            handlePlannedForFilterClick(PLAN_PLANNED_FOR_ID.custom);
                        }
                        else {
                            handlePlannedForClick();
                        }
                    }, isChecked: filter.completionYearAndMonth === PLAN_PLANNED_FOR_ID.custom, content: _jsx(Ditto, { componentId: "timepicker.customtimeframe" }), color: "black" }), filter.completionYearAndMonth === PLAN_PLANNED_FOR_ID.custom && (_jsx(_Fragment, { children: _jsx(FormInputSelect, { onClick: handlePlannedForClick, leadingIcon: _jsx(CalendarBlank, {}), hideMenu: true, placeholder: _jsx(Ditto, { componentId: "timepicker.selectcustomtimeframe" }), value: customPlannedFor.startYear &&
                            customPlannedFor.startMonth &&
                            customPlannedFor.endYear &&
                            customPlannedFor.endMonth ? (_jsxs(_Fragment, { children: [getCompletionYearMonthString(locale, customPlannedFor.startYear, customPlannedFor.startMonth), ' - ', getCompletionYearMonthString(locale, customPlannedFor.endYear, customPlannedFor.endMonth)] })) : undefined, size: "medium", variant: "ghost" }) }))] }));
    }
    function getPlanAreaFilterOptions({ target, panelContainerRef } = { target: 'leftPanel' }) {
        var _a, _b, _c, _d;
        return (_jsxs(_Fragment, { children: [_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.range" }), checkState: filter.areaMin !== undefined || filter.areaMax !== undefined
                        ? 'checked'
                        : 'not-checked', onCheckboxClick: handleAreaRangeClick, onToggle: panelContainerRef
                        ? (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef)
                        : undefined, count: planFilters.area.setCount ? 0 : undefined, isHeader: false }, { children: planFilters.area.setCount ? (_jsx(RangeSlider, { min: (_a = planFilters.area.min) !== null && _a !== void 0 ? _a : 0, max: (_b = planFilters.area.max) !== null && _b !== void 0 ? _b : 0, value: {
                            min: (_c = filter.areaMin) !== null && _c !== void 0 ? _c : planFilters.area.min,
                            max: (_d = filter.areaMax) !== null && _d !== void 0 ? _d : planFilters.area.max
                        }, onChange: (value) => {
                            var _a, _b;
                            if (((_a = filter.areaMin) !== null && _a !== void 0 ? _a : planFilters.area.min) !== value.min) {
                                handleAreaMinFilterChange(value.min);
                            }
                            if (((_b = filter.areaMax) !== null && _b !== void 0 ? _b : planFilters.area.max) !== value.max) {
                                handleAreaMaxFilterChange(value.max);
                            }
                        }, width: target === 'appliedFilterBar'
                            ? RANGE_SLIDER_APPLIED_FILTERS_WIDTH
                            : RANGE_SLIDER_LEFT_PANEL_WIDTH, showInputs: true, unitFormatter: unitFormatter({ type: 'area', decimals: 2 }) })) : undefined })), _jsx(FilterSelectbox, { onClick: () => handleAreaNotSpecifiedFilterClick(), isChecked: filter.areaIncludeNotSet, content: notSpecifiedLabel, color: "black", trailingElement: planFilters.area.notSetCount })] }));
    }
    function getPlanCostsFilterOptions({ target, panelContainerRef } = { target: 'leftPanel' }) {
        var _a, _b, _c, _d;
        return (_jsxs(_Fragment, { children: [_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.range" }), checkState: filter.costsMin !== undefined || filter.costsMax !== undefined
                        ? 'checked'
                        : 'not-checked', onCheckboxClick: handleCostsRangeClick, onToggle: panelContainerRef
                        ? (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef)
                        : undefined, count: planFilters.costs.setCount ? 0 : undefined, isHeader: false }, { children: planFilters.costs.setCount ? (_jsx(RangeSlider, { min: (_a = planFilters.costs.min) !== null && _a !== void 0 ? _a : 0, max: (_b = planFilters.costs.max) !== null && _b !== void 0 ? _b : 0, value: {
                            min: (_c = filter.costsMin) !== null && _c !== void 0 ? _c : planFilters.costs.min,
                            max: (_d = filter.costsMax) !== null && _d !== void 0 ? _d : planFilters.costs.max
                        }, onChange: (value) => {
                            var _a, _b;
                            if (((_a = filter.costsMin) !== null && _a !== void 0 ? _a : planFilters.costs.min) !== value.min) {
                                handleCostsMinFilterChange(value.min);
                            }
                            if (((_b = filter.costsMax) !== null && _b !== void 0 ? _b : planFilters.costs.max) !== value.max) {
                                handleCostsMaxFilterChange(value.max);
                            }
                        }, width: target === 'appliedFilterBar'
                            ? RANGE_SLIDER_APPLIED_FILTERS_WIDTH
                            : RANGE_SLIDER_LEFT_PANEL_WIDTH, showInputs: true, unitFormatter: unitFormatter({ type: 'currency', decimals: 2 }) })) : undefined })), _jsx(FilterSelectbox, { onClick: () => handleCostsNotSpecifiedFilterClick(), isChecked: filter.costsIncludeNotSet, content: notSpecifiedLabel, color: "black", trailingElement: planFilters.costs.notSetCount })] }));
    }
    function getPlanYearsOfUseFilterOptions({ target, panelContainerRef } = { target: 'leftPanel' }) {
        var _a, _b, _c, _d;
        return (_jsxs(_Fragment, { children: [_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.range" }), checkState: filter.yearsOfUseMin !== undefined || filter.yearsOfUseMax !== undefined
                        ? 'checked'
                        : 'not-checked', onCheckboxClick: handleYearsOfUseRangeClick, onToggle: panelContainerRef
                        ? (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef)
                        : undefined, count: planFilters.yearsOfUse.setCount ? 0 : undefined, isHeader: false }, { children: planFilters.yearsOfUse.setCount ? (_jsx(RangeSlider, { min: (_a = planFilters.yearsOfUse.min) !== null && _a !== void 0 ? _a : 0, max: (_b = planFilters.yearsOfUse.max) !== null && _b !== void 0 ? _b : 0, step: 1, value: {
                            min: (_c = filter.yearsOfUseMin) !== null && _c !== void 0 ? _c : planFilters.yearsOfUse.min,
                            max: (_d = filter.yearsOfUseMax) !== null && _d !== void 0 ? _d : planFilters.yearsOfUse.max
                        }, onChange: (value) => {
                            var _a, _b;
                            if (((_a = filter.yearsOfUseMin) !== null && _a !== void 0 ? _a : planFilters.yearsOfUse.min) !==
                                value.min) {
                                handleYearsOfUseMinFilterChange(value.min);
                            }
                            if (((_b = filter.yearsOfUseMax) !== null && _b !== void 0 ? _b : planFilters.yearsOfUse.max) !==
                                value.max) {
                                handleYearsOfUseMaxFilterChange(value.max);
                            }
                        }, width: target === 'appliedFilterBar'
                            ? RANGE_SLIDER_APPLIED_FILTERS_WIDTH
                            : RANGE_SLIDER_LEFT_PANEL_WIDTH, showInputs: true })) : undefined })), _jsx(FilterSelectbox, { onClick: () => handleYearsOfUseNotSpecifiedFilterClick(), isChecked: filter.yearsOfUseIncludeNotSet, content: notSpecifiedLabel, color: "black", trailingElement: planFilters.yearsOfUse.notSetCount })] }));
    }
    function getPlanEfficiencyFilterOptions() {
        return planFilters.efficiency.map((item) => {
            var _a;
            return (_jsx(FilterSelectbox, { onClick: () => handleEfficiencyFilterClick(item.id), isChecked: (_a = filter.efficiencyIds) === null || _a === void 0 ? void 0 : _a.includes(item.id), content: item.name, icon: getPlanEfficiencyIcon(item.id), color: "black", trailingElement: item.count }, `efficiency-${item.id}`));
        });
    }
    function handleCustomMonthClick(customData) {
        if (!customData)
            return;
        const customFilterData = filter.customData && filter.customData[customData.columnKey];
        const startDate = (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.from)
            ? parseDateInUTC(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.from)
            : undefined;
        const untilDate = (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.to) ? parseDateInUTC(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.to) : undefined;
        addModal({
            id: 'MonthPickerModalContainer',
            props: {
                rangeSelector: true,
                startMonth: startDate ? startDate.getMonth() + 1 : undefined,
                startYear: startDate ? startDate.getFullYear() : undefined,
                endMonth: untilDate ? untilDate.getMonth() + 1 : undefined,
                endYear: untilDate ? untilDate.getFullYear() : undefined,
                onConfirm: (value) => {
                    if (!customData)
                        return;
                    const newStartDate = (value === null || value === void 0 ? void 0 : value.startDate)
                        ? getDateInUTC(value === null || value === void 0 ? void 0 : value.startDate).toISOString()
                        : undefined;
                    const newUntilDate = (value === null || value === void 0 ? void 0 : value.untilDate)
                        ? getDateInUTC(value === null || value === void 0 ? void 0 : value.untilDate).toISOString()
                        : undefined;
                    handleCustomDataFilterClick(customData, value
                        ? {
                            isNotSet: false,
                            from: newStartDate,
                            to: newUntilDate !== null && newUntilDate !== void 0 ? newUntilDate : newStartDate
                        }
                        : {
                            from: undefined,
                            to: undefined
                        });
                }
            }
        });
    }
    function handleCustomDateClick(customData) {
        if (!customData)
            return;
        const customFilterData = filter.customData && filter.customData[customData.columnKey];
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                rangeSelector: true,
                startDate: (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.from)
                    ? format(parseDateInUTC(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.from), 'yyyy-MM-dd')
                    : undefined,
                endDate: (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.to)
                    ? format(parseDateInUTC(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.to), 'yyyy-MM-dd')
                    : undefined,
                onConfirm: (value) => {
                    if (!customData)
                        return;
                    const newStartDate = (value === null || value === void 0 ? void 0 : value.startDate)
                        ? getDateInUTC(value === null || value === void 0 ? void 0 : value.startDate).toISOString()
                        : undefined;
                    const newUntilDate = (value === null || value === void 0 ? void 0 : value.untilDate)
                        ? getDateInUTC(value === null || value === void 0 ? void 0 : value.untilDate).toISOString()
                        : undefined;
                    handleCustomDataFilterClick(customData, value
                        ? {
                            isNotSet: false,
                            from: newStartDate,
                            to: newUntilDate !== null && newUntilDate !== void 0 ? newUntilDate : newStartDate
                        }
                        : {
                            from: undefined,
                            to: undefined
                        });
                }
            }
        });
    }
    function getPlanCustomDataFilterOptions({ customData, target, panelContainerRef } = { target: 'leftPanel' }) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!customData)
            return _jsx(_Fragment, {});
        const customFilterData = filter.customData && filter.customData[customData.columnKey];
        switch (customData.propertyTypeId) {
            case PLAN_PROPERTY_ID.text:
                return (_jsxs(_Fragment, { children: [_jsx(FilterSelectbox, { onClick: () => handleCustomDataFilterClick(customData, {
                                isSet: !(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isSet) ? true : undefined
                            }), isChecked: customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isSet, content: _jsx(Ditto, { componentId: "filter.isadded" }), color: "black", trailingElement: customData.setCount }), _jsx(FilterSelectbox, { onClick: () => handleCustomDataFilterClick(customData, {
                                isNotSet: !(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet) ? true : undefined
                            }), isChecked: customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet, content: notSpecifiedLabel, color: "black", trailingElement: customData.notSetCount })] }));
            case PLAN_PROPERTY_ID.numeric:
                return (_jsxs(_Fragment, { children: [_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.range" }), checkState: (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.min) !== undefined ||
                                (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.max) !== undefined
                                ? 'checked'
                                : 'not-checked', onCheckboxClick: () => handleCustomDataRangeClick(customData), onToggle: panelContainerRef
                                ? (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef)
                                : undefined, count: customData.setCount === 0 ? 0 : undefined, isHeader: false }, { children: customData.setCount ? (_jsx(RangeSlider, { min: (_a = customData.min) !== null && _a !== void 0 ? _a : 0, max: (_b = customData.max) !== null && _b !== void 0 ? _b : 0, value: {
                                    min: (_c = customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.min) !== null && _c !== void 0 ? _c : customData.min,
                                    max: (_d = customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.max) !== null && _d !== void 0 ? _d : customData.max
                                }, onChange: (value) => {
                                    handleCustomDataFilterClick(customData, {
                                        min: value.min,
                                        max: value.max
                                    });
                                }, width: target === 'appliedFilterBar'
                                    ? RANGE_SLIDER_APPLIED_FILTERS_WIDTH
                                    : RANGE_SLIDER_LEFT_PANEL_WIDTH, unitFormatter: unitFormatter({
                                    type: customData.unitTypeId
                                        ? getUnitTypeById(customData.unitTypeId)
                                        : undefined,
                                    unit: (_e = planUnitTypes.find((unit) => unit.id === customData.unitTypeId)) === null || _e === void 0 ? void 0 : _e.title
                                }), showInputs: true })) : undefined })), _jsx(FilterSelectbox, { onClick: () => handleCustomDataFilterClick(customData, {
                                isNotSet: !(customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet) ? true : undefined
                            }), isChecked: customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet, content: notSpecifiedLabel, color: "black", trailingElement: customData.notSetCount })] }));
            case PLAN_PROPERTY_ID.list:
                return (_jsxs(_Fragment, { children: [(_f = customData.options) === null || _f === void 0 ? void 0 : _f.map((option) => {
                            var _a;
                            return (_jsx(FilterSelectbox, { onClick: () => handleCustomDataFilterClick(customData, {
                                    options: toggleItemInArray((customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.options) || [], option.id)
                                }), isChecked: (_a = customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.options) === null || _a === void 0 ? void 0 : _a.includes(option.id), content: option.title, color: "black", trailingElement: option.count }, `option-${option.id}`));
                        }), _jsx(FilterSelectbox, { onClick: () => handleCustomDataFilterClick(customData, {
                                options: toggleItemInArray((customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.options) || [], 0)
                            }), isChecked: (_g = customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.options) === null || _g === void 0 ? void 0 : _g.includes(0), content: notSpecifiedLabel, color: "black", trailingElement: customData.notSetCount })] }));
            case PLAN_PROPERTY_ID.date:
                return (_jsxs(_Fragment, { children: [_jsx(RadioSelectbox, { onClick: () => handleCustomDataFilterClick(customData, {
                                isNotSet: true,
                                from: undefined,
                                to: undefined
                            }), isChecked: customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet, content: notSpecifiedLabel, color: "black", count: customData.notSetCount }), _jsx(RadioSelectbox, { onClick: () => {
                                if (customData.variantTypeId === PLAN_DATE_VARIANT_ID.month) {
                                    handleCustomMonthClick(customData);
                                }
                                else {
                                    handleCustomDateClick(customData);
                                }
                            }, isChecked: (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet) === false, content: _jsx(Ditto, { componentId: "timepicker.customtimeframe" }), color: "black" }), (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet) === false && (_jsxs(_Fragment, { children: [customData.variantTypeId === PLAN_DATE_VARIANT_ID.month && (_jsx(FormInputSelect, { onClick: () => handleCustomMonthClick(customData), leadingIcon: _jsx(CalendarBlank, {}), hideMenu: true, placeholder: _jsx(Ditto, { componentId: "timepicker.selectcustomtimeframe" }), value: customFilterData.from && customFilterData.to ? (_jsxs(_Fragment, { children: [getCompletionYearMonthString(locale, parseDateInUTC(customFilterData.from).getFullYear(), parseDateInUTC(customFilterData.from).getMonth() + 1), ' - ', getCompletionYearMonthString(locale, parseDateInUTC(customFilterData.to).getFullYear(), parseDateInUTC(customFilterData.to).getMonth() + 1)] })) : undefined, size: "medium", variant: "ghost" })), customData.variantTypeId === PLAN_DATE_VARIANT_ID.day && (_jsx(FormInputSelect, { onClick: () => handleCustomDateClick(customData), leadingIcon: _jsx(CalendarBlank, {}), hideMenu: true, placeholder: _jsx(Ditto, { componentId: "timepicker.selectcustomtimeframe" }), value: customFilterData.from && customFilterData.to ? (_jsxs(_Fragment, { children: [formatDate(customFilterData.from).date, ' - ', formatDate(customFilterData.to).date] })) : undefined, size: "medium", variant: "ghost" }))] }))] }));
            default:
                return _jsx(_Fragment, {});
        }
    }
    return {
        getPlanPriorityFilterOptions,
        getPlanStatusFilterOptions,
        getPlanCategoryFilterOptions,
        getPlanBudgetFilterOptions,
        getPlanTreatmentFilterOptions,
        getPlanFirmlyPlannedFilterOptions,
        getPlanPlannedForFilterOptions,
        getPlanAreaFilterOptions,
        getPlanCostsFilterOptions,
        getPlanYearsOfUseFilterOptions,
        getPlanEfficiencyFilterOptions,
        getPlanCustomDataFilterOptions
    };
}
