var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { combineReducers, configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createMigrate, FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';
import { SENTRY_REDUX_CONFIG } from '../common/consts/sentry';
import config from '../config/config.json';
import actionbarReducer from './slices/actionbar';
import appReducer from './slices/app';
import assetConverterReducer from './slices/assetConverter';
import assetCreatorReducer from './slices/assetCreator';
import assetDetailReducer from './slices/assetDetail';
import authReducer from './slices/auth';
import bulkEditorReducer from './slices/bulkEditor';
import closeMarkerReducer from './slices/closeMarker';
import conditionDetailReducer from './slices/conditionDetail';
import conditionReportReducer from './slices/conditionReport';
import coWorkerManagementReducer from './slices/coWorkerManagement';
import featureReducer from './slices/feature';
import globalSettingReducer from './slices/globalSetting';
import imageViewerConditionReducer from './slices/imageViewerCondition';
import layerReducer from './slices/layer';
import leftPanelAlertReducer from './slices/leftPanelAlert';
import leftPanelAssetReducer from './slices/leftPanelAsset';
import leftPanelConditionReducer from './slices/leftPanelCondition';
import leftPanelMarkerReducer from './slices/leftPanelMarker';
import leftPanelObjectReducer from './slices/leftPanelObject';
import leftPanelPlanReducer from './slices/leftPanelPlan';
import leftPanelSignReducer from './slices/leftPanelSign';
import leftPanelSignInventoryReducer from './slices/leftPanelSignInventory';
import leftPanelTrackControlReducer from './slices/leftPanelTrackControl';
import leftPanelWinterServiceReducer from './slices/leftPanelWinterService';
import linkedMarkersReducer from './slices/linkedMarkers';
import mailReducer from './slices/mail';
import mainMapReducer from './slices/mainMap';
import mapPopoverReducer from './slices/mapPopover';
import markerBulkCreatorReducer from './slices/markerBulkCreator';
import markerCreatorReducer from './slices/markerCreator';
import markerDetailReducer from './slices/markerDetail';
import navigationReducer from './slices/navigation';
import noteReducer from './slices/note';
import notificationReducer from './slices/notification';
import planReducer from './slices/plan';
import presetsPanelReducer from './slices/presetsPanel';
import printReducer from './slices/print';
import segmentDetailReducer from './slices/segmentDetail';
import segmentFormReducer from './slices/segmentForm';
import sharedReducer from './slices/shared';
import sharedDataHolderReducer from './slices/sharedDataHolder';
import signCreatorReducer from './slices/signCreator';
import signDetailReducer from './slices/signDetail';
import signInventoryDetailReducer from './slices/signInventoryDetail';
import { listener } from './listener';
import { logger } from './logger';
const ACTION_RESET_ROOT_STATE = 'root/RESET';
const THUNK_PREFIX_ROOT_STATE = 'root/resetRootState';
const rootReducer = combineReducers({
    // alphabetical order
    actionbar: actionbarReducer,
    app: appReducer,
    assetConverter: assetConverterReducer,
    assetCreator: assetCreatorReducer,
    assetDetail: assetDetailReducer,
    auth: authReducer,
    bulkEditor: bulkEditorReducer,
    closeMarker: closeMarkerReducer,
    conditionDetail: conditionDetailReducer,
    conditionReport: conditionReportReducer,
    coWorkerManagement: coWorkerManagementReducer,
    feature: featureReducer,
    globalSettings: globalSettingReducer,
    imageViewerCondition: imageViewerConditionReducer,
    layer: layerReducer,
    leftPanelAlert: leftPanelAlertReducer,
    leftPanelAsset: leftPanelAssetReducer,
    leftPanelCondition: leftPanelConditionReducer,
    leftPanelTrackControl: leftPanelTrackControlReducer,
    leftPanelMarker: leftPanelMarkerReducer,
    leftPanelObject: leftPanelObjectReducer,
    leftPanelPlan: leftPanelPlanReducer,
    leftPanelSign: leftPanelSignReducer,
    leftPanelSignInventory: leftPanelSignInventoryReducer,
    leftPanelWinterService: leftPanelWinterServiceReducer,
    mail: mailReducer,
    mainMap: mainMapReducer,
    mapPopover: mapPopoverReducer,
    markerCreator: markerCreatorReducer,
    markerBulkCreator: markerBulkCreatorReducer,
    markerDetail: markerDetailReducer,
    navigation: navigationReducer,
    note: noteReducer,
    notification: notificationReducer,
    plan: planReducer,
    presetsPanel: presetsPanelReducer,
    print: printReducer,
    segmentDetail: segmentDetailReducer,
    segmentForm: segmentFormReducer,
    signCreator: signCreatorReducer,
    signDetail: signDetailReducer,
    signInventoryDetail: signInventoryDetailReducer,
    shared: sharedReducer,
    sharedDataHolder: sharedDataHolderReducer,
    linkedMarkers: linkedMarkersReducer
});
const resetableRootReducer = (state, action) => {
    if (action.type === ACTION_RESET_ROOT_STATE) {
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};
const persistConfig = {
    key: 'root',
    storage,
    timeout: 2000,
    version: 0,
    whitelist: [
        'leftPanelPlan',
        'leftPanelMarker',
        'leftPanelAsset',
        'leftPanelCondition',
        'leftPanelAlert',
        'leftPanelTrackControl',
        'leftPanelObject',
        'leftPanelWinterService',
        'leftPanelSign',
        'leftPanelSignInventory',
        'feature',
        'globalSettings',
        'mainMap',
        'plan',
        'segmentDetail',
        'segmentForm',
        'markerDetail',
        'signDetail',
        'signInventoryDetail',
        'assetDetail'
    ],
    // Custom state reconciler to ignore persisted state if query param is present
    stateReconciler: (inboundState, originalState, reducedState, config) => {
        const url = new URL(document.location.href);
        const params = url.searchParams;
        const query = params.get('q');
        if (query || url.pathname !== '/') {
            console.debug('state reconciler: returning reduced state because of query param or not root path');
            return reducedState;
        }
        console.debug('state reconciler: using auto merge level 1 because of root path');
        return autoMergeLevel1(inboundState, originalState, reducedState, config);
    },
    migrate: createMigrate({
        0: (state) => {
            if (!state) {
                return;
            }
            return Object.assign(Object.assign({}, state), { leftPanelSignInventory: Object.assign(Object.assign({}, state.leftPanelSignInventory), { filter: Object.assign(Object.assign({}, state.leftPanelSignInventory.filter), { signStatuses: [] }) }) });
        }
    }, { debug: false })
};
const persistedReducer = persistReducer(persistConfig, resetableRootReducer);
const sentryReduxEnhancer = Sentry.createReduxEnhancer(SENTRY_REDUX_CONFIG);
export const store = configureStore({
    middleware: (getDefaultMiddlewares) => {
        const middlewares = getDefaultMiddlewares({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).prepend(listener.middleware);
        if (config.enableReduxLogger) {
            return middlewares.concat(logger);
        }
        return middlewares;
    },
    enhancers: [sentryReduxEnhancer],
    reducer: persistedReducer
});
export const persistor = persistStore(store);
// reset all slices
export const resetRootState = createAsyncThunk(THUNK_PREFIX_ROOT_STATE, function (_payload, thunkAPI) {
    return __awaiter(this, void 0, void 0, function* () {
        thunkAPI.dispatch({ type: ACTION_RESET_ROOT_STATE });
    });
});
