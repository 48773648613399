import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { AnimatePresence } from 'framer-motion';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { FitInline } from '../../uikit/FitInline/FitInline';
import { ActionButton } from '../ActionButton/ActionButton';
import { ActionModal } from '../ActionModal/ActionModal';
import { BetaBadge } from '../BetaBadge/BetaBadge';
import { FloatingDropdown } from '../FloatingDropdown/FloatingDropdown';
import { CaretDouble, Check, Close, Download, FilterReset, Search, SegmentPoints, SegmentStreet, Sort } from '../icons';
import { Pill } from '../Pill/Pill';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import styles from './PanelHeader.module.css';
const DROP_MENU_MAX_HEIGHT = 250;
export function PanelHeader({ variant, title, onCollapse, resetFilter, exportXLS, label, multiselect, search, sort }) {
    var _a, _b;
    const exportModal = useFloatingDropdown({});
    const sortingModal = useFloatingDropdown({});
    const exportXLSArray = (Array.isArray(exportXLS) && exportXLS) || (exportXLS && [exportXLS]) || [];
    function handleExport(index) {
        var _a, _b;
        exportModal.hide();
        (_b = (_a = exportXLSArray[index]) === null || _a === void 0 ? void 0 : _a.onExport) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    function handleCollapse() {
        exportModal.hide();
        onCollapse();
    }
    function handleSort(id) {
        (sort === null || sort === void 0 ? void 0 : sort.onSort) && sort.onSort(id);
        sortingModal.hide();
    }
    let exportButton = undefined;
    if ((exportXLSArray === null || exportXLSArray === void 0 ? void 0 : exportXLSArray.length) === 1) {
        exportButton = (_jsx(ActionButton, { icon: _jsx(Download, {}), tooltip: {
                text: _jsx(Ditto, { componentId: "exportxls" })
            }, onClick: () => handleExport(0), disabled: (_a = exportXLSArray.at(0)) === null || _a === void 0 ? void 0 : _a.disabled, "data-test": "export-xls" }));
    }
    else if (exportXLSArray.length > 1) {
        exportButton = (_jsx("div", Object.assign({ ref: exportModal.buttonRef }, { children: _jsx(ActionButton, { icon: _jsx(Download, {}), isHover: exportModal.isOpen, tooltip: {
                    text: _jsx(Ditto, { componentId: "exportxls" })
                }, onClick: () => exportModal.toggle(), disabled: exportXLSArray.every((item) => item.disabled), "data-test": "export-xls" }) })));
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], variant && styles['container__' + variant]) }, { children: [_jsx(FitInline, Object.assign({ minFontSize: 18, defaultFontSize: 20 }, { children: _jsxs("div", Object.assign({ className: classes(styles['title'], label && styles['title-with-label']) }, { children: [title, label && (_jsxs(_Fragment, { children: [_jsx("span", { className: styles['label-spacer'] }), _jsx("span", Object.assign({ className: classes(styles['label-container']) }, { children: _jsx(BetaBadge, { content: label, inHeader: true }) }))] }))] })) })), multiselect && (_jsxs("div", Object.assign({ className: classes(styles['multiselect']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['multiselect-text']) }, { children: multiselect.text })), _jsx("div", Object.assign({ className: classes(styles['multiselect-button']) }, { children: _jsx(TooltipWrapper, Object.assign({ text: _jsx(Ditto, { componentId: "tooltip.resetseletion" }), position: "center" }, { children: _jsx(Button, { onClick: () => multiselect.onCloseClick(), icon: _jsx(Close, {}), variant: "square", color: "gray" }) })) }))] }))), !multiselect && (_jsxs("div", Object.assign({ className: styles['action-bar'] }, { children: [_jsx(ActionButton, { icon: _jsx(FilterReset, {}), tooltip: {
                            text: _jsx(Ditto, { componentId: "listpanel.resetallfilters" })
                        }, onClick: resetFilter.onReset, badge: resetFilter.filtersCount ? (_jsx(Pill, { number: resetFilter.filtersCount, size: "small", color: "black" })) : undefined, disabled: resetFilter.disabled, "data-test": "reset-filter" }), exportButton, !sort && !search && (_jsx(ActionButton, { icon: _jsx(CaretDouble, {}), tooltip: { text: _jsx(Ditto, { componentId: "leftpanel.collapse" }) }, onClick: handleCollapse, "data-test": "collapse-siderbar" })), sort && (_jsx("div", Object.assign({ ref: sortingModal.buttonRef }, { children: _jsx(ActionButton, { icon: _jsx(Sort, {}), isHover: sortingModal.isOpen, tooltip: {
                                text: _jsx(Ditto, { componentId: "tooltip.sorting" })
                            }, "data-test": "button-sorting", onClick: () => sortingModal.toggle() }) }))), search && (_jsx(ActionButton, { icon: _jsx(Search, {}), tooltip: {
                            text: _jsx(Ditto, { componentId: "tooltip.search" })
                        }, onClick: search.onClick, disabled: search.disabled, "data-test": "button-search" }))] }))), sort && (_jsx(AnimatePresence, { children: sortingModal.isOpen && (_createElement(FloatingDropdown, Object.assign({}, sortingModal.position, { key: 'sorting-dropdown', align: variant === 'fullwidth' ? 'right' : 'left', content: sortingModal.isOpen && (_jsx(ActionModal, Object.assign({ width: 275, maxHeight: DROP_MENU_MAX_HEIGHT }, { children: (_b = sort === null || sort === void 0 ? void 0 : sort.options) === null || _b === void 0 ? void 0 : _b.map((s) => (_jsxs(Fragment, { children: [_jsx(SelectboxEntry, { text: s.text, onClick: () => handleSort(s.id), trailingIcon: s.id === sort.current ? (_jsx(Check, {})) : undefined, "data-test": `select-${s.id}` }), s.showDividerBelow && _jsx(Divider, {})] }, `sort-${s.id}`))) }))), onClickOutside: () => sortingModal.hide() }))) })), _jsx(AnimatePresence, { children: exportModal.isOpen && (_createElement(FloatingDropdown, Object.assign({}, exportModal.position, { key: 'export-dropdown', align: variant === 'fullwidth' ? 'right' : 'left', content: _jsx(ActionModal, Object.assign({ maxHeight: DROP_MENU_MAX_HEIGHT }, { children: exportXLSArray === null || exportXLSArray === void 0 ? void 0 : exportXLSArray.map((item, index) => (_jsx(SelectboxEntry, { leadingIcon: index === 0 ? _jsx(SegmentPoints, {}) : _jsx(SegmentStreet, {}), text: item.label, onClick: () => handleExport(index), disabled: item.disabled, "data-test": item.dataTest }, `export-xls-${index}`))) }), 'export-modal'), onClickOutside: () => exportModal.hide() }))) })] })));
}
