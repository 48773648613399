export function isEmpty(value) {
    return value === undefined || value === null || value.length === 0;
}
export function checkRequired(value, requiredFields) {
    const emptyFields = [];
    for (const field of requiredFields) {
        if (!isEmpty(value[field])) {
            continue;
        }
        emptyFields.push(field);
    }
    return emptyFields;
}
