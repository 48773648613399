export function getRandomInt(min, max) {
    const roundedMin = Math.ceil(min);
    const roundedMax = Math.floor(max);
    return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin;
}
export function round1Decimal(num) {
    return Math.round(num * 10) / 10;
}
export function round2Decimal(num) {
    return Math.round(num * 100) / 100;
}
export function round6Decimal(num) {
    return Math.round(num * 1000000) / 1000000;
}
export function roundToHalf(num) {
    return Math.round(num * 2) / 2;
}
export function roundTo(num, range, fn = 'round') {
    const roundFn = Math[fn];
    for (let i = 0; i < range.length; i++) {
        if (num <= range[i]) {
            if (i === 0) {
                return roundFn(num / range[i]) * range[i];
            }
            else {
                return roundFn(num / range[i - 1]) * range[i - 1];
            }
        }
    }
    return roundFn(num / range[range.length - 1]) * range[range.length - 1];
}
export const NUMBER_REGEX = /^\d+((\.|,)\d*)?$|^$/;
export function normalize(val, min, max) {
    if (!val) {
        return min;
    }
    if (val > max) {
        return max;
    }
    if (val < min) {
        return min;
    }
    return (val - min) / (max - min);
}
