// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VW8V82wl_IoZfBd06wcf{padding:12px;font-size:16px;font-weight:600}.y2JFcf5iTq7wl8ExBQzq{padding:12px}.AHyWMVoou1UlQuzOHiWi{display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:24px}.AHyWMVoou1UlQuzOHiWi .xscd5Lwyd14hXbw8vYE2{height:42px;margin-bottom:0}.biMwuhvInFeWY1m6ymYM{display:flex;justify-content:center}.xscd5Lwyd14hXbw8vYE2{height:40px;margin-bottom:6px;border-radius:4px;background:#f1f2f4}`, "",{"version":3,"sources":["webpack://./src/components/SegmentDetails/SegmentDetails.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,cAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,YAAA,CACA,oCAAA,CACA,aAAA,CAGJ,4CACI,WAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,sBAAA,CAGJ,sBACI,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".address {\n    padding: 12px;\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.container-loading {\n    padding: 12px;\n}\n\n.two-columns {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-gap: 24px;\n}\n\n.two-columns .loading-bar {\n    height: 42px;\n    margin-bottom: 0;\n}\n\n.loader {\n    display: flex;\n    justify-content: center;\n}\n\n.loading-bar {\n    height: 40px;\n    margin-bottom: 6px;\n    border-radius: 4px;\n    background: #f1f2f4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"address": `VW8V82wl_IoZfBd06wcf`,
	"container-loading": `y2JFcf5iTq7wl8ExBQzq`,
	"two-columns": `AHyWMVoou1UlQuzOHiWi`,
	"loading-bar": `xscd5Lwyd14hXbw8vYE2`,
	"loader": `biMwuhvInFeWY1m6ymYM`
};
export default ___CSS_LOADER_EXPORT___;
