// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wkVvnWoiI0U7dXL3qSxo{overflow:hidden;display:flex;align-items:center;justify-content:center;height:100%}.NEUJ09QqQaXIOQi4CI_j{display:flex;align-items:center;justify-content:center;flex-direction:column;width:100%;height:100%;color:var(--primary-white)}.NEUJ09QqQaXIOQi4CI_j:hover{cursor:auto}.utPyHN7aAZSowSUi2RT5{margin-bottom:8px}.AOgjWiiEz5mIwa7XOQun{pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/ImageViewer/ZoomEnabledImage/ZoomEnabledImage.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAIJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,0BAAA,CAGJ,4BACI,WAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,mBAAA","sourcesContent":[".container {\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n\n/* TODO: check image viewer and delete these styles */\n.error {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    color: var(--primary-white);\n}\n\n.error:hover {\n    cursor: auto;\n}\n\n.icon {\n    margin-bottom: 8px;\n}\n\n.tag-wrapper {\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wkVvnWoiI0U7dXL3qSxo`,
	"error": `NEUJ09QqQaXIOQi4CI_j`,
	"icon": `utPyHN7aAZSowSUi2RT5`,
	"tag-wrapper": `AOgjWiiEz5mIwa7XOQun`
};
export default ___CSS_LOADER_EXPORT___;
