import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME } from '../../common/consts/mixpanel';
import { POPUP_SETTINGS_FIELDNAME } from '../../common/settings';
import { NotificationBanner } from '../../components/NotificationBanner/NotificationBanner';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useAppDispatch } from '../../state/hooks';
import { updateUserPopupSettingsTk } from '../../state/slices/auth';
export function PopupBannerContainer() {
    const dispatch = useAppDispatch();
    const webinarurl = useDittoWrapper({ componentId: 'assets.abtmteaser.webinarurl' });
    function handleClose() {
        dispatch(updateUserPopupSettingsTk(POPUP_SETTINGS_FIELDNAME.userClosedAbtmWebinarBanner));
    }
    function handleLinkClick() {
        window.open(webinarurl, '_blank');
        mixpanel.track(MIXPANEL_EVENT_NAME.clickABTMBanner);
    }
    return (_jsx(NotificationBanner, { text: _jsx(Ditto, { componentId: "assets.abtmfocuswebinarteaser.cta" }), link: { text: _jsx(Ditto, { componentId: "registerhere" }), onClick: handleLinkClick }, onClose: handleClose }));
}
