// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bjFroT1G9zqInyKhJntL{background:var(--primary-white);padding:12px;box-shadow:0 -1px 0 #f1f2f4 inset;display:flex;cursor:pointer;user-select:none}.bjFroT1G9zqInyKhJntL:hover,.R8X3EIaWxHLKnib1SFeo{background:#f1f2f4}.UCJp6ZhfC8phaoLrm2KV{background:#ecf1fd;box-shadow:0 1px 0 #1556eb inset,0 -1px 0 #1556eb inset}.UCJp6ZhfC8phaoLrm2KV+.UCJp6ZhfC8phaoLrm2KV{box-shadow:0 0 0,0 -1px 0 #1556eb inset}.tL9SBlcpNHLcTR2BuwmR+svg{margin-left:6px}.mMONFjPE0wWatqVEauyv{display:flex;flex-direction:column;align-items:flex-start;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Result/Result.module.css"],"names":[],"mappings":"AAAA,sBACI,+BAAA,CACA,YAAA,CACA,iCAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CAGJ,kDAEI,kBAAA,CAIJ,sBACI,kBAAA,CACA,uDAAA,CAGJ,4CACI,uCAAA,CAGJ,0BACI,eAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":[".container {\n    background: var(--primary-white);\n    padding: 12px;\n    box-shadow: 0 -1px 0 #f1f2f4 inset;\n    display: flex;\n    cursor: pointer;\n    user-select: none;\n}\n\n.container:hover,\n.container__hover {\n    background: #f1f2f4;\n    /* Primary/Grey-light */\n}\n\n.container__active {\n    background: #ecf1fd;\n    box-shadow: 0 1px 0 #1556eb inset, 0 -1px 0 #1556eb inset;\n}\n\n.container__active + .container__active {\n    box-shadow: 0 0 0, 0 -1px 0 #1556eb inset;\n}\n\n.checkbox + svg {\n    margin-left: 6px;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bjFroT1G9zqInyKhJntL`,
	"container__hover": `R8X3EIaWxHLKnib1SFeo`,
	"container__active": `UCJp6ZhfC8phaoLrm2KV`,
	"checkbox": `tL9SBlcpNHLcTR2BuwmR`,
	"content": `mMONFjPE0wWatqVEauyv`
};
export default ___CSS_LOADER_EXPORT___;
