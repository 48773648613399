// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YWEr2m3yNXCbaGFqiXU4{display:flex;position:absolute}.o3HmvktdRkNusRqBv3Mg{display:inline-block;border:2px solid var(--root-yellow-6);border-radius:2px 0 2px 2px;box-sizing:border-box}.lTHTPsYBvbwii8fxwci_{position:relative;top:0;left:-1px;padding:3px;height:100%;background:var(--root-yellow-6);border-radius:0 2px 2px 2px}`, "",{"version":3,"sources":["webpack://./src/components/ImageViewer/Tag/Tag.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,iBAAA,CAGJ,sBACI,oBAAA,CACA,qCAAA,CACA,2BAAA,CACA,qBAAA,CAGJ,sBACI,iBAAA,CACA,KAAA,CACA,SAAA,CACA,WAAA,CACA,WAAA,CACA,+BAAA,CACA,2BAAA","sourcesContent":[".container {\n    display: flex;\n    position: absolute;\n}\n\n.frame {\n    display: inline-block;\n    border: 2px solid var(--root-yellow-6);\n    border-radius: 2px 0 2px 2px;\n    box-sizing: border-box;\n}\n\n.tag-caption {\n    position: relative;\n    top: 0;\n    left: -1px;\n    padding: 3px;\n    height: 100%;\n    background: var(--root-yellow-6);\n    border-radius: 0 2px 2px 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YWEr2m3yNXCbaGFqiXU4`,
	"frame": `o3HmvktdRkNusRqBv3Mg`,
	"tag-caption": `lTHTPsYBvbwii8fxwci_`
};
export default ___CSS_LOADER_EXPORT___;
