import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { AnimatePresence, motion } from 'framer-motion';
import { dropdownVariants } from '../../common/consts/animations';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import styles from './DropMenu.module.css';
export function DropMenu({ control, menu, direction = 'down-right', variant, horizontal, isOpen, setIsOpen, floating, disabled, 'data-test': dataTest }) {
    const ref = useRef(null);
    const controlRef = useRef(null);
    const [floatingOffset, setFloatingOffset] = useState(0);
    useOnClickOutside(ref, () => setIsOpen(false));
    useLayoutEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        // Some advanced math to position the dropdown correctly,
        // needed because of position: inherit when floating is true.
        // Probably can be simplified.
        const offset = floating
            ? ((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.clientTop) !== null && _b !== void 0 ? _b : 0) +
                ((_e = (_d = (_c = ref.current) === null || _c === void 0 ? void 0 : _c.offsetParent) === null || _d === void 0 ? void 0 : _d.offsetHeight) !== null && _e !== void 0 ? _e : 0) -
                ((_h = (_g = (_f = ref.current) === null || _f === void 0 ? void 0 : _f.offsetParent) === null || _g === void 0 ? void 0 : _g.offsetTop) !== null && _h !== void 0 ? _h : 0) -
                ((_k = (_j = controlRef.current) === null || _j === void 0 ? void 0 : _j.offsetHeight) !== null && _k !== void 0 ? _k : 0)
            : undefined;
        setFloatingOffset(offset);
    }, [floating, ref, controlRef, isOpen]);
    return (_jsxs("div", Object.assign({ ref: ref, className: classes(styles['container'], floating && styles['container__floating'], variant && styles[`container__${variant}`]), "data-test": dataTest }, { children: [_jsx("div", Object.assign({ ref: controlRef, className: classes(styles['control'], disabled && styles['control__disabled']), onMouseDown: () => {
                    setIsOpen(!isOpen);
                } }, { children: control })), _jsx(AnimatePresence, { children: isOpen && (_jsxs("div", Object.assign({ className: styles['wrapper'], style: {
                        top: floatingOffset
                    } }, { children: [direction !== 'down-left' && (_jsx(motion.div, Object.assign({ variants: dropdownVariants(direction.includes('up') ? 'up' : 'down', horizontal && direction), initial: "initial", animate: "visible", exit: "exit" }, { children: _jsx("div", Object.assign({ className: classes(styles['anchor'], styles['anchor__' + direction]) }, { children: menu })) }), 'drop-menu')), direction === 'down-left' && (_jsx(motion.div, Object.assign({ variants: dropdownVariants('down', horizontal && direction), initial: "initial", animate: "visible", exit: "exit" }, { children: _jsx("div", Object.assign({ className: styles['bottom-line'] }, { children: _jsx("div", Object.assign({ className: classes(styles['anchor'], styles['anchor__' + direction]) }, { children: menu })) })) }), 'drop-menu'))] }), 'drop-menu-wrapper')) })] })));
}
