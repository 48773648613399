// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZEEczc0s2T8TEd7AoWiI{display:flex;gap:8px;align-items:center}.o3mHJQfBPRBPg4aqVPC5{flex-grow:1}.BYPuNSHkm8tS5yppt2Nw{line-height:130%;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;max-width:182px}.HUxJ8jlA1hRHDOzgjx6W{color:var(--primary-dark-blue, #222d39);font-size:12px;opacity:.4;margin-top:1px}`, "",{"version":3,"sources":["webpack://./src/components/NotificationOverlay/MarkerPreview/MarkerPreview.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA,CACA,kBAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,gBAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,eAAA,CAGJ,sBACI,uCAAA,CAEA,cAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".container {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n\n.title-block {\n    flex-grow: 1;\n}\n\n.title {\n    line-height: 130%;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    max-width: 182px;\n}\n\n.subtitle {\n    color: var(--primary-dark-blue, #222d39);\n\n    font-size: 12px;\n    opacity: 0.4;\n    margin-top: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZEEczc0s2T8TEd7AoWiI`,
	"title-block": `o3mHJQfBPRBPg4aqVPC5`,
	"title": `BYPuNSHkm8tS5yppt2Nw`,
	"subtitle": `HUxJ8jlA1hRHDOzgjx6W`
};
export default ___CSS_LOADER_EXPORT___;
