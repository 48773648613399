import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { Ditto } from 'ditto-react';
import { COMMON_ATTRIBUTE_TYPE } from '../../../../common/consts/attribute';
import { SUPPORTED_MARKER_ATTRIBUTE_TYPES } from '../../../../common/consts/marker';
import { getAttributeIcon, getAttributeSelectLabel } from '../../../../common/convert/attribute';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { Button } from '../../../../uikit/Button/Button';
import { ActionModal } from '../../../ActionModal/ActionModal';
import { DropMenu } from '../../../DropMenu/DropMenu';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Close, Plus, Task } from '../../../icons';
import { InfoBox } from '../../../InfoBox/InfoBox';
import { SelectboxEntry } from '../../../SelectboxEntry/SelectboxEntry';
import { SettingPanelLayout } from '../SettingPanelLayout/SettingPanelLayout';
import { SettingRow } from '../SettingRow/SettingRow';
import { TrailingSelect } from '../TrailingSelect/TrailingSelect';
import styles from './TaskCostsSettingPanel.module.css';
export function TaskCostsSettingPanel({ unitList, isReadOnly, handleAttributeDelete, handleAttributeChange, handleUnitChange, handleAttributeCreate, handleOptionDelete, handleOptionChange, handleOptionCreate, handleTaskCostsCancelClick, handleTaskCostsSaveClick, list }) {
    const newattribute = useDittoWrapper({ componentId: 'assets.newattribute' });
    const newoption = useDittoWrapper({ componentId: 'assets.newoption' });
    const addoption = useDittoWrapper({ componentId: 'assets.assettypes.attributes.addoption' });
    const refBottomAnchor = useRef(null);
    const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
    function scrollToBottom() {
        var _a;
        (_a = refBottomAnchor.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    }
    function handleAddPropertyClick(type) {
        scrollToBottom();
        setIsAddMenuOpen(false);
        handleAttributeCreate(type);
    }
    return (_jsxs(SettingPanelLayout, Object.assign({ overtitle: _jsx(Ditto, { componentId: "layer.marker" }), title: _jsx(Ditto, { componentId: "tasks.costs.costs" }), icon: _jsx(Task, {}), closeButton: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), color: "gray", onClick: () => handleTaskCostsCancelClick() }), bottomLeftButton: _jsx(DropMenu, { setIsOpen: setIsAddMenuOpen, isOpen: isAddMenuOpen, direction: "up-right", control: _jsx(Button, Object.assign({ variant: "modal-action", icon: _jsx(Plus, {}), color: "gray", disabled: isReadOnly }, { children: _jsx(Ditto, { componentId: "globalsettings.addproperty" }) })), menu: _jsx(ActionModal, Object.assign({ width: 180 }, { children: SUPPORTED_MARKER_ATTRIBUTE_TYPES.map((type) => (_jsx(SelectboxEntry, { variant: "slender", leadingIcon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: getAttributeIcon(type) })), text: getAttributeSelectLabel(type), onClick: () => handleAddPropertyClick(type) }, type))) })) }), bottomRightButton: _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => handleTaskCostsSaveClick(), disabled: isReadOnly }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }, { children: [_jsx(InfoBox, { className: styles['info-box'], content: isReadOnly ? (_jsx(Ditto, { componentId: "settings.onlyadminscaneditthefollowingelements" })) : (_jsx(Ditto, { componentId: "tasks.costs.settings.addnewpropertiesandcustomizetoyourneedstoenhanceorganizationandinsights" })) }), _jsx("div", Object.assign({ className: styles['content'] }, { children: list.map((attributeSchema, asIdx) => {
                    var _a, _b, _c;
                    return (_jsxs(SettingRow, Object.assign({ icon: "hidden", onDelete: isReadOnly ? undefined : () => handleAttributeDelete(asIdx), value: attributeSchema.name, autoFocus: attributeSchema.name === '', placeholder: newattribute, count: attributeSchema.type === COMMON_ATTRIBUTE_TYPE.select ||
                            attributeSchema.type === COMMON_ATTRIBUTE_TYPE.multiselect
                            ? (_a = attributeSchema.selectOptions) === null || _a === void 0 ? void 0 : _a.length
                            : undefined, onChange: attributeSchema.defaultId || isReadOnly
                            ? undefined
                            : (v) => handleAttributeChange(asIdx, v), innerIcon: getAttributeIcon(attributeSchema.type), innerIconOpacity: 0.4, trailingSelect: attributeSchema.type === COMMON_ATTRIBUTE_TYPE.number ? (_jsx(TrailingSelect, { variant: isReadOnly ? 'read-only' : 'default', value: (_b = unitList.find((u) => u.id === attributeSchema.unitId)) === null || _b === void 0 ? void 0 : _b.name, menuItems: unitList.map((unit) => (_jsx(SelectboxEntry, { text: unit.name, onClick: () => handleUnitChange(asIdx, unit.id) }, unit.id))) })) : undefined, expandable: attributeSchema.type === COMMON_ATTRIBUTE_TYPE.select ||
                            attributeSchema.type === COMMON_ATTRIBUTE_TYPE.multiselect }, { children: [(_c = attributeSchema.selectOptions) === null || _c === void 0 ? void 0 : _c.map((selectOption, slIdx) => (_jsx(SettingRow, { icon: "hidden", indentLevel: 1, onDelete: isReadOnly ? undefined : () => handleOptionDelete(asIdx, slIdx), value: selectOption.name, autoFocus: selectOption.name === '', placeholder: newoption, onChange: selectOption.defaultId || isReadOnly
                                    ? undefined
                                    : (v) => handleOptionChange(asIdx, slIdx, v) }, slIdx))), !isReadOnly && (_jsx(SettingRow, { icon: "hidden", indentLevel: 1, value: addoption, placeholder: addoption, onClick: () => handleOptionCreate(asIdx), variant: "button" }))] }), asIdx));
                }) })), _jsx("div", { ref: refBottomAnchor, className: styles['content-bottom-anchor'] })] })));
}
