// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wgT1moSQO5gIn2z6F7dt{width:100%;margin-top:-40px}.Nqw6TBntia0bI8EfDSSG{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-bottom:24px;margin-top:0px}.ufzr9syF4CIhPYE4CEKW{width:100%;display:flex;justify-content:flex-end;gap:12px}.cQJBGK_NIILczsz4dfJK{width:120px}.zFg5k4RQSmAcuiGTrDG9{margin-left:auto;width:120px}.uzJzkAQc3btJI527C3qj{width:120px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/AssigneeSelectModal/AssigneeSelectModal.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,gBAAA,CACA,WAAA,CAGJ,sBACI,WAAA","sourcesContent":[".footer {\n    width: 100%;\n    margin-top: -40px;\n}\n\n.footer-divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-bottom: 24px;\n    margin-top: 0px;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n}\n\n.button-reset {\n    width: 120px;\n}\n\n.button-cancel {\n    margin-left: auto;\n    width: 120px;\n}\n\n.button-confirm {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `wgT1moSQO5gIn2z6F7dt`,
	"footer-divider": `Nqw6TBntia0bI8EfDSSG`,
	"buttons": `ufzr9syF4CIhPYE4CEKW`,
	"button-reset": `cQJBGK_NIILczsz4dfJK`,
	"button-cancel": `zFg5k4RQSmAcuiGTrDG9`,
	"button-confirm": `uzJzkAQc3btJI527C3qj`
};
export default ___CSS_LOADER_EXPORT___;
