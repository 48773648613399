// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NbW0JVol56VRK55QCaXR{overflow:hidden;padding-bottom:56.25%;position:relative;height:0}.NbW0JVol56VRK55QCaXR iframe{left:0;top:0;height:100%;width:100%;position:absolute}`, "",{"version":3,"sources":["webpack://./src/components/YoutubeEmbed/YoutubeEmbed.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,qBAAA,CACA,iBAAA,CACA,QAAA,CAGJ,6BACI,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".video-responsive {\n    overflow: hidden;\n    padding-bottom: 56.25%;\n    position: relative;\n    height: 0;\n}\n\n.video-responsive iframe {\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video-responsive": `NbW0JVol56VRK55QCaXR`
};
export default ___CSS_LOADER_EXPORT___;
