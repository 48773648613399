import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { AssigneeSelect } from '../../EditorForms/AssigneeSelect/AssigneeSelect';
import { Close } from '../../icons';
import { Modal } from '../../Modal/Modal';
import styles from './AssigneeSelectModal.module.css';
export function AssigneeSelectModal({ options, selectedValues, onChange, onClose, onDone, title, showReset }) {
    const [values, setValues] = useState(selectedValues);
    function handleResetClick() {
        handleSelect([]);
    }
    function handleCloseClick() {
        onChange(selectedValues);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    function handleSelect(ids) {
        setValues(ids);
        onChange(ids);
    }
    function handleDoneClick() {
        onDone === null || onDone === void 0 ? void 0 : onDone(values);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    const assignee = (_jsx(AssigneeSelect, { options: options, selectedValues: values, onSelect: handleSelect }));
    return (_jsx(Modal, { width: 480, title: title || _jsx(Ditto, { componentId: "assignee" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: assignee, footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['footer-divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'], "data-test": "assignee-modal-buttons" }, { children: [showReset && (_jsx("div", Object.assign({ className: styles['button-reset'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleResetClick }, { children: _jsx(Ditto, { componentId: "appliedfilter.reset" }) })) }))), _jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleCloseClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })), _jsx("div", Object.assign({ className: styles['button-confirm'] }, { children: _jsx(Button, Object.assign({ color: "dark", variant: 'fullwidth', onClick: handleDoneClick }, { children: _jsx(Ditto, { componentId: "done" }) })) }))] }))] })), onClickOutside: handleCloseClick }));
}
