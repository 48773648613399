import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { TABLE_WIDTH } from '../../../../common/consts/table';
import useComponentWidth from '../../../../hooks/useComponentWidth';
import { TextOverflow } from '../../../TextOverflow/TextOverflow';
import { TooltipWrapper } from '../../../TooltipWrapper/TooltipWrapper';
import styles from './DataGridTableRowItemContent.module.css';
export function DataGridTableRowItemContent({ icon, hasPadding, maxLines = 1, text, textAlign, tooltipPosition = 'down' }) {
    const { ref, width } = useComponentWidth();
    const isXS = width > 0 ? width <= TABLE_WIDTH.s : false;
    const showText = (icon && !isXS) || (text && !icon);
    return (_jsx("div", Object.assign({ className: styles['container'], ref: ref }, { children: _jsxs("div", Object.assign({ className: classes(styles['content'], textAlign && styles[`content--${textAlign}`], hasPadding && styles['content--padding'], isXS && styles['size-xs']) }, { children: [icon && (_jsx(TooltipWrapper, Object.assign({ text: isXS ? text : undefined, arrow: tooltipPosition == 'up' ? 'down' : undefined }, { children: _jsx(_Fragment, { children: icon }) }))), showText && (_jsx(TextOverflow, { className: classes(styles['text'], maxLines === 1 && styles['text-overflow']), text: text, maxLines: maxLines, tooltip: "small", tooltipPosition: tooltipPosition, onWidthChange: () => { } }))] })) })));
}
