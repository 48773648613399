import { useMemo } from 'react';
import { DEBOUNCE_MAP_TILES_MS } from '../../common/consts/time';
import { createAssetTiles, createConditionTiles, createInspectionV2Tiles, createObjectTiles, createReferencingTiles, createSignInventoryTiles, createSignTiles, createTimePickerTiles } from '../../common/tiles';
import { ASSET_TILES_ENDPOINT, CONDITION_EDGE_TILES_ENDPOINT, CONDITION_OBJECT_TILES_ENDPOINT, CONDITION_POINT_TILES_ENDPOINT, createSignInventoryTileEndpoint, createSignTileEndpoint, INSPECTIONS_V2_TILES_ENDPOINT, REFERENCING_TILES_ENDPOINT } from '../../services/map';
import { useAppSelector } from '../../state/hooks';
import { useDebounce } from '../useDebounce';
export const useTiles = () => {
    const targetSchema = useAppSelector((state) => state.auth.targetSchema);
    const selectedTimepickerOption = useAppSelector((state) => state.presetsPanel.timepicker.selectedOption);
    const conditionFilter = useAppSelector((state) => state.leftPanelCondition.filter);
    const objectFilter = useAppSelector((state) => state.leftPanelObject.filter);
    const signFilter = useAppSelector((state) => state.leftPanelSign.filter);
    const trackControlFilter = useAppSelector((state) => state.leftPanelTrackControl.filter);
    const winterServiceFilter = useAppSelector((state) => state.leftPanelWinterService.filter);
    const signInventoryFilter = useAppSelector((state) => state.leftPanelSignInventory.filter);
    const assetFilter = useAppSelector((state) => state.leftPanelAsset.filter);
    const segmentsTiles = useMemo(() => {
        return createConditionTiles(CONDITION_EDGE_TILES_ENDPOINT, selectedTimepickerOption, conditionFilter);
    }, [conditionFilter, selectedTimepickerOption]);
    const segmentsFilteredOutTiles = useMemo(() => {
        return createTimePickerTiles(CONDITION_EDGE_TILES_ENDPOINT, selectedTimepickerOption);
    }, [selectedTimepickerOption]);
    const pointsTiles = useMemo(() => {
        return createConditionTiles(CONDITION_POINT_TILES_ENDPOINT, selectedTimepickerOption, conditionFilter);
    }, [conditionFilter, selectedTimepickerOption]);
    const pointsFilteredOutTiles = useMemo(() => {
        return createTimePickerTiles(CONDITION_POINT_TILES_ENDPOINT, selectedTimepickerOption);
    }, [selectedTimepickerOption]);
    const referencingTiles = useMemo(() => {
        return createReferencingTiles(REFERENCING_TILES_ENDPOINT);
    }, []);
    const objectsTiles = useMemo(() => {
        return createObjectTiles(CONDITION_OBJECT_TILES_ENDPOINT, selectedTimepickerOption, objectFilter);
    }, [selectedTimepickerOption, objectFilter]);
    const trackControlTiles = useMemo(() => {
        return createInspectionV2Tiles(INSPECTIONS_V2_TILES_ENDPOINT, selectedTimepickerOption, trackControlFilter);
    }, [selectedTimepickerOption, trackControlFilter]);
    const winterServiceTiles = useMemo(() => {
        return createInspectionV2Tiles(INSPECTIONS_V2_TILES_ENDPOINT, selectedTimepickerOption, winterServiceFilter);
    }, [selectedTimepickerOption, winterServiceFilter]);
    const signTiles = useMemo(() => {
        return createSignTiles(createSignTileEndpoint(targetSchema || ''), selectedTimepickerOption, signFilter);
    }, [targetSchema, selectedTimepickerOption, signFilter]);
    const signInventoryTiles = useMemo(() => {
        return createSignInventoryTiles(createSignInventoryTileEndpoint(targetSchema || ''), selectedTimepickerOption, signInventoryFilter);
    }, [targetSchema, selectedTimepickerOption, signInventoryFilter]);
    const debouncedAssetFilter = useDebounce(assetFilter, DEBOUNCE_MAP_TILES_MS);
    const assetTiles = useMemo(() => {
        return createAssetTiles(ASSET_TILES_ENDPOINT, selectedTimepickerOption, debouncedAssetFilter);
    }, [selectedTimepickerOption, debouncedAssetFilter]);
    return {
        segmentsTiles,
        segmentsFilteredOutTiles,
        pointsTiles,
        pointsFilteredOutTiles,
        referencingTiles,
        objectsTiles,
        trackControlTiles,
        winterServiceTiles,
        signTiles,
        signInventoryTiles,
        assetTiles
    };
};
