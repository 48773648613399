const MIN_PASSWORD_LENGTH = 10;
export function checkPassword(password, passwordConfirm) {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigitCase = /[0-9]/.test(password);
    const hasSpecialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);
    const hasMinimumLength = password.length >= MIN_PASSWORD_LENGTH;
    const hasPasswordMatch = passwordConfirm != undefined ? password.length > 0 && password == passwordConfirm : true;
    const isPasswordValid = hasUpperCase && hasDigitCase && hasSpecialCharacter && hasMinimumLength && hasPasswordMatch;
    return {
        hasUpperCase,
        hasDigitCase,
        hasSpecialCharacter,
        hasMinimumLength,
        hasPasswordMatch,
        isPasswordValid
    };
}
