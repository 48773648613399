import { COMMON_ATTRIBUTE_TYPE } from './attribute';
export const MARKER_STATUS = {
    open: 'open',
    inProgress: 'inProgress',
    closed: 'closed'
};
export var MARKER_STATUS_ID;
(function (MARKER_STATUS_ID) {
    MARKER_STATUS_ID[MARKER_STATUS_ID["open"] = 1] = "open";
    MARKER_STATUS_ID[MARKER_STATUS_ID["inProgress"] = 2] = "inProgress";
    MARKER_STATUS_ID[MARKER_STATUS_ID["closed"] = 3] = "closed";
})(MARKER_STATUS_ID || (MARKER_STATUS_ID = {}));
export const MARKER_PRIORITY = {
    high: 'high',
    medium: 'medium',
    low: 'low',
    noPriority: 'noPriority'
};
export var MARKER_PRIORITY_ID;
(function (MARKER_PRIORITY_ID) {
    MARKER_PRIORITY_ID[MARKER_PRIORITY_ID["noPriority"] = 4] = "noPriority";
    MARKER_PRIORITY_ID[MARKER_PRIORITY_ID["high"] = 3] = "high";
    MARKER_PRIORITY_ID[MARKER_PRIORITY_ID["medium"] = 2] = "medium";
    MARKER_PRIORITY_ID[MARKER_PRIORITY_ID["low"] = 1] = "low";
})(MARKER_PRIORITY_ID || (MARKER_PRIORITY_ID = {}));
export var MARKER_SOURCE_ID;
(function (MARKER_SOURCE_ID) {
    MARKER_SOURCE_ID[MARKER_SOURCE_ID["mobileApp"] = 1] = "mobileApp";
    MARKER_SOURCE_ID[MARKER_SOURCE_ID["webApp"] = 2] = "webApp";
    MARKER_SOURCE_ID[MARKER_SOURCE_ID["flutterApp"] = 3] = "flutterApp";
    MARKER_SOURCE_ID[MARKER_SOURCE_ID["iosApp"] = 4] = "iosApp";
})(MARKER_SOURCE_ID || (MARKER_SOURCE_ID = {}));
export var MARKER_INTERVAL;
(function (MARKER_INTERVAL) {
    MARKER_INTERVAL[MARKER_INTERVAL["oneTime"] = 1] = "oneTime";
    MARKER_INTERVAL[MARKER_INTERVAL["recurring"] = 2] = "recurring";
})(MARKER_INTERVAL || (MARKER_INTERVAL = {}));
export const MARKER_CHANGELOG_OPERATION = {
    create: 'create_marker',
    delete: 'delete_marker',
    updateRecurringFormula: 'update_marker_recurring_formula',
    updateTitle: 'update_marker_title',
    updateAddress: 'update_marker_address',
    updateAssignee: 'update_marker_assignees',
    updateStatus: 'update_marker_status',
    updatePriority: 'update_marker_priority',
    updateDescription: 'update_marker_description',
    updateCategory: 'update_marker_category',
    updateAudio: 'update_marker_audio',
    addPhotos: 'add_photos_to_marker',
    removePhotos: 'remove_photos_from_marker',
    linkAsset: 'link_marker_to_manual_inventory',
    unlinkAsset: 'unlink_marker_from_manual_inventory',
    statusReopen: 'recurring_marker_reopened',
    sharedByEmail: 'shared_by_email',
    createFile: 'create_file',
    deleteFile: 'delete_file',
    currentIterationClosed: 'current_task_iteration_closed',
    newIterationCreated: 'new_task_iteration_created',
    workingHourAdded: 'working_hour_added',
    workingHourRemoved: 'working_hour_removed',
    workingHourEdited: 'working_hour_edited',
    updateAttribute: 'update_marker_attribute'
};
export const SUPPORTED_MARKER_ATTRIBUTE_TYPES = [
    COMMON_ATTRIBUTE_TYPE.text,
    COMMON_ATTRIBUTE_TYPE.number,
    COMMON_ATTRIBUTE_TYPE.select,
    COMMON_ATTRIBUTE_TYPE.multiselect,
    COMMON_ATTRIBUTE_TYPE.date
];
export var MARKER_SECTION_ID;
(function (MARKER_SECTION_ID) {
    MARKER_SECTION_ID[MARKER_SECTION_ID["costs"] = 1] = "costs";
    // add more sections
})(MARKER_SECTION_ID || (MARKER_SECTION_ID = {}));
export var TASKS_TAB_INDEX;
(function (TASKS_TAB_INDEX) {
    TASKS_TAB_INDEX[TASKS_TAB_INDEX["details"] = 0] = "details";
    TASKS_TAB_INDEX[TASKS_TAB_INDEX["costs"] = 2] = "costs";
})(TASKS_TAB_INDEX || (TASKS_TAB_INDEX = {}));
