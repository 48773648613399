export class StreetartServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * for k8s tcp probes, prevents flooding logs with 404
     * @returns any A successful response.
     * @throws ApiError
     */
    streetartServiceRoot() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/'
        });
    }
    /**
     * @param body
     * @returns v1AssetAddFilesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetAddFiles(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/add-files',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetFoldersCreateResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetFoldersCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/folders/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetFoldersDeleteResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetFoldersDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/folders/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetFoldersListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetFoldersList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/folders/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetFoldersRearrangeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetFoldersRearrange(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/folders/rearrange',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetFoldersRenameResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetFoldersRename(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/folders/rename',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetRemoveFilesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetRemoveFiles(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/assets/remove-files',
            body: body
        });
    }
    /**
     * Do not add to api gateway, internal use only!
     * @param body
     * @returns v1InternalCleanUpStatusResponse A successful response.
     * @throws ApiError
     */
    streetartServiceInternalCleanUpStatus(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/internal/clean-up-inspection-status',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1InternalTrafficSignInventoryInspectionRemoveResponse A successful response.
     * @throws ApiError
     */
    streetartServiceInternalTrafficSignInventoryInspectionRemove(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/internal/traffic-sign-inventory/inspection/remove',
            body: body
        });
    }
    /**
     * Marks inspections in the status table as unexported
     * Do not add to api gateway, internal use only!
     * Don't use this endpoint to integrate with other services, use async messaging instead
     * @param body
     * @returns v1InternalTrafficSignsReexportResponse A successful response.
     * @throws ApiError
     */
    streetartServiceInternalTrafficSignsReexport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/internal/traffic-signs/reexport',
            body: body
        });
    }
    /**
     * Do not add to api gateway, internal use only!
     * @param body
     * @returns v1InternalUpdateCustomerResponse A successful response.
     * @throws ApiError
     */
    streetartServiceInternalUpdateCustomer(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/internal/traffic-signs/update-customer',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1CreateInventoryTypeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceCreateInventoryType(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/inventory-type/create-one',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1SaveInventoryTypeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceSaveInventoryType(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/inventory-type/save',
            body: body
        });
    }
    /**
     * deprecated, deprecation date 12.08.2024, TODO: remove after 12.05.2025
     * use /v2/manual-inventory/attributes/save
     * @param body
     * @returns v1ManualInventoryAddAttributeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryAddAttribute(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/add-attribute',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryAddPhotosResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryAddPhotos(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/add-photos',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventorySaveAttributeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventorySaveAttribute(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/attributes/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1BatchDeleteManualInventoriesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceBatchDeleteManualInventories(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/batch-delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1BulkCreateManualInventoryResponse A successful response.
     * @throws ApiError
     */
    streetartServiceBulkCreateManualInventory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/bulk-create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1BulkEditManualInventoriesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceBulkEditManualInventories(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/bulk-edit',
            body: body
        });
    }
    /**
     * deprecated, use /v1/manual-inventory/bulk-create instead
     * @param body
     * @returns v1CreateManualInventoryResponse A successful response.
     * @throws ApiError
     */
    streetartServiceCreateManualInventory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryExportResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryLightListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryLightList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/get-light-list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1GetManualInventoriesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceGetManualInventories(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/get-many',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1GetManualInventoryResponse A successful response.
     * @throws ApiError
     */
    streetartServiceGetManualInventory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/get-one',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1BulkCreateAssetFromImportToolResponse A successful response.
     * @throws ApiError
     */
    streetartServiceBulkCreateAssetFromImportTool(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/import-tool/bulk-create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1AssetImportProgressResponse A successful response.
     * @throws ApiError
     */
    streetartServiceAssetImportProgress(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/import-tool/get-progress',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryListOfIdsResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryListOfIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/list-of-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryRemovePhotosResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryRemovePhotos(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/remove-photos',
            body: body
        });
    }
    /**
     * endpoint to search assets to link it to the marker.
     * @param body
     * @returns v1ManualInventorySearchResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventorySearch(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/search',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param tileZ
     * @param tileX
     * @param tileY
     * @param query
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryTile(tileZ, tileX, tileY, query) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/manual-inventory/tile/{tile.z}/{tile.x}/{tile.y}',
            path: {
                'tile.z': tileZ,
                'tile.x': tileX,
                'tile.y': tileY
            },
            query: {
                query: query
            }
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryTypesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryTypes(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/types',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryUpdateAddressResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryUpdateAddress(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/update-address',
            body: body
        });
    }
    /**
     * deprecated, deprecation date 12.08.2024, TODO: remove after 12.05.2025
     * use /v2/manual-inventory/attributes/save
     * @param body
     * @returns v1ManualInventoryUpdateAttributeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryUpdateAttribute(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/update-attribute',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryUpdateNotesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryUpdateNotes(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/update-notes',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryUpdateTitleResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryUpdateTitle(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/update-title',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1ManualInventoryUpdateTypeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceManualInventoryUpdateType(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/manual-inventory/update-type',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryExportByEmailResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryExportByEmail(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/export-by-email',
            body: body
        });
    }
    /**
     * deprecated, use AssetRemoveFiles
     * @param body
     * @returns v1TrafficSignInventoryFileDeleteResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryFileDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/file/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryFoldersCreateResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryFoldersCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/folders/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryFoldersDeleteResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryFoldersDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/folders/delete',
            body: body
        });
    }
    /**
     * Message definitions are in traffic_signs_folders.proto
     * @param body
     * @returns v1TrafficSignInventoryFoldersListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryFoldersList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/folders/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryFoldersRearrangeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryFoldersRearrange(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/folders/rearrange',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryFoldersRenameResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryFoldersRename(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/folders/rename',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryInfoByIDResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryInfoById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/info-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryInfoByIDListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryInfoByIdList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/info-by-id-list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryListOfIdsResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryListOfIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/list-of-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1SignGetPropertySettingsResponse A successful response.
     * @throws ApiError
     */
    streetartServiceSignGetPropertySettings(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/property-settings/get',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1SignSavePropertySettingsResponse A successful response.
     * @throws ApiError
     */
    streetartServiceSignSavePropertySettings(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/property-settings/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1SignPropertyValueCreateResponse A successful response.
     * @throws ApiError
     */
    streetartServiceSignPropertyValueCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/property/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1SignPropertyValueUpdateResponse A successful response.
     * @throws ApiError
     */
    streetartServiceSignPropertyValueUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/property/update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignConfirmResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignConfirm(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/confirm',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignCreateResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignDeleteResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignUpdateAddressResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignUpdateAddress(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/update/address',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignUpdateDamageClassResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignUpdateDamageClass(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/update/damage-class',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignUpdateOrientationResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignUpdateOrientation(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/update/orientation',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventorySignUpdateTypeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventorySignUpdateType(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/sign/update/type',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryStatusListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryStatusList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/statuses/list',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param customer because customer is in the url path, so
     * cannot remove customer for backward compatibility but do not read it in controller
     * @param tileZ
     * @param tileX
     * @param tileY
     * @param query
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryTile(customer, tileZ, tileX, tileY, query) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/traffic-sign-inventory/tile/{customer}/{tile.z}/{tile.x}/{tile.y}',
            path: {
                customer: customer,
                'tile.z': tileZ,
                'tile.x': tileX,
                'tile.y': tileY
            },
            query: {
                query: query
            }
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryTreeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryTree(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/tree',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignInventoryTypesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryTypes(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/types',
            body: body
        });
    }
    /**
     * deprecated, use AssetAddFiles
     * @param body
     * @returns v1TrafficSignInventoryUploadFilesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignInventoryUploadFiles(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-sign-inventory/upload-files',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignsInfoByIDResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsInfoById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/info-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignsInfoByIDListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsInfoByIdList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/info-by-id-list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignsListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignsListCountResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsListCount(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/list-count',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param customer because customer is in the url path, so
     * cannot remove customer for backward compatibility but do not read it in controller
     * @param tileZ
     * @param tileX
     * @param tileY
     * @param query
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsTile(customer, tileZ, tileX, tileY, query) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/traffic-signs/tile/{customer}/{tile.z}/{tile.x}/{tile.y}',
            path: {
                customer: customer,
                'tile.z': tileZ,
                'tile.x': tileX,
                'tile.y': tileY
            },
            query: {
                query: query
            }
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignsTreeResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsTree(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/tree',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrafficSignsTypesResponse A successful response.
     * @throws ApiError
     */
    streetartServiceTrafficSignsTypes(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/traffic-signs/types',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1UnitsListResponse A successful response.
     * @throws ApiError
     */
    streetartServiceUnitsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/units/list',
            body: body
        });
    }
}
