import { generatedIconPrefix } from './constants';
export function parseIconId(id) {
    if (!id.startsWith(generatedIconPrefix)) {
        console.error('parseIconId failed to parse prefix', id);
        return;
    }
    const params = new URLSearchParams(id.replace(generatedIconPrefix, ''));
    const type = params.get('type');
    if (!type) {
        console.error('parseIconId failed to get type', id);
        return;
    }
    switch (type) {
        case 'icon-with-attachment': {
            const base = params.get('base') || '';
            const attachment = params.get('attachment') || '';
            const attachmentBackground = params.get('attachmentBackground') || '';
            return { type, base, attachment, attachmentBackground };
        }
        default:
            console.error('unknown generated icon type', id);
            return;
    }
}
