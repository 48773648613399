import { MARKER_PRIORITY_ID, MARKER_STATUS_ID } from '../consts/marker';
export function pointToMarkerSource(point) {
    return {
        linkedPoint: {
            pointUuid: point.systems_point_id,
            imageName: point.img_name,
            imageFolder: point.img_folder,
            displayName: point.display_name,
            imageURL: point.image_thumbnail_url
        },
        coordinates: point.coordinates,
        priorityId: MARKER_PRIORITY_ID.low,
        statusId: MARKER_STATUS_ID.open
    };
}
export function signToMarkerSource(sign) {
    return {
        linkedPoint: {
            pointUuid: sign.pointUuid,
            imageFolder: sign.inspectionUuid.toUpperCase(),
            imageName: sign.imageName,
            displayName: sign.displayName,
            imageURL: sign.imageUrl
        },
        coordinates: sign.offsetPosition,
        priorityId: MARKER_PRIORITY_ID.medium,
        statusId: MARKER_STATUS_ID.open,
        address: sign.address
    };
}
export function signInventoryToMarkerSource(signInventory, taskTitle, taskDescription, taskPriorityId) {
    const linkedPoint = signInventory.pointUuid
        ? {
            pointUuid: signInventory.pointUuid,
            imageFolder: signInventory.inspectionUuid,
            imageName: signInventory.imageName,
            displayName: signInventory.displayName,
            imageURL: signInventory.imageUrl
        }
        : undefined;
    return {
        linkedPoint,
        coordinates: signInventory.offsetPosition,
        priorityId: taskPriorityId,
        statusId: MARKER_STATUS_ID.open,
        title: taskTitle,
        description: taskDescription,
        magicInputValues: {
            title: taskTitle,
            priorityId: taskPriorityId,
            description: taskDescription
        },
        createdFrom: 'sign',
        address: signInventory.address
    };
}
export function assetToMarkerSource(asset) {
    return {
        coordinates: asset.coordinates,
        priorityId: MARKER_PRIORITY_ID.noPriority,
        statusId: MARKER_STATUS_ID.open,
        createdFrom: 'asset',
        linkedAsset: {
            id: asset.id,
            typeId: asset.inventoryTypeId,
            title: asset.title,
            address: asset.address,
            photos: asset.photos
        },
        address: asset.address
    };
}
