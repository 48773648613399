// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Aqt47hrST7BN7sfE7SgQ{display:inline-flex;align-items:center;justify-content:center}.FOTAKLnev1pRddcFa5k5{width:24px;height:24px}`, "",{"version":3,"sources":["webpack://./src/components/IconResizer/IconResizer.module.css"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,kBAAA,CACA,sBAAA,CAEJ,sBACI,UAAA,CACA,WAAA","sourcesContent":[".container {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n}\n.icon {\n    width: 24px;\n    height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Aqt47hrST7BN7sfE7SgQ`,
	"icon": `FOTAKLnev1pRddcFa5k5`
};
export default ___CSS_LOADER_EXPORT___;
