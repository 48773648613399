/**
 * This is neccesary for the exact tracking of which notification setting type updated,
 * otherwise it will get all values from the api and send them back, which will track the ones did not update
 */
export function getNotificationSettingProps(settings) {
    return {
        webApp: {
            assigneeChanged: settings.webApp.assigneeChanged,
            dueDateOverdue: settings.webApp.dueDateOverdue,
            statusClosed: settings.webApp.statusClosed
        },
        email: {
            assigneeChanged: settings.email.assigneeChanged,
            dueDateOverdue: settings.email.dueDateOverdue,
            statusClosed: settings.email.statusClosed
        }
    };
}
