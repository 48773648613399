import { useEffect, useRef } from 'react';
export function useCurrentTabScrollTop({ refContent, currentTab }) {
    const prevTab = useRef(currentTab);
    useEffect(() => {
        var _a;
        if (prevTab.current !== currentTab) {
            (_a = refContent.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0 });
        }
        prevTab.current = currentTab;
    }, [currentTab, refContent]);
}
