// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kPF2pR9jfAL00sSu8uTU{border-radius:4px 4px 0 0;background:#f0f0f0}.Kbh0D_nsePhpVG8rNB_h{display:flex;align-items:center;gap:8px;min-height:30px;flex:1 0 0;font-size:14px}.Dfci75e86dRvV2WDBcSG{display:flex;align-items:center;align-self:stretch;gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/Sign/Sign.module.css"],"names":[],"mappings":"AAAA,sBACI,yBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,eAAA,CACA,UAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".image {\n    border-radius: 4px 4px 0 0;\n    background: #f0f0f0;\n}\n\n.info {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    min-height: 30px;\n    flex: 1 0 0;\n    font-size: 14px;\n}\n\n.title-row {\n    display: flex;\n    align-items: center;\n    align-self: stretch;\n    gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `kPF2pR9jfAL00sSu8uTU`,
	"info": `Kbh0D_nsePhpVG8rNB_h`,
	"title-row": `Dfci75e86dRvV2WDBcSG`
};
export default ___CSS_LOADER_EXPORT___;
