var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { Ditto } from 'ditto-react';
import { WHITE_COLOR } from '../../common/color';
import { ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { TAB_LIST_2 } from '../../common/consts/panelTab';
import { getWinterServiceTypeLabel, WinterServiceIcon } from '../../common/convert/winterService';
import { inspectionSortingOptions } from '../../common/sort';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { LeftPanelListHeader } from '../../components/LeftPanelListHeader/LeftPanelListHeader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { useWinterServiceFilterHandlers } from '../../hooks/filters/useWinterServiceFilterHandlers';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHighlightedFeature, setHighlightedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { exportWinterServiceTk, fetchInspectionV2List, resetWinterServiceFilter, setWinterServiceActiveTabKey, setWinterServiceCurrentPage, setWinterServiceFilter, setWinterServiceSorting, setWinterServiceUseMapBounds } from '../../state/slices/leftPanelWinterService';
import { Button } from '../../uikit/Button/Button';
import { RadioSelectbox } from '../../uikit/RadioSelectbox/RadioSelectbox';
const WINTER_SERVICE_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'winter-service'
    }, isLoading: true }, idx)));
export function LeftPanelWinterServiceContainer() {
    const panelContainerRef = useRef(null);
    const { addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { activeTabKey, filter, currentPage, sort, resultList, resultFetchStatus, totalCount, useMapBounds } = useAppSelector((state) => state.leftPanelWinterService);
    const { winterServiceModeList, winterServiceAgeList } = useAppSelector((state) => state.shared);
    const { userAccount } = useAppSelector((state) => state.auth);
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { handleTypeFilterClick, handleAgeFilterClick } = useWinterServiceFilterHandlers();
    const dateTimeFormatter = useDateTimeFormatter();
    const { formatAddress } = useFormatter();
    const filtersCount = filter.ages.length + (filter.type ? 1 : 0);
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleSort(id) {
        dispatch(setWinterServiceSorting(id));
        dispatch(setWinterServiceCurrentPage(PAGE_NUMBER_FIRST));
    }
    function handleClickTab(tabId) {
        dispatch(setWinterServiceActiveTabKey(tabId));
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setWinterServiceCurrentPage(toPage));
    }
    function handleResetFilter() {
        dispatch(resetWinterServiceFilter());
    }
    function handleTypeGroupClick() {
        dispatch(setWinterServiceFilter({
            type: undefined
        }));
    }
    function handleExport() {
        return __awaiter(this, void 0, void 0, function* () {
            addModal({
                id: 'ExportModal',
                props: {
                    onStartExportClick: () => dispatch(exportWinterServiceTk()),
                    requestList: [
                        {
                            type: 'winter-service',
                            count: totalCount
                        }
                    ]
                }
            });
        });
    }
    function handleAgeGroupClick() {
        dispatch(setWinterServiceFilter({
            ages: filter.ages.length === 0 ? winterServiceAgeList.map((it) => it.age) : []
        }));
    }
    function handleCheckMapView() {
        dispatch(setWinterServiceUseMapBounds(!useMapBounds));
    }
    function handleClickInspection(id) {
        dispatch(setSelectedFeature({
            type: 'winter-service',
            id
        }));
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    const resultsCurrentPage = (() => {
        if (resultFetchStatus === 'loading') {
            return WINTER_SERVICE_LOADING_LIST;
        }
        if (resultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return resultList.map((item) => {
            var _a;
            return (_jsx(Result, { content: {
                    type: 'winter-service',
                    value: {
                        name: item.userName,
                        imagePreview: item.imagePreview,
                        dateTime: dateTimeFormatter(item.createdAt),
                        startAddress: formatAddress(item.startAddress, { isShort: true }),
                        endAddress: formatAddress(item.endAddress, { isShort: true }),
                        winterServiceTypes: (_a = item.winterServiceModes) === null || _a === void 0 ? void 0 : _a.map((i) => ({
                            icon: _jsx(WinterServiceIcon, { type: i }),
                            text: getWinterServiceTypeLabel(i)
                        })),
                        markerCount: item.taskCount
                    }
                }, isActive: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.id, onClick: () => handleClickInspection(item.id), onMouseEnter: () => dispatch(setHighlightedFeature({
                    type: 'winter-service',
                    id: item.id
                })), onMouseLeave: () => dispatch(clearHighlightedFeature()) }, item.id));
        });
    })();
    const winterServiceFilters = (_jsxs(LayerFilter, { children: [_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "winterservice.operationmode" }), checkState: filter.type ? 'checked' : 'not-checked', onCheckboxClick: handleTypeGroupClick }, { children: winterServiceModeList.map((i) => (_jsx(RadioSelectbox, { onClick: () => handleTypeFilterClick(i.id), isChecked: filter.type === i.id, icon: _jsx(WinterServiceIcon, { type: i.id }), content: getWinterServiceTypeLabel(i.id), color: "black", count: i.count }, `ws-type-option-${i.id}`))) })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "winterservice.lastwinterservice" }), checkState: getCheckboxCheckState(winterServiceAgeList.length, filter.ages.length), onCheckboxClick: handleAgeGroupClick }, { children: winterServiceAgeList.map((it) => (_jsx(FilterSelectbox, { onClick: () => handleAgeFilterClick(it.age), isChecked: filter.ages.includes(it.age), content: _jsx(CategoryBadge, { category: it.label, backgroundColor: it.color, color: it.age === 0 ? WHITE_COLOR : undefined }), color: 'black', trailingElement: it.count }, `age-month-option-${it.age}`))) }))] }));
    const bottomMenu = (() => {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
    })();
    useEffect(() => {
        if (useMapBounds) {
            dispatch(fetchInspectionV2List());
        }
    }, [dispatch, currentPage, sort, selectedOption, filter, useMapBounds, bounds]);
    useEffect(() => {
        if (!useMapBounds) {
            dispatch(fetchInspectionV2List());
        }
    }, [dispatch, currentPage, sort, selectedOption, filter, useMapBounds]);
    return (_jsx(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: _jsx(Ditto, { componentId: "filter.category.winterservice" }), resetFilter: {
                filtersCount,
                onReset: handleResetFilter,
                disabled: filtersCount === 0
            }, exportXLS: {
                onExport: handleExport,
                disabled: totalCount === 0 || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)
            }, onCollapse: handleCollapse }), tabBar: _jsx(PanelButtonGroup, { tabs: TAB_LIST_2, activeKey: activeTabKey, onClickTab: handleClickTab }), listHeader: _jsx(LeftPanelListHeader, { onFilterCurrentMapViewClick: handleCheckMapView, filterUseMapBounds: !!useMapBounds, resultsCount: totalCount, sort: {
                options: inspectionSortingOptions,
                current: sort,
                onSort: handleSort
            } }), pagination: activeTabKey === 'list' && resultList.length !== 0 ? (_jsx(Pagination, { totalItems: totalCount, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined, ref: panelContainerRef, activeTabKey: activeTabKey, bottomMenu: bottomMenu }, { children: activeTabKey === 'list' ? resultsCurrentPage : winterServiceFilters })));
}
