import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ActionButton } from '../ActionButton/ActionButton';
import { Plus } from '../icons';
import { WorkingHours } from '../WorkingHours/WorkingHours';
import styles from './WorkingHoursGroup.module.css';
export function WorkingHoursGroup({ workingHours, totalSpentTime, onClick, disabled }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], disabled && styles['disabled']) }, { children: [_jsxs("div", Object.assign({ className: styles['header'] }, { children: [_jsx("div", Object.assign({ className: styles['header-title'] }, { children: _jsx(Ditto, { componentId: "tasks.costs.workinghours" }) })), _jsx("div", Object.assign({ className: styles['header-button'] }, { children: _jsx(ActionButton, { icon: _jsx(Plus, {}), onClick: onClick }) }))] })), _jsx("div", Object.assign({ className: styles['entries'] }, { children: workingHours.map((item) => (_jsx(WorkingHours, { started: item.started, finished: item.finished, user: item.user, editable: item.editable, onClick: item.onClick }, item.id))) })), _jsx("div", Object.assign({ className: styles['total'] }, { children: _jsx(Ditto, { componentId: "tasks.costs.workinghours.totaltimespentxhours", variables: { TotalTimeSpent: totalSpentTime } }) }))] })));
}
WorkingHoursGroup.displayName = 'WorkingHoursGroup';
