var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { MAP_CURSOR } from '../../common/consts/map';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { uniqueV2 } from '../../common/utils/array';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { bulkCreateManualInventory } from '../../services/asset';
import { tryLinkFiles, tryUploadPhotos } from '../../services/fileManager';
import { batchDeleteMarkers, linkMarkersToAssets } from '../../services/marker';
import { setIsLoadingActionbar } from './actionbar';
import { setSelectedFeature } from './feature';
import { fetchAssetsTk } from './leftPanelAsset';
import { fetchMarkers } from './leftPanelMarker';
import { invalidateCachedLinkedMarkers } from './linkedMarkers';
import { fetchMarkerGeoJSON, setMapCursor } from './mainMap';
import { addNotificationMessage } from './notification';
import { fetchAssetTypes } from './shared';
const initialState = {
    createStatus: 'idle',
    markerIdsToLink: [],
    temporaryMarkerIds: [],
    step: 0,
    type: 'floating',
    filesToAdd: []
};
export const removeTemporaryMarkers = createAppAsyncThunk('assetCreator/removeTemporaryMarkers', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const { temporaryMarkerIds } = getState().assetCreator;
    yield batchDeleteMarkers({ ids: temporaryMarkerIds });
    yield dispatch(fetchMarkerGeoJSON());
    dispatch(removeAssetCreatorTemporaryMarkerIds(temporaryMarkerIds));
    dispatch(setIsLoadingActionbar(false));
}));
export const uploadCreateLinkAsset = createAppAsyncThunk('assetCreator/uploadCreateLinkAsset', (data, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    dispatch(setIsLoadingActionbar(true));
    let photoUuids = [];
    if (data.source) {
        const source = data.source;
        if ('marker' in source) {
            photoUuids = source.marker.photos.map((photo) => ({
                uuid: photo.uuid,
                createdAt: photo.createdAt
            }));
        }
        else if ('point' in source) {
            photoUuids = ((_a = (yield tryLinkFiles([source.point].map((point) => ({
                pointUuid: point.systems_point_id,
                imageFolder: point.img_folder,
                imageName: point.img_name
            }))))) !== null && _a !== void 0 ? _a : []).map((uuid) => ({
                uuid: uuid,
                createdAt: source.point.time_stamp
            }));
        }
        else if ('signInventory' in source) {
            const link = {
                pointUuid: source.signInventory.pointUuid,
                imageFolder: source.signInventory.inspectionUuid,
                imageName: source.signInventory.imageName
            };
            photoUuids = ((_b = (yield tryLinkFiles([link]))) !== null && _b !== void 0 ? _b : []).map((uuid) => ({
                uuid: uuid,
                createdAt: source.signInventory.timestamp
            }));
        }
    }
    const uploadedUuids = (yield tryUploadPhotos(data.photosToUpload, 'manual-inventory')) || [];
    const photoIds = uploadedUuids
        .map((uuid) => ({
        uuid,
        createdAt: new Date().toISOString()
    }))
        .concat(photoUuids);
    const res = yield bulkCreateManualInventory({
        manualInventories: [
            Object.assign(Object.assign({}, data.draft), { files: data.filesToLink, photoUuids: photoIds.length > 0 ? photoIds : undefined })
        ]
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
        return;
    }
    if (data.fromMarkerId || ((_d = (_c = data.markerIdsToLink) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 0) {
        const markerIds = [];
        if (data.fromMarkerId) {
            markerIds.push(data.fromMarkerId);
        }
        if (data.markerIdsToLink) {
            markerIds.push(...data.markerIdsToLink);
        }
        const linkRes = yield linkMarkersToAssets({
            linkData: [
                {
                    manualInventoryId: (_e = res.result.manualInventoryIds.at(0)) !== null && _e !== void 0 ? _e : 0,
                    markersIds: uniqueV2(markerIds)
                }
            ]
        });
        if (linkRes.errorCode !== RESPONSE_ERROR_CODE.success) {
            dispatch(addNotificationMessage({ type: 'error' }));
            return;
        }
        if (getState().layer.selectedLayerId === 'marker') {
            dispatch(fetchMarkers());
        }
    }
    if (typeof data.doNotNotify === 'undefined' || !data.doNotNotify) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: {
                    componentId: data.fromMarkerId
                        ? 'assets.newassetcreatedandlinkedwithtask'
                        : 'toast.successfullycreated'
                }
            },
            action: {
                type: 'dispatch',
                label: {
                    componentId: 'toast.open'
                },
                content: [
                    setSelectedFeature({
                        type: 'asset',
                        id: (_f = res.result.manualInventoryIds.at(0)) !== null && _f !== void 0 ? _f : 0
                    })
                ]
            }
        }));
    }
    dispatch(setMapCursor(MAP_CURSOR.default));
    dispatch(setIsLoadingActionbar(false));
    dispatch(resetAssetCreator());
    dispatch(fetchAssetsTk());
    dispatch(fetchAssetTypes());
    dispatch(invalidateCachedLinkedMarkers());
    return res.result.manualInventoryIds.at(0);
}));
export const assetCreatorSlice = createSlice({
    name: 'assetCreator',
    initialState,
    reducers: {
        reset: () => initialState,
        setAssetCreatorSource: (state, action) => {
            state.source = action.payload;
        },
        setAssetCreatorStep: (state, action) => {
            state.step = action.payload;
        },
        setAssetCreatorType: (state, action) => {
            state.type = action.payload;
        },
        addAssetCreatorMarkerIdsToLink: (state, action) => {
            state.markerIdsToLink = uniqueV2([...state.markerIdsToLink, ...action.payload]);
        },
        removeAssetCreatorMarkerIdsToLink: (state, action) => {
            var _a;
            state.markerIdsToLink = (_a = state.markerIdsToLink) === null || _a === void 0 ? void 0 : _a.filter((id) => !action.payload.includes(id));
        },
        addAssetCreatorTemporaryMarkerIds: (state, action) => {
            state.temporaryMarkerIds = uniqueV2([...state.temporaryMarkerIds, ...action.payload]);
        },
        removeAssetCreatorTemporaryMarkerIds: (state, action) => {
            state.temporaryMarkerIds = state.temporaryMarkerIds.filter((id) => !action.payload.includes(id));
        },
        addAssetCreatorFiles: (state, action) => {
            state.filesToAdd = [...state.filesToAdd, ...action.payload];
        },
        removeAssetCreatorFiles: (state, action) => {
            state.filesToAdd = state.filesToAdd.filter((item) => !action.payload.find((file) => file === item.uuid));
        }
    },
    extraReducers(builder) {
        builder
            .addCase(uploadCreateLinkAsset.pending, (state) => {
            state.createStatus = 'loading';
        })
            .addCase(uploadCreateLinkAsset.rejected, (state) => {
            state.createStatus = 'failed';
        })
            .addCase(uploadCreateLinkAsset.fulfilled, (state) => {
            state.createStatus = 'succeeded';
        });
    }
});
export const { reset: resetAssetCreator, setAssetCreatorSource, setAssetCreatorStep, setAssetCreatorType, addAssetCreatorMarkerIdsToLink, removeAssetCreatorMarkerIdsToLink, addAssetCreatorTemporaryMarkerIds, removeAssetCreatorTemporaryMarkerIds, addAssetCreatorFiles, removeAssetCreatorFiles } = assetCreatorSlice.actions;
export default assetCreatorSlice.reducer;
