// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QhvL0W5656WP_bo4kWCX{width:100%;height:calc(100% - 72px);margin-top:72px;border-radius:24px 24px 0 0}.vBr_BqFTPzn2x1MZBOyi{display:flex;align-items:center;justify-content:center;width:100%;height:65vh}.UqtDd6B_bNdXUKJJsvPr{padding-left:8px}._2Ujdmp6YwPdNzn0jd8Yg{display:flex;align-items:center;justify-content:space-between;margin-bottom:20px;margin-left:8px}._2Ujdmp6YwPdNzn0jd8Yg a{margin-top:0;white-space:nowrap}.DmamkObkOzrfpsK1dOSJ{display:flex;align-items:center;height:18px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/CoWorkerModal/CoWorkerModal.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,wBAAA,CACA,eAAA,CACA,2BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CAGJ,sBACI,gBAAA,CAGJ,uBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CAGJ,yBACI,YAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":[".container {\n    width: 100%;\n    height: calc(100% - 72px);\n    margin-top: 72px;\n    border-radius: 24px 24px 0 0;\n}\n\n.loading {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 65vh;\n}\n\n.title {\n    padding-left: 8px;\n}\n\n.info {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    margin-left: 8px;\n}\n\n.info a {\n    margin-top: 0;\n    white-space: nowrap;\n}\n\n.info-link {\n    display: flex;\n    align-items: center;\n    height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QhvL0W5656WP_bo4kWCX`,
	"loading": `vBr_BqFTPzn2x1MZBOyi`,
	"title": `UqtDd6B_bNdXUKJJsvPr`,
	"info": `_2Ujdmp6YwPdNzn0jd8Yg`,
	"info-link": `DmamkObkOzrfpsK1dOSJ`
};
export default ___CSS_LOADER_EXPORT___;
