var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { isDefined, uniqueV2 } from '../../common/utils/array';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getLightLinkedMarkers as fetchLinkedMarkers } from '../../services/marker';
const initialState = {
    cachedLinkedMarkers: {},
    cachedNoResults: [],
    cachedLinkedMarkersInvalidatedAt: 0
};
/**
 * Get an array of entries [assetId, linkedMarkers]
 * given a cache Record and the marker ids to retrieve (keys).
 */
const getLinkedMarkersByKeys = (map, keys) => {
    const assetIds = uniqueV2(Array.from(map.values())
        .flatMap((markers) => markers)
        .filter((marker) => keys.includes(marker.id))
        .map((marker) => marker.manualInventoryId)
        .filter(isDefined));
    return assetIds.map((aId) => [aId, map.get(aId)]);
};
const refreshLinkedMarkers = createAppAsyncThunk('linkedMarkers/refreshLinkedMarkers', (markerIds) => __awaiter(void 0, void 0, void 0, function* () {
    const idsToFetch = uniqueV2(markerIds);
    if (idsToFetch.length === 0) {
        return [];
    }
    const response = yield fetchLinkedMarkers({ ids: idsToFetch });
    if (response.errorCode !== RESPONSE_ERROR_CODE.success || !response.result) {
        return [];
    }
    return response.result.markers;
}));
export const getLinkedMarkers = createAppAsyncThunk('linkedMarkers/getLinkedMarkers', (markerIds, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { cachedLinkedMarkers, cachedNoResults } = getState().linkedMarkers;
    const cachedMap = new Map(Object.entries(cachedLinkedMarkers).map(([aId, markers]) => [Number(aId), markers]));
    const cachedMarkerIds = Array.from(cachedMap.values())
        .flatMap((markers) => markers)
        .map((marker) => marker.id)
        .concat(cachedNoResults);
    if (markerIds.every((id) => cachedMarkerIds.includes(id))) {
        return getLinkedMarkersByKeys(cachedMap, markerIds);
    }
    // We fetch the requested markers excluding the cached ones.
    const idsToFetch = markerIds.filter((id) => !cachedMarkerIds.includes(id));
    const result = yield dispatch(refreshLinkedMarkers(idsToFetch)).unwrap();
    const freshAssetIds = uniqueV2(result.map((item) => item.manualInventoryId).filter(isDefined));
    // We simply replace the markers for every asset
    // in the cache with the ones we just received from the server.
    for (const aId of freshAssetIds) {
        cachedMap.set(aId, result.filter((item) => item.manualInventoryId === aId));
    }
    return getLinkedMarkersByKeys(cachedMap, markerIds);
}));
export const linkedMarkersSlice = createSlice({
    name: 'linkedMarkers',
    initialState,
    reducers: {
        reset: () => initialState,
        invalidateCachedLinkedMarkers: (state) => {
            state.cachedLinkedMarkers = Object.fromEntries([]);
            state.cachedNoResults = [];
            state.cachedLinkedMarkersInvalidatedAt = performance.now();
        }
    },
    extraReducers(builder) {
        builder.addCase(getLinkedMarkers.fulfilled, (state, action) => {
            const markerIds = action.payload
                .flatMap(([, markers]) => markers)
                .map((marker) => marker.id);
            state.cachedNoResults = uniqueV2(action.meta.arg
                .filter((id) => !markerIds.includes(id))
                .concat(state.cachedNoResults));
            state.cachedLinkedMarkers = Object.fromEntries(action.payload);
        });
    }
});
export const { reset: resetLinkedMarkers, invalidateCachedLinkedMarkers } = linkedMarkersSlice.actions;
export default linkedMarkersSlice.reducer;
