import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { MarkerPriorityIcon } from '../../common/convert';
import { IconResizer } from '../IconResizer/IconResizer';
import styles from './MapPinAttachmentPriorities.module.css';
export function MapPinAttachmentPriorities({ tasks }) {
    return (_jsx("div", Object.assign({ className: styles['container'], style: { width: PRIO_WIDTHS[tasks.length - 1] } }, { children: tasks.map((item) => (_jsx("div", Object.assign({ className: styles['icon'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(MarkerPriorityIcon, { value: item.priority }) })) }), item.id))) })));
}
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const PRIO_WIDTHS = [20, 28, 34];
