var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getAssetInfoById } from '../../services/asset';
import { getNodeEdge, getPoint } from '../../services/condition';
import { getInspection } from '../../services/inspection';
import { getMarker } from '../../services/marker';
import { getPlanDetails } from '../../services/plan';
import { getSignInfoById } from '../../services/sign';
import { getSignInventoryInfoById } from '../../services/signInventory';
const initialState = {};
function getFeatureData(feature, countryCode) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (!feature) {
            return;
        }
        switch (feature.feature.type) {
            case 'point': {
                const result = yield getPoint(feature.feature.id);
                if (result.result) {
                    return {
                        type: feature.feature.type,
                        value: result.result
                    };
                }
                break;
            }
            case 'referencing-flag': {
                if (!feature.properties) {
                    return;
                }
                return {
                    type: 'referencing-flag',
                    value: { flag: feature.properties.flag }
                };
            }
            case 'note': {
                if (!feature.properties) {
                    return;
                }
                return {
                    type: 'note',
                    value: feature.properties
                };
            }
            case 'object':
            case 'alert': {
                const result = yield getPoint(feature.feature.id);
                if (result.result) {
                    return {
                        type: feature.feature.type,
                        value: result.result
                    };
                }
                break;
            }
            case 'segment': {
                const result = yield getNodeEdge(feature.feature.id);
                if (result.result) {
                    return {
                        type: 'node-edge',
                        value: result.result
                    };
                }
                break;
            }
            case 'track-control': {
                if (!feature.properties) {
                    return;
                }
                const result = yield getInspection({ id: feature.feature.id });
                if (result.result) {
                    return {
                        type: 'track-control',
                        value: Object.assign(Object.assign({}, result.result.inspection), { createdAt: feature.properties.timestamp })
                    };
                }
                break;
            }
            case 'plan': {
                const result = yield CancelableRequest.send(getPlanDetails, {
                    planId: feature.feature.id,
                    countryCode
                });
                if ((_a = result.result) === null || _a === void 0 ? void 0 : _a.plan) {
                    return {
                        type: 'plan',
                        value: result.result.plan
                    };
                }
                break;
            }
            case 'marker': {
                const result = yield getMarker(feature.feature.id);
                if (result.result) {
                    return {
                        type: 'marker',
                        value: result.result.marker
                    };
                }
                break;
            }
            case 'sign': {
                const result = yield getSignInfoById({ id: feature.feature.id });
                if (result.result) {
                    return {
                        type: 'sign',
                        value: result.result.sign
                    };
                }
                break;
            }
            case 'winter-service': {
                if (!feature.properties) {
                    return;
                }
                const result = yield getInspection({ id: feature.feature.id });
                if (result.result) {
                    return {
                        type: 'winter-service',
                        value: Object.assign(Object.assign({}, result.result.inspection), { createdAt: feature.properties.timestamp })
                    };
                }
                break;
            }
            case 'sign-inventory': {
                const result = yield getSignInventoryInfoById({ id: feature.feature.id });
                if (result.result) {
                    return {
                        type: 'sign-inventory',
                        value: result.result.sign
                    };
                }
                break;
            }
            case 'asset': {
                const result = yield getAssetInfoById(feature.feature.id);
                if (result.result) {
                    return {
                        type: 'asset',
                        value: result.result.manualInventory
                    };
                }
                break;
            }
        }
    });
}
export const fetchHoverPopoverData = createAppAsyncThunk('mapPopover/fetchHoverPopoverData', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { auth: { teamAccount }, feature: { hoveredFeature } } = getState();
    return yield getFeatureData(hoveredFeature, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode);
}));
export const fetchClickPopoverData = createAppAsyncThunk('mapPopover/fetchClickPopoverData', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { auth: { teamAccount }, feature: { clickedFeature } } = getState();
    return yield getFeatureData(clickedFeature, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode);
}));
export const mapPopoverSlice = createSlice({
    name: 'feature',
    initialState,
    reducers: {
        reset: () => initialState,
        clearClickPopoverData: (state) => (Object.assign(Object.assign({}, state), { clickData: undefined })),
        clearHoverPopoverData: (state) => (Object.assign(Object.assign({}, state), { hoverData: undefined }))
    },
    extraReducers(builder) {
        builder.addCase(fetchHoverPopoverData.pending, (state, action) => {
            state.activeHoverRequest = action.meta.requestId;
        });
        builder.addCase(fetchClickPopoverData.pending, (state, action) => {
            state.activeClickRequest = action.meta.requestId;
        });
        builder.addCase(fetchHoverPopoverData.fulfilled, (state, action) => {
            // reject older request results overriding newer results
            if (action.meta.requestId !== state.activeHoverRequest) {
                return;
            }
            state.hoverData = action.payload;
        });
        builder.addCase(fetchClickPopoverData.fulfilled, (state, action) => {
            // reject older request results overriding newer results
            if (action.meta.requestId !== state.activeClickRequest) {
                return;
            }
            state.clickData = action.payload;
        });
    }
});
export const { reset: resetMapPopover, clearClickPopoverData, clearHoverPopoverData } = mapPopoverSlice.actions;
export default mapPopoverSlice.reducer;
