import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../uikit/Button/Button';
import styles from './CloseTaskForm.module.css';
export function CloseTaskForm({ isLoading, wasClosed, onCloseTask, onCancel, onGoHome }) {
    if (wasClosed) {
        return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "close-task-form" }, { children: [_jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "tasks.markasdone.confirmationcopy" }) })), _jsx(Button, Object.assign({ color: "yellow", variant: "fullwidth", onClick: onGoHome }, { children: _jsx(Ditto, { componentId: "closetask.back" }) }))] })));
    }
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "close-task-form" }, { children: [_jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "closetask.cta" }) })), _jsx(Button, Object.assign({ color: "yellow", variant: "fullwidth", onClick: onCloseTask, disabled: isLoading }, { children: _jsx(Ditto, { componentId: "yes" }) })), _jsx(Button, Object.assign({ variant: "fullwidth", onClick: onCancel, disabled: isLoading }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) }))] })));
}
