// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D4cdrmkTlrQWrgwipNxM{position:relative}.p9Dp_6dn4U5X2hbbCmCb{position:absolute;z-index:1000}.adOAVghDR3JODTWgaAeA{transform:translate(0, 4px);padding-bottom:12px}.PtixrQtbVideZu9yusww{transform:translate(0, calc(-100% - 52px))}`, "",{"version":3,"sources":["webpack://./src/components/AddressSearch/AddressSearch.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,iBAAA,CACA,YAAA,CAGJ,sBACI,2BAAA,CACA,mBAAA,CAGJ,sBACI,0CAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.anchor {\n    position: absolute;\n    z-index: 1000;\n}\n\n.anchor__down {\n    transform: translate(0, 4px);\n    padding-bottom: 12px;\n}\n\n.anchor__up {\n    transform: translate(0, calc(-100% - 52px));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `D4cdrmkTlrQWrgwipNxM`,
	"anchor": `p9Dp_6dn4U5X2hbbCmCb`,
	"anchor__down": `adOAVghDR3JODTWgaAeA`,
	"anchor__up": `PtixrQtbVideZu9yusww`
};
export default ___CSS_LOADER_EXPORT___;
