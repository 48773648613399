// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fbzu8z6zkXEwE4l0oSkv{margin:0 auto;text-align:center}.FJ1ks6UPiYsZzq_mLcvj{display:flex;gap:8px;justify-content:center;flex-direction:row;white-space:nowrap}.d65xLCvozzJDBkVZWIGi{margin:6px 0;text-decoration:underline}.iSo1z8ejd93AUElHan0l{color:var(--primary-dark-blue-3)}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/MagicInputCostsTooltipContent/MagicInputCostsTooltipContent.module.css"],"names":[],"mappings":"AAAA,sBACI,aAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,OAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,yBAAA,CAGJ,sBACI,gCAAA","sourcesContent":[".wrapper {\n    margin: 0 auto;\n    text-align: center;\n}\n\n.top {\n    display: flex;\n    gap: 8px;\n    justify-content: center;\n    flex-direction: row;\n    white-space: nowrap;\n}\n\n.value {\n    margin: 6px 0;\n    text-decoration: underline;\n}\n\n.bottom {\n    color: var(--primary-dark-blue-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `fbzu8z6zkXEwE4l0oSkv`,
	"top": `FJ1ks6UPiYsZzq_mLcvj`,
	"value": `d65xLCvozzJDBkVZWIGi`,
	"bottom": `iSo1z8ejd93AUElHan0l`
};
export default ___CSS_LOADER_EXPORT___;
