import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { isDeepEqual } from '../../common/utils/object';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchTableViewSettings, updateTableViewSettings } from '../../state/slices/leftPanelPlan';
export const usePlanTableViewSettings = () => {
    const dispatch = useAppDispatch();
    const { table: { settings: tableSettings } } = useAppSelector((state) => state.leftPanelPlan);
    const { addModal } = useModalContext();
    const [planPlanTableViewSettingList, setPlanTableViewSettingList] = useState([]);
    useEffect(() => {
        if (!tableSettings) {
            dispatch(fetchTableViewSettings());
        }
        else {
            setPlanTableViewSettingList(tableSettings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableSettings]);
    function handlePlanTableViewSaveClick(items, callback) {
        items = items.map((item, index) => {
            return Object.assign(Object.assign({}, item), { orderNumber: (index + 1) * 100 });
        });
        dispatch(updateTableViewSettings({ items }));
        callback();
    }
    function handlePlanTableViewCancelClick(list, callback) {
        if (!tableSettings)
            return;
        const isTableViewSettingsChanged = !isDeepEqual(tableSettings, list);
        if (list && isTableViewSettingsChanged) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handlePlanTableViewSaveClick(list, callback);
                    },
                    onCancel: () => {
                        setPlanTableViewSettingList(tableSettings);
                        callback();
                    },
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }
    return {
        planPlanTableViewSettingList,
        setPlanTableViewSettingList,
        handlePlanTableViewSaveClick,
        handlePlanTableViewCancelClick
    };
};
