import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { MultiselectModal } from '../../components/Modals/MultiselectModal/MultiselectModal';
import { useModalContext } from '../../state/context';
/**
 * Designed to be shared for multiple layers, for example task attributes, asset attributes and so on.
 */
export function MultiselectModalContainer({ options, initialValues, onDone, title, settingPageId }) {
    const { addModal, removeModal } = useModalContext();
    function handleClose() {
        removeModal('MultiselectModalContainer');
    }
    function handleCostsCustomizeClick() {
        handleClose();
        addModal({
            id: 'GlobalSettingsModal',
            props: { initialPageId: settingPageId }
        });
    }
    function handleDone(ids) {
        handleClose();
        onDone(ids);
    }
    return (_jsx(MultiselectModal, { options: options, initialValues: initialValues, onClose: handleClose, onDone: handleDone, title: title, onCustomize: handleCostsCustomizeClick }));
}
