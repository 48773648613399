import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Divider } from '../../uikit/Divider/Divider';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { CostsProperties } from '../CostsProperties/CostsProperties';
import { FormInputText } from '../FormInputText/FormInputText';
import { Teaser } from '../Teaser/Teaser';
import { WorkingHoursGroup } from '../WorkingHoursGroup/WorkingHoursGroup';
export function CostsSectionTeaser({ workingHours, totalSpentTime, formatter, disabled, linkOnClick }) {
    const linkHref = useDittoWrapper({ componentId: 'tasks.costs.teaser.ctaurl' });
    const costcenterLabel = useDittoWrapper({ componentId: 'tasks.costs.costcenter' });
    const costcenterValue = useDittoWrapper({ componentId: 'tasks.costs.costcenter.teaser' });
    const equipmentLabel = useDittoWrapper({ componentId: 'tasks.costs.equipmentused' });
    const equipmentValue = useDittoWrapper({
        componentId: 'tasks.costs.equipment.teaser'
    });
    const formattedWorkingHours = workingHours.map((item) => ({
        started: formatter.formatDate(item.started).dateTime,
        finished: formatter.formatDate(item.finished).dateTime,
        user: item.user
    }));
    const formattedTotal = formatter.formatNumber(totalSpentTime);
    return (_jsxs(_Fragment, { children: [_jsx(Teaser, { badge: _jsx(NotificationBadge, { content: _jsx(Ditto, { componentId: "comingsoon" }), variant: "dark", fontSize: 12 }), title: _jsx(Ditto, { componentId: "tasks.costs.teaser.title" }), content: _jsx(Ditto, { componentId: "tasks.costs.teaser.description" }), linkLabel: _jsx(Ditto, { componentId: "tasks.costs.teaser.cta" }), linkHref: linkHref, linkOnClick: linkOnClick }), _jsx(WorkingHoursGroup, { workingHours: formattedWorkingHours, totalSpentTime: formattedTotal, disabled: disabled }), _jsx(Divider, {}), _jsxs(CostsProperties, Object.assign({ disabled: disabled }, { children: [_jsx(FormInputText, { label: costcenterLabel, value: costcenterValue, variant: "ghost" }), _jsx(FormInputText, { label: equipmentLabel, value: equipmentValue, variant: "ghost" })] }))] }));
}
