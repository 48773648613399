// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NkCHRSXE3pqtbfcfmoj3{color:var(--primary-white)}.NkCHRSXE3pqtbfcfmoj3>*:not(:last-child){margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/List/List.module.css"],"names":[],"mappings":"AAAA,sBACI,0BAAA,CAGJ,yCACI,iBAAA","sourcesContent":[".container {\n    color: var(--primary-white);\n}\n\n.container > *:not(:last-child) {\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NkCHRSXE3pqtbfcfmoj3`
};
export default ___CSS_LOADER_EXPORT___;
