import { Frequency, rrulestr } from 'rrule';
import { createRRule } from '.';
export const parseRecurringFormula = (recurringFormula) => {
    if (!recurringFormula) {
        return null;
    }
    try {
        const rrule = rrulestr(recurringFormula);
        const { options: { freq, dtstart: startDate, until: untilDate, count, interval, bymonthday, byyearday, byweekday } } = rrule;
        let formula = {
            frequency: {
                repeat: 'never'
            },
            startDate,
            untilDate: untilDate || undefined
        };
        if (freq === Frequency.DAILY && count === 1) {
            return formula;
        }
        switch (freq) {
            case Frequency.DAILY:
                formula.frequency = {
                    repeat: 'daily'
                };
                break;
            case Frequency.WEEKLY:
                formula.frequency = {
                    repeat: 'weekly',
                    days: byweekday
                };
                break;
            case Frequency.MONTHLY:
                formula.frequency = {
                    repeat: 'monthly',
                    days: bymonthday
                };
                break;
            case Frequency.YEARLY:
                formula.frequency = {
                    repeat: 'yearly',
                    days: byyearday
                };
                break;
            default:
                throw new Error(`unexpected frequency`);
        }
        if (interval > 1) {
            formula.frequency = {
                repeat: 'custom',
                interval,
                value: formula.frequency
            };
        }
        return formula;
    }
    catch (err) {
        console.error('failed to parse recurring formula, fallback to null', recurringFormula, err);
        return null;
    }
};
export function toStringRecurringFormula(newFormula) {
    if (!newFormula) {
        return '';
    }
    const rrule = createRRule(newFormula);
    if (rrule) {
        return rrule.toString();
    }
    return '';
}
