import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { ReportIssue } from '../../components/Modals/ReportIssue';
import { useUserFullname } from '../../hooks/useUserFullname';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { reportConditionIssue, reportSignIssue, resetConditionReport } from '../../state/slices/conditionReport';
export function ReportModalContainer() {
    const { removeModal } = useModalContext();
    const { selectedFeature } = useAppSelector((s) => s.feature);
    const { teamAccount } = useAppSelector((s) => s.auth);
    const conditionDetail = useAppSelector((s) => s.conditionDetail.feature);
    const signDetail = useAppSelector((s) => s.signDetail.signInfo);
    const signInventoryDetail = useAppSelector((s) => s.signInventoryDetail.signInfo);
    const sign = signDetail || signInventoryDetail;
    const { fullname: senderName } = useUserFullname();
    const customerName = useAppSelector((s) => { var _a; return ((_a = s.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.displayName) || ''; });
    const { isSending, isSent, error } = useAppSelector((s) => s.conditionReport);
    const [message, setMessage] = useState('');
    const dispatch = useAppDispatch();
    if (isSent) {
        setTimeout(() => handleClose(), 1000);
    }
    function handleClose() {
        dispatch(resetConditionReport());
        removeModal('ReportModal');
    }
    function handleSend() {
        if (!selectedFeature) {
            return;
        }
        if (sign &&
            (selectedFeature.type === 'sign' || selectedFeature.type === 'sign-inventory')) {
            dispatch(reportSignIssue({
                emails: [
                    {
                        subject: `[Report] Traffic sign #${sign.id} was reported`,
                        recipients: ['support@vialytics.de'],
                        template: {
                            name: 'report-template',
                            version: 'initial',
                            vars: {
                                type: 'Sign',
                                tld: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US' ? 'us' : 'de',
                                id: sign.id.toString(),
                                senderName,
                                customerName,
                                note: message,
                                pointImageUrl: sign.imageUrl,
                                link: window.location.href,
                                pointUUID: sign.pointUuid.toUpperCase(),
                                inspectionUUID: sign.inspectionUuid.toUpperCase(),
                                lng: sign.offsetPosition[0],
                                lat: sign.offsetPosition[1]
                            },
                            inlineAttachments: {},
                            attachments: {}
                        }
                    }
                ]
            }));
            return;
        }
        if (conditionDetail.type === '') {
            return;
        }
        const type = conditionDetail.type === 'node-edge' ? 'street' : 'point';
        const id = conditionDetail.type === 'node-edge'
            ? conditionDetail.value.street_id
            : conditionDetail.value.point_id;
        dispatch(reportConditionIssue({
            id,
            type,
            message
        }));
    }
    function handleChange(value) {
        setMessage(value);
    }
    return (_jsx(ReportIssue, { title: _jsx(Ditto, { componentId: "modal.reportissue" }), subtitle: _jsx(Ditto, { componentId: "modal.reportissue.text" }), content: message, error: error ? _jsx(Ditto, { componentId: "somethingwentwrongtext" }) : '', cancelButtonText: _jsx(Ditto, { componentId: "promt.cancel" }), sendButtonText: _jsx(Ditto, { componentId: "promt.send" }), onCancel: handleClose, onClose: handleClose, onSend: handleSend, onChange: handleChange, isSending: isSending }));
}
