// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E0jVlP_493k0hFR62QIi{padding:1px;background:var(--primary-white);box-shadow:0 0 4px rgba(0,0,0,.2);border-radius:2px;justify-content:flex-end;align-items:flex-start;gap:1px;display:inline-flex;position:relative;transform:translate(2px, -5px)}.WAdPcd60nuMz9d9YCg3s{min-width:16px;min-height:16px;border-radius:1px;overflow:hidden;justify-content:center;align-items:center;display:flex;box-sizing:border-box}.ci8vRbD1A7_afko91vzZ{box-shadow:0 0 4px var(--root-blue-6);outline:2px solid var(--root-blue-6);transform:translate(4px, -10px)}.NQ6BxEREkVuRBdS4RrtA{transform:translate(4px, -10px)}.tSjs8eRDaD9XzX5ojswb{visibility:hidden}`, "",{"version":3,"sources":["webpack://./src/components/MapPinAttachment/MapPinAttachment.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,+BAAA,CACA,iCAAA,CACA,iBAAA,CACA,wBAAA,CACA,sBAAA,CACA,OAAA,CACA,mBAAA,CACA,iBAAA,CACA,8BAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CAGJ,sBACI,qCAAA,CACA,oCAAA,CACA,+BAAA,CAGJ,sBACI,+BAAA,CAGJ,sBACI,iBAAA","sourcesContent":[".container {\n    padding: 1px;\n    background: var(--primary-white);\n    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);\n    border-radius: 2px;\n    justify-content: flex-end;\n    align-items: flex-start;\n    gap: 1px;\n    display: inline-flex;\n    position: relative;\n    transform: translate(2px, -5px);\n}\n\n.child {\n    min-width: 16px;\n    min-height: 16px;\n    border-radius: 1px;\n    overflow: hidden;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    box-sizing: border-box;\n}\n\n.selected {\n    box-shadow: 0 0 4px var(--root-blue-6);\n    outline: 2px solid var(--root-blue-6);\n    transform: translate(4px, -10px);\n}\n\n.hovered {\n    transform: translate(4px, -10px);\n}\n\n.hidden {\n    visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `E0jVlP_493k0hFR62QIi`,
	"child": `WAdPcd60nuMz9d9YCg3s`,
	"selected": `ci8vRbD1A7_afko91vzZ`,
	"hovered": `NQ6BxEREkVuRBdS4RrtA`,
	"hidden": `tSjs8eRDaD9XzX5ojswb`
};
export default ___CSS_LOADER_EXPORT___;
