import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME } from '../common/consts/mixpanel';
import { useModalContext } from '../state/context';
import { useAppSelector } from '../state/hooks';
import { useLayerChecker } from './useLayerChecker';
export function useRemainingServiceLifeIntroModal() {
    const { userAccount, isAuthenticated, targetSchema, teamAccount } = useAppSelector((state) => state.auth);
    const { addModal, modals } = useModalContext();
    const { isPlanVisible } = useLayerChecker();
    useEffect(() => {
        if (!isAuthenticated || !targetSchema || !teamAccount || !userAccount || !isPlanVisible) {
            return;
        }
        if (modals.find((m) => m.id === 'RemainingServiceLifeIntroModal')) {
            return;
        }
        // When a user (=user account) ticks the checkbox of ‘Don’t show again' and then clicks ‘Try now’ or x button at right top corner, it should afterwards really(!) never ever ever appear again for this user. We don’t wanna annoy them.
        if (userAccount === null || userAccount === void 0 ? void 0 : userAccount.popupSettings.doNotShowRemainingServiceLifeIntroModalAgain) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.showRemainingServiceLifeIntroPopup);
        addModal({ id: 'RemainingServiceLifeIntroModal' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
