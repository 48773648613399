import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './Grabber.module.css';
export function Grabber({ variant = 'default' }) {
    let element;
    switch (variant) {
        case 'default':
            element = (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "10", height: "40", fill: "none", viewBox: "0 0 10 40" }, { children: _jsx("rect", { width: "4", height: "40", x: "3", fill: "#818181", fillOpacity: "0.1", rx: "2" }) })));
            break;
        case 'hover':
            element = (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "10", height: "40", fill: "none", viewBox: "0 0 10 40" }, { children: _jsx("rect", { width: "6", height: "40", x: "2", fill: "#222D39", opacity: "0.5", rx: "3" }) })));
            break;
        case 'solid':
            element = (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "10", height: "40", fill: "none", viewBox: "0 0 10 40" }, { children: _jsx("path", { fill: "#222D39", d: "M2.676 15.864a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.426 15.864a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM2.676 21.24a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.426 21.24a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM2.676 26.614a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.426 26.614a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z", opacity: "0.2" }) })));
            break;
        case 'solid-hover':
            element = (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "10", height: "40", fill: "none", viewBox: "0 0 10 40" }, { children: _jsx("path", { fill: "#222D39", d: "M2.676 15.864a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.426 15.864a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM2.676 21.24a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.426 21.24a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM2.676 26.614a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.426 26.614a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" }) })));
            break;
    }
    return _jsx("div", Object.assign({ className: styles['container'] }, { children: element }));
}
