// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T9FMzkR8KjSGDMBuSLQb>svg:not(:last-child){margin-right:0}.pWcYnxdbqNkxGbnKj9C0{font-weight:600}.P1c5vZZibp_AGJLIhZw9{background:rgba(129,129,129,.1);border-radius:100%;opacity:.6}`, "",{"version":3,"sources":["webpack://./src/components/EditorForms/TreatmentSelect/TreatmentSelectGroup/TreatmentSelectGroup.module.css"],"names":[],"mappings":"AAAA,2CACI,cAAA,CAGJ,sBACI,eAAA,CAGJ,sBACI,+BAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".container > svg:not(:last-child) {\n    margin-right: 0;\n}\n\n.title {\n    font-weight: 600;\n}\n\n.toggle-icon {\n    background: rgba(129, 129, 129, 0.1);\n    border-radius: 100%;\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `T9FMzkR8KjSGDMBuSLQb`,
	"title": `pWcYnxdbqNkxGbnKj9C0`,
	"toggle-icon": `P1c5vZZibp_AGJLIhZw9`
};
export default ___CSS_LOADER_EXPORT___;
