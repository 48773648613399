var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { LOCALE } from '../../common/consts/locale';
import { getCloseMarkerURLData, ROOT_PATH } from '../../common/url';
import { CloseTaskForm } from '../../components/CloseTaskForm/CloseTaskForm';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setLocale } from '../../state/slices/app';
import { closeMarkerTk } from '../../state/slices/closeMarker';
export function CloseMarkerContainer() {
    const dispatch = useAppDispatch();
    const { token, locale: localeURL } = getCloseMarkerURLData();
    const { closeStatus } = useAppSelector((state) => state.closeMarker);
    const { locale } = useAppSelector((state) => state.app);
    const vialyticshomepage = useDittoWrapper({ componentId: 'vialyticshomepage' });
    if (localeURL && LOCALE[localeURL] && LOCALE[localeURL] !== locale) {
        dispatch(setLocale(LOCALE[localeURL]));
    }
    if (!token) {
        window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.unauthorized}`;
    }
    function handleCloseTask() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!token) {
                return;
            }
            dispatch(closeMarkerTk(token));
        });
    }
    function handleCancel() {
        window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.main}`;
    }
    function handleGoHome() {
        window.location.href = vialyticshomepage;
    }
    return (_jsx(CloseTaskForm, { isLoading: closeStatus === 'loading', wasClosed: closeStatus === 'succeeded', onCloseTask: handleCloseTask, onCancel: handleCancel, onGoHome: handleGoHome }));
}
