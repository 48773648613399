// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z_p_VvwbRX72jzCtAPBS{height:24px;position:relative}.Wlz0EVB18oDpVDeqdxS1{width:max-content;display:flex;flex-direction:row;justify-content:center;align-items:center;padding:8px;gap:6px;position:absolute;background:var(--root-dark-blue-6);box-shadow:0px 0px 10px rgba(0,0,0,.2);border-radius:6px;color:var(--primary-white);transform:translate(-8px, -8px);z-index:var(--zindex-tooltip);cursor:default;pointer-events:none}.jWIjHlkAo3yJvIrtlGRQ{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/uikit/IconHover/IconHover.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,OAAA,CAEA,iBAAA,CAEA,kCAAA,CACA,sCAAA,CACA,iBAAA,CACA,0BAAA,CAEA,+BAAA,CACA,6BAAA,CACA,cAAA,CACA,mBAAA,CAGJ,sBACI,cAAA","sourcesContent":[".container {\n    height: 24px;\n    position: relative;\n}\n\n.tooltip {\n    width: max-content;\n\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 8px;\n    gap: 6px;\n\n    position: absolute;\n\n    background: var(--root-dark-blue-6);\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n    border-radius: 6px;\n    color: var(--primary-white);\n\n    transform: translate(-8px, -8px);\n    z-index: var(--zindex-tooltip);\n    cursor: default;\n    pointer-events: none;\n}\n\n.clickable {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `z_p_VvwbRX72jzCtAPBS`,
	"tooltip": `Wlz0EVB18oDpVDeqdxS1`,
	"clickable": `jWIjHlkAo3yJvIrtlGRQ`
};
export default ___CSS_LOADER_EXPORT___;
