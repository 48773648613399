import { useCallback } from 'react';
import { getDateTimeFromTimestamp } from '../../common/utils/time';
import { useAppSelector } from '../../state/hooks';
/**
 * TODO: move this to useFormatter
 * @deprecated
 */
export function useDateTimeFormatter() {
    const { teamAccount } = useAppSelector((state) => state.auth);
    return useCallback((timestamp, options) => {
        const hideTime = (options === null || options === void 0 ? void 0 : options.forceShowTime) === undefined
            ? teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.hideTimeEnabled
            : !(options === null || options === void 0 ? void 0 : options.forceShowTime);
        return getDateTimeFromTimestamp(timestamp, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, hideTime);
    }, [teamAccount]);
}
