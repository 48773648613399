import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { parseRecurringFormula } from '../../../../common/recurringRules';
import { IconHover } from '../../../../uikit/IconHover/IconHover';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { CalendarDueDate, Recurring } from '../../../icons';
import styles from './DueDate.module.css';
export function DueDate({ dueDate, isRecurring, isOverdue, formula, 'data-test': dataTest }) {
    const parsedFormula = useMemo(() => parseRecurringFormula(formula), [formula]);
    const repeat = parsedFormula && repeatOptions[parsedFormula.frequency.repeat];
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": dataTest }, { children: [_jsxs("div", Object.assign({ className: classes(styles['due-date'], isOverdue && styles['due-date__overdue']) }, { children: [_jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(CalendarDueDate, {}) })), _jsx("span", { children: dueDate })] })), isRecurring && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles['vertical-line'] }), _jsxs("div", Object.assign({ className: styles['recurring-icon'] }, { children: [repeat && (_jsx(IconHover, { icon: _jsx(IconResizer, Object.assign({ size: 16, resizeContainer: true }, { children: _jsx(Recurring, {}) })), iconSize: 16, text: repeat })), !repeat && (_jsx(IconResizer, Object.assign({ size: 16, resizeContainer: true }, { children: _jsx(Recurring, {}) })))] }))] }))] })));
}
const repeatOptions = {
    never: null,
    daily: _jsx(Ditto, { componentId: "tasks.recurringtasks.intervall.repeat.daily" }),
    weekly: _jsx(Ditto, { componentId: "tasks.recurringtasks.intervall.repeat.weekly" }),
    monthly: _jsx(Ditto, { componentId: "tasks.recurringtasks.intervall.repeat.monthly" }),
    yearly: _jsx(Ditto, { componentId: "tasks.recurringtasks.intervall.repeat.yearly" }),
    custom: _jsx(Ditto, { componentId: "tasks.recurringtasks.intervall.repeat.custom" })
};
