import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_GROUP_KEY } from '../../common/consts/mixpanel';
import { isAttributeFilterNotEmpty } from '../../common/filter';
import { toggleItemInArray, xorify } from '../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setAssetFilter } from '../../state/slices/leftPanelAsset';
export function useAssetFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelAsset);
    const { assetTypeMap, assetFolders } = useAppSelector((state) => state.shared);
    const dispatch = useAppDispatch();
    function handleTypeFilterClick(value) {
        const assetType = assetTypeMap[value];
        mixpanel.track(MIXPANEL_EVENT_NAME.filterAssetType, {
            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: assetType === null || assetType === void 0 ? void 0 : assetType.name,
            [MIXPANEL_GROUP_KEY.assetTypeId]: assetType === null || assetType === void 0 ? void 0 : assetType.id
        });
        dispatch(setAssetFilter({
            typeIds: toggleItemInArray(filter.typeIds, value)
        }));
    }
    function handleSelectAttributeFilterClick(id, value) {
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            const newAttributeFilter = {
                id,
                type: 'select',
                selectValue: [value]
            };
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'select') {
            const newSelectValue = toggleItemInArray(attributeFilter.selectValue, value);
            const newAttributeFilter = Object.assign(Object.assign({}, attributeFilter), { selectValue: newSelectValue });
            dispatch(setAssetFilter({
                attributes: filter.attributes
                    .map((at) => (at.id === id ? newAttributeFilter : at))
                    .filter(isAttributeFilterNotEmpty)
            }));
        }
    }
    function handleSelectAttributeFilterNotSpecifiedClick(id) {
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            const newAttributeFilter = {
                id,
                type: 'select',
                selectValue: [],
                showNotSpecified: true
            };
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'select') {
            const newAttributeFilter = Object.assign(Object.assign({}, attributeFilter), { showNotSpecified: !attributeFilter.showNotSpecified });
            dispatch(setAssetFilter({
                attributes: filter.attributes
                    .map((at) => (at.id === id ? newAttributeFilter : at))
                    .filter(isAttributeFilterNotEmpty)
            }));
        }
    }
    function isAttributeSelectOptionChecked(id, value, notSpecified) {
        const filterAttribute = filter.attributes.find((fa) => fa.id === id);
        if (!filterAttribute) {
            return false;
        }
        if (filterAttribute.type === 'select' && value) {
            return !!filterAttribute.selectValue.find((selectedOption) => selectedOption === value);
        }
        else if (filterAttribute.type === 'select' && notSpecified) {
            return filterAttribute.showNotSpecified;
        }
    }
    function handleNumberAttributeFilterChange(id, range) {
        const newAttributeFilter = {
            id,
            type: 'number',
            numberValue: range
        };
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'number') {
            dispatch(setAssetFilter({
                attributes: filter.attributes.map((at) => at.id === id ? newAttributeFilter : at)
            }));
        }
    }
    function handleDateAttributeFilterChange(id, range) {
        const newAttributeFilter = {
            id,
            type: 'date',
            dateValue: range
        };
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'date') {
            dispatch(setAssetFilter({
                attributes: filter.attributes.map((at) => at.id === id ? newAttributeFilter : at)
            }));
        }
    }
    function handleNumberAttributeNotSpecifiedClick(id) {
        const newAttributeFilter = {
            id,
            type: 'number',
            showNotSpecified: true
        };
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'number') {
            dispatch(setAssetFilter({
                attributes: filter.attributes.map((at) => at.id === id ? newAttributeFilter : at)
            }));
        }
    }
    function handleDateAttributeNotSpecifiedClick(id) {
        const newAttributeFilter = {
            id,
            type: 'date',
            showNotSpecified: true
        };
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'date') {
            dispatch(setAssetFilter({
                attributes: filter.attributes.map((at) => at.id === id ? newAttributeFilter : at)
            }));
        }
    }
    function handleTextAttributeSpecifiedClick(id) {
        const newAttributeFilter = {
            id,
            type: 'text'
        };
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'text') {
            dispatch(setAssetFilter({
                attributes: filter.attributes.map((at) => at.id === id ? newAttributeFilter : at)
            }));
        }
    }
    function handleTextAttributeNotSpecifiedClick(id) {
        const newAttributeFilter = {
            id,
            type: 'text',
            showNotSpecified: true
        };
        const attributeFilter = filter.attributes.find((i) => i.id === id);
        if (!attributeFilter) {
            dispatch(setAssetFilter({
                attributes: filter.attributes.concat(newAttributeFilter)
            }));
            return;
        }
        if (attributeFilter.type === 'text') {
            dispatch(setAssetFilter({
                attributes: filter.attributes.map((at) => at.id === id ? newAttributeFilter : at)
            }));
        }
    }
    function handleFolderFilterClick(folderId) {
        const xorified = xorify(filter.filesAttached, filter.filesNotAttached, folderId);
        dispatch(setAssetFilter({
            filesAttached: xorified.list1,
            filesNotAttached: xorified.list2
        }));
    }
    function handleFolderAttachedFilterClick(folderId) {
        dispatch(setAssetFilter({
            filesAttached: toggleItemInArray(filter.filesAttached, folderId)
        }));
    }
    function handleFolderNotAttachedFilterClick(folderId) {
        dispatch(setAssetFilter({
            filesNotAttached: toggleItemInArray(filter.filesNotAttached, folderId)
        }));
    }
    function handleAllFoldersFilterClick() {
        const filteredFoldersCount = filter.filesAttached.length + filter.filesNotAttached.length;
        const filteredFolders = filteredFoldersCount === assetFolders.length * 2 ? [] : assetFolders.map((f) => f.id);
        dispatch(setAssetFilter({
            filesAttached: filteredFolders,
            filesNotAttached: filteredFolders
        }));
    }
    return {
        handleTypeFilterClick,
        handleSelectAttributeFilterClick,
        handleSelectAttributeFilterNotSpecifiedClick,
        handleNumberAttributeFilterChange,
        handleDateAttributeFilterChange,
        isAttributeSelectOptionChecked,
        handleNumberAttributeNotSpecifiedClick,
        handleDateAttributeNotSpecifiedClick,
        handleTextAttributeNotSpecifiedClick,
        handleTextAttributeSpecifiedClick,
        handleFolderFilterClick,
        handleFolderAttachedFilterClick,
        handleAllFoldersFilterClick,
        handleFolderNotAttachedFilterClick
    };
}
