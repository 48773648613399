var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, pathParams } from '../../common/fetch';
import { milkyWayApiClient } from '../../openapi';
const CONDITION_FILTER_TYPES_ENDPOINT = 'v1/map/filter/types/:language';
const CONDITION_RECONSTRUCTION_PROPOSALS_ENDPOINT = 'v1/map/filter/smart/:language';
const CONDITION_ROAD_PROPS_ENDPOINT = 'v1/stats/road-props/dict/:language';
const CONDITION_GRADE_PERCENTAGE_ENDPOINT = 'v1/stats/grade-percentage/streets';
export function getConditionFilters(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServiceRoadConditionFilters(body);
    });
}
export function getConditionCount(body) {
    return milkyWayApiClient.milkyWayApiService.milkyWayApiServiceRoadConditionCount(body);
}
export function getFilterTypes(language) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_FILTER_TYPES_ENDPOINT, { language }));
    });
}
export function getReconstructionProposals(language) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_RECONSTRUCTION_PROPOSALS_ENDPOINT, { language }));
    });
}
export function getRoadPropsDict(language) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_ROAD_PROPS_ENDPOINT, { language }));
    });
}
export function getGradePercentages(timePicker) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(CONDITION_GRADE_PERCENTAGE_ENDPOINT, timePicker);
    });
}
