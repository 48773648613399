var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { setAlertInactive } from '../../services/alert';
import { getNodeEdge, getPoint, getPointGeoJSON } from '../../services/condition';
import { fetchAlertGeoJSON } from './mainMap';
import { addNotificationMessage } from './notification';
const initialState = {
    isLoading: false,
    feature: {
        type: '',
        value: null
    },
    featureGeoJson: null
};
export const fetchPoint = createAppAsyncThunk('conditionDetail/fetchPoint', (uuid) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getPoint(uuid);
}));
export const fetchPointGeoJson = createAppAsyncThunk('conditionDetail/fetchPointGeoJson', (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getPointGeoJSON(id);
}));
export const fetchNodeEdge = createAppAsyncThunk('conditionDetail/fetchNodeEdge', (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getNodeEdge(id);
}));
export const ignoreAlert = createAppAsyncThunk('conditionDetail/ignoreAlert', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield setAlertInactive(id);
    dispatch(addNotificationMessage({
        type: 'success',
        ditto: {
            title: { componentId: 'toast.changessaved' }
        }
    }));
    dispatch(fetchAlertGeoJSON());
    return result;
}));
export const conditionDetailSlice = createSlice({
    name: 'conditionDetail',
    initialState,
    reducers: {
        reset: () => initialState,
        setFeature: (state, action) => {
            state.feature = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPoint.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.feature = {
                    type: 'point',
                    value: action.payload.result
                };
            }
        })
            .addCase(fetchPoint.rejected, (state, action) => {
            state.error = action.error;
        });
        builder
            .addCase(fetchPointGeoJson.fulfilled, (state, action) => {
            state.featureGeoJson = action.payload.result;
        })
            .addCase(fetchPointGeoJson.rejected, (state, action) => {
            state.error = action.error;
        });
        builder
            .addCase(fetchNodeEdge.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.feature = {
                    type: 'node-edge',
                    value: action.payload.result
                };
            }
        })
            .addCase(fetchNodeEdge.rejected, (state, action) => {
            state.error = action.error;
        });
        builder.addCase(ignoreAlert.fulfilled, (state, action) => {
            if (state.feature.type !== 'point') {
                return;
            }
            if (!action.payload.result || !action.payload.result.response.results) {
                return;
            }
            state.feature.value.recordAlerts = state.feature.value.recordAlerts.filter((r) => { var _a, _b; return r.recordAlertId !== ((_b = (_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.response.results) === null || _b === void 0 ? void 0 : _b.recordAlertId); });
        });
        builder
            .addMatcher(isAnyOf(fetchPoint.pending, fetchNodeEdge.pending, ignoreAlert.pending, fetchPointGeoJson.pending), (state) => {
            state.isLoading = true;
        })
            .addMatcher(isAnyOf(fetchPoint.fulfilled, fetchNodeEdge.fulfilled, ignoreAlert.fulfilled, fetchPointGeoJson.fulfilled, fetchPoint.rejected, fetchNodeEdge.rejected, ignoreAlert.rejected, fetchPointGeoJson.rejected), (state) => {
            state.isLoading = false;
        });
    }
});
export const { reset: resetConditionDetail, setFeature } = conditionDetailSlice.actions;
export default conditionDetailSlice.reducer;
