import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchAlertGeoJSON } from '../../state/slices/mainMap';
export function useAlertMapData() {
    const { alertGeoJSON } = useAppSelector((state) => state.mainMap);
    const { alertTypes } = useAppSelector((state) => state.shared);
    const { filter } = useAppSelector((state) => state.leftPanelAlert);
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (alertTypes.length === 0) {
            return;
        }
        dispatch(fetchAlertGeoJSON());
    }, [dispatch, filter, selectedOption, alertTypes]);
    return { alertGeoJSON };
}
