import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import styles from './Vignette.module.css';
export function Vignette({ className, content, color, backgroundColor, borderStyle, borderColor, size, opacity, 'data-test': dataTest }) {
    return (_jsx("div", Object.assign({ className: classes(styles['container'], size && styles[`container--${size}`], className), style: {
            color: color,
            backgroundColor: backgroundColor,
            borderStyle: borderStyle,
            borderColor: borderColor,
            opacity
        }, "data-test": dataTest }, { children: content })));
}
