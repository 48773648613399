import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { POPUP_SETTINGS_FIELDNAME } from '../../common/settings';
import { IntroModal } from '../../components/Modals/IntroModal/IntroModal';
import { useModalContext } from '../../state/context';
import { useAppDispatch } from '../../state/hooks';
import { updateUserPopupSettingsTk } from '../../state/slices/auth';
import { Button } from '../../uikit/Button/Button';
export function DefaultTreatmentsIntroModalContainer() {
    const { addModal, removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    function closeModal(dontShowAgain) {
        if (dontShowAgain) {
            dispatch(updateUserPopupSettingsTk(POPUP_SETTINGS_FIELDNAME.doNotShowDefaultTreatmentsIntroModalAgain));
        }
        removeModal('DefaultTreatmentsIntroModal');
    }
    function handleOpenTreatmentsSettingsClick() {
        removeModal('DefaultTreatmentsIntroModal');
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-treatments'
            }
        });
    }
    return (_jsx(IntroModal, { onNoClick: closeModal, onYesClick: closeModal, title: _jsx(Ditto, { componentId: "plannedsegments.treatment.newfeaturemodal" }), promoContent: _jsxs(_Fragment, { children: [_jsx("img", { src: "/default-treatments-intro.png", alt: "Default treatments intro" }), _jsx(Ditto, { componentId: "plannedsegments.treatment.newfeaturemodal.text1" })] }), subheader: _jsx(Ditto, { componentId: "plannedsegments.treatment.newfeaturemodal.text2" }), content: _jsx(Ditto, { componentId: "plannedsegments.treatment.newfeaturemodal.text3", richText: true }), yesLabel: _jsx(Ditto, { componentId: "gotit" }), linkButton: _jsx(Button, Object.assign({ color: "gray", variant: "slender", onClick: handleOpenTreatmentsSettingsClick }, { children: _jsx(Ditto, { componentId: "cta.opensettings" }) })) }));
}
