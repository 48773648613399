import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { IconHover } from '../../../uikit/IconHover/IconHover';
import { IconResizer } from '../../IconResizer/IconResizer';
import { ArrowRight, Task } from '../../icons';
import { TextOverflow } from '../../TextOverflow/TextOverflow';
import { TypedDitto } from '../../TypedDitto';
import styles from './WinterService.module.css';
export function WinterService({ content }) {
    var _a;
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-winter-service" }, { children: [_jsxs("div", Object.assign({ className: styles['left'] }, { children: [_jsxs("div", Object.assign({ className: styles['top'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle']) }, { children: [content.dateTime.dateTime, ' · ', content.name] })), _jsx("div", Object.assign({ className: styles['main'] }, { children: !content.startAddress && !content.endAddress ? (_jsx(Ditto, { componentId: "noexactaddress" })) : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles['line'] }, { children: [_jsx(TextOverflow, { text: content.startAddress, tooltip: "default", maxLines: 1 }), _jsx("div", Object.assign({ className: styles['icon'] }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(ArrowRight, {}) })) }))] })), _jsx("div", Object.assign({ className: styles['line'] }, { children: _jsx(TextOverflow, { text: content.endAddress, tooltip: "default", maxLines: 1 }) }))] })) }))] })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [(_a = content.winterServiceTypes) === null || _a === void 0 ? void 0 : _a.map((i, idx) => (_jsx("div", Object.assign({ className: classes(styles['tag'], styles['square']), "data-test": `tag-${idx}` }, { children: _jsx(IconHover, { icon: _jsx(IconResizer, Object.assign({ size: 16 }, { children: i.icon })), text: i.text }) }), idx))), !!content.markerCount && (_jsxs("div", Object.assign({ className: styles['tag'] }, { children: [_jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Task, {}) })), _jsx("span", Object.assign({ className: styles['tag-text'] }, { children: _jsx(TypedDitto, { componentId: "xtasks", count: content.markerCount, variables: {
                                                MarkersAttachedToTrackControl: content.markerCount
                                            } }) }))] })))] }))] })), content.imagePreview && (_jsx("div", Object.assign({ className: styles['right'] }, { children: _jsx("img", { className: styles['image'], src: content.imagePreview, alt: "map preview" }) })))] })));
}
