// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qlj_ScvgUmlCyKFh6TuJ{opacity:.3}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsTitlePlaceholder/DetailsTitlePlaceholder.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA","sourcesContent":[".placeholder {\n    opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `qlj_ScvgUmlCyKFh6TuJ`
};
export default ___CSS_LOADER_EXPORT___;
