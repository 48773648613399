import { jsx as _jsx } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { classes } from 'classifizer';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import styles from './FloatingTooltip.module.css';
const DROP_UP_SPACING = 100;
export function FloatingTooltip({ content, align = 'left', left, top, onClickOutside }) {
    const portalRoot = document.getElementById('root');
    const containerRef = useRef(null);
    const contentContainerRef = useRef(null);
    const [dropUp, setDropUp] = useState(false);
    const [zeroPosition, setZeroPosition] = useState({ top: 0, left: 0, right: 0 });
    useLayoutEffect(() => {
        if (!containerRef.current || !contentContainerRef.current) {
            return;
        }
        const zP = containerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const totalOffsetHeight = contentContainerRef.current.getBoundingClientRect().bottom - zP.top;
        const shouldDropUp = totalOffsetHeight >= windowHeight - DROP_UP_SPACING;
        setDropUp(shouldDropUp);
        setZeroPosition(zP);
    }, [top]);
    useOnClickOutside(contentContainerRef, () => onClickOutside && onClickOutside());
    const tooltip = (_jsx("div", Object.assign({ style: {
            top: -1 * zeroPosition.top,
            left: zeroPosition.left
        }, ref: containerRef, className: styles['container'] }, { children: _jsx("div", Object.assign({ style: {
                top,
                left: left
            }, className: styles['content-container'] }, { children: _jsx("div", Object.assign({ ref: contentContainerRef, className: classes(dropUp && styles['anchor__up-right'], styles[`content--${align}`]) }, { children: content })) })) })));
    if (!portalRoot)
        return null;
    return createPortal(tooltip, portalRoot);
}
