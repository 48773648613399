import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Ditto } from 'ditto-react';
import { AnimatePresence } from 'framer-motion';
import { downloadFile } from '../../common/utils/downloadFile';
import { FileGallery } from '../../components/FileGallery/FileGallery';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useLayoutChecker } from '../../hooks/useLayoutChecker';
import { useSignInventoryFileUpload } from '../../hooks/useSignInventoryFileUpload';
import { useSignInventoryInfoFetcher } from '../../hooks/useSignInventoryInfoFetcher';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { deleteFileTk, setSelectedFileId, uploadFilesTk } from '../../state/slices/signInventoryDetail';
export function FileGallerySignInventoryContainer() {
    const dispatch = useAppDispatch();
    const { addModal, removeModal, modals } = useModalContext();
    const { signInfo, selectedFileId } = useAppSelector((state) => state.signInventoryDetail);
    const { selectFilesForUpload } = useSignInventoryFileUpload();
    const dateTimeFormatter = useDateTimeFormatter();
    const { isEditor } = useUserRole();
    const { showBanner } = useLayoutChecker();
    const { reloadSignInventoryTilesData } = useTilesUpdator();
    useSignInventoryInfoFetcher();
    const handleCloseClick = useCallback(() => {
        removeModal('FileGallerySignInventory');
    }, [removeModal]);
    const handleOutsideClick = useCallback(() => {
        // Close modal only if the user clicked outside *and* there are no modals on top of gallery
        const index = modals.findIndex((modal) => modal.id === 'FileGallerySignInventory');
        const isGalleryModalLast = index === modals.length - 1;
        if (isGalleryModalLast) {
            handleCloseClick();
        }
    }, [handleCloseClick, modals]);
    const handleFileAddClick = useCallback(() => {
        selectFilesForUpload(() => {
            return new Promise((resolve) => {
                addModal({
                    id: 'FileUploadModal',
                    props: {
                        folders: (signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders) || [],
                        onUploadClick: (folderId) => {
                            resolve({ folderId });
                        },
                        onCloseClick: () => {
                            resolve({ folderId: null });
                        }
                    }
                });
            });
        }, (signInfo === null || signInfo === void 0 ? void 0 : signInfo.id) || -1, (folderId, files) => {
            dispatch(uploadFilesTk({
                signId: signInfo === null || signInfo === void 0 ? void 0 : signInfo.id,
                folderId,
                files: files
            }));
        });
    }, [addModal, dispatch, selectFilesForUpload, signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders, signInfo === null || signInfo === void 0 ? void 0 : signInfo.id]);
    const files = useMemo(() => (signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders.flatMap((folder) => folder.files.map((file) => ({
        id: file.id,
        thumb: null,
        name: file.name,
        folder: { name: folder.name, componentId: folder.componentId },
        link: file.fileLink,
        fileLinkToDownload: file.fileLink,
        mimeType: file.mimeType,
        createdAt: dateTimeFormatter(file.createdAt).dateTime
    })))) || [], [dateTimeFormatter, signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders]);
    const selectedIndex = files.findIndex((file) => file.id === selectedFileId);
    useEffect(() => {
        var _a;
        if (selectedIndex < 0) {
            dispatch(setSelectedFileId({ id: (_a = files[0]) === null || _a === void 0 ? void 0 : _a.id }));
        }
    }, [dispatch, files, selectedIndex]);
    useEffect(() => {
        if (files.length === 0) {
            handleCloseClick();
        }
    }, [files.length, handleCloseClick]);
    const handleSelectFileId = useCallback((fileId) => {
        dispatch(setSelectedFileId({ id: fileId }));
    }, [dispatch]);
    const handleDownloadFileClick = useCallback((fileId) => {
        const file = files.find((file) => file.id === fileId);
        downloadFile((file === null || file === void 0 ? void 0 : file.link) || '', (file === null || file === void 0 ? void 0 : file.name) || '');
    }, [files]);
    const handleFileDeleteClick = useCallback((fileId) => {
        if (!signInfo) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteFileTk({ signId: signInfo === null || signInfo === void 0 ? void 0 : signInfo.id, fileId })).then(() => {
                        reloadSignInventoryTilesData();
                    });
                },
                title: _jsx(Ditto, { componentId: "prompt.delete.file.deletefile" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.file.text" })
            }
        });
    }, [addModal, dispatch, reloadSignInventoryTilesData, signInfo]);
    const isModalPresent = modals.find((modal) => modal.id === 'FileGallerySignInventory');
    return createPortal(_jsx(AnimatePresence, Object.assign({ mode: "wait" }, { children: isModalPresent && (_jsx(ImageViewerModal, { onClickOutside: handleOutsideClick, imageViewer: _jsx(FileGallery, { files: files, activeIndex: selectedIndex, isEditor: isEditor, setSelectedFileId: handleSelectFileId, onDownloadClick: handleDownloadFileClick, onDoneClick: handleCloseClick, onFileAddClick: handleFileAddClick, onFileDeleteClick: handleFileDeleteClick }), isBanner: showBanner })) })), document.body);
}
