// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RinzcSjo3BTlEq439ngT{padding:16px;margin:0 12px 12px 12px;background:var(--root-yellow-2);display:flex;flex-direction:column;border:2px solid var(--secondary-yellow);border-radius:6px}.OHk3Znoxp0VZ4UuEmIn0{width:fit-content;margin-bottom:12px}.Jg12mfpkprMRHwhdMvw2{color:var(--primary-dark-blue);font-size:16px;line-height:19px;font-weight:600;word-wrap:break-word}.bt7cnE2QmsIb8mmQzkOY{color:var(--primary-dark-blue);opacity:.6;font-size:14px;font-weight:400;line-height:18.2px;word-wrap:break-word;margin:4px 0}.XwSUUeRQ6qqDtSJoT8v2{color:var(--primary-dark-blue);font-size:14px;font-weight:600;text-decoration:underline;word-wrap:break-word;margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/components/Teaser/Teaser.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,uBAAA,CACA,+BAAA,CACA,YAAA,CACA,qBAAA,CACA,wCAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,kBAAA,CAGJ,sBACI,8BAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,oBAAA,CAGJ,sBACI,8BAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,oBAAA,CACA,YAAA,CAGJ,sBACI,8BAAA,CACA,cAAA,CACA,eAAA,CACA,yBAAA,CACA,oBAAA,CACA,cAAA","sourcesContent":[".container {\n    padding: 16px;\n    margin: 0 12px 12px 12px;\n    background: var(--root-yellow-2);\n    display: flex;\n    flex-direction: column;\n    border: 2px solid var(--secondary-yellow);\n    border-radius: 6px;\n}\n\n.badge {\n    width: fit-content;\n    margin-bottom: 12px;\n}\n\n.title {\n    color: var(--primary-dark-blue);\n    font-size: 16px;\n    line-height: 19px;\n    font-weight: 600;\n    word-wrap: break-word;\n}\n\n.content {\n    color: var(--primary-dark-blue);\n    opacity: 0.6;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 18.2px;\n    word-wrap: break-word;\n    margin: 4px 0;\n}\n\n.link {\n    color: var(--primary-dark-blue);\n    font-size: 14px;\n    font-weight: 600;\n    text-decoration: underline;\n    word-wrap: break-word;\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RinzcSjo3BTlEq439ngT`,
	"badge": `OHk3Znoxp0VZ4UuEmIn0`,
	"title": `Jg12mfpkprMRHwhdMvw2`,
	"content": `bt7cnE2QmsIb8mmQzkOY`,
	"link": `XwSUUeRQ6qqDtSJoT8v2`
};
export default ___CSS_LOADER_EXPORT___;
