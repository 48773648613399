import { jsx as _jsx } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { classes } from 'classifizer';
import { motion } from 'framer-motion';
import { dropdownVariants } from '../../common/consts/animations';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import styles from './FloatingDropdown.module.css';
export function FloatingDropdown({ content, align = 'left', left, top, right, triggerHeight = 64, fillHeight, onClickOutside }) {
    const containerRef = useRef(null);
    const contentContainerRef = useRef(null);
    const [dropUp, setDropUp] = useState(false);
    const [alignment, setAlignment] = useState(align);
    const [maxHeight, setMaxHeight] = useState();
    const [zeroPosition, setZeroPosition] = useState({ top: 0, left: 0, right: 0 });
    useLayoutEffect(() => {
        if (!containerRef.current || !contentContainerRef.current) {
            return;
        }
        const zP = containerRef.current.getBoundingClientRect();
        const contentRect = contentContainerRef.current.getBoundingClientRect();
        const availableSpace = {
            top: contentRect.top - contentRect.height,
            bottom: zP.bottom - contentRect.bottom,
            right: zP.right - contentRect.right
        };
        if (availableSpace.top < 0 && availableSpace.bottom < 0) {
            if (availableSpace.bottom < availableSpace.top) {
                setDropUp(true);
                setMaxHeight(contentRect.height + availableSpace.top - triggerHeight);
            }
            else {
                setDropUp(false);
                setMaxHeight(contentRect.height + availableSpace.bottom);
            }
        }
        else {
            setDropUp(availableSpace.top > 0 && availableSpace.bottom < 0);
        }
        if (availableSpace.right < 0) {
            setAlignment('right');
        }
        setZeroPosition(zP);
    }, [triggerHeight, top]);
    const contentElement = fillHeight
        ? React.isValidElement(content) &&
            React.cloneElement(content, Object.assign(Object.assign({}, content.props), { maxHeight }))
        : content;
    useOnClickOutside(contentContainerRef, () => onClickOutside && onClickOutside());
    return createPortal(_jsx("div", Object.assign({ style: {
            top: -1 * zeroPosition.top,
            left: alignment == 'left' ? -1 * zeroPosition.left : undefined
        }, ref: containerRef, className: styles['container'], "data-overlay": true }, { children: _jsx("div", Object.assign({ style: {
                top,
                left: alignment === 'left' ? left : undefined,
                right: alignment === 'right' ? -1 * (right - zeroPosition.right) : undefined
            }, className: styles['content-container'] }, { children: _jsx("div", Object.assign({ ref: contentContainerRef, className: classes(dropUp && styles['anchor__up-right']), style: {
                    maxHeight,
                    transform: dropUp && triggerHeight
                        ? `translateY(calc(-100% - ${triggerHeight}px))`
                        : undefined
                } }, { children: _jsx(motion.div, Object.assign({ variants: dropdownVariants(dropUp ? 'up' : 'down'), initial: "initial", animate: "visible", exit: "exit" }, { children: contentElement }), `floating-dropdown-drop-${dropUp}`) })) })) })), document.body);
}
