import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { PLAN_DATE_VARIANT_ID, PLAN_PROPERTY_ID } from '../../../../common/consts/plan';
import { getPlanPropertyIcon, getPlanPropertyLabel } from '../../../../common/convert';
import { move, remove, replacePartial } from '../../../../common/utils/array';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { Button } from '../../../../uikit/Button/Button';
import { ActionModal } from '../../../ActionModal/ActionModal';
import { DropMenu } from '../../../DropMenu/DropMenu';
import { Close, Plus, SegmentCustom } from '../../../icons';
import { InfoBox } from '../../../InfoBox/InfoBox';
import { Loader } from '../../../Loader/Loader';
import { SelectboxEntry } from '../../../SelectboxEntry/SelectboxEntry';
import { TextOpacity } from '../../../TextOpacity/TextOpacity';
import { PlanSettingRow } from '../PlanSettingRow/PlanSettingRow';
import { SettingPanelLayout } from '../SettingPanelLayout/SettingPanelLayout';
import { TrailingSelect } from '../TrailingSelect/TrailingSelect';
import styles from './PlanPropertiesSettingPanel.module.css';
export function PlanPropertiesSettingPanel({ list, setList, tableViewSettingsList, propertyTypeList, unitTypeList, scrollToSelectedPropertyKey, getCustomPropertyDefaultTitle, onSaveClick, onCancelClick, onAttributeDelete, onSelectOptionDelete }) {
    const inputRefs = useRef([]);
    const optionInputRefs = useRef({});
    const refBottomAnchor = useRef(null);
    const currentTypeRef = useRef(null);
    const [areActionsOpen, setAreActionsOpen] = useState(false);
    const isLoading = !list || !propertyTypeList.length || !unitTypeList.length;
    useEffect(() => {
        if (!scrollToSelectedPropertyKey) {
            return;
        }
        if (currentTypeRef.current) {
            setTimeout(() => {
                var _a;
                (_a = currentTypeRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            }, 250);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToSelectedPropertyKey, currentTypeRef.current]);
    function scrollToBottom() {
        setTimeout(() => {
            var _a;
            (_a = refBottomAnchor.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        });
    }
    function handleKeyPress(event, index, optionIndex = -1) {
        if (event.key === 'Enter') {
            const nextOption = optionInputRefs.current[`option-${index}-${optionIndex + 1}`];
            if (nextOption) {
                nextOption.focus();
            }
            else {
                if (index < inputRefs.current.length - 1) {
                    inputRefs.current[index + 1].focus();
                }
                else {
                    handleSelectOptionCreate(index);
                }
            }
        }
    }
    function handleCreate(propertyTypeId) {
        var _a, _b;
        if (!list)
            return;
        let newOrderNr = ((_a = list[list.length - 1]) === null || _a === void 0 ? void 0 : _a.orderNumber) || 0;
        if (((_b = tableViewSettingsList[tableViewSettingsList.length - 1]) === null || _b === void 0 ? void 0 : _b.orderNumber) > newOrderNr) {
            newOrderNr = tableViewSettingsList[tableViewSettingsList.length - 1].orderNumber;
        }
        newOrderNr += 100;
        scrollToBottom();
        setList([
            ...list,
            {
                title: '',
                propertyTypeId,
                orderNumber: newOrderNr,
                selectOptions: propertyTypeId === PLAN_PROPERTY_ID.list
                    ? [
                        {
                            title: '',
                            orderNumber: 1
                        }
                    ]
                    : undefined,
                variantTypeId: propertyTypeId === PLAN_PROPERTY_ID.date ? PLAN_DATE_VARIANT_ID.day : undefined
            }
        ]);
    }
    function handleBlur(pIdx, soIdx) {
        if (!list)
            return;
        setList(soIdx != undefined
            ? replacePartial(list, pIdx, {
                selectOptions: replacePartial(list[pIdx].selectOptions, soIdx, {
                    title: list[pIdx].selectOptions[soIdx].title.trim()
                })
            })
            : replacePartial(list, pIdx, {
                title: list[pIdx].title.trim()
            }));
    }
    function handleChange(index, value) {
        if (!list)
            return;
        setList(replacePartial(list, index, {
            title: value
        }));
    }
    function handleDelete(index) {
        if (!list)
            return;
        const id = list[index].id;
        if (id) {
            onAttributeDelete && onAttributeDelete(id, () => setList(remove(list, index)));
        }
        else {
            setList(remove(list, index));
        }
    }
    function handleUnitOptionChange(index, unitTypeId) {
        if (!list)
            return;
        setList(replacePartial(list, index, { unitTypeId }));
    }
    function handleVariantOptionChange(index, variantTypeId) {
        if (!list)
            return;
        setList(replacePartial(list, index, { variantTypeId }));
    }
    function handleSelectOptionDragEnd(pIdx) {
        return ({ destination, source }) => {
            if (!destination || !list) {
                return;
            }
            setList(replacePartial(list, pIdx, {
                selectOptions: move(list[pIdx].selectOptions, source.index, destination.index).map((item, idx) => (Object.assign(Object.assign({}, item), { orderNumber: idx + 1 })))
            }));
        };
    }
    function handleSelectOptionChange(pIdx, soIdx, value) {
        if (!list)
            return;
        setList(replacePartial(list, pIdx, {
            selectOptions: replacePartial(list[pIdx].selectOptions, soIdx, {
                title: value
            })
        }));
    }
    function handleSelectOptionCreate(pIdx) {
        var _a;
        if (!list)
            return;
        setList(replacePartial(list, pIdx, {
            selectOptions: [
                ...(list[pIdx].selectOptions || []),
                {
                    title: '',
                    orderNumber: (((_a = list[pIdx].selectOptions) === null || _a === void 0 ? void 0 : _a.length) || 0) + 1
                }
            ]
        }));
    }
    function removeSelectOptionFromList(pIdx, soIdx) {
        if (!list)
            return;
        setList(replacePartial(list, pIdx, {
            selectOptions: remove(list[pIdx].selectOptions, soIdx)
        }));
    }
    function handleSelectOptionDelete(pIdx, soIdx) {
        if (!list || !list[pIdx].selectOptions)
            return;
        const columnKey = list[pIdx].columnKey;
        const id = list[pIdx].selectOptions[soIdx].id;
        if (id) {
            onSelectOptionDelete &&
                onSelectOptionDelete(columnKey, id, () => removeSelectOptionFromList(pIdx, soIdx));
        }
        else {
            removeSelectOptionFromList(pIdx, soIdx);
        }
    }
    return (_jsxs(SettingPanelLayout, Object.assign({ overtitle: _jsx(Ditto, { componentId: "layerpanel.maplayermodal.plannedsegments" }), title: _jsx(Ditto, { componentId: "properties" }), icon: _jsx(SegmentCustom, {}), closeButton: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), color: "gray", onClick: () => list && onCancelClick && onCancelClick(list) }), bottomLeftButton: _jsx(DropMenu, { setIsOpen: setAreActionsOpen, isOpen: areActionsOpen, direction: "up-right", control: _jsx(Button, Object.assign({ variant: "modal-action", icon: _jsx(Plus, {}), color: "gray" }, { children: _jsx(Ditto, { componentId: "globalsettings.addproperty" }) })), menu: _jsx(ActionModal, Object.assign({ width: 234 }, { children: propertyTypeList.map((property) => (_jsx(SelectboxEntry, { leadingIcon: getPlanPropertyIcon(property.id), text: getPlanPropertyLabel(property.id), onClick: () => {
                        setAreActionsOpen(false);
                        handleCreate(property.id);
                    }, "data-test": `btn-action-create-${property.title}` }, `property-${property.title}`))) })) }), bottomRightButton: _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => list && onSaveClick && onSaveClick(list) }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }, { children: [isLoading && (_jsx("div", Object.assign({ className: styles['center'] }, { children: _jsx(Loader, {}) }))), !isLoading && (_jsxs(_Fragment, { children: [_jsx(InfoBox, { className: styles['info-box'], content: _jsx(Ditto, { componentId: "globalsettings.content.properties.customizeobjectdetailsinfo" }) }), list.length === 0 && (_jsx("div", Object.assign({ className: styles['center'] }, { children: _jsx("img", { width: 245, height: 210, src: `/custom-properties-empty.png` }) }))), list.map((item, pIdx) => (_jsx(PropertySettingRow, { index: pIdx, item: item, placeholder: getCustomPropertyDefaultTitle(item.propertyTypeId, pIdx + 1), scrollToSelectedPropertyKey: scrollToSelectedPropertyKey, unitTypeList: unitTypeList, currentTypeRef: currentTypeRef, inputRefs: inputRefs, optionInputRefs: optionInputRefs, handleKeyPress: handleKeyPress, handleBlur: handleBlur, handleChange: handleChange, handleDelete: handleDelete, handleUnitOptionChange: handleUnitOptionChange, handleVariantOptionChange: handleVariantOptionChange, handleSelectOptionChange: handleSelectOptionChange, handleSelectOptionCreate: handleSelectOptionCreate, handleSelectOptionDelete: handleSelectOptionDelete, handleSelectOptionDragEnd: handleSelectOptionDragEnd }, item.id || `new-${pIdx}`))), _jsx("div", { ref: refBottomAnchor, className: styles['content-bottom-anchor'] })] }))] })));
}
function PropertySettingRow({ index: pIdx, item, placeholder, scrollToSelectedPropertyKey, unitTypeList, currentTypeRef, inputRefs, optionInputRefs, handleKeyPress, handleBlur, handleChange, handleDelete, handleUnitOptionChange, handleVariantOptionChange, handleSelectOptionChange, handleSelectOptionDelete, handleSelectOptionCreate, handleSelectOptionDragEnd }) {
    var _a, _b;
    const addOptionLabel = useDittoWrapper({
        componentId: 'assets.assettypes.attributes.addoption'
    });
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    const dayLabel = useDittoWrapper({ componentId: 'timepicker.day' });
    const monthLabel = useDittoWrapper({ componentId: 'timepicker.month' });
    return (_jsx(PlanSettingRow, Object.assign({ className: classes(item.propertyTypeId !== PLAN_PROPERTY_ID.list && styles['row-margin']), ref: (ref) => {
            if (scrollToSelectedPropertyKey === item.columnKey && (ref === null || ref === void 0 ? void 0 : ref.wrapperRef)) {
                currentTypeRef.current = ref === null || ref === void 0 ? void 0 : ref.wrapperRef;
            }
            if (ref === null || ref === void 0 ? void 0 : ref.inputRef) {
                inputRefs.current[pIdx] = ref === null || ref === void 0 ? void 0 : ref.inputRef;
            }
        }, onKeyDown: (event) => handleKeyPress(event, pIdx), onBlur: () => handleBlur(pIdx), onChange: (value) => handleChange(pIdx, value), onDelete: () => handleDelete(pIdx), value: item.title, placeholder: placeholder, initiallyExpanded: !item.id || scrollToSelectedPropertyKey === item.columnKey, autoFocus: !item.id, indentLevel: 1, innerIcon: getPlanPropertyIcon(item.propertyTypeId), innerIconOpacity: 0.4, trailingElement: item.propertyTypeId === PLAN_PROPERTY_ID.list && (_jsx(TextOpacity, { text: ((_a = item.selectOptions) === null || _a === void 0 ? void 0 : _a.length) || 0, opacity: 0.6 })), trailingSelect: item.propertyTypeId === PLAN_PROPERTY_ID.numeric ||
            item.propertyTypeId === PLAN_PROPERTY_ID.date ? (_jsxs(_Fragment, { children: [item.propertyTypeId === PLAN_PROPERTY_ID.numeric && (_jsx(TrailingSelect, { value: ((_b = unitTypeList.find((u) => u.id === item.unitTypeId)) === null || _b === void 0 ? void 0 : _b.title) ||
                        notSpecifiedLabel, menuItems: [
                        _jsx(SelectboxEntry, { text: notSpecifiedLabel, onClick: () => handleUnitOptionChange(pIdx, undefined) }, "unit-notspecified"),
                        ...unitTypeList.map((unit) => (_jsx(SelectboxEntry, { text: unit.title, onClick: () => handleUnitOptionChange(pIdx, unit.id) }, `unit-${unit.id}`)))
                    ] })), item.propertyTypeId === PLAN_PROPERTY_ID.date && (_jsx(TrailingSelect, { value: item.variantTypeId === 2 ? monthLabel : dayLabel, menuItems: [
                        _jsx(SelectboxEntry, { text: dayLabel, onClick: () => handleVariantOptionChange(pIdx, 1) }, "date-day"),
                        _jsx(SelectboxEntry, { text: monthLabel, onClick: () => handleVariantOptionChange(pIdx, 2) }, "date-month")
                    ] }))] })) : undefined }, { children: item.propertyTypeId === PLAN_PROPERTY_ID.list && (_jsxs(_Fragment, { children: [_jsx(DragDropContext, Object.assign({ onDragEnd: handleSelectOptionDragEnd(pIdx) }, { children: _jsx(Droppable, Object.assign({ droppableId: "droppableSelectOptions" }, { children: (provided) => {
                            var _a;
                            return (_jsxs("div", Object.assign({ ref: provided.innerRef, className: styles['options'] }, provided.droppableProps, { children: [(_a = item.selectOptions) === null || _a === void 0 ? void 0 : _a.map((option, soIdx) => (_jsx(DraggableOptionRow, { index: soIdx, item: option, placeholder: `Option ${soIdx + 1}`, propertyIndex: pIdx, propertyItem: item, optionInputRefs: optionInputRefs, handleKeyPress: handleKeyPress, handleBlur: handleBlur, handleSelectOptionChange: handleSelectOptionChange, handleSelectOptionDelete: handleSelectOptionDelete }, `option-${pIdx}-${soIdx}`))), provided.placeholder] })));
                        } })) })), _jsx(PlanSettingRow, { className: classes(styles['option-margin'], styles['add-option']), indentLevel: 2, value: addOptionLabel, onClick: () => handleSelectOptionCreate(pIdx), variant: "button" })] })) })));
}
function DraggableOptionRow({ index: soIdx, item, propertyIndex: pIdx, propertyItem, optionInputRefs, handleKeyPress, handleBlur, handleSelectOptionChange, handleSelectOptionDelete }) {
    return (_jsx(Draggable, Object.assign({ draggableId: `option-${pIdx}-${soIdx}`, index: soIdx, disableInteractiveElementBlocking: false }, { children: (provided) => (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { tabIndex: -1 }, { children: _jsx(PlanSettingRow, { className: classes(styles['row-margin'], propertyItem.selectOptions.length === 1 && styles['option-margin']), initiallyExpanded: false, ref: (ref) => {
                    if (ref === null || ref === void 0 ? void 0 : ref.inputRef) {
                        optionInputRefs.current[`option-${pIdx}-${soIdx}`] = ref === null || ref === void 0 ? void 0 : ref.inputRef;
                    }
                }, onKeyDown: (event) => handleKeyPress(event, pIdx, soIdx), onBlur: () => handleBlur(pIdx, soIdx), onChange: (value) => handleSelectOptionChange(pIdx, soIdx, value), onDelete: propertyItem.selectOptions.length > 1
                    ? () => handleSelectOptionDelete(pIdx, soIdx)
                    : undefined, indentLevel: 2, value: item.title, placeholder: `Option ${soIdx + 1}`, autoFocus: !item.id, draggable: true }) }))) })));
}
