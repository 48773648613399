// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FfPNmjFm15Mx9pogSMMR{width:366px;height:100%;max-height:calc(100vh - 24px - 48px);border-radius:16px;background:var(--primary-white);display:flex;flex-direction:column;align-items:stretch}.TeF29nStShcRIQtpYJXK{display:flex;align-items:center;justify-content:space-between;padding:12px}.jk4bnlpv8H5HYqRSAV6c{font-weight:600;font-size:20px}.K747gwOV6bkSg_D440zT{display:flex;align-items:stretch;flex-direction:column;overflow:auto;scrollbar-width:none !important}`, "",{"version":3,"sources":["webpack://./src/components/SegmentPanel/SegmentPanel.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,WAAA,CACA,oCAAA,CACA,kBAAA,CACA,+BAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,mBAAA,CACA,qBAAA,CACA,aAAA,CACA,+BAAA","sourcesContent":[".container {\n    width: 366px;\n    height: 100%;\n    max-height: calc(100vh - 24px - 48px);\n    border-radius: 16px;\n    background: var(--primary-white);\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n}\n\n.headline {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 20px;\n}\n\n.content {\n    display: flex;\n    align-items: stretch;\n    flex-direction: column;\n    overflow: auto;\n    scrollbar-width: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FfPNmjFm15Mx9pogSMMR`,
	"headline": `TeF29nStShcRIQtpYJXK`,
	"title": `jk4bnlpv8H5HYqRSAV6c`,
	"content": `K747gwOV6bkSg_D440zT`
};
export default ___CSS_LOADER_EXPORT___;
