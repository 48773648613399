import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { classes } from 'classifizer';
import { format, parse } from 'date-fns';
import { Ditto } from 'ditto-react';
import { PLAN_DATE_VARIANT_ID, PLAN_PROPERTY_ID } from '../../common/consts/plan';
import { getDateFnsLocale } from '../../common/utils/locale';
import { getCompletionYearMonthString } from '../../common/utils/time';
import { getUnitTypeById } from '../../common/utils/units';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useModalContext } from '../../state/context';
import { Divider } from '../../uikit/Divider/Divider';
import { Customize } from '../Customize/Customize';
import { FormInputSelect } from '../FormInputSelect/FormInputSelect';
import { FormInputText } from '../FormInputText/FormInputText';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import styles from './PlanCustomPropertiesPanel.module.css';
export function PlanCustomPropertiesPanel({ locale, countryCode, data = {}, padding, properties, planUnitTypes, onEditPropertiesClick, onChange, onSubmit, unitFormatter, variant }) {
    const { addModal } = useModalContext();
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    const elementRef = useRef(null);
    function handleChange(field, value) {
        onChange('customData', Object.assign(Object.assign({}, data), { [field]: value }));
    }
    function handleCustomMonthClick(columnKey, date) {
        if (!columnKey)
            return;
        addModal({
            id: 'MonthPickerModalContainer',
            props: {
                month: date ? (date === null || date === void 0 ? void 0 : date.getMonth()) + 1 : undefined,
                year: date ? date.getFullYear() : undefined,
                onConfirm: (value) => {
                    handleChange(columnKey, value ? format(value === null || value === void 0 ? void 0 : value.startDate, 'yyyy-MM-dd') : undefined);
                }
            }
        });
    }
    function handleCustomDateClick(columnKey, date) {
        if (!columnKey)
            return;
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                date: date ? format(date, 'yyyy-MM-dd') : undefined,
                onConfirm: (value) => {
                    handleChange(columnKey, value ? format(value === null || value === void 0 ? void 0 : value.startDate, 'yyyy-MM-dd') : undefined);
                }
            }
        });
    }
    function getColumnProperties(index) {
        var _a;
        let className = 'item';
        let dropdownDirection = 'down-right';
        // set className
        const propertyType = properties[index].propertyTypeId;
        const prevPropertyType = index > 0 ? properties[index - 1].propertyTypeId : undefined;
        const nextPropertyType = index < properties.length - 1 ? properties[index + 1].propertyTypeId : undefined;
        const isSmallField = (type) => type && type !== PLAN_PROPERTY_ID.text && type !== PLAN_PROPERTY_ID.list;
        if (propertyType === PLAN_PROPERTY_ID.date || propertyType === PLAN_PROPERTY_ID.numeric) {
            if (isSmallField(nextPropertyType) || isSmallField(prevPropertyType)) {
                className = 'item-small';
            }
        }
        // set dropdownDirection
        const cellElement = (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.children[index];
        if (cellElement) {
            const parentRect = elementRef.current.getBoundingClientRect();
            const cellRect = cellElement.getBoundingClientRect();
            const isOnRightSide = cellRect.left - parentRect.left > 0;
            if (isOnRightSide) {
                dropdownDirection = 'down-left';
            }
            else {
                if (!isSmallField(nextPropertyType)) {
                    className = 'item';
                }
            }
        }
        return { className, dropdownDirection };
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], padding && styles['container--padding'], variant && styles[`container--${variant}`]), "data-test": "custom-properties-container" }, { children: [_jsx("div", Object.assign({ className: styles['header'] }, { children: _jsx("div", Object.assign({ className: styles['headline'] }, { children: _jsx(Ditto, { componentId: "properties" }) })) })), _jsxs("div", Object.assign({ className: styles['content'], ref: elementRef }, { children: [properties.length === 0 && (_jsx("div", Object.assign({ className: styles['empty'] }, { children: _jsx(Ditto, { componentId: "customproperties.nopropertiesspecified" }) }))), properties.map((property, index) => {
                        var _a, _b, _c, _d;
                        const value = data ? data[property.columnKey] : undefined;
                        const selectedValue = (_b = (_a = property.selectOptions) === null || _a === void 0 ? void 0 : _a.find((option) => option.id === value)) === null || _b === void 0 ? void 0 : _b.title;
                        const { className } = getColumnProperties(index);
                        const { unit, decimalSeparator, thousandSeparator } = unitFormatter({
                            type: getUnitTypeById(property.unitTypeId || 0),
                            value,
                            unit: (_c = planUnitTypes.find((unit) => unit.id === property.unitTypeId)) === null || _c === void 0 ? void 0 : _c.title
                        });
                        let parsedDate;
                        if (data &&
                            data[property.columnKey] &&
                            property.propertyTypeId === PLAN_PROPERTY_ID.date) {
                            parsedDate = parse(value, 'yyyy-MM-dd', new Date(), {
                                locale: getDateFnsLocale(locale, countryCode)
                            });
                        }
                        switch (property.propertyTypeId) {
                            case PLAN_PROPERTY_ID.text:
                                return (_jsx("div", Object.assign({ className: styles[className] }, { children: _jsx(FormInputText, { label: property.title, placeholder: notSpecifiedLabel, onValueChange: (value) => handleChange(property.columnKey, value), onBlur: onSubmit, value: value || '', variant: variant }) }), `custom-property-${index}`));
                            case PLAN_PROPERTY_ID.numeric:
                                return (_jsx("div", Object.assign({ className: styles[className] }, { children: _jsx(NumericFormat, { customInput: FormInputText, onValueChange: (values) => {
                                            handleChange(property.columnKey, isNaN(parseFloat(values.value))
                                                ? undefined
                                                : parseFloat(values.value));
                                        }, onBlur: onSubmit, thousandSeparator: thousandSeparator, decimalSeparator: decimalSeparator, decimalScale: property.unitTypeId ? 2 : undefined, fixedDecimalScale: Boolean(property.unitTypeId), allowNegative: Boolean(!property.unitTypeId), suffix: property.unitTypeId ? ` ${unit}` : undefined, placeholder: notSpecifiedLabel, label: property.title, value: value !== undefined ? value : '', variant: variant, valueIsNumericString: true }) }), `custom-property-${index}`));
                            case PLAN_PROPERTY_ID.list:
                                return (_jsx("div", Object.assign({ className: styles[className] }, { children: _jsx(FormInputSelect, { label: property.title, placeholder: notSpecifiedLabel, menuItems: [
                                            ...(((_d = property.selectOptions) === null || _d === void 0 ? void 0 : _d.map((option) => (_jsx(SelectboxEntry, { onClick: () => {
                                                    handleChange(property.columnKey, option.id);
                                                    onSubmit && onSubmit();
                                                }, isChecked: value === option.id, text: option.title }, `option-${option.id}`)))) || []),
                                            _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "notspecified" }), onClick: () => {
                                                    handleChange(property.columnKey, undefined);
                                                    onSubmit && onSubmit();
                                                }, isChecked: !value }, "option-not-specifed"),
                                            _jsx(Divider, {}, "option-divider"),
                                            _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "properties.editproperties" }), onClick: () => onEditPropertiesClick(property.columnKey), withUnderline: true }, "option-edit-properties")
                                        ], value: selectedValue ? _jsx(_Fragment, { children: selectedValue }) : undefined, variant: variant }) }), `custom-property-${index}`));
                            case PLAN_PROPERTY_ID.date:
                                return (_jsx("div", Object.assign({ className: styles[className] }, { children: property.variantTypeId === PLAN_DATE_VARIANT_ID.month ? (_jsx(FormInputSelect, { label: property.title, onClick: () => handleCustomMonthClick(property.columnKey, parsedDate), hideMenu: true, placeholder: notSpecifiedLabel, value: parsedDate ? (_jsx(_Fragment, { children: getCompletionYearMonthString(locale, parsedDate === null || parsedDate === void 0 ? void 0 : parsedDate.getFullYear(), parsedDate.getMonth() + 1, true) })) : undefined, variant: variant })) : (_jsx(FormInputSelect, { label: property.title, onClick: () => handleCustomDateClick(property.columnKey, parsedDate), hideMenu: true, placeholder: notSpecifiedLabel, value: parsedDate ? (_jsx(_Fragment, { children: format(parsedDate, 'yyyy-MM-dd') })) : undefined, variant: variant })) }), `custom-property-${index}`));
                            default:
                                return _jsx(_Fragment, {});
                        }
                    })] })), _jsx(Customize, { onClick: onEditPropertiesClick, properties: true })] })));
}
