var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { keycloakLogout } from '../../common/keycloak';
import { ROOT_PATH } from '../../common/url';
import { validateEmail } from '../../common/validation/email';
import { AppLayoutForm } from '../../components/AppLayoutForm/AppLayoutForm';
import { RegisterEmail } from '../../components/Auth/RegisterEmail/RegisterEmail';
import { RegisterForm } from '../../components/Auth/RegisterForm/RegisterForm';
import { ThankYouModal } from '../../components/Modals/ThankYouModal/ThankYouModal';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addUserToGroup, checkIfUserExists, createUser, fetchTeamAccount } from '../../state/slices/auth';
import { NotificationContainer } from '../NotificationContainer/NotificationContainer';
const REGISTER_START_STEP = 'register-start';
const REGISTER_FORM_STEP = 'register-form';
const THANK_YOU_STEP = 'thank-you';
export function RegisterContainer() {
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector((state) => state.app);
    const { targetSchema, status, userAccount } = useAppSelector((state) => state.auth);
    const { departmentList } = useDepartmentsData();
    const [step, setStep] = useState(REGISTER_START_STEP);
    const [error, setError] = useState();
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        image: undefined,
        titleId: null,
        departmentId: null,
        email: ''
    });
    const [passwordValues, setPasswordValues] = useState({
        password: '',
        passwordConfirm: ''
    });
    useEffect(() => {
        if (targetSchema) {
            dispatch(fetchTeamAccount());
        }
    }, [dispatch, targetSchema]);
    useEffect(() => {
        if (userAccount === null || userAccount === void 0 ? void 0 : userAccount.email) {
            window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.main}`;
        }
    }, [userAccount === null || userAccount === void 0 ? void 0 : userAccount.email]);
    const emailErrorLabel = useDittoWrapper({ componentId: 'modal.email.error' });
    const errorLabel = useDittoWrapper({ componentId: 'toast.somethingwentwrong' });
    function onChange(name, value) {
        setValues(Object.assign(Object.assign({}, values), { [name]: value }));
    }
    function onPasswordChange(name, value) {
        setPasswordValues(Object.assign(Object.assign({}, passwordValues), { [name]: value }));
    }
    const emailCheckHandler = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setError(undefined);
            if (!validateEmail(values.email)) {
                setError(emailErrorLabel);
                return;
            }
            const isExists = yield dispatch(checkIfUserExists(values.email)).unwrap();
            if (isExists && targetSchema) {
                yield dispatch(addUserToGroup({ email: values.email })).unwrap();
                setStep(THANK_YOU_STEP);
            }
            else {
                setStep(REGISTER_FORM_STEP);
            }
        }
        catch (err) {
            setError(errorLabel);
        }
    });
    function handleRegisterClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!targetSchema) {
                return;
            }
            try {
                yield dispatch(createUser({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    userName: values.email,
                    titleId: values.titleId,
                    departmentId: values.departmentId,
                    groupNames: [targetSchema],
                    email: values.email,
                    locale,
                    password: passwordValues.password
                })).unwrap();
                setStep(THANK_YOU_STEP);
            }
            catch (err) {
                setError(errorLabel);
            }
        });
    }
    function onBackClick() {
        setStep(REGISTER_START_STEP);
    }
    function handleLogout() {
        keycloakLogout();
    }
    function getStepComponent(step) {
        switch (step) {
            case REGISTER_START_STEP:
                return (_jsx(RegisterEmail, { email: values.email, loading: status === 'loading', error: error, onChange: (value) => onChange('email', value.toLowerCase().trim()), onLogoutClick: handleLogout, onRegisterClick: emailCheckHandler }));
            case REGISTER_FORM_STEP:
                return (_jsx(RegisterForm, { currentLocale: locale, values: values, passwordValues: passwordValues, departmentList: departmentList, hasError: !!error, loading: status === 'loading', onChange: onChange, onBackClick: onBackClick, onPasswordChange: onPasswordChange, onRegisterClick: handleRegisterClick }));
            case THANK_YOU_STEP:
                return _jsx(ThankYouModal, { onSignInClick: handleLogout, variant: "email" });
        }
    }
    return (_jsx(AppLayoutForm, Object.assign({ notification: _jsx(NotificationContainer, {}) }, { children: getStepComponent(step) })));
}
