export function useFilterWrapperHandlers() {
    function handleFilterGroupWrapperToggle(state, filterWrapperRef, panelContainerRef) {
        // scroll to bottom if filter group is expanded and bottom of the filter group is not visible
        if (!state || !filterWrapperRef.current || !panelContainerRef.current) {
            return;
        }
        const doneButtonHeight = 65;
        const containerRect = panelContainerRef.current.getBoundingClientRect();
        const filterWrapperRect = filterWrapperRef.current.getBoundingClientRect();
        const filterWrapperTopEdge = filterWrapperRect.top - containerRect.top;
        const filterWrapperBottomEdge = filterWrapperRect.bottom + doneButtonHeight;
        let scrollTo;
        // if filter group is below the container, scroll to the bottom of the filter group
        if (containerRect.bottom < filterWrapperBottomEdge) {
            scrollTo =
                panelContainerRef.current.scrollTop +
                    filterWrapperBottomEdge -
                    containerRect.bottom;
        }
        // if filter group is higher than the container, scroll to the top of the filter group
        if (filterWrapperRect.height > containerRect.height) {
            scrollTo = panelContainerRef.current.scrollTop + filterWrapperTopEdge;
        }
        if (!scrollTo)
            return;
        panelContainerRef.current.scrollTo({
            top: scrollTo,
            behavior: 'smooth'
        });
    }
    return { handleFilterGroupWrapperToggle };
}
