import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { addBreadcrumb } from '@sentry/react';
import { Outlet } from '@tanstack/react-location';
import { keycloakLogin } from '../../common/keycloak';
import { ROOT_PATH } from '../../common/url';
import { useAppSelector } from '../../state/hooks';
export function Auth({ checkTargetSchema = true }) {
    const { targetSchema, isAuthenticated, isAuthProcessing, isKeycloakReady } = useAppSelector((state) => state.auth);
    useEffect(() => {
        if (!isAuthenticated && !isAuthProcessing && isKeycloakReady) {
            addBreadcrumb({
                category: 'user',
                message: 'Auth: Not authenticated, redirecting to login',
                level: 'info'
            });
            const redirectUri = `${window.location.origin.toString()}/${ROOT_PATH.account}`;
            keycloakLogin(redirectUri);
        }
        if (isAuthenticated && checkTargetSchema && !targetSchema) {
            addBreadcrumb({
                category: 'user',
                message: 'Auth: No target schema, redirecting to account page',
                level: 'info'
            });
            window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.account}`;
        }
        addBreadcrumb({
            category: 'user',
            message: 'Auth: Passing authenticated user to Outlet',
            level: 'info'
        });
    }, [checkTargetSchema, isAuthProcessing, isAuthenticated, isKeycloakReady, targetSchema]);
    return _jsx(Outlet, {});
}
