import { registerLocale } from 'react-datepicker';
import { de, enGB, enUS, fr } from 'date-fns/locale';
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
export const getDateFnsLocale = (locale, countryCode) => {
    switch (locale) {
        case 'de':
            return de;
        case 'fr':
            return fr;
        default: {
            if (countryCode === 'US') {
                return enUS;
            }
            return enGB;
        }
    }
};
export function getLanguageFromCountryCode(countryCode) {
    switch (countryCode) {
        case 'DE' || 'AT' || 'CH':
            return 'de';
        case 'FR':
            return 'fr';
        default:
            return 'en';
    }
}
