// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fikOPPRMXhPcrf9k4cwf{position:absolute;bottom:70px;left:50%;padding:0 4px;transform:translateX(-50%);background:var(--primary-white);box-shadow:var(--box-shadow-regular);border-radius:16px}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTableActionbar/DataGridTableActionbar.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,WAAA,CACA,QAAA,CACA,aAAA,CACA,0BAAA,CACA,+BAAA,CAGA,oCAAA,CACA,kBAAA","sourcesContent":[".container {\n    position: absolute;\n    bottom: 70px;\n    left: 50%;\n    padding: 0 4px;\n    transform: translateX(-50%);\n    background: var(--primary-white);\n\n    /* Elevated 02 */\n    box-shadow: var(--box-shadow-regular);\n    border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fikOPPRMXhPcrf9k4cwf`
};
export default ___CSS_LOADER_EXPORT___;
