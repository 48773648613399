import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useFloatingDropdown } from '../../../../hooks/forms/useFloatingDropdown';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { Button } from '../../../../uikit/Button/Button';
import { ActionModal } from '../../../ActionModal/ActionModal';
import { FloatingDropdown } from '../../../FloatingDropdown/FloatingDropdown';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { ArrowRight, Caret } from '../../../icons';
import styles from './AutoUpdateModalRowItem.module.css';
export function AutoUpdateModalRowItem({ label, inlineEdit, inputRef, isBulk, newValue, newMultipleValue, oldValue, onOpen, onSubmit, unitFormatter, 'data-test': dataTest }) {
    var _a, _b;
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown({});
    const notSpecified = useDittoWrapper({ componentId: 'notspecified' });
    const multiple = useDittoWrapper({ componentId: 'bulkeditmodal.multiple' });
    useEffect(() => {
        const handleKeyboardKey = (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                handleSubmit();
            }
            else if (event.key === 'Escape') {
                closeDropdown();
            }
        };
        if (isFloatingOpen) {
            window.addEventListener('keydown', handleKeyboardKey);
        }
        return () => {
            if (isFloatingOpen) {
                window.removeEventListener('keydown', handleKeyboardKey);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFloatingOpen, (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.value]);
    useEffect(() => {
        if ((inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) && isFloatingOpen) {
            // fix textarea cursor position / move cursor to the end of the text if it is textarea
            if (inputRef.current.selectionStart === 0) {
                inputRef.current.focus();
                inputRef.current.selectionStart = inputRef.current.selectionEnd =
                    inputRef.current.value.length;
            }
            else {
                inputRef.current.select();
            }
        }
    }, [inputRef, isFloatingOpen]);
    function closeDropdown() {
        hide();
    }
    function handleClick() {
        if (!isFloatingOpen) {
            onOpen && onOpen();
            toggle();
        }
    }
    function handleSubmit() {
        onSubmit && onSubmit();
        hide();
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles['field'], isFloatingOpen && styles['field--open']), ref: buttonRef, onClick: handleClick, "data-test": dataTest }, { children: [_jsx("div", Object.assign({ className: styles['title'] }, { children: label })), _jsxs("div", Object.assign({ className: styles['values'] }, { children: [_jsxs("span", Object.assign({ className: classes(styles['value'], styles['value--old']) }, { children: [unitFormatter && oldValue && unitFormatter({ value: oldValue }).formattedText, !unitFormatter && oldValue, !oldValue && (_jsx("span", Object.assign({ className: styles['notspecified'] }, { children: isBulk ? multiple : notSpecified })))] })), _jsx("span", Object.assign({ className: styles['arrow-icon'] }, { children: _jsx(ArrowRight, {}) })), _jsxs("span", Object.assign({ className: classes(styles['value']) }, { children: [newValue !== undefined && unitFormatter
                                ? unitFormatter({ value: newValue }).formattedText
                                : newValue !== null && newValue !== void 0 ? newValue : (_jsx("span", Object.assign({ className: styles['notspecified'] }, { children: newMultipleValue ? multiple : notSpecified }))), isFloatingOpen && (_jsx(FloatingDropdown, Object.assign({}, position, { align: "right", triggerHeight: (_b = buttonRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight, content: _jsxs(ActionModal, Object.assign({ className: styles['modal'] }, { children: [_jsx("div", Object.assign({ className: classes(styles['modal-content']) }, { children: inlineEdit })), _jsx("div", Object.assign({ className: styles['buttons-container'] }, { children: _jsx(Button, Object.assign({ color: "dark", onClick: handleSubmit }, { children: _jsx(Ditto, { componentId: "done" }) })) }))] })), onClickOutside: closeDropdown })))] })), _jsx("div", Object.assign({ className: styles['caret'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Caret, {}) })) }))] }))] })));
}
