import { jsx as _jsx } from "react/jsx-runtime";
import React, { useId } from 'react';
import { classes } from 'classifizer';
import { motion } from 'framer-motion';
import { Loader } from '../Loader/Loader';
import { Asset } from './Asset/Asset';
import { InspectionV2 } from './InspectionV2/InspectionV2';
import { Marker } from './Marker/Marker';
import { Point } from './Point/Point';
import { ReferencingFlag } from './ReferencingFlag/ReferencingFlag';
import { RoadWork } from './RoadWork/RoadWork';
import { Sign } from './Sign/Sign';
import styles from './MapPopover.module.css';
const POPOVER_DIRECTION_Y_OFFSET = {
    up: -20,
    down: 20
};
export function MapPopover({ direction = 'up', content }) {
    const key = useId();
    const contentChildren = getContent(content);
    return (_jsx(motion.div, Object.assign({ animate: {
            opacity: 1,
            y: POPOVER_DIRECTION_Y_OFFSET[direction],
            transition: {
                delay: 0.1
            }
        }, initial: { opacity: 0 }, exit: {
            opacity: 0,
            y: 0,
            transition: {
                type: 'tween',
                duration: 0.3,
                opacity: {
                    duration: 0.1
                }
            }
        }, className: classes(styles['container'], styles[`container__${direction}`]) }, { children: contentChildren }), key));
}
const getContent = (content) => {
    switch (content.type) {
        case 'loader':
            return _jsx(Loader, { color: "white" });
        case 'referencing-flag':
            if (content.value) {
                return _jsx(ReferencingFlag, { content: content.value });
            }
            break;
        case 'point':
            if (content.value) {
                return _jsx(Point, { content: content.value });
            }
            break;
        case 'marker':
            if (content.value) {
                return _jsx(Marker, { content: content.value });
            }
            break;
        case 'object':
            if (content.value) {
                return _jsx(Point, { content: content.value });
            }
            break;
        case 'track-control':
        case 'winter-service':
            if (content.value) {
                return _jsx(InspectionV2, { content: content.value });
            }
            break;
        case 'planned-segment':
            if (content.value) {
                return content.value;
            }
            break;
        case 'marker-cluster':
            if (content.value) {
                return content.value;
            }
            break;
        case 'sign':
            if (content.value) {
                return _jsx(Sign, { content: content.value });
            }
            break;
        case 'sign-inventory':
            if (content.value) {
                return _jsx(Sign, { content: content.value });
            }
            break;
        case 'asset':
            if (content.value) {
                return _jsx(Asset, { content: content.value });
            }
            break;
        case 'road-work':
            if (content.value) {
                return _jsx(RoadWork, { content: content.value });
            }
            break;
    }
};
