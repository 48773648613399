import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { TABLE_WIDTH } from '../../../../common/consts/table';
import { SortAZ, SortNeutral, SortZA } from '../../../icons';
import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import { TextOverflow } from '../../../TextOverflow/TextOverflow';
import styles from './DataGridTableHeaderItem.module.css';
const MAX_COL_WIDTH_SORT_ICON_EXCEPTION = 60;
export function DataGridTableHeaderItem({ name, title, width: widthProp, info, textAlign = 'left', onClick, onResizeStart, sort, stickyPosition, showTooltip = true, tooltipWidth = 220, isLastActionColumn }) {
    const [isHovering, setIsHovering] = useState(false);
    const width = typeof widthProp == 'number' ? widthProp : TABLE_WIDTH[widthProp];
    return (_jsxs("th", Object.assign({ className: classes(styles['container'], !onClick && styles['container__noninteractive'], stickyPosition !== undefined && styles['container--pinned'], isLastActionColumn && styles['container--last-action-column']), style: { width, left: stickyPosition }, onMouseEnter: onClick ? () => setIsHovering(true) : undefined, onMouseLeave: onClick ? () => setIsHovering(false) : undefined }, { children: [_jsxs("div", Object.assign({ className: classes(styles['text'], textAlign && styles[`text--${textAlign}`]), onClick: onClick }, { children: [info && _jsx(InfoTooltip, { tooltip: info, width: tooltipWidth }), showTooltip ? _jsx(TextOverflow, { text: title, maxLines: 1, tooltip: "small" }) : title] })), sort && (_jsxs("div", Object.assign({ className: classes(styles['icon'], textAlign === 'right' &&
                    (width ? width > MAX_COL_WIDTH_SORT_ICON_EXCEPTION : true) &&
                    styles['icon-left']) }, { children: [sort === 'asc' && _jsx(SortZA, {}), sort === 'desc' && _jsx(SortAZ, {}), !sort && isHovering && _jsx(SortNeutral, {})] }))), name && onResizeStart && (_jsx("span", { className: styles['resize-handle'], onMouseDown: (event) => onResizeStart(name, event.clientX) }))] })));
}
