import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { INSPECTION_MODE_ID } from '../../../common/consts/inspection';
import { getInspectionTypeLabelV2, WinterServiceIcon } from '../../../common/convert';
import { IconResizer } from '../../IconResizer/IconResizer';
import { TrackControl, WinterService } from '../../icons';
import { Vignette } from '../../Vignette/Vignette';
import styles from './InspectionV2.module.css';
export function InspectionV2({ content }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['info']) }, { children: [content.time, ' · ', content.driver || '-'] })), _jsxs("div", Object.assign({ className: classes(styles['main']) }, { children: [_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsxs(_Fragment, { children: [content.inspectionModeId === INSPECTION_MODE_ID.trackControl && (_jsx(TrackControl, {})), content.inspectionModeId === INSPECTION_MODE_ID.winterService && (_jsx(WinterService, {}))] }) })), getInspectionTypeLabelV2(content.inspectionModeId), content.winterServiceModes && content.winterServiceModes.length > 0 && (_jsx("div", Object.assign({ className: classes(styles['right']) }, { children: content.winterServiceModes.map((i) => (_jsx(Vignette, { backgroundColor: "#4E5761", content: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(WinterServiceIcon, { type: i }) })) }, i))) })))] }))] })));
}
