import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../FormInputSelect/FormInputSelect';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { TypedDitto } from '../../TypedDitto';
import { SelectedWeekdays } from './SelectedWeekdays';
import styles from './RepeatForm.module.css';
const MAX_MENU_HEIGHT = 204;
export function WeeklyRepeatForm({ frequency, onChange, locale }) {
    const now = new Date();
    const daysOfThisWeek = eachDayOfInterval({
        start: startOfWeek(now, { locale }),
        end: endOfWeek(now, { locale })
    });
    return (_jsx("div", Object.assign({ className: styles['input-select'] }, { children: _jsx(FormInputSelect, { menuMaxHeight: MAX_MENU_HEIGHT, label: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.onthesedays" }), value: _jsx(FormInputCurrentSelectedValue, { text: _jsx(SelectedWeekdays, { days: frequency.days, locale: locale }) }), menuItems: daysOfThisWeek.map((day, index) => (_jsx("div", Object.assign({ className: styles['repeat-option'] }, { children: _jsx(SelectboxEntry, { withCheckbox: true, isChecked: frequency.days.includes(index), text: format(day, 'cccc', { locale }), onClick: () => {
                        var _a, _b;
                        const exists = frequency.days.find((value) => value === index);
                        // strict undefined check is used because 0 is one of the array items
                        if (exists === undefined) {
                            onChange({
                                repeat: 'weekly',
                                days: [...frequency.days, index].sort((a, b) => a - b),
                                weekStartsOn: (_a = locale === null || locale === void 0 ? void 0 : locale.options) === null || _a === void 0 ? void 0 : _a.weekStartsOn
                            });
                            return;
                        }
                        onChange({
                            repeat: 'weekly',
                            days: frequency.days.filter((value) => value !== index),
                            weekStartsOn: (_b = locale === null || locale === void 0 ? void 0 : locale.options) === null || _b === void 0 ? void 0 : _b.weekStartsOn
                        });
                    } }) }), day.getTime()))), canFloat: true }) })));
}
