import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { getAssigneeIcon, getSignIcon, getSignTypeIcon } from '../../../common/convert';
import { getSignTranslation, getSignTypeAbbreviation } from '../../../common/convert/signInventory';
import { isValidDate } from '../../../common/utils/time';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { ChangelogItem } from '../../DetailsPanel/Changelog/ChangelogItem/ChangelogItem';
import { Asterisk, Attachments, Edit, NavigationOrientation, NoAttachments, SparkleMagicApplied, VerifiedApproved } from '../../icons';
export function SignInventoryChangelog({ sign, dateTimeFormatter, taskAssigneeMap, formatDateISO }) {
    const labelNotSpecified = useDittoWrapper({ componentId: 'notspecified' });
    const typeLabel = useDittoWrapper({ componentId: 'changelog.type' });
    const conditionLabel = useDittoWrapper({ componentId: 'changelog.condition' });
    const addressLabel = useDittoWrapper({ componentId: 'address' });
    const fileAddedLabel = useDittoWrapper({ componentId: 'changelog.fileadded' });
    const fileRemovedLabel = useDittoWrapper({ componentId: 'changelog.fileremoved' });
    const createdInWebAppLabel = useDittoWrapper({ componentId: 'changelog.createdinweb-app' });
    const orientationLabel = useDittoWrapper({ componentId: 'trafficsigns.orientation' });
    const changelog = sign.changelog.map((changelog) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        const dateTime = dateTimeFormatter(changelog.createdAt, { forceShowTime: true });
        const trailingIcon = getAssigneeIcon(taskAssigneeMap, changelog.userUuid);
        if (changelog.operation === 'create_sign_ai') {
            return (_jsx(ChangelogItem, { icon: _jsx(SparkleMagicApplied, {}), to: _jsx(Ditto, { componentId: "trafficsigns.popover.ai-recommodation" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'create_sign_manually') {
            return (_jsx(ChangelogItem, { icon: _jsx(Asterisk, {}), to: createdInWebAppLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'confirm_sign') {
            return (_jsx(ChangelogItem, { icon: _jsx(VerifiedApproved, {}), to: _jsx(Ditto, { componentId: "trafficsigns.verified" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'update_sign_orientation') {
            return (_jsx(ChangelogItem, { icon: _jsx(NavigationOrientation, {}), from: ((_a = changelog.oldValue) === null || _a === void 0 ? void 0 : _a.orientation) + '°', to: ((_b = changelog.newValue) === null || _b === void 0 ? void 0 : _b.orientation) + '°', description: orientationLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'update_sign_type') {
            return (_jsx(ChangelogItem, { icon: getSignTypeIcon((_c = changelog.newValue) === null || _c === void 0 ? void 0 : _c.subSpecifiedClass), from: _jsx(Ditto, { componentId: getSignTypeAbbreviation(((_d = changelog.oldValue) === null || _d === void 0 ? void 0 : _d.subSpecifiedClass) || '') }), to: _jsx(Ditto, { componentId: getSignTypeAbbreviation(((_e = changelog.newValue) === null || _e === void 0 ? void 0 : _e.subSpecifiedClass) || '') }), description: typeLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'update_sign_damage_class') {
            return (_jsx(ChangelogItem, { icon: getSignIcon((_f = changelog.newValue) === null || _f === void 0 ? void 0 : _f.signDamageClass), from: getSignTranslation((_g = changelog.oldValue) === null || _g === void 0 ? void 0 : _g.signDamageClass), to: getSignTranslation((_h = changelog.newValue) === null || _h === void 0 ? void 0 : _h.signDamageClass), description: conditionLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'update_address') {
            return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: (_j = changelog.oldValue) === null || _j === void 0 ? void 0 : _j.address, to: (_k = changelog.newValue) === null || _k === void 0 ? void 0 : _k.address, description: addressLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'create_property') {
            const newValue = ((_m = (_l = changelog.newValue) === null || _l === void 0 ? void 0 : _l.property) === null || _m === void 0 ? void 0 : _m.value) || labelNotSpecified;
            const formattedNewValue = isValidDate(newValue) ? formatDateISO(newValue) : newValue;
            return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: labelNotSpecified, to: formattedNewValue, description: (_p = (_o = changelog.newValue) === null || _o === void 0 ? void 0 : _o.property) === null || _p === void 0 ? void 0 : _p.name, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'update_property') {
            const oldValue = ((_r = (_q = changelog.oldValue) === null || _q === void 0 ? void 0 : _q.property) === null || _r === void 0 ? void 0 : _r.value) || labelNotSpecified;
            const newValue = ((_t = (_s = changelog.newValue) === null || _s === void 0 ? void 0 : _s.property) === null || _t === void 0 ? void 0 : _t.value) || labelNotSpecified;
            const formattedOldValue = isValidDate(oldValue) ? formatDateISO(oldValue) : oldValue;
            const formattedNewValue = isValidDate(newValue) ? formatDateISO(newValue) : newValue;
            return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: formattedOldValue, to: formattedNewValue, description: (_v = (_u = changelog.newValue) === null || _u === void 0 ? void 0 : _u.property) === null || _v === void 0 ? void 0 : _v.name, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
        }
        if (changelog.operation === 'create_file') {
            return (_jsx(ChangelogItem, { icon: _jsx(Attachments, {}), to: (_w = changelog.newValue) === null || _w === void 0 ? void 0 : _w.filename, description: fileAddedLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, ellipsis: true }, changelog.id));
        }
        if (changelog.operation === 'delete_file') {
            return (_jsx(ChangelogItem, { icon: _jsx(NoAttachments, {}), to: (_x = changelog.oldValue) === null || _x === void 0 ? void 0 : _x.filename, description: fileRemovedLabel, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, ellipsis: true }, changelog.id));
        }
    });
    return _jsx(_Fragment, { children: changelog });
}
