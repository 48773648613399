// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rMKceJdP8eOYH3RVAYHg{background:var(--primary-white);display:flex;flex-direction:column;align-items:flex-start;padding:0 12px 12px}.yBWsvglo93QdRsLngdfK{display:flex;flex-direction:row;align-items:center;align-self:stretch;gap:12px;height:32px}.vIXj3hqEFzoUc7ZZS99M{background:var(--primary-dark-blue);opacity:.2;border-radius:10px;height:4px;flex-grow:1;transition:opacity 150ms ease-out}.vIXj3hqEFzoUc7ZZS99M:hover{background:var(--status-hover-hover-dark)}.pE5JlkK61X4KsClKw7dc{opacity:1}.R8iEDXmBZwxdXQ1lTEtP{display:flex;flex-direction:row;align-items:flex-start;align-self:stretch;gap:12px;height:40px}`, "",{"version":3,"sources":["webpack://./src/uikit/Stepper/Stepper.module.css"],"names":[],"mappings":"AAAA,sBACI,+BAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,mBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,WAAA,CAGJ,sBACI,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iCAAA,CAGJ,4BACI,yCAAA,CAGJ,sBACI,SAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,WAAA","sourcesContent":[".container {\n    background: var(--primary-white);\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 0 12px 12px;\n}\n\n.step-row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    align-self: stretch;\n    gap: 12px;\n    height: 32px;\n}\n\n.step-marker {\n    background: var(--primary-dark-blue);\n    opacity: 0.2;\n    border-radius: 10px;\n    height: 4px;\n    flex-grow: 1;\n    transition: opacity 150ms ease-out;\n}\n\n.step-marker:hover {\n    background: var(--status-hover-hover-dark);\n}\n\n.step-marker__current {\n    opacity: 1;\n}\n\n.button-row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    align-self: stretch;\n    gap: 12px;\n    height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rMKceJdP8eOYH3RVAYHg`,
	"step-row": `yBWsvglo93QdRsLngdfK`,
	"step-marker": `vIXj3hqEFzoUc7ZZS99M`,
	"step-marker__current": `pE5JlkK61X4KsClKw7dc`,
	"button-row": `R8iEDXmBZwxdXQ1lTEtP`
};
export default ___CSS_LOADER_EXPORT___;
