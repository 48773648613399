var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Ditto } from 'ditto-react';
import { MAP_CURSOR } from '../../common/consts/map';
import { ConditionFeatureType } from '../../common/consts/panelTab';
import { PLAN_PRIORITY_LIMIT } from '../../common/consts/plan';
import { nodeEdgeDataToSegmentFormData, nodeEdgeListToSegmentFormData } from '../../common/convert/node-edge';
import { getPlanPriorityIcon, getPlanPriorityLabel, getPlanPriorityName, getPlanThirdPartyIcon, getPlanThirdPartyLabel } from '../../common/convert/plan';
import { DetailsInfo } from '../../components/DetailsPanel/DetailsInfo/DetailsInfo';
import { DetailsPanel } from '../../components/DetailsPanel/DetailsPanel';
import { TreatmentSelectField } from '../../components/EditorForms/TreatmentSelect/TreatmentSelectField/TreatmentSelectField';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { Check } from '../../components/icons';
import { Loader } from '../../components/Loader/Loader';
import { PlanCustomPropertiesPanel } from '../../components/PlanCustomPropertiesPanel/PlanCustomPropertiesPanel';
import { CategorySelectItem, CategorySelectItemCurrentSelectedValue } from '../../components/SegmentForm/CategorySelectItem/CategorySelectItem';
import { SegmentForm } from '../../components/SegmentForm/SegmentForm';
import { StatusSelectItem, StatusSelectItemCurrentSelectedValue } from '../../components/SegmentForm/StatusSelectItem/StatusSelectItem';
import { SegmentPanel } from '../../components/SegmentPanel/SegmentPanel';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useUnitInputConverter } from '../../hooks/formatting/useUnitInputConverter';
import { getEdgesByIdsList } from '../../services/condition';
import { getPlanPreview } from '../../services/plan';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { fetchNodeEdge } from '../../state/slices/conditionDetail';
import { fetchBudgetSettings, fetchNetworkSettings } from '../../state/slices/globalSetting';
import { fetchTableViewSettings } from '../../state/slices/leftPanelPlan';
import { setDrawArea, setDrawVertexCount, setMapCursor } from '../../state/slices/mainMap';
import { addNotificationMessage } from '../../state/slices/notification';
import { fetchPlan } from '../../state/slices/plan';
import { resetSegmentDetail, resetSegmentSelection } from '../../state/slices/segmentDetail';
import { createSegment, createStreet, fetchStreetGeoJSON, resetSegmentForm, setFormData, setValue, submitSegmentForm } from '../../state/slices/segmentForm';
import { fetchPlanFilters } from '../../state/slices/shared';
import { Button } from '../../uikit/Button/Button';
export function SegmentFormContainer() {
    const { addModal } = useModalContext();
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { budgetAndNetwork } = useAppSelector((state) => state.globalSettings);
    const { fetchStatus } = useAppSelector((state) => state.plan);
    const { segment: segmentDetail } = useAppSelector((state) => state.segmentDetail);
    const { isLoading: segmentDataIsLoading } = useAppSelector((state) => state.conditionDetail);
    const { form: formData, shouldSubmit, createStatus } = useAppSelector((state) => state.segmentForm);
    const { table: { settings: tableSettings } } = useAppSelector((state) => state.leftPanelPlan);
    const { selectedFeature, multiselectedFeatures } = useAppSelector((state) => state.feature);
    const { planCategoryList, planStatusList, planTreatmentsList, planUnitTypes } = useAppSelector((state) => state.shared);
    const segmentsSelectedFeatures = multiselectedFeatures === null || multiselectedFeatures === void 0 ? void 0 : multiselectedFeatures.filter((item) => item.type === ConditionFeatureType.segment);
    const { formatAddress } = useFormatter();
    const unitFormatter = useUnitFormatter();
    const inputConverter = useUnitInputConverter();
    const { locale } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetSegmentForm());
        function setWidth() {
            dispatch(fetchNetworkSettings());
        }
        function fetchPlanData(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { result } = (yield dispatch(fetchPlan(id)).unwrap()) || {};
                if (result === null || result === void 0 ? void 0 : result.plan) {
                    dispatch(setFormData(result.plan));
                    if (result.plan.width === undefined) {
                        setWidth();
                    }
                }
            });
        }
        function fetchSegmentData(id) {
            return __awaiter(this, void 0, void 0, function* () {
                dispatch(fetchStreetGeoJSON({ streetIds: [id] }));
                const { result } = (yield dispatch(fetchNodeEdge(id)).unwrap()) || {};
                if (result) {
                    dispatch(setFormData(nodeEdgeDataToSegmentFormData(result)));
                }
                setWidth();
            });
        }
        function fetchBulkSegmentData(ids) {
            return __awaiter(this, void 0, void 0, function* () {
                if (ids.length === 0) {
                    return;
                }
                dispatch(fetchStreetGeoJSON({ streetIds: ids }));
                const { result } = (yield getEdgesByIdsList({ ids })) || {};
                if (result === null || result === void 0 ? void 0 : result.list) {
                    dispatch(setFormData(nodeEdgeListToSegmentFormData(result.list)));
                }
                setWidth();
            });
        }
        if (!tableSettings) {
            // fetch custom properties definitions
            dispatch(fetchTableViewSettings());
        }
        if (selectedFeature) {
            if (selectedFeature.type === 'plan') {
                fetchPlanData(selectedFeature.id);
            }
            else if (selectedFeature.type === 'segment') {
                // Fetch segment/street data when user click on "Add to Planning"
                // from Condition results list and add them to form data
                fetchSegmentData(selectedFeature.id);
            }
        }
        else if (segmentsSelectedFeatures && segmentsSelectedFeatures.length > 0) {
            const segmentsSelectedIds = segmentsSelectedFeatures === null || segmentsSelectedFeatures === void 0 ? void 0 : segmentsSelectedFeatures.map((f) => f.id);
            fetchBulkSegmentData(segmentsSelectedIds);
        }
        else if (segmentDetail) {
            // Add selected points from polygon to form data (creating new segment)
            dispatch(setFormData(Object.assign(Object.assign({}, formData), { name: segmentDetail.display_name, userModifiedAddressStart: formatAddress(segmentDetail.address_start), userModifiedAddressEnd: formatAddress(segmentDetail.address_end), length: segmentDetail.length, recordIds: segmentDetail.feature_collection.features.map((feature) => { var _a; return (_a = feature.properties) === null || _a === void 0 ? void 0 : _a.id; }), thirdParty: false })));
            setWidth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Set default width value if not set
    useEffect(() => {
        var _a, _b;
        if (!(formData === null || formData === void 0 ? void 0 : formData.width) && ((_a = budgetAndNetwork.network) === null || _a === void 0 ? void 0 : _a.roadWidth)) {
            onChange('width', (_b = budgetAndNetwork.network) === null || _b === void 0 ? void 0 : _b.roadWidth);
            onChange('magicInputWidth', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetAndNetwork]);
    useEffect(() => {
        calculateFieldValues('treatmentId');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData === null || formData === void 0 ? void 0 : formData.treatmentId]);
    useEffect(() => {
        calculateFieldValues('width');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData === null || formData === void 0 ? void 0 : formData.width]);
    useEffect(() => {
        calculateFieldValues('costs');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData === null || formData === void 0 ? void 0 : formData.priority, formData === null || formData === void 0 ? void 0 : formData.costs, formData === null || formData === void 0 ? void 0 : formData.yearsOfUse]);
    useEffect(() => {
        calculateFieldValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData === null || formData === void 0 ? void 0 : formData.priority, formData === null || formData === void 0 ? void 0 : formData.yearsOfUse]);
    useEffect(() => {
        if (shouldSubmit) {
            dispatch(submitSegmentForm(false));
            onSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, shouldSubmit]);
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);
    const category = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return planCategoryList.find((category) => formData.categoryId === category.id);
    }, [formData, planCategoryList]);
    const status = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return planStatusList.find((status) => formData.statusId === status.id);
    }, [formData, planStatusList]);
    const customProperties = useMemo(() => (tableSettings === null || tableSettings === void 0 ? void 0 : tableSettings.filter((column) => !column.isDefault)) || [], [tableSettings]);
    function calculateFieldValues(triggeredField) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!formData || !formData.length)
                return;
            const { result: previewData } = yield getPlanPreview({
                countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode,
                treatmentId: formData.treatmentId,
                priority: formData.priority,
                costs: formData.costs,
                yearsOfUse: formData.yearsOfUse,
                width: formData.width || undefined,
                length: formData.length,
                magicInputCosts: (_a = formData.magicInputCosts) !== null && _a !== void 0 ? _a : (formData.costs === undefined && true),
                magicInputWidth: (_b = formData.magicInputWidth) !== null && _b !== void 0 ? _b : (formData.width === undefined && true),
                magicInputYearsOfUse: (_c = formData.magicInputYearsOfUse) !== null && _c !== void 0 ? _c : (formData.yearsOfUse === undefined && true)
            });
            if (triggeredField === 'treatmentId') {
                if (previewData === null || previewData === void 0 ? void 0 : previewData.costs) {
                    onChange('costs', previewData.costs);
                    onChange('magicInputCosts', true);
                }
                if (previewData === null || previewData === void 0 ? void 0 : previewData.yearsOfUse) {
                    onChange('yearsOfUse', previewData.yearsOfUse);
                    onChange('magicInputYearsOfUse', true);
                }
            }
            if (triggeredField !== 'costs' &&
                formData.magicInputCosts &&
                (previewData === null || previewData === void 0 ? void 0 : previewData.costs) !== undefined) {
                onChange('costs', previewData.costs);
                onChange('magicInputValueCosts', previewData.costs);
                // fix to avoid magicInputCosts is overwritten by component PriceInput and onChange event
                setTimeout(() => {
                    onChange('magicInputCosts', true);
                });
            }
            if (triggeredField !== 'width' && formData.width === undefined && (previewData === null || previewData === void 0 ? void 0 : previewData.width)) {
                onChange('width', previewData.width);
                onChange('magicInputWidth', true);
            }
            onChange('factor', previewData === null || previewData === void 0 ? void 0 : previewData.efficiency);
        });
    }
    function handleCloseButtonClick() {
        dispatch(resetSegmentForm());
        dispatch(resetSegmentDetail());
        dispatch(setDrawArea(0));
        dispatch(setDrawVertexCount(0));
        dispatch(resetSegmentSelection(true));
        dispatch(setCurrentAction('none'));
        dispatch(setMapCursor(MAP_CURSOR.default));
    }
    function onChange(field, value) {
        dispatch(setValue({ field: field, value }));
    }
    function handleTreatmentSelectClick() {
        return addModal({
            id: 'TreatmentSelectModalContainer',
            props: {
                value: formData === null || formData === void 0 ? void 0 : formData.treatmentId,
                onChange: (id) => __awaiter(this, void 0, void 0, function* () {
                    onChange('treatmentId', id);
                })
            }
        });
    }
    function handleEditPropertiesClick(propertyKey) {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-properties',
                modalProps: {
                    type: 'plan-properties',
                    selectedCustomPropertyKey: propertyKey
                }
            }
        });
    }
    function handleEditTreatmentsClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-treatments'
            }
        });
    }
    function handlePlannedForClick() {
        addModal({
            id: 'MonthPickerModalContainer',
            props: {
                month: formData === null || formData === void 0 ? void 0 : formData.completionMonth,
                year: formData === null || formData === void 0 ? void 0 : formData.completionYear,
                onConfirm: (value) => {
                    onChange('completionMonth', value ? value.startDate.getMonth() + 1 : undefined);
                    onChange('completionYear', value ? value.startDate.getFullYear() : undefined);
                }
            }
        });
    }
    function onKeyDown(event) {
        var _a;
        if (event.key === 'Enter') {
            if (event.target instanceof HTMLElement &&
                ((_a = event.target) === null || _a === void 0 ? void 0 : _a.attributes.getNamedItem('data-keyboard-priority'))) {
                return;
            }
            onSubmit();
        }
    }
    function getFormContent() {
        var _a, _b, _c, _d;
        return {
            name: (formData === null || formData === void 0 ? void 0 : formData.name) || '',
            userModifiedAddressStart: (formData === null || formData === void 0 ? void 0 : formData.userModifiedAddressStart) || '',
            userModifiedAddressEnd: (formData === null || formData === void 0 ? void 0 : formData.userModifiedAddressEnd) || '',
            treatment: (_jsx(TreatmentSelectField, { width: 164, options: planTreatmentsList, value: formData === null || formData === void 0 ? void 0 : formData.treatmentId, onClick: handleTreatmentSelectClick, "data-test": "segment-form-select-treatment" })),
            thirdParty: {
                current: formData && (_jsx(FormInputCurrentSelectedValue, { text: getPlanThirdPartyLabel(formData === null || formData === void 0 ? void 0 : formData.thirdParty), icon: getPlanThirdPartyIcon(formData === null || formData === void 0 ? void 0 : formData.thirdParty) })),
                items: [
                    _jsx(SelectboxEntry, { onClick: () => onChange('thirdParty', true), isChecked: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) === true, text: getPlanThirdPartyLabel(true), secondaryText: _jsx(Ditto, { componentId: "tooltip.thirdparty" }), leadingIcon: getPlanThirdPartyIcon(true), "data-test": "fixed-treatment-yes" }, "fixed-measure-yes"),
                    _jsx(SelectboxEntry, { onClick: () => onChange('thirdParty', false), isChecked: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) === false, text: getPlanThirdPartyLabel(false), secondaryText: _jsx(Ditto, { componentId: "plannedsegments.internallyinitiated" }), leadingIcon: getPlanThirdPartyIcon(false), "data-test": "fixed-treatment-no" }, "fixed-measure-no")
                ]
            },
            category: {
                current: (formData === null || formData === void 0 ? void 0 : formData.categoryId) && category ? (_jsx(CategorySelectItemCurrentSelectedValue, { category: {
                        categoryId: category.id,
                        categoryName: category.name
                    } })) : undefined,
                items: planCategoryList.map(({ id, name }) => (_jsx(CategorySelectItem, { category: {
                        categoryId: id,
                        categoryName: name
                    }, onClick: () => onChange('categoryId', id) }, `category-${id}`)))
            },
            width: formData === null || formData === void 0 ? void 0 : formData.width,
            length: formData === null || formData === void 0 ? void 0 : formData.length,
            area: ((_a = formData === null || formData === void 0 ? void 0 : formData.width) !== null && _a !== void 0 ? _a : 0) *
                ((_d = (_b = formData === null || formData === void 0 ? void 0 : formData.length) !== null && _b !== void 0 ? _b : (_c = budgetAndNetwork.network) === null || _c === void 0 ? void 0 : _c.roadWidth) !== null && _d !== void 0 ? _d : 0),
            costs: formData === null || formData === void 0 ? void 0 : formData.costs,
            magicInputValueCosts: formData === null || formData === void 0 ? void 0 : formData.magicInputValueCosts,
            magicInputCosts: formData === null || formData === void 0 ? void 0 : formData.magicInputCosts,
            magicInputWidth: formData === null || formData === void 0 ? void 0 : formData.magicInputWidth,
            magicInputYearsOfUse: formData === null || formData === void 0 ? void 0 : formData.magicInputYearsOfUse,
            status: {
                current: (formData === null || formData === void 0 ? void 0 : formData.statusId) && status ? (_jsx(StatusSelectItemCurrentSelectedValue, { status: {
                        statusId: status.id,
                        statusName: status.name
                    } })) : undefined,
                items: planStatusList.map(({ id, name }) => (_jsx(StatusSelectItem, { status: {
                        statusId: id,
                        statusName: name
                    }, onClick: () => onChange('statusId', id) }, `status-${id}`)))
            },
            priority: {
                current: (formData === null || formData === void 0 ? void 0 : formData.priority) !== undefined ? (_jsx(FormInputCurrentSelectedValue, { icon: getPlanPriorityIcon(getPlanPriorityName(formData.priority)), text: getPlanPriorityLabel(getPlanPriorityName(formData.priority)) })) : undefined,
                items: [
                    ...[
                        ...Object.entries(PLAN_PRIORITY_LIMIT),
                        ...((formData === null || formData === void 0 ? void 0 : formData.priority) &&
                            !Object.values(PLAN_PRIORITY_LIMIT).includes(formData.priority)
                            ? Object.entries({
                                [getPlanPriorityName(formData.priority)]: formData.priority
                            })
                            : [])
                    ]
                        .sort((a, b) => b[1] - a[1])
                        .map(([name, priorityValue]) => (_jsx(SelectboxEntry, { onClick: () => onChange('priority', priorityValue), isChecked: (formData === null || formData === void 0 ? void 0 : formData.priority) === priorityValue, text: getPlanPriorityLabel(name), leadingIcon: getPlanPriorityIcon(name), "data-test": `dropdown-priority-${priorityValue}` }, `priority-${priorityValue}`)))
                ]
            },
            completionMonth: formData === null || formData === void 0 ? void 0 : formData.completionMonth,
            completionYear: formData === null || formData === void 0 ? void 0 : formData.completionYear,
            yearsOfUse: formData === null || formData === void 0 ? void 0 : formData.yearsOfUse,
            lifeExtensionKm: formData === null || formData === void 0 ? void 0 : formData.lifeExtensionKm,
            note: (formData === null || formData === void 0 ? void 0 : formData.note) || '',
            customProperties: (_jsx(PlanCustomPropertiesPanel, { locale: locale, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, data: formData === null || formData === void 0 ? void 0 : formData.customData, properties: customProperties, planUnitTypes: planUnitTypes, onEditPropertiesClick: handleEditPropertiesClick, onChange: onChange, unitFormatter: unitFormatter })),
            factor: formData === null || formData === void 0 ? void 0 : formData.factor
        };
    }
    const isSubmitDisabled = !(formData === null || formData === void 0 ? void 0 : formData.name) ||
        !(formData === null || formData === void 0 ? void 0 : formData.categoryId) ||
        !(formData === null || formData === void 0 ? void 0 : formData.statusId) ||
        createStatus === 'loading' ||
        (!formData.streetIds && !formData.recordIds);
    function onSubmit() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const data = Object.assign({}, formData);
            // NOTE: TypeScript doesn't accept the implicit not-undefined assertions when we use "isSubmitDisabled" here, so we do it explicit
            if ((data === null || data === void 0 ? void 0 : data.name) === undefined || !(data === null || data === void 0 ? void 0 : data.categoryId) || !(data === null || data === void 0 ? void 0 : data.statusId)) {
                return;
            }
            if (segmentDetail) {
                if (data.userModifiedAddressStart === formatAddress(segmentDetail.address_start)) {
                    delete data.userModifiedAddressStart;
                }
                if (data.userModifiedAddressEnd === formatAddress(segmentDetail.address_end)) {
                    delete data.userModifiedAddressEnd;
                }
            }
            if (data.streetIds) {
                yield dispatch(createStreet(data));
                return;
            }
            if (data === null || data === void 0 ? void 0 : data.recordIds) {
                if ((data === null || data === void 0 ? void 0 : data.recordIds.length) === 0) {
                    dispatch(addNotificationMessage({
                        type: 'error',
                        ditto: {
                            title: { componentId: 'toast.nopoints' }
                        }
                    }));
                    return;
                }
                yield dispatch(createSegment(data));
                dispatch(fetchPlanFilters());
                // fetch budget settings if year which hasn't been defined before is created
                if (!((_a = budgetAndNetwork.budgets) === null || _a === void 0 ? void 0 : _a.find((budget) => budget.year === data.completionYear))) {
                    dispatch(fetchBudgetSettings());
                }
                return;
            }
        });
    }
    if (fetchStatus === 'failed') {
        return (_jsx(DetailsPanel, { children: _jsx(DetailsInfo, { linkText: "Close", linkOnClick: handleCloseButtonClick, content: _jsx(Ditto, { componentId: "somethingwentwrongtext" }) }) }));
    }
    const content = getFormContent();
    return (_jsx(SegmentPanel, Object.assign({ title: _jsx(Ditto, { componentId: "plannedsegmentcreator.title" }), onCloseClick: handleCloseButtonClick }, { children: fetchStatus === 'loading' || segmentDataIsLoading || !formData ? (_jsx(DetailsPanel, { loader: _jsx(Loader, {}) })) : (_jsx(SegmentForm, { locale: locale, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, content: content, footer: _jsx(Button, Object.assign({ icon: _jsx(Check, {}), disabled: isSubmitDisabled, variant: "fullwidth", color: "purple", onClick: onSubmit, "data-test": "btn-create-planned-segment" }, { children: _jsx(Ditto, { componentId: "createplannedsegment" }) })), onEditTreatmentsClick: handleEditTreatmentsClick, onPlannedForClick: handlePlannedForClick, inputConverter: inputConverter, unitFormatter: unitFormatter, onChange: onChange, showCostsCalcButton: false, variant: "create" })) })));
}
