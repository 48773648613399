import { FEATURE_STATE_HIGHLIGHT, FEATURE_STATE_HOVER, FEATURE_STATE_SELECTED } from '../featureStates';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
import { PLANS_SOURCE_ID } from '../sources';
export const PLANS_OUTLINE_LAYER_ID = `${LAYER_PREFIX}-plans-outline`;
export const PLANS_FILL_LAYER_ID = `${LAYER_PREFIX}-plans-fill`;
export const PLAN_OUTLINE_COLOR = {
    default: '#8600D8',
    focus: '#1556EB'
};
const PLAN_OUTLINE_WIDTH = {
    defaultMin: 5,
    default: 3,
    focusMin: 10,
    focus: 6
};
export const plansFillLayer = {
    id: PLANS_FILL_LAYER_ID,
    type: 'fill',
    interactive: true,
    source: PLANS_SOURCE_ID,
    paint: {
        'fill-color': ['get', 'color']
    }
};
export const plansOutlineLayer = {
    id: PLANS_OUTLINE_LAYER_ID,
    type: 'line',
    interactive: true,
    source: PLANS_SOURCE_ID,
    paint: {
        'line-color': [
            'case',
            ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
            PLAN_OUTLINE_COLOR.focus,
            PLAN_OUTLINE_COLOR.default
        ],
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            [
                'case',
                ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
                PLAN_OUTLINE_WIDTH.focusMin,
                ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
                PLAN_OUTLINE_WIDTH.focusMin,
                ['boolean', ['feature-state', FEATURE_STATE_HOVER], false],
                PLAN_OUTLINE_WIDTH.focusMin,
                PLAN_OUTLINE_WIDTH.defaultMin
            ],
            MAX_ZOOM,
            [
                'case',
                ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
                PLAN_OUTLINE_WIDTH.focus,
                ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
                PLAN_OUTLINE_WIDTH.focus,
                ['boolean', ['feature-state', FEATURE_STATE_HOVER], false],
                PLAN_OUTLINE_WIDTH.focus,
                PLAN_OUTLINE_WIDTH.default
            ]
        ]
    },
    layout: {
        'line-cap': 'round',
        'line-join': 'miter'
    }
};
