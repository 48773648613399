import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ManholeLevel, ManholeLowered, ManholeOutsticking } from '../../components/icons';
import { referencingOrStreetAndHouseNumber } from '../addressFormatter';
import { WARNING_ICON_COLOR } from '../color';
export function getLevelIcon(type) {
    switch (type) {
        case 'man_hole':
        case 'storm_drain':
        case 'even_manhole':
        case 'even_storm_drain':
            return ManholeLevel();
        case 'outsticking_manhole':
        case 'outsticking_storm_drain':
            return ManholeOutsticking();
        case 'lowered_manhole':
        case 'lowered_storm_drain':
            return ManholeLowered();
        default:
            return ManholeLevel();
    }
}
export function getObjectTranslation(type) {
    switch (type) {
        case 'man_hole':
        case 'even_manhole':
        case 'lowered_manhole':
        case 'outsticking_manhole':
            return _jsx(Ditto, { componentId: "filter.sewage.manhole" });
        case 'storm_drain':
        case 'even_storm_drain':
        case 'lowered_storm_drain':
        case 'outsticking_storm_drain':
            return _jsx(Ditto, { componentId: "filter.sewage.storm-drain" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getObjectLevelLabel(type) {
    switch (type) {
        case 'lowered_manhole':
        case 'lowered_storm_drain':
            return _jsx(Ditto, { componentId: "filter.sewage.manholes.lowered" });
        case 'outsticking_storm_drain':
        case 'outsticking_manhole':
            return _jsx(Ditto, { componentId: "filter.sewage.manholes.outsticking" });
        default:
            return _jsx(Ditto, { componentId: "objects.status.even" });
    }
}
export function getObjectLabelColor(type) {
    switch (type) {
        case 'lowered_manhole':
        case 'outsticking_manhole':
        case 'lowered_storm_drain':
        case 'outsticking_storm_drain':
            return WARNING_ICON_COLOR;
        default:
            return '';
    }
}
export function objectDataToContent(item, dateTimeFormatter, specifiedType = false, countryCode) {
    const objectLabel = getObjectLevelLabel(item.type);
    return {
        objectName: getObjectTranslation(item.type),
        title: referencingOrStreetAndHouseNumber(item.address, undefined, countryCode),
        dateTime: dateTimeFormatter(item.dateTime),
        object: specifiedType
            ? {
                icon: getLevelIcon(item.type),
                text: objectLabel,
                color: getObjectLabelColor(item.type)
            }
            : undefined,
        thumbnailUrl: item.thumbUrl
    };
}
