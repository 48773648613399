var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { DEBOUNCE_SEARCH } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { uniqueV2 } from '../../common/utils/array';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getAddressForPosition, getPositionForAddress } from '../../services/address';
import { getAssetInfoByIdList } from '../../services/asset';
import { getSegmentsByIds } from '../../services/condition';
import { getPointsByUuidList } from '../../services/condition/point';
import { getLinkedDefaultMarkers, getMarkersByIdList } from '../../services/marker';
import { getPlanDetailsByIdList } from '../../services/plan';
import { getSignInfoByIdList } from '../../services/sign';
import { addNotificationMessage } from './notification';
const initialState = {
    pointList: [],
    segmentList: [],
    planList: [],
    addressSearchStatus: 'idle',
    fetchedSuggestions: [],
    assetList: [],
    linkedMarkerList: [],
    markerList: []
};
export const fetchSegmentsTk = createAppAsyncThunk('sharedDataHolder/fetchSegmentsTk', (ids, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = getState();
    const countryCode = (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode;
    const res = yield getSegmentsByIds({ streetIds: ids, countryCode });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchPlansTk = createAppAsyncThunk('sharedDataHolder/fetchPlansTk', (ids, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const state = getState();
    const countryCode = (_b = state.auth.teamAccount) === null || _b === void 0 ? void 0 : _b.countryCode;
    const res = yield getPlanDetailsByIdList({ planIds: ids, countryCode });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchAssetsTk = createAppAsyncThunk('sharedDataHolder/fetchAssetsTk', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield CancelableRequest.send(getAssetInfoByIdList, body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchMarkersTk = createAppAsyncThunk('sharedDataHolder/fetchMarkersTk', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getMarkersByIdList(body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchLinkedMarkersTk = createAppAsyncThunk('sharedDataHolder/fetchLinkedMarkersTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getLinkedDefaultMarkers({
        ids
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchSignInfoList = createAppAsyncThunk('sharedDataHolder/fetchSignInfoList', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInfoByIdList({ ids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchPointList = createAppAsyncThunk('sharedDataHolder/fetchPointList', (uuids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const pointsResponse = yield getPointsByUuidList({ uuids: uniqueV2(uuids) });
    if (pointsResponse.errorCode || !pointsResponse.result) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return (_c = pointsResponse.result) === null || _c === void 0 ? void 0 : _c.list;
}));
// NOTE: debouncing for api calls is done by thunk layer, using createDebouncedAsyncThunk
export const fetchSuggestions = createDebouncedAsyncThunk('sharedDataHolder/fetchSuggestions', (query, { getState }) => {
    return getPositionForAddress(Object.assign(Object.assign({}, query), { locale: getState().app.locale }));
}, DEBOUNCE_SEARCH);
export const fetchAddress = createAppAsyncThunk('sharedDataHolder/fetchAddress', (pos) => {
    return getAddressForPosition(pos);
});
export const sharedDataHolderSlice = createSlice({
    name: 'sharedDataHolder',
    initialState,
    reducers: {
        reset: () => initialState,
        resetMarkerList: (state) => {
            state.markerList = [];
        },
        resetAssetFields: (state) => {
            state.assetFields = undefined;
        },
        setAssetFields: (state, action) => {
            state.assetFields = action.payload;
        },
        clearFetchedAddress: (state) => {
            state.fetchedAddress = undefined;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAddress.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.fetchedAddress = action.payload.result;
            }
        })
            .addCase(fetchSuggestions.pending, (state) => {
            state.addressSearchStatus = 'loading';
        })
            .addCase(fetchSuggestions.fulfilled, (state, action) => {
            state.addressSearchStatus = 'completed';
            if (action.payload.result) {
                state.fetchedSuggestions = action.payload.result.data;
            }
        })
            .addCase(fetchSegmentsTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.segmentList = action.payload.result.list;
            }
        })
            .addCase(fetchPlansTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.planList = action.payload.result.plans;
            }
        })
            .addCase(fetchAssetsTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.assetList = action.payload.result.manualInventories;
            }
        })
            .addCase(fetchMarkersTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.markerList = action.payload.result.markers;
            }
        })
            .addCase(fetchLinkedMarkersTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.linkedMarkerList = action.payload.result.markers;
            }
        })
            .addCase(fetchSignInfoList.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signInfoList = action.payload.result.sign;
            }
        })
            .addCase(fetchPointList.fulfilled, (state, action) => {
            if (action.payload) {
                state.pointList = action.payload;
            }
        });
    }
});
export const { reset: resetSharedDataHolder, resetMarkerList: resetSharedDataHolderMarkerList, resetAssetFields: resetSharedDataHolderAssetFields, setAssetFields: setSharedDataHolderAssetFields, clearFetchedAddress } = sharedDataHolderSlice.actions;
export default sharedDataHolderSlice.reducer;
