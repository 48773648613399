var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CancelableRequest } from '../../common/cancelableRequest';
import { PAGE_NUMBER_FIRST, SELECT_ALL_LIMIT } from '../../common/consts/config';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { uniqueV2 } from '../../common/utils/array';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getPaginationParams, getTimeParams } from '../../common/utils/params';
import { deleteAssets, exportAssets, getAssetFilterParams, getAssetIdList, getAssetList, getAssetSortParams } from '../../services/asset';
import { getLinkedDefaultMarkers } from '../../services/marker';
import { setIsLoadingActionbar } from './actionbar';
import { clearSelectedFeature } from './feature';
import { fetchMarkers } from './leftPanelMarker';
import { invalidateCachedLinkedMarkers } from './linkedMarkers';
import { addNotificationMessage } from './notification';
import { fetchAssetTypes } from './shared';
export const assetInitialFilter = {
    typeIds: [],
    attributes: [],
    filesAttached: [],
    filesNotAttached: [],
    search: undefined
};
const assetParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.asset;
const initialState = {
    resultList: [],
    totalCount: 0,
    resultFetchStatus: 'idle',
    currentPage: (assetParams === null || assetParams === void 0 ? void 0 : assetParams.currentPage) || PAGE_NUMBER_FIRST,
    useMapBounds: assetParams === null || assetParams === void 0 ? void 0 : assetParams.useMapBounds,
    activeTabKey: (assetParams === null || assetParams === void 0 ? void 0 : assetParams.activeTabKey) || 'filter',
    filter: Object.assign(Object.assign({}, assetInitialFilter), assetParams === null || assetParams === void 0 ? void 0 : assetParams.filter),
    sort: (assetParams === null || assetParams === void 0 ? void 0 : assetParams.sort) || 'date-desc',
    isMultiSelectOn: false,
    selectedIdList: [],
    linkedMarkers: [],
    linkedMarkersFetchStatus: 'idle'
};
export const fetchAssetsTk = createDebouncedAsyncThunk('leftPanelAsset/fetchAssetsTk', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds, currentPage } = state.leftPanelAsset;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    const res = yield CancelableRequest.send(getAssetList, {
        params: {
            pagination: getPaginationParams(currentPage),
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getAssetSortParams(sort),
            filter: getAssetFilterParams(filter)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchAssetsIdListTk = createAppAsyncThunk('leftPanelAsset/fetchAssetsIdListTk', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds } = state.leftPanelAsset;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    const res = yield getAssetIdList({
        params: {
            pagination: { offset: 0, limit: SELECT_ALL_LIMIT },
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getAssetSortParams(sort),
            filter: getAssetFilterParams(filter)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const exportAssetsTk = createAppAsyncThunk('leftPanelAsset/exportAssetsTk', (req, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds } = state.leftPanelAsset;
    const { locale } = state.app;
    const { userAccount } = state.auth;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)) {
        return;
    }
    const res = yield exportAssets({
        params: {
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getAssetSortParams(sort),
            filter: Object.assign(Object.assign({}, getAssetFilterParams(filter)), { manualInventoriesIds: req.ids })
        },
        language: locale,
        email: userAccount.email,
        utcOffset: moment().utcOffset()
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.processingexport' } }
        }));
    }
    return res;
}));
export const deleteAssetsTk = createDebouncedAsyncThunk('leftPanelAsset/deleteAssetsTk', (ids, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield deleteAssets({ manualInventoriesIds: ids });
    dispatch(setIsLoadingActionbar(false));
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.inventorydeleted', count: ids.length }
            }
        }));
        dispatch(clearSelectedFeature());
        dispatch(fetchAssetsTk());
        dispatch(fetchAssetTypes());
        if (getState().layer.selectedLayerId === 'marker') {
            dispatch(fetchMarkers());
        }
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(invalidateCachedLinkedMarkers());
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchLinkedMarkersTk = createDebouncedAsyncThunk('leftPanelAsset/fetchLinkedMarkersTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const uniqueIds = uniqueV2(ids);
    const res = yield getLinkedDefaultMarkers({
        ids: uniqueIds
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const leftPanelAssetSlice = createSlice({
    name: 'leftPanelAsset',
    initialState,
    reducers: {
        reset: () => initialState,
        setAssetActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setAssetCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setAssetUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        resetAssetFilter: (state) => {
            state.filter = assetInitialFilter;
        },
        setAssetFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setAssetSorting: (state, action) => {
            state.sort = action.payload;
        },
        setAssetSelectedIdList: (state, action) => {
            state.selectedIdList = action.payload;
        },
        startAssetMultiSelect: (state) => {
            state.isMultiSelectOn = true;
        },
        resetAssetMultiSelect: (state) => {
            state.isMultiSelectOn = false;
            state.selectedIdList = [];
        }
    },
    extraReducers(builder) {
        // NOTE: only add more cases when the loading state is interested.
        builder
            .addCase(fetchAssetsTk.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchAssetsTk.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.items;
                state.totalCount = action.payload.result.totalCount;
            }
        })
            .addCase(fetchAssetsIdListTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.selectedIdList = action.payload.result.ids;
            }
        })
            .addCase(fetchLinkedMarkersTk.pending, (state) => {
            state.linkedMarkersFetchStatus = 'loading';
        })
            .addCase(fetchLinkedMarkersTk.fulfilled, (state, action) => {
            state.linkedMarkersFetchStatus = 'completed';
            if (action.payload.result) {
                state.linkedMarkers = action.payload.result.markers;
            }
        });
    }
});
export const { reset: resetLeftPanelAsset, setAssetActiveTabKey, setAssetCurrentPage, setAssetUseMapBounds, setAssetFilter, setAssetSorting, resetAssetFilter, setAssetSelectedIdList, startAssetMultiSelect, resetAssetMultiSelect } = leftPanelAssetSlice.actions;
export default leftPanelAssetSlice.reducer;
