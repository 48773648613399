import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React, { Children, forwardRef, Fragment, isValidElement, useEffect, useMemo } from 'react';
import { classes } from 'classifizer';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import styles from './ActionModal.module.css';
export const ActionModal = forwardRef(({ className, children, width, maxHeight, onClick, ensureClickable, overflow, onItemClick, height, noPadding, extraRounded, scrollTo }, ref) => {
    const refs = useMemo(() => { var _a; return (_a = Children.map(children, () => React.createRef())) !== null && _a !== void 0 ? _a : []; }, [children]);
    // Make sure to call the original onClick prop function
    // (or onConfirm for some components inside a dropdown)
    // as well as onItemClick.
    const wrappedChildren = Children.map(children, (child, idx) => {
        if (isValidElement(child) && child.type !== Fragment) {
            const childOnClick = child.props.onConfirm || child.props.onClick;
            let wrappedAction;
            if (child.props.onConfirm) {
                wrappedAction = {
                    onConfirm: function () {
                        childOnClick === null || childOnClick === void 0 ? void 0 : childOnClick(...arguments);
                        onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick();
                    },
                    onMouseDown: function () {
                        childOnClick === null || childOnClick === void 0 ? void 0 : childOnClick(...arguments);
                        onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick();
                    }
                };
            }
            else {
                wrappedAction = {
                    onClick: function () {
                        childOnClick === null || childOnClick === void 0 ? void 0 : childOnClick(...arguments);
                        onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick();
                    },
                    onMouseDown: function () {
                        childOnClick === null || childOnClick === void 0 ? void 0 : childOnClick(...arguments);
                        onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick();
                    }
                };
            }
            return (_createElement(child.type, Object.assign({}, child.props, { key: child.key, ref: child.type === SelectboxEntry ? refs[idx] : undefined }, wrappedAction)));
        }
        return child;
    });
    useEffect(() => {
        var _a;
        if (!scrollTo) {
            return;
        }
        for (const [idx, ref] of refs.entries()) {
            const scroll = scrollTo === null || scrollTo === void 0 ? void 0 : scrollTo(idx);
            if (scroll) {
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'start' });
                break;
            }
        }
    }, [refs, scrollTo]);
    return (_jsx("div", Object.assign({ ref: ref, className: classes(styles['container'], overflow && styles['container__overflow'], ensureClickable && styles['container__clickable'], width === '100%' && styles['container__max-width'], noPadding && styles['container__no-padding'], extraRounded && styles['container__extra-rounded'], className), style: {
            width: width,
            maxHeight: maxHeight,
            height
        }, onClick: onClick && onClick }, { children: wrappedChildren })));
});
ActionModal.displayName = 'ActionModal';
