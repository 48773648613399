var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { BULK_MAIL_LIMIT, BULK_PRINT_LIMIT, ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { MIXPANEL_EVENT_NAME } from '../../common/consts/mixpanel';
import { TAB_LIST_2 } from '../../common/consts/panelTab';
import { BOTTOM_MENU_MODAL_WIDTH } from '../../common/consts/width';
import { getAssetTypeIdByAttributeId } from '../../common/convert/asset';
import { AssetIcon } from '../../common/icon';
import { LAYER_PATH } from '../../common/layer';
import { selectRangeIds } from '../../common/multiselect';
import { assetSortingOptions } from '../../common/sort';
import { ROOT_PATH } from '../../common/url';
import { toggleItemInArray } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { isDeepEqualByStringify } from '../../common/utils/object';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { DropMenuWrapper } from '../../components/DropMenuWrapper/DropMenuWrapper';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Attachments, Bin, Download, Edit, Mail, Overflow, Print, Task } from '../../components/icons';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { LeftPanelListHeader } from '../../components/LeftPanelListHeader/LeftPanelListHeader';
import { Loader } from '../../components/Loader/Loader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { SelectionBar } from '../../components/SelectionBar/SelectionBar';
import { TypedDitto } from '../../components/TypedDitto';
import { Vignette } from '../../components/Vignette/Vignette';
import { useAssetFilterHandlers } from '../../hooks/filters/useAssetFilterHandlers';
import { useFilterWrapperHandlers } from '../../hooks/filters/useFilterWrapperHandlers';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useAssetFilters } from '../../hooks/render/useAssetFilters';
import { useABTMIntroModal } from '../../hooks/useABTMIntroModal';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHighlightedFeature, clearSelectedFeature, setHighlightedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { deleteAssetsTk, exportAssetsTk, fetchAssetsIdListTk, fetchAssetsTk, fetchLinkedMarkersTk, resetAssetFilter, resetAssetMultiSelect, setAssetActiveTabKey, setAssetCurrentPage, setAssetFilter, setAssetSelectedIdList, setAssetSorting, setAssetUseMapBounds, startAssetMultiSelect } from '../../state/slices/leftPanelAsset';
import { setMarkerBulkCreatorPointSource } from '../../state/slices/markerBulkCreator';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
const ASSET_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'asset'
    }, isLoading: true }, idx)));
export function LeftPanelAssetContainer() {
    const panelContainerRef = useRef(null);
    const { addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const { activeTabKey, currentPage, resultFetchStatus, resultList, totalCount, useMapBounds, filter, sort, linkedMarkers, selectedIdList, isMultiSelectOn } = useAppSelector((state) => state.leftPanelAsset);
    const { targetSchema, userAccount } = useAppSelector((state) => state.auth);
    const { assetTypeList, assetTypeMap, assetFolders } = useAppSelector((state) => state.shared);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { handleTypeFilterClick, handleAllFoldersFilterClick } = useAssetFilterHandlers();
    const { handleFilterGroupWrapperToggle } = useFilterWrapperHandlers();
    const { getAssetAttributeFilter, assetFolderFilters, showAssetAttributeFiltersIndicator } = useAssetFilters({
        onAttributeFilterGroupWrapperToggle: handleAttributeFilterGroupWrapperToggle
    });
    const { formatAddress } = useFormatter();
    const { linkAssetsTasksEnabled } = useLayerChecker();
    const { reloadAssetTilesData } = useTilesUpdator();
    const { isEditor, isAdmin } = useUserRole();
    useABTMIntroModal();
    const { assetInfo } = useAppSelector((state) => state.assetDetail);
    const { marker } = useAppSelector((state) => state.markerDetail);
    const prevResultList = useRef(resultList);
    const filtersCount = filter.typeIds.length +
        filter.attributes.length +
        filter.filesAttached.length +
        filter.filesNotAttached.length;
    useEffect(() => {
        if (selectedIdList.length > 0 && !isMultiSelectOn) {
            dispatch(startAssetMultiSelect());
        }
        else if (selectedIdList.length === 0 && isMultiSelectOn) {
            dispatch(resetAssetMultiSelect());
        }
    }, [dispatch, isMultiSelectOn, selectedIdList.length]);
    function handleDeleteClick() {
        if (selectedIdList.length === 0) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteAssetsTk(selectedIdList))
                        .unwrap()
                        .then(() => {
                        dispatch(resetAssetMultiSelect());
                        reloadAssetTilesData();
                    });
                },
                title: _jsx(Ditto, { componentId: "assets.deleteselectedassetstitle" }),
                description: (_jsx(Ditto, { componentId: "assets.deleteselectedassetscopy", count: selectedIdList.length, variables: { AmountOfAssetsSelected: selectedIdList.length } }))
            }
        });
    }
    function handleResetSelectionClick() {
        dispatch(resetAssetMultiSelect());
        dispatch(clearSelectedFeature());
    }
    function handleAssetSelect(id, event) {
        if (!isMultiSelectOn) {
            dispatch(startAssetMultiSelect());
        }
        dispatch(setAssetSelectedIdList(toggleItemInArray(selectedIdList, id)));
        if (event === null || event === void 0 ? void 0 : event.nativeEvent.shiftKey) {
            dispatch(setAssetSelectedIdList(selectRangeIds(id, resultList, selectedIdList)));
        }
    }
    function handleResetFilter() {
        dispatch(resetAssetFilter());
    }
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleClickTab(tabId) {
        dispatch(setAssetActiveTabKey(tabId));
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setAssetCurrentPage(toPage));
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    function handleSort(id) {
        dispatch(setAssetSorting(id));
        dispatch(setAssetCurrentPage(PAGE_NUMBER_FIRST));
    }
    function handlePrint() {
        window.open(`/${ROOT_PATH.print}/${LAYER_PATH.asset}/${selectedIdList.join(',')}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleMail() {
        return __awaiter(this, void 0, void 0, function* () {
            addModal({
                id: 'MailModal',
                props: {
                    featureList: {
                        type: 'asset',
                        value: selectedIdList
                    }
                }
            });
        });
    }
    function handleAssetClick(id) {
        dispatch(setSelectedFeature({
            type: 'asset',
            id
        }));
    }
    function handleCheckMapView() {
        dispatch(setAssetUseMapBounds(!useMapBounds));
        handleClickTab('list');
    }
    function handleAttributeFilterGroupWrapperToggle(state, ref) {
        handleFilterGroupWrapperToggle(state, ref, panelContainerRef);
    }
    function handleEdit() {
        addModal({
            id: 'BulkEditAssetModal',
            props: {}
        });
    }
    function handleCreateMarker() {
        if (selectedIdList.length === 0) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.bulkCreateTasksFromAssets);
        dispatch(setMarkerBulkCreatorPointSource({ assetIds: selectedIdList }));
    }
    function handleExport(ids) {
        var _a;
        addModal({
            id: 'ExportModal',
            props: {
                onStartExportClick: () => dispatch(exportAssetsTk({ ids })),
                requestList: [
                    {
                        type: 'asset',
                        count: (_a = ids === null || ids === void 0 ? void 0 : ids.length) !== null && _a !== void 0 ? _a : totalCount
                    }
                ]
            }
        });
    }
    function handleCheckSelectAll() {
        if (selectedIdList.length === totalCount || selectedIdList.length === 1000) {
            dispatch(setAssetSelectedIdList([]));
        }
        else {
            dispatch(fetchAssetsIdListTk());
        }
    }
    const resultsCurrentPage = (() => {
        if (resultFetchStatus === 'loading') {
            return ASSET_LOADING_LIST;
        }
        if (resultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return resultList.map((item) => {
            const assetType = item.inventoryTypeId ? assetTypeMap[item.inventoryTypeId] : undefined;
            const tasks = linkedMarkers.filter((marker) => item.id === marker.manualInventoryId);
            return (_jsx(Result, { content: {
                    type: 'asset',
                    value: {
                        type: (assetType === null || assetType === void 0 ? void 0 : assetType.name) || '',
                        icon: _jsx(AssetIcon, { iconId: assetType === null || assetType === void 0 ? void 0 : assetType.iconId }),
                        title: item.title,
                        address: formatAddress(item.address),
                        thumbnails: item.imageUrls,
                        linkedTasks: tasks,
                        file: item.hasFiles ? (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Attachments, {}) })), backgroundColor: "#EFF0F0" })) : undefined
                    }
                }, isSelectable: isMultiSelectOn, isHighlighted: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.id, isActive: selectedIdList.includes(item.id), onSelect: (event) => handleAssetSelect(item.id, event), onClick: () => handleAssetClick(item.id), onMouseEnter: () => dispatch(setHighlightedFeature({ type: 'asset', id: item.id })), onMouseLeave: () => dispatch(clearHighlightedFeature()), onLinkedTaskClick: (taskId) => {
                    dispatch(setSelectedFeature({
                        type: 'marker',
                        id: taskId
                    }));
                } }, item.id));
        });
    })();
    const bottomMenu = (() => {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
        if (activeTabKey === 'list' && isMultiSelectOn) {
            return (_jsx(SelectionBar, Object.assign({ variant: "fullwidth", leftButton: isEditor ? (_jsx(Button, Object.assign({ disabled: !selectedIdList.length, icon: _jsx(Task, {}), color: "yellow", onClick: handleCreateMarker, variant: "slender" }, { children: _jsx(Ditto, { componentId: "creatextasks", variables: {
                            SelectedResults: selectedIdList.length
                        }, count: selectedIdList.length }) }))) : (_jsx("div", {})) }, { children: _jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ color: "gray", trailingIcon: _jsx(Overflow, {}), variant: "slender", "data-test": "more-btn-bottom" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), direction: "up-center-marker-menu", menu: _jsxs(ActionModal, Object.assign({ width: BOTTOM_MENU_MODAL_WIDTH }, { children: [_jsx(SelectboxEntry, { leadingIcon: _jsx(Download, {}), text: _jsx(Ditto, { componentId: "overflowmenue.exportxls" }), onClick: () => handleExport(selectedIdList), disabled: !selectedIdList.length || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email), "data-test": "export-xls" }), _jsx(SelectboxEntry, { leadingIcon: _jsx(Print, {}), text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), onClick: handlePrint, disabled: !selectedIdList.length ||
                                    selectedIdList.length > BULK_PRINT_LIMIT }), _jsx(SelectboxEntry, { leadingIcon: _jsx(Mail, {}), text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), onClick: handleMail, disabled: !selectedIdList.length ||
                                    selectedIdList.length > BULK_MAIL_LIMIT }), isEditor && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Edit, {}), text: _jsx(Ditto, { componentId: "listpanel.multiselect.editpropertiesbutton" }), onClick: handleEdit, disabled: !selectedIdList.length, "data-test": "edit-properties-btn" })), isAdmin && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Bin, {}), text: _jsx(Ditto, { componentId: "overflowmenue.delete" }), onClick: handleDeleteClick, disabled: !selectedIdList.length, color: "red" }))] })) }) })));
        }
    })();
    const assetFilter = assetTypeList.length ? (_jsxs(LayerFilter, { children: [assetTypeList.map((assetType) => (_jsx(FilterGroupWrapper, Object.assign({ onCheckboxClick: () => handleTypeFilterClick(assetType.id), onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), checkState: (function () {
                    if (filter.typeIds.includes(assetType.id)) {
                        return 'checked';
                    }
                    if (filter.attributes
                        .map((a) => getAssetTypeIdByAttributeId(assetTypeList, a.id))
                        .includes(assetType.id)) {
                        return 'indeterminate';
                    }
                    return 'not-checked';
                })(), content: assetType.name, icon: _jsx(AssetIcon, { iconId: assetType.iconId }), count: assetType.inventoryCount, hideBottomBar: true, isInitiallyCollapsed: true, disabled: !showAssetAttributeFiltersIndicator(assetType) }, { children: assetType.inventoryCount &&
                    assetType.attributes.map((attribute) => getAssetAttributeFilter(attribute)) }), assetType.id))), _jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "files.files" }), checkState: getCheckboxCheckState(assetFolders.length * 2, filter.filesAttached.length + filter.filesNotAttached.length), onCheckboxClick: () => handleAllFoldersFilterClick() }, { children: assetFolderFilters }), "files")] })] })) : (_jsx(Loader, { variant: "center" }));
    useEffect(() => {
        if (useMapBounds) {
            dispatch(fetchAssetsTk());
        }
    }, [dispatch, filter, useMapBounds, sort, currentPage, selectedOption, bounds]);
    useEffect(() => {
        if (!useMapBounds) {
            dispatch(fetchAssetsTk());
        }
    }, [dispatch, filter, useMapBounds, sort, currentPage, selectedOption]);
    useEffect(() => {
        if (linkAssetsTasksEnabled) {
            if (assetInfo && resultList.map((item) => item.id).includes(assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id)) {
                const markerIds = [
                    ...resultList.flatMap((asset) => asset.markerIds),
                    ...assetInfo.markerIds
                ];
                dispatch(fetchLinkedMarkersTk(markerIds));
            }
            else if (marker && resultList.flatMap((item) => item.markerIds).includes(marker.id)) {
                const markerIds = resultList.flatMap((asset) => asset.markerIds);
                dispatch(fetchLinkedMarkersTk(markerIds));
            }
            else if (!isDeepEqualByStringify(prevResultList.current, resultList)) {
                const markerIds = resultList.flatMap((asset) => asset.markerIds);
                dispatch(fetchLinkedMarkersTk(markerIds));
            }
            prevResultList.current = resultList;
        }
    }, [resultList, dispatch, linkAssetsTasksEnabled, assetInfo, marker]);
    useEffect(() => {
        if (activeTabKey === 'filter' && isMultiSelectOn) {
            dispatch(resetAssetMultiSelect());
        }
    }, [dispatch, activeTabKey, isMultiSelectOn]);
    return (_jsx(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: _jsx(Ditto, { componentId: "inventory.inventory" }), resetFilter: {
                filtersCount,
                onReset: handleResetFilter,
                disabled: filtersCount === 0
            }, exportXLS: {
                onExport: handleExport,
                disabled: totalCount === 0 || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)
            }, multiselect: isMultiSelectOn
                ? {
                    text: (_jsx(TypedDitto, { componentId: "listpanel.multiselect.x_selected_variabel", variables: { Amount: selectedIdList.length }, count: selectedIdList.length })),
                    onCloseClick: handleResetSelectionClick
                }
                : undefined, onCollapse: handleCollapse }), tabBar: _jsx(PanelButtonGroup, { tabs: TAB_LIST_2, activeKey: activeTabKey, onClickTab: handleClickTab }), listHeader: _jsx(LeftPanelListHeader, { onFilterCurrentMapViewClick: handleCheckMapView, onSelectAllClick: handleCheckSelectAll, filterUseMapBounds: !!useMapBounds, selectedCount: selectedIdList.length, resultsCount: totalCount, sort: {
                options: assetSortingOptions,
                onSort: handleSort,
                current: sort
            }, search: {
                onChange: (value) => {
                    if (value !== filter.search) {
                        dispatch(setAssetFilter({ search: value }));
                        if (currentPage !== PAGE_NUMBER_FIRST) {
                            dispatch(setAssetCurrentPage(PAGE_NUMBER_FIRST));
                        }
                    }
                },
                value: filter.search
            } }), pagination: activeTabKey === 'list' ? (_jsx(Pagination, { totalItems: totalCount, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined, ref: panelContainerRef, activeTabKey: activeTabKey, bottomMenu: bottomMenu }, { children: activeTabKey === 'list' ? resultsCurrentPage : assetFilter })));
}
