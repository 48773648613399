import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment, isValidElement, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { linspace } from '../../common/utils/linspace';
export function FitInline({ minFontSize, defaultFontSize, minLetterSpacing = -1.0, children }) {
    const stepsCount = useMemo(() => (defaultFontSize - minFontSize) * 10, [defaultFontSize, minFontSize]);
    const [step, setStep] = useState(stepsCount - 1);
    const [style, setStyle] = useState({});
    const ref = useRef(null);
    const fontSizeSteps = useMemo(() => linspace(minFontSize, defaultFontSize, stepsCount, true).map((n) => n.toFixed(2) + 'px'), [defaultFontSize, minFontSize, stepsCount]);
    const letterSpacingSteps = useMemo(() => linspace(minLetterSpacing, 0, stepsCount, true).map((n) => n.toFixed(2) + 'px'), [minLetterSpacing, stepsCount]);
    const apply = useCallback(() => {
        if (step === -1) {
            return;
        }
        const current = ref.current;
        if (!current) {
            return;
        }
        const { fontSize, height, lineHeight, letterSpacing } = getFontAttributes(current);
        if (Math.ceil(height) >= Math.ceil(lineHeight * 2) &&
            fontSize > minFontSize &&
            letterSpacing > minLetterSpacing) {
            setStyle({
                fontSize: fontSizeSteps[step],
                letterSpacing: letterSpacingSteps[step]
            });
            setStep(step - 1);
        }
    }, [fontSizeSteps, letterSpacingSteps, minFontSize, minLetterSpacing, step]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        apply();
    });
    if (!isValidElement(children) || children.type === Fragment) {
        console.error('FitInline expects a valid ReactElement as a child, not a fragment.');
        return null;
    }
    return (_jsx(children.type, Object.assign({ ref: ref }, children.props, { style: Object.assign(Object.assign({}, children.props.style), style) })));
}
function getFontAttributes(element) {
    const computedCSS = getComputedStyle(element);
    const { fontSize: inFontSize, height: inHeight, lineHeight: inLineHeight, letterSpacing: inLetterSpacingIn } = computedCSS;
    const fontSize = parseFloat(inFontSize);
    const height = parseFloat(inHeight);
    const lineHeight = parseFloat(inLineHeight);
    const letterSpacing = parseFloat(inLetterSpacingIn) || 0;
    return { fontSize, height, lineHeight, letterSpacing };
}
