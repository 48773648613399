var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { bulkCreateManualInventory } from '../../services/asset';
import { batchDeleteMarkers, getMarkersByIdList } from '../../services/marker';
import { clearSelectedFeature } from './feature';
import { enableMapLayer } from './layer';
import { fetchAssetsTk } from './leftPanelAsset';
import { fetchMarkers, resetMarkerMultiSelect } from './leftPanelMarker';
import { fetchMarkerGeoJSON } from './mainMap';
import { addNotificationMessage, removeNotificationMessage } from './notification';
import { fetchAssetTypes } from './shared';
const initialState = {
    markersForConverterList: [],
    createStatus: 'idle'
};
export const fetchMarkersForConverterTk = createAppAsyncThunk('assetConverter/fetchMarkersTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getMarkersByIdList({ ids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const bulkCreateAssets = createAppAsyncThunk('assetConverter/bulkCreateAssets', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield bulkCreateManualInventory(payload.assets);
    const notificationId = uuidv4();
    dispatch(addNotificationMessage({
        type: 'loading',
        id: notificationId,
        ditto: {
            title: { componentId: 'toast.convertingtasksthismaytakesometime' }
        }
    }));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
        dispatch(removeNotificationMessage(notificationId));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(removeNotificationMessage(notificationId));
        if (payload.deleteAfterwards) {
            const resDelete = yield batchDeleteMarkers({ ids: payload.markerIds });
            if (resDelete.errorCode !== RESPONSE_ERROR_CODE.success) {
                dispatch(addNotificationMessage({ type: 'error' }));
            }
            if (resDelete.errorCode === RESPONSE_ERROR_CODE.success) {
                dispatch(addNotificationMessage({
                    type: 'success',
                    ditto: {
                        title: {
                            componentId: 'toast.successfullyconvertedxassetstotasksanddeletedtheprevioustasks',
                            variables: {
                                AmountOfTasksSelectedToConvertToAssets: payload.markerIds.length
                            },
                            count: payload.markerIds.length
                        }
                    }
                }));
                dispatch(fetchMarkerGeoJSON());
                dispatch(fetchMarkers());
                dispatch(clearSelectedFeature());
                dispatch(resetMarkerMultiSelect());
            }
        }
        else {
            dispatch(addNotificationMessage({
                type: 'success',
                ditto: {
                    title: {
                        componentId: 'toast.successfullyconvertedxassetstotasks',
                        variables: {
                            AmountOfTasksSelectedToConvertToAssets: payload.markerIds.length
                        },
                        count: payload.markerIds.length
                    }
                }
            }));
        }
        dispatch(fetchAssetsTk());
        dispatch(fetchAssetTypes());
        dispatch(enableMapLayer('asset'));
    }
    return res;
}));
export const assetConverterSlice = createSlice({
    name: 'assetConverter',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMarkersForConverterTk.fulfilled, (state, action) => {
            var _a;
            state.markersForConverterList = ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.markers) || [];
        })
            .addCase(bulkCreateAssets.pending, (state) => {
            state.createStatus = 'loading';
        })
            .addCase(bulkCreateAssets.rejected, (state) => {
            state.createStatus = 'failed';
        })
            .addCase(bulkCreateAssets.fulfilled, (state) => {
            state.createStatus = 'succeeded';
        });
    }
});
export const { reset: resetAssetConverter } = assetConverterSlice.actions;
export default assetConverterSlice.reducer;
