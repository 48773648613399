import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { CalendarBlank, CaretCloseSmall, Close } from '../../icons';
import styles from './Timepicker.module.css';
export function Timepicker({ variant, boldText, normalText, trailingElement, onResetClick }) {
    return (_jsx("div", Object.assign({ className: styles['wrapper'], "data-test": "timepicker" }, { children: _jsxs("div", Object.assign({ className: classes(styles['container'], styles['container__' + variant]) }, { children: [_jsx(CalendarBlank, {}), _jsx("span", Object.assign({ className: styles['bold-text'] }, { children: boldText })), _jsx("span", Object.assign({ className: styles['normal-text'] }, { children: normalText })), trailingElement, variant === 'applied' && (_jsx("span", Object.assign({ className: classes(styles['close']), onClick: (e) => {
                        e.stopPropagation();
                        onResetClick && onResetClick();
                    } }, { children: _jsx(Close, {}) }))), variant === 'default' && _jsx(CaretCloseSmall, {})] })) })));
}
