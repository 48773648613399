var _a;
import { createSlice } from '@reduxjs/toolkit';
import { MAP_LAYER_LIST } from '../../common/layer';
import { getURLMapLayerId, getURLSearchParams } from '../../common/url';
const layerIds = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.layerIds;
const initialState = {
    layerList: layerIds
        ? MAP_LAYER_LIST.map((l) => (Object.assign(Object.assign({}, l), { isEnabled: layerIds.includes(l.id) })))
        : MAP_LAYER_LIST,
    selectedLayerId: getURLMapLayerId()
};
export const layerSlice = createSlice({
    name: 'layer',
    initialState,
    reducers: {
        setSelectedLayerId: (state, action) => {
            state.selectedLayerId = action.payload;
        },
        setMapLayers: (state, action) => {
            state.layerList = action.payload;
        },
        enableMapLayer: (state, action) => {
            state.layerList = state.layerList.map((l) => l.id === action.payload ? Object.assign(Object.assign({}, l), { isEnabled: true }) : l);
        },
        setIsActiveLayersRestored: (state) => {
            state.isActiveLayersRestored = true;
        },
        reset: () => (Object.assign(Object.assign({}, initialState), { selectedLayerId: undefined }))
    }
});
export const { setSelectedLayerId, setMapLayers, enableMapLayer, setIsActiveLayersRestored, reset: resetLayer } = layerSlice.actions;
export default layerSlice.reducer;
