import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { PLAN_TREATMENT_CATEGORY_ID } from '../../../common/consts/plan';
import { getPlanTreatmentCategoryLabel } from '../../../common/convert';
import { TreatmentIcon } from '../../../common/icon';
import { getCurrency } from '../../../common/utils/units';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Divider } from '../../../uikit/Divider/Divider';
import { FilterSelectbox } from '../../FilterSelectbox/FilterSelectbox';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import { Search } from '../../Search/Search';
import { TextOpacity } from '../../TextOpacity/TextOpacity';
import { TextOverflow } from '../../TextOverflow/TextOverflow';
import { TreatmentSelectGroup } from './TreatmentSelectGroup/TreatmentSelectGroup';
import styles from './TreatmentSelect.module.css';
const DEFAULT_TEXT_OVERFLOW_WIDTH = 280;
const INLINE_TEXT_OVERFLOW_WIDTH = 170;
const DESCRIPTION_TOOLTIP_WIDTH = 320;
export function TreatmentSelect({ className, countryCode, options, unitTypes, hideNotSpecified, variant = 'default', onChange }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'treatment.search' });
    const [searchValue, setSearchValue] = useState('');
    function getOptionsByCategory(array, id) {
        return array.filter((o) => o.categoryTypeId === id && !o.hidden);
    }
    const filteredOptions = searchValue
        ? options.filter((o) => o.title.toLowerCase().includes(searchValue.toLowerCase()))
        : options;
    return (_jsxs("div", Object.assign({ className: classes(styles['modal-search'], className) }, { children: [_jsx(Search, { placeholder: placeholderLabel, value: searchValue, onChange: (newSearchValue) => setSearchValue(newSearchValue), variant: "field" }), _jsx("div", { className: styles['modal-search-divider'] }), _jsx("div", Object.assign({ className: styles['modal-search-results'] }, { children: filteredOptions.length === 0 ? (_jsx("p", Object.assign({ className: styles['no-results'] }, { children: _jsx(Ditto, { componentId: "search.noresults" }) }))) : (_jsxs(_Fragment, { children: [Object.values(PLAN_TREATMENT_CATEGORY_ID).map((id) => {
                            const filteredOptionsByCategory = getOptionsByCategory(filteredOptions, id);
                            return filteredOptionsByCategory.length > 0 ? (_jsxs(TreatmentSelectGroup, Object.assign({ title: getPlanTreatmentCategoryLabel(id), icon: TreatmentIcon(id), isForcedOpen: searchValue.length > 0 }, { children: [filteredOptionsByCategory.map((o) => {
                                        var _a;
                                        const unit = (_a = unitTypes.find((unit) => unit.id === o.unitTypeId)) === null || _a === void 0 ? void 0 : _a.title;
                                        return (_jsx("div", Object.assign({ className: styles['search-result'] }, { children: _jsx(FilterSelectbox, { className: styles['search-item'], content: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles['title'] }, { children: [_jsx(TextOverflow, { text: o.title, maxLines: 1, tooltip: "small", tooltipAlign: "start", tooltipMaxWidth: variant === 'table-inline'
                                                                        ? INLINE_TEXT_OVERFLOW_WIDTH
                                                                        : DEFAULT_TEXT_OVERFLOW_WIDTH }), o.description && (_jsx(InfoTooltip, { tooltip: o.description, width: o.description.length >
                                                                        100
                                                                        ? DESCRIPTION_TOOLTIP_WIDTH
                                                                        : undefined }))] })), _jsx(TextOpacity, { text: _jsxs(_Fragment, { children: [o.costs === undefined &&
                                                                        !unit
                                                                        ? '-'
                                                                        : '', o.costs !== undefined
                                                                        ? `${o.costs} ${getCurrency(countryCode)}`
                                                                        : '', o.costs !== undefined &&
                                                                        unit
                                                                        ? '/'
                                                                        : '', unit !== null && unit !== void 0 ? unit : '', ' • ', o.lifeExtension !==
                                                                        undefined
                                                                        ? o.lifeExtension
                                                                        : '-', ' ', _jsx(Ditto, { componentId: "lifeextension.years" })] }), opacity: 0.4 })] }), noCheckbox: true, onClick: () => onChange(o.id), height: 56, color: "black" }) }), o.id));
                                    }), _jsx(Divider, { className: styles['divider'] })] }), `treatment-category-${id}`)) : null;
                        }), !hideNotSpecified && (_jsx(FilterSelectbox, { content: _jsx(Ditto, { componentId: "notspecified" }), noCheckbox: true, onClick: () => onChange(undefined), height: 56, color: "black" }))] })) }))] })));
}
