import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { classes } from 'classifizer';
import { parse } from 'date-fns';
import { getDateFnsLocale } from '../../common/utils/locale';
import { Button } from '../../uikit/Button/Button';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { TypedDitto } from '../TypedDitto';
import { CustomHeader } from './CustomHeader';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './YearPickerContent.module.css';
export const YearPickerContent = forwardRef(({ disabledYears, disabledYearsTooltip, locale: localeSupported, countryCode, year, onConfirm }, ref) => {
    const dateFormat = 'yyyy';
    const locale = getDateFnsLocale(localeSupported, countryCode);
    const [value, setValue] = useState(year ? parse(year.toString(), dateFormat, new Date(), { locale }) : null);
    useImperativeHandle(ref, () => ({
        submit: handleConfirmButtonClick
    }));
    function handleYearSelect(date) {
        if (date && (disabledYears === null || disabledYears === void 0 ? void 0 : disabledYears.includes(date === null || date === void 0 ? void 0 : date.getFullYear())))
            return;
        setValue(date);
    }
    function handleClearButtonClick() {
        onConfirm(undefined);
    }
    function handleConfirmButtonClick() {
        onConfirm(value === null || value === void 0 ? void 0 : value.getFullYear());
    }
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", Object.assign({ className: styles['datepicker-container'] }, { children: _jsx(CustomDatePicker, { showYearPicker: true, calendarClassName: styles['task-datepicker'], selected: value, onChange: handleYearSelect, locale: locale, dateFormat: dateFormat, renderCustomHeader: (params) => (_jsx(CustomHeader, Object.assign({ locale: locale }, params))), renderYearContent: (year) => {
                        const disabled = disabledYears === null || disabledYears === void 0 ? void 0 : disabledYears.includes(year);
                        return (_jsx("div", Object.assign({ className: classes(styles['year'], disabled && styles['year--disabled']) }, { children: disabled && disabledYearsTooltip ? (_jsx(TooltipWrapper, Object.assign({ text: disabledYearsTooltip }, { children: _jsx(_Fragment, { children: year }) }))) : (year) })));
                    }, inline: true, disabledKeyboardNavigation: true }) })), _jsxs("div", Object.assign({ className: classes(styles['content'], styles['footer']) }, { children: [_jsx(Button, Object.assign({ color: "gray", onClick: handleClearButtonClick }, { children: _jsx(TypedDitto, { componentId: "timepicker.clear" }) })), _jsx(Button, Object.assign({ color: "dark", disabled: !value, onClick: handleConfirmButtonClick }, { children: _jsx(TypedDitto, { componentId: "modal.confirm" }) }))] }))] })));
});
YearPickerContent.displayName = 'YearPickerContent';
