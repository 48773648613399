var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addBreadcrumb } from '@sentry/react';
import { HEADER_X_VIA_CLIENT_VALUE } from './consts/config';
import { getLocalStorageAll } from './localStorage';
import { getApiUrl, getURLSearchParams } from './url';
export const HTTP_STATUS_CODE = {
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    internalServerError: 500
};
export const RESPONSE_ERROR_CODE = {
    success: 0,
    error: -1,
    unknown: 99999,
    uploadError: 10008,
    emptyError: 11180,
    userError: 40000,
    authError: 40100,
    permissionError: 40300
};
export const RESPONSE_ERROR_MESSAGE = {
    success: '',
    error: 'Error',
    unknown: 'Unknown Error',
    userError: 'User Error',
    authError: 'Auth Error',
    permissionError: 'Permission Error'
};
export const STATUS_CODE_TO_ERROR_CODE = {
    [HTTP_STATUS_CODE.internalServerError]: RESPONSE_ERROR_CODE.unknown,
    [HTTP_STATUS_CODE.unauthorized]: RESPONSE_ERROR_CODE.authError,
    [HTTP_STATUS_CODE.badRequest]: RESPONSE_ERROR_CODE.userError
};
export let abortControllers = {};
export function getDefaultHeaders(hasFormData) {
    const { targetSchema, token } = getLocalStorageAll();
    const defaultHeaders = {
        'x-via-customer': getURLSearchParams().targetSchema || targetSchema || '',
        'x-via-client': HEADER_X_VIA_CLIENT_VALUE
    };
    if (token) {
        defaultHeaders['Authorization'] = `Bearer ${token}`;
    }
    if (!hasFormData) {
        defaultHeaders['Content-Type'] = 'application/json';
    }
    return defaultHeaders;
}
export function pathParams(url, params) {
    return Object.keys(params).reduce((url, key) => url.replace(`:${key}`, params[key]), url);
}
// by array values join by ","
function getURLParamsString(urlParams) {
    if (!urlParams) {
        return '';
    }
    const params = [];
    Object.keys(urlParams).forEach((key) => {
        let value = urlParams[key];
        if (Array.isArray(value)) {
            value = value.join(',');
        }
        if (typeof value !== 'undefined' && value !== '' && value !== null) {
            params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    });
    if (params.length === 0) {
        return '';
    }
    return `?${params.join('&')}`;
}
/**
 * by array values repeats keys, and without url encoding to enable array[] param
 */
function getURLParamsStringV2(urlParams) {
    if (!urlParams) {
        return '';
    }
    const params = [];
    Object.keys(urlParams).forEach((key) => {
        const value = urlParams[key];
        if (Array.isArray(value)) {
            value.forEach((v) => {
                if (typeof v !== 'undefined' && v !== '' && v !== null) {
                    params.push(`${key}=${v}`);
                }
            });
        }
        else {
            if (typeof value !== 'undefined' && value !== '' && value !== null) {
                params.push(`${key}=${value}`);
            }
        }
    });
    if (params.length === 0) {
        return '';
    }
    return `?${params.join('&')}`;
}
// NOTE: fetch layer returns a response container, and it does not throw errors
function http(path, config, useGateway) {
    return __awaiter(this, void 0, void 0, function* () {
        const hasFormData = config.body instanceof FormData;
        config.headers = Object.assign(Object.assign({}, getDefaultHeaders(hasFormData)), config.headers);
        const request = new Request(`${getApiUrl(useGateway)}/${path}`, config);
        const response = yield fetch(request);
        // general logging on failure, user error & unexpected errors
        if (response.status >= HTTP_STATUS_CODE.badRequest) {
            const errorLog = `Fetch Error: ${config.method} ${path} ${response.status} ${response.statusText} 
        req body:\n${config.body}
        res body:\n${yield response.clone().text()}`;
            if (response.status >= HTTP_STATUS_CODE.internalServerError) {
                console.error(errorLog);
            }
            else {
                console.warn(errorLog);
            }
            // extra reaction to non success response if necessary
        }
        // unexpected errors, network error & server error
        if (response.status >= HTTP_STATUS_CODE.internalServerError) {
            addBreadcrumb({
                category: 'fetch',
                message: 'fetch failed with unexpected error',
                data: { path, config, useGateway, response },
                level: 'error'
            });
            return {
                errorCode: RESPONSE_ERROR_CODE.unknown,
                errorMessage: RESPONSE_ERROR_MESSAGE.unknown
            };
        }
        if (response.status === HTTP_STATUS_CODE.unauthorized) {
            addBreadcrumb({
                category: 'fetch',
                message: 'fetch failed with unauthorized',
                data: { path, config, useGateway, response },
                level: 'error'
            });
            return {
                errorCode: RESPONSE_ERROR_CODE.authError,
                errorMessage: RESPONSE_ERROR_MESSAGE.authError
            };
        }
        if (response.status === HTTP_STATUS_CODE.forbidden) {
            addBreadcrumb({
                category: 'fetch',
                message: 'fetch failed with forbidden',
                data: { path, config, useGateway, response },
                level: 'error'
            });
            return {
                errorCode: RESPONSE_ERROR_CODE.permissionError,
                errorMessage: RESPONSE_ERROR_MESSAGE.permissionError
            };
        }
        const res = yield response.json().catch((err) => ({
            errorCode: RESPONSE_ERROR_CODE.unknown,
            errorMessage: err
        }));
        // new response container format
        if (res.errorCode !== undefined && res.errorMessage !== undefined) {
            if (res.errorCode !== 0) {
                addBreadcrumb({
                    category: 'fetch',
                    message: 'fetch failed with errorCode',
                    data: { path, config, useGateway, res },
                    level: 'error'
                });
            }
            return res;
        }
        // user error, old api response format
        if (response.status >= HTTP_STATUS_CODE.badRequest) {
            addBreadcrumb({
                category: 'fetch',
                message: 'fetch failed with bad request',
                data: { path, config, useGateway, response },
                level: 'warning'
            });
            return {
                errorCode: RESPONSE_ERROR_CODE.userError,
                errorMessage: res.error || res.errors || RESPONSE_ERROR_MESSAGE.userError
            };
        }
        // success, old api response format
        return {
            errorCode: RESPONSE_ERROR_CODE.success,
            errorMessage: RESPONSE_ERROR_MESSAGE.success,
            result: res.data || res.result || res.results || res
        };
    });
}
/**
 * by array values use "," as separator, and with url encoding
 */
export function get(path, urlParams, headers) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'GET', headers };
        return yield http(path + getURLParamsString(urlParams), init);
    });
}
/**
 * by array values repeats keys, and without url encoding to enable array[] param
 */
export function getV2(path, urlParams, headers) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'GET', headers };
        return yield http(path + getURLParamsStringV2(urlParams), init);
    });
}
export function post(path, body, urlParams, useGateway) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = {
            method: 'POST',
            body: body ? JSON.stringify(body) : undefined
        };
        return yield http(path + getURLParamsStringV2(urlParams), init, useGateway);
    });
}
export function postFormData(path, body, urlParams, useGateway) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = {
            method: 'POST',
            body
        };
        return yield http(path + getURLParamsStringV2(urlParams), init, useGateway);
    });
}
export function put(path, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'PUT', body: body ? JSON.stringify(body) : undefined };
        return yield http(path, init);
    });
}
export function patch(path, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'PATCH', body: body ? JSON.stringify(body) : undefined };
        return yield http(path, init);
    });
}
export function delete2(path, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'DELETE', body: body ? JSON.stringify(body) : undefined };
        return yield http(path, init);
    });
}
// Raw methods are used for non json fetch responses, it can throw
function httpRaw(path, config, useGateway) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        config.headers = getDefaultHeaders();
        const request = new Request(`${getApiUrl(useGateway)}/${path}`, config);
        const response = yield fetch(request);
        let errorCode = RESPONSE_ERROR_CODE.success;
        // general logging on failure, user error & unexpected errors
        if (response.status >= HTTP_STATUS_CODE.badRequest) {
            errorCode = (_a = STATUS_CODE_TO_ERROR_CODE[response.status]) !== null && _a !== void 0 ? _a : response.status;
            const errorLog = `Fetch Error: ${config.method} ${path} ${response.status} ${response.statusText} 
        req body:\n${config.body}
        res body:\n${yield response.clone().text()}`;
            if (response.status >= HTTP_STATUS_CODE.internalServerError) {
                console.error(errorLog);
            }
            else {
                console.warn(errorLog);
            }
        }
        return { errorCode, errorMessage: '', result: response };
    });
}
// Raw methods are used for non json fetch responses
export function getRaw(path, urlParams, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        if (abortSignalId) {
            abortControllers[abortSignalId] = new AbortController();
        }
        const init = {
            method: 'GET',
            signal: abortSignalId ? abortControllers[abortSignalId].signal : undefined
        };
        try {
            return yield httpRaw(path + getURLParamsString(urlParams), init, undefined);
        }
        finally {
            if (abortSignalId) {
                delete abortControllers[abortSignalId];
            }
        }
    });
}
// Raw methods are used for non json fetch responses
export function postRaw(path, body, useGateway, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        if (abortSignalId) {
            abortControllers[abortSignalId] = new AbortController();
        }
        const init = {
            method: 'POST',
            body: JSON.stringify(body),
            signal: abortSignalId ? abortControllers[abortSignalId].signal : undefined
        };
        try {
            return yield httpRaw(path, init, useGateway);
        }
        finally {
            if (abortSignalId) {
                delete abortControllers[abortSignalId];
            }
        }
    });
}
// Raw methods are used for non json fetch responses
export function putRaw(path, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'PUT', body: JSON.stringify(body) };
        return yield httpRaw(path, init);
    });
}
// NOTE: For requests to external services
function httpExternal(path, config) {
    return __awaiter(this, void 0, void 0, function* () {
        const request = new Request(path, config);
        const response = yield fetch(request);
        // general logging on failure, user error & unexpected errors
        if (response.status >= HTTP_STATUS_CODE.badRequest) {
            const errorLog = `Fetch Error: ${config.method} ${path} ${response.status} ${response.statusText} 
        req body:\n${config.body}
        res body:\n${yield response.clone().text()}`;
            if (response.status >= HTTP_STATUS_CODE.internalServerError) {
                console.error(errorLog);
            }
            else {
                console.warn(errorLog);
            }
        }
        // unexpected errors, network error & server error
        if (response.status >= HTTP_STATUS_CODE.internalServerError) {
            return {
                errorCode: RESPONSE_ERROR_CODE.unknown,
                errorMessage: RESPONSE_ERROR_MESSAGE.unknown
            };
        }
        if (response.status === HTTP_STATUS_CODE.unauthorized) {
            return {
                errorCode: RESPONSE_ERROR_CODE.authError,
                errorMessage: RESPONSE_ERROR_MESSAGE.authError
            };
        }
        const res = yield response.json().catch((err) => ({
            errorCode: RESPONSE_ERROR_CODE.unknown,
            errorMessage: err
        }));
        // user error
        if (response.status >= HTTP_STATUS_CODE.badRequest) {
            return {
                errorCode: RESPONSE_ERROR_CODE.userError,
                errorMessage: res.error || res.errors || RESPONSE_ERROR_MESSAGE.userError
            };
        }
        return {
            errorCode: RESPONSE_ERROR_CODE.success,
            errorMessage: RESPONSE_ERROR_MESSAGE.success,
            result: res
        };
    });
}
/**
 * by array values use "," as separator, and with url encoding
 */
export function getExternal(path, urlParams, headers) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = { method: 'GET', headers };
        return yield httpExternal(path + getURLParamsString(urlParams), init);
    });
}
