var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useState } from 'react';
import { classes } from 'classifizer';
import styles from './AssetTextInput.module.css';
export const AssetTextInput = forwardRef((_a, ref) => {
    var { width = '100%', label, error, value, onValueChange, required, onFocusChange, className, onBlur, onFocus } = _a, props = __rest(_a, ["width", "label", "error", "value", "onValueChange", "required", "onFocusChange", "className", "onBlur", "onFocus"]);
    const [isFocus, setIsFocus] = useState(false);
    return (_jsxs("div", Object.assign({ className: styles['wrapper'], style: { width }, "data-test": "asset-text" }, { children: [_jsxs("div", Object.assign({ className: classes(styles['field'], isFocus && styles['field__focus'], error && styles['field__error'], className), style: { width } }, { children: [_jsxs("span", Object.assign({ className: classes(styles['label']) }, { children: [required && _jsx("span", Object.assign({ className: styles['required'] }, { children: "*" })), _jsx("span", Object.assign({ className: styles['label-text'] }, { children: label }))] })), _jsx("span", Object.assign({ className: styles['input-group'] }, { children: _jsx("input", Object.assign({ onChange: (e) => onValueChange && onValueChange(e.target.value) }, props, { value: value, className: classes(styles['input']), ref: ref, onFocus: (e) => {
                                setIsFocus(true);
                                onFocusChange && onFocusChange(true);
                                onFocus && onFocus(e);
                            }, onBlur: (e) => {
                                setIsFocus(false);
                                onFocusChange && onFocusChange(false);
                                onBlur && onBlur(e);
                            } })) }))] })), error && _jsx("span", Object.assign({ className: styles['error'] }, { children: error }))] })));
});
AssetTextInput.displayName = 'AssetTextInput';
