// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aVKHqJpZSZ3ugtw45i47{display:flex;padding:12px 12px 12px 32px;flex-direction:column;justify-content:center;align-items:flex-start;align-self:stretch;cursor:pointer;border-radius:4px}.aVKHqJpZSZ3ugtw45i47:hover{background:rgba(129,129,129,.1)}.IU61mCE7XzxIjkyWM9jF{display:flex;align-items:center;gap:var(--gap-gap-m, 4px);align-self:stretch}.lpQfD3xG_oSdOksWEN0M{color:var(--primary-black, #000);font-weight:600}.PFwk7u_MpOHgKcn2UBhG{padding-left:0px;gap:10px;align-self:stretch}.u5JBE8QxsL4Szpe3yu08{padding-left:28px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/SettingCheckboxRow/SettingCheckboxRow.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,2BAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAGJ,4BACI,+BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,yBAAA,CACA,kBAAA,CAGJ,sBACI,gCAAA,CACA,eAAA,CAGJ,sBACI,gBAAA,CACA,QAAA,CACA,kBAAA,CAGJ,sBACI,iBAAA","sourcesContent":[".container {\n    display: flex;\n    padding: 12px 12px 12px 32px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    align-self: stretch;\n    cursor: pointer;\n    border-radius: 4px;\n}\n\n.container:hover {\n    background: rgba(129, 129, 129, 0.1);\n}\n\n.top {\n    display: flex;\n    align-items: center;\n    gap: var(--gap-gap-m, 4px);\n    align-self: stretch;\n}\n\n.top-text {\n    color: var(--primary-black, #000);\n    font-weight: 600;\n}\n\n.bottom {\n    padding-left: 0px;\n    gap: 10px;\n    align-self: stretch;\n}\n\n.bottom-text {\n    padding-left: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aVKHqJpZSZ3ugtw45i47`,
	"top": `IU61mCE7XzxIjkyWM9jF`,
	"top-text": `lpQfD3xG_oSdOksWEN0M`,
	"bottom": `PFwk7u_MpOHgKcn2UBhG`,
	"bottom-text": `u5JBE8QxsL4Szpe3yu08`
};
export default ___CSS_LOADER_EXPORT___;
