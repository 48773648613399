var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useId, useRef } from 'react';
import { classes } from 'classifizer';
import { motion } from 'framer-motion';
import { largeModalVariants, smallModalVariants } from '../../common/consts/animations';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import styles from './Modal.module.css';
export function Modal(_a) {
    var { className, title, titleMargin, leading, trailing, top, content, contentGap, footer, footerAlign = 'flex-end', onClickOutside, minHeight, width, animationVariant = 'small' } = _a, props = __rest(_a, ["className", "title", "titleMargin", "leading", "trailing", "top", "content", "contentGap", "footer", "footerAlign", "onClickOutside", "minHeight", "width", "animationVariant"]);
    const ref = useRef(null);
    const key = useId();
    useOnClickOutside(ref, (event) => onClickOutside && onClickOutside(event));
    const animationProps = animationVariant === 'small'
        ? {
            variants: smallModalVariants,
            initial: 'initial',
            animate: 'visible',
            exit: 'exit'
        }
        : {
            variants: largeModalVariants,
            initial: 'initial',
            animate: 'visible',
            exit: 'initial'
        };
    return (_jsxs(motion.div, Object.assign({ ref: ref, className: classes(styles['container'], className), style: { minHeight, width } }, animationProps, props, { children: [top && _jsx("div", Object.assign({ className: styles['top'] }, { children: top })), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles['title-group'], style: { marginBottom: titleMargin } }, { children: [leading && _jsx("div", Object.assign({ className: styles['title-buttons'] }, { children: leading })), _jsx("div", Object.assign({ className: styles['title'] }, { children: title })), trailing && _jsx("div", Object.assign({ className: styles['title-buttons'] }, { children: trailing }))] })), _jsx("div", Object.assign({ className: styles['content'], style: { gap: contentGap } }, { children: content }))] }), footer && (_jsx("div", Object.assign({ className: styles['footer'], style: { justifyContent: footerAlign } }, { children: footer })))] }), key));
}
