// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BCjNUuf_3wzRgZXtYPYw{display:flex;flex-direction:column;align-items:flex-start;align-self:stretch}.OH9lfTaWWR85vRQvEa4B{display:flex;padding:var(--padding-padding-m, 12px) var(--gap-gap-m, 4px) var(--padding-padding-m, 12px) var(--padding-padding-m, 12px);align-items:center;gap:2px;flex:1 0 0;align-self:stretch;border-radius:4px;line-height:130%;opacity:.5}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/Section/Section.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,0HAAA,CAEA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,kBAAA,CACA,iBAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    align-self: stretch;\n}\n\n.header {\n    display: flex;\n    padding: var(--padding-padding-m, 12px) var(--gap-gap-m, 4px) var(--padding-padding-m, 12px)\n        var(--padding-padding-m, 12px);\n    align-items: center;\n    gap: 2px;\n    flex: 1 0 0;\n    align-self: stretch;\n    border-radius: 4px;\n    line-height: 130%; /* 18.2px */\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BCjNUuf_3wzRgZXtYPYw`,
	"header": `OH9lfTaWWR85vRQvEa4B`
};
export default ___CSS_LOADER_EXPORT___;
