import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from '@tanstack/react-location';
import { SETTING_PAGE_ID } from '../../common/settings';
import { ROOT_PATH } from '../../common/url';
import { useModalContext } from '../../state/context';
export function RedirectSettings() {
    const { params: { settingsPageId } } = useMatch();
    const { addModal } = useModalContext();
    const navigate = useNavigate();
    useEffect(() => {
        navigate({
            to: `/${ROOT_PATH.main}`
        });
        if (settingsPageId === SETTING_PAGE_ID.notification) {
            addModal({
                id: 'GlobalSettingsModal',
                props: { initialPageId: 'notification' }
            });
        }
        if (settingsPageId === SETTING_PAGE_ID.assetType) {
            addModal({
                id: 'GlobalSettingsModal',
                props: { initialPageId: 'asset-type' }
            });
        }
        if (settingsPageId === SETTING_PAGE_ID.planTableView) {
            addModal({
                id: 'GlobalSettingsModal',
                props: { initialPageId: 'plan-table-view' }
            });
        }
        if (settingsPageId === SETTING_PAGE_ID.planProperties) {
            addModal({
                id: 'GlobalSettingsModal',
                props: { initialPageId: 'plan-properties' }
            });
        }
        if (settingsPageId === SETTING_PAGE_ID.planTreatments) {
            addModal({
                id: 'GlobalSettingsModal',
                props: { initialPageId: 'plan-treatments' }
            });
        }
        if (settingsPageId === SETTING_PAGE_ID.signInventoryProperties) {
            addModal({
                id: 'GlobalSettingsModal',
                props: { initialPageId: 'sign-inventory-properties' }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return _jsx(Outlet, {});
}
