var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { COMMON_ATTRIBUTE_TYPE } from '../../common/consts/attribute';
import { MARKER_SECTION_ID } from '../../common/consts/marker';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { remove, replacePartial } from '../../common/utils/array';
import { DeleteModalInfo } from '../../components/Modals/GlobalSettingsModal/DeleteModalInfo/DeleteModalInfo';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { deleteMarkerAttributeSchemaSelectOptionTk, deleteMarkerAttributeSchemaTk, saveMarkerAttributeSchemaTk } from '../../state/slices/globalSetting';
export function useTaskCostsSettings() {
    const dispatch = useAppDispatch();
    const { addModal, removeModal } = useModalContext();
    const { taskCostAttributeSchema } = useAppSelector((state) => state.shared);
    const [hasNotSavedChanges, setHasNotSavedChanges] = useState(false);
    const [list, setList] = useState(taskCostAttributeSchema);
    useEffect(() => {
        // prevent refresh when deleting records
        if (!hasNotSavedChanges) {
            setList(taskCostAttributeSchema);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskCostAttributeSchema]);
    function closeModal() {
        removeModal('DeleteModal');
        removeModal('GlobalSettingsModal');
    }
    function showDeleteAttributeConfirmation(id, affectedCount, cb) {
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "prompt.delete.property" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "prompt.delete.property.text" }), _jsx(DeleteModalInfo, { affectedCount: affectedCount })] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    dispatch(deleteMarkerAttributeSchemaTk(id));
                    cb();
                }
            }
        });
    }
    function showDeleteOptionConfirmation(id, affectedCount, cb) {
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "prompt.delete.option" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "prompt.delete.option.text" }), _jsx(DeleteModalInfo, { affectedCount: affectedCount })] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    dispatch(deleteMarkerAttributeSchemaSelectOptionTk(id));
                    cb();
                }
            }
        });
    }
    function handleTaskCostsSaveClick(cb) {
        return __awaiter(this, void 0, void 0, function* () {
            setHasNotSavedChanges(false);
            const schema = list
                .filter((v) => v.name || v.id)
                .map((v) => (Object.assign(Object.assign({}, v), { sectionId: MARKER_SECTION_ID.costs })));
            yield dispatch(saveMarkerAttributeSchemaTk({
                attributeSchema: schema
            }));
            const newProperties = schema.filter((v) => typeof v.id === 'undefined');
            for (const prop of newProperties) {
                mixpanel.track(MIXPANEL_EVENT_NAME.addCustomCostsProperty, {
                    [MIXPANEL_EVENT_PROPERTY.taskCostsPropertyName]: prop.name
                });
            }
            const callback = cb || closeModal;
            callback();
        });
    }
    function handleTaskCostsCancelClick(cb) {
        setHasNotSavedChanges(false);
        const callback = cb || closeModal;
        if (!hasNotSavedChanges) {
            callback();
            return;
        }
        addModal({
            id: 'SaveModal',
            props: {
                onConfirm: () => {
                    handleTaskCostsSaveClick(callback);
                },
                onCancel: () => {
                    setList(taskCostAttributeSchema);
                    callback();
                },
                description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
            }
        });
    }
    function setSelectOptions(asIdx, selectOptions) {
        setList(replacePartial(list, asIdx, { selectOptions }));
    }
    function handleAttributeDelete(asIdx) {
        const attribute = list[asIdx];
        // extra delete confirmation
        if (attribute.id) {
            showDeleteAttributeConfirmation(attribute.id, attribute.amountInUse, () => setList(remove(list, asIdx)));
            return;
        }
        setList(remove(list, asIdx));
    }
    function handleAttributeChange(asIdx, value) {
        setHasNotSavedChanges(true);
        setList(replacePartial(list, asIdx, { name: value }));
    }
    function handleUnitChange(asIdx, value) {
        setHasNotSavedChanges(true);
        setList(replacePartial(list, asIdx, { unitId: value }));
    }
    function handleAttributeCreate(type) {
        setHasNotSavedChanges(true);
        const newAttribute = (function () {
            switch (type) {
                case COMMON_ATTRIBUTE_TYPE.select:
                    return { type: COMMON_ATTRIBUTE_TYPE.select, selectOptions: [], name: '' };
                case COMMON_ATTRIBUTE_TYPE.number:
                    return { type: COMMON_ATTRIBUTE_TYPE.number, unitId: 1, name: '' };
                case COMMON_ATTRIBUTE_TYPE.date:
                    return { type: COMMON_ATTRIBUTE_TYPE.date, name: '' };
                case COMMON_ATTRIBUTE_TYPE.text:
                    return { type: COMMON_ATTRIBUTE_TYPE.text, name: '' };
                case COMMON_ATTRIBUTE_TYPE.multiselect:
                    return { type: COMMON_ATTRIBUTE_TYPE.multiselect, selectOptions: [], name: '' };
            }
        })();
        setList(list.concat(newAttribute));
    }
    function handleOptionDelete(asIdx, slIdx) {
        var _a;
        const option = (_a = list[asIdx].selectOptions) === null || _a === void 0 ? void 0 : _a[slIdx];
        // extra delete confirmation
        if (option === null || option === void 0 ? void 0 : option.id) {
            showDeleteOptionConfirmation(option.id, option.amountInUse, () => setSelectOptions(asIdx, remove(list[asIdx].selectOptions, slIdx)));
            return;
        }
        setSelectOptions(asIdx, remove(list[asIdx].selectOptions, slIdx));
    }
    function handleOptionChange(asIdx, slIdx, value) {
        setHasNotSavedChanges(true);
        setSelectOptions(asIdx, replacePartial(list[asIdx].selectOptions, slIdx, { name: value }));
    }
    function handleOptionCreate(asIdx) {
        var _a;
        setHasNotSavedChanges(true);
        setSelectOptions(asIdx, (_a = list[asIdx].selectOptions) === null || _a === void 0 ? void 0 : _a.concat({ name: '' }));
    }
    return {
        handleTaskCostsSaveClick,
        handleTaskCostsCancelClick,
        list,
        handleAttributeDelete,
        handleAttributeChange,
        handleUnitChange,
        handleAttributeCreate,
        handleOptionDelete,
        handleOptionChange,
        handleOptionCreate
    };
}
