import { useCallback } from 'react';
import { useMap } from 'react-map-gl';
import { ASSETS_SOURCE_ID, SIGN_INVENTORY_SOURCE_ID } from '../../common/mapbox';
import { useTiles } from './useTiles';
export function useTilesUpdator() {
    const { main: map } = useMap();
    const { assetTiles, signInventoryTiles } = useTiles();
    const reloadAssetTilesData = useCallback(() => {
        if (!map) {
            return;
        }
        const source = map.getSource(ASSETS_SOURCE_ID);
        source === null || source === void 0 ? void 0 : source.setTiles(assetTiles);
    }, [map, assetTiles]);
    const reloadSignInventoryTilesData = useCallback(() => {
        if (!map) {
            return;
        }
        const source = map.getSource(SIGN_INVENTORY_SOURCE_ID);
        source === null || source === void 0 ? void 0 : source.setTiles(signInventoryTiles);
    }, [map, signInventoryTiles]);
    return { reloadAssetTilesData, reloadSignInventoryTilesData };
}
