import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { getSuggestionDescription, getSuggestionTitle } from '../../common/addressFormatter';
import { MAP_CURSOR, MAP_DEFAULT_BOUND } from '../../common/consts/map';
import { parseBoundsArray, toBoundsArray } from '../../common/map';
import { Actionbar } from '../../components/Actionbar/Actionbar';
import { SearchHeader } from '../../components/Actionbar/Search/SearchHeader/SearchHeader';
import { SearchItem } from '../../components/Actionbar/Search/SearchItem/SearchItem';
import { useMainMap } from '../../hooks/mainMap';
import { useMapLoading } from '../../hooks/mainMap/useMapLoading';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useUserRole } from '../../hooks/useUserRole';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchMapboxSuggestions } from '../../state/slices/actionbar';
import { setCurrentAction } from '../../state/slices/app';
import { clearSelectedFeature } from '../../state/slices/feature';
import { setCurrentRightClickAction, setDrawArea, setDrawVertexCount, setMapCursor } from '../../state/slices/mainMap';
import { confirmSegmentSelection, resetSegmentSelection } from '../../state/slices/segmentDetail';
import { resetSegmentForm, submitSegmentForm } from '../../state/slices/segmentForm';
export function ActionbarContainer() {
    var _a;
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { center, drawVertexCount, currentRightClickAction, draggableFeature, rotatableFeature } = useAppSelector((state) => state.mainMap);
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { isLoading, fetchedSuggestions, horizontalOffset: actionbarHorizontalOffset } = useAppSelector((state) => state.actionbar);
    const { currentAction, locale } = useAppSelector((state) => state.app);
    const { form: segmentFormData } = useAppSelector((state) => state.segmentForm);
    const { segment } = useAppSelector((state) => state.segmentDetail);
    const { selectedLayerId } = useAppSelector((state) => state.layer);
    const customerBounds = (_a = parseBoundsArray(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.bounds)) !== null && _a !== void 0 ? _a : MAP_DEFAULT_BOUND;
    const dispatch = useAppDispatch();
    const { createSearchMarker, removeSearchMarker } = useMainMap();
    const { isMapLoading } = useMapLoading();
    const { isEditor, isAdmin } = useUserRole();
    const { isSignInventoryAuthorized, isEditableTrafficSignsEnabled } = useLayerChecker();
    const [isSearching, setIsSearching] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const isSegmentFormSubmitDisabled = !(segmentFormData === null || segmentFormData === void 0 ? void 0 : segmentFormData.name) ||
        !segmentFormData.categoryId ||
        !segmentFormData.statusId ||
        (!segmentFormData.streetIds && !segmentFormData.recordIds);
    function handleSearchValueChange(value) {
        setSearchValue(value);
        if (value) {
            dispatch(fetchMapboxSuggestions({
                input: value,
                proximity: center,
                bbox: toBoundsArray(customerBounds).join(','),
                language: locale
            }));
            setShowResults(true);
        }
    }
    function handleSearchSuggestionClick(item) {
        createSearchMarker({ lng: item.center[0], lat: item.center[1] });
        setSearchValue(getSuggestionTitle(item));
        setShowResults(false);
    }
    function handleMarkerActionClick() {
        dispatch(setCurrentAction('marker-creator'));
        dispatch(setMapCursor(MAP_CURSOR.crosshair));
    }
    function handleAssetActionClick() {
        dispatch(setCurrentAction('asset-creator'));
        dispatch(setMapCursor(MAP_CURSOR.crosshair));
    }
    function handleCreateTrafficSignClick() {
        dispatch(setCurrentAction('traffic-sign-creator'));
        dispatch(setMapCursor(MAP_CURSOR.crosshair));
    }
    function handlePlanActionClick() {
        dispatch(setCurrentAction('segment-creator'));
        dispatch(setMapCursor(MAP_CURSOR.crosshair));
    }
    function handleCreatePlanActionClick() {
        dispatch(clearSelectedFeature());
        dispatch(resetSegmentForm());
        dispatch(setCurrentAction('segment-creator'));
    }
    function handleAddToPlanningActionClick() {
        dispatch(setCurrentAction('segment-creator-form'));
        dispatch(setMapCursor(MAP_CURSOR.default));
    }
    function handleSavePlanClick() {
        dispatch(submitSegmentForm(true));
    }
    function handleNoteActionClick() {
        dispatch(setCurrentRightClickAction('note-creator-picker'));
    }
    function handleSearchActionClick() {
        setIsSearching(true);
    }
    function handleCloseSearchClick() {
        removeSearchMarker();
        setIsSearching(false);
        setSearchValue('');
    }
    function handleConfirmSegmentSelectionClick() {
        dispatch(confirmSegmentSelection(true));
    }
    function handleResetSegmentSelectionClick() {
        dispatch(setDrawArea(0));
        dispatch(setDrawVertexCount(0));
        dispatch(resetSegmentSelection(true));
    }
    function getSegmentCreatorType(points) {
        switch (points) {
            case 0:
                return 'start-selection';
            case 1:
                return 'add-plan-step-1';
            case 2:
                return 'add-plan-step-2';
            default:
                return !segment ? 'confirm-selection' : 'add-plan-confirm';
        }
    }
    const content = (() => {
        if (isLoading || (currentAction === 'none' && isMapLoading)) {
            return {
                type: 'loading'
            };
        }
        if (currentAction === 'feature-drag-drop' && draggableFeature) {
            return {
                type: 'feature-drag-drop',
                featureType: draggableFeature.type
            };
        }
        if (rotatableFeature) {
            return {
                type: 'feature-rotate',
                featureType: rotatableFeature.type
            };
        }
        if (currentAction === 'segment-creator') {
            return {
                type: 'segment-creator',
                content: {
                    type: getSegmentCreatorType(drawVertexCount),
                    onActionClick: getSegmentCreatorType(drawVertexCount) === 'confirm-selection'
                        ? handleConfirmSegmentSelectionClick
                        : handleAddToPlanningActionClick,
                    onResetClick: handleResetSegmentSelectionClick
                }
            };
        }
        if (currentAction === 'segment-creator-form') {
            return {
                type: 'segment-creator-form',
                onActionClick: !isSegmentFormSubmitDisabled ? handleSavePlanClick : undefined
            };
        }
        if (currentAction === 'marker-creator') {
            return;
        }
        if (currentAction === 'asset-creator') {
            return;
        }
        if (currentAction === 'traffic-sign-creator') {
            return;
        }
        if (isSearching) {
            return {
                type: 'search',
                onCloseClick: handleCloseSearchClick,
                value: searchValue,
                suggestions: showResults && (_jsxs(_Fragment, { children: [_jsx(SearchHeader, { title: _jsx(Ditto, { componentId: "address" }) }), fetchedSuggestions.map((item, idx) => (_jsx(SearchItem, { title: getSuggestionTitle(item, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode), description: getSuggestionDescription(item, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode), onClick: () => handleSearchSuggestionClick(item) }, idx)))] })),
                onValueChange: handleSearchValueChange
            };
        }
        if ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.type) === 'plan') {
            return {
                type: 'plan-selected',
                onMarkerActionClick: handleMarkerActionClick,
                onPlanActionClick: handleCreatePlanActionClick,
                onSearchActionClick: handleSearchActionClick
            };
        }
        let tooltip = undefined;
        if (currentRightClickAction === 'note-creator-picker') {
            tooltip = _jsx(Ditto, { componentId: "tooltip.placenote" });
        }
        return {
            type: (function () {
                switch (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.type) {
                    case 'point':
                        return 'point-selected';
                    case 'segment':
                        return 'segment-selected';
                }
                if (selectedLayerId === 'asset') {
                    return 'asset-active';
                }
                return 'default';
            })(),
            tooltip,
            onMarkerActionClick: handleMarkerActionClick,
            onAssetActionClick: handleAssetActionClick,
            onPlanActionClick: handlePlanActionClick,
            onAddToPlanningActionClick: handleAddToPlanningActionClick,
            onNoteActionClick: handleNoteActionClick,
            onSearchActionClick: handleSearchActionClick,
            onCreateTrafficSignClick: handleCreateTrafficSignClick
        };
    })();
    if (!content) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Actionbar, { content: content, isEditor: isEditor, isAdmin: isAdmin, isSignInventoryAuthorized: isSignInventoryAuthorized, isEditableTrafficSignsEnabled: isEditableTrafficSignsEnabled, horizontalOffset: actionbarHorizontalOffset }));
}
