import { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { ROOT_PATH } from '../common/url';
import { useAppSelector } from '../state/hooks';
import { useLayerChecker } from './useLayerChecker';
export function useAccessProtect() {
    const { selectedLayerId, layerList } = useAppSelector((state) => state.layer);
    const { isSettingsLoaded } = useLayerChecker();
    const navigate = useNavigate();
    useEffect(() => {
        var _a;
        if (!isSettingsLoaded || !selectedLayerId) {
            return;
        }
        if (!((_a = layerList.find((l) => l.id === selectedLayerId)) === null || _a === void 0 ? void 0 : _a.isAuthorized)) {
            navigate({ to: `/${ROOT_PATH.unauthorized}`, replace: true });
        }
    }, [navigate, selectedLayerId, isSettingsLoaded, layerList]);
}
