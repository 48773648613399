import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { LinkAssetModal } from '../../components/Modals/LinkAssetModal/LinkAssetModal';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearSearchAssets, fetchSearchAssets } from '../../state/slices/markerDetail';
export function LinkAssetModalContainer({ getAttachmentAsset, onClose, onDone }) {
    const dispatch = useAppDispatch();
    const { removeModal } = useModalContext();
    const { marker, searchedAssetList, searchStatus } = useAppSelector((state) => state.markerDetail);
    const { longitude, latitude } = useAppSelector((state) => state.mainMap.viewState);
    function handleInputChange(value) {
        var _a, _b;
        dispatch(fetchSearchAssets({
            search: value,
            searchCenter: {
                longitude: (_a = marker === null || marker === void 0 ? void 0 : marker.point.longitude) !== null && _a !== void 0 ? _a : longitude,
                latitude: (_b = marker === null || marker === void 0 ? void 0 : marker.point.latitude) !== null && _b !== void 0 ? _b : latitude
            }
        }));
    }
    function handleDone(id) {
        onDone === null || onDone === void 0 ? void 0 : onDone(id);
        handleClose();
    }
    function handleClose() {
        removeModal('LinkAssetModalContainer');
        dispatch(clearSearchAssets());
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (_jsx(LinkAssetModal, { status: searchStatus, results: searchedAssetList, getAttachmentAsset: getAttachmentAsset, onInputChange: handleInputChange, onClose: handleClose, onDone: handleDone }));
}
