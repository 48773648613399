import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_GROUP_KEY } from '../../common/consts/mixpanel';
import { isIdenticalAssetSetting, renderSummary, summarizeAssetDeletion } from '../../common/convert/asset';
import { getNewAttributeFilter } from '../../common/filter';
import { DeleteModalInfo } from '../../components/Modals/GlobalSettingsModal/DeleteModalInfo/DeleteModalInfo';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { saveAssetTypesTk } from '../../state/slices/globalSetting';
import { enableMapLayer, setSelectedLayerId } from '../../state/slices/layer';
import { assetInitialFilter, setAssetActiveTabKey, setAssetFilter } from '../../state/slices/leftPanelAsset';
import { useTilesUpdator } from '../mainMap/useTilesUpdator';
export const useAssetTypesSettings = () => {
    const dispatch = useAppDispatch();
    const { addModal, removeModal } = useModalContext();
    const { assetTypeList } = useAppSelector((state) => state.shared);
    const { reloadAssetTilesData } = useTilesUpdator();
    const [localAssetTypeList, setLocalAssetTypeList] = useState(assetTypeList);
    useEffect(() => setLocalAssetTypeList(assetTypeList), [assetTypeList]);
    function closeModal() {
        removeModal('DeleteModal');
        removeModal('GlobalSettingsModal');
    }
    function showAssetsList() {
        dispatch(enableMapLayer('asset'));
        dispatch(setSelectedLayerId('asset'));
        dispatch(setAssetActiveTabKey('list'));
    }
    function saveAssetSetting() {
        dispatch(saveAssetTypesTk(localAssetTypeList)).then(() => {
            reloadAssetTilesData();
        });
    }
    function resetLocalData() {
        setLocalAssetTypeList(assetTypeList);
    }
    function handleAssetTypeDeleteClick(id) {
        function handleLinkClick() {
            showAssetsList();
            dispatch(setAssetFilter(Object.assign(Object.assign({}, assetInitialFilter), { typeIds: [id] })));
            closeModal();
        }
        const toDelete = assetTypeList.find((i) => i.id === id);
        const toSave = assetTypeList.filter((i) => i.id !== id);
        if (!toDelete) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "assets.assettypes.deleteassettype" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "assets.assettypes.deleteassettypeconfirmation" }), _jsx(DeleteModalInfo, { affectedCount: toDelete.inventoryCount, onLinkClick: handleLinkClick })] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    dispatch(saveAssetTypesTk(toSave)).then(() => {
                        reloadAssetTilesData();
                    });
                    if (toDelete.defaultId) {
                        mixpanel.track(MIXPANEL_EVENT_NAME.deleteDefaultAssetType, {
                            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: toDelete.name,
                            [MIXPANEL_GROUP_KEY.assetTypeId]: toDelete.id
                        });
                    }
                }
            }
        });
    }
    function handleAssetAttributeDeleteClick(id, assetTypeId) {
        var _a;
        function handleLinkClick() {
            if (!toDelete) {
                return;
            }
            const newAttributeFilter = getNewAttributeFilter(toDelete);
            if (!newAttributeFilter) {
                return;
            }
            showAssetsList();
            dispatch(setAssetFilter(Object.assign(Object.assign({}, assetInitialFilter), { attributes: [newAttributeFilter] })));
            closeModal();
        }
        const toDelete = (_a = assetTypeList
            .find((i) => i.id === assetTypeId)) === null || _a === void 0 ? void 0 : _a.attributes.find((i) => i.id === id);
        const toSave = assetTypeList.map((i) => i.id === assetTypeId ? Object.assign(Object.assign({}, i), { attributes: i.attributes.filter((j) => j.id !== id) }) : i);
        if (!toDelete) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "assets.attributes.deleteattribute" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "assets.attributes.deleteattributeconfirmation" }), _jsx(DeleteModalInfo, { affectedCount: toDelete.amountInUse, onLinkClick: handleLinkClick })] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    dispatch(saveAssetTypesTk(toSave)).then(() => {
                        reloadAssetTilesData();
                    });
                    if (toDelete.defaultId) {
                        mixpanel.track(MIXPANEL_EVENT_NAME.deleteDefaultAssetProperty, {
                            [MIXPANEL_EVENT_PROPERTY.assetPropertyName]: toDelete.name,
                            [MIXPANEL_GROUP_KEY.assetPropertyId]: toDelete.id
                        });
                    }
                }
            }
        });
    }
    function handleAssetSelectOptionDeleteClick(id, assetTypeId, attributeId) {
        var _a, _b, _c;
        function handleLinkClick() {
            showAssetsList();
            dispatch(setAssetFilter(Object.assign(Object.assign({}, assetInitialFilter), { attributes: [{ type: 'select', id: attributeId, selectValue: [id] }] })));
            closeModal();
        }
        const toDelete = (_c = (_b = (_a = assetTypeList
            .find((i) => i.id === assetTypeId)) === null || _a === void 0 ? void 0 : _a.attributes.find((i) => i.id === attributeId)) === null || _b === void 0 ? void 0 : _b.selectOptions) === null || _c === void 0 ? void 0 : _c.find((i) => i.id === id);
        const toSave = assetTypeList.map((i) => i.id === assetTypeId
            ? Object.assign(Object.assign({}, i), { attributes: i.attributes.map((j) => {
                    var _a;
                    return j.id === attributeId
                        ? Object.assign(Object.assign({}, j), { selectOptions: (_a = j.selectOptions) === null || _a === void 0 ? void 0 : _a.filter((k) => k.id !== id) }) : j;
                }) }) : i);
        if (!toDelete) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "prompt.delete.option" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "prompt.delete.option.text" }), _jsx(DeleteModalInfo, { affectedCount: toDelete.amountInUse, onLinkClick: handleLinkClick })] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    dispatch(saveAssetTypesTk(toSave)).then(() => {
                        reloadAssetTilesData();
                    });
                }
            }
        });
    }
    function handleAssetSaveClick(callback) {
        const { summary, events } = summarizeAssetDeletion(localAssetTypeList, assetTypeList);
        const includesDelete = summary.length > 0;
        if (includesDelete) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        saveAssetSetting();
                        events.forEach((e) => mixpanel.track(e.name, e.values));
                        callback();
                    },
                    onCancel: () => callback(),
                    description: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "prompt.savechanges.copydeletions" }), renderSummary(summary)] })),
                    yesColor: 'red',
                    yesLabel: _jsx(Ditto, { componentId: "prompt.deleteandsave" })
                }
            });
            return;
        }
        saveAssetSetting();
        callback();
    }
    function handleAssetCancelClick(callback) {
        const isAssetSettingsChanged = !isIdenticalAssetSetting(localAssetTypeList, assetTypeList);
        if (isAssetSettingsChanged) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handleAssetSaveClick(callback);
                    },
                    onCancel: () => callback(),
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }
    return {
        localAssetTypeList,
        setLocalAssetTypeList,
        resetLocalData,
        handleAssetSaveClick,
        handleAssetCancelClick,
        handleAssetTypeDeleteClick,
        handleAssetAttributeDeleteClick,
        handleAssetSelectOptionDeleteClick
    };
};
