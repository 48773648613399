// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wpOtewoVEheJwRoNcdSF{display:flex;align-items:center;height:48px;background:var(--utility-hint);border-radius:12px;padding:0 16px;gap:8px}.NGccKRBp5pMKYPXPJiAv{font-weight:600;font-size:16px;line-height:18px;color:var(--secondary-blue);height:18px;overflow:hidden}@media screen and (max-width: 1200px){.NGccKRBp5pMKYPXPJiAv{max-width:6ch}}`, "",{"version":3,"sources":["webpack://./src/components/DemoBadge/DemoBadge.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,WAAA,CACA,8BAAA,CACA,kBAAA,CACA,cAAA,CACA,OAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,2BAAA,CACA,WAAA,CACA,eAAA,CAGJ,sCACI,sBACI,aAAA,CAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    height: 48px;\n    background: var(--utility-hint);\n    border-radius: 12px;\n    padding: 0 16px;\n    gap: 8px;\n}\n\n.text {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 18px;\n    color: var(--secondary-blue);\n    height: 18px;\n    overflow: hidden;\n}\n\n@media screen and (max-width: 1200px) {\n    .text {\n        max-width: 6ch;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wpOtewoVEheJwRoNcdSF`,
	"text": `NGccKRBp5pMKYPXPJiAv`
};
export default ___CSS_LOADER_EXPORT___;
