import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Image } from '../../ImagePreview/Image/Image';
import styles from './Thumbnail.module.css';
export function Thumbnail({ thumbnails }) {
    const IMAGE_PREVIEW_MAX_LIMIT = 4;
    const amount = thumbnails.length >= IMAGE_PREVIEW_MAX_LIMIT ? IMAGE_PREVIEW_MAX_LIMIT : thumbnails.length;
    const images = thumbnails.slice(0, IMAGE_PREVIEW_MAX_LIMIT);
    return (_jsxs("div", Object.assign({ className: classes(styles['wrapper'], styles['layout-' + amount], styles['grid']) }, { children: [images.map((thumbnail, idx) => (_jsx("div", Object.assign({ className: styles['image'], "data-test": "marker-thumb" }, { children: _jsx(Image, { src: thumbnail }) }), idx))), thumbnails.length > IMAGE_PREVIEW_MAX_LIMIT && (_jsxs("div", Object.assign({ className: classes(styles['overlay']) }, { children: ["+", thumbnails.length - IMAGE_PREVIEW_MAX_LIMIT] })))] })));
}
