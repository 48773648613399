import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { COMMON_ATTRIBUTE_TYPE } from '../../../../../common/consts/attribute';
import { useDittoWrapper } from '../../../../../hooks/useDittoWrapper';
import { SettingRow } from '../../SettingRow/SettingRow';
export function AssetAttributeOptions({ attribute, assetIndex: asIdx, attrIndex: atIdx, context }) {
    const newoption = useDittoWrapper({ componentId: 'assets.newoption' });
    const addoption = useDittoWrapper({ componentId: 'assets.assettypes.attributes.addoption' });
    const { isReadOnly, onOptionCreate, onOptionChange, onOptionDelete, onOptionDragEnd } = context;
    if (attribute.type !== COMMON_ATTRIBUTE_TYPE.select) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(DragDropContext, Object.assign({ onDragEnd: (params) => onOptionDragEnd(asIdx, atIdx, params) }, { children: _jsx(Droppable, Object.assign({ droppableId: "droppableAssetAttrsOptions", isDropDisabled: context.isReadOnly }, { children: (provided) => {
                var _a;
                return (_jsxs("div", Object.assign({ ref: provided.innerRef }, provided.droppableProps, { children: [(_a = attribute.selectOptions) === null || _a === void 0 ? void 0 : _a.map((selectOption, slIdx) => (_jsx(Draggable, Object.assign({ draggableId: String(selectOption.id), index: slIdx, disableInteractiveElementBlocking: false, isDragDisabled: isReadOnly }, { children: (provided) => (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { tabIndex: -1 }, { children: _jsx(SettingRow, { onDelete: isReadOnly
                                        ? undefined
                                        : () => onOptionDelete(asIdx, atIdx, slIdx), value: selectOption.name, autoFocus: selectOption.name === '', placeholder: newoption, onChange: selectOption.defaultId || isReadOnly
                                        ? undefined
                                        : (v) => onOptionChange(asIdx, atIdx, slIdx, v), indentLevel: 2, initiallyExpanded: false, draggable: !isReadOnly }, slIdx) }))) }), selectOption.id || `new-${slIdx}`))), provided.placeholder, !isReadOnly && (_jsx(SettingRow, { indentLevel: 2, value: addoption, placeholder: addoption, onClick: () => onOptionCreate(asIdx, atIdx), variant: "button" }))] })));
            } })) })));
}
