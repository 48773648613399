import { HEADER_X_VIA_CLIENT_VALUE } from '../consts/config';
import { getLocalStorageAll } from '../localStorage';
export const createRequestTransformerFunc = (targetCustomer, apiUrl) => (url, resourceType) => {
    const { token } = getLocalStorageAll();
    if ((resourceType === 'Source' || resourceType === 'Tile') &&
        (url.startsWith(apiUrl) || url.startsWith('http://localhost'))) {
        return {
            url,
            headers: {
                Authorization: `Bearer ${token}`,
                'x-via-customer': targetCustomer,
                'x-via-client': HEADER_X_VIA_CLIENT_VALUE
            }
        };
    }
    return {
        url
    };
};
