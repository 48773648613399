import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import moment from 'moment';
import { checkPassword } from '../../../common/validation/password';
import { Button } from '../../../uikit/Button/Button';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Warning1 } from '../../icons';
import { PasswordForm } from '../PasswordForm/PasswordForm';
import { UserDataForm } from '../UserDataForm/UserDataForm';
import styles from './RegisterForm.module.css';
export function RegisterForm({ currentLocale, hasError, loading, departmentList, passwordValues, values, onChange, onClickUpload, onPasswordChange, onRegisterClick, onBackClick }) {
    const isFormValid = values.firstName != '' &&
        values.lastName != '' &&
        values.titleId &&
        values.departmentId &&
        checkPassword(passwordValues.password, passwordValues.passwordConfirm).isPasswordValid;
    return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [_jsx("div", Object.assign({ className: styles['title-group'] }, { children: _jsx("span", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "register.register" }) })) })), _jsx(UserDataForm, { currentLocale: currentLocale, departmentList: departmentList, values: values, hideAvatar: true, hideLanguage: true, onChange: onChange, onClickUpload: onClickUpload }), _jsx(PasswordForm, { onChange: onPasswordChange, values: passwordValues }), _jsxs("p", Object.assign({ className: styles['copyright'] }, { children: ["vialytics \u00A9 ", moment().year()] })), hasError && (_jsxs("div", Object.assign({ className: classes(styles['error']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['icon']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Warning1, {}) })) })), _jsx(Ditto, { componentId: "toast.somethingwentwrong" })] }))), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [onBackClick && (_jsx(Button, Object.assign({ color: "gray", onClick: onBackClick, variant: "modal-action" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "modal.back" }) })) }))), _jsx(Button, Object.assign({ color: "dark", disabled: !isFormValid || loading, onClick: onRegisterClick, variant: "modal-action", "data-test": "register-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "register.register" }) })) }))] }))] })));
}
