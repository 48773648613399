import { COMMON_ATTRIBUTE_TYPE } from './attribute';
export const ASSET_ICON_TOTAL_COUNT = 42;
export const SUPPORTED_ASSET_ATTRIBUTE_TYPES = [
    COMMON_ATTRIBUTE_TYPE.select,
    COMMON_ATTRIBUTE_TYPE.number,
    COMMON_ATTRIBUTE_TYPE.text,
    COMMON_ATTRIBUTE_TYPE.date
];
export var ASSET_SOURCE_ID;
(function (ASSET_SOURCE_ID) {
    ASSET_SOURCE_ID[ASSET_SOURCE_ID["webApp"] = 2] = "webApp";
    ASSET_SOURCE_ID[ASSET_SOURCE_ID["flutterApp"] = 3] = "flutterApp";
    ASSET_SOURCE_ID[ASSET_SOURCE_ID["importCli"] = 4] = "importCli";
})(ASSET_SOURCE_ID || (ASSET_SOURCE_ID = {}));
export const RANGE_LIMIT = {
    min: 0,
    max: 100
};
export const ASSET_CHANGELOG_OPERATION = {
    create: 'create_manual_inventory',
    delete: 'delete_manual_inventory',
    updateTitle: 'update_manual_inventory_title',
    updateAddress: 'update_manual_inventory_address',
    updateNotes: 'update_manual_inventory_notes',
    udpateType: 'update_manual_inventory_type',
    updateAttribute: 'update_manual_inventory_attribute',
    addPhotos: 'add_photos_to_manual_inventory',
    removePhotos: 'remove_photos_from_manual_inventory',
    linkMarker: 'link_marker_to_manual_inventory',
    unlinkMarker: 'unlink_marker_from_manual_inventory',
    createFile: 'create_file',
    deleteFile: 'delete_file'
};
export const STREETART_ASSET_TYPE_ID = 2;
export const TAB_INDEX_ASSET_TASK = 1;
// asset icon ids, alphabetical order, matched between FE & BE
// 1 - Bank.svg
// 2 - Benches.svg
// 3 - Bicycle Parking.svg
// 4 - Bollard.svg
// 5 - Bridge.svg
// 6 - Bus Stop.svg
// 7 - Church.svg
// 8 - Clock.svg
// 9 - Crash Barriers.svg
// 10 - Cross Walks.svg
// 11 - Dog waste bag holders.svg
// 12 - Electricity.svg
// 13 - EV Charging Station.svg
// 14 - Fire Hydrant.svg
// 15 - Fire.svg
// 16 - Flag.svg
// 17 - Flower.svg
// 18 - Gas Station.svg
// 19 - Ladder.svg
// 20 - Lane Markings.svg
// 21 - Leaf.svg
// 22 - Lights.svg
// 23 - Monument.svg
// 24 - Park.svg
// 25 - Parking Meter.svg
// 26 - Phone.svg
// 27 - Pipe.svg
// 28 - Plane.svg
// 29 - Playground.svg
// 30 - Police.svg
// 31 - Public Transit.svg
// 32 - Restaurant.svg
// 33 - Sign.svg
// 34 - Sport.svg
// 35 - Storefront.svg
// 36 - Surfacebox.svg
// 37 - Traffic Lights.svg
// 38 - Trash Cans.svg
// 39 - Tree.svg
// 40 - Warning.svg
// 41 - Water.svg
// 42 - Wheelchair.svg
