// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FgtdY_853rEI9V2CJyjg{color:var(--primary-black, #000);font-weight:600;padding:0px 32px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/NotificationSettingPanel/NotificationSettingPanel.module.css"],"names":[],"mappings":"AAAA,sBACI,gCAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".section-heading {\n    color: var(--primary-black, #000);\n    font-weight: 600;\n    padding: 0px 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-heading": `FgtdY_853rEI9V2CJyjg`
};
export default ___CSS_LOADER_EXPORT___;
