// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ws49SEczVY92jr6gKEYb{flex-direction:column;align-items:flex-start;color:var(--primary-white);font-weight:600;font-size:14px;line-height:16px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/ReferencingFlag/ReferencingFlag.module.css"],"names":[],"mappings":"AAAA,sBACI,qBAAA,CACA,sBAAA,CAEA,0BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".flag {\n    flex-direction: column;\n    align-items: flex-start;\n\n    color: var(--primary-white);\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flag": `Ws49SEczVY92jr6gKEYb`
};
export default ___CSS_LOADER_EXPORT___;
