var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CancelableRequest } from '../../common/cancelableRequest';
import { PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { INSPECTION_MODE_ID } from '../../common/consts/inspection';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getPaginationParams, getTimeParams } from '../../common/utils/params';
import { exportWinterServices, getInspectionFilterParams, getInspectionListV2, getSortParams } from '../../services/inspection';
import { addNotificationMessage } from './notification';
const initialFilter = {
    ages: [],
    inspectionModeId: INSPECTION_MODE_ID.winterService
};
const winterServiceParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.winterService;
const initialState = {
    resultList: [],
    totalCount: 0,
    resultFetchStatus: 'idle',
    currentPage: PAGE_NUMBER_FIRST,
    activeTabKey: (winterServiceParams === null || winterServiceParams === void 0 ? void 0 : winterServiceParams.activeTabKey) || 'filter',
    filter: Object.assign(Object.assign({}, initialFilter), winterServiceParams === null || winterServiceParams === void 0 ? void 0 : winterServiceParams.filter),
    sort: (winterServiceParams === null || winterServiceParams === void 0 ? void 0 : winterServiceParams.sort) || 'date-desc'
};
export const fetchInspectionV2List = createDebouncedAsyncThunk('leftPanelWinterService/list', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { useMapBounds, currentPage, filter, sort } = state.leftPanelWinterService;
    const { bounds } = state.mainMap;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const res = yield CancelableRequest.send(getInspectionListV2, {
        params: {
            pagination: getPaginationParams(currentPage),
            filter: getInspectionFilterParams(filter),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            timepicker: getTimeParams(selectedOption)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const exportWinterServiceTk = createAppAsyncThunk('leftPanelWinterService/exportWinterServiceTk', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { useMapBounds, filter, sort } = state.leftPanelWinterService;
    const { bounds } = state.mainMap;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const { userAccount, teamAccount } = state.auth;
    if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)) {
        return;
    }
    const res = yield exportWinterServices({
        params: {
            filter: getInspectionFilterParams(filter),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            timepicker: getTimeParams(selectedOption)
        },
        language: locale,
        email: userAccount.email,
        useUsaFormat: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US',
        utcOffset: moment().utcOffset()
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.processingexport' } }
        }));
    }
    return res;
}));
export const leftPanelWinterServiceSlice = createSlice({
    name: 'leftPanelWinterService',
    initialState,
    reducers: {
        reset: () => initialState,
        setWinterServiceActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        resetWinterServiceFilter: (state) => {
            state.filter = initialFilter;
        },
        setWinterServiceFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setWinterServiceCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setWinterServiceSorting: (state, action) => {
            state.sort = action.payload;
        },
        setWinterServiceUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchInspectionV2List.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchInspectionV2List.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.inspections;
                state.totalCount = action.payload.result.totalCount;
            }
        });
    }
});
export const { reset: resetLeftPanelWinterService, setWinterServiceActiveTabKey, setWinterServiceFilter, resetWinterServiceFilter, setWinterServiceCurrentPage, setWinterServiceSorting, setWinterServiceUseMapBounds } = leftPanelWinterServiceSlice.actions;
export default leftPanelWinterServiceSlice.reducer;
