import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Manhole, SegmentPoints, SegmentStreet } from '../../components/icons';
import { ConditionSelectedFeatureType } from '../consts/panelTab';
export function getConditionTypeFilterLabel(type) {
    return (_jsxs(_Fragment, { children: [getConditionTypeIcon(type), getConditionTypeLabel(type)] }));
}
export function getConditionTypeIcon(type) {
    switch (type) {
        case ConditionSelectedFeatureType.segment:
            return _jsx(SegmentStreet, {});
        case ConditionSelectedFeatureType.point:
            return _jsx(SegmentPoints, {});
        case ConditionSelectedFeatureType.both:
            return (_jsxs(_Fragment, { children: [_jsx(SegmentPoints, {}), _jsx(SegmentStreet, {})] }));
        default:
            return _jsx(_Fragment, {});
    }
}
export function getConditionTypeLabel(type) {
    switch (type) {
        case ConditionSelectedFeatureType.segment:
            return _jsx(Ditto, { componentId: "segments" });
        case ConditionSelectedFeatureType.point:
            return _jsx(Ditto, { componentId: "points" });
        default:
            return '';
    }
}
export function ConditionHeaderLabel({ feature }) {
    switch (feature) {
        case 'point':
            return _jsx(Ditto, { componentId: "rightpanel.titletype.point" });
        case 'object':
            return _jsx(Ditto, { componentId: "rightpanel.titletype.stormdrainsmanholes" });
        default:
            return _jsx(Ditto, { componentId: "rightpanel.titletype.segment" });
    }
}
export function ConditionHeaderIcon({ feature }) {
    switch (feature) {
        case 'point':
            return _jsx(SegmentPoints, {});
        case 'object':
            return _jsx(Manhole, {});
        default:
            return _jsx(SegmentStreet, {});
    }
}
