import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { CoWorkerTableItem } from '../CoWorkerTableItem/CoWorkerTableItem';
import styles from './CoWorkerTable.module.css';
export function CoWorkerTable({ className, listData, isAdmin, roleList, onChangeUserRole, onDeleteInvite, onResendInvite, onRoleAndPermissionsClick }) {
    return (_jsxs("table", Object.assign({ className: classes(styles['table'], className) }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("td", { children: _jsx(Ditto, { componentId: "user" }) }), _jsx("td", Object.assign({ className: styles['department'] }, { children: _jsx(Ditto, { componentId: "register.department" }) })), _jsx("td", Object.assign({ className: styles['role'] }, { children: _jsx(Ditto, { componentId: "coworkermanagement.role" }) }))] }) }), _jsx("tbody", { children: listData
                    .sort((a) => (a.isCurrentUser ? -1 : 1))
                    .map((it) => (_jsx(CoWorkerTableItem, { data: it, isAdmin: isAdmin, roleList: roleList, onRoleAndPermissionsClick: onRoleAndPermissionsClick, onChangeUserRole: onChangeUserRole, onDeleteInvite: onDeleteInvite, onResendInvite: onResendInvite }, `co-worker-${it.uuid}`))) })] })));
}
