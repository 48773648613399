export function getCheckboxCheckState(total, checked) {
    if (total === undefined || checked === undefined) {
        return 'not-checked';
    }
    if (total === checked) {
        return 'checked';
    }
    if (checked === 0) {
        return 'not-checked';
    }
    return 'indeterminate';
}
export function getCheckboxCheckStateFromObject(object, keys = []) {
    const existedKeys = keys.filter((key) => {
        const value = object === null || object === void 0 ? void 0 : object[key];
        return value !== false && value !== undefined;
    });
    if (existedKeys.length === keys.length) {
        return 'checked';
    }
    if (existedKeys.length > 0) {
        return 'indeterminate';
    }
    return 'not-checked';
}
