// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r5_DVUKtvjnM_jmqcueC{padding:12px;display:flex;flex-direction:row;align-items:center;justify-content:space-between;gap:10px;border:1px solid rgba(129,129,129,.1);border-radius:6px;transition:background-color 150ms ease}.r5_DVUKtvjnM_jmqcueC:hover{background:rgba(129,129,129,.1);cursor:pointer}.xa4kUiXJs5usUiy9aeP5{display:flex;flex-direction:column;justify-content:center;gap:4px}.Jvkt2_a9ZYry5NdM5owW{color:var(--primary-dark-blue);opacity:.6;font-size:14px;font-weight:400;line-height:18.2px;word-wrap:break-word}.csYqEcDem9zLUQpzMr1Q{color:var(--primary-dark-blue);font-size:14px;font-weight:600;word-wrap:break-word}`, "",{"version":3,"sources":["webpack://./src/components/WorkingHours/WorkingHours.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,qCAAA,CACA,iBAAA,CACA,sCAAA,CAGJ,4BACI,+BAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CAGJ,sBACI,8BAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,oBAAA,CAGJ,sBACI,8BAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA","sourcesContent":[".container {\n    padding: 12px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    gap: 10px;\n    border: 1px solid rgba(129, 129, 129, 0.1);\n    border-radius: 6px;\n    transition: background-color 150ms ease;\n}\n\n.container:hover {\n    background: rgba(129, 129, 129, 0.1);\n    cursor: pointer;\n}\n\n.item {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 4px;\n}\n\n.item-label {\n    color: var(--primary-dark-blue);\n    opacity: 0.6;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 18.2px;\n    word-wrap: break-word;\n}\n\n.item-datetime {\n    color: var(--primary-dark-blue);\n    font-size: 14px;\n    font-weight: 600;\n    word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `r5_DVUKtvjnM_jmqcueC`,
	"item": `xa4kUiXJs5usUiy9aeP5`,
	"item-label": `Jvkt2_a9ZYry5NdM5owW`,
	"item-datetime": `csYqEcDem9zLUQpzMr1Q`
};
export default ___CSS_LOADER_EXPORT___;
