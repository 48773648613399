var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getNavigation } from '../../services/navigation';
export const fetchConditionNavigation = createAppAsyncThunk('navigation/fetchConditionNavigation', (uuid, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getNavigation(uuid, {
        filter: getState().leftPanelCondition.filter,
        timepicker: getState().presetsPanel.timepicker.selectedOption
    });
}));
const initialState = {};
export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConditionNavigation.pending, (state) => {
            state.fetchStatus = 'loading';
            state.isLoading = true;
        })
            .addCase(fetchConditionNavigation.rejected, (state) => {
            state.fetchStatus = 'failed';
        })
            .addCase(fetchConditionNavigation.fulfilled, (state, action) => {
            state.fetchStatus = 'succeeded';
            state.isLoading = false;
            state.navigation = action.payload.result;
        });
    }
});
export default navigationSlice.reducer;
