import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './IconResizer.module.css';
const STANDARD_ICON_SIZE = 24;
export function IconResizer({ children, size, resizeContainer }) {
    const content = (_jsx("div", Object.assign({ className: styles['icon'], style: {
            transform: `scale(${size / STANDARD_ICON_SIZE})`
        } }, { children: children })));
    if (!resizeContainer) {
        return content;
    }
    return (_jsx("div", Object.assign({ className: styles['container'], style: {
            height: `${size}px`,
            width: `${size}px`
        } }, { children: content })));
}
