import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useId, useState } from 'react';
import { Layer, Marker, Source, useMap } from 'react-map-gl';
import { Ditto } from 'ditto-react';
import { MAP_VIA_LAT, MAP_VIA_LNG, MINIMAP_DEFAULT_ZOOM } from '../../common/consts/map';
import { MIXPANEL_EVENT_NAME } from '../../common/consts/mixpanel';
import { SIGN_DAMAGE_ID, SIGN_DAMAGE_NAMES, SIGN_INVENTORY_SIGNS_TO_SHOW, SIGN_STATUS_CODE } from '../../common/consts/trafficSign';
import { ASSET_CREATOR_FORM_WIDTH } from '../../common/consts/width';
import { signInventoryToMarkerSource } from '../../common/convert';
import { getGroupFromSignTree, getSignFromSignTree, getSignIcon, getSignTranslation, getSignTypeAbbreviation, getSignTypeIcon } from '../../common/convert/signInventory';
import { formatLatLng } from '../../common/geoFormatter';
import { createRequestTransformerFunc, createSignInventorySource, DEFAULT_SOURCE_LAYER, signInventoryLayer, signInventoryPoleLayer } from '../../common/mapbox';
import { signInventoryPoleSelectedLayer, signInventorySelectedLayer } from '../../common/mapbox';
import { getApiHost, ROOT_PATH } from '../../common/url';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { AddressSearch } from '../../components/AddressSearch/AddressSearch';
import { Changelog } from '../../components/DetailsPanel/Changelog/Changelog';
import { DetailsCallToAction } from '../../components/DetailsPanel/DetailsCallToAction/DetailsCallToAction';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { DetailsHeader } from '../../components/DetailsPanel/DetailsHeader/DetailsHeader';
import { DetailsInfo } from '../../components/DetailsPanel/DetailsInfo/DetailsInfo';
import { DetailsMetaInformation } from '../../components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformation';
import { DetailsMetaInformationItem } from '../../components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem';
import { DetailsPanel } from '../../components/DetailsPanel/DetailsPanel';
import { EmptyState } from '../../components/DetailsPanel/EmptyState/EmptyState';
import { DropMenuWrapper } from '../../components/DropMenuWrapper/DropMenuWrapper';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { AddMarker, Bin, CalendarBlank, Close, Download, Mail, MapPin, Overflow, Print, Report, SparkleMagicApplied, VerifiedApproved } from '../../components/icons';
import { ImageCrop } from '../../components/ImageCrop/ImageCrop';
import { ImagePreview } from '../../components/ImagePreview/ImagePreview';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { Minimap } from '../../components/Minimap/Minimap';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { OrientationField } from '../../components/OrientationField/OrientationField';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { SignInventoryChangelog } from '../../components/SignInventoryDetail/SignInventoryChangelog/SignInventoryChangelog';
import { SignInventoryDetail } from '../../components/SignInventoryDetail/SignInventoryDetail';
import { TrafficSignRotator } from '../../components/TrafficSignRotator';
import config from '../../config/config.json';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useSignFields } from '../../hooks/forms/useSignFields';
import { useMainMap, useTiles } from '../../hooks/mainMap';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { useDelayedLoader } from '../../hooks/useDelayedLoader';
import { useDraggableFeature } from '../../hooks/useDraggableFeature';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMapInit } from '../../hooks/useMapInit';
import { useMarkerCreatorOpener } from '../../hooks/useMarkerCreatorOpener';
import { useRotatableFeature } from '../../hooks/useRotatableFeature';
import { useSignInventoryFiles } from '../../hooks/useSignInventoryFiles';
import { useSignInventoryFileUpload } from '../../hooks/useSignInventoryFileUpload';
import { useSignInventoryInfoFetcher } from '../../hooks/useSignInventoryInfoFetcher';
import { useUserRole } from '../../hooks/useUserRole';
import { submitTrackingEvent } from '../../services/mail';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearSelectedFeature } from '../../state/slices/feature';
import { deleteSigns, exportSignsByEmailTk, verifySigns } from '../../state/slices/leftPanelSignInventory';
import { setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { updateSignAddressTk, updateSignConditionTk, updateSignOrientationTk, uploadFilesTk } from '../../state/slices/signInventoryDetail';
import { Button } from '../../uikit/Button/Button';
const IMAGE_SIZE = { height: 250, width: 356 };
export function SignInventoryDetailContainer() {
    var _a, _b, _c, _d, _e;
    const { main: map } = useMap();
    const dispatch = useAppDispatch();
    const { formatAddress } = useFormatter();
    const dateTimeFormatter = useDateTimeFormatter();
    const { addModal, removeModal, modals } = useModalContext();
    const { isLoading, error, signInfo } = useAppSelector((state) => state.signInventoryDetail);
    const { signTree, signInventoryDamageTypes, taskAssigneeMap } = useAppSelector((state) => state.shared);
    const { targetSchema, userAccount } = useAppSelector((state) => state.auth);
    const { getMagicValuesForSignInventoryTask } = useMarkerCreatorOpener();
    const handleMapInit = useMapInit();
    const { signInventoryTiles } = useTiles();
    const { getBeforeId } = useMainMap();
    const { filesSection } = useSignInventoryFiles({ mode: 'edit' });
    const { selectFilesForUpload } = useSignInventoryFileUpload();
    const { isEditableTrafficSignsEnabled } = useLayerChecker();
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { draggableFeature, lastRotation } = useAppSelector((state) => state.mainMap);
    const { handleAddressSearchChange, fetchedSuggestions, addressSearchStatus } = useAddressSearch();
    const { getCustomPropertiesFields } = useSignFields({
        position: {
            lng: (_a = signInfo === null || signInfo === void 0 ? void 0 : signInfo.offsetPosition[0]) !== null && _a !== void 0 ? _a : MAP_VIA_LNG,
            lat: (_b = signInfo === null || signInfo === void 0 ? void 0 : signInfo.offsetPosition[1]) !== null && _b !== void 0 ? _b : MAP_VIA_LAT
        },
        condition: {
            id: SIGN_DAMAGE_ID.not_damaged,
            name: SIGN_DAMAGE_NAMES.not_damaged
        }
    });
    const signDamageTypesToShow = signInventoryDamageTypes.filter((t) => SIGN_INVENTORY_SIGNS_TO_SHOW.includes(t.id));
    const { enableDrag, disableDragWithTimeout } = useDraggableFeature();
    const { enableRotate, disableRotate } = useRotatableFeature();
    const { reloadSignInventoryTilesData } = useTilesUpdator();
    const { isEditor, isAdmin, isViewer } = useUserRole();
    const { formatDateISO } = useFormatter();
    const [isRotating, setIsRotating] = useState(false);
    useSignInventoryInfoFetcher();
    const sign = getSignFromSignTree(signTree, signInfo === null || signInfo === void 0 ? void 0 : signInfo.tag.subSpecifiedClass);
    const group = getGroupFromSignTree(signTree, signInfo === null || signInfo === void 0 ? void 0 : signInfo.tag.subSpecifiedClass);
    const isImageViewerModalActive = modals
        .map((modal) => modal.id)
        .filter((id) => id === 'ImageViewerSignInventory' || id === 'FileGallerySignInventory')
        .length > 0;
    const minimapId = useId();
    const delayedLoader = useDelayedLoader({ isLoading });
    const [localRotation, setLocalRotation] = useState((_c = signInfo === null || signInfo === void 0 ? void 0 : signInfo.orientation) !== null && _c !== void 0 ? _c : 0);
    useEffect(() => {
        if (!signInfo || !map) {
            return;
        }
        setIsRotating(false);
        disableRotate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInfo]);
    useEffect(() => {
        if (!lastRotation) {
            setLocalRotation((signInfo === null || signInfo === void 0 ? void 0 : signInfo.orientation) || 0);
            return;
        }
        setLocalRotation(lastRotation);
    }, [signInfo === null || signInfo === void 0 ? void 0 : signInfo.orientation, lastRotation]);
    useEffect(() => {
        if (!signInfo)
            return;
        setLocalRotation(signInfo === null || signInfo === void 0 ? void 0 : signInfo.orientation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInfo === null || signInfo === void 0 ? void 0 : signInfo.id]);
    function handleClickSuggestion(suggestion) {
        if (!signInfo) {
            return;
        }
        dispatch(updateSignAddressTk({
            id: signInfo.id,
            latitude: suggestion.position.lat,
            longitude: suggestion.position.lng
        }))
            .unwrap()
            .then(() => {
            reloadSignInventoryTilesData();
        });
    }
    function handleAddressClick() {
        if (!signInfo) {
            return;
        }
        enableDrag({
            type: 'sign-inventory',
            value: signInfo
        });
    }
    function handleOrientationClick() {
        if (!signInfo || !map) {
            return;
        }
        if (map.getBearing() !== 0) {
            map.rotateTo(0, { duration: 500 });
        }
        setIsRotating(true);
        enableRotate({
            type: 'sign-inventory',
            value: signInfo
        });
    }
    function handleOrientationBlur(newOrientation) {
        if (!signInfo) {
            return;
        }
        setIsRotating(false);
        disableRotate();
        if (signInfo.orientation === newOrientation) {
            return;
        }
        dispatch(updateSignOrientationTk({ id: signInfo.id, orientation: newOrientation })).then(() => {
            reloadSignInventoryTilesData();
        });
    }
    function handleImageEnlarge() {
        addModal({ id: 'ImageViewerSignInventory' });
    }
    function handleReportButtonClick() {
        addModal({ id: 'ReportModal' });
    }
    function handleDownloadButtonClick() {
        if (!signInfo) {
            return;
        }
        addModal({
            id: 'ExportModal',
            props: {
                onStartExportClick: () => dispatch(exportSignsByEmailTk({ ids: [signInfo.id] })),
                requestList: [
                    {
                        type: 'sign-inventory',
                        count: 1
                    }
                ]
            }
        });
    }
    function handleMailClick() {
        if (!signInfo) {
            return;
        }
        addModal({
            id: 'MailModal',
            props: {
                featureList: {
                    type: 'sign-inventory',
                    // TODO: @street-gallery team: remove type casting
                    value: [signInfo]
                }
            }
        });
    }
    function handlePrintClick() {
        window.open(`/${ROOT_PATH.print}/sign-inventory/${signInfo === null || signInfo === void 0 ? void 0 : signInfo.id}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleCreateMarkerClick() {
        if (!signInfo) {
            return;
        }
        const { taskTitle, taskDescription, taskPriorityId } = getMagicValuesForSignInventoryTask(signInfo.damageType);
        const fullTaskDescription = `${taskDescription} "${sign === null || sign === void 0 ? void 0 : sign.name} (${sign === null || sign === void 0 ? void 0 : sign.bastId})"`;
        dispatch(setMarkerCreatorPointSource(signInventoryToMarkerSource(signInfo, taskTitle, fullTaskDescription, taskPriorityId)));
        submitTrackingEvent(MIXPANEL_EVENT_NAME.createTaskFromSign);
    }
    function handleCloseClick() {
        if (modals.find((modal) => modal.id === 'FileGallerySignInventory')) {
            removeModal('FileGallerySignInventory');
            return;
        }
        else if (modals.find((modal) => modal.id === 'ImageViewerSignInventory')) {
            removeModal('ImageViewerSignInventory');
            return;
        }
        dispatch(clearSelectedFeature());
    }
    function handleVerifyClick(ids) {
        dispatch(verifySigns(ids)).then(() => {
            reloadSignInventoryTilesData();
        });
    }
    function handleDeleteClick(ids) {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteSigns(ids)).then(() => {
                        reloadSignInventoryTilesData();
                        removeModal('ImageViewerSignInventory');
                    });
                },
                title: _jsx(Ditto, { componentId: "prompt.delete.trafficsign" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.trafficsign.info" })
            }
        });
    }
    function handleDamageClassSelect(selectedDamageClass) {
        handleConditionItemClick(selectedDamageClass);
    }
    function handleConditionItemClick(selectedDamageClass) {
        if (!signInfo) {
            return;
        }
        dispatch(updateSignConditionTk({
            id: signInfo.id,
            damageClass: selectedDamageClass
        })).then(() => {
            reloadSignInventoryTilesData();
        });
    }
    function handleEditPropertiesClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'sign-inventory-properties',
                modalProps: {
                    type: 'sign-inventory-properties'
                }
            }
        });
    }
    const handleCustomizeClick = useCallback(() => {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'sign-inventory-files'
            }
        });
    }, [addModal]);
    const header = (_jsx(DetailsHeader, { buttonsRight: _jsxs(_Fragment, { children: [_jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ trailingIcon: _jsx(IconResizer, Object.assign({ size: 28 }, { children: _jsx(Overflow, {}) })), variant: "slender", "data-test": "three-dot-menu" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), menu: _jsxs(ActionModal, Object.assign({ width: 263 }, { children: [!isViewer &&
                                (signInfo === null || signInfo === void 0 ? void 0 : signInfo.statusCode) !== SIGN_STATUS_CODE.predicted && (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.createtask" }), leadingIcon: _jsx(AddMarker, {}), onClick: handleCreateMarkerClick })), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.exportxls" }), leadingIcon: _jsx(Download, {}), onClick: handleDownloadButtonClick, disabled: !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email) }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), leadingIcon: _jsx(Print, {}), onClick: handlePrintClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), leadingIcon: _jsx(Mail, {}), onClick: handleMailClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.reportissue" }), leadingIcon: _jsx(Report, {}), onClick: handleReportButtonClick }), (signInfo === null || signInfo === void 0 ? void 0 : signInfo.statusCode) === SIGN_STATUS_CODE.predicted &&
                                isEditableTrafficSignsEnabled &&
                                isEditor && (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "trafficsigns.verify" }), leadingIcon: _jsx(VerifiedApproved, {}), onClick: () => handleVerifyClick([signInfo.id]), "data-test": "verify-sign" })), signInfo && isEditableTrafficSignsEnabled && isEditor && (_jsx(SelectboxEntry, { color: "red", text: _jsx(Ditto, { componentId: "overflowmenue.delete" }), leadingIcon: _jsx(Bin, {}), onClick: () => handleDeleteClick([signInfo.id]) }))] })), direction: "down-left" }), _jsx(Button, Object.assign({ variant: "square", color: "gray-2", onClick: handleCloseClick }, { children: _jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(Close, {}) })) }))] }), title: _jsx(Ditto, { componentId: getSignTypeAbbreviation((signInfo === null || signInfo === void 0 ? void 0 : signInfo.tag.subSpecifiedClass) || '') }), titleIcon: getSignTypeIcon(signInfo === null || signInfo === void 0 ? void 0 : signInfo.tag.subSpecifiedClass), label: _jsx(Ditto, { componentId: "rightpanel.titletype.sign" }), id: signInfo === null || signInfo === void 0 ? void 0 : signInfo.id, withIconResizer: false, withUnderline: false }));
    const signDateTime = dateTimeFormatter(signInfo === null || signInfo === void 0 ? void 0 : signInfo.timestamp);
    const pinLabel = formatLatLng({
        lat: (_d = signInfo === null || signInfo === void 0 ? void 0 : signInfo.offsetPosition[1]) !== null && _d !== void 0 ? _d : 0,
        lng: (_e = signInfo === null || signInfo === void 0 ? void 0 : signInfo.offsetPosition[0]) !== null && _e !== void 0 ? _e : 0
    }, {
        // In US locale, 5 decimal places still
        // fits with the date & time in one line.
        // Corresponds to 1.11 m precision at equator.
        // Source: https://en.wikipedia.org/wiki/Decimal_degrees
        precision: 5
    });
    const metaInfo = (_jsxs(DetailsMetaInformation, { children: [_jsx(DetailsMetaInformationItem, { icon: _jsx(CalendarBlank, {}), label: signDateTime.dateTime }), _jsx(DetailsMetaInformationItem, { icon: _jsx(MapPin, {}), label: signInfo === null || signInfo === void 0 ? void 0 : signInfo.displayName }), _jsx(DetailsMetaInformationItem, { label: `(${pinLabel})`, "data-test": "sign-coords" })] }));
    const damageType = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'objects.status' }), content: _jsxs(_Fragment, { children: [getSignIcon(signInfo === null || signInfo === void 0 ? void 0 : signInfo.damageType), getSignTranslation(signInfo === null || signInfo === void 0 ? void 0 : signInfo.damageType)] }) }));
    const signName = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'signs.details.name' }), content: _jsxs(_Fragment, { children: [getSignTypeIcon(signInfo === null || signInfo === void 0 ? void 0 : signInfo.tag.subSpecifiedClass), _jsx(Ditto, { componentId: getSignTypeAbbreviation((signInfo === null || signInfo === void 0 ? void 0 : signInfo.tag.subSpecifiedClass) || '') })] }) }));
    const signGroup = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'signs.details.group' }), content: group === null || group === void 0 ? void 0 : group.name }));
    if (!targetSchema || !signInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    const geojson = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [signInfo.offsetPosition[0], signInfo.offsetPosition[1]]
        },
        properties: {
            icon: signInfo.damageType,
            sub_specified_class: signInfo.tag.subSpecifiedClass
        }
    };
    const mapContent = (_jsxs(Minimap, Object.assign({ ref: handleMapInit, initialViewState: {
            longitude: signInfo.offsetPosition[0],
            latitude: signInfo.offsetPosition[1],
            zoom: MINIMAP_DEFAULT_ZOOM
        }, transformRequest: createRequestTransformerFunc(targetSchema, getApiHost()), id: minimapId, size: "large", noOutline: true, variant: "image-viewer", interactive: true }, { children: [_jsxs(Source, Object.assign({}, createSignInventorySource(targetSchema), { tiles: signInventoryTiles }, { children: [_jsx(Layer, Object.assign({}, signInventoryPoleLayer, { "source-layer": DEFAULT_SOURCE_LAYER, beforeId: getBeforeId(signInventoryPoleLayer.id) })), _jsx(Layer, Object.assign({}, signInventoryLayer, { "source-layer": DEFAULT_SOURCE_LAYER }))] })), _jsxs(Source, Object.assign({ id: DEFAULT_SOURCE_LAYER, type: "geojson", data: geojson }, { children: [_jsx(Layer, Object.assign({}, signInventoryPoleSelectedLayer)), _jsx(Layer, Object.assign({}, signInventorySelectedLayer)), config.enableOrientation && (_jsx(Marker, Object.assign({ longitude: signInfo === null || signInfo === void 0 ? void 0 : signInfo.offsetPosition[0], latitude: signInfo === null || signInfo === void 0 ? void 0 : signInfo.offsetPosition[1], anchor: "center" }, { children: _jsx(TrafficSignRotator, { isRotatable: false, orientation: signInfo === null || signInfo === void 0 ? void 0 : signInfo.orientation, variant: "edit" }) })))] }))] })));
    const image = signInfo.imageUrl ? (_jsx(ImagePreview, Object.assign({}, IMAGE_SIZE, { onClickEnlarge: handleImageEnlarge }, { children: _jsx(ImageCrop, { src: signInfo.imageUrl, croppedImageUrl: signInfo.croppedImageUrl, alt: "Traffic Sign", tag: signInfo.tag, imageSize: IMAGE_SIZE }) }))) : (_jsx(_Fragment, {}));
    let callToAction;
    if (isEditableTrafficSignsEnabled &&
        isEditor &&
        signInfo.statusCode === SIGN_STATUS_CODE.predicted) {
        callToAction = (_jsx(DetailsCallToAction, { icon: _jsx(SparkleMagicApplied, {}), title: _jsx(Ditto, { componentId: "magicinput.aigeneratedtrafficsign" }), content: _jsx(Ditto, { componentId: "magicinput.aigeneratedtrafficsigncopy" }), buttons: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ color: "transparent", variant: "fullwidth", onClick: () => handleDeleteClick([signInfo.id]) }, { children: _jsx(Ditto, { componentId: "overflowmenue.delete" }) })), _jsx(Button, Object.assign({ icon: _jsx(VerifiedApproved, {}), color: "dark", variant: "fullwidth", onClick: () => handleVerifyClick([signInfo.id]), "data-test": "verify-sign" }, { children: _jsx(Ditto, { componentId: "trafficsigns.verify" }) }))] }), color: "var(--root-green-8)", backgroundColor: "var(--root-green-2)" }));
    }
    const infobox = (_jsx(InfoBox, { content: _jsx(Ditto, { componentId: "address.dragobjectonmaporenteraddresshere" }) }));
    const addressField = (_jsx(AddressSearch, Object.assign({ value: formatAddress(signInfo.address), width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleAddressSearchChange, status: addressSearchStatus, variant: "ghost", onClick: handleAddressClick, onBlur: disableDragWithTimeout, disabled: !(isEditor && isEditableTrafficSignsEnabled), infobox: draggableFeature && draggableFeature.type === 'sign-inventory' && infobox }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatAddress(s.address, { isShort: true }), secondaryText: formatAddress(s.address, {
                cityAndCountry: true
            }), onClick: () => handleClickSuggestion(s) }, `${s.position.lat}-${s.position.lng}`))) })));
    function handleSignTypeSelectFieldClick() {
        if (!signInfo || !(isEditableTrafficSignsEnabled && isEditor))
            return;
        addModal({ id: 'SignTypeSelectModalContainer', props: { signId: signInfo === null || signInfo === void 0 ? void 0 : signInfo.id } });
    }
    const orientationField = (_jsx(OrientationField, { orientation: localRotation, onClick: handleOrientationClick, onBlur: handleOrientationBlur, isRotating: isRotating, variant: 'ghost' }));
    const filesCount = signInfo.folders
        .map((folder) => folder.files.length)
        .reduce((acc, current) => acc + current, 0);
    function getFilesSection() {
        if (!isEditableTrafficSignsEnabled) {
            return;
        }
        if (signInfo && filesCount === 0) {
            return (_jsx(EmptyState, { illustration: _jsx("img", { src: "/documents.svg" }), title: _jsx(Ditto, { componentId: "files.getstartedwithdocuments" }), caption: _jsx(Ditto, { componentId: "files.keepallrelevantinformationatyourfingertips" }), action: {
                    label: _jsx(Ditto, { componentId: "files.uploaddocument" }),
                    onClick: () => selectFilesForUpload(() => new Promise((resolve) => addModal({
                        id: 'FileUploadModal',
                        props: {
                            folders: signInfo.folders,
                            onUploadClick: (folderId) => {
                                resolve({ folderId });
                            },
                            onCloseClick: () => {
                                resolve({ folderId: null });
                            }
                        }
                    })), signInfo.id, (folderId, files) => {
                        dispatch(uploadFilesTk({
                            signId: signInfo.id,
                            folderId,
                            files: files
                        }));
                    })
                }, customize: { show: !isViewer, onClick: handleCustomizeClick } }));
        }
        return filesSection;
    }
    return (_jsx(DetailsPanel, Object.assign({ loader: delayedLoader, isError: !!error, error: _jsx(DetailsInfo, { linkText: _jsx(Ditto, { componentId: "close" }), content: _jsx(Ditto, { componentId: "somethingwentwrongtext" }) }) }, { children: _jsx(SignInventoryDetail, { subSpecifiedClass: signInfo.tag.subSpecifiedClass, header: header, metaInfo: metaInfo, callToAction: callToAction, imagePreview: isImageViewerModalActive ? mapContent : image, name: signName, bastId: (sign === null || sign === void 0 ? void 0 : sign.bastId) || '', group: signGroup, damageType: damageType, filesSection: getFilesSection(), filesCount: filesCount, propertiesSection: isEditableTrafficSignsEnabled
                ? getCustomPropertiesFields('edit', signInfo)
                : undefined, onEditPropertiesClick: handleEditPropertiesClick, selectedFeature: selectedFeature, onAddMarkerClick: handleCreateMarkerClick, showFooter: !(isEditableTrafficSignsEnabled &&
                signInfo.statusCode === SIGN_STATUS_CODE.predicted), conditionField: { items: signDamageTypesToShow, current: signInfo === null || signInfo === void 0 ? void 0 : signInfo.damageType }, onDamageClassSelect: handleDamageClassSelect, addressField: addressField, orientationField: orientationField, onSignTypeSelectFieldClick: handleSignTypeSelectFieldClick, isEditableTrafficSignsEnabled: isEditableTrafficSignsEnabled, isEditor: isEditor, isAdmin: isAdmin, isViewer: isViewer, changelog: isEditableTrafficSignsEnabled ? (_jsx(Changelog, { children: _jsx(SignInventoryChangelog, { sign: signInfo, dateTimeFormatter: dateTimeFormatter, assigneeList: [], taskAssigneeMap: taskAssigneeMap, formatDateISO: formatDateISO }) })) : undefined }) })));
}
