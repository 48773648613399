import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getPlanCategoryLabel, getPlanCategoryVignette } from '../../../common/convert/plan';
import { FilterSelectbox } from '../../FilterSelectbox/FilterSelectbox';
import { FormInputCurrentSelectedValue } from '../../FormInputSelect/FormInputSelect';
export function CategorySelectItem({ category: { categoryId }, isChecked, onClick }) {
    return (_jsx(FilterSelectbox, { noCheckbox: true, content: getPlanCategoryLabel(categoryId), onClick: onClick, isChecked: isChecked, icon: getPlanCategoryVignette(categoryId), "data-test": `dropdown-category-${categoryId}` }, categoryId));
}
export function CategorySelectItemCurrentSelectedValue({ category: { categoryId } }) {
    return (_jsx(FormInputCurrentSelectedValue, { text: getPlanCategoryLabel(categoryId), icon: getPlanCategoryVignette(categoryId) }, categoryId));
}
