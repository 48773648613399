import { useState } from 'react';
import { checkRequired } from '../../common/validation/required';
import { useAppDispatch } from '../../state/hooks';
import { addNotificationMessage } from '../../state/slices/notification';
export function useMarkerFields({ priority, status, creator, taskTitle, taskDescription, sourceImages }) {
    const dispatch = useAppDispatch();
    const [markerFields, setMarkerFields] = useState({
        taskTitle: taskTitle !== null && taskTitle !== void 0 ? taskTitle : '',
        description: taskDescription !== null && taskDescription !== void 0 ? taskDescription : '',
        creator,
        images: [],
        priority,
        status,
        assignees: [],
        recurringFormula: '',
        sourceImages: sourceImages
    });
    function getValidatedMarkerDraft() {
        var _a, _b, _c;
        const draft = {
            creator: markerFields.creator,
            description: markerFields.description,
            priorityId: (_a = markerFields.priority) === null || _a === void 0 ? void 0 : _a.priorityId,
            statusId: (_b = markerFields.status) === null || _b === void 0 ? void 0 : _b.statusId,
            categoryId: (_c = markerFields.category) === null || _c === void 0 ? void 0 : _c.categoryId,
            assignees: markerFields.assignees,
            title: markerFields.taskTitle,
            recurringFormula: markerFields.recurringFormula
        };
        const missingFields = checkRequired(draft, ['statusId', 'priorityId']);
        if (missingFields.length > 0) {
            dispatch(addNotificationMessage({
                type: 'error',
                title: `Required: ${missingFields.join(', ')}`
            }));
            return;
        }
        return draft;
    }
    function updateMarkerFields(update) {
        setMarkerFields(Object.assign(Object.assign({}, markerFields), update));
        return;
    }
    return {
        markerFields,
        updateMarkerFields,
        getValidatedMarkerDraft
    };
}
