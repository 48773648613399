import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '../../../uikit/Button/Button';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Close, Warning1 } from '../../icons';
import { Modal } from '../../Modal/Modal';
import styles from './ReportIssue.module.css';
export function ReportIssue({ title, subtitle, content, error, cancelButtonText, sendButtonText, isSending, onClose, onCancel, onSend, onChange }) {
    return (_jsx(Modal, { width: 770, title: title, titleMargin: 0, trailing: _jsx(Button, Object.assign({ variant: "square", onClick: onClose, disabled: isSending }, { children: _jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(Close, {}) })) })), content: _jsxs(_Fragment, { children: [subtitle, _jsx("textarea", { className: styles['content'], defaultValue: content, disabled: isSending, onChange: (e) => onChange(e.target.value) }), error && (_jsxs("div", Object.assign({ className: styles['error'] }, { children: [_jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(Warning1, {}) })), error] })))] }), contentGap: 12, footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ color: "gray", onClick: onCancel, disabled: isSending }, { children: _jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx("span", { children: cancelButtonText }) })) })), _jsx(Button, Object.assign({ color: "dark", onClick: onSend, disabled: isSending || !content.length }, { children: _jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx("span", { children: sendButtonText }) })) }))] }) }));
}
