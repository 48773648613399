import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { ActionButton } from '../../ActionButton/ActionButton';
import { GradeBadge } from '../../GradeBadge/GradeBadge';
import { Length, SegmentCustom } from '../../icons';
import styles from './Segment.module.css';
export function Segment({ content: { edge, id, title, grade, gradePCI, gradePaser, length }, isAdmin, onAddToPlanningButtonClick, withAction, checkbox, showGradePCI, showGradePaser, countryCode }) {
    const edgeLabel = useDittoWrapper({
        componentId: 'edgeiddemo',
        variables: { Edge: edge, ID: id }
    });
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-segment" }, { children: [_jsxs("div", Object.assign({ className: styles['overtitle'] }, { children: [checkbox, _jsx("span", Object.assign({ "data-test": "segment-label" }, { children: edgeLabel }))] })), _jsx("div", Object.assign({ className: styles['title'] }, { children: title })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [showGradePCI && gradePCI !== undefined && (_jsx(GradeBadge, { type: "pci", grade: gradePCI, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.pci" }), ':'] }), tooltip: _jsx(Ditto, { componentId: "grading.pcibeta" }), "data-test": "grade-pci-badge" })), showGradePaser && gradePaser !== undefined && (_jsx(GradeBadge, { type: "paser", grade: gradePaser, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.paser" }), ':'] }), tooltip: _jsx(Ditto, { componentId: "grading.paserbeta" }), "data-test": "grade-paser-badge" })), _jsx(GradeBadge, { type: "emi", grade: grade, prefix: countryCode === 'US' ? (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.emi" }), ':'] })) : undefined, "data-test": "grade-badge" }), _jsxs("div", Object.assign({ className: styles['length'], "data-test": "segment-length" }, { children: [_jsx("div", Object.assign({ className: styles['icon'] }, { children: _jsx(Length, {}) })), length.formattedText] })), withAction && isAdmin && (_jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx(ActionButton, { className: styles['action-button'], icon: _jsx(SegmentCustom, {}), tooltip: {
                                text: _jsx(Ditto, { componentId: "createplannedsegment" }),
                                arrow: 'up',
                                position: 'end'
                            }, onClick: onAddToPlanningButtonClick, "data-test": "btn-add-to-planning" }) })))] }))] })));
}
