import { useEffect, useRef, useState } from 'react';
function useComponentWidth() {
    const [width, setWidth] = useState(0);
    const ref = useRef(null);
    useEffect(() => {
        const element = ref.current;
        if (!element)
            return;
        const resizeObserver = new ResizeObserver((entries) => {
            const [entry] = entries;
            if (entry) {
                setWidth(entry.contentRect.width);
            }
        });
        resizeObserver.observe(element);
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    return { ref, width };
}
export default useComponentWidth;
