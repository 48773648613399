import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto, useDittoComponent } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Warning1 } from '../../icons';
import { Loader } from '../../Loader/Loader';
import styles from './Join.module.css';
export function Join({ inviteData, loading, hasError, roleList, onAcceptInviteClick }) {
    var _a;
    const role = useDittoComponent({
        componentId: inviteData
            ? `role.${(_a = roleList.find((role) => role.id === (inviteData === null || inviteData === void 0 ? void 0 : inviteData.roleId))) === null || _a === void 0 ? void 0 : _a.name}`
            : 'coworkermanagement.role'
    });
    return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles['title-group'] }, { children: _jsx("span", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "register.invite.join" }) })) })), _jsx("div", Object.assign({ className: styles['content'] }, { children: loading || hasError || !inviteData ? (_jsx("div", Object.assign({ className: styles['loading'] }, { children: _jsx(Loader, {}) }))) : (_jsx(_Fragment, { children: _jsx("p", { children: _jsx(Ditto, { componentId: "register.invite.info1", variables: {
                                        Username: `${inviteData.invitedBy
                                            ? `${inviteData.invitedBy.firstName} ${inviteData.invitedBy.lastName}`
                                            : 'vialytics'}`,
                                        TeamAccount: inviteData.accountName || '',
                                        UserRole: role || ''
                                    } }) }) })) })), hasError && (_jsxs("div", Object.assign({ className: classes(styles['error']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['icon']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Warning1, {}) })) })), _jsx(Ditto, { componentId: "toast.somethingwentwrong" })] })))] }), _jsx("div", Object.assign({ className: styles['buttons'] }, { children: _jsx(Button, Object.assign({ disabled: loading || hasError || !inviteData, variant: "modal-action", color: "dark", onClick: onAcceptInviteClick, "data-test": "accept-invite-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "register.invite.accept" }) })) })) }))] })));
}
