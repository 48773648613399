var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { PLAN_PLANNED_FOR_ID } from '../../common/consts/plan';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { planPlanningForFilters } from '../../common/planFilterTypes';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getTimeParams } from '../../common/utils/params';
import { getBudgetsBreakdown, getPlanList, getPlanTableSum, getTableViewSettings, removeTableViewProperty, removeTableViewSelectOption, saveTableViewSettings } from '../../services/plan';
import { addNotificationMessage } from './notification';
import { removePlan, updatePlan } from './plan';
import { fetchPlanFilters } from './shared';
const initialFilterPlan = {
    budgetIds: [],
    priorityIds: [],
    categoryIds: [],
    statusIds: [],
    treatmentIds: [],
    yearsOfUseIds: [],
    efficiencyIds: []
};
const planParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.plan;
const initialState = {
    resultList: [],
    totalCount: 0,
    // TODO: (suggestion) switch to same method as tasks/assets to improve performance
    firstThousandIds: [],
    resultFetchStatus: 'idle',
    sort: Object.assign({ sortBy: 'created_at', sortOrder: 'desc' }, planParams === null || planParams === void 0 ? void 0 : planParams.sort),
    filter: Object.assign(Object.assign({}, initialFilterPlan), planParams === null || planParams === void 0 ? void 0 : planParams.filter),
    selectedIdList: [],
    activeTabKey: (planParams === null || planParams === void 0 ? void 0 : planParams.activeTabKey) || 'filter',
    currentPage: (planParams === null || planParams === void 0 ? void 0 : planParams.currentPage) || PAGE_NUMBER_FIRST,
    useMapBounds: planParams === null || planParams === void 0 ? void 0 : planParams.useMapBounds,
    isMultiSelectOn: false,
    isSearchBarOn: false,
    table: {
        budgetsFetchStatus: 'idle',
        settings: null
    }
};
export const fetchPlans = createDebouncedAsyncThunk('leftPanelPlan/plan/fetchList', ({ fetchSumLine } = { fetchSumLine: true }, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c, _d;
    const state = getState();
    const { teamAccount } = state.auth;
    const _e = state.leftPanelPlan, { sort } = _e, _f = _e.filter, { completionYearAndMonth, customCompletionYearAndMonth } = _f, filter = __rest(_f, ["completionYearAndMonth", "customCompletionYearAndMonth"]), { useMapBounds, activeTabKey, currentPage, table: { budgetTabYear } } = _e;
    const { bounds } = state.mainMap;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    let completion;
    if (completionYearAndMonth) {
        if (completionYearAndMonth === PLAN_PLANNED_FOR_ID.custom) {
            completion = customCompletionYearAndMonth;
        }
        else {
            completion = (_b = planPlanningForFilters.find((item) => item.id === completionYearAndMonth)) === null || _b === void 0 ? void 0 : _b.value;
        }
    }
    const body = {
        filter: Object.assign(Object.assign({}, filter), { completionFrom: completion === null || completion === void 0 ? void 0 : completion.from, completionTo: completion === null || completion === void 0 ? void 0 : completion.to, completionIncludeNotSet: completionYearAndMonth === PLAN_PLANNED_FOR_ID.notspecified || undefined, priorityIncludeNotSet: ((_c = filter.priorityIds) === null || _c === void 0 ? void 0 : _c.includes(0)) || undefined, efficiencyIncludeNotSet: ((_d = filter.efficiencyIds) === null || _d === void 0 ? void 0 : _d.includes(0)) || undefined, bbox: getBBoxParams(bounds, useMapBounds), 
            // filter by selected budget tab in table view
            budgetIds: activeTabKey === 'table' && budgetTabYear !== undefined
                ? [budgetTabYear]
                : filter.budgetIds }),
        timepicker: getTimeParams(selectedOption),
        limit: ITEMS_PER_PAGE,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
        countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode
    };
    const [listResponse, tableSumResponse] = yield Promise.all([
        CancelableRequest.send(getPlanList, body),
        fetchSumLine
            ? CancelableRequest.send(getPlanTableSum, body)
            : Promise.resolve(undefined)
    ]);
    if (listResponse.errorCode === RESPONSE_ERROR_CODE.emptyError) {
        return { list: Object.assign(Object.assign({}, listResponse), { result: Object.assign(Object.assign({}, listResponse.result), { plans: null }) }) };
    }
    if (listResponse.errorCode !== RESPONSE_ERROR_CODE.success ||
        (fetchSumLine && (tableSumResponse === null || tableSumResponse === void 0 ? void 0 : tableSumResponse.errorCode) !== RESPONSE_ERROR_CODE.success)) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return {
        list: listResponse,
        tableSum: tableSumResponse
    };
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchTableViewSettings = createDebouncedAsyncThunk('leftPanelPlan/plan/fetchTableViewSettings', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    const state = getState();
    const { locale: language } = state.app;
    const countryCode = (_g = state.auth.teamAccount) === null || _g === void 0 ? void 0 : _g.countryCode;
    const res = yield getTableViewSettings({ language, countryCode });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const updateTableViewSettings = createAppAsyncThunk('leftPanelPlan/plan/updateTableViewSettings', ({ items, silentUpdate }, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    const state = getState();
    const { locale: language } = state.app;
    const countryCode = (_h = state.auth.teamAccount) === null || _h === void 0 ? void 0 : _h.countryCode;
    // TODO: loading state?
    const res = yield saveTableViewSettings({ items, language, countryCode });
    if (res.errorCode === RESPONSE_ERROR_CODE.success && !silentUpdate) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchPlanFilters());
    return res;
}));
export const deleteTableViewProperty = createAppAsyncThunk('leftPanelPlan/plan/deleteTableViewProperty', ({ id, silentUpdate }, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    // TODO: loading state?
    const res = yield removeTableViewProperty({ id });
    if (res.errorCode === RESPONSE_ERROR_CODE.success && !silentUpdate) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchPlanFilters());
    return Object.assign(Object.assign({}, res), { item: { id } });
}));
export const deleteTableViewSelectOption = createAppAsyncThunk('leftPanelPlan/plan/deleteTableViewSelectOption', ({ id, columnKey, silentUpdate }, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    // TODO: loading state?
    const res = yield removeTableViewSelectOption({ id, columnKey });
    if (res.errorCode === RESPONSE_ERROR_CODE.success && !silentUpdate) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchPlanFilters());
    return Object.assign(Object.assign({}, res), { item: { id, columnKey } });
}));
export const fetchBudgetsBreakdown = createDebouncedAsyncThunk('leftPanelPlan/plan/fetchBudgetsBreakdown', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getBudgetsBreakdown();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const leftPanelPlanSlice = createSlice({
    name: 'leftPanelPlan',
    initialState,
    reducers: {
        reset: () => initialState,
        setPlanFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setPlanSorting: (state, action) => {
            state.sort = action.payload;
        },
        setPlanSelectedIdList: (state, action) => {
            state.selectedIdList = action.payload;
        },
        startPlanMultiSelect: (state) => {
            state.isMultiSelectOn = true;
        },
        resetPlanMultiSelect: (state) => {
            state.isMultiSelectOn = false;
            state.selectedIdList = [];
        },
        startPlanSearchBar: (state) => {
            state.isSearchBarOn = true;
        },
        resetPlanSearchBar: (state) => {
            state.isSearchBarOn = false;
            state.filter = Object.assign(Object.assign({}, state.filter), { search: undefined });
        },
        resetPlanFilter: (state) => {
            state.filter = initialFilterPlan;
            state.table.budgetTabYear = undefined;
        },
        setPlanActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setPlanCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setFirstPage: (state) => {
            state.currentPage = 1;
        },
        setUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        setTableValue: (state, action) => {
            var _a, _b;
            state.table.form = {
                planId: action.payload.planId,
                thirdParty: (_b = (_a = state.table.form) === null || _a === void 0 ? void 0 : _a.thirdParty) !== null && _b !== void 0 ? _b : false,
                [action.payload.field]: action.payload.value
            };
        },
        setTableBudgetTabYear: (state, action) => {
            state.table.budgetTabYear = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPlans.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchPlans.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            state.resultFetchStatus = 'completed';
            if (((_b = (_a = action.payload.list) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.plans) !== undefined) {
                state.resultList = action.payload.list.result.plans;
            }
            if ((_d = (_c = action.payload.list) === null || _c === void 0 ? void 0 : _c.result) === null || _d === void 0 ? void 0 : _d.firstThousandIds) {
                state.firstThousandIds = (_e = action.payload.list) === null || _e === void 0 ? void 0 : _e.result.firstThousandIds;
            }
            state.totalCount = ((_g = (_f = action.payload) === null || _f === void 0 ? void 0 : _f.list.result) === null || _g === void 0 ? void 0 : _g.totalCount) || 0;
            if ((_h = action.payload.tableSum) === null || _h === void 0 ? void 0 : _h.result) {
                state.table.tableSum = action.payload.tableSum.result.columns.reduce((acc, item) => {
                    acc[item.columnKey] = item.value;
                    return acc;
                }, {});
            }
        })
            .addCase(removePlan.fulfilled, (state, action) => {
            if (action.payload.id && state.resultList) {
                state.resultList =
                    state.resultList.filter((plan) => plan.planId !== action.payload.id) ||
                        null;
                state.totalCount = state.totalCount - 1;
                if (state.totalCount > 0) {
                    state.resultList = state.resultList.filter((plan) => plan.planId !== action.payload.id);
                }
                else {
                    state.resultList = null;
                }
            }
        })
            .addCase(updatePlan.fulfilled, (state, action) => {
            var _a;
            const { result } = action.payload;
            if ((result === null || result === void 0 ? void 0 : result.newData) && state.resultList) {
                state.resultList = (_a = state.resultList) === null || _a === void 0 ? void 0 : _a.map((plan) => {
                    if (plan.planId === result.newData.planId) {
                        return result.newData;
                    }
                    return plan;
                });
            }
        })
            .addCase(deleteTableViewProperty.fulfilled, (state, action) => {
            if (state.table.settings) {
                state.table.settings = state.table.settings.filter((item) => item.id !== action.payload.item.id);
            }
        })
            .addCase(deleteTableViewSelectOption.fulfilled, (state, action) => {
            var _a;
            if (state.table.settings) {
                const columnIndex = state.table.settings.findIndex((item) => action.payload.item.columnKey === item.columnKey);
                state.table.settings[columnIndex].selectOptions = (_a = state.table.settings[columnIndex].selectOptions) === null || _a === void 0 ? void 0 : _a.filter((item) => item.id !== action.payload.item.id);
            }
        })
            .addCase(fetchBudgetsBreakdown.pending, (state) => {
            state.table.budgetsFetchStatus = 'loading';
        })
            .addCase(fetchBudgetsBreakdown.fulfilled, (state, action) => {
            state.table.budgetsFetchStatus = 'completed';
            state.table.budgets = action.payload.result;
        })
            .addMatcher(isAnyOf(fetchTableViewSettings.fulfilled, updateTableViewSettings.fulfilled), (state, action) => {
            var _a;
            let settings = (_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.settings;
            if (settings) {
                const tableSettings = settings.sort((a, b) => a.orderNumber - b.orderNumber);
                state.table.settings = [
                    ...tableSettings.filter(({ pinLeft }) => pinLeft),
                    ...tableSettings.filter(({ pinLeft }) => !pinLeft)
                ];
            }
        });
    }
});
export const { reset: resetLeftPanelPlan, setPlanFilter, setPlanSorting, setPlanSelectedIdList, startPlanMultiSelect, resetPlanMultiSelect, startPlanSearchBar, resetPlanSearchBar, resetPlanFilter, setPlanActiveTabKey, setPlanCurrentPage, setFirstPage, setUseMapBounds, setTableValue, setTableBudgetTabYear } = leftPanelPlanSlice.actions;
export default leftPanelPlanSlice.reducer;
