// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XIPRBrSn0YkuP3DKiFIb{margin-bottom:0px}@media print{.HY6R4WtH4VS1mzFuhyWJ{display:none}}.tx6EE3KYToeLQWu0EQtI{padding:2px;margin-top:6px;background:none;border:none;font-weight:600;font-size:14px;text-decoration:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/DamageGradingPanel/DamageGradingPanel.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,aACI,sBACI,YAAA,CAAA,CAIR,sBACI,WAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CACA,yBAAA,CACA,cAAA","sourcesContent":[".damage-grade {\n    margin-bottom: 0px;\n}\n\n@media print {\n    .expand-close {\n        display: none;\n    }\n}\n\n.expand-close-link {\n    padding: 2px;\n    margin-top: 6px;\n    background: none;\n    border: none;\n    font-weight: 600;\n    font-size: 14px;\n    text-decoration: underline;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"damage-grade": `XIPRBrSn0YkuP3DKiFIb`,
	"expand-close": `HY6R4WtH4VS1mzFuhyWJ`,
	"expand-close-link": `tx6EE3KYToeLQWu0EQtI`
};
export default ___CSS_LOADER_EXPORT___;
