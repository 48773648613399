var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, pathParams } from '../../common/fetch';
import { milkyWayApiClient } from '../../openapi';
const CONDITION_NODE_EDGE_ENDPOINT = 'v1/stats/streets/:id';
const CONDITION_NODE_EDGE_GEOJSON_ENDPOINT = 'v1/printing/map/preview/streets/:id';
export function getNodeEdge(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_NODE_EDGE_ENDPOINT, { id }));
    });
}
export function getEdgesByIdsList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServiceEdgesByIDsList(body);
    });
}
export function getNodeEdgeGeoJSON(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_NODE_EDGE_GEOJSON_ENDPOINT, { id }));
    });
}
