import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { FormInputSelect } from '../../../FormInputSelect/FormInputSelect';
import styles from './TrailingSelect.module.css';
const TRAILING_SELECT_WIDTH = 174;
const TRAILING_SELECT_MAX_HEIGHT = 330;
export function TrailingSelect({ className, value, width = TRAILING_SELECT_WIDTH, menuAlign = 'left', menuWidth, menuItems, variant = 'default' }) {
    return (_jsx(FormInputSelect, { canFloat: true, className: classes(styles['unit-select'], styles[`unit-select__${variant}`], className), size: "medium", value: _jsx("div", Object.assign({ className: styles['unit-select-value'] }, { children: value })), width: width, menuMaxHeight: TRAILING_SELECT_MAX_HEIGHT, menuWidth: menuWidth !== null && menuWidth !== void 0 ? menuWidth : width, menuItems: menuItems, disabled: variant === 'read-only', dropDownOffset: {
            top: 8,
            left: menuAlign === 'right' ? width - (menuWidth !== null && menuWidth !== void 0 ? menuWidth : width) : 0,
            right: 0
        } }));
}
