// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._1s5n1UjMCFXPjrlCZLe{white-space:pre-line}.UDCw5URWcNj_q1q2NHhf{overflow-y:auto;max-height:calc(95vh - 250px);display:flex;flex-direction:column;gap:24px}.ftefvZ1S6ZZ012WSYsQ6{max-height:95vh}`, "",{"version":3,"sources":["webpack://./src/components/Modals/InventoryTeaserModal/InventoryTeaserModal.module.css"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAGJ,sBACI,eAAA,CACA,6BAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,sBACI,eAAA","sourcesContent":[".description-text {\n    white-space: pre-line;\n}\n\n.content {\n    overflow-y: auto;\n    max-height: calc(95vh - 250px);\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.modal {\n    max-height: 95vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description-text": `_1s5n1UjMCFXPjrlCZLe`,
	"content": `UDCw5URWcNj_q1q2NHhf`,
	"modal": `ftefvZ1S6ZZ012WSYsQ6`
};
export default ___CSS_LOADER_EXPORT___;
