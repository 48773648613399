var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MAX_IMAGES_PER_UPLOAD } from '../common/consts/config';
import { selectImagesDialog } from '../common/selectImages';
import { useAppDispatch } from '../state/hooks';
import { addNotificationMessage } from '../state/slices/notification';
export const useImageUploadModal = () => {
    const dispatch = useAppDispatch();
    function handleSelectImagesError(e) {
        for (const fileError of e) {
            if (fileError.type === 'files_over_size') {
                dispatch(addNotificationMessage({
                    type: 'error',
                    ditto: {
                        title: {
                            componentId: 'toast.uploadmaximumsize',
                            variables: {
                                FileName: fileError.fileName,
                                Upload_Maximum_Size: fileError.value.maxSize
                            }
                        }
                    }
                }));
            }
            else {
                console.error('selectImagesDialog error', e);
            }
        }
    }
    function handleSelectImagesDone(images, cb) {
        if (images.length > MAX_IMAGES_PER_UPLOAD) {
            dispatch(addNotificationMessage({
                type: 'error',
                ditto: {
                    title: {
                        componentId: 'toast.selectmax10images',
                        variables: { Amount_Images: MAX_IMAGES_PER_UPLOAD }
                    }
                }
            }));
            return;
        }
        cb(images);
    }
    function selectConfirmPhotos(cb) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const newImages = yield selectImagesDialog();
                if (!newImages.images) {
                    return;
                }
                handleSelectImagesDone(newImages.processedImages, cb);
            }
            catch (e) {
                handleSelectImagesError(e);
            }
        });
    }
    return { selectConfirmPhotos };
};
