import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ActionButton } from '../../ActionButton/ActionButton';
import { Reset, Search as SearchIcon } from '../../icons';
import styles from './Search.module.css';
export function Search({ placeholder, value, onChange, suggestions }) {
    return (_jsxs("div", { children: [suggestions && (_jsx("div", Object.assign({ className: styles['anchor'] }, { children: _jsx("div", Object.assign({ className: styles['menu-container'], "data-test": "search-result-container" }, { children: suggestions })) }))), _jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("span", Object.assign({ className: styles['search-icon'] }, { children: _jsx(SearchIcon, {}) })), _jsx("input", { className: styles['input'], type: "text", placeholder: placeholder, value: value, onChange: (e) => onChange && onChange(e.target.value), autoFocus: true, "data-test": "search-input" }), value && (_jsx("span", Object.assign({ className: styles['reset-icon'] }, { children: _jsx(ActionButton, { icon: _jsx(Reset, {}), tooltip: {
                                text: _jsx(Ditto, { componentId: "tooltip.deletequery" })
                            }, onClick: () => onChange && onChange(''), "data-test": "search-input-clear" }) })))] }))] }));
}
