// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EoAoxTtSudWQvMcLiBP2{position:absolute;left:0;right:0;top:0;bottom:0;background:rgba(34,45,57,.6);display:flex;align-items:center;justify-content:center;z-index:var(--zindex-modal-backdrop);overflow:hidden;outline:none}.lJ4P7FVjcUoHHjLs0M5d{z-index:1060}`, "",{"version":3,"sources":["webpack://./src/components/ModalOverlay/ModalOverlay.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CAGA,4BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,oCAAA,CACA,eAAA,CAEA,YAAA,CAGJ,sBACI,YAAA","sourcesContent":[".container {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n\n    /* Primary/Dark-blue 60% */\n    background: rgba(34, 45, 57, 0.6);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: var(--zindex-modal-backdrop);\n    overflow: hidden;\n\n    outline: none;\n}\n\n.over-gallery {\n    z-index: 1060;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EoAoxTtSudWQvMcLiBP2`,
	"over-gallery": `lJ4P7FVjcUoHHjLs0M5d`
};
export default ___CSS_LOADER_EXPORT___;
