var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
function calculateSignPosition(tag, container) {
    const { xmin, xmax, ymin, ymax } = tag;
    return {
        x: -(xmin + xmax) / 2 + container.width / 2,
        y: -(ymin + ymax) / 2 + container.height / 2
    };
}
export function ImageCrop(_a) {
    var { croppedImageUrl, tag, imageSize } = _a, props = __rest(_a, ["croppedImageUrl", "tag", "imageSize"]);
    if (croppedImageUrl) {
        return (_jsx("img", Object.assign({}, props, { src: croppedImageUrl, style: Object.assign(Object.assign({}, imageSize), { objectFit: 'contain' }) })));
    }
    const p = calculateSignPosition(tag, imageSize);
    return (_jsx("img", Object.assign({}, props, { style: Object.assign(Object.assign({}, imageSize), { objectFit: 'none', objectPosition: `${p.x}px ${p.y}px` }) })));
}
