var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getPointImageUrl } from '../../services/condition';
import { addNotificationMessage } from './notification';
const initialState = {};
export const fetchPointImgData = createAppAsyncThunk('imageViewerCondition/fetchPointImageData', (pointUUID, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const res = yield getPointImageUrl(pointUUID, {
        preview: false
    });
    if (res.errorCode || !((_a = res.result) === null || _a === void 0 ? void 0 : _a.image_url)) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const imageViewerConditionSlice = createSlice({
    name: 'imageViewerCondition',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder.addCase(fetchPointImgData.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.imageURL = action.payload.result.image_url;
            }
        });
    }
});
export const { reset: resetImageViewerCondition } = imageViewerConditionSlice.actions;
export default imageViewerConditionSlice.reducer;
