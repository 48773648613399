import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setLocalStoragePartial } from '../common/localStorage';
import { stringifySearch } from '../common/url';
import { isDeepEqualByStringify } from '../common/utils/object';
export const listener = createListenerMiddleware();
listener.startListening({
    predicate: (action, currentState, previousState) => {
        return !isDeepEqualByStringify(getURLParamsByReduxState(currentState), getURLParamsByReduxState(previousState));
    },
    effect: (action, api) => {
        const state = api.getState();
        const newParams = getURLParamsByReduxState(state);
        history.pushState({}, '', `${location.pathname}${stringifySearch(newParams)}`);
        document.documentElement.lang = state.app.locale;
        const targetSchema = state.auth.targetSchema;
        if (targetSchema) {
            setLocalStoragePartial({ targetSchema });
        }
    }
});
function getURLParamsByReduxState(state) {
    const enabledLayerIds = state.layer.layerList.filter((l) => l.isEnabled).map((l) => l.id);
    const params = {
        q: {
            layerIds: enabledLayerIds,
            plan: {
                sort: state.leftPanelPlan.sort,
                filter: state.leftPanelPlan.filter,
                activeTabKey: state.leftPanelPlan.activeTabKey,
                useMapBounds: state.leftPanelPlan.useMapBounds,
                currentPage: state.leftPanelPlan.currentPage
            },
            marker: {
                filter: state.leftPanelMarker.filter,
                sort: state.leftPanelMarker.sort,
                activeTabKey: state.leftPanelMarker.activeTabKey,
                useMapBounds: state.leftPanelMarker.useMapBounds,
                currentPage: state.leftPanelMarker.currentPage
            },
            asset: {
                filter: state.leftPanelAsset.filter,
                sort: state.leftPanelAsset.sort,
                activeTabKey: state.leftPanelAsset.activeTabKey,
                useMapBounds: state.leftPanelAsset.useMapBounds,
                currentPage: state.leftPanelAsset.currentPage
            },
            condition: {
                selectedFeatureType: state.leftPanelCondition.selectedFeatureType,
                filter: state.leftPanelCondition.filter,
                sort: state.leftPanelCondition.sort,
                activeTabKey: state.leftPanelCondition.activeTabKey
            },
            alert: {
                filter: state.leftPanelAlert.filter,
                sort: state.leftPanelAlert.sort
            },
            object: {
                filter: state.leftPanelObject.filter,
                sort: state.leftPanelObject.sort
            },
            trackControl: {
                filter: state.leftPanelTrackControl.filter,
                sort: state.leftPanelTrackControl.sort
            },
            winterService: {
                filter: state.leftPanelWinterService.filter,
                sort: state.leftPanelWinterService.sort,
                activeTabKey: state.leftPanelWinterService.activeTabKey
            },
            sign: {
                filter: state.leftPanelSign.filter,
                sort: state.leftPanelSign.sort
            },
            signInventory: {
                filter: state.leftPanelSignInventory.filter,
                sort: state.leftPanelSignInventory.sort
            },
            timepickerSelectedOption: state.presetsPanel.timepicker.selectedOption,
            selectedFeature: state.feature.selectedFeature,
            mapView: state.mainMap.viewState,
            currentModalId: state.app.currentModalId,
            globalSettings: {
                currentPageId: state.globalSettings.currentPageId
            }
        },
        targetSchema: state.auth.targetSchema
    };
    return params;
}
