import { useCallback, useState } from 'react';
import { useMap } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { MAP_MARKER_CLICK_ZOOM } from '../../common/consts/map';
import { alertsHighlightLayer, alertsHoverLayer, alertsLayer, alertsSelectedLayer, markersHighlightedLayer, markersHoveredLayer, markersLayer, markersSelectedLayer, noteLayer, objectsHighlightLayer, objectsHoverLayer, objectsLayer, objectsSelectedLayer, plansFillLayer, plansOutlineLayer, pointsFilteredOutLayer, pointsLayer, referencingFlagHoveredLayer, referencingFlagLayer, segmentsFilteredOutLayer, segmentsJunctionsLayer, segmentsLayer, segmentsSelectedLayer, trackControlLayer, trackControlSelectedLayer, trafficSignsHighlightLayer, trafficSignsHoverLayer, trafficSignsLayer, trafficSignsSelectedLayer, winterServiceLayer, winterServiceSelectedLayer } from '../../common/mapbox';
import { signInventoryHighlightLayer, signInventoryHoverLayer, signInventoryLayer, signInventoryPoleLayer, signInventorySelectedLayer } from '../../common/mapbox';
import { assetHighlightLayer, assetHoverLayer, assetLayer, assetSelectedLayer } from '../../common/mapbox/layers/assetLayer';
const possibleInteractiveLayerIds = [
    pointsLayer.id,
    referencingFlagLayer.id,
    referencingFlagHoveredLayer.id,
    noteLayer.id,
    segmentsLayer.id,
    plansFillLayer.id,
    plansOutlineLayer.id,
    alertsLayer.id,
    objectsLayer.id,
    objectsHoverLayer.id,
    trackControlLayer.id,
    markersLayer.id,
    trafficSignsLayer.id,
    winterServiceLayer.id,
    signInventoryLayer.id,
    assetLayer.id
];
// from top to bottom
const mapboxLayerOrder = [
    //
    noteLayer.id,
    //
    markersSelectedLayer.id,
    markersHoveredLayer.id,
    markersHighlightedLayer.id,
    markersLayer.id,
    //
    alertsSelectedLayer.id,
    alertsHighlightLayer.id,
    alertsHoverLayer.id,
    alertsLayer.id,
    //
    referencingFlagHoveredLayer.id,
    referencingFlagLayer.id,
    //
    assetSelectedLayer.id,
    assetHighlightLayer.id,
    assetHoverLayer.id,
    assetLayer.id,
    //
    trafficSignsSelectedLayer.id,
    trafficSignsHighlightLayer.id,
    trafficSignsHoverLayer.id,
    trafficSignsLayer.id,
    //
    signInventorySelectedLayer.id,
    signInventoryHighlightLayer.id,
    signInventoryHoverLayer.id,
    signInventoryLayer.id,
    //
    signInventoryPoleLayer.id,
    //
    objectsSelectedLayer.id,
    objectsHighlightLayer.id,
    objectsHoverLayer.id,
    objectsLayer.id,
    //
    'road-label',
    //
    segmentsJunctionsLayer.id,
    segmentsLayer.id,
    segmentsSelectedLayer.id,
    segmentsFilteredOutLayer.id,
    //
    pointsLayer.id,
    pointsFilteredOutLayer.id,
    //
    plansFillLayer.id,
    plansOutlineLayer.id,
    //
    trackControlLayer.id,
    trackControlSelectedLayer.id,
    //
    winterServiceLayer.id,
    winterServiceSelectedLayer.id
];
export function useMainMap() {
    const { main: map } = useMap();
    const [searchMarker, setSearchMarker] = useState();
    const interactiveLayerIds = (function () {
        if (!map) {
            return [];
        }
        return possibleInteractiveLayerIds.filter((id) => map.getLayer(id));
    })();
    const repositionDraft = useCallback((position) => {
        if (!map) {
            return;
        }
        if (map.getBounds().contains(position)) {
            return;
        }
        map.flyTo({ center: position });
    }, [map]);
    const moveIntoView = useCallback((position) => {
        if (!map) {
            return;
        }
        if (map.getBounds().contains(position)) {
            return;
        }
        map.flyTo({ center: position });
    }, [map]);
    function createSearchMarker(center) {
        removeSearchMarker();
        if (map) {
            const marker = new mapboxgl.Marker({ color: 'orange' })
                .setLngLat(center)
                .addTo(map.getMap());
            map.flyTo({ center, zoom: MAP_MARKER_CLICK_ZOOM });
            setSearchMarker(marker);
        }
    }
    function removeSearchMarker() {
        searchMarker === null || searchMarker === void 0 ? void 0 : searchMarker.remove();
        setSearchMarker(undefined);
    }
    function getBeforeId(layerId) {
        if (!map) {
            return;
        }
        const roadLabelIdx = mapboxLayerOrder.findIndex((id) => id === 'road-label');
        const idx = mapboxLayerOrder.findIndex((id) => id === layerId);
        if (roadLabelIdx < idx) {
            return 'road-label';
        }
        for (let i = idx - 1; i >= 0; i--) {
            if (map.getLayer(mapboxLayerOrder[i])) {
                return mapboxLayerOrder[i];
            }
        }
    }
    return {
        createSearchMarker,
        removeSearchMarker,
        repositionDraft,
        moveIntoView,
        interactiveLayerIds,
        getBeforeId
    };
}
