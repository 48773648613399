import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TASKS_TAB_INDEX } from '../../common/consts/marker';
import { MissingCostsDataModal } from '../../components/Modals/MissingCostsDataModal/MissingCostsDataModal';
import { useModalContext } from '../../state/context';
import { useAppDispatch } from '../../state/hooks';
import { setMarkerCurrentTab } from '../../state/slices/markerDetail';
export function MissingCostsDataModalContainer() {
    const dispatch = useAppDispatch();
    const { removeModal } = useModalContext();
    function closeModal() {
        removeModal('MissingCostsDataModalContainer');
    }
    function handleYesClick() {
        dispatch(setMarkerCurrentTab(TASKS_TAB_INDEX.costs));
        closeModal();
    }
    function handleNoClick() {
        closeModal();
    }
    return _jsx(MissingCostsDataModal, { onYesClick: handleYesClick, onNoClick: handleNoClick });
}
