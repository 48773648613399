var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { MAP_CURSOR, MAP_VIA_LAT, MAP_VIA_LNG } from '../../common/consts/map';
import { MIXPANEL_EVENT_VALUE } from '../../common/consts/mixpanel';
import { SIGN_DAMAGE_ID, SIGN_DAMAGE_NAMES, SIGN_INVENTORY_SIGNS_TO_SHOW } from '../../common/consts/trafficSign';
import { ASSET_CREATOR_FORM_WIDTH } from '../../common/consts/width';
import { getBastId } from '../../common/convert/signInventory';
import { AddressSearch } from '../../components/AddressSearch/AddressSearch';
import { OrientationField } from '../../components/OrientationField/OrientationField';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { TrafficSignCreator } from '../../components/TrafficSignCreator/TrafficSignCreator';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useSignFields } from '../../hooks/forms/useSignFields';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { useRotatableFeature } from '../../hooks/useRotatableFeature';
import { useSignInventoryFiles } from '../../hooks/useSignInventoryFiles';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setActionbarHorizontalOffset } from '../../state/slices/actionbar';
import { setCurrentAction } from '../../state/slices/app';
import { clearDraftPin, clearLastRotation, clearRotatableFeature, setDraftPinContent, setMapCursor, setRotatableFeature } from '../../state/slices/mainMap';
import { clearFetchedAddress, fetchAddress, resetSharedDataHolder } from '../../state/slices/sharedDataHolder';
import { createSignTk } from '../../state/slices/signCreator';
export function TrafficSignCreatorContainer() {
    const dispatch = useAppDispatch();
    const { center, draftPin, rotatableFeature } = useAppSelector((state) => state.mainMap);
    const { main: map } = useMap();
    const { horizontalOffset: actionbarHorizontalOffset } = useAppSelector((state) => state.actionbar);
    const { addModal } = useModalContext();
    const rootRef = useRef(null);
    const returnToCenter = useRef(center);
    const returnToActionbarOffset = useRef(actionbarHorizontalOffset);
    const { signTree, signInventoryDamageTypes } = useAppSelector((state) => state.shared);
    const { createStatus } = useAppSelector((state) => state.signCreator);
    const [matchingSignTree, setMatchingSignTree] = useState(signTree);
    const [selectedType, setSelectedType] = useState('');
    const { formatAddress } = useFormatter();
    const { updateSignFields, signFields, getSignDraft, getCustomPropertiesFields, updateSignFiles } = useSignFields({
        position: { lng: MAP_VIA_LNG, lat: MAP_VIA_LAT },
        condition: {
            id: SIGN_DAMAGE_ID.not_damaged,
            name: SIGN_DAMAGE_NAMES.not_damaged
        }
    });
    const { filesSection } = useSignInventoryFiles({
        mode: 'create',
        signFields,
        onGetUuids: handleGetUuids
    });
    const { isAdmin } = useUserRole();
    const [bastId, setBastId] = useState('');
    const { handleAddressSearchChange, handleAddressSuggestionClick, fetchedAddress, fetchedSuggestions, addressSearchStatus } = useAddressSearch();
    const signDamageTypesToShow = signInventoryDamageTypes.filter((t) => SIGN_INVENTORY_SIGNS_TO_SHOW.includes(t.id));
    const { enableRotate, disableRotate } = useRotatableFeature();
    const [isRotating, setIsRotating] = useState(false);
    function handleSelectType(type) {
        setSelectedType(type);
        updateSignFields({ subSpecifiedClass: type });
        setBastId(getBastId(signTree, type));
        dispatch(setDraftPinContent({ type: 'sign', icon: type }));
    }
    useEffect(() => {
        if (draftPin &&
            draftPin.lat !== signFields.position.lat &&
            draftPin.lng !== signFields.position.lng) {
            updateSignFields({
                position: draftPin
            });
            dispatch(fetchAddress(draftPin));
        }
    }, [dispatch, draftPin, signFields.position, updateSignFields]);
    useEffect(() => {
        if ((rotatableFeature === null || rotatableFeature === void 0 ? void 0 : rotatableFeature.type) !== 'sign-inventory-creator') {
            return;
        }
        const orientation = Math.round(rotatableFeature.value.orientation);
        updateSignFields({
            orientation
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rotatableFeature]);
    useEffect(() => {
        setMatchingSignTree(signTree);
    }, [signTree]);
    useEffect(() => {
        var _a, _b;
        dispatch(setActionbarHorizontalOffset(-Math.round(((_b = (_a = rootRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0) / 2)));
    }, [map, draftPin, rootRef, dispatch]);
    function handleClickSignCreate() {
        return __awaiter(this, void 0, void 0, function* () {
            const signDraft = getSignDraft();
            if (signDraft) {
                dispatch(createSignTk(Object.assign(Object.assign({}, signDraft), { address: fetchedAddress, tracking: {
                        signCreatedBy: MIXPANEL_EVENT_VALUE.signCreatedBySignCreator
                    } })));
                dispatch(clearFetchedAddress());
                dispatch(clearDraftPin());
                dispatch(setMapCursor(MAP_CURSOR.default));
                dispatch(clearLastRotation());
            }
        });
    }
    function handleClickClose() {
        dispatch(setCurrentAction('none'));
        dispatch(setMapCursor(MAP_CURSOR.default));
        dispatch(setActionbarHorizontalOffset(returnToActionbarOffset.current));
        dispatch(clearDraftPin());
        dispatch(resetSharedDataHolder());
        dispatch(clearLastRotation());
        map === null || map === void 0 ? void 0 : map.easeTo({
            center: returnToCenter.current,
            padding: { top: 0, right: 0, bottom: 0, left: 0 }
        });
    }
    function handleSearchValueChange(value) {
        if (!value) {
            setMatchingSignTree(signTree);
            return;
        }
        const matchingGroups = [];
        for (const group of signTree) {
            if (!group.items) {
                continue;
            }
            const matchingItems = [];
            for (const signType of group.items) {
                if (signType.abbreviation.toLowerCase().includes(value.toLowerCase()) ||
                    signType.name.toLowerCase().includes(value.toLowerCase()) ||
                    signType.bastId.toLowerCase().includes(value.toLowerCase())) {
                    matchingItems.push(signType);
                }
            }
            if (matchingItems.length > 0) {
                matchingGroups.push({
                    id: group.id,
                    name: group.name,
                    items: matchingItems
                });
            }
        }
        setMatchingSignTree(matchingGroups);
    }
    function handleSignTypeSelectFieldClick() {
        addModal({
            id: 'SignTypeSelectModalContainer',
            props: { onSelectedTypeChange: handleSelectType }
        });
    }
    function handleEditPropertiesClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'sign-inventory-properties',
                modalProps: {
                    type: 'sign-inventory-properties'
                }
            }
        });
    }
    function handleGetUuids(folderId, uuids) {
        updateSignFiles(folderId, uuids);
    }
    function handleOrientationClick() {
        if (!draftPin || !map) {
            return;
        }
        const signDraft = getSignDraft();
        if (map.getBearing() !== 0) {
            map.rotateTo(0, { duration: 500 });
        }
        setIsRotating(true);
        enableRotate({
            type: 'sign-inventory-creator',
            value: {
                offsetPosition: [signFields.position.lng, signFields.position.lat],
                orientation: signDraft.orientation || 0
            }
        });
    }
    function handleOrientationBlur(newOrientation) {
        if (!draftPin) {
            return;
        }
        setIsRotating(false);
        disableRotate();
        if ((rotatableFeature === null || rotatableFeature === void 0 ? void 0 : rotatableFeature.type) !== 'sign-inventory-creator') {
            return;
        }
        dispatch(setRotatableFeature({
            type: 'sign-inventory-creator',
            value: { offsetPosition: [draftPin.lng, draftPin.lat], orientation: newOrientation }
        }));
        updateSignFields({
            orientation: newOrientation
        });
        dispatch(clearRotatableFeature());
    }
    const orientationField = (_jsx(OrientationField, { orientation: signFields.orientation, onClick: handleOrientationClick, onBlur: handleOrientationBlur, isRotating: isRotating }));
    return (_jsx(TrafficSignCreator, { ref: rootRef, onClickCreate: handleClickSignCreate, onClickClose: handleClickClose, onSearchValueChange: handleSearchValueChange, searchResults: matchingSignTree, selectedType: selectedType, onSelectType: handleSelectType, filesSection: filesSection, propertiesSection: getCustomPropertiesFields('create'), onEditPropertiesClick: handleEditPropertiesClick, addressField: _jsx(_Fragment, { children: _jsx(AddressSearch, Object.assign({ value: formatAddress(fetchedAddress), width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleAddressSearchChange, status: addressSearchStatus }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatAddress(s.address, { isShort: true }), secondaryText: formatAddress(s.address, {
                        cityAndCountry: true
                    }), onClick: () => handleAddressSuggestionClick(s) }, `${s.position.lat}-${s.position.lng}`))) })) }), orientationField: orientationField, conditionField: { items: signDamageTypesToShow, current: signFields.condition }, onChange: updateSignFields, onSignTypeSelectFieldClick: handleSignTypeSelectFieldClick, bastId: bastId, disableCreateButton: createStatus === 'loading', isAdmin: isAdmin }));
}
