// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TBISAqLGbISb1pVivtBQ{display:flex;flex-direction:column;align-items:flex-start;padding:8px;gap:8px;width:260px;font-size:14px;line-height:130%;color:var(--primary-white)}.cuB4dCuZGYnXPo9FCry1{display:flex;flex-direction:row;align-items:center;gap:8px;height:30px}.CRQhZ1qBEHhcp84Rbo9F{font-size:12px;line-height:14px;opacity:.6}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/RoadWork/RoadWork.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,WAAA,CACA,OAAA,CAEA,WAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 8px;\n    gap: 8px;\n\n    width: 260px;\n    font-size: 14px;\n    line-height: 130%;\n    color: var(--primary-white);\n}\n\n.row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 8px;\n    height: 30px;\n}\n\n.footer {\n    font-size: 12px;\n    line-height: 14px;\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TBISAqLGbISb1pVivtBQ`,
	"row": `cuB4dCuZGYnXPo9FCry1`,
	"footer": `CRQhZ1qBEHhcp84Rbo9F`
};
export default ___CSS_LOADER_EXPORT___;
