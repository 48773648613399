// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BilPS1mcfbaxqbgWJ12t{display:flex;flex-direction:row;align-items:center;align-self:stretch;gap:8px}.Shwpl3vSUKFTYndr7lHM{display:flex;flex-direction:row;align-items:center;overflow:hidden;gap:8px;width:100%}.eVycZhiMXHJrqaaDn4MT{width:24px}.XGW_oOQeyZUE5rbCK1Ig{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.D8g1MCuaLw0yQ3KxJifc{display:flex;flex-direction:row;align-items:flex-start;padding:7px 12px;height:30px;width:40px;background:var(--status-hover-hover-dark);border-radius:6px;font-weight:600;font-size:14px;line-height:16px;text-align:center;color:var(--primary-white)}`, "",{"version":3,"sources":["webpack://./src/uikit/MultipleItemOverflow/MultipleItemOverflow.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,OAAA,CACA,UAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,eAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CACA,yCAAA,CACA,iBAAA,CAEA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,0BAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    align-self: stretch;\n    gap: 8px;\n}\n\n.item {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    overflow: hidden;\n    gap: 8px;\n    width: 100%;\n}\n\n.icon {\n    width: 24px;\n}\n\n.label {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.badge {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 7px 12px;\n    height: 30px;\n    width: 40px;\n    background: var(--status-hover-hover-dark);\n    border-radius: 6px;\n\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    text-align: center;\n    color: var(--primary-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BilPS1mcfbaxqbgWJ12t`,
	"item": `Shwpl3vSUKFTYndr7lHM`,
	"icon": `eVycZhiMXHJrqaaDn4MT`,
	"label": `XGW_oOQeyZUE5rbCK1Ig`,
	"badge": `D8g1MCuaLw0yQ3KxJifc`
};
export default ___CSS_LOADER_EXPORT___;
