var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function addImage(map, imageName, fileName) {
    if (!map.hasImage(imageName)) {
        // TODO: Sometimes icons don't appear unless you move the map. Need to call map.triggerRepaint() once all images are loaded.
        map.loadImage(fileName, (error, image) => {
            if (error) {
                throw error;
            }
            if (!map.hasImage(imageName) && image !== undefined) {
                map.addImage(imageName, image);
            }
        });
    }
}
export function addLazyLoadedImage(map, imageName, fileName) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            map.loadImage(fileName, (error, image) => {
                if (error) {
                    reject(error);
                    return;
                }
                if (image === undefined) {
                    reject('image undefined');
                    return;
                }
                // Remove placeholder image
                map.removeImage(imageName);
                // Add real image
                map.addImage(imageName, image);
                resolve();
            });
        });
    });
}
export function getMapImages(names) {
    return names.map((v) => ({
        image: v,
        file: `/${v}.png`
    }));
}
