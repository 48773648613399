export const FEATURE_STATE_HOVER = 'hover';
export const FEATURE_STATE_SELECTED = 'selected';
export const FEATURE_STATE_FILTERED_OUT = 'filtered-out';
export const FEATURE_STATE_HIGHLIGHT = 'highlight';
export const hoverState = {
    [FEATURE_STATE_HOVER]: true
};
export const selectedState = {
    [FEATURE_STATE_SELECTED]: true
};
export const filteredOutState = {
    [FEATURE_STATE_FILTERED_OUT]: true
};
export const highlightState = {
    [FEATURE_STATE_HIGHLIGHT]: true
};
