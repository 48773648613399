import { useDittoComponent } from 'ditto-react';
export function getDittoComponentId(props) {
    return props.componentId + (props.useUsVariant ? '_us' : '');
}
/**
 * Get a pure string, can be useful for placeholders, otherwise you may use Ditto component.
 * inline syntax is easier to follow
 */
export function useDittoWrapper(props) {
    let componentId = getDittoComponentId(props);
    let text = useDittoComponent(Object.assign(Object.assign({}, props), { componentId }));
    return text || props.componentId;
}
