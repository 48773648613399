// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bciwefTu_qjEAO0g6T_Q{text-align:center}.NJEe3lEeGJ5MBIw59jMg{display:flex;gap:8px;flex-direction:row;align-items:center;justify-content:center;white-space:nowrap}.P9gSUR8kHsfoVHJr7ZXQ{display:flex;flex-direction:column}.eTXRAHTroHVr6ZkZ7m2T{border-bottom:1px solid var(--primary-white)}.TdFr4trZlovdu7xgvhPk{margin-top:4px;opacity:.6;text-align:left}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/EfficiencyTooltipContent/EfficiencyTooltipContent.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,YAAA,CACA,OAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CAGJ,sBACI,4CAAA,CAGJ,sBACI,cAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".wrapper {\n    text-align: center;\n}\n\n.top {\n    display: flex;\n    gap: 8px;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    white-space: nowrap;\n}\n\n.equation {\n    display: flex;\n    flex-direction: column;\n}\n\n.equation__top {\n    border-bottom: 1px solid var(--primary-white);\n}\n\n.bottom {\n    margin-top: 4px;\n    opacity: 0.6;\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `bciwefTu_qjEAO0g6T_Q`,
	"top": `NJEe3lEeGJ5MBIw59jMg`,
	"equation": `P9gSUR8kHsfoVHJr7ZXQ`,
	"equation__top": `eTXRAHTroHVr6ZkZ7m2T`,
	"bottom": `TdFr4trZlovdu7xgvhPk`
};
export default ___CSS_LOADER_EXPORT___;
