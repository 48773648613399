// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pLtHAl9zAO83OlET8xrD{display:flex;align-items:center;justify-content:space-between;width:100%;padding:0 6px}.epteKVTXChth6e5ulKZw{display:flex;align-items:center;gap:8px}.UPjl3JAejTphDTAAi24F{opacity:.4}.VqnwRfjW0FsOSYjiyCz4{margin-left:auto}.R_LdoxqXKRaxGXKQGir8{opacity:.4;display:flex;flex-direction:row;align-items:center}`, "",{"version":3,"sources":["webpack://./src/uikit/RadioSelectbox/RadioSelectbox.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    padding: 0 6px;\n}\n\n.left {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.count {\n    opacity: 0.4;\n}\n\n.trailing-anchor {\n    margin-left: auto;\n}\n\n.trailing-element {\n    opacity: 0.4;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pLtHAl9zAO83OlET8xrD`,
	"left": `epteKVTXChth6e5ulKZw`,
	"count": `UPjl3JAejTphDTAAi24F`,
	"trailing-anchor": `VqnwRfjW0FsOSYjiyCz4`,
	"trailing-element": `R_LdoxqXKRaxGXKQGir8`
};
export default ___CSS_LOADER_EXPORT___;
