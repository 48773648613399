import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { ALERT_COLOR } from '../../common/color';
import { INSPECTION_MODE_ID } from '../../common/consts/inspection';
import { getPlanCategoryVignette, getPlanPriorityIcon, getPlanPriorityName, getPlanStatusLabel, getPlanStatusVignette, getSignIcon, getSignTranslation } from '../../common/convert';
import { getSignFromSignTree, getSignTypeIcon } from '../../common/convert/signInventory';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { GradeBadge } from '../../components/GradeBadge/GradeBadge';
import { IconText } from '../../components/IconText/IconText';
import { List } from '../../components/MapPopover/List/List';
import { MapPopover } from '../../components/MapPopover/MapPopover';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useObjectAlertDetails } from '../../hooks/useObjectAlertDetails';
import { useAppSelector } from '../../state/hooks';
import { NoteDetailContainer } from '../NoteDetailContainer/NoteDetailContainer';
export function MapPopoverHoverContainer({ direction }) {
    var _a, _b, _c, _d;
    const { hoverData } = useAppSelector((state) => state.mapPopover);
    const { hoveredFeature } = useAppSelector((state) => state.feature);
    const { planFilters, markerCategoryList, signTree, assetTypeMap } = useAppSelector((state) => state.shared);
    const [content, setContent] = useState(hoverData);
    useEffect(() => {
        if (content !== hoverData && typeof hoverData !== 'undefined') {
            setContent(hoverData);
        }
    }, [content, hoverData, hoveredFeature]);
    let feature;
    switch (content === null || content === void 0 ? void 0 : content.type) {
        case 'point':
        case 'object':
        case 'alert':
        case 'node-edge':
            feature = content;
            break;
    }
    const objectAlertDetails = useObjectAlertDetails({ feature });
    const dateTimeFormatter = useDateTimeFormatter();
    const { formatAddress } = useFormatter();
    const { isEditableTrafficSignsEnabled } = useLayerChecker();
    if (!content) {
        return _jsx(MapPopover, { direction: direction, content: { type: 'loader' } });
    }
    const data = content;
    switch (data.type) {
        case 'point':
        case 'node-edge':
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'point',
                    value: {
                        imageURL: data.value.image_thumbnail_url,
                        title: data.value.display_name,
                        badge: ((_a = data.value.grade) === null || _a === void 0 ? void 0 : _a.emi) && (_jsx(GradeBadge, { grade: data.value.grade.emi }))
                    }
                } }));
        case 'referencing-flag': {
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'referencing-flag',
                    value: {
                        flag: data.value.flag
                    }
                } }));
        }
        case 'note': {
            return _jsx(NoteDetailContainer, { direction: direction, variant: "hover" });
        }
        case 'alert': {
            const shownAlert = objectAlertDetails === null || objectAlertDetails === void 0 ? void 0 : objectAlertDetails.statusItems.filter((f) => f.color === ALERT_COLOR)[0];
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'point',
                    value: {
                        imageURL: data.value.image_thumbnail_url,
                        title: shownAlert && shownAlert.content,
                        badge: ((_b = data.value.grade) === null || _b === void 0 ? void 0 : _b.emi) && (_jsx(GradeBadge, { grade: (_c = data.value.grade) === null || _c === void 0 ? void 0 : _c.emi }))
                    }
                } }));
        }
        case 'object': {
            const shownObject = objectAlertDetails === null || objectAlertDetails === void 0 ? void 0 : objectAlertDetails.typeItems[0];
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'point',
                    value: {
                        imageURL: data.value.image_thumbnail_url,
                        title: data.value.display_name,
                        badge: (_jsx(CategoryBadge, { category: shownObject ? shownObject.content : '-', backgroundColor: "rgba(255, 255, 255, 0.15)", color: "#FFFFFF" }))
                    }
                } }));
        }
        case 'marker': {
            const category = markerCategoryList.find((s) => data.value.categoryId === s.categoryId);
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'marker',
                    value: {
                        imageURL: (_d = data.value.photos[0]) === null || _d === void 0 ? void 0 : _d.thumbUrl,
                        title: data.value.title || formatAddress(data.value.addressJson),
                        badge: category && (_jsx(CategoryBadge, { category: category.categoryName, backgroundColor: category.color }))
                    }
                } }));
        }
        case 'track-control':
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'track-control',
                    value: {
                        time: dateTimeFormatter(data.value.createdAt).dateTime,
                        driver: data.value.userName,
                        inspectionModeId: INSPECTION_MODE_ID.trackControl
                    }
                } }));
        case 'plan': {
            const treatment = planFilters.treatment.find((m) => { var _a; return m.id === ((_a = data === null || data === void 0 ? void 0 : data.value) === null || _a === void 0 ? void 0 : _a.treatmentId); });
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'planned-segment',
                    value: (_jsxs(List, Object.assign({ width: "244px" }, { children: [_jsx(IconText, { icon: data.value.priority
                                    ? getPlanPriorityIcon(getPlanPriorityName(data.value.priority))
                                    : undefined, text: data.value.name, trailingElement: _jsx(GradeBadge, { grade: data.value.grade }) }), _jsx(IconText, { icon: data.value.categoryId
                                    ? getPlanCategoryVignette(data.value.categoryId)
                                    : undefined, text: (treatment === null || treatment === void 0 ? void 0 : treatment.name) || '-' }), _jsx(IconText, { icon: getPlanStatusVignette(data.value.statusId), text: getPlanStatusLabel(data.value.statusId) })] })))
                } }));
        }
        case 'sign': {
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'sign',
                    value: {
                        image: {
                            url: data.value.imageUrl,
                            tag: data.value.tag
                        },
                        damage: (_jsx(IconText, { icon: getSignIcon(data.value.damageType), text: getSignTranslation(data.value.damageType) }))
                    }
                } }));
        }
        case 'winter-service':
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'winter-service',
                    value: {
                        time: dateTimeFormatter(data.value.createdAt).dateTime,
                        driver: data.value.userName,
                        inspectionModeId: INSPECTION_MODE_ID.winterService,
                        winterServiceModes: data.value.winterServiceModes
                    }
                } }));
        case 'sign-inventory': {
            const sign = getSignFromSignTree(signTree, data.value.tag.subSpecifiedClass);
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'sign-inventory',
                    value: {
                        image: {
                            url: data.value.imageUrl,
                            croppedImageUrl: data.value.croppedImageUrl,
                            tag: data.value.tag
                        },
                        signType: {
                            id: (sign === null || sign === void 0 ? void 0 : sign.id) || '',
                            icon: getSignTypeIcon(data.value.tag.subSpecifiedClass),
                            label: sign === null || sign === void 0 ? void 0 : sign.abbreviation,
                            badgeContent: sign === null || sign === void 0 ? void 0 : sign.bastId
                        },
                        damage: (_jsx(IconText, { icon: getSignIcon(data.value.damageType), text: getSignTranslation(data.value.damageType) })),
                        statusCode: isEditableTrafficSignsEnabled ? data.value.statusCode : ''
                    }
                } }));
        }
        case 'asset': {
            const photo = data.value.photos[0];
            const assetType = data.value.inventoryTypeId
                ? assetTypeMap[data.value.inventoryTypeId]
                : undefined;
            return (_jsx(MapPopover, { direction: direction, content: {
                    type: 'asset',
                    value: {
                        imageURL: photo === null || photo === void 0 ? void 0 : photo.imageThumbUrl,
                        title: data.value.title,
                        assetIconId: assetType === null || assetType === void 0 ? void 0 : assetType.iconId
                    }
                } }));
        }
    }
    return null;
}
