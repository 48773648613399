// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h8PPZJKU7qt38C1sftLD{display:inline-block}.t8B2RfybHQwi1jfdKEMH{display:flex;flex-direction:row;align-items:center;padding:12px;gap:8px;height:48px;background:var(--primary-white);border-radius:12px}.t8B2RfybHQwi1jfdKEMH:hover{background:#e6e7e9}.ILR1AV_4Nkp3XdXkg7Fn{background:#fbd400}.ILR1AV_4Nkp3XdXkg7Fn:hover{background:#efca00}.g5LFe1iBu1FvymWvjYVz{font-weight:600;font-size:16px;line-height:19px}.uBolD1PrLM3dESIbud4N{font-weight:400;font-size:14px;color:rgba(34,45,57,.6)}.pB9hmSMJREb3zGcbpMVh{display:flex}`, "",{"version":3,"sources":["webpack://./src/components/PresetsPanel/Timepicker/Timepicker.module.css"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAEJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,OAAA,CAEA,WAAA,CAGA,+BAAA,CACA,kBAAA,CAGJ,4BACI,kBAAA,CAGJ,sBACI,kBAAA,CAGJ,4BACI,kBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,uBAAA,CAGJ,sBACI,YAAA","sourcesContent":[".wrapper {\n    display: inline-block;\n}\n.container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 12px;\n    gap: 8px;\n\n    height: 48px;\n\n    /* Primary/White */\n    background: var(--primary-white);\n    border-radius: 12px;\n}\n\n.container:hover {\n    background: #e6e7e9;\n}\n\n.container__applied {\n    background: #fbd400;\n}\n\n.container__applied:hover {\n    background: #efca00;\n}\n\n.bold-text {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 19px;\n}\n\n.normal-text {\n    font-weight: 400;\n    font-size: 14px;\n    color: rgba(34, 45, 57, 0.6);\n}\n\n.close {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `h8PPZJKU7qt38C1sftLD`,
	"container": `t8B2RfybHQwi1jfdKEMH`,
	"container__applied": `ILR1AV_4Nkp3XdXkg7Fn`,
	"bold-text": `g5LFe1iBu1FvymWvjYVz`,
	"normal-text": `uBolD1PrLM3dESIbud4N`,
	"close": `pB9hmSMJREb3zGcbpMVh`
};
export default ___CSS_LOADER_EXPORT___;
