import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { getSignTypeIcon } from '../../common/convert';
import { getSignTypeAbbreviation } from '../../common/convert/signInventory';
import config from '../../config/config.json';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { TabBar } from '../../uikit/TabBar/TabBar';
import { CustomPropertiesForm } from '../CustomPropertiesForm/CustomPropertiesForm';
import { SignTypeSelectField } from '../EditorForms/SignTypeSelect/SignTypeSelectField/SignTypeSelectField';
import { FormInputSelect } from '../FormInputSelect/FormInputSelect';
import { Task } from '../icons';
import { SignConditionItem, SignConditionSelectedValueWithId } from '../TrafficSignCreator/fields/SignConditionField';
import styles from './SignInventoryDetail.module.css';
export function SignInventoryDetail({ subSpecifiedClass, header, imagePreview, damageType, bastId, metaInfo, filesSection, filesCount, propertiesSection, onEditPropertiesClick, selectedFeature, onAddMarkerClick, callToAction, showFooter, changelog, conditionField, onDamageClassSelect, addressField, orientationField, onSignTypeSelectFieldClick, isEditableTrafficSignsEnabled, isEditor, isAdmin, isViewer }) {
    const [currentTab, setCurrentTab] = useState(0);
    useEffect(() => {
        setCurrentTab(0);
    }, [setCurrentTab, selectedFeature]);
    const conditionItems = [];
    conditionItems.push(...conditionField.items.map((cond) => (_jsx(SignConditionItem, { condition: cond, onClick: () => {
            onDamageClassSelect(cond.id);
        }, isSelected: cond.id === damageType.props.value }, cond.id))));
    function handleSignTypeSelectFieldClick() {
        onSignTypeSelectFieldClick === null || onSignTypeSelectFieldClick === void 0 ? void 0 : onSignTypeSelectFieldClick();
    }
    const signTypeAbbrev = useDittoWrapper({
        componentId: getSignTypeAbbreviation(subSpecifiedClass)
    });
    const nameAndTypeIDLabel = useDittoWrapper({
        componentId: 'trafficsigns.properties.nameandtypeid'
    });
    const conditionLabel = useDittoWrapper({ componentId: 'objects.status' });
    const tabs = [
        {
            label: _jsx(Ditto, { componentId: "details" }),
            content: (_jsxs("div", Object.assign({ className: styles['content-subgroup'] }, { children: [imagePreview && _jsx("div", Object.assign({ className: styles['image-preview'] }, { children: imagePreview })), _jsx("div", Object.assign({ className: styles['meta-info'] }, { children: metaInfo })), _jsxs("div", Object.assign({ className: classes(styles['content-wrapper']) }, { children: [_jsx("div", Object.assign({ className: styles['content-row'] }, { children: _jsx(SignTypeSelectField, { label: nameAndTypeIDLabel, variant: "ghost", width: "100%", leadingIcon: getSignTypeIcon(subSpecifiedClass), value: `${signTypeAbbrev} – ${bastId}`, onClick: handleSignTypeSelectFieldClick, disabled: !(isEditor && isEditableTrafficSignsEnabled) }) })), _jsx("div", Object.assign({ className: styles['content-row'] }, { children: addressField })), config.enableOrientation && (_jsx("div", Object.assign({ className: styles['content-row'] }, { children: orientationField }))), _jsx("div", Object.assign({ className: styles['content-row'] }, { children: _jsx(FormInputSelect, { label: conditionLabel, variant: "ghost", width: "100%", menuMaxHeight: 250, menuItems: conditionItems, menuWidth: 330, value: conditionField.current ? (_jsx(SignConditionSelectedValueWithId, { id: conditionField.current })) : (_jsx(_Fragment, {})), canFloat: true, disabled: !(isEditor && isEditableTrafficSignsEnabled), "data-test": "sign-condition" }) }))] })), _jsx(CustomPropertiesForm, Object.assign({ editable: isAdmin && !!isEditableTrafficSignsEnabled, onEditPropertiesClick: onEditPropertiesClick }, { children: propertiesSection }))] })))
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "files.files" }), _jsx(NotificationBadge, { content: filesCount })] })),
            content: filesSection
        },
        {
            label: _jsx(Ditto, { componentId: "marker.changelog.titel" }),
            content: _jsx("div", Object.assign({ className: styles['changelog-container'] }, { children: changelog }))
        }
    ];
    const content = tabs[currentTab].content;
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "sign-details" }, { children: [header, _jsx("div", { children: _jsx(TabBar, { tabs: tabs, current: currentTab, setCurrent: setCurrentTab }) }), _jsx("div", Object.assign({ className: styles['content-group'] }, { children: content })), callToAction && (_jsx("div", Object.assign({ className: classes(styles['call-to-action']) }, { children: callToAction }))), !isViewer && showFooter && (_jsx("div", Object.assign({ className: classes(styles['footer']) }, { children: _jsx(Button, Object.assign({ variant: "fullwidth", color: "dark", icon: _jsx(Task, {}), onClick: onAddMarkerClick }, { children: _jsx(Ditto, { componentId: "createmarker" }) })) })))] })));
}
