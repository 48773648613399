import { LOCALE } from './consts/locale';
export const LOCAL_STORAGE_PREFIX = 'vialytics-web-app-';
const LOCAL_STORAGE_KEY = {
    locale: 'locale',
    token: 'token',
    refreshToken: 'refreshToken',
    idToken: 'idToken',
    isAuthenticated: 'isAuthenticated',
    targetSchema: 'targetSchema',
    redirected: 'redirected'
};
function getLocalStorageValue(key) {
    const value = window.localStorage.getItem(LOCAL_STORAGE_PREFIX + key);
    return value;
}
function getLocaleFromLocal() {
    const locale = getLocalStorageValue(LOCAL_STORAGE_KEY.locale);
    if (!locale) {
        return undefined;
    }
    if (Object.keys(LOCALE).includes(locale)) {
        return locale;
    }
}
function getLocalStorageValueBool(key) {
    const value = getLocalStorageValue(key);
    return value === 'true';
}
export function getLocalStorageValueNumber(key) {
    const value = getLocalStorageValue(key);
    return Number(value);
}
export function getLocalStorageAll() {
    const locale = getLocaleFromLocal();
    const token = getLocalStorageValue(LOCAL_STORAGE_KEY.token) || undefined;
    const refreshToken = getLocalStorageValue(LOCAL_STORAGE_KEY.refreshToken) || undefined;
    const idToken = getLocalStorageValue(LOCAL_STORAGE_KEY.idToken) || undefined;
    const targetSchema = getLocalStorageValue(LOCAL_STORAGE_KEY.targetSchema) || undefined;
    const redirected = getLocalStorageValueBool(LOCAL_STORAGE_KEY.redirected);
    return {
        locale,
        token,
        refreshToken,
        idToken,
        targetSchema,
        redirected
    };
}
function setLocalStorageValue(key, value) {
    window.localStorage.setItem(LOCAL_STORAGE_PREFIX + key, value);
}
function removeLocalStorageValue(key) {
    window.localStorage.removeItem(LOCAL_STORAGE_PREFIX + key);
}
export function setLocalStoragePartial(data) {
    Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined) {
            setLocalStorageValue(key, value.toString());
        }
    });
}
export function removeLocalStoragePartial(data) {
    Object.entries(data).forEach(([key, value]) => {
        if (value === undefined) {
            removeLocalStorageValue(key);
        }
    });
}
