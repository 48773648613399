// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dklLWQUKu7FvRfAIi5ka p{margin:0}.dklLWQUKu7FvRfAIi5ka p.eLfQ3kBQo7GfBTcp0h5R{font-weight:700;margin-bottom:16px}.lrIccvomIJxYWdh9wxmR{display:grid;grid-template-columns:1fr 1fr;margin:0;margin-bottom:8px;padding:0;list-style-type:none}.lrIccvomIJxYWdh9wxmR li{display:flex;gap:8px;align-items:center;margin-bottom:10px}.lrIccvomIJxYWdh9wxmR .l1CExvAHySZ9AkNs7cMT svg{color:#f55}.lrIccvomIJxYWdh9wxmR .fCY18D5n9K8Ywx5im82I svg{color:#6dd07f}.kdfwdshujuAbWcLXwqdg{margin-bottom:18px}.MFK3XHqM5QYOmquG3NoO{margin:12px 0}`, "",{"version":3,"sources":["webpack://./src/components/Auth/PasswordForm/PasswordForm.module.css"],"names":[],"mappings":"AAAA,wBACI,QAAA,CAGJ,6CACI,eAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CACA,QAAA,CACA,iBAAA,CACA,SAAA,CACA,oBAAA,CAGJ,yBACI,YAAA,CACA,OAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,gDACI,UAAA,CAGJ,gDACI,aAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,aAAA","sourcesContent":[".container p {\n    margin: 0;\n}\n\n.container p.rules-info {\n    font-weight: 700;\n    margin-bottom: 16px;\n}\n\n.rules {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    margin: 0;\n    margin-bottom: 8px;\n    padding: 0;\n    list-style-type: none;\n}\n\n.rules li {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.rules .error svg {\n    color: #ff5555;\n}\n\n.rules .success svg {\n    color: #6dd07f;\n}\n\n.old-password {\n    margin-bottom: 18px;\n}\n\n.new-password {\n    margin: 12px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dklLWQUKu7FvRfAIi5ka`,
	"rules-info": `eLfQ3kBQo7GfBTcp0h5R`,
	"rules": `lrIccvomIJxYWdh9wxmR`,
	"error": `l1CExvAHySZ9AkNs7cMT`,
	"success": `fCY18D5n9K8Ywx5im82I`,
	"old-password": `kdfwdshujuAbWcLXwqdg`,
	"new-password": `MFK3XHqM5QYOmquG3NoO`
};
export default ___CSS_LOADER_EXPORT___;
