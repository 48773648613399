import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import styles from './Divider.module.css';
export function Divider({ className, height, margin = '6px', marginTop, width, variant = 'dark' }) {
    return (_jsx("div", { className: classes(styles['divider'], styles[`divider-${variant}`], className), style: {
            height,
            marginTop: marginTop || margin,
            marginBottom: margin,
            width
        } }));
}
