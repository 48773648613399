var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { getMapboxStaticImageURL } from '../../../common/mapbox/staticImage';
import styles from './MapboxStaticImage.module.css';
/**
 *  https://docs.mapbox.com/api/maps/static-images/
 */
export function MapboxStaticImage(_a) {
    var { onLoad, overlayElement, overlayAnchor = 'center', containerHeight = '230px' } = _a, props = __rest(_a, ["onLoad", "overlayElement", "overlayAnchor", "containerHeight"]);
    return (_jsxs("div", Object.assign({ className: styles['container'], style: { height: containerHeight } }, { children: [_jsx("img", { className: styles['image'], src: getMapboxStaticImageURL(props), onLoad: onLoad }), _jsx("div", Object.assign({ className: classes(styles['overlay'], styles[`overlay__${overlayAnchor}`]) }, { children: overlayElement }))] })));
}
