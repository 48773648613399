import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { getDittoComponentId, useDittoWrapper } from '../../../hooks/useDittoWrapper';
import styles from './MileYearsTooltipContent.module.css';
export function MileYearsTooltipContent({ countryCode }) {
    const wikiLink = useDittoWrapper({ componentId: 'wikilink.remainingservicelife' });
    return (_jsxs("div", Object.assign({ className: styles['wrapper'] }, { children: [_jsxs("div", Object.assign({ className: styles['top'] }, { children: [_jsx(Ditto, { componentId: "magicinput.tooltip.sumsign" }), ' ', _jsx(Ditto, { componentId: getDittoComponentId({
                            componentId: 'tooltip.remainingservicelife.calculation',
                            useUsVariant: countryCode === 'US'
                        }) })] })), _jsxs("div", Object.assign({ className: styles['bottom'] }, { children: [_jsx("p", { children: _jsx(Ditto, { componentId: getDittoComponentId({
                                componentId: 'tooltip.remainingservicelife.info',
                                useUsVariant: countryCode === 'US'
                            }) }) }), _jsx("a", Object.assign({ href: wikiLink, target: "_blank", className: styles['link'], rel: "noreferrer" }, { children: _jsx(Ditto, { componentId: "tooltip.remainingservicelife.clicktoread" }) }))] }))] })));
}
