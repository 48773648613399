import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
import { ALERTS_SOURCE_ID } from '../sources';
export const ALERTS_LAYER_ID = {
    default: `${LAYER_PREFIX}-alerts`,
    hover: `${LAYER_PREFIX}-alerts-hover`,
    highlight: `${LAYER_PREFIX}-alerts-highlight`,
    selected: `${LAYER_PREFIX}-alerts-selected`
};
export const ALERT_IMAGE_NAME = {
    default: 'alert-default',
    hover: 'alert-hover',
    highlight: 'alert-highlight',
    selected: 'alert-selected'
};
const ICON_SIZE = {
    default: 0.4,
    max: 0.7,
    hoverMultiplier: 1.2
};
export const alertsLayer = {
    id: ALERTS_LAYER_ID.default,
    interactive: true,
    type: 'symbol',
    source: ALERTS_SOURCE_ID,
    paint: {
        'icon-opacity': 1
    },
    layout: {
        'icon-image': ALERT_IMAGE_NAME.default,
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            ICON_SIZE.default,
            MAX_ZOOM,
            ICON_SIZE.max
        ],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-anchor': 'center'
    }
};
export const alertsHoverLayer = Object.assign(Object.assign({}, alertsLayer), { id: ALERTS_LAYER_ID.hover, layout: Object.assign(Object.assign({}, alertsLayer.layout), { 'icon-image': ALERT_IMAGE_NAME.hover, 'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            ICON_SIZE.default * ICON_SIZE.hoverMultiplier,
            MAX_ZOOM,
            ICON_SIZE.max * ICON_SIZE.hoverMultiplier
        ] }) });
export const alertsHighlightLayer = Object.assign(Object.assign({}, alertsLayer), { id: ALERTS_LAYER_ID.highlight, layout: Object.assign(Object.assign({}, alertsLayer.layout), { 'icon-image': ALERT_IMAGE_NAME.highlight }) });
export const alertsSelectedLayer = Object.assign(Object.assign({}, alertsLayer), { id: ALERTS_LAYER_ID.selected, layout: Object.assign(Object.assign({}, alertsLayer.layout), { 'icon-image': ALERT_IMAGE_NAME.selected }) });
