// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QSPOfldYmAL3jzw3mJE2{width:100%;margin-top:-40px}.cWf47SuLH7LMqmGetyBY{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-bottom:24px;margin-top:0px}.nOCdkBXPlynecfKVUazN{width:100%;display:flex;justify-content:flex-end;gap:12px}.id0kJR_FIZDLRL3ICeLY{width:120px}._ggrz_eYnkI7GjxENNn7{width:120px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/LinkTasksModal/LinkTasksModal.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,WAAA","sourcesContent":[".footer {\n    width: 100%;\n    margin-top: -40px;\n}\n\n.footer-divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-bottom: 24px;\n    margin-top: 0px;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n}\n\n.button-reset {\n    width: 120px;\n}\n\n.button-confirm {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `QSPOfldYmAL3jzw3mJE2`,
	"footer-divider": `cWf47SuLH7LMqmGetyBY`,
	"buttons": `nOCdkBXPlynecfKVUazN`,
	"button-reset": `id0kJR_FIZDLRL3ICeLY`,
	"button-confirm": `_ggrz_eYnkI7GjxENNn7`
};
export default ___CSS_LOADER_EXPORT___;
