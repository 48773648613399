import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDelayedLoader } from '../../hooks/useDelayedLoader';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { AttachmentItem } from '../DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { Search } from '../Search/Search';
import { NoMatch } from '../Searchbar';
import styles from './LinkedAssetSelect.module.css';
export function LinkedAssetSelect({ status, results, getAttachmentAsset, onSelect, onInputValueChange }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'search.searchfor' });
    const delayedLoader = useDelayedLoader({ isLoading: status === 'loading' });
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {
        handleInputValueChange('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleInputValueChange(value) {
        onInputValueChange(value);
        setSearchValue(value);
    }
    function handleAssetSelect(id) {
        onSelect(id);
    }
    const children = (() => {
        if (delayedLoader) {
            return delayedLoader;
        }
        if (results.length === 0) {
            return _jsx(NoMatch, {});
        }
        return results.map((asset) => (_jsx("div", Object.assign({ className: styles['search-result'] }, { children: _jsx(AttachmentItem, Object.assign({ onClick: () => handleAssetSelect(asset.id) }, { children: getAttachmentAsset(asset) }), asset.id) }), asset.id)));
    })();
    return (_jsxs("div", Object.assign({ className: styles['modal-search'] }, { children: [_jsx(Search, { placeholder: placeholderLabel, value: searchValue, onChange: handleInputValueChange, variant: "field" }), _jsx("div", { className: styles['modal-search-divider'] }), _jsx("div", Object.assign({ className: styles['modal-search-results'] }, { children: children }))] })));
}
