// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TI_iw3AB_S98J3B0ddF6{width:100%;height:100%;display:flex;flex-direction:column;padding:0 12px}.gUWv3qYKGFqtQvQyTJch{padding-bottom:12px}.aBBQY6_8gKgrb9kiRxBg{display:flex;align-items:center;padding:8px 16px 8px 8px}.OX6mlanZ53ItoC2jeACq{display:flex;align-items:center;gap:4px;width:100%;font-size:16px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/FilesSection/FilesSection.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,cAAA,CAGJ,sBACI,mBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 0 12px;\n}\n\n.container--padded-bottom {\n    padding-bottom: 12px;\n}\n\n.headline {\n    display: flex;\n    align-items: center;\n    padding: 8px 16px 8px 8px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    width: 100%;\n    font-size: 16px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TI_iw3AB_S98J3B0ddF6`,
	"container--padded-bottom": `gUWv3qYKGFqtQvQyTJch`,
	"headline": `aBBQY6_8gKgrb9kiRxBg`,
	"title": `OX6mlanZ53ItoC2jeACq`
};
export default ___CSS_LOADER_EXPORT___;
