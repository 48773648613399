import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from '@tanstack/react-location';
import { validate as uuidValidate } from 'uuid';
import { LAYER_PATH } from '../../common/layer';
import { ROOT_PATH } from '../../common/url';
import { useAppDispatch } from '../../state/hooks';
import { setSelectedFeature } from '../../state/slices/feature';
import { enableMapLayer } from '../../state/slices/layer';
export function RedirectUniversalLink() {
    const { params: { universalLinkType, universalLinkId } } = useMatch();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        navigate({
            to: `/${ROOT_PATH.main}`
        });
        if (universalLinkType === LAYER_PATH.marker && parseInt(universalLinkId) > 0) {
            dispatch(enableMapLayer('marker'));
            dispatch(setSelectedFeature({ type: 'marker', id: parseInt(universalLinkId) }));
        }
        if (universalLinkType === LAYER_PATH.asset && parseInt(universalLinkId) > 0) {
            dispatch(enableMapLayer('asset'));
            dispatch(setSelectedFeature({ type: 'asset', id: parseInt(universalLinkId) }));
        }
        if (universalLinkType === LAYER_PATH.condition) {
            dispatch(enableMapLayer('condition'));
            if (uuidValidate(universalLinkId)) {
                dispatch(setSelectedFeature({ type: 'point', id: universalLinkId }));
            }
            else if (parseInt(universalLinkId) > 0) {
                dispatch(setSelectedFeature({ type: 'segment', id: parseInt(universalLinkId) }));
            }
        }
        // possible to add other types of feature if necessary
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return _jsx(Outlet, {});
}
