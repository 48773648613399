import { bbox, center, featureCollection, points } from '@turf/turf';
export function moveMapToCenterOfCoordinates(map, coordinates) {
    const c = center(points(coordinates));
    const co = c.geometry.coordinates;
    moveMapToPoint(map, { lng: co[0], lat: co[1] });
}
export function moveMapToPoint(map, center, zoom) {
    zoom = zoom !== null && zoom !== void 0 ? zoom : map.getZoom();
    map.easeTo({
        center,
        zoom
    });
}
export function moveMapToFeatureCollection(map, features) {
    if (!features.length) {
        return;
    }
    const filteredFeatures = features.filter((f) => f.geometry);
    if (!filteredFeatures.length) {
        return;
    }
    const bounds = bbox(featureCollection(filteredFeatures));
    const [x1, y1, x2, y2] = bounds;
    map.fitBounds([x1, y1, x2, y2], {
        padding: 30,
        maxZoom: 17
    });
}
export function isFeatureInMapView(map, layerId, promoteId, selectedId) {
    if (!map.getLayer(layerId)) {
        return;
    }
    const features = map.queryRenderedFeatures(undefined, {
        layers: [layerId],
        filter: ['==', promoteId, selectedId]
    });
    return features.length > 0;
}
