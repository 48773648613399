// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WdVRObpOfkt260jf4hQy{width:356px;height:100%;display:flex;flex-direction:column;background:var(--primary-white);box-shadow:var(--box-shadow-regular)}.hjQXzHdTgaWxPLN024qa{padding-left:12px}.MHuCuLk6CNHhmjiv1y7K{background-color:#f1f2f4}.nY_mYD2CQMtfFbWbM9N_{padding-top:12px;display:flex;flex-direction:column;align-items:flex-start;height:100%;overflow-y:auto;scrollbar-width:none !important;-ms-overflow-style:none}.nY_mYD2CQMtfFbWbM9N_::-webkit-scrollbar{display:none}.nY_mYD2CQMtfFbWbM9N_>*:not(:last-child){margin-bottom:12px}.uKQBj3ApWW8taur1uy0J{padding:12px;border-top:1px solid var(--primary-grey)}`, "",{"version":3,"sources":["webpack://./src/components/SignDetail/SignDetail.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,+BAAA,CAEA,oCAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,wBAAA,CAGJ,sBACI,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,WAAA,CACA,eAAA,CACA,+BAAA,CACA,uBAAA,CAGJ,yCACI,YAAA,CAGJ,yCACI,kBAAA,CAGJ,sBACI,YAAA,CACA,wCAAA","sourcesContent":[".container {\n    width: 356px;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    /* Primary/White */\n    background: var(--primary-white);\n    /* Elevated 02 */\n    box-shadow: var(--box-shadow-regular);\n}\n\n.meta-info {\n    padding-left: 12px;\n}\n\n.image-preview {\n    background-color: #f1f2f4;\n}\n\n.content-group {\n    padding-top: 12px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    height: 100%;\n    overflow-y: auto;\n    scrollbar-width: none !important;\n    -ms-overflow-style: none;\n}\n\n.content-group::-webkit-scrollbar {\n    display: none;\n}\n\n.content-group > *:not(:last-child) {\n    margin-bottom: 12px;\n}\n\n.footer {\n    padding: 12px;\n    border-top: 1px solid var(--primary-grey);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WdVRObpOfkt260jf4hQy`,
	"meta-info": `hjQXzHdTgaWxPLN024qa`,
	"image-preview": `MHuCuLk6CNHhmjiv1y7K`,
	"content-group": `nY_mYD2CQMtfFbWbM9N_`,
	"footer": `uKQBj3ApWW8taur1uy0J`
};
export default ___CSS_LOADER_EXPORT___;
