import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Tooltip } from '../components/Tooltip/Tooltip';
import { TypedDitto } from '../components/TypedDitto';
export const GRADES = {
    '1.0': 1,
    '1.5': 1.5,
    '2.0': 2,
    '2.5': 2.5,
    '3.0': 3,
    '3.5': 3.5,
    '4.0': 4,
    '4.5': 4.5,
    '5.0': 5
};
export function normalizeBarHeight(km, gradePercentages) {
    const MAX_BAR_KM = gradePercentages.length
        ? Math.ceil(Math.max(...gradePercentages.map((grade) => grade.km)))
        : 1;
    if (km >= MAX_BAR_KM) {
        return 100;
    }
    const height = (km / MAX_BAR_KM) * 100;
    if (height === 0) {
        return 1;
    }
    return height;
}
export function createBar(grade, gradePercentages, unitFormatter, position) {
    const gradePercentage = gradePercentages.find((item) => item.rangeIdentifier === grade);
    let km = 0;
    if (gradePercentage === null || gradePercentage === void 0 ? void 0 : gradePercentage.km) {
        km = Math.round(gradePercentage.km * 100) / 100;
    }
    const percentage = (gradePercentage === null || gradePercentage === void 0 ? void 0 : gradePercentage.percentage) || 0;
    const height = normalizeBarHeight(km, gradePercentages);
    const toolTipText = (_jsx(TypedDitto, { componentId: "filter.condition.hover", variables: {
            Grade_GradingLogic: grade,
            Kilometer: unitFormatter({
                type: 'largeLength',
                value: gradePercentage === null || gradePercentage === void 0 ? void 0 : gradePercentage.km,
                decimals: 2
            }).formattedText,
            Percentage: percentage
        } }));
    return {
        height,
        tooltip: _jsx(Tooltip, { text: toolTipText, arrow: "down", position: position })
    };
}
export function createCaptions(gradePercentages, unitFormatter) {
    const CAPTIONS = 5;
    const captions = [
        unitFormatter({ type: 'largeLength', value: 0, spacing: false, decimals: 1 }).formattedText
    ];
    if (gradePercentages.length) {
        const MAX_BAR_KM = Math.ceil(Math.max(...gradePercentages.map((grade) => grade.km)));
        for (let index = 1; index < CAPTIONS; index++) {
            const value = Number((MAX_BAR_KM / (CAPTIONS - 1)) * index);
            captions[index] = unitFormatter({
                type: 'largeLength',
                value,
                spacing: false,
                decimals: 1
            }).formattedText;
        }
    }
    return captions;
}
