var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { setLocalStoragePartial } from '../../common/localStorage';
import { selectImagesDialog } from '../../common/selectImages';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { FlagFrance, FlagGerman, FlagUK } from '../../components/icons';
import { UserAccountModal } from '../../components/Modals/UserAccountModal/UserAccountModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';
import { updateProfileImage } from '../../services/accounts';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setLocale } from '../../state/slices/app';
import { fetchCurrentUser, updateUser } from '../../state/slices/auth';
import { addNotificationMessage } from '../../state/slices/notification';
function getCurrentLocaleElement(locale) {
    switch (locale) {
        case 'en':
            return (_jsx(FormInputCurrentSelectedValue, { text: _jsx(Ditto, { componentId: "language.english" }), icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(FlagUK, {}) })) }));
        case 'de':
            return (_jsx(FormInputCurrentSelectedValue, { text: _jsx(Ditto, { componentId: "language.deutsch" }), icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(FlagGerman, {}) })) }));
        case 'fr':
            return (_jsx(FormInputCurrentSelectedValue, { text: _jsx(Ditto, { componentId: "language.fran\u00E7ais" }), icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(FlagFrance, {}) })) }));
    }
}
function getLanguageList(locale, onChange) {
    return {
        current: getCurrentLocaleElement(locale),
        items: [
            _jsx(SelectboxEntry, { leadingIcon: _jsx(FlagUK, {}), text: _jsx(Ditto, { componentId: "language.english" }), onClick: () => onChange && onChange('locale', 'en'), "data-test": "language-en" }, 0),
            _jsx(SelectboxEntry, { leadingIcon: _jsx(FlagFrance, {}), text: _jsx(Ditto, { componentId: "language.fran\u00E7ais" }), onClick: () => onChange && onChange('locale', 'fr'), "data-test": "language-fr" }, 1),
            _jsx(SelectboxEntry, { leadingIcon: _jsx(FlagGerman, {}), text: _jsx(Ditto, { componentId: "language.deutsch" }), onClick: () => onChange && onChange('locale', 'de'), "data-test": "language-de" }, 2)
        ]
    };
}
export function UserAccountModalContainer() {
    const { addModal, removeModal, modals } = useModalContext();
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector((state) => state.app);
    const { userAccount } = useAppSelector((state) => state.auth);
    const { departmentList, status } = useDepartmentsData();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({
        firstName: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.firstName) || '',
        lastName: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.lastName) || '',
        image: undefined,
        titleId: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.titleId) || null,
        departmentId: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.departmentId) || null,
        email: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.email) || '',
        phoneNumber: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.phoneNumber) || '',
        locale: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.locale) || locale
    });
    function onChange(name, value) {
        setValues(Object.assign(Object.assign({}, values), { [name]: value }));
    }
    function closeModal() {
        removeModal('UserAccountModal');
    }
    function handleOnClickUpload() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const newImages = yield selectImagesDialog({ fileLimit: 1 });
                if (newImages.processedImages.length > 0)
                    onChange('image', newImages.processedImages[0].url);
            }
            catch (e) {
                console.error('UserAccountSettings/handleOnClickUpload:', e);
                if (Array.isArray(e) && ((_a = e[0]) === null || _a === void 0 ? void 0 : _a.type) === 'files_over_size') {
                    dispatch(addNotificationMessage({
                        type: 'error',
                        ditto: {
                            title: {
                                componentId: 'file.toolarge',
                                variables: { Size: 10 }
                            }
                        }
                    }));
                }
            }
        });
    }
    function handleSaveClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!userAccount) {
                return;
            }
            setIsSubmitting(true);
            yield Promise.all([
                values.image
                    ? yield fetch(values.image)
                        .then((response) => response.blob())
                        .then((blob) => __awaiter(this, void 0, void 0, function* () {
                        const formData = new FormData();
                        formData.append('uuid', userAccount.uuid);
                        formData.append('file', blob);
                        const res = yield updateProfileImage(formData);
                        if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
                            dispatch(addNotificationMessage({
                                type: 'error',
                                ditto: res.errorCode === RESPONSE_ERROR_CODE.uploadError
                                    ? { title: { componentId: 'toast.invalidimagefile' } }
                                    : undefined
                            }));
                        }
                    }))
                    : {},
                yield dispatch(updateUser({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    titleId: values.titleId,
                    departmentId: values.departmentId,
                    locale: values.locale
                }))
            ]);
            yield dispatch(fetchCurrentUser());
            if (values.locale !== locale) {
                handleLocaleChange(values.locale);
            }
            closeModal();
            setIsSubmitting(false);
        });
    }
    function handleCancelClick() {
        closeModal();
    }
    function handleLocaleChange(locale) {
        dispatch(setLocale(locale));
        setLocalStoragePartial({ locale });
        window.location.reload();
    }
    function handleResetPasswordClick() {
        addModal({ id: 'UpdatePasswordModal' });
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'UserAccountModal') {
            return;
        }
        handleCancelClick();
    }
    return (_jsx(UserAccountModal, { loading: isSubmitting || status === 'loading' || departmentList.length === 0, currentLocale: locale, values: values, user: userAccount, departmentList: departmentList, languageList: getLanguageList(values.locale, onChange), onChange: onChange, onClickUpload: handleOnClickUpload, onSaveClick: handleSaveClick, onCancelClick: handleCancelClick, onClickOutside: handleClickOutside, onResetPasswordClick: handleResetPasswordClick }));
}
