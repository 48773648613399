var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getSelectedSegmentByPolygon } from '../../services/stats';
import { setIsLoadingActionbar } from './actionbar';
import { addNotificationMessage } from './notification';
const initialState = {
    fetchStatus: 'idle',
    shouldConfirmSelect: false,
    shouldResetSelect: false
};
export const getSegmentByPolygon = createAppAsyncThunk('segmentDetail/segment/getByPolygon', (body, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { filter } = state.leftPanelCondition;
    const timepicker = state.presetsPanel.timepicker.selectedOption;
    const params = {
        polygon: body.polygon,
        filter,
        timepicker
    };
    dispatch(setIsLoadingActionbar(true));
    const res = yield getSelectedSegmentByPolygon(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setIsLoadingActionbar(false));
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    return res;
}));
export const segmentDetailSlice = createSlice({
    name: 'segmentDetail',
    initialState,
    reducers: {
        reset: () => initialState,
        setShouldConfirmSelect: (state, action) => {
            state.shouldConfirmSelect = action.payload;
        },
        setShouldResetSelect: (state, action) => {
            state.shouldResetSelect = action.payload;
        }
    },
    extraReducers(builder) {
        // Individual handlers
        builder
            .addCase(getSegmentByPolygon.fulfilled, (state, action) => {
            state.segment = action.payload.result;
        })
            // Actions that affect entire container
            .addMatcher(isAnyOf(getSegmentByPolygon.fulfilled), (state) => {
            state.fetchStatus = 'succeeded';
        })
            .addMatcher(isAnyOf(getSegmentByPolygon.pending), (state) => {
            state.fetchStatus = 'loading';
        })
            .addMatcher(isAnyOf(getSegmentByPolygon.rejected), (state) => {
            state.fetchStatus = 'failed';
        });
    }
});
export const { reset: resetSegmentDetail, setShouldConfirmSelect: confirmSegmentSelection, setShouldResetSelect: resetSegmentSelection } = segmentDetailSlice.actions;
export default segmentDetailSlice.reducer;
