// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NzRNSX96AZ9IPpmJloRF{width:18px;height:18px;padding:3px;box-sizing:border-box;display:flex;justify-content:center;align-items:center;opacity:1;background:var(--root-grey-2);border-radius:50%;cursor:pointer;transition:all 200ms ease;color:var(--primary-dark-blue)}.Ck2Hcwv7uxxwBLMX6s5Q{opacity:.8}.xBtIRU2DFRojZLanl61l{cursor:default;opacity:.4}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/SectionExpander/SectionExpander.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,WAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,6BAAA,CACA,iBAAA,CACA,cAAA,CACA,yBAAA,CACA,8BAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,cAAA,CACA,UAAA","sourcesContent":[".container {\n    width: 18px;\n    height: 18px;\n    padding: 3px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    opacity: 1;\n    background: var(--root-grey-2);\n    border-radius: 50%;\n    cursor: pointer;\n    transition: all 200ms ease;\n    color: var(--primary-dark-blue);\n}\n\n.container--collapsed {\n    opacity: 0.8;\n}\n\n.container--disabled {\n    cursor: default;\n    opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NzRNSX96AZ9IPpmJloRF`,
	"container--collapsed": `Ck2Hcwv7uxxwBLMX6s5Q`,
	"container--disabled": `xBtIRU2DFRojZLanl61l`
};
export default ___CSS_LOADER_EXPORT___;
