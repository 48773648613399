import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { ActionButton } from '../ActionButton/ActionButton';
import { ActionModal } from '../ActionModal/ActionModal';
import { DropMenu } from '../DropMenu/DropMenu';
import { Assets, Check, Close, Notes, Plus, Search as SearchIcon, SegmentCustom, Signs2, Task } from '../icons';
import { Loader } from '../Loader/Loader';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { Search } from './Search/Search';
import { SegmentCreator } from './SegmentCreator/SegmentCreator';
import { CreatorHintBox } from './CreatorHintBox';
import styles from './Actionbar.module.css';
export function Actionbar({ className, content, isEditor, isAdmin, isSignInventoryAuthorized, isEditableTrafficSignsEnabled, horizontalOffset }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'enteraddressidortitle' });
    const [areActionsOpen, setAreActionsOpen] = useState(false);
    const childComponents = (function () {
        switch (content.type) {
            case 'loading':
                return _jsx(Loader, {});
            case 'segment-creator':
                return _jsx(SegmentCreator, { content: content.content });
            case 'segment-creator-form':
                return (_jsx(Button, Object.assign({ color: "purple", disabled: !content.onActionClick, onClick: content.onActionClick, "data-test": "btn-action-create-planned-segment" }, { children: _jsxs("div", Object.assign({ className: styles['button-content'] }, { children: [_jsx(Check, {}), _jsx("span", { children: _jsx(Ditto, { componentId: "createplannedsegment" }) })] })) })));
            case 'search':
                return (_jsxs(_Fragment, { children: [_jsx(Search, { placeholder: placeholderLabel, value: content.value, onChange: content.onValueChange, suggestions: content.suggestions, "data-test": "search-input" }), _jsx(ActionButton, { icon: _jsx(Close, {}), tooltip: {
                                text: _jsx(Ditto, { componentId: "tooltip.closesearch" })
                            }, onClick: content.onCloseClick, "data-test": "close-search" })] }));
            case 'feature-drag-drop':
                return (_jsx(CreatorHintBox, { text: _jsx(Ditto, { componentId: "address.dragobjecttonewposition" }) }));
            case 'feature-rotate':
                return (_jsx(CreatorHintBox, { text: _jsx(Ditto, { componentId: "orientation.dragorientationtonewposition" }) }));
            case 'default':
            case 'point-selected':
            case 'segment-selected':
            case 'plan-selected':
            case 'asset-active':
                return (_jsxs(_Fragment, { children: [_jsx(ActionButton, { icon: _jsx(SearchIcon, {}), tooltip: {
                                text: _jsx(Ditto, { componentId: "tooltip.search" })
                            }, onClick: content.onSearchActionClick, "data-test": "btn-action-search" }), _jsx(DropMenu, { setIsOpen: setAreActionsOpen, isOpen: areActionsOpen, direction: "up-right", control: content.tooltip ? (_jsx(TooltipWrapper, Object.assign({ text: content.tooltip, position: "center", arrow: "down", isAlwaysVisible: true }, { children: _jsx(ActionButton, { icon: _jsx(Plus, {}), "data-test": "btn-action-plus" }) }))) : (_jsx(ActionButton, { icon: _jsx(Plus, {}), "data-test": "btn-action-plus" })), menu: _jsxs(ActionModal, Object.assign({ width: 234 }, { children: [isEditor && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Task, {}), text: _jsx(Ditto, { componentId: "createmarker" }), onClick: () => {
                                            var _a;
                                            setAreActionsOpen(false);
                                            (_a = content.onMarkerActionClick) === null || _a === void 0 ? void 0 : _a.call(content);
                                        }, "data-test": "btn-action-create-task" })), _jsx(SelectboxEntry, { leadingIcon: _jsx(Notes, {}), text: _jsx(Ditto, { componentId: "actionmodal.createnote" }), onClick: () => {
                                            var _a;
                                            setAreActionsOpen(false);
                                            (_a = content.onNoteActionClick) === null || _a === void 0 ? void 0 : _a.call(content);
                                        }, "data-test": "btn-action-create-note" }), isAdmin && (_jsx(SelectboxEntry, { leadingIcon: _jsx(SegmentCustom, {}), text: _jsx(Ditto, { componentId: "createplannedsegment" }), onClick: () => {
                                            var _a;
                                            setAreActionsOpen(false);
                                            (_a = content.onPlanActionClick) === null || _a === void 0 ? void 0 : _a.call(content);
                                        }, "data-test": 'btn-action-create-planned-segment' })), isEditor && content.type !== 'asset-active' && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Assets, {}), text: _jsx(Ditto, { componentId: "actionmodal.createinventory" }), onClick: () => {
                                            var _a;
                                            setAreActionsOpen(false);
                                            (_a = content.onAssetActionClick) === null || _a === void 0 ? void 0 : _a.call(content);
                                        }, "data-test": "btn-action-create-asset" })), isSignInventoryAuthorized &&
                                        isEditableTrafficSignsEnabled &&
                                        isEditor && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Signs2, {}), text: _jsx(Ditto, { componentId: "trafficsigns.createtrafficsign" }), onClick: () => {
                                            setAreActionsOpen(false);
                                            content.onCreateTrafficSignClick &&
                                                content.onCreateTrafficSignClick();
                                        }, "data-test": "btn-action-create-sign" }))] })) }), isEditor && content.type === 'asset-active' && (_jsx(Button, Object.assign({ icon: _jsx(Assets, {}), onClick: content.onAssetActionClick, color: "gray", "data-test": "btn-action-create-asset" }, { children: _jsx(Ditto, { componentId: "actionmodal.createinventory" }) }))), isEditor && content.type === 'point-selected' && (_jsx(Button, Object.assign({ color: "yellow", onClick: content.onMarkerActionClick }, { children: _jsxs("div", Object.assign({ className: styles['button-content'] }, { children: [_jsx(Task, {}), _jsx("span", { children: _jsx(Ditto, { componentId: "createmarker" }) })] })) }))), isAdmin &&
                            (content.type === 'segment-selected' ||
                                content.type === 'plan-selected') && (_jsx(Button, Object.assign({ color: "purple", onClick: content.onAddToPlanningActionClick, disabled: content.type === 'plan-selected', "data-test": 'btn-action-segment-selected' }, { children: _jsxs("div", Object.assign({ className: styles['button-content'] }, { children: [_jsx(SegmentCustom, {}), _jsxs("span", { children: [content.type === 'segment-selected' && (_jsx(Ditto, { componentId: "createplannedsegment" })), content.type === 'plan-selected' && (_jsx(Ditto, { componentId: "editsegment" }))] })] })) })))] }));
        }
    })();
    let translateX;
    if (horizontalOffset) {
        translateX = `translateX(${horizontalOffset}px)`;
    }
    let variant = '';
    if (content.type === 'feature-drag-drop' || content.type === 'feature-rotate') {
        variant = 'dark';
    }
    return (_jsx("div", Object.assign({ style: { transform: translateX } }, { children: _jsx("div", Object.assign({ className: classes(styles['container'], styles[variant], className) }, { children: _jsx("div", Object.assign({ className: styles['inner'] }, { children: childComponents })) })) })));
}
