import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ActionButton } from '../../ActionButton/ActionButton';
import { ActionModal } from '../../ActionModal/ActionModal';
import { DropMenu } from '../../DropMenu/DropMenu';
import { Bin, Download, File, OverflowDotsThreeVertical } from '../../icons';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import styles from './SingleFile.module.css';
export function SingleFile({ name, date, isEditor, withDropMenu, onFileClick, onDownloadClick, onDeleteClick, isSelected, previewUrl }) {
    const [menuOpen, setMenuOpen] = useState(false);
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], isSelected && styles['container__selected']), onClick: onFileClick }, { children: [_jsx("div", Object.assign({ className: classes(styles['image-wrapper']) }, { children: previewUrl ? (_jsx("img", { className: styles['image'], src: previewUrl, alt: "file" })) : (_jsx(File, {})) })), _jsxs("div", Object.assign({ className: classes(styles['file']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['name']), "data-test": "single-file-name" }, { children: name })), _jsx("div", Object.assign({ className: classes(styles['date']), "data-test": "single-file-date" }, { children: date }))] })), withDropMenu && (_jsx(DropMenu, { control: _jsx("div", Object.assign({ onClick: (e) => {
                        e.stopPropagation();
                        setMenuOpen(true);
                    } }, { children: _jsx(ActionButton, { icon: _jsx(OverflowDotsThreeVertical, {}) }) })), menu: _jsxs(ActionModal, Object.assign({ width: 234 }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.downloadfile" }), leadingIcon: _jsx(Download, {}), onClick: (e) => {
                                e === null || e === void 0 ? void 0 : e.stopPropagation();
                                setMenuOpen(false);
                                onDownloadClick();
                            } }), isEditor && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles['action-modal-separator'] }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.delete" }), leadingIcon: _jsx(Bin, {}), color: "red", onClick: (e) => {
                                        e === null || e === void 0 ? void 0 : e.stopPropagation();
                                        setMenuOpen(false);
                                        onDeleteClick();
                                    } })] }))] })), isOpen: menuOpen, setIsOpen: setMenuOpen, direction: "down-left" }))] })));
}
