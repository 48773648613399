// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vDBnjDmncsuW2L4v1Cem{display:flex;align-items:flex-start;gap:var(--padding-padding-m, 12px);flex-wrap:wrap}.hPGClrg4o4xRqt8aJ7OS{gap:var(--padding-padding-m, 12px);width:100%;display:flex;flex-direction:column;gap:var(--padding-padding-m, 12px)}`, "",{"version":3,"sources":["webpack://./src/components/PrintLayout/PrintDetail/PrintDetail.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sBAAA,CACA,kCAAA,CACA,cAAA,CAGJ,sBACI,kCAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kCAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: flex-start;\n    gap: var(--padding-padding-m, 12px);\n    flex-wrap: wrap;\n}\n\n.fullwidth {\n    gap: var(--padding-padding-m, 12px);\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: var(--padding-padding-m, 12px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vDBnjDmncsuW2L4v1Cem`,
	"fullwidth": `hPGClrg4o4xRqt8aJ7OS`
};
export default ___CSS_LOADER_EXPORT___;
