// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mzPKj6NHg7bJLijGN9Tq{display:flex;align-items:center;justify-content:space-between;font-size:14px;padding:12px;height:42px}.X4qUe0fMp8R4po0qXkBP,.VsGvdsR9dKwb7ZMMVxAK{opacity:.6;height:38px}.X4qUe0fMp8R4po0qXkBP{margin-left:-18px}.Y9IEYTAZ1e6pJlSVFsE_{opacity:.4;height:42px}.PXz06LYTVaZ66UxiTF22{font-weight:600}`, "",{"version":3,"sources":["webpack://./src/uikit/Heading/Heading.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,cAAA,CACA,YAAA,CACA,WAAA,CAGJ,4CAEI,UAAA,CACA,WAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CAGJ,sBACI,eAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 14px;\n    padding: 12px;\n    height: 42px;\n}\n\n.container__filter-group,\n.container__bar-filter-group {\n    opacity: 0.6;\n    height: 38px;\n}\n\n.container__filter-group {\n    margin-left: -18px;\n}\n\n.container__condition-filter {\n    opacity: 0.4;\n    height: 42px;\n}\n\n.text__condition-filter {\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `mzPKj6NHg7bJLijGN9Tq`,
	"container__filter-group": `X4qUe0fMp8R4po0qXkBP`,
	"container__bar-filter-group": `VsGvdsR9dKwb7ZMMVxAK`,
	"container__condition-filter": `Y9IEYTAZ1e6pJlSVFsE_`,
	"text__condition-filter": `PXz06LYTVaZ66UxiTF22`
};
export default ___CSS_LOADER_EXPORT___;
