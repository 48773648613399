var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { postRaw } from '../common/fetch';
import { planningToolClient } from '../openapi';
const PT_PLANNING_LIST_EXPORT_V2_ENDPOINT = 'planning-tool/v2/plan/export';
const PT_PLANNING_LIST_BY_IDS_EXPORT_V2_ENDPOINT = 'planning-tool/v2/plan/export-by-ids';
export function getPlanDetails(body) {
    return planningToolClient.planningToolService.planningToolServicePlanGet(body);
}
export function deletePlan(planId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanDelete({
            planId
        });
    });
}
export function getPlanStatusList() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceStatusTypeList(undefined);
    });
}
export function getPlanCategoryList() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceCategoryTypeList(undefined);
    });
}
export function getPlanFilters(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanFilterList(body);
    });
}
export function getPlanList(body) {
    return planningToolClient.planningToolService.planningToolServicePlanList(body);
}
export function getPlanTableSum(body) {
    return planningToolClient.planningToolService.planningToolServicePlanTableSumLine(body);
}
export function getPlanDetailsByIdList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanListByIds(body);
    });
}
export function createPlanningItem(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanCreateRecords(body);
    });
}
export function updatePlanningItem(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanUpdate(body);
    });
}
export function bulkUpdatePreview(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBulkPlanUpdatePreview(body);
    });
}
export function bulkUpdate(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBulkPlanUpdate(body);
    });
}
export function bulkDelete(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBulkPlanDelete(body);
    });
}
export function createStreetItem(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanCreateStreets(body);
    });
}
export function getPlanPreview(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanPreview(body);
    });
}
export function getPlanTreatmentsList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTreatmentsList(body);
    });
}
export function getPlanPropertyTypeList() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTablePropertyTypeList({});
    });
}
export function getPlanUnitTypeList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTableUnitTypeList(body);
    });
}
// NOTE: do NOT copy this function as an example,
// this is special communication when reponse is not json,
export function exportPlanList(body, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield postRaw(PT_PLANNING_LIST_EXPORT_V2_ENDPOINT, body, true, abortSignalId);
    });
}
export function exportPlanListByIds(body, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield postRaw(PT_PLANNING_LIST_BY_IDS_EXPORT_V2_ENDPOINT, body, true, abortSignalId);
        // NOTE: this is code handle openapi cancelable promise, but has problem to start download
        // const request = planningToolClient.planningToolService.planningToolServicePlanExport(body);
        // if (abortSignalId) {
        //     cancelablePromises[abortSignalId] = request;
        // }
        // try {
        //     return await request;
        // } finally {
        //     if (abortSignalId) {
        //         delete cancelablePromises[abortSignalId];
        //     }
        // }
    });
}
export function getPlanMapGeoJSON(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanMap(body);
    });
}
export function getPlanMapGeoJSONByPlanIds(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanMapByIds(body);
    });
}
export function getPlanMapGeoJSONByStreetId(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServicePlanMapPreview(body);
    });
}
export function getTableViewSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTableSettingsList(body);
    });
}
export function saveTableViewSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTableSettingsSave(body);
    });
}
export function removeTableViewProperty(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTableSettingsDelete(body);
    });
}
export function removeTableViewSelectOption(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTableSelectOptionDelete(body);
    });
}
export function saveTreatmentsSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTreatmentsSave(body);
    });
}
export function checkTreatmentsAutoUpdateAvailable(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTreatmentsSavePreview(body);
    });
}
export function removeTreatmentSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceTreatmentsDelete(body);
    });
}
export function getNetworkSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceNetworkGet(body);
    });
}
export function saveNetworkSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceNetworkUpdate(body);
    });
}
export function checkNetworkAutoUpdateAvailable(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceNetworkUpdatePreview(body);
    });
}
export function getBudgetsSettings() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBudgetsList({});
    });
}
export function saveBudgetSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBudgetsSave(body);
    });
}
export function removeBudgetSetting(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBudgetsDelete(body);
    });
}
export function getBudgetsBreakdown() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield planningToolClient.planningToolService.planningToolServiceBudgetsBreakdown({});
    });
}
