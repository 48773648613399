import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { ActionModal } from '../ActionModal/ActionModal';
import { DropMenu } from '../DropMenu/DropMenu';
import { FloatingDropdown } from '../FloatingDropdown/FloatingDropdown';
import { Caret } from '../icons';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import styles from './FormInputMultiSelect.module.css';
export function FormInputMultiSelect({ disabled = false, required, width, menuWidth, menuMaxHeight, label, value, info, align, isDark = false, variant = 'default', 'data-test': dataTest, children, withConfirmButton, icon, canFloat = false, onConfirmClick, hideMenu = false }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'notspecified' });
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown({});
    const [isOpen, setIsOpen] = useState(false);
    function handleConfirmClick() {
        hide();
        onConfirmClick && onConfirmClick();
        setIsOpen(false);
    }
    const control = (_jsxs("div", Object.assign({ ref: buttonRef, className: classes(styles['field'], !hideMenu && isOpen && styles['field__focus'], isDark && styles['dark'], variant && styles[`variant-${variant}`], disabled && styles['disabled']), style: { width }, tabIndex: 0, onClick: () => toggle(), "data-test": dataTest }, { children: [_jsxs("div", Object.assign({ className: styles['label-container'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['label'], value && variant !== 'ghost' && styles['label__small']) }, { children: [required && _jsx("span", Object.assign({ className: styles['required'] }, { children: "*" })), _jsx("span", Object.assign({ className: styles['label-text'] }, { children: label })), info && _jsx(InfoTooltip, { tooltip: info.tooltip, position: info.position })] })), value ? (_jsx("div", Object.assign({ className: styles['value'] }, { children: value }))) : (_jsxs("div", Object.assign({ className: styles['placeholder-container'] }, { children: [_jsx("span", Object.assign({ className: styles['icon'] }, { children: icon })), ' ', _jsx("span", Object.assign({ className: styles['placeholder-text'] }, { children: placeholderLabel }))] })))] })), _jsx("span", Object.assign({ className: styles['trailing-icon'] }, { children: !disabled && _jsx(Caret, {}) }))] })));
    const menu = (_jsxs(ActionModal, Object.assign({ maxHeight: menuMaxHeight, width: menuWidth || width }, { children: [children, withConfirmButton && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Button, Object.assign({ color: "dark", onClick: handleConfirmClick }, { children: _jsx(Ditto, { componentId: "confirmselection" }) }))] }))] })));
    return (_jsx(_Fragment, { children: canFloat ? (_jsxs(_Fragment, { children: [control, !hideMenu && isFloatingOpen && (_jsx(FloatingDropdown, Object.assign({}, position, { align: align, content: menu, onClickOutside: () => hide() })))] })) : (_jsx(DropMenu, { direction: "down-right", control: control, menu: hideMenu ? _jsx(_Fragment, {}) : menu, isOpen: isOpen, setIsOpen: (shouldOpen) => setIsOpen(shouldOpen), disabled: disabled })) }));
}
