import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { classes } from 'classifizer';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { FloatingTooltip } from '../FloatingTooltip/FloatingTooltip';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './ActionButton.module.css';
export const ActionButton = forwardRef(function ActionButton({ className, icon, isActive, onClick, isHover, tooltip, badge, disabled, 'data-test': dataTest, size = 'default', variant }, ref) {
    const { isOpen, hide, show, buttonRef, position } = useFloatingDropdown({
        offset: { top: (tooltip === null || tooltip === void 0 ? void 0 : tooltip.arrow) === 'up' ? 10 : 0, left: 20, right: 20 }
    });
    return (_jsxs("div", Object.assign({ ref: ref, className: styles['wrapper'] }, { children: [badge && _jsx("div", Object.assign({ className: styles['badge'] }, { children: badge })), _jsx("div", Object.assign({ ref: buttonRef, className: classes(styles['container'], className, isActive && styles['container__active'], isHover && styles['container__hover'], disabled && styles['container__disabled'], variant && styles[`container__${variant}`], size && styles[`container__${size}`]), onClick: (event) => !disabled && (onClick === null || onClick === void 0 ? void 0 : onClick(event)), onMouseEnter: show, onMouseLeave: hide, "data-test": dataTest }, { children: icon })), tooltip && isOpen && (_jsx(FloatingTooltip, Object.assign({}, position, { content: _jsx(Tooltip, Object.assign({}, tooltip)), onClickOutside: () => hide() })))] })));
});
