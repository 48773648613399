import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { createBar, createCaptions, GRADES } from '../../common/bar';
import { ConditionSelectedFeatureType, PRIMARY_SELECTED_FEATURE } from '../../common/consts/panelTab';
import { getConditionTypeIcon, getConditionTypeLabel } from '../../common/convert';
import { M_TO_FT } from '../../common/utils/units';
import { DamageFilter } from '../../components/DamageFilter/DamageFilter';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { GradeSlider } from '../../components/GradeSlider/GradeSlider';
import { StreetCrack, StreetFull, StreetOutbursts, StreetSurface } from '../../components/icons';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import { ReconstructionProposalsItem } from '../../components/ReconstructionProposalsItem/ReconstructionProposalsItem';
import { useAppSelector } from '../../state/hooks';
import { DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN } from '../../state/slices/leftPanelCondition';
import { Heading } from '../../uikit/Heading/Heading';
import { useConditionFilterHandlers } from '../filters/useConditionFilterHandlers';
import { useUnitFormatter } from '../formatting/useUnitFormatter';
export function useConditionFilters() {
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { filter, selectedFeatureType, reconstructionProposals } = useAppSelector((state) => state.leftPanelCondition);
    const { conditionFilters: { segmentLength, types: { streetTypes, surfaceTypes, roadDamages, roadDamageGroups } }, gradePercentages } = useAppSelector((state) => state.shared);
    const { handleReconstructionProposalChange, handleSelectedFeatureTypeChange, handleGradeValueChange, handleSegmentLengthChange, handleStreetTypeChange, handleSurfaceTypeChange, handleDamageTypeChange, handleExcludeDamageChange, handleFilterDamageRangeChange, handleResetSelection } = useConditionFilterHandlers();
    const unitFormatter = useUnitFormatter();
    const primarySelectedFeature = PRIMARY_SELECTED_FEATURE[selectedFeatureType];
    const filterableStreetTypes = streetTypes.filter((s) => s.is_used_as_filter);
    const reconstructionProposalsIconsMap = {
        filling_outbursts: _jsx(StreetOutbursts, {}),
        crack_n_seam_fill: _jsx(StreetCrack, {}),
        top_surface_renewal: _jsx(StreetSurface, {}),
        full_renewal: _jsx(StreetFull, {})
    };
    function getConditionReconstructionProposalsFilterOptions() {
        return reconstructionProposals.map((item) => (_jsx(ReconstructionProposalsItem, { id: item.id, icon: reconstructionProposalsIconsMap[item.name], title: _jsx(Ditto, { componentId: `filter.reconstruction-proposals.${item.name}.title` }), subtitle: _jsx(Ditto, { componentId: `filter.reconstruction-proposals.${item.name}.description` }), 
            // TODO: Fill in length
            length: unitFormatter({ type: 'length', value: undefined }), isSelected: filter.reconstructionProposalId === item.id, onClick: () => {
                handleReconstructionProposalChange(item.id);
            } }, item.name)));
    }
    function getConditionTypeFilterOptions() {
        var _a, _b, _c, _d;
        const useUSFormat = (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US';
        const minFormattedValue = unitFormatter({
            type: 'length',
            value: segmentLength.min,
            decimals: 0
        });
        const maxFormattedValue = unitFormatter({
            type: 'length',
            value: segmentLength.max,
            decimals: 0
        });
        const minFilterFormattedValue = unitFormatter({
            type: 'length',
            value: filter.segmentLengthMin,
            decimals: 0
        });
        const maxFilterFormattedValue = unitFormatter({
            type: 'length',
            value: filter.segmentLengthMax,
            decimals: 0
        });
        return (_jsxs(_Fragment, { children: [_jsx(FilterSelectbox, { icon: getConditionTypeIcon(ConditionSelectedFeatureType.point), onClick: () => {
                        handleResetSelection();
                        handleSelectedFeatureTypeChange(ConditionSelectedFeatureType.point);
                    }, isChecked: selectedFeatureType === ConditionSelectedFeatureType.point ||
                        selectedFeatureType === ConditionSelectedFeatureType.both, content: getConditionTypeLabel(ConditionSelectedFeatureType.point), color: "black" }), _jsx(FilterSelectbox, Object.assign({ icon: getConditionTypeIcon(ConditionSelectedFeatureType.segment), onClick: () => {
                        handleResetSelection();
                        handleSelectedFeatureTypeChange(ConditionSelectedFeatureType.segment);
                    }, isChecked: selectedFeatureType === ConditionSelectedFeatureType.segment ||
                        selectedFeatureType === ConditionSelectedFeatureType.both, content: getConditionTypeLabel(ConditionSelectedFeatureType.segment), color: "black", trailingElement: PRIMARY_SELECTED_FEATURE[selectedFeatureType] === 'segment' &&
                        (filter.segmentLengthMin > segmentLength.min ||
                            filter.segmentLengthMax < segmentLength.max) &&
                        `${`${Math.floor(minFilterFormattedValue.value)} ${minFilterFormattedValue.unit}`} - ${`${Math.ceil(maxFilterFormattedValue.value)} ${maxFilterFormattedValue.unit}`}` }, { children: primarySelectedFeature === 'segment' && (_jsx(RangeSlider, { "data-test": "length-slider", width: 328, min: (_a = segmentLength.min) !== null && _a !== void 0 ? _a : 0, max: (_b = segmentLength.max) !== null && _b !== void 0 ? _b : 0, step: useUSFormat ? 1 / M_TO_FT : 1, color: 'black', captions: [
                            {
                                label: `${`${Math.floor(minFormattedValue.value)} ${minFormattedValue.unit}`}`,
                                value: (_c = segmentLength.min) !== null && _c !== void 0 ? _c : 0
                            },
                            {
                                label: `${`${Math.ceil(maxFormattedValue.value)} ${maxFormattedValue.unit}`}`,
                                value: (_d = segmentLength.max) !== null && _d !== void 0 ? _d : 0
                            }
                        ], value: {
                            min: filter.segmentLengthMin,
                            max: filter.segmentLengthMax
                        }, onChange: (values) => {
                            if (useUSFormat && segmentLength.min && segmentLength.max) {
                                if (Math.abs(values.min - segmentLength.min) < 1 / M_TO_FT) {
                                    values.min = segmentLength.min;
                                }
                                else {
                                    const feets = Math.floor(values.min * M_TO_FT);
                                    values.min = feets / M_TO_FT;
                                }
                                if (Math.abs(values.max - segmentLength.max) < 1 / M_TO_FT) {
                                    values.max = segmentLength.max;
                                }
                                else {
                                    const feets = Math.ceil(values.max * M_TO_FT);
                                    values.max = feets / M_TO_FT;
                                }
                            }
                            handleSegmentLengthChange(values);
                        } })) }))] }));
    }
    function getConditionGradeFilterOptions() {
        return (_jsx(GradeSlider, { bar1: createBar(GRADES['1.0'], gradePercentages, unitFormatter, 'start'), bar15: createBar(GRADES['1.5'], gradePercentages, unitFormatter, 'start'), bar2: createBar(GRADES['2.0'], gradePercentages, unitFormatter), bar25: createBar(GRADES['2.5'], gradePercentages, unitFormatter), bar3: createBar(GRADES['3.0'], gradePercentages, unitFormatter), bar35: createBar(GRADES['3.5'], gradePercentages, unitFormatter), bar4: createBar(GRADES['4.0'], gradePercentages, unitFormatter), bar45: createBar(GRADES['4.5'], gradePercentages, unitFormatter), bar5: createBar(GRADES['5.0'], gradePercentages, unitFormatter), captions: createCaptions(gradePercentages, unitFormatter), leftGradeValue: filter.grade.min, rightGradeValue: filter.grade.max, onChange: handleGradeValueChange, "data-test": "grade-slider" }));
    }
    function getConditionStreetTypeFilterOptions() {
        return filterableStreetTypes.map((item) => (_jsx(FilterSelectbox, { onClick: () => handleStreetTypeChange(item.id), isChecked: filter.streetType.includes(item.id), content: _jsx(Ditto, { componentId: `filter.roadclassification.${item.name}` }), color: "black" }, item.id)));
    }
    function getConditionSurfaceTypeFilterOptions() {
        return surfaceTypes.map((item) => (_jsx(FilterSelectbox, { onClick: () => handleSurfaceTypeChange(item.id), isChecked: filter.surfaceType.includes(item.id), content: _jsx(Ditto, { componentId: `filter.surfacetype.${item.name}` }), color: "black" }, item.id)));
    }
    function getConditionRoadDamageFilterOptions() {
        return roadDamageGroups.map((damageGroup, dmgIndex) => (_jsxs(React.Fragment, { children: [_jsx(Heading, { text: _jsx(Ditto, { componentId: `damagegroup.${damageGroup.name}` }), variant: "filter-group" }), roadDamages
                    .filter((item) => damageGroup.childrens.includes(item.name))
                    .map((item) => {
                    var _a, _b, _c;
                    const damageRange = filter.damageType.find((damage) => damage.id === item.id);
                    return (_jsx(DamageFilter, { name: _jsx(Ditto, { componentId: `damagecategory.${item.name}` }), value: {
                            min: (_a = damageRange === null || damageRange === void 0 ? void 0 : damageRange.range.min) !== null && _a !== void 0 ? _a : DEFAULT_GRADE_MIN,
                            max: (_b = damageRange === null || damageRange === void 0 ? void 0 : damageRange.range.max) !== null && _b !== void 0 ? _b : DEFAULT_GRADE_MAX
                        }, isChecked: !!damageRange, isExcluded: (_c = damageRange === null || damageRange === void 0 ? void 0 : damageRange.isExcluded) !== null && _c !== void 0 ? _c : false, onFilterClick: () => handleDamageTypeChange(item), onExcludeClick: () => handleExcludeDamageChange(item.id), onChange: (value) => {
                            handleFilterDamageRangeChange(item.id, value);
                        }, "data-test": `road-damage-${item.name}` }, item.id));
                })] }, `${damageGroup.name}-${dmgIndex}`)));
    }
    return {
        getConditionTypeFilterOptions,
        getConditionReconstructionProposalsFilterOptions,
        getConditionGradeFilterOptions,
        getConditionStreetTypeFilterOptions,
        getConditionSurfaceTypeFilterOptions,
        getConditionRoadDamageFilterOptions
    };
}
