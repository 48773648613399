import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getSignIcon, getSignTranslation } from '../../../common/convert';
import { FormInputCurrentSelectedValue } from '../../FormInputSelect/FormInputSelect';
import { Check, SparkleMagicApplied } from '../../icons';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { Vignette } from '../../Vignette/Vignette';
export function SignConditionItem({ condition, onClick, isMagicInput, isSelected }) {
    const label = getSignTranslation(condition.id);
    let trailingIcon;
    if (isSelected) {
        trailingIcon = _jsx(Check, {});
    }
    if (isMagicInput) {
        trailingIcon = _jsx(SparkleMagicApplied, {});
    }
    return (_jsx(SelectboxEntry, { leadingIcon: _jsx(Vignette, { content: getSignIcon(condition.id), color: "black" }), text: label, onClick: onClick && onClick, trailingIcon: trailingIcon, "data-test": `sign-condition-${condition.id}` }));
}
export function SignConditionSelectedValue({ condition }) {
    const label = getSignTranslation(condition.id);
    return (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(Vignette, { content: getSignIcon(condition.id), color: "black" }), text: label }));
}
export function SignConditionSelectedValueWithId({ id }) {
    const label = getSignTranslation(id);
    return (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(Vignette, { content: getSignIcon(id), color: "black" }), text: label }));
}
