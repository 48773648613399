import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { format } from 'date-fns';
import { ArrowLeft, ArrowRight } from '../../icons';
import styles from './CustomHeader.module.css';
const ITEMS_ON_PAGE = 12;
const MIN_YEAR = 2017;
export function CustomHeader({ locale, date, decreaseYear, increaseYear }) {
    const currentYear = parseInt(format(date, 'yyyy', { locale: locale }));
    const yearIndex = currentYear % ITEMS_ON_PAGE;
    return (_jsxs("div", Object.assign({ className: styles['custom-header'] }, { children: [_jsx("button", Object.assign({ "aria-label": "Previous Year", onClick: decreaseYear, className: styles['nav-button'], disabled: currentYear - yearIndex + 1 <= MIN_YEAR }, { children: _jsx(ArrowLeft, {}) })), _jsx("span", Object.assign({ className: styles['current-date-wrapper'] }, { children: _jsxs("span", Object.assign({ className: styles['current-year'], "data-test": "current-date" }, { children: [currentYear - yearIndex + 1, " - ", currentYear + ITEMS_ON_PAGE - yearIndex] })) })), _jsx("button", Object.assign({ "aria-label": "Next Year", onClick: increaseYear, className: styles['nav-button'] }, { children: _jsx(ArrowRight, {}) }))] })));
}
