var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CancelableRequest } from '../../common/cancelableRequest';
import { PAGE_NUMBER_FIRST, SELECT_ALL_LIMIT } from '../../common/consts/config';
import { MARKER_STATUS_ID } from '../../common/consts/marker';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { uniqueV2 } from '../../common/utils/array';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getPaginationParams, getTimeParams } from '../../common/utils/params';
import { getLightAssets } from '../../services/asset';
import { batchDeleteMarkers, exportMarkers, getFilterParams, getMarkerIdList, getMarkerList, getSortParams } from '../../services/marker';
import { clearSelectedFeature } from './feature';
import { fetchMarkerGeoJSON } from './mainMap';
import { addNotificationMessage } from './notification';
export const initialFilterMarker = {
    priorityId: [],
    statusId: [MARKER_STATUS_ID.open, MARKER_STATUS_ID.inProgress],
    category: [],
    assignee: [],
    interval: [],
    filesAttached: [],
    filesNotAttached: []
};
const markerParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.marker;
const initialState = {
    resultList: [],
    totalCount: 0,
    resultFetchStatus: 'idle',
    sort: (markerParams === null || markerParams === void 0 ? void 0 : markerParams.sort) || 'date-dec',
    filter: Object.assign(Object.assign({}, initialFilterMarker), markerParams === null || markerParams === void 0 ? void 0 : markerParams.filter),
    selectedIdList: [],
    useMapBounds: markerParams === null || markerParams === void 0 ? void 0 : markerParams.useMapBounds,
    activeTabKey: (markerParams === null || markerParams === void 0 ? void 0 : markerParams.activeTabKey) || 'filter',
    currentPage: (markerParams === null || markerParams === void 0 ? void 0 : markerParams.currentPage) || PAGE_NUMBER_FIRST,
    isMultiSelectOn: false,
    linkedAssets: [],
    linkedAssetsFetchStatus: 'idle'
};
// NOTE: createAppAsyncThunk catches errors by default, and dispatches /fail action
// we ensure that the content does not throw an exception by fetch wrapper
// then we dont need to handle the /fail action
// NOTE: "getState" can be use to get current state before the api calls,
// so that the current state does NOT need to be retrieved by containers and passed to thunk through paramaters
export const fetchMarkers = createDebouncedAsyncThunk('leftPanelMarker/marker/fetchList', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds, currentPage } = state.leftPanelMarker;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    const res = yield CancelableRequest.send(getMarkerList, {
        params: {
            pagination: getPaginationParams(currentPage),
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            filter: getFilterParams(filter)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    // NOTE: example to notify specific user error
    if (res.errorCode === RESPONSE_ERROR_CODE.userError) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const exportMarkersTk = createAppAsyncThunk('leftPanelMarker/exportMarkersTk', (req, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds } = state.leftPanelMarker;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    const { locale } = state.app;
    const { userAccount, teamAccount } = state.auth;
    if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)) {
        return;
    }
    const res = yield exportMarkers({
        params: {
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            filter: getFilterParams(filter)
        },
        language: locale,
        email: userAccount.email,
        useUsaFormat: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US',
        markersIds: req.ids,
        utcOffset: moment().utcOffset()
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.processingexport' } }
        }));
    }
    return res;
}));
export const fetchMarkersIdListTk = createAppAsyncThunk('leftPanelAsset/fetchMarkersIdListTk', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds } = state.leftPanelMarker;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    const res = yield CancelableRequest.send(getMarkerIdList, {
        params: {
            pagination: { offset: 0, limit: SELECT_ALL_LIMIT },
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            filter: getFilterParams(filter)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const deleteMarkersTk = createAppAsyncThunk('leftPanelMarker/deleteMarkersTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield batchDeleteMarkers({ ids });
    // NOTE: example to notify error
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    // NOTE: example to notify success
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.markerdeleted' }
            }
        }));
        dispatch(fetchMarkerGeoJSON());
        dispatch(fetchMarkers());
        dispatch(resetMarkerMultiSelect());
        dispatch(clearSelectedFeature());
    }
    return res;
}));
export const fetchLinkedAssetsTk = createDebouncedAsyncThunk('leftPanelMarker/fetchLinkedAssetsTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const uniqueIds = uniqueV2(ids);
    const res = yield CancelableRequest.send(getLightAssets, {
        ids: uniqueIds
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const leftPanelMarkerSlice = createSlice({
    name: 'leftPanelMarker',
    initialState,
    reducers: {
        reset: () => initialState,
        resetMarkerFilter: (state) => {
            state.filter = initialFilterMarker;
        },
        setMarkerFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setMarkerSorting: (state, action) => {
            state.sort = action.payload;
        },
        setMarkerSelectedIdList: (state, action) => {
            state.selectedIdList = action.payload;
        },
        startMarkerMultiSelect: (state) => {
            state.isMultiSelectOn = true;
        },
        resetMarkerMultiSelect: (state) => {
            state.isMultiSelectOn = false;
            state.selectedIdList = [];
        },
        resetMarkerSearchBar: (state) => {
            state.filter = Object.assign(Object.assign({}, state.filter), { search: undefined });
        },
        setUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        setMarkerActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setMarkerCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        }
    },
    extraReducers(builder) {
        // NOTE: only add more cases when the loading state is interested.
        builder
            .addCase(fetchMarkers.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchMarkers.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.markers;
                state.totalCount = action.payload.result.totalCount;
            }
        })
            .addCase(fetchMarkersIdListTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.selectedIdList = action.payload.result.ids;
            }
        })
            .addCase(fetchLinkedAssetsTk.pending, (state) => {
            state.linkedAssetsFetchStatus = 'loading';
        })
            .addCase(fetchLinkedAssetsTk.fulfilled, (state, action) => {
            state.linkedAssetsFetchStatus = 'completed';
            if (action.payload.result) {
                state.linkedAssets = action.payload.result.manualInventories;
            }
        });
        // NOTE: otherwise add only fulfilled case is enough
    }
});
export const { reset: resetLeftPanelMarker, resetMarkerFilter, setMarkerFilter, setMarkerSorting, setMarkerSelectedIdList, setUseMapBounds, setMarkerActiveTabKey, setMarkerCurrentPage, startMarkerMultiSelect, resetMarkerMultiSelect, resetMarkerSearchBar } = leftPanelMarkerSlice.actions;
export default leftPanelMarkerSlice.reducer;
