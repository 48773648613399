import { jsx as _jsx } from "react/jsx-runtime";
import React, { useId } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { ModalOverlay } from '../../components/ModalOverlay/ModalOverlay';
import { useEscapeKeyListener } from '../../hooks/useEscapeListener';
import { useModalContext } from '../../state/context';
import { ABTMIntroModalContainer } from '../ABTMIntroModalContainer/ABTMIntroModalContainer';
import { AddCoWorkerModalContainer } from '../AddCoWorkerModalContainer/AddCoWorkerModalContainer';
import { AssetConverterModalContainer } from '../AssetConverterModalContainer/AssetConverterModalContainer';
import { AssigneeSelectModalContainer } from '../AssigneeSelectModalContainer/AssigneeSelectModalContainer';
import { AutoUpdateModalContainer } from '../AutoUpdateModalContainer/AutoUpdateModalContainer';
import { BulkEditAssetContainer } from '../BulkEditAssetContainer/BulkEditAssetContainer';
import { BulkEditMarkerContainer } from '../BulkEditMarkerContainer/BulkEditMarkerContainer';
import { BulkEditPlanContainer } from '../BulkEditPlanContainer/BulkEditPlanContainer';
import { CategorySelectModalContainer } from '../CategorySelectModalContainer/CategorySelectModalContainer';
import { CoWorkerModalContainer } from '../CoWorkerModalContainer/CoWorkerModalContainer';
import { DataUpdateModalContainer } from '../DataUpdateModalContainer/DataUpdateModalContainer';
import { DatePickerModalContainer } from '../DatePickerModalContainer/DatePickerModalContainer';
import { DefaultTreatmentsIntroModalContainer } from '../DefaultTreatmentsIntroModalContainer/DefaultTreatmentsIntroModalContainer';
import { DeleteModalContainer } from '../DeleteModalContainer/DeleteModalContainer';
import { DueDatePickerModalContainer } from '../DueDatePickerModalContainer/DueDatePickerModalContainer';
import { ExportModalContainer } from '../ExportModalContainer/ExportModalContainer';
import { FileGalleryAssetContainer } from '../FileGalleryAssetContainer/FileGalleryAssetContainer';
import { FileGallerySignInventoryContainer } from '../FileGallerySignInventoryContainer/FileGallerySignInventoryContainer';
import { FileGalleryTaskContainer } from '../FileGalleryTaskContainer/FileGalleryTaskContainer';
import { FileUploadModalContainer } from '../FileUploadModal/FileUploadModalContainer';
import { GlobalSettingsModalContainer } from '../GlobalSettingsModalContainer/GlobalSettingsModalContainer';
import { ImageViewerAssetContainer } from '../ImageViewerAssetContainer/ImageViewerAssetContainer';
import { ImageViewerConditionContainer } from '../ImageViewerConditionContainer/ImageViewerConditionContainer';
import { ImageViewerMarkerContainer } from '../ImageViewerMarkerContainer/ImageViewerMarkerContainer';
import { ImageViewerObjectContainer } from '../ImageViewerObjectContainer/ImageViewerObjectContainer';
import { ImageViewerSignContainer } from '../ImageViewerSignContainer/ImageViewerSignContainer';
import { ImageViewerSignInventoryContainer } from '../ImageViewerSignInventoryContainer/ImageViewerSignInventoryContainer';
import { InventoryTeaserModalContainer } from '../InventoryTeaserModalContainer/InventoryTeaserModalContainer';
import { LinkAssetModalContainer } from '../LinkAssetModalContainer/LinkAssetModalContainer';
import { LinkTasksModalContainer } from '../LinkTasksModalContainer/LinkTasksModalContainer';
import { MailModalContainer } from '../MailModalContainer/MailModalContainer';
import { MarkerCreatorContainer } from '../MarkerCreatorContainer/MarkerCreatorContainer';
import { MissingCostsDataModalContainer } from '../MissingCostsDataModalContainer/MissingCostsDataModalContainer';
import { MonthPickerModalContainer } from '../MonthPickerModalContainer/MonthPickerModalContainer';
import { MultiselectModalContainer } from '../MultiselectModalContainer/MultiselectModalContainer';
import { RemainingServiceLifeIntroModalContainer } from '../RemainingServiceLifeIntroModalContainer/RemainingServiceLifeIntroModalContainer';
import { ReportModalContainer } from '../ReportModalContainer/';
import { RolePermissionsModalContainer } from '../RolePermissionsModalContainer/RolePermissionsModalContainer';
import { SaveModalContainer } from '../SaveModalContainer/SaveModalContainer';
import { SignInventoryIntroModalContainer } from '../SignInventoryIntroModalContainer/SignInventoryIntroModalContainer';
import { SignTypeSelectModalContainer } from '../SignTypeSelectModalContainer/SignTypeSelectModalContainer';
import { TreatmentSelectModalContainer } from '../TreatmentSelectModalContainer/TreatmentSelectModalContainer';
import { UpdatePasswordModalContainer } from '../UpdatePasswordModalContainer/UpdatePasswordModalContainer';
import { UserAccountModalContainer } from '../UserAccountModalContainer/UserAccountModalContainer';
import { WorkingHoursPickerModalContainer } from '../WorkingHoursPickerModalContainer/WorkingHoursPickerModalContainer';
import { YearPickerModalContainer } from '../YearPickerModalContainer/YearPickerModalContainer';
function getModal(modal) {
    switch (modal.id) {
        // NOTE: here to add all modals available in the whole application
        case 'GlobalSettingsModal':
            return _jsx(GlobalSettingsModalContainer, Object.assign({}, modal.props));
        case 'UserAccountModal':
            return _jsx(UserAccountModalContainer, {});
        case 'CoWorkerModal':
            return _jsx(CoWorkerModalContainer, {});
        case 'AddCoWorkerModal':
            return _jsx(AddCoWorkerModalContainer, {});
        case 'RolePermissionsModal':
            return _jsx(RolePermissionsModalContainer, {});
        case 'DeleteModal':
            return _jsx(DeleteModalContainer, Object.assign({}, modal.props));
        case 'SaveModal':
            return _jsx(SaveModalContainer, Object.assign({}, modal.props));
        case 'MarkerCreatorModal':
            return _jsx(MarkerCreatorContainer, {});
        case 'MailModal':
            return _jsx(MailModalContainer, Object.assign({}, modal.props));
        case 'BulkEditMarkerModal':
            return _jsx(BulkEditMarkerContainer, Object.assign({}, modal.props));
        case 'BulkEditAssetModal':
            return _jsx(BulkEditAssetContainer, Object.assign({}, modal.props));
        case 'BulkEditPlanModal':
            return _jsx(BulkEditPlanContainer, Object.assign({}, modal.props));
        case 'ReportModal':
            return _jsx(ReportModalContainer, {});
        case 'UpdatePasswordModal':
            return _jsx(UpdatePasswordModalContainer, {});
        case 'ImageViewerMarker':
            return _jsx(ImageViewerMarkerContainer, Object.assign({}, modal.props));
        case 'ImageViewerCondition':
            return _jsx(ImageViewerConditionContainer, {});
        case 'ImageViewerObject':
            return _jsx(ImageViewerObjectContainer, {});
        case 'ImageViewerSign':
            return _jsx(ImageViewerSignContainer, {});
        case 'ImageViewerAsset':
            return _jsx(ImageViewerAssetContainer, Object.assign({}, modal.props));
        case 'ImageViewerSignInventory':
            return _jsx(ImageViewerSignInventoryContainer, {});
        case 'InventoryTeaserModal':
            return _jsx(InventoryTeaserModalContainer, {});
        case 'AssetConverterModal':
            return _jsx(AssetConverterModalContainer, {});
        case 'ABTMIntroModal':
            return _jsx(ABTMIntroModalContainer, {});
        case 'SignInventoryIntroModal':
            return _jsx(SignInventoryIntroModalContainer, {});
        case 'DefaultTreatmentsIntroModal':
            return _jsx(DefaultTreatmentsIntroModalContainer, {});
        case 'MonthPickerModalContainer':
            return _jsx(MonthPickerModalContainer, Object.assign({}, modal.props));
        case 'YearPickerModalContainer':
            return _jsx(YearPickerModalContainer, Object.assign({}, modal.props));
        case 'DatePickerModalContainer':
            return _jsx(DatePickerModalContainer, Object.assign({}, modal.props));
        case 'DueDatePickerModalContainer':
            return _jsx(DueDatePickerModalContainer, Object.assign({}, modal.props));
        case 'AssigneeSelectModalContainer':
            return _jsx(AssigneeSelectModalContainer, Object.assign({}, modal.props));
        case 'MultiselectModalContainer':
            return _jsx(MultiselectModalContainer, Object.assign({}, modal.props));
        case 'CategorySelectModalContainer':
            return _jsx(CategorySelectModalContainer, Object.assign({}, modal.props));
        case 'TreatmentSelectModalContainer':
            return _jsx(TreatmentSelectModalContainer, Object.assign({}, modal.props));
        case 'ExportModal':
            return _jsx(ExportModalContainer, Object.assign({}, modal.props));
        case 'LinkTasksModalContainer':
            return _jsx(LinkTasksModalContainer, Object.assign({}, modal.props));
        case 'LinkAssetModalContainer':
            return _jsx(LinkAssetModalContainer, Object.assign({}, modal.props));
        case 'FileGallerySignInventory':
            return _jsx(FileGallerySignInventoryContainer, {});
        case 'SignTypeSelectModalContainer':
            return _jsx(SignTypeSelectModalContainer, Object.assign({}, modal.props));
        case 'FileUploadModal':
            return _jsx(FileUploadModalContainer, Object.assign({}, modal.props));
        case 'AutoUpdateModalContainer':
            return _jsx(AutoUpdateModalContainer, Object.assign({}, modal.props));
        case 'DataUpdateModalContainer':
            return _jsx(DataUpdateModalContainer, Object.assign({}, modal.props));
        case 'FileGalleryTaskContainer':
            return _jsx(FileGalleryTaskContainer, {});
        case 'FileGalleryAssetContainer':
            return _jsx(FileGalleryAssetContainer, {});
        case 'WorkingHoursPickerModalContainer':
            return _jsx(WorkingHoursPickerModalContainer, Object.assign({}, modal.props));
        case 'MissingCostsDataModalContainer':
            return _jsx(MissingCostsDataModalContainer, {});
        case 'RemainingServiceLifeIntroModal':
            return _jsx(RemainingServiceLifeIntroModalContainer, {});
    }
}
const noAnimationModals = [
    'ImageViewerMarker',
    'ImageViewerCondition',
    'ImageViewerObject',
    'ImageViewerSign',
    'ImageViewerAsset',
    'ImageViewerSignInventory'
];
const isFileGalleryModalPresent = (modals) => {
    const index = modals.findIndex((modal) => modal.id === 'FileGallerySignInventory' ||
        modal.id === 'FileGalleryTaskContainer' ||
        modal.id === 'FileGalleryAssetContainer');
    return index !== -1 && index !== modals.length - 1;
};
export function ModalOverlayContainer() {
    const key = useId();
    const { modals, removeModal } = useModalContext();
    const modalElements = modals.map((modal) => (_jsx(ModalOverlay, Object.assign({ animate: !noAnimationModals.includes(modal.id), overGallery: isFileGalleryModalPresent(modals), "data-overlay": true }, { children: getModal(modal) }), modal.id)));
    useEscapeKeyListener(() => {
        if (modals.length > 0) {
            removeModal(modals[modals.length - 1].id);
        }
    });
    return createPortal(_jsx(AnimatePresence, Object.assign({ mode: "sync" }, { children: modalElements }), key), document.body);
}
