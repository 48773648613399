import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { resetAssetMultiSelect } from '../state/slices/leftPanelAsset';
import { clearConditionMultiSelectedFeatures } from '../state/slices/leftPanelCondition';
import { resetMarkerMultiSelect } from '../state/slices/leftPanelMarker';
import { resetObjectMultiSelect } from '../state/slices/leftPanelObject';
import { resetPlanMultiSelect } from '../state/slices/leftPanelPlan';
import { resetSignInventoryMultiSelect } from '../state/slices/leftPanelSignInventory';
export function useResetMultiselectOnLayerSelect() {
    const { selectedLayerId, layerList } = useAppSelector((state) => state.layer);
    const dispatch = useAppDispatch();
    const resetAllLayersMultiselectLeftPanelExceptSelected = useCallback(() => {
        for (const layer of layerList) {
            if (selectedLayerId !== layer.id) {
                switch (layer.id) {
                    case 'asset':
                        dispatch(resetAssetMultiSelect());
                        break;
                    case 'condition':
                        dispatch(clearConditionMultiSelectedFeatures());
                        break;
                    case 'marker':
                        dispatch(resetMarkerMultiSelect());
                        break;
                    case 'object':
                        dispatch(resetObjectMultiSelect());
                        break;
                    case 'plan':
                        dispatch(resetPlanMultiSelect());
                        break;
                    case 'sign-inventory':
                        dispatch(resetSignInventoryMultiSelect());
                        break;
                    default:
                        break;
                }
            }
        }
    }, [dispatch, layerList, selectedLayerId]);
    useEffect(() => {
        resetAllLayersMultiselectLeftPanelExceptSelected();
    }, [resetAllLayersMultiselectLeftPanelExceptSelected, selectedLayerId]);
}
