var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addMarkerFiles } from '../services/marker';
import { useAppDispatch } from '../state/hooks';
import { fetchMarkers } from '../state/slices/leftPanelMarker';
import { fetchMarkerGeoJSON } from '../state/slices/mainMap';
import { addMarkerCreatorFiles } from '../state/slices/markerCreator';
import { fetchMarker } from '../state/slices/markerDetail';
import { addNotificationMessage } from '../state/slices/notification';
import { fetchMarkerFolderTk } from '../state/slices/shared';
import { useFileUpload } from './useFileUpload';
export function useMarkerFileUpload() {
    const dispatch = useAppDispatch();
    const { selectFilesDialog, uploadFile } = useFileUpload();
    function handleFileUpload(index, totalFiles) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: {
                    componentId: 'toast.uploadfile',
                    variables: {
                        Number_1: index,
                        count: totalFiles
                    }
                }
            }
        }));
    }
    function handleUploadError() {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    function selectFilesForUpload(markerId, selectFolder) {
        uploadFiles({
            markerId,
            selectFilesDialog,
            selectFolder,
            uploadFile,
            onFileUpload: handleFileUpload,
            onError: handleUploadError,
            dispatch
        }).then(() => {
            if (markerId !== -1) {
                dispatch(fetchMarker(markerId));
            }
            dispatch(fetchMarkers());
            dispatch(fetchMarkerGeoJSON());
            dispatch(fetchMarkerFolderTk());
        });
    }
    return { selectFilesForUpload };
}
function uploadFiles({ markerId, selectFolder, selectFilesDialog, uploadFile, onFileUpload, onError, dispatch }) {
    return __awaiter(this, void 0, void 0, function* () {
        const ACCEPTED_FILE_TYPES = [
            'jpg',
            'jpeg',
            'png',
            'data',
            'mp4',
            'flac',
            'pdf',
            'doc',
            'docx',
            'rtf',
            'odt',
            'xls',
            'xlsx'
        ].map((ext) => `.${ext}`);
        yield (function () {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const files = yield selectFilesDialog({
                        accept: ACCEPTED_FILE_TYPES,
                        resolveErrorWithDescription: true
                    });
                    if (!files) {
                        throw new Error();
                    }
                    if ('error' in files && 'invalidFormat' in files) {
                        dispatch(addNotificationMessage({
                            type: 'error',
                            ditto: {
                                title: {
                                    componentId: 'toast.invalidimagefile'
                                }
                            }
                        }));
                        return;
                    }
                    else if ('error' in files) {
                        dispatch(addNotificationMessage({
                            type: 'error',
                            ditto: {
                                title: {
                                    componentId: 'toast.uploadmaximumsize',
                                    variables: { FileName: files.file, Upload_Maximum_Size: '10 MB' }
                                }
                            }
                        }));
                        return;
                    }
                    const { folderId } = yield selectFolder();
                    if (folderId === null) {
                        // No folder selected => upload cancelled
                        return;
                    }
                    const uuids = yield awaitInSequence(files.map((file, index) => {
                        onFileUpload(index + 1, files.length);
                        return () => uploadFile(file, 'road-safety');
                    }));
                    const filesWithUuids = uuids.map((id, index) => ({
                        uuid: id,
                        name: files[index].name
                    }));
                    if (markerId === -1) {
                        dispatch(addMarkerCreatorFiles(filesWithUuids.map((item) => ({
                            uuid: item.uuid,
                            name: item.name,
                            id: -1,
                            fileLink: '',
                            fileLinkToDownload: '',
                            createdAt: new Date().toISOString(),
                            mimeType: '',
                            folderId: folderId
                        }))));
                    }
                    else {
                        yield addMarkerFiles({ markerId, folderId, files: filesWithUuids });
                    }
                }
                catch (error) {
                    onError();
                }
            });
        })();
    });
}
function awaitInSequence(actions) {
    return __awaiter(this, void 0, void 0, function* () {
        const results = [];
        for (const action of actions) {
            results.push(yield action());
        }
        return results;
    });
}
