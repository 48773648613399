var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { post, postFormData } from '../common/fetch';
import config from '../config/config.json';
import { accountsAppClient } from '../openapi';
const ENDPOINT_ACCOUNT_USER_LIST = 'v1/accounts-app/account/user/list';
const ENDPOINT_ACCOUNT_CURRENT_USER = 'v1/accounts-app/user/getbytoken';
const ENDPOINT_ACCOUNT_GET = 'v1/accounts-app/account/get';
const ENDPOINT_USER_ACCOUNT_ACCESS_LIST = 'v1/accounts-app/user/account/list';
const GW_ROLE_LIST_ENDPOINT = 'v1/accounts-app/role/list';
const GW_DEPARTMENT_LIST_ENDPOINT = 'v1/accounts-app/department/list';
const GW_UPLOAD_IMAGE_ENDPOINT = 'v1/accounts-app/user/profile-image';
const GW_CONFIRM_INVITE = 'v1/accounts-app/user/confirm';
const GW_REGISTER_CREATE_USER = 'v1/accounts-app/user/create';
const GW_REGISTER_ADD_USER_TO_GROUP = 'v1/accounts-app/account/user/add';
const GW_REGISTER_CHECK_EMAIL_ENDPOINT = 'v1/accounts-app/user/check';
const GW_USER_UPDATE_ENDPOINT = 'v1/accounts-app/user/update';
const GW_VALIDATE_INVITE = 'v1/accounts-app/invite/validate';
const GW_UPDATE_PASSWORD = 'v1/accounts-app/user/password/change';
export const ERROR_CODE_INVITE_NOT_FOUND = 10050;
export const ERROR_CODE_INVITE_IS_EXPIRED = 10051;
export const ERROR_CODE_INVITE_IS_SPENT = 10052;
export function getUserAccountList() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(ENDPOINT_ACCOUNT_USER_LIST, {}, undefined, true);
    });
}
export function getUserAccountAccessList() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(ENDPOINT_USER_ACCOUNT_ACCESS_LIST, {}, undefined, true);
    });
}
export function getCurrentUser() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(ENDPOINT_ACCOUNT_CURRENT_USER, {}, undefined, true);
    });
}
export function getAuthTokenForStorybook() {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${config.keycloak.url}/realms/vialytics/protocol/openid-connect/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                client_id: config.keycloak.clientId,
                grant_type: 'password',
                scope: 'openid',
                username: config.storybook.username,
                password: config.storybook.password
            })
        });
        const parsedResponse = yield response.json();
        const accessToken = parsedResponse.access_token;
        const targetSchema = config.storybook.targetSchema;
        return { accessToken, targetSchema };
    });
}
export function checkIfEmailExists(email) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_REGISTER_CHECK_EMAIL_ENDPOINT, {
            email
        }, undefined, true);
    });
}
export function addToGroup(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_REGISTER_ADD_USER_TO_GROUP, data, undefined, true);
    });
}
export function validateUserInvite(token) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_VALIDATE_INVITE, { token }, undefined, true);
    });
}
export function confirmUserInvite(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_CONFIRM_INVITE, data, undefined, true);
    });
}
export function createNewUser(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_REGISTER_CREATE_USER, Object.assign(Object.assign({}, data), { duration: 0 }), undefined, true);
    });
}
export function updateUserPassword(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_UPDATE_PASSWORD, data, undefined, true);
    });
}
export function getRoleList() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_ROLE_LIST_ENDPOINT, {}, undefined, true);
    });
}
export function getDepartmentList(locale) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_DEPARTMENT_LIST_ENDPOINT, { locale }, undefined, true);
    });
}
export function getTeamAccount() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(ENDPOINT_ACCOUNT_GET, {}, undefined, true);
    });
}
export function updateUserData(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_USER_UPDATE_ENDPOINT, data, undefined, true);
    });
}
export function updateProfileImage(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield postFormData(GW_UPLOAD_IMAGE_ENDPOINT, data, undefined, true);
    });
}
export function updateUserPopupSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceUserUpdatePopupSettings(body);
    });
}
export function updateUserClientSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceUserUpdateClientSettings(body);
    });
}
