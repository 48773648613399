import { MARKER_PRIORITY } from '../../common/consts/marker';
import { useDittoWrapper } from '../useDittoWrapper';
export function usePriorityLabel(statusName) {
    const noPriority = useDittoWrapper({ componentId: 'marker.priority.nopriority' });
    const low = useDittoWrapper({ componentId: 'marker.priority.low' });
    const medium = useDittoWrapper({ componentId: 'marker.priority.medium' });
    const high = useDittoWrapper({ componentId: 'marker.priority.high' });
    const noneLabel = useDittoWrapper({ componentId: 'marker.changelog.none' });
    switch (statusName) {
        case MARKER_PRIORITY.noPriority:
            return noPriority;
        case MARKER_PRIORITY.low:
            return low;
        case MARKER_PRIORITY.medium:
            return medium;
        case MARKER_PRIORITY.high:
            return high;
        default:
            return noneLabel;
    }
}
