var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getExternal } from '../common/fetch';
import config from '../config/config.json';
const MAPBOX_REVERSE_GEOCODING_ENDPOINT = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
export const MapboxContextTypes = {
    country: 'country',
    region: 'region',
    postcode: 'postcode',
    district: 'district',
    place: 'place',
    locality: 'locality',
    address: 'address',
    street: 'street'
};
const RESULT_FORMAT = 'json';
const RESULT_LIMIT = 7;
const RESULT_TYPES = 'postcode,district,place,locality,address,poi';
export function getMapboxReverseGeocoding({ input, proximity, bbox, language }) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield getExternal(`${MAPBOX_REVERSE_GEOCODING_ENDPOINT}/${input}.${RESULT_FORMAT}?access_token=${config.mapbox.token}&proximity=${proximity.lng},${proximity.lat}&bbox=${bbox}&types=${RESULT_TYPES}&limit=${RESULT_LIMIT}&language=${language}`);
    });
}
