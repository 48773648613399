import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { AnimatePresence } from 'framer-motion';
import { CATEGORY_COLORS } from '../../../../../common/color';
import { useFloatingDropdown } from '../../../../../hooks/forms/useFloatingDropdown';
import { ActionModal } from '../../../../ActionModal/ActionModal';
import { FloatingDropdown } from '../../../../FloatingDropdown/FloatingDropdown';
import { IconResizer } from '../../../../IconResizer/IconResizer';
import { Caret, Check } from '../../../../icons';
import styles from './CategoryColorSelect.module.css';
export function CategoryColorSelect({ color, isReadOnly, onSelect }) {
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown({ offset: { top: 12, left: 0, right: 0 } });
    function handleSelect(color) {
        hide();
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(color);
    }
    const control = (_jsx("div", Object.assign({ ref: buttonRef, onClick: isReadOnly ? undefined : toggle, className: classes(styles['item'], isReadOnly && styles['item--disabled'], isFloatingOpen && styles['item--active']), "data-test": "color-option-dropdown", style: { background: color } }, { children: _jsx("div", Object.assign({ className: styles['item-inner'] }, { children: !isReadOnly && _jsx(Caret, {}) })) })));
    return (_jsxs(_Fragment, { children: [control, _jsx(AnimatePresence, { children: isFloatingOpen && (_jsx(FloatingDropdown, Object.assign({}, position, { content: _jsx(ActionModal, Object.assign({ className: styles['color-options'] }, { children: CATEGORY_COLORS.map((item) => (_jsx("div", Object.assign({ onClick: (event) => {
                                event.stopPropagation();
                                handleSelect(item);
                            }, onMouseDown: (event) => {
                                event.stopPropagation();
                                handleSelect(item);
                            } }, { children: _jsx("div", Object.assign({ className: styles['color-option'], "data-test": `color-option-${item}`, style: {
                                    background: item
                                } }, { children: item === color && (_jsx("div", Object.assign({ className: styles['color-option-selected'] }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Check, {}) })) }))) })) }), item))) })), onClickOutside: () => hide() }), 'dropdown')) })] }));
}
