var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { uniqueV2 } from '../../common/utils/array';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { batchUpdatManualInventory, getLightAssets } from '../../services/asset';
import { batchUpdateMarkers } from '../../services/marker';
import { bulkDelete, bulkUpdate } from '../../services/plan';
import { clearSelectedFeature } from './feature';
import { fetchAssetsTk } from './leftPanelAsset';
import { fetchMarkers } from './leftPanelMarker';
import { fetchPlans } from './leftPanelPlan';
import { invalidateCachedLinkedMarkers } from './linkedMarkers';
import { addNotificationMessage } from './notification';
const initialState = {
    status: 'idle'
};
export const updatePlans = createAppAsyncThunk('bulkEditor/updatePlans', (data, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = getState();
    const countryCode = (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode;
    const result = yield bulkUpdate(Object.assign(Object.assign({}, data), { countryCode }));
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.result !== undefined && result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchPlans());
        dispatch(clearSelectedFeature());
    }
    return result;
}));
export const deletePlans = createAppAsyncThunk('bulkEditor/deletePlans', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield bulkDelete(data);
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.result !== undefined && result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.plannedsegmentdeleted' }
            }
        }));
        dispatch(fetchPlans());
        dispatch(clearSelectedFeature());
    }
    return result;
}));
export const updateMarkers = createAppAsyncThunk('bulkEditor/updateMarkers', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield batchUpdateMarkers(data);
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.result !== undefined && result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchMarkers());
        dispatch(clearSelectedFeature());
    }
    dispatch(invalidateCachedLinkedMarkers());
    return result;
}));
export const updateAssets = createAppAsyncThunk('bulkEditor/updateAssets', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield batchUpdatManualInventory(data);
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.result !== undefined && result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchAssetsTk());
        dispatch(clearSelectedFeature());
    }
    return result;
}));
export const fetchLightAssetTk = createAppAsyncThunk('bulkEditor/fetchLightAssetTk', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const res = yield CancelableRequest.send(getLightAssets, {
        ids: uniqueV2(ids)
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    const assets = (_b = res.result) === null || _b === void 0 ? void 0 : _b.manualInventories;
    if (assets === null || assets === void 0 ? void 0 : assets.every((v) => v.inventoryTypeId === assets[0].inventoryTypeId)) {
        return assets[0].inventoryTypeId;
    }
    else {
        return undefined;
    }
}));
export const bulkEditorSlice = createSlice({
    name: 'bulkEditor',
    initialState,
    reducers: {
        setForm: (state, action) => {
            state.form = action.payload;
        },
        updateFormValue: (state, action) => {
            state.form = Object.assign(Object.assign({}, state.form), { [action.payload.field]: action.payload.value });
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        // Individual handlers
        builder
            .addCase(fetchLightAssetTk.fulfilled, (state, action) => {
            if (action.payload) {
                state.form = { inventoryTypeId: action.payload };
            }
        })
            // Actions that affect entire container
            .addMatcher(isAnyOf(updatePlans.fulfilled, updateMarkers.fulfilled, updateAssets.fulfilled, deletePlans.fulfilled), (state) => {
            state.status = 'succeeded';
            state.form = undefined;
        })
            .addMatcher(isAnyOf(updatePlans.pending, updateMarkers.pending, updateAssets.pending, deletePlans.pending), (state) => {
            state.status = 'loading';
        })
            .addMatcher(isAnyOf(updatePlans.rejected, updateMarkers.rejected, updateAssets.rejected, deletePlans.rejected), (state) => {
            state.status = 'failed';
        });
    }
});
export const { setForm, updateFormValue, reset: resetBulkEditor } = bulkEditorSlice.actions;
export default bulkEditorSlice.reducer;
