var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { deletePlan, getPlanDetails, updatePlanningItem } from '../../services/plan';
import { setIsLoadingActionbar } from './actionbar';
import { clearSelectedFeature } from './feature';
import { fetchPlans } from './leftPanelPlan';
import { fetchPlanGeoJSON } from './mainMap';
import { addNotificationMessage } from './notification';
import { resetSegmentForm } from './segmentForm';
import { fetchMarkerFilters, fetchPlanFilters } from './shared';
const initialState = {
    fetchStatus: 'idle',
    updateStatus: 'idle'
};
export const fetchPlan = createAppAsyncThunk('plan/fetchPlanDetails', (id, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    dispatch(resetSegmentForm());
    const state = getState();
    const countryCode = (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode;
    return CancelableRequest.send(getPlanDetails, { planId: id, countryCode });
}));
export const updatePlan = createAppAsyncThunk('plan/updatePlanDetails', (data, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const state = getState();
    const countryCode = (_b = state.auth.teamAccount) === null || _b === void 0 ? void 0 : _b.countryCode;
    const result = yield updatePlanningItem(Object.assign(Object.assign({}, data), { countryCode }));
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            },
            clearBeforeAdd: true
        }));
        yield Promise.all([
            dispatch(fetchPlans()),
            dispatch(fetchPlanGeoJSON()),
            dispatch(fetchPlanFilters()),
            dispatch(fetchMarkerFilters())
        ]);
    }
    return Object.assign(Object.assign({}, result), { data });
}));
export const removePlan = createAppAsyncThunk('plan/removePlan', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const count = yield deletePlan(id);
    dispatch(setIsLoadingActionbar(false));
    dispatch(addNotificationMessage({
        type: 'success',
        ditto: {
            title: { componentId: 'toast.plannedsegmentdeleted' }
        }
    }));
    dispatch(clearSelectedFeature());
    dispatch(resetSegmentForm());
    dispatch(fetchPlanGeoJSON());
    return {
        id,
        count
    };
}));
export const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        // Individual handlers
        builder
            // Actions that affect entire container
            .addCase(fetchPlan.fulfilled, (state, action) => {
            var _a;
            state.currentPlan = (_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.plan;
            state.fetchStatus = 'succeeded';
            state.updateStatus = 'idle';
        })
            .addCase(fetchPlan.pending, (state) => {
            state.fetchStatus = 'loading';
        })
            .addCase(fetchPlan.rejected, (state) => {
            state.fetchStatus = 'failed';
        })
            .addCase(updatePlan.pending, (state) => {
            state.updateStatus = 'loading';
        })
            .addCase(updatePlan.rejected, (state) => {
            state.updateStatus = 'failed';
        })
            .addCase(updatePlan.fulfilled, (state, action) => {
            var _a;
            state.updateStatus = 'succeeded';
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.newData) {
                state.currentPlan = action.payload.result.newData;
            }
        });
    }
});
export const { reset: resetPlan } = planSlice.actions;
export default planSlice.reducer;
