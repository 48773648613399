import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import styles from './AppLayout.module.css';
export const LAYERS_PANEL_WIDTH = 72;
export const PRESET_PANEL_HEIGHT = 72;
export const LEFT_PANEL_WIDTH = 369;
export const RIGHT_PANEL_WIDTH = 356;
export function AppLayout({ mode = 'default', layerPanel, presetPanel, filterPanel, map, detailPanel, notification, modalOverlay, minimapSwitch, actionbar, hintbox, floatingFilter, floatingPanel, minimapPreview, banner }) {
    const hideOnFullscreenMap = {
        display: mode === 'fullscreen-map' ? 'none' : undefined
    };
    const isGalleryModalActive = mode === 'gallery-modal';
    const filterPanelPresentWidth = (filterPanel && LEFT_PANEL_WIDTH) || 0;
    const detailPanelPresentWidth = (detailPanel && RIGHT_PANEL_WIDTH) || 0;
    return (_jsxs(LayoutGroup, { children: [_jsxs("div", Object.assign({ className: styles['wrapper'] }, { children: [banner && _jsx("div", Object.assign({ className: styles['banner'] }, { children: banner })), _jsxs("div", Object.assign({ className: classes(styles['container'], banner && styles['container__with-banner']) }, { children: [_jsx("div", Object.assign({ className: "mapzoom-container-global", style: {
                                    position: 'absolute',
                                    inset: 0,
                                    pointerEvents: mode === 'no-main-map' ? 'none' : 'all'
                                } }, { children: map })), _jsx(motion.div, Object.assign({ animate: { x: mode === 'fullscreen-map' ? -1 * LAYERS_PANEL_WIDTH : 0 }, initial: {
                                    x: -1 * LAYERS_PANEL_WIDTH
                                }, transition: { type: 'tween', ease: 'easeOut' }, className: styles['left-layer-panel'], style: hideOnFullscreenMap }, { children: layerPanel })), _jsxs("div", Object.assign({ className: styles['main-right-section'], style: {
                                    width: mode === 'fullscreen-map'
                                        ? '100%'
                                        : `calc(100% - ${LAYERS_PANEL_WIDTH}px)`
                                } }, { children: [_jsx(motion.div, Object.assign({ animate: {
                                            y: mode === 'fullscreen-map' ? -1 * PRESET_PANEL_HEIGHT : 0
                                        }, initial: {
                                            y: mode === 'fullscreen-map' ? 0 : -1 * PRESET_PANEL_HEIGHT
                                        }, transition: { type: 'tween', ease: 'easeOut' }, className: styles['top-preset-panel'], style: hideOnFullscreenMap }, { children: presetPanel })), _jsxs("div", Object.assign({ className: classes(styles['main-bottom-section'], banner && styles['main-bottom-section__with-banner']), style: {
                                            height: mode === 'fullscreen-map'
                                                ? '100%'
                                                : `calc(100% - ${PRESET_PANEL_HEIGHT}px)`
                                        } }, { children: [_jsx(AnimatePresence, { children: filterPanel && (_jsx(motion.div, Object.assign({ animate: {
                                                        x: mode === 'fullscreen-map'
                                                            ? -1 * LEFT_PANEL_WIDTH
                                                            : 0,
                                                        width: mode === 'no-main-map' ? '100%' : LEFT_PANEL_WIDTH
                                                    }, initial: {
                                                        x: -1 * LEFT_PANEL_WIDTH,
                                                        width: 0
                                                    }, exit: {
                                                        x: -1 * LEFT_PANEL_WIDTH,
                                                        width: 0
                                                    }, transition: {
                                                        type: 'tween',
                                                        ease: 'easeOut'
                                                    }, className: styles['left-filter-panel'], style: Object.assign({}, hideOnFullscreenMap) }, { children: filterPanel }), 'filter-panel')) }), _jsxs(motion.div, Object.assign({ layoutRoot: true, className: styles['middle-map-section'], style: {
                                                    visibility: mode === 'no-main-map' ? 'hidden' : 'visible',
                                                    width: mode === 'fullscreen-map'
                                                        ? '100%'
                                                        : `calc(100% - ${filterPanelPresentWidth}px - ${detailPanelPresentWidth}px)`
                                                } }, { children: [_jsx(motion.div, Object.assign({ layout: "position", transition: { type: 'tween', ease: 'easeOut' }, className: styles['minimap-switch'], style: {
                                                            pointerEvents: mode === 'fullscreen-map' ? 'none' : 'all',
                                                            opacity: mode === 'fullscreen-map' ? 0 : 1
                                                        } }, { children: minimapSwitch })), _jsx(motion.div, Object.assign({ className: styles['hintbox'], layout: "position", transition: { type: 'tween', ease: 'easeOut' } }, { children: _jsx("div", Object.assign({ style: {
                                                                marginRight: floatingPanel
                                                                    ? RIGHT_PANEL_WIDTH
                                                                    : undefined
                                                            } }, { children: hintbox })) })), _jsx(motion.div, Object.assign({ className: styles['actionbar'], layout: "position", transition: { type: 'tween', ease: 'easeOut' } }, { children: _jsx("div", Object.assign({ style: {
                                                                marginRight: floatingPanel
                                                                    ? RIGHT_PANEL_WIDTH
                                                                    : undefined
                                                            } }, { children: actionbar })) })), _jsx(motion.div, Object.assign({ layout: "position", transition: { type: 'tween', ease: 'easeOut' }, className: styles['floating-filter'], style: hideOnFullscreenMap }, { children: floatingFilter })), _jsx(AnimatePresence, { children: floatingPanel && (_jsx(motion.div, Object.assign({ className: styles['floating-panel'], transition: {
                                                                type: 'spring',
                                                                stiffness: 500,
                                                                damping: 60,
                                                                opacity: {
                                                                    duration: 0.15
                                                                }
                                                            }, variants: {
                                                                initial: {
                                                                    opacity: 0,
                                                                    scaleX: 0.75,
                                                                    scaleY: 0.75,
                                                                    x: '-75%'
                                                                },
                                                                enter: {
                                                                    opacity: 1,
                                                                    scaleX: 1,
                                                                    scaleY: 1,
                                                                    x: 0
                                                                }
                                                            }, initial: "initial", animate: "enter", exit: "initial" }, { children: floatingPanel }), 'floating-panel')) })] }), 'middle-map-section'), mode !== 'fullscreen-map' && (_jsx(AnimatePresence, { children: detailPanel && (_jsx(motion.div, Object.assign({ className: classes(styles['right-detail-section'], minimapPreview &&
                                                        styles['right-detail-section__clickthrough'], isGalleryModalActive &&
                                                        styles['right-detail-section__gallery']), transition: isGalleryModalActive
                                                        ? detailPanelModalTransition
                                                        : detailPanelEnterTransition, variants: detailPanelVariants, initial: "initial", exit: "initial", animate: isGalleryModalActive ? 'rounded' : 'enter', "data-test": "div-right-detail-section" }, { children: detailPanel }), 'detail-panel')) }))] }))] })), _jsx("div", Object.assign({ className: styles['notification-overlay'] }, { children: notification })), _jsx("div", Object.assign({ className: classes(styles['minimap-preview'], detailPanel && styles['minimap-preview__behind']) }, { children: minimapPreview }))] }))] })), _jsx("div", { children: modalOverlay }, 'modal-overlay')] }));
}
const detailPanelVariants = {
    initial: {
        x: RIGHT_PANEL_WIDTH
    },
    enter: {
        x: 0
    },
    rounded: {
        x: 0,
        borderRadius: '0 24px 0 0'
    }
};
const detailPanelEnterTransition = {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.5
};
const detailPanelModalTransition = {
    type: 'spring',
    stiffness: 740,
    damping: 70,
    mass: 1.2,
    delay: 0.1
};
