// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pPEvHOb6Q5Oux0tAzfT5{position:relative}.w3sjjV8FllIMP8vcczQN{height:100%;width:100%;object-fit:cover;object-position:center}.qDti7EXxYkaS2QHeNkPg{position:absolute}.QI3nYBtYxGKYVkgHZ2UE{display:inline-block;border:2px solid var(--root-yellow-6);border-radius:2px}.cVQLODlKH6lZvM9gVUmE{display:flex;align-items:center;position:relative;top:-4px;background:var(--root-yellow-6);border-radius:2px;padding-right:6px;font-size:9px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/ImageWithTag/ImageWithTag.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,sBAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,oBAAA,CACA,qCAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,QAAA,CACA,+BAAA,CACA,iBAAA,CACA,iBAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    object-position: center;\n}\n\n.tag {\n    position: absolute;\n}\n\n.frame {\n    display: inline-block;\n    border: 2px solid var(--root-yellow-6);\n    border-radius: 2px;\n}\n\n.tag-caption {\n    display: flex;\n    align-items: center;\n    position: relative;\n    top: -4px;\n    background: var(--root-yellow-6);\n    border-radius: 2px;\n    padding-right: 6px;\n    font-size: 9px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pPEvHOb6Q5Oux0tAzfT5`,
	"image": `w3sjjV8FllIMP8vcczQN`,
	"tag": `qDti7EXxYkaS2QHeNkPg`,
	"frame": `QI3nYBtYxGKYVkgHZ2UE`,
	"tag-caption": `cVQLODlKH6lZvM9gVUmE`
};
export default ___CSS_LOADER_EXPORT___;
