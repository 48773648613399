import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { SIGN_STATUS_CODE } from '../../../common/consts/trafficSign';
import { getSignTypeAbbreviation } from '../../../common/convert/signInventory';
import { getLanguageFromCountryCode } from '../../../common/utils/locale';
import { useAppSelector } from '../../../state/hooks';
import { ActionButton } from '../../ActionButton/ActionButton';
import { IconResizer } from '../../IconResizer/IconResizer';
import { SparkleMagicApplied, Task, VerifiedApproved } from '../../icons';
import { ImageCrop } from '../../ImageCrop/ImageCrop';
import { TextOverflow } from '../../TextOverflow/TextOverflow';
import { Vignette } from '../../Vignette/Vignette';
import { ImageEnlarge } from '../ImageEnlarge/ImageEnlarge';
import styles from './SignInventory.module.css';
const IMAGE_SIZE = { height: 90, width: 140 };
export function SignInventory({ content: { sign, image }, withAction, isLimited, onImageEnlargeClick, onAddMarkerButtonClick, checkbox, statusCode, isViewer }) {
    const { teamAccount } = useAppSelector((state) => state.auth);
    const language = teamAccount && getLanguageFromCountryCode(teamAccount.countryCode);
    let status;
    switch (statusCode) {
        case SIGN_STATUS_CODE.predicted:
            status = (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(SparkleMagicApplied, {}) })), backgroundColor: "var(--badge-green-light)" }));
            break;
        case SIGN_STATUS_CODE.confirmed:
            status = (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(VerifiedApproved, {}) })), backgroundColor: "var(--primary-grey)" }));
            break;
    }
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-sign" }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle'], isLimited && styles['limited']) }, { children: [checkbox, sign === null || sign === void 0 ? void 0 : sign.bastId, " \u2022 ID: ", sign === null || sign === void 0 ? void 0 : sign.id] })), sign && (_jsxs("div", Object.assign({ className: styles['sign-main'] }, { children: [sign.typeIcon, _jsx("span", Object.assign({ className: styles['tag-text'] }, { children: _jsx("div", Object.assign({ lang: language }, { children: _jsx(TextOverflow, { text: _jsx(Ditto, { componentId: getSignTypeAbbreviation(sign.subSpecifiedClass) }), maxLines: 2, tooltip: "small", tooltipPosition: "up", tooltipAlign: 'start', wordBreak: 'normal' }) })) }))] }))), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [sign && (_jsxs(_Fragment, { children: [status, sign.damageIcon && (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: sign.damageIcon })), backgroundColor: "var(--primary-grey)" }))] })), !isViewer && withAction && (_jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx(ActionButton, { className: styles['action-button'], icon: _jsx(Task, {}), tooltip: {
                                        text: _jsx(Ditto, { componentId: "tooltip.createmarker" }),
                                        arrow: 'up'
                                    }, onClick: onAddMarkerButtonClick }) })))] }))] })), (image === null || image === void 0 ? void 0 : image.url) && (_jsxs("div", Object.assign({ className: styles['right'], style: IMAGE_SIZE }, { children: [withAction && _jsx(ImageEnlarge, { onClick: onImageEnlargeClick }), _jsx(ImageCrop, { className: styles['image'], src: image.url, croppedImageUrl: image.croppedImageUrl, alt: "Traffic Sign", tag: image.tag, imageSize: IMAGE_SIZE })] })))] })));
}
