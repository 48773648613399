import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { IconResizer } from '../IconResizer/IconResizer';
import { External, InfoColored } from '../icons';
import styles from './InfoBox.module.css';
export function InfoBox({ content, linkText, linkOnClick, icon, variant, width, className, linkExternalIcon }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], variant && styles[variant], icon === false && styles['no-icon'], className), style: {
            width
        } }, { children: [icon !== false && (_jsx("span", Object.assign({ className: classes(styles['icon']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: icon || _jsx(InfoColored, {}) })) }))), _jsx("p", { children: content }), linkText && linkOnClick && (_jsxs("a", Object.assign({ className: classes(styles['link']), onClick: () => linkOnClick() }, { children: [linkText, linkExternalIcon && (_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(External, {}) })))] })))] })));
}
