import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
export function SelectedWeekdays({ days, locale }) {
    if (days.length === 0) {
        return null;
    }
    const now = new Date();
    const daysOfThisWeek = eachDayOfInterval({
        start: startOfWeek(now, { locale }),
        end: endOfWeek(now, { locale })
    });
    if (days.length === 1) {
        return _jsx(_Fragment, { children: format(daysOfThisWeek[days[0]], 'cccc', { locale }) });
    }
    return (_jsx(_Fragment, { children: days
            .sort((a, b) => a - b)
            .map((value) => format(daysOfThisWeek[value], 'ccc', { locale }))
            .join(', ') }));
}
