import { MARKER_STATUS } from '../../common/consts/marker';
import { useDittoWrapper } from '../useDittoWrapper';
export function useStatusLabel(statusName) {
    const open = useDittoWrapper({ componentId: 'marker.status.open' });
    const inProgress = useDittoWrapper({ componentId: 'marker.status.inprogress' });
    const done = useDittoWrapper({ componentId: 'marker.changelog.state.done' });
    const noneLabel = useDittoWrapper({ componentId: 'marker.changelog.none' });
    switch (statusName) {
        case MARKER_STATUS.open:
            return open;
        case MARKER_STATUS.inProgress:
            return inProgress;
        case MARKER_STATUS.closed:
            return done;
        default:
            return noneLabel;
    }
}
