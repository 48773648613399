import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Assets, Treatment0, Treatment1, Treatment2, Treatment3 } from '../components/icons';
import { getMarkerPriorityName } from './convert';
export function Icon({ name, size = 24, dataTest }) {
    return _jsx("img", { width: size, height: size, src: `/${name}.svg`, "data-test": dataTest });
}
const ASSET_ICON_MIN = 1;
const ASSET_ICON_MAX = 42;
export function AssetIcon({ iconId, size = 24, format = 'svg', suffix = '' }) {
    if (!iconId || iconId < ASSET_ICON_MIN || iconId > ASSET_ICON_MAX) {
        return _jsx(Assets, {});
    }
    return _jsx("img", { width: size, height: size, src: `/${iconId}${suffix}.${format}` });
}
export function MarkerIcon({ priorityId, width, state = 'default' }) {
    return _jsx("img", { src: `/marker-${getMarkerPriorityName(priorityId)}-${state}.png`, width: width });
}
export function TreatmentIcon(categoryId) {
    switch (categoryId) {
        case 1:
            return _jsx(Treatment1, {});
        case 2:
            return _jsx(Treatment2, {});
        case 3:
            return _jsx(Treatment3, {});
        default:
            return _jsx(Treatment0, {});
    }
}
export function Logo({ variant, size = 64 }) {
    return _jsx("img", { width: size, src: `/logo-${variant}.png` });
}
