import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { NOTE_COLORS } from '../../common/consts/note';
import { getNoteIcon } from '../../common/convert/note';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { Bin, Check } from '../icons';
import styles from './NoteCreator.module.css';
export function NoteCreator({ color, text, date, onSetEdit, onSaveClick, onRemoveClick, onChangeFormData, isClosed = false, isEdit, direction = 'up' }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'note.enter' });
    const colorOptions = NOTE_COLORS.map((c) => (_jsx("div", Object.assign({ className: classes(styles['color-wrapper']), onClick: () => onChangeFormData && onChangeFormData({ color: c }) }, { children: _jsx("a", { className: classes(styles['color'], styles[c], color === c && styles['selected']), "data-test": `note-color-${c}${color === c ? '-selected' : ''}` }) }), c)));
    function handleContainerClick() {
        onSetEdit && onSetEdit();
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles[`container__${direction}`]), onClick: handleContainerClick }, { children: [_jsx("div", Object.assign({ className: classes(styles['toggle-wrapper']) }, { children: _jsx("a", Object.assign({ className: classes(styles['toggle']) }, { children: getNoteIcon(color) })) })), !isClosed && (_jsx("div", Object.assign({ className: classes(styles['note']), "data-test": "note-modal" }, { children: isEdit ? (_jsxs(_Fragment, { children: [_jsx("textarea", { required: true, className: classes(styles['edit-text']), placeholder: placeholderLabel, onChange: (e) => onChangeFormData && onChangeFormData({ text: e.target.value }), value: text, "data-test": "note-textarea" }), _jsxs("div", Object.assign({ className: classes(styles['footer']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['colors']) }, { children: colorOptions })), _jsxs("div", Object.assign({ className: classes(styles['buttons']) }, { children: [_jsx(Button, { className: classes(styles['remove']), icon: _jsx(Bin, {}), variant: "square", onClick: onRemoveClick, "data-test": "remove-note-button" }), _jsx(Button, { icon: _jsx(Check, {}), variant: "square", color: "yellow", onClick: () => {
                                                if (!onSaveClick) {
                                                    return;
                                                }
                                                onSaveClick({ text, color });
                                            }, "data-test": "note-save-button" })] }))] }))] })) : (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classes(styles['headline']) }, { children: _jsx("span", Object.assign({ className: classes(styles['date']) }, { children: date.dateTime })) })), _jsx("p", Object.assign({ className: classes(styles['text']) }, { children: text }))] })) })))] })));
}
