// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BnEAz0l2hd9Bv_GI8oix{display:flex;flex-direction:column;align-items:stretch;padding:24px;background:var(--primary-white);box-shadow:var(--box-shadow-regular);border-radius:16px;width:520px}.dDcDc8maNT1Dc13SDX9f{overflow-x:hidden;overflow-y:auto;height:240px;width:100%}.HgYF4iYAtMjg0EIjqTmQ{padding:0 0 0 4px;height:48px;font-weight:600;font-size:20px;line-height:24px}.FmIZ4ZfMnifa7PmFhilC{margin-top:40px}.nLLJ4qJ28qTn0pOzYc6Y{color:#1556eb;margin:0}`, "",{"version":3,"sources":["webpack://./src/components/AccountSelector/AccountSelector.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,YAAA,CAEA,+BAAA,CAGA,oCAAA,CACA,kBAAA,CACA,WAAA,CAGJ,sBACI,iBAAA,CACA,eAAA,CACA,YAAA,CACA,UAAA,CAGJ,sBACI,iBAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,eAAA,CAGJ,sBACI,aAAA,CACA,QAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    padding: 24px;\n\n    background: var(--primary-white);\n    /* Elevated 02 */\n\n    box-shadow: var(--box-shadow-regular);\n    border-radius: 16px;\n    width: 520px;\n}\n\n.options {\n    overflow-x: hidden;\n    overflow-y: auto;\n    height: 240px;\n    width: 100%;\n}\n\n.title {\n    padding: 0 0 0 4px;\n    height: 48px;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 24px;\n}\n\n.bottom-section {\n    margin-top: 40px;\n}\n\n.logout-button {\n    color: #1556eb;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BnEAz0l2hd9Bv_GI8oix`,
	"options": `dDcDc8maNT1Dc13SDX9f`,
	"title": `HgYF4iYAtMjg0EIjqTmQ`,
	"bottom-section": `FmIZ4ZfMnifa7PmFhilC`,
	"logout-button": `nLLJ4qJ28qTn0pOzYc6Y`
};
export default ___CSS_LOADER_EXPORT___;
