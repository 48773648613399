// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TbiF6xm7XtS7MJUmUmDT{width:480px}.d2DMF4lXa1wyJJIZeMuj{width:100%;display:flex;justify-content:flex-end;gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/AssetConverterModal/AssetConverterModal.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":[".container {\n    width: 480px;\n}\n\n.footer {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TbiF6xm7XtS7MJUmUmDT`,
	"footer": `d2DMF4lXa1wyJJIZeMuj`
};
export default ___CSS_LOADER_EXPORT___;
