var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getURLSearchParams } from '../../common/url';
export const initialSelectedFeature = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.selectedFeature;
const initialState = {
    selectedFeature: initialSelectedFeature
};
export const featureSlice = createSlice({
    name: 'feature',
    initialState,
    reducers: {
        setSelectedFeature: (state, action) => {
            state.selectedFeature = action.payload;
        },
        setSelectedFeatures: (state, action) => {
            state.multiselectedFeatures = action.payload;
        },
        clearSelectedFeature: (state) => {
            state.selectedFeature = undefined;
        },
        setHoveredFeature: (state, action) => {
            state.hoveredFeature = action.payload;
        },
        clearHoveredFeature: (state) => {
            state.hoveredFeature = undefined;
        },
        setClickedFeature: (state, action) => {
            state.clickedFeature = action.payload;
        },
        clearClickedFeature: (state) => {
            state.clickedFeature = undefined;
        },
        setHighlightedFeature: (state, action) => {
            state.highlightedFeature = action.payload;
        },
        clearHighlightedFeature: (state) => {
            state.highlightedFeature = undefined;
        },
        reset: () => initialState
    }
});
export const { setSelectedFeature, clearSelectedFeature, setHoveredFeature, clearHoveredFeature, setHighlightedFeature, clearClickedFeature, setClickedFeature, clearHighlightedFeature, reset: resetSelectedFeature, setSelectedFeatures } = featureSlice.actions;
export default featureSlice.reducer;
