// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T_PF845iKu5kyjVuOp4E{display:flex;align-items:center;padding:8px 0 8px 8px}.lO5rcAnrdnVucNw7Z8Jn{width:100%;font-size:14px;line-height:130%;opacity:.6}`, "",{"version":3,"sources":["webpack://./src/components/Files/Folder/Folder.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,qBAAA,CAGJ,sBACI,UAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".headline {\n    display: flex;\n    align-items: center;\n    padding: 8px 0 8px 8px;\n}\n\n.name {\n    width: 100%;\n    font-size: 14px;\n    line-height: 130%;\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": `T_PF845iKu5kyjVuOp4E`,
	"name": `lO5rcAnrdnVucNw7Z8Jn`
};
export default ___CSS_LOADER_EXPORT___;
