var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createPortal } from 'react-dom';
import { Ditto } from 'ditto-react';
import { AnimatePresence } from 'framer-motion';
import { downloadFile } from '../../common/utils/downloadFile';
import { FileGallery } from '../../components/FileGallery/FileGallery';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useAssetFileUpload } from '../../hooks/useAssetFileUpload';
import { useUserRole } from '../../hooks/useUserRole';
import { removeAssetFiles } from '../../services/asset';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchAssetInfoById, setAssetSelectedFileId } from '../../state/slices/assetDetail';
import { fetchAssetsTk } from '../../state/slices/leftPanelAsset';
import { addNotificationMessage } from '../../state/slices/notification';
import { fetchAssetFolderTk, fetchAssetTypes } from '../../state/slices/shared';
export function FileGalleryAssetContainer() {
    var _a;
    const dispatch = useAppDispatch();
    const { addModal, removeModal, modals } = useModalContext();
    const { assetInfo, selectedFileId } = useAppSelector((state) => state.assetDetail);
    const { assetFolders: foldersList } = useAppSelector((state) => state.shared);
    const { selectFilesForUpload } = useAssetFileUpload();
    const dateTimeFormatter = useDateTimeFormatter();
    const { isEditor } = useUserRole();
    function handleCloseClick() {
        dispatch(setAssetSelectedFileId());
        removeModal('FileGalleryAssetContainer');
    }
    function handleOutsideClick() {
        // Close modal only if the user clicked outside *and* there are no modals on top of gallery
        const index = modals.findIndex((modal) => modal.id === 'FileGalleryAssetContainer');
        const isGalleryModalLast = index === modals.length - 1;
        if (isGalleryModalLast) {
            handleCloseClick();
        }
    }
    function handleFileAddClick() {
        var _a, _b;
        selectFilesForUpload((_a = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.assetId) !== null && _a !== void 0 ? _a : 0, (_b = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id) !== null && _b !== void 0 ? _b : 0, () => {
            return new Promise((resolve) => {
                addModal({
                    id: 'FileUploadModal',
                    props: {
                        folders: foldersList,
                        onUploadClick: (folderId) => {
                            resolve({ folderId });
                        },
                        onCloseClick: () => {
                            resolve({ folderId: null });
                        }
                    }
                });
            });
        });
    }
    const files = (_a = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.files.map((file) => {
        var _a, _b;
        const folder = foldersList.find((item) => item.id === file.folderId);
        const componentId = (folder === null || folder === void 0 ? void 0 : folder.name) ? '' : (_a = folder === null || folder === void 0 ? void 0 : folder.componentId) !== null && _a !== void 0 ? _a : '';
        return {
            id: file.id,
            thumb: null,
            name: file.name,
            folder: { name: (_b = folder === null || folder === void 0 ? void 0 : folder.name) !== null && _b !== void 0 ? _b : '', componentId: componentId },
            link: file.fileLink,
            fileLinkToDownload: file.fileLinkToDownload,
            mimeType: file.mimeType,
            createdAt: dateTimeFormatter(file.createdAt).dateTime
        };
    })) !== null && _a !== void 0 ? _a : [];
    const selectedIndex = files.findIndex((file) => file.id === selectedFileId);
    function handleSelectFileId(id) {
        dispatch(setAssetSelectedFileId(id));
    }
    function handleDownloadFileClick(fileId) {
        const file = files.find((file) => file.id === fileId);
        downloadFile((file === null || file === void 0 ? void 0 : file.fileLinkToDownload) || '', (file === null || file === void 0 ? void 0 : file.name) || '');
    }
    function handleFileDeleteClick(fileId) {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    yield removeAssetFiles({ assetId: assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.assetId, fileIds: [fileId] });
                    yield dispatch(fetchAssetInfoById((_a = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id) !== null && _a !== void 0 ? _a : 0));
                    dispatch(fetchAssetsTk());
                    dispatch(fetchAssetTypes());
                    dispatch(fetchAssetFolderTk());
                    dispatch(addNotificationMessage({
                        type: 'success',
                        ditto: { title: { componentId: 'toast.filedeleted' } }
                    }));
                }),
                title: _jsx(Ditto, { componentId: "prompt.delete.file.deletefile" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.file.text" })
            }
        });
    }
    const isModalPresent = modals.find((modal) => modal.id === 'FileGalleryAssetContainer');
    return createPortal(_jsx(AnimatePresence, Object.assign({ mode: "wait" }, { children: isModalPresent && (_jsx(ImageViewerModal, { onClickOutside: handleOutsideClick, imageViewer: _jsx(FileGallery, { files: files, activeIndex: selectedIndex, isEditor: isEditor, setSelectedFileId: handleSelectFileId, onDownloadClick: handleDownloadFileClick, onDoneClick: handleCloseClick, onFileAddClick: handleFileAddClick, onFileDeleteClick: handleFileDeleteClick }) })) })), document.body);
}
