import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { classes } from 'classifizer';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import styles from './LeftPanel.module.css';
const BOX_SHADOW_HEIGHT = 16;
export const LeftPanel = forwardRef(({ header, tabBar, listHeader, fullWidthHeaderContent, footer, pagination, bottomMenu, children, onMouseEnter, onMouseLeave, variant, activeTabKey }, ref) => {
    var _a, _b;
    const scrollContainerRef = useRef(null);
    const headerContainerRef = useRef(null);
    const scrollDirection = useScrollDirection(scrollContainerRef);
    const headerHeight = (_b = (_a = headerContainerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : 0;
    useEffect(() => {
        if (!scrollContainerRef.current)
            return;
        scrollContainerRef.current.scrollTop = 0;
    }, [activeTabKey]);
    useImperativeHandle(ref, () => scrollContainerRef.current, []);
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], variant && styles['container__' + variant]), onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, "data-test": "left-panel-container", id: "left-panel-container", ref: scrollContainerRef }, { children: [_jsxs("div", Object.assign({ className: classes(styles['header-group'], scrollDirection === 'up' && styles['header-group--sticky']), style: {
                    top: scrollDirection === 'up' ? undefined : -headerHeight - BOX_SHADOW_HEIGHT
                }, ref: headerContainerRef }, { children: [_jsxs("div", Object.assign({ className: styles['header-group__top'] }, { children: [_jsxs("div", Object.assign({ className: styles['header-group__main'] }, { children: [header && _jsx("div", Object.assign({ className: styles['header'] }, { children: header })), tabBar && _jsx("div", Object.assign({ className: styles['tabbar'] }, { children: tabBar })), activeTabKey === 'list' && (_jsx("div", Object.assign({ className: styles['list-header'] }, { children: listHeader })))] })), variant === 'table' && fullWidthHeaderContent && (_jsx("div", Object.assign({ className: styles['header-group__fullwidth'] }, { children: fullWidthHeaderContent })))] })), footer && _jsx("div", Object.assign({ className: styles['header-group__footer'] }, { children: footer }))] })), _jsxs("div", Object.assign({ className: styles['content-container'] }, { children: [_jsx("div", Object.assign({ className: styles['results-container'] }, { children: children })), pagination && _jsx("div", Object.assign({ className: styles['pagination'] }, { children: pagination }))] })), bottomMenu && _jsx("div", Object.assign({ className: styles['bottom-menu'] }, { children: bottomMenu }))] })));
});
LeftPanel.displayName = 'LeftPanel';
