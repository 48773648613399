import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { LinkTasksModal } from '../../components/Modals/LinkTasksModal/LinkTasksModal';
import { useAttachmentMarker } from '../../hooks/render/useAttachmentMarker';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchSearchMarkers } from '../../state/slices/assetDetail';
export function LinkTasksModalContainer({ onClose, onDone }) {
    const dispatch = useAppDispatch();
    const { removeModal } = useModalContext();
    const { getAttachmentMarker } = useAttachmentMarker();
    const { searchedMarkerList, searchStatus } = useAppSelector((state) => state.assetDetail);
    const { markerIdsToLink } = useAppSelector((state) => state.assetCreator);
    const { draftPin } = useAppSelector((state) => state.mainMap);
    const { longitude, latitude } = useAppSelector((state) => state.mainMap.viewState);
    const [selectedMarkers, setSelectedMarkers] = useState([]);
    useEffect(() => {
        setSelectedMarkers(markerIdsToLink !== null && markerIdsToLink !== void 0 ? markerIdsToLink : []);
    }, [markerIdsToLink]);
    function handleInputChange(value) {
        var _a, _b;
        dispatch(fetchSearchMarkers({
            search: value,
            searchCenter: {
                longitude: (_a = draftPin === null || draftPin === void 0 ? void 0 : draftPin.lng) !== null && _a !== void 0 ? _a : longitude,
                latitude: (_b = draftPin === null || draftPin === void 0 ? void 0 : draftPin.lat) !== null && _b !== void 0 ? _b : latitude
            }
        }));
    }
    function handleSelect(ids) {
        setSelectedMarkers(ids);
    }
    function handleClose() {
        removeModal('LinkTasksModalContainer');
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    function handleDone(ids) {
        if (ids.length > 0) {
            onDone === null || onDone === void 0 ? void 0 : onDone(ids);
        }
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (_jsx(LinkTasksModal, { status: searchStatus, results: searchedMarkerList, selectedValues: selectedMarkers, markerIdsToLink: markerIdsToLink !== null && markerIdsToLink !== void 0 ? markerIdsToLink : [], getAttachmentMarker: getAttachmentMarker, onInputChange: handleInputChange, onSelect: handleSelect, onClose: handleClose, onDone: handleDone }));
}
