import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import styles from './DataGridTableFooter.module.css';
export function DataGridTableFooter({ children, tableRef, isRightPanelOpen }) {
    const footerRef = useRef(null);
    const [collision, setCollision] = useState(false);
    useEffect(() => {
        const tableElement = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current;
        const footerElement = footerRef === null || footerRef === void 0 ? void 0 : footerRef.current;
        if (tableElement && footerElement) {
            const rect1 = tableElement.getBoundingClientRect();
            const rect2 = footerElement.getBoundingClientRect();
            // check if the two elements are overlapping
            if (rect1.bottom > rect2.top && rect1.top < rect2.bottom) {
                setCollision(true);
            }
            else {
                setCollision(false);
            }
        }
    }, [tableRef, footerRef]);
    return (_jsx("tfoot", Object.assign({ ref: footerRef, className: classes(styles['container'], isRightPanelOpen && styles['container--right-panel-open']), style: { position: collision ? 'sticky' : 'absolute' } }, { children: _jsx("tr", { children: children }) })));
}
