// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._foCXsEUEh7drYq0r33g{display:flex;gap:4px;width:100%;font-size:12px;font-style:normal;font-weight:400;line-height:18px}.uhavWjUl6AJ8VJ8U9Iyh{flex:1}.yqjr7vElgXR5YFWmynGD{display:flex;justify-content:space-between;width:100%}.E4tHvRVKZrYzAzaGMAYt{opacity:.4;margin:0;font-size:8px;font-style:normal;font-weight:600;line-height:18px;text-transform:uppercase}.xdUvEL3_8Fu6Jj9fRVoc{display:flex;align-items:center;gap:4px}`, "",{"version":3,"sources":["webpack://./src/components/BudgetTooltip/BudgetTooltipBudgetItem/BudgetTooltipBudgetItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA,CACA,UAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGJ,sBACI,MAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CACA,UAAA,CAGJ,sBACI,UAAA,CACA,QAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,wBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".container {\n    display: flex;\n    gap: 4px;\n    width: 100%;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 18px; /* 150% */\n}\n\n.content {\n    flex: 1;\n}\n\n.row {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.caption {\n    opacity: 0.4;\n    margin: 0;\n    font-size: 8px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 18px; /* 225% */\n    text-transform: uppercase;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_foCXsEUEh7drYq0r33g`,
	"content": `uhavWjUl6AJ8VJ8U9Iyh`,
	"row": `yqjr7vElgXR5YFWmynGD`,
	"caption": `E4tHvRVKZrYzAzaGMAYt`,
	"title": `xdUvEL3_8Fu6Jj9fRVoc`
};
export default ___CSS_LOADER_EXPORT___;
