var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { validateEmail } from '../../common/validation/email';
import { validatePhoneNumber } from '../../common/validation/phoneNumber';
import { AddCoWorkerModal } from '../../components/Modals/AddCoWorkerModal/AddCoWorkerModal';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { inviteUser } from '../../state/slices/coWorkerManagement';
export function AddCoWorkerModalContainer() {
    const dispatch = useAppDispatch();
    const { addModal, removeModal, modals } = useModalContext();
    const { departmentList } = useDepartmentsData();
    const { isSubmitting } = useAppSelector((state) => state.coWorkerManagement);
    const { users } = useAppSelector((state) => state.shared);
    const { roleList, isAdmin } = useUserRole();
    const [emailError, setEmailError] = useState();
    const novalidemailorphonenumber = useDittoWrapper({
        componentId: 'errorpage.novalidemailorphonenumber'
    });
    const personalreadyinvited = useDittoWrapper({
        componentId: 'coworkermanagement.personalreadyinvited'
    });
    function closeModal() {
        removeModal('AddCoWorkerModal');
    }
    function checkIfUserExists(username) {
        return !!users.find((user) => user.email === username || user.phoneNumber === username);
    }
    function handleInviteClick(values) {
        return __awaiter(this, void 0, void 0, function* () {
            const username = values.username;
            const isValidEmail = validateEmail(username);
            const isValidPhoneNumber = validatePhoneNumber(username);
            if (!isValidEmail && !isValidPhoneNumber) {
                setEmailError(novalidemailorphonenumber);
                return;
            }
            if (checkIfUserExists(username)) {
                setEmailError(personalreadyinvited);
                return;
            }
            yield dispatch(inviteUser(isValidEmail
                ? { email: username, roleId: values.roleId }
                : { phoneNumber: username, roleId: values.roleId }));
            closeModal();
        });
    }
    function handleCancelClick() {
        closeModal();
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'AddCoWorkerModal') {
            return;
        }
        handleCancelClick();
    }
    function handleRoleAndPermissionsClick() {
        addModal({ id: 'RolePermissionsModal' });
    }
    return (_jsx(AddCoWorkerModal, { departmentList: departmentList, emailError: emailError, loading: isSubmitting, userIsAdmin: isAdmin, roleList: roleList, onInviteClick: handleInviteClick, onCancelClick: handleCancelClick, onClickOutside: handleClickOutside, onRoleAndPermissionsClick: handleRoleAndPermissionsClick }));
}
