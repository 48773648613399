import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { DeleteModal } from '../../components/Modals/DeleteModal/DeleteModal';
import { useModalContext } from '../../state/context';
export function DeleteModalContainer({ onConfirm, onCancel, title, description, yesLabel = _jsx(Ditto, { componentId: "delete" }) }) {
    const { removeModal } = useModalContext();
    function closeModal() {
        removeModal('DeleteModal');
    }
    function handleYesClick() {
        closeModal();
        onConfirm();
    }
    function handleNoClick() {
        closeModal();
        onCancel && onCancel();
    }
    return (_jsx(DeleteModal, { title: title, description: description, yesLabel: yesLabel, noLabel: _jsx(Ditto, { componentId: "promt.cancel" }), onYesClick: handleYesClick, onNoClick: handleNoClick }));
}
