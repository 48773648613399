// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TnJ5cLL1d1InxcA6C5an{width:24px;height:24px;position:relative}.meqDVb4qEGjnJqmwl9yw{display:flex;flex-direction:row;justify-content:center;align-items:center;padding:8px;gap:4px;isolation:isolate;position:absolute;background:#222d39;box-shadow:0px 0px 10px rgba(0,0,0,.2);border-radius:6px;color:var(--primary-white);z-index:1}.Up4mGb04Tqs6YEytGwo5{transform:translate(-8px, -8px)}.f3WGTM7U2yJ7ci57TL57{transform:translate(calc(-100% + 32px), -8px)}`, "",{"version":3,"sources":["webpack://./src/uikit/AvatarHover/AvatarHover.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,OAAA,CACA,iBAAA,CAEA,iBAAA,CAGA,kBAAA,CACA,sCAAA,CACA,iBAAA,CACA,0BAAA,CACA,SAAA,CAGJ,sBACI,+BAAA,CAGJ,sBACI,6CAAA","sourcesContent":[".container {\n    width: 24px;\n    height: 24px;\n    position: relative;\n}\n\n.tooltip {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 8px;\n    gap: 4px;\n    isolation: isolate;\n\n    position: absolute;\n\n    /* primary-dark-blue */\n    background: #222d39;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n    border-radius: 6px;\n    color: var(--primary-white);\n    z-index: 1;\n}\n\n.tooltip__right {\n    transform: translate(-8px, -8px);\n}\n\n.tooltip__left {\n    transform: translate(calc(-100% + 32px), -8px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TnJ5cLL1d1InxcA6C5an`,
	"tooltip": `meqDVb4qEGjnJqmwl9yw`,
	"tooltip__right": `Up4mGb04Tqs6YEytGwo5`,
	"tooltip__left": `f3WGTM7U2yJ7ci57TL57`
};
export default ___CSS_LOADER_EXPORT___;
