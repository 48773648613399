var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { combineDamages } from '../common/conditionDamages';
import { post } from '../common/fetch';
import { rangeToValues } from '../common/range';
import { DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN } from '../state/slices/leftPanelCondition';
const ST_GET_INFO_BY_POLYGON = 'v1/stats/by-polygon';
export function getSelectedSegmentByPolygon(params) {
    return __awaiter(this, void 0, void 0, function* () {
        const { filter } = params;
        const damages = combineDamages(filter.damageType);
        const grades = filter.grade.max === DEFAULT_GRADE_MAX && filter.grade.min === DEFAULT_GRADE_MIN
            ? []
            : rangeToValues(filter.grade);
        const reqParams = {
            polygon: params.polygon,
            timePickerRange: params.timepicker,
            grades,
            segmentLength: filter.segmentLengthMin,
            streetTypes: filter.streetType,
            surfaceTypes: filter.surfaceType,
            damages
        };
        return yield post(ST_GET_INFO_BY_POLYGON, reqParams);
    });
}
