import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { TABLE_WIDTH } from '../../common/consts/table';
import { useIsInMountTransition } from '../../hooks/useIsInMountTransition';
import { LAYERS_PANEL_WIDTH, RIGHT_PANEL_WIDTH } from '../AppLayout/AppLayout';
import { Loader } from '../Loader/Loader';
import { DataGridTableFooterItem } from './DataGridTableFooter/DataGridTableFooterItem/DataGridTableFooterItem';
import styles from './DataGridTable.module.css';
export function DataGridTable({ header, footer, children, loading, settings, setSettings, isEditable, isRightPanelOpen }) {
    const [columnProps, setColumnProps] = useState([]);
    const [resizeData, setResizeData] = useState(null);
    const tableRef = useRef(null);
    const isInMountTransition = useIsInMountTransition();
    useEffect(() => {
        if (settings === null || settings === void 0 ? void 0 : settings.length) {
            setColumnProps(getColumnProps());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);
    function getColumnProps() {
        let array = [];
        if (settings) {
            const settingsArray = [
                {
                    columnKey: 'multiselect',
                    orderNumber: 0,
                    width: TABLE_WIDTH.xs,
                    pinLeft: true,
                    hidden: false
                },
                ...settings
            ];
            const headerChildren = header.props.children.flat();
            if (headerChildren.find((child) => child && child.props.isLastActionColumn)) {
                settingsArray.push({ width: TABLE_WIDTH.xl, pinLeft: false, hidden: false });
            }
            settingsArray.forEach((column) => {
                var _a, _b;
                const headerChild = headerChildren.find((child) => child && child.props.name === column.columnKey);
                if (!headerChild)
                    return;
                array.push({
                    name: column.columnKey,
                    width: (_a = column.width) !== null && _a !== void 0 ? _a : TABLE_WIDTH[headerChild.props.width],
                    minWidth: headerChild.props.minWidth
                        ? TABLE_WIDTH[headerChild.props.minWidth]
                        : undefined,
                    pinned: (_b = column.pinLeft) !== null && _b !== void 0 ? _b : false,
                    visible: !column.hidden
                });
            });
        }
        const pinnedColumns = array.filter(({ pinned }) => pinned);
        const unpinnedColumns = array.filter(({ pinned }) => !pinned);
        return [...pinnedColumns, ...unpinnedColumns];
    }
    function saveColumnWidth(name, width) {
        if (!settings || !setSettings)
            return;
        const columnSettings = settings === null || settings === void 0 ? void 0 : settings.map((column) => {
            if (column.columnKey === name) {
                return Object.assign(Object.assign({}, column), { width });
            }
            return column;
        });
        setSettings(columnSettings);
    }
    // Resize columns
    useEffect(() => {
        let newWidth;
        function handleMouseUp() {
            if (resizeData) {
                saveColumnWidth(resizeData.name, newWidth);
            }
            setResizeData(null);
        }
        function handleMouseMove({ movementX }) {
            if (!resizeData)
                return;
            setColumnProps((prev) => prev.map((column) => (Object.assign(Object.assign({}, column), { width: column.name === resizeData.name
                    ? (newWidth = Math.max(column.width + movementX, column.minWidth || TABLE_WIDTH.xs))
                    : column.width }))));
        }
        if (resizeData) {
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('mousemove', handleMouseMove);
        }
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resizeData]);
    function onResizeStart(name, pos) {
        setResizeData({ name, startPosition: pos });
    }
    const totalTableWidth = useMemo(() => columnProps.filter(({ visible }) => visible).reduce((sum, obj) => sum + obj.width, 0), [columnProps]);
    function buildTable(children, props) {
        if (!isEditable) {
            return children;
        }
        const childrenArray = React.Children.toArray(children).map((child) => {
            if (child.type === React.Fragment) {
                return child.props.children;
            }
            return child;
        });
        return React.Children.map(childrenArray, (rowChild) => {
            if (!React.isValidElement(rowChild) || !rowChild.props.children) {
                return rowChild;
            }
            let pinnedWidthSum = 0;
            return React.cloneElement(rowChild, Object.assign(Object.assign({}, rowChild.props), { isScrollable: totalTableWidth >
                    window.innerWidth -
                        LAYERS_PANEL_WIDTH -
                        (isRightPanelOpen ? RIGHT_PANEL_WIDTH : 0), children: columnProps
                    .filter(({ visible }) => visible)
                    .map(({ name, width, pinned }, index) => {
                    const cell = rowChild.props.children.flat();
                    const child = cell.find((rowChild) => rowChild && rowChild.props.name === name);
                    let result;
                    if (child) {
                        result = React.cloneElement(child, Object.assign({ key: `cell-${name}`, width, stickyPosition: pinned ? pinnedWidthSum : undefined }, props));
                    }
                    else {
                        result = React.createElement('td', {
                            key: `call-header-${index}`
                        });
                    }
                    if (pinned) {
                        pinnedWidthSum += width || 0;
                    }
                    return result;
                }) }));
        });
    }
    function buildFooter(children, props) {
        if (!React.isValidElement(children))
            return null;
        let pinnedWidthSum = 0;
        return React.cloneElement(children, Object.assign(Object.assign({}, children.props), { tableRef: tableRef, children: columnProps
                .filter(({ visible }) => visible)
                .map(({ name, width, pinned }) => {
                const footerChildren = children.props.children.flat();
                const node = footerChildren.find((footerChild) => footerChild && footerChild.props.name === name);
                let result;
                if (node) {
                    result = React.cloneElement(node, Object.assign({ key: `footer-${name}`, width, stickyPosition: pinned ? pinnedWidthSum : undefined }, props));
                }
                else {
                    result = React.createElement(DataGridTableFooterItem, {
                        key: `footer-${name}`,
                        name,
                        width,
                        stickyPosition: pinned ? pinnedWidthSum : undefined
                    });
                }
                if (pinned) {
                    pinnedWidthSum += width || 0;
                }
                return result;
            }) }));
    }
    return (_jsxs("div", Object.assign({ className: styles['wrapper'], style: { width: totalTableWidth + (isRightPanelOpen ? RIGHT_PANEL_WIDTH : 0) } }, { children: [(isInMountTransition || loading) && (_jsx("div", Object.assign({ className: classes(styles['loading'], isInMountTransition && styles['loading--in-transition']) }, { children: _jsx(Loader, { variant: "center" }) }), 'loader')), _jsx("table", Object.assign({ className: styles['container'], ref: tableRef, "data-test": "data-grid-table-container" }, { children: !isInMountTransition && (_jsxs(_Fragment, { children: [_jsx("thead", { children: buildTable(header, { onResizeStart }) }), _jsx("tbody", { children: buildTable(children) }), footer && buildFooter(footer)] })) }))] })));
}
