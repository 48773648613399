import { useEffect } from 'react';
import { useControl, useMap } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import booleanIntersects from '@turf/boolean-intersects';
import { area as turfArea } from '@turf/turf';
import { DEFAULT_SOURCE_LAYER, POINTS_SOURCE_ID } from '../../../common/mapbox';
import { unique } from '../../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { setDrawArea, setDrawPolygon, setDrawVertexCount } from '../../../state/slices/mainMap';
import { addNotificationMessage } from '../../../state/slices/notification';
import { confirmSegmentSelection, getSegmentByPolygon, resetSegmentDetail, resetSegmentSelection } from '../../../state/slices/segmentDetail';
export function DrawTool() {
    const dispatch = useAppDispatch();
    const { drawVertexCount } = useAppSelector((state) => state.mainMap);
    const { main: map } = useMap();
    const { shouldConfirmSelect, shouldResetSelect } = useAppSelector((state) => state.segmentDetail);
    const draw = useControl(() => new MapboxDraw({
        defaultMode: 'draw_polygon',
        displayControlsDefault: false,
        controls: {
            trash: true
        }
    }), () => { });
    useEffect(() => {
        if (map) {
            map.on('draw.create', onUpdate);
            map.on('draw.update', onUpdate);
            map.on('draw.delete', onDelete);
            map.on('draw.modechange', onChangeMode);
            map.on('click', onClick);
        }
        return () => {
            if (map) {
                map.off('draw.create', onUpdate);
                map.off('draw.update', onUpdate);
                map.off('draw.delete', onDelete);
                map.off('draw.modechange', onChangeMode);
                map.off('click', onClick);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawVertexCount]);
    useEffect(() => {
        if (shouldConfirmSelect) {
            dispatch(confirmSegmentSelection(false));
            onConfirmSelection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, shouldConfirmSelect]);
    useEffect(() => {
        if (shouldResetSelect) {
            dispatch(resetSegmentSelection(false));
            onReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, shouldResetSelect]);
    function updateVertexCount() {
        if (map && draw) {
            const data = draw.getAll();
            const { features } = data;
            if (features && features.length > 0) {
                const [feature] = features;
                if (feature && feature.geometry.type === 'Polygon') {
                    const [vertexArray = []] = feature.geometry.coordinates;
                    // TODO: revert previous function
                    const pointsArray = unique(vertexArray).filter((v) => v !== null);
                    dispatch(setDrawVertexCount(pointsArray.length));
                    const area = turfArea(data);
                    dispatch(setDrawArea(area));
                }
            }
            else {
                dispatch(setDrawArea(0));
                dispatch(setDrawVertexCount(0));
                dispatch(resetSegmentDetail());
            }
        }
    }
    function onClick() {
        updateVertexCount();
    }
    function onConfirmSelection() {
        const featureCollection = draw.getAll();
        const [feature] = featureCollection.features;
        if (feature) {
            draw.add(feature);
            draw.changeMode('simple_select');
        }
    }
    function onReset() {
        draw.deleteAll();
        draw.changeMode('draw_polygon');
        dispatch(setDrawVertexCount(0));
        dispatch(resetSegmentDetail());
    }
    function onChangeMode() {
        // Avoid map freeze on ESC button (occurs in old webapp too)
        const { features } = draw.getAll();
        if (!(features === null || features === void 0 ? void 0 : features.length)) {
            updateVertexCount();
            draw.changeMode('draw_polygon');
        }
    }
    function onUpdate(event) {
        const [feature] = event.features;
        updateVertexCount();
        if (!map || !feature || feature.geometry.type !== 'Polygon') {
            return;
        }
        if (feature.geometry.coordinates.length > 0) {
            const featuresInPolygon = map
                .querySourceFeatures(POINTS_SOURCE_ID, {
                sourceLayer: DEFAULT_SOURCE_LAYER
            })
                .filter((val) => booleanIntersects(val, feature));
            if (featuresInPolygon.length === 0) {
                dispatch(resetSegmentDetail());
                dispatch(addNotificationMessage({
                    type: 'error',
                    closeAfterSeconds: 5,
                    ditto: {
                        title: { componentId: 'toast.nopoints' }
                    }
                }));
                return;
            }
            dispatch(setDrawPolygon(feature));
            dispatch(getSegmentByPolygon({
                polygon: feature.geometry.coordinates[0]
            }));
        }
    }
    function onDelete() {
        draw.changeMode('draw_polygon');
    }
    return null;
}
