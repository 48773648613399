export function getParentElements(ref) {
    const elements = [];
    let currentElement;
    if ('current' in ref) {
        currentElement = ref.current;
    }
    else {
        currentElement = ref;
    }
    while (currentElement === null || currentElement === void 0 ? void 0 : currentElement.parentElement) {
        const parent = currentElement.parentElement;
        elements.push(parent);
        currentElement = parent;
    }
    return elements;
}
