export function segmentToContent(item, unitFormatter) {
    return {
        edge: item.segmentNumber,
        id: item.streetId,
        title: item.streetName,
        grade: item.grade.emi,
        gradePCI: item.grade.pci,
        gradePaser: item.grade.paser,
        length: unitFormatter({ type: 'length', value: item.length })
    };
}
export function pointToContent(item) {
    return {
        roadType: item.streetType,
        surfaceType: item.surfaceType,
        id: item.pointId,
        title: item.displayName,
        grade: item.grade.emi,
        gradePCI: item.damageStats.pci,
        gradePaser: item.damageStats.paser,
        image_url: item.imageThumbnailUrl
    };
}
export function nodeEdgeDataToSegmentFormData(item) {
    return {
        name: item.display_name || '',
        streetIds: [item.street_id],
        length: item.street_length,
        priority: 0,
        statusId: 1,
        categoryId: 3,
        thirdParty: false
    };
}
export function nodeEdgeListToSegmentFormData(items) {
    return {
        name: items[0].displayName || '',
        streetIds: items.map((item) => item.streetId),
        length: items.reduce((accumulator, currentValue) => accumulator + currentValue.streetLength, 0),
        priority: 0,
        statusId: 1,
        categoryId: 3,
        thirdParty: false
    };
}
