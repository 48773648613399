var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Ditto } from 'ditto-react';
import { v4 as uuidv4 } from 'uuid';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createSignInventoryFolder, deleteSignInventoryFolder, getSignInventoryFoldersList, rearrangeSignInventoryFolders, renameSignInventoryFolder } from '../../services/signInventory';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchSignInventoryFoldersList, setSignInventoryFilter } from '../../state/slices/leftPanelSignInventory';
import { addNotificationMessage } from '../../state/slices/notification';
import { fetchSignInventoryInfoById } from '../../state/slices/signInventoryDetail';
export const useSignInventoryFoldersSettings = () => {
    const dispatch = useAppDispatch();
    const { signInfo } = useAppSelector((state) => state.signInventoryDetail);
    const [foldersList, setFoldersList] = useState([]);
    const persistedFoldersList = useRef([]);
    const { addModal } = useModalContext();
    const newFolder = useCallback((name) => {
        setFoldersList((list) => {
            let maxOrder = 0;
            list.forEach((folder) => {
                if (folder.orderNumber > maxOrder) {
                    maxOrder = folder.orderNumber;
                }
            });
            const folder = createFolder(name, maxOrder + 1);
            return [...list, folder];
        });
    }, [setFoldersList]);
    const rename = useCallback((id, name) => {
        setFoldersList((list) => {
            const newList = [...list];
            const index = newList.findIndex((item) => item.id === id);
            const folder = renameFolder(name, newList[index]);
            newList[index] = folder;
            return newList;
        });
    }, [setFoldersList]);
    const rearrange = useCallback((id, order) => {
        setFoldersList((list) => {
            const newList = [...list];
            const oldIndex = newList.findIndex((item) => item.id === id);
            const newFolder = rearrangeFolder(order, newList[oldIndex]);
            newList.splice(oldIndex, 1);
            newList.splice(order, 0, newFolder);
            const firstOrderableIndex = newList.findIndex((item) => !item.isSystem);
            newList.slice(firstOrderableIndex).forEach((item, index) => {
                item.orderNumber = index + firstOrderableIndex;
            });
            return newList;
        });
    }, [setFoldersList]);
    const deleteFolder = useCallback((id) => {
        setFoldersList((list) => {
            const newList = [...list];
            const index = newList.findIndex((item) => item.id === id);
            newList.splice(index, 1);
            return newList;
        });
    }, [setFoldersList]);
    const fetchFolders = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getSignInventoryFoldersList({});
        if (response.errorCode !== RESPONSE_ERROR_CODE.success) {
            dispatch(errorNotification());
            return;
        }
        const list = response.result.folders.sort((a, b) => a.orderNumber - b.orderNumber);
        persistedFoldersList.current = list;
        setFoldersList(list);
        dispatch(fetchSignInventoryFoldersList());
        if (signInfo === null || signInfo === void 0 ? void 0 : signInfo.id) {
            dispatch(fetchSignInventoryInfoById(signInfo.id));
        }
    }), [dispatch, signInfo === null || signInfo === void 0 ? void 0 : signInfo.id]);
    const checkForChanges = useCallback(() => {
        const { createOps, renameOps, deleteOps } = getDataChangeOperations(persistedFoldersList.current, foldersList);
        const { order } = getRearrangeOperation(foldersList.filter((folder) => typeof folder.id === 'number'));
        const orderChange = order.filter((op) => {
            const match = persistedFoldersList.current.find((folder) => folder.id === op.id);
            return (match === null || match === void 0 ? void 0 : match.orderNumber) !== op.orderNumber;
        });
        return [createOps, renameOps, deleteOps, orderChange].some((ops) => ops.length > 0);
    }, [foldersList]);
    const checkForDeleteChanges = useCallback(() => {
        const { deleteOps } = getDataChangeOperations(persistedFoldersList.current, foldersList);
        const names = persistedFoldersList.current
            .filter((folder) => deleteOps.find((op) => op.id === folder.id))
            .map((folder) => folder.name);
        return names;
    }, [foldersList]);
    const persist = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { createOps, renameOps, deleteOps } = getDataChangeOperations(persistedFoldersList.current, foldersList);
        const createFoldersResponse = yield Promise.all(createOps.map((op) => createSignInventoryFolder({ name: op.name })));
        if (createFoldersResponse.some((res) => res.errorCode !== RESPONSE_ERROR_CODE.success)) {
            dispatch(errorNotification());
            return null;
        }
        const createdFolders = new Map(createFoldersResponse.map((response, index) => [
            createOps[index].id,
            response.result.folder
        ]));
        const rename = renameOps.map((op) => renameSignInventoryFolder(op));
        const remove = deleteOps.map((op) => deleteSignInventoryFolder(op));
        const response = yield Promise.all([...rename, ...remove]);
        if (response.some((res) => res.errorCode !== RESPONSE_ERROR_CODE.success)) {
            dispatch(errorNotification());
            return null;
        }
        return createdFolders;
    }), [dispatch, foldersList]);
    const persistOrder = useCallback((folders) => __awaiter(void 0, void 0, void 0, function* () {
        const { order } = getRearrangeOperation(folders.filter((folder) => typeof folder.id === 'number'));
        const response = yield rearrangeSignInventoryFolders({ newOrderNumbers: order });
        if (response.errorCode !== RESPONSE_ERROR_CODE.success) {
            dispatch(errorNotification());
            return false;
        }
        return true;
    }), [dispatch]);
    const sync = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const persistResult = yield persist();
        const newList = [...foldersList];
        if (persistResult !== null && persistResult.size > 0) {
            for (const [id, createdFolder] of persistResult.entries()) {
                const folderIndex = newList.findIndex((item) => item.id === id);
                newList[folderIndex] = Object.assign(Object.assign({}, createdFolder), { orderNumber: newList[folderIndex].orderNumber });
            }
            setFoldersList(newList);
        }
        const persistOrderResult = yield persistOrder(newList);
        if (!persistOrderResult) {
            dispatch(errorNotification());
            return;
        }
        yield fetchFolders();
    }), [dispatch, fetchFolders, foldersList, persist, persistOrder]);
    useEffect(() => {
        fetchFolders();
    }, [fetchFolders]);
    const handleFoldersSaveClick = useCallback((callback) => {
        const deleteChanges = checkForDeleteChanges();
        if (deleteChanges.length > 0) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        sync();
                        callback();
                    },
                    onCancel: () => callback(),
                    description: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "prompt.savechanges.copydeletions" }), renderDeletions(deleteChanges)] })),
                    yesColor: 'red',
                    yesLabel: _jsx(Ditto, { componentId: "prompt.deleteandsave" })
                }
            });
            return;
        }
        sync();
        callback();
    }, [addModal, checkForDeleteChanges, sync]);
    const handleFoldersCancelClick = useCallback((callback) => {
        const changes = checkForChanges();
        if (changes) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handleFoldersSaveClick(callback);
                    },
                    onCancel: () => callback(),
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }, [addModal, checkForChanges, handleFoldersSaveClick]);
    const handleFolderDeleteClick = useCallback((folderId, info) => {
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "prompt.delete.filecontainer.heading" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "prompt.delete.filecontainer.text" }), info] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    deleteFolder(folderId);
                }
            }
        });
    }, [addModal, deleteFolder]);
    const handleFolderFilterClick = useCallback((folderId) => {
        if (typeof folderId === 'string') {
            console.error('Folder id is not a number');
            return;
        }
        dispatch(setSignInventoryFilter({
            damageTypeIds: [],
            signTypeIds: [],
            signStatuses: [],
            filesAttached: [folderId],
            filesNotAttached: [],
            properties: []
        }));
    }, [dispatch]);
    return {
        foldersList: foldersList,
        createFolder: newFolder,
        renameFolder: rename,
        rearrangeFolder: rearrange,
        handleFoldersSaveClick,
        handleFoldersCancelClick,
        handleFolderDeleteClick,
        handleFolderFilterClick
    };
};
const getDataChangeOperations = (backendFolders, localFolders) => {
    const createOps = localFolders
        .filter((item) => typeof item.id === 'string')
        .map((item) => ({
        id: item.id,
        name: item.name
    }));
    const renameOps = backendFolders
        .map((backendFolder) => {
        const local = localFolders.find((item) => item.id === backendFolder.id);
        return [backendFolder, local];
    })
        .filter(([backend, local]) => local && local.name !== backend.name).map(([backend, local]) => ({
        id: backend.id,
        newName: local.name
    }));
    const deleteOps = backendFolders
        .filter((backend) => !localFolders.find((local) => local.id === backend.id))
        .map((folder) => ({ id: folder.id }));
    return {
        createOps: createOps,
        renameOps: renameOps,
        deleteOps: deleteOps
    };
};
const getRearrangeOperation = (folders) => {
    return {
        order: folders
            .filter((folder) => !folder.isSystem)
            .map((folder) => ({ id: folder.id, orderNumber: folder.orderNumber }))
    };
};
const createFolder = (name, order) => {
    return {
        id: uuidv4(),
        isSystem: false,
        componentId: '',
        name: name,
        trafficSignsCount: 0,
        orderNumber: order,
        notAttachedCount: 0
    };
};
const renameFolder = (name, folder) => {
    return Object.assign(Object.assign({}, folder), { name });
};
const rearrangeFolder = (order, folder) => {
    return Object.assign(Object.assign({}, folder), { orderNumber: order });
};
const errorNotification = () => {
    return addNotificationMessage({ type: 'error' });
};
const renderDeletions = (deletions) => {
    return (_jsx("ul", { children: deletions.map((item, index) => (_jsx("li", { children: item }, index))) }));
};
