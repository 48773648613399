import { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { setDraftPin, setDraftPinInitialPosition } from '../state/slices/mainMap';
import { clearFetchedAddress, fetchAddress, fetchSuggestions } from '../state/slices/sharedDataHolder';
import { useMainMap } from './mainMap';
export function useAddressSearch() {
    const dispatch = useAppDispatch();
    const { fetchedAddress, fetchedSuggestions, addressSearchStatus } = useAppSelector((state) => state.sharedDataHolder);
    const { main: map } = useMap();
    const { draftPin } = useAppSelector((state) => state.mainMap);
    const { moveIntoView } = useMainMap();
    useEffect(() => {
        if (!draftPin) {
            return;
        }
        dispatch(fetchAddress(draftPin));
    }, [dispatch, draftPin]);
    function handleAddressSearchChange(val) {
        const center = map === null || map === void 0 ? void 0 : map.getCenter();
        if (val.length > 2 && center) {
            dispatch(fetchSuggestions({
                query: val,
                proximity: center
            }));
        }
        else {
            dispatch(clearFetchedAddress());
        }
    }
    function handleAddressSuggestionClick(s) {
        dispatch(setDraftPinInitialPosition(s.position));
        dispatch(setDraftPin(s.position));
        moveIntoView(s.position);
    }
    return {
        handleAddressSearchChange,
        handleAddressSuggestionClick,
        fetchedAddress,
        fetchedSuggestions,
        draftPin,
        addressSearchStatus
    };
}
