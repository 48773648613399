var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { FORM_UPDATE_NOTIFICATION_DURATION_SECONDS, NOTIFICATION_CLOSE_DURATION_SECONDS } from '../../common/consts/config';
import { MARKER_STATUS_ID } from '../../common/consts/marker';
import { DEBOUNCE_SEARCH, DEBOUNCE_SHARED_FETCH, DEBOUNCE_USER_INPUT_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getAddressForPosition } from '../../services/address';
import { getLightAssets, searchAsset } from '../../services/asset';
import { tryUploadPhotos } from '../../services/fileManager';
import { addMarkerPhotos, batchDeleteMarkers, getMarker, linkMarkersToAssets, markerTimelogBatchCreate, markerTimelogDelete, markerTimelogUpdate, removeMarkerFiles, removeMarkerPhoto, saveMarkerAttribute, setMarkerCategory, setMarkerDueDate, unlinkMarkerFromAsset, updateAddress, updateMarkerDescription, updateMarkerPriority, updateMarkerStatus, updateTaskAssignees, updateTaskTitle } from '../../services/marker';
import { setIsLoadingActionbar } from './actionbar';
import { clearSelectedFeature } from './feature';
import { fetchMarkers } from './leftPanelMarker';
import { invalidateCachedLinkedMarkers } from './linkedMarkers';
import { fetchMarkerGeoJSON } from './mainMap';
import { addNotificationMessage, setNotificationMessage } from './notification';
import { fetchMarkerFolderTk } from './shared';
const initialState = {
    fetchStatus: 'idle',
    updateStatus: 'idle',
    searchedAssetList: [],
    searchStatus: 'idle',
    lookupAssetMap: {},
    fetchAssetStatus: 'idle',
    currentTab: 0
};
export const fetchMarker = createAppAsyncThunk('markerDetail/fetchMarker', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getMarker(id);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchLightAsset = createDebouncedAsyncThunk('markerDetail/fetchLightAsset', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield CancelableRequest.send(getLightAssets, { ids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSearchAssets = createDebouncedAsyncThunk('markerDetail/fetchSearchAssets', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield searchAsset(req);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SEARCH);
export const linkAsset = createAppAsyncThunk('markerDetail/linkAsset', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield linkMarkersToAssets({
        linkData: [
            {
                manualInventoryId: req.assetId,
                markersIds: [req.markerId]
            }
        ]
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, markerId: req.markerId }, {
        type: 'success',
        ditto: {
            title: {
                componentId: 'tasks.taskandassetlinked'
            }
        }
    });
    dispatch(invalidateCachedLinkedMarkers());
}));
export const unlinkAsset = createAppAsyncThunk('markerDetail/unlinkAsset', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield unlinkMarkerFromAsset({
        manualInventoryId: req.assetId,
        markerId: [req.markerId]
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, markerId: req.markerId }, {
        type: 'success',
        ditto: {
            title: {
                componentId: 'assets.assetunlinked'
            }
        }
    });
    dispatch(invalidateCachedLinkedMarkers());
}));
export const removeMarker = createAppAsyncThunk('markerDetail/removeMarker', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield batchDeleteMarkers({ ids: [id] });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, map: true }, {
        type: 'success',
        ditto: {
            title: {
                componentId: 'toast.markerdeleted'
            }
        }
    });
    dispatch(invalidateCachedLinkedMarkers());
    dispatch(clearSelectedFeature());
    return res;
}));
export const addMarkerPhotosTk = createAppAsyncThunk('markerDetail/addMarkerPhotosTk', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const photoUuids = yield tryUploadPhotos(payload.photos, 'road-safety');
    if (photoUuids && photoUuids.length) {
        const res = yield addMarkerPhotos({
            markerId: payload.markerId,
            photoUuids
        });
        notifyAndReloadAfterUpdate(dispatch, res, {
            list: true,
            markerId: payload.markerId
        });
    }
    dispatch(setIsLoadingActionbar(false));
}));
export const saveMarkerAttributeTk = createDebouncedAsyncThunk('assetDetail/saveMarkerAttributeTk', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield saveMarkerAttribute(payload.body);
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, {
        markerId: payload.markerId
    });
}), DEBOUNCE_USER_INPUT_MS);
function notifyAndReloadAfterUpdate(dispatch, res, fetchOptions, successMessage = {
    closeAfterSeconds: FORM_UPDATE_NOTIFICATION_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: { componentId: 'toast.changessaved' }
    }
}) {
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setNotificationMessage(successMessage));
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.markerId) {
            dispatch(fetchMarker(fetchOptions.markerId));
        }
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.list) {
            dispatch(fetchMarkers());
        }
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.map) {
            dispatch(fetchMarkerGeoJSON());
        }
    }
}
const welldoneMessage = {
    closeAfterSeconds: FORM_UPDATE_NOTIFICATION_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: {
            componentId: 'toast.welldone'
        }
    }
};
const welldoneRecurringMessage = {
    closeAfterSeconds: NOTIFICATION_CLOSE_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: {
            componentId: 'toast.taskmarkedasdonenextrecurringtaskcreated'
        }
    }
};
export const updateMarkerStatusTk = createAppAsyncThunk('markerDetail/updateMarkerStatusTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateMarkerStatus(update);
    dispatch(setIsLoadingActionbar(false));
    const specialMessage = (function () {
        var _a;
        if (update.statusId === MARKER_STATUS_ID.closed) {
            if ((_a = res.result) === null || _a === void 0 ? void 0 : _a.newRecurringMarkerId) {
                return welldoneRecurringMessage;
            }
            return welldoneMessage;
        }
    })();
    notifyAndReloadAfterUpdate(dispatch, res, {
        list: true,
        map: true,
        markerId: update.markerId
    }, specialMessage);
    dispatch(invalidateCachedLinkedMarkers());
}));
export const updateMarkerPriorityTk = createAppAsyncThunk('markerDetail/updateMarkerPriorityTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateMarkerPriority(update);
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, {
        list: true,
        map: true,
        markerId: update.markerId
    });
    dispatch(invalidateCachedLinkedMarkers());
}));
export const updateMarkerDescriptionTk = createAppAsyncThunk('markerDetail/updateMarkerDescriptionTk', (update, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateMarkerDescription(update);
    dispatch(setIsLoadingActionbar(false));
    // Fetching a marker after update is pointless if another one is selected right after.
    // Use case: change the description of a marker in the detail panel, then
    // select a different marker => fetching the updated marker shows the details
    // of the updated one instead of the selected marker.
    const { selectedFeature } = getState().feature;
    if ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === update.markerId) {
        notifyAndReloadAfterUpdate(dispatch, res, { markerId: update.markerId });
    }
    else {
        notifyAndReloadAfterUpdate(dispatch, res);
    }
}));
export const setMarkerCategoryTk = createAppAsyncThunk('markerDetail/updateMarkerStatusTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield setMarkerCategory(update);
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, markerId: update.markerId });
}));
export const updateTaskAssigneesTk = createAppAsyncThunk('markerDetail/updateTaskAssignees', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateTaskAssignees(update);
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, markerId: update.id });
}));
export const updateTaskTitleTk = createAppAsyncThunk('markerDetail/updateTaskTitle', (update, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateTaskTitle(update);
    dispatch(setIsLoadingActionbar(false));
    const { selectedFeature } = getState().feature;
    if ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === update.id) {
        notifyAndReloadAfterUpdate(dispatch, res, { list: true, markerId: update.id });
    }
    else {
        notifyAndReloadAfterUpdate(dispatch, res, { list: true });
    }
}));
export const updateMarkerAddressTk = createAppAsyncThunk('markerDetail/updateMarkerAddressTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateAddress({
        id: update.id,
        latitude: update.position.lat,
        longitude: update.position.lng,
        addressJson: update.address || (yield getAddressForPosition(update.position)).result
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, {
        list: true,
        map: true,
        markerId: update.id
    });
}));
export const updateTaskDueDateTk = createAppAsyncThunk('markerDetail/updateTaskDueDateTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield setMarkerDueDate(update);
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, markerId: update.markerId });
    dispatch(invalidateCachedLinkedMarkers());
}));
export const removeMarkerPhotoTk = createAppAsyncThunk('markerDetail/deleteMarkerPhoto', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield removeMarkerPhoto({
        markerId: update.markerId,
        photoIds: [update.photoId]
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.imagedeleted' }
            }
        }));
        dispatch(fetchMarker(update.markerId));
    }
}));
export const removeFiles = createAppAsyncThunk('markerDetail/removeFiles', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield removeMarkerFiles({ fileIds: [req.fileId], markerId: req.markerId });
    dispatch(fetchMarkers());
    dispatch(fetchMarkerGeoJSON());
    dispatch(fetchMarkerFolderTk());
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.filedeleted' }
            }
        }));
        dispatch(fetchMarker(req.markerId));
    }
}));
export const createTimelogEntries = createAppAsyncThunk('markerDetail/createTimelogEntries', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield markerTimelogBatchCreate({ timelog: req.timelog });
    dispatch(fetchMarker(req.markerId));
    dispatch(setIsLoadingActionbar(false));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const updateTimelogEntry = createAppAsyncThunk('markerDetail/updateTimelogEntry', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield markerTimelogUpdate({
        id: req.timelogId,
        markerId: req.markerId,
        startedAt: req.startedAt,
        finishedAt: req.finishedAt
    });
    dispatch(fetchMarker(req.markerId));
    dispatch(setIsLoadingActionbar(false));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const deleteTimelogEntry = createAppAsyncThunk('markerDetail/deleteTimelogEntry', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield markerTimelogDelete({
        markerId: req.markerId,
        timelogIds: [req.timelogId]
    });
    dispatch(fetchMarker(req.markerId));
    dispatch(setIsLoadingActionbar(false));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const markerDetailSlice = createSlice({
    name: 'markerDetail',
    initialState,
    reducers: {
        setMarkerTitle: (state, action) => {
            if (!state.marker) {
                return;
            }
            state.marker.title = action.payload;
        },
        clearSearchAssets: (state) => {
            state.searchedAssetList = [];
        },
        setMarkerSelectedFileId: (state, action) => {
            state.selectedFileId = action.payload;
        },
        setMarkerCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        // Individual handlers
        builder
            .addCase(removeMarker.fulfilled, (state) => {
            state.marker = undefined;
        })
            .addCase(fetchMarker.fulfilled, (state, action) => {
            var _a;
            state.marker = (_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.marker;
        })
            .addCase(fetchLightAsset.pending, (state) => {
            state.fetchAssetStatus = 'loading';
        })
            .addCase(fetchLightAsset.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.lookupAssetMap = Object.fromEntries(action.payload.result.manualInventories.map((t) => [t.id, t]));
            }
            state.fetchAssetStatus = 'completed';
        })
            .addCase(fetchSearchAssets.pending, (state) => {
            state.searchStatus = 'loading';
        })
            .addCase(fetchSearchAssets.fulfilled, (state, action) => {
            state.searchStatus = 'completed';
            if (action.payload.result) {
                state.searchedAssetList = action.payload.result.manualInventories;
            }
        })
            // Actions that affect entire container
            .addMatcher(isAnyOf(fetchMarker.fulfilled, removeMarker.fulfilled), (state) => {
            state.fetchStatus = 'succeeded';
        })
            .addMatcher(isAnyOf(fetchMarker.pending, removeMarker.pending), (state) => {
            state.fetchStatus = 'loading';
        })
            .addMatcher(isAnyOf(fetchMarker.rejected, removeMarker.rejected), (state) => {
            state.fetchStatus = 'failed';
        })
            // Actions that affect form
            .addMatcher(isAnyOf(updateMarkerStatusTk.fulfilled, updateMarkerDescriptionTk.fulfilled, updateMarkerPriorityTk.fulfilled, setMarkerCategoryTk.fulfilled), (state) => {
            state.updateStatus = 'succeeded';
        })
            .addMatcher(isAnyOf(updateMarkerStatusTk.pending, updateMarkerDescriptionTk.pending, updateMarkerPriorityTk.pending, setMarkerCategoryTk.pending), (state) => {
            state.updateStatus = 'loading';
        })
            .addMatcher(isAnyOf(updateMarkerStatusTk.rejected, updateMarkerDescriptionTk.rejected, updateMarkerPriorityTk.rejected, setMarkerCategoryTk.rejected), (state) => {
            state.updateStatus = 'failed';
        });
    }
});
export const { reset: resetMarkerDetail, setMarkerTitle, clearSearchAssets, setMarkerSelectedFileId, setMarkerCurrentTab } = markerDetailSlice.actions;
export default markerDetailSlice.reducer;
