// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BCH9D5lfyHrdyp1dafGc{display:flex;align-items:center}.f83BesVQvE6_dT4Luxnk{margin-right:8px;height:24px;width:24px}.E9o8kfs4nQ4Tl0xtzRM4{width:100%;font-size:14px;line-height:20px}.X8EjYWVfKD4Ds0xonXcm svg path{stroke:#fff}`, "",{"version":3,"sources":["webpack://./src/components/IconText/IconText.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CAGJ,sBACI,gBAAA,CACA,WAAA,CACA,UAAA,CAGJ,sBACI,UAAA,CACA,cAAA,CACA,gBAAA,CAGJ,+BACI,WAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n}\n\n.icon {\n    margin-right: 8px;\n    height: 24px;\n    width: 24px;\n}\n\n.text {\n    width: 100%;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.color__white svg path {\n    stroke: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BCH9D5lfyHrdyp1dafGc`,
	"icon": `f83BesVQvE6_dT4Luxnk`,
	"text": `E9o8kfs4nQ4Tl0xtzRM4`,
	"color__white": `X8EjYWVfKD4Ds0xonXcm`
};
export default ___CSS_LOADER_EXPORT___;
