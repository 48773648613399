import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchPlanGeoJSON } from '../../state/slices/mainMap';
export function usePlanMapData() {
    const { planGeoJSON } = useAppSelector((state) => state.mainMap);
    const { filter } = useAppSelector((state) => state.leftPanelPlan);
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!filter.search) {
            dispatch(fetchPlanGeoJSON());
        }
    }, [dispatch, filter, selectedOption]);
    return { planGeoJSON };
}
