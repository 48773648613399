var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { streetartClient } from '../openapi';
export function getUnits(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceUnitsList(body);
    });
}
export function getAssetTypes(body) {
    return streetartClient.streetartService.streetartServiceManualInventoryTypes(body);
}
export function saveAssetTypes(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceSaveInventoryType(body);
    });
}
export function getAssetInfoById(id) {
    return streetartClient.streetartService.streetartServiceGetManualInventory({
        id
    });
}
export function getAssetInfoByIdList(body) {
    return streetartClient.streetartService.streetartServiceGetManualInventories(body);
}
export function getAssetList(body) {
    return streetartClient.streetartService.streetartServiceManualInventoryList(body);
}
export function getAssetIdList(body) {
    return streetartClient.streetartService.streetartServiceManualInventoryListOfIds(body);
}
export function exportAssets(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryExport(body);
    });
}
export function deleteAssets(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceBatchDeleteManualInventories(body);
    });
}
export function bulkCreateManualInventory(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceBulkCreateManualInventory(body);
    });
}
export function updateManualInventoryNotes(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryUpdateNotes(body);
    });
}
export function updateManualInventoryType(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryUpdateType(body);
    });
}
export function updateManualInventoryTitle(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryUpdateTitle(body);
    });
}
export function batchUpdatManualInventory(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceBulkEditManualInventories(body);
    });
}
export function saveManualInventoryAttribute(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventorySaveAttribute(body);
    });
}
export function addManualInventoryPhotos(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryAddPhotos(body);
    });
}
export function deleteManualInventoryPhotos(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryRemovePhotos(body);
    });
}
export function updateAddress(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventoryUpdateAddress(body);
    });
}
export function getAssetSortParams(id) {
    switch (id) {
        case 'date-asc':
            return { sortBy: 'created_at', sortOrder: 'asc' };
        case 'date-desc':
            return { sortBy: 'created_at', sortOrder: 'desc' };
        case 'title-asc':
            return { sortBy: 'title', sortOrder: 'asc' };
        case 'title-desc':
            return { sortBy: 'title', sortOrder: 'desc' };
        default:
            console.error('unknown asset sort id', id);
    }
}
export function getAssetFilterParams(filter) {
    return {
        inventoryTypes: filter.typeIds,
        inventoryTypesAttributes: filter.attributes,
        search: filter.search,
        filesAttached: filter.filesAttached,
        filesNotAttached: filter.filesNotAttached
    };
}
export function searchAsset(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield streetartClient.streetartService.streetartServiceManualInventorySearch(body);
    });
}
export function getLightAssets(body) {
    return streetartClient.streetartService.streetartServiceManualInventoryLightList(body);
}
export function getAssetFileFoldersList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetFoldersList(body);
    });
}
export function createAssetFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetFoldersCreate(body);
    });
}
export function renameAssetFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetFoldersRename(body);
    });
}
export function rearrangeAssetFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetFoldersRearrange(body);
    });
}
export function deleteAssetFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetFoldersDelete(body);
    });
}
export function addAssetFiles(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetAddFiles(body);
    });
}
export function removeAssetFiles(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceAssetRemoveFiles(body);
    });
}
