export class FileManagerServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * for k8s tcp probes, prevents flooding logs with 404
     * @returns any A successful response.
     * @throws ApiError
     */
    fileManagerServiceRoot() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/'
        });
    }
    /**
     * request will be authenticated by customer header
     * @param body
     * @returns v1DownloadImagePermaUrlByIdBatchResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadImagePermaUrlByIdBatch(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download-image/perma-url-batch/common',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * @param body
     * @returns v1DownloadImageSignedUrlByIdBatchResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadImageSignedUrlByIdBatch(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download-image/signed-url-batch/common',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * @param body
     * @returns v1DownloadPermaUrlByIdBatchMigrationRsResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadPermaUrlByIdBatchMigrationRs(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download/perma-url-batch-migration/road-safety',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1DownloadPermaUrlBatchRoadConditionResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadPermaUrlBatchRoadCondition(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download/perma-url-batch/road-condition',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * @param body
     * @returns v1DownloadSignedUrlByIdBatchMigrationRsResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadSignedUrlByIdBatchMigrationRs(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download/signed-url-batch-migration/road-safety',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * @param body
     * @returns v1DownloadSignedUrlByIdBatchResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadSignedUrlByIdBatch(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download/signed-url-batch/common',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * @param body
     * @returns v1DownloadSignedUrlByIdResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceDownloadSignedUrlById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/download/signed-url/common',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * based customer header we will be restricting access to the file
     * @param body
     * @returns v1UploadConfirmationCommonResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceUploadConfirmationCommon(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/upload/confirmation/common',
            body: body
        });
    }
    /**
     * request will be authenticated by customer header
     * based customer header we will be restricting access to the file
     * @param body
     * @returns v1UploadSignedUrlByIdCommonResponse A successful response.
     * @throws ApiError
     */
    fileManagerServiceUploadSignedUrlByIdCommon(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/upload/signed-url/common',
            body: body
        });
    }
}
