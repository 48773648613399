var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { post } from '../common/fetch';
const GW_GET_INVITATIONS_ENDPOINT = 'v1/accounts-app/invite/list';
const GW_DELETE_INVITATION_ENDPOINT = 'v1/accounts-app/invite/delete';
const GW_SEND_INVITATION_ENDPOINT = 'v1/accounts-app/invite/create';
const GW_SET_USER_ROLE_ENDPOINT = 'v1/accounts-app/user/role/set';
const GW_RESEND_INVITATION_ENDPOINT = 'v1/accounts-app/invite/resend';
export const ERROR_CODE_INVITE_NOT_FOUND = 10006;
export function getInvitations() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_GET_INVITATIONS_ENDPOINT, undefined, undefined, true);
    });
}
export function sendInvitation(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_SEND_INVITATION_ENDPOINT, data, undefined, true);
    });
}
export function resendInvitation(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_RESEND_INVITATION_ENDPOINT, data, undefined, true);
    });
}
export function deleteInvitation(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_DELETE_INVITATION_ENDPOINT, data, undefined, true);
    });
}
export function setRoleId(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(GW_SET_USER_ROLE_ENDPOINT, { uuid: data.uuid, role_id: data.roleId }, undefined, true);
    });
}
