export function getFilterLabel(filters, name) {
    const item = filters.find((i) => i.name === name);
    if (item) {
        return item.label;
    }
    return '';
}
export function getFilterLabels(filters, names) {
    if (!names)
        return '';
    return names.map((name) => getFilterLabel(filters, name)).join(', ');
}
