import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { PLAN_CATEGORY } from '../../../../common/consts/plan';
import { getPlanCategoryLabel, getPlanCategoryVignette } from '../../../../common/convert/plan';
import { replacePartial } from '../../../../common/utils/array';
import { convertUnitInput } from '../../../../common/utils/units';
import { getDittoComponentId, useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { useModalContext } from '../../../../state/context';
import { Button } from '../../../../uikit/Button/Button';
import { Divider } from '../../../../uikit/Divider/Divider';
import { ActionButton } from '../../../ActionButton/ActionButton';
import { FormInputText } from '../../../FormInputText/FormInputText';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Bin, Close, Plus, Reset, SegmentCustom } from '../../../icons';
import { InfoBox } from '../../../InfoBox/InfoBox';
import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import { Loader } from '../../../Loader/Loader';
import { SettingPanelLayout } from '../SettingPanelLayout/SettingPanelLayout';
import styles from './PlanBudgetAndNetworkSettingPanel.module.css';
const FIXED_DECIMALS = 4;
export function PlanBudgetAndNetworkSettingPanel({ countryCode, networkSettings, setNetworkSettings, budgetsSettings, setBudgetsSettings, onSaveClick, onDeleteClick, onCancelClick, scrollToSelectedPropertyKey, unitFormatter }) {
    const refBottomAnchor = useRef(null);
    const inputRef = useRef(null);
    const { addModal } = useModalContext();
    const isLoading = !networkSettings || !budgetsSettings;
    const [networkWidth, setNetworkWidth] = useState('');
    const [streetWidth, setStreetWidth] = useState('');
    const enterBudgetLabel = useDittoWrapper({ componentId: 'plannedsegments.budget.enter' });
    const notAvailableBudgetYear = useDittoWrapper({
        componentId: 'plannedsegments.budget.occupiedyear'
    });
    useEffect(() => {
        if (!scrollToSelectedPropertyKey) {
            return;
        }
        if (inputRef.current) {
            setTimeout(() => {
                var _a;
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            }, 250);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToSelectedPropertyKey, inputRef.current]);
    useEffect(() => {
        if (networkSettings === null || networkSettings === void 0 ? void 0 : networkSettings.lengthKm) {
            setNetworkWidth(unitFormatter({
                type: 'largeLength',
                value: networkSettings === null || networkSettings === void 0 ? void 0 : networkSettings.lengthKm
            }).value.toString());
        }
        if (networkSettings === null || networkSettings === void 0 ? void 0 : networkSettings.roadWidth) {
            setStreetWidth(unitFormatter({
                type: 'length',
                value: networkSettings === null || networkSettings === void 0 ? void 0 : networkSettings.roadWidth
            }).value.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [networkSettings]);
    function scrollToYear(year) {
        setTimeout(() => {
            var _a;
            const currentRow = document.querySelector(`[data-year="${year}"]`);
            if (currentRow) {
                currentRow.scrollIntoView({ behavior: 'smooth' });
                (_a = currentRow.querySelector('input')) === null || _a === void 0 ? void 0 : _a.focus();
            }
        });
    }
    function handleResetNetworkSizeClick() {
        if (networkSettings === null || networkSettings === void 0 ? void 0 : networkSettings.defaultLengthKm) {
            setNetworkSettings('lengthKm', networkSettings.defaultLengthKm);
        }
    }
    function handleResetStreetWidthClick() {
        if (networkSettings === null || networkSettings === void 0 ? void 0 : networkSettings.defaultRoadWidth) {
            setNetworkSettings('roadWidth', networkSettings.defaultRoadWidth);
        }
    }
    function handleBudgetChange(index, field, value) {
        if (!budgetsSettings)
            return;
        setBudgetsSettings(replacePartial(budgetsSettings, index, {
            [field]: value
        }));
    }
    function handleAddBudgetClick() {
        if (!budgetsSettings)
            return;
        addModal({
            id: 'YearPickerModalContainer',
            props: {
                disabledYears: budgetsSettings === null || budgetsSettings === void 0 ? void 0 : budgetsSettings.map((budget) => budget.year),
                disabledYearsTooltip: notAvailableBudgetYear,
                onConfirm: (value) => {
                    if (value) {
                        setBudgetsSettings([
                            ...budgetsSettings,
                            {
                                year: value,
                                maintenance: undefined,
                                capital: undefined,
                                isDeletable: true
                            }
                        ].sort((a, b) => a.year - b.year));
                        scrollToYear(value);
                    }
                }
            }
        });
    }
    return (_jsxs(SettingPanelLayout, Object.assign({ overtitle: _jsx(Ditto, { componentId: "layerpanel.maplayermodal.plannedsegments" }), title: _jsx(Ditto, { componentId: "budgetandnetwork" }), icon: _jsx(SegmentCustom, {}), closeButton: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), color: "gray", onClick: () => networkSettings &&
                budgetsSettings &&
                onCancelClick &&
                onCancelClick(networkSettings, budgetsSettings) }), bottomLeftButton: _jsx(Button, Object.assign({ variant: "modal-action", onClick: handleAddBudgetClick, icon: _jsx(Plus, {}), color: "gray" }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.addbudget" }) })), bottomRightButton: _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => networkSettings &&
                budgetsSettings &&
                onSaveClick &&
                onSaveClick(networkSettings, budgetsSettings) }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }, { children: [isLoading && (_jsx("div", Object.assign({ className: styles['center'] }, { children: _jsx(Loader, {}) }))), !isLoading && (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx(InfoBox, { className: styles['info-box'], content: _jsx(Ditto, { componentId: "plannedsegments.budget.networksizeinfo" }) }), _jsx(PlanNetworkSettingRow, { label: _jsx(Ditto, { componentId: "plannedsegments.budget.networksize" }), tooltip: _jsx(Ditto, { componentId: getDittoComponentId({
                                componentId: 'globalsettings.tooltip.networksize',
                                useUsVariant: countryCode === 'US'
                            }) }), decimals: false, onReset: handleResetNetworkSizeClick, onValueChange: (value) => {
                            setNetworkWidth(value.value);
                        }, onBlur: () => {
                            setNetworkSettings('lengthKm', parseFloat(convertUnitInput('largeLength', countryCode, networkWidth).toFixed(FIXED_DECIMALS)));
                        }, value: networkWidth, unitFormatter: unitFormatter({ type: 'largeLength' }) }), _jsx(PlanNetworkSettingRow, { label: _jsx(Ditto, { componentId: "globalsettings.defaultstreetwidth" }), tooltip: _jsx(Ditto, { componentId: "globalsettings.tooltip.streetwidth" }), decimals: false, onReset: handleResetStreetWidthClick, onValueChange: (values) => {
                            setStreetWidth(values.floatValue ? values.floatValue.toString() : '');
                        }, onBlur: () => {
                            setNetworkSettings('roadWidth', streetWidth !== ''
                                ? parseFloat(convertUnitInput('length', countryCode, streetWidth).toFixed(FIXED_DECIMALS))
                                : undefined);
                        }, value: streetWidth, unitFormatter: unitFormatter({ type: 'length' }) }), _jsx(Divider, {}), _jsx("p", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "plannedsergments.budget.budgets" }) })), _jsx(InfoBox, { className: styles['info-box'], content: _jsx(Ditto, { componentId: "plannedsegments.budget.settingsinfo" }) }), (budgetsSettings === null || budgetsSettings === void 0 ? void 0 : budgetsSettings.length) > 0 && (_jsxs("div", Object.assign({ className: classes(styles['row'], styles['header']) }, { children: [_jsx("div", Object.assign({ className: styles['label'] }, { children: _jsx("p", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.year" }) })) })), _jsx("div", Object.assign({ className: classes(styles['field'], styles['field--right']) }, { children: _jsxs("div", Object.assign({ className: styles['title'] }, { children: [getPlanCategoryVignette(PLAN_CATEGORY.shortTerm), getPlanCategoryLabel(PLAN_CATEGORY.shortTerm)] })) })), _jsx("div", Object.assign({ className: classes(styles['field'], styles['field--right']) }, { children: _jsxs("div", Object.assign({ className: styles['title'] }, { children: [getPlanCategoryVignette(PLAN_CATEGORY.longTerm), getPlanCategoryLabel(PLAN_CATEGORY.longTerm)] })) })), _jsx("div", Object.assign({ className: classes(styles['field'], styles['field--right'], styles['field--thin']) }, { children: _jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.availablebudget" }) })) }))] }))), budgetsSettings === null || budgetsSettings === void 0 ? void 0 : budgetsSettings.map((budget, index) => {
                        var _a, _b;
                        return (_jsx(PlanBudgetSettingRow, { label: budget.year, onMaintenanceValueChange: (values) => {
                                if (!budget.maintenance && !values.floatValue)
                                    return;
                                handleBudgetChange(index, 'maintenance', values.floatValue || undefined);
                            }, maintenanceValue: `${budget.maintenance}`, onCapitalValueChange: (values) => {
                                if (!budget.capital && !values.floatValue)
                                    return;
                                handleBudgetChange(index, 'capital', values.floatValue || undefined);
                            }, capitalValue: `${budget.capital}`, onDelete: () => onDeleteClick && onDeleteClick(budget.year), isDeletable: budget.isDeletable, placeholder: enterBudgetLabel, inputRef: inputRef, unitFormatter: unitFormatter({
                                type: 'currency',
                                value: ((_a = budget.maintenance) !== null && _a !== void 0 ? _a : 0) + ((_b = budget.capital) !== null && _b !== void 0 ? _b : 0),
                                trimTrailingZeros: true
                            }) }, `budget-${budget.year}`));
                    }), _jsx("div", { ref: refBottomAnchor, className: styles['content-bottom-anchor'] })] })))] })));
}
function PlanNetworkSettingRow({ label, value, tooltip, isDeletable, placeholder, decimals = true, onBlur, onDelete, onReset, onValueChange, scrollToSelectedPropertyKey, inputRef, unitFormatter }) {
    const { unit, thousandSeparator, decimalSeparator } = unitFormatter;
    return (_jsxs("div", Object.assign({ className: styles['row'], "data-year": label, "data-test": "plan-budget-network-settings-row" }, { children: [_jsxs("div", Object.assign({ className: styles['label'] }, { children: [_jsxs("div", Object.assign({ className: styles['title'] }, { children: [label, tooltip && _jsx(InfoTooltip, { tooltip: tooltip })] })), onReset && (_jsx("div", Object.assign({ className: styles['reset-button-wrapper'] }, { children: _jsx(ActionButton, { disabled: !onReset, onClick: onReset, icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Reset, {}) })), tooltip: {
                                text: _jsx(Ditto, { componentId: "tooltip.treatments.resettodefault" })
                            }, size: "small" }) })))] })), _jsx("div", Object.assign({ className: styles['field'] }, { children: _jsx(NumericFormat, { customInput: FormInputText, height: 48, onBlur: onBlur, onValueChange: onValueChange, decimalScale: decimals ? 2 : 0, decimalSeparator: decimalSeparator, thousandSeparator: thousandSeparator, allowNegative: false, getInputRef: (ref) => {
                        if (scrollToSelectedPropertyKey === label && inputRef) {
                            inputRef.current = ref;
                        }
                    }, suffix: unit ? ` ${unit}` : undefined, placeholder: placeholder, textAlign: "right", value: value, valueIsNumericString: true }) })), isDeletable !== undefined && (_jsx("div", Object.assign({ className: styles['delete'] }, { children: _jsx(ActionButton, { disabled: !isDeletable, onClick: isDeletable ? onDelete : undefined, icon: _jsx(Bin, {}), tooltip: !isDeletable
                        ? {
                            text: (_jsx(Ditto, { componentId: "plannedsegments.budget.tooltip.deletebudget" }))
                        }
                        : undefined, size: "large" }) })))] })));
}
function PlanBudgetSettingRow({ label, maintenanceValue, capitalValue, tooltip, isDeletable, placeholder, decimals = true, onBlur, onDelete, onReset, onMaintenanceValueChange, onCapitalValueChange, scrollToSelectedPropertyKey, inputRef, unitFormatter }) {
    const { unit, thousandSeparator, decimalSeparator, formattedText } = unitFormatter;
    return (_jsxs("div", Object.assign({ className: styles['row'], "data-year": label }, { children: [_jsxs("div", Object.assign({ className: styles['label'] }, { children: [_jsxs("div", Object.assign({ className: styles['title'] }, { children: [label, tooltip && _jsx(InfoTooltip, { tooltip: tooltip })] })), onReset && (_jsx("div", Object.assign({ className: styles['reset-button-wrapper'] }, { children: _jsx(ActionButton, { disabled: !onReset, onClick: onReset, icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Reset, {}) })), tooltip: {
                                text: _jsx(Ditto, { componentId: "tooltip.treatments.resettodefault" })
                            }, size: "small" }) })))] })), _jsx("div", Object.assign({ className: styles['field'] }, { children: _jsx(NumericFormat, { customInput: FormInputText, height: 48, onBlur: onBlur, onValueChange: onMaintenanceValueChange, decimalScale: decimals ? 2 : 0, decimalSeparator: decimalSeparator, thousandSeparator: thousandSeparator, allowNegative: false, getInputRef: (ref) => {
                        if (scrollToSelectedPropertyKey === label && inputRef) {
                            inputRef.current = ref;
                        }
                    }, suffix: unit ? ` ${unit}` : undefined, placeholder: placeholder, textAlign: "right", value: maintenanceValue, valueIsNumericString: true }) })), _jsx("div", Object.assign({ className: styles['field'] }, { children: _jsx(NumericFormat, { customInput: FormInputText, height: 48, onBlur: onBlur, onValueChange: onCapitalValueChange, decimalScale: decimals ? 2 : 0, decimalSeparator: decimalSeparator, thousandSeparator: thousandSeparator, allowNegative: false, getInputRef: (ref) => {
                        if (scrollToSelectedPropertyKey === label && inputRef) {
                            inputRef.current = ref;
                        }
                    }, suffix: unit ? ` ${unit}` : undefined, placeholder: placeholder, textAlign: "right", value: capitalValue, valueIsNumericString: true }) })), _jsx("div", Object.assign({ className: classes(styles['field'], styles['field--right'], styles['field--thin']) }, { children: _jsx("p", Object.assign({ className: styles['value'] }, { children: formattedText })) })), isDeletable !== undefined && (_jsx("div", Object.assign({ className: styles['delete'] }, { children: _jsx(ActionButton, { disabled: !isDeletable, onClick: isDeletable ? onDelete : undefined, icon: _jsx(Bin, {}), tooltip: !isDeletable
                        ? {
                            text: (_jsx(Ditto, { componentId: "plannedsegments.budget.tooltip.deletebudget" }))
                        }
                        : undefined, size: "large" }) })))] })));
}
