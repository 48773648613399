import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Avatar } from '../../../uikit/Avatar/Avatar';
import { IconHover } from '../../../uikit/IconHover/IconHover';
import { AssigneeName } from '../../EditorForms/AssigneeSelect/AssigneeName/AssigneeName';
import styles from './AssigneePreview.module.css';
export function AssigneePreview({ options, selectedValues, wrapAfter = 7 }) {
    const firstAssignee = options.find((o) => o.id === selectedValues[0]);
    if (selectedValues.length === 1 && firstAssignee) {
        return (_jsxs(_Fragment, { children: [_jsx(Avatar, { firstName: firstAssignee.firstName, lastName: firstAssignee.lastName, src: firstAssignee.profileURL, size: 24 }), _jsx(AssigneeName, { firstName: firstAssignee.firstName, lastName: firstAssignee.lastName, truncate: true })] }));
    }
    if (selectedValues.length > 1) {
        const assignees = selectedValues.slice(0, wrapAfter);
        const assigneesWrap = selectedValues.length > assignees.length && (_jsxs("div", Object.assign({ className: styles['assignees-wrap'] }, { children: ["+", selectedValues.length - assignees.length] })));
        return (_jsxs(_Fragment, { children: [assignees
                    .map((assignee) => options.find((option) => option.id === assignee))
                    .map((assignee) => assignee && (_jsx(IconHover, { icon: _jsx(Avatar, { firstName: assignee.firstName, lastName: assignee.lastName, src: assignee.profileURL, size: 24 }), text: _jsx(AssigneeName, { firstName: assignee.firstName, lastName: assignee.lastName, truncate: true }) }, assignee.id))), assigneesWrap] }));
    }
    return _jsx(_Fragment, {});
}
