// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qIuFYcaG_MgZBnn_DM4b{display:flex;align-items:center;gap:8px;margin-right:8px;padding-right:8px;border-right:1px solid rgba(196,196,196,.3)}.qIuFYcaG_MgZBnn_DM4b:last-child{margin:0;padding:0;border-right:none}.DNql2F3z8YZCGeWPGo4q{opacity:.4;font-weight:600;font-size:14px;line-height:16px}.JLt_N082Ow226VoMqqEQ{display:flex;align-items:center;gap:8px;font-weight:600;font-size:14px;line-height:16px}`, "",{"version":3,"sources":["webpack://./src/components/AppliedFilterProperties/AppliedFilterProperties.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gBAAA,CACA,iBAAA,CACA,2CAAA,CAGJ,iCACI,QAAA,CACA,SAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    margin-right: 8px;\n    padding-right: 8px;\n    border-right: 1px solid rgba(196, 196, 196, 0.3);\n}\n\n.container:last-child {\n    margin: 0;\n    padding: 0;\n    border-right: none;\n}\n\n.filter-category {\n    opacity: 0.4;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n}\n\n.filter-values {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `qIuFYcaG_MgZBnn_DM4b`,
	"filter-category": `DNql2F3z8YZCGeWPGo4q`,
	"filter-values": `JLt_N082Ow226VoMqqEQ`
};
export default ___CSS_LOADER_EXPORT___;
