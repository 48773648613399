import { addMonths, addYears, endOfYear, startOfToday, startOfYear } from 'date-fns';
import { PLAN_PLANNED_FOR_ID } from './consts/plan';
import { getDateInUTC } from './utils/time';
export const planPlanningForFilters = [
    {
        id: PLAN_PLANNED_FOR_ID.overdue,
        value: {
            to: getDateInUTC(addMonths(startOfToday(), -1)).toISOString()
        }
    },
    {
        id: PLAN_PLANNED_FOR_ID.next3months,
        value: {
            from: getDateInUTC(startOfToday()).toISOString(),
            to: getDateInUTC(addMonths(startOfToday(), 3)).toISOString()
        }
    },
    {
        id: PLAN_PLANNED_FOR_ID.thisyear,
        value: {
            from: getDateInUTC(startOfYear(new Date())).toISOString(),
            to: getDateInUTC(endOfYear(new Date())).toISOString()
        }
    },
    {
        id: PLAN_PLANNED_FOR_ID.nextyear,
        value: {
            from: getDateInUTC(addYears(startOfYear(new Date()), 1)).toISOString(),
            to: getDateInUTC(addYears(endOfYear(new Date()), 1)).toISOString()
        }
    },
    {
        id: PLAN_PLANNED_FOR_ID.notspecified,
        value: {
            from: undefined,
            to: undefined
        }
    }
];
