// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O1Y0tGoo9t_y7tTJ_v5U{width:100%;padding:0 12px}.UF3bOFcSwRBjlP48ASLP{width:100%;margin-bottom:12px}`, "",{"version":3,"sources":["webpack://./src/components/CreatorLinkedTasks/CreatorLinkedTasks.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,cAAA,CAGJ,sBACI,UAAA,CACA,kBAAA","sourcesContent":[".container {\n    width: 100%;\n    padding: 0 12px;\n}\n\n.info-box {\n    width: 100%;\n    margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `O1Y0tGoo9t_y7tTJ_v5U`,
	"info-box": `UF3bOFcSwRBjlP48ASLP`
};
export default ___CSS_LOADER_EXPORT___;
