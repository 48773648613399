import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CUSTOM_REPEAT_OPTIONS } from '../../../common/recurringRules';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../FormInputSelect/FormInputSelect';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { TypedDitto } from '../../TypedDitto';
import { MonthlyRepeatForm } from './MonthlyRepeatForm';
import { WeeklyRepeatForm } from './WeeklyRepeatForm';
import styles from './RepeatForm.module.css';
const MAX_MENU_HEIGHT = 204;
const MENU_WIDTH = 164;
const INTERVAL_MAX = 31;
const intervals = [...Array(INTERVAL_MAX)].map((_, i) => i + 1);
export function CustomRepeatForm({ frequency, onChange, locale }) {
    const repeatOptions = {
        daily: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.custom.day" }),
        weekly: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.week" }),
        monthly: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.custom.month" }),
        yearly: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.custom.year" })
    };
    const handleRepeatOptionSelect = (option) => {
        onChange(Object.assign(Object.assign({}, frequency), { value: {
                repeat: option,
                days: []
            } }));
    };
    const handleIntervalChange = (interval) => {
        onChange(Object.assign(Object.assign({}, frequency), { interval }));
    };
    const handleValueChange = (value) => {
        onChange(Object.assign(Object.assign({}, frequency), { value: value }));
    };
    return (_jsxs("div", Object.assign({ className: styles['input-select'] }, { children: [_jsxs("span", Object.assign({ className: styles['interval-label'] }, { children: [_jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.custom.repeatevery" }), ' '] })), _jsxs("div", Object.assign({ className: styles['interval-select'] }, { children: [_jsx(FormInputSelect, { menuWidth: MENU_WIDTH, menuMaxHeight: MAX_MENU_HEIGHT, className: styles['interval-select-item'], value: _jsx(FormInputCurrentSelectedValue, { text: frequency.interval }), menuItems: intervals.map((interval) => (_jsx("div", Object.assign({ className: styles['repeat-option-half'] }, { children: _jsx(SelectboxEntry, { text: interval, onClick: () => handleIntervalChange(interval), isChecked: frequency.interval === interval }) }), interval))), canFloat: true }), _jsx(FormInputSelect, { menuWidth: MENU_WIDTH, className: styles['interval-select-item'], value: _jsx(FormInputCurrentSelectedValue, { text: repeatOptions[frequency.value.repeat] }), menuItems: CUSTOM_REPEAT_OPTIONS.map((option) => (_jsx("div", Object.assign({ className: styles['repeat-option-half'] }, { children: _jsx(SelectboxEntry, { text: repeatOptions[option], onClick: () => handleRepeatOptionSelect(option), isChecked: frequency.value.repeat === option }) }), option))), canFloat: true })] })), frequency.value.repeat === 'weekly' && (_jsx(WeeklyRepeatForm, { frequency: frequency.value, locale: locale, onChange: handleValueChange })), frequency.value.repeat === 'monthly' && (_jsx(MonthlyRepeatForm, { frequency: frequency.value, locale: locale, onChange: handleValueChange }))] })));
}
