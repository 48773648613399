import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { getSignPropertyFieldsWidth } from '../../common/convert/signInventory';
import { fromDateUTC, toDateUTC } from '../../common/utils/time';
import { CommonAttributeField } from '../../components/CommonAttributeField/CommonAttributeField';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchSignInventoryPropertySettings } from '../../state/slices/shared';
import { addSignPropertyValue, updateSignPropertyValue } from '../../state/slices/signInventoryDetail';
import { useFormatter } from '../formatting/useFormatter';
import { useUserRole } from '../useUserRole';
const FORM_FIELD_WIDTH = 334;
export function useSignFields({ position, condition }) {
    const dispatch = useAppDispatch();
    const formatter = useFormatter();
    const { signProperties, unitList } = useAppSelector((state) => state.shared);
    const { folderList } = useAppSelector((state) => state.leftPanelSignInventory);
    const { isEditor } = useUserRole();
    const isReadOnly = !isEditor;
    const initialSignFields = {
        subSpecifiedClass: '',
        condition,
        position,
        images: {
            processedImages: [],
            images: []
        },
        properties: [],
        folders: folderList.map((folder) => ({
            id: folder.id,
            name: folder.name,
            isSystem: folder.isSystem,
            componentId: folder.componentId,
            files: []
        })),
        orientation: 0
    };
    const [signFields, setSignFields] = useState(initialSignFields);
    function getSignDraft() {
        const draft = {
            subSpecifiedClass: signFields.subSpecifiedClass,
            latitude: signFields.position.lat,
            longitude: signFields.position.lng,
            signDamageClass: signFields.condition.id,
            properties: signFields.properties,
            files: signFields.folders.flatMap((folder) => {
                return folder.files.map((file) => ({
                    folderId: folder.id,
                    name: file.name,
                    uuid: file.fileLink
                }));
            }),
            orientation: signFields.orientation
        };
        return draft;
    }
    function updateSignFields(update) {
        setSignFields(Object.assign(Object.assign({}, signFields), update));
        return;
    }
    function updateSignProperties(property) {
        const index = signFields.properties.findIndex((item) => item.id === property.id);
        if (index === -1) {
            setSignFields(Object.assign(Object.assign({}, signFields), { properties: [...signFields.properties, property] }));
        }
        else {
            let updatedProperties = signFields.properties;
            updatedProperties[index] = property;
            setSignFields(Object.assign(Object.assign({}, signFields), { properties: [...updatedProperties] }));
        }
    }
    function updateSignFiles(folderId, files) {
        const folders = signFields.folders.map((folder) => {
            if (folder.id === folderId) {
                return Object.assign(Object.assign({}, folder), { files: folder.files.concat(files.map((file) => ({
                        id: -1,
                        name: file.name,
                        fileLink: file.uuid,
                        mimeType: '',
                        createdAt: ''
                    }))) });
            }
            return folder;
        });
        setSignFields(Object.assign(Object.assign({}, signFields), { folders }));
    }
    function clearSignFields() {
        setSignFields(initialSignFields);
    }
    function getCustomPropertiesFields(mode, signInfo) {
        function getSignPropertyValue(property) {
            const initialValue = signInfo === null || signInfo === void 0 ? void 0 : signInfo.properties.find((i) => i.propertyId === property.id);
            switch (property.type) {
                case 'number':
                    return initialValue === null || initialValue === void 0 ? void 0 : initialValue.numberValue;
                case 'select':
                    return initialValue === null || initialValue === void 0 ? void 0 : initialValue.selectValue;
                case 'date':
                    return (initialValue === null || initialValue === void 0 ? void 0 : initialValue.dateValue)
                        ? fromDateUTC(new Date(initialValue === null || initialValue === void 0 ? void 0 : initialValue.dateValue))
                        : undefined;
                default:
                    return initialValue === null || initialValue === void 0 ? void 0 : initialValue.textValue;
            }
        }
        function getPropertyValueByType(value, type) {
            if (value === '') {
                return null;
            }
            switch (type) {
                case 'number':
                    return parseFloat(value);
                case 'select':
                    return parseInt(value);
                case 'date':
                    return toDateUTC(value) || null;
                default:
                    return value;
            }
        }
        function handlePropertyChange(value, id, type, signId, exists) {
            if (!signId) {
                return handleCreateSignPropertyChange(value, id, type);
            }
            return handleEditSignPropertyChange(value, id, type, signId, exists);
        }
        function handleCreateSignPropertyChange(value, propertyId, type) {
            const v = getPropertyValueByType(value, type);
            updateSignProperties({ id: propertyId, [`${type}Value`]: v });
        }
        function handleEditSignPropertyChange(value, id, type, signId, exists) {
            const v = getPropertyValueByType(value, type);
            if (exists) {
                dispatch(updateSignPropertyValue({
                    id,
                    signId,
                    [`${type}Value`]: v
                }))
                    .unwrap()
                    .then(() => dispatch(fetchSignInventoryPropertySettings()));
            }
            else {
                dispatch(addSignPropertyValue({
                    signId,
                    propertyId: id,
                    [`${type}Value`]: v
                }))
                    .unwrap()
                    .then(() => dispatch(fetchSignInventoryPropertySettings()));
            }
        }
        const properties = signProperties && getSignPropertyFieldsWidth(signProperties);
        return (properties &&
            properties.map((item) => {
                var _a, _b, _c;
                let onChange = (value) => handlePropertyChange(value, item.id, item.type);
                let initialValue;
                if (mode == 'edit') {
                    const existingPropertyId = (_a = signInfo === null || signInfo === void 0 ? void 0 : signInfo.properties.find((i) => i.propertyId === item.id)) === null || _a === void 0 ? void 0 : _a.id;
                    initialValue = (_b = getSignPropertyValue(item)) === null || _b === void 0 ? void 0 : _b.toString();
                    const idToSend = existingPropertyId ? existingPropertyId : item.id;
                    onChange = (value) => handlePropertyChange(value, idToSend, item.type, signInfo === null || signInfo === void 0 ? void 0 : signInfo.id, !!existingPropertyId);
                }
                return (_jsx(CommonAttributeField, { attribute: item, dropMenuWidth: FORM_FIELD_WIDTH, onChange: onChange, initialValue: initialValue, unit: (_c = unitList.find((u) => u.id === item.unitId)) === null || _c === void 0 ? void 0 : _c.abbreviation, variant: "ghost", disabled: isReadOnly, isFullWidth: item.isFullWidth, formatter: formatter, settingPageId: "sign-inventory-properties" }, `sign-property-${signInfo === null || signInfo === void 0 ? void 0 : signInfo.id}-${item.id}`));
            }));
    }
    return {
        signFields,
        updateSignFields,
        getSignDraft,
        updateSignProperties,
        clearSignFields,
        getCustomPropertiesFields,
        updateSignFiles
    };
}
