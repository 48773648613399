import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import styles from './Radio.module.css';
export function Radio({ isChecked, onClick, isHovering, color = 'black', 'data-test': dataTest }) {
    return (_jsx("div", Object.assign({ className: classes(styles['container']), onClick: onClick, "data-test": dataTest }, { children: (function () {
            if (isHovering) {
                return (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: _jsxs("g", { children: [_jsx("path", { fill: "#D3D5D7", d: "M4 12a8 8 0 118 8 8.01 8.01 0 01-8-8z" }), _jsx("path", { fill: "#fff", d: "M9 12a3 3 0 116 0 3 3 0 01-6 0z" })] }) })));
            }
            if (!isChecked) {
                return (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: _jsx("g", { children: _jsx("g", { children: _jsxs("g", { children: [_jsx("mask", Object.assign({ id: "path-1-inside-1_1_3281", fill: "#fff" }, { children: _jsx("path", { d: "M12 20a8 8 0 118-8 8.01 8.01 0 01-8 8z" }) })), _jsx("path", { fill: "#D3D5D7", d: "M12 20v1h.001L12 20zm8-8l1 .001V12h-1zm-8 7a7 7 0 01-7-7H3a9 9 0 009 9v-2zm-7-7a7 7 0 017-7V3a9 9 0 00-9 9h2zm7-7a7 7 0 017 7h2a9 9 0 00-9-9v2zm7 6.999A7.009 7.009 0 0111.999 19L12 21A9.009 9.009 0 0021 12.001L19 12z", mask: "url(#path-1-inside-1_1_3281)" })] }) }) }) })));
            }
            if (color === 'black') {
                return (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: _jsxs("g", { children: [_jsx("path", { fill: "#222D39", d: "M4 12a8 8 0 118 8 8.01 8.01 0 01-8-8z" }), _jsx("path", { fill: "#fff", d: "M9 12a3 3 0 116 0 3 3 0 01-6 0z" })] }) })));
            }
            if (color === 'yellow') {
                return (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: _jsx("g", { children: _jsxs("g", { children: [_jsx("path", { fill: "#FBD400", d: "M4 12a8 8 0 118 8 8.01 8.01 0 01-8-8z" }), _jsx("path", { fill: "#000", d: "M9 12a3 3 0 116 0 3 3 0 01-6 0z" })] }) }) })));
            }
        })() })));
}
