import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchSignInfoById } from '../state/slices/signDetail';
export const useSignInfoFetcher = () => {
    const dispatch = useAppDispatch();
    const { selectedFeature } = useAppSelector((state) => state.feature);
    useEffect(() => {
        if (!selectedFeature || selectedFeature.type !== 'sign') {
            return;
        }
        dispatch(fetchSignInfoById(selectedFeature.id));
    }, [selectedFeature, dispatch]);
    return;
};
