import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto, DittoComponent } from 'ditto-react';
import { SIGN_STATUS_CODE } from '../../../common/consts/trafficSign';
import { getSignTypeAbbreviation } from '../../../common/convert/signInventory';
import { SparkleMagicApplied, VerifiedApproved } from '../../icons';
import { IconText } from '../../IconText/IconText';
import { ImageCrop } from '../../ImageCrop/ImageCrop';
import { TextOverflow } from '../../TextOverflow/TextOverflow';
import { List } from '../List/List';
import styles from './Sign.module.css';
const IMAGE_SIZE = { height: 140, width: 244 };
export function Sign({ content: { image, damage, signType, statusCode } }) {
    let status;
    switch (statusCode) {
        case SIGN_STATUS_CODE.predicted:
            status = (_jsx("div", Object.assign({ className: classes(styles['info'], styles['ai']) }, { children: _jsx(IconText, { icon: _jsx(SparkleMagicApplied, {}), text: _jsx(DittoComponent, { componentId: "trafficsigns.popover.ai-recommodation" }) }) })));
            break;
        case SIGN_STATUS_CODE.confirmed:
            status = (_jsx("div", Object.assign({ className: classes(styles['info'], styles['verified']) }, { children: _jsx(IconText, { icon: _jsx(VerifiedApproved, {}), iconColor: "white", text: _jsx(DittoComponent, { componentId: "trafficsigns.verified" }) }) })));
            break;
    }
    return (_jsxs(List, Object.assign({ width: '100%' }, { children: [(image === null || image === void 0 ? void 0 : image.url) && (_jsx("div", Object.assign({ className: classes(styles['image-wrapper']) }, { children: _jsx(ImageCrop, { className: styles['image'], src: image.url, croppedImageUrl: image.croppedImageUrl, alt: "Traffic Sign", tag: image.tag, imageSize: IMAGE_SIZE }) }))), signType && (_jsx("div", Object.assign({ className: styles['title-row'] }, { children: _jsxs("div", Object.assign({ className: classes(styles['info']) }, { children: [signType.icon, _jsx(Ditto, { componentId: getSignTypeAbbreviation(signType.id) })] })) }))), damage && (_jsx("div", Object.assign({ className: classes(styles['info']) }, { children: _jsx(TextOverflow, { text: damage, maxLines: 1, tooltip: "default" }) }))), status] })));
}
