import { toggleItemInArray } from '../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setWinterServiceFilter } from '../../state/slices/leftPanelWinterService';
export function useWinterServiceFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelWinterService);
    const dispatch = useAppDispatch();
    function handleTypeFilterClick(value) {
        dispatch(setWinterServiceFilter({
            type: value
        }));
    }
    function handleAgeFilterClick(value) {
        dispatch(setWinterServiceFilter({
            ages: toggleItemInArray(filter.ages, value)
        }));
    }
    return { handleTypeFilterClick, handleAgeFilterClick };
}
