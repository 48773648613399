// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lg7vEwQqsYiTwmRv2YV5{display:flex;align-items:center;padding:0 12px;height:56px}.uXmmQB9XUQpxsk_HgZGu{background:#ecf1fd;border-top:1px solid #1556eb;border-bottom:1px solid #1556eb}.lg7vEwQqsYiTwmRv2YV5:hover{cursor:pointer}.lg7vEwQqsYiTwmRv2YV5:not(.uXmmQB9XUQpxsk_HgZGu):hover{background:rgba(129,129,129,.1)}.XMkXhiAZmWdYxd4duLVU{margin-right:8px}.QAhpEzlDCYxP9uIb1YK6{width:100%}.BPV1tZETUagByRY8B8Ew{font-size:14px;line-height:20px}.sIcyJH6XrOpwVQ_7M7Vg{font-size:12px;line-height:14px;color:rgba(34,45,57,.4)}.mffuSRQTujFA_5W4uE_8{font-size:14px;line-height:14px;color:rgba(34,45,57,.4)}`, "",{"version":3,"sources":["webpack://./src/components/ReconstructionProposalsItem/ReconstructionProposalsItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CAGJ,sBACI,kBAAA,CACA,4BAAA,CACA,+BAAA,CAGJ,4BACI,cAAA,CAGJ,uDACI,+BAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CACA,uBAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CACA,uBAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    padding: 0 12px;\n    height: 56px;\n}\n\n.selected {\n    background: rgba(236, 241, 253, 1);\n    border-top: 1px solid rgba(21, 86, 235, 1);\n    border-bottom: 1px solid rgba(21, 86, 235, 1);\n}\n\n.container:hover {\n    cursor: pointer;\n}\n\n.container:not(.selected):hover {\n    background: rgba(129, 129, 129, 0.1);\n}\n\n.icon {\n    margin-right: 8px;\n}\n\n.text {\n    width: 100%;\n}\n\n.title {\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.subtitle {\n    font-size: 12px;\n    line-height: 14px;\n    color: rgba(34, 45, 57, 0.4);\n}\n\n.km {\n    font-size: 14px;\n    line-height: 14px;\n    color: rgba(34, 45, 57, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lg7vEwQqsYiTwmRv2YV5`,
	"selected": `uXmmQB9XUQpxsk_HgZGu`,
	"icon": `XMkXhiAZmWdYxd4duLVU`,
	"text": `QAhpEzlDCYxP9uIb1YK6`,
	"title": `BPV1tZETUagByRY8B8Ew`,
	"subtitle": `sIcyJH6XrOpwVQ_7M7Vg`,
	"km": `mffuSRQTujFA_5W4uE_8`
};
export default ___CSS_LOADER_EXPORT___;
