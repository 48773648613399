import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { AssigneeSelectModal } from '../../components/Modals/AssigneeSelectModal/AssigneeSelectModal';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
export function AssigneeSelectModalContainer({ initialValues = [], onConfirm, title, showReset }) {
    const { removeModal } = useModalContext();
    const { taskAssigneeList } = useAppSelector((state) => state.shared);
    const [selectedValues, setSelectedValues] = useState(initialValues);
    function handleClose() {
        removeModal('AssigneeSelectModalContainer');
    }
    function handleChange(ids) {
        setSelectedValues(ids);
    }
    return (_jsx(AssigneeSelectModal, { options: taskAssigneeList, selectedValues: selectedValues, onChange: handleChange, onClose: handleClose, onDone: onConfirm, title: title, showReset: showReset }));
}
