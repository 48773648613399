// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qi_ZNOkN9IIVyWOnYFBI{position:relative}.YoG3Zm4uQkxAaGl_iATL{width:100%;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;hyphens:auto;-ms-hyphens:auto;-webkit-hyphens:auto;-moz-hyphens:auto;word-wrap:break-word}.oziHOpTaQDACzwCbDVix{display:none;position:absolute;z-index:var(--zindex-tooltip);left:40%;bottom:-10px;transform:translateX(-60%)}.JuOE3ySNTeV7pZQ28Tnx,.CoE_m7EaokX5OldmawKA{width:100%}.jdbP0Yu6LO2pQXnG1_xd{bottom:50px}.Qi_ZNOkN9IIVyWOnYFBI:hover .oziHOpTaQDACzwCbDVix{display:block}`, "",{"version":3,"sources":["webpack://./src/components/TextOverflow/TextOverflow.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CACA,sBAAA,CACA,mBAAA,CACA,2BAAA,CACA,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CACA,oBAAA,CAGJ,sBACI,YAAA,CACA,iBAAA,CACA,6BAAA,CACA,QAAA,CACA,YAAA,CACA,0BAAA,CAGJ,4CAEI,UAAA,CAGJ,sBACI,WAAA,CAGJ,kDACI,aAAA","sourcesContent":[".overflow-wrapper {\n    position: relative;\n}\n\n.text {\n    width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    hyphens: auto;\n    -ms-hyphens: auto;\n    -webkit-hyphens: auto;\n    -moz-hyphens: auto;\n    word-wrap: break-word;\n}\n\n.anchor-tooltip {\n    display: none;\n    position: absolute;\n    z-index: var(--zindex-tooltip);\n    left: 40%;\n    bottom: -10px;\n    transform: translateX(-60%);\n}\n\n.anchor-tooltip__start,\n.anchor-tooltip__end {\n    width: 100%;\n}\n\n.anchor-tooltip__up {\n    bottom: 50px;\n}\n\n.overflow-wrapper:hover .anchor-tooltip {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overflow-wrapper": `Qi_ZNOkN9IIVyWOnYFBI`,
	"text": `YoG3Zm4uQkxAaGl_iATL`,
	"anchor-tooltip": `oziHOpTaQDACzwCbDVix`,
	"anchor-tooltip__start": `JuOE3ySNTeV7pZQ28Tnx`,
	"anchor-tooltip__end": `CoE_m7EaokX5OldmawKA`,
	"anchor-tooltip__up": `jdbP0Yu6LO2pQXnG1_xd`
};
export default ___CSS_LOADER_EXPORT___;
