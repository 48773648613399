var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { v4 as uuid } from 'uuid';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createMarkerFolder, deleteMarkerFolder, rearrangeMarkerFolder, renameMarkerFolder } from '../../services/marker';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { enableMapLayer } from '../../state/slices/layer';
import { setMarkerFilter } from '../../state/slices/leftPanelMarker';
import { addNotificationMessage } from '../../state/slices/notification';
import { fetchMarkerFolderTk } from '../../state/slices/shared';
export const useTasksFoldersSettings = () => {
    const dispatch = useAppDispatch();
    const { addModal } = useModalContext();
    const { markerFolders } = useAppSelector((state) => state.shared);
    const [foldersList, setFoldersList] = useState([]);
    const persistedFoldersList = orderedFoldersList(markerFolders);
    function orderedFoldersList(list) {
        return [...list].sort((a, b) => a.orderNumber - b.orderNumber);
    }
    function newFolder(name) {
        setFoldersList((list) => {
            let maxOrder = 0;
            list.forEach((folder) => {
                if (folder.orderNumber > maxOrder) {
                    maxOrder = folder.orderNumber;
                }
            });
            const folder = createFolder(name, maxOrder + 1);
            return [...list, folder];
        });
    }
    function rename(id, name) {
        setFoldersList((list) => {
            const newList = [...list];
            const index = newList.findIndex((item) => item.id === id);
            const folder = renameFolder(name, newList[index]);
            newList[index] = folder;
            return newList;
        });
    }
    function rearrange(id, order) {
        setFoldersList((list) => {
            // Copy objects as their properties are read-only otherwise
            const newList = [...list].map((item) => (Object.assign({}, item)));
            const oldIndex = newList.findIndex((item) => item.id === id);
            const newFolder = rearrangeFolder(order, newList[oldIndex]);
            newList.splice(oldIndex, 1);
            newList.splice(order, 0, newFolder);
            const firstOrderableIndex = newList.findIndex((item) => !item.isSystem);
            newList.slice(firstOrderableIndex).forEach((item, index) => {
                item.orderNumber = index + firstOrderableIndex;
            });
            return newList;
        });
    }
    function deleteFolder(id) {
        setFoldersList((list) => {
            const newList = [...list];
            const index = newList.findIndex((item) => item.id === id);
            newList.splice(index, 1);
            return newList;
        });
    }
    function fetchFolders() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield dispatch(fetchMarkerFolderTk()).unwrap();
            if (response.errorCode !== RESPONSE_ERROR_CODE.success) {
                dispatch(errorNotification());
            }
        });
    }
    function checkForChanges() {
        const { createOps, renameOps, deleteOps } = getDataChangeOperations(persistedFoldersList, foldersList);
        const { order } = getRearrangeOperation(foldersList.filter((folder) => typeof folder.id === 'number'));
        const orderChange = order.filter((op) => {
            const match = persistedFoldersList.find((folder) => folder.id === op.id);
            return (match === null || match === void 0 ? void 0 : match.orderNumber) !== op.orderNumber;
        });
        return [createOps, renameOps, deleteOps, orderChange].some((ops) => ops.length > 0);
    }
    function persist() {
        return __awaiter(this, void 0, void 0, function* () {
            const { createOps, renameOps, deleteOps } = getDataChangeOperations(persistedFoldersList, foldersList);
            const createFoldersResponse = yield Promise.all(createOps.map((op) => createMarkerFolder({ name: op.name })));
            if (createFoldersResponse.some((res) => res.errorCode !== RESPONSE_ERROR_CODE.success)) {
                dispatch(errorNotification());
                return null;
            }
            const createdFolders = new Map(createFoldersResponse.map((response, index) => [
                createOps[index].id,
                response.result.folder
            ]));
            const rename = renameOps.map((op) => renameMarkerFolder(op));
            const remove = deleteOps.map((op) => deleteMarkerFolder(op));
            const response = yield Promise.all([...rename, ...remove]);
            if (response.some((res) => res.errorCode !== RESPONSE_ERROR_CODE.success)) {
                dispatch(errorNotification());
                return null;
            }
            return createdFolders;
        });
    }
    function persistOrder(folders) {
        return __awaiter(this, void 0, void 0, function* () {
            const { order } = getRearrangeOperation(folders.filter((folder) => typeof folder.id === 'number'));
            const response = yield rearrangeMarkerFolder({ newOrderNumbers: order });
            if (response.errorCode !== RESPONSE_ERROR_CODE.success) {
                dispatch(errorNotification());
                return false;
            }
            return true;
        });
    }
    function sync() {
        return __awaiter(this, void 0, void 0, function* () {
            const persistResult = yield persist();
            const newList = [...foldersList];
            if (persistResult !== null && persistResult.size > 0) {
                for (const [id, createdFolder] of persistResult.entries()) {
                    const folderIndex = newList.findIndex((item) => item.id === id);
                    newList[folderIndex] = Object.assign(Object.assign({}, createdFolder), { orderNumber: newList[folderIndex].orderNumber });
                }
                setFoldersList(newList);
            }
            const persistOrderResult = yield persistOrder(newList);
            if (!persistOrderResult) {
                dispatch(errorNotification());
                return;
            }
            yield fetchFolders();
        });
    }
    function handleFoldersSaveClick(callback) {
        sync();
        callback();
    }
    function handleFoldersCancelClick(callback) {
        const changes = checkForChanges();
        if (changes) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handleFoldersSaveClick(callback);
                    },
                    onCancel: () => callback(),
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }
    function handleFoldersFilterClick(folderId) {
        if (typeof folderId === 'string') {
            console.error('Folder id is not a number => "newly" created, i.e. temporary');
            return;
        }
        dispatch(enableMapLayer('marker'));
        dispatch(setMarkerFilter({
            assignee: [],
            category: [],
            dueDateId: undefined,
            filesAttached: [folderId],
            filesNotAttached: [],
            interval: [],
            priorityId: [],
            search: undefined,
            statusId: []
        }));
    }
    function handleFolderDeleteClick(folderId, info) {
        addModal({
            id: 'DeleteModal',
            props: {
                title: _jsx(Ditto, { componentId: "prompt.delete.filecontainer.heading" }),
                description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "prompt.delete.filecontainer.text" }), info] })),
                yesLabel: _jsx(Ditto, { componentId: "delete" }),
                onConfirm: () => {
                    deleteFolder(folderId);
                }
            }
        });
    }
    // Keep local folders list up to date
    useEffect(() => {
        setFoldersList(orderedFoldersList(markerFolders));
    }, [markerFolders]);
    return {
        foldersList: foldersList,
        fetchFolders,
        createFolder: newFolder,
        renameFolder: rename,
        rearrangeFolder: rearrange,
        handleFoldersSaveClick,
        handleFoldersCancelClick,
        handleFoldersFilterClick,
        handleFolderDeleteClick
    };
};
function getDataChangeOperations(backendFolders, localFolders) {
    const createOps = localFolders
        .filter((item) => typeof item.id === 'string')
        .map((item) => {
        var _a;
        return ({
            id: item.id,
            name: (_a = item.name) !== null && _a !== void 0 ? _a : ''
        });
    });
    const renameOps = backendFolders
        .map((backendFolder) => {
        const local = localFolders.find((item) => item.id === backendFolder.id);
        return [backendFolder, local];
    })
        .filter(([backend, local]) => local && local.name !== backend.name).map(([backend, local]) => ({
        id: backend.id,
        newName: local.name
    }));
    const deleteOps = backendFolders
        .filter((backend) => !localFolders.find((local) => local.id === backend.id))
        .map((folder) => ({ id: folder.id }));
    return {
        createOps: createOps,
        renameOps: renameOps,
        deleteOps: deleteOps
    };
}
function getRearrangeOperation(folders) {
    return {
        order: folders
            .filter((folder) => !folder.isSystem)
            .map((folder) => ({ id: folder.id, orderNumber: folder.orderNumber }))
    };
}
function createFolder(name, order) {
    return {
        id: uuid(),
        isSystem: false,
        componentId: '',
        name: name,
        orderNumber: order,
        attachedMarkersCount: 0,
        notAttachedMarkersCount: 0
    };
}
function renameFolder(name, folder) {
    return Object.assign(Object.assign({}, folder), { name });
}
const rearrangeFolder = (order, folder) => {
    return Object.assign(Object.assign({}, folder), { orderNumber: order });
};
function errorNotification() {
    return addNotificationMessage({ type: 'error' });
}
