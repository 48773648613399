import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import styles from './BudgetTooltipBalanceBar.module.css';
export function BudgetTooltipBalanceBar({ availableBudget, balance, totalCosts, isMissingBudgets, unitFormatter }) {
    const totalValueFormat = unitFormatter({
        type: 'currency',
        value: totalCosts
    });
    const balanceValueFormat = unitFormatter({
        type: 'currency',
        value: balance
    });
    const progressValue = availableBudget > 0 ? (totalCosts / availableBudget) * 100 : 0;
    const balanceValue = availableBudget > 0 ? Math.abs(balance / availableBudget) * 100 : 100;
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['bar'] }, { children: [balance >= 0 && (_jsx("div", { className: styles['bar-fill'], style: { width: `${progressValue}%` } })), balance < 0 && (_jsxs(_Fragment, { children: [!isMissingBudgets && availableBudget > 0 && (_jsx("div", { className: styles['bar-fill'], style: { width: '100%' } })), _jsx("div", { className: classes(styles['bar-fill'], styles['bar-fill--exceed']), style: { width: `${!isMissingBudgets ? balanceValue : 100}%` } })] }))] })), _jsxs("div", Object.assign({ className: styles['row'] }, { children: [_jsx("div", Object.assign({ className: styles['total'] }, { children: totalValueFormat.formattedText })), _jsx("div", Object.assign({ className: classes(styles['balance'], balance < 0 && styles['balance--exceed']) }, { children: balanceValueFormat.formattedText }))] }))] })));
}
