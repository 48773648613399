export const NOTIFICATION_CLOSE_DURATION_SECONDS = 5;
export const FORM_UPDATE_NOTIFICATION_DURATION_SECONDS = 3;
export const CURRENCY_DEFAULT_DIGITS = 2;
export const ACCESS_TOKEN_DURATION_SECONDS = 295;
export const ITEMS_PER_PAGE = 25;
export const PAGE_NUMBER_FIRST = 1;
export const MAX_IMAGES_PER_UPLOAD = 10;
export const LAT_LNG_FRACTION_DIGITS = 6;
export const BULK_MAIL_LIMIT = 10;
export const BULK_PRINT_LIMIT = ITEMS_PER_PAGE;
export const BULK_CREATE_TASK_LIMIT = ITEMS_PER_PAGE;
export const BULK_CREATE_PLANNED_SEGMENTS_LIMIT = ITEMS_PER_PAGE;
export var MAINTENANCE_MODE;
(function (MAINTENANCE_MODE) {
    MAINTENANCE_MODE[MAINTENANCE_MODE["disabled"] = 0] = "disabled";
    MAINTENANCE_MODE[MAINTENANCE_MODE["enabled"] = 1] = "enabled";
    MAINTENANCE_MODE[MAINTENANCE_MODE["permanent"] = 2] = "permanent";
})(MAINTENANCE_MODE || (MAINTENANCE_MODE = {}));
export const FILE_MANAGER_LINK_SOURCE = 'web-app';
export const HEADER_X_VIA_CLIENT_VALUE = 'web-app';
export const SELECT_ALL_LIMIT = 1000;
