// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eLDmcu8sSPnSbqHZ41Sf{height:24px;width:fit-content;padding:4px 4px 4px 8px;margin-left:4px;font-weight:400;display:flex;align-items:center;justify-content:center;border-radius:6px;font-size:14px;line-height:16px;background:var(--root-dark-blue-2);color:var(--primary-black)}`, "",{"version":3,"sources":["webpack://./src/components/DatePickerContent/RepeatBadge/RepeatBadge.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,iBAAA,CACA,uBAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,kCAAA,CACA,0BAAA","sourcesContent":[".container {\n    height: 24px;\n    width: fit-content;\n    padding: 4px 4px 4px 8px;\n    margin-left: 4px;\n    font-weight: 400;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 6px;\n    font-size: 14px;\n    line-height: 16px;\n    background: var(--root-dark-blue-2);\n    color: var(--primary-black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `eLDmcu8sSPnSbqHZ41Sf`
};
export default ___CSS_LOADER_EXPORT___;
