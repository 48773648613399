import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ActionModal } from '../ActionModal/ActionModal';
import { DropMenu } from '../DropMenu/DropMenu';
export function DropMenuWrapper({ control, menu, direction, variant }) {
    const [isOpen, setIsOpen] = useState(false);
    // Ensure we close the menu on item click
    let wrappedMenu;
    if (menu.type === ActionModal) {
        wrappedMenu = (_jsx(ActionModal, Object.assign({}, menu.props, { onItemClick: () => {
                var _a, _b;
                (_b = (_a = menu.props).onItemClick) === null || _b === void 0 ? void 0 : _b.call(_a);
                setIsOpen(false);
            } })));
    }
    return (_jsx(DropMenu, { control: control, menu: wrappedMenu !== null && wrappedMenu !== void 0 ? wrappedMenu : menu, direction: direction, variant: variant, isOpen: isOpen, setIsOpen: (isOpen) => setIsOpen(isOpen) }));
}
