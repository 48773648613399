import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Edit } from '../../icons';
import { TooltipWrapper } from '../../TooltipWrapper/TooltipWrapper';
import styles from './DetailsHeader.module.css';
export function DetailsHeader({ buttonsLeft, buttonsRight, tabBar, title, titleIcon, label, withUnderline = true, withIconResizer = true, id, onTitleClick }) {
    const [isIdCopied, setIdIsCopied] = useState(false);
    const idTooltipText = isIdCopied ? (_jsx(Ditto, { componentId: "tooltip.copiedtoclipboard" })) : (_jsx(Ditto, { componentId: "tooltip.clicktocopy" }));
    function handleLabelIdClick() {
        var _a;
        // Catch and log errors if the clipboard is disabled
        navigator.clipboard.writeText((_a = id === null || id === void 0 ? void 0 : id.toString()) !== null && _a !== void 0 ? _a : '').catch((error) => {
            console.error('Copy to clipboard: ', error);
        });
        setIdIsCopied(true);
    }
    function handleTitleClick() {
        onTitleClick === null || onTitleClick === void 0 ? void 0 : onTitleClick();
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], (!withUnderline || tabBar) && styles['no-underline']) }, { children: [_jsxs("div", Object.assign({ className: styles['two-columns'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left-column']) }, { children: [buttonsLeft, label && (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: styles['label'], "data-test": "details-label" }, { children: label })), _jsx("span", Object.assign({ className: styles['id-container'] }, { children: id && (_jsx(TooltipWrapper, Object.assign({ text: idTooltipText, size: "small", onClick: handleLabelIdClick }, { children: _jsxs("span", Object.assign({ className: styles['id'], onMouseLeave: () => setIdIsCopied(false), "data-test": "item-id" }, { children: ['#', _jsx(_Fragment, { children: "\u00A0" }), id] })) }))) }))] }))] })), _jsx("div", Object.assign({ className: classes(styles['right-column']), "data-test": "details-header-right-column" }, { children: buttonsRight }))] })), title && (_jsxs("div", Object.assign({ className: classes(styles['title']), "data-test": "item-title" }, { children: [titleIcon && withIconResizer ? (_jsx(IconResizer, Object.assign({ size: 18 }, { children: titleIcon }))) : (titleIcon), _jsxs("span", Object.assign({ className: classes(onTitleClick && styles['title--editable']), onClick: onTitleClick && handleTitleClick }, { children: [_jsx("span", { children: title }), onTitleClick && (_jsx("span", Object.assign({ className: styles['title-edit'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Edit, {}) })) })))] }))] }))), _jsx("div", Object.assign({ className: styles['tab-bar'] }, { children: tabBar }))] })));
}
