import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { FormInputSelect } from '../FormInputSelect/FormInputSelect';
import { FormInputTextarea } from '../FormInputTextarea/FormInputTextarea';
import { Check, SparkleMagicApplied } from '../icons';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import { TypedDitto } from '../TypedDitto';
import { Vignette } from '../Vignette/Vignette';
import { MarkerPriorityItem, MarkerPrioritySelectedValue } from './fields/MarkerPriorityField';
import { MarkerStatusField, MarkerStatusSelectedValue } from './fields/MarkerStatusField';
import styles from '../MarkerLowerForm/MarkerLowerForm.module.css';
export function MarkerLowerForm({ isDark = false, width = 332, margin = 12, description, magicDescription, title, address, status, priority, magicPriorityIds, category, assignee, taskDatePicker, variant, onChange }) {
    const markerDescriptionLabel = useDittoWrapper({ componentId: 'taskdescription' });
    const placeholderText = useDittoWrapper({ componentId: 'notspecified' });
    const [currentDesc, setCurrentDesc] = useState(description);
    useEffect(() => {
        setCurrentDesc(description);
    }, [description]);
    function handleDescriptionChange(val) {
        setCurrentDesc(val);
        if (variant !== 'ghost') {
            onChange === null || onChange === void 0 ? void 0 : onChange({ description: val });
        }
    }
    function handleClickStatusItem(item) {
        onChange === null || onChange === void 0 ? void 0 : onChange({
            status: item
        });
    }
    function handleClickPriorityItem(item) {
        onChange === null || onChange === void 0 ? void 0 : onChange({
            priority: item
        });
    }
    function handleSaveDescription() {
        onChange === null || onChange === void 0 ? void 0 : onChange({
            description: currentDesc
        });
    }
    const priorityItems = [];
    if (magicPriorityIds && magicPriorityIds.length > 0) {
        priorityItems.push(_jsx(SelectboxEntry, { leadingIcon: _jsx(Vignette, { content: _jsx(SparkleMagicApplied, {}), color: "black" }), text: _jsx(TypedDitto, { componentId: "taskcreator.magicinputautofill" }), onClick: () => {
                const prio = priority.items.find((p) => magicPriorityIds.includes(p.priorityId));
                if (prio) {
                    handleClickPriorityItem(prio);
                }
            }, trailingIcon: priority.current && magicPriorityIds.includes(priority.current.priorityId) ? (_jsx(Check, {})) : undefined }, 'magic'));
    }
    priorityItems.push(...priority.items.map((p) => {
        var _a;
        return (_jsx(MarkerPriorityItem, { priority: p, onClick: () => handleClickPriorityItem(p), isSelected: p.priorityId === ((_a = priority.current) === null || _a === void 0 ? void 0 : _a.priorityId), isMagicInput: magicPriorityIds === null || magicPriorityIds === void 0 ? void 0 : magicPriorityIds.includes(p.priorityId) }, p.priorityId));
    }));
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles[`container__${variant}`]) }, { children: [title, address, _jsxs("div", Object.assign({ className: classes(styles['row'], styles[`row__${variant}`]) }, { children: [_jsx(FormInputSelect, { isDark: isDark, label: _jsx(Ditto, { componentId: "marker.status" }), menuMaxHeight: 250, width: width / 2 - margin / 2, menuItems: status.items.map((s) => {
                            var _a;
                            return (_jsx(MarkerStatusField, { status: s, onClick: () => handleClickStatusItem(s), isSelected: s.statusId === ((_a = status.current) === null || _a === void 0 ? void 0 : _a.statusId) }, s.statusId));
                        }), value: status.current && _jsx(MarkerStatusSelectedValue, { status: status.current }), variant: variant, canFloat: true, "data-test": "task-select-status" }), _jsx(FormInputSelect, { isDark: isDark, label: _jsx(Ditto, { componentId: "marker.priority" }), menuMaxHeight: 250, width: width / 2 - margin / 2, menuItems: priorityItems, value: priority.current && (_jsx(MarkerPrioritySelectedValue, { priority: priority.current })), variant: variant, canFloat: true, "data-test": "task-select-priority" })] })), category, assignee, taskDatePicker, _jsx(FormInputTextarea, { isDark: isDark, label: markerDescriptionLabel, width: width, variant: variant, placeholder: placeholderText, value: currentDesc, onValueChange: handleDescriptionChange, onBlur: variant === 'ghost' ? handleSaveDescription : undefined, magicValue: magicDescription, autosize: true })] })));
}
