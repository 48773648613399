// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O9dCLrlud8ex3ptIdlcw{display:flex;padding:12px;min-height:42px;align-items:center;align-self:stretch;border-radius:4px;cursor:pointer;color:var(--primary-dark-blue);transition:background-color 200ms ease;gap:6px}.O9dCLrlud8ex3ptIdlcw:hover{background:var(--status-hover-hover, rgba(129, 129, 129, 0.1))}.Q5Q54bCubDiABCeQTtPd{background:var(--status-hover-hover, rgba(129, 129, 129, 0.1))}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/SectionItem/SectionItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,8BAAA,CACA,sCAAA,CACA,OAAA,CAGJ,4BACI,8DAAA,CAGJ,sBACI,8DAAA","sourcesContent":[".container {\n    display: flex;\n    padding: 12px;\n    min-height: 42px;\n    align-items: center;\n    align-self: stretch;\n    border-radius: 4px;\n    cursor: pointer;\n    color: var(--primary-dark-blue);\n    transition: background-color 200ms ease;\n    gap: 6px;\n}\n\n.container:hover {\n    background: var(--status-hover-hover, rgba(129, 129, 129, 0.1));\n}\n\n.container--selected {\n    background: var(--status-hover-hover, rgba(129, 129, 129, 0.1));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `O9dCLrlud8ex3ptIdlcw`,
	"container--selected": `Q5Q54bCubDiABCeQTtPd`
};
export default ___CSS_LOADER_EXPORT___;
