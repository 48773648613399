import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { getPlanCategoryLabel, getPlanCategoryVignette } from '../../../common/convert/plan';
import { BudgetTooltipBalanceBar } from '../BudgetTooltipBalanceBar/BudgetTooltipBalanceBar';
import styles from './BudgetTooltipBudgetItem.module.css';
export function BudgetTooltipBudgetItem({ categoryId, balance, unitFormatter }) {
    var _a;
    const value = unitFormatter({ type: 'currency', value: (_a = balance.availableBudget) !== null && _a !== void 0 ? _a : 0 });
    return (_jsx("div", Object.assign({ className: styles['container'] }, { children: _jsxs("div", Object.assign({ className: styles['content'] }, { children: [_jsxs("div", Object.assign({ className: styles['row'] }, { children: [_jsx("div", Object.assign({ className: styles['caption'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.budget" }) })), _jsx("div", Object.assign({ className: styles['caption'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.total" }) }))] })), _jsxs("div", Object.assign({ className: styles['row'] }, { children: [_jsxs("div", Object.assign({ className: styles['title'] }, { children: [getPlanCategoryVignette(categoryId, { size: 'small' }), getPlanCategoryLabel(categoryId)] })), _jsx("div", Object.assign({ className: styles['value'] }, { children: value.formattedText }))] })), _jsxs("div", Object.assign({ className: styles['row'] }, { children: [_jsx("div", Object.assign({ className: styles['caption'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.allocated" }) })), _jsx("div", Object.assign({ className: styles['caption'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.balance" }) }))] })), _jsx("div", Object.assign({ className: styles['row'] }, { children: _jsx(BudgetTooltipBalanceBar, { availableBudget: balance.availableBudget, balance: balance.balance, totalCosts: balance.totalCosts, unitFormatter: unitFormatter }) }))] })) })));
}
