import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { Ditto } from 'ditto-react';
import { downloadFile } from '../common/utils/downloadFile';
import { Files } from '../components/Files/Files';
import { Folder } from '../components/Files/Folder/Folder';
import { SingleFile } from '../components/Files/SingleFile/SingleFile';
import { TextOverflow } from '../components/TextOverflow/TextOverflow';
import { useModalContext } from '../state/context';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchSignInventoryFoldersList } from '../state/slices/leftPanelSignInventory';
import { deleteFileTk, setSelectedFileId } from '../state/slices/signInventoryDetail';
import { useDateTimeFormatter } from './formatting/useDateTimeFormatter';
import { useTilesUpdator } from './mainMap/useTilesUpdator';
import { useSignInventoryFileUpload } from './useSignInventoryFileUpload';
import { useUserRole } from './useUserRole';
export const useSignInventoryFiles = ({ mode, signFields, onGetUuids }) => {
    const dispatch = useAppDispatch();
    const { isAdmin, isEditor } = useUserRole();
    const { signInfo, selectedFileId } = useAppSelector((state) => state.signInventoryDetail);
    const { folderList } = useAppSelector((state) => state.leftPanelSignInventory);
    const { addModal, removeModal, modals } = useModalContext();
    const dateTimeFormatter = useDateTimeFormatter();
    const { selectFilesForUpload } = useSignInventoryFileUpload();
    const { reloadSignInventoryTilesData } = useTilesUpdator();
    useEffect(() => {
        if (signInfo && (signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders.length) > 0) {
            return;
        }
        if (folderList.length > 0) {
            return;
        }
        dispatch(fetchSignInventoryFoldersList());
    }, [dispatch, folderList, signInfo]);
    const handleEditClick = useCallback(() => {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'sign-inventory-files'
            }
        });
    }, [addModal]);
    const handleFilePlusClick = useCallback((folderId) => {
        selectFilesForUpload(() => Promise.resolve({ folderId }), signInfo === null || signInfo === void 0 ? void 0 : signInfo.id, onGetUuids);
    }, [selectFilesForUpload, signInfo, onGetUuids]);
    const handleFileClick = useCallback((fileId) => {
        if (mode === 'create') {
            return;
        }
        dispatch(setSelectedFileId({ id: fileId }));
        const modal = modals.find((modal) => modal.id === 'FileGallerySignInventory');
        if (!modal) {
            removeModal('ImageViewerSignInventory');
            addModal({ id: 'FileGallerySignInventory' });
        }
    }, [addModal, dispatch, modals, mode, removeModal]);
    const handleDownloadClick = useCallback((fileLink, filename) => {
        downloadFile(fileLink, filename);
    }, []);
    const handleFileDeleteClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (fileId) => {
        if (!signInfo) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteFileTk({ signId: signInfo === null || signInfo === void 0 ? void 0 : signInfo.id, fileId })).then(() => {
                        reloadSignInventoryTilesData();
                    });
                },
                title: _jsx(Ditto, { componentId: "prompt.delete.file.deletefile" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.file.text" })
            }
        });
    }, [signInfo, addModal, dispatch, reloadSignInventoryTilesData]);
    const filesSection = useMemo(() => composeFilesSection({
        isEditor: isEditor,
        isAdmin: isAdmin,
        folders: (mode === 'edit' ? signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders : signFields === null || signFields === void 0 ? void 0 : signFields.folders) || [],
        withDropMenu: mode === 'edit',
        handleEditClick,
        handleFilePlusClick,
        handleFileClick,
        handleDownloadClick,
        handleFileDeleteClick,
        options: { dateTimeFormatter },
        selectedFileId: selectedFileId,
        mode
    }), [
        dateTimeFormatter,
        handleDownloadClick,
        handleEditClick,
        handleFileClick,
        handleFileDeleteClick,
        handleFilePlusClick,
        isAdmin,
        isEditor,
        mode,
        signFields === null || signFields === void 0 ? void 0 : signFields.folders,
        signInfo === null || signInfo === void 0 ? void 0 : signInfo.folders,
        selectedFileId
    ]);
    return { filesSection };
};
const composeFilesSection = ({ isEditor, isAdmin, folders, withDropMenu = true, handleEditClick, handleFilePlusClick, handleFileClick, handleDownloadClick, handleFileDeleteClick, options, selectedFileId, mode }) => {
    const fileCount = folders
        .map((folder) => folder.files.length)
        .reduce((acc, current) => acc + current, 0);
    return (_jsx(Files, Object.assign({ title: mode === 'create' && _jsx(Ditto, { componentId: "files.files" }), count: fileCount, editable: isAdmin, onEditClick: handleEditClick }, { children: folders.map((folder) => (_jsx(Folder, Object.assign({ name: getFolderName(folder), isEditor: isEditor, onPlusClick: () => handleFilePlusClick(folder.id) }, { children: folder.files.map((file) => (_jsx(SingleFile, { name: _jsx(TextOverflow, { text: file.name, maxLines: 1 }), date: options.dateTimeFormatter(file.createdAt).date, isEditor: isEditor, withDropMenu: withDropMenu, onFileClick: () => handleFileClick(file.id), onDownloadClick: () => handleDownloadClick(file.fileLink, file.name), onDeleteClick: () => handleFileDeleteClick(file.id), isSelected: selectedFileId === file.id, previewUrl: file.mimeType.startsWith('image/') ? file.fileLink : undefined }, file.id))) }), folder.id))) })));
};
const getFolderName = (folder) => {
    if (!folder.isSystem) {
        return folder.name;
    }
    return _jsx(Ditto, { componentId: folder.componentId });
};
