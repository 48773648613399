import { toggleItemInArray } from '../common/utils/array';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { setAssetSelectedIdList } from '../state/slices/leftPanelAsset';
import { setMarkerSelectedIdList } from '../state/slices/leftPanelMarker';
export function useMapMultiSelect() {
    const dispatch = useAppDispatch();
    const { isMultiSelectOn: isAssetMultiSelectOn, selectedIdList: selectedAssetIdList } = useAppSelector((state) => state.leftPanelAsset);
    const { isMultiSelectOn: isMakrerMultiSelectOn, selectedIdList: selectedMarkerIdList } = useAppSelector((state) => state.leftPanelMarker);
    const showMultiSelectHint = (isAssetMultiSelectOn && selectedAssetIdList.length >= 1) ||
        (isMakrerMultiSelectOn && selectedMarkerIdList.length >= 1);
    const isMultiSelectOn = isAssetMultiSelectOn || isMakrerMultiSelectOn;
    function multiSelectFeature(feature) {
        if (isAssetMultiSelectOn && feature.type === 'asset') {
            dispatch(setAssetSelectedIdList(toggleItemInArray(selectedAssetIdList, feature.id)));
        }
        if (isMakrerMultiSelectOn && feature.type === 'marker') {
            dispatch(setMarkerSelectedIdList(toggleItemInArray(selectedMarkerIdList, feature.id)));
        }
    }
    return { showMultiSelectHint, isMultiSelectOn, multiSelectFeature };
}
