// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ekv3UxkqVTUoOSTFXbER{position:relative;padding:12px 12px 12px 40px;color:#1556eb;font-weight:400;font-size:12px;line-height:18px;background:rgba(21,86,235,.1);border-radius:4px}.nb5g8tT6lFxAXfl_l8AG{position:absolute;top:8px;left:8px}.jso3YsSq1hsYPNq3TGag{display:inline-block;margin-top:8px;text-decoration:underline}.jso3YsSq1hsYPNq3TGag:hover{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsInfo/DetailsInfo.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,2BAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,6BAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,OAAA,CACA,QAAA,CAGJ,sBACI,oBAAA,CACA,cAAA,CACA,yBAAA,CAGJ,4BACI,cAAA","sourcesContent":[".container {\n    position: relative;\n    padding: 12px 12px 12px 40px;\n    color: rgba(21, 86, 235, 1);\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n    background: rgba(21, 86, 235, 0.1);\n    border-radius: 4px;\n}\n\n.icon {\n    position: absolute;\n    top: 8px;\n    left: 8px;\n}\n\n.link {\n    display: inline-block;\n    margin-top: 8px;\n    text-decoration: underline;\n}\n\n.link:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ekv3UxkqVTUoOSTFXbER`,
	"icon": `nb5g8tT6lFxAXfl_l8AG`,
	"link": `jso3YsSq1hsYPNq3TGag`
};
export default ___CSS_LOADER_EXPORT___;
