export const createIconWithAttachment = (baseImageUrl, attachmentUrl, attachmentBackgroundUrl) => {
    const baseSize = 90;
    const iconChannels = 4;
    let isRendered = false;
    let data = new Uint8Array(baseSize * baseSize * iconChannels);
    let isBaseImageLoaded = false;
    const baseImage = new Image();
    baseImage.src = baseImageUrl;
    baseImage.onload = () => {
        isBaseImageLoaded = true;
    };
    let isAttachmentImageLoaded = false;
    const attachmentImage = new Image();
    attachmentImage.src = attachmentUrl;
    attachmentImage.onload = () => {
        isAttachmentImageLoaded = true;
    };
    let isAttachmentBackgroundImageLoaded = false;
    const attachmentBackgoundImage = new Image();
    attachmentBackgoundImage.src = attachmentBackgroundUrl;
    attachmentBackgoundImage.onload = () => {
        isAttachmentBackgroundImageLoaded = true;
    };
    const canvas = document.createElement('canvas');
    canvas.width = baseSize;
    canvas.height = baseSize;
    const ctx = canvas.getContext('2d');
    return () => {
        return {
            data,
            width: baseSize,
            height: baseSize,
            render: function () {
                if (isRendered) {
                    return false;
                }
                if (!ctx) {
                    console.error('IconWithAttachments failed to get 2d context');
                    return false;
                }
                if (!isBaseImageLoaded ||
                    !isAttachmentImageLoaded ||
                    !isAttachmentBackgroundImageLoaded) {
                    return false;
                }
                ctx.drawImage(baseImage, 0, 30, 60, 60);
                ctx.drawImage(attachmentBackgoundImage, 40, 10, 45, 45);
                ctx.drawImage(attachmentImage, 43, 13, 38, 38);
                this.data = ctx.getImageData(0, 0, baseSize, baseSize).data;
                isRendered = true;
                return true;
            }
        };
    };
};
