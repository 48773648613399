// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W6UviNt_A4Ir0dV1w690{border:2px solid rgba(0,0,0,0);padding:10px 12px}.Uw__KAMyTlqIXZrmVoN5{opacity:.6;margin-bottom:4px}.BEGCU4P86FJmZf1goiDE{display:flex;flex-direction:column;gap:4px}.v0nI7m7l_hUkxfVwtNkk{opacity:.3}`, "",{"version":3,"sources":["webpack://./src/components/PrintLayout/PrintField/PrintField.module.css"],"names":[],"mappings":"AAAA,sBACI,8BAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,OAAA,CAGJ,sBACI,UAAA","sourcesContent":[".container {\n    border: 2px solid transparent;\n    padding: 10px 12px;\n}\n\n.label {\n    opacity: 0.6;\n    margin-bottom: 4px;\n}\n\n.value {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.placeholder {\n    opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `W6UviNt_A4Ir0dV1w690`,
	"label": `Uw__KAMyTlqIXZrmVoN5`,
	"value": `BEGCU4P86FJmZf1goiDE`,
	"placeholder": `v0nI7m7l_hUkxfVwtNkk`
};
export default ___CSS_LOADER_EXPORT___;
