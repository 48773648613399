import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { format } from 'date-fns';
import { IconResizer } from '../../IconResizer/IconResizer';
import { ArrowLeft, ArrowRight, Caret } from '../../icons';
import styles from './CustomHeader.module.css';
export function CustomHeader({ locale, monthDate, decreaseMonth, increaseMonth, decreaseYear, increaseYear, toggleMonthYear, isRenderingMonth }) {
    return (_jsxs("div", Object.assign({ className: styles['custom-header'] }, { children: [_jsx("button", Object.assign({ "aria-label": "Previous Month", onClick: isRenderingMonth ? decreaseYear : decreaseMonth, className: styles['nav-button'], "data-test": "previous-month-year" }, { children: _jsx(ArrowLeft, {}) })), _jsxs("span", Object.assign({ className: styles['current-date-wrapper'] }, { children: [_jsx("span", Object.assign({ className: styles['current-month'], "data-test": "current-month-year" }, { children: isRenderingMonth
                            ? format(monthDate, 'yyyy', {
                                locale: locale
                            })
                            : format(monthDate, 'MMMM yyyy', {
                                locale: locale
                            }) })), _jsx("button", Object.assign({ value: '', className: classes(styles['year-select'], isRenderingMonth && styles['year-select__on']), onClick: toggleMonthYear, "data-test": "year-select-toggle" }, { children: _jsx(IconResizer, Object.assign({ size: 20 }, { children: _jsx(Caret, {}) })) }))] })), _jsx("button", Object.assign({ "aria-label": "Next Month", onClick: isRenderingMonth ? increaseYear : increaseMonth, className: styles['nav-button'], "data-test": "next-month-year" }, { children: _jsx(ArrowRight, {}) }))] })));
}
