import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { AnimatePresence, motion } from 'framer-motion';
import { dropdownVariants } from '../../../../common/consts/animations';
import { getRemainingServiceLifeIcon } from '../../../../common/convert';
import useComponentWidth from '../../../../hooks/useComponentWidth';
import { getDittoComponentId, useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { Button } from '../../../../uikit/Button/Button';
import { Info } from '../../../icons';
import { TextOverflow } from '../../../TextOverflow/TextOverflow';
import styles from './DataGridTableFooterItemMileYears.module.css';
const HIDE_DELAY = 1000; // ms
export function DataGridTableFooterItemMileYears({ countryCode, selectedYears, size, networkSize, stickyPosition, onEditNetworkClick, unitFormatter }) {
    const { ref, width } = useComponentWidth();
    const [isOpen, setIsOpen] = useState(false);
    const timeoutRef = useRef();
    const lifeExtensionKmUnit = useDittoWrapper({
        componentId: 'remainingservicelife.mileyears.abbreviation',
        useUsVariant: countryCode === 'US'
    });
    const balance = size - networkSize;
    const isNotAvailable = selectedYears.length === 0 || selectedYears.includes(0);
    // TODO: change to useFormatter
    const sizeFormat = unitFormatter({
        type: 'largeLength',
        decimals: 2,
        trimTrailingZeros: true,
        value: size,
        unit: lifeExtensionKmUnit
    });
    const balanceFormat = unitFormatter({
        type: 'largeLength',
        decimals: 2,
        trimTrailingZeros: true,
        value: balance,
        unit: lifeExtensionKmUnit
    });
    const networkSizeFormat = unitFormatter({
        type: 'largeLength',
        decimals: 2,
        trimTrailingZeros: true,
        value: networkSize,
        unit: lifeExtensionKmUnit
    });
    useOnClickOutside(ref, () => setIsOpen(false));
    useEffect(() => {
        var _a;
        const scrollContainer = document.getElementById('left-panel-container');
        if (!scrollContainer || !isOpen)
            return;
        let initScrollX = (_a = scrollContainer.scrollLeft) !== null && _a !== void 0 ? _a : 0;
        function scrollListener() {
            if (!scrollContainer)
                return;
            const scrollX = scrollContainer.scrollLeft;
            if (Math.abs(initScrollX - scrollX) > width) {
                setIsOpen(false);
            }
        }
        scrollContainer.addEventListener('scroll', scrollListener, { passive: true });
        return () => {
            scrollContainer.removeEventListener('scroll', scrollListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    function handleOpen() {
        if (!isOpen) {
            setIsOpen(true);
        }
    }
    function handleClose() {
        if (isOpen) {
            setIsOpen(false);
        }
    }
    function handleClick() {
        if (!isOpen) {
            handleOpen();
        }
        else {
            handleClose();
        }
    }
    function handleMouseEnter() {
        clearTimeout(timeoutRef.current);
        handleOpen();
    }
    function handleMouseLeave() {
        timeoutRef.current = setTimeout(() => {
            setIsOpen(false);
        }, HIDE_DELAY);
    }
    function getRemainingServiceLifeText() {
        if (isNotAvailable) {
            return _jsx(Ditto, { componentId: "tooltip.remainingservicelife.notavailable" });
        }
        if (balance === 0) {
            return (_jsx(Ditto, { componentId: getDittoComponentId({
                    componentId: 'tooltip.remainingservicelife.stable',
                    useUsVariant: countryCode === 'US'
                }), variables: { RSL50KM: networkSizeFormat.formattedValue } }));
        }
        if (balance > 0) {
            return (_jsx(Ditto, { componentId: getDittoComponentId({
                    componentId: 'tooltip.remainingservicelife.plus',
                    useUsVariant: countryCode === 'US'
                }), variables: {
                    RSL52KM: sizeFormat.formattedValue,
                    RSL50KM: networkSizeFormat.formattedValue
                } }));
        }
        if (balance < 0) {
            return (_jsx(Ditto, { componentId: getDittoComponentId({
                    componentId: 'tooltip.remainingservicelife.total',
                    useUsVariant: countryCode === 'US'
                }), variables: {
                    RSL49KM: sizeFormat.formattedValue,
                    RSL50KM: networkSizeFormat.formattedValue,
                    LeftRSLKM: balanceFormat.formattedValue.replace('-', '')
                } }));
        }
    }
    return (_jsxs("td", Object.assign({ className: classes(styles['container'], styles['container--border'], stickyPosition !== undefined && styles['container--pinned']), style: { width, left: stickyPosition }, onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, ref: ref }, { children: [_jsxs("div", Object.assign({ className: classes(styles['content']) }, { children: [!isNotAvailable ? getRemainingServiceLifeIcon(balance) : _jsx(Info, {}), _jsxs("div", Object.assign({ className: styles['text'] }, { children: [_jsx("div", Object.assign({ className: styles['size'] }, { children: _jsx(TextOverflow, { text: sizeFormat.formattedText, maxLines: 1, tooltip: "small", tooltipPosition: "up", onWidthChange: () => { } }) })), _jsx("div", Object.assign({ className: classes(styles['balance'], !isNotAvailable && balance < 0 && styles['negative']) }, { children: _jsx(TextOverflow, { text: !isNotAvailable
                                        ? balanceFormat.formattedText
                                        : `- ${balanceFormat.unit}`, maxLines: 1, tooltip: "small", tooltipPosition: "up", onWidthChange: () => { } }) }))] }))] })), _jsx(AnimatePresence, { children: isOpen && (_jsxs(motion.div, Object.assign({ className: styles['tooltip'], variants: dropdownVariants('up'), initial: "initial", animate: "visible", exit: "exit" }, { children: [_jsx("div", Object.assign({ className: styles['tooltip__header'] }, { children: _jsx(Ditto, { componentId: "remainingservicelife.title" }) })), getRemainingServiceLifeText(), _jsx(Button, Object.assign({ className: styles['button'], color: "dark-gray", onClick: onEditNetworkClick, variant: "fullwidth" }, { children: _jsx(Ditto, { componentId: "tooltip.remainingservicelife.editnetworksize" }) }))] }))) })] })));
}
