import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import moment from 'moment';
import { Button } from '../../../uikit/Button/Button';
import { UserDataForm } from '../../Auth/UserDataForm/UserDataForm';
import { Close } from '../../icons';
import { Loader } from '../../Loader/Loader';
import { Modal } from '../../Modal/Modal';
import styles from './UserAccountModal.module.css';
export function UserAccountModal({ currentLocale, departmentList, languageList, loading, user, values, onChange, onClickUpload, onSaveClick, onCancelClick, onResetPasswordClick, onClickOutside }) {
    return (_jsx(Modal, { width: 576, onClickOutside: onClickOutside, title: _jsx(Ditto, { componentId: "useraccountsettings" }), "data-test": "user-account-modal", trailing: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), onClick: () => onCancelClick && onCancelClick(), "data-test": "button-close" }), content: _jsxs(_Fragment, { children: [loading ? (_jsx("div", Object.assign({ className: styles['loader'] }, { children: _jsx(Loader, {}) }))) : (_jsx(UserDataForm, { currentLocale: currentLocale, departmentList: departmentList, languageList: languageList, onChange: onChange, onClickUpload: onClickUpload, user: user, values: values })), _jsxs("p", Object.assign({ className: styles['copyright'] }, { children: ["vialytics \u00A9 ", moment().year()] }))] }), contentGap: 12, footer: _jsxs(_Fragment, { children: [onResetPasswordClick && (_jsx(Button, Object.assign({ variant: "modal-action", className: styles['reset-button'], onClick: () => onResetPasswordClick(), "data-test": "reset-password-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "useraccount.resetpw" }) })) }))), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: () => onCancelClick && onCancelClick() }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })), _jsx(Button, Object.assign({ disabled: loading ||
                                !values.firstName ||
                                !values.lastName ||
                                !values.titleId ||
                                !values.departmentId, variant: "modal-action", color: "dark", onClick: () => onSaveClick && onSaveClick(), "data-test": "save-user-account-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }))] }))] }) }));
}
