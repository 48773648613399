var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { STREETART_ASSET_TYPE_ID } from '../../common/consts/asset';
import { INSPECTION_MODE_ID } from '../../common/consts/inspection';
import { MARKER_PRIORITY_ID, MARKER_SECTION_ID, MARKER_STATUS_ID } from '../../common/consts/marker';
import { PLAN_STATUS } from '../../common/consts/plan';
import { DEBOUNCE_SHARED_FETCH } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getTimeParams } from '../../common/utils/params';
import { getUserAccountList } from '../../services/accounts';
import { getAlertTypes } from '../../services/alert';
import { getAssetFileFoldersList, getAssetTypes, getUnits } from '../../services/asset';
import { getConditionFilters, getFilterTypes, getGradePercentages, getRoadPropsDict } from '../../services/condition';
import { getInspectionAgeFilters, getWinterServiceFilters } from '../../services/inspection';
import { createMarkerCategory, deleteMarkerCategory, getMarkerAttributeSchemaList, getMarkerFileFoldersList, getMarkerFilters, NOT_SPECIFIED_CATEGORY_ID, updateMarkerCategory } from '../../services/marker';
import { getUsersProfile } from '../../services/notifications';
import { getObjectTypes } from '../../services/object';
import { getPlanCategoryList, getPlanFilters, getPlanPropertyTypeList, getPlanStatusList, getPlanTreatmentsList, getPlanUnitTypeList, removeTreatmentSettings, saveTreatmentsSettings } from '../../services/plan';
import { getSignDamageTypes } from '../../services/sign';
import { getSignInventoryDamageTypes, getSignInventoryPropertySettings, getSignInventoryStatuses, getSignTree } from '../../services/signInventory';
import { setIsLoadingActionbar } from './actionbar';
import { setAlertFilter } from './leftPanelAlert';
import { addNotificationMessage } from './notification';
const initialState = {
    users: [],
    userMap: {},
    conditionFilters: {
        segmentLength: {
            min: 0,
            max: 0
        },
        types: {
            roadDamages: [],
            surfaceTypes: [],
            streetTypes: [],
            objects: [],
            roadDamageGroups: [],
            objectGroups: []
        }
    },
    markerStatusList: [],
    markerPriorityList: [],
    markerCategoryList: [],
    markerCategoryMap: {},
    markerFilters: {
        intervals: [],
        dueDates: [],
        categories: [],
        assignees: [],
        priorities: [],
        statuses: []
    },
    markerFolders: [],
    taskAttributeMap: {},
    taskCostAttributeSchema: [],
    taskAssigneeList: [],
    taskAssigneeMap: {},
    planFilters: {
        area: {
            min: 0,
            max: 0
        },
        budget: [],
        category: [],
        costs: {
            min: 0,
            max: 0
        },
        efficiency: [],
        firmlyPlanned: [],
        treatment: [],
        plannedFor: [],
        priority: [],
        status: [],
        yearsOfUse: {
            min: 0,
            max: 0
        },
        customData: []
    },
    planStatusList: [],
    planCategoryList: [],
    planTreatmentsList: [],
    planTreatmentUnitTypes: [],
    planPropertyTypes: [],
    planUnitTypes: [],
    roadPropsDict: {
        roadProps: {
            labels: {},
            streetTypes: {},
            surfaceTypes: {},
            roadDamages: {},
            roadDamageGroups: {},
            objects: {},
            objectGroups: {}
        }
    },
    gradePercentages: [],
    alertTypes: [],
    objectTypes: [],
    signDamageTypes: [],
    signInventoryDamageTypes: [],
    signStatuses: [],
    signProperties: [],
    trackControlAgeList: [],
    winterServiceModeList: [],
    winterServiceAgeList: [],
    signTree: [],
    assetTypeList: [],
    assetTypeMap: {},
    assetFolders: [],
    unitList: [],
    unitMap: {},
    isSignDamageTypesLoading: false,
    isSignTreeLoading: false
};
export const fetchConditionFilters = createDebouncedAsyncThunk('shared/fetchConditionFilters', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getConditionFilters({ language: getState().app.locale });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
// NOTE: do NOT duplicate thunk functions which using the same service function in different slices.
// the thunk functions are sharable between containers.
export const fetchMarkerFilters = createDebouncedAsyncThunk('shared/fetchMarkerFilters', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getMarkerFilters({ language: getState().app.locale });
    // NOTE: notifications for api calls are done in the Thunk layer, by "dispatch",
    // so that each container does not need to handle separatly
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchMarkerAttributeSchemaTk = createDebouncedAsyncThunk('shared/fetchMarkerAttributeSchemaTk', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getMarkerAttributeSchemaList({ language: getState().app.locale });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchMarkerFolderTk = createDebouncedAsyncThunk('shared/fetchMarkerFolderTk', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getMarkerFileFoldersList({ language: getState().app.locale });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchAssetFolderTk = createDebouncedAsyncThunk('shared/fetchAssetFolderTk', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getAssetFileFoldersList({
        language: getState().app.locale,
        assetTypeId: STREETART_ASSET_TYPE_ID
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchPlanFilters = createDebouncedAsyncThunk('shared/fetchPlanFilters', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = getState();
    const res = yield getPlanFilters({
        language: state.app.locale,
        countryCode: (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode
    });
    // NOTE: notifications for api calls are done in the Thunk layer, by "dispatch",
    // so that each container does not need to handle separatly
    if (res.errorCode === RESPONSE_ERROR_CODE.emptyError) {
        return Object.assign(Object.assign({}, res), { result: null });
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchTrackControlAgeFilters = createDebouncedAsyncThunk('shared/fetchTrackControlAgeFilters', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getInspectionAgeFilters({
        language: getState().app.locale,
        inspectionModeId: INSPECTION_MODE_ID.trackControl
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchWinterServiceFilters = createDebouncedAsyncThunk('shared/fetchWinterServiceFilters', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getWinterServiceFilters({});
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchWinterServiceAgeFilters = createDebouncedAsyncThunk('shared/fetchWinterServiceAgeFilters', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getInspectionAgeFilters({
        language: getState().app.locale,
        inspectionModeId: INSPECTION_MODE_ID.winterService
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchUserAccountList = createDebouncedAsyncThunk('shared/fetchUserAccountList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getUserAccountList();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const deleteMarkerCategoryTk = createAppAsyncThunk('shared/markerCategory/delete', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield deleteMarkerCategory(id);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.categorydeleted' }
            }
        }));
        dispatch(fetchMarkerFilters());
    }
    dispatch(setIsLoadingActionbar(false));
    return res;
}));
export const updateMarkerCategoryTk = createAppAsyncThunk('shared/markerCategory/update', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateMarkerCategory(body);
    dispatch(setIsLoadingActionbar(false));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchMarkerFilters());
    }
    return res;
}));
export const createMarkerCategoryTk = createAppAsyncThunk('shared/markerCategory/create', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield createMarkerCategory(body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchMarkerFilters());
    }
    dispatch(setIsLoadingActionbar(false));
    return res;
}));
export const fetchPlanStatus = createDebouncedAsyncThunk('shared/planStatus/fetchList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getPlanStatusList();
    // NOTE: notifications for api calls are done in the Thunk layer, by "dispatch",
    // so that each container does not need to handle separatly
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchPlanCategory = createDebouncedAsyncThunk('shared/planCategory/fetchList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getPlanCategoryList();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchPlanTreatmentsList = createDebouncedAsyncThunk('shared/planTreatments/fetchList', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { locale } = state.app;
    const { teamAccount } = state.auth;
    const res = yield getPlanTreatmentsList({
        language: locale,
        countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchPlanPropertyTypes = createDebouncedAsyncThunk('shared/planPropertyType/fetchList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getPlanPropertyTypeList();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchPlanUnitTypes = createDebouncedAsyncThunk('shared/planUnitType/fetchList', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const state = getState();
    const res = yield getPlanUnitTypeList({ countryCode: (_b = state.auth.teamAccount) === null || _b === void 0 ? void 0 : _b.countryCode });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchRoadPropsDict = createDebouncedAsyncThunk('shared/fetchRoadPropsDict', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const res = yield getRoadPropsDict(state.app.locale);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchGradePercentages = createDebouncedAsyncThunk('shared/fetchGradePercentages', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const res = yield getGradePercentages(state.presetsPanel.timepicker.selectedOption);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchFilterTypes = createDebouncedAsyncThunk('shared/fetchFilterTypes', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const res = yield getFilterTypes(state.app.locale);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchAlertTypes = createDebouncedAsyncThunk('shared/fetchAlertTypes', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getAlertTypes();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.result) {
        // TODO: (need BE changes) change the ids for alert type either to number or string,
        // right now it is mapping everywhere by string but the filter accepts number
        // which made it impossible to set default by redux states
        // @Radu is the number id consistent?
        const defaultFilterTypeIds = res.result.list
            .filter((i) => i.typeName === 'pothole')
            .map((i) => i.typeId);
        dispatch(setAlertFilter({
            typeId: defaultFilterTypeIds
        }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchObjectTypes = createDebouncedAsyncThunk('shared/fetchObjectTypes', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getObjectTypes();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignDamageTypes = createDebouncedAsyncThunk('shared/fetchSignDamageTypes', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const res = yield getSignDamageTypes({
        timepicker: getTimeParams(selectedOption),
        locale,
        withCounts: false
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignDamageTypesWithCounts = createDebouncedAsyncThunk('shared/fetchSignDamageTypesWithCounts', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const res = yield getSignDamageTypes({
        timepicker: getTimeParams(selectedOption),
        locale,
        withCounts: true
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignInventoryDamageTypes = createDebouncedAsyncThunk('shared/fetchSignInventoryDamageTypes', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const res = yield getSignInventoryDamageTypes({
        timepicker: getTimeParams(selectedOption),
        locale,
        withCounts: false
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignInventoryDamageTypesWithCounts = createDebouncedAsyncThunk('shared/fetchSignInventoryDamageTypesWithCounts', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const res = yield getSignInventoryDamageTypes({
        timepicker: getTimeParams(selectedOption),
        locale,
        withCounts: true
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignInventoryStatuses = createDebouncedAsyncThunk('shared/fetchSignInventoryStatuses', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInventoryStatuses({});
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignInventoryPropertySettings = createDebouncedAsyncThunk('shared/fetchSignInventoryPropertySettings', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInventoryPropertySettings({});
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignInventoryTree = createDebouncedAsyncThunk('shared/fetchSignInventoryTree', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const res = yield getSignTree({
        timepicker: getTimeParams(selectedOption),
        locale,
        withCounts: false
    });
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchSignInventoryTreeWithCounts = createDebouncedAsyncThunk('shared/fetchSignInventoryTreeWithCounts', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    const res = yield getSignTree({
        timepicker: getTimeParams(selectedOption),
        locale,
        withCounts: true
    });
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const fetchUnitsTk = createAppAsyncThunk('shared/fetchUnitsTk', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const { locale } = getState().app;
    const res = yield getUnits({ language: locale });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchAssetTypes = createAppAsyncThunk('shared/fetchAssetTypes', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { locale } = getState().app;
    const res = yield CancelableRequest.send(getAssetTypes, { language: locale });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const updateTreatmentsSettings = createAppAsyncThunk('leftPanelPlan/plan/updateTreatmentsSettings', ({ autoUpdateAction, items, silentUpdate }, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { locale: language } = state.app;
    const { teamAccount } = state.auth;
    // TODO: loading state?
    const res = yield saveTreatmentsSettings({
        items,
        language,
        autoUpdateAction,
        countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode
    });
    if (res.errorCode === RESPONSE_ERROR_CODE.success && !silentUpdate) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchPlanFilters());
    return res;
}));
export const deleteTreatmentSetting = createAppAsyncThunk('leftPanelPlan/plan/deleteTreatmentSetting', ({ id, silentUpdate }, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield removeTreatmentSettings({ id });
    if (res.errorCode === RESPONSE_ERROR_CODE.success && !silentUpdate) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchPlanTreatmentsList());
    dispatch(fetchPlanFilters());
    return Object.assign(Object.assign({}, res), { item: { id } });
}));
export const fetchUserProfiles = createAppAsyncThunk('shared/fetchUserProfiles', (uuids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getUsersProfile({ uuids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        reset: () => initialState,
        setSignTreeGroupsVisible: (state) => {
            state.signTree = state.signTree.map((group) => (Object.assign(Object.assign({}, group), { visible: true })));
        },
        setSignTreeGroupItemsVisible: (state, action) => {
            state.signTree = state.signTree.map((group) => (Object.assign(Object.assign({}, group), { items: group.id === action.payload
                    ? group.items.map((item) => (Object.assign(Object.assign({}, item), { visible: true })))
                    : group.items })));
        },
        setSignTreeItemVisible: (state, action) => {
            // update state only if group or item are not visible
            const group = state.signTree.find((g) => g.id === action.payload.groupId);
            if (!group) {
                return;
            }
            const item = group.items.find((i) => i.id === action.payload.itemId);
            if (!item) {
                return;
            }
            if (!group.visible || !item.visible) {
                state.signTree = state.signTree.map((group) => (Object.assign(Object.assign({}, group), { visible: group.id === action.payload.groupId ? true : group.visible, items: group.items.map((item) => (Object.assign(Object.assign({}, item), { visible: item.id === action.payload.itemId ? true : item.visible }))) })));
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMarkerFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                const filters = action.payload.result;
                state.markerFilters = filters;
                state.markerCategoryList = filters.categories
                    .filter((i) => i.id !== NOT_SPECIFIED_CATEGORY_ID)
                    .map((i) => ({
                    categoryId: i.id,
                    categoryName: i.name,
                    color: i.color
                }));
                state.markerCategoryMap = Object.fromEntries(filters.categories.map((t) => [t.id, t]));
                state.markerPriorityList = filters.priorities.map((i) => ({
                    priorityId: i.id,
                    priority: MARKER_PRIORITY_ID[i.id]
                }));
                state.markerStatusList = filters.statuses.map((i) => ({
                    statusId: i.id,
                    statusName: MARKER_STATUS_ID[i.id]
                }));
            }
        })
            .addCase(fetchMarkerFolderTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.markerFolders = action.payload.result.folders;
            }
        })
            .addCase(fetchMarkerAttributeSchemaTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                const attributeSchema = action.payload.result.attributeSchema;
                state.taskAttributeMap = Object.fromEntries(attributeSchema.map((t) => [t.id, t]));
                state.taskCostAttributeSchema = attributeSchema.filter((v) => v.sectionId === MARKER_SECTION_ID.costs);
                // filter and add more sections here
            }
        })
            .addCase(fetchAssetFolderTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.assetFolders = action.payload.result.folders;
            }
        })
            .addCase(fetchTrackControlAgeFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.trackControlAgeList = action.payload.result.filters;
            }
        })
            .addCase(fetchWinterServiceFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.winterServiceModeList = action.payload.result.inspectionModes;
            }
        })
            .addCase(fetchWinterServiceAgeFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.winterServiceAgeList = action.payload.result.filters;
            }
        })
            .addCase(fetchUserAccountList.fulfilled, (state, action) => {
            if (action.payload.result) {
                const assigneeList = action.payload.result.users
                    .filter((i) => i.firstName || i.lastName || i.email || i.phoneNumber)
                    .map((i) => ({
                    id: i.uuid,
                    firstName: i.firstName || '',
                    lastName: i.lastName || '',
                    email: i.email,
                    phoneNumber: i.phoneNumber,
                    profileURL: i.profileImageUrl || undefined
                }));
                state.taskAssigneeList = assigneeList;
                state.taskAssigneeMap = Object.fromEntries(assigneeList.map((t) => [t.id, t]));
                state.users = action.payload.result.users;
            }
        })
            .addCase(fetchUserProfiles.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.userMap = Object.fromEntries(action.payload.result.users.map((t) => [t.uuid, t]));
            }
        })
            .addCase(fetchPlanFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.planFilters = action.payload.result;
            }
        })
            .addCase(fetchPlanStatus.fulfilled, (state, action) => {
            var _a;
            const order = [
                PLAN_STATUS.inPlanning,
                PLAN_STATUS.contracted,
                PLAN_STATUS.completed
            ];
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.types) {
                state.planStatusList = action.payload.result.types.sort((a, b) => {
                    return order.indexOf(a.id) - order.indexOf(b.id);
                });
            }
        })
            .addCase(fetchPlanCategory.fulfilled, (state, action) => {
            var _a;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.types) {
                state.planCategoryList = action.payload.result.types;
            }
        })
            .addCase(fetchPlanTreatmentsList.fulfilled, (state, action) => {
            var _a, _b;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.treatments) {
                state.planTreatmentsList = action.payload.result.treatments.sort((a, b) => a.orderNumber - b.orderNumber);
            }
            if ((_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.unitTypes) {
                state.planTreatmentUnitTypes = action.payload.result.unitTypes;
            }
        })
            .addCase(updateTreatmentsSettings.fulfilled, (state, action) => {
            var _a;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.treatments) {
                state.planTreatmentsList = action.payload.result.treatments.sort((a, b) => a.orderNumber - b.orderNumber);
            }
        })
            .addCase(fetchPlanPropertyTypes.fulfilled, (state, action) => {
            var _a;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.types) {
                state.planPropertyTypes = action.payload.result.types;
            }
        })
            .addCase(fetchPlanUnitTypes.fulfilled, (state, action) => {
            var _a;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.types) {
                state.planUnitTypes = action.payload.result.types;
            }
        })
            .addCase(fetchRoadPropsDict.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.roadPropsDict = action.payload.result;
            }
        })
            .addCase(fetchGradePercentages.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.gradePercentages = action.payload.result.gradePercentage;
            }
        })
            .addCase(fetchConditionFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                const { min, max } = action.payload.result.filters.segmentDetails;
                if (min != undefined && max) {
                    state.conditionFilters.segmentLength = {
                        min: Math.floor(min),
                        max: Math.ceil(max)
                    };
                }
            }
        })
            .addCase(fetchFilterTypes.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.conditionFilters.types = action.payload.result;
            }
        })
            .addCase(fetchAlertTypes.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.alertTypes = action.payload.result.list;
            }
        })
            .addCase(fetchObjectTypes.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.objectTypes = action.payload.result.list;
            }
        })
            .addCase(fetchSignDamageTypes.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signDamageTypes = action.payload.result.types;
            }
        })
            .addCase(fetchSignDamageTypesWithCounts.pending, (state) => {
            state.isSignDamageTypesLoading = true;
        })
            .addCase(fetchSignDamageTypesWithCounts.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signDamageTypes = action.payload.result.types;
            }
            state.isSignDamageTypesLoading = false;
        })
            .addCase(fetchSignInventoryDamageTypes.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signInventoryDamageTypes = action.payload.result.types;
            }
        })
            .addCase(fetchSignInventoryDamageTypesWithCounts.pending, (state) => {
            state.isSignDamageTypesLoading = true;
        })
            .addCase(fetchSignInventoryDamageTypesWithCounts.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signInventoryDamageTypes = action.payload.result.types;
            }
            state.isSignDamageTypesLoading = false;
        })
            .addCase(fetchSignInventoryStatuses.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signStatuses = action.payload.result.statuses;
            }
        })
            .addCase(fetchSignInventoryPropertySettings.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.signProperties = action.payload.result.properties;
            }
        })
            .addCase(fetchSignInventoryTree.fulfilled, (state, action) => {
            var _a, _b;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.groups) {
                state.signTree = (_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.groups.map((group) => {
                    return Object.assign(Object.assign({}, group), { items: group.items.map((item) => {
                            return Object.assign(Object.assign({}, item), { visible: true });
                        }) });
                });
            }
        })
            .addCase(fetchSignInventoryTreeWithCounts.pending, (state) => {
            state.isSignTreeLoading = true;
        })
            .addCase(fetchSignInventoryTreeWithCounts.fulfilled, (state, action) => {
            var _a, _b;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.groups) {
                state.signTree = (_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.groups.map((group) => {
                    const items = group.items.map((item) => {
                        return Object.assign(Object.assign({}, item), { visible: item.count > 0 });
                    });
                    const isGroupVisible = items.some((item) => item.visible);
                    return Object.assign(Object.assign({}, group), { visible: isGroupVisible, items });
                });
            }
            state.isSignTreeLoading = false;
        })
            .addCase(fetchAssetTypes.fulfilled, (state, action) => {
            if (action.payload.result) {
                const inventoryTypes = action.payload.result.inventoryTypes;
                state.assetTypeList = inventoryTypes;
                state.assetTypeMap = Object.fromEntries(inventoryTypes.map((t) => [t.id, t]));
            }
        })
            .addCase(fetchUnitsTk.fulfilled, (state, action) => {
            if (action.payload.result) {
                const unitList = action.payload.result.units;
                state.unitList = unitList;
                state.unitMap = Object.fromEntries(unitList.map((t) => [t.id, t]));
            }
        });
    }
});
export const { reset: resetShared, setSignTreeGroupsVisible, setSignTreeGroupItemsVisible, setSignTreeItemVisible } = sharedSlice.actions;
export default sharedSlice.reducer;
