var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { PLAN_PROPERTY_ID } from '../../common/consts/plan';
import { isDeepEqualByStringify } from '../../common/utils/object';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { deleteTableViewProperty, deleteTableViewSelectOption, fetchTableViewSettings, updateTableViewSettings } from '../../state/slices/leftPanelPlan';
import { fetchPlanPropertyTypes, fetchPlanUnitTypes } from '../../state/slices/shared';
import { useDittoWrapper } from '../useDittoWrapper';
export const usePlanPropertiesSettings = () => {
    const dispatch = useAppDispatch();
    const { table: { settings: tableSettings } } = useAppSelector((state) => state.leftPanelPlan);
    const { planPropertyTypes, planUnitTypes } = useAppSelector((state) => state.shared);
    const { addModal } = useModalContext();
    const [planPropertySettingList, setPlanPropertySettingList] = useState(null);
    const textFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.text'
    });
    const numericFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.numeric'
    });
    const listFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.select'
    });
    const dateFieldDefaultTitle = useDittoWrapper({
        componentId: 'customproperties.placeholder.date'
    });
    useEffect(() => {
        dispatch(fetchPlanPropertyTypes());
        dispatch(fetchPlanUnitTypes());
        if (!tableSettings) {
            dispatch(fetchTableViewSettings());
        }
        else {
            setPlanPropertySettingList(tableSettings.filter((item) => !item.isDefault));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableSettings]);
    function getCustomPropertyDefaultTitle(propertyTypeId, index) {
        let title = '';
        switch (propertyTypeId) {
            case PLAN_PROPERTY_ID.text:
                title = textFieldDefaultTitle;
                break;
            case PLAN_PROPERTY_ID.numeric:
                title = numericFieldDefaultTitle;
                break;
            case PLAN_PROPERTY_ID.list:
                title = listFieldDefaultTitle;
                break;
            case PLAN_PROPERTY_ID.date:
                title = dateFieldDefaultTitle;
                break;
            default:
                title = '';
                break;
        }
        return title.replace('1', index.toString());
    }
    function handlePlanPropertySaveClick(items, callback) {
        const itemsToSave = items.map((item, index) => {
            var _a;
            return (Object.assign(Object.assign({}, item), { title: item.title || getCustomPropertyDefaultTitle(item.propertyTypeId, index + 1), selectOptions: (_a = item.selectOptions) === null || _a === void 0 ? void 0 : _a.map((option, optionIndex) => (Object.assign(Object.assign({}, option), { title: option.title || `Option ${optionIndex + 1}` }))) }));
        });
        dispatch(updateTableViewSettings({ items: itemsToSave }));
        callback();
    }
    function handlePlanPropertyCancelClick(list, callback) {
        if (!tableSettings)
            return;
        const oldPropertySettingsList = tableSettings.filter((item) => !item.isDefault);
        const isPlanPropertySettingsChanged = !isDeepEqualByStringify(oldPropertySettingsList, list);
        if (list && isPlanPropertySettingsChanged) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handlePlanPropertySaveClick(list, callback);
                    },
                    onCancel: () => {
                        setPlanPropertySettingList(oldPropertySettingsList);
                        callback();
                    },
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }
    function handlePlanPropertyDeleteClick(id, confirmCallback) {
        if (!tableSettings)
            return;
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    yield dispatch(deleteTableViewProperty({ id }));
                    confirmCallback && confirmCallback();
                }),
                title: _jsx(Ditto, { componentId: "prompt.delete.property" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.property.info" })
            }
        });
        return;
    }
    function handlePlanPropertySelectOptionDeleteClick(columnKey, id, confirmCallback) {
        if (!tableSettings)
            return;
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    yield dispatch(deleteTableViewSelectOption({ columnKey, id }));
                    confirmCallback && confirmCallback();
                }),
                title: _jsx(Ditto, { componentId: "prompt.delete.option" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.option.text" })
            }
        });
        return;
    }
    return {
        planPropertyTypes,
        planUnitTypes,
        planPropertySettingList,
        getCustomPropertyDefaultTitle,
        setPlanPropertySettingList,
        handlePlanPropertySaveClick,
        handlePlanPropertyCancelClick,
        handlePlanPropertyDeleteClick,
        handlePlanPropertySelectOptionDeleteClick
    };
};
