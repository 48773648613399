// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xHuL5nCVFuLNeExb6qEv{min-height:48px;width:100%}.FBwbUX76Taite1JUI6GK{margin-bottom:16px;width:100%}.y4zqz0g3OhHe1NZsIJRI{display:flex;flex-direction:column;align-items:stretch;align-self:stretch}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/AssetTypesSettingPanel/AssetTypesSettingPanel.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,UAAA,CAGJ,sBACI,kBAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,kBAAA","sourcesContent":[".content-bottom-anchor {\n    min-height: 48px;\n    width: 100%;\n}\n\n.info-box {\n    margin-bottom: 16px;\n    width: 100%;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    align-self: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-bottom-anchor": `xHuL5nCVFuLNeExb6qEv`,
	"info-box": `FBwbUX76Taite1JUI6GK`,
	"content": `y4zqz0g3OhHe1NZsIJRI`
};
export default ___CSS_LOADER_EXPORT___;
