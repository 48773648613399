import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Loader } from '../../Loader/Loader';
import { UserDataForm } from '../UserDataForm/UserDataForm';
import styles from './MissingUserDetail.module.css';
export function MissingUserDetail({ currentLocale, departmentList, values, loading, onChange, onClickUpload, onLaterClick, onSubmitClick }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container']), "data-test": "register-email-modal" }, { children: [_jsx("div", Object.assign({ className: styles['title-group'] }, { children: _jsx("span", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "register.moredata" }) })) })), _jsx("p", Object.assign({ className: styles['subtitle'] }, { children: _jsx(Ditto, { componentId: "register.moredata.info" }) })), loading ? (_jsx("div", Object.assign({ className: styles['loader'] }, { children: _jsx(Loader, {}) }))) : (_jsx("div", Object.assign({ className: styles['content'] }, { children: _jsx(UserDataForm, { currentLocale: currentLocale, departmentList: departmentList, values: values, onChange: onChange, onClickUpload: onClickUpload }) }))), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: onLaterClick, "data-test": "logout-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "register.later" }) })) })), _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", disabled: loading ||
                            !values.firstName ||
                            !values.lastName ||
                            !values.titleId ||
                            !values.departmentId, onClick: onSubmitClick, "data-test": "save-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }))] }))] })));
}
