import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Button } from '../../../uikit/Button/Button';
import { FormInputText } from '../../FormInputText/FormInputText';
import styles from './RegisterEmail.module.css';
export function RegisterEmail({ email = '', loading, error, onRegisterClick, onLogoutClick, onChange }) {
    const emailLabel = useDittoWrapper({ componentId: 'register.e-mail' });
    return (_jsxs("div", Object.assign({ className: classes(styles['container']), "data-test": "register-email-modal" }, { children: [_jsx("div", Object.assign({ className: styles['title-group'] }, { children: _jsx("span", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "register.register" }) })) })), _jsx("div", Object.assign({ className: styles['content'] }, { children: _jsx("p", { children: _jsx(Ditto, { componentId: "register.info" }) }) })), _jsx("div", Object.assign({ className: styles['input-group'] }, { children: _jsx(FormInputText, { id: "email", label: emailLabel, value: email, error: error, onValueChange: (value) => onChange && onChange(value), width: "100%", "data-test": "email-input" }) })), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: onLogoutClick, "data-test": "logout-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "layerpanel.accountmodal.logout" }) })) })), _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", disabled: loading, onClick: onRegisterClick, "data-test": "register-button" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "register.register" }) })) }))] }))] })));
}
