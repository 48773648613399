import { useEffect, useRef, useState } from 'react';
/**
 * Useful when you need a delay after component mount.
 * @param mountingDelay Delay in milliseconds, default is 500.
 * @returns Mutable reference with the .current value indicating whether
 * the component is still mounting (+ delay) or not.
 */
export const useMountDelay = (mountingDelay = 500) => {
    const mounting = useRef(true);
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        let delay = 0;
        if (mounting.current) {
            delay = mountingDelay;
        }
        const timeout = setTimeout(() => {
            mounting.current = false;
            setMounted(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [mountingDelay]);
    return { mounting, mounted };
};
