import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { DELAY_DISABLE_ROTATE } from '../common/consts/time';
import { TrafficSignRotator } from '../components/TrafficSignRotator';
import config from '../config/config.json';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { clearRotatableFeature, setLastRotation, setRotatableFeature } from '../state/slices/mainMap';
import { useTilesUpdator } from './mainMap/useTilesUpdator';
export function useRotatableFeature() {
    const dispatch = useAppDispatch();
    const { rotatableFeature } = useAppSelector((state) => state.mainMap);
    const { reloadSignInventoryTilesData, reloadAssetTilesData } = useTilesUpdator();
    function enableRotate(value) {
        dispatch(setRotatableFeature(value));
    }
    function disableRotate() {
        dispatch(clearRotatableFeature());
    }
    function disableRotateWithTimeout() {
        setTimeout(() => disableRotate(), DELAY_DISABLE_ROTATE);
    }
    function updateOrientation(orientation) {
        dispatch(setLastRotation(orientation));
    }
    function updateCreatorOrientation(orientation) {
        if ((rotatableFeature === null || rotatableFeature === void 0 ? void 0 : rotatableFeature.type) !== 'sign-inventory-creator') {
            return;
        }
        dispatch(setRotatableFeature({
            type: 'sign-inventory-creator',
            value: {
                offsetPosition: rotatableFeature.value.offsetPosition,
                orientation
            }
        }));
    }
    const marker = useMemo(() => {
        if (!rotatableFeature || !config.enableOrientation) {
            return null;
        }
        // NOTE: extend this switch statement with other draggableFeature types
        switch (rotatableFeature.type) {
            case 'sign-inventory':
                return (_jsx(Marker, Object.assign({ longitude: rotatableFeature.value.offsetPosition[0], latitude: rotatableFeature.value.offsetPosition[1], anchor: "center" }, { children: _jsx(TrafficSignRotator, { isRotatable: true, orientation: rotatableFeature.value.orientation, onOrientationChange: (newOrientation) => updateOrientation(Math.round(newOrientation)), variant: "edit" }) })));
            case 'sign-inventory-creator':
                return (_jsx(Marker, Object.assign({ longitude: rotatableFeature.value.offsetPosition[0], latitude: rotatableFeature.value.offsetPosition[1], anchor: "center" }, { children: _jsx(TrafficSignRotator, { isRotatable: true, orientation: rotatableFeature.value.orientation, onOrientationChange: (newOrientation) => updateCreatorOrientation(newOrientation), variant: "create" }) })));
            default:
                return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rotatableFeature, dispatch, reloadSignInventoryTilesData, reloadAssetTilesData]);
    return {
        enableRotate,
        disableRotate,
        disableRotateWithTimeout,
        marker
    };
}
