import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
export function Loading({ type }) {
    switch (type) {
        case 'segment':
            return (_jsxs("svg", Object.assign({ width: "344", height: "97", viewBox: "0 0 344 97", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "344", height: "59", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "67", width: "106", height: "30", rx: "4", fill: "#F1F2F4" })] })));
        case 'point':
            return (_jsxs("svg", Object.assign({ width: "344", height: "97", viewBox: "0 0 344 97", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "196", height: "59", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "67", width: "40", height: "30", rx: "4", fill: "#F1F2F4" }), _jsx("path", { d: "M204 4C204 1.79086 205.791 0 208 0H340C342.209 0 344 1.79086 344 4V92C344 94.2091 342.209 96 340 96H208C205.791 96 204 94.2091 204 92V4Z", fill: "#F1F2F4" })] })));
        case 'marker':
            return (_jsxs("svg", Object.assign({ width: "344", height: "133", viewBox: "0 0 344 133", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "196", height: "97", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { width: "196", height: "59", rx: "4", fill: "#F1F2F4" }), _jsx("path", { d: "M204 4C204 1.79086 205.791 0 208 0H340C342.209 0 344 1.79086 344 4V92C344 94.2091 342.209 96 340 96H208C205.791 96 204 94.2091 204 92V4Z", fill: "#F1F2F4" }), _jsx("rect", { y: "109", width: "226", height: "24", rx: "4", fill: "#F1F2F4" })] })));
        case 'object':
            return (_jsxs("svg", Object.assign({ width: "344", height: "97", viewBox: "0 0 344 97", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "196", height: "59", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "67", width: "196", height: "30", rx: "4", fill: "#F1F2F4" }), _jsx("path", { d: "M204 4C204 1.79086 205.791 0 208 0H340C342.209 0 344 1.79086 344 4V92C344 94.2091 342.209 96 340 96H208C205.791 96 204 94.2091 204 92V4Z", fill: "#F1F2F4" })] })));
        case 'track-control':
        case 'winter-service':
            return (_jsxs("svg", Object.assign({ width: "344", height: "97", viewBox: "0 0 344 97", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "344", height: "59", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "71", width: "245", height: "26", rx: "4", fill: "#F1F2F4" })] })));
        case 'planned-segment':
            return (_jsxs("svg", Object.assign({ width: "344", height: "136", viewBox: "0 0 344 136", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "344", height: "74", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { width: "344", height: "28", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "36", width: "344", height: "38", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "82", width: "344", height: "18", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "110", width: "61", height: "24", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { x: "209", y: "108", width: "135", height: "28", rx: "4", fill: "#F1F2F4" })] })));
        case 'rs-alert':
        case 'asset':
        case 'sign-inventory':
        case 'sign':
            return (_jsxs("svg", Object.assign({ width: "344", height: "97", viewBox: "0 0 344 97", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "196", height: "59", rx: "4", fill: "#F1F2F4" }), _jsx("rect", { y: "67", width: "40", height: "30", rx: "4", fill: "#F1F2F4" }), _jsx("path", { d: "M204 4C204 1.79086 205.791 0 208 0H340C342.209 0 344 1.79086 344 4V92C344 94.2091 342.209 96 340 96H208C205.791 96 204 94.2091 204 92V4Z", fill: "#F1F2F4" })] })));
        default:
            return _jsx(_Fragment, {});
    }
}
