// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tuiuwnPuaAMrK8E2dscw{cursor:pointer;display:flex;align-items:center;justify-content:space-between}.w0cWv74g6aOKPsMyf4Zz{flex-grow:1;padding:12px;font-weight:600;font-size:14px;line-height:16px;color:var(--primary-dark-blue)}.bZAjQDH7JjuG5nHOucKX{margin-left:auto;opacity:.4}`, "",{"version":3,"sources":["webpack://./src/components/FilterGroupSection/FilterGroupSection.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,sBACI,WAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,8BAAA,CAGJ,sBACI,gBAAA,CACA,UAAA","sourcesContent":[".toggle {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.headline {\n    flex-grow: 1;\n    padding: 12px;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    color: var(--primary-dark-blue);\n}\n\n.trailing {\n    margin-left: auto;\n    opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `tuiuwnPuaAMrK8E2dscw`,
	"headline": `w0cWv74g6aOKPsMyf4Zz`,
	"trailing": `bZAjQDH7JjuG5nHOucKX`
};
export default ___CSS_LOADER_EXPORT___;
