import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../FormInputSelect/FormInputSelect';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { TypedDitto } from '../../TypedDitto';
import { SelectedDays } from './SelectedDays';
import styles from './RepeatForm.module.css';
const DAYS_IN_MONTH = 31;
const MAX_MENU_HEIGHT = 204;
export function MonthlyRepeatForm({ frequency, onChange, locale }) {
    const menuItems = [];
    for (let index = 1; index <= DAYS_IN_MONTH; index++) {
        menuItems.push(_jsx("div", Object.assign({ className: styles['repeat-option'] }, { children: _jsx(SelectboxEntry, { withCheckbox: true, isChecked: frequency.days.includes(index), text: index, onClick: () => {
                    const exists = frequency.days.find((value) => value === index);
                    if (exists === undefined) {
                        onChange({
                            repeat: 'monthly',
                            days: [...frequency.days, index].sort((a, b) => a - b)
                        });
                        return;
                    }
                    onChange({
                        repeat: 'monthly',
                        days: frequency.days.filter((value) => value !== index)
                    });
                } }) }), index));
    }
    return (_jsx("div", Object.assign({ className: styles['input-select'] }, { children: _jsx(FormInputSelect, { menuMaxHeight: MAX_MENU_HEIGHT, label: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.onthe" }), value: _jsx(FormInputCurrentSelectedValue, { text: _jsx(SelectedDays, { days: frequency.days, locale: locale }) }), menuItems: menuItems, canFloat: true }) })));
}
