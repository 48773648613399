// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snogeY6noI7Q0UwbG8UY{margin:-8px 0 8px}.Ume24yyDAE7Mq8_GdjrX{height:476px}.AClErchaYmHl48D1RYlw{width:100%;margin-top:-40px}.RevfCykIkQud85y_fX5w{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-bottom:24px;margin-top:0}.moTKUJNDuGRMaJDna2aP{width:100%;display:flex;align-items:center;justify-content:space-between;gap:12px}.Aiw3kd4UtJLc6CZ4AOr6{background:none;border:none;text-decoration:underline;font-weight:600;cursor:pointer}.CRGb_TLWz8Z4mV1GwItZ{width:120px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/TreatmentSelectModal/TreatmentSelectModal.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,UAAA,CACA,gBAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAGJ,sBACI,eAAA,CACA,WAAA,CACA,yBAAA,CACA,eAAA,CACA,cAAA,CAGJ,sBACI,WAAA","sourcesContent":[".info-box {\n    margin: -8px 0 8px;\n}\n\n.treatment-select {\n    height: 476px;\n}\n\n.footer {\n    width: 100%;\n    margin-top: -40px;\n}\n\n.footer-divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-bottom: 24px;\n    margin-top: 0;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 12px;\n}\n\n.button-edit-treatments {\n    background: none;\n    border: none;\n    text-decoration: underline;\n    font-weight: 600;\n    cursor: pointer;\n}\n\n.button-cancel {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-box": `snogeY6noI7Q0UwbG8UY`,
	"treatment-select": `Ume24yyDAE7Mq8_GdjrX`,
	"footer": `AClErchaYmHl48D1RYlw`,
	"footer-divider": `RevfCykIkQud85y_fX5w`,
	"buttons": `moTKUJNDuGRMaJDna2aP`,
	"button-edit-treatments": `Aiw3kd4UtJLc6CZ4AOr6`,
	"button-cancel": `CRGb_TLWz8Z4mV1GwItZ`
};
export default ___CSS_LOADER_EXPORT___;
