import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { Ditto } from 'ditto-react';
import { MAP_POPOVER_ANCHOR } from '../../common/consts/map';
import { MARKER_ICON_HORIZONTAL_OFFSET, MARKER_NOTE_ICON_VERTICAL_OFFSET, NOTE_COLORS } from '../../common/consts/note';
import { getNoteIcon } from '../../common/convert/note';
import { NoteCreator } from '../../components/NoteCreator/NoteCreator';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { usePositioningMarker } from '../../hooks/mainMap/usePositioningMarker';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHoveredFeature, setClickedFeature } from '../../state/slices/feature';
import { deleteNote, saveNoteDraft } from '../../state/slices/note';
export function NoteDetailContainer({ direction, variant = 'hover' }) {
    const { addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { userAccount } = useAppSelector((state) => state.auth);
    const { hoveredFeature, clickedFeature } = useAppSelector((state) => state.feature);
    const dateTimeFormatter = useDateTimeFormatter();
    const { isEditor } = useUserRole();
    const feature = variant === 'click' ? clickedFeature : hoveredFeature;
    const [noteFormData, setNoteFormData] = useState({
        text: '',
        color: NOTE_COLORS[0]
    });
    const [isDragging, setIsDragging] = useState(false);
    const note = useMemo(() => {
        if (!feature ||
            !feature.properties ||
            !feature.location ||
            feature.feature.type !== 'note') {
            return;
        }
        setNoteFormData({ text: feature.properties.text, color: feature.properties.color });
        return {
            id: feature.feature.id,
            text: feature.properties.text,
            color: feature.properties.color,
            date: dateTimeFormatter(feature.properties.date),
            location: feature.location,
            isEditable: !isEditor
                ? (userAccount === null || userAccount === void 0 ? void 0 : userAccount.uuid) === feature.properties.createdBy ||
                    (userAccount === null || userAccount === void 0 ? void 0 : userAccount.email) === feature.properties.createdBy
                : true
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTimeFormatter, feature]);
    function handleNoteDragEnd(e) {
        if (!note) {
            return;
        }
        const mappedColor = NOTE_COLORS.findIndex((c) => c === noteFormData.color);
        dispatch(saveNoteDraft({
            id: note.id,
            geo: e.lngLat,
            color: mappedColor,
            text: note.text
        }));
    }
    function handleNoteDrag() {
        setIsDragging(true);
    }
    const { positioningMarker } = usePositioningMarker({
        initialPosition: note === null || note === void 0 ? void 0 : note.location,
        onDragEnd: handleNoteDragEnd,
        onDrag: handleNoteDrag,
        icon: getNoteIcon(note === null || note === void 0 ? void 0 : note.color),
        offset: [MARKER_ICON_HORIZONTAL_OFFSET, MARKER_NOTE_ICON_VERTICAL_OFFSET],
        anchor: MAP_POPOVER_ANCHOR.note
    });
    function handleSaveClick(noteFormData) {
        if (!note) {
            return;
        }
        const mappedColor = NOTE_COLORS.findIndex((c) => c === noteFormData.color);
        if (!noteFormData.text || !noteFormData.color || mappedColor === -1) {
            console.debug('NoteDetailContainer: basic validation failed for data', noteFormData);
            return;
        }
        dispatch(saveNoteDraft({
            id: note.id,
            color: mappedColor,
            text: noteFormData.text,
            geo: note.location
        }));
    }
    function handleRemoveClick() {
        if (!note) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteNote(note.id));
                },
                title: _jsx(Ditto, { componentId: "note.delete" }),
                description: _jsx(Ditto, { componentId: "note.delete.info" })
            }
        });
    }
    function handleSetEdit() {
        if (!hoveredFeature) {
            return;
        }
        dispatch(setClickedFeature(hoveredFeature));
        dispatch(setClickedFeature(hoveredFeature));
        dispatch(clearHoveredFeature());
    }
    function handleChangeFormData(data) {
        setNoteFormData(Object.assign(Object.assign({}, noteFormData), data));
    }
    if (!note) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [variant === 'click' && positioningMarker, !isDragging && (_jsx(NoteCreator, { direction: direction, text: noteFormData.text, color: noteFormData.color, date: note.date, isEdit: note.isEditable ? variant === 'click' : undefined, onChangeFormData: handleChangeFormData, onSetEdit: handleSetEdit, onSaveClick: handleSaveClick, onRemoveClick: handleRemoveClick }))] }));
}
