var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { deleteInvitation, getInvitations, resendInvitation, sendInvitation, setRoleId } from '../../services/coWorkerManagement';
import { addNotificationMessage } from './notification';
import { fetchUserAccountList } from './shared';
const initialState = {
    invitations: [],
    fetchInvitationsStatus: 'idle',
    isSubmitting: false
};
export const fetchInvitations = createAppAsyncThunk('coWorkerManagement/fetchInvitations', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getInvitations();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const setUserRole = createAppAsyncThunk('auth/setUserRole', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield setRoleId(data);
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        const error = `${result.errorCode} ${result.errorMessage}`;
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error(error);
    }
    if (result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchUserAccountList());
    }
    return data;
}));
export const inviteUser = createAppAsyncThunk('coWorkerManagement/inviteUser', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield sendInvitation(data);
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        const error = `${result.errorCode} ${result.errorMessage}`;
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error(error);
    }
    if (result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.invitesent' }
            }
        }));
        dispatch(fetchInvitations());
    }
    return result;
}));
export const resendInvite = createAppAsyncThunk('coWorkerManagement/resendInvite', (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { targetSchema } = getState().auth;
    if (!targetSchema) {
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error();
    }
    const result = yield resendInvitation(Object.assign(Object.assign({}, payload), { accountUsername: targetSchema }));
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        const error = `${result.errorCode} ${result.errorMessage}`;
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error(error);
    }
    if (result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.invitesent' }
            }
        }));
    }
    return result;
}));
export const deleteInvite = createAppAsyncThunk('coWorkerManagement/deleteInvite', (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { targetSchema } = getState().auth;
    if (!targetSchema) {
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error();
    }
    const result = yield deleteInvitation(Object.assign(Object.assign({}, payload), { accountUsername: targetSchema }));
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        const error = `${result.errorCode} ${result.errorMessage}`;
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error(error);
    }
    if (result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.invitedeleted' }
            }
        }));
        dispatch(fetchInvitations());
    }
    return result;
}));
export const coWorkerManagmenetSlice = createSlice({
    name: 'segmentForm',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        // Individual handlers
        builder
            .addCase(fetchInvitations.pending, (state) => {
            state.fetchInvitationsStatus = 'loading';
        })
            .addCase(fetchInvitations.rejected, (state) => {
            state.fetchInvitationsStatus = 'failed';
        })
            .addCase(fetchInvitations.fulfilled, (state, action) => {
            var _a;
            state.fetchInvitationsStatus = 'succeeded';
            if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.result) {
                state.invitations = action.payload.result.invites || [];
            }
        })
            .addCase(inviteUser.pending, (state) => {
            state.isSubmitting = true;
        })
            .addMatcher(isAnyOf(inviteUser.rejected, inviteUser.fulfilled), (state) => {
            state.isSubmitting = false;
        });
    }
});
export const { reset: resetCoWorkerManagement } = coWorkerManagmenetSlice.actions;
export default coWorkerManagmenetSlice.reducer;
