var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { TIME_PICKER_RANGE_FORMAT } from '../common/consts/timepicker';
import { get } from '../common/fetch';
export const TIME_PICKER_ENDPOINT = 'v1/time-picker';
export const TIME_PICKER_CONDITION_OPTIONS_COMPLETE = `${TIME_PICKER_ENDPOINT}/condition/options/complete`;
export const TIME_PICKER_RS_STATS_MARKERS = `${TIME_PICKER_ENDPOINT}/road-safety/stats/markers`;
export const TIME_PICKER_CONDITION_STATS_GRADE = `${TIME_PICKER_ENDPOINT}/condition/stats/grade`;
export const TIME_PICKER_CONDITION_STATS_DISTANCE = `${TIME_PICKER_ENDPOINT}/condition/stats/distance`;
export function getTimePickerParams(selectedOption) {
    const format = 'YYYY-MM-DDTHH:mm:ss[Z]';
    return {
        from: moment(selectedOption.from, TIME_PICKER_RANGE_FORMAT).format(format),
        to: moment(selectedOption.to, TIME_PICKER_RANGE_FORMAT).format(format)
    };
}
export function getTimePickerOptions() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(TIME_PICKER_CONDITION_OPTIONS_COMPLETE);
    });
}
export function getMarkers(range) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(TIME_PICKER_RS_STATS_MARKERS, range);
    });
}
export function getGrade(range) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(TIME_PICKER_CONDITION_STATS_GRADE, range);
    });
}
export function getDistance(range) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(TIME_PICKER_CONDITION_STATS_DISTANCE, range);
    });
}
