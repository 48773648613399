var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { accountsAppClient } from '../openapi';
export function countUnreadNotifications() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceNotificationCountUnread({});
    });
}
export function getUserNotifications() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceNotificationListForUser({});
    });
}
export function getUsersProfile(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceUsersListProfile(body);
    });
}
export function batchMarkAsRead(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceNotificationBatchUpdateMarkedAsRead(body);
    });
}
export function markAllAsRead() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceNotificationMarkAllAsReadForUser({});
    });
}
export function saveNotificationSettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield accountsAppClient.accountsApiService.accountsApiServiceUserUpdateNotificationSettings(body);
    });
}
