import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { TreatmentIcon } from '../../../common/icon';
import { GradeBadge } from '../../GradeBadge/GradeBadge';
import { IconResizer } from '../../IconResizer/IconResizer';
import { TextOverflow } from '../../TextOverflow/TextOverflow';
import { Vignette } from '../../Vignette/Vignette';
import styles from './PlannedSegment.module.css';
export function PlannedSegment({ content, checkbox }) {
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-plan" }, { children: [_jsxs("div", Object.assign({ className: styles['title'] }, { children: [checkbox && checkbox, content.priority && content.priority.icon, _jsx(TextOverflow, { className: styles['title-text'], maxLines: 2, text: content.title, tooltip: "default" }), _jsx("div", Object.assign({ className: styles['grade'] }, { children: _jsx(GradeBadge, { grade: content.grade, size: "small" }) }))] })), _jsxs("div", Object.assign({ className: styles['subtitle'] }, { children: [_jsxs("div", Object.assign({ className: styles['subtitle-text'], "data-test": "subtitle-text" }, { children: [content.area && `${content.area.formattedText}  · `, content.cost && `${content.cost.formattedText} · `, content.formattedCompletion] })), _jsx("div", Object.assign({ className: styles['subtitle-text'] }, { children: `${content.addressStart} - ${content.addressEnd}` }))] })), _jsx("div", Object.assign({ className: classes(styles['description']) }, { children: content.description })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['tag'], styles['badge']), style: {
                            color: content.status.iconColor,
                            backgroundColor: content.status.backgroundColor
                        } }, { children: [_jsx(IconResizer, Object.assign({ size: 16 }, { children: content.status.icon })), _jsx("span", Object.assign({ "data-test": "status-text" }, { children: content.status.text }))] })), _jsxs("div", Object.assign({ className: styles['tag'] }, { children: [content.treatment && (_jsxs("span", Object.assign({ className: styles['treatment-text'], "data-test": "treatment-text" }, { children: [_jsx("div", Object.assign({ className: styles['treatment-icon'] }, { children: TreatmentIcon(content.treatment.categoryTypeId) })), _jsx(TextOverflow, { maxLines: 1, text: content.treatment.text, tooltip: "default" })] }))), content.category && (_jsx(Vignette, { content: content.category.letter, color: content.category.letterColor, backgroundColor: content.category.backgroundColor, "data-test": "category-letter" }))] }))] }))] })));
}
