import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { MarkerIntervalVignette, MarkerPriorityIcon, MarkerStatusVignette } from '../../common/convert';
import { moveMatchToFront } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { AssigneeName } from '../../components/EditorForms/AssigneeSelect/AssigneeName/AssigneeName';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { Attachments, NoAttachments } from '../../components/icons';
import { NOT_SPECIFIED_ASSIGNEE_ID, NOT_SPECIFIED_CATEGORY_ID } from '../../services/marker';
import { useAppSelector } from '../../state/hooks';
import { Avatar } from '../../uikit/Avatar/Avatar';
import { RadioSelectbox } from '../../uikit/RadioSelectbox/RadioSelectbox';
import { useMarkerFilterHandlers } from '../filters/useMarkerFilterHandlers';
export function useMarkerFilters() {
    var _a, _b, _c;
    const { markerFolders, markerFilters, taskAssigneeList } = useAppSelector((state) => state.shared);
    const { filter } = useAppSelector((state) => state.leftPanelMarker);
    const { userAccount } = useAppSelector((state) => state.auth);
    const { handleFolderFilterClick, handleFolderAttachedFilterClick, handleFolderNotAttachedFilterClick, handleDueDateFilterClick, handleIntervalFilterClick, handleStatusFilterClick, handlePriorityFilterClick, handleCategoryFilterClick, handleAssigneeFilterClick } = useMarkerFilterHandlers();
    const markerFolderFilters = markerFolders.map((folder) => (_jsxs(FilterGroupWrapper, Object.assign({ content: folder.name, checkState: getCheckboxCheckState(2, filter.filesAttached.filter((n) => n === folder.id).length +
            filter.filesNotAttached.filter((n) => n === folder.id).length), onCheckboxClick: () => handleFolderFilterClick(folder.id), hideBottomBar: true }, { children: [_jsx(FilterSelectbox, { onClick: () => handleFolderAttachedFilterClick(folder.id), isChecked: filter.filesAttached.includes(folder.id), icon: _jsx(Attachments, {}), content: _jsx(Ditto, { componentId: "files.attached" }), color: "black", trailingElement: folder.attachedMarkersCount, wrapContent: true }, `${folder.id}-attached`), _jsx(FilterSelectbox, { onClick: () => handleFolderNotAttachedFilterClick(folder.id), isChecked: filter.filesNotAttached.includes(folder.id), icon: _jsx(NoAttachments, {}), content: _jsx(Ditto, { componentId: "files.noattachments" }), color: "black", trailingElement: folder.notAttachedMarkersCount, wrapContent: true }, `${folder.id}-notattached`)] }), folder.id)));
    const markerIntervalFilters = markerFilters.intervals.map((i) => (_jsx(FilterSelectbox, { onClick: () => handleIntervalFilterClick(i.id), isChecked: filter.interval.includes(i.id), content: i.name, icon: _jsx(MarkerIntervalVignette, { value: i.id }), color: "black", trailingElement: i.count }, i.id)));
    const markerDuedateFilters = markerFilters.dueDates.map((i) => (_jsx(RadioSelectbox, { onClick: () => handleDueDateFilterClick(i.id), content: i.name, count: i.count, isChecked: filter.dueDateId === i.id }, i.id)));
    const markerCategoryFilters = (_jsxs(_Fragment, { children: [markerFilters.categories
                .filter((i) => i.id !== NOT_SPECIFIED_CATEGORY_ID)
                .map((item) => (_jsx(FilterSelectbox, { onClick: () => handleCategoryFilterClick(item.id), isChecked: filter.category.includes(item.id), content: _jsx(CategoryBadge, { category: item.name, backgroundColor: item.color }), color: "black", trailingElement: item.count }, item.id))), _jsx(FilterSelectbox, { onClick: () => handleCategoryFilterClick(NOT_SPECIFIED_CATEGORY_ID), isChecked: filter.category.includes(NOT_SPECIFIED_CATEGORY_ID), content: _jsx(Ditto, { componentId: "notspecified" }), color: "black", trailingElement: (_a = markerFilters.categories.find((i) => i.id === NOT_SPECIFIED_CATEGORY_ID)) === null || _a === void 0 ? void 0 : _a.count })] }));
    const markerStatusFilters = markerFilters.statuses.map((item) => (_jsx(FilterSelectbox, { onClick: () => handleStatusFilterClick(item.id), isChecked: filter.statusId.includes(item.id), icon: MarkerStatusVignette({ value: item.id }), content: item.name, color: "black", trailingElement: item.count }, item.id)));
    const markerPriorityFilters = markerFilters.priorities.map((item) => (_jsx(FilterSelectbox, { onClick: () => handlePriorityFilterClick(item.id), isChecked: filter.priorityId.includes(item.id), icon: _jsx(MarkerPriorityIcon, { value: item.id }), content: item.name, color: "black", trailingElement: item.count }, item.id)));
    const markerAssigneeFilters = (_jsxs(_Fragment, { children: [moveMatchToFront(taskAssigneeList, (i) => i.id === (userAccount === null || userAccount === void 0 ? void 0 : userAccount.uuid)).map((i) => {
                var _a, _b;
                return (_jsx(FilterSelectbox, { onClick: () => handleAssigneeFilterClick(i.id), isChecked: filter.assignee.includes(i.id), content: _jsx(AssigneeName, { firstName: i.firstName, lastName: i.lastName, isYou: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.uuid) === i.id, wrapContent: true }), icon: _jsx(Avatar, { firstName: i.firstName, lastName: i.lastName, src: i.profileURL, size: 24 }), color: "black", height: 56, trailingElement: ((_b = (_a = markerFilters === null || markerFilters === void 0 ? void 0 : markerFilters.assignees) === null || _a === void 0 ? void 0 : _a.find((j) => j.uuid === i.id)) === null || _b === void 0 ? void 0 : _b.count) || 0, wrapContent: true }, i.id));
            }), _jsx(FilterSelectbox, { onClick: () => handleAssigneeFilterClick(NOT_SPECIFIED_ASSIGNEE_ID), isChecked: filter.assignee.includes(NOT_SPECIFIED_ASSIGNEE_ID), content: _jsx(Ditto, { componentId: "notspecified" }), color: "black", trailingElement: (_c = (_b = markerFilters === null || markerFilters === void 0 ? void 0 : markerFilters.assignees) === null || _b === void 0 ? void 0 : _b.find((i) => !i.uuid)) === null || _c === void 0 ? void 0 : _c.count })] }));
    return {
        markerFolderFilters,
        markerIntervalFilters,
        markerDuedateFilters,
        markerCategoryFilters,
        markerStatusFilters,
        markerPriorityFilters,
        markerAssigneeFilters
    };
}
