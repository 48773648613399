var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { Ditto } from 'ditto-react';
import { v4 as uuidv4 } from 'uuid';
import { BULK_MAIL_LIMIT, BULK_PRINT_LIMIT, ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { TAB_LIST_2 } from '../../common/consts/panelTab';
import { SIGNS_TO_SHOW } from '../../common/consts/trafficSign';
import { getSignIcon, getSignTranslation } from '../../common/convert/sign';
import { downloadTrafficSignList } from '../../common/export';
import { selectRangeIds } from '../../common/multiselect';
import { ROOT_PATH } from '../../common/url';
import { toggleItemInArray } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { getBBoxParams, getTimeParams } from '../../common/utils/params';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Download, Mail, Print, Task } from '../../components/icons';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { Loader } from '../../components/Loader/Loader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { SelectionBar } from '../../components/SelectionBar/SelectionBar';
import { TypedDitto } from '../../components/TypedDitto';
import { useSignFilterHandlers } from '../../hooks/filters/useSignsFilterHandlers';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useMarkerCreatorOpener } from '../../hooks/useMarkerCreatorOpener';
import { useUserRole } from '../../hooks/useUserRole';
import { getFilterParams, getSortParams } from '../../services/sign';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHighlightedFeature, setHighlightedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { fetchSignList, fetchSignListCount, resetSignFilter, setSignActiveTabKey, setSignCurrentPage, setSignFilter, setSignSelectedIdList, startSignMultiSelect } from '../../state/slices/leftPanelSign';
import { setMarkerBulkCreatorPointSource } from '../../state/slices/markerBulkCreator';
import { addNotificationMessage, removeNotificationMessage } from '../../state/slices/notification';
import { fetchSignInfoList } from '../../state/slices/sharedDataHolder';
import { Button } from '../../uikit/Button/Button';
const SIGNS_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'sign'
    }, isLoading: true }, idx)));
export const LeftPanelSignContainer = () => {
    const panelContainerRef = useRef(null);
    const { addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const dateTimeFormatter = useDateTimeFormatter();
    const { resultList, filter, totalCountList, resultFetchStatus, currentPage, useMapBounds, activeTabKey, selectedIdList, sort, isMultiSelectOn } = useAppSelector((state) => state.leftPanelSign);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { signDamageTypes, isSignDamageTypesLoading } = useAppSelector((state) => state.shared);
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const { locale } = useAppSelector((state) => state.app);
    const { targetSchema } = useAppSelector((state) => state.auth);
    const { handleDamageFilterClick } = useSignFilterHandlers();
    const { handleAddMarkerFromSignButtonClick } = useMarkerCreatorOpener();
    const { isEditor } = useUserRole();
    const signDamageTypesToShow = signDamageTypes.filter((t) => SIGNS_TO_SHOW.includes(t.id));
    useEffect(() => {
        if (useMapBounds) {
            dispatch(fetchSignList());
        }
    }, [dispatch, filter, useMapBounds, sort, currentPage, selectedOption, bounds]);
    useEffect(() => {
        if (useMapBounds) {
            dispatch(fetchSignListCount());
        }
    }, [dispatch, filter, useMapBounds, selectedOption, bounds]);
    useEffect(() => {
        if (!useMapBounds) {
            dispatch(fetchSignList());
        }
    }, [dispatch, filter, useMapBounds, sort, currentPage, selectedOption]);
    useEffect(() => {
        if (!useMapBounds) {
            dispatch(fetchSignListCount());
        }
    }, [dispatch, filter, useMapBounds, selectedOption]);
    const resultPageList = resultList.slice(0, ITEMS_PER_PAGE);
    const filtersCount = filter.damageTypeIds.length;
    function handleExport(selectedIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationId = uuidv4();
            const abortSignalId = notificationId;
            dispatch(addNotificationMessage({
                closeAfterSeconds: 0,
                id: notificationId,
                type: 'loading',
                ditto: {
                    title: { componentId: 'toast.prepareexport' },
                    description: { componentId: 'toast.largedata' }
                },
                action: {
                    type: 'fetch-abort',
                    signalId: abortSignalId,
                    label: { componentId: 'promt.cancel' },
                    color: 'dark-gray'
                }
            }));
            try {
                yield downloadTrafficSignList({
                    language: locale,
                    params: selectedIds
                        ? {}
                        : {
                            filter: getFilterParams(filter),
                            bbox: getBBoxParams(bounds, useMapBounds),
                            sort: getSortParams(sort),
                            timepicker: getTimeParams(selectedOption)
                        },
                    signIds: selectedIds
                }, abortSignalId);
            }
            catch (error) {
                const errorName = error instanceof Error ? error.name : undefined;
                if (errorName === 'AbortError') {
                    return;
                }
                else {
                    dispatch(addNotificationMessage({ type: 'error' }));
                }
            }
            finally {
                dispatch(removeNotificationMessage(notificationId));
            }
        });
    }
    function handleMail() {
        dispatch(fetchSignInfoList(selectedIdList))
            .unwrap()
            .then((res) => {
            var _a;
            if ((_a = res.result) === null || _a === void 0 ? void 0 : _a.sign) {
                addModal({
                    id: 'MailModal',
                    props: {
                        featureList: {
                            type: 'sign',
                            value: res.result.sign
                        }
                    }
                });
            }
        });
    }
    function handlePrint(ids) {
        window.open(`/${ROOT_PATH.print}/sign/${ids.join(',')}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleBulkCreateMarker() {
        if (selectedIdList.length === 0) {
            return;
        }
        dispatch(setMarkerBulkCreatorPointSource({ signIds: selectedIdList }));
    }
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleSignClick(id) {
        if ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === id) {
            return;
        }
        dispatch(setSelectedFeature({
            type: 'sign',
            id
        }));
    }
    function handleSignsDamageTypeGroupClick() {
        dispatch(setSignFilter({
            damageTypeIds: filter.damageTypeIds.length === 0
                ? signDamageTypesToShow.map((item) => item.id)
                : []
        }));
    }
    function handleResetFilter() {
        dispatch(resetSignFilter());
    }
    function handleClickTab(tabId) {
        dispatch(setSignActiveTabKey(tabId));
        if (tabId === 'list') {
            dispatch(setSignCurrentPage(PAGE_NUMBER_FIRST));
        }
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setSignCurrentPage(toPage));
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    function handleSignSelectClick(id, event) {
        if (!isMultiSelectOn) {
            dispatch(startSignMultiSelect());
        }
        dispatch(setSignSelectedIdList(toggleItemInArray(selectedIdList, id)));
        if (event === null || event === void 0 ? void 0 : event.nativeEvent.shiftKey) {
            dispatch(setSignSelectedIdList(selectRangeIds(id, resultPageList, selectedIdList)));
        }
    }
    function handleImageEnlargeClick(id) {
        handleSignClick(id);
        addModal({ id: 'ImageViewerSign' });
    }
    const resultsCurrentPage = (() => {
        if (resultFetchStatus === 'loading') {
            return SIGNS_LOADING_LIST;
        }
        if (resultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return resultList.map((item) => {
            return (_jsx(Result, { content: {
                    type: 'sign',
                    value: {
                        dateTime: dateTimeFormatter(item.timestamp),
                        address: item.displayName,
                        sign: {
                            icon: getSignIcon(item.damageType),
                            text: getSignTranslation(item.damageType)
                        },
                        image: {
                            url: item.imageUrl,
                            tag: item.tag
                        }
                    }
                }, isSelectable: isMultiSelectOn, isHighlighted: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.id, isActive: selectedIdList.includes(item.id), isEditor: isEditor, onSelect: (event) => handleSignSelectClick(item.id, event), onClick: () => handleSignClick(item.id), onImageEnlargeClick: () => handleImageEnlargeClick(item.id), onAddMarkerButtonClick: () => handleAddMarkerFromSignButtonClick(item.id), onMouseEnter: () => dispatch(setHighlightedFeature({ type: 'sign', id: item.id })), onMouseLeave: () => dispatch(clearHighlightedFeature()) }, item.id));
        });
    })();
    const signsFilter = signDamageTypesToShow.length && (_jsx(LayerFilter, { children: _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "objects.status" }), checkState: getCheckboxCheckState(signDamageTypesToShow.length, filter.damageTypeIds.length), onCheckboxClick: handleSignsDamageTypeGroupClick }, { children: signDamageTypesToShow.map((signType) => (_jsx(FilterSelectbox, { onClick: () => handleDamageFilterClick(signType.id), isChecked: filter.damageTypeIds.includes(signType.id), content: getSignTranslation(signType.id), icon: getSignIcon(signType.id), color: "black", trailingElement: isSignDamageTypesLoading ? (_jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Loader, {}) }))) : (signType.count) }, signType.name))) })) }));
    const bottomMenu = (() => {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
        if (activeTabKey === 'list' && isMultiSelectOn) {
            return (_jsxs(SelectionBar, Object.assign({ leftButton: _jsx(Button, Object.assign({ disabled: !selectedIdList.length, icon: _jsx(Task, {}), color: "yellow", onClick: handleBulkCreateMarker, variant: "slender" }, { children: _jsx(TypedDitto, { componentId: "creatextasks", variables: {
                            SelectedResults: selectedIdList.length
                        }, count: selectedIdList.length }) })) }, { children: [_jsx(ActionButton, { disabled: !selectedIdList.length || selectedIdList.length > BULK_MAIL_LIMIT, icon: _jsx(Mail, {}), onClick: handleMail }), _jsx(ActionButton, { disabled: !selectedIdList.length || selectedIdList.length > BULK_PRINT_LIMIT, icon: _jsx(Print, {}), onClick: () => handlePrint(selectedIdList) }), _jsx(ActionButton, { disabled: !selectedIdList.length, icon: _jsx(Download, {}), onClick: () => handleExport(selectedIdList) })] })));
        }
    })();
    return (_jsx(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: _jsx(Ditto, { componentId: "category.trafficsigns" }), label: _jsx(Ditto, { componentId: "betalabel" }), resetFilter: {
                filtersCount,
                onReset: handleResetFilter,
                disabled: filtersCount === 0
            }, exportXLS: {
                onExport: handleExport,
                disabled: totalCountList === 0
            }, onCollapse: handleCollapse }), tabBar: isMultiSelectOn ? undefined : (_jsx(PanelButtonGroup, { tabs: TAB_LIST_2, activeKey: activeTabKey, onClickTab: handleClickTab })), ref: panelContainerRef, bottomMenu: bottomMenu, pagination: activeTabKey === 'list' && resultList.length !== 0 ? (_jsx(Pagination, { totalItems: totalCountList, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined }, { children: activeTabKey === 'list' ? resultsCurrentPage : signsFilter })));
};
