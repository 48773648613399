// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b9sOEqR3VX1ZkCfL8YDX{padding:4px 16px}.xYSRH4j_Jx54uXYggbJN{display:flex;align-items:center;justify-content:space-between;font-weight:600;font-size:20px;line-height:40px;hyphens:auto;-ms-hyphens:auto;-webkit-hyphens:auto;-moz-hyphens:auto;overflow-wrap:anywhere}.KQPcvDQ3kHUTf85KlOqi{font-weight:600;font-size:16px;line-height:19px;margin-top:60px}.f39RQ5mYYlca2siwJXFZ{font-weight:400;font-size:14px;line-height:140%;margin-top:16px;margin-bottom:26px}.VlIwRYPDwztg2Tw1C9gW{display:block;margin:10px auto 20px;text-align:center}.qS3369QauFapIJA_IPof{width:100%;height:48px}`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/NoPlannedSegments/NoPlannedSegments.module.css"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CACA,sBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sBACI,aAAA,CACA,qBAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,WAAA","sourcesContent":[".container {\n    padding: 4px 16px;\n}\n\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 40px;\n    hyphens: auto;\n    -ms-hyphens: auto;\n    -webkit-hyphens: auto;\n    -moz-hyphens: auto;\n    overflow-wrap: anywhere;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 19px;\n    margin-top: 60px;\n}\n\n.description {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    margin-top: 16px;\n    margin-bottom: 26px;\n}\n\n.image {\n    display: block;\n    margin: 10px auto 20px;\n    text-align: center;\n}\n\n.button {\n    width: 100%;\n    height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `b9sOEqR3VX1ZkCfL8YDX`,
	"header": `xYSRH4j_Jx54uXYggbJN`,
	"title": `KQPcvDQ3kHUTf85KlOqi`,
	"description": `f39RQ5mYYlca2siwJXFZ`,
	"image": `VlIwRYPDwztg2Tw1C9gW`,
	"button": `qS3369QauFapIJA_IPof`
};
export default ___CSS_LOADER_EXPORT___;
