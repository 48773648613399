import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Checkbox } from '../../../uikit/Checkbox/Checkbox';
import { Close } from '../../icons';
import { Modal } from '../../Modal/Modal';
import { YoutubeEmbed } from '../../YoutubeEmbed/YoutubeEmbed';
import styles from './IntroModal.module.css';
export function IntroModal({ onYesClick, onNoClick, promoContent, videoUrl, title, subheader, content, yesLabel, linkButton }) {
    const [dontShowAgain, setDontShowAgain] = React.useState(false);
    return (_jsx(Modal, { className: styles['modal'], title: _jsx("p", { children: title }), "data-test": "intro", trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: () => onNoClick && onNoClick(dontShowAgain), "data-test": "intro-close" }), content: _jsxs("div", Object.assign({ className: styles['content-wrapper'] }, { children: [promoContent && (_jsx("div", Object.assign({ className: classes(styles['promo'], styles['promo--text']) }, { children: promoContent }))), videoUrl && (_jsx("div", Object.assign({ className: styles['promo'] }, { children: _jsx(YoutubeEmbed, { embedId: videoUrl }) }))), _jsxs("div", Object.assign({ className: styles['content'] }, { children: [subheader && _jsx("p", Object.assign({ className: styles['subheader'] }, { children: subheader })), _jsx("p", { children: content })] }))] })), footer: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles['checkbox'] }, { children: [_jsx(Checkbox, { isChecked: dontShowAgain, color: "black", onClick: () => {
                                setDontShowAgain(!dontShowAgain);
                            } }), _jsx(Ditto, { componentId: "modal.dontshowagain" })] })), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [linkButton, _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => onYesClick && onYesClick(dontShowAgain) }, { children: yesLabel }))] }))] }), footerAlign: "space-between", width: 600 }));
}
