import { NON_SELECTABLE_FEATURES } from '../../common/consts/map';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_EVENT_VALUE } from '../../common/consts/mixpanel';
import { extractFeatureFromEvent, getFeatureLocation, isSameSelectedFeature, toSelectedFeature } from '../../common/mapbox';
import { submitTrackingEvent } from '../../services/mail';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearClickedFeature, clearHoveredFeature, clearSelectedFeature, setClickedFeature, setHoveredFeature, setSelectedFeature } from '../../state/slices/feature';
import { clearRightClickCoordinates, resetMapCursor, setClickCoordinates, setCurrentRightClickAction, setDraftPin, setDraftPinInitialPosition, setMapCursor, setRightClickCoordinates } from '../../state/slices/mainMap';
import { useMapMultiSelect } from '../useMapMultiSelect';
export function useMapEventHandlers() {
    const dispatch = useAppDispatch();
    const { selectedFeature, hoveredFeature } = useAppSelector((state) => state.feature);
    const { currentAction } = useAppSelector((state) => state.app);
    const { currentRightClickAction } = useAppSelector((state) => state.mainMap);
    const { isMultiSelectOn, multiSelectFeature } = useMapMultiSelect();
    function handleMouseMove(e) {
        var _a;
        const mapFeature = extractFeatureFromEvent(e);
        if (!mapFeature) {
            dispatch(clearHoveredFeature());
            return;
        }
        // skip arrows in inspection layer and segment creator form
        if (currentAction === 'segment-creator-form' || ((_a = mapFeature.properties) === null || _a === void 0 ? void 0 : _a.arrow)) {
            return;
        }
        const featureIdentifier = {
            id: mapFeature.id,
            source: mapFeature.source,
            sourceLayer: mapFeature.sourceLayer
        };
        const feature = toSelectedFeature(featureIdentifier);
        if (isSameSelectedFeature(feature, hoveredFeature === null || hoveredFeature === void 0 ? void 0 : hoveredFeature.feature)) {
            return;
        }
        dispatch(setHoveredFeature({
            feature,
            properties: mapFeature.properties,
            location: getFeatureLocation(mapFeature)
        }));
    }
    function handleRightClick(e) {
        dispatch(setRightClickCoordinates({
            lat: e.lngLat.lat,
            lng: e.lngLat.lng
        }));
        dispatch(setCurrentRightClickAction('none'));
    }
    function handleClick(e) {
        var _a;
        dispatch(setClickCoordinates({
            lat: e.lngLat.lat,
            lng: e.lngLat.lng
        }));
        dispatch(clearRightClickCoordinates());
        dispatch(setCurrentRightClickAction('none'));
        if (currentAction === 'marker-creator' ||
            currentAction === 'asset-creator' ||
            currentAction === 'traffic-sign-creator') {
            const position = {
                lat: e.lngLat.lat,
                lng: e.lngLat.lng
            };
            dispatch(setDraftPin(position));
            dispatch(setDraftPinInitialPosition(position));
            return;
        }
        if (currentRightClickAction === 'note-creator-picker') {
            dispatch(setRightClickCoordinates(e.lngLat));
            dispatch(setCurrentRightClickAction('note-creator'));
            return;
        }
        const mapFeature = extractFeatureFromEvent(e);
        if (!mapFeature) {
            dispatch(clearClickedFeature());
            dispatch(clearSelectedFeature());
            return;
        }
        // skip arrows in inspection layer and segment creator form
        if (currentAction === 'segment-creator-form' || ((_a = mapFeature.properties) === null || _a === void 0 ? void 0 : _a.arrow)) {
            return;
        }
        const featureIdentifier = {
            id: mapFeature.id,
            source: mapFeature.source,
            sourceLayer: mapFeature.sourceLayer
        };
        const feature = toSelectedFeature(featureIdentifier);
        if (isSameSelectedFeature(feature, selectedFeature)) {
            return;
        }
        if (isMultiSelectOn && e.originalEvent.metaKey) {
            multiSelectFeature(feature);
            return;
        }
        dispatch(setClickedFeature({
            location: getFeatureLocation(mapFeature),
            feature,
            properties: mapFeature.properties
        }));
        if (NON_SELECTABLE_FEATURES.includes(feature.type)) {
            return;
        }
        dispatch(setSelectedFeature(feature));
        if (feature.type === 'sign-inventory') {
            submitTrackingEvent(MIXPANEL_EVENT_NAME.openTrafficSignDetails, {
                [MIXPANEL_EVENT_PROPERTY.openTSDetailsFrom]: MIXPANEL_EVENT_VALUE.map
            });
        }
    }
    function handleMouseEnter() {
        if (currentRightClickAction === 'note-creator-picker' ||
            currentAction === 'segment-creator') {
            return;
        }
        dispatch(setMapCursor('pointer'));
    }
    function handleMouseLeave() {
        if (currentRightClickAction === 'note-creator-picker' ||
            currentAction === 'segment-creator') {
            return;
        }
        dispatch(resetMapCursor());
        dispatch(clearHoveredFeature());
    }
    return {
        handleMouseMove,
        handleClick,
        handleRightClick,
        handleMouseEnter,
        handleMouseLeave
    };
}
