// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JVGr2sQ_g6fVQyo0ZdLC{width:244px;height:140px}.dj9LAcVBsTtSPhx2tmzJ{height:100%;width:100%;object-fit:cover;border-radius:4px 4px 0 0;background:#f0f0f0}.Mo8Amu4BizjwSuBBUOta{display:flex;align-items:center;justify-content:space-between;height:30px;max-width:244px}.AlzSDqkeyeLTZJ_RUZku{display:flex;width:24px;height:24px;padding:3px;justify-content:center;align-items:center;border-radius:100px;background:var(--primary-white, #fff)}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/Asset/Asset.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,yBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,mBAAA,CACA,qCAAA","sourcesContent":[".image-wrapper {\n    width: 244px;\n    height: 140px;\n}\n\n.image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 4px 4px 0 0;\n    background: #f0f0f0;\n}\n\n.info {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 30px;\n    max-width: 244px;\n}\n\n.icon-background {\n    display: flex;\n    width: 24px;\n    height: 24px;\n    padding: 3px;\n    justify-content: center;\n    align-items: center;\n    border-radius: 100px;\n    background: var(--primary-white, #fff);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-wrapper": `JVGr2sQ_g6fVQyo0ZdLC`,
	"image": `dj9LAcVBsTtSPhx2tmzJ`,
	"info": `Mo8Amu4BizjwSuBBUOta`,
	"icon-background": `AlzSDqkeyeLTZJ_RUZku`
};
export default ___CSS_LOADER_EXPORT___;
