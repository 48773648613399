import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Unassigned } from '../../components/icons';
import styles from './Avatar.module.css';
const DEFAULT_FONT_SIZE = 14;
const DEFAULT_AVATAR_SIZE = 40;
export function Avatar({ firstName, lastName, color = 'dark', size = DEFAULT_AVATAR_SIZE, src, anonymize }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], color && styles[`container--${color}`]), style: {
            minWidth: size,
            width: size,
            height: size,
            borderRadius: 6
        }, "data-test": "avatar" }, { children: [anonymize && (_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Unassigned, {}) }))), !src && !anonymize && (_jsxs("p", Object.assign({ className: styles['text'], style: {
                    fontSize: size > DEFAULT_AVATAR_SIZE
                        ? (size / DEFAULT_AVATAR_SIZE) * DEFAULT_FONT_SIZE
                        : undefined
                } }, { children: [firstName && firstName[0].toUpperCase(), lastName && lastName[0].toUpperCase()] }))), src && !anonymize && (_jsx("img", { src: src, className: styles['image'], style: { width: size, height: size } }))] })));
}
