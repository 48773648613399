var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { isDeepEqualByStringify } from '../../common/utils/object';
import { checkTreatmentsAutoUpdateAvailable } from '../../services/plan';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setSelectedLayerId } from '../../state/slices/layer';
import { fetchPlans, setPlanActiveTabKey } from '../../state/slices/leftPanelPlan';
import { fetchPlan } from '../../state/slices/plan';
import { deleteTreatmentSetting, updateTreatmentsSettings } from '../../state/slices/shared';
import { useDittoWrapper } from '../useDittoWrapper';
export const usePlanTreatmentsSettings = () => {
    const dispatch = useAppDispatch();
    const { addModal } = useModalContext();
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { currentPlan } = useAppSelector((state) => state.plan);
    const { planTreatmentsList, planTreatmentUnitTypes } = useAppSelector((state) => state.shared);
    const defaultTitle = useDittoWrapper({ componentId: 'filter.measure.custommeasure' });
    const [planTreatmentsSettingList, setPlanTreatmentsSettingList] = useState(planTreatmentsList);
    useEffect(() => {
        if (planTreatmentsList) {
            setPlanTreatmentsSettingList(planTreatmentsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planTreatmentsList]);
    function handlePlanTreatmentsSaveClick(items, callback) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            function refreshPlans() {
                if (currentPlan) {
                    dispatch(fetchPlan(currentPlan.planId));
                }
                dispatch(fetchPlans());
            }
            const itemsToSave = items.map((item, index) => (Object.assign(Object.assign({}, item), { title: item.title || defaultTitle, orderNumber: index })));
            const res = yield checkTreatmentsAutoUpdateAvailable({
                countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode,
                items
            });
            if ((_a = res.result) === null || _a === void 0 ? void 0 : _a.autoUpdateAvailable) {
                addModal({
                    id: 'DataUpdateModalContainer',
                    props: {
                        onDeclineClick: () => {
                            dispatch(updateTreatmentsSettings({
                                items: itemsToSave,
                                autoUpdateAction: 'decline'
                            }));
                            refreshPlans();
                            callback();
                        },
                        onUpdateAllClick: () => {
                            dispatch(updateTreatmentsSettings({
                                items: itemsToSave,
                                autoUpdateAction: 'accept'
                            }));
                            refreshPlans();
                            callback();
                        },
                        onLaterClick: () => {
                            dispatch(updateTreatmentsSettings({
                                items: itemsToSave,
                                autoUpdateAction: 'postpone'
                            }));
                            refreshPlans();
                            dispatch(setSelectedLayerId('plan'));
                            dispatch(setPlanActiveTabKey('table'));
                            callback();
                        }
                    }
                });
            }
            else {
                yield dispatch(updateTreatmentsSettings({ items: itemsToSave }));
                callback();
            }
            callback();
        });
    }
    function handlePlanTreatmentsCancelClick(list, callback) {
        if (!planTreatmentsList)
            return;
        const isPlanPropertySettingsChanged = !isDeepEqualByStringify(planTreatmentsList, list);
        if (list && isPlanPropertySettingsChanged) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handlePlanTreatmentsSaveClick(list, callback);
                    },
                    onCancel: () => {
                        setPlanTreatmentsSettingList(planTreatmentsList);
                        callback();
                    },
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }
    function handlePlanTreatmentsDeleteClick(id, confirmCallback) {
        if (!planTreatmentsSettingList)
            return;
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    yield dispatch(deleteTreatmentSetting({ id }));
                    confirmCallback && confirmCallback();
                }),
                title: _jsx(Ditto, { componentId: "promt.delete.measure" }),
                description: _jsx(Ditto, { componentId: "promt.delete.measure.text" })
            }
        });
        return;
    }
    return {
        planTreatmentsSettingList,
        planTreatmentUnitTypes,
        setPlanTreatmentsSettingList,
        handlePlanTreatmentsSaveClick,
        handlePlanTreatmentsCancelClick,
        handlePlanTreatmentsDeleteClick
    };
};
