import { useEffect } from 'react';
import { useModalContext } from '../state/context';
import { useAppDispatch } from '../state/hooks';
import { setCurrentModalId } from '../state/slices/app';
export function useModalContextToReduxSync() {
    const { modals } = useModalContext();
    const dispatch = useAppDispatch();
    const currentModalId = (function () {
        const relevantModalIds = [
            'ImageViewerMarker',
            'ImageViewerCondition',
            'ImageViewerObject',
            'ImageViewerSign',
            'ImageViewerSignInventory',
            'ImageViewerAsset',
            'GlobalSettingsModal'
        ];
        for (const mid of relevantModalIds) {
            if (modals.find((m) => m.id === mid)) {
                return mid;
            }
        }
    })();
    useEffect(() => {
        dispatch(setCurrentModalId(currentModalId));
    }, [currentModalId, dispatch]);
}
