import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { TABLE_WIDTH } from '../../../../common/consts/table';
import useComponentWidth from '../../../../hooks/useComponentWidth';
import { TextOverflow } from '../../../TextOverflow/TextOverflow';
import { TooltipWrapper } from '../../../TooltipWrapper/TooltipWrapper';
import styles from './DataGridTableFooterItem.module.css';
export function DataGridTableFooterItem({ content, icon, stickyPosition, textAlign }) {
    const { ref, width } = useComponentWidth();
    const isXS = width > 0 ? width < TABLE_WIDTH.s : false;
    return (_jsx("td", Object.assign({ className: classes(styles['container'], content && styles['container--border'], stickyPosition !== undefined && styles['container--pinned']), style: { width, textAlign, left: stickyPosition }, ref: ref }, { children: _jsxs("div", Object.assign({ className: classes(styles['text'], textAlign && styles[`text--${textAlign}`], icon && isXS && styles['text--center']) }, { children: [icon && (_jsx(TooltipWrapper, Object.assign({ text: isXS ? content : undefined, arrow: "down" }, { children: _jsx(_Fragment, { children: icon }) }))), ((icon && !isXS) || (content && !icon)) && (_jsx(TextOverflow, { text: content, maxLines: 1, tooltip: "small", tooltipPosition: "up", onWidthChange: () => { } }))] })) })));
}
