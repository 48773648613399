import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { POPUP_SETTINGS_FIELDNAME } from '../../common/settings';
import { External } from '../../components/icons';
import { IntroModal } from '../../components/Modals/IntroModal/IntroModal';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useModalContext } from '../../state/context';
import { useAppDispatch } from '../../state/hooks';
import { updateUserPopupSettingsTk } from '../../state/slices/auth';
import { Button } from '../../uikit/Button/Button';
export function SignInventoryIntroModalContainer() {
    const { removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    const wikiurl = useDittoWrapper({ componentId: 'wikilink.trafficsigns' });
    function closeModal(dontShowAgain) {
        if (dontShowAgain) {
            dispatch(updateUserPopupSettingsTk(POPUP_SETTINGS_FIELDNAME.doNotShowSignInventoryIntroModalAgain));
        }
        removeModal('SignInventoryIntroModal');
    }
    function handleLearnMoreClick() {
        window.open(wikiurl, '_blank');
    }
    return (_jsx(IntroModal, { onNoClick: closeModal, onYesClick: closeModal, title: _jsx(Ditto, { componentId: "newfeaturemodal.trafficsigns.header" }), subheader: _jsx(Ditto, { componentId: "newfeaturemodal.trafficsigns.subheader", richText: true }), content: _jsx(Ditto, { componentId: "newfeaturemodal.trafficsigns.content", richText: true }), yesLabel: _jsx(Ditto, { componentId: "assets.intro.trynow" }), linkButton: _jsx(Button, Object.assign({ icon: _jsx(External, {}), color: "gray", variant: "slender", onClick: handleLearnMoreClick }, { children: _jsx(Ditto, { componentId: "infobutton.learnmore" }) })) }));
}
