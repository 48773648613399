// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fGM3NypWEESbXlo81Zwo{position:absolute;top:12px;right:12px}`, "",{"version":3,"sources":["webpack://./src/components/AccountSelector/AccountSelectorLoader/AccountSelectorLoader.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".logout-icon {\n    position: absolute;\n    top: 12px;\n    right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logout-icon": `fGM3NypWEESbXlo81Zwo`
};
export default ___CSS_LOADER_EXPORT___;
