import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { checkPassword } from '../../../common/validation/password';
import { Button } from '../../../uikit/Button/Button';
import { PasswordForm } from '../../Auth/PasswordForm/PasswordForm';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Close, Warning1 } from '../../icons';
import { Modal } from '../../Modal/Modal';
import styles from './UpdatePasswordModal.module.css';
export function UpdatePasswordModal({ loading, showWarning, values, onCancelClick, onChange, onClickOutside, onSubmit }) {
    const isSubmitDisabled = loading ||
        !checkPassword(values.password, values.passwordConfirm).isPasswordValid ||
        !values.oldPassword;
    return (_jsx(Modal, { width: 576, onClickOutside: onClickOutside, "data-test": "update-password-modal", title: _jsx(Ditto, { componentId: "register.updatepassword" }), trailing: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), onClick: () => onCancelClick && onCancelClick() }), content: _jsxs(_Fragment, { children: [showWarning && (_jsxs("div", Object.assign({ className: classes(styles['warning']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['icon']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Warning1, {}) })) })), _jsx(Ditto, { componentId: "register.updatepassword.info" })] }))), _jsx(PasswordForm, { onChange: onChange, values: values })] }), footer: _jsx(Button, Object.assign({ color: "dark", disabled: isSubmitDisabled, onClick: onSubmit, variant: "modal-action", "data-test": "update-password-submit" }, { children: _jsx("span", Object.assign({ className: styles['button-text'] }, { children: _jsx(Ditto, { componentId: "modal.confirm" }) })) })) }));
}
