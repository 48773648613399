import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { CalendarBlank, ID, ListDashes, Select, Text } from '../../components/icons';
import { COMMON_ATTRIBUTE_TYPE } from '../consts/attribute';
export function getAttributeIcon(type) {
    switch (type) {
        case COMMON_ATTRIBUTE_TYPE.select:
            return _jsx(Select, {});
        case COMMON_ATTRIBUTE_TYPE.number:
            return _jsx(ID, {});
        case COMMON_ATTRIBUTE_TYPE.text:
            return _jsx(Text, {});
        case COMMON_ATTRIBUTE_TYPE.date:
            return _jsx(CalendarBlank, {});
        case COMMON_ATTRIBUTE_TYPE.multiselect:
            return _jsx(ListDashes, {});
        default:
            return _jsx(_Fragment, {});
    }
}
export function getAttributeSelectLabel(value) {
    switch (value) {
        case COMMON_ATTRIBUTE_TYPE.text:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.text" });
        case COMMON_ATTRIBUTE_TYPE.select:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.list" });
        case COMMON_ATTRIBUTE_TYPE.number:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.number" });
        case COMMON_ATTRIBUTE_TYPE.date:
            return _jsx(Ditto, { componentId: "globalsettings.datatype.date" });
        case COMMON_ATTRIBUTE_TYPE.multiselect:
            return _jsx(Ditto, { componentId: "filter.multiselect.multiselect" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function parseCommonAttributeValue(value, type) {
    switch (type) {
        case COMMON_ATTRIBUTE_TYPE.number:
            return parseFloat(value);
        case COMMON_ATTRIBUTE_TYPE.select:
            return parseInt(value) || undefined;
        case COMMON_ATTRIBUTE_TYPE.date:
            return new Date(value) || undefined;
        case COMMON_ATTRIBUTE_TYPE.multiselect:
            return csvToArray(value);
        case COMMON_ATTRIBUTE_TYPE.text:
            return value;
    }
}
export function csvToArray(value) {
    const array = value ? value.split(',') : [];
    return array.map((v) => parseInt(v));
}
export function arrayToCsv(value) {
    return value.join(',');
}
export function displayCsv(value) {
    return value === null || value === void 0 ? void 0 : value.join(', ');
}
export function getAttributeValue(attributeSchema, formatter, attribute, unit) {
    var _a, _b, _c, _d;
    if (!attribute) {
        return;
    }
    switch (attributeSchema.type) {
        case COMMON_ATTRIBUTE_TYPE.number:
            return attribute.numberValue !== undefined
                ? formatter.formatNumberWithUnit(attribute.numberValue, 10, unit)
                : undefined;
        case COMMON_ATTRIBUTE_TYPE.select:
            return (_b = (_a = attributeSchema.selectOptions) === null || _a === void 0 ? void 0 : _a.find((i) => i.id === attribute.selectValue)) === null || _b === void 0 ? void 0 : _b.name;
        case COMMON_ATTRIBUTE_TYPE.date:
            return attribute.dateValue ? formatter.formatDateISO(attribute.dateValue) : undefined;
        case COMMON_ATTRIBUTE_TYPE.text:
            return (_c = attribute.textValue) !== null && _c !== void 0 ? _c : undefined;
        case COMMON_ATTRIBUTE_TYPE.multiselect:
            return displayCsv((_d = attributeSchema.selectOptions) === null || _d === void 0 ? void 0 : _d.filter((i) => { var _a; return (_a = attribute.multiselectValue) === null || _a === void 0 ? void 0 : _a.includes(i.id); }).map((o) => o.name));
    }
}
