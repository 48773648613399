// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wAsRO0LD_p2zpJqDOnxt{position:relative}.JyEbjX1p_6votzANCP10{height:8px}.MDaRrDhXoiIpvlewLZ2d{display:flex;gap:12px}.Ad5oM14d9TaGgleTjlzy{display:flex;align-items:center;justify-content:center;width:100%;margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/AutoUpdateModal/AutoUpdateModal.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,YAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.space {\n    height: 8px;\n}\n\n.gap {\n    display: flex;\n    gap: 12px;\n}\n\n.loading {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wAsRO0LD_p2zpJqDOnxt`,
	"space": `JyEbjX1p_6votzANCP10`,
	"gap": `MDaRrDhXoiIpvlewLZ2d`,
	"loading": `Ad5oM14d9TaGgleTjlzy`
};
export default ___CSS_LOADER_EXPORT___;
