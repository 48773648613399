import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export function Spacer({ children, top, bottom, left, right }) {
    return (_jsx("div", Object.assign({ style: {
            paddingLeft: left,
            paddingRight: right,
            paddingTop: top,
            paddingBottom: bottom
        } }, { children: children })));
}
