// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e8ZkaTUeOdW9bHNJhLFV{overflow:hidden;display:flex;flex-direction:column;flex-shrink:0;max-height:100%;width:100%}.S3RBkXsV5jGj8tSB7eU5{padding:12px}.YCMjuKLaNNkKu23mJJXa{width:100%;height:1px;background:var(--primary-black);opacity:.05;flex-shrink:0}.vzVuoqYD2ly9KziOXGc6{display:flex;flex-direction:column;align-items:center;padding:12px;scroll-behavior:smooth;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/components/AssetTypeSearch/AssetTypeSearch.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,eAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".container {\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    flex-shrink: 0;\n    max-height: 100%;\n    width: 100%;\n}\n\n.search-field {\n    padding: 12px;\n}\n\n.divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    flex-shrink: 0;\n}\n\n.items {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 12px;\n    scroll-behavior: smooth;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `e8ZkaTUeOdW9bHNJhLFV`,
	"search-field": `S3RBkXsV5jGj8tSB7eU5`,
	"divider": `YCMjuKLaNNkKu23mJJXa`,
	"items": `vzVuoqYD2ly9KziOXGc6`
};
export default ___CSS_LOADER_EXPORT___;
