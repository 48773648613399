// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icX3grdrxUqAXGVJx1bv{display:flex;flex-direction:column;gap:12px}.yDkA5BkMzZhpdw7v1cVt{display:flex;flex-direction:row;align-items:start;justify-content:space-between;gap:4px;width:100%}.m4ZDZlBeCJ8sqwMB7h9s{user-select:none}.HkrjGoxOnR4uZISsxdXi input{color:#b1b5b9;caret-color:rgba(0,0,0,0)}.ONDCI2gtMOfBQOiSR2CA{width:50%}._3V14X0K_2K9RZhtdv5fk.MFnTzC5VxFU4eikYOHpH .yXaAs9gU67pleM2MOraH{min-height:361px}`, "",{"version":3,"sources":["webpack://./src/components/MonthPickerContent/MonthPickerContent.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACA,OAAA,CACA,UAAA,CAGJ,sBACI,gBAAA,CAGJ,4BACI,aAAA,CACA,yBAAA,CAGJ,sBACI,SAAA,CAGJ,kEACI,gBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n\n.content {\n    display: flex;\n    flex-direction: row;\n    align-items: start;\n    justify-content: space-between;\n    gap: 4px;\n    width: 100%;\n}\n\n.date-input {\n    user-select: none;\n}\n\n.date-input--empty input {\n    color: #b1b5b9;\n    caret-color: transparent;\n}\n\n.content-half {\n    width: 50%;\n}\n\n.datepicker-containter.fixed-height .react-datepicker__month-container {\n    min-height: 361px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `icX3grdrxUqAXGVJx1bv`,
	"content": `yDkA5BkMzZhpdw7v1cVt`,
	"date-input": `m4ZDZlBeCJ8sqwMB7h9s`,
	"date-input--empty": `HkrjGoxOnR4uZISsxdXi`,
	"content-half": `ONDCI2gtMOfBQOiSR2CA`,
	"datepicker-containter": `_3V14X0K_2K9RZhtdv5fk`,
	"fixed-height": `MFnTzC5VxFU4eikYOHpH`,
	"react-datepicker__month-container": `yXaAs9gU67pleM2MOraH`
};
export default ___CSS_LOADER_EXPORT___;
