var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAlertSortOrder } from '../common/convert/alert';
import { get, getV2, patch, postRaw } from '../common/fetch';
const ALERT_TYPES_ENDPOINT = 'v3/road-safety/record-alerts/types';
const ALERT_LIST_ENDPOINT = 'v3/road-safety/record-alerts';
const ALERT_SET_INACTIVE_ENDPOINT = 'v1/road-safety/record-alerts';
const ALERT_GEOJSON_ENDPOINT = 'v1/road-safety/record-alerts/geojson';
const ALERT_EXPORT_ENDPOINT = 'road-safety/v2/road-alerts/export';
export function getAlertTypes() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield get(ALERT_TYPES_ENDPOINT);
        (_a = response.result) === null || _a === void 0 ? void 0 : _a.list.sort((a, b) => getAlertSortOrder(a.typeName) - getAlertSortOrder(b.typeName));
        return response;
    });
}
export function getAlertList(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield getV2(ALERT_LIST_ENDPOINT, params);
    });
}
export function setAlertInactive(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield patch(`${ALERT_SET_INACTIVE_ENDPOINT}/${id}/state-inactive`);
    });
}
export function getAlertGeoJSON(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield getV2(ALERT_GEOJSON_ENDPOINT, {
            from: params.from,
            to: params.to,
            'typeId[]': params.typeId
        });
    });
}
// NOTE: do NOT copy this function as an example,
// this is special communication when reponse is not json,
export function exportAlertList(body, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield postRaw(ALERT_EXPORT_ENDPOINT, body, true, abortSignalId);
    });
}
export function getSortParams(id) {
    switch (id) {
        case 'created_at_server-asc':
            return { sortBy: 'created_at_server', sortOrder: 'asc' };
        case 'created_at_server-desc':
            return { sortBy: 'created_at_server', sortOrder: 'desc' };
        default:
            console.error('unknown rs-alert sort id', id);
    }
}
export function getFilterParams(filter) {
    return {
        damageTypeIds: filter.typeId
    };
}
