import { ASSET_TILES_ENDPOINT, CONDITION_EDGE_JUNCTIONS_TILES_ENDPOINT, CONDITION_EDGE_TILES_ENDPOINT, CONDITION_OBJECT_TILES_ENDPOINT, CONDITION_POINT_TILES_ENDPOINT, createSignInventoryTileEndpoint, createSignTileEndpoint, INSPECTIONS_V2_TILES_ENDPOINT, REFERENCING_TILES_ENDPOINT } from '../../services/map';
export const MAP_ID_FIELD_NAME = {
    point: 'point_id',
    alert: 'point_id',
    object: 'point_id',
    segment: 'street_id',
    marker: 'pointOfInterestId',
    sign: 'sign_id',
    signInventory: 'sign_id',
    inspection: 'inspection_uuid',
    inspectionV2: 'inspectionId',
    plan: 'planId',
    note: 'id',
    asset: 'manualInventoryID'
};
export function isMapSourceLoaded(map, sourceId) {
    return map.getSource(sourceId) && map.isSourceLoaded(sourceId);
}
export const SEGMENTS_SOURCE_ID = 'segments';
export const segmentsSource = {
    id: SEGMENTS_SOURCE_ID,
    type: 'vector',
    tiles: [CONDITION_EDGE_TILES_ENDPOINT]
};
export const SEGMENT_JUNCTIONS_SOURCE_ID = 'segment-junctions';
export const segmentJunctionsSource = {
    id: SEGMENT_JUNCTIONS_SOURCE_ID,
    type: 'vector',
    tiles: [CONDITION_EDGE_JUNCTIONS_TILES_ENDPOINT]
};
export const POINTS_SOURCE_ID = 'points';
export const pointSource = {
    id: POINTS_SOURCE_ID,
    type: 'vector',
    tiles: [CONDITION_POINT_TILES_ENDPOINT],
    promoteId: MAP_ID_FIELD_NAME.point
};
export const PLANS_SOURCE_ID = `plans`;
export const planSource = {
    id: PLANS_SOURCE_ID,
    promoteId: MAP_ID_FIELD_NAME.plan
};
export const ALERTS_SOURCE_ID = `alerts`;
export const alertSource = {
    id: ALERTS_SOURCE_ID,
    promoteId: MAP_ID_FIELD_NAME.alert
};
export const OBJECTS_SOURCE_ID = `objects`;
export const objectSource = {
    id: OBJECTS_SOURCE_ID,
    type: 'vector',
    tiles: [CONDITION_OBJECT_TILES_ENDPOINT],
    promoteId: MAP_ID_FIELD_NAME.object
};
export const REFERENCING_SOURCE_ID = `referencing`;
export const referencingSource = {
    id: REFERENCING_SOURCE_ID,
    type: 'vector',
    tiles: [REFERENCING_TILES_ENDPOINT],
    promoteId: 'id'
};
export const NOTE_SOURCE_ID = 'note';
export const TRACK_CONTROL_SOURCE_ID = `track-control`;
export const trackControlSource = {
    id: TRACK_CONTROL_SOURCE_ID,
    type: 'vector',
    tiles: [INSPECTIONS_V2_TILES_ENDPOINT],
    promoteId: MAP_ID_FIELD_NAME.inspectionV2
};
export const WINTER_SERVICE_SOURCE_ID = `winter-service`;
export const winterServiceSource = {
    id: WINTER_SERVICE_SOURCE_ID,
    type: 'vector',
    tiles: [INSPECTIONS_V2_TILES_ENDPOINT],
    promoteId: MAP_ID_FIELD_NAME.inspectionV2
};
export const MARKERS_SOURCE_ID = 'markers';
export const TRAFFIC_SIGNS_SOURCE_ID = 'traffic-signs';
export const SIGN_INVENTORY_SOURCE_ID = 'sign-inventory';
export const SEGMENT_CREATE_SOURCE_ID = 'segment-create';
export const createTrafficSignSource = (customer) => ({
    id: TRAFFIC_SIGNS_SOURCE_ID,
    type: 'vector',
    tiles: [createSignTileEndpoint(customer)],
    promoteId: MAP_ID_FIELD_NAME.sign
});
export const createSignInventorySource = (customer) => ({
    id: SIGN_INVENTORY_SOURCE_ID,
    type: 'vector',
    tiles: [createSignInventoryTileEndpoint(customer)],
    promoteId: MAP_ID_FIELD_NAME.signInventory
});
export const ASSETS_SOURCE_ID = 'assets';
export const assetSource = {
    id: ASSETS_SOURCE_ID,
    type: 'vector',
    tiles: [ASSET_TILES_ENDPOINT],
    promoteId: MAP_ID_FIELD_NAME.asset
};
