// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lDMGeuycCRSRCsIMSVqY{padding:24px}.zEbo2auqnPjSHDwnMeES{font-weight:600;font-size:16px;line-height:19px;margin-top:42px}.lJz0gT_dTWt2vZy3YDKQ{font-weight:400;font-size:14px;line-height:140%;margin-top:16px;margin-bottom:26px}.G8LLLAOUEp2vxTO8PPIZ{vertical-align:top}.OmlCF8rvS1J_KDT37XQA{display:inline;border:0;padding:0 4px 0 0;background:none;text-decoration:underline;cursor:pointer}.aCabLW3RXkbxdE6pAVSm{height:48px}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/AllColumnsHidden/AllColumnsHidden.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,cAAA,CACA,QAAA,CACA,iBAAA,CACA,eAAA,CACA,yBAAA,CACA,cAAA,CAGJ,sBACI,WAAA","sourcesContent":[".container {\n    padding: 24px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 19px;\n    margin-top: 42px;\n}\n\n.description {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    margin-top: 16px;\n    margin-bottom: 26px;\n}\n\n.row {\n    vertical-align: top;\n}\n\n.link {\n    display: inline;\n    border: 0;\n    padding: 0 4px 0 0;\n    background: none;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.button {\n    height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lDMGeuycCRSRCsIMSVqY`,
	"title": `zEbo2auqnPjSHDwnMeES`,
	"description": `lJz0gT_dTWt2vZy3YDKQ`,
	"row": `G8LLLAOUEp2vxTO8PPIZ`,
	"link": `OmlCF8rvS1J_KDT37XQA`,
	"button": `aCabLW3RXkbxdE6pAVSm`
};
export default ___CSS_LOADER_EXPORT___;
