import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { COMMON_ATTRIBUTE_TYPE } from '../../../../../common/consts/attribute';
import { getAttributeIcon } from '../../../../../common/convert/attribute';
import { useDittoWrapper } from '../../../../../hooks/useDittoWrapper';
import { SelectboxEntry } from '../../../../SelectboxEntry/SelectboxEntry';
import { SettingRow } from '../../SettingRow/SettingRow';
import { TrailingSelect } from '../../TrailingSelect/TrailingSelect';
export function AssetAttributeRow({ assetIndex: asIdx, attrIndex: atIdx, attribute, context, children }) {
    const newattribute = useDittoWrapper({ componentId: 'assets.newattribute' });
    const [dragDisabled, setDragDisabled] = useState(false);
    const { isReadOnly, unitList, onAttributeChange, onAttributeDelete, onUnitChange } = context;
    return (_jsx(Draggable, Object.assign({ draggableId: String(attribute.id), index: atIdx, disableInteractiveElementBlocking: false, isDragDisabled: isReadOnly || dragDisabled }, { children: (provided) => {
            var _a, _b;
            return (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { tabIndex: -1 }, { children: _jsx(SettingRow, Object.assign({ onDelete: isReadOnly ? undefined : () => onAttributeDelete(asIdx, atIdx), value: attribute.name, autoFocus: attribute.name === '', placeholder: newattribute, count: attribute.type === COMMON_ATTRIBUTE_TYPE.select
                        ? (_a = attribute.selectOptions) === null || _a === void 0 ? void 0 : _a.length
                        : undefined, onChange: attribute.defaultId || isReadOnly
                        ? undefined
                        : (v) => onAttributeChange(asIdx, atIdx, v), indentLevel: 1, innerIcon: getAttributeIcon(attribute.type), innerIconOpacity: 0.4, trailingSelect: attribute.type === COMMON_ATTRIBUTE_TYPE.number ? (_jsx(TrailingSelect, { variant: isReadOnly ? 'read-only' : 'default', value: (_b = unitList.find((u) => u.id === attribute.unitId)) === null || _b === void 0 ? void 0 : _b.name, menuItems: unitList.map((unit) => (_jsx(SelectboxEntry, { text: unit.name, onClick: () => onUnitChange(asIdx, atIdx, unit.id) }, unit.id))) })) : undefined, draggable: !isReadOnly, initiallyExpanded: true, expandable: attribute.type === 'select' }, { children: _jsx("div", Object.assign({ onMouseEnter: () => setDragDisabled(true), onMouseLeave: () => setDragDisabled(false) }, { children: children })) }), atIdx) })));
        } }), attribute.id || `new-${atIdx}`));
}
