import { COMMON_ATTRIBUTE_TYPE } from './consts/attribute';
export function isAttributeFilterNotEmpty(f) {
    switch (f.type) {
        case 'number':
            return !!(f.numberValue || f.showNotSpecified);
        case 'select':
            return !!(f.selectValue.length > 0 || f.showNotSpecified);
        case 'date':
            return !!(f.dateValue || f.showNotSpecified);
        case 'text':
            return true;
    }
}
export function getNewAttributeFilter(attribute) {
    var _a;
    switch (attribute.type) {
        case COMMON_ATTRIBUTE_TYPE.select:
            return {
                type: 'select',
                id: attribute.id,
                selectValue: ((_a = attribute.selectOptions) === null || _a === void 0 ? void 0 : _a.map((s) => s.id)) || []
            };
        case COMMON_ATTRIBUTE_TYPE.number:
            return {
                type: 'number',
                id: attribute.id
            };
        case COMMON_ATTRIBUTE_TYPE.date:
            return {
                type: 'date',
                id: attribute.id
            };
        case COMMON_ATTRIBUTE_TYPE.text:
            return {
                type: 'text',
                id: attribute.id
            };
    }
}
