import { FEATURE_STATE_FILTERED_OUT, FEATURE_STATE_HIGHLIGHT, FEATURE_STATE_HOVER, FEATURE_STATE_SELECTED } from '../featureStates';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM, MIN_ZOOM, POINTS_FILTERED_OUT_VISIBLE_ZOOM, POINTS_OUTLINE_VISIBLE_ZOON } from '../settings';
import { PLANS_SOURCE_ID, POINTS_SOURCE_ID } from '../sources';
export const POINTS_LAYER_ID = `${LAYER_PREFIX}-points`;
export const ALL_POINTS_LAYER_ID = `${LAYER_PREFIX}-all-points`;
export const FILTERED_OUT_POINTS_LAYER_ID = `${LAYER_PREFIX}-filtered-out-points`;
const DEFAULT_CIRCLE_RADIUS = 7;
const HOVER_CIRCLE_RADIUS = 11;
const HIGHLIGHT_CIRCLE_RADIUS = 11;
const FILTERED_OUT_CIRCLE_RADIUS = 2;
const FILTERED_OUT_CIRCLE_OPACITY = 0.6;
const DEFAULT_CIRCLE_STROKE_COLOR = '#FFFFFF';
const SELECTED_CIRCLE_STROKE_COLOR = '#1556EB';
const FILTERED_OUT_CIRCLE_STROKE_COLOR = '#222D39';
const getCircleStrokeColorExpressionBySelectedAll = (selectedAll) => {
    return [
        'case',
        ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
        selectedAll ? DEFAULT_CIRCLE_STROKE_COLOR : SELECTED_CIRCLE_STROKE_COLOR,
        ['boolean', ['feature-state', FEATURE_STATE_FILTERED_OUT], false],
        FILTERED_OUT_CIRCLE_STROKE_COLOR,
        selectedAll ? SELECTED_CIRCLE_STROKE_COLOR : DEFAULT_CIRCLE_STROKE_COLOR
    ];
};
export const pointsLayer = {
    id: POINTS_LAYER_ID,
    source: POINTS_SOURCE_ID,
    type: 'circle',
    minzoom: MIN_ZOOM,
    paint: {
        'circle-color': ['get', 'color'],
        'circle-opacity': 1,
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // zoom is MIN_ZOOM (or less) -> circle radius will be 2px
            MIN_ZOOM,
            2,
            // zoom is DEFAULT_ZOOM (or less) -> circle radius will be 2px
            DEFAULT_ZOOM,
            2,
            // zoom is MAX_ZOOM (or greater) -> circle radius will be 8px (11px if hovered)
            MAX_ZOOM,
            [
                'case',
                ['boolean', ['feature-state', FEATURE_STATE_HOVER], false],
                HOVER_CIRCLE_RADIUS,
                ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
                HIGHLIGHT_CIRCLE_RADIUS,
                ['boolean', ['feature-state', FEATURE_STATE_FILTERED_OUT], false],
                FILTERED_OUT_CIRCLE_RADIUS,
                DEFAULT_CIRCLE_RADIUS
            ]
        ],
        'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], DEFAULT_ZOOM, 0, MAX_ZOOM, 3],
        'circle-stroke-color': getCircleStrokeColorExpressionBySelectedAll(false),
        'circle-stroke-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            POINTS_OUTLINE_VISIBLE_ZOON - 2,
            0,
            POINTS_OUTLINE_VISIBLE_ZOON,
            [
                'case',
                ['boolean', ['feature-state', FEATURE_STATE_FILTERED_OUT], false],
                FILTERED_OUT_CIRCLE_OPACITY,
                1
            ]
        ],
        'circle-pitch-alignment': 'map'
    }
};
export const pointsSelectedAllLayer = Object.assign(Object.assign({}, pointsLayer), { paint: Object.assign(Object.assign({}, pointsLayer.paint), { 'circle-stroke-color': getCircleStrokeColorExpressionBySelectedAll(true) }) });
export const pointsFilteredOutLayer = {
    id: FILTERED_OUT_POINTS_LAYER_ID,
    source: PLANS_SOURCE_ID,
    type: 'circle',
    minzoom: POINTS_FILTERED_OUT_VISIBLE_ZOOM,
    paint: {
        'circle-color': ['get', 'color'],
        'circle-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            POINTS_FILTERED_OUT_VISIBLE_ZOOM,
            0,
            POINTS_FILTERED_OUT_VISIBLE_ZOOM + 1,
            1
        ],
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            MIN_ZOOM,
            2,
            DEFAULT_ZOOM,
            2,
            MAX_ZOOM,
            FILTERED_OUT_CIRCLE_RADIUS
        ],
        'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], DEFAULT_ZOOM, 0, MAX_ZOOM, 3],
        'circle-stroke-color': FILTERED_OUT_CIRCLE_STROKE_COLOR,
        'circle-stroke-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            POINTS_FILTERED_OUT_VISIBLE_ZOOM + 3,
            0,
            MAX_ZOOM,
            FILTERED_OUT_CIRCLE_OPACITY
        ],
        'circle-pitch-alignment': 'map'
    }
};
