import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { CATEGORY_COLORS } from '../../common/color';
import { biFilter } from '../../common/utils/array';
import { DeleteModalInfo } from '../../components/Modals/GlobalSettingsModal/DeleteModalInfo/DeleteModalInfo';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { enableMapLayer, setSelectedLayerId } from '../../state/slices/layer';
import { setAssetActiveTabKey } from '../../state/slices/leftPanelAsset';
import { initialFilterMarker, setMarkerFilter } from '../../state/slices/leftPanelMarker';
import { createMarkerCategoryTk, deleteMarkerCategoryTk, updateMarkerCategoryTk } from '../../state/slices/shared';
import { useDittoWrapper } from '../useDittoWrapper';
export const useTaskCategoriesSettings = () => {
    const dispatch = useAppDispatch();
    const { addModal, removeModal } = useModalContext();
    const { markerCategoryList, markerCategoryMap } = useAppSelector((state) => state.shared);
    const [categories, setCategories] = useState(markerCategoryList);
    const [operations, setOperations] = useState([]);
    const categoryNamePlaceholder = useDittoWrapper({ componentId: 'category-name' });
    const newCategoryNamePlaceholder = useDittoWrapper({ componentId: 'newcategory' });
    useEffect(() => {
        setCategories(markerCategoryList);
    }, [markerCategoryList]);
    function closeModal() {
        removeModal('DeleteModal');
        removeModal('GlobalSettingsModal');
    }
    function showTasksList() {
        dispatch(enableMapLayer('marker'));
        dispatch(setSelectedLayerId('marker'));
        dispatch(setAssetActiveTabKey('list'));
    }
    function saveTaskCategories() {
        const { match: updates, rest: creations } = biFilter(operations, (op) => op.id > 0);
        Promise.all(updates.map((update) => dispatch(updateMarkerCategoryTk(update))));
        Promise.all(creations.map((create) => dispatch(createMarkerCategoryTk({ color: create.color, name: create.name }))));
    }
    function handleNewOperation(update) {
        const operation = operations.find((op) => op.id === update.id);
        if (operation) {
            const newOperations = operations.filter((op) => op.id !== update.id);
            setOperations([...newOperations, Object.assign(Object.assign({}, operation), update)]);
        }
        else {
            const category = categories.find((category) => category.categoryId === update.id);
            if (category) {
                setOperations([
                    ...operations,
                    Object.assign({ name: category.categoryName || categoryNamePlaceholder, color: category.color }, update)
                ]);
            }
            else {
                setOperations([
                    ...operations,
                    {
                        id: update.id,
                        name: update.name || newCategoryNamePlaceholder,
                        color: update.color
                    }
                ]);
            }
        }
    }
    function handleTaskCategoriesSaveClick(callback) {
        saveTaskCategories();
        reset();
        callback === null || callback === void 0 ? void 0 : callback();
    }
    function handleTaskCategoriesCancelClick(callback) {
        if (operations.length > 0) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        saveTaskCategories();
                        reset();
                        callback === null || callback === void 0 ? void 0 : callback();
                    },
                    onCancel: () => {
                        reset();
                        callback === null || callback === void 0 ? void 0 : callback();
                    },
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        reset();
        callback === null || callback === void 0 ? void 0 : callback();
    }
    function handleCategorySelectColor(id, color) {
        const index = categories.findIndex((category) => category.categoryId === id);
        if (index === -1) {
            return;
        }
        const category = Object.assign(Object.assign({}, categories.at(index)), { color: color });
        const newCategories = [...categories];
        newCategories.splice(index, 1, category);
        setCategories(newCategories);
        handleNewOperation({
            id: category.categoryId,
            color: category.color
        });
    }
    function handleCategoryNameChange(id, name) {
        const index = categories.findIndex((category) => category.categoryId === id);
        if (index === -1) {
            return;
        }
        const category = Object.assign(Object.assign({}, categories.at(index)), { categoryName: name });
        const newCategories = [...categories];
        newCategories.splice(index, 1, category);
        setCategories(newCategories);
        handleNewOperation({
            id: category.categoryId,
            name: category.categoryName
        });
    }
    function handleCategoryDelete(id) {
        function handleLinkClick() {
            showTasksList();
            dispatch(setMarkerFilter(Object.assign(Object.assign({}, initialFilterMarker), { category: [id] })));
            closeModal();
        }
        if (id < 0) {
            const index = categories.findIndex((category) => category.categoryId === id);
            const newCategories = [...categories];
            newCategories.splice(index, 1);
            setCategories(newCategories);
        }
        else {
            const affectedCount = markerCategoryMap[id].count;
            addModal({
                id: 'DeleteModal',
                props: {
                    onConfirm: () => {
                        dispatch(deleteMarkerCategoryTk(id)).then(() => {
                            const index = categories.findIndex((category) => category.categoryId === id);
                            if (index === -1) {
                                return;
                            }
                            const newCategories = [...categories];
                            newCategories.splice(index, 1);
                            setCategories(newCategories);
                        });
                    },
                    title: _jsx(Ditto, { componentId: "promt.delete.category" }),
                    description: (_jsxs("div", { children: [_jsx(Ditto, { componentId: "prompt.delete.category.text" }), _jsx(DeleteModalInfo, { affectedCount: affectedCount, onLinkClick: handleLinkClick })] }))
                }
            });
        }
    }
    function handleCategoryCreate() {
        // All new categories have a negative id
        const id = Math.min(...categories.map((category) => category.categoryId), 0) - 1;
        const category = {
            categoryId: id,
            categoryName: '',
            color: CATEGORY_COLORS[0]
        };
        const newCategories = [...categories, category];
        setCategories(newCategories);
        handleNewOperation({
            id: id,
            name: category.categoryName,
            color: category.color
        });
    }
    function reset() {
        setCategories(markerCategoryList);
        setOperations([]);
    }
    return {
        categories,
        handleCategorySelectColor,
        handleCategoryNameChange,
        handleCategoryDelete,
        handleCategoryCreate,
        handleTaskCategoriesSaveClick,
        handleTaskCategoriesCancelClick
    };
};
