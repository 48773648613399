import { useAppSelector } from '../state/hooks';
import { useMarkerFileUpload } from './useMarkerFileUpload';
export const useMarkerFiles = () => {
    const { marker } = useAppSelector((state) => state.markerDetail);
    const { selectFilesForUpload } = useMarkerFileUpload();
    function handleFilesUpload(selectFolder) {
        if (marker) {
            selectFilesForUpload(marker.id, selectFolder);
        }
        else {
            selectFilesForUpload(-1, selectFolder);
        }
    }
    return { selectFilesForUpload: handleFilesUpload };
};
