// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VplOpGJzqD7F7Ff9lX4D{display:inline-flex;align-items:center;justify-content:center;min-width:45px;height:30px;border-radius:4px;font-weight:600;font-size:14px;color:var(--primary-black);padding:6px 8px}.NtbzI4KIiAVb1Ctm99jw{height:24px}.WQl6F6MZNgaEUh3xcjoW{min-width:auto;height:18px;border-radius:6px;padding:1px 5px}`, "",{"version":3,"sources":["webpack://./src/components/GradeBadge/GradeBadge.module.css"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,0BAAA,CACA,eAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,cAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".container {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    min-width: 45px;\n    height: 30px;\n    border-radius: 4px;\n    font-weight: 600;\n    font-size: 14px;\n    color: var(--primary-black);\n    padding: 6px 8px;\n}\n\n.container__small {\n    height: 24px;\n}\n\n.container__notification {\n    min-width: auto;\n    height: 18px;\n    border-radius: 6px;\n    padding: 1px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VplOpGJzqD7F7Ff9lX4D`,
	"container__small": `NtbzI4KIiAVb1Ctm99jw`,
	"container__notification": `WQl6F6MZNgaEUh3xcjoW`
};
export default ___CSS_LOADER_EXPORT___;
