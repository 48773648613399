export const DEBOUNCE_GEOCODE_API_MS = 150;
export const DEBOUNCE_MAIL_MS = 1000;
export const DEBOUNCE_FETCH_LIST_MS = 100;
export const DEBOUNCE_MAP_TILES_MS = 200;
export const DEBOUNCE_URL_UPDATES_MS = 1000;
export const DEBOUNCE_MAP_VIEW_STATE_MS = 1000;
export const DEBOUNCE_SHARED_FETCH = 20;
export const DEBOUNCE_USER_INPUT_MS = 1000;
export const PRINT_WINDOW_DELAY = 500; // ms
export const DEBOUNCE_SEARCH = 200;
export const DEBOUNCE_FILTER_MIXPANEL = 1000;
export const DELAY_DISABLE_DRAG = 500;
export const DELAY_DISABLE_ROTATE = 500;
export const FILE_MANAGER_DURATION_SECONDS_MAX = 600000;
export const DELAY_SET_ACTIVE_LAYER_RESTORED = 1000;
export const DETAIL_PANEL_TRANSITION_DURATION_MS = 500;
