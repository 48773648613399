// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GuTcbFzgcVHowYVpzTKH{display:flex;flex-direction:column;align-items:center;position:absolute}.XfmeOwYl4UzlYHecszep{height:20px;width:8px;z-index:0}.QaKTHQCR7iUUUB4E8q0b{height:35px;width:35px;z-index:1}.P0Nivt4HmnGw0bpSiqXc .QaKTHQCR7iUUUB4E8q0b{transform:translate(0.5px, 6px)}.UHinpO24P2dMWD7v5xKd{position:absolute;top:-6px;right:-14px;z-index:2;display:flex;background:var(--primary-white);border:1px solid var(--grey-2);border-radius:4px}.kMLTqLf1BzMd9O37sqMp{position:absolute;top:42px}`, "",{"version":3,"sources":["webpack://./src/components/TrafficSignMarker/TrafficSignMarker.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,sBACI,WAAA,CACA,SAAA,CACA,SAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,SAAA,CAGJ,4CACI,+BAAA,CAGJ,sBACI,iBAAA,CACA,QAAA,CACA,WAAA,CACA,SAAA,CACA,YAAA,CACA,+BAAA,CACA,8BAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,QAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: absolute;\n}\n\n.pole-image {\n    height: 20px;\n    width: 8px;\n    z-index: 0;\n}\n\n.sign-image {\n    height: 35px;\n    width: 35px;\n    z-index: 1;\n}\n\n.print .sign-image {\n    transform: translate(0.5px, 6px);\n}\n\n.damage {\n    position: absolute;\n    top: -6px;\n    right: -14px;\n    z-index: 2;\n    display: flex;\n    background: var(--primary-white);\n    border: 1px solid var(--grey-2);\n    border-radius: 4px;\n}\n\n.rotator {\n    position: absolute;\n    top: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GuTcbFzgcVHowYVpzTKH`,
	"pole-image": `XfmeOwYl4UzlYHecszep`,
	"sign-image": `QaKTHQCR7iUUUB4E8q0b`,
	"print": `P0Nivt4HmnGw0bpSiqXc`,
	"damage": `UHinpO24P2dMWD7v5xKd`,
	"rotator": `kMLTqLf1BzMd9O37sqMp`
};
export default ___CSS_LOADER_EXPORT___;
