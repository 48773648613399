import { useState } from 'react';
import { checkRequired } from '../../common/validation/required';
import { useAppDispatch } from '../../state/hooks';
import { addNotificationMessage } from '../../state/slices/notification';
export function useAssetFields(initialAssetFields) {
    const dispatch = useAppDispatch();
    const assetFieldsInitialState = Object.assign({ title: '', notes: '', images: [], attributes: [] }, initialAssetFields);
    const [assetFields, setAssetFields] = useState(assetFieldsInitialState);
    const isReadyToSubmit = !!assetFields.typeId;
    function getAssetDraft() {
        const draft = {
            title: assetFields.title,
            inventoryTypeId: assetFields.typeId,
            attributes: assetFields.attributes,
            notes: assetFields.notes
        };
        const missingFields = checkRequired(draft, ['inventoryTypeId']);
        if (missingFields.length > 0) {
            dispatch(addNotificationMessage({
                type: 'error',
                title: `Required: ${missingFields.join(', ')}`
            }));
            return;
        }
        return draft;
    }
    function updateAssetFields(update) {
        setAssetFields(Object.assign(Object.assign({}, assetFields), update));
    }
    function updateAttributes(attr) {
        const oldAttr = assetFields.attributes.find((item) => item.inventoryTypeAttributeId === attr.inventoryTypeAttributeId);
        if (!oldAttr) {
            updateAssetFields({
                attributes: [...assetFields.attributes, attr]
            });
            return;
        }
        updateAssetFields({
            attributes: assetFields.attributes.map((a) => a.inventoryTypeAttributeId === attr.inventoryTypeAttributeId ? attr : a)
        });
    }
    function clearAssetFields() {
        setAssetFields(assetFieldsInitialState);
    }
    return {
        assetFields,
        updateAssetFields,
        getAssetDraft,
        updateAttributes,
        clearAssetFields,
        isReadyToSubmit
    };
}
