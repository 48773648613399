// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.knSEAq5YfBEK4M6XIP8d{opacity:.4}.DANNkoo9F5sl04s4Rwnq{opacity:1}.DANNkoo9F5sl04s4Rwnq>*:not(:last-child){border-right:1px solid rgba(196,196,196,.3);padding-right:4px}.DANNkoo9F5sl04s4Rwnq>*:not(:first-child){border-right:1px solid rgba(196,196,196,.3);padding-left:4px}.qdDY2ICpRe4qWoNpyhL_{color:var(--primary-black, #000);font-feature-settings:"clig" off,"liga" off;font-weight:600;display:flex;align-items:center;gap:4px;padding:6px;height:16px}.csPpN2U7zoj_2TXzTgm0{display:flex;align-items:center;margin-left:-4px}.csPpN2U7zoj_2TXzTgm0>*:not(:last-child){border-right:1px solid rgba(196,196,196,.3)}`, "",{"version":3,"sources":["webpack://./src/components/AppliedFilterBar/FilterPreviewMany/FilterPreviewMany.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,SAAA,CAGJ,yCACI,2CAAA,CACA,iBAAA,CAGJ,0CACI,2CAAA,CACA,gBAAA,CAGJ,sBACI,gCAAA,CACA,2CAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CACA,WAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,gBAAA,CAGJ,yCACI,2CAAA","sourcesContent":[".label {\n    opacity: 0.4;\n}\n\n.value {\n    opacity: 1;\n}\n\n.value > *:not(:last-child) {\n    border-right: 1px solid rgba(196, 196, 196, 0.3);\n    padding-right: 4px;\n}\n\n.value > *:not(:first-child) {\n    border-right: 1px solid rgba(196, 196, 196, 0.3);\n    padding-left: 4px;\n}\n\n.block {\n    color: var(--primary-black, #000);\n    font-feature-settings: 'clig' off, 'liga' off;\n    font-weight: 600;\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    padding: 6px;\n    height: 16px;\n}\n\n.container {\n    display: flex;\n    align-items: center;\n    margin-left: -4px;\n}\n\n.container > *:not(:last-child) {\n    border-right: 1px solid rgba(196, 196, 196, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `knSEAq5YfBEK4M6XIP8d`,
	"value": `DANNkoo9F5sl04s4Rwnq`,
	"block": `qdDY2ICpRe4qWoNpyhL_`,
	"container": `csPpN2U7zoj_2TXzTgm0`
};
export default ___CSS_LOADER_EXPORT___;
