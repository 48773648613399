import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getPlanStatusLabel, getPlanStatusVignette } from '../../../common/convert/plan';
import { FormInputCurrentSelectedValue } from '../../FormInputSelect/FormInputSelect';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
export function StatusSelectItem({ status: { statusId }, isChecked, onClick }) {
    return (_jsx(SelectboxEntry, { leadingIcon: getPlanStatusVignette(statusId), text: getPlanStatusLabel(statusId), onClick: onClick, isChecked: isChecked, "data-test": `dropdown-status-${statusId}` }));
}
export function StatusSelectItemCurrentSelectedValue({ status: { statusId }, onClick }) {
    return (_jsx(FormInputCurrentSelectedValue, { icon: getPlanStatusVignette(statusId), text: getPlanStatusLabel(statusId), onClick: onClick }));
}
