var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { LOCALE } from '../../common/consts/locale';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getPaginationParams, getTimeParams } from '../../common/utils/params';
import { getFilterParams, getSignsList, getSignsListCount, getSignTree, getSortParams } from '../../services/sign';
import { addNotificationMessage } from './notification';
export const signInitialFilter = {
    damageTypeIds: [],
    signTypeIds: [],
    signStatuses: [],
    filesAttached: [],
    filesNotAttached: [],
    properties: []
};
const signsParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.sign;
const initialState = {
    resultList: [],
    totalCountList: 0,
    // TODO: (suggestion) switch to same method as tasks/assets to improve performance
    firstThousandIds: [],
    resultFetchStatus: 'idle',
    folderListFetchStatus: 'idle',
    filter: Object.assign(Object.assign({}, signInitialFilter), signsParams === null || signsParams === void 0 ? void 0 : signsParams.filter),
    sort: (signsParams === null || signsParams === void 0 ? void 0 : signsParams.sort) || 'date-desc',
    selectedIdList: [],
    currentPage: PAGE_NUMBER_FIRST,
    activeTabKey: 'filter',
    isMultiSelectOn: false
};
export const fetchSignList = createDebouncedAsyncThunk('leftPanelSign/list', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { useMapBounds, currentPage, filter, sort } = state.leftPanelSign;
    const { bounds } = state.mainMap;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const res = yield getSignsList({
        params: {
            pagination: getPaginationParams(currentPage),
            filter: getFilterParams(filter),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            timepicker: getTimeParams(selectedOption)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchSignListCount = createDebouncedAsyncThunk('leftPanelSign/listCount', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { useMapBounds, filter } = state.leftPanelSign;
    const { bounds } = state.mainMap;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const res = yield getSignsListCount({
        params: {
            filter: getFilterParams(filter),
            bbox: getBBoxParams(bounds, useMapBounds),
            timepicker: getTimeParams(selectedOption)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchSignTree = createDebouncedAsyncThunk('leftPanelSign/signTree', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const res = yield getSignTree({
        timepicker: getTimeParams(selectedOption),
        locale: LOCALE.de // NOTE: Fixed to DE while signs in other countries are not available
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const leftPanelSignSlice = createSlice({
    name: 'leftPanelSign',
    initialState,
    reducers: {
        reset: () => initialState,
        resetSignFilter: (state) => {
            state.filter = signInitialFilter;
        },
        setSignFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setSignSelectedIdList: (state, action) => {
            state.selectedIdList = action.payload;
        },
        startSignMultiSelect: (state) => {
            state.isMultiSelectOn = true;
        },
        resetSignMultiSelect: (state) => {
            state.isMultiSelectOn = false;
            state.selectedIdList = [];
        },
        setSignUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        setSignActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setSignCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setSignSorting: (state, action) => {
            state.sort = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSignList.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchSignList.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.signs;
                state.firstThousandIds = action.payload.result.firstThousandIds;
            }
        })
            .addCase(fetchSignListCount.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.totalCountList = action.payload.result.totalCount;
            }
        });
    }
});
export const { reset: resetLeftPanelSign, resetSignFilter, setSignSelectedIdList, setSignUseMapBounds, setSignActiveTabKey, setSignCurrentPage, setSignFilter, setSignSorting, resetSignMultiSelect, startSignMultiSelect } = leftPanelSignSlice.actions;
export default leftPanelSignSlice.reducer;
