var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { addLazyLoadedImage, getMapImages } from '../common/mapbox';
import { createDynamicIcon, generatedIconPrefix } from '../common/mapbox/dynamicIcon';
import { useAppSelector } from '../state/hooks';
export function useMapInit() {
    const { locale } = useAppSelector((state) => state.app);
    return useCallback((map) => {
        if (!map) {
            return;
        }
        // Translate map labels
        const language = new MapboxLanguage({
            defaultLanguage: locale
        });
        map.addControl(language);
        // Set up map icon lazy loading
        const onStyleImageMissing = (e) => __awaiter(this, void 0, void 0, function* () {
            if (!e.id) {
                return;
            }
            if (e.id.startsWith(generatedIconPrefix)) {
                const dynamicIcon = createDynamicIcon(e.id);
                map.addImage(e.id, dynamicIcon());
                return;
            }
            // Add placeholder image so we don't get mapbox warnings
            map.addImage(e.id, { width: 0, height: 0, data: new Uint8Array() });
            const imageObj = getMapImages([e.id])[0];
            yield addLazyLoadedImage(map.getMap(), imageObj.image, imageObj.file);
        });
        map.on('styleimagemissing', onStyleImageMissing);
        return () => {
            map.off('styleimagemissing', onStyleImageMissing);
        };
    }, [locale]);
}
