import polyline from '@mapbox/polyline';
import config from '../../config/config.json';
import { MAP_STATIC_HEIGHT, MAP_STATIC_WIDTH, MAP_STYLE_ID, PRINT_MAP_ZOOM } from '../consts/map';
const DEFAULT_LINE_WIDTH = 5;
/**
 *  https://docs.mapbox.com/api/maps/static-images/
 */
export function getMapboxStaticImageURL({ lon, lat, zoom = PRINT_MAP_ZOOM, iconURL, height = MAP_STATIC_HEIGHT, width = MAP_STATIC_WIDTH, geojson }) {
    let geojsonArray;
    if (geojson) {
        geojsonArray = geojson.features.map((feature) => {
            const { geometry, properties } = feature;
            let style = '';
            let coordinates;
            if (geometry.type === 'LineString' && (properties === null || properties === void 0 ? void 0 : properties.color)) {
                const color = properties.color.replace('#', '');
                const lineWidth = properties.lineWidth || DEFAULT_LINE_WIDTH;
                style = `path-${lineWidth}+${color}`;
                coordinates = polyline.fromGeoJSON(geometry);
            }
            return style ? `${style}(${coordinates})` : `geojson(${JSON.stringify(geometry)})`;
        });
        const bbox = getBoundingBox(geojson);
        const center = getCenter(bbox);
        lat = center[0];
        lon = center[1];
        zoom = Math.min(PRINT_MAP_ZOOM, getZoomLevel(bbox) - 2);
    }
    return `https://api.mapbox.com/styles/v1/${MAP_STYLE_ID}/static${iconURL ? `/url-${encodeURIComponent(iconURL)}(${lon},${lat})` : ''}${geojsonArray ? `/${geojsonArray.join(',')}` : ''}/${lat},${lon},${zoom},0,0/${width}x${height}?access_token=${config.mapbox.token}`;
}
const getBoundingBox = (geojson) => {
    let minLat = Infinity;
    let minLng = Infinity;
    let maxLat = -Infinity;
    let maxLng = -Infinity;
    const setMinMaxCoordinates = (coord) => {
        const [lng, lat] = coord;
        minLat = Math.min(minLat, lat);
        minLng = Math.min(minLng, lng);
        maxLat = Math.max(maxLat, lat);
        maxLng = Math.max(maxLng, lng);
    };
    const processCoordinates = (coords) => {
        coords.forEach((coord) => {
            if (Array.isArray(coord[0])) {
                processCoordinates(coord);
            }
            else {
                setMinMaxCoordinates(coord);
            }
        });
    };
    geojson.features.forEach((feature) => {
        const { geometry } = feature;
        if (geometry.type === 'Point') {
            setMinMaxCoordinates(geometry.coordinates);
        }
        else if (geometry.type !== 'GeometryCollection') {
            processCoordinates(geometry.coordinates);
        }
    });
    return [
        [minLng, minLat],
        [maxLng, maxLat]
    ];
};
const getCenter = (bbox) => {
    const [minLng, minLat] = bbox[0];
    const [maxLng, maxLat] = bbox[1];
    const centerLng = (minLng + maxLng) / 2;
    const centerLat = (minLat + maxLat) / 2;
    return [centerLng, centerLat];
};
const getZoomLevel = (bbox) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;
    const latDiff = bbox[1][1] - bbox[0][1];
    const lngDiff = bbox[1][0] - bbox[0][0];
    const latZoom = Math.log2(WORLD_DIM.height / latDiff);
    const lngZoom = Math.log2(WORLD_DIM.width / lngDiff);
    return Math.min(Math.min(latZoom, lngZoom), ZOOM_MAX);
};
