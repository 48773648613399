import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Plowing, Salting, SaltingAndPlowing } from '../../components/icons';
import { WINTER_SERVICE_TYPE_ID } from '../consts/winterService';
export function WinterServiceIcon({ type }) {
    switch (type) {
        case WINTER_SERVICE_TYPE_ID.salting:
            return _jsx(Salting, {});
        case WINTER_SERVICE_TYPE_ID.plowing:
            return _jsx(Plowing, {});
        case WINTER_SERVICE_TYPE_ID.saltingAndPlowing:
            return _jsx(SaltingAndPlowing, {});
        default:
            return null;
    }
}
export function getWinterServiceTypeLabel(type) {
    switch (type) {
        case WINTER_SERVICE_TYPE_ID.salting:
            return _jsx(Ditto, { componentId: "winterservice.salting" });
        case WINTER_SERVICE_TYPE_ID.plowing:
            return _jsx(Ditto, { componentId: "winterservice.plowing" });
        case WINTER_SERVICE_TYPE_ID.saltingAndPlowing:
            return (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "winterservice.salting" }), " +", ' ', _jsx(Ditto, { componentId: "winterservice.plowing" })] }));
        case WINTER_SERVICE_TYPE_ID.notSpecified:
            return _jsx(Ditto, { componentId: "notspecified" });
        default:
            return null;
    }
}
