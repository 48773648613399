import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ProductFruits } from 'react-product-fruits';
import config from '../../config/config.json';
import { useAppSelector } from '../../state/hooks';
export function ProductFruitsWrapper() {
    const { locale } = useAppSelector((state) => state.app);
    const { userAccount } = useAppSelector((state) => state.auth);
    return (_jsx(_Fragment, { children: config.productFruits.enable && (userAccount === null || userAccount === void 0 ? void 0 : userAccount.uuid) && (_jsx(ProductFruits, { workspaceCode: config.productFruits.code, language: locale, user: { username: userAccount.uuid } })) }));
}
