var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MB } from '../common/consts/data';
import { uploadFile } from '../services/fileManager';
export const useFileUpload = () => {
    return {
        selectFilesDialog,
        uploadFile: uploadFile
    };
};
const createFileInput = ({ multiple, accept }) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', accept.join(','));
    input.setAttribute('multiple', String(multiple !== null && multiple !== void 0 ? multiple : false));
    return input;
};
const selectFilesDialog = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const DEFAULT_SIZE_LIMIT = 10 * MB;
    const multiple = options.fileCountLimit ? options.fileCountLimit > 1 : true;
    const input = createFileInput({
        multiple,
        accept: options.accept
    });
    const promise = new Promise((resolve) => {
        const listener = () => {
            var _a;
            const files = Array.from((_a = input.files) !== null && _a !== void 0 ? _a : []);
            const valid = files.every((file) => {
                var _a, _b, _c;
                return file.size <= ((_a = options.sizeLimit) !== null && _a !== void 0 ? _a : DEFAULT_SIZE_LIMIT) &&
                    options.accept.includes((_c = '.' + ((_b = file.name.split('.').at(-1)) === null || _b === void 0 ? void 0 : _b.toLowerCase())) !== null && _c !== void 0 ? _c : '');
            });
            if (!valid) {
                if (options.resolveErrorWithDescription) {
                    const largeFile = files.find((file) => { var _a; return file.size > ((_a = options.sizeLimit) !== null && _a !== void 0 ? _a : DEFAULT_SIZE_LIMIT); });
                    const invalidTypeFile = files.find((file) => {
                        var _a, _b;
                        return !options.accept.includes((_b = '.' + ((_a = file.name.split('.').at(-1)) === null || _a === void 0 ? void 0 : _a.toLowerCase())) !== null && _b !== void 0 ? _b : '');
                    });
                    if (largeFile) {
                        return resolve({
                            error: true,
                            file: largeFile.name
                        });
                    }
                    if (invalidTypeFile) {
                        return resolve({
                            error: true,
                            file: invalidTypeFile.name,
                            invalidFormat: true
                        });
                    }
                }
                return resolve(null);
            }
            return resolve(files);
        };
        input.addEventListener('change', listener);
        input.click();
    });
    return promise;
});
