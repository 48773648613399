import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ALERT_COLOR } from '../common/color';
import { getAlertIcon } from '../common/convert/alert';
import { getLevelIcon, getObjectLabelColor, getObjectLevelLabel, getObjectTranslation } from '../common/convert/object';
import { useAppSelector } from '../state/hooks';
export function useObjectAlertDetails({ feature }) {
    const { teamAccount } = useAppSelector((state) => state.auth);
    const showSpecifiedClasses = (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.manHoleStormDrainSpecifiedClassesEnabled) === true;
    const { alertTypes } = useAppSelector((state) => state.shared);
    if (!feature || !feature.value || !('detected_objects' in feature.value)) {
        return;
    }
    const typeItems = [];
    const statusItems = [];
    for (const detectedObject of feature.value.detected_objects) {
        typeItems.push({ content: getObjectTranslation(detectedObject) });
        if (showSpecifiedClasses) {
            statusItems.push({
                content: getObjectLevelLabel(detectedObject),
                icon: getLevelIcon(detectedObject),
                color: getObjectLabelColor(detectedObject)
            });
        }
    }
    if (feature.type === 'point' || feature.type === 'alert' || feature.type === 'object') {
        for (const alert of feature.value.recordAlerts) {
            const alertType = alertTypes.find((t) => t.typeId === alert.typeId);
            if (!alertType) {
                continue;
            }
            // Don't duplicate alerts that are based on objects
            if (feature.value.detected_objects.includes(alertType.typeName)) {
                continue;
            }
            statusItems.push({
                content: _jsx(Ditto, { componentId: `filter.damagetype.${alertType.typeName}` }),
                color: ALERT_COLOR,
                icon: getAlertIcon(alertType.typeName)
            });
        }
    }
    return { typeItems, statusItems };
}
