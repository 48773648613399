// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bTVjwdNWQDimWV9H6yL9{padding:16px}.onfazWdAA8pz4PqYRqnw{display:flex;align-items:center;gap:6px;margin-bottom:10px;font-size:16px;line-height:20px;font-weight:600}.IjYIHn4SfWGeM1Cu1A7X{margin-bottom:10px;font-size:14px;font-weight:400;line-height:18px}.uNxkUsqBwyWMCe5Vpa47{display:flex;gap:6px}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsCallToAction/DetailsCallToAction.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAGJ,sBACI,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAGJ,sBACI,YAAA,CACA,OAAA","sourcesContent":[".container {\n    padding: 16px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    margin-bottom: 10px;\n    font-size: 16px;\n    line-height: 20px;\n    font-weight: 600;\n}\n\n.content {\n    margin-bottom: 10px;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 18px;\n}\n\n.buttons {\n    display: flex;\n    gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bTVjwdNWQDimWV9H6yL9`,
	"title": `onfazWdAA8pz4PqYRqnw`,
	"content": `IjYIHn4SfWGeM1Cu1A7X`,
	"buttons": `uNxkUsqBwyWMCe5Vpa47`
};
export default ___CSS_LOADER_EXPORT___;
