import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DataUpdateModal } from '../../components/Modals/DataUpdateModal/DataUpdateModal';
import { useModalContext } from '../../state/context';
export function DataUpdateModalContainer({ onDeclineClick, onUpdateAllClick, onLaterClick }) {
    const { removeModal } = useModalContext();
    function handleClose() {
        removeModal('DataUpdateModalContainer');
    }
    function handleDeclineClick() {
        onDeclineClick === null || onDeclineClick === void 0 ? void 0 : onDeclineClick();
        handleClose();
    }
    function handleUpdateAllClick() {
        onUpdateAllClick === null || onUpdateAllClick === void 0 ? void 0 : onUpdateAllClick();
        handleClose();
    }
    function handleLaterClick() {
        onLaterClick === null || onLaterClick === void 0 ? void 0 : onLaterClick();
        handleClose();
    }
    return (_jsx(DataUpdateModal, { onDeclineClick: handleDeclineClick, onUpdateAllClick: handleUpdateAllClick, onLaterClick: handleLaterClick }));
}
