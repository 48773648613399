import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { pointToMarkerSource } from '../../common/convert';
import { LAYER_PATH } from '../../common/layer';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { Assets, Plus, Task } from '../../components/icons';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetAssetCreator, setAssetCreatorSource, setAssetCreatorType } from '../../state/slices/assetCreator';
import { fetchPointGeoJson } from '../../state/slices/conditionDetail';
import { fetchPointImgData, resetImageViewerCondition } from '../../state/slices/imageViewerCondition';
import { resetMarkerCreator, setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { fetchConditionNavigation } from '../../state/slices/navigation';
import { Button } from '../../uikit/Button/Button';
import { AssetCreatorContainer } from '../AssetCreatorContainer/AssetCreatorContainer';
import { ConditionDetailContainer } from '../ConditionDetailContainer';
import { RightPanelMarkerCreatorContainer } from '../RightPanelMarkerCreatorContainer/RightPanelMarkerCreatorContainer';
export function ImageViewerObjectContainer() {
    const dispatch = useAppDispatch();
    const { removeModal, modals } = useModalContext();
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { feature } = useAppSelector((state) => state.conditionDetail);
    const { imageURL } = useAppSelector((state) => state.imageViewerCondition);
    const { targetSchema } = useAppSelector((state) => state.auth);
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    const pointInfo = feature.type === 'point' ? feature.value : undefined;
    useEffect(() => {
        if (!feature || (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.type) !== 'object') {
            return;
        }
        else if (feature.type === 'point') {
            dispatch(fetchPointImgData(feature.value.systems_point_id));
            dispatch(fetchPointGeoJson(feature.value.point_id));
            dispatch(fetchConditionNavigation(feature.value.systems_point_id));
        }
    }, [dispatch, feature, selectedFeature]);
    function handleClickClose() {
        dispatch(resetImageViewerCondition());
        removeModal('ImageViewerObject');
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'ImageViewerObject') {
            return;
        }
        handleClickClose();
    }
    function handleAddMarkerButtonClick() {
        if (!pointInfo) {
            return;
        }
        dispatch(setMarkerCreatorPointSource(pointToMarkerSource(pointInfo)));
    }
    function handleAddAssetButtonClick() {
        if (!pointInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.createAssetFromImageViewer, {
            [MIXPANEL_EVENT_PROPERTY.layerName]: LAYER_PATH.object
        });
        dispatch(setAssetCreatorType('right-panel'));
        dispatch(setAssetCreatorSource({ point: pointInfo }));
    }
    function handleBackButtonClick(type) {
        if (type === 'marker') {
            dispatch(resetMarkerCreator());
        }
        else if (type === 'asset') {
            dispatch(resetAssetCreator());
        }
    }
    const topButtonsCreate = (_jsx(FloatingDropdownWrapper, { control: _jsx(Button, { color: "dark", variant: "square", icon: _jsx(Plus, {}) }), topOffset: 4, menu: _jsxs(ActionModal, Object.assign({ extraRounded: true }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "createmarker" }), leadingIcon: _jsx(Task, {}), onClick: handleAddMarkerButtonClick, size: "medium", extraRounded: true }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "actionmodal.createinventory" }), leadingIcon: _jsx(Assets, {}), onClick: handleAddAssetButtonClick, size: "medium", extraRounded: true })] })) }));
    if (markerCreatorSource) {
        return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Condition", singleImage: renderImage(), onBackButtonClick: () => handleBackButtonClick('marker'), onDoneClick: handleClickOutside }), creator: _jsx(RightPanelMarkerCreatorContainer, {}) }) }));
    }
    if (assetCreatorSource) {
        return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Condition", singleImage: renderImage(), onBackButtonClick: () => handleBackButtonClick('asset'), onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate }), creator: _jsx(AssetCreatorContainer, {}) }));
    }
    if (!targetSchema || !pointInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Condition", onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate, singleImage: renderImage(), details: pointInfo && _jsx(ConditionDetailContainer, { variant: "image-viewer" }) }) }));
    function renderImage() {
        if (imageURL)
            return _jsx("img", { src: imageURL });
        return _jsx(_Fragment, {});
    }
}
