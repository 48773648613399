import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useAppSelector } from '../../../state/hooks';
import { MINIMAP_DEFAULT_ZOOM } from '../../consts/map';
import { toFeatureIdentifier } from '../convert';
import { safeRemoveFeatureState, safeSetFeatureState } from '../features';
import { highlightState, hoverState, selectedState } from '../featureStates';
import { DEFAULT_SOURCE_LAYER } from '../settings';
import { POINTS_SOURCE_ID } from '../sources';
export function ImageViewerMinimapController() {
    const { current: map } = useMap();
    const { selectedFeature, highlightedFeature, hoveredFeature } = useAppSelector((state) => state.feature);
    const { feature } = useAppSelector((state) => state.conditionDetail);
    const isStyleLoaded = map === null || map === void 0 ? void 0 : map.isStyleLoaded();
    const pointInfo = feature.type === 'point' ? feature.value : undefined;
    if (map && selectedFeature) {
        map.on('load', () => {
            const featureIdentifier = toFeatureIdentifier(selectedFeature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, selectedState);
            }
        });
    }
    useEffect(() => {
        if (!map || !pointInfo) {
            return;
        }
        const coords = pointInfo.coordinates;
        if (coords.length < 2) {
            return;
        }
        map.flyTo({
            center: [coords[0], coords[1]],
            zoom: MINIMAP_DEFAULT_ZOOM,
            duration: 500
        });
    }, [map, pointInfo]);
    useEffect(() => {
        if (map === undefined || !isStyleLoaded) {
            return;
        }
        safeRemoveFeatureState(map, {
            source: POINTS_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        if (hoveredFeature) {
            const featureIdentifier = toFeatureIdentifier(hoveredFeature.feature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, hoverState);
            }
        }
        if (highlightedFeature) {
            const featureIdentifier = toFeatureIdentifier(highlightedFeature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, highlightState);
            }
        }
        if (selectedFeature) {
            const featureIdentifier = toFeatureIdentifier(selectedFeature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, selectedState);
            }
        }
    }, [selectedFeature, map, highlightedFeature, hoveredFeature, isStyleLoaded]);
    return _jsx(_Fragment, {});
}
