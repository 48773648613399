var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { v4 } from 'uuid';
import { MB } from './consts/data';
import { formatBytes } from './utils/data';
export function getBase64FromBlob(image) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = function () {
                const base64 = reader.result;
                if (typeof base64 === 'string') {
                    return res(base64);
                }
                rej(new Error('FileReader returned non-string result'));
            };
            reader.onerror = rej;
            reader.readAsDataURL(image);
        });
    });
}
function createImageInput({ multiple, accept } = {}) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', accept || 'image/*');
    if (multiple) {
        input.setAttribute('multiple', 'true');
    }
    input.setAttribute('value', '');
    input.style.display = 'none';
    input.style.visibility = 'hidden';
    input.style.width = '0';
    input.style.height = '0';
    // Some browsers do not work reliably without appending
    // the element to the body. For example, Safari on iOS (iPad)
    // does not work every time without this line.
    document.body.appendChild(input);
    return input;
}
function destroyImageInput(input) {
    document.body.removeChild(input);
}
function validateFiles(sizeLimit, files) {
    const errors = [];
    for (const file of files) {
        if (file.size > sizeLimit) {
            errors.push({
                type: 'files_over_size',
                value: { size: formatBytes(file.size), maxSize: formatBytes(sizeLimit) },
                fileName: file.name
            });
        }
    }
    return errors;
}
const DEFAULT_SIZE_LIMIT = 10 * MB;
export function selectImagesDialog(options = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((res, rej) => {
            const input = createImageInput({
                multiple: options.fileLimit ? options.fileLimit > 1 : true
            });
            const listener = () => {
                var _a;
                const images = Array.from((_a = input.files) !== null && _a !== void 0 ? _a : []);
                const errors = validateFiles(options.sizeLimit || DEFAULT_SIZE_LIMIT, images);
                if (errors.length > 0) {
                    return rej(errors);
                }
                const process = () => __awaiter(this, void 0, void 0, function* () {
                    const base64 = yield Promise.all(images.map((img) => getBase64FromBlob(img)));
                    const processedImages = base64.map((b64, idx) => ({
                        photoId: v4(),
                        url: URL.createObjectURL(images[idx]),
                        base64: b64,
                        file: images[idx]
                    }));
                    res({ processedImages, images });
                });
                process();
                destroyImageInput(input);
            };
            input.addEventListener('change', listener, { once: true });
            input.click();
        });
    });
}
