var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { roadSafetyClient } from '../openapi';
export const NOT_SPECIFIED_ASSIGNEE_ID = 'x';
export const NOT_SPECIFIED_CATEGORY_ID = 0;
// NOTE: services layer will be used to combine reqeusts,
//  or chaining requests which intermediate states does not needs to be saved
export function addMarkerPhotos(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceAddMarkerPhotos(body);
    });
}
export function updateMarkerStatus(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceSetMarkerStatus(body);
    });
}
export function updateMarkerPriority(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceSetMarkerPriority(body);
    });
}
export function setMarkerCategory(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceSetMarkerCategory(body);
    });
}
export function updateMarkerDescription(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceSetMarkerDescription(body);
    });
}
export function getMarkerFilters(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersFilters(body);
    });
}
export function getMarkerList(body) {
    return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersList(body);
}
export function getMarkerIdList(body) {
    return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersListOfIds(body);
}
export function setMarkerDueDate(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceSetMarkerDueDate(body);
    });
}
export function updateTaskAssignees(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerUpdateAssignees(body);
    });
}
export function updateTaskTitle(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerUpdateTitle(body);
    });
}
export function batchUpdateMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceBatchUpdateMarker(body);
    });
}
export function batchDeleteMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerBatchDelete(body);
    });
}
export function updateAddress(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerUpdateAddress(body);
    });
}
export function createMarkerBatch(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerBatchCreate(body);
    });
}
export function exportMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersExportByEmail(body);
    });
}
export function getMarker(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerById({ id: id });
    });
}
export function getMarkersByIdList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersByIDs(body);
    });
}
export function createMarkerCategory(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceCreateCategory(body);
    });
}
export function updateMarkerCategory(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceUpdateCategory(body);
    });
}
export function deleteMarkerCategory(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceDeleteCategory({ id });
    });
}
export function removeMarkerPhoto(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceRemoveMarkerPhotos(body);
    });
}
export function getMarkerGeoJSON(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersGeoJson(body);
    });
}
export function closeMarker(token) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceCloseMarker({
            encryptParams: token
        });
    });
}
export function getSortParams(id) {
    switch (id) {
        case 'date-asc':
            return { sortBy: 'created_at', sortOrder: 'asc' };
        case 'date-dec':
            return { sortBy: 'created_at', sortOrder: 'desc' };
    }
}
export function getFilterParams(filter) {
    return {
        search: filter.search,
        statusIds: filter.statusId,
        categoryIds: filter.category,
        priorityTypeIds: filter.priorityId,
        assignees: filter.assignee.length === 0
            ? undefined
            : {
                uuids: filter.assignee.filter((i) => i !== NOT_SPECIFIED_ASSIGNEE_ID),
                showNotSpecified: !!filter.assignee.find((i) => i === NOT_SPECIFIED_ASSIGNEE_ID)
            },
        intervalIds: filter.interval,
        dueDates: {
            typeId: filter.dueDateId
        },
        filesAttached: filter.filesAttached,
        filesNotAttached: filter.filesNotAttached
    };
}
export function getLinkedDefaultMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersLinkedListDefault(body);
    });
}
export function getLinkedClosedMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersLinkedListClosed(body);
    });
}
export function getLightMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersLightList(body);
    });
}
export function getLightLinkedMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersLightLinkedList(body);
    });
}
export function getSearchMarkers(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerSearch(body);
    });
}
export function linkMarkersToAssets(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceBulkLinkMarkerToManualInventories(body);
    });
}
export function unlinkMarkerFromAsset(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield roadSafetyClient.roadSafetyService.roadSafetyServiceUnlinkMarkerFromManualInventory(body);
    });
}
export function saveMarkerMailChangelog(body) {
    return roadSafetyClient.roadSafetyService.roadSafetyServiceSaveSentEmailChangelog(body);
}
export function getMarkerAttributeSchemaList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerAttributeSchemaList(body);
    });
}
export function saveMarkerAttributeSchema(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerAttributeSchemaBatchCreate(body);
    });
}
export function deleteMarkerAttributeSchema(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerAttributeSchemaBatchDelete(body);
    });
}
export function deleteMarkerAttributeSchemaSelectOption(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerAttributeSchemaSelectOptionsBatchDelete(body);
    });
}
export function saveMarkerAttribute(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerSaveAttribute(body);
    });
}
export function getMarkerFileFoldersList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersFoldersList(body);
    });
}
export function createMarkerFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersFoldersCreate(body);
    });
}
export function renameMarkerFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersFoldersRename(body);
    });
}
export function rearrangeMarkerFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersFoldersRearrange(body);
    });
}
export function deleteMarkerFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkersFoldersDelete(body);
    });
}
export function addMarkerFiles(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerAddFiles(body);
    });
}
export function removeMarkerFiles(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerRemoveFiles(body);
    });
}
export function markerTimelogBatchCreate(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerTimelogBatchCreate(body);
    });
}
export function markerTimelogUpdate(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerTimelogUpdate(body);
    });
}
export function markerTimelogDelete(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return roadSafetyClient.roadSafetyService.roadSafetyServiceMarkerTimelogDelete(body);
    });
}
