var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Close } from '../../components/icons';
import { Modal } from '../../components/Modal/Modal';
import { YearPickerContent } from '../../components/YearPickerContent/YearPickerContent';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
import { Button } from '../../uikit/Button/Button';
export function YearPickerModalContainer(_a) {
    var { title, onConfirm } = _a, props = __rest(_a, ["title", "onConfirm"]);
    const { removeModal } = useModalContext();
    const { locale } = useAppSelector((state) => state.app);
    const { teamAccount } = useAppSelector((state) => state.auth);
    function handleCloseClick(event) {
        // fix for the unexpected close issue when the tooltip is clicked
        if ((event === null || event === void 0 ? void 0 : event.target.innerText) === props.disabledYearsTooltip)
            return;
        removeModal('YearPickerModalContainer');
    }
    function handleConfirmClick(year) {
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(year);
        handleCloseClick();
    }
    return (_jsx(Modal, { title: title !== null && title !== void 0 ? title : _jsx(Ditto, { componentId: "datepicker.tooltip.pickyear" }), trailing: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), onClick: handleCloseClick }), "data-test": "month-picker", content: _jsx(YearPickerContent, Object.assign({ countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, locale: locale, onConfirm: handleConfirmClick }, props)), width: 480, onClickOutside: handleCloseClick }));
}
