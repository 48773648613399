export class RoadSafetyServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param body
     * @returns v2CreateCategoryResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceCreateCategory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/category/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2DeleteCategoryResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceDeleteCategory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/category/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UpdateCategoryResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceUpdateCategory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/category/update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionsCreateManyResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionsCreateMany(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/create-many',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionsExportResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionsExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionsFiltersAgeResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionsFiltersAge(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/filters/age',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionsFiltersCreatorsResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionsFiltersCreators(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/filters/creators',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionsFiltersWinterServiceModesResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionsFiltersWinterServiceModes(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/filters/winter-service-modes',
            body: body
        });
    }
    /**
     * deprecated, added as a workaround to flutter app mapbox sdk support, TODO: remove after 15.07.2024
     * @param body
     * @returns v2InspectionGeoJSONResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionGeoJson(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/geojson',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionByIDResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/get-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionsListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/inspections/list',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param tileZ
     * @param tileX
     * @param tileY
     * @param query
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionTiles(tileZ, tileX, tileY, query) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/inspections/tile/{tile.z}/{tile.x}/{tile.y}',
            path: {
                'tile.z': tileZ,
                'tile.x': tileX,
                'tile.y': tileY
            },
            query: {
                query: query
            }
        });
    }
    /**
     * @param body
     * @returns v2DeleteInspectionsResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceDeleteInspections(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/internal/inspections/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2InspectionSetDateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceInspectionSetDate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/internal/inspections/set-inspection-date',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerAddFilesResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerAddFiles(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/add-files',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2AddMarkerPhotosResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceAddMarkerPhotos(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/add-photos',
            body: body
        });
    }
    /**
     * this endpoint also supports batch updates, we put create/update in one endpoint for now because the design has one save button for both create and update
     * @param body
     * @returns v2MarkerAttributeSchemaBatchCreateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerAttributeSchemaBatchCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/attribute-schema/batch-create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerAttributeSchemaBatchDeleteResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerAttributeSchemaBatchDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/attribute-schema/batch-delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerAttributeSchemaSelectOptionsBatchDeleteResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerAttributeSchemaSelectOptionsBatchDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/attribute-schema/batch-delete-select-options',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerAttributeSchemaListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerAttributeSchemaList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/attribute-schema/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerSaveAttributeResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerSaveAttribute(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/attributes/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerBatchCreateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerBatchCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/batch-create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerBatchDeleteResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerBatchDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/batch-delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BatchUpdateMarkerResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceBatchUpdateMarker(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/batch-update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2BulkLinkMarkerToManualInventoriesResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceBulkLinkMarkerToManualInventories(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/bulk-link-to-manual-inventories',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2CloseMarkerResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceCloseMarker(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/close',
            body: body
        });
    }
    /**
     * deprecated and can be removed after swtiching to /v2/markers/batch-create, TODO: remove after 15.07.2024
     * @param body
     * @returns v2MarkersCreateManyResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersCreateMany(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/create-many',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersExportByEmailResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersExportByEmail(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/export-by-email',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersFiltersResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersFilters(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/filters',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersFoldersCreateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersFoldersCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/folders/create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersFoldersDeleteResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersFoldersDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/folders/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersFoldersListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersFoldersList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/folders/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersFoldersRearrangeResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersFoldersRearrange(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/folders/rearrange',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersFoldersRenameResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersFoldersRename(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/folders/rename',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersGeoJSONResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersGeoJson(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/geojson',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerByIDResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersByIDsResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersByIDs(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-by-id-list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersLightListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersLightList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-light-list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersLightLinkedListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersLightLinkedList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-light-sorted-linked-list-by-ids',
            body: body
        });
    }
    /**
     * deprecated, use /get-sorted-linked-list-default and /get-sorted-linked-list-closed instead, TODO: remove after 15.07.2024
     * @param body
     * @returns v2MarkersLinkedListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersLinkedList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-sorted-linked-list-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersLinkedListClosedResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersLinkedListClosed(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-sorted-linked-list-closed',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersLinkedListDefaultResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersLinkedListDefault(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/get-sorted-linked-list-default',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2LinkMarkerToManualInventoryResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceLinkMarkerToManualInventory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/link-to-manual-inventory',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersListResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/list',
            body: body
        });
    }
    /**
     * deprecated, remove later (replaced by /v2/markers/list with inspection id param), TODO: remove after 15.07.2024
     * @param body
     * @returns v2MarkersListByInspectionIDResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersListByInspectionId(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/list-by-inspection-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkersListOfIdsResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkersListOfIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/list-of-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerRemoveFilesResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerRemoveFiles(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/remove-files',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2RemoveMarkerPhotosResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceRemoveMarkerPhotos(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/remove-photos',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2SaveSentEmailChangelogResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceSaveSentEmailChangelog(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/save-sent-email-changelog',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerSearchResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerSearch(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/search',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2SetMarkerCategoryResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceSetMarkerCategory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/set-category',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2SetMarkerDescriptionResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceSetMarkerDescription(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/set-description',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2SetMarkerDueDateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceSetMarkerDueDate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/set-due-date',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2SetMarkerPriorityResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceSetMarkerPriority(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/set-priority',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2SetMarkerStatusResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceSetMarkerStatus(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/set-status',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerTimelogBatchCreateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerTimelogBatchCreate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/timelog/batch-create',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerTimelogDeleteResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerTimelogDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/timelog/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerTimelogUpdateResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerTimelogUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/timelog/update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UnlinkMarkerFromManualInventoryResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceUnlinkMarkerFromManualInventory(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/unlink-manual-inventory',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerUpdateAddressResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerUpdateAddress(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/update-address',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerUpdateAssigneesResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerUpdateAssignees(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/update-assignees',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UpdateMarkerAudioResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceUpdateMarkerAudio(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/update-audio',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MarkerUpdateTitleResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceMarkerUpdateTitle(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/markers/update-title',
            body: body
        });
    }
    /**
     * TODO: @Radu move to /intertal
     * @param body
     * @returns v2RoadAlertsDeleteByInspectionResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceRoadAlertsDeleteByInspection(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/road-alerts/delete-by-inspection',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    roadSafetyServiceRoadAlertsExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/road-alerts/export',
            body: body
        });
    }
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     * @param body
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    roadSafetyServiceWinterServiceExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/winter-service/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2WinterServiceExportByEmailResponse A successful response.
     * @throws ApiError
     */
    roadSafetyServiceWinterServiceExportByEmail(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/winter-service/export-by-email',
            body: body
        });
    }
}
