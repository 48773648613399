import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { toggleItemInArray } from '../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setManholeObjectFilter, setStormDrainObjectFilter } from '../../state/slices/leftPanelObject';
export function useObjectFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelObject);
    const dispatch = useAppDispatch();
    function handleManholeTypeFilterClick(value) {
        mixpanel.track(MIXPANEL_EVENT_NAME.filterObject, {
            [MIXPANEL_EVENT_PROPERTY.objectType]: value
        });
        dispatch(setManholeObjectFilter(Object.assign(Object.assign({}, filter), { manholeType: toggleItemInArray(filter.manholeType, value) })));
    }
    function handleStormDrainTypeFilterClick(value) {
        mixpanel.track(MIXPANEL_EVENT_NAME.filterObject, {
            [MIXPANEL_EVENT_PROPERTY.objectType]: value
        });
        dispatch(setStormDrainObjectFilter(Object.assign(Object.assign({}, filter), { stormDrainType: toggleItemInArray(filter.stormDrainType, value) })));
    }
    return { handleManholeTypeFilterClick, handleStormDrainTypeFilterClick };
}
