// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uxLtxKb4dd7z8_Wnmhr4{font-weight:normal;font-size:14px;line-height:normal}`, "",{"version":3,"sources":["webpack://./src/components/EditorForms/AssigneeSelect/AssigneeSelectField/AssigneeSelectField.module.css"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CACA,cAAA,CACA,kBAAA","sourcesContent":[".container {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `uxLtxKb4dd7z8_Wnmhr4`
};
export default ___CSS_LOADER_EXPORT___;
