import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { CustomizeButton } from '../../uikit/CustomizeButton/CustomizeButton';
import styles from './Customize.module.css';
export function Customize({ onClick, className, properties }) {
    const label = properties ? (_jsx(Ditto, { componentId: "assets.customizeattributes" })) : (_jsx(Ditto, { componentId: "edit" }));
    return (_jsx("div", Object.assign({ className: classes(styles['container'], className) }, { children: _jsx(CustomizeButton, { label: label, onClick: onClick }) })));
}
