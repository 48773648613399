import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { AnimatePresence } from 'framer-motion';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { ActionModal } from '../ActionModal/ActionModal';
import { DropMenu } from '../DropMenu/DropMenu';
import { FloatingDropdown } from '../FloatingDropdown/FloatingDropdown';
import { Caret } from '../icons';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import { TextOverflow } from '../TextOverflow/TextOverflow';
import styles from './FormInputSelect.module.css';
export function FormInputSelect({ className, disabled = false, required, width, menuWidth, menuMaxHeight, label, leadingIcon, menuItems, value, info, align, isDark = false, canFloat = false, variant = 'default', size = 'default', placeholder, dropDownOffset, hideMenu = false, onClick, 'data-test': dataTest }) {
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown(dropDownOffset ? { offset: dropDownOffset } : {});
    const [isOpen, setIsOpen] = useState(false);
    const [dropUp, setDropUp] = useState(false);
    const ref = useRef(null);
    useLayoutEffect(() => {
        if (ref.current) {
            const windowHeight = window.innerHeight;
            const totalOffsetHeight = ref.current.getBoundingClientRect().bottom;
            const shouldDropUp = totalOffsetHeight >= windowHeight;
            setDropUp(shouldDropUp);
        }
    }, [isOpen]);
    const huggingCaret = variant === 'ghost' && value;
    const placeholderText = placeholder || (size === 'small' ? label : undefined);
    const caretIcon = (_jsx("span", Object.assign({ className: styles['trailing-icon'] }, { children: _jsx(Caret, {}) })));
    const control = (_jsxs("div", Object.assign({ ref: buttonRef, className: classes(styles['field'], !hideMenu && isOpen && styles['field__focus'], isDark && styles['dark'], variant && styles[`field__${variant}`], size && styles[`field__${size}`], disabled && styles['disabled'], className), style: { width }, tabIndex: 0, onClick: () => {
            var _a;
            if (!disabled) {
                if (onClick) {
                    onClick();
                    return;
                }
                toggle();
                (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
        }, "data-test": dataTest }, { children: [_jsxs("div", Object.assign({ className: styles['label-container'] }, { children: [label && (_jsxs("div", Object.assign({ className: styles['label'] }, { children: [required && _jsx("span", Object.assign({ className: styles['required'] }, { children: "*" })), _jsx(TextOverflow, { className: styles['label-text'], text: label, maxLines: 1, tooltip: "small", tooltipPosition: "up", tooltipMaxWidth: 160 }), info && _jsx(InfoTooltip, { tooltip: info.tooltip, position: info.position })] }))), _jsxs("div", Object.assign({ className: styles['value'] }, { children: [leadingIcon && _jsx("span", Object.assign({ className: styles['leading-icon'] }, { children: leadingIcon })), _jsx("span", Object.assign({ className: styles['value-text'] }, { children: value !== null && value !== void 0 ? value : _jsx("span", Object.assign({ className: styles['placeholder'] }, { children: placeholderText })) })), huggingCaret && !disabled && caretIcon] }))] })), !huggingCaret && !disabled && caretIcon] })));
    const menu = (_jsx(ActionModal, Object.assign({ ref: ref, maxHeight: menuMaxHeight, width: menuWidth || width, onItemClick: () => {
            hide();
            setIsOpen(false);
        } }, { children: menuItems })));
    return (_jsxs(_Fragment, { children: [!canFloat && (_jsx(DropMenu, { direction: dropUp ? 'up-right' : 'down-right', control: control, menu: hideMenu ? _jsx(_Fragment, {}) : menu, isOpen: isOpen, setIsOpen: (shouldOpen) => setIsOpen(shouldOpen), disabled: disabled })), canFloat && control, !hideMenu && (_jsx(AnimatePresence, { children: canFloat && isFloatingOpen && (_jsx(FloatingDropdown, Object.assign({}, position, { align: align, content: menu, onClickOutside: () => hide() }), 'floating-dropdown')) }))] }));
}
// this supposed to be only the current selected value, for dropdown menu please use SelectboxEntry or FilterSelectbox
export function FormInputCurrentSelectedValue({ text, icon, empty, onClick, backgroundColor, bold }) {
    return (_jsxs("span", Object.assign({ className: styles['select-value-group'], onClick: onClick, "data-test": "select-value" }, { children: [icon, _jsx("span", Object.assign({ className: classes(styles['select-value-color'], backgroundColor && styles['color']), style: { backgroundColor }, "data-test": "select-value-color" }, { children: _jsx("span", Object.assign({ className: classes(styles['select-value-group-text'], bold && styles['select-value-group-text-bold'], empty && styles['select-value-group-text-notspecified']), "data-test": "select-value-text" }, { children: text })) }))] })));
}
