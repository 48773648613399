import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ImageInProgress } from '../../../uikit/ImageInProgress/ImageInProgress';
import styles from './Image.module.css';
export function Image({ src, alt }) {
    const [isError, setIsError] = useState(src === undefined);
    function handleError() {
        setIsError(true);
    }
    return isError ? (_jsx(ImageInProgress, {})) : (_jsx("img", { className: styles['image'], src: src, alt: alt, onError: handleError }));
}
