var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, pathParams } from '../../common/fetch';
import { milkyWayApiClient } from '../../openapi';
const CONDITION_POINT_ENDPOINT = 'v1/stats/points/by-uuid/:uuid';
const CONDITION_POINT_IMAGE_URL_ENDPOINT = 'v1/image/:uuid';
const CONDITION_POINT_GEOJSON_ENDPOINT = 'v1/printing/map/preview/points/:id';
export function getPoint(uuid) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_POINT_ENDPOINT, { uuid }));
    });
}
export function getPointsList(body) {
    return milkyWayApiClient.milkyWayApiService.milkyWayApiServicePointsList(body);
}
export function getPointsListCount(body) {
    return milkyWayApiClient.milkyWayApiService.milkyWayApiServicePointsCount(body);
}
export function getPointsByUuidList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServicePointsByUuiDsList(body);
    });
}
export function getPointImageUrl(uuid, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const defaultOptions = {
            preview: false
        };
        return yield get(pathParams(CONDITION_POINT_IMAGE_URL_ENDPOINT, { uuid }), Object.assign(Object.assign({}, defaultOptions), options), { 'No-Redirect': 'true' });
    });
}
export function getPointGeoJSON(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield get(pathParams(CONDITION_POINT_GEOJSON_ENDPOINT, { id }));
    });
}
export function exportPointList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServicePointsExport(body);
    });
}
export function exportPointByIdsList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServicePointsExportByIds(body);
    });
}
