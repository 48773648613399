import { useEffect } from 'react';
/**
 * Captures the focus inside the given ref. In other words, this hook
 * makes sure that pressing tab or shift+tab always focuses on the children
 * of ref as long as ref is in DOM. Useful for modals.
 * To work properly it requires that ref has the tabindex attribute set
 * to a non-negative value.
 */
export function useCaptureFocus({ ref }) {
    useEffect(() => {
        var _a, _b;
        // Focus on the container, this ensures that we are able to "trap" focus
        // inside the container in the keydown event listener below.
        // We do this only if the focus is outside the container on mount,
        // it means som element is focused inside and thus the focus is already "trapped".
        if (!((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement))) {
            (_b = ref.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        const listener = (e) => {
            var _a, _b, _c, _d;
            if (e.key !== 'Tab') {
                return;
            }
            const current = ref.current;
            const focusableChildren = getFocusableChildren(current);
            const firstFocusableChild = focusableChildren === null || focusableChildren === void 0 ? void 0 : focusableChildren.at(0);
            const lastFocusableChild = focusableChildren === null || focusableChildren === void 0 ? void 0 : focusableChildren.at(-1);
            // The following makes sure that we "tab-through" in a "circle",
            // never leaving the inside of the ref container (as far as focus is concerned).
            if (e.shiftKey) {
                // Reverse mode, focus on the last if first is reached and the user
                // presses shift + tab to focus backwards.
                // Also focus on the last if the container is a child of a focused element.
                if (firstFocusableChild &&
                    ((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.isSameNode(firstFocusableChild))) {
                    e.preventDefault();
                    lastFocusableChild === null || lastFocusableChild === void 0 ? void 0 : lastFocusableChild.focus();
                }
                else if ((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.contains(current)) {
                    e.preventDefault();
                    lastFocusableChild === null || lastFocusableChild === void 0 ? void 0 : lastFocusableChild.focus();
                }
            }
            else {
                // "Forwards", focus on the first if last is reached
                // or the container is a child of a focused element.
                if (lastFocusableChild && ((_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.isSameNode(lastFocusableChild))) {
                    e.preventDefault();
                    firstFocusableChild === null || firstFocusableChild === void 0 ? void 0 : firstFocusableChild.focus();
                }
                else if ((_d = document.activeElement) === null || _d === void 0 ? void 0 : _d.contains(current)) {
                    e.preventDefault();
                    firstFocusableChild === null || firstFocusableChild === void 0 ? void 0 : firstFocusableChild.focus();
                }
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [ref]);
}
function getFocusableChildren(node) {
    var _a;
    return Array.from((_a = node === null || node === void 0 ? void 0 : node.querySelectorAll(`
            input:not([tabindex^="-"]):not([disabled]):not([type=hidden]),
            select:not([tabindex^="-"]):not([disabled]),
            textarea:not([tabindex^="-"]):not([disabled]),
            button:not([tabindex^="-"]):not([disabled]),
            a[href]:not([tabindex^="-"]):not([disabled]),
            [tabindex]:not([tabindex^="-"]):not([disabled])
        `)) !== null && _a !== void 0 ? _a : []);
}
