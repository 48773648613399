var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { FORM_UPDATE_NOTIFICATION_DURATION_SECONDS } from '../../common/consts/config';
import { DEBOUNCE_USER_INPUT_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getAddressForPosition } from '../../services/address';
import { createSignInventoryPropertyValue, deleteSignInventoryFile, getSignInventoryInfoById, updateSignAddress, updateSignCondition, updateSignInventoryPropertyValue, updateSignOrientation, updateSignType, uploadSignInventoryFiles } from '../../services/signInventory';
import { setIsLoadingActionbar } from './actionbar';
import { fetchSignInventoryFoldersList, fetchSignInventoryList } from './leftPanelSignInventory';
import { addNotificationMessage, setNotificationMessage } from './notification';
import { fetchSignInventoryDamageTypesWithCounts, fetchSignInventoryTreeWithCounts } from './shared';
export const fetchSignInventoryInfoById = createAppAsyncThunk('signDetail/fetchSignInventoryInfoById', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInventoryInfoById({ id });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
const initialState = {
    signInfo: null,
    isLoading: false
};
export const updateSignTypeTk = createAppAsyncThunk('signInventoryDetail/updateSignDamageClass', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield updateSignType({
        signId: payload.id,
        signType: payload.signType
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(setIsLoadingActionbar(true));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: payload.id, tree: true });
    dispatch(setIsLoadingActionbar(false));
}));
export const updateSignOrientationTk = createAppAsyncThunk('signInventoryDetail/updateSignOrientation', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield updateSignOrientation({
        signId: payload.id,
        orientation: payload.orientation
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(setIsLoadingActionbar(true));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: payload.id, tree: true });
    dispatch(setIsLoadingActionbar(false));
}));
export const updateSignConditionTk = createAppAsyncThunk('signInventoryDetail/updateSignDamageClass', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield updateSignCondition({
        signId: payload.id,
        signDamageClass: payload.damageClass
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchSignInventoryDamageTypesWithCounts());
    dispatch(setIsLoadingActionbar(true));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: payload.id });
    dispatch(setIsLoadingActionbar(false));
}));
export const updateSignAddressTk = createAppAsyncThunk('signInventoryDetail/updateSignAddress', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const fetchedAddress = yield getAddressForPosition({
        lng: payload.longitude,
        lat: payload.latitude
    });
    const res = yield updateSignAddress({
        signId: payload.id,
        latitude: payload.latitude,
        longitude: payload.longitude,
        address: fetchedAddress.result
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(setIsLoadingActionbar(true));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: payload.id });
    dispatch(setIsLoadingActionbar(false));
}));
export const updateSignPropertyValue = createDebouncedAsyncThunk('signInventoryDetail/updateSignPropertyValue', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateSignInventoryPropertyValue({
        id: payload.id,
        signId: payload.signId,
        numberValue: payload.numberValue,
        selectValue: payload.selectValue,
        textValue: payload.textValue,
        dateValue: payload.dateValue
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: payload.signId });
    dispatch(setIsLoadingActionbar(false));
}), DEBOUNCE_USER_INPUT_MS);
export const addSignPropertyValue = createDebouncedAsyncThunk('signInventoryDetail/addSignPropertyValue', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield createSignInventoryPropertyValue({
        signId: payload.signId,
        propertyId: payload.propertyId,
        numberValue: payload.numberValue,
        selectValue: payload.selectValue,
        textValue: payload.textValue,
        dateValue: payload.dateValue
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    notifyAndReloadAfterUpdate(dispatch, res, {
        list: true,
        signId: payload.signId
    });
    dispatch(setIsLoadingActionbar(false));
}), DEBOUNCE_USER_INPUT_MS);
export const deleteFileTk = createDebouncedAsyncThunk('signInventoryDetail/deleteFileTk', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield deleteSignInventoryFile({
        signId: payload.signId,
        fileIds: [payload.fileId]
    });
    notifyAndReloadAfterFileDelete(dispatch, res, {
        signId: payload.signId
    });
    dispatch(setIsLoadingActionbar(false));
}), DEBOUNCE_USER_INPUT_MS);
export const uploadFilesTk = createDebouncedAsyncThunk('signInventoryDetail/uploadFilesTk', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield uploadSignInventoryFiles({
        signId: payload.signId,
        folderId: payload.folderId,
        files: payload.files
    });
    notifyAndReloadAfterFileUpload(dispatch, res, {
        signId: payload.signId
    });
    dispatch(setIsLoadingActionbar(false));
}), DEBOUNCE_USER_INPUT_MS);
export function notifyAndReloadAfterUpdate(dispatch, res, fetchOptions, successMessage = {
    closeAfterSeconds: FORM_UPDATE_NOTIFICATION_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: { componentId: 'toast.changessaved' }
    }
}) {
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.tree) {
            dispatch(fetchSignInventoryTreeWithCounts());
        }
        dispatch(setNotificationMessage(successMessage));
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.signId) {
            dispatch(fetchSignInventoryInfoById(fetchOptions.signId));
        }
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.list) {
            dispatch(fetchSignInventoryList());
        }
    }
}
export function notifyAndReloadAfterFileDelete(dispatch, res, fetchOptions, successMessage = {
    closeAfterSeconds: FORM_UPDATE_NOTIFICATION_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: { componentId: 'toast.filedeleted' }
    }
}) {
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setNotificationMessage(successMessage));
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.signId) {
            dispatch(fetchSignInventoryInfoById(fetchOptions.signId));
        }
        dispatch(fetchSignInventoryList());
        dispatch(fetchSignInventoryFoldersList());
    }
}
export function notifyAndReloadAfterFileUpload(dispatch, res, fetchOptions, successMessage = {
    closeAfterSeconds: FORM_UPDATE_NOTIFICATION_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: { componentId: 'toast.successfullyuploaded' }
    }
}) {
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setNotificationMessage(successMessage));
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.signId) {
            dispatch(fetchSignInventoryInfoById(fetchOptions.signId));
        }
        dispatch(fetchSignInventoryList());
        dispatch(fetchSignInventoryFoldersList());
    }
}
export const signInventoryDetailSlice = createSlice({
    name: 'signInventoryDetail',
    initialState,
    reducers: {
        reset: () => initialState,
        setSelectedFileId: (state, action) => {
            state.selectedFileId = action.payload.id;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSignInventoryInfoById.fulfilled, (state, action) => {
            var _a;
            if (action.payload.result) {
                if (action.payload.result.sign.id !== ((_a = state.signInfo) === null || _a === void 0 ? void 0 : _a.id)) {
                    state.selectedFileId = undefined;
                }
                state.isLoading = false;
                state.signInfo = action.payload.result.sign;
            }
        })
            .addCase(fetchSignInventoryInfoById.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(fetchSignInventoryInfoById.rejected, (state, action) => {
            state.error = action.error;
            state.selectedFileId = undefined;
        });
    }
});
export const { reset: resetSignDetail, setSelectedFileId } = signInventoryDetailSlice.actions;
export default signInventoryDetailSlice.reducer;
