import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Button } from '../../../uikit/Button/Button';
import { ActionButton } from '../../ActionButton/ActionButton';
import { FormInputText } from '../../FormInputText/FormInputText';
import { Close } from '../../icons';
import { Modal } from '../../Modal/Modal';
import { CoWorkerRoleSelect } from '../CoWorkerModal/CoWorkerRoleSelect/CoWorkerRoleSelect';
export function AddCoWorkerModal({ className, emailError, loading, roleList, userIsAdmin, onInviteClick, onCancelClick, onClickOutside, onRoleAndPermissionsClick }) {
    const [username, setUsername] = useState(''); // this can be email or phone number
    const [roleId, setRoleId] = useState();
    const usernameLabel = useDittoWrapper({
        componentId: 'coworkermanagement.emailaddressorphonenumber'
    });
    return (_jsx(Modal, { "data-test": "add-coworker-modal", className: className, onClickOutside: onClickOutside, title: _jsx(Ditto, { componentId: "coworkermanagement.add" }), trailing: _jsx(ActionButton, { icon: _jsx(Close, {}), onClick: () => onCancelClick && onCancelClick() }), content: _jsxs(_Fragment, { children: [_jsx(FormInputText, { required: true, error: emailError, label: usernameLabel, value: username, onValueChange: (value) => setUsername(value.toLowerCase().trim()), width: "100%", "data-test": "add-coworker-email-input" }), _jsx(CoWorkerRoleSelect, { required: true, list: roleList, onChange: (value) => value && setRoleId(value), onRoleAndPermissionsClick: onRoleAndPermissionsClick, userIsAdmin: !!userIsAdmin, value: roleId, variant: "default" })] }), contentGap: 12, footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: () => onCancelClick && onCancelClick() }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })), _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", disabled: loading || !username || !roleId, onClick: () => {
                        const tidyUsername = username
                            .split(' ')
                            .join('')
                            .replaceAll(/^[0]{2}/g, '+');
                        setUsername(tidyUsername);
                        onInviteClick === null || onInviteClick === void 0 ? void 0 : onInviteClick({ username: tidyUsername, roleId: roleId || 0 });
                    }, "data-test": "add-coworker-invite-button" }, { children: _jsx(Ditto, { componentId: "coworkermanagement.invite" }) }))] }) }));
}
