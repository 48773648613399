import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { SUPPORTED_ASSET_ATTRIBUTE_TYPES } from '../../../../../common/consts/asset';
import { getAttributeIcon, getAttributeSelectLabel } from '../../../../../common/convert/attribute';
import { useFloatingDropdown } from '../../../../../hooks/forms/useFloatingDropdown';
import { useDittoWrapper } from '../../../../../hooks/useDittoWrapper';
import { ActionModal } from '../../../../ActionModal/ActionModal';
import { FloatingDropdown } from '../../../../FloatingDropdown/FloatingDropdown';
import { Plus } from '../../../../icons';
import { SelectboxEntry } from '../../../../SelectboxEntry/SelectboxEntry';
import { SettingRow } from '../../SettingRow/SettingRow';
const INDENT_PER_LEVEL = 40;
export function AssetAddPropertySelect({ className, onSelect, indentLevel = 0 }) {
    const addattribute = useDittoWrapper({ componentId: 'globalsettings.addproperty' });
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown({
        offset: { top: 0, left: 72, right: 0 }
    });
    function handleSelect(v) {
        hide();
        onSelect(v);
    }
    return (_jsxs("div", Object.assign({ className: className, style: { paddingLeft: indentLevel * INDENT_PER_LEVEL } }, { children: [_jsx("div", Object.assign({ ref: buttonRef }, { children: _jsx(SettingRow, { onClick: toggle, innerIcon: _jsx(Plus, {}), value: addattribute, variant: "button", expandable: false }) })), _jsx(AnimatePresence, { children: isFloatingOpen && (_createElement(FloatingDropdown, Object.assign({}, position, { key: 'dropdown', content: _jsx(ActionModal, Object.assign({ width: 176 }, { children: SUPPORTED_ASSET_ATTRIBUTE_TYPES.map((at) => (_jsx(SelectboxEntry, { text: getAttributeSelectLabel(at), leadingIcon: getAttributeIcon(at), onClick: () => handleSelect(at) }, at))) })), onClickOutside: () => hide() }))) })] })));
}
