// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sh7XD06piTp3Icz9r1sN{width:100%;margin-top:-40px}.SJSokWOJbZtkC40NsIgG{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-bottom:24px;margin-top:0px}.kr57h5o05OuOoNKbV5wh{width:100%;display:flex;justify-content:space-between;gap:12px}.YLnYHc3WejJ0qmYigVGP{width:120px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/CategorySelectModal/CategorySelectModal.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,6BAAA,CACA,QAAA,CAGJ,sBACI,WAAA","sourcesContent":[".footer {\n    width: 100%;\n    margin-top: -40px;\n}\n\n.footer-divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-bottom: 24px;\n    margin-top: 0px;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    gap: 12px;\n}\n\n.button-cancel {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Sh7XD06piTp3Icz9r1sN`,
	"footer-divider": `SJSokWOJbZtkC40NsIgG`,
	"buttons": `kr57h5o05OuOoNKbV5wh`,
	"button-cancel": `YLnYHc3WejJ0qmYigVGP`
};
export default ___CSS_LOADER_EXPORT___;
