import { useAssetFileUpload } from './useAssetFileUpload';
export const useAssetFiles = (params) => {
    const { selectFilesForUpload } = useAssetFileUpload();
    function handleFilesUpload(selectFolder) {
        if (typeof (params === null || params === void 0 ? void 0 : params.asset) === 'undefined') {
            selectFilesForUpload(-1, -1, selectFolder);
        }
        else {
            selectFilesForUpload(params.asset.assetId, params.asset.manualInventoryId, selectFolder);
        }
    }
    return { selectFilesForUpload: handleFilesUpload };
};
