import { useEffect } from 'react';
import { DELAY_SET_ACTIVE_LAYER_RESTORED } from '../common/consts/time';
import { PRIVILEGE_LEVEL } from '../common/consts/user';
import { CLIENT_SETTINGS_FIELDNAME } from '../common/settings';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { updateUserClientSettingsTk } from '../state/slices/auth';
import { setIsActiveLayersRestored, setMapLayers } from '../state/slices/layer';
import { useLayerChecker } from './useLayerChecker';
export function useLayers() {
    const { layerList, isActiveLayersRestored } = useAppSelector((state) => state.layer);
    const { userAccount, roles } = useAppSelector((state) => state.auth);
    const { isSettingsLoaded } = useLayerChecker();
    const dispatch = useAppDispatch();
    useEffect(() => {
        var _a;
        if (!isSettingsLoaded || !userAccount) {
            return;
        }
        const privilegeLevel = ((_a = roles.list.find((role) => role.id === userAccount.currentAccountRoleId)) === null || _a === void 0 ? void 0 : _a.privilegeLevel) || 0;
        let updatedLayers = layerList.filter((layer) => {
            if (userAccount.currentAccountRoleId == null) {
                return true;
            }
            if (privilegeLevel <= PRIVILEGE_LEVEL.viewer) {
                if (layer.id === 'marker') {
                    return false;
                }
            }
            if (privilegeLevel < PRIVILEGE_LEVEL.admin) {
                if (layer.id === 'plan') {
                    return false;
                }
            }
            return true;
        });
        const activeLayers = userAccount.clientSettings.activeLayers;
        if (activeLayers && activeLayers.length > 0) {
            updatedLayers = updatedLayers.map((l) => (Object.assign(Object.assign({}, l), { isEnabled: activeLayers.includes(l.id) })));
        }
        dispatch(setMapLayers(updatedLayers));
        setTimeout(() => dispatch(setIsActiveLayersRestored()), DELAY_SET_ACTIVE_LAYER_RESTORED);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSettingsLoaded]);
    useEffect(() => {
        if (!isActiveLayersRestored) {
            return;
        }
        const activeLayers = layerList.filter((l) => l.isEnabled).map((l) => l.id);
        dispatch(updateUserClientSettingsTk({
            fieldName: CLIENT_SETTINGS_FIELDNAME.activeLayers,
            value: activeLayers
        }));
    }, [dispatch, isActiveLayersRestored, layerList]);
}
