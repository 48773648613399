// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bZAOMGpPocf3PlZNeT35{display:flex;height:40px;padding:7.5px var(--Padding-PaddingM, 12px);flex-direction:column;justify-content:center;align-items:flex-start;gap:3px;border-radius:var(--CornerRadius-CornerS, 6px);font-size:14px;font-weight:600;text-decoration-line:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/SectionFooter/SectionFooter.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,WAAA,CACA,2CAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,OAAA,CACA,8CAAA,CACA,cAAA,CACA,eAAA,CACA,8BAAA,CACA,cAAA","sourcesContent":[".content-footer {\n    display: flex;\n    height: 40px;\n    padding: 7.5px var(--Padding-PaddingM, 12px);\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 3px;\n    border-radius: var(--CornerRadius-CornerS, 6px);\n    font-size: 14px;\n    font-weight: 600;\n    text-decoration-line: underline;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-footer": `bZAOMGpPocf3PlZNeT35`
};
export default ___CSS_LOADER_EXPORT___;
