export class AccountsApiServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * for k8s tcp probes, prevents flooding logs with 404
     * @returns any A successful response.
     * @throws ApiError
     */
    accountsApiServiceRoot() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/'
        });
    }
    /**
     * @param body
     * @returns v2ArchiveAccountResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceArchiveAccount(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/account/archive',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MaintenanceModeListResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceMaintenanceModeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/account/maintenance-mode/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2MaintenanceModeUpdateResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceMaintenanceModeUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/account/maintenance-mode/update',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UpdateAccountNameResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUpdateAccountName(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/account/set-name',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UpdateAccountTypeResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUpdateAccountType(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/account/set-type',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2AppSupportedVersionsFlutterResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceAppSupportedVersionsFlutter(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/app/supported-versions/flutter-app',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NotificationBatchUpdateMarkedAsReadResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceNotificationBatchUpdateMarkedAsRead(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/notification/batch-update-marked-as-read',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NotificationCountUnreadResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceNotificationCountUnread(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/notification/count-unread',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NotificationDeleteTokenResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceNotificationDeleteToken(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/notification/delete-token',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NotificationListForUserResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceNotificationListForUser(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/notification/list-for-user',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NotificationMarkAllAsReadForUserResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceNotificationMarkAllAsReadForUser(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/notification/mark-all-as-read-for-user',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2NotificationSaveTokenResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceNotificationSaveToken(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/notification/save-token',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UserActivateIncludeAnalyticsResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUserActivateIncludeAnalytics(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/include-analytics/activate',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UserDeactivateIncludeAnalyticsResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUserDeactivateIncludeAnalytics(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/include-analytics/deactivate',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UsersListResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUsersList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UsersListByUuidResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUsersListByUuid(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/list-by-uuid',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UsersListProfileResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUsersListProfile(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/list-profile',
            body: body
        });
    }
    /**
     * possible to add more client settings here
     * @param body
     * @returns v2UserUpdateClientSettingsResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUserUpdateClientSettings(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/update-client-settings',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UserUpdateNotificationSettingsResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUserUpdateNotificationSettings(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/update-notification-settings',
            body: body
        });
    }
    /**
     * @param body
     * @returns v2UserUpdatePopupSettingsResponse A successful response.
     * @throws ApiError
     */
    accountsApiServiceUserUpdatePopupSettings(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/update-popup-settings',
            body: body
        });
    }
}
