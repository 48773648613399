import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { MAP_STYLE_URL, MAP_STYLE_URL_SATELLITE } from '../../common/consts/map';
import { getViewState } from '../../common/mapbox';
import { Minimap } from '../../components/Minimap/Minimap';
import { useMapInit } from '../../hooks/useMapInit';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { defaultViewState, initialViewState, setStyle } from '../../state/slices/mainMap';
export function MinimapSwitchContainer() {
    const { main: map } = useMap();
    const dispatch = useAppDispatch();
    const { style } = useAppSelector((state) => state.mainMap);
    const [viewState, setViewState] = useState(initialViewState || defaultViewState);
    const handleMapInit = useMapInit();
    const minimapStyle = style === 'default' ? 'satellite' : 'default';
    const moveHandler = (e) => {
        setViewState(getViewState(e.target));
    };
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('move', moveHandler);
        return () => {
            map.off('move', moveHandler);
        };
    }, [map]);
    function handleStyleToggle() {
        dispatch(setStyle(style === 'default' ? 'satellite' : 'default'));
    }
    return (_jsx(Minimap, { ref: handleMapInit, latitude: viewState.latitude, longitude: viewState.longitude, zoom: viewState.zoom - 2, mapStyle: minimapStyle === 'default' ? MAP_STYLE_URL : MAP_STYLE_URL_SATELLITE, onStyleToggle: handleStyleToggle, id: "MinimapSwitchContainer" }));
}
