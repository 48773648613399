import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { InventoryTeaserModal } from '../../components/Modals/InventoryTeaserModal/InventoryTeaserModal';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useModalContext } from '../../state/context';
export function InventoryTeaserModalContainer() {
    const { removeModal } = useModalContext();
    const formURL = useDittoWrapper({ componentId: 'teaser.immodule.urlform' });
    const videoId = useDittoWrapper({ componentId: 'teaser.immodule.urlvideo' });
    function closeModal() {
        removeModal('InventoryTeaserModal');
    }
    function handleYesClick() {
        window.open(formURL, '_blank');
        closeModal();
    }
    function handleNoClick() {
        closeModal();
    }
    return (_jsx(InventoryTeaserModal, { onYesClick: handleYesClick, onNoClick: handleNoClick, videoEmbedId: videoId }));
}
