// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c7zCMjWUBxNDe5iIqljZ{position:absolute;bottom:0;left:0;height:56px;display:flex;align-items:center;margin-left:48px;color:var(--primary-dark-blue);font-size:14px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTableTotalCount/DataGridTableTotalCount.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,QAAA,CACA,MAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,8BAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".container {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    height: 56px;\n    display: flex;\n    align-items: center;\n    margin-left: 48px;\n    color: var(--primary-dark-blue);\n    font-size: 14px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `c7zCMjWUBxNDe5iIqljZ`
};
export default ___CSS_LOADER_EXPORT___;
