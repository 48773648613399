var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { saveAssetTypes } from '../../services/asset';
import { deleteMarkerAttributeSchema, deleteMarkerAttributeSchemaSelectOption, saveMarkerAttributeSchema } from '../../services/marker';
import { saveNotificationSettings } from '../../services/notifications';
import { getBudgetsSettings, getNetworkSettings, removeBudgetSetting, saveBudgetSettings, saveNetworkSettings } from '../../services/plan';
import { signInventorySavePropertySettings } from '../../services/signInventory';
import { fetchCurrentUser } from './auth';
import { clearSelectedFeature } from './feature';
import { resetAssetFilter } from './leftPanelAsset';
import { fetchMarkers } from './leftPanelMarker';
import { fetchBudgetsBreakdown } from './leftPanelPlan';
import { fetchMarkerGeoJSON } from './mainMap';
import { addNotificationMessage, fetchUserNotificationsTk } from './notification';
import { fetchPlan } from './plan';
import { fetchAssetTypes, fetchMarkerAttributeSchemaTk, fetchSignInventoryPropertySettings } from './shared';
const settingParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.globalSettings;
const initialState = {
    currentPageId: (settingParams === null || settingParams === void 0 ? void 0 : settingParams.currentPageId) || 'notification',
    budgetAndNetwork: {
        network: undefined,
        budgets: undefined
    }
};
export const fetchNetworkSettings = createAppAsyncThunk('globalSetting/network/get', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const state = getState();
    const countryCode = (_b = state.auth.teamAccount) === null || _b === void 0 ? void 0 : _b.countryCode;
    const res = yield getNetworkSettings({ countryCode });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const updateNetworkSettings = createAppAsyncThunk('globalSetting/network/update', (body, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const state = getState();
    const countryCode = (_c = state.auth.teamAccount) === null || _c === void 0 ? void 0 : _c.countryCode;
    const currentPlan = state.plan.currentPlan;
    const res = yield saveNetworkSettings(Object.assign(Object.assign({}, body), { countryCode }));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        if (currentPlan) {
            dispatch(fetchPlan(currentPlan.planId));
        }
    }
    return res;
}));
export const fetchBudgetSettings = createAppAsyncThunk('globalSetting/network/bugdet/list', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getBudgetsSettings();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const updateBudgetSettings = createAppAsyncThunk('globalSetting/network/budget/update', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield saveBudgetSettings(body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    return res;
}));
export const deleteBudget = createAppAsyncThunk('globalSetting/network/remove', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield removeBudgetSetting(body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    return Object.assign(Object.assign({}, res), { result: body.id });
}));
export const updateBudgetAndNetworkSettings = createAppAsyncThunk('globalSetting/network/update', ({ budgetSettings, networkSettings }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e, _f;
    const state = getState();
    const countryCode = (_d = state.auth.teamAccount) === null || _d === void 0 ? void 0 : _d.countryCode;
    const currentPlan = state.plan.currentPlan;
    const [budgetResponse, networkResponse] = yield Promise.all([
        saveBudgetSettings(budgetSettings),
        saveNetworkSettings(Object.assign({ countryCode }, networkSettings))
    ]);
    if (budgetResponse.errorCode !== RESPONSE_ERROR_CODE.success ||
        networkResponse.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(addNotificationMessage({
        type: 'success',
        ditto: {
            title: { componentId: 'toast.changessaved' }
        }
    }));
    dispatch(fetchBudgetsBreakdown());
    if (currentPlan) {
        dispatch(fetchPlan(currentPlan.planId));
    }
    return Object.assign(Object.assign(Object.assign({}, budgetResponse), networkResponse), { result: {
            budgets: (_e = budgetResponse.result) === null || _e === void 0 ? void 0 : _e.budgets,
            network: (_f = networkResponse.result) === null || _f === void 0 ? void 0 : _f.network
        } });
}));
export const saveAssetTypesTk = createAppAsyncThunk('globalSetting/saveAssetTypesTk', (value, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield saveAssetTypes({ inventoryTypes: value });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchAssetTypes());
        dispatch(resetAssetFilter());
        dispatch(clearSelectedFeature());
    }
    return res;
}));
export const saveMarkerAttributeSchemaTk = createAppAsyncThunk('globalSetting/saveMarkerAttributeSchemaTk', (body, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield saveMarkerAttributeSchema(body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchMarkerAttributeSchemaTk());
    }
    return res;
}));
export const deleteMarkerAttributeSchemaTk = createAppAsyncThunk('globalSetting/deleteMarkerAttributeSchemaTk', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield deleteMarkerAttributeSchema({ ids: [id] });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchMarkerAttributeSchemaTk());
        dispatch(fetchMarkerGeoJSON());
        dispatch(fetchMarkers());
        dispatch(clearSelectedFeature());
    }
    return res;
}));
export const deleteMarkerAttributeSchemaSelectOptionTk = createAppAsyncThunk('globalSetting/deleteMarkerAttributeSchemaSelectOptionTk', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield deleteMarkerAttributeSchemaSelectOption({ ids: [id] });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchMarkerAttributeSchemaTk());
        dispatch(fetchMarkerGeoJSON());
        dispatch(fetchMarkers());
        dispatch(clearSelectedFeature());
    }
    return res;
}));
export const saveNotificationSettingsTk = createAppAsyncThunk('globalSetting/saveNotificationSettingsTk', (value, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield saveNotificationSettings({ notificationSettings: value });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchCurrentUser());
        dispatch(fetchUserNotificationsTk());
    }
    return res;
}));
export const saveSignInventoryPropertySettings = createAppAsyncThunk('globalSetting/saveSignInventoryPropertySettingsTk', (properties, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield signInventorySavePropertySettings({ properties });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
        dispatch(fetchSignInventoryPropertySettings());
    }
    return res;
}));
export const globalSettingSlice = createSlice({
    name: 'globalSetting',
    initialState,
    reducers: {
        setCurrentPageId: (state, action) => {
            state.currentPageId = action.payload;
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(deleteBudget.fulfilled, (state, action) => {
            var _a;
            const budgetId = action.payload.result;
            state.budgetAndNetwork.budgets = (_a = state.budgetAndNetwork.budgets) === null || _a === void 0 ? void 0 : _a.filter((budget) => budget.id !== budgetId);
        })
            .addMatcher(isAnyOf(fetchNetworkSettings.fulfilled, updateNetworkSettings.fulfilled, updateBudgetAndNetworkSettings.fulfilled), (state, action) => {
            var _a, _b;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.network) {
                state.budgetAndNetwork = Object.assign(Object.assign({}, state.budgetAndNetwork), { network: (_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.network });
            }
        })
            .addMatcher(isAnyOf(fetchBudgetSettings.fulfilled, updateBudgetSettings.fulfilled, updateBudgetAndNetworkSettings.fulfilled), (state, action) => {
            var _a, _b;
            if ((_a = action.payload.result) === null || _a === void 0 ? void 0 : _a.budgets) {
                state.budgetAndNetwork = Object.assign(Object.assign({}, state.budgetAndNetwork), { budgets: (_b = action.payload.result) === null || _b === void 0 ? void 0 : _b.budgets });
            }
        });
    }
});
export const { reset, setCurrentPageId } = globalSettingSlice.actions;
export default globalSettingSlice.reducer;
