import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchSignInventoryInfoById } from '../state/slices/signInventoryDetail';
export const useSignInventoryInfoFetcher = () => {
    const dispatch = useAppDispatch();
    const { selectedFeature } = useAppSelector((state) => state.feature);
    useEffect(() => {
        if (!selectedFeature || selectedFeature.type !== 'sign-inventory') {
            return;
        }
        dispatch(fetchSignInventoryInfoById(selectedFeature.id));
    }, [selectedFeature, dispatch]);
    return;
};
