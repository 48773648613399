import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Asterisk, CalendarBlank, Check, Close, Efficiency1, Efficiency2, Efficiency3, Efficiency4, Efficiency5, ID, ManualCheck, Minus, RemainingServiceLifeLevel, RemainingServiceLifeNegative, RemainingServiceLifeNegativeMin, RemainingServiceLifePositive, RemainingServiceLifePositiveMin, Select, Text } from '../../components/icons';
import { Vignette } from '../../components/Vignette/Vignette';
import { PLAN_CATEGORY, PLAN_EFFICIENCY_ID, PLAN_EFFICIENCY_LIMIT, PLAN_PRIORITY, PLAN_PRIORITY_ID, PLAN_PRIORITY_LIMIT, PLAN_PROPERTY_ID, PLAN_REMAINIG_SERVICE_LIFE_LIMIT, PLAN_STATUS, PLAN_TREATMENT_CATEGORY_ID } from '../consts/plan';
import { Icon } from '../icon';
import { getCompletionYearMonthString } from '../utils/time';
function getPlanCategoryColor(value) {
    switch (value) {
        case PLAN_CATEGORY.shortTerm:
            return '#ffffff';
        case PLAN_CATEGORY.longTerm:
        case PLAN_CATEGORY.none:
            return '#000000';
        default:
            return '#ffffff';
    }
}
export function getPlanCategoryBackgroundColor(value) {
    switch (value) {
        case PLAN_CATEGORY.shortTerm:
            return '#0c348c';
        case PLAN_CATEGORY.longTerm:
            return '#ff682d';
        case PLAN_CATEGORY.none:
            return '#d3d5d7';
        default:
            return '#ffffff';
    }
}
function getPlanCategoryLetter(value) {
    switch (value) {
        case PLAN_CATEGORY.shortTerm:
            return _jsx(Ditto, { componentId: "plannedsegments.shortterm.letter" });
        case PLAN_CATEGORY.longTerm:
            return _jsx(Ditto, { componentId: "plannedsegments.longterm.letter" });
        case PLAN_CATEGORY.none:
            return _jsx(Minus, {});
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanCategoryLabel(value) {
    switch (value) {
        case PLAN_CATEGORY.shortTerm:
            return _jsx(Ditto, { componentId: "plannedsegments.shortterm" });
        case PLAN_CATEGORY.longTerm:
            return _jsx(Ditto, { componentId: "plannedsegments.longterm" });
        case PLAN_CATEGORY.none:
            return _jsx(Ditto, { componentId: "plannedsegments.none" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanCategoryDescription(value) {
    switch (value) {
        case PLAN_CATEGORY.shortTerm:
            return _jsx(Ditto, { componentId: "plannedsegments.shortterm.description" });
        case PLAN_CATEGORY.longTerm:
            return _jsx(Ditto, { componentId: "plannedsegments.longterm.description" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanEfficiencyIcon(value) {
    switch (value) {
        case PLAN_EFFICIENCY_ID.highest:
            return _jsx(Efficiency1, {});
        case PLAN_EFFICIENCY_ID.high:
            return _jsx(Efficiency2, {});
        case PLAN_EFFICIENCY_ID.medium:
            return _jsx(Efficiency3, {});
        case PLAN_EFFICIENCY_ID.low:
            return _jsx(Efficiency4, {});
        case PLAN_EFFICIENCY_ID.lowest:
            return _jsx(Efficiency5, {});
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanEfficiencyName(value) {
    if (value > PLAN_EFFICIENCY_LIMIT.high) {
        return PLAN_EFFICIENCY_ID.highest;
    }
    if (value > PLAN_EFFICIENCY_LIMIT.medium) {
        return PLAN_EFFICIENCY_ID.high;
    }
    if (value > PLAN_EFFICIENCY_LIMIT.low) {
        return PLAN_EFFICIENCY_ID.medium;
    }
    if (value > PLAN_EFFICIENCY_LIMIT.lowest) {
        return PLAN_EFFICIENCY_ID.low;
    }
    if (value > 0) {
        return PLAN_EFFICIENCY_ID.lowest;
    }
    return PLAN_EFFICIENCY_ID.notspecified;
}
export function getRemainingServiceLifeIcon(value) {
    if (value <= PLAN_REMAINIG_SERVICE_LIFE_LIMIT.negative) {
        return _jsx(RemainingServiceLifeNegative, {});
    }
    if (value < 0) {
        return _jsx(RemainingServiceLifeNegativeMin, {});
    }
    if (value === 0) {
        return _jsx(RemainingServiceLifeLevel, {});
    }
    if (value >= PLAN_REMAINIG_SERVICE_LIFE_LIMIT.positive) {
        return _jsx(RemainingServiceLifePositive, {});
    }
    if (value > 0) {
        return _jsx(RemainingServiceLifePositiveMin, {});
    }
}
function getPlanStatusIcon(value) {
    switch (value) {
        case PLAN_STATUS.inPlanning:
            return _jsx(Asterisk, {});
        case PLAN_STATUS.contracted:
            return _jsx(ManualCheck, {});
        case PLAN_STATUS.completed:
            return _jsx(Check, {});
        default:
            return _jsx(_Fragment, {});
    }
}
function getPlanStatusColor(value) {
    switch (value) {
        case PLAN_STATUS.inPlanning:
            return '#564F2F';
        case PLAN_STATUS.contracted:
            return '#222D39';
        case PLAN_STATUS.completed:
            return '#395F40';
        default:
            return '#ffffff';
    }
}
export function getPlanStatusLabel(value) {
    switch (value) {
        case PLAN_STATUS.inPlanning:
            return _jsx(Ditto, { componentId: "plannedsegments.status.inplanning" });
        case PLAN_STATUS.contracted:
            return _jsx(Ditto, { componentId: "plannedsegments.status.contracted" });
        case PLAN_STATUS.completed:
            return _jsx(Ditto, { componentId: "plannedsegments.status.closed" });
        default:
            return _jsx(_Fragment, {});
    }
}
function getPlanStatusBackgroundColor(value) {
    switch (value) {
        case PLAN_STATUS.inPlanning:
            return 'rgba(247, 229, 151, 1)';
        case PLAN_STATUS.contracted:
            return 'rgba(235, 194, 154, 1)';
        case PLAN_STATUS.completed:
            return 'rgba(167, 227, 178, 1)';
        default:
            return '#ffffff';
    }
}
export function getPlanStatusVignette(value) {
    return (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: getPlanStatusIcon(value) })), color: getPlanStatusColor(value), backgroundColor: getPlanStatusBackgroundColor(value), "data-test": `status-value-${value}` }));
}
export function getPlanCategoryVignette(value, props) {
    return (_jsx(Vignette, Object.assign({ content: getPlanCategoryLetter(value), color: getPlanCategoryColor(value), backgroundColor: getPlanCategoryBackgroundColor(value) }, props)));
}
export function getPlanPriorityIcon(value) {
    switch (value) {
        case PLAN_PRIORITY.highest:
        case PLAN_PRIORITY_ID.highest:
        case PLAN_PRIORITY_LIMIT.highest:
            return _jsx(Icon, { name: "Highest", dataTest: "priority-icon-100" });
        case PLAN_PRIORITY.high:
        case PLAN_PRIORITY_ID.high:
        case PLAN_PRIORITY_LIMIT.high:
            return _jsx(Icon, { name: "High", dataTest: "priority-icon-80" });
        case PLAN_PRIORITY.medium:
        case PLAN_PRIORITY_ID.medium:
        case PLAN_PRIORITY_LIMIT.medium:
            return _jsx(Icon, { name: "Medium", dataTest: "priority-icon-60" });
        case PLAN_PRIORITY.low:
        case PLAN_PRIORITY_ID.low:
        case PLAN_PRIORITY_LIMIT.low:
            return _jsx(Icon, { name: "Low", dataTest: "priority-icon-40" });
        case PLAN_PRIORITY.lowest:
        case PLAN_PRIORITY_ID.lowest:
        case PLAN_PRIORITY_LIMIT.lowest:
            return _jsx(Icon, { name: "Lowest", dataTest: "priority-icon-20" });
        case PLAN_PRIORITY.none:
        case PLAN_PRIORITY_ID.none:
        case PLAN_PRIORITY_LIMIT.none:
            return _jsx(Icon, { name: "None", dataTest: "priority-icon-0" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanPriorityName(value) {
    if (value > PLAN_PRIORITY_LIMIT.high) {
        return PLAN_PRIORITY.highest;
    }
    if (value > PLAN_PRIORITY_LIMIT.medium) {
        return PLAN_PRIORITY.high;
    }
    if (value > PLAN_PRIORITY_LIMIT.low) {
        return PLAN_PRIORITY.medium;
    }
    if (value > PLAN_PRIORITY_LIMIT.lowest) {
        return PLAN_PRIORITY.low;
    }
    if (value > 0) {
        return PLAN_PRIORITY.lowest;
    }
    return PLAN_PRIORITY.none;
}
export function getPlanPriorityLabel(value) {
    switch (value) {
        case PLAN_PRIORITY.highest:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.highest" });
        case PLAN_PRIORITY.high:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.high" });
        case PLAN_PRIORITY.medium:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.medium" });
        case PLAN_PRIORITY.low:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.low" });
        case PLAN_PRIORITY.lowest:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.lowest" });
        case PLAN_PRIORITY.none:
            return _jsx(Ditto, { componentId: "plannedsegments.none" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanPriorityLabelById(value) {
    switch (value) {
        case PLAN_PRIORITY_ID.highest:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.highest" });
        case PLAN_PRIORITY_ID.high:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.high" });
        case PLAN_PRIORITY_ID.medium:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.medium" });
        case PLAN_PRIORITY_ID.low:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.low" });
        case PLAN_PRIORITY_ID.lowest:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.lowest" });
        case PLAN_PRIORITY_ID.none:
            return _jsx(Ditto, { componentId: "plannedsegments.none" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanPriorityLabelWithValue(value) {
    switch (value) {
        case PLAN_PRIORITY.highest:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.highest100" });
        case PLAN_PRIORITY.high:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.high80" });
        case PLAN_PRIORITY.medium:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.medium60" });
        case PLAN_PRIORITY.low:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.low40" });
        case PLAN_PRIORITY.lowest:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.lowest20" });
        case PLAN_PRIORITY.none:
            return _jsx(Ditto, { componentId: "plannedsegments.priority.none" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getPlanTreatmentCategoryLabel(value) {
    switch (value) {
        case PLAN_TREATMENT_CATEGORY_ID.preservation:
            return _jsx(Ditto, { componentId: "treatments.category.preservation" });
        case PLAN_TREATMENT_CATEGORY_ID.rehabilitation:
            return _jsx(Ditto, { componentId: "treatments.category.rehabilitation" });
        case PLAN_TREATMENT_CATEGORY_ID.reconstruction:
            return _jsx(Ditto, { componentId: "treatments.category.reconstruction" });
        default:
            return _jsx(Ditto, { componentId: "treatments.category.uncategorized" });
    }
}
export function getPlanTreatmentCategoryColor(value) {
    switch (value) {
        case PLAN_TREATMENT_CATEGORY_ID.preservation:
            return '#6dd07f';
        case PLAN_TREATMENT_CATEGORY_ID.rehabilitation:
            return '#1456ea';
        case PLAN_TREATMENT_CATEGORY_ID.reconstruction:
            return '#fbd400';
        default:
            return '#ffffff';
    }
}
export function getPlanThirdPartyLabel(value) {
    return value ? _jsx(Ditto, { componentId: "yes" }) : _jsx(Ditto, { componentId: "no" });
}
export function getPlanThirdPartyIcon(value) {
    return value ? (_jsx(Vignette, { backgroundColor: "#C5ECCC", color: "#2C5333", content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Check, {}) })) })) : (_jsx(Vignette, { backgroundColor: "#FFBBBB", color: "#972525", content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Close, {}) })) }));
}
export function getPlanPropertyIcon(id) {
    switch (id) {
        case PLAN_PROPERTY_ID.text:
            return _jsx(Text, {});
        case PLAN_PROPERTY_ID.numeric:
            return _jsx(ID, {});
        case PLAN_PROPERTY_ID.list:
            return _jsx(Select, {});
        case PLAN_PROPERTY_ID.date:
            return _jsx(CalendarBlank, {});
        default:
            return _jsx(Text, {});
    }
}
export function getPlanPropertyLabel(id) {
    switch (id) {
        case PLAN_PROPERTY_ID.text:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.text" });
        case PLAN_PROPERTY_ID.numeric:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.number" });
        case PLAN_PROPERTY_ID.list:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.list" });
        case PLAN_PROPERTY_ID.date:
            return _jsx(Ditto, { componentId: "globalsettings.datatype.date" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function planDataToContent(locale, item, treatmentsList, formatter, unitFormatter) {
    var _a, _b, _c;
    const treatmentType = treatmentsList.find((i) => i.id === item.treatmentId);
    return {
        priority: {
            text: getPlanPriorityLabel(getPlanPriorityName((_a = item.priority) !== null && _a !== void 0 ? _a : 0)),
            icon: getPlanPriorityIcon(getPlanPriorityName((_b = item.priority) !== null && _b !== void 0 ? _b : 0))
        },
        title: item.name,
        grade: item.grade,
        area: item.area !== undefined && item.area > 0
            ? unitFormatter({ type: 'area', value: item.area })
            : undefined,
        cost: item.costs !== undefined && item.costs > 0
            ? unitFormatter({ type: 'currency', value: item.costs })
            : undefined,
        addressStart: item.addressStart.userModifiedAddress ||
            formatter.formatAddress(item.addressStart, { isShort: true }),
        addressEnd: item.addressEnd.userModifiedAddress ||
            formatter.formatAddress(item.addressEnd, { isShort: true }),
        description: (_c = item.note) !== null && _c !== void 0 ? _c : '',
        status: {
            icon: getPlanStatusIcon(item.statusId),
            text: getPlanStatusLabel(item.statusId),
            iconColor: getPlanStatusColor(item.statusId),
            backgroundColor: getPlanStatusBackgroundColor(item.statusId)
        },
        treatment: treatmentType
            ? {
                text: treatmentType.title,
                categoryTypeId: treatmentType.categoryTypeId
            }
            : undefined,
        category: item.categoryId
            ? {
                letter: getPlanCategoryLetter(item.categoryId),
                letterColor: getPlanCategoryColor(item.categoryId),
                backgroundColor: getPlanCategoryBackgroundColor(item.categoryId)
            }
            : undefined,
        formattedCompletion: item.completionYear === undefined || item.completionMonth === undefined
            ? ''
            : getCompletionYearMonthString(locale, item.completionYear, item.completionMonth, true)
    };
}
