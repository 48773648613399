import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { Ditto } from 'ditto-react';
import { MINIMAP_DEFAULT_ZOOM } from '../../common/consts/map';
import { signToMarkerSource } from '../../common/convert';
import { getSignIcon, getSignTranslation } from '../../common/convert/sign';
import { formatLatLng } from '../../common/geoFormatter';
import { createRequestTransformerFunc, TRAFFIC_SIGNS_LAYER_ID, trafficSignsLayer } from '../../common/mapbox';
import { getApiHost, ROOT_PATH } from '../../common/url';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { DetailsHeader } from '../../components/DetailsPanel/DetailsHeader/DetailsHeader';
import { DetailsInfo } from '../../components/DetailsPanel/DetailsInfo/DetailsInfo';
import { DetailsMetaInformation } from '../../components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformation';
import { DetailsMetaInformationItem } from '../../components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem';
import { DetailsPanel } from '../../components/DetailsPanel/DetailsPanel';
import { DropMenuWrapper } from '../../components/DropMenuWrapper/DropMenuWrapper';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { CalendarBlank, Clock, Close, ID, Mail, MapPin, OverflowDotsThreeVertical, Print, Report, Signs2, Task } from '../../components/icons';
import { ImageCrop } from '../../components/ImageCrop/ImageCrop';
import { ImagePreview } from '../../components/ImagePreview/ImagePreview';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { Loader } from '../../components/Loader/Loader';
import { Minimap } from '../../components/Minimap/Minimap';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { SignDetail } from '../../components/SignDetail/SignDetail';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useMapInit } from '../../hooks/useMapInit';
import { useSignInfoFetcher } from '../../hooks/useSignInfoFetcher';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearSelectedFeature } from '../../state/slices/feature';
import { setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { Button } from '../../uikit/Button/Button';
const IMAGE_SIZE = { height: 250, width: 356 };
export function SignDetailContainer({ variant }) {
    const dispatch = useAppDispatch();
    const dateTimeFormatter = useDateTimeFormatter();
    const { addModal } = useModalContext();
    const { isLoading, error, signInfo } = useAppSelector((state) => state.signDetail);
    const { targetSchema } = useAppSelector((state) => state.auth);
    const { removeModal } = useModalContext();
    const handleMapInit = useMapInit();
    const { isEditor } = useUserRole();
    useSignInfoFetcher();
    if (isLoading) {
        return _jsx(DetailsPanel, { loader: _jsx(Loader, {}) });
    }
    if (error || !signInfo) {
        return (_jsx(DetailsPanel, { children: _jsx(DetailsInfo, { linkText: _jsx(Ditto, { componentId: "close" }), content: _jsx(Ditto, { componentId: "somethingwentwrongtext" }) }) }));
    }
    function handleImageEnlarge() {
        addModal({ id: 'ImageViewerSign' });
    }
    function handleReportButtonClick() {
        addModal({ id: 'ReportModal' });
    }
    function handleMailClick() {
        if (!signInfo) {
            return;
        }
        addModal({
            id: 'MailModal',
            props: {
                featureList: {
                    type: 'sign',
                    // TODO: @street-gallery team: remove type casting
                    value: [signInfo]
                }
            }
        });
    }
    function handlePrintClick() {
        window.open(`/${ROOT_PATH.print}/sign/${signInfo === null || signInfo === void 0 ? void 0 : signInfo.id}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleCreateMarkerClick() {
        if (!signInfo) {
            return;
        }
        dispatch(setMarkerCreatorPointSource(signToMarkerSource(signInfo)));
    }
    function handleCloseClick() {
        if (variant === 'image-viewer') {
            removeModal('ImageViewerSign');
            return;
        }
        dispatch(clearSelectedFeature());
    }
    const header = (_jsx(DetailsHeader, { buttonsRight: _jsxs(_Fragment, { children: [_jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ trailingIcon: _jsx(OverflowDotsThreeVertical, {}), "data-test": "three-dot-menu", variant: "slender" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), menu: _jsxs(ActionModal, Object.assign({ width: 263 }, { children: [isEditor && (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.createtask" }), leadingIcon: _jsx(Task, {}), onClick: handleCreateMarkerClick })), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), leadingIcon: _jsx(Print, {}), onClick: handlePrintClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), leadingIcon: _jsx(Mail, {}), onClick: handleMailClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.reportissue" }), leadingIcon: _jsx(Report, {}), onClick: handleReportButtonClick })] })), direction: "down-left" }), _jsx(Button, Object.assign({ variant: "square", color: "dark", onClick: handleCloseClick }, { children: _jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(Close, {}) })) }))] }), title: signInfo.displayName, titleIcon: _jsx(Signs2, {}), label: _jsx(Ditto, { componentId: "rightpanel.titletype.sign" }) }));
    const signDateTime = dateTimeFormatter(signInfo.timestamp);
    const metaInfo = (_jsxs(DetailsMetaInformation, { children: [_jsx(DetailsMetaInformationItem, { icon: _jsx(CalendarBlank, {}), label: signDateTime.date }), _jsx(DetailsMetaInformationItem, { icon: _jsx(Clock, {}), label: signDateTime.time }), _jsx(DetailsMetaInformationItem, { icon: _jsx(ID, {}), label: signInfo.id }), _jsx(DetailsMetaInformationItem, { icon: _jsx(MapPin, {}), label: formatLatLng({
                    lat: Number(signInfo.offsetPosition[1]),
                    lng: Number(signInfo.offsetPosition[0])
                }) })] }));
    const damageType = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'objects.status' }), content: _jsxs(_Fragment, { children: [getSignIcon(signInfo.damageType), getSignTranslation(signInfo.damageType)] }) }));
    if (!targetSchema || !signInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    const geojson = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [signInfo.offsetPosition[0], signInfo.offsetPosition[1]]
        },
        properties: {
            icon: signInfo.damageType
        }
    };
    const mapContent = (_jsx(Minimap, Object.assign({ ref: handleMapInit, longitude: signInfo.offsetPosition[0], latitude: signInfo.offsetPosition[1], zoom: MINIMAP_DEFAULT_ZOOM, transformRequest: createRequestTransformerFunc(targetSchema, getApiHost()), id: "ImageViewerSignContainer", size: "large", noOutline: true, variant: "image-viewer" }, { children: _jsx(Source, Object.assign({ id: TRAFFIC_SIGNS_LAYER_ID.default, type: "geojson", data: geojson }, { children: _jsx(Layer, Object.assign({}, trafficSignsLayer)) })) })));
    const image = (_jsx(ImagePreview, Object.assign({}, IMAGE_SIZE, { onClickEnlarge: handleImageEnlarge }, { children: _jsx(ImageCrop, { src: signInfo.imageUrl, alt: "Traffic Sign", tag: signInfo.tag, imageSize: IMAGE_SIZE }) })));
    return (_jsx(SignDetail, { header: header, metaInfo: metaInfo, imagePreview: variant === 'image-viewer' ? mapContent : image, damageType: damageType, onAddMarkerClick: isEditor ? handleCreateMarkerClick : undefined }));
}
