import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { LAYER_PATH } from '../../common/layer';
import { AppLayout } from '../../components/AppLayout/AppLayout';
import { Loader } from '../../components/Loader/Loader';
import { ActionbarContainer } from '../../containers/ActionbarContainer/ActionbarContainer';
import { AppliedFilterBarContainer } from '../../containers/AppliedFilterBarContainer/AppliedFilterBarContainer';
import { AssetCreatorContainer } from '../../containers/AssetCreatorContainer/AssetCreatorContainer';
import { AssetDetailContainer } from '../../containers/AssetDetailContainer/AssetDetailContainer';
import { ConditionDetailContainer } from '../../containers/ConditionDetailContainer';
import { HintboxContainer } from '../../containers/HintboxContainer/HintboxContainer';
import { LayerPanelContainer } from '../../containers/LayerPanelContainer/LayerPanelContainer';
import { LeftPanelAlertContainer } from '../../containers/LeftPanelAlertContainer/LeftPanelAlertContainer';
import { LeftPanelAssetContainer } from '../../containers/LeftPanelAssetContainer/LeftPanelAssetContainer';
import { LeftPanelConditionContainer } from '../../containers/LeftPanelConditionContainer';
import { LeftPanelMarkerContainer } from '../../containers/LeftPanelMarkerContainer/LeftPanelMarkerContainer';
import { LeftPanelObjectContainer } from '../../containers/LeftPanelObjectContainer/LeftPanelObjectContainer';
import { LeftPanelPlanContainer } from '../../containers/LeftPanelPlanContainer/LeftPanelPlanContainer';
import { LeftPanelSignContainer } from '../../containers/LeftPanelSignContainer';
import { LeftPanelSignInventoryContainer } from '../../containers/LeftPanelSignInventoryContainer';
import { LeftPanelTrackControlContainer } from '../../containers/LeftPanelTrackControlContainer/LeftPanelTrackControlContainer';
import { LeftPanelWinterServiceContainer } from '../../containers/LeftPanelWinterServiceContainer';
import { MapContainer } from '../../containers/MapContainer/MapContainer';
import { MarkerCreatorContainer } from '../../containers/MarkerCreatorContainer/MarkerCreatorContainer';
import { MarkerDetailContainer } from '../../containers/MarkerDetailContainer/MarkerDetailContainer';
import { MinimapSwitchContainer } from '../../containers/MinimapSwitchContainer/MinimapSwitchContainer';
import { ModalOverlayContainer } from '../../containers/ModalOverlayContainer/ModalOverlayContainer';
import { NotificationContainer } from '../../containers/NotificationContainer/NotificationContainer';
import { PlanDetailContainer } from '../../containers/PlanDetailContainer/PlanDetailContainer';
import { PopupBannerContainer } from '../../containers/PopupBannerContainer/PopupBannerContainer';
import { PresetsPanelContainer } from '../../containers/PresetsPanelContainer/PresetsPanelContainer';
import { RightPanelMarkerBulkCreatorContainer } from '../../containers/RightPanelMarkerBulkCreatorContainer/RightPanelMarkerBulkCreatorContainer';
import { RightPanelMarkerCreatorContainer } from '../../containers/RightPanelMarkerCreatorContainer/RightPanelMarkerCreatorContainer';
import { SegmentCreatorContainer } from '../../containers/SegmentCreatorContainer/SegmentCreatorContainer';
import { SegmentFormContainer } from '../../containers/SegmentFormContainer/SegmentFormContainer';
import { SignDetailContainer } from '../../containers/SignDetailContainer/SignDetailContainer';
import { SignInventoryDetailContainer } from '../../containers/SignInventoryDetailContainer/SignInventoryDetailContainer';
import { TrafficSignCreatorContainer } from '../../containers/TrafficSignCreatorContainer/TrafficSignCreatorContainer';
import { useMapTransition } from '../../hooks/mainMap';
import { useAccessProtect } from '../../hooks/useAccessProtect';
import { useAppData } from '../../hooks/useAppData';
import { useBrowserBackListener } from '../../hooks/useBrowserBackListener';
import { useLayers } from '../../hooks/useLayers';
import { useLayoutChecker } from '../../hooks/useLayoutChecker';
import { useModalContextToReduxSync } from '../../hooks/useModalContextToReduxSync';
import { useReduxToURLLayerPathSync } from '../../hooks/useReduxToURLLayerPathSync';
import { useResetMultiselectOnLayerSelect } from '../../hooks/useResetMultiselectOnLayerSelect';
import { useSharedData } from '../../hooks/useSharedData';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
// NOTE: it needs to be in react location router
// it is a special container, which does not belong to storybook
export function AppContainer() {
    const { selectedLayerId } = useAppSelector((state) => state.layer);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const { source: markerBulkCreatorSource } = useAppSelector((state) => state.markerBulkCreator);
    const { currentAction } = useAppSelector((state) => state.app);
    const { teamAccount, isAuthenticated, roles, targetSchema, userAccount } = useAppSelector((state) => state.auth);
    const { showMainMap, showBanner } = useLayoutChecker();
    const { modals } = useModalContext();
    useReduxToURLLayerPathSync();
    useModalContextToReduxSync();
    useAccessProtect();
    useAppData();
    useSharedData();
    useBrowserBackListener();
    useLayers();
    useResetMultiselectOnLayerSelect();
    const galleryModalActive = modals
        .map((modal) => modal.id)
        .find((item) => [
        'FileGallerySignInventory',
        'FileGalleryTaskContainer',
        'FileGalleryAssetContainer'
    ].includes(item));
    const filterPanel = useMemo(function () {
        switch (selectedLayerId) {
            case LAYER_PATH.marker:
                return _jsx(LeftPanelMarkerContainer, {});
            case LAYER_PATH.plan:
                return _jsx(LeftPanelPlanContainer, {});
            case LAYER_PATH.condition:
                return _jsx(LeftPanelConditionContainer, {});
            case LAYER_PATH.alert:
                return _jsx(LeftPanelAlertContainer, {});
            case LAYER_PATH.trackControl:
                return _jsx(LeftPanelTrackControlContainer, {});
            case LAYER_PATH.object:
                return _jsx(LeftPanelObjectContainer, {});
            case LAYER_PATH.sign:
                return _jsx(LeftPanelSignContainer, {});
            case LAYER_PATH.signInventory:
                return _jsx(LeftPanelSignInventoryContainer, {});
            case LAYER_PATH.winterService:
                return _jsx(LeftPanelWinterServiceContainer, {});
            case LAYER_PATH.asset:
                return _jsx(LeftPanelAssetContainer, {});
            default:
                return undefined;
        }
    }, [selectedLayerId]);
    const detailPanel = useMemo(function () {
        if (assetCreatorSource) {
            return _jsx(AssetCreatorContainer, {});
        }
        if (markerCreatorSource) {
            return _jsx(RightPanelMarkerCreatorContainer, {});
        }
        if (markerBulkCreatorSource) {
            return _jsx(RightPanelMarkerBulkCreatorContainer, {});
        }
        if (!selectedFeature) {
            return;
        }
        switch (selectedFeature.type) {
            case 'point':
            case 'segment':
            case 'alert':
            case 'object':
                return _jsx(ConditionDetailContainer, {});
            case 'marker':
                return _jsx(MarkerDetailContainer, {});
            case 'plan':
                return _jsx(PlanDetailContainer, {});
            case 'sign':
                return _jsx(SignDetailContainer, {});
            case 'sign-inventory':
                return _jsx(SignInventoryDetailContainer, {});
            case 'asset':
                return _jsx(AssetDetailContainer, {});
        }
    }, [assetCreatorSource, markerBulkCreatorSource, markerCreatorSource, selectedFeature]);
    const floatingPanel = useMemo(function () {
        switch (currentAction) {
            case 'segment-creator':
                return _jsx(SegmentCreatorContainer, {});
            case 'segment-creator-form':
                return _jsx(SegmentFormContainer, {});
            case 'marker-creator':
                return _jsx(MarkerCreatorContainer, {});
            case 'asset-creator':
                return _jsx(AssetCreatorContainer, {});
            case 'traffic-sign-creator':
                return _jsx(TrafficSignCreatorContainer, {});
        }
    }, [currentAction]);
    const mode = useMemo(function () {
        if (!showMainMap) {
            return 'no-main-map';
        }
        if (currentAction !== 'none') {
            if (currentAction !== 'feature-drag-drop' && currentAction !== 'feature-rotate') {
                return 'fullscreen-map';
            }
        }
        if (galleryModalActive) {
            return 'gallery-modal';
        }
        return 'default';
    }, [currentAction, galleryModalActive, showMainMap]);
    useMapTransition(!!filterPanel, !!detailPanel);
    if (!teamAccount ||
        !isAuthenticated ||
        !targetSchema ||
        !userAccount ||
        roles.status !== 'succeeded') {
        return _jsx(Loader, { variant: "fullscreen" });
    }
    return (_jsx(AppLayout, { layerPanel: _jsx(LayerPanelContainer, {}), presetPanel: _jsx(PresetsPanelContainer, {}), filterPanel: filterPanel, map: _jsx(MapContainer, {}), detailPanel: detailPanel, notification: _jsx(NotificationContainer, {}), modalOverlay: _jsx(ModalOverlayContainer, {}), minimapSwitch: _jsx(MinimapSwitchContainer, {}), hintbox: _jsx(HintboxContainer, {}), actionbar: _jsx(ActionbarContainer, {}), floatingFilter: _jsx(AppliedFilterBarContainer, {}), floatingPanel: floatingPanel, mode: mode, banner: showBanner && _jsx(PopupBannerContainer, {}) }));
}
