import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Close, Search as SearchIcon } from '../icons';
import styles from './Search.module.css';
export function Search({ placeholder, value, onChange, variant = 'default' }) {
    const [isFocus, setIsFocus] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        var _a;
        if (inputRef.current) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles[`container__${variant}`], isFocus && styles[`container__${variant}__focus`]) }, { children: [_jsx("input", { ref: inputRef, className: classes(styles['input']), type: "text", placeholder: placeholder, value: value, onChange: (e) => onChange && onChange(e.target.value), onFocus: () => setIsFocus(true), onBlur: () => setIsFocus(false), "data-test": "input-search" }), value && (_jsx("span", Object.assign({ className: styles['reset-icon'], onClick: () => onChange && onChange('') }, { children: _jsx(Close, {}) }))), !value && (_jsx("span", Object.assign({ className: styles['search-icon'] }, { children: _jsx(SearchIcon, {}) })))] })));
}
