// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds2AkrlQ2IPabgzKEzg9{position:fixed;z-index:var(--zindex-tooltip)}.C8Gn21lyeFKenQBFrJ2Q{position:absolute}.jto_3PzRN2Pgj7oWYKTo span{transform:translate(0, 0)}._Q3NEV07IsYLirQKAkfK span{transform:translate(calc(-100% - 10px), -50%)}.buGE5zDMZ0sUiliV4AwS{transform:translate(0, -117%)}`, "",{"version":3,"sources":["webpack://./src/components/FloatingTooltip/FloatingTooltip.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,6BAAA,CAGJ,sBACI,iBAAA,CAGJ,2BACI,yBAAA,CAGJ,2BACI,6CAAA,CAGJ,sBACI,6BAAA","sourcesContent":[".container {\n    position: fixed;\n    z-index: var(--zindex-tooltip);\n}\n\n.content-container {\n    position: absolute;\n}\n\n.content--left span {\n    transform: translate(0, 0);\n}\n\n.content--right span {\n    transform: translate(calc(-100% - 10px), -50%);\n}\n\n.anchor__up-right {\n    transform: translate(0, calc(-117%));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ds2AkrlQ2IPabgzKEzg9`,
	"content-container": `C8Gn21lyeFKenQBFrJ2Q`,
	"content--left": `jto_3PzRN2Pgj7oWYKTo`,
	"content--right": `_Q3NEV07IsYLirQKAkfK`,
	"anchor__up-right": `buGE5zDMZ0sUiliV4AwS`
};
export default ___CSS_LOADER_EXPORT___;
