import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { useIsInMountTransition } from '../../hooks/useIsInMountTransition';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { Alert } from './Alert/Alert';
import { Asset } from './Asset/Asset';
import { Loading } from './Loading/Loading';
import { Marker } from './Marker/Marker';
import { Object2 } from './Object2/Object2';
import { PlannedSegment } from './PlannedSegment/PlannedSegment';
import { Point } from './Point/Point';
import { Segment } from './Segment/Segment';
import { Sign } from './Sign/Sign';
import { SignInventory } from './SignInventory/SignInventory';
import { TrackControl } from './TrackControl/TrackControl';
import { WinterService } from './WinterService/WinterService';
import styles from './Result.module.css';
export function Result({ content, onClick, onAddMarkerButtonClick, onAddToPlanningButtonClick, onImageEnlargeClick, onLinkedTaskClick, onSelect, onMouseEnter, onMouseLeave, isLoading, isActive, isHighlighted, isAdmin, isEditor, isViewer, isSelectable, showGradePCI, showGradePaser, countryCode }) {
    const checkboxRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const isInTransition = useIsInMountTransition();
    const handleAddMarkerButtonClick = useCallback(function () {
        onAddMarkerButtonClick === null || onAddMarkerButtonClick === void 0 ? void 0 : onAddMarkerButtonClick();
    }, [onAddMarkerButtonClick]);
    const handleAddToPlanningButtonClick = useCallback(function () {
        onAddToPlanningButtonClick === null || onAddToPlanningButtonClick === void 0 ? void 0 : onAddToPlanningButtonClick();
    }, [onAddToPlanningButtonClick]);
    const handleImageEnlargeClick = useCallback(function () {
        onImageEnlargeClick === null || onImageEnlargeClick === void 0 ? void 0 : onImageEnlargeClick();
    }, [onImageEnlargeClick]);
    const handleLinkedTaskClick = useCallback(function (assetId) {
        onLinkedTaskClick === null || onLinkedTaskClick === void 0 ? void 0 : onLinkedTaskClick(assetId);
    }, [onLinkedTaskClick]);
    const handleSelect = useCallback(function (e) {
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(e);
    }, [onSelect]);
    const handleClick = useCallback(function (event) {
        var _a;
        const isCheckboxClicked = (_a = checkboxRef === null || checkboxRef === void 0 ? void 0 : checkboxRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target);
        if (!isCheckboxClicked) {
            onClick && onClick();
        }
    }, [onClick]);
    const handleMouseEnter = useCallback(() => {
        setIsHovering(true);
        onMouseEnter === null || onMouseEnter === void 0 ? void 0 : onMouseEnter();
    }, [onMouseEnter]);
    const handleMouseLeave = useCallback(() => {
        setIsHovering(false);
        onMouseLeave === null || onMouseLeave === void 0 ? void 0 : onMouseLeave();
    }, [onMouseLeave]);
    const contentNode = useMemo(() => {
        if (isLoading || isInTransition) {
            return _jsx(Loading, { type: content.type });
        }
        const checkbox = isSelectable || isHovering ? (_jsx(Checkbox, { className: styles['checkbox'], onClick: handleSelect, isChecked: isActive, isHovering: isHovering, color: "black", "data-test": "result-item-checkbox", checkboxRef: checkboxRef })) : undefined;
        switch (content.type) {
            case 'segment':
                if (content.value) {
                    return (_jsx(Segment, { content: content.value, isAdmin: isAdmin, checkbox: checkbox, withAction: isHovering, onAddToPlanningButtonClick: handleAddToPlanningButtonClick, showGradePCI: showGradePCI, showGradePaser: showGradePaser, countryCode: countryCode }));
                }
                break;
            case 'point':
                if (content.value) {
                    return (_jsx(Point, { content: content.value, withAction: isHovering, checkbox: checkbox, onAddMarkerButtonClick: handleAddMarkerButtonClick, onImageEnlargeClick: handleImageEnlargeClick, showGradePCI: showGradePCI, showGradePaser: showGradePaser, countryCode: countryCode }));
                }
                break;
            case 'marker':
                if (content.value) {
                    return (_jsx(Marker, { content: content.value, withAction: isHovering, checkbox: checkbox, onImageEnlargeClick: handleImageEnlargeClick }));
                }
                break;
            case 'object':
                if (content.value) {
                    return (_jsx(Object2, { content: content.value, withAction: isHovering, isEditor: isEditor, checkbox: checkbox, onAddMarkerButtonClick: handleAddMarkerButtonClick, onImageEnlargeClick: handleImageEnlargeClick }));
                }
                break;
            case 'sign':
                if (content.value) {
                    return (_jsx(Sign, { content: content.value, withAction: isHovering, isEditor: isEditor, checkbox: checkbox, onAddMarkerButtonClick: handleAddMarkerButtonClick, onImageEnlargeClick: handleImageEnlargeClick }));
                }
                break;
            case 'sign-inventory':
                if (content.value) {
                    return (_jsx(SignInventory, { content: content.value, withAction: isHovering, isEditor: isEditor, isViewer: isViewer, isLimited: isSelectable, onAddMarkerButtonClick: handleAddMarkerButtonClick, onImageEnlargeClick: handleImageEnlargeClick, checkbox: checkbox, statusCode: content.value.statusCode }));
                }
                break;
            case 'track-control':
                if (content.value) {
                    return _jsx(TrackControl, { content: content.value });
                }
                break;
            case 'winter-service':
                if (content.value) {
                    return _jsx(WinterService, { content: content.value, isLimited: isSelectable });
                }
                break;
            case 'asset':
                if (content.value) {
                    return (_jsx(Asset, { content: content.value, checkbox: checkbox, onLinkedTaskClick: handleLinkedTaskClick }));
                }
                break;
            case 'planned-segment':
                if (content.value) {
                    return _jsx(PlannedSegment, { content: content.value, checkbox: checkbox });
                }
                break;
            case 'rs-alert':
                if (content.value) {
                    return (_jsx(Alert, { content: content.value, withAction: isHovering || isActive, isEditor: isEditor, onImageEnlargeClick: handleImageEnlargeClick }));
                }
                break;
        }
    }, [
        content.type,
        content.value,
        countryCode,
        isActive,
        isAdmin,
        isEditor,
        isHovering,
        isInTransition,
        isLoading,
        isSelectable,
        isViewer,
        handleAddMarkerButtonClick,
        handleAddToPlanningButtonClick,
        handleImageEnlargeClick,
        handleLinkedTaskClick,
        handleSelect,
        showGradePCI,
        showGradePaser
    ]);
    return (_jsx("div", Object.assign({ className: classes(styles['container'], (isActive || isHighlighted) && styles['container__active']), onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, "data-test": "result-item" }, { children: _jsx("div", Object.assign({ className: styles['content'] }, { children: contentNode })) })));
}
