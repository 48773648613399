import { LAYER_PATH } from './layer';
import { ROOT_PATH, UNIVERSAL_LINK_PATH } from './url';
export function getGoogleMapLink({ lat, lng }) {
    return `https://www.google.com/maps/place/${lat},${lng}`;
}
export function getUniversalLinkForMarker(id) {
    return `${window.location.origin.toString()}/${ROOT_PATH.universalLink}/${LAYER_PATH.marker}/${id}`;
}
export function getUniversalLinkForAsset(id) {
    return `${window.location.origin.toString()}/${ROOT_PATH.universalLink}/${LAYER_PATH.asset}/${id}`;
}
export function getCloseMarkerLink(decryptCloseParams, locale) {
    return `${window.location.origin.toString()}/${ROOT_PATH.closeMarker}?token=${decryptCloseParams}&locale=${locale}`;
}
export function getUniversalLinkForLogin() {
    return `${window.location.origin.toString()}/${ROOT_PATH.universalLink}/${UNIVERSAL_LINK_PATH.login}`;
}
