// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O7efCkAcPerSsYoDVgXQ{display:flex;padding:var(--Gap-GapL, 8px) var(--Gap-GapM, 4px) var(--Gap-GapL, 8px) var(--Gap-GapL, 8px);justify-content:center;align-items:center;gap:10px;flex:1 0 0;border-radius:var(--CornerRadius-CornerS, 6px);border:1px solid rgba(0,0,0,0)}.pWrrqoKzpQepyfwtJxT6{border:1px solid var(--primary-grey, #eff0f0)}.zwXFl0Hw06qqgtLwHSu8{border:1px solid var(--secondary-blue, #1456ea);background:var(--root-blue-1, #e8eefd)}.KncGY14JUvcFNTRAclYm{cursor:pointer}.KncGY14JUvcFNTRAclYm:hover{background:var(--status-hover-hover, rgba(129, 129, 129, 0.1))}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/Attachment/AttachmentItem/AttachmentItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,2FAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CACA,8CAAA,CACA,8BAAA,CAGJ,sBACI,6CAAA,CAGJ,sBACI,+CAAA,CACA,sCAAA,CAGJ,sBACI,cAAA,CAGJ,4BACI,8DAAA","sourcesContent":[".container {\n    display: flex;\n    padding: var(--Gap-GapL, 8px) var(--Gap-GapM, 4px) var(--Gap-GapL, 8px) var(--Gap-GapL, 8px);\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    flex: 1 0 0;\n    border-radius: var(--CornerRadius-CornerS, 6px);\n    border: 1px solid transparent;\n}\n\n.container__outline {\n    border: 1px solid var(--primary-grey, #eff0f0);\n}\n\n.container__selected {\n    border: 1px solid var(--secondary-blue, #1456ea);\n    background: var(--root-blue-1, #e8eefd);\n}\n\n.container__interactive {\n    cursor: pointer;\n}\n\n.container__interactive:hover {\n    background: var(--status-hover-hover, rgba(129, 129, 129, 0.1));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `O7efCkAcPerSsYoDVgXQ`,
	"container__outline": `pWrrqoKzpQepyfwtJxT6`,
	"container__selected": `zwXFl0Hw06qqgtLwHSu8`,
	"container__interactive": `KncGY14JUvcFNTRAclYm`
};
export default ___CSS_LOADER_EXPORT___;
