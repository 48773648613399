// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Iw2hKW5apJ6877KNNSga{max-height:95vh}.tR1ZdyxqNBNPYAshCx_Z{display:flex;flex-direction:column;max-height:calc(95vh - 250px)}.iabU49HcaHJmPkXzCegd{margin-bottom:24px}._PnAlEY7DntOgf9DfWqC{position:relative;top:-2px;display:inline-flex;min-width:18px;min-height:18px;vertical-align:middle}.Xrq3Qn1Mvy5gX4R2iFzV{position:relative;left:-24px;display:flex;flex-direction:column;align-items:center;gap:12px;width:calc(100% + 48px);background:var(--root-dark-blue-1);margin-top:24px;padding:0 24px 24px 24px;font-size:12px}.ysvl5nF92nWmSpqX_Yjl{display:flex;justify-content:flex-end;align-items:center;flex:1;gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/DataUpdateModal/DataUpdateModal.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,6BAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,iBAAA,CACA,QAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CAGJ,sBACI,iBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,uBAAA,CACA,kCAAA,CACA,eAAA,CACA,wBAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,wBAAA,CACA,kBAAA,CACA,MAAA,CACA,QAAA","sourcesContent":[".modal {\n    max-height: 95vh;\n}\n\n.content-wrapper {\n    display: flex;\n    flex-direction: column;\n    max-height: calc(95vh - 250px);\n}\n\n.infobox {\n    margin-bottom: 24px;\n}\n\n.star {\n    position: relative;\n    top: -2px;\n    display: inline-flex;\n    min-width: 18px;\n    min-height: 18px;\n    vertical-align: middle;\n}\n\n.image {\n    position: relative;\n    left: -24px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 12px;\n    width: calc(100% + 48px);\n    background: var(--root-dark-blue-1);\n    margin-top: 24px;\n    padding: 0 24px 24px 24px;\n    font-size: 12px;\n}\n\n.buttons {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    flex: 1;\n    gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Iw2hKW5apJ6877KNNSga`,
	"content-wrapper": `tR1ZdyxqNBNPYAshCx_Z`,
	"infobox": `iabU49HcaHJmPkXzCegd`,
	"star": `_PnAlEY7DntOgf9DfWqC`,
	"image": `Xrq3Qn1Mvy5gX4R2iFzV`,
	"buttons": `ysvl5nF92nWmSpqX_Yjl`
};
export default ___CSS_LOADER_EXPORT___;
