// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m2s7iUtX6vDsBGlqxpYU{display:inline-flex;padding:4px;align-items:flex-start;gap:4px;border-radius:16px;background:var(--primary-dark-blue, #222d39);height:48px;color:var(--primary-white, #fff);box-shadow:var(--box-shadow-regular)}.xDUn_DQZfUa36yzyU2zx{display:flex;padding:var(--Gap-GapL, 8px) 6px;align-items:center;gap:2px;border-radius:12px}.XobF9L0zyit1MOspuvg4{font-weight:600;margin:0;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/uikit/CreatorHintBox/CreatorHintBox.module.css"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,WAAA,CACA,sBAAA,CACA,OAAA,CACA,kBAAA,CACA,4CAAA,CACA,WAAA,CACA,gCAAA,CAGA,oCAAA,CAGJ,sBACI,YAAA,CACA,gCAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CAGJ,sBACI,eAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".container {\n    display: inline-flex;\n    padding: 4px;\n    align-items: flex-start;\n    gap: 4px;\n    border-radius: 16px;\n    background: var(--primary-dark-blue, #222d39);\n    height: 48px;\n    color: var(--primary-white, #fff);\n\n    /* Elevated 02 */\n    box-shadow: var(--box-shadow-regular);\n}\n\n.content {\n    display: flex;\n    padding: var(--Gap-GapL, 8px) 6px;\n    align-items: center;\n    gap: 2px;\n    border-radius: 12px;\n}\n\n.text {\n    font-weight: 600;\n    margin: 0;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `m2s7iUtX6vDsBGlqxpYU`,
	"content": `xDUn_DQZfUa36yzyU2zx`,
	"text": `XobF9L0zyit1MOspuvg4`
};
export default ___CSS_LOADER_EXPORT___;
