import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { formatLatLng } from '../../../common/geoFormatter';
import { Logo } from '../../../common/icon';
import { round6Decimal } from '../../../common/utils/number';
import { DetailsMetaInformation } from '../../DetailsPanel/DetailsMetaInformation/DetailsMetaInformation';
import { DetailsMetaInformationItem } from '../../DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem';
import { CalendarBlank, Clock, ID, MapPin } from '../../icons';
import styles from './PrintMeta.module.css';
export function PrintMeta({ layerName, icon, title, date, time, id, address, location }) {
    let pinLabel;
    if (location) {
        const lat = round6Decimal(location.lat);
        const lng = round6Decimal(location.lng);
        pinLabel = address
            ? `${address} (${formatLatLng({ lat, lng })})`
            : formatLatLng({ lat, lng });
    }
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['header'] }, { children: [layerName, _jsx(Logo, { variant: "black-yellow" })] })), _jsxs("div", Object.assign({ className: styles['title'] }, { children: [icon, title] })), _jsx("div", Object.assign({ className: styles['meta-info'] }, { children: _jsxs(DetailsMetaInformation, { children: [_jsx(DetailsMetaInformationItem, { icon: _jsx(CalendarBlank, {}), label: date }), _jsx(DetailsMetaInformationItem, { icon: _jsx(Clock, {}), label: time }), _jsx(DetailsMetaInformationItem, { icon: _jsx(ID, {}), label: id }), pinLabel && (_jsx(DetailsMetaInformationItem, { icon: _jsx(MapPin, {}), label: pinLabel, "data-test": "task-coords" }))] }) }))] })));
}
