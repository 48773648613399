import { getAssetFilterParams } from '../services/asset';
import { getInspectionFilterParams } from '../services/inspection';
import { getFilterParams } from '../services/sign';
import { getSignInventoryFilterParams } from '../services/signInventory';
import { DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN } from '../state/slices/leftPanelCondition';
import { getTimeParams } from './utils/params';
import { rangeToValues } from './range';
const MAX_GRADE = 5;
export const createTimePickerTiles = (baseTileEndpoint, timepicker) => {
    const query = {
        timePickerRange: timepicker
    };
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createConditionTiles = (baseTileEndpoint, timepicker, filter) => {
    const damages = {};
    const orQueries = filter.damageType
        .filter((damage) => !damage.isExcluded)
        .map((damage) => {
        return {
            [damage.name]: {
                $gt: damage.range.min,
                $lte: damage.range.max === MAX_GRADE ? undefined : damage.range.max
            }
        };
    });
    if (orQueries.length) {
        damages.$or = orQueries;
    }
    const andQueries = filter.damageType
        .filter((damage) => damage.isExcluded)
        .map((damage) => {
        return {
            [damage.name]: {
                $not: {
                    $gt: damage.range.min,
                    $lte: damage.range.max === MAX_GRADE ? undefined : damage.range.max
                }
            }
        };
    });
    if (andQueries.length) {
        damages.$and = andQueries;
    }
    const grades = filter.grade.max === DEFAULT_GRADE_MAX && filter.grade.min === DEFAULT_GRADE_MIN
        ? []
        : rangeToValues(filter.grade);
    const query = {
        grades,
        timePickerRange: timepicker,
        segmentLengthMin: filter.segmentLengthMin,
        segmentLengthMax: filter.segmentLengthMax,
        damages,
        streetTypes: filter.streetType,
        surfaceTypes: filter.surfaceType
    };
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createReferencingTiles = (baseTileEndpoint) => {
    return [`${baseTileEndpoint}?t=${Date.now()}`];
};
export const createObjectTiles = (baseTileEndpoint, timepicker, filter) => {
    const query = {
        timePickerRange: timepicker,
        objectNames: [...filter.manholeType, ...filter.stormDrainType]
    };
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createInspectionTiles = (baseTileEndpoint, timepicker, filter) => {
    const query = {
        timePickerRange: timepicker,
        ages: filter.ages
    };
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createInspectionV2Tiles = (baseTileEndpoint, timepicker, filter) => {
    const query = {
        timePickerRange: getTimeParams(timepicker),
        filter: getInspectionFilterParams(filter)
    };
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createSignTiles = (baseTileEndpoint, timepicker, filter, showHiddenSigns) => {
    const query = Object.assign({ timePickerRange: timepicker, showHiddenSigns }, getFilterParams(filter));
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createSignInventoryTiles = (baseTileEndpoint, timepicker, filter, showHiddenSigns) => {
    const query = Object.assign({ timePickerRange: timepicker, showHiddenSigns }, getSignInventoryFilterParams(filter));
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
export const createAssetTiles = (baseTileEndpoint, timepicker, filter) => {
    const query = Object.assign({ timePickerRange: getTimeParams(timepicker) }, getAssetFilterParams(filter));
    return [`${baseTileEndpoint}?t=${Date.now()}&query=${JSON.stringify(query)}`];
};
