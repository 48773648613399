import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ExportModal } from '../../components/Modals/ExportModal/ExportModal';
import { useModalContext } from '../../state/context';
export function ExportModalContainer({ onStartExportClick, requestList }) {
    const { modals, removeModal } = useModalContext();
    function handleCloseClick() {
        if (modals[modals.length - 1].id !== 'ExportModal') {
            return;
        }
        closeModal();
    }
    function closeModal() {
        removeModal('ExportModal');
    }
    function handleStartExportClick() {
        closeModal();
        onStartExportClick && onStartExportClick();
    }
    return (_jsx(ExportModal, { onStartExportClick: handleStartExportClick, onClose: handleCloseClick, requestList: requestList }));
}
