import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { classes } from 'classifizer';
import { addMinutes, differenceInMinutes, endOfDay, format, isAfter, isBefore, isSameDay, isSameMonth, isValid, parse, startOfDay, startOfYear, subMinutes } from 'date-fns';
import { Ditto } from 'ditto-react';
import { isDefined } from '../../common/utils/array';
import { getDateFnsLocale } from '../../common/utils/locale';
import { getDatePickerDateFormat, getDatePickerTimeFormat, stripIllegalDateCharacters, stripIllegalTimeCharacters } from '../../common/utils/time';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { ActionModal } from '../ActionModal/ActionModal';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { AssigneeSelectField } from '../EditorForms/AssigneeSelect/AssigneeSelectField/AssigneeSelectField';
import { FloatingDropdownWrapper } from '../FloatingDropdownWrapper/FloatingDropdownWrapper';
import { FormInputText } from '../FormInputText/FormInputText';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import { CustomDayWrapper } from './CustomDay';
import { CustomHeader } from './CustomHeader';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './WorkingHoursPicker.module.css';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MIN_DATE = startOfYear(new Date(2000, 0, 1));
export function WorkingHoursPicker({ locale: localeSupported, countryCode, workersList, selectedWorkerIds = [], onWorkersFieldClick, onDatesChange, formatter, start, finish, readonly, readonlyWorker }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const dateLabel = useDittoWrapper({ componentId: 'date' });
    const timeLabel = useDittoWrapper({ componentId: 'time' });
    const startedDateLabel = useDittoWrapper({
        componentId: 'tasks.costs.workinghours.started'
    });
    const finishedDateLabel = useDittoWrapper({ componentId: 'tasks.costs.finished' });
    const invalidFormatLabel = useDittoWrapper({ componentId: 'invalidformat' });
    const emptyInputLabel = useDittoWrapper({ componentId: 'errorpage.cantbeempty' });
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    const cantBeLaterLabel = useDittoWrapper({ componentId: 'errorpage.cantbelaterthanfinished' });
    const cantBeEarlierLabel = useDittoWrapper({
        componentId: 'errorpage.cantbeearlierthanstarted'
    });
    const datePickerDateFormat = getDatePickerDateFormat(countryCode);
    const datePickerTimeFormat = getDatePickerTimeFormat(countryCode);
    const locale = getDateFnsLocale(localeSupported, countryCode);
    const [state, dispatch] = useReducer(reducer(datePickerDateFormat, datePickerTimeFormat), initializeState(datePickerDateFormat, datePickerTimeFormat, start, finish));
    const [isRenderingMonth, setIsRenderingMonth] = useState(false);
    const [calendarViewMonthDate, setCalendarViewMonthDate] = useState(null);
    const [isChoosingStartDate, setIsChoosingStartDate] = useState(true);
    const [isStartDateInputInFocus, setIsStartDateInputInFocus] = useState(false);
    const [isFinishDateInputInFocus, setIsFinishDateInputInFocus] = useState(false);
    const startDateError = (((_a = state.error) === null || _a === void 0 ? void 0 : _a.start) === 'empty' && emptyInputLabel) ||
        (((_b = state.error) === null || _b === void 0 ? void 0 : _b.start) === 'invalid' && invalidFormatLabel) ||
        (((_c = state.error) === null || _c === void 0 ? void 0 : _c.start) === 'later' && cantBeLaterLabel);
    const finishDateError = (((_d = state.error) === null || _d === void 0 ? void 0 : _d.finish) === 'empty' && emptyInputLabel) ||
        (((_e = state.error) === null || _e === void 0 ? void 0 : _e.finish) === 'invalid' && invalidFormatLabel) ||
        (((_f = state.error) === null || _f === void 0 ? void 0 : _f.finish) === 'earlier' && cantBeEarlierLabel);
    const startTimeError = (((_g = state.error) === null || _g === void 0 ? void 0 : _g.startTime) === 'empty' && emptyInputLabel) ||
        (((_h = state.error) === null || _h === void 0 ? void 0 : _h.startTime) === 'invalid' && invalidFormatLabel) ||
        (((_j = state.error) === null || _j === void 0 ? void 0 : _j.startTime) === 'later' && cantBeLaterLabel);
    const finishTimeError = (((_k = state.error) === null || _k === void 0 ? void 0 : _k.finishTime) === 'empty' && emptyInputLabel) ||
        (((_l = state.error) === null || _l === void 0 ? void 0 : _l.finishTime) === 'invalid' && invalidFormatLabel) ||
        (((_m = state.error) === null || _m === void 0 ? void 0 : _m.finishTime) === 'earlier' && cantBeEarlierLabel);
    const totalSpentTime = getTotalSpentTime();
    useEffect(() => {
        var _a;
        const valid = !state.init &&
            isBefore(state.dates.start, state.dates.finish) &&
            (typeof state.error === 'undefined' ||
                Object.values((_a = state.error) !== null && _a !== void 0 ? _a : {}).every((prop) => typeof prop === 'undefined'));
        onDatesChange(state.dates.start, state.dates.finish, valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dates.start, state.dates.finish, state.error]);
    function getTotalSpentTime() {
        var _a;
        // No total time spent if in initial state or there is an error
        if (state.init || Object.values((_a = state.error) !== null && _a !== void 0 ? _a : {}).some(isDefined)) {
            return undefined;
        }
        // Get the difference in minutes and convert to hours, format with two decimal places
        const diffMinutes = differenceInMinutes(state.dates.finish, state.dates.start);
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return formatter.formatNumber(diffMinutes / 60, 2);
    }
    const getTimeSelectorOptions = useCallback((field) => {
        var _a, _b, _c, _d;
        const DIFF_MINUTES = 30;
        const start = startOfDay(field === 'start' ? state.dates.start : state.dates.finish);
        const until = subMinutes(endOfDay(field === 'start' ? state.dates.start : state.dates.finish), DIFF_MINUTES);
        const options = [
            { timestamp: start.getTime(), formatted: format(start, datePickerTimeFormat) }
        ];
        while (isBefore((_b = (_a = options.at(-1)) === null || _a === void 0 ? void 0 : _a.timestamp) !== null && _b !== void 0 ? _b : 0, until)) {
            const time = addMinutes((_d = (_c = options.at(-1)) === null || _c === void 0 ? void 0 : _c.timestamp) !== null && _d !== void 0 ? _d : 0, DIFF_MINUTES);
            const entry = {
                timestamp: time.getTime(),
                formatted: format(time, datePickerTimeFormat)
            };
            options.push(entry);
        }
        return options;
    }, [datePickerTimeFormat, state.dates.finish, state.dates.start]);
    const startTimeSelectorOptions = useMemo(() => getTimeSelectorOptions('start'), [getTimeSelectorOptions]);
    const finishTimeSelectorOptions = useMemo(() => getTimeSelectorOptions('finish'), [getTimeSelectorOptions]);
    function handleStartTimeSelect(time) {
        dispatch({
            type: 'setStartTimeInput',
            payload: time
        });
    }
    function handleFinishTimeSelect(time) {
        dispatch({
            type: 'setFinishTimeInput',
            payload: time
        });
    }
    function handleCalendarMonthChange(date) {
        if (readonly) {
            return;
        }
        setCalendarViewMonthDate(date);
    }
    function handleMonthSelect(date) {
        if (readonly) {
            return;
        }
        setIsRenderingMonth(false);
        setCalendarViewMonthDate(date);
    }
    function handleStartDateClick() {
        setIsStartDateInputInFocus(true);
        setIsFinishDateInputInFocus(false);
        setIsChoosingStartDate(true);
    }
    function handleFinishDateClick() {
        setIsStartDateInputInFocus(false);
        setIsFinishDateInputInFocus(true);
        setIsChoosingStartDate(false);
    }
    function handleStartDateInputFocus() {
        handleStartDateClick();
    }
    function handleStartDateInputBlur() {
        setIsStartDateInputInFocus(false);
        setIsFinishDateInputInFocus(false);
        if (state.init) {
            dispatch({
                type: 'setStartTimeInput',
                payload: ''
            });
        }
    }
    function handleFinishDateInputFocus() {
        handleFinishDateClick();
    }
    function handleFinishDateInputBlur() {
        setIsStartDateInputInFocus(false);
        setIsFinishDateInputInFocus(false);
    }
    function handleCalendarDateSelect(date) {
        if (readonly) {
            return;
        }
        if (!date) {
            return;
        }
        if (isChoosingStartDate) {
            dispatch({
                type: 'setStartDate',
                payload: date
            });
            setIsChoosingStartDate(false);
        }
        else {
            dispatch({
                type: 'setFinishDate',
                payload: date
            });
            setIsChoosingStartDate(true);
        }
        setCalendarViewMonthDate(date);
    }
    function handleStartDateFieldChange(value) {
        const stripped = stripIllegalDateCharacters(value);
        dispatch({
            type: 'setStartInput',
            payload: stripped
        });
    }
    function handleStartDateTimeFieldChange(value) {
        const stripped = stripIllegalTimeCharacters(value, countryCode);
        dispatch({
            type: 'setStartTimeInput',
            payload: stripped
        });
    }
    function handleFinishDateFieldChange(value) {
        const stripped = stripIllegalDateCharacters(value);
        dispatch({
            type: 'setFinishInput',
            payload: stripped
        });
    }
    function handleFinishDateTimeFieldChange(value) {
        const stripped = stripIllegalTimeCharacters(value, countryCode);
        dispatch({
            type: 'setFinishTimeInput',
            payload: stripped
        });
    }
    function handleWorkersFieldClick() {
        onWorkersFieldClick === null || onWorkersFieldClick === void 0 ? void 0 : onWorkersFieldClick();
    }
    function handleStartInputScrollTo(index) {
        var _a, _b, _c, _d;
        if (state.init) {
            return !!((_a = startTimeSelectorOptions.at(index)) === null || _a === void 0 ? void 0 : _a.formatted.startsWith('07'));
        }
        if (state.inputs.startTime === '') {
            return false;
        }
        const start = (_b = state.inputs.startTime.split(':').at(0)) !== null && _b !== void 0 ? _b : '';
        return (!!((_c = startTimeSelectorOptions.at(index)) === null || _c === void 0 ? void 0 : _c.formatted.startsWith(start)) ||
            !!((_d = startTimeSelectorOptions.at(index)) === null || _d === void 0 ? void 0 : _d.formatted.startsWith('0' + start)));
    }
    function handleFinishInputScrollTo(index) {
        var _a, _b, _c;
        if (state.inputs.finishTime === '') {
            return false;
        }
        const finish = (_a = state.inputs.finishTime.split(':').at(0)) !== null && _a !== void 0 ? _a : '';
        return (!!((_b = finishTimeSelectorOptions.at(index)) === null || _b === void 0 ? void 0 : _b.formatted.startsWith(finish)) ||
            !!((_c = finishTimeSelectorOptions.at(index)) === null || _c === void 0 ? void 0 : _c.formatted.startsWith('0' + finish)));
    }
    const selected = (() => {
        if (isRenderingMonth) {
            return undefined;
        }
        if (isStartDateInputInFocus && state.dates.start) {
            return state.dates.start;
        }
        if (isFinishDateInputInFocus && state.dates.finish) {
            return state.dates.finish;
        }
        if (!state.dates.start && calendarViewMonthDate) {
            return calendarViewMonthDate;
        }
        else if (!state.dates.start) {
            return new Date();
        }
    })();
    if (selected && calendarViewMonthDate && !isSameDay(selected, calendarViewMonthDate)) {
        setCalendarViewMonthDate(selected);
    }
    else if (selected && !calendarViewMonthDate) {
        setCalendarViewMonthDate(selected);
    }
    const calendar = (_jsx(CustomDatePicker, { showMonthYearPicker: isRenderingMonth, monthClassName: (date) => {
            if (calendarViewMonthDate && isSameMonth(date, calendarViewMonthDate)) {
                return styles['month--selected'];
            }
            return null;
        }, minDate: MIN_DATE, startDate: state.dates.start, endDate: state.dates.finish, selected: selected, onChange: isRenderingMonth ? handleMonthSelect : handleCalendarDateSelect, locale: locale, dateFormat: datePickerDateFormat, renderCustomHeader: (params) => (_jsx(CustomHeader, Object.assign({ locale: locale, isRenderingMonth: isRenderingMonth, toggleMonthYear: () => !readonly && setIsRenderingMonth(!isRenderingMonth), disabled: readonly }, params, { "data-test": "current-year" }))), renderDayContents: (dayOfMonth, date) => (_jsx(CustomDayWrapper, { dayOfMonth: dayOfMonth, date: date, startDate: state.dates.start, endDate: state.dates.finish, selectedDate: state.dates.finish, minDate: MIN_DATE, highlightedDates: [] }, date === null || date === void 0 ? void 0 : date.getTime())), inline: true, disabledKeyboardNavigation: true, onMonthChange: handleCalendarMonthChange, disabled: readonly }));
    const dateRangeInputs = (_jsxs("div", Object.assign({ className: styles['content'] }, { children: [_jsx("span", Object.assign({ className: styles['input-label'] }, { children: startedDateLabel })), _jsxs("div", Object.assign({ className: styles['content-row'] }, { children: [_jsx("div", Object.assign({ className: styles['content-half'] }, { children: _jsx(FormInputText, { label: dateLabel, value: state.inputs.start, placeholder: notSpecifiedLabel, onValueChange: handleStartDateFieldChange, error: startDateError || undefined, onClick: handleStartDateClick, onFocus: handleStartDateInputFocus, onBlur: handleStartDateInputBlur, variant: readonly ? 'ghost' : 'editor-field', disabled: readonly }) })), _jsx("div", Object.assign({ className: styles['content-half'] }, { children: _jsx(FloatingDropdownWrapper, { control: _jsx(FormInputText, { label: timeLabel, value: state.inputs.startTime, placeholder: notSpecifiedLabel, onValueChange: handleStartDateTimeFieldChange, error: startTimeError || undefined, onClick: handleStartDateClick, onFocus: handleStartDateInputFocus, onBlur: handleStartDateInputBlur, variant: readonly ? 'ghost' : 'editor-field', disabled: readonly }), menu: _jsx(ActionModal, Object.assign({ width: 164, maxHeight: 227, scrollTo: handleStartInputScrollTo }, { children: startTimeSelectorOptions.map((item) => (_jsx(SelectboxEntry, { text: item.formatted, size: "medium", onClick: () => handleStartTimeSelect(item.formatted) }, item.timestamp))) })), 
                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                            topOffset: ((_o = state.error) === null || _o === void 0 ? void 0 : _o.startTime) ? -20 : undefined }) }))] })), _jsx("span", Object.assign({ className: classes(styles['input-label'], styles['input-label-second']) }, { children: finishedDateLabel })), _jsxs("div", Object.assign({ className: styles['content-row'] }, { children: [_jsx("div", Object.assign({ className: styles['content-half'] }, { children: _jsx(FormInputText, { label: dateLabel, value: state.inputs.finish, placeholder: notSpecifiedLabel, onValueChange: handleFinishDateFieldChange, error: finishDateError || undefined, onClick: handleFinishDateClick, onFocus: handleFinishDateInputFocus, onBlur: handleFinishDateInputBlur, variant: readonly ? 'ghost' : 'editor-field', disabled: readonly }) })), _jsx("div", Object.assign({ className: styles['content-half'] }, { children: _jsx(FloatingDropdownWrapper, { control: _jsx(FormInputText, { label: timeLabel, value: state.inputs.finishTime, placeholder: notSpecifiedLabel, onValueChange: handleFinishDateTimeFieldChange, error: finishTimeError || undefined, onClick: handleFinishDateClick, onFocus: handleFinishDateInputFocus, onBlur: handleFinishDateInputBlur, variant: readonly ? 'ghost' : 'editor-field', disabled: readonly }), menu: _jsx(ActionModal, Object.assign({ width: 164, maxHeight: 227, scrollTo: handleFinishInputScrollTo }, { children: finishTimeSelectorOptions.map((item) => (_jsx(SelectboxEntry, { text: item.formatted, size: "medium", onClick: () => handleFinishTimeSelect(item.formatted) }, item.timestamp))) })), 
                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                            topOffset: ((_p = state.error) === null || _p === void 0 ? void 0 : _p.finishTime) ? -20 : undefined }) }))] }))] })));
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", Object.assign({ className: classes(styles['column'], styles['column-calendar']) }, { children: calendar })), _jsx("div", { className: styles['column-divider'] }), _jsxs("div", Object.assign({ className: classes(styles['column'], styles['column-details']) }, { children: [dateRangeInputs, totalSpentTime && (_jsx("div", Object.assign({ className: styles['total'] }, { children: _jsx(Ditto, { componentId: "tasks.costs.workinghours.totaltimespentxhours", variables: { TotalTimeSpent: totalSpentTime } }) }))), !readonly && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles['row-divider'] }), _jsx(AssigneeSelectField, { options: workersList, selectedValues: selectedWorkerIds, onClick: handleWorkersFieldClick, width: '100%', label: _jsx(Ditto, { componentId: "tasks.costs.workinghours.worker" }), disabled: readonlyWorker, variant: readonlyWorker ? 'ghost' : undefined })] }))] }))] })));
}
const actionDateToStateMap = {
    setStartDate: 'start',
    setFinishDate: 'finish'
};
const actionInputToStateMap = {
    setStartInput: 'start',
    setFinishInput: 'finish',
    setStartTimeInput: 'startTime',
    setFinishTimeInput: 'finishTime'
};
function validateState(errorInput, field, startDate, finishDate) {
    let error = Object.assign({}, errorInput);
    if (field === 'start') {
        if (isAfter(startDate, finishDate)) {
            error.start = 'later';
        }
    }
    else if (field === 'finish') {
        if (!isBefore(startDate, finishDate)) {
            error.finish = 'earlier';
        }
    }
    else if (field === 'startTime') {
        if (isAfter(startDate, finishDate)) {
            error.startTime = 'later';
        }
    }
    else if (field === 'finishTime') {
        if (!isBefore(startDate, finishDate)) {
            error.finishTime = 'earlier';
        }
    }
    if (isBefore(startDate, finishDate)) {
        for (const [key, prop] of Object.entries(error)) {
            if (prop === 'earlier' || prop === 'later') {
                error[key] = undefined;
            }
        }
    }
    return error;
}
function inputReducer(state, payload, field, dateFormat, timeFormat) {
    // We don't use startTime & finishTime in the state as date objects,
    // since 'start' and 'finish' properties store the Date object with date & time
    let dateField;
    if (field === 'startTime') {
        dateField = 'start';
    }
    else if (field === 'finishTime') {
        dateField = 'finish';
    }
    else {
        dateField = field;
    }
    let date = state.dates[dateField];
    let error = Object.assign({}, state.error);
    if (payload === '') {
        error[field] = 'empty';
    }
    else {
        // Parse date & time based on given field
        const action = {
            start: (referenceDate) => {
                const newDate = parse(payload, dateFormat, referenceDate);
                if (isValid(newDate)) {
                    date = newDate;
                    error.start = undefined;
                }
                else {
                    error.start = 'invalid';
                }
            },
            finish: (referenceDate) => {
                const newDate = parse(payload, dateFormat, referenceDate);
                if (isValid(newDate)) {
                    date = newDate;
                    error.finish = undefined;
                }
                else {
                    error.finish = 'invalid';
                }
            },
            startTime: (referenceDate, input) => {
                const dateTime = parse(input !== null && input !== void 0 ? input : payload, timeFormat, referenceDate);
                if (input === '') {
                    error.startTime = 'empty';
                }
                else if (isValid(dateTime)) {
                    date.setHours(dateTime.getHours());
                    date.setMinutes(dateTime.getMinutes());
                    error.startTime = undefined;
                }
                else {
                    error.startTime = 'invalid';
                }
            },
            finishTime: (referenceDate, input) => {
                const dateTime = parse(input !== null && input !== void 0 ? input : payload, timeFormat, referenceDate);
                if (input === '') {
                    error.finishTime = 'empty';
                }
                else if (isValid(dateTime)) {
                    date.setHours(dateTime.getHours());
                    date.setMinutes(dateTime.getMinutes());
                    error.finishTime = undefined;
                }
                else {
                    error.finishTime = 'invalid';
                }
            }
        };
        action[field](date);
        // Apply startTime and finishTime input values to the date
        if (field === 'start') {
            action.startTime(date, state.inputs.startTime);
        }
        else if (field === 'finish') {
            action.finishTime(date, state.inputs.finishTime);
        }
        if (dateField === 'start') {
            error = Object.assign({}, validateState(error, field, date, state.dates.finish));
        }
        else if (dateField === 'finish') {
            error = Object.assign({}, validateState(error, field, state.dates.start, date));
        }
    }
    return {
        init: false,
        inputs: Object.assign(Object.assign({}, state.inputs), { [field]: payload }),
        dates: Object.assign(Object.assign({}, state.dates), { [dateField]: date }),
        error: Object.assign({}, error)
    };
}
function dateReducer(state, payload, field, dateFormat, timeFormat) {
    let newState = Object.assign({}, state);
    if (field === 'start') {
        const reduced = inputReducer(state, format(payload, dateFormat), 'start', dateFormat, timeFormat);
        return {
            init: false,
            inputs: Object.assign(Object.assign({}, newState.inputs), reduced.inputs),
            dates: Object.assign(Object.assign({}, newState.dates), reduced.dates),
            error: Object.assign(Object.assign({}, newState.error), reduced.error)
        };
    }
    else if (field === 'finish') {
        const reduced = inputReducer(state, format(payload, dateFormat), 'finish', dateFormat, timeFormat);
        return {
            init: false,
            inputs: Object.assign(Object.assign({}, newState.inputs), reduced.inputs),
            dates: Object.assign(Object.assign({}, newState.dates), reduced.dates),
            error: Object.assign(Object.assign({}, newState.error), reduced.error)
        };
    }
    throw new Error();
}
function reducer(datePickerDateFormat, datePickerTimeFormat) {
    return function (state, action) {
        if (Object.keys(actionInputToStateMap).includes(action.type)) {
            const actionAsSetInput = action;
            const newState = inputReducer(state, actionAsSetInput.payload, actionInputToStateMap[actionAsSetInput.type], datePickerDateFormat, datePickerTimeFormat);
            return newState;
        }
        else if (Object.keys(actionDateToStateMap).includes(action.type)) {
            const actionAsSetDate = action;
            const newState = dateReducer(state, actionAsSetDate.payload, actionDateToStateMap[actionAsSetDate.type], datePickerDateFormat, datePickerTimeFormat);
            return newState;
        }
        throw Error('Unknown action.');
    };
}
const initializeState = (datePickerDateFormat, datePickerTimeFormat, start, finish) => {
    if (start && finish) {
        return {
            init: false,
            inputs: {
                start: format(start, datePickerDateFormat),
                startTime: format(start, datePickerTimeFormat),
                finish: format(finish, datePickerDateFormat),
                finishTime: format(finish, datePickerTimeFormat)
            },
            dates: {
                start: start,
                finish: finish
            }
        };
    }
    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    return {
        init: true,
        inputs: {
            start: format(now, datePickerDateFormat),
            startTime: '',
            finish: format(now, datePickerDateFormat),
            finishTime: format(now, datePickerTimeFormat)
        },
        dates: {
            start: startOfDay(now),
            finish: now
        }
    };
};
