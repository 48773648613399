// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u2SbaK3V8KJa2k9V054y{table-layout:fixed;border-collapse:collapse;z-index:0;width:100%;min-height:100%}.u2SbaK3V8KJa2k9V054y *::before,.u2SbaK3V8KJa2k9V054y *::after{box-sizing:border-box;pointer-events:none}.u2SbaK3V8KJa2k9V054y tr::after{pointer-events:initial}.u2SbaK3V8KJa2k9V054y:hover tr::after{pointer-events:none}.aE62SDFLY15avDTijTLF{position:relative;z-index:0;height:100%}.h7ciH789fjU4fbH1xTuH{position:fixed;width:calc(100vw - 72px);height:calc(100% - 188px);z-index:1;background:rgba(255,255,255,.6)}.QEONB93eiRPGP17Fn4ed{background:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTable.module.css"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CACA,wBAAA,CACA,SAAA,CACA,UAAA,CACA,eAAA,CAGJ,+DAEI,qBAAA,CACA,mBAAA,CAGJ,gCACI,sBAAA,CAGJ,sCACI,mBAAA,CAGJ,sBACI,iBAAA,CACA,SAAA,CACA,WAAA,CAGJ,sBACI,cAAA,CACA,wBAAA,CACA,yBAAA,CACA,SAAA,CACA,+BAAA,CAGJ,sBACI,wBAAA","sourcesContent":[".container {\n    table-layout: fixed;\n    border-collapse: collapse;\n    z-index: 0;\n    width: 100%;\n    min-height: 100%;\n}\n\n.container *::before,\n.container *::after {\n    box-sizing: border-box;\n    pointer-events: none;\n}\n\n.container tr::after {\n    pointer-events: initial;\n}\n\n.container:hover tr::after {\n    pointer-events: none;\n}\n\n.wrapper {\n    position: relative;\n    z-index: 0;\n    height: 100%;\n}\n\n.loading {\n    position: fixed;\n    width: calc(100vw - 72px);\n    height: calc(100% - 188px);\n    z-index: 1;\n    background: rgba(255, 255, 255, 0.6);\n}\n\n.loading--in-transition {\n    background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `u2SbaK3V8KJa2k9V054y`,
	"wrapper": `aE62SDFLY15avDTijTLF`,
	"loading": `h7ciH789fjU4fbH1xTuH`,
	"loading--in-transition": `QEONB93eiRPGP17Fn4ed`
};
export default ___CSS_LOADER_EXPORT___;
