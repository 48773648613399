import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Close, Folder } from '../../components/icons';
import { Modal } from '../../components/Modal/Modal';
import { useModalContext } from '../../state/context';
import { Button } from '../../uikit/Button/Button';
import { RadioSelectbox } from '../../uikit/RadioSelectbox/RadioSelectbox';
import styles from './FileUploadModalContainer.module.css';
export function FileUploadModalContainer({ folders, onCloseClick, onUploadClick }) {
    const { removeModal } = useModalContext();
    const [selectedFolderId, setSelectedFolderId] = useState();
    function handleFolderClick(folderId) {
        setSelectedFolderId(folderId);
    }
    function handleCloseClick() {
        onCloseClick();
        removeModal('FileUploadModal');
    }
    function handleUploadClick() {
        if (typeof selectedFolderId === 'undefined') {
            throw new Error();
        }
        onUploadClick(selectedFolderId);
        removeModal('FileUploadModal');
    }
    function getFolderName(folder) {
        var _a;
        if (folder.componentId === '') {
            return folder.name;
        }
        return _jsx(Ditto, { componentId: (_a = folder.componentId) !== null && _a !== void 0 ? _a : '' });
    }
    return (_jsx(Modal, { width: 680, title: _jsx(Ditto, { componentId: "addfilestofolder" }), trailing: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: folders.map((folder) => (_jsx(RadioSelectbox, { icon: _jsx("div", Object.assign({ className: styles['folder-icon'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Folder, {}) })) })), content: getFolderName(folder), isChecked: folder.id === selectedFolderId, onClick: () => handleFolderClick(folder.id) }, folder.id))), footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleCloseClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })), _jsx("div", Object.assign({ className: styles['button-upload'] }, { children: _jsx(Button, Object.assign({ color: "dark", variant: 'fullwidth', disabled: typeof selectedFolderId === 'undefined', onClick: handleUploadClick }, { children: _jsx(Ditto, { componentId: "upload" }) })) }))] }))] })) }));
}
