import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { RolePermissionsModal } from '../../components/Modals/RolePermissionsModal/RolePermissionsModal';
import { useModalContext } from '../../state/context';
export function RolePermissionsModalContainer() {
    const { removeModal, modals } = useModalContext();
    function closeModal() {
        removeModal('RolePermissionsModal');
    }
    function handleCancelClick() {
        closeModal();
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'RolePermissionsModal') {
            return;
        }
        handleCancelClick();
    }
    return (_jsx(RolePermissionsModal, { onCloseClick: handleCancelClick, onClickOutside: handleClickOutside }));
}
