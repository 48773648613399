// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgyQe3Bz4XTw14llFIpY{display:flex;align-items:center;margin-bottom:20px;color:#ff3e3e}.Iuh5l3qkDNr7OfklJmUk{margin-right:4px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/UpdatePasswordModal/UpdatePasswordModal.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CAGJ,sBACI,gBAAA","sourcesContent":[".warning {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n    color: rgba(255, 62, 62, 1);\n}\n\n.icon {\n    margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": `bgyQe3Bz4XTw14llFIpY`,
	"icon": `Iuh5l3qkDNr7OfklJmUk`
};
export default ___CSS_LOADER_EXPORT___;
