import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { FilterSelectbox } from '../FilterSelectbox/FilterSelectbox';
import { RangeSlider } from '../RangeSlider/RangeSlider';
import styles from './DamageFilter.module.css';
export function DamageFilter({ name, value, isChecked, isExcluded, onChange, onFilterClick, onExcludeClick, 'data-test': dataTest }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container']), "data-test": dataTest }, { children: [_jsxs("div", Object.assign({ className: classes(styles['checkboxes-row']) }, { children: [_jsx(FilterSelectbox, { onClick: onFilterClick, isChecked: isChecked, content: name, color: "black", wrapContent: true }), isChecked && (_jsx(FilterSelectbox, { className: styles['exclude-select'], isChecked: isExcluded, onClick: onExcludeClick, content: _jsx(Ditto, { componentId: "filter.exclude" }), color: "red" }))] })), isChecked && (_jsx("div", Object.assign({ className: classes(styles['slider']) }, { children: _jsx(RangeSlider, { min: 1, max: 5, step: 0.5, value: value, color: isExcluded ? 'red' : 'black', captions: [1, 2, 3, 4, 5], onChange: onChange, isExcluded: isExcluded }) })))] })));
}
