import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { CoWorkerModal } from '../../components/Modals/CoWorkerModal/CoWorkerModal';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { deleteInvite, fetchInvitations, resendInvite, setUserRole } from '../../state/slices/coWorkerManagement';
function getListData(users, invitations, teamAccount, userAccount, departmentList, roleList) {
    const listData = [];
    users.forEach((user) => {
        var _a, _b, _c;
        const currentAccount = (_a = user.accounts) === null || _a === void 0 ? void 0 : _a.find((acc) => acc.username === (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.username));
        if (!currentAccount) {
            return;
        }
        const departmentNameId = (_b = departmentList.find((department) => department.id === user.departmentId)) === null || _b === void 0 ? void 0 : _b.name;
        const roleId = currentAccount.roleId;
        const roleName = (_c = roleList.find((role) => role.id === roleId)) === null || _c === void 0 ? void 0 : _c.name;
        listData.push({
            uuid: user.uuid,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email,
            phoneNumber: user.phoneNumber,
            profileImageUrl: user.profileImageUrl,
            isCurrentUser: user.uuid === (userAccount === null || userAccount === void 0 ? void 0 : userAccount.uuid),
            roleId: roleId || 0,
            roleName: roleName || '',
            departmentNameId: departmentNameId || '',
            isInviteType: false
        });
    });
    invitations.forEach((invitation) => {
        var _a;
        const roleName = (_a = roleList.find((role) => role.id === invitation.roleId)) === null || _a === void 0 ? void 0 : _a.name;
        listData.push({
            uuid: invitation.id.toString(),
            firstName: invitation.firstName || '',
            lastName: invitation.lastName || '',
            email: invitation.email,
            phoneNumber: invitation.phoneNumber,
            isCurrentUser: false,
            roleId: invitation.roleId,
            roleName: roleName || '',
            departmentNameId: '',
            isInviteType: true
        });
    });
    return listData;
}
export function CoWorkerModalContainer() {
    const dispatch = useAppDispatch();
    const { addModal, removeModal, modals } = useModalContext();
    const { departmentList, status } = useDepartmentsData();
    const { roleList, isAdmin } = useUserRole();
    const { teamAccount, userAccount } = useAppSelector((state) => state.auth);
    const { fetchInvitationsStatus, invitations } = useAppSelector((state) => state.coWorkerManagement);
    const { users } = useAppSelector((state) => state.shared);
    useEffect(() => {
        dispatch(fetchInvitations());
    }, [dispatch]);
    function closeModal() {
        removeModal('CoWorkerModal');
    }
    function handleCancelClick() {
        closeModal();
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'CoWorkerModal') {
            return;
        }
        handleCancelClick();
    }
    function handleAddCoWorkerClick() {
        addModal({ id: 'AddCoWorkerModal' });
    }
    function handleRoleAndPermissionsClick() {
        addModal({ id: 'RolePermissionsModal' });
    }
    function handleResendInvite(email, phoneNumber) {
        dispatch(resendInvite({ email, phoneNumber }));
    }
    function handleDeleteInvite(email, phoneNumber) {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteInvite({ email, phoneNumber }));
                },
                title: _jsx(Ditto, { componentId: "prompt.delete.invite" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.invite.text" })
            }
        });
    }
    function handleChangeUserRole(uuid, roleId) {
        dispatch(setUserRole({ uuid, roleId }));
    }
    const listData = getListData(users, invitations, teamAccount, userAccount, departmentList, roleList);
    return (_jsx(CoWorkerModal, { listData: listData, isAdmin: isAdmin, loading: status === 'loading' || fetchInvitationsStatus === 'loading', roleList: roleList, onAddCoWorkerClick: handleAddCoWorkerClick, onChangeUserRole: handleChangeUserRole, onCancelClick: handleCancelClick, onClickOutside: handleClickOutside, onDeleteInvite: handleDeleteInvite, onResendInvite: handleResendInvite, onRoleAndPermissionsClick: handleRoleAndPermissionsClick }));
}
