import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { bbox } from '@turf/turf';
import { useAppSelector } from '../../../state/hooks';
import { safeRemoveFeatureState, safeSetFeatureState } from '../features';
import { selectedState } from '../featureStates';
import { PLANS_SOURCE_ID } from '..';
const PLAN_MAP_PADDING = 50;
const PLAN_MAP_ANIMATION_MS = 150;
export function PlanMinimapController() {
    const { current: map } = useMap();
    const { currentPlan } = useAppSelector((state) => state.plan);
    const { planGeoJSON } = useAppSelector((state) => state.mainMap);
    useEffect(() => {
        if (map === undefined || !planGeoJSON || !currentPlan) {
            return;
        }
        const planId = currentPlan.planId;
        safeRemoveFeatureState(map, { source: PLANS_SOURCE_ID });
        if (planId === undefined) {
            return;
        }
        safeSetFeatureState(map, { source: PLANS_SOURCE_ID, id: planId }, selectedState);
        const selected = planGeoJSON.features.find((item) => item.id === planId);
        if (!selected || !selected.geometry) {
            return;
        }
        const [x1, y1, x2, y2] = bbox(selected);
        map.fitBounds([x1, y1, x2, y2], {
            padding: PLAN_MAP_PADDING,
            duration: PLAN_MAP_ANIMATION_MS
        });
    }, [currentPlan, planGeoJSON, map]);
    return _jsx(_Fragment, {});
}
