var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MINIMAP_DEFAULT_ZOOM } from '../../common/consts/map';
import { MARKER_STATUS_ID } from '../../common/consts/marker';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_GROUP_KEY } from '../../common/consts/mixpanel';
import { assetToMarkerSource, MarkerStatusVignette } from '../../common/convert';
import { getAttributeFieldsWidth, getAttributeInputValue, getLookupMarkerIds } from '../../common/convert/asset';
import { parseCommonAttributeValue } from '../../common/convert/attribute';
import { formatLatLng } from '../../common/geoFormatter';
import { AssetIcon } from '../../common/icon';
import { LAYER_PATH } from '../../common/layer';
import { createRequestTransformerFunc } from '../../common/mapbox';
import { ASSET_LAYER_ID, ASSET_PROPERTY_ICON_ID, assetLayer } from '../../common/mapbox/layers/assetLayer';
import { getApiHost, ROOT_PATH } from '../../common/url';
import { isDefined, uniqueV2 } from '../../common/utils/array';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { AddressSearch } from '../../components/AddressSearch/AddressSearch';
import { AssetChangelog } from '../../components/AssetDetail/AssetChangelog/AssetChangelog';
import { AssetDetail } from '../../components/AssetDetail/AssetDetail';
import { AssetLowerForm } from '../../components/AssetLowerForm/AssetLowerForm';
import { CommonAttributeField } from '../../components/CommonAttributeField/CommonAttributeField';
import { Attachment } from '../../components/DetailsPanel/Attachment/Attachment';
import { AttachmentItem } from '../../components/DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { Changelog } from '../../components/DetailsPanel/Changelog/Changelog';
import { DetailsHeader } from '../../components/DetailsPanel/DetailsHeader/DetailsHeader';
import { DetailsMetaInformation } from '../../components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformation';
import { DetailsMetaInformationItem } from '../../components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem';
import { DetailsPanel } from '../../components/DetailsPanel/DetailsPanel';
import { DetailsTitlePlaceholder } from '../../components/DetailsPanel/DetailsTitlePlaceholder/DetailsTitlePlaceholder';
import { EmptyState } from '../../components/DetailsPanel/EmptyState/EmptyState';
import { SectionDescription } from '../../components/DetailsPanel/SectionDescription/SectionDescription';
import { SectionFooter } from '../../components/DetailsPanel/SectionFooter/SectionFooter';
import { DropMenuWrapper } from '../../components/DropMenuWrapper/DropMenuWrapper';
import { FilesSection } from '../../components/FilesSection/FilesSection';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { FormInputTextarea } from '../../components/FormInputTextarea/FormInputTextarea';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Bin, CalendarBlank, Close, Download, Link, LinkBreak, Mail, MapPin, Open, Overflow, Plus, Print } from '../../components/icons';
import { Image } from '../../components/ImagePreview/Image/Image';
import { ImagePreview } from '../../components/ImagePreview/ImagePreview';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { Minimap } from '../../components/Minimap/Minimap';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { TypedDitto } from '../../components/TypedDitto';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useMainMap } from '../../hooks/mainMap';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useAttachmentMarker } from '../../hooks/render/useAttachmentMarker';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { useAssetFiles } from '../../hooks/useAssetFiles';
import { useDelayedLoader } from '../../hooks/useDelayedLoader';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useDraggableFeature } from '../../hooks/useDraggableFeature';
import { useImageUploadModal } from '../../hooks/useImageUploadModal';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMapInit } from '../../hooks/useMapInit';
import { useUserRole } from '../../hooks/useUserRole';
import { removeAssetFiles } from '../../services/asset';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addAssetPhotosTk, clearHasClosedMarkers, clearSearchMarkers, fetchAssetInfoById, fetchClosedLinkedMarkers, fetchDefaultLinkedMarkers, fetchLookupMarkers, linkMarker, saveAssetAttributeTk, setAssetSelectedFileId, unlinkMarker, updateAssetAddressTk, updateAssetNotes, updateAssetTitle, updateAssetType, updateMarkerStatusTk } from '../../state/slices/assetDetail';
import { clearSelectedFeature, setSelectedFeature } from '../../state/slices/feature';
import { enableMapLayer } from '../../state/slices/layer';
import { deleteAssetsTk, exportAssetsTk, fetchAssetsTk } from '../../state/slices/leftPanelAsset';
import { setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { addNotificationMessage } from '../../state/slices/notification';
import { fetchAssetFolderTk, fetchAssetTypes, fetchUserProfiles } from '../../state/slices/shared';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
const IMAGE_SIZE = { height: 250, width: 356 };
const FORM_FIELD_WIDTH = 334;
const SHOW_MARKER_PAGE_SIZE = 5;
export function AssetDetailContainer({ variant }) {
    var _a, _b, _c;
    const dispatch = useAppDispatch();
    const { addModal, removeModal, modals } = useModalContext();
    const taskdescription = useDittoWrapper({ componentId: 'taskdescription' });
    const assettitle = useDittoWrapper({ componentId: 'assets.assettitle' });
    const placeholderText = useDittoWrapper({ componentId: 'notspecified' });
    const { isLoading, assetInfo, markerList, closedMarkerList, lookupMarkerMap, hasClosedMarkers, selectedFileId } = useAppSelector((state) => state.assetDetail);
    const { targetSchema, userAccount } = useAppSelector((state) => state.auth);
    const { assetTypeList, unitMap, assetTypeMap, assetFolders, userMap } = useAppSelector((state) => state.shared);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { draggableFeature } = useAppSelector((state) => state.mainMap);
    const { selectFilesForUpload } = useAssetFiles({
        asset: { assetId: (_a = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.assetId) !== null && _a !== void 0 ? _a : 0, manualInventoryId: (_b = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id) !== null && _b !== void 0 ? _b : 0 }
    });
    const { moveIntoView } = useMainMap();
    const handleMapInit = useMapInit();
    const { selectConfirmPhotos } = useImageUploadModal();
    const { reloadAssetTilesData } = useTilesUpdator();
    const formatter = useFormatter();
    const { isViewer, isEditor, isAdmin } = useUserRole();
    const { linkAssetsTasksEnabled } = useLayerChecker();
    const { getAttachmentMarker } = useAttachmentMarker();
    const { enableDrag, disableDragWithTimeout } = useDraggableFeature();
    const { handleAddressSearchChange, fetchedSuggestions, addressSearchStatus } = useAddressSearch();
    const [showFirstNClosedMarker, setShowFirstNClosedMarker] = useState(0);
    const [localTitle, setLocalTitle] = useState('');
    const [localNotes, setLocalNotes] = useState('');
    const assetType = (assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.inventoryTypeId)
        ? assetTypeMap[assetInfo.inventoryTypeId]
        : undefined;
    const assetIconId = (assetType === null || assetType === void 0 ? void 0 : assetType.iconId) || 0;
    const isReadOnly = !isEditor;
    const loader = useDelayedLoader({ isLoading: isLoading || !assetInfo || !targetSchema });
    const refInputTitle = useRef(null);
    const [currentTab, setCurrentTab] = useState(0);
    useEffect(() => {
        if (!selectedFeature || selectedFeature.type !== 'asset') {
            return;
        }
        dispatch(fetchAssetInfoById(selectedFeature.id));
        setLocalTitle('');
        setLocalNotes('');
    }, [selectedFeature, dispatch]);
    useEffect(() => {
        if (!assetInfo) {
            return;
        }
        setShowFirstNClosedMarker(0);
        setLocalNotes(assetInfo.notes || '');
        setLocalTitle(assetInfo.title || '');
        dispatch(clearSearchMarkers());
        dispatch(clearHasClosedMarkers());
        if (linkAssetsTasksEnabled && assetInfo.markerIds.length > 0) {
            dispatch(fetchDefaultLinkedMarkers(assetInfo.markerIds));
        }
        if (linkAssetsTasksEnabled) {
            const markerIds = getLookupMarkerIds(assetInfo.changelog);
            if (markerIds.length > 0) {
                dispatch(fetchLookupMarkers(markerIds));
            }
        }
        const userUUIDs = uniqueV2(assetInfo.changelog.map((i) => i.user).filter(isDefined));
        if (userUUIDs.length > 0) {
            dispatch(fetchUserProfiles(userUUIDs));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetInfo, dispatch, linkAssetsTasksEnabled]);
    function handleCreateMarkerClick() {
        if (!assetInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.linkNewTaskToExistingAsset, {
            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: assetType === null || assetType === void 0 ? void 0 : assetType.name,
            [MIXPANEL_GROUP_KEY.assetTypeId]: assetType === null || assetType === void 0 ? void 0 : assetType.id
        });
        dispatch(setMarkerCreatorPointSource(assetToMarkerSource(assetInfo)));
    }
    function handleLinkMarkerClick() {
        if (!assetInfo) {
            return;
        }
        addModal({
            id: 'LinkTasksModalContainer',
            props: {
                onDone: (ids) => {
                    mixpanel.track(MIXPANEL_EVENT_NAME.linkExistingTaskToExistingAsset, {
                        [MIXPANEL_EVENT_PROPERTY.assetTypeName]: assetType === null || assetType === void 0 ? void 0 : assetType.name,
                        [MIXPANEL_GROUP_KEY.assetTypeId]: assetType === null || assetType === void 0 ? void 0 : assetType.id
                    });
                    dispatch(linkMarker({ assetId: assetInfo.id, markerId: ids }))
                        .unwrap()
                        .then(() => {
                        reloadAssetTilesData();
                    });
                    dispatch(clearSearchMarkers());
                }
            }
        });
    }
    function handleUnlinkMarkerClick(id) {
        if (!assetInfo) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(unlinkMarker({ assetId: assetInfo.id, markerId: id }))
                        .unwrap()
                        .then(() => {
                        reloadAssetTilesData();
                    });
                },
                title: _jsx(Ditto, { componentId: "tasks.unlinktask" }),
                description: _jsx(Ditto, { componentId: "tasks.unlinktaskcopy" }),
                yesLabel: _jsx(Ditto, { componentId: "tasks.unlink" })
            }
        });
    }
    function handleOpenMarkerClick(id) {
        removeModal('ImageViewerAsset');
        removeModal('FileGalleryAssetContainer');
        dispatch(enableMapLayer('marker'));
        dispatch(setSelectedFeature({ type: 'marker', id }));
    }
    function handleCloseMarkerClick(id) {
        if (!assetInfo) {
            return;
        }
        dispatch(updateMarkerStatusTk({
            assetId: assetInfo.id,
            markerId: id,
            statusId: MARKER_STATUS_ID.closed
        }))
            .unwrap()
            .then(() => {
            reloadAssetTilesData();
        });
    }
    function handleShowClosedMarkerClick() {
        setShowFirstNClosedMarker(SHOW_MARKER_PAGE_SIZE);
        if (!assetInfo) {
            return;
        }
        dispatch(fetchClosedLinkedMarkers(assetInfo.markerIds));
    }
    function handleShowMoreClick() {
        setShowFirstNClosedMarker(showFirstNClosedMarker + SHOW_MARKER_PAGE_SIZE);
    }
    function handleCloseClick() {
        if (variant === 'image-viewer') {
            removeModal('ImageViewerAsset');
            return;
        }
        if (modals.find((item) => item.id === 'FileGalleryAssetContainer')) {
            dispatch(setAssetSelectedFileId());
            removeModal('FileGalleryAssetContainer');
            return;
        }
        dispatch(clearSelectedFeature());
    }
    function handleImageEnlarge(index) {
        addModal({ id: 'ImageViewerAsset', props: { initialActiveIndex: index } });
    }
    function handleAssetTypeChange(val) {
        if (!(assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id)) {
            return;
        }
        addModal({
            id: 'SaveModal',
            props: {
                onConfirm: () => {
                    dispatch(updateAssetType({
                        id: assetInfo.id,
                        inventoryTypeId: val.id
                    }))
                        .unwrap()
                        .then(() => {
                        reloadAssetTilesData();
                    });
                },
                onCancel: () => removeModal('SaveModal'),
                title: _jsx(Ditto, { componentId: "assets.changeassettype" }),
                description: _jsx(Ditto, { componentId: "assets.changeassettypecopy" }),
                yesColor: 'red',
                yesLabel: _jsx(Ditto, { componentId: "prompt.deleteandchange" })
            }
        });
    }
    function handleAssetTitleClick() {
        setCurrentTab(0);
        requestAnimationFrame(() => {
            var _a;
            (_a = refInputTitle.current) === null || _a === void 0 ? void 0 : _a.focus();
        });
    }
    function handleTitleSubmit() {
        if (!assetInfo || localTitle === assetInfo.title) {
            return;
        }
        dispatch(updateAssetTitle({
            id: assetInfo.id,
            title: localTitle
        }));
    }
    function handleNotesSubmit() {
        if (!assetInfo || localNotes === assetInfo.notes) {
            return;
        }
        dispatch(updateAssetNotes({
            id: assetInfo.id,
            notes: localNotes
        }));
    }
    function handleAttributeChange(value, type, typeAttributeId) {
        if (!assetInfo) {
            return;
        }
        const v = parseCommonAttributeValue(value, type);
        dispatch(saveAssetAttributeTk({
            assetId: assetInfo.id,
            body: {
                manualInventoryId: assetInfo.id,
                inventoryTypeAttributeId: typeAttributeId,
                [`${type}Value`]: v
            }
        }));
    }
    function handleImageUploadClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!assetInfo) {
                return;
            }
            selectConfirmPhotos((img) => dispatch(addAssetPhotosTk({
                assetId: assetInfo.id,
                photos: img.map((i) => i.file)
            })));
        });
    }
    function handleAddNewTypeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'asset-type',
                modalProps: {
                    type: 'asset-type',
                    shouldGoToAddNewType: true
                }
            }
        });
    }
    function handleOpenSettings() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'asset-type',
                modalProps: {
                    type: 'asset-type',
                    selectedAssetType: (assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.inventoryTypeId) || 0
                }
            }
        });
    }
    function handleDeleteButtonClick() {
        if (!assetInfo) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(deleteAssetsTk([assetInfo.id]))
                        .unwrap()
                        .then(() => {
                        reloadAssetTilesData();
                    });
                    removeModal('ImageViewerAsset');
                },
                title: _jsx(Ditto, { componentId: "assets.deleteasset" }),
                description: _jsx(Ditto, { componentId: "assets.deleteasset.confirmation" })
            }
        });
    }
    function handlePrintButtonClick() {
        if (!assetInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.printSelectedAsset, {
            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: assetType === null || assetType === void 0 ? void 0 : assetType.name,
            [MIXPANEL_GROUP_KEY.assetTypeId]: assetType === null || assetType === void 0 ? void 0 : assetType.id
        });
        window.open(`/${ROOT_PATH.print}/${LAYER_PATH.asset}/${assetInfo.id}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleMailButtonClick() {
        if (!assetInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.emailSelectedAsset, {
            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: assetType === null || assetType === void 0 ? void 0 : assetType.name,
            [MIXPANEL_GROUP_KEY.assetTypeId]: assetType === null || assetType === void 0 ? void 0 : assetType.id
        });
        addModal({
            id: 'MailModal',
            props: {
                featureList: {
                    type: 'asset',
                    value: [assetInfo.id]
                }
            }
        });
    }
    function handleAddressSuggestionClick(s) {
        if (!assetInfo) {
            return;
        }
        dispatch(updateAssetAddressTk({
            id: assetInfo.id,
            position: s.position,
            address: s.address
        }))
            .unwrap()
            .then(() => reloadAssetTilesData());
        moveIntoView(s.position);
    }
    function handleAddressClick() {
        if (!assetInfo) {
            return;
        }
        enableDrag({
            type: 'asset',
            value: assetInfo
        });
    }
    function handleDownloadButtonClick() {
        if (!assetInfo) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.exportSelectedAsset, {
            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: assetType === null || assetType === void 0 ? void 0 : assetType.name,
            [MIXPANEL_GROUP_KEY.assetTypeId]: assetType === null || assetType === void 0 ? void 0 : assetType.id
        });
        addModal({
            id: 'ExportModal',
            props: {
                onStartExportClick: () => dispatch(exportAssetsTk({ ids: [assetInfo.id] })),
                requestList: [
                    {
                        type: 'asset',
                        count: 1
                    }
                ]
            }
        });
    }
    function handleFileDelete(file) {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    yield removeAssetFiles({ fileIds: [file.id], assetId: assetInfo.assetId });
                    yield dispatch(fetchAssetInfoById(assetInfo.id));
                    dispatch(fetchAssetsTk());
                    dispatch(fetchAssetTypes());
                    dispatch(fetchAssetFolderTk());
                    dispatch(addNotificationMessage({
                        type: 'success',
                        ditto: { title: { componentId: 'toast.filedeleted' } }
                    }));
                }),
                title: _jsx(Ditto, { componentId: "prompt.delete.file.deletefile" }),
                description: _jsx(Ditto, { componentId: "prompt.delete.file.text" }),
                yesLabel: _jsx(Ditto, { componentId: "delete" })
            }
        });
    }
    function handleFileClick(id) {
        dispatch(setAssetSelectedFileId(id));
        mixpanel.track(MIXPANEL_EVENT_NAME.viewAssetFile);
        const modal = modals.find((modal) => modal.id === 'FileGalleryAssetContainer');
        if (!modal) {
            addModal({ id: 'FileGalleryAssetContainer' });
        }
    }
    function handleCustomizeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: { initialPageId: 'asset-folders' }
        });
    }
    const header = (_jsx(DetailsHeader, { buttonsRight: _jsxs(_Fragment, { children: [_jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ trailingIcon: _jsx(IconResizer, Object.assign({ size: 28 }, { children: _jsx(Overflow, {}) })), "data-test": "three-dot-menu", variant: "slender" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), menu: _jsxs(ActionModal, Object.assign({ width: 263 }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.exportxls" }), leadingIcon: _jsx(Download, {}), onClick: handleDownloadButtonClick, disabled: !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email) }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), leadingIcon: _jsx(Print, {}), onClick: handlePrintButtonClick, "data-test": "btn-asset-print" }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), leadingIcon: _jsx(Mail, {}), onClick: handleMailButtonClick, "data-test": "btn-share-email" }), isAdmin && (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.delete" }), leadingIcon: _jsx(Bin, {}), onClick: handleDeleteButtonClick, color: "red" }))] })), direction: "down-left" }), _jsx(Button, Object.assign({ variant: "square", color: "gray-2", onClick: handleCloseClick }, { children: _jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(Close, {}) })) }))] }), title: (assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.title) || _jsx(DetailsTitlePlaceholder, {}), titleIcon: _jsx(AssetIcon, { iconId: assetIconId }), label: _jsx(Ditto, { componentId: "assets.asset" }), withUnderline: false, id: assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id, onTitleClick: (!isViewer && handleAssetTitleClick) || undefined }));
    const dateTime = formatter.formatDate(assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.createdAt);
    const pinLabel = assetInfo &&
        formatLatLng({
            lat: assetInfo.coordinates[1],
            lng: assetInfo.coordinates[0]
        }, {
            // In US locale, 5 decimal places still
            // fits with the date & time in one line.
            // Corresponds to 1.11 m precision at equator.
            // Source: https://en.wikipedia.org/wiki/Decimal_degrees
            precision: 5
        });
    const metaInfo = (_jsxs(DetailsMetaInformation, { children: [_jsx(DetailsMetaInformationItem, { icon: _jsx(CalendarBlank, {}), label: dateTime.dateTime, "data-test": "asset-date" }), _jsx(DetailsMetaInformationItem, { icon: _jsx(MapPin, {}), label: pinLabel, "data-test": "asset-coords" })] }));
    const geojson = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: (assetInfo && [assetInfo.coordinates[0], assetInfo.coordinates[1]]) || []
        },
        properties: {
            [ASSET_PROPERTY_ICON_ID]: assetIconId
        }
    };
    const mapContent = assetInfo && (_jsx(Minimap, Object.assign({ ref: handleMapInit, longitude: assetInfo.coordinates[0], latitude: assetInfo.coordinates[1], zoom: MINIMAP_DEFAULT_ZOOM, transformRequest: createRequestTransformerFunc(targetSchema || '', getApiHost()), id: "ImageViewerAssetContainer", size: "large", noOutline: true, variant: "image-viewer" }, { children: _jsx(Source, Object.assign({ id: ASSET_LAYER_ID.default, type: "geojson", data: geojson }, { children: _jsx(Layer, Object.assign({}, assetLayer)) })) })));
    const image = (_jsx(ImagePreview, Object.assign({}, IMAGE_SIZE, { onClickEnlarge: handleImageEnlarge, uploadImage: _jsx(TypedDitto, { componentId: "uploadimage" }), onClickUpload: isReadOnly ? undefined : handleImageUploadClick }, { children: assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.photos.map((p) => (_jsx(Image, { src: p.imageThumbUrl, alt: "Asset" }, p.id))) })));
    const currentAssetType = assetInfo && assetInfo.inventoryTypeId
        ? assetTypeMap[assetInfo.inventoryTypeId]
        : undefined;
    const attributes = (currentAssetType === null || currentAssetType === void 0 ? void 0 : currentAssetType.attributes) && getAttributeFieldsWidth(currentAssetType.attributes);
    const linkedMarkersSection = (_jsxs(_Fragment, { children: [((assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.markerIds.length) === 0 || markerList.length === 0) && (_jsx(EmptyState, { illustration: _jsx("img", { src: "/tasks.svg" }), title: _jsx(Ditto, { componentId: "tasks.trackyourtasks" }), caption: _jsx(Ditto, { componentId: "tasks.linktasksthatneedtobedoneonthisasset" }), action: {
                    label: _jsx(Ditto, { componentId: "createmarker" }),
                    onClick: handleCreateMarkerClick
                } })), (assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.markerIds.length) !== 0 && (_jsxs(Attachment, Object.assign({ description: markerList.length > 0 && (_jsx(SectionDescription, { subtitle: _jsx(Ditto, { componentId: "assets.opentasks" }), plusButton: _jsx(FloatingDropdownWrapper, { control: _jsx(ActionButton, { icon: _jsx(Plus, {}) }), menu: _jsxs(ActionModal, Object.assign({ width: 332 }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.createnewtask" }), leadingIcon: _jsx(Plus, {}), onClick: handleCreateMarkerClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.linkexistingtask" }), leadingIcon: _jsx(Link, {}), onClick: handleLinkMarkerClick })] })) }) })), footer: (function () {
                    if (showFirstNClosedMarker === 0 && hasClosedMarkers) {
                        return (_jsx(SectionFooter, Object.assign({ onClick: handleShowClosedMarkerClick }, { children: _jsx(Ditto, { componentId: "tasks.showclosedtasks" }) })));
                    }
                    if (closedMarkerList.length > showFirstNClosedMarker) {
                        return (_jsx(SectionFooter, Object.assign({ onClick: handleShowMoreClick }, { children: _jsx(Ditto, { componentId: "showmore" }) })));
                    }
                })() }, { children: [markerList.map((marker) => (_jsx(AttachmentItem, Object.assign({ variant: "outline", onClick: () => handleOpenMarkerClick(marker.id), trailingElement: _jsx(FloatingDropdownWrapper, { control: _jsx(ActionButton, { icon: _jsx(Overflow, {}), "data-test": "task-action-btn" }), menu: _jsxs(ActionModal, Object.assign({ width: 324 }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "assets.opentask" }), leadingIcon: _jsx(Open, {}), onClick: () => handleOpenMarkerClick(marker.id) }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.setstatustoclosed" }), leadingIcon: _jsx(MarkerStatusVignette, { value: MARKER_STATUS_ID.closed }), onClick: () => handleCloseMarkerClick(marker.id), "data-test": "task-close-btn" }), _jsx(Divider, {}), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.unlink" }), leadingIcon: _jsx(LinkBreak, {}), color: "red", onClick: () => handleUnlinkMarkerClick(marker.id) })] })) }) }, { children: getAttachmentMarker(marker) }), marker.id))), showFirstNClosedMarker > 0 && (_jsxs(_Fragment, { children: [_jsx(SectionDescription, { subtitle: _jsx(Ditto, { componentId: "tasks.closedtasks" }) }), closedMarkerList.slice(0, showFirstNClosedMarker).map((marker) => (_jsx(AttachmentItem, Object.assign({ opacity: 0.5, onClick: () => handleOpenMarkerClick(marker.id) }, { children: getAttachmentMarker(marker) }), marker.id)))] }))] })))] }));
    const filesSection = (_jsxs(_Fragment, { children: [(assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.files.length) === 0 && !isViewer && (_jsx(EmptyState, { illustration: _jsx("img", { src: "/documents.svg" }), title: _jsx(Ditto, { componentId: "files.getstartedwithdocuments" }), caption: _jsx(Ditto, { componentId: "files.keepallrelevantinformationatyourfingertips" }), action: {
                    label: _jsx(Ditto, { componentId: "files.uploaddocument" }),
                    onClick: () => selectFilesForUpload(() => new Promise((resolve) => addModal({
                        id: 'FileUploadModal',
                        props: {
                            folders: assetFolders,
                            onUploadClick: (folderId) => {
                                resolve({ folderId });
                            },
                            onCloseClick: () => {
                                resolve({ folderId: null });
                            }
                        }
                    })))
                }, customize: { show: !isViewer, onClick: handleCustomizeClick } })), ((assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.files.length) !== 0 || isViewer) && (_jsx(FilesSection, { id: (_c = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id) !== null && _c !== void 0 ? _c : 0, files: assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.files, folders: assetFolders, selectedFileId: selectedFileId, selectFilesForUpload: selectFilesForUpload, dateTimeFormatter: formatter.formatDate, handleFileDeleteClick: handleFileDelete, handleFileClick: handleFileClick, handleCustomizeClick: handleCustomizeClick, readonly: isViewer }))] }));
    const changelogSection = (_jsxs(_Fragment, { children: [(assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.changelog.length) === 0 && (_jsx(EmptyState, { illustration: null, title: _jsx(Ditto, { componentId: "changelog.nochangeshavebeenmadeyet" }), caption: _jsx(Ditto, { componentId: "changelog.nochangeshavebeenmadeyet.assoonaschangesaremadetheywillbedisplayedhere" }) })), (assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.changelog.length) !== 0 && assetInfo && (_jsx(Changelog, { children: _jsx(AssetChangelog, { changelogList: assetInfo.changelog, userMap: userMap, assetTypeMap: assetTypeMap, lookupMarkerMap: lookupMarkerMap, formatter: formatter, unitMap: unitMap }) }))] }));
    const lowerForm = (_jsx(AssetLowerForm, { isReadOnly: isReadOnly, title: _jsx(FormInputText, { ref: refInputTitle, label: assettitle, value: localTitle, onValueChange: (value) => setLocalTitle(value), onBlur: handleTitleSubmit, variant: "ghost", disabled: isReadOnly, placeholder: placeholderText }), address: _jsx(AddressSearch, Object.assign({ value: formatter.formatAddress(assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.address), width: FORM_FIELD_WIDTH, onValueChange: handleAddressSearchChange, required: true, variant: "ghost", status: addressSearchStatus, disabled: isReadOnly, onClick: handleAddressClick, onBlur: disableDragWithTimeout, infobox: (draggableFeature === null || draggableFeature === void 0 ? void 0 : draggableFeature.type) === 'asset' && (_jsx(InfoBox, { content: _jsx(Ditto, { componentId: "address.dragobjectonmaporenteraddresshere" }) })) }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatter.formatAddress(s.address, { isShort: true }), secondaryText: formatter.formatAddress(s.address, {
                    cityAndCountry: true
                }), onClick: () => handleAddressSuggestionClick(s) }, `${s.position.lat}-${s.position.lng}`))) })), assetTypesList: assetTypeList, assetTypeValue: currentAssetType, onAssetTypeChange: handleAssetTypeChange, onAddNewTypeClick: handleAddNewTypeClick, onCustomizeAttributesClick: handleOpenSettings, attributeForm: attributes === null || attributes === void 0 ? void 0 : attributes.map((item) => {
            var _a, _b;
            const initialValue = (assetInfo && ((_a = getAttributeInputValue(item, assetInfo)) === null || _a === void 0 ? void 0 : _a.toString())) || undefined;
            return (_jsx(CommonAttributeField
            // Force re-render dependent on initialValue
            , { attribute: item, initialValue: initialValue, dropMenuWidth: FORM_FIELD_WIDTH, onChange: (value) => handleAttributeChange(value, item.type, item.id), unit: item.unitId ? (_b = unitMap[item.unitId]) === null || _b === void 0 ? void 0 : _b.abbreviation : undefined, variant: "ghost", disabled: isReadOnly, isFullWidth: item.isFullWidth, formatter: formatter, settingPageId: "asset-type" }, `${item.id}-${initialValue}`));
        }), variant: "ghost", description: _jsx(FormInputTextarea, { label: taskdescription, width: FORM_FIELD_WIDTH, variant: "ghost", placeholder: placeholderText, value: localNotes, onValueChange: (value) => setLocalNotes(value), onBlur: handleNotesSubmit, disabled: isReadOnly, autosize: true, "data-test": "asset-notes" }) }));
    return (_jsx(DetailsPanel, Object.assign({ loader: loader }, { children: _jsx(AssetDetail, { header: header, metaInfo: metaInfo, imagePreview: variant === 'image-viewer' ? _jsx(_Fragment, { children: mapContent }) : image, lowerForm: lowerForm, markerSection: linkAssetsTasksEnabled ? linkedMarkersSection : undefined, filesSection: filesSection, filesCount: assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.files.length, changelogSection: changelogSection, markerCount: linkAssetsTasksEnabled ? markerList.length : undefined, currentTab: currentTab, setCurrentTab: setCurrentTab }) })));
}
