import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { AssetIcon } from '../../../common/icon';
import { ImagePlaceholder } from '../../../uikit/ImagePlaceholder/ImagePlaceholder';
import { IconText } from '../../IconText/IconText';
import { List } from '../List/List';
import styles from './Asset.module.css';
export function Asset({ content: { imageURL, title, assetIconId } }) {
    return (_jsxs(List, Object.assign({ width: '100%' }, { children: [_jsx("div", Object.assign({ className: classes(styles['image-wrapper']) }, { children: imageURL ? (_jsx("img", { className: classes(styles['image']), src: imageURL })) : (_jsx(ImagePlaceholder, {})) })), _jsx("div", Object.assign({ className: classes(styles['info']) }, { children: _jsx(IconText, { icon: _jsx("div", Object.assign({ className: classes(styles['icon-background']) }, { children: _jsx(AssetIcon, { iconId: assetIconId, size: 18 }) })), text: title }) }))] })));
}
