import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { format } from 'date-fns';
import { Ditto } from 'ditto-react';
import { RANGE_LIMIT } from '../../common/consts/asset';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { getDateInUTC, parseDateInUTC } from '../../common/utils/time';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { FormInputSelect } from '../../components/FormInputSelect/FormInputSelect';
import { CalendarBlank } from '../../components/icons';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
import { RadioSelectbox } from '../../uikit/RadioSelectbox/RadioSelectbox';
import { useSignInventoryFilterHandlers } from '../filters/useSignInventoryFilterHandlers';
import { useDateTimeFormatter } from '../formatting/useDateTimeFormatter';
export function useSignPropertyFilter({ onPropertyFilterGroupWrapperToggle }) {
    const { filter } = useAppSelector((state) => state.leftPanelSignInventory);
    const { unitList } = useAppSelector((state) => state.shared);
    const { addModal } = useModalContext();
    const dateTimeFormatter = useDateTimeFormatter();
    const { handlePropertyFilterClick, handlePropertyGroupFilterClick, handlePropertyFilterChange, isPropertySelectOptionChecked, countPropertySelectedOptions, getPropertyFilterNumberValue, isPropertyNumberNotSpecifiedChecked, handleNumberPropertyNotSpecifiedClick, handleDatePropertyFilterClick, getPropertyFilter } = useSignInventoryFilterHandlers();
    const labelNotSpecified = _jsx(Ditto, { componentId: "notspecified" });
    function handleCustomDateClick(propertyId, filterDateValue) {
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                rangeSelector: true,
                startDate: (filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.from)
                    ? format(parseDateInUTC(filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.from), 'yyyy-MM-dd')
                    : undefined,
                endDate: (filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.to)
                    ? format(parseDateInUTC(filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.to), 'yyyy-MM-dd')
                    : undefined,
                onConfirm: (value) => {
                    handleDatePropertyFilterClick(propertyId, (value === null || value === void 0 ? void 0 : value.startDate) ? getDateInUTC(value === null || value === void 0 ? void 0 : value.startDate).toISOString() : undefined, (value === null || value === void 0 ? void 0 : value.untilDate) ? getDateInUTC(value === null || value === void 0 ? void 0 : value.untilDate).toISOString() : undefined, false);
                }
            }
        });
    }
    function getSignPropertyFilter(property) {
        var _a, _b, _c;
        if (!property.amountInUse) {
            return;
        }
        const filterValue = getPropertyFilter(property.id);
        const filterDateValue = (filterValue === null || filterValue === void 0 ? void 0 : filterValue.type) === 'date' ? filterValue.dateValue : undefined;
        const isDateNotSpecifiedChecked = (filterValue === null || filterValue === void 0 ? void 0 : filterValue.type) === 'date' && !!(filterValue === null || filterValue === void 0 ? void 0 : filterValue.showNotSpecified);
        const isCustomTimeFrameChecked = !!(filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.from) && !!(filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.to);
        return (_jsxs("div", { children: [property.type === 'select' && (_jsxs(FilterGroupWrapper, Object.assign({ content: property.name, checkState: getCheckboxCheckState((_a = property.selectOptions) === null || _a === void 0 ? void 0 : _a.length, countPropertySelectedOptions(property.id)), onCheckboxClick: () => handlePropertyGroupFilterClick(property.id), onToggle: onPropertyFilterGroupWrapperToggle }, { children: [(_b = property.selectOptions) === null || _b === void 0 ? void 0 : _b.map((selectOption) => (_jsx(FilterSelectbox, { onClick: () => handlePropertyFilterClick(property.id, selectOption.id), isChecked: isPropertySelectOptionChecked(property.id, selectOption.id), content: selectOption.name, color: "black", trailingElement: selectOption.amountInUse }, selectOption.id))), _jsx(FilterSelectbox, { color: "black", isChecked: isPropertySelectOptionChecked(property.id, undefined, true), onClick: () => handlePropertyFilterClick(property.id, undefined, true), content: labelNotSpecified, trailingElement: property.notSpecifiedAmount }, property.id + '-selectbox')] }), property.id + '-select')), property.type === 'number' && property.amountInUse >= 1 && (_jsxs(FilterGroupWrapper, Object.assign({ content: property.name, checkState: filter.properties.find((fa) => fa.id === property.id)
                        ? 'checked'
                        : 'not-checked', onCheckboxClick: () => handlePropertyGroupFilterClick(property.id), onToggle: onPropertyFilterGroupWrapperToggle }, { children: [_jsx(RangeSlider, { min: property.numberMin || RANGE_LIMIT.min, max: property.numberMax || RANGE_LIMIT.max, showInputs: true, width: 290, value: getPropertyFilterNumberValue(property.id), unit: (_c = unitList.find((u) => u.id === property.unitId)) === null || _c === void 0 ? void 0 : _c.abbreviation, onChange: (value) => handlePropertyFilterChange(property.id, value) }, property.id + '-range-slider'), _jsx(FilterSelectbox, { color: "black", isChecked: isPropertyNumberNotSpecifiedChecked(property.id), onClick: () => handleNumberPropertyNotSpecifiedClick(property.id), content: labelNotSpecified, trailingElement: property.notSpecifiedAmount }, property.id + '-range-selectbox')] }), property.id + '-range')), property.type === 'date' && property.amountInUse >= 1 && (_jsx(_Fragment, { children: _jsxs(FilterGroupWrapper, Object.assign({ content: property.name, checkState: isDateNotSpecifiedChecked || isCustomTimeFrameChecked
                            ? 'checked'
                            : 'not-checked', onCheckboxClick: () => handlePropertyGroupFilterClick(property.id), onToggle: onPropertyFilterGroupWrapperToggle }, { children: [_jsx(RadioSelectbox, { onClick: () => handleDatePropertyFilterClick(property.id, undefined, undefined, true), isChecked: isDateNotSpecifiedChecked, content: labelNotSpecified, color: "black", count: property.notSpecifiedAmount }), _jsx(RadioSelectbox, { onClick: () => {
                                    if ((filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.from) && (filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.to)) {
                                        return;
                                    }
                                    handleCustomDateClick(property.id, filterDateValue);
                                }, isChecked: isCustomTimeFrameChecked, content: _jsx(Ditto, { componentId: "timepicker.customtimeframe" }), color: "black" }), (filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.from) && (filterDateValue === null || filterDateValue === void 0 ? void 0 : filterDateValue.to) && (_jsx(FormInputSelect, { onClick: () => handleCustomDateClick(property.id, filterDateValue), leadingIcon: _jsx(CalendarBlank, {}), hideMenu: true, placeholder: _jsx(Ditto, { componentId: "timepicker.selectcustomtimeframe" }), value: filterDateValue.from && filterDateValue.to ? (_jsxs(_Fragment, { children: [dateTimeFormatter(filterDateValue.from).date, ' - ', dateTimeFormatter(filterDateValue.to).date] })) : undefined, size: "medium", variant: "ghost" }))] }), property.id + '-date-range') }))] }, property.id));
    }
    return { getSignPropertyFilter };
}
