// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gegJJvXQSQYMgQY1YOIU{display:flex;flex-direction:column;justify-content:center;flex:1 0 0;width:100%;box-sizing:border-box;padding-right:0;gap:2px;margin-bottom:6px;transition:all 200ms ease}.UgCjUH4P8FS9cOWZQ9FA{gap:0;margin-bottom:0}._uve17BaBTzBWoM6JWfi{opacity:.8;padding-left:2px;transition:all 200ms ease;padding:8px;border-radius:4px}.zDf7d_dH7b9uHd073ksE:hover{opacity:1;background:rgba(129,129,129,.1);cursor:pointer}.LHdifaOC_R62sxMo6tbY{width:100%;padding-left:28px;margin-top:4px;overflow:hidden;display:flex;flex-direction:column;gap:4px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/SectionLayer/SectionLayer.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,UAAA,CACA,UAAA,CACA,qBAAA,CACA,eAAA,CACA,OAAA,CACA,iBAAA,CACA,yBAAA,CAGJ,sBACI,KAAA,CACA,eAAA,CAGJ,sBACI,UAAA,CACA,gBAAA,CACA,yBAAA,CACA,WAAA,CACA,iBAAA,CAGJ,4BACI,SAAA,CACA,+BAAA,CACA,cAAA,CAGJ,sBACI,UAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    flex: 1 0 0;\n    width: 100%;\n    box-sizing: border-box;\n    padding-right: 0;\n    gap: 2px;\n    margin-bottom: 6px;\n    transition: all 200ms ease;\n}\n\n.container--collapsed {\n    gap: 0;\n    margin-bottom: 0;\n}\n\n.header {\n    opacity: 0.8;\n    padding-left: 2px;\n    transition: all 200ms ease;\n    padding: 8px;\n    border-radius: 4px;\n}\n\n.header--collapsible:hover {\n    opacity: 1;\n    background: rgba(129, 129, 129, 0.1);\n    cursor: pointer;\n}\n\n.children {\n    width: 100%;\n    padding-left: 28px;\n    margin-top: 4px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gegJJvXQSQYMgQY1YOIU`,
	"container--collapsed": `UgCjUH4P8FS9cOWZQ9FA`,
	"header": `_uve17BaBTzBWoM6JWfi`,
	"header--collapsible": `zDf7d_dH7b9uHd073ksE`,
	"children": `LHdifaOC_R62sxMo6tbY`
};
export default ___CSS_LOADER_EXPORT___;
