import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ActionButton } from '../../ActionButton/ActionButton';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Task } from '../../icons';
import { ImageCrop } from '../../ImageCrop/ImageCrop';
import { ImageEnlarge } from '../ImageEnlarge/ImageEnlarge';
import styles from './Sign.module.css';
const IMAGE_SIZE = { height: 82, width: 140 };
export function Sign({ content: { dateTime, address, sign, image }, withAction, isEditor, onImageEnlargeClick, onAddMarkerButtonClick, checkbox }) {
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle']) }, { children: [checkbox && checkbox, isEditor ? dateTime.dateTime : dateTime.date] })), _jsx("div", Object.assign({ className: styles['title'] }, { children: address })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [sign && (_jsxs("div", Object.assign({ className: styles['tag'], style: { color: sign.color } }, { children: [_jsx(IconResizer, Object.assign({ size: 24 }, { children: sign.icon })), _jsx("span", Object.assign({ className: styles['tag-text'] }, { children: sign.text }))] }))), isEditor && withAction && (_jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx(ActionButton, { className: styles['action-button'], icon: _jsx(Task, {}), tooltip: {
                                        text: _jsx(Ditto, { componentId: "tooltip.createmarker" }),
                                        arrow: 'up'
                                    }, onClick: onAddMarkerButtonClick }) })))] }))] })), _jsxs("div", Object.assign({ className: styles['right'], style: IMAGE_SIZE }, { children: [withAction && _jsx(ImageEnlarge, { onClick: onImageEnlargeClick }), image && (_jsx(ImageCrop, { className: styles['image'], src: image.url, alt: "Traffic Sign", tag: image.tag, imageSize: IMAGE_SIZE }))] }))] })));
}
