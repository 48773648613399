import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { MarkerPriorityIcon } from '../../../common/convert/marker';
import { usePriorityLabel } from '../../../hooks/ditto/usePriorityLabel';
import { FormInputCurrentSelectedValue } from '../../FormInputSelect/FormInputSelect';
import { Check, SparkleMagicApplied } from '../../icons';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { Vignette } from '../../Vignette/Vignette';
export function MarkerPriorityItem({ priority, onClick, isMagicInput, isSelected }) {
    const label = usePriorityLabel(priority.priority);
    let trailingIcon;
    if (isSelected) {
        trailingIcon = _jsx(Check, {});
    }
    if (isMagicInput) {
        trailingIcon = _jsx(SparkleMagicApplied, {});
    }
    return (_jsx(SelectboxEntry, { leadingIcon: _jsx(Vignette, { content: _jsx(MarkerPriorityIcon, { value: priority.priorityId }), color: "black" }), text: label, onClick: onClick && onClick, trailingIcon: trailingIcon, "data-test": `dropdown-priority-${priority.priorityId}` }));
}
export function MarkerPrioritySelectedValue({ priority }) {
    const label = usePriorityLabel(priority.priority);
    return (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(Vignette, { content: _jsx(MarkerPriorityIcon, { value: priority.priorityId }), color: "black" }), text: label }));
}
