import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Marker, useMap } from 'react-map-gl';
import { MARKER_PRIORITY_COLORS } from '../../common/color';
import { MAP_MARKER_CLUSTER_ZOOM } from '../../common/consts/map';
import { MARKER_PRIORITY_ID } from '../../common/consts/marker';
import { MarkerPriorityIcon } from '../../common/convert';
import { MARKERS_SOURCE_ID } from '../../common/mapbox';
import { uniqueV2 } from '../../common/utils/array';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { IconText } from '../../components/IconText/IconText';
import { List } from '../../components/MapPopover/List/List';
import { MapPopover } from '../../components/MapPopover/MapPopover';
import { MarkerCluster } from '../../components/MarkerCluster/MarkerCluster';
import { TypedDitto } from '../../components/TypedDitto';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHoveredFeature } from '../../state/slices/feature';
const clusterCache = {};
const createNewMarkerCluster = (map, cluster, clearHoveredFeature) => {
    if (cluster.geometry.type != 'Point') {
        return null;
    }
    const props = cluster.properties;
    if (!props || !props.cluster || !props.cluster_id) {
        return null;
    }
    const [longitude, latitude] = cluster.geometry.coordinates;
    const { green, yellow, red, grey, cluster_id: clusterId } = props;
    const items = [];
    const popupItems = [];
    if (red) {
        items.push({
            color: MARKER_PRIORITY_COLORS.high,
            count: red
        });
        popupItems.push(_jsx(IconText, { icon: _jsx(MarkerPriorityIcon, { value: MARKER_PRIORITY_ID.high }), text: _jsx(TypedDitto, { componentId: "marker.priority.high" }), trailingElement: _jsx(CategoryBadge, { category: red, color: "white", backgroundColor: "transparent" }) }, `cluster-${clusterId}-red`));
    }
    if (yellow) {
        items.push({
            color: MARKER_PRIORITY_COLORS.medium,
            count: yellow
        });
        popupItems.push(_jsx(IconText, { icon: _jsx(MarkerPriorityIcon, { value: MARKER_PRIORITY_ID.medium }), text: _jsx(TypedDitto, { componentId: "marker.priority.medium" }), trailingElement: _jsx(CategoryBadge, { category: yellow, color: "white", backgroundColor: "transparent" }) }, `cluster-${clusterId}-yellow`));
    }
    if (green) {
        items.push({
            color: MARKER_PRIORITY_COLORS.low,
            count: green
        });
        popupItems.push(_jsx(IconText, { icon: _jsx(MarkerPriorityIcon, { value: MARKER_PRIORITY_ID.low }), text: _jsx(TypedDitto, { componentId: "marker.priority.low" }), trailingElement: _jsx(CategoryBadge, { category: green, color: "white", backgroundColor: "transparent" }) }, `cluster-${clusterId}-green`));
    }
    if (grey) {
        items.push({
            color: MARKER_PRIORITY_COLORS.noPriority,
            count: grey
        });
        popupItems.push(_jsx(IconText, { icon: _jsx(MarkerPriorityIcon, { value: MARKER_PRIORITY_ID.noPriority }), text: _jsx(TypedDitto, { componentId: "marker.priority.nopriority" }), trailingElement: _jsx(CategoryBadge, { category: grey, color: "white", backgroundColor: "transparent" }) }, `cluster-${clusterId}-grey`));
    }
    const handleClick = () => {
        map.flyTo({
            center: [longitude, latitude],
            zoom: MAP_MARKER_CLUSTER_ZOOM
        });
    };
    return (_jsx(Marker, Object.assign({ latitude: latitude, longitude: longitude, onClick: handleClick }, { children: _jsx(MarkerCluster, { items: items, popup: _jsx(MapPopover, { content: {
                    type: 'marker-cluster',
                    value: _jsx(List, Object.assign({ width: "160px" }, { children: popupItems }))
                } }, 'popover'), clearHoveredFeature: clearHoveredFeature }) }), `cluster-${clusterId}`));
};
export const useMarkersClusters = () => {
    const { main: map } = useMap();
    const { style: mainMapStyle } = useAppSelector((state) => state.mainMap);
    const [isLoaded, setIsLoaded] = useState(false);
    const [clusters, setClusters] = useState([]);
    const dispatch = useAppDispatch();
    const clearHovered = useCallback(() => {
        dispatch(clearHoveredFeature());
    }, [dispatch]);
    const handleRender = useCallback(() => {
        if (!isLoaded || !(map === null || map === void 0 ? void 0 : map.loaded)) {
            return;
        }
        const newClusters = map.querySourceFeatures(MARKERS_SOURCE_ID, {
            filter: ['==', 'cluster', true]
        });
        let cached = true;
        for (const cluster of newClusters) {
            if (!clusters.map((node) => node.key).includes(`cluster-${cluster.id}`)) {
                cached = false;
            }
        }
        // Skip setClusters if all are cached and there are no new clusters.
        // This skips a huge amount of circular re-renders because this hook
        // listens to the "render" events, which are triggered again when the
        // clusters are returned and rendered on the map, causing an infinite loop.
        const uniqueClusters = uniqueV2(newClusters.map((cluster) => Number(cluster.id)));
        if (cached && uniqueClusters.length === clusters.length) {
            return;
        }
        const newClusterNodes = {};
        newClusters.forEach((cluster) => {
            const props = cluster.properties;
            if (!map || !props || !props.cluster || !props.cluster_id) {
                return;
            }
            const { cluster_id: clusterId } = props;
            const existingCluster = clusterCache[clusterId];
            if (existingCluster) {
                newClusterNodes[clusterId] = existingCluster;
                return;
            }
            const newCluster = createNewMarkerCluster(map, cluster, clearHovered);
            if (newCluster != null) {
                clusterCache[clusterId] = newCluster;
                newClusterNodes[clusterId] = newCluster;
            }
        });
        setClusters(Object.values(newClusterNodes));
    }, [map, clusters, isLoaded, clearHovered]);
    const handleLoad = () => {
        setIsLoaded(false);
    };
    const handleIdle = () => {
        setIsLoaded(true);
    };
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('render', handleRender);
        return () => {
            map.off('render', handleRender);
        };
    }, [map, handleRender]);
    useEffect(() => {
        if (!map) {
            return;
        }
        map.once('styledata', handleLoad);
        map.once('idle', handleIdle);
    }, [map, mainMapStyle]);
    return { clusters };
};
