import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { COLOR_NO_GRADE, EMI_GRADE, getColorForGrade, PASER_GRADE, PCI_GRADE } from '../../common/color';
import { round2Decimal } from '../../common/utils/number';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import styles from './GradeBadge.module.css';
function getRangeByType(type) {
    switch (type) {
        case 'emi':
            return EMI_GRADE;
        case 'pci':
            return PCI_GRADE;
        case 'paser':
            return PASER_GRADE;
    }
}
export function GradeBadge({ type = 'emi', grade, size, tooltip, prefix, suffix, 'data-test': dataTest }) {
    const range = getRangeByType(type);
    const value = (() => {
        if (typeof grade === 'number' && grade <= range.max && grade >= range.min) {
            return type === 'emi' ? round2Decimal(grade).toFixed(2) : Math.round(grade);
        }
        return '-';
    })();
    const badge = (_jsxs("div", Object.assign({ className: classes(styles['container'], size && styles['container__' + size]), style: {
            backgroundColor: typeof grade === 'number' ? getColorForGrade(grade, range) : COLOR_NO_GRADE
        }, "data-test": dataTest }, { children: [prefix, prefix && ' ', value, suffix && ' ', suffix] })));
    return tooltip ? _jsx(TooltipWrapper, Object.assign({ text: tooltip }, { children: badge })) : badge;
}
