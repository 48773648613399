export class MailServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * for k8s tcp probes, prevents flooding logs with 404
     * @returns any A successful response.
     * @throws ApiError
     */
    mailServiceRoot() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/'
        });
    }
    /**
     * @param body
     * @returns v1SendManyMailUsingTemplateResponse A successful response.
     * @throws ApiError
     */
    mailServiceSendManyMailUsingTemplate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/mail/send-many-using-template',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1SendMailUsingTemplateResponse A successful response.
     * @throws ApiError
     */
    mailServiceSendMailUsingTemplate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/mail/send-using-template',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1RenderTemplateResponse A successful response.
     * @throws ApiError
     */
    mailServiceRenderTemplate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/templates/render',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1RenderManyTemplatesResponse A successful response.
     * @throws ApiError
     */
    mailServiceRenderManyTemplates(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/templates/render-many',
            body: body
        });
    }
    /**
     * @param body
     * @returns v1TrackingSubmitEventsResponse A successful response.
     * @throws ApiError
     */
    mailServiceTrackingSubmitEvents(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/tracking/submit-events',
            body: body
        });
    }
}
