import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { classes } from 'classifizer';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './IconHover.module.css';
export function IconHover({ icon, text, iconSize = 24, clickable }) {
    const ref = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const [position, setPosition] = useState({ left: 0, top: 0 });
    useEffect(() => {
        var _a, _b, _c;
        if (!isHovering) {
            return;
        }
        const rect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        setPosition({ left: (_b = rect === null || rect === void 0 ? void 0 : rect.left) !== null && _b !== void 0 ? _b : 0, top: (_c = rect === null || rect === void 0 ? void 0 : rect.top) !== null && _c !== void 0 ? _c : 0 });
    }, [isHovering]);
    return (_jsxs("div", Object.assign({ ref: ref, className: classes(styles['container']), onMouseEnter: () => setIsHovering(true), onMouseLeave: () => setIsHovering(false), onWheelCapture: () => setIsHovering(false), style: {
            width: iconSize,
            height: iconSize
        } }, { children: [createPortal(_jsx(AnimatePresence, { children: isHovering && (_jsx("div", Object.assign({ style: {
                        position: 'absolute',
                        left: position.left,
                        top: position.top
                    } }, { children: _jsxs(motion.div, Object.assign({ className: classes(styles['tooltip'], clickable && styles['clickable']), transition: tooltipTransition, variants: tooltipVariants(iconSize), initial: "initial", exit: "exit", animate: "expanded" }, { children: [icon, text] }), 'tooltip') }), 'tooltip-wrapper')) }), document.body), icon] })));
}
const tooltipVariants = (iconSize) => ({
    initial: {
        opacity: 0,
        clipPath: `circle(${iconSize / 2}px at ${iconSize}px 50%)`
    },
    expanded: {
        opacity: 1.0,
        clipPath: `circle(140% at ${iconSize}px 50%)`
    },
    exit: {
        opacity: 0,
        clipPath: `circle(${iconSize / 2}px at ${iconSize}px 50%)`,
        transition: {
            opacity: {
                ease: 'easeIn',
                duration: 0.15,
                delay: 0.1
            }
        }
    }
});
const tooltipTransition = {
    opacity: {
        ease: 'easeOut',
        duration: 0.15
    },
    clipPath: {
        type: 'spring',
        stiffness: 500,
        damping: 60
    }
};
