import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchCurrentUser, fetchRoleList, fetchTeamAccount } from '../state/slices/auth';
export function useAppData() {
    const { isAuthenticated, targetSchema } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isAuthenticated && targetSchema) {
            dispatch(fetchTeamAccount());
            dispatch(fetchCurrentUser());
            dispatch(fetchRoleList());
        }
    }, [dispatch, isAuthenticated, targetSchema]);
}
