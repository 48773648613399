import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { DamageGradingPanel } from '../../components/DamageGradingPanel';
import { DetailsClassification } from '../../components/DetailsPanel/DetailsClassification/DetailsClassification';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { SegmentStreet } from '../../components/icons';
import { Loader } from '../../components/Loader/Loader';
import { MapboxStaticImage } from '../../components/PrintLayout/MapboxStaticImage/MapboxStaticImage';
import { PrintDetail } from '../../components/PrintLayout/PrintDetail/PrintDetail';
import { PrintLayout } from '../../components/PrintLayout/PrintLayout';
import { PrintMeta } from '../../components/PrintLayout/PrintMeta/PrintMeta';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { usePrint } from '../../hooks/usePrint';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchFilterTypes } from '../../state/slices/shared';
import { fetchSegmentsTk } from '../../state/slices/sharedDataHolder';
import { UnitFormatContainer } from '../Formatters/UnitFormatContainer/UnitFormatContainer';
export function PrintSegmentContainer({ ids }) {
    const dispatch = useAppDispatch();
    const { segmentList } = useAppSelector((s) => s.sharedDataHolder);
    const { teamAccount } = useAppSelector((s) => s.auth);
    const { conditionFilters: { types: { streetTypes, surfaceTypes, roadDamages, roadDamageGroups } } } = useAppSelector((s) => s.shared);
    const { onMapLoad, setImageCount } = usePrint(ids.length);
    const { shouldHideSurfaceTypes } = useLayerChecker();
    const { formatAddress, formatDate } = useFormatter();
    useEffect(() => {
        dispatch(fetchSegmentsTk(ids));
        dispatch(fetchFilterTypes());
        setImageCount(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!segmentList) {
        return _jsx(Loader, { variant: "fullscreen" });
    }
    return (_jsx(_Fragment, { children: segmentList === null || segmentList === void 0 ? void 0 : segmentList.map((segment) => {
            var _a, _b, _c, _d, _e;
            const dateTime = formatDate(segment.createdAt);
            const streetType = (_b = (_a = streetTypes.find((type) => type.id === segment.streetTypeId)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '';
            const surfaceType = (_d = (_c = surfaceTypes.find((type) => type.id === segment.surfaceTypeId)) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : '';
            return (_jsx(PrintLayout, { meta: _jsx(PrintMeta, { layerName: _jsx(Ditto, { componentId: "rightpanel.titletype.segment" }), icon: _jsx(SegmentStreet, {}), title: segment.streetName || _jsx(Ditto, { componentId: "tasktitle.notitle" }), date: dateTime.date, time: dateTime.time, id: segment.streetLibraryId, address: formatAddress(segment.addressStart) }), details: _jsx(PrintDetail, { fullwidthField: _jsxs(_Fragment, { children: [_jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.start" }), content: formatAddress(segment.addressStart, {
                                            isShort: true
                                        }), variant: "print" }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.end" }), content: formatAddress(segment.addressEnd, {
                                            isShort: true
                                        }), variant: "print" }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "streetclassification.classification" }), content: streetType, variant: "print" }), !shouldHideSurfaceTypes && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: surfaceType, variant: "print" })), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.length" }), content: _jsx(UnitFormatContainer, { type: "length", value: segment.length }), variant: "print" }), segment.width ? (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.width" }), content: _jsx(UnitFormatContainer, { type: "length", value: segment.width }), variant: "print" })) : undefined] })), _jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: (_e = segment.grade) === null || _e === void 0 ? void 0 : _e.emi, damageStats: segment.grade.damageStats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, variant: "print" })] }) }), map: _jsx(MapboxStaticImage, { width: 725, height: 346, containerHeight: "346px", onLoad: onMapLoad, geojson: segment.featureCollection, overlayAnchor: "bottom" }), mapFullWidth: true }, segment.streetLibraryId));
        }) }));
}
