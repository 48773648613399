import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { CaretCloseSmall, CaretOpenSmall } from '../icons';
import styles from './FilterGroupSection.module.css';
export function FilterGroupSection({ headline, children, trailing, startOpen = false }) {
    const [isOpen, setIsOpen] = useState(startOpen);
    function handleToggle() {
        setIsOpen(!isOpen);
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [_jsxs("a", Object.assign({ className: classes(styles['toggle']), onClick: handleToggle, "data-test": "toggle" }, { children: [_jsx("span", Object.assign({ className: styles['headline'] }, { children: headline })), _jsx("span", Object.assign({ className: styles['trailing'] }, { children: trailing })), isOpen ? _jsx(CaretOpenSmall, {}) : _jsx(CaretCloseSmall, {})] })), isOpen && _jsx("div", Object.assign({ className: classes(styles['items']) }, { children: children }))] })));
}
