import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Avatar } from '../../../uikit/Avatar/Avatar';
import { Divider } from '../../../uikit/Divider/Divider';
import { FilterSelectbox } from '../../FilterSelectbox/FilterSelectbox';
import { Check, Filters, LogOut, Users } from '../../icons';
import { Search } from '../../Search/Search';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { TextOverflow } from '../../TextOverflow/TextOverflow';
import styles from './MapLayerSettings.module.css';
const MIN_ACCOUNTS_TO_SWITCH = 2;
const MIN_ACCOUNTS_TO_SHOW_SEARCH = 3;
export function MapLayerSettings({ currentAccountId, onGlobalSettingClick, onUserSettingClick, onCoWorkerSettingClick, onLogoutClick, accountList, onAccountSwitch, userAccount, locale, onItemSelect }) {
    var _a;
    const [searchValue, setSearchValue] = useState('');
    const placehoderLabel = useDittoWrapper({ componentId: 'switchaccount.enter' });
    const currentAccountName = ((_a = accountList.find((item) => item.id === currentAccountId)) === null || _a === void 0 ? void 0 : _a.name) || 'Unknown Account';
    const showSearch = accountList.length > MIN_ACCOUNTS_TO_SHOW_SEARCH;
    const showAccountSwtich = accountList.length >= MIN_ACCOUNTS_TO_SWITCH;
    const filteredAccountList = searchValue
        ? accountList.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
        : accountList;
    function handleItemSelect(clickHandler) {
        return () => {
            onItemSelect === null || onItemSelect === void 0 ? void 0 : onItemSelect();
            clickHandler();
        };
    }
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [showAccountSwtich && (_jsxs(_Fragment, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "switchaccount" }), disabled: true, isLabel: true }), showSearch && (_jsx(Search, { placeholder: placehoderLabel, value: searchValue, onChange: (value) => setSearchValue(value) })), _jsx("div", Object.assign({ className: styles['options'] }, { children: filteredAccountList.map((item) => (_jsx(FilterSelectbox, { className: classes(styles['option'], styles[`option--locale-${locale}`]), noCheckbox: true, content: _jsx(TextOverflow, { className: styles['option-title'], text: item.name }), hoverTrailingText: _jsx(Ditto, { componentId: "switchaccount.switch" }), trailingElement: item.id === currentAccountId ? _jsx(Check, {}) : undefined, disabled: item.id === currentAccountId, onClick: () => onAccountSwitch(item.id), variant: "dark" }, item.id))) })), _jsx(Divider, { margin: "4px", variant: "white", className: styles['divider'] })] })), _jsx(SelectboxEntry, { text: currentAccountName, disabled: true, isLabel: true }), userAccount && (_jsx(FilterSelectbox, { noCheckbox: true, icon: _jsx(Avatar, { size: 24, firstName: userAccount.firstName || '', lastName: userAccount.lastName || '', src: userAccount.profileImageUrl != ''
                        ? userAccount.profileImageUrl
                        : undefined }), content: _jsx(TextOverflow, { className: styles['text'], maxLines: 1, text: `${userAccount.firstName} ${userAccount.lastName}`, width: 280 }), caption: _jsx(TextOverflow, { className: styles['text'], maxLines: 1, text: userAccount.email || userAccount.phoneNumber, width: 280 }), onClick: handleItemSelect(onUserSettingClick), variant: "dark" })), onCoWorkerSettingClick && (_jsx(FilterSelectbox, { noCheckbox: true, icon: _jsx(Users, {}), content: _jsx(Ditto, { componentId: "coworkermanagement.coworker" }), caption: _jsx(Ditto, { componentId: "coworkermanagement.accessrights" }), onClick: handleItemSelect(onCoWorkerSettingClick), variant: "dark", "data-test": "coworker-management-btn" })), onGlobalSettingClick && (_jsx(FilterSelectbox, { noCheckbox: true, icon: _jsx(Filters, {}), content: _jsx(Ditto, { componentId: "layerpanel.accountmodal.teamsettings" }), caption: _jsx(Ditto, { componentId: "teamaccountsettings.manageteamcontent" }), onClick: handleItemSelect(onGlobalSettingClick), variant: "dark", "data-test": "global-settings-btn" })), _jsx(Divider, { margin: "4px", variant: "white", className: styles['divider'] }), _jsx(FilterSelectbox, { noCheckbox: true, icon: _jsx(LogOut, {}), content: _jsx(Ditto, { componentId: "layerpanel.accountmodal.logout" }), onClick: handleItemSelect(onLogoutClick), "data-test": "btn-logout", variant: "dark" })] })));
}
