import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { MARKER_STATUS_ID } from '../../common/consts/marker';
import { PRINT_FIELD_WIDTH } from '../../common/consts/width';
import { MarkerStatusVignette } from '../../common/convert';
import { Icon, MarkerIcon } from '../../common/icon';
import { getCloseMarkerLink, getUniversalLinkForMarker } from '../../common/link';
import { parseRecurringFormula } from '../../common/recurringRules';
import { DueDatePickerControl } from '../../components/DueDatePicker/DueDatePickerControl/DueDatePickerControl';
import { AssigneeSelectField } from '../../components/EditorForms/AssigneeSelect/AssigneeSelectField/AssigneeSelectField';
import { CategoryItem } from '../../components/EditorForms/CategoryItem/CategoryItem';
import { FormInputSelect } from '../../components/FormInputSelect/FormInputSelect';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { FormInputTextarea } from '../../components/FormInputTextarea/FormInputTextarea';
import { Task } from '../../components/icons';
import { Loader } from '../../components/Loader/Loader';
import { MarkerPrioritySelectedValue } from '../../components/MarkerLowerForm/fields/MarkerPriorityField';
import { MarkerStatusSelectedValue } from '../../components/MarkerLowerForm/fields/MarkerStatusField';
import { MapboxStaticImage } from '../../components/PrintLayout/MapboxStaticImage/MapboxStaticImage';
import { PrintDetail } from '../../components/PrintLayout/PrintDetail/PrintDetail';
import { PrintImage } from '../../components/PrintLayout/PrintImage/PrintImage';
import { PrintLayout } from '../../components/PrintLayout/PrintLayout';
import { PrintMeta } from '../../components/PrintLayout/PrintMeta/PrintMeta';
import { QRCodeHolder } from '../../components/PrintLayout/QRCodeHolder/QRCodeHolder';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { usePrint } from '../../hooks/usePrint';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchMarkerFilters, fetchUserAccountList } from '../../state/slices/shared';
import { fetchMarkersTk } from '../../state/slices/sharedDataHolder';
export function PrintMarkerContainer({ ids }) {
    const dispatch = useAppDispatch();
    const { markerList } = useAppSelector((s) => s.sharedDataHolder);
    const { locale } = useAppSelector((s) => s.app);
    const { markerStatusList, markerCategoryList, markerPriorityList, taskAssigneeList } = useAppSelector((s) => s.shared);
    const dateTimeFormatter = useDateTimeFormatter();
    const { onMapLoad, onImageLoad, setImageCount } = usePrint(ids.length);
    const { formatAddress } = useFormatter();
    const creator = useDittoWrapper({ componentId: 'marker.creator' });
    const taskdescription = useDittoWrapper({ componentId: 'taskdescription' });
    useEffect(() => {
        dispatch(fetchMarkersTk({ ids }));
        dispatch(fetchMarkerFilters());
        dispatch(fetchUserAccountList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!markerList) {
            return;
        }
        const totalImagesWithUrl = markerList
            .map((r) => r.photos.filter((p) => !!p.thumbUrl).length)
            .reduce((total, photoCount) => total + photoCount, 0);
        setImageCount(totalImagesWithUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markerList]);
    if (!markerList) {
        return _jsx(Loader, { variant: "fullscreen" });
    }
    return (_jsx(_Fragment, { children: markerList === null || markerList === void 0 ? void 0 : markerList.map((marker) => {
            var _a;
            const markerCategory = markerCategoryList.find((category) => marker.categoryId === category.categoryId);
            const markerStatus = markerStatusList.find((status) => marker.statusId === status.statusId);
            const markerPriority = markerPriorityList.find((priority) => marker.priorityTypeId === priority.priorityId);
            const dueDate = ((_a = marker === null || marker === void 0 ? void 0 : marker.dueDate) === null || _a === void 0 ? void 0 : _a.dueDate)
                ? new Date(marker.dueDate.dueDate)
                : undefined;
            const dateTime = dateTimeFormatter(marker.createdAt);
            const recurringFormula = parseRecurringFormula(marker === null || marker === void 0 ? void 0 : marker.recurringFormula);
            return (_jsx(PrintLayout, { meta: _jsx(PrintMeta, { layerName: _jsx(Ditto, { componentId: "rightpanel.titletype.task" }), icon: _jsx(Task, {}), title: marker.title || _jsx(Ditto, { componentId: "tasktitle.notitle" }), date: dateTime.date, time: dateTime.time, id: marker.id, address: formatAddress(marker.addressJson), location: {
                        lat: marker.point.latitude,
                        lng: marker.point.longitude
                    } }), details: _jsxs(PrintDetail, Object.assign({ fullwidthField: _jsx(FormInputTextarea, { label: taskdescription, width: "100%", value: marker.issueDescription, variant: "ghost", autosize: true, disabled: true }) }, { children: [_jsx(FormInputSelect, { variant: "ghost", width: PRINT_FIELD_WIDTH, disabled: true, label: _jsx(Ditto, { componentId: "marker.status" }), value: markerStatus ? (_jsx(MarkerStatusSelectedValue, { status: markerStatus })) : undefined }), _jsx(FormInputSelect, { variant: "ghost", width: PRINT_FIELD_WIDTH, disabled: true, label: _jsx(Ditto, { componentId: "marker.priority" }), value: markerPriority ? (_jsx(MarkerPrioritySelectedValue, { priority: markerPriority })) : undefined }), _jsx(FormInputSelect, { variant: "ghost", width: PRINT_FIELD_WIDTH, disabled: true, label: _jsx(Ditto, { componentId: "marker.category" }), value: markerCategory ? (_jsx(CategoryItem, { selected: true, category: markerCategory })) : undefined, placeholder: _jsx(Ditto, { componentId: "notspecified" }) }), _jsx(AssigneeSelectField, { selectedValues: marker.assignees.map((a) => a.uuid), options: taskAssigneeList, variant: "ghost", width: PRINT_FIELD_WIDTH, disabled: true }), _jsx(DueDatePickerControl, { frequency: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.frequency, value: dueDate, dateTimeFormatter: dateTimeFormatter, variant: "ghost", disabled: true }), _jsx(FormInputText, { value: marker.createdBy, label: creator, variant: "ghost", width: PRINT_FIELD_WIDTH, disabled: true })] })), qrCode: _jsx(QRCodeHolder, { instruction: _jsx(Ditto, { componentId: "print.qrcodecta" }), qrCodeList: [
                        {
                            url: getUniversalLinkForMarker(marker.id),
                            icon: _jsx(Icon, { name: "ToDo" }),
                            text: _jsx(Ditto, { componentId: "print.qrcodeopenintodoapp" }),
                            codePosition: 'left'
                        },
                        {
                            url: getCloseMarkerLink(marker.decryptCloseParams, locale),
                            icon: (_jsx(MarkerStatusVignette, { value: MARKER_STATUS_ID.closed })),
                            text: _jsx(Ditto, { componentId: "print.qrcodemarktaskasdone" }),
                            codePosition: 'right'
                        }
                    ] }), map: _jsx(MapboxStaticImage, { lon: marker.point.longitude, lat: marker.point.latitude, onLoad: onMapLoad, overlayElement: _jsx(MarkerIcon, { priorityId: marker.priorityTypeId, width: 30 }), overlayAnchor: "bottom" }), images: marker.photos
                    .filter((p) => !!p.thumbUrl)
                    .map((p) => (_jsx(PrintImage, { src: p.thumbUrl, onLoad: onImageLoad, caption: dateTimeFormatter(p.createdAt).dateTime }, p.id))) }, marker.id));
        }) }));
}
