// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ul_2OvVeHqBRScaGrjPw{padding:12px;width:260px;height:62px;border-radius:4px;cursor:pointer}.Ul_2OvVeHqBRScaGrjPw:hover{background:rgba(129,129,129,.1)}.K5UV_uB9TTrux6b4CgOA{font-weight:400;font-size:14px;line-height:140%;margin-bottom:4px}.VZQqMVlb9BwZm7CWlLXx{font-weight:400;font-size:12px;line-height:14px;opacity:.4}`, "",{"version":3,"sources":["webpack://./src/components/Actionbar/Search/SearchItem/SearchItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CAGJ,4BACI,+BAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".container {\n    padding: 12px;\n    width: 260px;\n    height: 62px;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.container:hover {\n    background: rgba(129, 129, 129, 0.1);\n}\n\n.title {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    margin-bottom: 4px;\n}\n\n.description {\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 14px;\n    opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ul_2OvVeHqBRScaGrjPw`,
	"title": `K5UV_uB9TTrux6b4CgOA`,
	"description": `VZQqMVlb9BwZm7CWlLXx`
};
export default ___CSS_LOADER_EXPORT___;
