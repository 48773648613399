import { getParentElements } from './parentElements';
export function getCommonParentElement(refs) {
    const parentNodes = refs.map((ref) => getParentElements(ref)).reverse();
    for (const parents of parentNodes) {
        for (const parent of parents) {
            const test = parentNodes.every((p) => p.includes(parent));
            if (test) {
                return parent;
            }
        }
    }
}
