// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kT_36YIX1rBhPv564MFo{width:100%;display:flex;flex-wrap:wrap}.kT_36YIX1rBhPv564MFo>*:not(:last-child){margin-right:8px}.kT_36YIX1rBhPv564MFo>*{margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/components/AppliedFilterBar/AppliedFilterBar.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,YAAA,CACA,cAAA,CAGJ,yCACI,gBAAA,CAGJ,wBACI,iBAAA","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.container > *:not(:last-child) {\n    margin-right: 8px;\n}\n\n.container > * {\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kT_36YIX1rBhPv564MFo`
};
export default ___CSS_LOADER_EXPORT___;
