import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ManholeLowered, ManholeOutsticking, Pothole } from '../../components/icons';
import { referencingOrStreetAndHouseNumber } from '../addressFormatter';
import { ALERT_TYPE_SORT_ORDER } from '../consts/alert';
export function getAlertTranslation(typeName) {
    switch (typeName) {
        case 'pothole':
            return _jsx(Ditto, { componentId: "filter.damagetype.pothole" });
        case 'lowered_storm_drain':
            return _jsx(Ditto, { componentId: "filter.damagetype.loweredstormdrain" });
        case 'lowered_manhole':
            return _jsx(Ditto, { componentId: "filter.damagetype.loweredmanhole" });
        case 'outsticking_storm_drain':
            return _jsx(Ditto, { componentId: "filter.damagetype.outstickingstormdrain" });
        case 'outsticking_manhole':
            return _jsx(Ditto, { componentId: "filter.damagetype.outstickingmanhole" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getAlertIcon(typeName) {
    switch (typeName) {
        case 'pothole':
            return Pothole();
        case 'lowered_storm_drain':
            return ManholeLowered();
        case 'lowered_manhole':
            return ManholeLowered();
        case 'outsticking_storm_drain':
            return ManholeOutsticking();
        case 'outsticking_manhole':
            return ManholeOutsticking();
        default:
            return Pothole();
    }
}
export function getAlertSortOrder(typeName) {
    return ALERT_TYPE_SORT_ORDER[typeName];
}
export function alertDataToContent(item, dateTimeFormatter, countryCode) {
    return {
        id: item.recordAlertId,
        dateTime: dateTimeFormatter(item.inspectedAt),
        name: getAlertTranslation(item.typeName),
        address: referencingOrStreetAndHouseNumber(item.address, '-', countryCode),
        grade: item.grade,
        thumbnailUrl: item.thumbnailUrl
    };
}
