export const largeModalVariants = {
    initial: {
        y: 300,
        opacity: 0,
        transition: { type: 'spring', stiffness: 750, damping: 53, mass: 1.2 }
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 740, damping: 70, mass: 1.2, delay: 0.1 }
    }
};
const smallModalTransition = {
    type: 'spring',
    stiffness: 500,
    damping: 60
};
export const smallModalVariants = {
    initial: {
        y: 20,
        opacity: 0,
        transition: smallModalTransition
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: Object.assign(Object.assign({}, smallModalTransition), { delay: 0.1 })
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: smallModalTransition
    }
};
const dropdownTransition = {
    type: 'spring',
    stiffness: 1000,
    damping: 58
};
export const dropdownVariants = (direction = 'down', horizontal = false) => {
    const DROPDOWN_OFFSET = 8;
    let y = -DROPDOWN_OFFSET;
    if (direction === 'up') {
        y = DROPDOWN_OFFSET;
    }
    let x = 0;
    if (typeof horizontal === 'string' && horizontal.includes('left')) {
        x = DROPDOWN_OFFSET;
    }
    else if (typeof horizontal === 'string' && horizontal.includes('right')) {
        x = -DROPDOWN_OFFSET;
    }
    if (x !== 0) {
        y = 0;
    }
    return {
        initial: {
            x: x,
            y: y,
            opacity: 0.2,
            transition: dropdownTransition
        },
        visible: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: dropdownTransition
        },
        exit: {
            x: x,
            y: y,
            opacity: 0,
            transition: dropdownTransition
        }
    };
};
export const layerSelectorTitleTransition = {
    type: 'spring',
    stiffness: 750,
    damping: 53,
    mass: 1.2
};
export const layerSelectorTitleVariants = {
    overlay: {
        opacity: 1
    },
    initial: {
        opacity: 0
    }
};
