// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U8XnuzqB09twO0pcvK0E{margin-bottom:8px}.KlZgsvvFUIWnAoIYiKfM{width:326px}.IB1UFsK4NMVRjyU96NVL{width:152px}.pMh29OgWFmEsrIN3dGqg{display:inline-flex;line-height:130%;font-size:14px;font-weight:400;margin-bottom:6px}.JFMmVn9UT6KCNgJxpDkR{width:100%;display:flex;flex-direction:row;align-items:start;justify-content:space-between;gap:10px;margin-bottom:8px}.zxkfX2xzgcViDankogcy{width:inherit}`, "",{"version":3,"sources":["webpack://./src/components/DatePickerContent/RepeatForm/RepeatForm.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,mBAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACA,QAAA,CACA,iBAAA,CAGJ,sBACI,aAAA","sourcesContent":[".input-select {\n    margin-bottom: 8px;\n}\n\n.repeat-option {\n    width: 326px;\n}\n\n.repeat-option-half {\n    width: 152px;\n}\n\n.interval-label {\n    display: inline-flex;\n    line-height: 130%;\n    font-size: 14px;\n    font-weight: 400;\n    margin-bottom: 6px;\n}\n\n.interval-select {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: start;\n    justify-content: space-between;\n    gap: 10px;\n    margin-bottom: 8px;\n}\n\n.interval-select-item {\n    width: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-select": `U8XnuzqB09twO0pcvK0E`,
	"repeat-option": `KlZgsvvFUIWnAoIYiKfM`,
	"repeat-option-half": `IB1UFsK4NMVRjyU96NVL`,
	"interval-label": `pMh29OgWFmEsrIN3dGqg`,
	"interval-select": `JFMmVn9UT6KCNgJxpDkR`,
	"interval-select-item": `zxkfX2xzgcViDankogcy`
};
export default ___CSS_LOADER_EXPORT___;
