import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { SIGN_PROPERTY_TYPE } from '../../common/consts/trafficSign';
import { getNotificationSettingProps } from '../../common/convert/notification';
import { AssetFoldersSettingPanel } from '../../components/Modals/GlobalSettingsModal/AssetFoldersSettingPanel/AssetFoldersSettingPanel';
import { AssetTypesSettingPanel } from '../../components/Modals/GlobalSettingsModal/AssetTypesSettingPanel/AssetTypesSettingPanel';
import { GlobalSettingsModal } from '../../components/Modals/GlobalSettingsModal/GlobalSettingsModal';
import { NotificationSettingPanel } from '../../components/Modals/GlobalSettingsModal/NotificationSettingPanel/NotificationSettingPanel';
import { PlanBudgetAndNetworkSettingPanel } from '../../components/Modals/GlobalSettingsModal/PlanBudgetAndNetworkSettingPanel/PlanBudgetAndNetworkSettingPanel';
import { PlanPropertiesSettingPanel } from '../../components/Modals/GlobalSettingsModal/PlanPropertiesSettingPanel/PlanPropertiesSettingPanel';
import { PlanTableViewSettingPanel } from '../../components/Modals/GlobalSettingsModal/PlanTableViewSettingPanel/PlanTableViewSettingPanel';
import { PlanTreatmentsSettingPanel } from '../../components/Modals/GlobalSettingsModal/PlanTreatmentsSettingPanel/PlanTreatmentsSettingPanel';
import { SignInventoryFilesPanel } from '../../components/Modals/GlobalSettingsModal/SignInventoryFilesPanel/SignInventoryFilesPanel';
import { SignInventoryPropertiesSettingPanel } from '../../components/Modals/GlobalSettingsModal/SignInventoryPropertiesPanel/SignInventoryPropertiesSettingPanel';
import { TaskCategoriesSettingPanel } from '../../components/Modals/GlobalSettingsModal/TaskCategoriesSettingPanel/TaskCategoriesSettingPanel';
import { TaskCostsSettingPanel } from '../../components/Modals/GlobalSettingsModal/TaskCostsSettingPanel/TaskCostsSettingPanel';
import { TaskFoldersSettingPanel } from '../../components/Modals/GlobalSettingsModal/TaskFoldersSettingPanel/TaskFoldersSettingPanel';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useAssetsFoldersSettings } from '../../hooks/settings/useAssetsFoldersSettings';
import { useAssetTypesSettings } from '../../hooks/settings/useAssetTypesSettings';
import { usePlanBudgetAndNetworkSettings } from '../../hooks/settings/usePlanBudgetAndNetworkSettings';
import { usePlanPropertiesSettings } from '../../hooks/settings/usePlanPropertiesSettings';
import { usePlanTableViewSettings } from '../../hooks/settings/usePlanTableViewSettings';
import { usePlanTreatmentsSettings } from '../../hooks/settings/usePlanTreatmentsSettings';
import { useSignInventoryFoldersSettings } from '../../hooks/settings/useSignInventoryFoldersSettings';
import { useSignInventoryPropertiesSettings } from '../../hooks/settings/useSignInventoryPropertiesSettings';
import { useTaskCategoriesSettings } from '../../hooks/settings/useTaskCategoriesSettings';
import { useTaskCostsSettings } from '../../hooks/settings/useTaskCostsSettings';
import { useTasksFoldersSettings } from '../../hooks/settings/useTasksFoldersSettings';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { saveNotificationSettingsTk, setCurrentPageId } from '../../state/slices/globalSetting';
import { setSelectedLayerId } from '../../state/slices/layer';
import { fetchAssetsTk, setAssetActiveTabKey } from '../../state/slices/leftPanelAsset';
import { fetchMarkers, setMarkerActiveTabKey } from '../../state/slices/leftPanelMarker';
import { fetchMarkerGeoJSON } from '../../state/slices/mainMap';
import { fetchAssetFolderTk, fetchAssetTypes, fetchMarkerAttributeSchemaTk, fetchMarkerFilters, fetchMarkerFolderTk } from '../../state/slices/shared';
export function GlobalSettingsModalContainer({ modalProps, initialPageId }) {
    const dispatch = useAppDispatch();
    const { currentPageId } = useAppSelector((state) => state.globalSettings);
    const { teamAccount, userAccount } = useAppSelector((state) => state.auth);
    const { unitList } = useAppSelector((state) => state.shared);
    const { removeModal } = useModalContext();
    const unitFormatter = useUnitFormatter();
    const { isAdmin, isEditor } = useUserRole();
    const { localAssetTypeList, setLocalAssetTypeList, resetLocalData, handleAssetSaveClick, handleAssetCancelClick, handleAssetTypeDeleteClick, handleAssetAttributeDeleteClick, handleAssetSelectOptionDeleteClick } = useAssetTypesSettings();
    const taskCostSettingHandlers = useTaskCostsSettings();
    const { handleTaskCostsCancelClick } = taskCostSettingHandlers;
    const { categories, handleTaskCategoriesCancelClick, handleTaskCategoriesSaveClick, handleCategoryCreate, handleCategoryDelete, handleCategoryNameChange, handleCategorySelectColor } = useTaskCategoriesSettings();
    const { planNetworkSettings, handlePlanNetworkSettingsChange, planBudgetSettings, setPlanBudgetSettings, handlePlanBudgetAndNetworkSaveClick, handlePlanBudgetAndNetworkCancelClick, handlePlanBudgetAndNetworkDeleteClick } = usePlanBudgetAndNetworkSettings();
    const { planPlanTableViewSettingList, setPlanTableViewSettingList, handlePlanTableViewSaveClick, handlePlanTableViewCancelClick } = usePlanTableViewSettings();
    const { planPropertyTypes, planUnitTypes, planPropertySettingList, getCustomPropertyDefaultTitle, setPlanPropertySettingList, handlePlanPropertySaveClick, handlePlanPropertyCancelClick, handlePlanPropertyDeleteClick, handlePlanPropertySelectOptionDeleteClick } = usePlanPropertiesSettings();
    const { planTreatmentsSettingList, planTreatmentUnitTypes, setPlanTreatmentsSettingList, handlePlanTreatmentsCancelClick, handlePlanTreatmentsDeleteClick, handlePlanTreatmentsSaveClick } = usePlanTreatmentsSettings();
    const { foldersList: signInventoryFoldersList, createFolder: signInventoryCreateFolder, renameFolder: signInventoryRenameFolder, rearrangeFolder: signInventoryRearrangeFolder, handleFoldersSaveClick: handleSignInventoryFoldersSaveClick, handleFoldersCancelClick: handleSignInventoryFoldersCancelClick, handleFolderDeleteClick: handleSignInventoryFolderDeleteClick, handleFolderFilterClick: handleSignInventoryFolderFilterClick } = useSignInventoryFoldersSettings();
    const { foldersList: tasksFoldersList, fetchFolders: tasksFetchFolders, createFolder: tasksCreateFolder, renameFolder: tasksRenameFolder, rearrangeFolder: tasksRearrangeFolder, handleFoldersSaveClick: handleTasksFoldersSaveClick, handleFoldersCancelClick: handleTasksFoldersCancelClick, handleFoldersFilterClick: handleTasksFolderFilterClick, handleFolderDeleteClick: handleTasksFolderDeleteClick } = useTasksFoldersSettings();
    const { foldersList: assetsFoldersList, fetchFolders: assetsFetchFolders, createFolder: assetsCreateFolder, renameFolder: assetsRenameFolder, rearrangeFolder: assetsRearrangeFolder, handleFoldersSaveClick: handleAssetsFoldersSaveClick, handleFoldersCancelClick: handleAssetsFoldersCancelClick, handleFoldersFilterClick: handleAssetsFolderFilterClick, handleFolderDeleteClick: handleAssetsFolderDeleteClick } = useAssetsFoldersSettings();
    const { signInventoryPropertyList, addSignPropertyItemToDeletedList, setSignInventoryPropertyList, handleSignInventoryPropertySettingsSaveClick, getSignCustomPropertyDefaultName, handleSignInventoryPropertySettingsCancelClick } = useSignInventoryPropertiesSettings();
    const { isEditableTrafficSignsEnabled, isTaskCostsEnabled, isTaskCategoryCustomizationEnabled } = useLayerChecker();
    function closeModal() {
        removeModal('GlobalSettingsModal');
        removeModal('DeleteModal');
    }
    function handlePageChange(pageId) {
        if (currentPageId === 'asset-type') {
            handleAssetCancelClick(() => {
                dispatch(setCurrentPageId(pageId));
                resetLocalData();
            });
            return;
        }
        if (currentPageId === 'plan-budget-and-network') {
            handlePlanBudgetAndNetworkCancelClick(planNetworkSettings, planBudgetSettings, () => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'plan-table-view') {
            handlePlanTableViewCancelClick(planPlanTableViewSettingList, () => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'plan-properties') {
            handlePlanPropertyCancelClick(planPropertySettingList, () => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'plan-treatments') {
            handlePlanTreatmentsCancelClick(planTreatmentsSettingList, () => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'sign-inventory-properties') {
            handleSignInventoryPropertySettingsCancelClick(signInventoryPropertyList, () => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'task-folders') {
            handleTasksFoldersCancelClick(() => {
                tasksFetchFolders();
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'task-costs') {
            handleTaskCostsCancelClick(() => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'task-categories') {
            handleTaskCategoriesCancelClick(() => {
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        if (currentPageId === 'asset-folders') {
            handleAssetsFoldersCancelClick(() => {
                assetsFetchFolders();
                dispatch(setCurrentPageId(pageId));
            });
            return;
        }
        dispatch(setCurrentPageId(pageId));
    }
    useEffect(() => {
        if (initialPageId) {
            dispatch(setCurrentPageId(initialPageId));
        }
    }, [dispatch, initialPageId]);
    useEffect(() => {
        if (currentPageId === 'task-costs') {
            dispatch(fetchMarkerAttributeSchemaTk());
        }
    }, [dispatch, currentPageId]);
    return (_jsx(GlobalSettingsModal, { currentPageId: currentPageId, onPageChange: handlePageChange, leftPanel: {
            plan: isAdmin,
            signInventory: !!isEditableTrafficSignsEnabled && isAdmin,
            task: isEditor,
            taskCosts: isTaskCostsEnabled
        }, rightPanel: (function () {
            switch (currentPageId) {
                case 'asset-type':
                    return (_jsx(AssetTypesSettingPanel, { assetTypeList: localAssetTypeList, setAssetTypeList: setLocalAssetTypeList, unitList: unitList, onSaveClick: () => handleAssetSaveClick(() => closeModal()), onCancelClick: () => handleAssetCancelClick(() => closeModal()), onAssetTypeDeleteClick: handleAssetTypeDeleteClick, onAttributeDeleteClick: handleAssetAttributeDeleteClick, onSelectOptionDeleteClick: handleAssetSelectOptionDeleteClick, scrollToAddNewType: (modalProps === null || modalProps === void 0 ? void 0 : modalProps.type) === 'asset-type'
                            ? modalProps.shouldGoToAddNewType
                            : undefined, scrollToSelectedType: (modalProps === null || modalProps === void 0 ? void 0 : modalProps.type) === 'asset-type'
                            ? modalProps.selectedAssetType
                            : undefined, isReadOnly: !isAdmin }));
                case 'asset-folders':
                    return (_jsx(AssetFoldersSettingPanel, { isReadOnly: !isAdmin, foldersList: assetsFoldersList, createFolder: assetsCreateFolder, renameFolder: assetsRenameFolder, rearrangeFolder: assetsRearrangeFolder, onFolderDeleteClick: handleAssetsFolderDeleteClick, onCancelClick: () => handleAssetsFoldersCancelClick(() => closeModal()), onSaveClick: () => handleAssetsFoldersSaveClick(() => {
                            dispatch(fetchAssetFolderTk());
                            dispatch(fetchAssetTypes());
                            dispatch(fetchAssetsTk());
                            closeModal();
                        }), onFolderFilterClick: (folderId) => {
                            dispatch(setSelectedLayerId('asset'));
                            dispatch(setAssetActiveTabKey('list'));
                            handleAssetsFolderFilterClick(folderId);
                            closeModal();
                        } }));
                case 'task-folders':
                    return (_jsx(TaskFoldersSettingPanel, { isReadOnly: !isAdmin, foldersList: tasksFoldersList, createFolder: tasksCreateFolder, renameFolder: tasksRenameFolder, rearrangeFolder: tasksRearrangeFolder, onFolderDeleteClick: handleTasksFolderDeleteClick, onCancelClick: () => handleTasksFoldersCancelClick(() => closeModal()), onSaveClick: () => handleTasksFoldersSaveClick(() => {
                            dispatch(fetchMarkerFolderTk());
                            dispatch(fetchMarkerFilters());
                            dispatch(fetchMarkers());
                            dispatch(fetchMarkerGeoJSON());
                            closeModal();
                        }), onFolderFilterClick: (folderId) => {
                            dispatch(setSelectedLayerId('marker'));
                            dispatch(setMarkerActiveTabKey('list'));
                            handleTasksFolderFilterClick(folderId);
                            closeModal();
                        } }));
                case 'task-costs':
                    return (_jsx(TaskCostsSettingPanel, Object.assign({}, taskCostSettingHandlers, { unitList: unitList, isReadOnly: !isAdmin })));
                case 'task-categories':
                    return (_jsx(TaskCategoriesSettingPanel, { options: categories, isReadOnly: !isAdmin, scrollToAddNewCategory: (modalProps === null || modalProps === void 0 ? void 0 : modalProps.type) === 'task-categories' &&
                            !!modalProps.shouldGoToAddNewCategory, onTaskCategoriesCancelClick: () => {
                            handleTaskCategoriesCancelClick(() => {
                                closeModal();
                            });
                        }, onTaskCategoriesSaveClick: () => {
                            handleTaskCategoriesSaveClick(() => {
                                closeModal();
                            });
                        }, onCategoryCreate: handleCategoryCreate, onCategoryDelete: handleCategoryDelete, onCategoryNameChange: handleCategoryNameChange, onCategorySelectColor: handleCategorySelectColor, isCustomizationEnabled: isTaskCategoryCustomizationEnabled }));
                case 'notification':
                    if (userAccount === null || userAccount === void 0 ? void 0 : userAccount.notificationSettings) {
                        return (_jsx(NotificationSettingPanel, { settings: getNotificationSettingProps(userAccount.notificationSettings), onCancelClick: () => closeModal(), onSaveClick: (value) => {
                                dispatch(saveNotificationSettingsTk(value));
                                closeModal();
                            } }));
                    }
                    break;
                case 'plan-table-view':
                    return (_jsx(PlanTableViewSettingPanel, { list: planPlanTableViewSettingList, setList: setPlanTableViewSettingList, onSaveClick: (list) => handlePlanTableViewSaveClick(list, () => closeModal()), onCancelClick: (list) => handlePlanTableViewCancelClick(list, () => closeModal()) }));
                case 'plan-budget-and-network':
                    return (_jsx(PlanBudgetAndNetworkSettingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, networkSettings: planNetworkSettings, setNetworkSettings: handlePlanNetworkSettingsChange, budgetsSettings: planBudgetSettings, setBudgetsSettings: setPlanBudgetSettings, scrollToSelectedPropertyKey: (modalProps === null || modalProps === void 0 ? void 0 : modalProps.type) === 'plan-budget-and-network'
                            ? modalProps.selectedCustomPropertyKey
                            : undefined, onSaveClick: (networkSettings, budgetSettings) => handlePlanBudgetAndNetworkSaveClick(networkSettings, budgetSettings, () => closeModal()), onCancelClick: (networkSettings, budgetSettings) => handlePlanBudgetAndNetworkCancelClick(networkSettings, budgetSettings, () => closeModal()), onDeleteClick: (year, confirmCallback) => handlePlanBudgetAndNetworkDeleteClick(year, confirmCallback), unitFormatter: unitFormatter }));
                case 'plan-properties':
                    return (_jsx(PlanPropertiesSettingPanel, { list: planPropertySettingList, tableViewSettingsList: planPlanTableViewSettingList, propertyTypeList: planPropertyTypes, unitTypeList: planUnitTypes, setList: setPlanPropertySettingList, getCustomPropertyDefaultTitle: getCustomPropertyDefaultTitle, scrollToSelectedPropertyKey: (modalProps === null || modalProps === void 0 ? void 0 : modalProps.type) === 'plan-properties'
                            ? modalProps.selectedCustomPropertyKey
                            : undefined, onSaveClick: (list) => handlePlanPropertySaveClick(list, () => closeModal()), onCancelClick: (list) => handlePlanPropertyCancelClick(list, () => closeModal()), onAttributeDelete: (id, confirmCallback) => handlePlanPropertyDeleteClick(id, confirmCallback), onSelectOptionDelete: (columnKey, id, confirmCallback) => handlePlanPropertySelectOptionDeleteClick(columnKey, id, confirmCallback) }));
                case 'plan-treatments':
                    return (_jsx(PlanTreatmentsSettingPanel, { list: planTreatmentsSettingList, unitTypeList: planTreatmentUnitTypes, setList: setPlanTreatmentsSettingList, unitFormatter: unitFormatter, onSaveClick: (list) => {
                            handlePlanTreatmentsSaveClick(list, () => closeModal());
                        }, onCancelClick: (list) => {
                            handlePlanTreatmentsCancelClick(list, () => closeModal());
                        }, onTreatmentDelete: (id, confirmCallback) => {
                            handlePlanTreatmentsDeleteClick(id, confirmCallback);
                        } }));
                case 'sign-inventory-files':
                    return (_jsx(SignInventoryFilesPanel, { isReadOnly: !isAdmin, foldersList: signInventoryFoldersList, createFolder: signInventoryCreateFolder, renameFolder: signInventoryRenameFolder, rearrangeFolder: signInventoryRearrangeFolder, onCancelClick: () => handleSignInventoryFoldersCancelClick(() => closeModal()), onSaveClick: () => handleSignInventoryFoldersSaveClick(() => closeModal()), onFolderDeleteClick: handleSignInventoryFolderDeleteClick, onFolderFilterClick: (folderId) => {
                            handleSignInventoryFolderFilterClick(folderId);
                            closeModal();
                        } }));
                case 'sign-inventory-properties':
                    return (_jsx(SignInventoryPropertiesSettingPanel, { signPropertyList: signInventoryPropertyList, propertyTypeList: [
                            SIGN_PROPERTY_TYPE.text,
                            SIGN_PROPERTY_TYPE.number,
                            SIGN_PROPERTY_TYPE.select,
                            SIGN_PROPERTY_TYPE.date
                        ], unitTypeList: unitList, setSignPropertyList: setSignInventoryPropertyList, addItemToDeletedList: addSignPropertyItemToDeletedList, getCustomPropertyDefaultName: getSignCustomPropertyDefaultName, scrollToSelectedPropertyId: 
                        // undefined
                        (modalProps === null || modalProps === void 0 ? void 0 : modalProps.type) === 'sign-inventory-properties'
                            ? modalProps.selectedCustomPropertyId
                            : undefined, onSaveClick: (list) => handleSignInventoryPropertySettingsSaveClick(list, () => closeModal()), onCancelClick: (list) => handleSignInventoryPropertySettingsCancelClick(list, () => closeModal()) }));
            }
        })() }));
}
