// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w4R6XOxN5WO2gObCcp1M{height:20px;position:relative}.qxzXlS2MglaJscoA2_v5{width:18px;height:18px;overflow:hidden;position:absolute;top:0;left:0;margin:1px}.qxzXlS2MglaJscoA2_v5::after{content:"";position:absolute;left:0;top:0;width:100%;height:100%;border-radius:50%;box-shadow:inset 0 0 0 1px rgba(0,0,0,.2)}.qxzXlS2MglaJscoA2_v5:nth-child(1){left:0;z-index:4}.qxzXlS2MglaJscoA2_v5:nth-child(2){left:8px;z-index:3}.qxzXlS2MglaJscoA2_v5:nth-child(3){left:14px;z-index:2}.qxzXlS2MglaJscoA2_v5>div{transform-origin:top left}`, "",{"version":3,"sources":["webpack://./src/components/MapPinAttachmentPriorities/MapPinAttachmentPriorities.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CAGJ,6BACI,UAAA,CACA,iBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,yCAAA,CAGJ,mCACI,MAAA,CACA,SAAA,CAGJ,mCACI,QAAA,CACA,SAAA,CAGJ,mCACI,SAAA,CACA,SAAA,CAGJ,0BACI,yBAAA","sourcesContent":[".container {\n    height: 20px;\n    position: relative;\n}\n\n.icon {\n    width: 18px;\n    height: 18px;\n    overflow: hidden;\n    position: absolute;\n    top: 0;\n    left: 0;\n    margin: 1px;\n}\n\n.icon::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);\n}\n\n.icon:nth-child(1) {\n    left: 0;\n    z-index: 4;\n}\n\n.icon:nth-child(2) {\n    left: 8px;\n    z-index: 3;\n}\n\n.icon:nth-child(3) {\n    left: 14px;\n    z-index: 2;\n}\n\n.icon > div {\n    transform-origin: top left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `w4R6XOxN5WO2gObCcp1M`,
	"icon": `qxzXlS2MglaJscoA2_v5`
};
export default ___CSS_LOADER_EXPORT___;
