import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { v4 as uuid } from 'uuid';
import { createRRule, parseRecurringFormula } from '../../../common/recurringRules';
import { isValidRecurringFormula } from '../../../common/recurringRules/validate';
import { Button } from '../../../uikit/Button/Button';
import { DatePickerContent } from '../../DatePickerContent';
import { Close } from '../../icons';
import { Modal } from '../../Modal/Modal';
import styles from './DueDatePickerModal.module.css';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MIN_DATE = new Date(2020, 0, 1);
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MAX_DATE = new Date(2050, 0, 1);
export function DueDatePickerModal({ recurringFormula, localeSupported, closestDueDate, onCloseClick, onConfirm }) {
    var _a;
    const [localFormula, setLocalFormula] = useState(recurringFormula);
    const [localDuedate, setLocalDuedate] = useState(closestDueDate || ((_a = parseRecurringFormula(recurringFormula)) === null || _a === void 0 ? void 0 : _a.startDate));
    // Used to force complete re-render and a new instance of DatePickerContent on reset.
    const [key, setKey] = useState(uuid());
    const isValidFormula = isValidRecurringFormula(parseRecurringFormula(localFormula));
    function handleResetClick() {
        setLocalFormula('');
        setLocalDuedate(undefined);
        setKey(uuid());
    }
    function handleFormulaChange(newFormula) {
        setLocalFormula(newFormula ? createRRule(newFormula).toString() : '');
    }
    function handleConfirmClick() {
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(localFormula);
    }
    return (_jsx(Modal, { width: 760, title: _jsx(Ditto, { componentId: "duedate" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: onCloseClick }), content: _jsx(DatePickerContent, { recurringFormula: localFormula, locale: localeSupported, closestDueDate: localDuedate, showDateInputs: true, showRepeatForm: true, onFormulaChange: handleFormulaChange, layout: "horizontal", showNeverLabel: true, minDate: MIN_DATE, maxDate: MAX_DATE, fixedHeight: true }, key), footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx("div", Object.assign({ className: styles['button-reset'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleResetClick }, { children: _jsx(Ditto, { componentId: "appliedfilter.reset" }) })) })), _jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: onCloseClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })), _jsx("div", Object.assign({ className: styles['button-confirm'] }, { children: _jsx(Button, Object.assign({ color: "dark", variant: 'fullwidth', onClick: handleConfirmClick, disabled: !isValidFormula }, { children: _jsx(Ditto, { componentId: "modal.confirm" }) })) }))] }))] })), onClickOutside: onCloseClick }));
}
