// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UfYkdBqKc6RPOmRqSInA{display:flex;flex-direction:column;align-items:center;width:320px;height:70px}.zAdDlQwiZIraDURKec7R{font-weight:600;font-size:14px;line-height:14px;color:var(--primary-black);opacity:.6;margin-bottom:12px;margin-top:12px}.TJ0_PVPfIxFF7BCNyl8A{display:flex;flex-direction:row;justify-content:center;align-items:center;padding:0 12px;width:345px;height:40px}.TJ0_PVPfIxFF7BCNyl8A>*:not(:last-child){margin-right:6px;margin-left:0}.TJ0_PVPfIxFF7BCNyl8A>button>div{padding:0}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,WAAA,CACA,WAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CAEA,UAAA,CACA,kBAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,WAAA,CACA,WAAA,CAGJ,yCACI,gBAAA,CACA,aAAA,CAGJ,iCACI,SAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    width: 320px;\n    height: 70px;\n}\n\n.text {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 14px;\n    color: var(--primary-black);\n\n    opacity: 0.6;\n    margin-bottom: 12px;\n    margin-top: 12px;\n}\n\n.buttons {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 0 12px;\n\n    width: 345px;\n    height: 40px;\n}\n\n.buttons > *:not(:last-child) {\n    margin-right: 6px;\n    margin-left: 0;\n}\n\n.buttons > button > div {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UfYkdBqKc6RPOmRqSInA`,
	"text": `zAdDlQwiZIraDURKec7R`,
	"buttons": `TJ0_PVPfIxFF7BCNyl8A`
};
export default ___CSS_LOADER_EXPORT___;
