import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Button } from '../../../uikit/Button/Button';
import { FormInputText } from '../../FormInputText/FormInputText';
import { Close, Search } from '../../icons';
import { Modal } from '../../Modal/Modal';
import { TrafficSignCreatorFilterGroup } from '../../TrafficSignCreatorFilterGroup/TrafficSignCreatorFilterGroup';
import styles from './SignTypeSelectModal.module.css';
export function SignTypeSelectModal({ onSelectType, onSearchValueChange, searchResults, onClose }) {
    const [searchValue, setSearchValue] = useState('');
    const placeholder = useDittoWrapper({ componentId: 'trafficsigns.searchplaceholder' });
    const handleSearchValueChange = (value) => {
        setSearchValue(value);
        onSearchValueChange(value);
    };
    function handleCloseClick() {
        onClose();
    }
    return (_jsx(Modal, { className: styles['modal'], width: 480, title: _jsx(Ditto, { componentId: 'trafficsigns.properties.nameandtypeid' }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: _jsxs(_Fragment, { children: [_jsx(FormInputText, { autoFocus: true, placeholder: placeholder, value: searchValue, onValueChange: handleSearchValueChange, trailingIcon: _jsx(Search, {}), height: 48 }), _jsx("div", Object.assign({ className: styles['filter-groups'] }, { children: searchResults.map((group) => (_jsx(TrafficSignCreatorFilterGroup, { id: group.id, items: group.items, onSelectType: onSelectType }, group.id))) }))] }), onClickOutside: handleCloseClick }));
}
