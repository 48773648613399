export var INSPECTION_MODE_ID;
(function (INSPECTION_MODE_ID) {
    INSPECTION_MODE_ID[INSPECTION_MODE_ID["trackControl"] = 1] = "trackControl";
    INSPECTION_MODE_ID[INSPECTION_MODE_ID["winterService"] = 2] = "winterService";
})(INSPECTION_MODE_ID || (INSPECTION_MODE_ID = {}));
export var WINTER_SERVICE_MODE_ID;
(function (WINTER_SERVICE_MODE_ID) {
    WINTER_SERVICE_MODE_ID[WINTER_SERVICE_MODE_ID["plowing"] = 1] = "plowing";
    WINTER_SERVICE_MODE_ID[WINTER_SERVICE_MODE_ID["salting"] = 2] = "salting";
})(WINTER_SERVICE_MODE_ID || (WINTER_SERVICE_MODE_ID = {}));
