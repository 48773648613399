// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LrTPcEAV8fqOrJYOzbVQ{display:inline-block}.xmXFbYXtctvzrtZOFzlW{display:flex;justify-content:center;align-items:center;padding:4px;height:24px;background:rgba(15,18,22,.05);border-radius:6px}.HiGMN8tSPicJrb2FLQnF{background:var(--primary-black);border:2px solid var(--primary-white);color:var(--primary-white)}.YU8Af1HriqfQlIckFKJe{border-radius:var(--corner-radius-corner-s, 6px);background:var(--status-error, #f13b3b);color:var(--primary-white)}.X79kit8UWDAHrwS74Guo{height:18px}.FJxCoycU8DUkEMhrEvHD{font-weight:600;font-size:12px}`, "",{"version":3,"sources":["webpack://./src/components/Pill/Pill.module.css"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAEJ,sBACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,WAAA,CACA,6BAAA,CACA,iBAAA,CAGJ,sBACI,+BAAA,CACA,qCAAA,CACA,0BAAA,CAGJ,sBACI,gDAAA,CACA,uCAAA,CACA,0BAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,eAAA,CACA,cAAA","sourcesContent":[".wrapper {\n    display: inline-block;\n}\n.container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 4px;\n    height: 24px;\n    background: rgba(15, 18, 22, 0.05);\n    border-radius: 6px;\n}\n\n.container__black {\n    background: var(--primary-black);\n    border: 2px solid var(--primary-white);\n    color: var(--primary-white);\n}\n\n.container__red {\n    border-radius: var(--corner-radius-corner-s, 6px);\n    background: var(--status-error, #f13b3b);\n    color: var(--primary-white);\n}\n\n.container__small {\n    height: 18px;\n}\n\n.text {\n    font-weight: 600;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LrTPcEAV8fqOrJYOzbVQ`,
	"container": `xmXFbYXtctvzrtZOFzlW`,
	"container__black": `HiGMN8tSPicJrb2FLQnF`,
	"container__red": `YU8Af1HriqfQlIckFKJe`,
	"container__small": `X79kit8UWDAHrwS74Guo`,
	"text": `FJxCoycU8DUkEMhrEvHD`
};
export default ___CSS_LOADER_EXPORT___;
