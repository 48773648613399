// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LEn5SEdUQEjN7bq_txHd{display:flex;flex-direction:column;align-items:stretch;width:100%}.n1_MXotm2xrH6TVxSdmS{display:flex;padding:0px var(--Padding-PaddingM, 10px) var(--Gap-GapL, 8px) var(--Padding-PaddingM, 18px);flex-direction:column;align-items:flex-start;gap:6px;align-self:stretch}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/Changelog/Changelog.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,4FAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    width: 100%;\n}\n\n.content {\n    display: flex;\n    padding: 0px var(--Padding-PaddingM, 10px) var(--Gap-GapL, 8px) var(--Padding-PaddingM, 18px);\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 6px;\n    align-self: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LEn5SEdUQEjN7bq_txHd`,
	"content": `n1_MXotm2xrH6TVxSdmS`
};
export default ___CSS_LOADER_EXPORT___;
