import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
export const planSortingOptions = [
    {
        id: 'created_at-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'created_at-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" }),
        showDividerBelow: true
    },
    {
        id: 'completion_year_and_month-asc',
        text: _jsx(Ditto, { componentId: "sort.date.sooner" })
    },
    {
        id: 'completion_year_and_month-desc',
        text: _jsx(Ditto, { componentId: "sort.date.later" }),
        showDividerBelow: true
    },
    {
        id: 'costs-asc',
        text: _jsx(Ditto, { componentId: "sort.costs.lowest" })
    },
    {
        id: 'costs-desc',
        text: _jsx(Ditto, { componentId: "sort.costs.highest" }),
        showDividerBelow: true
    },
    {
        id: 'grade-desc',
        text: _jsx(Ditto, { componentId: "sort.grade.worst" })
    },
    {
        id: 'grade-asc',
        text: _jsx(Ditto, { componentId: "sort.grade.best" }),
        showDividerBelow: true
    },
    {
        id: 'priority-desc',
        text: _jsx(Ditto, { componentId: "sort.priority.highest" })
    },
    {
        id: 'priority-asc',
        text: _jsx(Ditto, { componentId: "sort.priority.lowest" }),
        showDividerBelow: true
    },
    {
        id: 'area-desc',
        text: _jsx(Ditto, { componentId: "sort.area.larger" })
    },
    {
        id: 'area-asc',
        text: _jsx(Ditto, { componentId: "sort.area.smaller" }),
        showDividerBelow: true
    },
    {
        id: 'name-asc',
        text: _jsx(Ditto, { componentId: "sort.name.ascending" })
    },
    {
        id: 'name-desc',
        text: _jsx(Ditto, { componentId: "sort.name.descending" })
    }
];
export const markerSortingOptions = [
    {
        id: 'date-dec',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'date-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" })
    }
];
export const assetSortingOptions = [
    {
        id: 'date-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'date-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" }),
        showDividerBelow: true
    },
    {
        id: 'title-asc',
        text: _jsx(Ditto, { componentId: "sort.name.ascending" })
    },
    {
        id: 'title-desc',
        text: _jsx(Ditto, { componentId: "sort.name.descending" })
    }
];
export const alertSortingOptions = [
    {
        id: 'created_at_server-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'created_at_server-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" })
    }
];
export const objectSortingOptions = [
    {
        id: 'date-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'date-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" })
    }
];
export const signsSortingOptions = [
    {
        id: 'date-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'date-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" }),
        showDividerBelow: true
    },
    {
        id: 'street-asc',
        text: _jsx(Ditto, { componentId: "sort.streetnameascending" })
    },
    {
        id: 'street-desc',
        text: _jsx(Ditto, { componentId: "sort.streetnamedescending" })
    }
];
export const inspectionSortingOptions = [
    {
        id: 'date-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'date-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" })
    }
];
export const signInventorySortingOptions = [
    {
        id: 'date-desc',
        text: _jsx(Ditto, { componentId: "sort.date.newest" })
    },
    {
        id: 'date-asc',
        text: _jsx(Ditto, { componentId: "sort.date.oldest" })
    }
];
export const edgesSortingOptions = [
    {
        id: 'length-desc',
        text: _jsx(Ditto, { componentId: "sort.length.longest" })
    },
    {
        id: 'length-asc',
        text: _jsx(Ditto, { componentId: "sort.length.shortest" }),
        showDividerBelow: true
    },
    {
        id: 'grade-desc',
        text: _jsx(Ditto, { componentId: "sort.grade.worst" })
    },
    {
        id: 'grade-asc',
        text: _jsx(Ditto, { componentId: "sort.grade.best" })
    }
];
