// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZWIV9QZxKApy5EHEwL0G{position:relative}.B6lYQJ1aT9qwebWfSZ_L{height:8px}.BGAlUjv_DPZpILMQMA6q{display:flex;gap:12px}.gMLJXHnceSGvMHEelSMK{position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:center;width:100%;height:100%;background:rgba(255,255,255,.6);border-radius:16px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/BulkEditModal/BulkEditModal.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,YAAA,CACA,QAAA,CAGJ,sBACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,kBAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.space {\n    height: 8px;\n}\n\n.gap {\n    display: flex;\n    gap: 12px;\n}\n\n.loading {\n    position: absolute;\n    top: 0;\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    background: rgba(255, 255, 255, 0.6);\n    border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZWIV9QZxKApy5EHEwL0G`,
	"space": `B6lYQJ1aT9qwebWfSZ_L`,
	"gap": `BGAlUjv_DPZpILMQMA6q`,
	"loading": `gMLJXHnceSGvMHEelSMK`
};
export default ___CSS_LOADER_EXPORT___;
