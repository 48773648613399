import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { COMMON_ATTRIBUTE_TYPE } from '../../common/consts/attribute';
import { arrayToCsv } from '../../common/convert/attribute';
import { Divider } from '../../uikit/Divider/Divider';
import { CommonAttributeField } from '../CommonAttributeField/CommonAttributeField';
import { CostsProperties } from '../CostsProperties/CostsProperties';
import { Customize } from '../Customize/Customize';
import { EmptyState } from '../DetailsPanel/EmptyState/EmptyState';
import { WorkingHoursGroup } from '../WorkingHoursGroup/WorkingHoursGroup';
import styles from './CostsSection.module.css';
const FORM_FIELD_WIDTH = 334;
export function CostsSection({ workingHours, totalSpentTime, formatter, onPlusClick, onCustomizeClick, attributes, attributeSchema, unitMap, isReadOnly, onAttributeChange }) {
    const formattedWorkingHours = workingHours.map((item) => ({
        id: item.id,
        started: formatter.formatDate(item.started).dateTime,
        finished: formatter.formatDate(item.finished).dateTime,
        user: item.user,
        editable: item.editable,
        onClick: item.onClick
    }));
    const formattedTotal = formatter.formatNumber(totalSpentTime, 2);
    function handleCustomizeClick() {
        onCustomizeClick();
    }
    return (_jsxs(_Fragment, { children: [workingHours.length === 0 && (_jsx(EmptyState, { illustration: _jsx("img", { src: "/costs.svg" }), title: _jsx(Ditto, { componentId: "tasks.costs.emptystate.logyourwork" }), caption: _jsx(Ditto, { componentId: "tasks.costs.emptystate.logyourworkcaption" }), action: {
                    label: _jsx(Ditto, { componentId: "tasks.costs.addworkinghours" }),
                    onClick: onPlusClick
                } })), workingHours.length > 0 && (_jsx(WorkingHoursGroup, { workingHours: formattedWorkingHours, totalSpentTime: formattedTotal, onClick: onPlusClick })), _jsx(Divider, {}), _jsx(CostsProperties, { children: attributeSchema.map((item) => {
                    var _a;
                    const initialValue = (function () {
                        var _a, _b;
                        const attribute = attributes.find((v) => v.poiAttributeSchemaId === item.id);
                        switch (item.type) {
                            case COMMON_ATTRIBUTE_TYPE.number:
                                return (_a = attribute === null || attribute === void 0 ? void 0 : attribute.numberValue) === null || _a === void 0 ? void 0 : _a.toString();
                            case COMMON_ATTRIBUTE_TYPE.select:
                                return (_b = attribute === null || attribute === void 0 ? void 0 : attribute.selectValue) === null || _b === void 0 ? void 0 : _b.toString();
                            case COMMON_ATTRIBUTE_TYPE.date:
                                return attribute === null || attribute === void 0 ? void 0 : attribute.dateValue;
                            case COMMON_ATTRIBUTE_TYPE.text:
                                return attribute === null || attribute === void 0 ? void 0 : attribute.textValue;
                            case COMMON_ATTRIBUTE_TYPE.multiselect:
                                return (attribute === null || attribute === void 0 ? void 0 : attribute.multiselectValue)
                                    ? arrayToCsv(attribute.multiselectValue)
                                    : undefined;
                        }
                    })();
                    return (_jsx(CommonAttributeField, { attribute: item, initialValue: initialValue, dropMenuWidth: FORM_FIELD_WIDTH, onChange: (value) => onAttributeChange(value, item.type, item.id), unit: item.unitId ? (_a = unitMap[item.unitId]) === null || _a === void 0 ? void 0 : _a.abbreviation : undefined, variant: "ghost", disabled: isReadOnly, formatter: formatter, isFullWidth: true, settingPageId: "task-costs" }, item.id));
                }) }), _jsx(Customize, { className: styles['customize'], onClick: handleCustomizeClick, properties: true })] }));
}
CostsSection.displayName = 'CostsSection';
