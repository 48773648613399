// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lep0ToF4lkzsdPaOY4na{margin-bottom:24px}.x_hgeUIlOG6FeBTv6yf7{opacity:.6;margin-bottom:4px}.rJhGWWgUsmI766I2dxzw{display:flex;align-items:center;font-weight:600;font-size:14px;line-height:16px}.TlXbpB2tHjTNSArYrA9n{margin-right:8px;width:8px;height:8px;border-radius:9999px}`, "",{"version":3,"sources":["webpack://./src/components/DamageGrade/DamageGrade.module.css"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CAGJ,sBACI,UAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,gBAAA,CACA,SAAA,CACA,UAAA,CACA,oBAAA","sourcesContent":[".container {\n    margin-bottom: 24px;\n}\n\n.title {\n    opacity: 0.6;\n    margin-bottom: 4px;\n}\n\n.grade {\n    display: flex;\n    align-items: center;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n}\n\n.color {\n    margin-right: 8px;\n    width: 8px;\n    height: 8px;\n    border-radius: 9999px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lep0ToF4lkzsdPaOY4na`,
	"title": `x_hgeUIlOG6FeBTv6yf7`,
	"grade": `rJhGWWgUsmI766I2dxzw`,
	"color": `TlXbpB2tHjTNSArYrA9n`
};
export default ___CSS_LOADER_EXPORT___;
