import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Button } from '../../uikit/Button/Button';
import styles from './AppliedFilter.module.css';
export function AppliedFilter({ name, value, children, onDeleteClick, onShowAllClick, deleteLabel = _jsx(Ditto, { componentId: "delete" }), link, variant = 'map' }) {
    const ref = useRef(null);
    const menuRef = useRef(null);
    useOnClickOutside(ref, () => setIsOpen(false));
    const [isOpen, setIsOpen] = useState(false);
    const [dropLeft, setDropLeft] = useState(false);
    function onFilterClick() {
        setIsOpen(!isOpen);
    }
    useLayoutEffect(() => {
        if (menuRef.current) {
            const totalOffsetWidth = menuRef.current.getBoundingClientRect().right;
            const shouldDropLeft = totalOffsetWidth >= window.innerWidth;
            setDropLeft(shouldDropLeft);
        }
    }, [isOpen]);
    return (_jsxs("div", Object.assign({ className: classes(styles['container']), ref: ref }, { children: [_jsxs("div", Object.assign({ className: classes(styles['filter'], isOpen && styles['open'], styles[`variant-${variant}`]), onClick: onFilterClick, "data-test": "filter" }, { children: [_jsx("div", Object.assign({ className: classes(styles['filter-name']) }, { children: name })), _jsx("div", Object.assign({ className: classes(styles['filter-value']), "data-test": "filter-value" }, { children: value }))] })), isOpen && (_jsx("div", Object.assign({ className: classes(styles['filters-anchor']) }, { children: _jsxs("div", Object.assign({ className: classes(styles['filters-wrapper']), ref: menuRef, style: dropLeft ? { right: 0 } : { left: 0 } }, { children: [_jsx("div", Object.assign({ className: classes(styles['filters']) }, { children: children })), onShowAllClick && (_jsx("a", Object.assign({ onClick: onShowAllClick, className: classes(styles['show-all']) }, { children: _jsx(Ditto, { componentId: "allfilter" }) }))), children && _jsx("hr", { className: classes(styles['separator']) }), _jsxs("div", Object.assign({ className: classes(styles['footer']) }, { children: [link && (_jsx("button", Object.assign({ onClick: link.onClick, className: styles['link-button'], color: "dark" }, { children: link.text }))), _jsxs("div", Object.assign({ className: classes(styles['buttons']) }, { children: [_jsx(Button, Object.assign({ onClick: onDeleteClick, className: classes(styles['button']), color: "gray", variant: "modal-action" }, { children: deleteLabel })), _jsx(Button, Object.assign({ onClick: () => setIsOpen(false), className: classes(styles['button']), color: "dark", variant: "modal-action" }, { children: _jsx(Ditto, { componentId: "done" }) }))] }))] }))] })) })))] })));
}
