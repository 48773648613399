import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { isAfter, isBefore, isSameDay, isToday } from 'date-fns';
import { CustomDay } from './CustomDay';
export function CustomDayWrapper({ dayOfMonth, date, startDate, endDate, selectedDate, minDate, maxDate, highlightedDates }) {
    if (!date) {
        return _jsx(CustomDay, { variant: "default", dayOfMonth: dayOfMonth });
    }
    if (minDate && isBefore(date, minDate)) {
        return _jsx(CustomDay, { variant: "disabled", dayOfMonth: dayOfMonth });
    }
    if (maxDate && isAfter(date, maxDate)) {
        return _jsx(CustomDay, { variant: "disabled", dayOfMonth: dayOfMonth });
    }
    if (!startDate) {
        if (isToday(date)) {
            return _jsx(CustomDay, { variant: "today", dayOfMonth: dayOfMonth });
        }
        return _jsx(CustomDay, { variant: "default", dayOfMonth: dayOfMonth });
    }
    // range selected
    if (endDate) {
        if (isSameDay(date, startDate)) {
            return _jsx(CustomDay, { variant: "range-start", dayOfMonth: dayOfMonth });
        }
        if (isSameDay(date, endDate)) {
            return _jsx(CustomDay, { variant: "range-end", dayOfMonth: dayOfMonth });
        }
        if (highlightedDates.find((highlightedDate) => isSameDay(date, highlightedDate))) {
            return _jsx(CustomDay, { variant: "range-highlighted", dayOfMonth: dayOfMonth });
        }
        if (isAfter(date, startDate) && isBefore(date, endDate)) {
            return _jsx(CustomDay, { variant: "range-in", dayOfMonth: dayOfMonth });
        }
        return _jsx(CustomDay, { variant: "default", dayOfMonth: dayOfMonth });
    }
    if (selectedDate && isSameDay(date, selectedDate)) {
        return _jsx(CustomDay, { variant: "selected", dayOfMonth: dayOfMonth });
    }
    if (highlightedDates.find((highlightedDate) => isSameDay(date, highlightedDate))) {
        return _jsx(CustomDay, { variant: "highlighted", dayOfMonth: dayOfMonth });
    }
    if (isToday(date)) {
        return _jsx(CustomDay, { variant: "today", dayOfMonth: dayOfMonth });
    }
    return _jsx(CustomDay, { variant: "default", dayOfMonth: dayOfMonth });
}
