var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { Ditto } from 'ditto-react';
import { BULK_CREATE_PLANNED_SEGMENTS_LIMIT, BULK_CREATE_TASK_LIMIT, BULK_MAIL_LIMIT, BULK_PRINT_LIMIT, ITEMS_PER_PAGE } from '../../common/consts/config';
import { ConditionFeatureType, ConditionFeatureTypeTab, ConditionSelectedFeatureType, TAB_LIST_2 } from '../../common/consts/panelTab';
import { BOTTOM_MENU_MODAL_WIDTH } from '../../common/consts/width';
import { pointToContent, segmentToContent } from '../../common/convert';
import { edgesSortingOptions } from '../../common/sort';
import { ROOT_PATH } from '../../common/url';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { DropMenuWrapper } from '../../components/DropMenuWrapper/DropMenuWrapper';
import { FilterGroupSection } from '../../components/FilterGroupSection/FilterGroupSection';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { Download, Mail, Overflow, Print, SegmentCustom, Task } from '../../components/icons';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { LeftPanelListHeader } from '../../components/LeftPanelListHeader/LeftPanelListHeader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { SelectionBar } from '../../components/SelectionBar/SelectionBar';
import { TypedDitto } from '../../components/TypedDitto';
import { useConditionFilterHandlers } from '../../hooks/filters/useConditionFilterHandlers';
import { useFilterWrapperHandlers } from '../../hooks/filters/useFilterWrapperHandlers';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useSourceChecker } from '../../hooks/mainMap';
import { useConditionFilters } from '../../hooks/render/useConditionFilters';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMarkerCreatorOpener } from '../../hooks/useMarkerCreatorOpener';
import { useTabBar } from '../../hooks/useTabBar';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { clearHighlightedFeature, setHighlightedFeature, setSelectedFeature, setSelectedFeatures } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { clearConditionMultiSelectedFeatures, exportFeaturesTk, fetchPointsResultList, fetchReconstructionProposals, fetchResultListCount, fetchSegmentsResultList, resetConditionFilter, setConditionActiveTabKey, setConditionFilter, setConditionIsMultiSelectOn, setConditionIsPointsSelectedAll, setConditionIsSegmentsSelectedAll, setConditionSorting, setCurrentPage, setFirstPage, setUseMapBounds, toggleConditionMultiSelectedFeature } from '../../state/slices/leftPanelCondition';
import { setMarkerBulkCreatorPointSource } from '../../state/slices/markerBulkCreator';
import { resetMarkerCreator } from '../../state/slices/markerCreator';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { TabBar } from '../../uikit/TabBar/TabBar';
const SEGMENTS_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'segment'
    }, isLoading: true }, idx)));
const POINTS_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'point'
    }, isLoading: true }, idx)));
export function LeftPanelConditionContainer() {
    const panelContainerRef = useRef(null);
    const { addModal } = useModalContext();
    const { points: { resultList: pointsResultList, resultFetchStatus: pointsResultFetchStatus, totalCount: pointsTotalCount, isSelectedAll: isPointsSelectedAll }, segments: { resultList: segmentsResultList, resultFetchStatus: segmentsResultFetchStatus, totalCount: segmentsTotalCount, isSelectedAll: isSegmentsSelectedAll, totalLength: segmentsTotalLength }, currentPage, filter, sort, useMapBounds, activeTabKey, selectedFeatureType, isMultiSelectOn, multiSelectedFeatures } = useAppSelector((state) => state.leftPanelCondition);
    const { targetSchema, teamAccount, userAccount } = useAppSelector((state) => state.auth);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const { selectedOption: timepicker } = useAppSelector((state) => state.presetsPanel.timepicker);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const { conditionFilters: { types: { streetTypes, surfaceTypes, roadDamages } } } = useAppSelector((state) => state.shared);
    const dispatch = useAppDispatch();
    const unitFormatter = useUnitFormatter();
    const { handleAddMarkerFromPointButtonClick } = useMarkerCreatorOpener();
    const { conditionFiltersCount: filtersCount } = useSourceChecker();
    const { shouldHideSurfaceTypes } = useLayerChecker();
    const { getConditionReconstructionProposalsFilterOptions, getConditionTypeFilterOptions, getConditionGradeFilterOptions, getConditionStreetTypeFilterOptions, getConditionSurfaceTypeFilterOptions, getConditionRoadDamageFilterOptions } = useConditionFilters();
    const { handleResetSelection } = useConditionFilterHandlers();
    const { handleFilterGroupWrapperToggle } = useFilterWrapperHandlers();
    const { isAdmin } = useUserRole();
    const { currentTab, setCurrentTab } = useTabBar({
        tabsCount: 2,
        scrollToCurrent: true
    });
    const filterableStreetTypes = streetTypes.filter((s) => s.is_used_as_filter);
    const pointsSelectedFeatures = multiSelectedFeatures.filter((item) => item.type === ConditionFeatureType.point);
    const segmentsSelectedFeatures = multiSelectedFeatures.filter((item) => item.type === ConditionFeatureType.segment);
    const pointsSelectedIds = pointsSelectedFeatures.map((f) => f.id);
    const segmentsSelectedIds = segmentsSelectedFeatures.map((f) => f.id);
    const totalSelectedCount = (() => {
        switch (currentTab) {
            case ConditionFeatureTypeTab.points:
                return isPointsSelectedAll
                    ? pointsTotalCount - pointsSelectedFeatures.length
                    : pointsSelectedFeatures.length;
            case ConditionFeatureTypeTab.segments:
                return isSegmentsSelectedAll
                    ? segmentsTotalCount - segmentsSelectedFeatures.length
                    : segmentsSelectedFeatures.length;
            default:
                return 0;
        }
    })();
    const tabs = (function () {
        const tabs = [
            {
                label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "segments" }), _jsx(NotificationBadge, { content: segmentsTotalCount })] }))
            },
            {
                label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "points" }), _jsx(NotificationBadge, { content: pointsTotalCount })] }))
            }
        ];
        return tabs;
    })();
    const reconstructionProposalsLabel = useDittoWrapper({
        componentId: 'filter.reconstruction-proposals'
    });
    const roadClassificationFilterLabel = useDittoWrapper({
        componentId: 'filter.roadclassification'
    });
    const surfaceTypeFilterLabel = useDittoWrapper({
        componentId: 'filter.surfacetype'
    });
    const damageTypeFilterLabel = useDittoWrapper({
        componentId: 'filter.damage'
    });
    const titleLabel = useDittoWrapper({
        componentId: 'layer.condition'
    });
    const totalLengthLabel = useDittoWrapper({
        componentId: 'grade.totalamount'
    });
    function handleResetFilter() {
        dispatch(resetConditionFilter());
    }
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleMail(type) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (type) {
                case ConditionFeatureType.point: {
                    let selectedPoints = [];
                    if (isPointsSelectedAll) {
                        selectedPoints = pointsResultList
                            .filter((item) => !pointsSelectedFeatures.find((feature) => feature.id === item.systemsPointId))
                            .map((item) => ({
                            type,
                            recordId: item.pointId,
                            id: item.systemsPointId
                        }));
                    }
                    else {
                        for (const item of pointsSelectedFeatures) {
                            selectedPoints.push({
                                type,
                                recordId: item.recordId,
                                id: item.id
                            });
                        }
                    }
                    addModal({
                        id: 'MailModal',
                        props: {
                            featureList: {
                                type,
                                value: selectedPoints
                            }
                        }
                    });
                    break;
                }
                case ConditionFeatureType.segment: {
                    let selectedEdges = [];
                    if (isSegmentsSelectedAll) {
                        segmentsResultList
                            .filter((item) => !segmentsSelectedFeatures.find((feature) => feature.id === item.streetId))
                            .map((item) => ({
                            id: item.streetId
                        }));
                    }
                    else {
                        for (const item of segmentsSelectedFeatures) {
                            selectedEdges.push({
                                type,
                                id: item.id
                            });
                        }
                    }
                    addModal({
                        id: 'MailModal',
                        props: {
                            featureList: {
                                type,
                                value: selectedEdges
                            }
                        }
                    });
                    break;
                }
            }
        });
    }
    function handlePrint(type) {
        if (totalSelectedCount > BULK_PRINT_LIMIT)
            return;
        let selectedIds = [];
        switch (type) {
            case ConditionFeatureType.point:
                selectedIds = isPointsSelectedAll
                    ? pointsResultList
                        .filter((item) => !pointsSelectedIds.includes(item.systemsPointId))
                        .map((item) => item.systemsPointId)
                    : pointsSelectedIds;
                break;
            case ConditionFeatureType.segment:
                selectedIds = isSegmentsSelectedAll
                    ? segmentsResultList
                        .filter((item) => !segmentsSelectedIds.includes(item.streetId))
                        .map((item) => item.streetId)
                    : segmentsSelectedIds;
                break;
        }
        window.open(`/${ROOT_PATH.print}/${type}/${selectedIds.join(',')}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleExport(type) {
        return __awaiter(this, void 0, void 0, function* () {
            let count = totalSelectedCount;
            if (!isMultiSelectOn) {
                if (type === ConditionFeatureType.point) {
                    count = pointsTotalCount;
                }
                else if (type === ConditionFeatureType.segment) {
                    count = segmentsTotalCount;
                }
            }
            addModal({
                id: 'ExportModal',
                props: {
                    onStartExportClick: () => __awaiter(this, void 0, void 0, function* () { return dispatch(exportFeaturesTk(type)); }),
                    requestList: [
                        {
                            type,
                            count
                        }
                    ]
                }
            });
        });
    }
    function handleSort(id) {
        let newSort = {};
        switch (id) {
            case 'grade-asc':
                newSort = { sortBy: 'grade', sortOrder: 'asc' };
                break;
            case 'grade-desc':
                newSort = { sortBy: 'grade', sortOrder: 'desc' };
                break;
            case 'length-asc':
                newSort = { sortBy: 'length', sortOrder: 'asc' };
                break;
            case 'length-desc':
                newSort = { sortBy: 'length', sortOrder: 'desc' };
                break;
            default:
                console.error('unknown node edges sort id', id);
        }
        dispatch(setConditionSorting(newSort));
        dispatch(setFirstPage());
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setCurrentPage(toPage));
    }
    function handleClickTab(tabId) {
        dispatch(setConditionActiveTabKey(tabId));
        if (tabId === 'list') {
            dispatch(setFirstPage());
        }
    }
    function handleCheckMapView() {
        dispatch(setUseMapBounds(!useMapBounds));
        handleClickTab('list');
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    function handleSegmentClick(id) {
        dispatch(setSelectedFeature({
            type: 'segment',
            id
        }));
    }
    function handleAddToPlanningClick(id) {
        dispatch(setSelectedFeatures([])); // reset multiple selected features
        dispatch(setSelectedFeature({
            type: 'segment',
            id
        }));
        dispatch(setCurrentAction('segment-creator-form'));
    }
    function handleAddToBulkPlanningClick() {
        return __awaiter(this, void 0, void 0, function* () {
            dispatch(setSelectedFeature(undefined)); // reset single selected features
            dispatch(setCurrentAction('segment-creator-form'));
        });
    }
    function handlePointClick(uuid, id) {
        if (markerCreatorSource) {
            dispatch(resetMarkerCreator());
        }
        dispatch(setSelectedFeature({
            type: 'point',
            recordId: id,
            id: uuid
        }));
    }
    function handleImageEnlargeClick(uuid, id) {
        handlePointClick(uuid, id);
        addModal({ id: 'ImageViewerCondition' });
    }
    function handleStreetTypeGroupClick() {
        dispatch(setConditionFilter({
            streetType: filter.streetType.length === 0
                ? filterableStreetTypes.map((item) => item.id)
                : []
        }));
    }
    function handleSurfaceTypeGroupClick() {
        dispatch(setConditionFilter({
            surfaceType: filter.surfaceType.length === 0 ? surfaceTypes.map((item) => item.id) : []
        }));
    }
    function handleDamageTypeGroupClick() {
        dispatch(setConditionFilter({
            damageType: []
        }));
    }
    function handlePointResultMouseEnter(item) {
        dispatch(setHighlightedFeature({
            type: ConditionFeatureType.point,
            id: item.systemsPointId,
            recordId: item.pointId
        }));
    }
    function handleSegmentResultMouseEnter(item) {
        dispatch(setHighlightedFeature({
            type: ConditionFeatureType.segment,
            id: item.streetId
        }));
    }
    function handleRangePointSelect(currentItem) {
        const previousSelected = pointsResultList.findIndex((item) => item.systemsPointId === pointsSelectedFeatures[pointsSelectedFeatures.length - 1].id);
        const currentSelected = pointsResultList.findIndex((item) => item === currentItem);
        const selectedRange = pointsResultList.slice(Math.min(previousSelected, currentSelected), Math.max(previousSelected, currentSelected));
        const isAlreadySelected = (item) => pointsSelectedFeatures.find((f) => f.id === item.systemsPointId);
        selectedRange.map((item) => {
            if (!isAlreadySelected(item)) {
                dispatch(toggleConditionMultiSelectedFeature({
                    type: ConditionFeatureType.point,
                    recordId: item.pointId,
                    id: item.systemsPointId
                }));
            }
        });
    }
    function handleRangeSegmentSelect(currentItem) {
        const previousSelected = segmentsResultList.findIndex((item) => item.streetId === segmentsSelectedFeatures[segmentsSelectedFeatures.length - 1].id);
        const currentSelected = segmentsResultList.findIndex((item) => item === currentItem);
        const selectedRange = segmentsResultList.slice(Math.min(previousSelected, currentSelected), Math.max(previousSelected, currentSelected));
        const isAlreadySelected = (item) => segmentsSelectedFeatures.find((f) => f.id === item.streetId);
        selectedRange.map((item) => {
            if (!isAlreadySelected(item)) {
                dispatch(toggleConditionMultiSelectedFeature({
                    type: ConditionFeatureType.segment,
                    id: item.streetId
                }));
            }
        });
    }
    function handlePointSelect(item, event) {
        dispatch(setConditionIsMultiSelectOn(true));
        dispatch(toggleConditionMultiSelectedFeature({
            type: ConditionFeatureType.point,
            recordId: item.pointId,
            id: item.systemsPointId
        }));
        if (event === null || event === void 0 ? void 0 : event.nativeEvent.shiftKey) {
            handleRangePointSelect(item);
        }
    }
    function handleSegmentSelect(item, event) {
        dispatch(setConditionIsMultiSelectOn(true));
        dispatch(toggleConditionMultiSelectedFeature({
            type: ConditionFeatureType.segment,
            id: item.streetId
        }));
        if (event === null || event === void 0 ? void 0 : event.nativeEvent.shiftKey) {
            handleRangeSegmentSelect(item);
        }
    }
    function handleCheckSelectAll() {
        if (currentTab === ConditionFeatureTypeTab.points &&
            totalSelectedCount === pointsTotalCount) {
            dispatch(clearConditionMultiSelectedFeatures());
            dispatch(setConditionIsPointsSelectedAll(false));
            return;
        }
        else if (currentTab === ConditionFeatureTypeTab.segments &&
            totalSelectedCount === segmentsTotalCount) {
            dispatch(clearConditionMultiSelectedFeatures());
            dispatch(setConditionIsSegmentsSelectedAll(false));
            return;
        }
        if (pointsSelectedIds.length > 0 || segmentsSelectedIds.length > 0) {
            dispatch(clearConditionMultiSelectedFeatures());
            if (currentTab === ConditionFeatureTypeTab.points) {
                dispatch(setConditionIsPointsSelectedAll(true));
            }
            else if (currentTab === ConditionFeatureTypeTab.segments) {
                dispatch(setConditionIsSegmentsSelectedAll(true));
            }
        }
        else if (isPointsSelectedAll || isSegmentsSelectedAll) {
            handleResetSelection();
            dispatch(setConditionIsMultiSelectOn(false));
            if (currentTab === ConditionFeatureTypeTab.points) {
                dispatch(setConditionIsPointsSelectedAll(false));
            }
            else if (currentTab === ConditionFeatureTypeTab.segments) {
                dispatch(setConditionIsSegmentsSelectedAll(false));
            }
        }
        else {
            dispatch(setConditionIsMultiSelectOn(true));
            if (currentTab === ConditionFeatureTypeTab.points) {
                dispatch(setConditionIsPointsSelectedAll(true));
            }
            else if (currentTab === ConditionFeatureTypeTab.segments) {
                dispatch(setConditionIsSegmentsSelectedAll(true));
            }
        }
    }
    function handleBulkCreateMarker() {
        if (pointsSelectedIds.length === 0) {
            return;
        }
        const pointIds = isPointsSelectedAll
            ? pointsResultList
                .filter((item) => !pointsSelectedIds.includes(item.systemsPointId))
                .map((item) => item.systemsPointId)
            : pointsSelectedIds;
        dispatch(setMarkerBulkCreatorPointSource({ pointIds }));
    }
    function isActionButtonDisabled(type, limit) {
        // We don't support the excludeIds array for email and print actions,
        // so we are able to get list of ids only when list has one page.
        if (type === ConditionFeatureType.point) {
            if (isPointsSelectedAll) {
                return (pointsTotalCount > ITEMS_PER_PAGE ||
                    Math.min(totalSelectedCount, pointsTotalCount) > limit);
            }
            else {
                return !totalSelectedCount || totalSelectedCount > limit;
            }
        }
        else if (type === ConditionFeatureType.segment) {
            if (isSegmentsSelectedAll) {
                return (segmentsTotalCount > ITEMS_PER_PAGE ||
                    Math.min(totalSelectedCount, segmentsTotalCount) > limit);
            }
            else {
                return !totalSelectedCount || totalSelectedCount > limit;
            }
        }
        return true;
    }
    function fetchResultList() {
        switch (selectedFeatureType) {
            case ConditionSelectedFeatureType.point:
                dispatch(fetchPointsResultList());
                break;
            case ConditionSelectedFeatureType.segment:
                dispatch(fetchSegmentsResultList());
                break;
            case ConditionSelectedFeatureType.both:
                dispatch(fetchPointsResultList());
                dispatch(fetchSegmentsResultList());
                break;
        }
    }
    useEffect(() => {
        if (useMapBounds) {
            fetchResultList();
            dispatch(fetchResultListCount());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filter, timepicker, sort, useMapBounds, selectedFeatureType, bounds]);
    useEffect(() => {
        if (!useMapBounds) {
            fetchResultList();
            dispatch(fetchResultListCount());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filter, timepicker, sort, useMapBounds, selectedFeatureType]);
    useEffect(() => {
        if (useMapBounds) {
            fetchResultList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage, useMapBounds, bounds]);
    useEffect(() => {
        if (!useMapBounds) {
            fetchResultList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage, useMapBounds]);
    useEffect(() => {
        dispatch(fetchReconstructionProposals());
    }, [dispatch]);
    useEffect(() => {
        dispatch(setSelectedFeatures(multiSelectedFeatures));
    }, [dispatch, multiSelectedFeatures]);
    useEffect(() => {
        if (selectedFeatureType === ConditionSelectedFeatureType.point) {
            setCurrentTab(ConditionFeatureTypeTab.points);
            return;
        }
        setCurrentTab(ConditionFeatureTypeTab.segments);
    }, [selectedFeatureType, setCurrentTab]);
    useEffect(() => {
        if (activeTabKey === 'filter' && isMultiSelectOn) {
            dispatch(setConditionIsMultiSelectOn(false));
            dispatch(setConditionIsPointsSelectedAll(false));
            dispatch(setConditionIsSegmentsSelectedAll(false));
        }
    }, [dispatch, activeTabKey, isMultiSelectOn]);
    useEffect(() => {
        if (totalSelectedCount > 0) {
            dispatch(setConditionIsMultiSelectOn(true));
        }
        else if (totalSelectedCount === 0) {
            dispatch(setConditionIsMultiSelectOn(false));
        }
    }, [dispatch, totalSelectedCount]);
    const pointsResultsCurrentPage = (() => {
        if (pointsResultFetchStatus !== 'completed') {
            return POINTS_LOADING_LIST;
        }
        if (pointsResultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return pointsResultList.map((item, index) => (_jsx(Result, { content: {
                type: 'point',
                value: pointToContent(item)
            }, onAddMarkerButtonClick: () => handleAddMarkerFromPointButtonClick(item.systemsPointId), onClick: () => handlePointClick(item.systemsPointId, item.pointId), isSelectable: isMultiSelectOn, isHighlighted: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.systemsPointId, isActive: isMultiSelectOn &&
                ((!isPointsSelectedAll && pointsSelectedIds.includes(item.systemsPointId)) ||
                    (isPointsSelectedAll && !pointsSelectedIds.includes(item.systemsPointId))), onImageEnlargeClick: () => handleImageEnlargeClick(item.systemsPointId, item.pointId), onSelect: (event) => handlePointSelect(item, event), onMouseEnter: () => handlePointResultMouseEnter(item), onMouseLeave: () => dispatch(clearHighlightedFeature()), showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode }, `${item.pointId}-${index}`)));
    })();
    const segmentsResultsCurrentPage = (() => {
        if (segmentsResultFetchStatus !== 'completed') {
            return SEGMENTS_LOADING_LIST;
        }
        if (segmentsResultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return segmentsResultList.map((item, index) => (_jsx(Result, { content: {
                type: 'segment',
                value: segmentToContent(item, unitFormatter)
            }, onClick: () => handleSegmentClick(item.streetId), onAddToPlanningButtonClick: () => handleAddToPlanningClick(item.streetId), isSelectable: isMultiSelectOn, isHighlighted: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.streetId, isActive: isMultiSelectOn &&
                ((!isSegmentsSelectedAll && segmentsSelectedIds.includes(item.streetId)) ||
                    (isSegmentsSelectedAll && !segmentsSelectedIds.includes(item.streetId))), isAdmin: isAdmin, onSelect: (event) => handleSegmentSelect(item, event), onMouseEnter: () => handleSegmentResultMouseEnter(item), onMouseLeave: () => dispatch(clearHighlightedFeature()), showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode }, `${item.streetId}-${index}`)));
    })();
    const conditionFilter = (_jsxs(LayerFilter, { children: [_jsx(FilterGroupSection, Object.assign({ headline: reconstructionProposalsLabel }, { children: getConditionReconstructionProposalsFilterOptions() })), _jsx(FilterGroupSection, Object.assign({ headline: _jsx(Ditto, { componentId: "condition.view" }), startOpen: true }, { children: getConditionTypeFilterOptions() })), _jsx(Divider, {}), _jsx(FilterGroupSection, Object.assign({ headline: _jsx(Ditto, { componentId: "filter.grading" }), trailing: `${totalLengthLabel} ${unitFormatter({
                    type: 'largeLength',
                    value: segmentsTotalLength / 1000,
                    decimals: 2
                }).formattedText}`, startOpen: true }, { children: getConditionGradeFilterOptions() })), _jsx(Divider, {}), _jsx(FilterGroupWrapper, Object.assign({ content: roadClassificationFilterLabel, checkState: getCheckboxCheckState(filterableStreetTypes.length, filter.streetType.length), onCheckboxClick: handleStreetTypeGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef) }, { children: getConditionStreetTypeFilterOptions() })), !shouldHideSurfaceTypes && (_jsx(FilterGroupWrapper, Object.assign({ content: surfaceTypeFilterLabel, checkState: getCheckboxCheckState(surfaceTypes.length, filter.surfaceType.length), onCheckboxClick: handleSurfaceTypeGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef) }, { children: getConditionSurfaceTypeFilterOptions() }))), _jsx(FilterGroupWrapper, Object.assign({ content: damageTypeFilterLabel, checkState: getCheckboxCheckState(roadDamages.length, filter.damageType.length), description: _jsx(Ditto, { componentId: 'filter.damage.description' }), onCheckboxClick: handleDamageTypeGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef) }, { children: getConditionRoadDamageFilterOptions() }))] }));
    const sortOptions = selectedFeatureType == ConditionSelectedFeatureType.point
        ? edgesSortingOptions.filter((option) => !option.id.includes('length'))
        : edgesSortingOptions;
    const resultList = (() => {
        switch (selectedFeatureType) {
            case ConditionSelectedFeatureType.both:
                return currentTab === ConditionFeatureTypeTab.points
                    ? pointsResultsCurrentPage
                    : segmentsResultsCurrentPage;
            case ConditionSelectedFeatureType.point:
                return pointsResultsCurrentPage;
            case ConditionSelectedFeatureType.segment:
                return segmentsResultsCurrentPage;
        }
    })();
    const bottomMenu = (function () {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
        if (activeTabKey === 'list' && isMultiSelectOn) {
            if (currentTab === ConditionFeatureTypeTab.points) {
                return (_jsx(SelectionBar, Object.assign({ variant: "fullwidth", leftButton: _jsx(Button, Object.assign({ disabled: isActionButtonDisabled(ConditionFeatureType.point, BULK_CREATE_TASK_LIMIT), icon: _jsx(Task, {}), color: "yellow", onClick: handleBulkCreateMarker, "data-test": "create-tasks-btn" }, { children: _jsx(Ditto, { componentId: "overflowmenue.createtask" }) })) }, { children: _jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ color: "gray", trailingIcon: _jsx(Overflow, {}), variant: "slender" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), direction: "up-center-marker-menu", menu: _jsxs(ActionModal, Object.assign({ width: BOTTOM_MENU_MODAL_WIDTH }, { children: [_jsx(SelectboxEntry, { leadingIcon: _jsx(Download, {}), text: _jsx(Ditto, { componentId: "overflowmenue.exportxls" }), onClick: () => handleExport(ConditionFeatureType.point), disabled: (!pointsSelectedIds.length && !isPointsSelectedAll) ||
                                        !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email) }), _jsx(SelectboxEntry, { leadingIcon: _jsx(Print, {}), text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), onClick: () => handlePrint(ConditionFeatureType.point), disabled: isActionButtonDisabled(ConditionFeatureType.point, BULK_PRINT_LIMIT) }), _jsx(SelectboxEntry, { leadingIcon: _jsx(Mail, {}), text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), onClick: () => handleMail(ConditionFeatureType.point), disabled: isActionButtonDisabled(ConditionFeatureType.point, BULK_MAIL_LIMIT) })] })) }) })));
            }
            if (currentTab === ConditionFeatureTypeTab.segments) {
                return (_jsx(SelectionBar, Object.assign({ variant: "fullwidth", leftButton: _jsx(Button, Object.assign({ disabled: isActionButtonDisabled(ConditionFeatureType.segment, BULK_CREATE_PLANNED_SEGMENTS_LIMIT), icon: _jsx(SegmentCustom, {}), color: "purple", onClick: handleAddToBulkPlanningClick, "data-test": "create-bulk-plan-btn", variant: "slender" }, { children: _jsx(Ditto, { componentId: "createplannedsegment" }) })) }, { children: _jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ color: "gray", trailingIcon: _jsx(Overflow, {}), variant: "slender" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), direction: "up-center-marker-menu", menu: _jsxs(ActionModal, Object.assign({ width: BOTTOM_MENU_MODAL_WIDTH }, { children: [_jsx(SelectboxEntry, { leadingIcon: _jsx(Download, {}), text: _jsx(Ditto, { componentId: "overflowmenue.exportxls" }), onClick: () => handleExport(ConditionFeatureType.segment), disabled: !segmentsSelectedIds.length && !isSegmentsSelectedAll }), _jsx(SelectboxEntry, { leadingIcon: _jsx(Print, {}), text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), onClick: () => handlePrint(ConditionFeatureType.segment), disabled: isActionButtonDisabled(ConditionFeatureType.segment, BULK_PRINT_LIMIT) }), _jsx(SelectboxEntry, { leadingIcon: _jsx(Mail, {}), text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), onClick: () => handleMail(ConditionFeatureType.segment), disabled: isActionButtonDisabled(ConditionFeatureType.segment, BULK_MAIL_LIMIT) })] })) }) })));
            }
        }
    })();
    return (_jsx(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: titleLabel, resetFilter: {
                filtersCount: filtersCount +
                    (selectedFeatureType === ConditionSelectedFeatureType.both ? 0 : 1),
                onReset: handleResetFilter,
                disabled: filtersCount === 0 &&
                    selectedFeatureType === ConditionSelectedFeatureType.both
            }, exportXLS: [
                {
                    label: _jsx(Ditto, { componentId: "export.points.xls" }),
                    onExport: () => handleExport(ConditionFeatureType.point),
                    disabled: pointsTotalCount === 0 || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)
                },
                {
                    label: _jsx(Ditto, { componentId: "export.segments.xls" }),
                    onExport: () => handleExport(ConditionFeatureType.segment),
                    disabled: segmentsTotalCount === 0 || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)
                }
            ], multiselect: isMultiSelectOn
                ? {
                    text: (_jsx(TypedDitto, { componentId: "listpanel.multiselect.x_selected_variabel", variables: {
                            Amount: totalSelectedCount
                        }, count: totalSelectedCount })),
                    onCloseClick: handleResetSelection
                }
                : undefined, onCollapse: handleCollapse }), tabBar: _jsx(PanelButtonGroup, { tabs: TAB_LIST_2, activeKey: activeTabKey, onClickTab: handleClickTab }), activeTabKey: activeTabKey, ref: panelContainerRef, listHeader: _jsx(LeftPanelListHeader, { onFilterCurrentMapViewClick: handleCheckMapView, onSelectAllClick: handleCheckSelectAll, filterUseMapBounds: useMapBounds, selectedCount: totalSelectedCount, resultsCount: currentTab === ConditionFeatureTypeTab.points
                ? pointsTotalCount
                : segmentsTotalCount, sort: {
                options: sortOptions,
                onSort: handleSort,
                current: sort.sortBy && sort.sortOrder && `${sort.sortBy}-${sort.sortOrder}`
            }, tabs: selectedFeatureType === ConditionSelectedFeatureType.both && (_jsx(TabBar, { tabs: tabs, current: currentTab, setCurrent: setCurrentTab, width: "100%" })) }), pagination: activeTabKey === 'list' ? (_jsx(Pagination, { totalItems: currentTab === ConditionFeatureTypeTab.points
                ? pointsTotalCount
                : segmentsTotalCount, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined, bottomMenu: bottomMenu }, { children: activeTabKey === 'list' ? resultList : conditionFilter })));
}
