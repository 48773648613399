import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { FormInputText } from '../FormInputText/FormInputText';
import { NavigationOrientation } from '../icons';
export function OrientationField({ orientation, onClick, onBlur, isRotating, variant }) {
    const orientationLabel = useDittoWrapper({ componentId: 'trafficsigns.orientation' });
    const [localValue, setLocalValue] = useState(orientation !== null && orientation !== void 0 ? orientation : 0);
    const fullCircle = 360;
    const numDirections = 8;
    const degreeIncrement = fullCircle / numDirections;
    const directions = ['° N', '° NE', '° E', '° SE', '° S', '° SW', '° W', '° NW'];
    function getDirection(orientation) {
        const index = Math.floor((orientation % fullCircle) / degreeIncrement);
        return directions[index];
    }
    function handleBlur() {
        if (onBlur) {
            onBlur(localValue);
        }
    }
    const direction = getDirection(orientation);
    useEffect(() => {
        setLocalValue(orientation !== null && orientation !== void 0 ? orientation : 0);
    }, [orientation]);
    const handleValueChange = (value) => {
        const numericValue = Number(value);
        if (!isNaN(numericValue)) {
            setLocalValue(numericValue);
        }
    };
    return (_jsx(FormInputText, { onClick: onClick, onBlur: handleBlur, label: orientationLabel, onValueChange: handleValueChange, value: `${localValue}${!isRotating ? direction : ''}`, min: 0, max: 359, variant: variant, leadingIcon: _jsx(NavigationOrientation, {}) }));
}
