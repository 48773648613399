import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CategoryItem } from '../../components/EditorForms/CategoryItem/CategoryItem';
import { CategorySelectField } from '../../components/EditorForms/CategorySelect/CategorySelectField/CategorySelectField';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
/**
 * @deprecated TODO: refactor and remove this container.
 * do NOT copy this approach here. we try to avoid nesting containers in containers. if there are some duplicated logics, try to create hooks instead.
 */
export function CategorySelectContainer({ isDark, width, onCategorySelect, selectedId, variant }) {
    const { addModal } = useModalContext();
    const { markerCategoryList } = useAppSelector((state) => state.shared);
    const selectedCategory = markerCategoryList.find((category) => category.categoryId === selectedId);
    const selectedItem = selectedCategory && (_jsx(CategoryItem, { category: selectedCategory, selected: true, variant: variant }, selectedCategory.categoryId));
    function handleSelectFieldClick() {
        addModal({
            id: 'CategorySelectModalContainer',
            props: {
                onSelect: onCategorySelect
            }
        });
    }
    return (_jsx(CategorySelectField, { isDark: isDark, width: width, value: selectedItem, variant: variant, onClick: handleSelectFieldClick }));
}
