import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const MAX_DEGREE_NUMBER = 360;
const ANGLE = 45;
function convertAzimuth(deg) {
    return ((deg % MAX_DEGREE_NUMBER) + MAX_DEGREE_NUMBER) % MAX_DEGREE_NUMBER;
}
export function DirectionIndicator({ azimuth }) {
    const startDeg = convertAzimuth(azimuth - ANGLE);
    const endDeg = convertAzimuth(azimuth + ANGLE);
    let background = '';
    if (startDeg < endDeg) {
        background = `rgba(45, 52, 68, 0) ${startDeg}deg, rgba(45, 52, 68, 0.2) ${startDeg}deg ${endDeg}deg, rgba(45, 52, 68, 0) ${endDeg}deg`;
    }
    else {
        background = `rgba(45, 52, 68, 0.2) ${endDeg}deg, rgba(45, 52, 68, 0) ${endDeg}deg ${startDeg}deg, rgba(45, 52, 68, 0.2) ${startDeg}deg`;
    }
    return (_jsx("div", { style: {
            width: '56px',
            height: '56px',
            borderRadius: '50%',
            background: `conic-gradient(${background})`
        } }));
}
