import { update } from './object';
export function toggleItemInArray(array, value) {
    if (array.includes(value)) {
        return array.filter((v) => v !== value);
    }
    return array.concat([value]);
}
export function toggleObjectInArray(array, value, equals) {
    if (array.some((item) => equals(item, value))) {
        return array.filter((item) => !equals(item, value));
    }
    return [...array, value];
}
export function getUpdateDiff(oldList, newList) {
    const toRemove = [];
    const toAdd = [];
    const len = Math.max(oldList.length, newList.length);
    for (let i = 0; i < len; i += 1) {
        const exitingItem = oldList[i];
        if (exitingItem && !newList.includes(exitingItem)) {
            toRemove.push(exitingItem);
        }
        const newItem = newList[i];
        if (newItem && !oldList.includes(newItem)) {
            toAdd.push(newItem);
        }
    }
    return { toRemove, toAdd };
}
export function findOne(list, comparator, fallback) {
    var _a;
    return (_a = list.find(comparator)) !== null && _a !== void 0 ? _a : fallback;
}
export function findLastIndex(array, callback) {
    const reversedArray = [...array].reverse();
    const index = reversedArray.findIndex(callback);
    return index !== -1 ? array.length - 1 - index : -1;
}
export function isEqual(a, b) {
    if (a.length !== b.length) {
        return false;
    }
    return a.every((item, index) => item === b[index]);
}
export function unique(array) {
    let j = {};
    array.forEach(function (v) {
        j[v + '::' + typeof v] = v;
    });
    return Object.keys(j).map(function (v) {
        return j[v];
    });
}
export function biFilter(list, comp) {
    const match = [];
    const rest = [];
    for (const item of list) {
        if (comp(item)) {
            match.push(item);
        }
        else {
            rest.push(item);
        }
    }
    return { match, rest };
}
export function moveMatchToFront(list, comp) {
    const { match, rest } = biFilter(list, comp);
    return [...match, ...rest];
}
export function sum(list) {
    return list.reduce((a, b) => a + b, 0);
}
export function average(list, fractionDigits) {
    if (fractionDigits) {
        return parseFloat((sum(list) / list.length).toFixed(fractionDigits));
    }
    return sum(list) / list.length;
}
export function move(list = [], from, to) {
    const copy = [...list];
    const item = copy[from];
    copy.splice(from, 1);
    copy.splice(to, 0, item);
    return copy;
}
export function replace(list = [], index, item) {
    const copy = [...list];
    copy[index] = item;
    return copy;
}
export function replacePartial(list = [], index, value) {
    return replace(list, index, update(list[index], value));
}
export function remove(list = [], index) {
    const copy = [...list];
    copy.splice(index, 1);
    return copy;
}
/**
 * this function can filter out undefined in array type,
 * for example convert from (number | undefined)[] to number[]
 */
export function isDefined(value) {
    return value !== undefined;
}
export function uniqueV2(list) {
    return [...new Set(list)];
}
export function xorify(list1 = [], list2 = [], value, equals = (a, b) => a === b) {
    var _a, _b;
    let found1 = false;
    let found2 = false;
    if (list1.some((item) => equals(item, value))) {
        found1 = true;
    }
    if (list2.some((item) => equals(item, value))) {
        found2 = true;
    }
    const new1 = (_a = list1.filter((item) => !equals(item, value))) !== null && _a !== void 0 ? _a : [];
    const new2 = (_b = list2.filter((item) => !equals(item, value))) !== null && _b !== void 0 ? _b : [];
    if (found1 && found2) {
        return { list1: new1, list2: new2 };
    }
    return { list1: [...new1, value], list2: [...new2, value] };
}
