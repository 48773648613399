import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { COMMON_ATTRIBUTE_TYPE } from '../../common/consts/attribute';
import { arrayToCsv, csvToArray, displayCsv } from '../../common/convert/attribute';
import { fromDateUTC, toDateUTC } from '../../common/utils/time';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useModalContext } from '../../state/context';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../FormInputSelect/FormInputSelect';
import { FormInputText } from '../FormInputText/FormInputText';
import { Check } from '../icons';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import styles from './CommonAttributeField.module.css';
const DROP_MENU_MAX_HEIGHT = 352;
/**
 * Designed to be shared for multiple layers, for example task attributes, asset attributes and so on.
 */
export function CommonAttributeField({ attribute, dropMenuWidth, initialValue, onChange, unit, variant, disabled, isFullWidth, width, formatter, settingPageId }) {
    const { addModal } = useModalContext();
    const placeholderText = useDittoWrapper({ componentId: 'notspecified' });
    const [localValue, setLocalValue] = useState(initialValue !== null && initialValue !== void 0 ? initialValue : '');
    const [placeholder, setPlaceholder] = useState(placeholderText);
    useEffect(() => {
        setLocalValue(initialValue !== null && initialValue !== void 0 ? initialValue : '');
    }, [initialValue]);
    function handleChange(v) {
        const value = v || '';
        setLocalValue(value);
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }
    function handleInputSubmit() {
        if (!attribute.type) {
            return;
        }
        if (initialValue === undefined && localValue === '') {
            return;
        }
        if (localValue !== initialValue) {
            onChange === null || onChange === void 0 ? void 0 : onChange(localValue);
        }
        else {
            setPlaceholder(placeholderText);
        }
    }
    function getSelectEntries(items = []) {
        return items
            .map((v) => {
            return (_jsx(SelectboxEntry, { text: v.name, onClick: () => handleChange(v.id.toString()), trailingIcon: v.id === parseInt(localValue) ? _jsx(Check, {}) : undefined }, v.id));
        })
            .concat(_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "notspecified" }), onClick: () => handleChange(''), trailingIcon: !parseInt(localValue) ? _jsx(Check, {}) : undefined }, "not-specified"));
    }
    function getSelectText(value) {
        var _a, _b;
        const id = parseInt(value);
        if (!attribute.selectOptions) {
            return;
        }
        return (_b = (_a = attribute.selectOptions) === null || _a === void 0 ? void 0 : _a.find((i) => i.id === id)) === null || _b === void 0 ? void 0 : _b.name;
    }
    function getMultiSelectText(value) {
        if (!attribute.selectOptions) {
            return;
        }
        const options = attribute.selectOptions.filter((i) => csvToArray(value).includes(i.id));
        const names = options.map((o) => o.name);
        return displayCsv(names);
    }
    function handleAttributeDateClick() {
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                date: toDateUTC(localValue),
                onConfirm: (value) => handleChange(fromDateUTC(value === null || value === void 0 ? void 0 : value.startDate))
            }
        });
    }
    function handleAttributeMultiselectClick() {
        addModal({
            id: 'MultiselectModalContainer',
            props: {
                onDone: (value) => handleChange(arrayToCsv(value)),
                options: attribute.selectOptions || [],
                title: attribute.name,
                initialValues: csvToArray(localValue),
                settingPageId
            }
        });
    }
    function renderField() {
        switch (attribute.type) {
            case COMMON_ATTRIBUTE_TYPE.number:
                return (_jsx(NumericFormat, { value: localValue, customInput: FormInputText, label: attribute.name, placeholder: placeholderText, onValueChange: (v) => { var _a; return setLocalValue(((_a = v.floatValue) === null || _a === void 0 ? void 0 : _a.toString()) || ''); }, onFocus: () => setPlaceholder(` ${unit}`), onBlur: () => handleInputSubmit(), suffix: unit && ` ${unit}`, decimalSeparator: formatter.decimalSeparator, variant: variant, width: width, disabled: disabled, valueIsNumericString: true }));
            case COMMON_ATTRIBUTE_TYPE.date:
                return (_jsx(FormInputSelect, { label: attribute.name, onClick: handleAttributeDateClick, hideMenu: true, placeholder: placeholder, value: localValue ? (_jsx(FormInputCurrentSelectedValue, { text: formatter.formatDateISO(localValue) })) : (_jsx(FormInputCurrentSelectedValue, { text: _jsx("span", Object.assign({ className: styles['placeholder-text'] }, { children: placeholder })) })), variant: variant, disabled: disabled }));
            case COMMON_ATTRIBUTE_TYPE.select:
                return (_jsx(FormInputSelect, { label: attribute.name, menuItems: getSelectEntries(attribute.selectOptions), menuWidth: dropMenuWidth, variant: variant, value: localValue ? (_jsx(FormInputCurrentSelectedValue, { text: getSelectText(localValue) })) : (_jsx(FormInputCurrentSelectedValue, { text: _jsx("span", Object.assign({ className: styles['placeholder-text'] }, { children: placeholder })) })), disabled: disabled, canFloat: true, menuMaxHeight: DROP_MENU_MAX_HEIGHT, width: width }));
            case COMMON_ATTRIBUTE_TYPE.text:
                return (_jsx(FormInputText, { value: localValue, label: attribute.name, placeholder: placeholder, onValueChange: (value) => setLocalValue(value), onBlur: () => handleInputSubmit(), variant: variant, disabled: disabled, width: width, "data-test": "asset-attribute-text" }));
            case COMMON_ATTRIBUTE_TYPE.multiselect:
                return (_jsx(FormInputSelect, { label: attribute.name, onClick: handleAttributeMultiselectClick, hideMenu: true, placeholder: placeholder, value: localValue ? (_jsx(FormInputCurrentSelectedValue, { text: getMultiSelectText(localValue) })) : (_jsx(FormInputCurrentSelectedValue, { text: _jsx("span", Object.assign({ className: styles['placeholder-text'] }, { children: placeholder })) })), variant: variant, disabled: disabled }));
        }
    }
    return (_jsx("div", Object.assign({ className: classes(isFullWidth && styles[`full-width`]), "data-test": `asset-attribute-${attribute.type}` }, { children: renderField() })));
}
