import mixpanel from 'mixpanel-browser';
import { MARKER_PRIORITY_ID } from '../common/consts/marker';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_EVENT_VALUE } from '../common/consts/mixpanel';
import { pointToMarkerSource, signInventoryToMarkerSource, signToMarkerSource } from '../common/convert';
import { getSignFromSignTree } from '../common/convert/signInventory';
import { submitTrackingEvent } from '../services/mail';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchPoint } from '../state/slices/conditionDetail';
import { setMarkerCreatorPointSource } from '../state/slices/markerCreator';
import { fetchSignInfoById } from '../state/slices/signDetail';
import { fetchSignInventoryInfoById } from '../state/slices/signInventoryDetail';
export function useMarkerCreatorOpener() {
    const dispatch = useAppDispatch();
    const { signInventoryDamageTypes, signTree } = useAppSelector((state) => state.shared);
    function handleAddMarkerFromPointButtonClick(id, trackEvent) {
        if (trackEvent) {
            mixpanel.track(MIXPANEL_EVENT_NAME.createTaskFromObject, {
                [MIXPANEL_EVENT_PROPERTY.taskCreatorSource]: MIXPANEL_EVENT_VALUE.list
            });
        }
        dispatch(fetchPoint(id))
            .unwrap()
            .then((res) => {
            if (res.result) {
                dispatch(setMarkerCreatorPointSource(pointToMarkerSource(res.result)));
            }
        });
    }
    function handleAddMarkerFromSignButtonClick(id) {
        dispatch(fetchSignInfoById(id))
            .unwrap()
            .then((res) => {
            if (res.result) {
                dispatch(setMarkerCreatorPointSource(signToMarkerSource(res.result.sign)));
            }
        })
            .catch((err) => console.error('Error in handleAddMarkerFromSignButtonClick', err));
    }
    function handleAddMarkerFromSignInventoryButtonClick(id) {
        dispatch(fetchSignInventoryInfoById(id))
            .unwrap()
            .then((res) => {
            if (res.result) {
                const sign = getSignFromSignTree(signTree, res.result.sign.tag.subSpecifiedClass);
                const { taskTitle, taskDescription, taskPriorityId } = getMagicValuesForSignInventoryTask(res.result.sign.damageType);
                const fullTaskDescription = `${taskDescription} "${sign === null || sign === void 0 ? void 0 : sign.name} (${sign === null || sign === void 0 ? void 0 : sign.bastId})"`;
                dispatch(setMarkerCreatorPointSource(signInventoryToMarkerSource(res.result.sign, taskTitle, fullTaskDescription, taskPriorityId)));
                submitTrackingEvent(MIXPANEL_EVENT_NAME.createTaskFromSign);
            }
        })
            .catch((err) => console.error('Error in handleAddMarkerFromSignInventoryButtonClick', err));
    }
    function getMagicValuesForSignInventoryTask(damageType) {
        var _a;
        const { taskTitle, taskDescription, taskPriorityId } = (_a = signInventoryDamageTypes.find((d) => d.id === damageType)) !== null && _a !== void 0 ? _a : {
            taskTitle: '',
            taskDescription: '',
            taskPriorityId: MARKER_PRIORITY_ID.medium
        };
        return { taskTitle, taskDescription, taskPriorityId };
    }
    return {
        handleAddMarkerFromPointButtonClick,
        handleAddMarkerFromSignButtonClick,
        handleAddMarkerFromSignInventoryButtonClick,
        getMagicValuesForSignInventoryTask
    };
}
