import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { classes } from 'classifizer';
import { Button } from '../../uikit/Button/Button';
import { CloseAlt } from '../icons';
import styles from './Toast.module.css';
export function Toast({ title, color, subtitle, innerContent, button, closeAfterSecond, onClose }) {
    useEffect(() => {
        if (closeAfterSecond) {
            setTimeout(() => onClose && onClose(), closeAfterSecond * 1000);
        }
    });
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], color && styles[`${color}`]), "data-test": "toast-container" }, { children: [_jsxs("div", Object.assign({ className: classes(styles['title-subtitle']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['title']), "data-test": "toast-title" }, { children: title })), subtitle && _jsx("div", Object.assign({ className: classes(styles['subtitle']) }, { children: subtitle }))] })), innerContent, button && (_jsx("div", Object.assign({ className: classes(styles['button-wrapper']) }, { children: _jsx(Button, Object.assign({ className: classes(styles['button']), color: button.buttonColor || 'yellow', icon: button.buttonIcon, onClick: button.onClick, "data-test": "toast-btn" }, { children: button.buttonText })) }))), !button && (_jsx("a", Object.assign({ className: classes(styles['close']), onClick: () => onClose && onClose(), "data-test": "toast-close" }, { children: _jsx(CloseAlt, {}) }))), closeAfterSecond && (_jsx("div", Object.assign({ style: { animationDuration: closeAfterSecond + 's' }, className: classes(styles['timer']) }, { children: "test" })))] })));
}
