import { LAYER_PATH } from '../common/layer';
import { useAppSelector } from '../state/hooks';
import { useUserRole } from './useUserRole';
export function useLayerChecker() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { layerList } = useAppSelector((state) => state.layer);
    const { teamAccount, userAccount, roles } = useAppSelector((state) => state.auth);
    const { isEditor } = useUserRole();
    const isSettingsLoaded = teamAccount !== undefined && userAccount !== undefined && roles.list.length > 0;
    // TODO: this has to match the values in fetchTeamAccount, need to think of how to refactor to make it one source of truth
    const isSignAuthorized = false;
    const isReferencingAuthorized = teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.referencingEnabled;
    const isManholeAuthorized = teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.manHoleStormDrainSpecifiedClassesEnabled;
    const linkAssetsTasksEnabled = isEditor;
    const isTaskCostsEnabled = !!(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.taskCostsEnabled);
    const isTaskCategoryCustomizationEnabled = !!(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.taskCategoryCustomizationEnabled);
    // TODO: this is a quick solution to enable editable traffic signs for all users who have at least
    //  one traffic sign setting enabled.
    //  Will be refactored as soon as backend is ready
    const isSignInventoryAuthorized = (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.trafficSignsEnabled) ||
        (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.allTrafficSignsEnabled) ||
        (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.editableTrafficSignsEnabled);
    const isEditableTrafficSignsEnabled = isSignInventoryAuthorized;
    const isConditionVisible = (_a = layerList.find((item) => item.id === LAYER_PATH.condition)) === null || _a === void 0 ? void 0 : _a.isEnabled;
    const isReferencingVisible = isReferencingAuthorized &&
        ((_b = layerList.find((item) => item.id === LAYER_PATH.referencing)) === null || _b === void 0 ? void 0 : _b.isEnabled);
    const isMarkerVisible = isEditor && ((_c = layerList.find((item) => item.id === LAYER_PATH.marker)) === null || _c === void 0 ? void 0 : _c.isEnabled);
    const isPlanVisible = (_d = layerList.find((item) => item.id === LAYER_PATH.plan)) === null || _d === void 0 ? void 0 : _d.isEnabled;
    const isAlertVisible = (_e = layerList.find((item) => item.id === LAYER_PATH.alert)) === null || _e === void 0 ? void 0 : _e.isEnabled;
    const isObjectVisible = (_f = layerList.find((item) => item.id === LAYER_PATH.object)) === null || _f === void 0 ? void 0 : _f.isEnabled;
    const isNoteVisible = (_g = layerList.find((item) => item.id === LAYER_PATH.note)) === null || _g === void 0 ? void 0 : _g.isEnabled;
    const isTrackControlVisible = (_h = layerList.find((item) => item.id === LAYER_PATH.trackControl)) === null || _h === void 0 ? void 0 : _h.isEnabled;
    const isSignVisible = isSignAuthorized && ((_j = layerList.find((item) => item.id === LAYER_PATH.sign)) === null || _j === void 0 ? void 0 : _j.isEnabled);
    const isSignInventoryVisible = isSignInventoryAuthorized &&
        ((_k = layerList.find((item) => item.id === LAYER_PATH.signInventory)) === null || _k === void 0 ? void 0 : _k.isEnabled);
    const isWinterServiceVisible = (_l = layerList.find((item) => item.id === LAYER_PATH.winterService)) === null || _l === void 0 ? void 0 : _l.isEnabled;
    const isAssetVisible = (_m = layerList.find((item) => item.id === LAYER_PATH.asset)) === null || _m === void 0 ? void 0 : _m.isEnabled;
    const shouldHideSurfaceTypes = (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US';
    const shouldShowInventoryTeaser = (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'DE' ||
        (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'FR' ||
        (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US';
    return {
        isSettingsLoaded,
        isConditionVisible,
        isReferencingVisible,
        isReferencingAuthorized,
        isMarkerVisible,
        isPlanVisible,
        isAlertVisible,
        isObjectVisible,
        isNoteVisible,
        isTrackControlVisible,
        isSignVisible,
        isSignAuthorized,
        isSignInventoryVisible,
        isSignInventoryAuthorized,
        isManholeAuthorized,
        isWinterServiceVisible,
        isAssetVisible,
        shouldHideSurfaceTypes,
        shouldShowInventoryTeaser,
        linkAssetsTasksEnabled,
        isEditableTrafficSignsEnabled,
        isTaskCategoryCustomizationEnabled,
        isTaskCostsEnabled
    };
}
