import { useEffect } from 'react';
export function useBrowserBackListener() {
    useEffect(() => {
        const listener = () => {
            window.location.reload();
        };
        window.addEventListener('popstate', listener);
        return () => {
            window.removeEventListener('popstate', listener);
        };
    }, []);
}
