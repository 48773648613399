import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import styles from './Checkbox.module.css';
function getCheckboxIcon({ color, isChecked, isIndeterminate, isHovering }) {
    if (!isChecked && color !== 'white' && color !== 'dark') {
        return (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: _jsx("rect", { width: "17", height: "17", x: "3.5", y: "3.5", stroke: "#CACACA", strokeLinecap: "round", strokeLinejoin: "round", rx: "3.5" }) })));
    }
    if (!isChecked && color === 'white') {
        return (_jsx("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "white", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }) })));
    }
    if (!isChecked && color === 'dark') {
        const outline = isHovering ? '#222D39' : '#D3D5D7';
        return (_jsxs("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "2", y: "2", width: "20", height: "20", rx: "5", fill: "#818181", fillOpacity: "0.1" }), _jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "white", stroke: outline, strokeLinecap: "round", strokeLinejoin: "round" })] })));
    }
    if (color === 'dark') {
        if (isIndeterminate) {
            return (_jsxs("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "#434D57", stroke: "#434D57", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.125 12H10.625H7.875", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
        }
        if (isChecked) {
            return (_jsxs("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "2", y: "2", width: "20", height: "20", rx: "5", fill: "#818181", fillOpacity: "0.1" }), _jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "#434D57", stroke: "#434D57", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.125 9.75L10.625 15L7.875 12.375", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
        }
    }
    if (color === 'black') {
        if (isIndeterminate) {
            return (_jsxs("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "#222D39", stroke: "#222D39", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.125 12H10.625H7.875", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
        }
        if (isChecked) {
            return (_jsxs("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "#222D39", stroke: "#222D39", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.125 9.75L10.625 15L7.875 12.375", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
        }
    }
    if (color === 'yellow') {
        if (isIndeterminate) {
            return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: [_jsx("rect", { width: "17", height: "17", x: "3.5", y: "3.5", fill: "#FBD400", stroke: "#FBD400", strokeLinecap: "round", strokeLinejoin: "round", rx: "3.5" }), _jsx("path", { stroke: "#222D39", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M16.125 12h-8.25" })] })));
        }
        if (isChecked) {
            return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: [_jsx("rect", { width: "17", height: "17", x: "3.5", y: "3.5", fill: "#FBD400", stroke: "#FBD400", strokeLinecap: "round", strokeLinejoin: "round", rx: "3.5" }), _jsx("path", { stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M16.125 9.75l-5.5 5.25-2.75-2.625" })] })));
        }
    }
    if (color === 'red') {
        return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: [_jsx("rect", { width: "17", height: "17", x: "3.5", y: "3.5", fill: "#FF3E3E", stroke: "#FF3E3E", strokeLinecap: "round", strokeLinejoin: "round", rx: "3.5" }), _jsx("path", { stroke: "#fff", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M16.125 9.75l-5.5 5.25-2.75-2.625" })] })));
    }
    if (color === 'white') {
        if (isChecked) {
            return (_jsxs("svg", Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "3.5", fill: "white", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.125 9.75L10.625 15L7.875 12.375", stroke: "#222D39", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
        }
    }
}
export function Checkbox({ className, isChecked, onClick, isHovering, color = 'yellow', isIndeterminate, checkboxRef, 'data-test': dataTest }) {
    return (_jsx("div", Object.assign({ className: classes(styles['container'], isHovering && styles['container__hover'], className), onClick: onClick, "data-test": dataTest, ref: checkboxRef }, { children: getCheckboxIcon({ color, isChecked, isIndeterminate, isHovering }) })));
}
