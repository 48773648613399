import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { toggleItemInArray } from '../../common/utils/array';
import { useDelayedLoader } from '../../hooks/useDelayedLoader';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { AttachmentItem } from '../DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { FilterSelectbox } from '../FilterSelectbox/FilterSelectbox';
import { Search } from '../Search/Search';
import { NoMatch } from '../Searchbar';
import styles from './LinkedTasksSelect.module.css';
export function LinkedTasksSelect({ status, results, selectedValues, markerIdsToLink, getAttachmentMarker, onSelect, onInputValueChange }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'search.searchfor' });
    const delayedLoader = useDelayedLoader({ isLoading: status === 'loading' });
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {
        handleInputValueChange('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleInputValueChange(value) {
        onInputValueChange(value);
        setSearchValue(value);
    }
    function handleSelectboxClick(id) {
        const newSelectedValue = toggleItemInArray(selectedValues, id);
        onSelect(newSelectedValue);
    }
    const children = (() => {
        if (delayedLoader) {
            return delayedLoader;
        }
        if (results.length === 0) {
            return _jsx(NoMatch, {});
        }
        return results
            .filter((marker) => !markerIdsToLink.includes(marker.id))
            .map((marker) => (_jsx("div", Object.assign({ className: styles['search-result'] }, { children: _jsx(FilterSelectbox, { content: _jsx(AttachmentItem, { children: getAttachmentMarker(marker) }), isChecked: selectedValues.includes(marker.id), onClick: () => handleSelectboxClick(marker.id), height: 70, color: "black" }) }), marker.id)));
    })();
    return (_jsxs("div", Object.assign({ className: styles['modal-search'] }, { children: [_jsx(Search, { placeholder: placeholderLabel, value: searchValue, onChange: handleInputValueChange, variant: "field" }), _jsx("div", { className: styles['modal-search-divider'] }), _jsx("div", Object.assign({ className: styles['modal-search-results'] }, { children: children }))] })));
}
