var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { classes } from 'classifizer';
import styles from './Button.module.css';
export const Button = forwardRef(function Button(_a, ref) {
    var { className, children, onClick, variant = 'primary', color = 'default', type = 'button', disabled, icon, trailingIcon, size = 'medium', textAlign = 'center', extraRounded, lessRounded, 'data-test': dataTest } = _a, props = __rest(_a, ["className", "children", "onClick", "variant", "color", "type", "disabled", "icon", "trailingIcon", "size", "textAlign", "extraRounded", "lessRounded", 'data-test']);
    return (_jsx("button", Object.assign({ type: type, className: classes(styles['button'], variant && styles[`button__${variant}`], color && styles[`button__${color}`], size && styles[`button__${size}`], extraRounded && styles['button__extra-rounded'], lessRounded && styles['button__less-rounded'], className), onClick: onClick, disabled: disabled, "data-test": dataTest, ref: ref }, props, { children: _jsxs("div", Object.assign({ className: styles['inner'], style: { justifyContent: textAlign } }, { children: [icon && _jsx("span", Object.assign({ className: classes(styles['icon']) }, { children: icon })), children && _jsx("div", Object.assign({ className: classes(styles['text']) }, { children: children })), trailingIcon && (_jsx("span", Object.assign({ className: classes(styles['trailing-icon']) }, { children: trailingIcon })))] })) })));
});
