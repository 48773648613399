var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { assetToMarkerSource } from '../../common/convert';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { Plus, Task } from '../../components/icons';
import { ImageGallery } from '../../components/ImageGallery/ImageGallery';
import { GalleryImage } from '../../components/ImageViewer/GalleryImage/GalleryImage';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useImageUploadModal } from '../../hooks/useImageUploadModal';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addAssetPhotosTk, removeAssetPhotos } from '../../state/slices/assetDetail';
import { resetMarkerCreator, setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { Button } from '../../uikit/Button/Button';
import { AssetDetailContainer } from '../AssetDetailContainer/AssetDetailContainer';
import { RightPanelMarkerCreatorContainer } from '../RightPanelMarkerCreatorContainer/RightPanelMarkerCreatorContainer';
export function ImageViewerAssetContainer({ initialActiveIndex = 0 }) {
    const dispatch = useAppDispatch();
    const { removeModal, modals, addModal } = useModalContext();
    const dateTimeFormatter = useDateTimeFormatter();
    const { assetInfo } = useAppSelector((state) => state.assetDetail);
    const { selectConfirmPhotos } = useImageUploadModal();
    const { isEditor } = useUserRole();
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const isReadOnly = !isEditor;
    if (!assetInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    const detailPanel = (() => {
        if (markerCreatorSource) {
            return _jsx(RightPanelMarkerCreatorContainer, {});
        }
        return _jsx(AssetDetailContainer, { variant: "image-viewer" });
    })();
    const topButtonsCreate = (_jsx(FloatingDropdownWrapper, { control: _jsx(Button, { color: "dark", variant: "square", icon: _jsx(Plus, {}) }), topOffset: 4, menu: _jsx(ActionModal, Object.assign({ extraRounded: true }, { children: _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "createmarker" }), leadingIcon: _jsx(Task, {}), onClick: handleAddMarkerButtonClick, size: "medium", extraRounded: true }) })) }));
    function handleAddMarkerButtonClick() {
        if (!assetInfo) {
            return;
        }
        dispatch(setMarkerCreatorPointSource(assetToMarkerSource(assetInfo)));
    }
    function handleBackButtonClick() {
        dispatch(resetMarkerCreator());
    }
    function handleImageUploadClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!assetInfo) {
                return;
            }
            selectConfirmPhotos((img) => dispatch(addAssetPhotosTk({
                assetId: assetInfo.id,
                photos: img.map((i) => i.file)
            })));
        });
    }
    function handleDeleteImage(photoId) {
        if (!assetInfo) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(removeAssetPhotos({ id: assetInfo.id, photoIds: [photoId] }));
                },
                title: _jsx(Ditto, { componentId: "promt.delete.image" }),
                description: _jsx(Ditto, { componentId: "promt.delete.image.text" })
            }
        });
    }
    function handleClickOutside() {
        if (modals.find((m) => m.id === 'DeleteModal')) {
            return;
        }
        removeModal('ImageViewerAsset');
    }
    return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageGallery, { initialActiveIndex: initialActiveIndex, images: assetInfo.photos.map((p) => ({
                id: p.id,
                image: _jsx(GalleryImage, { src: p.imageUrl }),
                thumb: _jsx(GalleryImage, { src: p.imageThumbUrl }),
                createdAt: dateTimeFormatter(p.createdAt)
            })), details: detailPanel, onAddImage: isReadOnly ? undefined : handleImageUploadClick, onDeleteImage: isReadOnly ? undefined : handleDeleteImage, onBackButtonClick: markerCreatorSource ? handleBackButtonClick : undefined, onDoneClick: handleClickOutside, topButtonsCreate: topButtonsCreate }) }));
}
