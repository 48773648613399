import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import * as Sentry from '@sentry/browser';
import { bbox } from '@turf/turf';
import { LngLat } from 'mapbox-gl';
import { getInspection } from '../../../services/inspection';
import { useAppSelector } from '../../../state/hooks';
import { initialViewState } from '../../../state/slices/mainMap';
import { milkyWayWindow } from '../../window';
import { markersLayer, plansFillLayer, pointsLayer, segmentsLayer, trackControlLayer, trafficSignsLayer, winterServiceLayer } from '../layers';
import { assetLayer } from '../layers/assetLayer';
import { signInventoryLayer } from '../layers/signInventoryLayer';
import { isFeatureInMapView, moveMapToCenterOfCoordinates, moveMapToFeatureCollection, moveMapToPoint } from '../move';
import { DEFAULT_SOURCE_LAYER } from '../settings';
import { isMapSourceLoaded, MAP_ID_FIELD_NAME, SEGMENTS_SOURCE_ID } from '../sources';
const BOUNDS_ARRAY_LENGTH = 4;
export function MainMapController() {
    const { current: map } = useMap();
    if (milkyWayWindow.isSystemUnderTest) {
        milkyWayWindow.map = map;
    }
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { feature: conditionFeature } = useAppSelector((state) => state.conditionDetail);
    const { marker } = useAppSelector((state) => state.markerDetail);
    const { assetInfo } = useAppSelector((state) => state.assetDetail);
    const { signInfo } = useAppSelector((state) => state.signDetail);
    const { signInfo: signInventoryInfo } = useAppSelector((state) => state.signInventoryDetail);
    const { currentPlan } = useAppSelector((state) => state.plan);
    const { geojson: selectedCollection } = useAppSelector((state) => state.segmentForm);
    const { planGeoJSON, drawPolygon } = useAppSelector((state) => state.mainMap);
    useEffect(() => {
        if (map && selectedCollection) {
            const [x1, y1, x2, y2] = bbox(selectedCollection.collection);
            map.fitBounds([x1, y1, x2, y2], {
                padding: 30,
                maxZoom: 17
            });
        }
    }, [map, selectedCollection]);
    useEffect(() => {
        if (map && drawPolygon) {
            const [x1, y1, x2, y2] = bbox(drawPolygon);
            map.fitBounds([x1, y1, x2, y2], {
                padding: 30,
                maxZoom: 17
            });
            return;
        }
    }, [map, drawPolygon]);
    // center map to account
    useEffect(() => {
        if (!map) {
            return;
        }
        if (!teamAccount) {
            return;
        }
        if (initialViewState) {
            return;
        }
        const { lat: latitude, lon: longitude } = teamAccount;
        map.jumpTo({
            center: new LngLat(longitude, latitude)
        });
        if (Array.isArray(teamAccount.bounds) &&
            teamAccount.bounds.length === BOUNDS_ARRAY_LENGTH) {
            map.fitBounds([
                teamAccount.bounds[0],
                teamAccount.bounds[1],
                teamAccount.bounds[2],
                teamAccount.bounds[3]
            ], { animate: false, padding: 50 });
        }
        else {
            Sentry.captureException(new Error('Invalid bounds'), {
                extra: { teamAccount: teamAccount.username, bounds: teamAccount.bounds }
            });
        }
    }, [teamAccount, map]);
    // center map to feature if not in view
    useEffect(() => {
        var _a;
        if (!map || !selectedFeature || !map.getMap()) {
            return;
        }
        const safeMap = map.getMap();
        switch (selectedFeature.type) {
            case 'sign': {
                if (isFeatureInMapView(safeMap, trafficSignsLayer.id, MAP_ID_FIELD_NAME.sign, selectedFeature.id)) {
                    return;
                }
                if (selectedFeature.id === (signInfo === null || signInfo === void 0 ? void 0 : signInfo.id) && signInfo.offsetPosition.length >= 2) {
                    const center = new LngLat(signInfo.offsetPosition[0], signInfo.offsetPosition[1]);
                    moveMapToPoint(safeMap, center);
                }
                return;
            }
            case 'sign-inventory': {
                if (isFeatureInMapView(safeMap, signInventoryLayer.id, MAP_ID_FIELD_NAME.signInventory, selectedFeature.id)) {
                    return;
                }
                if (selectedFeature.id === (signInventoryInfo === null || signInventoryInfo === void 0 ? void 0 : signInventoryInfo.id) &&
                    signInventoryInfo.offsetPosition.length >= 2) {
                    const center = new LngLat(signInventoryInfo.offsetPosition[0], signInventoryInfo.offsetPosition[1]);
                    moveMapToPoint(safeMap, center);
                }
                return;
            }
            case 'marker': {
                if (isFeatureInMapView(safeMap, markersLayer.id, MAP_ID_FIELD_NAME.marker, selectedFeature.id)) {
                    return;
                }
                if (selectedFeature.id === (marker === null || marker === void 0 ? void 0 : marker.id)) {
                    moveMapToPoint(safeMap, {
                        lng: marker.point.longitude,
                        lat: marker.point.latitude
                    });
                }
                return;
            }
            case 'asset': {
                if (isFeatureInMapView(safeMap, assetLayer.id, MAP_ID_FIELD_NAME.asset, selectedFeature.id)) {
                    return;
                }
                if (selectedFeature.id === (assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.id) && assetInfo.coordinates.length >= 2) {
                    const center = new LngLat(assetInfo.coordinates[0], assetInfo.coordinates[1]);
                    moveMapToPoint(safeMap, center);
                }
                return;
            }
            case 'point':
            case 'alert':
            case 'object': {
                if (isFeatureInMapView(safeMap, pointsLayer.id, MAP_ID_FIELD_NAME.point, selectedFeature.id)) {
                    return;
                }
                if (!conditionFeature || conditionFeature.type !== 'point') {
                    return;
                }
                if (selectedFeature.id === ((_a = conditionFeature.value) === null || _a === void 0 ? void 0 : _a.systems_point_id) &&
                    conditionFeature.value.coordinates.length >= 2) {
                    const center = new LngLat(conditionFeature.value.coordinates[0], conditionFeature.value.coordinates[1]);
                    moveMapToPoint(safeMap, center);
                }
                return;
            }
            case 'segment': {
                if (!isMapSourceLoaded(map, SEGMENTS_SOURCE_ID)) {
                    return;
                }
                if (isFeatureInMapView(safeMap, segmentsLayer.id, MAP_ID_FIELD_NAME.segment, selectedFeature.id)) {
                    return;
                }
                // TODO: sometimes on higher zoom query provides empty array.
                // Map hides invisible points, but we don't have from where get coordinates
                const features = map.querySourceFeatures(SEGMENTS_SOURCE_ID, {
                    sourceLayer: DEFAULT_SOURCE_LAYER,
                    filter: ['==', MAP_ID_FIELD_NAME.segment, selectedFeature.id]
                });
                moveMapToFeatureCollection(safeMap, features);
                return;
            }
            case 'plan': {
                if (!planGeoJSON || !currentPlan) {
                    return;
                }
                if (isFeatureInMapView(safeMap, plansFillLayer.id, MAP_ID_FIELD_NAME.plan, selectedFeature.id)) {
                    return;
                }
                const features = planGeoJSON.features.filter((plan) => plan.id === selectedFeature.id);
                moveMapToFeatureCollection(safeMap, features);
                return;
            }
            case 'track-control':
            case 'winter-service': {
                const layer = selectedFeature.type === 'track-control'
                    ? trackControlLayer
                    : winterServiceLayer;
                if (isFeatureInMapView(safeMap, layer.id, MAP_ID_FIELD_NAME.inspectionV2, selectedFeature.id)) {
                    return;
                }
                getInspection({ id: selectedFeature.id }).then((res) => {
                    if (res.result && res.result.inspection.coordinates.length > 0) {
                        moveMapToCenterOfCoordinates(safeMap, res.result.inspection.coordinates);
                    }
                });
                return;
            }
        }
    }, [
        conditionFeature,
        selectedFeature,
        marker,
        assetInfo,
        signInfo,
        signInventoryInfo,
        map,
        planGeoJSON,
        currentPlan
    ]);
    return _jsx(_Fragment, {});
}
