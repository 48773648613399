import { useEffect, useState } from 'react';
export const useScrollDirection = (scrollContainerRef) => {
    const [scrollDirection, setScrollDirection] = useState('down');
    useEffect(() => {
        var _a;
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer)
            return;
        let lastScrollY = (_a = scrollContainer.scrollTop) !== null && _a !== void 0 ? _a : 0;
        function scrollListener() {
            if (!scrollContainerRef.current)
                return;
            const scrollY = scrollContainerRef.current.scrollTop;
            const scrollDirection = scrollY === 0 || scrollY > lastScrollY ? 'down' : 'up';
            lastScrollY = scrollY;
            setScrollDirection(scrollDirection);
        }
        scrollContainer.addEventListener('scroll', scrollListener, {
            passive: true
        });
        return () => {
            scrollContainer.removeEventListener('scroll', scrollListener);
        };
    }, [scrollContainerRef]);
    return scrollDirection;
};
