// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yfr042Sp5hd03ODq5ITj{width:100%}.scYoA3BggwMptbGBEGdW{width:100%;display:flex;justify-content:flex-end;gap:12px}.id1k8Oc_bhoXwqH3glsc{width:120px;margin-right:auto}.uUOopi5PNBUSMRefGY8j{width:120px}.xwxknGwV8G4Bc_fjvGZn{width:120px}`, "",{"version":3,"sources":["webpack://./src/containers/WorkingHoursPickerModalContainer/WorkingHoursPickerModalContainer.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA,CAGJ,sBACI,WAAA,CACA,iBAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,WAAA","sourcesContent":[".footer {\n    width: 100%;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n}\n\n.button-delete {\n    width: 120px;\n    margin-right: auto;\n}\n\n.button-cancel {\n    width: 120px;\n}\n\n.button-confirm {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Yfr042Sp5hd03ODq5ITj`,
	"buttons": `scYoA3BggwMptbGBEGdW`,
	"button-delete": `id1k8Oc_bhoXwqH3glsc`,
	"button-cancel": `uUOopi5PNBUSMRefGY8j`,
	"button-confirm": `xwxknGwV8G4Bc_fjvGZn`
};
export default ___CSS_LOADER_EXPORT___;
