import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { AssetIcon } from '../../../../common/icon';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { Checkbox } from '../../../../uikit/Checkbox/Checkbox';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../../FormInputSelect/FormInputSelect';
import { Check, Plus, Tree2 } from '../../../icons';
import { SelectboxEntry } from '../../../SelectboxEntry/SelectboxEntry';
import styles from './AssetConverterForm.module.css';
export const FORM_WIDTH = 432;
const DROP_MENU_MAX_HEIGHT = 352;
export function AssetConverterForm({ assetTypesList, selectedAmount, attributeFieds, isDeleteChecked, onAssetTypeChange, onAddNewTypeClick, onDeleteClick }) {
    const assetTypeLabel = useDittoWrapper({ componentId: 'assets.assettype' });
    const placeholder = useDittoWrapper({ componentId: 'tasks.tasktoassetconverter.pleaseselect' });
    const addAssetTypeText = useDittoWrapper({ componentId: 'assets.addassettype' });
    const [selectLocalValue, setSelectLocalValue] = useState(undefined);
    const [showAttributeFields, setShowAttributeFields] = useState(false);
    function handleShowAttributeFields() {
        setShowAttributeFields(!showAttributeFields);
    }
    const placeholderField = (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(Tree2, {}), text: _jsx("span", Object.assign({ className: styles['placeholder-text'] }, { children: placeholder })) }));
    const assetTypesItems = assetTypesList.map((assetType) => (_jsx(SelectboxEntry, { text: assetType.name, leadingIcon: _jsx(AssetIcon, { iconId: assetType.iconId }), trailingIcon: (selectLocalValue === null || selectLocalValue === void 0 ? void 0 : selectLocalValue.id) === assetType.id ? _jsx(Check, {}) : undefined, onClick: () => {
            setSelectLocalValue(assetType);
            onAssetTypeChange && onAssetTypeChange(assetType);
        } }, assetType.id)));
    const addAssetTypeItem = (_jsx(SelectboxEntry, { leadingIcon: _jsx(Plus, {}), text: _jsx("span", Object.assign({ className: styles['add-button'] }, { children: addAssetTypeText })), onClick: () => onAddNewTypeClick && onAddNewTypeClick() }));
    const showDetailsButton = selectLocalValue && selectLocalValue.attributes.length ? true : false;
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", Object.assign({ className: styles['description'] }, { children: showAttributeFields ? (_jsx(Ditto, { componentId: "tasks.tasktoassetconverter.fillinthedetailsapplyingtoallselecteditems" })) : (_jsx(Ditto, { componentId: "tasks.tasktoassetconverter.description" })) })), _jsx("div", Object.assign({ className: styles['form'] }, { children: _jsx(FormInputSelect, { label: _jsx("span", Object.assign({ className: styles['label'] }, { children: assetTypeLabel })), width: FORM_WIDTH, menuMaxHeight: DROP_MENU_MAX_HEIGHT, menuWidth: FORM_WIDTH, menuItems: [...assetTypesItems, addAssetTypeItem], required: true, value: selectLocalValue ? (_jsx(FormInputCurrentSelectedValue, { text: selectLocalValue.name, icon: _jsx(AssetIcon, { iconId: selectLocalValue.iconId }) }, selectLocalValue.id)) : (placeholderField) }) })), showAttributeFields && attributeFieds && (_jsx("div", Object.assign({ className: styles['attribute-form'] }, { children: attributeFieds }))), _jsxs("div", Object.assign({ className: styles['bottom-content'] }, { children: [_jsxs("span", Object.assign({ className: styles['checkbox-field'], onClick: onDeleteClick }, { children: [_jsx(Checkbox, { isChecked: isDeleteChecked, color: "black" }), _jsx("span", Object.assign({ className: styles['checkbox-label'] }, { children: _jsx(Ditto, { componentId: "tasks.tasktoassetconverter.deletextasksafterwards", variables: { AmountOfTasksSelectedToConvertToAssets: selectedAmount }, count: selectedAmount }) }))] })), showDetailsButton && (_jsx("div", Object.assign({ className: styles['button-container'] }, { children: _jsx("button", Object.assign({ className: styles['button-text'], onClick: handleShowAttributeFields }, { children: showAttributeFields ? (_jsx(Ditto, { componentId: "tasks.tasktoassetconverter.hideproperties" })) : (_jsx(Ditto, { componentId: "tasks.tasktoassetconverter.adddetails" })) })) })))] }))] })));
}
