import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../uikit/Button/Button';
import { Task } from '../icons';
import styles from './SignDetail.module.css';
export function SignDetail({ header, imagePreview, damageType, metaInfo, onAddMarkerClick }) {
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "sign-details" }, { children: [header, _jsxs("div", Object.assign({ className: styles['content-group'] }, { children: [_jsx("div", Object.assign({ className: styles['meta-info'] }, { children: metaInfo })), _jsx("div", Object.assign({ className: styles['image-preview'] }, { children: imagePreview })), damageType] })), onAddMarkerClick && (_jsx("div", Object.assign({ className: classes(styles['footer']) }, { children: _jsx(Button, Object.assign({ variant: "fullwidth", color: "yellow", size: "large", icon: _jsx(Task, {}), onClick: onAddMarkerClick }, { children: _jsx(Ditto, { componentId: "createmarker" }) })) })))] })));
}
