import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { AnimatePresence, motion } from 'framer-motion';
import { layerSelectorTitleTransition, layerSelectorTitleVariants } from '../../../common/consts/animations';
import { Tooltip } from '../../Tooltip/Tooltip';
import styles from './SidebarBottomButton.module.css';
export function SidebarBottomButton({ icon, tooltip, title, isHover, onClick, isOverlay, 'data-test': dataTest }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], isHover && styles['container--hover']), onClick: onClick, "data-test": dataTest }, { children: [_jsx("div", Object.assign({ className: classes(styles['button-container']) }, { children: icon })), !isOverlay && (_jsx("div", Object.assign({ className: styles['anchor-tooltip'] }, { children: _jsx(Tooltip, { text: tooltip, position: "start" }) }))), _jsx(AnimatePresence, { children: isOverlay && (_jsx(motion.div, Object.assign({ transition: layerSelectorTitleTransition, variants: layerSelectorTitleVariants, initial: "initial", animate: "overlay", exit: "initial", className: styles['title'] }, { children: title }), 'title')) })] })));
}
