var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState } from 'react';
import { getURLSearchParams } from '../../common/url';
// NOTE: added modal context because in redux it is not recommended to save functions in states
// for modals it is useful to have callback functions, for example, onConfirm in DeleteModalContainer
const ModalContext = createContext(undefined);
const currentModalId = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.currentModalId;
export function ModalContextProvider({ children }) {
    const [modals, setModals] = useState(currentModalId ? [{ id: currentModalId }] : []);
    function addModal(modal) {
        setModals((modals) => modals.concat(modal));
    }
    function removeModal(id) {
        setModals((modals) => modals.filter((item) => item.id !== id));
    }
    function clearModals() {
        setModals([]);
    }
    return (_jsx(ModalContext.Provider, Object.assign({ value: { modals: modals, addModal, removeModal, clearModals } }, { children: children })));
}
export function useModalContext() {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalContextProvider');
    }
    return context;
}
