import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { ActionModal } from '../ActionModal/ActionModal';
import { FloatingDropdown } from '../FloatingDropdown/FloatingDropdown';
export function FloatingDropdownWrapper({ control, menu, topOffset }) {
    const dropdown = useFloatingDropdown({ offset: { top: topOffset !== null && topOffset !== void 0 ? topOffset : 0, left: 0, right: 0 } });
    // Ensure we close the menu on item click
    let wrappedMenu;
    if (menu.type === ActionModal) {
        wrappedMenu = (_createElement(ActionModal, Object.assign({}, menu.props, { key: menu.key, onItemClick: () => {
                var _a, _b;
                (_b = (_a = menu.props).onItemClick) === null || _b === void 0 ? void 0 : _b.call(_a);
                dropdown.hide();
            } })));
    }
    else {
        throw new Error('ActionModal as menu element expected.');
    }
    return (_jsxs(AnimatePresence, { children: [_jsx("div", Object.assign({ ref: dropdown.buttonRef, onClick: (e) => {
                    // Prevent triggering onClick of the parent(s)
                    e.stopPropagation();
                }, onMouseDown: (e) => {
                    // Prevent triggering onMouseDown of the parent(s)
                    e.stopPropagation();
                    dropdown.toggle();
                }, onBlur: () => {
                    dropdown.hide();
                } }, { children: control }), 'control'), dropdown.isOpen && (_jsx("div", Object.assign({ onClick: (event) => {
                    // Prevents click-through
                    event.stopPropagation();
                }, style: {
                    position: 'absolute'
                } }, { children: _jsx(FloatingDropdown, Object.assign({}, dropdown.position, { onClickOutside: () => dropdown.hide(), content: wrappedMenu })) }), 'dropdown-wrapper'))] }));
}
