import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { FilterSelectbox } from '../FilterSelectbox/FilterSelectbox';
import { CaretRight } from '../icons';
import { Search } from '../Search/Search';
import styles from './AccountSelector.module.css';
const MIN_ACCOUNTS_TO_SHOW_SEARCH = 3;
export function AccountSelector({ accountList, onSelectAccount, onLogoutClick }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'switchaccount.enter' });
    const [searchValue, setSearchValue] = useState('');
    const showSearch = accountList.length > MIN_ACCOUNTS_TO_SHOW_SEARCH;
    const filteredAccountList = searchValue
        ? accountList.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
        : accountList;
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "account-selector" }, { children: [_jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "accountselector" }) })), showSearch && (_jsx(Search, { placeholder: placeholderLabel, value: searchValue, onChange: (value) => setSearchValue(value) })), _jsx("div", Object.assign({ className: styles['options'] }, { children: filteredAccountList.map((item) => (_jsx(FilterSelectbox, { noCheckbox: true, content: item.name, hoverTrailingIcon: { icon: _jsx(CaretRight, {}) }, onClick: () => onSelectAccount(item.id) }, item.id))) })), _jsx("div", Object.assign({ className: styles['bottom-section'] }, { children: _jsx(Button, Object.assign({ className: styles['logout-button'], onClick: onLogoutClick, "data-test": "btn-acc-logout" }, { children: _jsx(Ditto, { componentId: "layerpanel.accountmodal.logout" }) })) }))] })));
}
