// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hq7WY0Q5Ex7PSXY2mMNA{height:6px}.cC_sztbR4oKFMqGyFJPq{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-top:12px;flex-shrink:0}.EmaIxOaDnekZnbXIbTuH{height:476px;overflow:hidden;display:flex;flex-direction:column;flex-shrink:0}.NxZpXogd9TGbpkp8Ku7f{display:flex;flex-direction:column;align-items:center;padding-top:12px;padding-bottom:12px;overflow-y:auto}.elM3peKHwwWrclQZnYYS{width:100%;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/components/EditorForms/AssigneeSelect/AssigneeSelect.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,eAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,eAAA,CAGJ,sBACI,UAAA,CACA,aAAA","sourcesContent":[".search-bottom-space {\n    height: 6px;\n}\n\n.modal-search-divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-top: 12px;\n    flex-shrink: 0;\n}\n\n.modal-search {\n    height: 476px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    flex-shrink: 0;\n}\n\n.modal-search-results {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    overflow-y: auto;\n}\n\n.search-result {\n    width: 100%;\n    flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-bottom-space": `Hq7WY0Q5Ex7PSXY2mMNA`,
	"modal-search-divider": `cC_sztbR4oKFMqGyFJPq`,
	"modal-search": `EmaIxOaDnekZnbXIbTuH`,
	"modal-search-results": `NxZpXogd9TGbpkp8Ku7f`,
	"search-result": `elM3peKHwwWrclQZnYYS`
};
export default ___CSS_LOADER_EXPORT___;
