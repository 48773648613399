// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lQqFFjyq5MWFUHvYwd6N{border-radius:0 6px 6px 0 !important;background:var(--primary-grey, #eff0f0) !important}.lb2Nr2ZrTSINmik3hJpG{margin-left:auto;margin-right:4px}.lQqFFjyq5MWFUHvYwd6N:hover{background:var(--status-hover-hover, rgba(129, 129, 129, 0.1)) !important}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/TrailingSelect/TrailingSelect.module.css"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,kDAAA,CAGJ,sBACI,gBAAA,CACA,gBAAA,CAGJ,4BACI,yEAAA","sourcesContent":[".unit-select {\n    border-radius: 0 6px 6px 0 !important;\n    background: var(--primary-grey, #eff0f0) !important;\n}\n\n.unit-select-value {\n    margin-left: auto;\n    margin-right: 4px;\n}\n\n.unit-select:hover {\n    background: var(--status-hover-hover, rgba(129, 129, 129, 0.1)) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-select": `lQqFFjyq5MWFUHvYwd6N`,
	"unit-select-value": `lb2Nr2ZrTSINmik3hJpG`
};
export default ___CSS_LOADER_EXPORT___;
