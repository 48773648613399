import { useEffect } from 'react';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { ROOT_PATH } from '../common/url';
import { useAppSelector } from '../state/hooks';
export function useReduxToURLLayerPathSync() {
    const { selectedLayerId } = useAppSelector((state) => state.layer);
    const { params: { mapLayerId } } = useMatch();
    const navigate = useNavigate();
    const search = useSearch();
    useEffect(() => {
        if (selectedLayerId !== mapLayerId) {
            navigate({
                to: selectedLayerId
                    ? `/${ROOT_PATH.main}/${selectedLayerId}`
                    : `/${ROOT_PATH.main}`,
                search
            });
        }
    }, [navigate, selectedLayerId, search, mapLayerId]);
}
