export const selectRangeIds = (currentItem, resultList, multiselectList) => {
    const previousSelected = resultList.findIndex((item) => item.id === multiselectList[multiselectList.length - 1]);
    const currentSelected = resultList.findIndex((item) => item.id === currentItem);
    const selectedRange = resultList.slice(Math.min(previousSelected, currentSelected), Math.max(previousSelected, currentSelected) + 1);
    const selectedRangeIds = selectedRange.map((item) => item.id);
    return [...new Set([...multiselectList, ...selectedRangeIds])];
};
export const selectRangeObjects = (currentItem, resultList, multiselectList) => {
    const combinedKeys = resultList.map((item) => ({
        detected_object_type_id: item.detected_object_type_id,
        point_id: item.pointId,
        record_data_id: item.record_data_id
    }));
    const previousSelected = combinedKeys.findIndex((item) => {
        const selectedItem = multiselectList[multiselectList.length - 1];
        return (item.detected_object_type_id === selectedItem.detected_object_type_id &&
            item.point_id === selectedItem.point_id &&
            item.record_data_id === selectedItem.record_data_id);
    });
    const currentSelected = combinedKeys.findIndex((item) => item.detected_object_type_id === currentItem.detected_object_type_id &&
        item.point_id === currentItem.point_id &&
        item.record_data_id === currentItem.record_data_id);
    const selectedRange = combinedKeys.slice(Math.min(previousSelected, currentSelected), Math.max(previousSelected, currentSelected) + 1);
    const data = [...multiselectList, ...selectedRange];
    const uniqueData = [];
    const seenIds = new Set();
    for (const item of data) {
        const key = JSON.stringify(item);
        if (!seenIds.has(key)) {
            seenIds.add(key);
            uniqueData.push(item);
        }
    }
    return uniqueData;
};
