import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DueDatePickerModal } from '../../components/Modals/DueDatePickerModal/DueDatePickerModal';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
export function DueDatePickerModalContainer({ recurringFormula, closestDueDate, onConfirm }) {
    const { removeModal } = useModalContext();
    const { locale } = useAppSelector((state) => state.app);
    function handleCloseClick() {
        removeModal('DueDatePickerModalContainer');
    }
    function handleConfirmClick(newFormula) {
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(newFormula);
        handleCloseClick();
    }
    return (_jsx(DueDatePickerModal, { localeSupported: locale, recurringFormula: recurringFormula || '', closestDueDate: closestDueDate, onConfirm: handleConfirmClick, onCloseClick: handleCloseClick }));
}
