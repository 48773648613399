import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchMarkerGeoJSON } from '../../state/slices/mainMap';
export function useMarkersMapData() {
    const { markerGeoJSON } = useAppSelector((state) => state.mainMap);
    const { filter } = useAppSelector((state) => state.leftPanelMarker);
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchMarkerGeoJSON());
    }, [dispatch, filter, selectedOption]);
    return { markerGeoJSON };
}
