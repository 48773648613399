// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dXOagDiLnWFegEDbqfhp{width:100%;height:1px;min-height:1px}.XUl03ut4_5P2p4CshuzL{background:var(--primary-black);opacity:.05}.x9hJgVEn4xotA1ip2Pns{background:var(--primary-white);opacity:.3}`, "",{"version":3,"sources":["webpack://./src/uikit/Divider/Divider.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,UAAA,CACA,cAAA,CAGJ,sBACI,+BAAA,CACA,WAAA,CAGJ,sBACI,+BAAA,CACA,UAAA","sourcesContent":[".divider {\n    width: 100%;\n    height: 1px;\n    min-height: 1px;\n}\n\n.divider-dark {\n    background: var(--primary-black);\n    opacity: 0.05;\n}\n\n.divider-white {\n    background: var(--primary-white);\n    opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `dXOagDiLnWFegEDbqfhp`,
	"divider-dark": `XUl03ut4_5P2p4CshuzL`,
	"divider-white": `x9hJgVEn4xotA1ip2Pns`
};
export default ___CSS_LOADER_EXPORT___;
