import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { PersistGate } from 'redux-persist/integration/react';
import '@af-utils/scrollend-polyfill';
import { AppRouter } from './application/AppRouter/AppRouter';
import { SENTRY_CONFIG } from './common/consts/sentry';
import { addPlatformClassToBody } from './common/utils/platformDetect';
import { milkyWayWindow } from './common/window';
import { Loader } from './components/Loader/Loader';
import config from './config/config.json';
import { DittoContextProvider, MapContextProvider, ModalContextProvider } from './state/context';
import { persistor, store } from './state/store';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './components/MapZoom/controls.css';
mixpanel.init(config.mixpanel.token, {
    persistence: 'localStorage'
});
Sentry.init(SENTRY_CONFIG);
addPlatformClassToBody();
const root = ReactDOM.createRoot(document.getElementById('root'));
// Expose store if running in test suite
if (milkyWayWindow.isSystemUnderTest) {
    milkyWayWindow.reduxStore = store;
}
root.render(
// NOTE: strict mode makes components render twice in dev environment.
_jsx(React.StrictMode, { children: _jsx(ModalContextProvider, { children: _jsx(MapContextProvider, { children: _jsx(ReduxProvider, Object.assign({ store: store }, { children: _jsx(PersistGate, Object.assign({ loading: _jsx(Loader, {}), persistor: persistor }, { children: _jsx(DittoContextProvider, { children: _jsx(AppRouter, {}) }) })) })) }) }) }));
