// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xSUDzZlw3YSMZkP0G8PQ{white-space:nowrap}.k2iL9VtYdaC6STHbR4Gy{white-space:normal}.keRHUegdnjo4sKbqFf5f{overflow:hidden;max-width:20ch;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;text-overflow:ellipsis;white-space:break-spaces}`, "",{"version":3,"sources":["webpack://./src/components/EditorForms/AssigneeSelect/AssigneeName/AssigneeName.module.css"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,sBAAA,CACA,wBAAA","sourcesContent":[".text {\n    white-space: nowrap;\n}\n\n.wrap {\n    white-space: normal;\n}\n\n.truncate {\n    overflow: hidden;\n    max-width: 20ch;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 1;\n    text-overflow: ellipsis;\n    white-space: break-spaces;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `xSUDzZlw3YSMZkP0G8PQ`,
	"wrap": `k2iL9VtYdaC6STHbR4Gy`,
	"truncate": `keRHUegdnjo4sKbqFf5f`
};
export default ___CSS_LOADER_EXPORT___;
