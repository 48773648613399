var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { addBreadcrumb, setUser } from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { ACCESS_TOKEN_DURATION_SECONDS } from '../../common/consts/config';
import { MIXPANEL_GROUP_KEY } from '../../common/consts/mixpanel';
import { DEBOUNCE_SHARED_FETCH, DEBOUNCE_USER_INPUT_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { keycloakLogout } from '../../common/keycloak';
import { getLocalStorageAll, setLocalStoragePartial } from '../../common/localStorage';
import { CLIENT_SETTINGS_FIELDNAME } from '../../common/settings';
import { getURLSearchParams, ROOT_PATH } from '../../common/url';
import { needToRegister } from '../../common/user';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { milkyWayWindow } from '../../common/window';
import { addToGroup, checkIfEmailExists, confirmUserInvite, createNewUser, ERROR_CODE_INVITE_IS_EXPIRED, ERROR_CODE_INVITE_IS_SPENT, ERROR_CODE_INVITE_NOT_FOUND, getAuthTokenForStorybook, getDepartmentList, getRoleList, getTeamAccount, getUserAccountAccessList, updateUserClientSettings, updateUserData, updateUserPassword, updateUserPopupSettings, validateUserInvite } from '../../services/accounts';
import { getCurrentUser } from '../../services/accounts';
import { setMapLayers } from './layer';
import { addNotificationMessage } from './notification';
// Define the initial state using that type
const initialState = {
    status: 'idle',
    isAuthenticated: false,
    isAuthProcessing: false,
    targetSchema: getURLSearchParams().targetSchema || getLocalStorageAll().targetSchema,
    accountsAccessList: [],
    departmentList: [],
    roles: {
        status: 'idle',
        list: []
    }
};
export const fetchCurrentUser = createDebouncedAsyncThunk('shared/fetchCurrentUser', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const res = yield getCurrentUser();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.authError) {
        keycloakLogout();
        return;
    }
    const user = (_a = res.result) === null || _a === void 0 ? void 0 : _a.user;
    const { targetSchema } = getState().auth;
    if (!user) {
        return null;
    }
    mixpanel.identify(user.uuid);
    if (needToRegister(user) &&
        window.location.pathname !== `/${ROOT_PATH.register}` &&
        !milkyWayWindow.isRunningStorybook) {
        const { targetSchema } = getState().auth;
        if (targetSchema) {
            window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.register}?targetSchema=${targetSchema}`;
        }
    }
    user.currentAccountRoleId = (_b = user.accounts.find((account) => account.username === targetSchema)) === null || _b === void 0 ? void 0 : _b.roleId;
    if (user.super) {
        user.currentAccountRoleId = user.superRoleId;
    }
    return user;
}), DEBOUNCE_SHARED_FETCH);
export const fetchAccessList = createAppAsyncThunk('auth/fetchAccessList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getUserAccountAccessList();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        addBreadcrumb({
            category: 'user',
            message: 'auth/fetchAccessList: Failed',
            data: res,
            level: 'warning'
        });
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchRoleList = createAppAsyncThunk('auth/role/fetchList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getRoleList();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchDepartmentList = createAppAsyncThunk('auth/department/fetchList', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { locale } = getState().app;
    const res = yield getDepartmentList(locale);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchTeamAccount = createDebouncedAsyncThunk('auth/teamAccount', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d;
    const res = yield getTeamAccount();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
        addBreadcrumb({
            category: 'user',
            message: 'auth/fetchTeamAccount: Failed',
            data: res,
            level: 'warning'
        });
        console.warn('auth/fetchTeamAccount:', res.errorMessage);
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.authError) {
        keycloakLogout();
        return;
    }
    const teamAccount = (_c = res.result) === null || _c === void 0 ? void 0 : _c.account;
    if (teamAccount) {
        mixpanel.register({
            [MIXPANEL_GROUP_KEY.customerId]: teamAccount.username
        });
        const { layerList } = getState().layer;
        const enabledLayerList = (_d = getURLSearchParams().q) === null || _d === void 0 ? void 0 : _d.layerIds;
        const updatedLayers = layerList.map((item) => (Object.assign(Object.assign({}, item), { 
            // TODO: this has to match the values in useLayerChecker, need to think of how to refactor to make it one source of truth
            isAuthorized: (function () {
                switch (item.id) {
                    case 'sign':
                        return false;
                    case 'sign-inventory':
                        return (teamAccount.settings.allTrafficSignsEnabled ||
                            teamAccount.settings.trafficSignsEnabled ||
                            teamAccount.settings.editableTrafficSignsEnabled);
                    case 'referencing-flag':
                        return !!teamAccount.settings.referencingEnabled;
                    case 'object':
                        return (teamAccount.settings.basicManHoleStormDrainEnabled ||
                            teamAccount.settings.manHoleStormDrainSpecifiedClassesEnabled);
                    default:
                        return true;
                }
            })(), isEnabled: (function () {
                switch (item.id) {
                    case 'referencing-flag':
                        return (Boolean(enabledLayerList === null || enabledLayerList === void 0 ? void 0 : enabledLayerList.find((layer) => layer === 'referencing-flag')) || !teamAccount.settings.hideReferencing);
                    default:
                        return item.isEnabled;
                }
            })() })));
        dispatch(setMapLayers(updatedLayers));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
export const updateUser = createAppAsyncThunk('auth/updateUser', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield updateUserData(data);
    if (result.errorCode !== RESPONSE_ERROR_CODE.success) {
        const error = `${result.errorCode} ${result.errorMessage}`;
        dispatch(addNotificationMessage({ type: 'error' }));
        throw new Error(error);
    }
    if (result.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.changessaved' } }
        }));
    }
    return result;
}));
export const updateUserPopupSettingsTk = createAppAsyncThunk('auth/updateUserPopupSettingsTk', (fieldName, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield updateUserPopupSettings({ fieldName });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(fetchCurrentUser());
    }
    return res;
}));
export const updateUserClientSettingsTk = createDebouncedAsyncThunk('auth/updateUserClientSettingsTk', (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield updateUserClientSettings(body);
}), DEBOUNCE_USER_INPUT_MS);
export const resetSettingsActiveLayersTk = createAppAsyncThunk('auth/resetSettingsActiveLayersTk', () => __awaiter(void 0, void 0, void 0, function* () {
    const settings = { fieldName: CLIENT_SETTINGS_FIELDNAME.activeLayers, value: [] };
    return updateUserClientSettings(settings);
}));
export const updatePassword = createAppAsyncThunk('auth/updatePassword', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield updateUserPassword(data);
    if (response.errorCode !== RESPONSE_ERROR_CODE.success) {
        const error = `${response.errorCode} ${response.errorMessage}`;
        dispatch(addNotificationMessage({
            type: 'error',
            ditto: response.errorCode === RESPONSE_ERROR_CODE.authError
                ? {
                    title: { componentId: 'register.wrongoldpassword' }
                }
                : undefined
        }));
        throw new Error(error);
    }
    if (response.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.changessaved' }
            }
        }));
    }
    return response;
}));
export const checkIfUserExists = createAppAsyncThunk('auth/checkIfUserExists', (username, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield checkIfEmailExists(username);
    if (response.result) {
        return response.result.exists;
    }
    dispatch(addNotificationMessage({
        type: 'error'
    }));
    throw new Error(response.errorMessage);
}));
export const addUserToGroup = createAppAsyncThunk('auth/addUserToGroup', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield addToGroup(data);
    if (response.errorCode === RESPONSE_ERROR_CODE.success) {
        return response.result;
    }
    dispatch(addNotificationMessage({
        type: 'error'
    }));
    throw new Error(response.errorMessage);
}));
export const validateInvite = createAppAsyncThunk('auth/validateInvite', (token, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield validateUserInvite(token);
    if ([
        RESPONSE_ERROR_CODE.success,
        ERROR_CODE_INVITE_NOT_FOUND,
        ERROR_CODE_INVITE_IS_EXPIRED,
        ERROR_CODE_INVITE_IS_SPENT
    ].includes(response.errorCode)) {
        return response;
    }
    dispatch(addNotificationMessage({
        type: 'error'
    }));
    throw new Error(`${response.errorCode} ${response.errorMessage}`);
}));
export const confirmInvite = createAppAsyncThunk('auth/confirmInvite', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield confirmUserInvite(data);
    if (response.errorCode === RESPONSE_ERROR_CODE.success) {
        return true;
    }
    const error = `${response.errorCode} ${response.errorMessage}`;
    if (response.errorCode === ERROR_CODE_INVITE_NOT_FOUND) {
        console.warn(`Auth/confirmInvite: Expected error, err: ${error}`);
    }
    else {
        console.error(`Auth/confirmInvite: Unexpected error, err: ${error}`);
    }
    dispatch(addNotificationMessage({
        type: 'error'
    }));
    throw new Error(error);
}));
export const createUser = createAppAsyncThunk('auth/createUser', (data, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield createNewUser(data);
    if (response.errorCode === RESPONSE_ERROR_CODE.success) {
        return true;
    }
    const error = `${response.errorCode} ${response.errorMessage}`;
    dispatch(addNotificationMessage({
        type: 'error'
    }));
    throw new Error(error);
}));
// this should only be used in storybook, not in production.
export const getAccessTokenForStorybook = createAppAsyncThunk('auth/token', () => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getAuthTokenForStorybook();
    setLocalStoragePartial({
        token: res.accessToken,
        targetSchema: res.targetSchema
    });
    return res;
}), {
    condition: (_, { getState }) => {
        const state = getState();
        const { expiresAt, status } = state.auth;
        if (status === 'loading') {
            return false;
        }
        if (expiresAt !== undefined && new Date().getTime() < expiresAt) {
            return false;
        }
    }
});
export const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        reset: () => initialState,
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setIsAuthProcessing: (state, action) => {
            state.isAuthProcessing = action.payload;
        },
        setIsKeycloakReady: (state, action) => {
            state.isKeycloakReady = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAccessTokenForStorybook.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAccessTokenForStorybook.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.targetSchema = action.payload.targetSchema;
            state.expiresAt = new Date().getTime() + ACCESS_TOKEN_DURATION_SECONDS * 1000;
        })
            .addCase(getAccessTokenForStorybook.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
            .addCase(fetchAccessList.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.accountsAccessList = action.payload.result.accounts.sort((a, b) => a.displayName.localeCompare(b.displayName));
            }
        })
            .addCase(fetchRoleList.pending, (state) => {
            state.roles.status = 'loading';
        })
            .addCase(fetchRoleList.rejected, (state) => {
            state.roles.status = 'failed';
        })
            .addCase(fetchRoleList.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.roles.status = 'succeeded';
                state.roles.list = action.payload.result.roles;
            }
        })
            .addCase(fetchDepartmentList.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.departmentList = action.payload.result.departments;
            }
        })
            .addCase(fetchTeamAccount.fulfilled, (state, action) => {
            var _a;
            if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.result) {
                state.teamAccount = action.payload.result.account;
                setUser({
                    username: action.payload.result.account.username
                });
            }
        })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.userAccount = action.payload;
                setUser({
                    email: action.payload.email || ''
                });
            }
        })
            .addMatcher(isAnyOf(addUserToGroup.fulfilled, checkIfUserExists.fulfilled, createUser.fulfilled, confirmInvite.fulfilled, fetchCurrentUser.fulfilled, fetchDepartmentList.fulfilled, validateInvite.fulfilled, updateUser.fulfilled, updatePassword.fulfilled), (state) => {
            state.status = 'succeeded';
        })
            .addMatcher(isAnyOf(addUserToGroup.pending, checkIfUserExists.pending, createUser.pending, confirmInvite.pending, fetchCurrentUser.pending, fetchDepartmentList.pending, validateInvite.pending, updateUser.pending, updatePassword.pending), (state) => {
            state.status = 'loading';
        })
            .addMatcher(isAnyOf(addUserToGroup.rejected, checkIfUserExists.rejected, createUser.rejected, confirmInvite.rejected, fetchCurrentUser.rejected, fetchDepartmentList.rejected, validateInvite.rejected, updateUser.rejected, updatePassword.rejected), (state) => {
            state.status = 'failed';
        });
    }
});
export const { reset: resetAuth, setIsAuthenticated, setIsKeycloakReady, setIsAuthProcessing } = authSlice.actions;
export default authSlice.reducer;
