import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ImagePlaceholder } from '../../../uikit/ImagePlaceholder/ImagePlaceholder';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Recording } from '../../icons';
import { ImageEnlarge } from '../ImageEnlarge/ImageEnlarge';
import { Thumbnail } from '../Thumbnail/Thumbnail';
import styles from './Marker.module.css';
export function Marker({ content, withAction, onImageEnlargeClick, checkbox }) {
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-marker" }, { children: [_jsxs("div", Object.assign({ className: styles['top'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle']), "data-test": "marker-date" }, { children: [checkbox, _jsx("span", { children: content.dateTime.dateTime })] })), _jsxs("div", Object.assign({ className: styles['title'] }, { children: [content.priority.icon, _jsx("span", Object.assign({ className: classes(styles['title-text'], !content.title && styles['title-text__empty']), "data-test": "task-title" }, { children: content.title || _jsx(Ditto, { componentId: "tasktitle.notitle" }) }))] })), _jsx("div", Object.assign({ className: classes(styles['description']) }, { children: content.description }))] })), _jsxs("div", Object.assign({ className: classes(styles['right'], content.thumbnails.length && styles['right__no-background']) }, { children: [content.thumbnails.length ? (_jsx(Thumbnail, { thumbnails: content.thumbnails })) : (_jsx(ImagePlaceholder, {})), withAction && _jsx(ImageEnlarge, { onClick: onImageEnlargeClick })] }))] })), content.assignee && _jsx("div", Object.assign({ className: styles['tags'] }, { children: content.assignee })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [content.status, content.category, content.audioDuration && (_jsxs("div", Object.assign({ className: styles['tag'] }, { children: [_jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Recording, {}) })), _jsx("span", Object.assign({ className: styles['tag-text'] }, { children: content.audioDuration }))] }))), content.dueDate, content.linkedAsset, content.file] }))] })));
}
