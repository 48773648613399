// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.raOcNP9rd1YMucUGXfJg{display:flex;flex-direction:column;align-items:stretch;gap:var(--gap-gap-m, 4px)}.NbgSFqqBaVy_5FlyL2wu{display:flex;align-items:center;justify-content:space-between;color:var(--primary-dark-blue-3, #7a8188);font-size:12px}.XDQUw4m4IXwlsW5ILOw2{display:flex;align-items:center;gap:var(--gap-gap-m, 4px);font-size:18px;font-weight:600}.wtnne9wrhnKIAEqjPqAN *{font-size:12px !important}`, "",{"version":3,"sources":["webpack://./src/components/PrintLayout/PrintMeta/PrintMeta.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,yBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,yCAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,yBAAA,CACA,cAAA,CACA,eAAA,CAGJ,wBACI,yBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    gap: var(--gap-gap-m, 4px);\n}\n\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    color: var(--primary-dark-blue-3, #7a8188);\n    font-size: 12px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    gap: var(--gap-gap-m, 4px);\n    font-size: 18px;\n    font-weight: 600;\n}\n\n.meta-info * {\n    font-size: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `raOcNP9rd1YMucUGXfJg`,
	"header": `NbgSFqqBaVy_5FlyL2wu`,
	"title": `XDQUw4m4IXwlsW5ILOw2`,
	"meta-info": `wtnne9wrhnKIAEqjPqAN`
};
export default ___CSS_LOADER_EXPORT___;
