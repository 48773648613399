import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { SignCovered, SignDirty, SignFaded, SignNoDamage, SignStickered } from '../../components/icons';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { SIGN_DAMAGE_ID } from '../consts/trafficSign';
export function getSignIcon(damageId) {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return SignCovered();
        case SIGN_DAMAGE_ID.dirty:
            return SignDirty();
        case SIGN_DAMAGE_ID.faded:
            return SignFaded();
        case SIGN_DAMAGE_ID.stickered:
            return SignStickered();
        default:
            return SignNoDamage();
    }
}
export function getSignTypeIcon(id) {
    return _jsx("img", { src: `/${id}.png`, alt: id, width: 24, height: 24 });
}
export function getSignTypeIconPath(id) {
    return `<img src="/${id}.png" alt="${id}" width="24" height="24" />`;
}
export function getSignDamageName(damageId) {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return 'covered';
        case SIGN_DAMAGE_ID.dirty:
            return 'dirty';
        case SIGN_DAMAGE_ID.faded:
            return 'faded';
        case SIGN_DAMAGE_ID.stickered:
            return 'stickered';
        default:
            return 'noDamage';
    }
}
export const getSignTranslation = (damageId) => {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return _jsx(Ditto, { componentId: "signs.condition.covered" });
        case SIGN_DAMAGE_ID.dirty:
            return _jsx(Ditto, { componentId: "signs.condition.dirty" });
        case SIGN_DAMAGE_ID.faded:
            return _jsx(Ditto, { componentId: "signs.condition.faded" });
        case SIGN_DAMAGE_ID.stickered:
            return _jsx(Ditto, { componentId: "signs.condition.stickered" });
        default:
            return _jsx(Ditto, { componentId: "signs.condition.notdamaged" });
    }
};
export function useSignTranslations() {
    const coveredLabel = useDittoWrapper({ componentId: 'signs.condition.covered' });
    const dirtyLabel = useDittoWrapper({ componentId: 'signs.condition.dirty' });
    const fadedLabel = useDittoWrapper({ componentId: 'signs.condition.faded' });
    const stickeredLabel = useDittoWrapper({ componentId: 'signs.condition.stickered' });
    const notDamagedLabel = useDittoWrapper({ componentId: 'signs.condition.notdamaged' });
    function getSignLabelString(damageId) {
        switch (damageId) {
            case SIGN_DAMAGE_ID.covered:
                return coveredLabel;
            case SIGN_DAMAGE_ID.dirty:
                return dirtyLabel;
            case SIGN_DAMAGE_ID.faded:
                return fadedLabel;
            case SIGN_DAMAGE_ID.stickered:
                return stickeredLabel;
            case SIGN_DAMAGE_ID.not_damaged:
                return notDamagedLabel;
        }
    }
    return { getSignLabelString };
}
export function getSignDamageInitialValue(damageId) {
    return (_jsx(FormInputCurrentSelectedValue, { text: getSignTranslation(damageId), icon: getSignIcon(damageId) }));
}
export const getSignDropdownItems = (damageTypesArray) => {
    return damageTypesArray.map((damageType) => (_jsx(SelectboxEntry, { leadingIcon: getSignIcon(damageType.id), text: getSignTranslation(damageType.id) }, damageType.id)));
};
