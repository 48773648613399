// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vLY5uTqpdlTM4aZJkggt{display:flex;height:40px;padding:0 4px 0 12px;justify-content:space-between;align-items:center;align-self:stretch}.tZz3f8mku2196_UqaQQe{display:flex;align-items:center;gap:4px}.LeYHh93BvT0zdxSgJoa6{font-size:16px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/SectionHeader/SectionHeader.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,WAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CAGJ,sBACI,cAAA,CACA,eAAA","sourcesContent":[".header {\n    display: flex;\n    height: 40px;\n    padding: 0 4px 0 12px;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n}\n\n.header-left {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n\n.title {\n    font-size: 16px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `vLY5uTqpdlTM4aZJkggt`,
	"header-left": `tZz3f8mku2196_UqaQQe`,
	"title": `LeYHh93BvT0zdxSgJoa6`
};
export default ___CSS_LOADER_EXPORT___;
