import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getCommonParentElement } from '../common/utils/commonParent';
import { rafSchd } from '../common/utils/rafSchd';
/**
 * Observe intersection changes between refs and their parent.
 * It is assumed that all refs are children of the same parent element
 * @param refs Array of RefObjects, either created with React.createRef or React.useRef
 * @returns Array where each element is the intersection ratio of the corresponding ref and its parent.
 */
export const useIntersectionObserver = ({ refs }) => {
    const intersectRatios = useRef(Array(refs.length).fill(0));
    const thresholds = useMemo(() => {
        const NUM_THRESHOLDS = 100;
        const buildThresholdList = (steps) => {
            const thresholds = new Array(steps + 1).fill(0);
            for (let i = 1.0; i <= steps; i++) {
                let ratio = i / steps;
                thresholds[i] = ratio;
            }
            thresholds[steps] = 0;
            return thresholds;
        };
        return buildThresholdList(NUM_THRESHOLDS);
    }, []);
    const handleIntersect = rafSchd(useCallback((entries) => {
        for (const [index, ref] of refs.entries()) {
            for (const entry of entries) {
                if (entry.target === ref.current) {
                    intersectRatios.current[index] = entry.intersectionRatio;
                }
            }
        }
    }, [refs, intersectRatios]));
    useEffect(() => {
        const parent = getCommonParentElement(refs);
        if (!parent) {
            return;
        }
        const observer = new IntersectionObserver(handleIntersect, {
            root: parent,
            threshold: thresholds
        });
        for (const ref of refs) {
            if (ref.current) {
                observer.observe(ref.current);
            }
        }
        return () => {
            observer.disconnect();
        };
    }, [refs, thresholds, handleIntersect]);
    return { intersectRatios };
};
