import { SIGN_DAMAGE_ID, SIGN_DAMAGE_NAMES } from '../../consts/trafficSign';
import { generatedIconPrefix } from '../dynamicIcon';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
import { SIGN_INVENTORY_SOURCE_ID } from '../sources';
export const SIGN_INVENTORY_LAYER_ID = {
    pole: `${LAYER_PREFIX}-sign-inventory-pole`,
    poleSelected: `${LAYER_PREFIX}-sign-inventory-pole-selected`,
    orientation: `${LAYER_PREFIX}-sign-inventory-orientation`,
    orientationHover: `${LAYER_PREFIX}-sign-inventory-orientation-hover`,
    default: `${LAYER_PREFIX}-sign-inventory`,
    hover: `${LAYER_PREFIX}-sign-inventory-hover`,
    highlight: `${LAYER_PREFIX}-sign-inventory-highlight`,
    selected: `${LAYER_PREFIX}-sign-inventory-selected`,
    restricted: `${LAYER_PREFIX}-sign-inventory-restricted`
};
// Assets are 60x60
const MIN_SCALE = 0.4;
const MAX_SCALE = 0.6;
const SHOW_POLES_ZOOM_LEVEL = 13;
const SIGN_ICON_VERTICAL_OFFSET = -42;
const SIGN_ICON_HORIZONTAL_OFFSET = 16;
export const signInventoryPoleSelectedLayer = {
    id: SIGN_INVENTORY_LAYER_ID.poleSelected,
    type: 'symbol',
    layout: {
        'icon-image': 'sign-pin-selected',
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-offset': [1, 0],
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'symbol-z-order': 'source',
        'symbol-sort-key': ['get', 'z_index']
    },
    paint: {
        'icon-opacity': ['step', ['zoom'], ['get', 'opacity'], 0, 0, SHOW_POLES_ZOOM_LEVEL, 1]
    }
};
export const signInventoryPoleLayer = {
    id: SIGN_INVENTORY_LAYER_ID.pole,
    type: 'symbol',
    layout: {
        'icon-image': 'sign-pin',
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'symbol-z-order': 'source',
        'symbol-sort-key': ['get', 'z_index']
    },
    paint: {
        'icon-opacity': ['step', ['zoom'], ['get', 'opacity'], 0, 0, SHOW_POLES_ZOOM_LEVEL, 1]
    },
    filter: ['!=', ['get', 'sub_specified_class'], 'reflector_posts_de_620-40_620-41']
};
export const signInventoryLayer = {
    id: SIGN_INVENTORY_LAYER_ID.default,
    source: SIGN_INVENTORY_SOURCE_ID,
    type: 'symbol',
    layout: {
        'icon-image': [
            'case',
            [
                'match',
                ['get', 'icon'],
                [
                    SIGN_DAMAGE_ID.covered,
                    SIGN_DAMAGE_ID.dirty,
                    SIGN_DAMAGE_ID.faded,
                    SIGN_DAMAGE_ID.stickered
                ],
                true,
                false
            ],
            [
                'concat',
                generatedIconPrefix,
                `type=icon-with-attachment&base=/`,
                ['get', 'sub_specified_class'],
                '.png',
                '&attachment=/sign-',
                [
                    'case',
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.covered],
                    SIGN_DAMAGE_NAMES.covered,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.dirty],
                    SIGN_DAMAGE_NAMES.dirty,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.faded],
                    SIGN_DAMAGE_NAMES.faded,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.stickered],
                    SIGN_DAMAGE_NAMES.stickered,
                    SIGN_DAMAGE_NAMES.not_damaged
                ],
                '-default.png',
                '&attachmentBackground=/attachment-background-default.png'
            ],
            ['get', 'sub_specified_class']
        ],
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-offset': [
            'case',
            [
                'match',
                ['get', 'icon'],
                [
                    SIGN_DAMAGE_ID.covered,
                    SIGN_DAMAGE_ID.dirty,
                    SIGN_DAMAGE_ID.faded,
                    SIGN_DAMAGE_ID.stickered
                ],
                true,
                false
            ],
            ['literal', [SIGN_ICON_HORIZONTAL_OFFSET, SIGN_ICON_VERTICAL_OFFSET]],
            ['literal', [0, SIGN_ICON_VERTICAL_OFFSET]]
        ],
        'symbol-z-order': 'source',
        'symbol-sort-key': ['get', 'z_index']
    },
    paint: {
        'icon-opacity': ['get', 'opacity']
    }
};
export const signInventoryHoverLayer = Object.assign(Object.assign({}, signInventoryLayer), { id: SIGN_INVENTORY_LAYER_ID.hover, layout: Object.assign(Object.assign({}, signInventoryLayer.layout), { 'icon-image': [
            'case',
            [
                'match',
                ['get', 'icon'],
                [
                    SIGN_DAMAGE_ID.covered,
                    SIGN_DAMAGE_ID.dirty,
                    SIGN_DAMAGE_ID.faded,
                    SIGN_DAMAGE_ID.stickered
                ],
                true,
                false
            ],
            [
                'concat',
                generatedIconPrefix,
                `type=icon-with-attachment&base=/`,
                ['get', 'sub_specified_class'],
                '-hover.png',
                '&attachment=/sign-',
                [
                    'case',
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.covered],
                    SIGN_DAMAGE_NAMES.covered,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.dirty],
                    SIGN_DAMAGE_NAMES.dirty,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.faded],
                    SIGN_DAMAGE_NAMES.faded,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.stickered],
                    SIGN_DAMAGE_NAMES.stickered,
                    SIGN_DAMAGE_NAMES.not_damaged
                ],
                '-default.png',
                '&attachmentBackground=/attachment-background-hover.png'
            ],
            ['concat', ['get', 'sub_specified_class'], '-hover']
        ], 'icon-size': ['interpolate', ['linear'], ['zoom'], MAX_ZOOM, MAX_SCALE] }) });
export const signInventoryHighlightLayer = Object.assign(Object.assign({}, signInventoryLayer), { id: SIGN_INVENTORY_LAYER_ID.highlight, layout: Object.assign(Object.assign({}, signInventoryLayer.layout), { 'icon-image': ['get', 'sub_specified_class'] }) });
export const signInventorySelectedLayer = Object.assign(Object.assign({}, signInventoryLayer), { id: SIGN_INVENTORY_LAYER_ID.selected, layout: Object.assign(Object.assign({}, signInventoryLayer.layout), { 'icon-image': [
            'case',
            [
                'match',
                ['get', 'icon'],
                [
                    SIGN_DAMAGE_ID.covered,
                    SIGN_DAMAGE_ID.dirty,
                    SIGN_DAMAGE_ID.faded,
                    SIGN_DAMAGE_ID.stickered
                ],
                true,
                false
            ],
            [
                'concat',
                generatedIconPrefix,
                `type=icon-with-attachment&base=/`,
                ['get', 'sub_specified_class'],
                '-selected.png',
                '&attachment=/sign-',
                [
                    'case',
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.covered],
                    SIGN_DAMAGE_NAMES.covered,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.dirty],
                    SIGN_DAMAGE_NAMES.dirty,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.faded],
                    SIGN_DAMAGE_NAMES.faded,
                    ['==', ['get', 'icon'], SIGN_DAMAGE_ID.stickered],
                    SIGN_DAMAGE_NAMES.stickered,
                    SIGN_DAMAGE_NAMES.not_damaged
                ],
                '-default.png',
                '&attachmentBackground=/attachment-background-selected.png'
            ],
            ['concat', ['get', 'sub_specified_class'], '-selected']
        ], 'icon-size': ['interpolate', ['linear'], ['zoom'], MAX_ZOOM, MAX_SCALE] }) });
export const signInventoryResctrictedAreaLayer = {
    id: SIGN_INVENTORY_LAYER_ID.restricted,
    type: 'fill',
    paint: {
        'fill-color': '#ccc',
        'fill-opacity': 0.4
    }
};
