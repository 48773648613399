var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useMatch } from '@tanstack/react-location';
import { Ditto } from 'ditto-react';
import { keycloakLogin } from '../../common/keycloak';
import { getUniversalLinkForLogin } from '../../common/link';
import { getLocalStorageAll, setLocalStoragePartial } from '../../common/localStorage';
import { ROOT_PATH } from '../../common/url';
import { AppLayoutForm } from '../../components/AppLayoutForm/AppLayoutForm';
import { Join } from '../../components/Auth/Join/Join';
import { RegisterForm } from '../../components/Auth/RegisterForm/RegisterForm';
import { Modal } from '../../components/Modal/Modal';
import { ThankYouModal } from '../../components/Modals/ThankYouModal/ThankYouModal';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';
import { useRolesData } from '../../hooks/useRolesData';
import { ERROR_CODE_INVITE_IS_EXPIRED, ERROR_CODE_INVITE_IS_SPENT, ERROR_CODE_INVITE_NOT_FOUND } from '../../services/accounts';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setLocale } from '../../state/slices/app';
import { confirmInvite, validateInvite } from '../../state/slices/auth';
import { Button } from '../../uikit/Button/Button';
import { ButtonLink } from '../../uikit/ButtonLink/ButtonLink';
import { NotificationContainer } from '../NotificationContainer/NotificationContainer';
const INVITATION_START_STEP = 'invitation-start';
const REGISTER_FORM_STEP = 'register-form';
const THANK_YOU_STEP = 'thank-you';
const NOT_FOUND_STEP = 'not-found';
const EXPIRED_INVITE_STEP = 'expired-invite';
const IS_SPENT_STEP = 'is-spent';
export function JoinContainer() {
    const { params: { token: urlToken } } = useMatch();
    const { token } = getLocalStorageAll();
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector((state) => state.app);
    const { status } = useAppSelector((state) => state.auth);
    const { roleList } = useRolesData();
    const { departmentList } = useDepartmentsData();
    const [step, setStep] = useState(INVITATION_START_STEP);
    const [stepValues, setStepValues] = useState();
    const [error, setError] = useState(false);
    const [inviteData, setInviteData] = useState();
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        image: undefined,
        titleId: null,
        departmentId: null,
        email: ''
    });
    const [passwordValues, setPasswordValues] = useState({
        password: '',
        passwordConfirm: ''
    });
    useEffect(() => {
        const fetchUserDetails = () => __awaiter(this, void 0, void 0, function* () {
            if (!urlToken) {
                return;
            }
            try {
                const respond = yield dispatch(validateInvite(urlToken)).unwrap();
                if (respond) {
                    const result = respond.result;
                    if (result) {
                        setInviteData(result);
                        dispatch(setLocale(result.locale));
                        setLocalStoragePartial({ locale: result.locale });
                        setValues(Object.assign(Object.assign({}, values), { firstName: result.firstName || '', lastName: result.lastName || '', email: result.email || '', phoneNumber: result.phoneNumber }));
                    }
                    switch (respond.errorCode) {
                        case ERROR_CODE_INVITE_NOT_FOUND:
                            setStep(NOT_FOUND_STEP);
                            return;
                        case ERROR_CODE_INVITE_IS_EXPIRED:
                            setStep(EXPIRED_INVITE_STEP);
                            return;
                        case ERROR_CODE_INVITE_IS_SPENT:
                            setStep(IS_SPENT_STEP);
                            setStepValues({ accountName: (result === null || result === void 0 ? void 0 : result.accountName) || '' });
                            return;
                    }
                }
            }
            catch (err) {
                console.log(err);
                setError(true);
            }
        });
        fetchUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlToken]);
    function onChange(name, value) {
        setValues(Object.assign(Object.assign({}, values), { [name]: value }));
    }
    function onPasswordChange(name, value) {
        setPasswordValues(Object.assign(Object.assign({}, passwordValues), { [name]: value }));
    }
    function handleAcceptInviteClick() {
        if (inviteData === null || inviteData === void 0 ? void 0 : inviteData.userExists) {
            handleRegisterClick();
            return;
        }
        setStep(REGISTER_FORM_STEP);
    }
    function handleRegisterClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!urlToken) {
                return;
            }
            try {
                yield dispatch(confirmInvite({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    titleId: values.titleId,
                    departmentId: values.departmentId,
                    password: passwordValues.password,
                    token: urlToken
                })).unwrap();
                setStep(THANK_YOU_STEP);
            }
            catch (err) {
                setError(true);
            }
        });
    }
    function handleSignInClick() {
        const redirectUri = `${window.location.origin.toString()}/${ROOT_PATH.account}`;
        if (token) {
            window.location.href = redirectUri;
        }
        else {
            keycloakLogin(redirectUri, (values === null || values === void 0 ? void 0 : values.email) || values.phoneNumber || '');
        }
    }
    function handleSeeYouSoonClick() {
        let redirectUri;
        switch (locale) {
            case 'de':
                redirectUri = 'https://vialytics.de';
                break;
            case 'fr':
                redirectUri = 'https://vialytics.fr';
                break;
            case 'en':
                redirectUri = 'https://vialytics.com';
        }
        window.location.href = redirectUri;
    }
    function getStepComponent(step) {
        const signInButton = (_jsxs(_Fragment, { children: [_jsx(BrowserView, { children: _jsx(Button, Object.assign({ onClick: handleSignInClick, color: "dark", "data-test": "sign-in-button" }, { children: _jsx(Ditto, { componentId: "register.signin" }) })) }), _jsx(MobileView, { children: _jsx(ButtonLink, Object.assign({ href: getUniversalLinkForLogin() }, { children: _jsx(Ditto, { componentId: "register.signin" }) })) })] }));
        switch (step) {
            case INVITATION_START_STEP:
                return (_jsx(Join, { inviteData: inviteData, loading: status === 'loading', roleList: roleList, hasError: error, onAcceptInviteClick: handleAcceptInviteClick }));
            case REGISTER_FORM_STEP:
                return (_jsx(RegisterForm, { currentLocale: locale, values: values, passwordValues: passwordValues, departmentList: departmentList, hasError: error, loading: status === 'loading', onChange: onChange, onPasswordChange: onPasswordChange, onRegisterClick: handleRegisterClick }));
            case THANK_YOU_STEP:
                return (_jsx(ThankYouModal, { onSignInClick: handleSignInClick, variant: values.phoneNumber ? 'phone-number' : 'email', phoneNumber: values.phoneNumber }));
            case EXPIRED_INVITE_STEP:
                return (_jsx(Modal, { title: _jsx(Ditto, { componentId: "register.inviteexpired" }), content: _jsx(Ditto, { componentId: "register.inviteexpired.text" }), footer: _jsx(Button, Object.assign({ onClick: handleSeeYouSoonClick, color: "dark" }, { children: _jsx(Ditto, { componentId: "register.seeyou" }) })) }));
            case NOT_FOUND_STEP:
                return (_jsx(Modal, { title: _jsx(Ditto, { componentId: "register.unknowninvite" }), content: _jsx(Ditto, { componentId: "register.unknowninvite.text" }), footer: signInButton }));
            case IS_SPENT_STEP:
                return (_jsx(Modal, { title: _jsx(Ditto, { componentId: "register.alreadyjoined", variables: { TeamAccount: (stepValues === null || stepValues === void 0 ? void 0 : stepValues.accountName) || '' } }), content: _jsx(Ditto, { componentId: "register.alreadyjoined.text" }), footer: signInButton }));
        }
    }
    return (_jsx(AppLayoutForm, Object.assign({ notification: _jsx(NotificationContainer, {}) }, { children: getStepComponent(step) })));
}
