import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useEffect, useState } from 'react';
import { classes } from 'classifizer';
import { DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN, GRADE_STEP } from '../../state/slices/leftPanelCondition';
import { RangeSlider } from '../RangeSlider/RangeSlider';
import styles from './GradeSlider.module.css';
const DISABLED_BAR_COLOR = 'rgba(15, 18, 22, 0.05)';
// TODO: Decide how to use unitFormatter here when dynamic scale is implemented
export function GradeSlider({ bar1, bar15, bar2, bar25, bar3, bar35, bar4, bar45, bar5, captions, leftGradeValue, rightGradeValue, onChange, 'data-test': dataTest }) {
    const [leftValue, setLeftValue] = useState(leftGradeValue || DEFAULT_GRADE_MIN);
    const [rightValue, setRightValue] = useState(rightGradeValue || DEFAULT_GRADE_MAX);
    useEffect(() => {
        setLeftValue(leftGradeValue || DEFAULT_GRADE_MIN);
        setRightValue(rightGradeValue || DEFAULT_GRADE_MAX);
    }, [leftGradeValue, rightGradeValue]);
    const barsEnabled = {};
    for (let i = DEFAULT_GRADE_MIN; i <= DEFAULT_GRADE_MAX; i = i + GRADE_STEP) {
        barsEnabled[i.toString()] = leftValue <= i && rightValue >= i;
    }
    function moveTo(value) {
        const leftDiff = Math.abs(leftValue - value);
        const rightDiff = Math.abs(rightValue - value);
        if (((leftDiff <= rightDiff || rightValue === value) &&
            leftValue !== value &&
            value <= rightValue) ||
            (value === DEFAULT_GRADE_MAX && rightValue >= value)) {
            if (onChange) {
                onChange({ min: value, max: rightValue });
            }
            else {
                setLeftValue(value);
            }
        }
        else {
            if (onChange) {
                onChange({ min: leftValue, max: value });
            }
            else {
                setRightValue(value);
            }
        }
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['bars']), "data-test": "left-panel-grade-bars" }, { children: [_jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['1']
                                ? 'rgba(60, 206, 189, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar1.height + '%'
                        }, "data-test": "gradeBar1", onClick: () => {
                            moveTo(1);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar1.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['1.5']
                                ? 'rgba(116, 208, 135, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar15.height + '%'
                        }, "data-test": "gradeBar15", onClick: () => {
                            moveTo(1.5);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar15.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['2']
                                ? 'rgba(160, 209, 91, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar2.height + '%'
                        }, "data-test": "gradeBar2", onClick: () => {
                            moveTo(2);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar2.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['2.5']
                                ? 'rgba(204, 210, 48, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar25.height + '%'
                        }, "data-test": "gradeBar25", onClick: () => {
                            moveTo(2.5);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar25.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['3']
                                ? 'rgba(252, 211, 0, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar3.height + '%'
                        }, "data-test": "gradeBar3", onClick: () => {
                            moveTo(3);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar3.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['3.5']
                                ? 'rgba(254, 173, 17, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar35.height + '%'
                        }, "data-test": "gradeBar35", onClick: () => {
                            moveTo(3.5);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar35.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['4']
                                ? 'rgba(254, 138, 31, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar4.height + '%'
                        }, "data-test": "gradeBar4", onClick: () => {
                            moveTo(4);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar4.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['4.5']
                                ? 'rgba(255, 104, 45, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar45.height + '%'
                        }, "data-test": "gradeBar45", onClick: () => {
                            moveTo(4.5);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar45.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['bar']), style: {
                            backgroundColor: barsEnabled['5']
                                ? 'rgba(255, 62, 62, 1)'
                                : DISABLED_BAR_COLOR,
                            height: bar5.height + '%'
                        }, "data-test": "gradeBar5", onClick: () => {
                            moveTo(5);
                        } }, { children: _jsx("div", Object.assign({ className: classes(styles['tooltip']) }, { children: bar5.tooltip })) })), _jsx("div", Object.assign({ className: classes(styles['legend']), "data-test": "grade-legend" }, { children: captions === null || captions === void 0 ? void 0 : captions.map((caption, index) => (_jsx("div", Object.assign({ className: classes(styles[`step-${index}`]) }, { children: caption }), `caption-${index}`))) }))] })), _jsx("div", Object.assign({ className: classes(styles['slider']), "data-test": dataTest }, { children: _jsx(RangeSlider, { min: 1, max: 5, step: 0.5, captions: [{ label: '>1', value: 1 }, 2, 3, 4, 5], value: {
                        min: leftValue,
                        max: rightValue
                    }, onChange: (value) => {
                        onChange && onChange(value);
                        setLeftValue(value.min);
                        setRightValue(value.max);
                    } }) }))] })));
}
