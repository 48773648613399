// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImHpiIG7kLJttzktYQMG{display:flex;width:18px;height:18px;background:rgba(129,129,129,.1);border-radius:100%}.ImHpiIG7kLJttzktYQMG svg{width:18px;height:18px}.jXa_udyom81nPWBlH2VO{visibility:hidden}.YWTAiHNYpGVTgzSY3piN:hover .eOKkyACsMHpKeN2Fewx_{display:inline-block}.eOKkyACsMHpKeN2Fewx_{display:none;border:none;background:none;color:var(--primary-dark-blue, #222d39);font-weight:600;text-decoration:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/FilterGroupWrapper/FilterGroupWrapper.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,kBAAA,CAGJ,0BACI,UAAA,CACA,WAAA,CAGJ,sBACI,iBAAA,CAGJ,kDACI,oBAAA,CAGJ,sBACI,YAAA,CACA,WAAA,CACA,eAAA,CACA,uCAAA,CACA,eAAA,CACA,yBAAA,CACA,cAAA","sourcesContent":[".arrow {\n    display: flex;\n    width: 18px;\n    height: 18px;\n    background: rgba(129, 129, 129, 0.1);\n    border-radius: 100%;\n}\n\n.arrow svg {\n    width: 18px;\n    height: 18px;\n}\n\n.disabled {\n    visibility: hidden;\n}\n\n.container:hover .edit-button {\n    display: inline-block;\n}\n\n.edit-button {\n    display: none;\n    border: none;\n    background: none;\n    color: var(--primary-dark-blue, #222d39);\n    font-weight: 600;\n    text-decoration: underline;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `ImHpiIG7kLJttzktYQMG`,
	"disabled": `jXa_udyom81nPWBlH2VO`,
	"container": `YWTAiHNYpGVTgzSY3piN`,
	"edit-button": `eOKkyACsMHpKeN2Fewx_`
};
export default ___CSS_LOADER_EXPORT___;
