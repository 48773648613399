export var PLAN_CATEGORY;
(function (PLAN_CATEGORY) {
    PLAN_CATEGORY[PLAN_CATEGORY["shortTerm"] = 1] = "shortTerm";
    PLAN_CATEGORY[PLAN_CATEGORY["longTerm"] = 2] = "longTerm";
    PLAN_CATEGORY[PLAN_CATEGORY["none"] = 3] = "none";
})(PLAN_CATEGORY || (PLAN_CATEGORY = {}));
export var PLAN_PRIORITY_ID;
(function (PLAN_PRIORITY_ID) {
    PLAN_PRIORITY_ID[PLAN_PRIORITY_ID["none"] = 0] = "none";
    PLAN_PRIORITY_ID[PLAN_PRIORITY_ID["lowest"] = 1] = "lowest";
    PLAN_PRIORITY_ID[PLAN_PRIORITY_ID["low"] = 2] = "low";
    PLAN_PRIORITY_ID[PLAN_PRIORITY_ID["medium"] = 3] = "medium";
    PLAN_PRIORITY_ID[PLAN_PRIORITY_ID["high"] = 4] = "high";
    PLAN_PRIORITY_ID[PLAN_PRIORITY_ID["highest"] = 5] = "highest";
})(PLAN_PRIORITY_ID || (PLAN_PRIORITY_ID = {}));
export const PLAN_PRIORITY = {
    highest: 'highest',
    high: 'high',
    medium: 'medium',
    low: 'low',
    lowest: 'lowest',
    none: 'none'
};
export const PLAN_PRIORITY_LIMIT = {
    highest: 100,
    high: 80,
    medium: 60,
    low: 40,
    lowest: 20,
    none: 0
};
export const PLAN_TREATMENT_CATEGORY_ID = {
    preservation: 1,
    rehabilitation: 2,
    reconstruction: 3,
    uncategorized: undefined
};
export var PLAN_PLANNED_FOR_ID;
(function (PLAN_PLANNED_FOR_ID) {
    PLAN_PLANNED_FOR_ID[PLAN_PLANNED_FOR_ID["custom"] = -1] = "custom";
    PLAN_PLANNED_FOR_ID[PLAN_PLANNED_FOR_ID["notspecified"] = 0] = "notspecified";
    PLAN_PLANNED_FOR_ID[PLAN_PLANNED_FOR_ID["overdue"] = 1] = "overdue";
    PLAN_PLANNED_FOR_ID[PLAN_PLANNED_FOR_ID["next3months"] = 2] = "next3months";
    PLAN_PLANNED_FOR_ID[PLAN_PLANNED_FOR_ID["thisyear"] = 3] = "thisyear";
    PLAN_PLANNED_FOR_ID[PLAN_PLANNED_FOR_ID["nextyear"] = 4] = "nextyear";
})(PLAN_PLANNED_FOR_ID || (PLAN_PLANNED_FOR_ID = {}));
export var PLAN_EFFICIENCY_ID;
(function (PLAN_EFFICIENCY_ID) {
    PLAN_EFFICIENCY_ID[PLAN_EFFICIENCY_ID["notspecified"] = 0] = "notspecified";
    PLAN_EFFICIENCY_ID[PLAN_EFFICIENCY_ID["lowest"] = 1] = "lowest";
    PLAN_EFFICIENCY_ID[PLAN_EFFICIENCY_ID["low"] = 2] = "low";
    PLAN_EFFICIENCY_ID[PLAN_EFFICIENCY_ID["medium"] = 3] = "medium";
    PLAN_EFFICIENCY_ID[PLAN_EFFICIENCY_ID["high"] = 4] = "high";
    PLAN_EFFICIENCY_ID[PLAN_EFFICIENCY_ID["highest"] = 5] = "highest";
})(PLAN_EFFICIENCY_ID || (PLAN_EFFICIENCY_ID = {}));
export const PLAN_EFFICIENCY_LIMIT = {
    notspecified: 0,
    lowest: 10,
    low: 20,
    medium: 25,
    high: 30,
    highest: 999999999
};
export const PLAN_REMAINIG_SERVICE_LIFE_LIMIT = {
    positive: 15,
    negative: -15
};
export var PLAN_STATUS;
(function (PLAN_STATUS) {
    PLAN_STATUS[PLAN_STATUS["inPlanning"] = 1] = "inPlanning";
    PLAN_STATUS[PLAN_STATUS["completed"] = 2] = "completed";
    PLAN_STATUS[PLAN_STATUS["contracted"] = 3] = "contracted";
})(PLAN_STATUS || (PLAN_STATUS = {}));
export var PLAN_PROPERTY_ID;
(function (PLAN_PROPERTY_ID) {
    PLAN_PROPERTY_ID[PLAN_PROPERTY_ID["text"] = 1] = "text";
    PLAN_PROPERTY_ID[PLAN_PROPERTY_ID["numeric"] = 2] = "numeric";
    PLAN_PROPERTY_ID[PLAN_PROPERTY_ID["list"] = 3] = "list";
    PLAN_PROPERTY_ID[PLAN_PROPERTY_ID["date"] = 4] = "date";
})(PLAN_PROPERTY_ID || (PLAN_PROPERTY_ID = {}));
export var PLAN_DATE_VARIANT_ID;
(function (PLAN_DATE_VARIANT_ID) {
    PLAN_DATE_VARIANT_ID[PLAN_DATE_VARIANT_ID["day"] = 1] = "day";
    PLAN_DATE_VARIANT_ID[PLAN_DATE_VARIANT_ID["month"] = 2] = "month";
})(PLAN_DATE_VARIANT_ID || (PLAN_DATE_VARIANT_ID = {}));
