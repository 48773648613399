import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import styles from './CategoryBadge.module.css';
export function CategoryBadge({ category, color, backgroundColor, variant }) {
    return (_jsx("div", Object.assign({ className: classes(styles['container'], styles['container__' + variant]), style: {
            color: color,
            backgroundColor: backgroundColor
        }, "data-test": "category-badge" }, { children: _jsx("span", Object.assign({ "data-test": "category-text" }, { children: category })) })));
}
