import { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { LAYERS_PANEL_WIDTH, LEFT_PANEL_WIDTH, PRESET_PANEL_HEIGHT, RIGHT_PANEL_WIDTH } from '../../components/AppLayout/AppLayout';
export const useMapTransition = (leftPanelShouldOpen, rightPanelShouldOpen) => {
    const { main: map } = useMap();
    useEffect(() => {
        if (!map) {
            return;
        }
        const currentPadding = map.getPadding();
        map.easeTo({
            padding: Object.assign(Object.assign({}, currentPadding), { top: PRESET_PANEL_HEIGHT, left: leftPanelShouldOpen
                    ? LAYERS_PANEL_WIDTH + LEFT_PANEL_WIDTH
                    : LAYERS_PANEL_WIDTH, right: rightPanelShouldOpen ? RIGHT_PANEL_WIDTH : 0 })
        });
    }, [leftPanelShouldOpen, map, rightPanelShouldOpen]);
};
