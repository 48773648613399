import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Children } from 'react';
import { classes } from 'classifizer';
import styles from './MapPinAttachment.module.css';
export function MapPinAttachment({ children, id }) {
    const childrenNodes = Children.toArray(children).map((child, index) => (_jsx("div", Object.assign({ className: styles['child'] }, { children: child }), index)));
    if (childrenNodes.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", Object.assign({ id: id, className: classes(styles['container']) }, { children: childrenNodes })));
}
export const MAP_PIN_ATTACHMENT_HOVERED_CLASS = styles['hovered'];
export const MAP_PIN_ATTACHMENT_SELECTED_CLASS = styles['selected'];
export const MAP_PIN_ATTACHMENT_HIDDEN_CLASS = styles['hidden'];
