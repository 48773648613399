export const NOTIFICATION_CLIENT_DATA_KEY = {
    markerId: 'markerId',
    fromUserUuid: 'fromUserUuid'
};
export var NOTIFICATION_TYPE_ID;
(function (NOTIFICATION_TYPE_ID) {
    NOTIFICATION_TYPE_ID[NOTIFICATION_TYPE_ID["assigneeChanged"] = 1] = "assigneeChanged";
    NOTIFICATION_TYPE_ID[NOTIFICATION_TYPE_ID["dueDateOverdue"] = 2] = "dueDateOverdue";
    NOTIFICATION_TYPE_ID[NOTIFICATION_TYPE_ID["statusClosed"] = 3] = "statusClosed";
})(NOTIFICATION_TYPE_ID || (NOTIFICATION_TYPE_ID = {}));
