// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TZBhCsZ68dRM0A141j0L{height:6px}.ffOjMcDnRr0hS8AAhhzp{width:100%;height:1px;background:var(--primary-black);opacity:.05;margin-top:12px;flex-shrink:0}.D7CnnFlT4BYqpsTVc6Kf{height:476px;overflow:hidden;display:flex;flex-direction:column;flex-shrink:0}.P0rgxBX7UXmsBV1Qub4P{display:flex;flex-direction:column;align-items:center;padding-top:12px;padding-bottom:12px;overflow-y:auto;flex-grow:1}.wCG4WVlgZVCJAuUoaWVA{width:100%;height:56px;line-height:130%;flex-shrink:0;border-radius:8px;overflow:hidden;display:flex}`, "",{"version":3,"sources":["webpack://./src/components/LinkedAssetSelect/LinkedAssetSelect.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,eAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,eAAA,CACA,WAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA","sourcesContent":[".search-bottom-space {\n    height: 6px;\n}\n\n.modal-search-divider {\n    width: 100%;\n    height: 1px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-top: 12px;\n    flex-shrink: 0;\n}\n\n.modal-search {\n    height: 476px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    flex-shrink: 0;\n}\n\n.modal-search-results {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    overflow-y: auto;\n    flex-grow: 1;\n}\n\n.search-result {\n    width: 100%;\n    height: 56px;\n    line-height: 130%;\n    flex-shrink: 0;\n    border-radius: 8px;\n    overflow: hidden;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-bottom-space": `TZBhCsZ68dRM0A141j0L`,
	"modal-search-divider": `ffOjMcDnRr0hS8AAhhzp`,
	"modal-search": `D7CnnFlT4BYqpsTVc6Kf`,
	"modal-search-results": `P0rgxBX7UXmsBV1Qub4P`,
	"search-result": `wCG4WVlgZVCJAuUoaWVA`
};
export default ___CSS_LOADER_EXPORT___;
