import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { NoteCreatorBlack, NoteCreatorBlue, NoteCreatorGreen, NoteCreatorPurple, NoteCreatorRed, NoteCreatorYellow } from '../../components/icons';
export function getNoteIcon(color) {
    switch (color) {
        case 'black':
            return _jsx(NoteCreatorBlack, {});
        case 'yellow':
            return _jsx(NoteCreatorYellow, {});
        case 'red':
            return _jsx(NoteCreatorRed, {});
        case 'green':
            return _jsx(NoteCreatorGreen, {});
        case 'blue':
            return _jsx(NoteCreatorBlue, {});
        case 'purple':
        default:
            return _jsx(NoteCreatorPurple, {});
    }
}
