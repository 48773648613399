import { FEATURE_STATE_HIGHLIGHT, FEATURE_STATE_HOVER, FEATURE_STATE_SELECTED } from '../featureStates';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM, MIN_ZOOM } from '../settings';
const INSPECTIONS_LAYER_ID = `${LAYER_PREFIX}-inspections`;
const INSPECTIONS_SELECTED_LAYER_ID = `${LAYER_PREFIX}-inspections-selected`;
const LINE_WIDTH = {
    defaultMin: 2,
    default: 8,
    hoverMin: 4,
    hover: 16,
    highlightMin: 4,
    highlight: 16,
    selectedMin: 4,
    selected: 16,
    outlineMin: 8,
    outline: 32,
    filteredOut: 4
};
const SELECTED_LINE_STROKE_COLOR = '#1556EB';
export const inspectionsLayer = {
    id: INSPECTIONS_LAYER_ID,
    type: 'line',
    paint: {
        'line-color': [
            'case',
            ['boolean', ['get', 'arrow'], false],
            ['get', 'arrowColor'],
            ['get', 'color']
        ],
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            MIN_ZOOM,
            1,
            DEFAULT_ZOOM,
            [
                'case',
                ['boolean', ['feature-state', FEATURE_STATE_HOVER], false],
                LINE_WIDTH.hoverMin,
                ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
                LINE_WIDTH.highlightMin,
                ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
                LINE_WIDTH.selectedMin,
                LINE_WIDTH.defaultMin
            ],
            MAX_ZOOM,
            [
                'case',
                ['boolean', ['get', 'arrow'], false],
                3,
                [
                    'case',
                    ['boolean', ['feature-state', FEATURE_STATE_HOVER], false],
                    LINE_WIDTH.hover,
                    ['boolean', ['feature-state', FEATURE_STATE_HIGHLIGHT], false],
                    LINE_WIDTH.highlight,
                    ['boolean', ['feature-state', FEATURE_STATE_SELECTED], false],
                    LINE_WIDTH.selected,
                    LINE_WIDTH.default
                ]
            ]
        ]
    },
    layout: {
        'line-cap': 'round',
        'line-join': 'miter'
    }
};
export const inspectionsSelectedLayer = {
    id: INSPECTIONS_SELECTED_LAYER_ID,
    type: 'line',
    paint: {
        'line-color': SELECTED_LINE_STROKE_COLOR,
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            LINE_WIDTH.outlineMin,
            MAX_ZOOM,
            LINE_WIDTH.outline
        ]
    },
    layout: {
        'line-cap': 'round',
        'line-join': 'miter'
    }
};
