// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dgjbXr7Rl_o4vnwAr5zb{height:100%;width:100%;opacity:.6;display:flex;align-items:center;justify-content:center;min-height:100px}`, "",{"version":3,"sources":["webpack://./src/components/Searchbar/NoMatch/NoMatch.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,UAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA","sourcesContent":[".container {\n    height: 100%;\n    width: 100%;\n    opacity: 0.6;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dgjbXr7Rl_o4vnwAr5zb`
};
export default ___CSS_LOADER_EXPORT___;
