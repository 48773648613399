// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cZ9K8Og6eNls8UTTMnRg{padding:16px 12px;width:50%}.AIt2Fa5xH4oGwUbtroin{width:100%}.SobJXPeIvx0wHCFamIxw{width:33%}.lD86x4RkqBlFV4h6oltM{display:flex;align-items:center;margin-bottom:8px;font-size:14px;font-weight:400;line-height:130%;opacity:.6}.rtN_B0d2bKrfkfklenw7{display:flex;align-items:center;gap:4px;font-size:14px;font-weight:600;line-height:normal;hyphens:auto;overflow-wrap:break-word;word-wrap:break-word}@media print{.cZ9K8Og6eNls8UTTMnRg{padding-top:5px}}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,SAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,SAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,YAAA,CACA,wBAAA,CACA,oBAAA,CAGJ,aACI,sBACI,eAAA,CAAA","sourcesContent":[".container {\n    padding: 16px 12px;\n    width: 50%;\n}\n\n.container--fullwidth {\n    width: 100%;\n}\n\n.container--print {\n    width: 33%;\n}\n\n.headline {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 130%;\n    opacity: 0.6;\n}\n\n.content {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: normal;\n    hyphens: auto;\n    overflow-wrap: break-word;\n    word-wrap: break-word;\n}\n\n@media print {\n    .container {\n        padding-top: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cZ9K8Og6eNls8UTTMnRg`,
	"container--fullwidth": `AIt2Fa5xH4oGwUbtroin`,
	"container--print": `SobJXPeIvx0wHCFamIxw`,
	"headline": `lD86x4RkqBlFV4h6oltM`,
	"content": `rtN_B0d2bKrfkfklenw7`
};
export default ___CSS_LOADER_EXPORT___;
