import { MAP_NOTE_ICON_HORIZONTAL_OFFSET, MAP_NOTE_ICON_VERTICAL_OFFSET } from '../../consts/map';
import { NOTE_COLORS } from '../../consts/note';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
export const NOTE_LAYER_ID = {
    default: `${LAYER_PREFIX}-note`,
    hover: `${LAYER_PREFIX}-note-hover`,
    highlight: `${LAYER_PREFIX}-note-highlight`,
    selected: `${LAYER_PREFIX}-note-selected`
};
const NOTE_IMAGE_NAME_BASE = 'note-default';
export const NOTES_IMAGE_NAMES = NOTE_COLORS.map((color) => `${NOTE_IMAGE_NAME_BASE}-${color}`);
const MIN_SCALE = 0.8;
const MAX_SCALE = 1.0;
export const noteLayer = {
    id: NOTE_LAYER_ID.default,
    type: 'symbol',
    layout: {
        'icon-image': ['concat', NOTE_IMAGE_NAME_BASE, '-', ['get', 'color']],
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': false,
        'icon-ignore-placement': true,
        'icon-anchor': 'center',
        'icon-offset': [MAP_NOTE_ICON_HORIZONTAL_OFFSET, MAP_NOTE_ICON_VERTICAL_OFFSET]
    }
};
