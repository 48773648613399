// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u0S5oS6ZjBIDzBKNlpw3{display:flex;flex-wrap:wrap;padding:4px 8px}.lY2qM7oACL8dREkJQhS3{padding-left:36px}._5huS1i7YoJ0qnSl94nOb{padding:0 12px}.u0S5oS6ZjBIDzBKNlpw3:after{content:"";margin-left:12px;margin-right:12px;height:1px;width:100%;background:var(--primary-black);opacity:.05}.B2oIN77Y2XgNvUIBtlHm:after{height:0}.j8vG4HGer3GOHpBxhiFg{margin:10px 0 0 12px;flex-basis:100%;font-weight:600;font-size:16px}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsClassification/DetailsClassification.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,cAAA,CACA,eAAA,CAGJ,sBACI,iBAAA,CAGJ,uBACI,cAAA,CAGJ,4BACI,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CAGJ,4BACI,QAAA,CAGJ,sBACI,oBAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".container {\n    display: flex;\n    flex-wrap: wrap;\n    padding: 4px 8px;\n}\n\n.container__image-viewer {\n    padding-left: 36px;\n}\n\n.container__ghost {\n    padding: 0 12px;\n}\n\n.container:after {\n    content: '';\n    margin-left: 12px;\n    margin-right: 12px;\n    height: 1px;\n    width: 100%;\n    background: var(--primary-black);\n    opacity: 0.05;\n}\n\n.no-underline:after {\n    height: 0;\n}\n\n.headline {\n    margin: 10px 0 0 12px;\n    flex-basis: 100%;\n    font-weight: 600;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `u0S5oS6ZjBIDzBKNlpw3`,
	"container__image-viewer": `lY2qM7oACL8dREkJQhS3`,
	"container__ghost": `_5huS1i7YoJ0qnSl94nOb`,
	"no-underline": `B2oIN77Y2XgNvUIBtlHm`,
	"headline": `j8vG4HGer3GOHpBxhiFg`
};
export default ___CSS_LOADER_EXPORT___;
