// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BwquEHlJlTYeNr0DCbmA{display:flex;align-items:center;justify-content:center;height:30px;border-radius:6px;font-weight:600;font-size:14px;line-height:16px;color:var(--primary-black);padding:6px 12px;flex-wrap:nowrap;max-width:244px;width:fit-content}.hqc1LZMxnhQbx9Q7FRKC{height:24px;padding:4px;max-width:114px}.HVgVmkilENUJRvKxO1Uq{height:24px;padding:4px;max-width:244px}.BwquEHlJlTYeNr0DCbmA span{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/components/CategoryBadge/CategoryBadge.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CACA,gBAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CAGJ,sBACI,WAAA,CACA,WAAA,CACA,eAAA,CAGJ,sBACI,WAAA,CACA,WAAA,CACA,eAAA,CAGJ,2BACI,eAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 30px;\n    border-radius: 6px;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    color: var(--primary-black);\n    padding: 6px 12px;\n    flex-wrap: nowrap;\n    max-width: 244px;\n    width: fit-content;\n}\n\n.container__list {\n    height: 24px;\n    padding: 4px;\n    max-width: 114px;\n}\n\n.container__ghost {\n    height: 24px;\n    padding: 4px;\n    max-width: 244px;\n}\n\n.container span {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BwquEHlJlTYeNr0DCbmA`,
	"container__list": `hqc1LZMxnhQbx9Q7FRKC`,
	"container__ghost": `HVgVmkilENUJRvKxO1Uq`
};
export default ___CSS_LOADER_EXPORT___;
