import moment from 'moment';
import { getDateFormat, getDateTimeFromTimestamp, parseDateInUTC } from '../../common/utils/time';
import { M_TO_FT } from '../../common/utils/units';
import { useAppSelector } from '../../state/hooks';
import { useDittoWrapper } from '../useDittoWrapper';
/**
 * possible to extend, format number, date and etc.
 */
export function useFormatter() {
    const { teamAccount } = useAppSelector((state) => state.auth);
    return useFormatterNoRedux(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode);
}
/**
 * This function does not access redux store, so you can use it in component stories.
 */
export function useFormatterNoRedux(countryCode) {
    const station = useDittoWrapper({ componentId: 'referencing.station' });
    const noexactaddress = useDittoWrapper({ componentId: 'noexactaddress' });
    const useUSFormat = countryCode === 'US';
    const decimalSeparator = countryCode && ['US', 'GB', 'CW'].includes(countryCode) ? '.' : ',';
    function formatAddress(json, options) {
        var _a;
        function getNotProcessedAddress() {
            var _a;
            if (!json) {
                return undefined;
            }
            if ((_a = json.linearReferencing) === null || _a === void 0 ? void 0 : _a.found) {
                const { name, node, subnode, distance } = json.linearReferencing;
                return `${name}, ${node}, ${subnode}, ${station}: ${distance}m`;
            }
            if (json.geocoding) {
                const { road, streetNumber, postcode, city, state, country } = json.geocoding;
                const leftParts = useUSFormat ? [streetNumber, road] : [road, streetNumber];
                const left = leftParts.filter((p) => !!p).join(' ');
                const rightParts = useUSFormat ? [state, postcode] : [postcode, city];
                const right = rightParts.filter((p) => !!p).join(' ');
                const middle = useUSFormat ? city : undefined;
                if (options === null || options === void 0 ? void 0 : options.isShort) {
                    return left;
                }
                if (options === null || options === void 0 ? void 0 : options.cityAndCountry) {
                    return [right, country].filter((p) => !!p).join(', ');
                }
                return [left, middle, right].filter((p) => !!p).join(', ');
            }
            return undefined;
        }
        const fallback = (options === null || options === void 0 ? void 0 : options.fallback) || noexactaddress;
        return ((_a = getNotProcessedAddress()) === null || _a === void 0 ? void 0 : _a.trim()) || fallback;
    }
    function formatDistance(distance = 0) {
        if (useUSFormat) {
            return `${formatNumber(distance * M_TO_FT, 1)} ft`;
        }
        return `${formatNumber(distance, 1)} m`;
    }
    function formatNumber(number, decimal) {
        const numberFormatter = new Intl.NumberFormat(useUSFormat ? 'en-US' : 'de-DE', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: decimal,
            useGrouping: false
        });
        return numberFormatter.format(number);
    }
    function formatNumberWithUnit(number, decimal, unit) {
        return [formatNumber(number, decimal), unit].join(' ');
    }
    // format: 2024-06-28T00:00:00.000Z, apply offset, used when the absolute date is needed
    function formatDateISO(isoString) {
        if (!isoString) {
            return '/';
        }
        const formattedDate = moment(parseDateInUTC(isoString)).format(getDateFormat(countryCode));
        return formattedDate;
    }
    function formatDate(timestamp) {
        return getDateTimeFromTimestamp(timestamp, countryCode);
    }
    function formatDateWithTZOffset(date) {
        return moment(date).format();
    }
    return {
        formatAddress,
        formatDistance,
        decimalSeparator,
        formatNumber,
        formatNumberWithUnit,
        formatDateISO,
        formatDate,
        formatDateWithTZOffset
    };
}
