import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FormInputText } from '../../../FormInputText/FormInputText';
export function SignTypeSelectField({ label, variant, width = 332, leadingIcon, value, onClick, disabled }) {
    return (_jsx("div", Object.assign({ style: { width }, onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(), onKeyDown: (event) => {
            if (event.key === 'Enter') {
                onClick === null || onClick === void 0 ? void 0 : onClick();
            }
        } }, { children: _jsx(FormInputText, { label: label, variant: variant, width: width, leadingIcon: leadingIcon, value: value, disabled: disabled }) })));
}
