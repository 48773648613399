import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { FormInputText } from '../FormInputText/FormInputText';
import { Eye, EyeClosed } from '../icons';
import styles from './FormInputPassword.module.css';
export function FormInputPassword(props) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const passwordLabel = useDittoWrapper({ componentId: 'useraccount.password' });
    function togglePasswordVisibility() {
        setIsPasswordVisible(!isPasswordVisible);
    }
    return (_jsx(FormInputText, Object.assign({}, props, { label: props.label || passwordLabel, type: isPasswordVisible ? 'text' : 'password', trailingIcon: _jsx("span", Object.assign({ className: styles['icon'], onClick: togglePasswordVisibility }, { children: isPasswordVisible ? _jsx(Eye, {}) : _jsx(EyeClosed, {}) })) })));
}
