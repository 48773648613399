import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchRoleList } from '../state/slices/auth';
export function useRolesData() {
    const { roles: { list: roleList, status } } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (roleList.length === 0) {
            dispatch(fetchRoleList());
        }
    }, [roleList, dispatch]);
    return { roleList, status };
}
