// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RLrAnmAC06QLoiEWyFQY{display:flex;flex-direction:row;align-items:center;justify-content:center;padding:12px;width:100%;height:48px;font-weight:400;font-size:14px;cursor:pointer;border-radius:8px}.RLrAnmAC06QLoiEWyFQY:hover,.unRsX5JcRBqJKoZplxF9{background:rgba(129,129,129,.1)}`, "",{"version":3,"sources":["webpack://./src/components/TimePickerOverlay/PickerItem/PickerItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAGJ,kDAEI,+BAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    padding: 12px;\n    width: 100%;\n    height: 48px;\n    font-weight: 400;\n    font-size: 14px;\n    cursor: pointer;\n    border-radius: 8px;\n}\n\n.container:hover,\n.container__selected {\n    background: rgba(129, 129, 129, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RLrAnmAC06QLoiEWyFQY`,
	"container__selected": `unRsX5JcRBqJKoZplxF9`
};
export default ___CSS_LOADER_EXPORT___;
