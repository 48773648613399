import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Divider } from '../../uikit/Divider/Divider';
import { Loader } from '../Loader/Loader';
import styles from './SegmentDetails.module.css';
export function SegmentDetails({ address, details, averageGrading, isLoading }) {
    if (isLoading) {
        return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles['container-loading']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['loader']) }, { children: _jsx(Loader, {}) })), _jsxs("div", Object.assign({ className: classes(styles['two-columns']) }, { children: [_jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) })] })), _jsx(Divider, { margin: "9px" }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) }), _jsx("div", { className: classes(styles['loading-bar']) })] })));
    }
    else {
        return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [address && (_jsx("div", Object.assign({ className: classes(styles['address']), "data-test": "segment-detail-address" }, { children: address }))), details && _jsx("div", Object.assign({ className: classes(styles['details']) }, { children: details })), averageGrading && (_jsx("div", Object.assign({ className: classes(styles['average-grading']) }, { children: averageGrading })))] })));
    }
}
