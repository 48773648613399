// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t_Fe1LJsiRj6VSOiMdMk{position:relative}.vOR8nYnwhYpLZlhCTGJy{cursor:pointer}.QunEoj8bz6D4g5HFJJi4{display:none}.yn0uO7Q7bSk0DSRAK5V7{display:block}.t_Fe1LJsiRj6VSOiMdMk:hover .QunEoj8bz6D4g5HFJJi4{display:block}.okoGHA3y7rEDiUe1NkcA{position:absolute}.okoGHA3y7rEDiUe1NkcA:not(.v2l0_hHhd2OmTqkWuwxX,.tUWpqExuzoX13JMMp38K){top:100%;left:50%}.okoGHA3y7rEDiUe1NkcA.lW4oRhcL5nyack6ynpr4{top:calc(100% + 10px)}.okoGHA3y7rEDiUe1NkcA.RB0V7C2c2PHjwgIRh_b4{top:calc(-100% - 10px)}.okoGHA3y7rEDiUe1NkcA.v2l0_hHhd2OmTqkWuwxX{left:calc(100% + 10px)}.okoGHA3y7rEDiUe1NkcA.tUWpqExuzoX13JMMp38K>div>span{right:calc(100% + 10px)}`, "",{"version":3,"sources":["webpack://./src/components/TooltipWrapper/TooltipWrapper.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,cAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,aAAA,CAGJ,kDACI,aAAA,CAGJ,sBACI,iBAAA,CAGJ,uEACI,QAAA,CACA,QAAA,CAGJ,2CACI,qBAAA,CAGJ,2CACI,sBAAA,CAGJ,2CACI,sBAAA,CAGJ,oDACI,uBAAA","sourcesContent":[".wrapper {\n    position: relative;\n}\n\n.wrapper__interactive {\n    cursor: pointer;\n}\n\n.anchor-tooltip {\n    display: none;\n}\n\n.anchor-tooltip__fixed {\n    display: block;\n}\n\n.wrapper:hover .anchor-tooltip {\n    display: block;\n}\n\n.tooltip-wrapper {\n    position: absolute;\n}\n\n.tooltip-wrapper:not(.tooltip-wrapper__arrow-left, .tooltip-wrapper__arrow-right) {\n    top: 100%;\n    left: 50%;\n}\n\n.tooltip-wrapper.tooltip-wrapper__arrow-up {\n    top: calc(100% + 10px);\n}\n\n.tooltip-wrapper.tooltip-wrapper__arrow-down {\n    top: calc(-100% - 10px);\n}\n\n.tooltip-wrapper.tooltip-wrapper__arrow-left {\n    left: calc(100% + 10px);\n}\n\n.tooltip-wrapper.tooltip-wrapper__arrow-right > div > span {\n    right: calc(100% + 10px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `t_Fe1LJsiRj6VSOiMdMk`,
	"wrapper__interactive": `vOR8nYnwhYpLZlhCTGJy`,
	"anchor-tooltip": `QunEoj8bz6D4g5HFJJi4`,
	"anchor-tooltip__fixed": `yn0uO7Q7bSk0DSRAK5V7`,
	"tooltip-wrapper": `okoGHA3y7rEDiUe1NkcA`,
	"tooltip-wrapper__arrow-left": `v2l0_hHhd2OmTqkWuwxX`,
	"tooltip-wrapper__arrow-right": `tUWpqExuzoX13JMMp38K`,
	"tooltip-wrapper__arrow-up": `lW4oRhcL5nyack6ynpr4`,
	"tooltip-wrapper__arrow-down": `RB0V7C2c2PHjwgIRh_b4`
};
export default ___CSS_LOADER_EXPORT___;
