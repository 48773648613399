import { jsx as _jsx } from "react/jsx-runtime";
import React, { useId, useRef } from 'react';
import { classes } from 'classifizer';
import { motion } from 'framer-motion';
import { useCaptureFocus } from '../../hooks/useCaptureFocus';
import styles from './ModalOverlay.module.css';
export function ModalOverlay({ children, animate = true, overGallery }) {
    const ref = useRef(null);
    const key = useId();
    const animationProps = animate && {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    };
    useCaptureFocus({ ref });
    return (_jsx(motion.div, Object.assign({ ref: ref }, animationProps, { className: classes(styles['container'], overGallery && styles['over-gallery']), "data-overlay": true, tabIndex: 0 }, { children: children }), key));
}
