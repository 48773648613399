var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Marker, useMap } from 'react-map-gl';
import { distance } from '@turf/turf';
import { LAYER_PATH } from '../../common/layer';
import { ASSETS_SOURCE_ID, MARKERS_SOURCE_ID, markersHighlightedLayer, markersHoveredLayer, markersLayer, markersSelectedLayer } from '../../common/mapbox';
import { assetLayer } from '../../common/mapbox/layers/assetLayer';
import { isDefined, uniqueV2 } from '../../common/utils/array';
import { MAP_PIN_ATTACHMENT_HIDDEN_CLASS, MAP_PIN_ATTACHMENT_HOVERED_CLASS, MAP_PIN_ATTACHMENT_SELECTED_CLASS, MapPinAttachment } from '../../components/MapPinAttachment/MapPinAttachment';
import { MapPinAttachmentPriorities } from '../../components/MapPinAttachmentPriorities/MapPinAttachmentPriorities';
import { useDebounceCallback } from '../../hooks/useDebounceCallback';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { getLinkedMarkers as getLinkedTasks } from '../../state/slices/linkedMarkers';
import { fetchMarkerGeoJSON } from '../../state/slices/mainMap';
/**
 * In this component, to avoid confusion, markers are referred to as tasks,
 * while the words marker or markers etc. are Mapbox GL map markers.
 * The naming is consistent within the component for sanity.
 */
export function AssetLinkedTasksAttachmentContainer() {
    const { main: map } = useMap();
    const dispatch = useAppDispatch();
    const { currentAction } = useAppSelector((state) => state.app);
    const { layerList } = useAppSelector((state) => state.layer);
    const { selectedFeature, hoveredFeature } = useAppSelector((state) => state.feature);
    const { cachedLinkedMarkers: cachedLinkedTasks, cachedLinkedMarkersInvalidatedAt: cachedLinkedTasksInvalidatedAt } = useAppSelector((state) => state.linkedMarkers);
    const assetsLayer = layerList.find((item) => item.id === LAYER_PATH.asset);
    const tasksLayer = layerList.find((item) => item.id === LAYER_PATH.marker);
    const [markers, setMarkers] = useState(new Map());
    const fetchLinkedTasks = useCallback((ids) => dispatch(getLinkedTasks(ids)).unwrap(), [dispatch]);
    const debouncedOnAssetDataListener = useDebounceCallback(() => onAssetData(map, markers, setMarkers, fetchLinkedTasks), DEBOUNCE_ON_MAP_DATA_EVENT_MS, [map, markers, setMarkers, fetchLinkedTasks]);
    const debouncedOnTasksDataListener = useDebounceCallback(() => onTasksData(map), DEBOUNCE_ON_MAP_DATA_EVENT_MS, [map]);
    useEffect(() => {
        if (currentAction !== 'none') {
            setMarkers(new Map());
        }
    }, [currentAction]);
    useEffect(() => {
        if (!assetsLayer || !assetsLayer.isEnabled) {
            setMarkers(new Map());
        }
    }, [assetsLayer]);
    useEffect(() => {
        if (tasksLayer && tasksLayer.isEnabled) {
            dispatch(fetchMarkerGeoJSON());
        }
    }, [tasksLayer, cachedLinkedTasksInvalidatedAt, dispatch]);
    useEffect(() => {
        if (!map) {
            return;
        }
        debouncedOnTasksDataListener();
    }, [map, assetsLayer, tasksLayer, debouncedOnTasksDataListener]);
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('sourcedata', debouncedOnAssetDataListener);
        return () => {
            map.off('sourcedata', debouncedOnAssetDataListener);
        };
    }, [map, debouncedOnAssetDataListener]);
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('sourcedata', debouncedOnTasksDataListener);
        return () => {
            map.off('sourcedata', debouncedOnTasksDataListener);
        };
    }, [map, debouncedOnTasksDataListener]);
    useEffect(() => {
        const attachments = getAttachments();
        for (const attachment of attachments) {
            removeState(attachment, 'selected');
        }
        if (selectedFeature) {
            if (selectedFeature.type === 'asset') {
                const attachment = getAttachment(selectedFeature.id);
                setState(attachment, 'selected');
                removeState(attachment, 'hidden');
            }
            else if (selectedFeature.type === 'marker') {
                const feature = Object.values(cachedLinkedTasks)
                    .flatMap((tasks) => tasks)
                    .find((task) => task.id === selectedFeature.id &&
                    typeof task.manualInventoryId !== 'undefined');
                if (feature === null || feature === void 0 ? void 0 : feature.manualInventoryId) {
                    const attachment = getAttachment(feature.manualInventoryId);
                    setState(attachment, 'selected');
                    removeState(attachment, 'hidden');
                }
            }
        }
    }, [selectedFeature, markers, cachedLinkedTasks]);
    useEffect(() => {
        if (hoveredFeature) {
            const attachment = getAttachment(hoveredFeature.feature.id);
            setState(attachment, 'hovered');
        }
        else {
            const attachments = getAttachments();
            for (const attachment of attachments) {
                removeState(attachment, 'hovered');
            }
        }
    }, [hoveredFeature, markers]);
    return _jsx(_Fragment, { children: Array.from(markers.values()) });
}
function onTasksData(map) {
    if (!map.getSource(MARKERS_SOURCE_ID) || !map.isSourceLoaded(MARKERS_SOURCE_ID)) {
        return;
    }
    if (!map.getLayer(markersLayer.id)) {
        return;
    }
    // We're only interested in the rendered features.
    const [ne, sw] = map.getBounds().toArray();
    const tasks = map.queryRenderedFeatures([map.project(ne), map.project(sw)], {
        layers: [
            markersLayer.id,
            markersHoveredLayer.id,
            markersHighlightedLayer.id,
            markersSelectedLayer.id
        ]
    });
    if (!map.getLayer(assetLayer.id)) {
        tasks.forEach((feature) => {
            map.removeFeatureState({ source: MARKERS_SOURCE_ID, id: feature.id }, 'hidden');
        });
        return;
    }
    const assets = map.queryRenderedFeatures([map.project(ne), map.project(sw)], {
        layers: [assetLayer.id]
    });
    tasks.forEach((feature) => {
        const task = feature;
        const featureLinkedAssetId = task.properties.manualInventoryId;
        if (typeof featureLinkedAssetId !== 'undefined') {
            const asset = assets.find((item) => item.id === featureLinkedAssetId);
            if (asset &&
                distance(asset.geometry.coordinates, task.geometry.coordinates, {
                    units: 'meters'
                }) < HIDE_LINKED_TASKS_WITHIN_DISTANCE) {
                map.setFeatureState({ source: MARKERS_SOURCE_ID, id: task.id }, { hidden: true });
            }
            else {
                map.setFeatureState({ source: MARKERS_SOURCE_ID, id: task.id }, { hidden: false });
            }
        }
        else {
            map.setFeatureState({ source: MARKERS_SOURCE_ID, id: task.id }, { hidden: false });
        }
    });
}
function onAssetData(map, markers, setMarkers, fetchLinkedTasks) {
    if (!map.getSource(ASSETS_SOURCE_ID) || !map.isSourceLoaded(ASSETS_SOURCE_ID)) {
        return;
    }
    const markersMap = new Map(markers.entries());
    // We're drawing attachments on the assets
    // that are visible in the viewport only, so we need
    // north-east and south-west points which we project
    // to pixels in the viewport.
    const [ne, sw] = map.getBounds().toArray();
    const assets = map.queryRenderedFeatures([map.project(ne), map.project(sw)], {
        layers: [assetLayer.id]
    });
    if (assets.length === 0) {
        markersMap.clear();
    }
    const linkedTasksMap = new Map(assets
        .filter((item) => { var _a, _b; return ((_a = item.properties) === null || _a === void 0 ? void 0 : _a.linkedMarkersIds) && ((_b = item.properties) === null || _b === void 0 ? void 0 : _b.linkedMarkersIds) !== '[]'; })
        .map((item) => {
        var _a;
        return [
            item.id,
            JSON.parse((_a = item.properties) === null || _a === void 0 ? void 0 : _a.linkedMarkersIds)
        ];
    }));
    return fetchTasks(assets, linkedTasksMap, markersMap, setMarkers, fetchLinkedTasks);
}
function fetchTasks(assets, linkedTasksMap, markersMap, setMarkers, fetchLinkedTasks) {
    return __awaiter(this, void 0, void 0, function* () {
        const taskIds = uniqueV2(Array.from(linkedTasksMap.values()).flatMap((item) => item));
        const tasks = new Map(yield fetchLinkedTasks(taskIds));
        const assetIds = assets.map((feature) => feature.id).filter(isDefined);
        for (const assetId of markersMap.keys()) {
            if (!assetIds.includes(assetId)) {
                markersMap.delete(assetId);
            }
        }
        assets.forEach((feature) => {
            var _a;
            const asset = feature;
            const coords = asset.geometry.coordinates;
            const linkedTasks = (_a = tasks
                .get(asset.id)) === null || _a === void 0 ? void 0 : _a.slice(0, 3).map((task) => ({ id: task.id, priority: task.priorityTypeId }));
            if (linkedTasks && linkedTasks.length > 0) {
                const marker = createMarker(asset.id, coords, linkedTasks);
                markersMap.set(asset.id, marker);
            }
            else {
                markersMap.delete(asset.id);
            }
        });
        setMarkers(markersMap);
    });
}
function createMarker(id, coordinates, tasks) {
    return (_jsx(MemoizedMarker, { id: id, coordinates: coordinates, tasks: tasks }, attachmentId(id)));
}
function attachmentId(id = '') {
    return `asset-attachment-${id}`;
}
function getAttachments() {
    const main = document.querySelector('div[id=main]');
    return (main === null || main === void 0 ? void 0 : main.querySelectorAll(`div[id^=${attachmentId()}]`)) || [];
}
function getAttachment(id) {
    var _a;
    const main = document.querySelector('div[id=main]');
    return (_a = main === null || main === void 0 ? void 0 : main.querySelector(`div[id=${attachmentId(id)}]`)) !== null && _a !== void 0 ? _a : null;
}
function removeState(attachment, state) {
    attachment === null || attachment === void 0 ? void 0 : attachment.classList.remove(AttachmentStates[state]);
}
function setState(attachment, state) {
    attachment === null || attachment === void 0 ? void 0 : attachment.classList.add(AttachmentStates[state]);
}
const ASSET_ATTACHMENT_X_OFFSET = 0;
const ASSET_ATTACHMENT_Y_OFFSET = -16;
const ASSET_ATTACHMENT_OFFSET = [
    ASSET_ATTACHMENT_X_OFFSET,
    ASSET_ATTACHMENT_Y_OFFSET
];
const HIDE_LINKED_TASKS_WITHIN_DISTANCE = 5.0;
const DEBOUNCE_ON_MAP_DATA_EVENT_MS = 100;
const AttachmentStates = {
    hovered: MAP_PIN_ATTACHMENT_HOVERED_CLASS,
    selected: MAP_PIN_ATTACHMENT_SELECTED_CLASS,
    hidden: MAP_PIN_ATTACHMENT_HIDDEN_CLASS
};
const MemoizedMarker = memo(function MemoizedMarker({ id, coordinates, tasks }) {
    return (_jsx(Marker, Object.assign({ longitude: coordinates[0], latitude: coordinates[1], anchor: "top-left", pitchAlignment: "viewport", offset: ASSET_ATTACHMENT_OFFSET }, { children: _jsx(MapPinAttachment, Object.assign({ id: attachmentId(id) }, { children: _jsx(MapPinAttachmentPriorities, { tasks: tasks }) })) })));
}, (prevProps, nextProps) => prevProps.id === nextProps.id &&
    prevProps.coordinates[0] === nextProps.coordinates[0] &&
    prevProps.coordinates[1] === nextProps.coordinates[1] &&
    prevProps.tasks.length === nextProps.tasks.length &&
    prevProps.tasks.every((item, idx) => item.id === nextProps.tasks[idx].id &&
        item.priority === nextProps.tasks[idx].priority));
