// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z6jv_alGw2c0yoj4QAXO{display:flex;padding:8px;border-radius:4px;background:var(--status-error);color:var(--primary-white);font-size:12px;line-height:18px;white-space:normal}`, "",{"version":3,"sources":["webpack://./src/components/BudgetTooltip/BudgetTooltipError/BudgetTooltipError.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,WAAA,CACA,iBAAA,CACA,8BAAA,CACA,0BAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".container {\n    display: flex;\n    padding: 8px;\n    border-radius: 4px;\n    background: var(--status-error);\n    color: var(--primary-white);\n    font-size: 12px;\n    line-height: 18px; /* 150% */\n    white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Z6jv_alGw2c0yoj4QAXO`
};
export default ___CSS_LOADER_EXPORT___;
