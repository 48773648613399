// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rMrlOgCxJTHHRjyEh3Fa{position:sticky;bottom:-2px;left:0;z-index:10;width:100%;height:56px;padding:0 4px}.rMrlOgCxJTHHRjyEh3Fa:after{content:"";position:absolute;top:-2px;left:0;width:100%;height:100%;min-width:calc(100vw - 72px);border-top:2px solid var(--root-grey-9);background:var(--primary-white)}.rHrwNztM_jOxbUKEoZdg:after{min-width:calc(100vw - 72px - 356px)}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTableFooter/DataGridTableFooter.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,WAAA,CACA,MAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CAGJ,4BACI,UAAA,CACA,iBAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,4BAAA,CACA,uCAAA,CACA,+BAAA,CAGJ,4BACI,oCAAA","sourcesContent":[".container {\n    position: sticky;\n    bottom: -2px;\n    left: 0;\n    z-index: 10;\n    width: 100%;\n    height: 56px;\n    padding: 0 4px;\n}\n\n.container:after {\n    content: '';\n    position: absolute;\n    top: -2px;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    min-width: calc(100vw - 72px);\n    border-top: 2px solid var(--root-grey-9);\n    background: var(--primary-white);\n}\n\n.container--right-panel-open:after {\n    min-width: calc(100vw - 72px - 356px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rMrlOgCxJTHHRjyEh3Fa`,
	"container--right-panel-open": `rHrwNztM_jOxbUKEoZdg`
};
export default ___CSS_LOADER_EXPORT___;
