import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { MarkerPriorityIcon } from '../../../../common/convert';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { IconHover } from '../../../../uikit/IconHover/IconHover';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Recurring } from '../../../icons';
import styles from './AssignedTasks.module.css';
export function AssignedTasks({ tasks, onTaskClick }) {
    const noTitle = useDittoWrapper({ componentId: 'tasktitle.notitle' });
    const more = tasks.length > 3 && (_jsxs("span", Object.assign({ className: styles['item-more'] }, { children: ["+", tasks.length - 3] })));
    const recurring = tasks.some((item) => item.isRecurring) && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles['recurring-divider'] }), _jsx("div", Object.assign({ className: styles['item-icon'] }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Recurring, {}) })) }))] }));
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [tasks.slice(0, 3).map((task) => (_jsx("div", Object.assign({ className: styles['item'], onClick: (event) => {
                    event.stopPropagation();
                    onTaskClick === null || onTaskClick === void 0 ? void 0 : onTaskClick(task.id);
                } }, { children: _jsx(IconHover, { iconSize: 16, icon: _jsx("div", Object.assign({ className: classes(styles['item-icon'], styles['item-icon-priority']) }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(MarkerPriorityIcon, { value: task.priorityTypeId }) })) })), text: _jsx("div", Object.assign({ className: styles['item-title'] }, { children: task.title || noTitle })), clickable: true }) }), task.id))), more, recurring] })));
}
