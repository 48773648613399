var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SIGN_INVENTORY_SIGNS_TO_SHOW } from '../common/consts/trafficSign';
import { streetartClient } from '../openapi';
export function getSortParams(id) {
    switch (id) {
        case 'date-asc':
            return { sortBy: 'asset_created_at', sortOrder: 'asc' };
        case 'date-desc':
            return { sortBy: 'asset_created_at', sortOrder: 'desc' };
        case 'street-asc':
            return { sortBy: 'street_name', sortOrder: 'asc' };
        case 'street-desc':
            return { sortBy: 'street_name', sortOrder: 'desc' };
        default:
            console.error('unknown sign sort id', id);
    }
}
export function getSignInventoryFilterParams(filter) {
    return {
        signDamageTypes: filter.damageTypeIds.length === 0 ? SIGN_INVENTORY_SIGNS_TO_SHOW : filter.damageTypeIds,
        signTypeIds: filter.signTypeIds.length === 0 ? undefined : filter.signTypeIds,
        signStatuses: filter.signStatuses.length === 0 ? undefined : filter.signStatuses,
        filesAttached: filter.filesAttached,
        filesNotAttached: filter.filesNotAttached,
        signProperties: filter.properties
    };
}
export function getSignsInventoryList(body) {
    return streetartClient.streetartService.streetartServiceTrafficSignInventoryList(body);
}
export function getSignInventoryDamageTypes(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryTypes(body);
    });
}
export function getSignInventoryStatuses(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryStatusList(body);
    });
}
export function getSignInventoryPropertySettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceSignGetPropertySettings(body);
    });
}
export function getSignInventoryInfoById(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryInfoById(body);
    });
}
export function getSignInventoryInfoByIdList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryInfoByIdList(body);
    });
}
export function exportByEmail(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryExportByEmail(body);
    });
}
export function getSignTree(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignsTree(body);
    });
}
export function uploadSignInventoryFiles(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryUploadFiles(body);
    });
}
export function getSignInventoryFoldersList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryFoldersList(body);
    });
}
export function createSignInventoryFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryFoldersCreate(body);
    });
}
export function deleteSignInventoryFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryFoldersDelete(body);
    });
}
export function renameSignInventoryFolder(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryFoldersRename(body);
    });
}
export function rearrangeSignInventoryFolders(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryFoldersRearrange(body);
    });
}
export function signInventoryConfirm(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignConfirm(body);
    });
}
export function signInventoryDelete(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignDelete(body);
    });
}
export function createSignInventory(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignCreate(body);
    });
}
export function updateSignCondition(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignUpdateDamageClass(body);
    });
}
export function updateSignAddress(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignUpdateAddress(body);
    });
}
export function updateSignType(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignUpdateType(body);
    });
}
export function updateSignOrientation(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventorySignUpdateOrientation(body);
    });
}
export function signInventorySavePropertySettings(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceSignSavePropertySettings(body);
    });
}
export function updateSignInventoryPropertyValue(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceSignPropertyValueUpdate(body);
    });
}
export function createSignInventoryPropertyValue(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceSignPropertyValueCreate(body);
    });
}
export function deleteSignInventoryFile(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryFileDelete(body);
    });
}
