import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { PRIVILEGE_LEVEL } from '../../../../common/consts/user';
import { DetailsInfo } from '../../../DetailsPanel/DetailsInfo/DetailsInfo';
import { FormInputCurrentSelectedValue } from '../../../FormInputSelect/FormInputSelect';
import { FormInputSelect } from '../../../FormInputSelect/FormInputSelect';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Check, External } from '../../../icons';
import { SelectboxEntry } from '../../../SelectboxEntry/SelectboxEntry';
import styles from './CoWorkerRoleSelect.module.css';
export function CoWorkerRoleSelect({ className, disabled, list, onChange, onRoleAndPermissionsClick, required, userIsAdmin = false, 
// showRemoveAccessItem = false, // temporarly deleted
variant = 'small', value }) {
    var _a;
    return (_jsx(FormInputSelect, { "data-test": "add-coworker-role-select", className: className, disabled: disabled, align: variant == 'small' ? 'right' : 'left', width: "100%", label: _jsx(Ditto, { componentId: "coworkermanagement.role" }), menuItems: [
            _jsx(DetailsInfo, { className: styles['info'], linkText: _jsxs("span", Object.assign({ className: styles['info-link'] }, { children: [_jsx(Ditto, { componentId: "coworkermanagement.seerolesandpermissions" }), _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(External, {}) }))] })), linkOnClick: onRoleAndPermissionsClick, content: '' }, "role-info"),
            ...list
                .filter((role) => (!userIsAdmin ? role.privilegeLevel < PRIVILEGE_LEVEL.admin : true) &&
                role.id <= 3 // to avoid display "disabled" role in select field
            )
                .map((role) => (_jsx(SelectboxEntry, { "data-test": "role-item", text: _jsx(Ditto, { componentId: `role.${role.name}` }), secondaryText: _jsx(Ditto, { componentId: `role.${role.name}info` }), onClick: () => onChange && onChange(role.id), trailingIcon: role.id === value ? _jsx(Check, {}) : undefined }, `role-${role.name}`)))
            // temporarly deleted
            // ...(userIsAdmin && showRemoveAccessItem
            //     ? [
            //           <Divider key="divider" />,
            //           <SelectboxEntry
            //               key="removeAccess"
            //               color="red"
            //               text={<Ditto componentId="coworkermanagement.removeaccess" />}
            //               onClick={() => onChange && onChange(undefined)}
            //           />
            //       ]
            //     : [])
        ], menuWidth: 300, required: required, size: variant, value: value ? (_jsx(FormInputCurrentSelectedValue, { text: _jsx(Ditto, { componentId: `role.${(_a = list.find((role) => role.id === value)) === null || _a === void 0 ? void 0 : _a.name}` }) })) : undefined }));
}
