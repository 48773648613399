import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Close } from '../../icons';
import { InfoBox } from '../../InfoBox/InfoBox';
import { Loader } from '../../Loader/Loader';
import { Modal } from '../../Modal/Modal';
import { BulkEditContentItem } from './BulkEditContentItem/BulkEditContentItem';
import styles from './BulkEditModal.module.css';
export function BulkEditModal({ className, isChanged, content, loading, onCloseClick, onSubmitClick, onDeleteClick }) {
    const [menuOpen, setMenuOpen] = useState(content.map(() => false));
    function setIsOpen(index, open) {
        const array = content.map(() => false);
        array[index] = open;
        setMenuOpen(array);
    }
    return (_jsx(Modal, { className: classes(styles['container'], className), "data-test": "bulk-edit-modal", title: _jsx(Ditto, { componentId: "bulkeditmodal.propertyeditor" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: () => onCloseClick && onCloseClick() }), content: _jsxs(_Fragment, { children: [loading && (_jsx("div", Object.assign({ className: styles['loading'] }, { children: _jsx(Loader, {}) }))), _jsx(InfoBox, { content: _jsx(Ditto, { componentId: "bulkeditmodal.propertyeditor.info" }) }), _jsx("div", { className: styles['space'] }), content.map((item, index) => (_jsx(BulkEditContentItem, { menuOpen: menuOpen[index], setMenuOpen: (open) => setIsOpen(index, open), height: item.height, position: item.position, content: item, "data-test": `task-select-${item.key}`, noMenuPadding: item.noMenuPadding }, item.key)))] }), footer: _jsxs(_Fragment, { children: [onDeleteClick ? (_jsx(Button, Object.assign({ variant: "modal-action", color: "red", onClick: () => onDeleteClick() }, { children: _jsx(Ditto, { componentId: "bulkeditmodal.deleteselected" }) }))) : (_jsx("div", {})), _jsxs("div", Object.assign({ className: styles['gap'] }, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: () => onCloseClick && onCloseClick(), "data-test": "cancel-btn" }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })), _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", disabled: !isChanged, onClick: () => onSubmitClick && onSubmitClick(), "data-test": "bulk-done-btn" }, { children: _jsx(Ditto, { componentId: "done" }) }))] }))] }), footerAlign: "space-between" }));
}
