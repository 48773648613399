/* eslint-disable @typescript-eslint/no-magic-numbers */
export const M_TO_FT = 3.280839895;
export const M2_TO_FT2 = 10.763914692;
export const KM_TO_MILES = 0.62137119;
export const KG_TO_LB = 2.20462262;
export const UnitTypesIds = {
    weight: [1, 8],
    length: [2, 3, 9, 10, 15],
    area: [4, 5, 11, 12, 16, 17],
    currency: [7, 14] // €, $
};
const SEPARATOR_REGEX = /(\.|,)(?=[^(.|,)]*$)/;
const COUNTRY_UNITS = {
    default: {
        currency: {
            name: '€',
            factor: 1
        },
        area: {
            name: 'm²',
            factor: 1
        },
        largeLength: {
            name: 'km',
            factor: 1
        },
        length: {
            name: 'm',
            factor: 1
        },
        weight: {
            name: 'kg',
            factor: 1
        },
        lifeExtensionKm: {
            name: '',
            factor: 1
        }
    },
    ['US']: {
        currency: {
            name: '$',
            factor: 1,
            before: true
        },
        area: {
            name: 'ft²',
            factor: M2_TO_FT2
        },
        largeLength: {
            name: 'mi',
            factor: KM_TO_MILES
        },
        length: {
            name: 'ft',
            factor: M_TO_FT
        },
        weight: {
            name: 'lb',
            factor: KG_TO_LB
        },
        lifeExtensionKm: {
            name: '',
            factor: KM_TO_MILES
        }
    },
    ['CH']: {
        currency: {
            name: 'CHF',
            factor: 1,
            before: true
        },
        area: {
            name: 'm²',
            factor: 1
        },
        largeLength: {
            name: 'km',
            factor: 1
        },
        length: {
            name: 'm',
            factor: 1
        },
        weight: {
            name: 'kg',
            factor: 1
        },
        lifeExtensionKm: {
            name: '',
            factor: 1
        }
    }
};
export const COUNTRY_DECIMAL_SEPARATORS = {
    default: ',',
    ['US']: '.',
    ['GB']: '.',
    ['CW']: '.'
};
const STANDARD_DECIMAL_SEPARATOR = '.';
const COUNTRY_THOUSAND_SEPARATORS = {
    default: '.',
    ['US']: ',',
    ['GB']: ',',
    ['CW']: ','
};
export function getCurrency(countryCode) {
    var _a, _b;
    return ((_b = (countryCode && ((_a = COUNTRY_UNITS[countryCode]) === null || _a === void 0 ? void 0 : _a.currency.name))) !== null && _b !== void 0 ? _b : COUNTRY_UNITS.default.currency.name);
}
export function formatUnit(countryCode = 'default', { type, value, decimals, spacing = true, factor: customFactor, unit: customUnit, trimTrailingZeros }) {
    var _a, _b, _c, _d;
    const countryUnits = (_a = COUNTRY_UNITS[countryCode]) !== null && _a !== void 0 ? _a : COUNTRY_UNITS.default;
    const unit = type
        ? countryUnits[type]
        : { name: customUnit, factor: customFactor, before: false };
    const decimalSeparator = (_b = COUNTRY_DECIMAL_SEPARATORS[countryCode]) !== null && _b !== void 0 ? _b : COUNTRY_DECIMAL_SEPARATORS.default;
    const thousandSeparator = (_c = COUNTRY_THOUSAND_SEPARATORS[countryCode]) !== null && _c !== void 0 ? _c : COUNTRY_THOUSAND_SEPARATORS.default;
    const unitName = customUnit !== null && customUnit !== void 0 ? customUnit : unit.name;
    const factorValue = (_d = customFactor !== null && customFactor !== void 0 ? customFactor : unit.factor) !== null && _d !== void 0 ? _d : 1;
    if (unitName === undefined) {
        spacing = false;
    }
    if (value === undefined) {
        return {
            formattedText: '',
            formattedValue: '',
            unit: unitName,
            displayValue: '',
            value: 0,
            decimals,
            decimalSeparator,
            thousandSeparator
        };
    }
    const convertedValue = value * factorValue;
    let displayValue = '';
    if (decimals !== undefined) {
        displayValue = convertedValue.toFixed(decimals);
    }
    else {
        switch (type) {
            case 'weight':
            case 'currency':
            case 'length':
            case 'area':
                displayValue = convertedValue.toFixed(2);
                break;
            case 'largeLength':
                displayValue = Math.round(convertedValue).toString();
                break;
            default:
                displayValue = convertedValue.toString();
                break;
        }
    }
    displayValue = displayValue.replace(SEPARATOR_REGEX, decimalSeparator);
    let formattedValue = displayValue;
    if (trimTrailingZeros) {
        formattedValue = formattedValue.replace(`${decimalSeparator}00`, '');
    }
    if (decimalSeparator !== STANDARD_DECIMAL_SEPARATOR) {
        formattedValue = formattedValue.replace(STANDARD_DECIMAL_SEPARATOR, decimalSeparator);
    }
    const parts = formattedValue.split(decimalSeparator);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    formattedValue = parts.join(decimalSeparator);
    const order = unit.before ? [unitName, formattedValue] : [formattedValue, unitName];
    return {
        formattedText: order.join(spacing ? ' ' : ''),
        formattedValue: formattedValue,
        unit: unitName,
        displayValue,
        value: convertedValue,
        decimals,
        decimalSeparator,
        thousandSeparator
    };
}
// NOTE: Converts input given in a given unit (e.g. ft) into the standard unit (e.g. m)
export function convertUnitInput(type, countryCode = 'default', input) {
    var _a;
    if (typeof input !== 'string') {
        return NaN;
    }
    const countryUnits = (_a = COUNTRY_UNITS[countryCode]) !== null && _a !== void 0 ? _a : COUNTRY_UNITS.default;
    const unit = countryUnits[type];
    if (unit.factor === 0) {
        return 0;
    }
    const cleaned = input.replace(unit.name, '').trim();
    const parsed = parseDecimalInput(countryCode, cleaned);
    if (parsed === 0) {
        return 0;
    }
    return (1 / unit.factor) * parsed;
}
export function parseDecimalInput(countryCode = 'default', input) {
    var _a, _b, _c, _d;
    if (input === undefined || input.length === 0) {
        return 0;
    }
    const thousandSeparator = (_a = COUNTRY_THOUSAND_SEPARATORS[countryCode]) !== null && _a !== void 0 ? _a : COUNTRY_THOUSAND_SEPARATORS.default;
    const decimalSeparator = (_b = COUNTRY_DECIMAL_SEPARATORS[countryCode]) !== null && _b !== void 0 ? _b : COUNTRY_DECIMAL_SEPARATORS.default;
    let standardizedInput = input;
    // get the last occurrence of coma or dot, depending what user typed
    const separator = (_d = (_c = standardizedInput.match(SEPARATOR_REGEX)) === null || _c === void 0 ? void 0 : _c[0]) !== null && _d !== void 0 ? _d : decimalSeparator;
    const parts = standardizedInput.split(separator);
    parts[0] = parts[0].replaceAll(thousandSeparator, '');
    standardizedInput = parts.join('.');
    return parseFloat(standardizedInput);
}
export function getUnitTypeById(id) {
    if (UnitTypesIds.weight.includes(id))
        return 'weight';
    if (UnitTypesIds.length.includes(id))
        return 'length';
    if (UnitTypesIds.area.includes(id))
        return 'area';
    if (UnitTypesIds.currency.includes(id))
        return 'currency';
    return 'length';
}
