import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { FormInputSelect } from '../../../FormInputSelect/FormInputSelect';
export function CategorySelectField({ isDark = false, width = 332, value, variant, onClick }) {
    return (_jsx("div", Object.assign({ style: { width }, onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(), onKeyDown: (event) => {
            if (event.key === 'Enter') {
                onClick === null || onClick === void 0 ? void 0 : onClick();
            }
        } }, { children: _jsx(FormInputSelect, { isDark: isDark, label: _jsx(Ditto, { componentId: "marker.category" }), width: width, value: value, "data-test": "task-category", variant: variant, placeholder: _jsx(Ditto, { componentId: "notspecified" }), hideMenu: true, canFloat: true }) })));
}
