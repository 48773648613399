import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ImageInProgress } from '../../../uikit/ImageInProgress/ImageInProgress';
export function GalleryImage({ src }) {
    const [isError, setIsError] = useState(src === undefined);
    function handleError() {
        setIsError(true);
    }
    return isError ? _jsx(ImageInProgress, {}) : _jsx("img", { src: src, onError: handleError });
}
