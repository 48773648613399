import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../uikit/Button/Button';
import { ArrowLeft } from '../icons';
import { Loader } from '../Loader/Loader';
import { ZoomEnabledImage } from './ZoomEnabledImage/ZoomEnabledImage';
import styles from './ImageViewer.module.css';
export function ImageViewer(props) {
    const [isZooming, setIsZooming] = useState(false);
    const handleZoom = (isZooming) => {
        setIsZooming(isZooming);
    };
    const { variant } = props;
    if (variant === 'Loading') {
        return (_jsx("div", Object.assign({ className: classes(styles['container']) }, { children: _jsx("div", Object.assign({ className: styles['loading'] }, { children: _jsx(Loader, { color: "dark" }) })) })));
    }
    const isSingleImage = variant === 'Condition' ||
        variant === 'Object' ||
        variant === 'Sign' ||
        variant === 'SignInventory';
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], isSingleImage && styles['single-image-view']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['big-picture'], isSingleImage && styles['single-image-view'], isZooming && styles['zooming']) }, { children: [isSingleImage &&
                        props.singleImage !== undefined &&
                        props.singleImage.props.src !== undefined && (_jsx("figure", Object.assign({ className: classes(styles['image']) }, { children: _jsx(ZoomEnabledImage, { src: props.singleImage.props.src, onZooming: handleZoom, sign: variant === 'Sign' || variant === 'SignInventory'
                                ? props.sign
                                : undefined }) }))), !isZooming && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classes(styles['top-buttons']) }, { children: [!props.onBackButtonClick && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ color: "white", variant: "modal-action", onClick: props.onDoneClick }, { children: _jsx(Ditto, { componentId: "done" }) })), props.topButtonsCreate && props.topButtonsCreate] })), props.onBackButtonClick && (_jsx(Button, Object.assign({ color: "dark", variant: "modal-action", icon: _jsx(ArrowLeft, {}), onClick: props.onBackButtonClick }, { children: _jsx(Ditto, { componentId: "modal.back" }) })))] })), props.navigation] }))] })), !isZooming && _jsx("div", Object.assign({ className: styles['details'] }, { children: props.details }))] })));
}
