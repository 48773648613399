import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { MAP_TRAFFIC_SIGN_ICON_HORIZONTAL_OFFSET, MAP_TRAFFIC_SIGN_ICON_VERTICAL_OFFSET } from '../common/consts/map';
import { DELAY_DISABLE_DRAG } from '../common/consts/time';
import { SearchResult } from '../components/icons';
import { TrafficSignMarker } from '../components/TrafficSignMarker';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { setCurrentAction } from '../state/slices/app';
import { updateAssetAddressTk } from '../state/slices/assetDetail';
import { clearDraggableFeature, setDraggableFeature } from '../state/slices/mainMap';
import { updateMarkerAddressTk } from '../state/slices/markerDetail';
import { updateSignAddressTk } from '../state/slices/signInventoryDetail';
import { useTilesUpdator } from './mainMap/useTilesUpdator';
export function useDraggableFeature() {
    const dispatch = useAppDispatch();
    const { draggableFeature } = useAppSelector((state) => state.mainMap);
    const { reloadSignInventoryTilesData, reloadAssetTilesData } = useTilesUpdator();
    function enableDrag(value) {
        dispatch(setDraggableFeature(value));
        dispatch(setCurrentAction('feature-drag-drop'));
    }
    function disableDrag() {
        dispatch(clearDraggableFeature());
        dispatch(setCurrentAction('none'));
    }
    function disableDragWithTimeout() {
        setTimeout(() => disableDrag(), DELAY_DISABLE_DRAG);
    }
    const marker = useMemo(() => {
        if (!draggableFeature) {
            return null;
        }
        function handleDragEnd(e) {
            if (!draggableFeature) {
                return;
            }
            //  NOTE: extend this switch statement to update location for other draggableFeature types
            switch (draggableFeature.type) {
                case 'sign-inventory':
                    dispatch(updateSignAddressTk({
                        id: draggableFeature.value.id,
                        latitude: e.lngLat.lat,
                        longitude: e.lngLat.lng
                    })).then(() => {
                        reloadSignInventoryTilesData();
                    });
                    break;
                case 'asset':
                    dispatch(updateAssetAddressTk({
                        id: draggableFeature.value.id,
                        position: e.lngLat
                    })).then(() => {
                        reloadAssetTilesData();
                    });
                    break;
                case 'marker':
                    dispatch(updateMarkerAddressTk({
                        id: draggableFeature.value.id,
                        position: e.lngLat
                    }));
                    break;
                default:
                    break;
            }
        }
        //  NOTE: extend this switch statement with other draggableFeature types
        switch (draggableFeature.type) {
            case 'sign-inventory':
                return (_jsx(Marker, Object.assign({ draggable: true, longitude: draggableFeature.value.offsetPosition[0], latitude: draggableFeature.value.offsetPosition[1], anchor: "center", onDragEnd: handleDragEnd, offset: [
                        MAP_TRAFFIC_SIGN_ICON_HORIZONTAL_OFFSET,
                        MAP_TRAFFIC_SIGN_ICON_VERTICAL_OFFSET
                    ] }, { children: _jsx(TrafficSignMarker, { subSpecifiedClass: draggableFeature.value.tag.subSpecifiedClass, variant: "default" }) })));
            case 'asset':
                return (_jsx(Marker, Object.assign({ draggable: true, longitude: draggableFeature.value.coordinates[0], latitude: draggableFeature.value.coordinates[1], anchor: "center", onDragEnd: handleDragEnd }, { children: _jsx(SearchResult, {}) })));
            case 'marker':
                return (_jsx(Marker, Object.assign({ draggable: true, longitude: draggableFeature.value.point.longitude, latitude: draggableFeature.value.point.latitude, anchor: "bottom", onDragEnd: handleDragEnd }, { children: _jsx(SearchResult, {}) })));
            default:
                return null;
        }
    }, [draggableFeature, dispatch, reloadSignInventoryTilesData, reloadAssetTilesData]);
    return {
        enableDrag,
        disableDrag,
        disableDragWithTimeout,
        marker
    };
}
