import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Ditto } from 'ditto-react';
import { getUniversalLinkForLogin } from '../../../common/link';
import { Button } from '../../../uikit/Button/Button';
import { ButtonLink } from '../../../uikit/ButtonLink/ButtonLink';
import { Modal } from '../../Modal/Modal';
import styles from './ThankYouModal.module.css';
export function ThankYouModal({ onSignInClick, variant, phoneNumber }) {
    return (_jsx(Modal, { minHeight: 360, title: _jsx(Ditto, { componentId: "register.thankyou" }), content: _jsx("div", Object.assign({ className: styles['content'] }, { children: variant === 'email' ? (_jsx(Ditto, { componentId: "register.thankyou.info" })) : (_jsx(Ditto, { componentId: "register.thankyou.infophonenumber", variables: { PhoneNumber: phoneNumber || '' } })) })), footer: _jsxs(_Fragment, { children: [_jsx(BrowserView, { children: _jsx(Button, Object.assign({ onClick: onSignInClick, color: "dark", "data-test": "sign-in-button" }, { children: _jsx(Ditto, { componentId: "register.signin" }) })) }), _jsx(MobileView, { children: _jsx(ButtonLink, Object.assign({ href: getUniversalLinkForLogin() }, { children: _jsx(Ditto, { componentId: "register.signin" }) })) })] }) }));
}
