import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { MAP_CURSOR } from '../../common/consts/map';
import { DamageGradingPanel } from '../../components/DamageGradingPanel';
import { DetailsClassification } from '../../components/DetailsPanel/DetailsClassification/DetailsClassification';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { DetailsInfo } from '../../components/DetailsPanel/DetailsInfo/DetailsInfo';
import { DetailsPanel } from '../../components/DetailsPanel/DetailsPanel';
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip';
import { SegmentDetails } from '../../components/SegmentDetails/SegmentDetails';
import { SegmentPanel } from '../../components/SegmentPanel/SegmentPanel';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useSourceChecker } from '../../hooks/mainMap';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { setDrawArea, setDrawVertexCount, setMapCursor } from '../../state/slices/mainMap';
import { resetSegmentDetail, resetSegmentSelection } from '../../state/slices/segmentDetail';
import { resetSegmentForm } from '../../state/slices/segmentForm';
import { UnitFormatContainer } from '../Formatters/UnitFormatContainer/UnitFormatContainer';
export function SegmentCreatorContainer() {
    var _a;
    const dispatch = useAppDispatch();
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { segment, fetchStatus } = useAppSelector((state) => state.segmentDetail);
    const { drawArea } = useAppSelector((state) => state.mainMap);
    const { types: { roadDamageGroups, roadDamages } } = useAppSelector((state) => state.shared.conditionFilters);
    const { streetTypes, surfaceTypes } = useAppSelector((state) => state.shared.roadPropsDict.roadProps);
    const { conditionFiltersCount: filtersCount } = useSourceChecker();
    const unitFormatter = useUnitFormatter();
    useEffect(() => {
        dispatch(resetSegmentForm());
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, segment]);
    function onKeyDown(event) {
        if (event.key === 'Enter' && segment) {
            dispatch(setCurrentAction('segment-creator-form'));
        }
    }
    function handleCloseButtonClick() {
        dispatch(setDrawArea(0));
        dispatch(setDrawVertexCount(0));
        dispatch(resetSegmentDetail());
        dispatch(resetSegmentSelection(true));
        dispatch(setCurrentAction('none'));
        dispatch(setMapCursor(MAP_CURSOR.default));
    }
    if (fetchStatus === 'failed') {
        return (_jsx(DetailsPanel, { children: _jsx(DetailsInfo, { linkText: "Close", linkOnClick: handleCloseButtonClick, content: _jsx(Ditto, { componentId: "somethingwentwrongtext" }) }) }));
    }
    return (_jsxs(SegmentPanel, Object.assign({ title: _jsx(Ditto, { componentId: "plannedsegmentcreator.title" }), onCloseClick: handleCloseButtonClick }, { children: [segment && fetchStatus !== 'loading' && (_jsx(SegmentDetails, { isLoading: fetchStatus !== 'succeeded', address: segment.display_name, details: _jsxs(DetailsClassification, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.roadclassification" }), content: segment.street_types
                                .map((type) => streetTypes[type])
                                .join(', ') }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: segment.surface_types
                                .map((type) => surfaceTypes[type])
                                .join(', ') }), _jsx(DetailsClassificationItem, { headline: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "plannedsegments.length" }), filtersCount > 0 && (_jsx(InfoTooltip, { tooltip: _jsx(Ditto, { componentId: 'tooltip.segmentcreator.removefilters' }) }))] }), headlineHeight: 18, content: filtersCount === 0 ? (_jsx(UnitFormatContainer, { type: "length", value: segment.length })) : ('-') }), _jsx(DetailsClassificationItem, { headline: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "segmentcreator.selectedarea" }), _jsx(InfoTooltip, { tooltip: _jsx(Ditto, { componentId: "tooltip.segmentcreator.areaofselection" }), position: "end" })] }), headlineHeight: 18, content: unitFormatter({ type: 'area', value: drawArea }).formattedText })] }), averageGrading: _jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: (_a = segment.grade) === null || _a === void 0 ? void 0 : _a.emi, damageStats: segment.damage_stats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser }) })), fetchStatus === 'loading' && _jsx(SegmentDetails, { isLoading: true })] })));
}
