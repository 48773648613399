import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { ImageInProgress } from '../../../uikit/ImageInProgress/ImageInProgress';
import { Tag } from '../Tag/Tag';
import styles from './ZoomEnabledImage.module.css';
export function ZoomEnabledImage({ className, src, alt, onZooming, sign }) {
    const imgRef = useRef(null);
    const [isZoom, setIsZoom] = useState(false);
    const [zoomX, setZoomX] = useState(0);
    const [zoomY, setZoomY] = useState(0);
    const [isError, setIsError] = useState(false);
    const [imgHeight, setImgHeight] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    const onImageClick = (event) => {
        event.preventDefault();
        setIsZoom(!isZoom);
        if (onZooming) {
            onZooming(!isZoom);
        }
    };
    useEffect(() => {
        const mouseMoveListener = (event) => {
            if (imgRef.current) {
                setZoomX(Math.min(event.offsetX, imgRef.current.offsetWidth));
                setZoomY(Math.min(event.offsetY, imgRef.current.offsetHeight));
            }
        };
        document.addEventListener('mousemove', mouseMoveListener, false);
        return () => {
            document.removeEventListener('mousemove', mouseMoveListener, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isZoom]);
    useEffect(() => {
        setIsError(false);
    }, [src]);
    function handleError() {
        setIsError(true);
    }
    useEffect(() => {
        if (!imgRef.current) {
            return;
        }
        setImgHeight(imgRef.current.height);
        setImgWidth(imgRef.current.width);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgRef, imgHeight, imgWidth, isZoom, window.innerHeight, window.innerWidth]);
    if (isError) {
        return _jsx(ImageInProgress, { variant: "dark" });
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container']), onClick: onImageClick, style: {
            cursor: isZoom ? 'zoom-out' : 'zoom-in',
            transform: isZoom ? 'scale(2.5)' : '',
            transformOrigin: isZoom ? `${zoomX}px ${zoomY}px` : ''
        } }, { children: [_jsx("img", { ref: imgRef, className: className, src: src, alt: alt, onError: handleError, "data-test": "image-viewer-main-image-src" }), sign && (_jsx("div", Object.assign({ className: styles['tag-wrapper'] }, { children: _jsx(Tag, { tag: sign.tag, currentHeight: imgHeight, currentWidth: imgWidth, damageType: sign.damageType, signIcon: sign.tag.subSpecifiedClass, statusCode: sign.statusCode }) })))] })));
}
