export const downloadFile = (fileLink, filename) => {
    const linkElement = createLinkElement(fileLink, filename);
    linkElement.click();
};
const createLinkElement = (fileLink, filename) => {
    const element = document.createElement('a');
    element.setAttribute('href', fileLink);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    return element;
};
