// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I8azcRcgxkfvNRsbnrsx{display:flex;padding:4px 0}.I8azcRcgxkfvNRsbnrsx>*:not(:last-child){margin-right:8px}.tlWGWcKQemb6IPdI6lmV{width:100%;justify-content:space-between}.tlWGWcKQemb6IPdI6lmV>button:first-child{flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/components/SelectionBar/SelectionBar.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,aAAA,CAGJ,yCACI,gBAAA,CAGJ,sBACI,UAAA,CACA,6BAAA,CAGJ,yCACI,WAAA","sourcesContent":[".container {\n    display: flex;\n    padding: 4px 0;\n}\n\n.container > *:not(:last-child) {\n    margin-right: 8px;\n}\n\n.container__fullwidth {\n    width: 100%;\n    justify-content: space-between;\n}\n\n.container__fullwidth > button:first-child {\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `I8azcRcgxkfvNRsbnrsx`,
	"container__fullwidth": `tlWGWcKQemb6IPdI6lmV`
};
export default ___CSS_LOADER_EXPORT___;
