import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Filter, ListDashes2, TableView } from '../../components/icons';
export const TAB_LIST_2 = [
    {
        id: 'filter',
        icon: _jsx(Filter, {}),
        text: _jsx(Ditto, { componentId: "leftpanel.panelbuttongroup.filter" })
    },
    {
        id: 'list',
        icon: _jsx(ListDashes2, {}),
        text: _jsx(Ditto, { componentId: "leftpanel.panelbuttongroup.list" })
    }
];
export const TAB_LIST_3 = [
    {
        id: 'filter',
        icon: _jsx(Filter, {}),
        text: _jsx(Ditto, { componentId: "leftpanel.panelbuttongroup.filter" })
    },
    {
        id: 'list',
        icon: _jsx(ListDashes2, {}),
        text: _jsx(Ditto, { componentId: "leftpanel.panelbuttongroup.list" })
    },
    {
        id: 'table',
        icon: _jsx(TableView, {}),
        text: _jsx(Ditto, { componentId: "leftpanel.panelbuttongroup.table" })
    }
];
export var ConditionFeatureType;
(function (ConditionFeatureType) {
    ConditionFeatureType["point"] = "point";
    ConditionFeatureType["segment"] = "segment";
})(ConditionFeatureType || (ConditionFeatureType = {}));
export var ConditionFeatureTypeTab;
(function (ConditionFeatureTypeTab) {
    ConditionFeatureTypeTab[ConditionFeatureTypeTab["segments"] = 0] = "segments";
    ConditionFeatureTypeTab[ConditionFeatureTypeTab["points"] = 1] = "points";
})(ConditionFeatureTypeTab || (ConditionFeatureTypeTab = {}));
export var ConditionSelectedFeatureType;
(function (ConditionSelectedFeatureType) {
    ConditionSelectedFeatureType["point"] = "point";
    ConditionSelectedFeatureType["segment"] = "segment";
    ConditionSelectedFeatureType["both"] = "both";
})(ConditionSelectedFeatureType || (ConditionSelectedFeatureType = {}));
export const PRIMARY_SELECTED_FEATURE = {
    [ConditionSelectedFeatureType.both]: ConditionFeatureType.segment,
    [ConditionSelectedFeatureType.point]: ConditionFeatureType.point,
    [ConditionSelectedFeatureType.segment]: ConditionFeatureType.segment
};
