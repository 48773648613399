import { isAfter, isBefore, isSameDay } from 'date-fns';
import { createRRule } from './createRRule';
import { parseRecurringFormula } from './parseRecurringFormula';
import { getFrequencyDays, getFrequencyRepeat } from './utils';
export const MIN_YEAR = 2017;
export const CURRENT_YEAR = new Date().getFullYear();
/**
 * Return an array with dates according to
 * the formula for a calendar month view.
 */
export const getDueDatesForCalendarView = (recurringFormula, monthViewStart, monthViewEnd) => {
    var _a, _b;
    if (!recurringFormula) {
        return [];
    }
    /**
     * Get due dates for a recurring rule with empty days array.
     */
    const getDueDatesForEmptyDays = (frequency) => {
        var _a;
        const repeat = getFrequencyRepeat(frequency).repeat;
        const days = (_a = getFrequencyDays(frequency)) !== null && _a !== void 0 ? _a : [];
        if (repeat === 'never') {
            return [];
        }
        else if (repeat === 'weekly' && days.length === 0) {
            return [];
        }
        else if (repeat === 'monthly' && days.length === 0) {
            return [];
        }
        else if (repeat === 'yearly' && days.length === 0) {
            const next = getClosestDueDate(recurringFormula, monthViewStart);
            if (next) {
                return [next];
            }
        }
    };
    const datesForEmptyDays = getDueDatesForEmptyDays(recurringFormula.frequency);
    if (datesForEmptyDays) {
        return datesForEmptyDays;
    }
    const startDate = isBefore(monthViewStart, recurringFormula.startDate)
        ? recurringFormula.startDate
        : monthViewStart;
    const untilDate = isAfter(monthViewEnd, (_a = recurringFormula.untilDate) !== null && _a !== void 0 ? _a : monthViewEnd)
        ? (_b = recurringFormula.untilDate) !== null && _b !== void 0 ? _b : monthViewEnd
        : monthViewEnd;
    const rrule = createRRule({
        startDate: recurringFormula.startDate,
        untilDate: recurringFormula.untilDate,
        frequency: recurringFormula.frequency
    });
    if (rrule) {
        return rrule.between(startDate, untilDate, true);
    }
    return [];
};
export const getClosestDueDate = (recurringFormula, after) => {
    if (!recurringFormula) {
        return null;
    }
    const rrule = createRRule(recurringFormula);
    if (!rrule) {
        return null;
    }
    return rrule.after(after || recurringFormula.startDate, true);
};
export const isOverdue = (date) => {
    const now = new Date();
    return isBefore(date, now) || isSameDay(date, now);
};
export const isForever = (date) => {
    return !date;
};
export function isFormulaRecurring(recurringFormula) {
    let formula;
    if (typeof recurringFormula === 'string') {
        formula = parseRecurringFormula(recurringFormula);
    }
    else {
        formula = recurringFormula;
    }
    if (!formula) {
        return false;
    }
    return formula.frequency.repeat !== 'never';
}
