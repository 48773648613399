import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { ActionButton } from '../../ActionButton/ActionButton';
import { GradeBadge } from '../../GradeBadge/GradeBadge';
import { Task } from '../../icons';
import { ImageEnlarge } from '../ImageEnlarge/ImageEnlarge';
import styles from './Point.module.css';
export function Point({ content: { id, title, grade, gradePCI, gradePaser, roadType, surfaceType, image_url }, withAction, isEditor, checkbox, showGradePCI, showGradePaser, countryCode, onAddMarkerButtonClick, onImageEnlargeClick }) {
    const idLabel = useDittoWrapper({ componentId: 'pointid' });
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-point" }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle']) }, { children: [checkbox && checkbox, _jsx("span", { children: `${idLabel} ${id} · ${roadType}${surfaceType && ` · ${surfaceType}`}` })] })), _jsx("div", Object.assign({ className: styles['title'] }, { children: title })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [showGradePCI && gradePCI !== undefined && (_jsx(GradeBadge, { type: "pci", grade: gradePCI, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.pci" }), ':'] }), tooltip: _jsx(Ditto, { componentId: "grading.pcibeta" }) })), showGradePaser && gradePaser !== undefined && (_jsx(GradeBadge, { type: "paser", grade: gradePaser, prefix: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.paser" }), ':'] }), tooltip: _jsx(Ditto, { componentId: "grading.paserbeta" }) })), _jsx(GradeBadge, { type: "emi", grade: grade, prefix: countryCode === 'US' ? (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "grading.emi" }), ':'] })) : undefined }), withAction && isEditor && (_jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx(ActionButton, { onClick: onAddMarkerButtonClick, className: styles['action-button'], icon: _jsx(Task, {}), tooltip: {
                                        text: _jsx(Ditto, { componentId: "tooltip.createmarker" }),
                                        arrow: 'up'
                                    } }) })))] }))] })), _jsxs("div", Object.assign({ className: styles['right'] }, { children: [withAction && _jsx(ImageEnlarge, { onClick: onImageEnlargeClick }), image_url && _jsx("img", { className: styles['image'], src: image_url, alt: "point" })] }))] })));
}
