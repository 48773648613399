import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Close } from '../../icons';
import { LinkedAssetSelect } from '../../LinkedAssetSelect/LinkedAssetSelect';
import { Modal } from '../../Modal/Modal';
import styles from './LinkAssetModal.module.css';
export function LinkAssetModal({ status, results, getAttachmentAsset, onInputChange, onClose, onDone }) {
    function handleInputValueChange(value) {
        onInputChange(value);
    }
    function handleSelect(id) {
        onDone === null || onDone === void 0 ? void 0 : onDone(id);
    }
    function handleCloseClick() {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    const linkedAssetSelect = (_jsx(LinkedAssetSelect, { status: status, results: results, onSelect: handleSelect, getAttachmentAsset: getAttachmentAsset, onInputValueChange: handleInputValueChange }));
    return (_jsx(Modal, { width: 480, title: _jsx(Ditto, { componentId: "assets.linkasset" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: linkedAssetSelect, footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['footer-divider'] }), _jsx("div", Object.assign({ className: styles['buttons'] }, { children: _jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleCloseClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })) }))] })), onClickOutside: handleCloseClick }));
}
