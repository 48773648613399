import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../uikit/Button/Button';
import styles from './ErrorPage.module.css';
export function ErrorPage({ variant, onButtonClick }) {
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", { className: styles['left-layer-panel'] }), _jsxs("div", Object.assign({ className: styles['main-right-section'] }, { children: [_jsxs("div", Object.assign({ className: styles['left-info'] }, { children: [_jsx("img", { className: styles['graphic'], src: (function () {
                                    switch (variant) {
                                        case 'not-found':
                                        case 'no-permission':
                                            return '/404-graphic.png';
                                        case 'maintenance':
                                        case 'generic':
                                            return '/maintenance-graphic.png';
                                    }
                                })() }), _jsx("p", Object.assign({ className: styles['title'] }, { children: (function () {
                                    switch (variant) {
                                        case 'not-found':
                                            return _jsx(Ditto, { componentId: "errorpage.404" });
                                        case 'no-permission':
                                            return _jsx(Ditto, { componentId: "nopermissionpage.nopermission" });
                                        case 'maintenance':
                                            return _jsx(Ditto, { componentId: "maintenancepage.wewillbeback" });
                                        case 'generic':
                                            return _jsx(Ditto, { componentId: "somethingwentwrongtext" });
                                    }
                                })() })), _jsx("p", Object.assign({ className: styles['description'] }, { children: (function () {
                                    switch (variant) {
                                        case 'not-found':
                                        case 'no-permission':
                                            return _jsx(Ditto, { componentId: "errorpage.description" });
                                        case 'maintenance':
                                            return _jsx(Ditto, { componentId: "maintenancepage.description" });
                                    }
                                })() })), variant !== 'maintenance' && (_jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx(Button, Object.assign({ color: "yellow", onClick: onButtonClick }, { children: _jsx(Ditto, { componentId: "errorpage.button" }) })) })))] })), _jsx("img", { className: styles['right-image'], src: "/streetview.jpg" })] }))] })));
}
