import moment from 'moment';
import { ITEMS_PER_PAGE } from '../consts/config';
import { TIME_PICKER_RANGE_FORMAT } from '../consts/timepicker';
export function getBBoxParams(bounds, useMapBounds) {
    return useMapBounds
        ? {
            xmin: bounds.west,
            xmax: bounds.east,
            ymin: bounds.south,
            ymax: bounds.north
        }
        : undefined;
}
export function getTimeParams(selectedOption) {
    const format = 'YYYY-MM-DDTHH:mm:ss[Z]';
    return {
        from: moment(selectedOption.from, TIME_PICKER_RANGE_FORMAT).format(format),
        to: moment(selectedOption.to, TIME_PICKER_RANGE_FORMAT).format(format)
    };
}
export function getPaginationParams(currentPage) {
    return {
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE
    };
}
