// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xsa3mYfVT8fKLUk0wwpz{padding:2px 2px 2px 6px;background:var(--root-dark-blue-1);display:flex;flex-direction:row;align-items:center;gap:4px;border:1px solid rgba(129,129,129,.1);color:var(--primary-dark-blue);font-size:14px;font-weight:600;word-wrap:break-word;border-radius:4px;cursor:pointer}.Xsa3mYfVT8fKLUk0wwpz:hover{background:var(--root-dark-blue-2)}`, "",{"version":3,"sources":["webpack://./src/uikit/CustomizeButton/CustomizeButton.module.css"],"names":[],"mappings":"AAAA,sBACI,uBAAA,CACA,kCAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,qCAAA,CACA,8BAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CACA,iBAAA,CACA,cAAA,CAGJ,4BACI,kCAAA","sourcesContent":[".container {\n    padding: 2px 2px 2px 6px;\n    background: var(--root-dark-blue-1);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 4px;\n    border: 1px solid rgba(128.56, 128.56, 128.56, 0.1);\n    color: var(--primary-dark-blue);\n    font-size: 14px;\n    font-weight: 600;\n    word-wrap: break-word;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.container:hover {\n    background: var(--root-dark-blue-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Xsa3mYfVT8fKLUk0wwpz`
};
export default ___CSS_LOADER_EXPORT___;
