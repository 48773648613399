var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SIGNS_TO_SHOW } from '../common/consts/trafficSign';
import { post, postRaw } from '../common/fetch';
import { streetartClient } from '../openapi';
const SIGN_INFO_BY_ID_ENDPOINT = 'streetart/v1/traffic-signs/info-by-id';
const SIGNS_LIST_ENDPOINT = 'streetart/v1/traffic-signs/list';
const SIGNS_EXPORT_ENDPOINT = 'streetart/v1/traffic-signs/export';
const SIGNS_LIST_COUNT_ENDPOINT = 'streetart/v1/traffic-signs/list-count';
const SIGN_TYPES_ENDPOINT = 'streetart/v1/traffic-signs/types';
export function getSortParams(id) {
    switch (id) {
        case 'date-asc':
            return { sortBy: 'asset_created_at', sortOrder: 'asc' };
        case 'date-desc':
            return { sortBy: 'asset_created_at', sortOrder: 'desc' };
        case 'street-asc':
            return { sortBy: 'street_name', sortOrder: 'asc' };
        case 'street-desc':
            return { sortBy: 'street_name', sortOrder: 'desc' };
        default:
            console.error('unknown sign sort id', id);
    }
}
export function getFilterParams(filter) {
    return {
        signDamageTypes: filter.damageTypeIds.length === 0 ? SIGNS_TO_SHOW : filter.damageTypeIds
        // TODO: signTypeIds once backend supports it
    };
}
export function getSignsList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(SIGNS_LIST_ENDPOINT, body, undefined, true);
    });
}
export function getSignsListCount(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(SIGNS_LIST_COUNT_ENDPOINT, body, undefined, true);
    });
}
export function getSignDamageTypes(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(SIGN_TYPES_ENDPOINT, body, undefined, true);
    });
}
export function getSignInfoById(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(SIGN_INFO_BY_ID_ENDPOINT, body, undefined, true);
    });
}
export function getSignInfoByIdList(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignInventoryInfoByIdList(body);
        // return await post<ResponseSignInfoByIds>(SIGN_INFO_BY_ID_LIST_ENDPOINT, body, undefined, true);
    });
}
// NOTE: do NOT copy this function as an example,
// this is special communication when reponse is not json,
export function exportSignList(body, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield postRaw(SIGNS_EXPORT_ENDPOINT, body, true, abortSignalId);
    });
}
export function getSignTree(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return streetartClient.streetartService.streetartServiceTrafficSignsTree(body);
    });
}
