// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iA_w7rif2ujiy8gwrLrE{color:var(--primary-white);width:100%;display:flex;flex-direction:column;align-items:stretch}.YnwFROv9j4HWy6SwDl9E{font-size:12px;opacity:.6;white-space:nowrap}.IQxHW1bkY7wY9khG1qzA{margin-top:8px;display:flex;gap:8px;align-items:center}._u2UYDruonIvs5DNAodp{margin-left:auto;display:flex;gap:4px}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/InspectionV2/InspectionV2.module.css"],"names":[],"mappings":"AAAA,sBACI,0BAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CAGJ,sBACI,cAAA,CACA,UAAA,CACA,kBAAA,CAGJ,sBACI,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAGJ,sBACI,gBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".container {\n    color: var(--primary-white);\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n}\n\n.info {\n    font-size: 12px;\n    opacity: 0.6;\n    white-space: nowrap;\n}\n\n.main {\n    margin-top: 8px;\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n\n.right {\n    margin-left: auto;\n    display: flex;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `iA_w7rif2ujiy8gwrLrE`,
	"info": `YnwFROv9j4HWy6SwDl9E`,
	"main": `IQxHW1bkY7wY9khG1qzA`,
	"right": `_u2UYDruonIvs5DNAodp`
};
export default ___CSS_LOADER_EXPORT___;
