export var COMMON_ATTRIBUTE_TYPE;
(function (COMMON_ATTRIBUTE_TYPE) {
    COMMON_ATTRIBUTE_TYPE["text"] = "text";
    COMMON_ATTRIBUTE_TYPE["number"] = "number";
    COMMON_ATTRIBUTE_TYPE["date"] = "date";
    COMMON_ATTRIBUTE_TYPE["select"] = "select";
    COMMON_ATTRIBUTE_TYPE["multiselect"] = "multiselect";
})(COMMON_ATTRIBUTE_TYPE || (COMMON_ATTRIBUTE_TYPE = {}));
export var UNIT_ID;
(function (UNIT_ID) {
    UNIT_ID[UNIT_ID["unitNumber"] = 1] = "unitNumber";
    UNIT_ID[UNIT_ID["unitMeter"] = 2] = "unitMeter";
    UNIT_ID[UNIT_ID["unitCentimeter"] = 3] = "unitCentimeter";
    UNIT_ID[UNIT_ID["unitDays"] = 7] = "unitDays";
    UNIT_ID[UNIT_ID["unitLiters"] = 8] = "unitLiters";
})(UNIT_ID || (UNIT_ID = {}));
