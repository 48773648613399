import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { MarkerPriorityIcon, MarkerStatusVignette } from '../../common/convert';
import { isBeforeNow } from '../../common/utils/time';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { Marker } from '../../components/DetailsPanel/Attachment/AttachmentItem/Marker/Marker';
import { ClosedDate } from '../../components/Result/Marker/ClosedDate/ClosedDate';
import { DueDate } from '../../components/Result/Marker/DueDate/DueDate';
import { useAppSelector } from '../../state/hooks';
import { Avatar } from '../../uikit/Avatar/Avatar';
import { useDateTimeFormatter } from '../formatting/useDateTimeFormatter';
import { useFormatter } from '../formatting/useFormatter';
export function useAttachmentMarker() {
    const { taskAssigneeMap, markerCategoryMap } = useAppSelector((state) => state.shared);
    const dateTimeFormatter = useDateTimeFormatter();
    const { formatDistance } = useFormatter();
    function getAttachmentMarker(marker) {
        return (_jsx(Marker, { priority: _jsx(MarkerPriorityIcon, { value: marker.priorityTypeId }), title: marker.title || _jsx(Ditto, { componentId: "tasktitle.notitle" }), titleInfo: marker.distance ? `(${formatDistance(marker.distance)})` : undefined, status: !marker.dueDateClosedDate && _jsx(MarkerStatusVignette, { value: marker.statusId }), category: (function () {
                const category = marker.categoryId
                    ? markerCategoryMap[marker.categoryId]
                    : undefined;
                if (category) {
                    return (_jsx(CategoryBadge, { category: category.name, backgroundColor: category.color, variant: "list" }));
                }
            })(), assignee: marker.assignees.map((a) => {
                const assignee = taskAssigneeMap[a.uuid];
                if (assignee) {
                    return (_jsx(Avatar, { firstName: assignee.firstName, lastName: assignee.lastName, size: 24, src: assignee.profileURL }, assignee.id));
                }
            }), dueDate: marker.dueDate && !marker.dueDateClosedDate ? (_jsx(DueDate, { dueDate: dateTimeFormatter(marker.dueDate).date, isRecurring: marker.isRecurring, isOverdue: isBeforeNow(marker.dueDate) })) : undefined, closedDate: marker.dueDateClosedDate ? (_jsx(ClosedDate, { closedDate: dateTimeFormatter(marker.dueDateClosedDate).date })) : undefined }));
    }
    return { getAttachmentMarker };
}
