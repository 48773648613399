var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useId, useState } from 'react';
import { Layer, Map, Source, useMap } from 'react-map-gl';
import { bbox } from '@turf/turf';
import { MAP_SNAPSHOT_QUALITY, MAP_STYLE_URL } from '../../common/consts/map';
import { FILE_MANAGER_DURATION_SECONDS_MAX } from '../../common/consts/time';
import { getMarkerPriorityImageName, getSignDamageName, useMarkerTranslations, useSignTranslations } from '../../common/convert';
import { getGroupFromSignTree, getSignFromSignTree } from '../../common/convert/signInventory';
import { getCloseMarkerLink, getGoogleMapLink, getUniversalLinkForAsset, getUniversalLinkForMarker } from '../../common/link';
import { MARKER_PROPERTY_ICON_ID, markersLayer, plansOutlineLayer, segmentsLayer, trafficSignsLayer } from '../../common/mapbox';
import { ASSET_PROPERTY_ICON_ID, assetLayer } from '../../common/mapbox/layers/assetLayer';
import { signInventoryLayer } from '../../common/mapbox/layers/signInventoryLayer';
import { uniqueV2 } from '../../common/utils/array';
import { Loader } from '../../components/Loader/Loader';
import { MailModal } from '../../components/Modals/Mail/MailModal';
import config from '../../config/config.json';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMapInit } from '../../hooks/useMapInit';
import { useUserFullname } from '../../hooks/useUserFullname';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { bulkGetMailAsset, bulkGetMailSignInventory, bulkGetMailTask, bulkGetMailTrafficSign, bulkSendMailAsset, bulkSendMailSignInventory, bulkSendMailTask, bulkSendMailTrafficSign, getEdgeGeoJSON, getMailPlanTemplates, getMailPointTemplates, getMailSegmentTemplates, getPlanGeoJSON, resetMail, saveMarkerMailChangelogTk, sendMail as sendMailAction, setFeatureGeoJSONs } from '../../state/slices/mail';
import { fetchAssetsTk, fetchLinkedMarkersTk, fetchMarkersTk } from '../../state/slices/sharedDataHolder';
const MAIL_MODAL_MAP_SOURCE_ID = 'mail-modal-source-id';
const MAIL_MODAL_MAP_ID = 'mail-modal-snapshot-map';
export function MailModalContainer({ featureList }) {
    const { removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    const key = useId();
    const { taskAssigneeList, markerCategoryList, signTree, assetTypeMap } = useAppSelector((state) => state.shared);
    const { markerList, assetList, linkedMarkerList } = useAppSelector((state) => state.sharedDataHolder);
    const { mailContents, featureGeoJSONs, isSending } = useAppSelector((s) => s.mail);
    const { locale } = useAppSelector((s) => s.app);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { [MAIL_MODAL_MAP_ID]: map } = useMap();
    const handleMapInit = useMapInit();
    const dateTimeFormatter = useDateTimeFormatter();
    const { getSignLabelString } = useSignTranslations();
    const { formatAddress } = useFormatter();
    const { fullname: senderName } = useUserFullname();
    const { getPriorityLabel, getStatusLabel } = useMarkerTranslations();
    const { linkAssetsTasksEnabled } = useLayerChecker();
    const [note, setNote] = useState('');
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [mapSnapshots, setMapSnapshots] = useState([]);
    const [mapGeoJSON, setMapGeoJSON] = useState();
    const noTitleLabel = useDittoWrapper({ componentId: 'tasktitle.notitle' });
    const markerSubjectSingular = useDittoWrapper({
        componentId: 'mails.tasksharedwithyou',
        variables: {
            TaskTitle: featureList.type === 'marker' && markerList.length > 0
                ? markerList[0].title || noTitleLabel
                : ''
        }
    });
    const markerSubjectPlural = useDittoWrapper({ componentId: 'mails.severaltasksshared' });
    const assetSubjectSingular = useDittoWrapper({
        componentId: 'mails.assetsharedwithyou',
        variables: {
            AssetTitle: featureList.type === 'asset' && assetList.length > 0
                ? assetList[0].title || noTitleLabel
                : ''
        }
    });
    const assetSubjectPlural = useDittoWrapper({ componentId: 'mails.severalassetsshared' });
    const signLabel = useDittoWrapper({ componentId: 'damagedsign' });
    const withMap = featureList.type === 'plan' ||
        featureList.type === 'segment' ||
        featureList.type === 'sign' ||
        featureList.type === 'sign-inventory' ||
        featureList.type === 'marker' ||
        featureList.type === 'asset';
    const areGeoJSONsReady = featureGeoJSONs.length === featureList.value.length;
    const areSnapshotsReady = mapSnapshots.length === featureList.value.length;
    useEffect(() => {
        setNote('');
        setMapSnapshots([]);
        setIsMapLoaded(false);
        setMapGeoJSON(undefined);
        dispatch(resetMail());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (withMap && !areSnapshotsReady) {
            return;
        }
        getMailContents(note);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areSnapshotsReady, note]);
    useEffect(() => {
        if (!withMap || !isMapLoaded || !areGeoJSONsReady || areSnapshotsReady) {
            return;
        }
        getMapSnapshots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areGeoJSONsReady, isMapLoaded, areSnapshotsReady, map]);
    // Get data or geojson for map snapshot if needed
    useEffect(() => {
        switch (featureList.type) {
            case 'segment':
                dispatch(getEdgeGeoJSON(featureList.value.map((f) => f.id)));
                break;
            case 'plan':
                dispatch(getPlanGeoJSON(featureList.value));
                break;
            case 'sign':
                dispatch(setFeatureGeoJSONs(featureList.value.map((f) => ({
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: { type: 'Point', coordinates: f.offsetPosition },
                            properties: {
                                'icon-image': getSignDamageName(f.damageType)
                            }
                        }
                    ]
                }))));
                break;
            case 'sign-inventory':
                dispatch(setFeatureGeoJSONs(featureList.value.map((f) => ({
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: { type: 'Point', coordinates: f.offsetPosition },
                            properties: {
                                sub_specified_class: f.tag.subSpecifiedClass
                            }
                        }
                    ]
                }))));
                break;
            case 'marker': {
                dispatch(fetchMarkersTk({
                    ids: featureList.value,
                    imageUrlDurationSeconds: FILE_MANAGER_DURATION_SECONDS_MAX
                }))
                    .unwrap()
                    .then((res) => {
                    if (res.result) {
                        dispatch(setFeatureGeoJSONs(res.result.markers.map((marker) => ({
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [
                                            marker.point.longitude,
                                            marker.point.latitude
                                        ]
                                    },
                                    properties: {
                                        [MARKER_PROPERTY_ICON_ID]: getMarkerPriorityImageName(marker.priorityTypeId)
                                    }
                                }
                            ]
                        }))));
                    }
                });
                break;
            }
            case 'asset': {
                dispatch(fetchAssetsTk({
                    ids: featureList.value,
                    imageUrlDurationSeconds: FILE_MANAGER_DURATION_SECONDS_MAX
                }))
                    .unwrap()
                    .then((res) => {
                    if (res.result) {
                        dispatch(setFeatureGeoJSONs(res.result.manualInventories.map((asset) => {
                            var _a;
                            return {
                                type: 'FeatureCollection',
                                features: [
                                    {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'Point',
                                            coordinates: asset.coordinates
                                        },
                                        properties: {
                                            [ASSET_PROPERTY_ICON_ID]: (_a = assetTypeMap[asset.inventoryTypeId]) === null || _a === void 0 ? void 0 : _a.iconId
                                        }
                                    }
                                ]
                            };
                        })));
                    }
                });
                break;
            }
        }
    }, [assetTypeMap, dispatch, featureList]);
    useEffect(() => {
        if (!assetList) {
            return;
        }
        const allMarkerIds = assetList.flatMap((v) => v.markerIds);
        if (linkAssetsTasksEnabled && allMarkerIds.length > 0) {
            dispatch(fetchLinkedMarkersTk(uniqueV2(allMarkerIds)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetList]);
    if (withMap && !areGeoJSONsReady) {
        return _jsx(Loader, { variant: "default" });
    }
    function getMapSnapshots() {
        return __awaiter(this, void 0, void 0, function* () {
            const snapshots = [];
            if (!map) {
                return;
            }
            for (const fGeo of featureGeoJSONs) {
                setMapGeoJSON(fGeo); // TODO: remove type assignment after getEdgeGeoJSON migration
                map.fitBounds(bbox(fGeo), {
                    padding: 30,
                    animate: false,
                    maxZoom: 17
                });
                const { clientHeight, clientWidth } = map.getContainer();
                // Wait for map to have loaded enough for snapshot
                yield map.once('idle');
                const image = map.getCanvas().toDataURL('image/png', MAP_SNAPSHOT_QUALITY);
                snapshots.push({
                    base64: image,
                    height: Math.round(clientHeight * MAP_SNAPSHOT_QUALITY),
                    width: Math.round(clientWidth * MAP_SNAPSHOT_QUALITY)
                });
            }
            setMapSnapshots(snapshots);
        });
    }
    function getSignMailTemplateContent(f, mapSnapshotBase64) {
        let version = 'initial';
        switch (locale) {
            case 'de':
                version = 'de';
                break;
            case 'fr':
                version = 'fr';
                break;
            default:
        }
        return {
            name: 'traffic-signs-template-en',
            version: version,
            vars: {
                senderName,
                address: f.displayName,
                signId: f.id,
                signDate: dateTimeFormatter(f.timestamp).date,
                signTime: dateTimeFormatter(f.timestamp).time,
                signCondition: getSignLabelString(f.damageType) || '',
                lat: f.offsetPosition[1],
                lng: f.offsetPosition[0],
                note: note,
                link: window.location.href,
                pointImageUrl: f.imageUrl
            },
            inlineAttachments: {
                mapImage: mapSnapshotBase64.replace('data:image/png;base64,', '')
            },
            attachments: {}
        };
    }
    function getSignInventoryMailTemplateContent(f, mapSnapshotBase64) {
        let version = 'sign-email-en';
        const sign = getSignFromSignTree(signTree, f.tag.subSpecifiedClass);
        const group = getGroupFromSignTree(signTree, f.tag.subSpecifiedClass);
        switch (locale) {
            case 'de':
                version = 'sign-email-de';
                break;
            case 'fr':
                version = 'sign-email-fr';
                break;
            default:
        }
        return {
            name: 'mail template',
            version,
            vars: {
                senderName,
                date: dateTimeFormatter(f.timestamp).date,
                time: dateTimeFormatter(f.timestamp).time,
                id: f.id,
                title: (sign === null || sign === void 0 ? void 0 : sign.abbreviation) || '',
                group: (group === null || group === void 0 ? void 0 : group.name) || '',
                name: (sign === null || sign === void 0 ? void 0 : sign.abbreviation) || '',
                bastId: (sign === null || sign === void 0 ? void 0 : sign.bastId) || '',
                condition: getSignLabelString(f.damageType) || '',
                photoUrl: f.imageUrl
            },
            inlineAttachments: {
                mapUrl: mapSnapshotBase64.replace('data:image/png;base64,', '')
            },
            attachments: {}
        };
    }
    function getTaskMailTemplateContent(marker, mapSnapshotBase64) {
        let version = 'task-email-en';
        switch (locale) {
            case 'de':
                version = 'task-email-de';
                break;
            case 'fr':
                version = 'task-email-fr';
                break;
            default:
        }
        const assignees = taskAssigneeList
            .filter((a) => marker.assignees.find((assignee) => assignee.uuid === a.id))
            .map((a) => `${a.firstName} ${a.lastName}`);
        const category = markerCategoryList.find((c) => c.categoryId === marker.categoryId);
        return {
            name: 'mail template',
            version: version,
            vars: {
                senderName,
                createddate: dateTimeFormatter(marker.createdAt).date,
                createdtime: dateTimeFormatter(marker.createdAt).time,
                id: marker.id.toString(),
                title: marker.title || noTitleLabel,
                address: formatAddress(marker.addressJson, { fallback: '-' }),
                assignees: assignees.join(', ') || '-',
                priority: getPriorityLabel(marker.priorityTypeId),
                category: (category === null || category === void 0 ? void 0 : category.categoryName) || '-',
                link: getUniversalLinkForMarker(marker.id),
                dueDate: marker.dueDate ? dateTimeFormatter(marker.dueDate.dueDate).date : '-',
                status: getStatusLabel(marker.statusId),
                photoUrl: (marker.photos.length > 0 && marker.photos[0].mediumUrl) || '',
                description: marker.issueDescription || '-',
                note: note,
                amountOfImagesAttached: marker.photos.length.toString(),
                googleMapLink: getGoogleMapLink({
                    lat: marker.point.latitude,
                    lng: marker.point.longitude
                }),
                closeMarkerLink: getCloseMarkerLink(marker.decryptCloseParams, locale)
            },
            inlineAttachments: {
                mapUrl: mapSnapshotBase64.replace('data:image/png;base64,', '')
            },
            attachments: {}
        };
    }
    function getAssetMailTemplateContent(asset, mapSnapshotBase64) {
        var _a;
        let version = 'asset-mail-en';
        switch (locale) {
            case 'de':
                version = 'asset-mail-de';
                break;
            case 'fr':
                version = 'asset-mail-fr';
                break;
            default:
        }
        return {
            name: 'mail template',
            version: version,
            vars: {
                senderName,
                date: dateTimeFormatter(asset.createdAt).date,
                time: dateTimeFormatter(asset.createdAt).time,
                id: asset.id.toString(),
                assettype: ((_a = assetTypeMap[asset.inventoryTypeId]) === null || _a === void 0 ? void 0 : _a.name) || '-',
                title: asset.title || noTitleLabel,
                address: formatAddress(asset.address, { fallback: '-' }),
                link: getUniversalLinkForAsset(asset.id),
                amountOfImagesAttached: asset.photos.length.toString(),
                photoUrl: (asset.photos.length > 0 && asset.photos[0].imageUrl) || '',
                description: asset.notes || '-',
                linkedtasks: linkedMarkerList
                    .filter((i) => i.manualInventoryId === asset.id)
                    .map((i) => i.title || noTitleLabel)
                    .join(', ') || '-',
                notes: note,
                googleMapLink: getGoogleMapLink({
                    lat: asset.coordinates[1],
                    lng: asset.coordinates[0]
                })
            },
            inlineAttachments: {
                mapUrl: mapSnapshotBase64.replace('data:image/png;base64,', '')
            },
            attachments: {}
        };
    }
    function getMailContents(note) {
        switch (featureList.type) {
            case 'plan':
                dispatch(getMailPlanTemplates({
                    locale,
                    mailNote: note,
                    mapSnapshots,
                    planIds: featureList.value
                }));
                break;
            case 'segment':
                dispatch(getMailSegmentTemplates({
                    locale,
                    note,
                    nodeEdges: featureList.value.map((f, i) => ({
                        streetId: f.id,
                        mapSnapshot: mapSnapshots[i]
                    }))
                }));
                break;
            case 'point':
                dispatch(getMailPointTemplates({
                    locale,
                    note,
                    points: featureList.value.map((f) => ({
                        uuid: f.id
                    }))
                }));
                break;
            case 'marker':
                dispatch(bulkGetMailTask({
                    templates: markerList.map((f, index) => getTaskMailTemplateContent(f, mapSnapshots[index].base64))
                }));
                break;
            case 'asset':
                dispatch(bulkGetMailAsset({
                    templates: assetList.map((f, index) => getAssetMailTemplateContent(f, mapSnapshots[index].base64))
                }));
                break;
            case 'sign':
                dispatch(bulkGetMailTrafficSign({
                    templates: featureList.value.map((f, index) => getSignMailTemplateContent(f, mapSnapshots[index].base64))
                }));
                break;
            case 'sign-inventory':
                dispatch(bulkGetMailSignInventory({
                    templates: featureList.value.map((f, index) => getSignInventoryMailTemplateContent(f, mapSnapshots[index].base64))
                }));
                break;
        }
    }
    function handleNoteChange(val) {
        setNote(val);
    }
    function handleClose() {
        removeModal('MailModal');
    }
    function handleSend(to, cc, subject) {
        return __awaiter(this, void 0, void 0, function* () {
            if (isSending || to.length === 0)
                return;
            switch (featureList.type) {
                case 'sign':
                    yield dispatch(bulkSendMailTrafficSign({
                        emails: featureList.value.map((f, index) => ({
                            template: getSignMailTemplateContent(f, mapSnapshots[index].base64),
                            recipients: to,
                            ccRecipients: cc,
                            subject
                        }))
                    }));
                    break;
                case 'sign-inventory':
                    yield dispatch(bulkSendMailSignInventory({
                        emails: featureList.value.map((f, index) => ({
                            template: getSignInventoryMailTemplateContent(f, mapSnapshots[index].base64),
                            recipients: to,
                            ccRecipients: cc,
                            subject
                        }))
                    }));
                    break;
                case 'marker':
                    if (markerList.length > 0) {
                        yield dispatch(bulkSendMailTask({
                            emails: markerList.map((f, index) => ({
                                template: getTaskMailTemplateContent(f, mapSnapshots[index].base64),
                                recipients: to,
                                ccRecipients: cc,
                                subject
                            }))
                        }));
                        dispatch(saveMarkerMailChangelogTk({
                            body: {
                                changelog: markerList.map((i) => ({
                                    markerId: i.id,
                                    subject,
                                    recipients: to
                                }))
                            },
                            markerId: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.type) === 'marker' ? selectedFeature.id : undefined
                        }));
                    }
                    break;
                case 'asset':
                    if (assetList.length > 0) {
                        yield dispatch(bulkSendMailAsset({
                            emails: assetList.map((f, index) => ({
                                template: getAssetMailTemplateContent(f, mapSnapshots[index].base64),
                                recipients: to,
                                ccRecipients: cc,
                                subject
                            }))
                        }));
                    }
                    break;
                default:
                    yield dispatch(sendMailAction(mailContents.map((m) => ({
                        to,
                        cc: cc.join(','),
                        subject,
                        template: m
                    }))));
            }
            handleClose();
        });
    }
    const snapshotMap = (_jsx(Map, Object.assign({ id: MAIL_MODAL_MAP_ID, ref: handleMapInit, mapStyle: MAP_STYLE_URL, mapboxAccessToken: config.mapbox.token, preserveDrawingBuffer: true, onLoad: () => setIsMapLoaded(true), interactive: false }, { children: mapGeoJSON && (_jsx(Source, Object.assign({ id: MAIL_MODAL_MAP_SOURCE_ID, type: "geojson", data: mapGeoJSON }, { children: (function () {
                switch (featureList.type) {
                    case 'segment':
                        return _jsx(Layer, Object.assign({}, segmentsLayer));
                    case 'plan':
                        return _jsx(Layer, Object.assign({}, plansOutlineLayer));
                    case 'sign':
                        return _jsx(Layer, Object.assign({}, trafficSignsLayer));
                    case 'sign-inventory':
                        return _jsx(Layer, Object.assign({}, signInventoryLayer));
                    case 'marker':
                        return _jsx(Layer, Object.assign({}, markersLayer));
                    case 'asset':
                        return _jsx(Layer, Object.assign({}, assetLayer));
                }
            })() }))) })));
    const subject = (function () {
        if (featureList.value.length === 0) {
            return '';
        }
        if (featureList.type === 'sign') {
            return `${signLabel}: ${featureList.value[0].displayName}`;
        }
        if (featureList.type === 'sign-inventory') {
            return `${signLabel}: ${featureList.value[0].displayName}`;
        }
        if (featureList.type === 'marker') {
            if (featureList.value.length > 1) {
                return markerSubjectPlural;
            }
            return markerSubjectSingular;
        }
        if (featureList.type === 'asset') {
            if (featureList.value.length > 1) {
                return assetSubjectPlural;
            }
            return assetSubjectSingular;
        }
    })();
    return (_jsx(MailModal, { snapshotMap: withMap && areGeoJSONsReady && !areSnapshotsReady && snapshotMap, onSendClick: handleSend, onCancelClick: handleClose, content: mailContents === null || mailContents === void 0 ? void 0 : mailContents.join('\n'), subject: subject, note: note, onNoteChange: handleNoteChange, isSending: isSending, onClickOutside: handleClose }, key));
}
