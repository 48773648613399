// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P8lj91Qm14G46ZfD0gFg{margin-top:auto;padding:12px 0;display:flex;align-items:center;justify-content:end;position:sticky;bottom:0;z-index:var(--zindex-sticky)}`, "",{"version":3,"sources":["webpack://./src/components/Customize/Customize.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,eAAA,CACA,QAAA,CACA,4BAAA","sourcesContent":[".container {\n    margin-top: auto;\n    padding: 12px 0;\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    position: sticky;\n    bottom: 0;\n    z-index: var(--zindex-sticky);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `P8lj91Qm14G46ZfD0gFg`
};
export default ___CSS_LOADER_EXPORT___;
