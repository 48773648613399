import { useEffect } from 'react';
export function useOnClickWithinElementBounds(ref, onEvent) {
    useEffect(() => {
        const listener = (event) => {
            var _a;
            let clientX = 0;
            let clientY = 0;
            if (event instanceof MouseEvent) {
                clientX = event.clientX;
                clientY = event.clientY;
            }
            else if (event instanceof TouchEvent) {
                clientX = event.touches[0].clientX;
                clientY = event.touches[0].clientY;
            }
            const inside = isPointWithinRect((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect(), {
                x: clientX,
                y: clientY
            });
            onEvent(inside);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, onEvent]);
}
const isPointWithinRect = (rect, point) => {
    return (rect.x <= point.x &&
        point.x <= rect.x + rect.width &&
        rect.y <= point.y &&
        point.y <= rect.y + rect.height);
};
