import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { IconResizer } from '../IconResizer/IconResizer';
import { Check } from '../icons';
import styles from './FilterSelectbox.module.css';
export function FilterSelectbox({ className, noCheckbox, isChecked, isIndeterminate, color = 'yellow', icon, content, wrapContent, caption, trailingElement, trailingIcon, onClick, onCheckboxClick, disabled, hoverTrailingText, hoverTrailingIcon, label, variant = 'light', infoTooltip, 'data-test': dataTest, children, height = 48, defaultCursor }) {
    const checkbox = (_jsxs("div", Object.assign({ className: classes(styles['container'], disabled && styles['container__disabled'], styles[`container-${variant}`], defaultCursor && styles['container__default-cursor'], className), onClick: () => onClick && onClick(), "data-test": "container-checkbox", style: { height } }, { children: [!noCheckbox && (_jsx(Checkbox, { isChecked: isChecked, isIndeterminate: isIndeterminate, color: color, onClick: (e) => {
                    if (onCheckboxClick) {
                        e.stopPropagation();
                        onCheckboxClick();
                    }
                }, "data-test": `checkbox${isChecked ? '-checked' : ''}` })), icon, _jsxs("span", Object.assign({ className: classes(styles['name'], wrapContent && styles['name__wrap']), "data-test": dataTest }, { children: [content, caption && _jsx("span", Object.assign({ className: styles['caption'] }, { children: caption }))] })), label && _jsx("span", { children: label }), _jsx("span", { className: styles['trailing-anchor'] }), noCheckbox && isChecked ? (_jsx("span", Object.assign({ className: styles['trailing-checkmark'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Check, {}) })) }))) : (trailingIcon), trailingElement !== undefined && (_jsx("span", Object.assign({ className: styles['trailing-element'], "data-test": "trailing-element" }, { children: trailingElement }))), hoverTrailingText && _jsx("div", Object.assign({ className: styles['hover-text'] }, { children: hoverTrailingText })), hoverTrailingIcon && (_jsx("div", Object.assign({ className: styles['hover-icon'], onClick: (e) => {
                    e.stopPropagation();
                    hoverTrailingIcon.onIconClick && hoverTrailingIcon.onIconClick();
                }, "data-test": "hover-icon" }, { children: hoverTrailingIcon.icon }))), infoTooltip && _jsx("span", { children: infoTooltip })] })));
    if (!children) {
        return checkbox;
    }
    return (_jsxs("div", Object.assign({ className: styles['wrapper'] }, { children: [checkbox, _jsx("div", Object.assign({ className: styles['children'] }, { children: children }))] })));
}
