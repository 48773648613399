import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { getFilterLabel } from '../../common/conditionFilterTypes';
import { DamageGradingPanel } from '../../components/DamageGradingPanel';
import { DetailsClassification } from '../../components/DetailsPanel/DetailsClassification/DetailsClassification';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { Point, SegmentPoints } from '../../components/icons';
import { Loader } from '../../components/Loader/Loader';
import { MapboxStaticImage } from '../../components/PrintLayout/MapboxStaticImage/MapboxStaticImage';
import { PrintDetail } from '../../components/PrintLayout/PrintDetail/PrintDetail';
import { PrintImage } from '../../components/PrintLayout/PrintImage/PrintImage';
import { PrintLayout } from '../../components/PrintLayout/PrintLayout';
import { PrintMeta } from '../../components/PrintLayout/PrintMeta/PrintMeta';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { usePrint } from '../../hooks/usePrint';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchPrintData } from '../../state/slices/print';
import { fetchFilterTypes } from '../../state/slices/shared';
export function PrintPointContainer({ uuids }) {
    const dispatch = useAppDispatch();
    const { data: { results: points } } = useAppSelector((s) => s.print);
    const { teamAccount } = useAppSelector((s) => s.auth);
    const { conditionFilters: { types: { streetTypes, roadDamages, roadDamageGroups } } } = useAppSelector((s) => s.shared);
    const { onMapLoad, onImageLoad, setImageCount } = usePrint(uuids.length);
    const { shouldHideSurfaceTypes } = useLayerChecker();
    const { formatDate } = useFormatter();
    useEffect(() => {
        dispatch(fetchFilterTypes());
        dispatch(
        // TODO: change to new endpoint when will be ready
        fetchPrintData({
            type: 'point',
            uuids
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!points) {
            return;
        }
        const totalImagesWithUrl = points
            .map((r) => r.photoUrls.length)
            .reduce((total, photoCount) => total + photoCount, 0);
        setImageCount(totalImagesWithUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points]);
    if (!points) {
        return _jsx(Loader, { variant: "fullscreen" });
    }
    return (_jsx(_Fragment, { children: points === null || points === void 0 ? void 0 : points.map((point) => {
            var _a, _b;
            if (point.feature.type !== 'point')
                return null;
            const data = point.feature.value;
            const dateTime = formatDate(data.timeStamp);
            return (_jsx(PrintLayout, { meta: _jsx(PrintMeta, { layerName: _jsx(Ditto, { componentId: "rightpanel.titletype.point" }), icon: _jsx(SegmentPoints, {}), title: data.streetName || _jsx(Ditto, { componentId: "tasktitle.notitle" }), date: dateTime.date, time: dateTime.time, location: {
                        lat: data.coordinates[0],
                        lng: data.coordinates[1]
                    }, id: data.pointId }), details: _jsx(PrintDetail, { fullwidthField: _jsxs(_Fragment, { children: [_jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "streetclassification.classification" }), content: getFilterLabel(streetTypes, data.streetType), variant: "print" }), !shouldHideSurfaceTypes && data.surfaceType && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: data.surfaceType, variant: "print" }))] })), _jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: (_a = data.grade) === null || _a === void 0 ? void 0 : _a.emi, damageStats: data.damageStats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, isExpanded: true, variant: "print" })] }) }), map: _jsx(MapboxStaticImage, { lat: data.coordinates[0], lon: data.coordinates[1], overlayElement: _jsx(Point, { color: (_b = point.geojson.features[0].properties) === null || _b === void 0 ? void 0 : _b.color }), onLoad: onMapLoad, overlayAnchor: "bottom" }), images: point.photoUrls.map((p, index) => (_jsx(PrintImage, { src: p, onLoad: onImageLoad }, `image-${index}`))) }, data.pointId));
        }) }));
}
