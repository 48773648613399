var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { TIME_PICKER_LATEST_ID, TIME_PICKER_RANGE_LATEST } from '../../common/consts/timepicker';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getDistance, getGrade, getMarkers, getTimePickerOptions } from '../../services/timepicker';
import { addNotificationMessage } from './notification';
const timepickerOption = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.timepickerSelectedOption;
// dynamic latest timepicker option (the range in the url might be old, we replace by the up to date latest range)
const initialOption = timepickerOption && timepickerOption.id !== TIME_PICKER_LATEST_ID
    ? timepickerOption
    : Object.assign({ id: TIME_PICKER_LATEST_ID }, TIME_PICKER_RANGE_LATEST);
const initialState = {
    timepicker: {
        options: [],
        selectedOption: initialOption
    }
};
export const fetchTimepickerOption = createAppAsyncThunk('presetsPanel/option/fetchList', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getTimePickerOptions();
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), {
    condition: (_, { getState }) => {
        const state = getState();
        const { options } = state.presetsPanel.timepicker;
        if (options.length > 0) {
            return false;
        }
    }
});
export const fetchTimepickerGrade = createAppAsyncThunk('presetsPanel/conditionGrade/fetch', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { from, to } = state.presetsPanel.timepicker.selectedOption;
    const res = yield getGrade({ from, to });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchTimepickerDistance = createAppAsyncThunk('presetsPanel/conditionDistance/fetch', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { from, to } = state.presetsPanel.timepicker.selectedOption;
    const res = yield getDistance({ from, to });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchTimepickerMarkerCount = createAppAsyncThunk('presetsPanel/markerCount/fetch', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { from, to } = state.presetsPanel.timepicker.selectedOption;
    const res = yield getMarkers({ from, to });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const presetsPanelSlice = createSlice({
    name: 'presetsPanel',
    initialState,
    reducers: {
        reset: () => initialState,
        setSelectedTimeOption: (state, action) => {
            state.timepicker.selectedOption = action.payload;
        },
        setSelectedGrade: (state, action) => {
            state.timepicker.selectedGrade = Object.assign(Object.assign({}, state.timepicker.selectedGrade), { grade: action.payload });
        },
        setSelectedGradePCI: (state, action) => {
            state.timepicker.selectedGrade = Object.assign(Object.assign({}, state.timepicker.selectedGrade), { gradePci: action.payload });
        },
        setSelectedGradePaser: (state, action) => {
            state.timepicker.selectedGrade = Object.assign(Object.assign({}, state.timepicker.selectedGrade), { gradePaser: action.payload });
        },
        setSelectedKm: (state, action) => {
            state.timepicker.selectedKm = action.payload;
        },
        setSelectedMarker: (state, action) => {
            state.timepicker.selectedMarkers = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchTimepickerOption.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.timepicker.options = action.payload.result.options;
            }
        });
        builder.addCase(fetchTimepickerGrade.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.timepicker.selectedGrade = action.payload.result.grade;
            }
        });
        builder.addCase(fetchTimepickerDistance.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.timepicker.selectedKm = action.payload.result.distance;
            }
        });
        builder.addCase(fetchTimepickerMarkerCount.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.timepicker.selectedMarkers = action.payload.result.markerStats;
            }
        });
    }
});
export const { reset: resetPresetsPanel, setSelectedTimeOption, setSelectedGrade, setSelectedGradePCI, setSelectedGradePaser, setSelectedKm, setSelectedMarker } = presetsPanelSlice.actions;
export default presetsPanelSlice.reducer;
