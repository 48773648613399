import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { AssetIcon } from '../../common/icon';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Plus } from '../icons';
import { Search } from '../Search/Search';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import styles from './AssetTypeSearch.module.css';
export function AssetTypeSearch({ assetTypeList, onAssetTypeSelect, onAddNewTypeClick }) {
    const placeholder = useDittoWrapper({ componentId: 'assets.searchassettype' });
    const [value, setValue] = useState('');
    const valueLower = value.toLowerCase();
    function onChange(newValue) {
        setValue(newValue);
    }
    function sortOptions(a, b) {
        return (a.name.toLocaleLowerCase().indexOf(valueLower) -
            b.name.toLowerCase().indexOf(valueLower));
    }
    const filteredList = value
        ? assetTypeList
            .filter((item) => item.name.toLowerCase().includes(valueLower))
            .sort(sortOptions)
        : assetTypeList;
    const items = filteredList.map((assetType) => (_jsx(SelectboxEntry, { text: assetType.name, leadingIcon: _jsx(AssetIcon, { iconId: assetType.iconId }), onClick: () => {
            onAssetTypeSelect(assetType);
        }, "data-test": "asset-type-search-item" }, assetType.id)));
    const addItem = (_jsx(SelectboxEntry, { leadingIcon: _jsx(Plus, {}), text: _jsx(Ditto, { componentId: "assets.addassettype" }), onClick: () => onAddNewTypeClick === null || onAddNewTypeClick === void 0 ? void 0 : onAddNewTypeClick(), withUnderline: true }, 'add-asset-type'));
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", Object.assign({ className: styles['search-field'] }, { children: _jsx(Search, { placeholder: placeholder, value: value, onChange: onChange, variant: "field" }) })), _jsx("div", { className: styles['divider'] }), _jsx("div", Object.assign({ className: styles['items'] }, { children: [...items, addItem] }))] })));
}
