import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import DittoProvider from 'ditto-react';
import { DITTO_LOCALE } from '../../common/ditto';
import ditto from '../../ditto';
import { useAppSelector } from '../hooks';
export function DittoContextProvider({ children }) {
    const { locale } = useAppSelector((state) => state.app);
    return (_jsx(DittoProvider, Object.assign({ source: ditto, variant: DITTO_LOCALE[locale] }, { children: children })));
}
