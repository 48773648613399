import { createSlice } from '@reduxjs/toolkit';
import { LOCALE } from '../../common/consts/locale';
import { getLocalStorageAll } from '../../common/localStorage';
// Define the initial state using that type
// TODO: POLISHING can be also from domain .com .de
const initialState = {
    locale: getLocalStorageAll().locale || LOCALE.de,
    currentAction: 'none'
};
export const appSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLocale: (state, action) => {
            state.locale = action.payload;
        },
        setCurrentAction: (state, action) => {
            state.currentAction = action.payload;
        },
        setCurrentModalId: (state, action) => {
            state.currentModalId = action.payload;
        },
        reset: () => initialState
    }
});
export const { setLocale, reset: resetApp, setCurrentAction, setCurrentModalId } = appSlice.actions;
export default appSlice.reducer;
