var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { MAP_CURSOR, MAP_DEFAULT_BOUND, MAP_RIGHT_CLICK_ACTION_CURSOR, MAP_VIA_LAT, MAP_VIA_LNG } from '../../common/consts/map';
import { NOTE_COLORS } from '../../common/consts/note';
import { PLAN_PLANNED_FOR_ID } from '../../common/consts/plan';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { DEFAULT_ZOOM } from '../../common/mapbox';
import { planPlanningForFilters } from '../../common/planFilterTypes';
import { getURLSearchParams } from '../../common/url';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { isDeepEqual } from '../../common/utils/object';
import { getTimeParams } from '../../common/utils/params';
import { isValidPoint } from '../../common/validation/geo';
import { PRESET_PANEL_HEIGHT } from '../../components/AppLayout/AppLayout';
import { getAlertGeoJSON } from '../../services/alert';
import { getFilterParams, getMarkerGeoJSON } from '../../services/marker';
import { getNoteList } from '../../services/note';
import { getPlanMapGeoJSON } from '../../services/plan';
export const initialViewState = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.mapView;
export const defaultViewState = {
    zoom: DEFAULT_ZOOM,
    latitude: MAP_VIA_LAT,
    longitude: MAP_VIA_LNG,
    bearing: 0,
    pitch: 0,
    padding: {
        top: PRESET_PANEL_HEIGHT,
        right: 0,
        bottom: 0,
        left: 0
    }
};
const initialState = {
    viewState: initialViewState || defaultViewState,
    center: {
        lat: defaultViewState.latitude,
        lng: defaultViewState.longitude
    },
    bounds: MAP_DEFAULT_BOUND,
    style: 'default',
    currentRightClickAction: 'none',
    drawVertexCount: 0,
    drawArea: 0,
    cursor: 'auto'
};
export const fetchPlanGeoJSON = createDebouncedAsyncThunk('mainMap/fetchPlanGeoJSON', (_, { getState /*, dispatch */ }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c, _d;
    const state = getState();
    const _e = state.leftPanelPlan.filter, { completionYearAndMonth, customCompletionYearAndMonth } = _e, filter = __rest(_e, ["completionYearAndMonth", "customCompletionYearAndMonth"]);
    const { timepicker: { selectedOption } } = state.presetsPanel;
    let completion;
    if (completionYearAndMonth) {
        if (completionYearAndMonth === PLAN_PLANNED_FOR_ID.custom) {
            completion = customCompletionYearAndMonth;
        }
        else {
            completion = (_b = planPlanningForFilters.find((item) => item.id === completionYearAndMonth)) === null || _b === void 0 ? void 0 : _b.value;
        }
    }
    const params = {
        filter: Object.assign({ completionFrom: completion === null || completion === void 0 ? void 0 : completion.from, completionTo: completion === null || completion === void 0 ? void 0 : completion.to, completionIncludeNotSet: completionYearAndMonth === PLAN_PLANNED_FOR_ID.notspecified || undefined, priorityIncludeNotSet: ((_c = filter.priorityIds) === null || _c === void 0 ? void 0 : _c.includes(0)) || undefined, efficiencyIncludeNotSet: ((_d = filter.efficiencyIds) === null || _d === void 0 ? void 0 : _d.includes(0)) || undefined }, filter),
        timepicker: getTimeParams(selectedOption)
    };
    const res = yield getPlanMapGeoJSON(params);
    // fix: PU-150
    // if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
    //     dispatch(addNotificationMessage({ type: 'error' }));
    // }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchAlertGeoJSON = createDebouncedAsyncThunk('mainMap/fetchAlertGeoJSON', (_, { getState /*, dispatch */ }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { filter } = state.leftPanelAlert;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const params = Object.assign(Object.assign({}, filter), { from: selectedOption.from, to: selectedOption.to });
    const res = yield getAlertGeoJSON(params);
    // fix: PU-150
    // if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
    //     dispatch(addNotificationMessage({ type: 'error' }));
    // }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchMarkerGeoJSON = createDebouncedAsyncThunk('mainMap/fetchMarkerGeoJSON', (_, { getState /*, dispatch */ }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { filter } = state.leftPanelMarker;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const res = yield getMarkerGeoJSON({
        params: {
            filter: getFilterParams(filter),
            timepicker: getTimeParams(selectedOption)
        }
    });
    // fix: PU-150
    // if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
    //     dispatch(addNotificationMessage({ type: 'error' }));
    // }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchNoteGeoJSON = createDebouncedAsyncThunk('mainMap/fetchNoteGeoJSON', () => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getNoteList();
    // fix: PU-150
    // if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
    //     dispatch(addNotificationMessage({ type: 'error' }));
    // }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const mainMapSlice = createSlice({
    name: 'mainMap',
    initialState,
    reducers: {
        setDebouncedViewState: (state, action) => {
            const { viewState, bounds } = action.payload;
            if (!isDeepEqual(state.viewState, viewState)) {
                state.viewState = viewState;
                state.center = {
                    lat: viewState.latitude,
                    lng: viewState.longitude
                };
                state.bounds = bounds;
            }
        },
        setCursor: (state, action) => {
            state.cursor = action.payload;
        },
        resetCursor: (state) => {
            state.cursor = initialState.cursor;
        },
        setStyle: (state, action) => {
            state.style = action.payload;
        },
        setClickCoordinates: (state, action) => {
            state.clickCoordinates = action.payload;
        },
        setRightClickCoordinates: (state, action) => {
            state.rightClickCoordinates = action.payload;
        },
        clearRightClickCoordinates: (state) => {
            state.rightClickCoordinates = undefined;
        },
        setCurrentRightClickAction: (state, action) => {
            var _a;
            state.cursor = (_a = MAP_RIGHT_CLICK_ACTION_CURSOR[action.payload]) !== null && _a !== void 0 ? _a : initialState.cursor;
            state.currentRightClickAction = action.payload;
        },
        setDraftPinInitialPosition: (state, action) => {
            state.draftPinInitialPosition = action.payload;
        },
        setDraftPin: (state, action) => {
            state.draftPin = action.payload;
        },
        setDraftPinContent: (state, action) => {
            state.draftPinContent = action.payload;
        },
        clearDraftPin: (state) => {
            state.draftPinInitialPosition = undefined;
            state.draftPin = undefined;
            state.draftPinContent = undefined;
        },
        setDrawArea: (state, action) => {
            state.drawArea = action.payload;
        },
        setDrawVertexCount: (state, action) => {
            state.drawVertexCount = action.payload;
            state.cursor = MAP_CURSOR.crosshair;
        },
        setDrawPolygon: (state, action) => {
            state.drawPolygon = action.payload;
        },
        setDraggableFeature: (state, action) => {
            state.draggableFeature = action.payload;
        },
        clearDraggableFeature: (state) => {
            state.draggableFeature = undefined;
        },
        setRotatableFeature: (state, action) => {
            state.rotatableFeature = action.payload;
            state.lastRotation = action.payload.value.orientation;
        },
        clearRotatableFeature: (state) => {
            state.rotatableFeature = undefined;
        },
        setLastRotation: (state, action) => {
            state.lastRotation = action.payload;
        },
        clearLastRotation: (state) => {
            state.lastRotation = undefined;
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        builder.addCase(fetchPlanGeoJSON.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.planGeoJSON = action.payload.result.geojson; // type casting because of v2GeoFeature type is not compatible with featureCollection function
            }
        });
        builder.addCase(fetchAlertGeoJSON.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.alertGeoJSON = action.payload.result.geodata;
            }
        });
        builder.addCase(fetchMarkerGeoJSON.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.markerGeoJSON = action.payload.result.featureCollection;
            }
        });
        builder.addCase(fetchNoteGeoJSON.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            if (action.payload.result) {
                const noteList = action.payload.result;
                const features = [];
                for (const [id, note] of Object.entries(noteList)) {
                    // Check for unreliable firebase data
                    if (!note || !isValidPoint(note.geo)) {
                        continue;
                    }
                    const mappedColor = (_a = NOTE_COLORS[note.color]) !== null && _a !== void 0 ? _a : NOTE_COLORS[0];
                    features.push({
                        type: 'Feature',
                        properties: {
                            id,
                            color: mappedColor,
                            text: note.text,
                            date: note.date,
                            createdBy: note.createdBy
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [(_c = (_b = note.geo) === null || _b === void 0 ? void 0 : _b.lng) !== null && _c !== void 0 ? _c : 0, (_e = (_d = note.geo) === null || _d === void 0 ? void 0 : _d.lat) !== null && _e !== void 0 ? _e : 0]
                        }
                    });
                }
                state.noteGeoJSON = { type: 'FeatureCollection', features };
            }
        });
    }
});
export const { reset: resetMainMap, setDebouncedViewState, setStyle, setClickCoordinates, setRightClickCoordinates, clearRightClickCoordinates, setCurrentRightClickAction, setDraftPinInitialPosition, setDraftPin, setDraftPinContent, clearDraftPin, setDrawArea, setDrawVertexCount, setDrawPolygon, setDraggableFeature, clearDraggableFeature, setRotatableFeature, clearRotatableFeature, setLastRotation, clearLastRotation, setCursor: setMapCursor, resetCursor: resetMapCursor } = mainMapSlice.actions;
export default mainMapSlice.reducer;
