import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { TreatmentSelect } from '../../EditorForms/TreatmentSelect/TreatmentSelect';
import { Close, SparkleMagicApplied } from '../../icons';
import { InfoBox } from '../../InfoBox/InfoBox';
import { Modal } from '../../Modal/Modal';
import styles from './TreatmentSelectModal.module.css';
export function TreatmentSelectModal({ options, unitTypes, value, countryCode, hideNotSpecified, onChange, onClose, onEditTreatmentsClick }) {
    function handleCloseClick() {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (_jsx(Modal, { width: 480, title: _jsx(Ditto, { componentId: "filter.measure" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: _jsxs(_Fragment, { children: [_jsx(InfoBox, { className: styles['info-box'], icon: _jsx(SparkleMagicApplied, {}), content: _jsx(Ditto, { componentId: "magicinput.info.treatment" }), variant: "green" }), _jsx(TreatmentSelect, { className: styles['treatment-select'], countryCode: countryCode, options: options, unitTypes: unitTypes, value: value, hideNotSpecified: hideNotSpecified, onChange: onChange })] }), footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['footer-divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx("button", Object.assign({ className: styles['button-edit-treatments'], onClick: onEditTreatmentsClick }, { children: _jsx(Ditto, { componentId: "treatments.edittreatments" }) })), _jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleCloseClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) }))] }))] })), onClickOutside: handleCloseClick }));
}
