// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hBkZ8Bcjfj1GeZAdYVr7{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/FormInputPassword/FormInputPassword.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA","sourcesContent":[".icon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `hBkZ8Bcjfj1GeZAdYVr7`
};
export default ___CSS_LOADER_EXPORT___;
