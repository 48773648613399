import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { RANGE_LIMIT } from '../../common/consts/asset';
import { COMMON_ATTRIBUTE_TYPE } from '../../common/consts/attribute';
import { getNewAttributeFilter } from '../../common/filter';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { fromDateUTC, toDateUTC } from '../../common/utils/time';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { FormInputSelect } from '../../components/FormInputSelect/FormInputSelect';
import { Attachments, CalendarBlank, NoAttachments } from '../../components/icons';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setAssetFilter } from '../../state/slices/leftPanelAsset';
import { RadioSelectbox } from '../../uikit/RadioSelectbox/RadioSelectbox';
import { useAssetFilterHandlers } from '../filters/useAssetFilterHandlers';
import { useFormatter } from '../formatting/useFormatter';
export function useAssetFilters({ onAttributeFilterGroupWrapperToggle }) {
    const { addModal } = useModalContext();
    const { filter } = useAppSelector((state) => state.leftPanelAsset);
    const { unitList, assetFolders } = useAppSelector((state) => state.shared);
    const dispatch = useAppDispatch();
    const { handleSelectAttributeFilterClick, handleSelectAttributeFilterNotSpecifiedClick, handleNumberAttributeFilterChange, handleDateAttributeFilterChange, isAttributeSelectOptionChecked, handleNumberAttributeNotSpecifiedClick, handleDateAttributeNotSpecifiedClick, handleTextAttributeNotSpecifiedClick, handleTextAttributeSpecifiedClick, handleFolderFilterClick, handleFolderAttachedFilterClick, handleFolderNotAttachedFilterClick } = useAssetFilterHandlers();
    const formatter = useFormatter();
    function handleCustomDateClick(id, initialRange) {
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                rangeSelector: true,
                startDate: toDateUTC(initialRange === null || initialRange === void 0 ? void 0 : initialRange.from),
                endDate: toDateUTC(initialRange === null || initialRange === void 0 ? void 0 : initialRange.to),
                onConfirm: (value) => {
                    handleDateAttributeFilterChange(id, {
                        from: fromDateUTC(value === null || value === void 0 ? void 0 : value.startDate),
                        to: fromDateUTC(value === null || value === void 0 ? void 0 : value.untilDate)
                    });
                }
            }
        });
    }
    function showAssetAttributeFiltersIndicator(assetType) {
        function showFilter(attribute) {
            return (attribute.amountInUse > 0 &&
                (attribute.type === COMMON_ATTRIBUTE_TYPE.select ||
                    (attribute.type === COMMON_ATTRIBUTE_TYPE.number &&
                        attribute.amountInUse >= 1) ||
                    (attribute.type === COMMON_ATTRIBUTE_TYPE.date && attribute.amountInUse >= 1) ||
                    attribute.type === COMMON_ATTRIBUTE_TYPE.text));
        }
        return assetType.inventoryCount > 0 && assetType.attributes.find((a) => showFilter(a));
    }
    function getAssetAttributeFilter(attribute) {
        var _a, _b, _c, _d, _e;
        function handleAttributeGroupFilterClick(id) {
            if (filterAttribute) {
                dispatch(setAssetFilter({
                    attributes: filter.attributes.filter((at) => at.id !== id)
                }));
                return;
            }
            const newAttributeFilter = getNewAttributeFilter(attribute);
            if (newAttributeFilter) {
                dispatch(setAssetFilter({
                    attributes: filter.attributes.concat(newAttributeFilter)
                }));
            }
        }
        if (attribute.amountInUse === 0) {
            return;
        }
        const filterAttribute = filter.attributes.find((fa) => fa.id === attribute.id);
        const filterDateValue = (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'date' ? filterAttribute.dateValue : undefined;
        const filterNumberValue = (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'number' ? filterAttribute.numberValue : undefined;
        const filterSelectValue = (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'select' ? filterAttribute.selectValue : undefined;
        return (_jsxs("div", { children: [attribute.type === COMMON_ATTRIBUTE_TYPE.select && (_jsxs(FilterGroupWrapper, Object.assign({ content: attribute.name, checkState: getCheckboxCheckState((_a = attribute.selectOptions) === null || _a === void 0 ? void 0 : _a.length, filterSelectValue === null || filterSelectValue === void 0 ? void 0 : filterSelectValue.length), onCheckboxClick: () => handleAttributeGroupFilterClick(attribute.id), onToggle: onAttributeFilterGroupWrapperToggle }, { children: [(_b = attribute.selectOptions) === null || _b === void 0 ? void 0 : _b.map((selectOption) => (_jsx(FilterSelectbox, { onClick: () => handleSelectAttributeFilterClick(attribute.id, selectOption.id), isChecked: isAttributeSelectOptionChecked(attribute.id, selectOption.id), content: selectOption.name, color: "black", trailingElement: selectOption.amountInUse }, selectOption.id))), _jsx(FilterSelectbox, { color: "black", isChecked: isAttributeSelectOptionChecked(attribute.id, undefined, true), onClick: () => handleSelectAttributeFilterNotSpecifiedClick(attribute.id), content: _jsx(Ditto, { componentId: "notspecified" }), trailingElement: attribute.notSpecifiedAmount }, attribute.id + '-selectbox')] }), attribute.id + '-select')), attribute.type === COMMON_ATTRIBUTE_TYPE.number && attribute.amountInUse >= 1 && (_jsxs(FilterGroupWrapper, Object.assign({ content: attribute.name, checkState: filterAttribute ? 'checked' : 'not-checked', onCheckboxClick: () => handleAttributeGroupFilterClick(attribute.id), onToggle: onAttributeFilterGroupWrapperToggle }, { children: [_jsx(RadioSelectbox, { color: "black", isChecked: (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'number' &&
                                !filterAttribute.showNotSpecified, onClick: () => filterNumberValue
                                ? undefined
                                : handleNumberAttributeFilterChange(attribute.id, {
                                    min: attribute.numberMin,
                                    max: attribute.numberMax
                                }), content: _jsx(Ditto, { componentId: "filter.range" }), count: attribute.amountInUse }), _jsx(RangeSlider, { indent: "12px", min: (_c = attribute.numberMin) !== null && _c !== void 0 ? _c : RANGE_LIMIT.min, max: (_d = attribute.numberMax) !== null && _d !== void 0 ? _d : RANGE_LIMIT.max, showInputs: true, value: filterNumberValue, unit: (_e = unitList.find((u) => u.id === attribute.unitId)) === null || _e === void 0 ? void 0 : _e.abbreviation, onChange: (value) => handleNumberAttributeFilterChange(attribute.id, value) }), _jsx(RadioSelectbox, { color: "black", isChecked: (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'number' &&
                                filterAttribute.showNotSpecified, onClick: () => handleNumberAttributeNotSpecifiedClick(attribute.id), content: _jsx(Ditto, { componentId: "notspecified" }), count: attribute.notSpecifiedAmount })] }), attribute.id + '-range')), attribute.type === COMMON_ATTRIBUTE_TYPE.date && attribute.amountInUse >= 1 && (_jsx(_Fragment, { children: _jsxs(FilterGroupWrapper, Object.assign({ content: attribute.name, checkState: filterAttribute ? 'checked' : 'not-checked', onCheckboxClick: () => handleAttributeGroupFilterClick(attribute.id), onToggle: onAttributeFilterGroupWrapperToggle }, { children: [_jsx(RadioSelectbox, { onClick: filterDateValue
                                    ? undefined
                                    : () => handleCustomDateClick(attribute.id, filterDateValue), isChecked: (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'date' &&
                                    !filterAttribute.showNotSpecified, content: _jsx(Ditto, { componentId: "timepicker.customtimeframe" }), color: "black", count: attribute.amountInUse }), filterDateValue && (_jsx(FormInputSelect, { onClick: () => handleCustomDateClick(attribute.id, filterDateValue), leadingIcon: _jsx(CalendarBlank, {}), hideMenu: true, placeholder: _jsx(Ditto, { componentId: "timepicker.selectcustomtimeframe" }), value: _jsxs(_Fragment, { children: [formatter.formatDateISO(filterDateValue.from), ' - ', formatter.formatDateISO(filterDateValue.to)] }), size: "medium", variant: "ghost" })), _jsx(RadioSelectbox, { isChecked: (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'date' &&
                                    filterAttribute.showNotSpecified, onClick: () => handleDateAttributeNotSpecifiedClick(attribute.id), content: _jsx(Ditto, { componentId: "notspecified" }), color: "black", count: attribute.notSpecifiedAmount })] }), attribute.id + '-date-range') })), attribute.type === COMMON_ATTRIBUTE_TYPE.text && (_jsxs(FilterGroupWrapper, Object.assign({ content: attribute.name, checkState: filterAttribute ? 'checked' : 'not-checked', onCheckboxClick: () => handleAttributeGroupFilterClick(attribute.id), onToggle: onAttributeFilterGroupWrapperToggle }, { children: [_jsx(RadioSelectbox, { color: "black", isChecked: (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'text' &&
                                !filterAttribute.showNotSpecified, onClick: () => handleTextAttributeSpecifiedClick(attribute.id), content: _jsx(Ditto, { componentId: "filter.isadded" }), count: attribute.amountInUse }), _jsx(RadioSelectbox, { color: "black", isChecked: (filterAttribute === null || filterAttribute === void 0 ? void 0 : filterAttribute.type) === 'text' && filterAttribute.showNotSpecified, onClick: () => handleTextAttributeNotSpecifiedClick(attribute.id), content: _jsx(Ditto, { componentId: "notspecified" }), count: attribute.notSpecifiedAmount })] }), attribute.id + '-text'))] }, attribute.id));
    }
    const assetFolderFilters = assetFolders.map((folder) => (_jsxs(FilterGroupWrapper, Object.assign({ content: folder.name, checkState: getCheckboxCheckState(2, filter.filesAttached.filter((n) => n === folder.id).length +
            filter.filesNotAttached.filter((n) => n === folder.id).length), onCheckboxClick: () => handleFolderFilterClick(folder.id), hideBottomBar: true }, { children: [_jsx(FilterSelectbox, { onClick: () => handleFolderAttachedFilterClick(folder.id), isChecked: filter.filesAttached.includes(folder.id), icon: _jsx(Attachments, {}), content: _jsx(Ditto, { componentId: "files.attached" }), color: "black", trailingElement: folder.attachedCount, wrapContent: true }), _jsx(FilterSelectbox, { onClick: () => handleFolderNotAttachedFilterClick(folder.id), isChecked: filter.filesNotAttached.includes(folder.id), icon: _jsx(NoAttachments, {}), content: _jsx(Ditto, { componentId: "files.noattachments" }), color: "black", trailingElement: folder.notAttachedCount, wrapContent: true })] }), folder.id)));
    return { getAssetAttributeFilter, assetFolderFilters, showAssetAttributeFiltersIndicator };
}
