import { PRIVILEGE_LEVEL } from '../common/consts/user';
import { useAppSelector } from '../state/hooks';
export function useUserRole() {
    const { userAccount, roles: { list: roleList } } = useAppSelector((state) => state.auth);
    const userRole = roleList.find((role) => role.id === (userAccount === null || userAccount === void 0 ? void 0 : userAccount.currentAccountRoleId));
    if (!userAccount || userRole === undefined) {
        return {
            roleList,
            isAdmin: false,
            isEditor: false,
            isViewer: false,
            userRole: undefined
        };
    }
    if (userAccount.currentAccountRoleId === null) {
        return {
            roleList,
            isAdmin: true,
            isEditor: true,
            isViewer: true,
            userRole: undefined
        };
    }
    return {
        roleList,
        isAdmin: userRole.privilegeLevel >= PRIVILEGE_LEVEL.admin,
        isEditor: userRole.privilegeLevel >= PRIVILEGE_LEVEL.editor,
        isViewer: userRole.privilegeLevel >= PRIVILEGE_LEVEL.viewer &&
            userRole.privilegeLevel < PRIVILEGE_LEVEL.editor,
        userRole
    };
}
