import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ActionButton } from '../../ActionButton/ActionButton';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Task } from '../../icons';
import { ImageEnlarge } from '../ImageEnlarge/ImageEnlarge';
import styles from './Object2.module.css';
export function Object2({ content: { objectName, dateTime, title, object, thumbnailUrl }, withAction, isEditor, checkbox, onImageEnlargeClick, onAddMarkerButtonClick }) {
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-object" }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle']) }, { children: [checkbox && checkbox, _jsxs("div", { children: [`${isEditor ? dateTime.dateTime : dateTime.date} · `, _jsx("span", Object.assign({ "data-test": "object-name" }, { children: objectName }))] })] })), _jsx("div", Object.assign({ className: styles['title'] }, { children: title })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [object && (_jsxs("div", Object.assign({ className: styles['tag'], style: { color: object.color } }, { children: [_jsx(IconResizer, Object.assign({ size: 16 }, { children: object.icon })), _jsx("span", Object.assign({ className: styles['tag-text'] }, { children: object.text }))] }))), withAction && isEditor && (_jsx("div", Object.assign({ className: styles['button'] }, { children: _jsx(ActionButton, { className: styles['action-button'], icon: _jsx(Task, {}), tooltip: {
                                        text: _jsx(Ditto, { componentId: "tooltip.createmarker" }),
                                        arrow: 'up'
                                    }, onClick: onAddMarkerButtonClick }) })))] }))] })), _jsxs("div", Object.assign({ className: styles['right'] }, { children: [withAction && _jsx(ImageEnlarge, { onClick: onImageEnlargeClick }), thumbnailUrl && (_jsx("img", { className: styles['image'], src: thumbnailUrl, alt: "marker" }))] }))] })));
}
