import { OBJECT_TYPE } from '../../consts/object';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
import { OBJECTS_SOURCE_ID } from '../sources';
export const OBJECTS_LAYER_ID = {
    default: `${LAYER_PREFIX}-objects`,
    hover: `${LAYER_PREFIX}-objects-hover`,
    highlight: `${LAYER_PREFIX}-objects-highlight`,
    selected: `${LAYER_PREFIX}-objects-selected`
};
export const MANHOLE_IMAGE_NAME = {
    default: 'manhole-default',
    hover: 'manhole-hover',
    highlight: 'manhole-highlight',
    selected: 'manhole-selected'
};
export const STORMDRAIN_IMAGE_NAME = {
    default: 'stormdrain-default',
    hover: 'stormdrain-hover',
    highlight: 'stormdrain-highlight',
    selected: 'stormdrain-selected'
};
const ICON_SIZE = {
    default: 0.5,
    max: 1
};
export const objectsLayer = {
    id: OBJECTS_LAYER_ID.default,
    interactive: true,
    type: 'symbol',
    source: OBJECTS_SOURCE_ID,
    paint: {
        'icon-opacity': 1
    },
    layout: {
        'icon-image': [
            'case',
            ['in', OBJECT_TYPE.manhole, ['string', ['get', 'detected_object']]],
            MANHOLE_IMAGE_NAME.default,
            ['in', OBJECT_TYPE.stormDrain, ['string', ['get', 'detected_object']]],
            STORMDRAIN_IMAGE_NAME.default,
            MANHOLE_IMAGE_NAME.default
        ],
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            ICON_SIZE.default,
            MAX_ZOOM,
            ICON_SIZE.max
        ],
        'icon-allow-overlap': false,
        'icon-anchor': 'center'
    }
};
export const objectsHoverLayer = Object.assign(Object.assign({}, objectsLayer), { id: OBJECTS_LAYER_ID.hover, layout: Object.assign(Object.assign({}, objectsLayer.layout), { 'icon-image': [
            'case',
            ['in', OBJECT_TYPE.manhole, ['string', ['get', 'detected_object']]],
            MANHOLE_IMAGE_NAME.hover,
            ['in', OBJECT_TYPE.stormDrain, ['string', ['get', 'detected_object']]],
            STORMDRAIN_IMAGE_NAME.hover,
            MANHOLE_IMAGE_NAME.hover
        ] }) });
export const objectsHighlightLayer = Object.assign(Object.assign({}, objectsLayer), { id: OBJECTS_LAYER_ID.highlight, layout: Object.assign(Object.assign({}, objectsLayer.layout), { 'icon-image': [
            'case',
            ['in', OBJECT_TYPE.manhole, ['string', ['get', 'detected_object']]],
            MANHOLE_IMAGE_NAME.highlight,
            ['in', OBJECT_TYPE.stormDrain, ['string', ['get', 'detected_object']]],
            STORMDRAIN_IMAGE_NAME.highlight,
            MANHOLE_IMAGE_NAME.highlight
        ] }) });
export const objectsSelectedLayer = Object.assign(Object.assign({}, objectsLayer), { id: OBJECTS_LAYER_ID.selected, layout: Object.assign(Object.assign({}, objectsLayer.layout), { 'icon-allow-overlap': true, 'icon-ignore-placement': true, 'icon-image': [
            'case',
            ['in', OBJECT_TYPE.manhole, ['string', ['get', 'detected_object']]],
            MANHOLE_IMAGE_NAME.selected,
            ['in', OBJECT_TYPE.stormDrain, ['string', ['get', 'detected_object']]],
            STORMDRAIN_IMAGE_NAME.selected,
            MANHOLE_IMAGE_NAME.selected
        ] }) });
