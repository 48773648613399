import { DEFAULT_MANHOLE_OPTIONS, DEFAULT_STORM_DRAIN_OPTIONS, PREMIUM_MANHOLE_OPTIONS, PREMIUM_STORM_DRAIN_OPTIONS } from '../common/consts/object';
import { useAppSelector } from '../state/hooks';
export function useObjectTypes() {
    const { objectTypes } = useAppSelector((state) => state.shared);
    const { teamAccount } = useAppSelector((state) => state.auth);
    const isPremium = teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.manHoleStormDrainSpecifiedClassesEnabled;
    if (isPremium) {
        return {
            manholeOptions: PREMIUM_MANHOLE_OPTIONS.map((i) => {
                var _a;
                return ({
                    name: i,
                    count: (_a = objectTypes.find((t) => t.name === i)) === null || _a === void 0 ? void 0 : _a.count
                });
            }),
            stormDrainOptions: PREMIUM_STORM_DRAIN_OPTIONS.map((i) => {
                var _a;
                return ({
                    name: i,
                    count: (_a = objectTypes.find((t) => t.name === i)) === null || _a === void 0 ? void 0 : _a.count
                });
            })
        };
    }
    return {
        manholeOptions: DEFAULT_MANHOLE_OPTIONS.map((i) => ({
            name: i,
            count: countInOptions(objectTypes, DEFAULT_MANHOLE_OPTIONS.concat(PREMIUM_MANHOLE_OPTIONS))
        })),
        stormDrainOptions: DEFAULT_STORM_DRAIN_OPTIONS.map((i) => ({
            name: i,
            count: countInOptions(objectTypes, DEFAULT_STORM_DRAIN_OPTIONS.concat(PREMIUM_STORM_DRAIN_OPTIONS))
        }))
    };
}
function countInOptions(options, idFilter) {
    return options.filter((o) => idFilter.includes(o.name)).reduce((p, c) => p + c.count, 0);
}
