import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { Ditto } from 'ditto-react';
import { MARKER_CHANGELOG_OPERATION } from '../../../common/consts/marker';
import { getAssigneeNames, getCategoryBadge, getMarkerPriorityLabel, getMarkerSourceLabel, getMarkerStatusLabel, getUserIcon, MarkerPriorityIcon, MarkerStatusVignette } from '../../../common/convert';
import { getAttributeValue } from '../../../common/convert/attribute';
import { AssetIcon } from '../../../common/icon';
import { parseRecurringFormula } from '../../../common/recurringRules';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Divider } from '../../../uikit/Divider/Divider';
import { Link } from '../../../uikit/Link/Link';
import { SelectedFrequency } from '../../DatePickerContent/RepeatForm/SelectedFrequency';
import { ChangelogItem } from '../../DetailsPanel/Changelog/ChangelogItem/ChangelogItem';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Asterisk, Attachments, CalendarDueDate, Clock, Edit, GitBranch, Mail, MapPin, Minus, NoAttachments, Plus, Recurring, Unassigned } from '../../icons';
import { Vignette } from '../../Vignette/Vignette';
export function MarkerChangelogV2({ changelogList, formatter, taskAssigneeMap, userMap, markerCategoryMap, lookupAssetMap, assetTypeMap, onMarkerLinkClick, unitMap, taskAttributeMap }) {
    const notspecified = _jsx(Ditto, { componentId: "notspecified" });
    const notitle = useDittoWrapper({ componentId: 'tasktitle.notitle' });
    const changelogElements = changelogList.map((changelog) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22;
        const dateTime = formatter.formatDate(changelog.createdAt);
        const trailingIcon = getUserIcon(userMap, changelog.userUuid);
        switch (changelog.operation) {
            case MARKER_CHANGELOG_OPERATION.create:
                return (_jsx(ChangelogItem, { icon: _jsx(Asterisk, {}), to: getMarkerSourceLabel((_a = changelog.newVal) === null || _a === void 0 ? void 0 : _a.sourceId), description: _jsx(Ditto, { componentId: "rightpanel.titletype.task" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateAssignee:
                return (_jsx(ChangelogItem, { icon: _jsx(Unassigned, {}), from: getAssigneeNames(taskAssigneeMap, (_b = changelog.oldVal) === null || _b === void 0 ? void 0 : _b.assignees) ||
                        notspecified, to: getAssigneeNames(taskAssigneeMap, (_c = changelog.newVal) === null || _c === void 0 ? void 0 : _c.assignees) ||
                        notspecified, description: _jsx(Ditto, { componentId: "assignee" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateTitle:
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: ((_d = changelog.oldVal) === null || _d === void 0 ? void 0 : _d.title) || notspecified, to: ((_e = changelog.newVal) === null || _e === void 0 ? void 0 : _e.title) || notspecified, description: _jsx(Ditto, { componentId: "tasks.tasktitle" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateAddress:
                return (_jsx(ChangelogItem, { icon: _jsx(MapPin, {}), from: formatter.formatAddress((_f = changelog.oldVal) === null || _f === void 0 ? void 0 : _f.address) || notspecified, to: formatter.formatAddress((_g = changelog.newVal) === null || _g === void 0 ? void 0 : _g.address) || notspecified, description: _jsx(Ditto, { componentId: "address" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateDescription:
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: ((_h = changelog.oldVal) === null || _h === void 0 ? void 0 : _h.description) || notspecified, to: ((_j = changelog.newVal) === null || _j === void 0 ? void 0 : _j.description) || notspecified, description: _jsx(Ditto, { componentId: "taskdescription" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateStatus:
                return (_jsx(ChangelogItem, { icon: _jsx(MarkerStatusVignette, { value: ((_k = changelog.newVal) === null || _k === void 0 ? void 0 : _k.statusId) || 0 }), from: getMarkerStatusLabel(((_l = changelog.oldVal) === null || _l === void 0 ? void 0 : _l.statusId) || 0), to: getMarkerStatusLabel(((_m = changelog.newVal) === null || _m === void 0 ? void 0 : _m.statusId) || 0), description: _jsx(Ditto, { componentId: "marker.status" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.statusReopen:
                return (_jsxs(Fragment, { children: [_jsx(ChangelogItem, { icon: _jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Recurring, {}) })), backgroundColor: "#D3D5D7" }), to: _jsx(Ditto, { componentId: "tasks.taskreopened" }), description: _jsx(Ditto, { componentId: "marker.status" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }), _jsx(Divider, { height: "8px", margin: "0" })] }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updatePriority:
                return (_jsx(ChangelogItem, { icon: _jsx(MarkerPriorityIcon, { value: ((_o = changelog.newVal) === null || _o === void 0 ? void 0 : _o.priorityId) || 0 }), from: getMarkerPriorityLabel(((_p = changelog.oldVal) === null || _p === void 0 ? void 0 : _p.priorityId) || 0), to: getMarkerPriorityLabel(((_q = changelog.newVal) === null || _q === void 0 ? void 0 : _q.priorityId) || 0), description: _jsx(Ditto, { componentId: "marker.priority" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateCategory:
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: getCategoryBadge(markerCategoryMap, (_r = changelog.oldVal) === null || _r === void 0 ? void 0 : _r.categoryId) ||
                        notspecified, to: getCategoryBadge(markerCategoryMap, (_s = changelog.newVal) === null || _s === void 0 ? void 0 : _s.categoryId) ||
                        notspecified, description: _jsx(Ditto, { componentId: "marker.category" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.addPhotos: {
                const count = ((_u = (_t = changelog.newVal) === null || _t === void 0 ? void 0 : _t.photoIds) === null || _u === void 0 ? void 0 : _u.length) || 0;
                return (_jsx(ChangelogItem, { icon: _jsx(Plus, {}), to: _jsx(Ditto, { componentId: "changelogmarker.changelog.addedximages", count: count, variables: { Amount_Images: count } }), description: _jsx(Ditto, { componentId: "marker.changelog.image" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.removePhotos: {
                const count = ((_w = (_v = changelog.oldVal) === null || _v === void 0 ? void 0 : _v.photoIds) === null || _w === void 0 ? void 0 : _w.length) || 0;
                return (_jsx(ChangelogItem, { icon: _jsx(Minus, {}), to: _jsx(Ditto, { componentId: "changelog.removedximages", count: count, variables: { Amount_Images: count } }), description: _jsx(Ditto, { componentId: "marker.changelog.image" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.updateRecurringFormula: {
                const oldFormula = ((_x = changelog.oldVal) === null || _x === void 0 ? void 0 : _x.recurringFormula)
                    ? parseRecurringFormula(changelog.oldVal.recurringFormula)
                    : undefined;
                const newFormula = ((_y = changelog.newVal) === null || _y === void 0 ? void 0 : _y.recurringFormula)
                    ? parseRecurringFormula(changelog.newVal.recurringFormula)
                    : undefined;
                const oldDate = oldFormula
                    ? formatter.formatDate(oldFormula.startDate).date
                    : undefined;
                const newDate = newFormula
                    ? formatter.formatDate(newFormula.startDate).date
                    : undefined;
                const dateEqual = oldDate === newDate;
                const frequencyEqual = (oldFormula === null || oldFormula === void 0 ? void 0 : oldFormula.frequency.repeat) === (newFormula === null || newFormula === void 0 ? void 0 : newFormula.frequency.repeat);
                return (_jsxs(Fragment, { children: [!dateEqual && (_jsx(ChangelogItem, { icon: _jsx(CalendarDueDate, {}), from: oldDate || notspecified, to: newDate || notspecified, description: _jsx(Ditto, { componentId: "duedate" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon })), !frequencyEqual && (_jsx(ChangelogItem, { icon: _jsx(Recurring, {}), from: oldFormula ? (_jsx(SelectedFrequency, { frequency: oldFormula.frequency })) : (notspecified), to: newFormula ? (_jsx(SelectedFrequency, { frequency: newFormula.frequency })) : (notspecified), description: _jsx(Ditto, { componentId: "tasks.recurringtasks.intervall.repeat" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }))] }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.linkAsset: {
                const assetId = (_z = changelog.newVal) === null || _z === void 0 ? void 0 : _z.manualInventoryId;
                if (!assetId) {
                    return;
                }
                const asset = lookupAssetMap[assetId];
                if (asset === undefined) {
                    return;
                }
                const assetType = assetTypeMap[asset.inventoryTypeId];
                return (_jsx(ChangelogItem, { icon: _jsx(AssetIcon, { iconId: assetType === null || assetType === void 0 ? void 0 : assetType.iconId }), to: asset.title || notitle, description: _jsx(Ditto, { componentId: "assets.assetlinked" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.unlinkAsset: {
                const assetId = (_0 = changelog.oldVal) === null || _0 === void 0 ? void 0 : _0.manualInventoryId;
                if (!assetId) {
                    return;
                }
                const asset = lookupAssetMap[assetId];
                if (asset === undefined) {
                    return;
                }
                const assetType = assetTypeMap[asset.inventoryTypeId];
                return (_jsx(ChangelogItem, { icon: _jsx(AssetIcon, { iconId: assetType === null || assetType === void 0 ? void 0 : assetType.iconId }), to: asset.title || notitle, description: _jsx(Ditto, { componentId: "assets.assetunlinked" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.sharedByEmail: {
                return (_jsx(ChangelogItem, { icon: _jsx(Mail, {}), to: _jsx(Ditto, { componentId: "changelogmarker.changelog.mailsentsubject", variables: {
                            EmailSubject: ((_1 = changelog.newVal) === null || _1 === void 0 ? void 0 : _1.emailSubject) || ''
                        } }), description: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "changelogmarker.changelog.mailsentto", variables: {
                                    EmailRecipient: ((_3 = (_2 = changelog.newVal) === null || _2 === void 0 ? void 0 : _2.emailRecipients) === null || _3 === void 0 ? void 0 : _3.join(', ')) || ''
                                } }), _jsx("br", {}), _jsx(Ditto, { componentId: "changelogmarker.changelog.sentbyemail" })] }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.createFile: {
                return (_jsx(ChangelogItem, { icon: _jsx(Attachments, {}), to: (_4 = changelog.newVal) === null || _4 === void 0 ? void 0 : _4.filename, description: _jsx(Ditto, { componentId: "changelog.fileadded" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, ellipsis: true }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.deleteFile: {
                return (_jsx(ChangelogItem, { icon: _jsx(NoAttachments, {}), to: (_5 = changelog.oldVal) === null || _5 === void 0 ? void 0 : _5.filename, description: _jsx(Ditto, { componentId: "changelog.fileremoved" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, ellipsis: true }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.currentIterationClosed: {
                const markerId = ((_6 = changelog.newVal) === null || _6 === void 0 ? void 0 : _6.pointOfInterestId) || 0;
                const markerTitle = ((_7 = changelog.newVal) === null || _7 === void 0 ? void 0 : _7.title) || notitle;
                return (_jsx(ChangelogItem, { icon: _jsx(GitBranch, {}), to: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "changelog.newtaskiterationcreatedidname" }), ' ', _jsx(Link, Object.assign({ onClick: () => onMarkerLinkClick(markerId) }, { children: `#${markerId} (${markerTitle})` }))] }), description: _jsx(Ditto, { componentId: "rightpanel.titletype.task" }), date: dateTime.date, time: dateTime.time }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.newIterationCreated: {
                const markerId = ((_8 = changelog.newVal) === null || _8 === void 0 ? void 0 : _8.pointOfInterestId) || 0;
                const markerTitle = ((_9 = changelog.newVal) === null || _9 === void 0 ? void 0 : _9.title) || notitle;
                return (_jsx(ChangelogItem, { icon: _jsx(GitBranch, {}), to: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "changelog.newtaskiterationcreatedbasedontaskidname" }), ' ', _jsx(Link, Object.assign({ onClick: () => onMarkerLinkClick(markerId) }, { children: `#${markerId} (${markerTitle})` }))] }), description: _jsx(Ditto, { componentId: "rightpanel.titletype.task" }), date: dateTime.date, time: dateTime.time }, changelog.id));
            }
            case MARKER_CHANGELOG_OPERATION.workingHourAdded:
                return (_jsx(ChangelogItem, { icon: _jsx(Clock, {}), from: formatter.formatDate((_10 = changelog.newVal) === null || _10 === void 0 ? void 0 : _10.startedAt).dateTime, to: formatter.formatDate((_11 = changelog.newVal) === null || _11 === void 0 ? void 0 : _11.finishedAt).dateTime, description: _jsx(Ditto, { componentId: "tasks.costs.workinghoursadded" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, fromOpacity: 1 }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.workingHourEdited:
                return (_jsx(ChangelogItem, { icon: _jsx(Clock, {}), from: formatter.formatDate((_12 = changelog.newVal) === null || _12 === void 0 ? void 0 : _12.startedAt).dateTime, to: formatter.formatDate((_13 = changelog.newVal) === null || _13 === void 0 ? void 0 : _13.finishedAt).dateTime, description: _jsx(Ditto, { componentId: "tasks.costs.workinghourentryedited" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, fromOpacity: 1 }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.workingHourRemoved:
                return (_jsx(ChangelogItem, { icon: _jsx(Clock, {}), from: formatter.formatDate((_14 = changelog.oldVal) === null || _14 === void 0 ? void 0 : _14.startedAt).dateTime, to: formatter.formatDate((_15 = changelog.oldVal) === null || _15 === void 0 ? void 0 : _15.finishedAt).dateTime, description: _jsx(Ditto, { componentId: "tasks.costs.workinghourentryremoved" }), date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon, fromOpacity: 1 }, changelog.id));
            case MARKER_CHANGELOG_OPERATION.updateAttribute: {
                const attributeSchemaId = ((_17 = (_16 = changelog.oldVal) === null || _16 === void 0 ? void 0 : _16.markerAttribute) === null || _17 === void 0 ? void 0 : _17.poiAttributeSchemaId) ||
                    ((_19 = (_18 = changelog.newVal) === null || _18 === void 0 ? void 0 : _18.markerAttribute) === null || _19 === void 0 ? void 0 : _19.poiAttributeSchemaId);
                if (!attributeSchemaId) {
                    return;
                }
                const attributeSchema = taskAttributeMap[attributeSchemaId];
                if (!attributeSchema) {
                    return;
                }
                const unit = attributeSchema.unitId
                    ? (_20 = unitMap[attributeSchema.unitId]) === null || _20 === void 0 ? void 0 : _20.abbreviation
                    : undefined;
                return (_jsx(ChangelogItem, { icon: _jsx(Edit, {}), from: getAttributeValue(attributeSchema, formatter, (_21 = changelog.oldVal) === null || _21 === void 0 ? void 0 : _21.markerAttribute, unit) || notspecified, to: getAttributeValue(attributeSchema, formatter, (_22 = changelog.newVal) === null || _22 === void 0 ? void 0 : _22.markerAttribute, unit) || notspecified, description: attributeSchema.name, date: dateTime.date, time: dateTime.time, trailingIcon: trailingIcon }, changelog.id));
            }
        }
    });
    return _jsx(_Fragment, { children: changelogElements });
}
