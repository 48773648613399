// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xxTyqYWpHaLXPxmmudWQ{display:inline-flex;justify-content:center;align-items:center;background:var(--primary-grey);border-radius:6px;height:24px;padding:4px}.AyZu8U9yh8DUWX68vIIn{text-align:center;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/Result/Marker/ClosedDate/ClosedDate.module.css"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,8BAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CAGJ,sBACI,iBAAA,CAEA,eAAA","sourcesContent":[".container {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    background: var(--primary-grey);\n    border-radius: 6px;\n    height: 24px;\n    padding: 4px;\n}\n\n.text {\n    text-align: center;\n\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `xxTyqYWpHaLXPxmmudWQ`,
	"text": `AyZu8U9yh8DUWX68vIIn`
};
export default ___CSS_LOADER_EXPORT___;
