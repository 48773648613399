import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import styles from './FilterPreviewMany.module.css';
export function FilterPreviewMany({ filters, name, formatter }) {
    const notspecified = (_jsx("span", { children: _jsx(Ditto, { componentId: "notspecified" }) }));
    const specified = (_jsx("span", { children: _jsx(Ditto, { componentId: "filter.isadded" }) }));
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [filters.length === 0 && name, filters.map((filter) => (_jsxs("span", Object.assign({ className: styles['block'] }, { children: [_jsx("span", Object.assign({ className: styles['label'] }, { children: filter.label })), _jsxs("span", Object.assign({ className: styles['value'] }, { children: [filter.type === 'select' &&
                                (filter.selectValue.length === 1
                                    ? filter.selectValue[0]
                                    : filter.selectValue.length), filter.type === 'number' &&
                                (filter.showNotSpecified ? (notspecified) : (_jsxs(_Fragment, { children: [filter.numberValue && (_jsx("span", { children: `${filter.numberValue.min || filter.limit.min}-${filter.numberValue.max || filter.limit.max} ${filter.unit}` })), !filter.numberValue && specified] }))), filter.type === 'date' &&
                                (filter.showNotSpecified ? (notspecified) : (_jsxs(_Fragment, { children: [filter.dateValue && (_jsxs("span", { children: [formatter.formatDateISO(filter.dateValue.from), ' - ', formatter.formatDateISO(filter.dateValue.to)] })), !filter.dateValue && specified] }))), filter.type === 'text' &&
                                (filter.showNotSpecified ? notspecified : specified)] }))] }), filter.id)))] })));
}
