// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WMY9EHcYvpHBMFwCJw9_{height:75vh;overflow:hidden}.rEBFEQUAKah__2kwmYcO{display:flex;flex-direction:column;overflow:auto;height:50vh;width:100%;margin-top:6px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/SignTypeSelectModal/SignTypeSelectModal.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".modal {\n    height: 75vh;\n    overflow: hidden;\n}\n\n.filter-groups {\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n    height: 50vh;\n    width: 100%;\n    margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `WMY9EHcYvpHBMFwCJw9_`,
	"filter-groups": `rEBFEQUAKah__2kwmYcO`
};
export default ___CSS_LOADER_EXPORT___;
