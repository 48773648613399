import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FilterSelectbox } from '../../../FilterSelectbox/FilterSelectbox';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { CaretCloseSmall, CaretOpenSmall } from '../../../icons';
import styles from './TreatmentSelectGroup.module.css';
export function TreatmentSelectGroup({ title, icon, initiallyOpen, isForcedOpen, children }) {
    const [isOpen, setIsOpen] = useState(initiallyOpen);
    function toggle() {
        setIsOpen(!isOpen);
    }
    return (_jsxs(_Fragment, { children: [_jsx(FilterSelectbox, { className: styles['container'], label: _jsx("span", Object.assign({ className: styles['title'] }, { children: title })), onClick: toggle, icon: icon, noCheckbox: true, trailingIcon: !isForcedOpen && (_jsx("div", Object.assign({ className: styles['toggle-icon'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: isOpen ? _jsx(CaretOpenSmall, {}) : _jsx(CaretCloseSmall, {}) })) }))) }), (isForcedOpen || isOpen) && children] }));
}
