// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Up4_BZSNuodooxzQzNE{display:flex;align-items:flex-start;gap:10px;border-radius:6px;font-weight:600;font-size:14px;line-height:16px;text-align:center}.JTDxRSYyURdB2AbJUOvA{background:var(--status-hover-hover-on-grey);height:18px;padding:1px 5px}.CKIEvsLGQoNIovBOY_uW{padding:7px 12px;background:var(--status-hover-hover-on-dark);color:var(--primary-white)}.MylDNIuvhgGXbRzs2uRG{background:var(--primary-dark-blue);height:18px;padding:1px 5px;color:var(--primary-white)}`, "",{"version":3,"sources":["webpack://./src/uikit/NotificationBadge/NotificationBadge.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sBAAA,CACA,QAAA,CACA,iBAAA,CAEA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAGJ,sBACI,4CAAA,CACA,WAAA,CACA,eAAA,CAGJ,sBACI,gBAAA,CACA,4CAAA,CACA,0BAAA,CAGJ,sBACI,mCAAA,CACA,WAAA,CACA,eAAA,CACA,0BAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: flex-start;\n    gap: 10px;\n    border-radius: 6px;\n\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    text-align: center;\n}\n\n.container__default {\n    background: var(--status-hover-hover-on-grey);\n    height: 18px;\n    padding: 1px 5px;\n}\n\n.container__popover {\n    padding: 7px 12px;\n    background: var(--status-hover-hover-on-dark);\n    color: var(--primary-white);\n}\n\n.container__dark {\n    background: var(--primary-dark-blue);\n    height: 18px;\n    padding: 1px 5px;\n    color: var(--primary-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_Up4_BZSNuodooxzQzNE`,
	"container__default": `JTDxRSYyURdB2AbJUOvA`,
	"container__popover": `CKIEvsLGQoNIovBOY_uW`,
	"container__dark": `MylDNIuvhgGXbRzs2uRG`
};
export default ___CSS_LOADER_EXPORT___;
