// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E_n5AEf2VrCfv1uAZNAE{padding:0;vertical-align:bottom}.z8SZmmT25PHZhYUtswNe{position:sticky;left:0;width:calc(100vw - 72px);height:106px;background:var(--primary-grey);padding:0 4px}.qjnzImh0SQu1pEotHkVh{width:calc(100vw - 72px - 356px)}.Vj4wHRg1ci529e3SQcLJ{height:56px;width:100%;flex-direction:row;justify-content:space-between;padding-left:48px}.fR43r6c8yyfOU4HNjpPF{position:sticky;right:0}.Vj4wHRg1ci529e3SQcLJ>span{opacity:1}.RYfAkq0bYLzEF44ZvqVi{display:none}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTablePagination/DataGridTablePagination.module.css"],"names":[],"mappings":"AAAA,sBACI,SAAA,CACA,qBAAA,CAGJ,sBACI,eAAA,CACA,MAAA,CACA,wBAAA,CACA,YAAA,CACA,8BAAA,CACA,aAAA,CAGJ,sBACI,gCAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA,CAGJ,sBACI,eAAA,CACA,OAAA,CAGJ,2BACI,SAAA,CAGJ,sBACI,YAAA","sourcesContent":[".wrapper {\n    padding: 0;\n    vertical-align: bottom;\n}\n\n.container {\n    position: sticky;\n    left: 0;\n    width: calc(100vw - 72px);\n    height: 106px;\n    background: var(--primary-grey);\n    padding: 0 4px;\n}\n\n.container--right-panel-open {\n    width: calc(100vw - 72px - 356px);\n}\n\n.pagination {\n    height: 56px;\n    width: 100%;\n    flex-direction: row;\n    justify-content: space-between;\n    padding-left: 48px;\n}\n\n.pagination-buttons {\n    position: sticky;\n    right: 0;\n}\n\n.pagination > span {\n    opacity: 1;\n}\n\n.hide {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `E_n5AEf2VrCfv1uAZNAE`,
	"container": `z8SZmmT25PHZhYUtswNe`,
	"container--right-panel-open": `qjnzImh0SQu1pEotHkVh`,
	"pagination": `Vj4wHRg1ci529e3SQcLJ`,
	"pagination-buttons": `fR43r6c8yyfOU4HNjpPF`,
	"hide": `RYfAkq0bYLzEF44ZvqVi`
};
export default ___CSS_LOADER_EXPORT___;
