import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useDittoWrapper } from '../../../../../hooks/useDittoWrapper';
import { SettingRow } from '../../SettingRow/SettingRow';
import { IconSelect } from '../IconSelect/IconSelect';
import { AssetAddPropertySelect } from './AssetAddPropertySelect';
import { AssetAttributeOptions } from './AssetAttributeOptions';
import { AssetAttributeRow } from './AssetAttributeRow';
export function AssetTypeRow({ index: asIdx, assetType, currentTypeRef, context }) {
    const newtype = useDittoWrapper({ componentId: 'assets.newtype' });
    const [dragDisabled, setDragDisabled] = useState(false);
    const { isReadOnly, scrollToSelectedType, onAssetTypeDelete, onAssetTypeIconChange, onAssetTypeChange, onAttributeDragEnd, onAttributeCreate } = context;
    return (_jsx(Draggable, Object.assign({ draggableId: String(assetType.id), index: asIdx, disableInteractiveElementBlocking: false, isDragDisabled: isReadOnly || dragDisabled }, { children: (provided) => (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { tabIndex: -1 }, { children: _jsxs(SettingRow, Object.assign({ ref: assetType.id === scrollToSelectedType ? currentTypeRef : undefined, onDelete: isReadOnly ? undefined : () => onAssetTypeDelete(asIdx), icon: _jsx(IconSelect, { iconId: assetType.iconId, onSelect: isReadOnly ? undefined : (v) => onAssetTypeIconChange(asIdx, v) }), value: assetType.name, autoFocus: assetType.name === '', placeholder: newtype, count: assetType.attributes.length, onChange: assetType.defaultId || isReadOnly
                    ? undefined
                    : (v) => onAssetTypeChange(asIdx, v), initiallyExpanded: assetType.id === scrollToSelectedType, draggable: !isReadOnly, expandable: true }, { children: [_jsx("div", Object.assign({ onMouseEnter: () => setDragDisabled(true), onMouseLeave: () => setDragDisabled(false) }, { children: _jsx(DragDropContext, Object.assign({ onDragEnd: (params) => onAttributeDragEnd(asIdx, params) }, { children: _jsx(Droppable, Object.assign({ droppableId: "droppableAssetAttrs", isDropDisabled: context.isReadOnly }, { children: (provided) => (_jsxs("div", Object.assign({ ref: provided.innerRef }, provided.droppableProps, { children: [assetType.attributes.map((attribute, atIdx) => (_jsx(AssetAttributeRow, Object.assign({ assetIndex: asIdx, attrIndex: atIdx, attribute: attribute, context: context }, { children: _jsx(AssetAttributeOptions, { assetIndex: asIdx, attrIndex: atIdx, attribute: attribute, context: context }) }), attribute.id || `new-${atIdx}`))), provided.placeholder] }))) })) })) })), !isReadOnly && (_jsx(AssetAddPropertySelect, { indentLevel: 1, onSelect: (v) => onAttributeCreate(asIdx, v) }))] }), asIdx) }))) }), assetType.id || `new-${asIdx}`));
}
