import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Divider } from '../../../uikit/Divider/Divider';
import { Close } from '../../icons';
import { Modal } from '../../Modal/Modal';
export function ExportModal({ requestList, onClose, onStartExportClick }) {
    function getExportTypeDittoId(type) {
        switch (type) {
            case 'segment':
                return 'export.namesegment';
            case 'point':
                return 'points';
            case 'marker':
                return 'layer.tasks';
            case 'plan':
                return 'layerpanel.maplayermodal.plannedsegments';
            case 'alert':
                return 'layerpanel.maplayermodal.roadsafetyalerts';
            case 'track-control':
                return 'trackcontrols';
            case 'winter-service':
                return 'winterservice.winterservices';
            case 'sign-inventory':
                return 'layerpanel.maplayermodal.signs';
            case 'object':
                return 'layerpanel.maplayermodal.objects';
            case 'asset':
                return 'assets.assets';
            default:
                return '';
        }
    }
    return (_jsx(Modal, { title: _jsx(Ditto, { componentId: "fileexport" }), "data-test": "export-modal", onClickOutside: onClose, trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: onClose }), content: _jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "fileexport.description" }), _jsx(Divider, { margin: "12px" }), _jsx("strong", { children: _jsx(Ditto, { componentId: "fileexport.request" }) }), requestList.map(({ type, count }) => (_jsxs("p", { children: [count, " ", _jsx(Ditto, { componentId: getExportTypeDittoId(type), count: count })] }, type)))] }), footer: _jsx(Button, Object.assign({ onClick: onStartExportClick, color: "dark", "data-test": "start-to-export" }, { children: _jsx(Ditto, { componentId: "fileexport.cta" }) })) }));
}
