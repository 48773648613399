import { useEffect } from 'react';
import { getParentElements } from '../common/utils/parentElements';
export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            // Since this element (ref) can have a pseudo-child that is rendered
            // outside of the current DOM tree, we need to handle this case.
            // Examples where we need it:
            // - Modal with a FloatingDropdown child
            // - DropMenu with a FloatingDropdown child
            const nodes = [
                event.target,
                ...getParentElements(event.target)
            ];
            // Ignore if the ref is not a child of target and
            // the target or one of its parents is an overlay.
            if (!event.target.contains(ref.current) &&
                nodes.some((node) => !!node.getAttribute('data-overlay'))) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
