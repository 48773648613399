var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_EVENT_VALUE } from '../../common/consts/mixpanel';
import { ALERT_OBJECT_TYPES } from '../../common/consts/object';
import { TAB_LIST_2 } from '../../common/consts/panelTab';
import { getLevelIcon, getObjectLevelLabel, objectDataToContent } from '../../common/convert';
import { selectRangeObjects } from '../../common/multiselect';
import { objectSortingOptions } from '../../common/sort';
import { toggleObjectInArray } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { Download, Mail, ObjectManhole, ObjectStormdrain, Print, Task, Warning1 } from '../../components/icons';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { LeftPanelListHeader } from '../../components/LeftPanelListHeader/LeftPanelListHeader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { SelectionBar } from '../../components/SelectionBar/SelectionBar';
import { TypedDitto } from '../../components/TypedDitto';
import { Vignette } from '../../components/Vignette/Vignette';
import { useObjectFilterHandlers } from '../../hooks/filters/useObjectFilterHandlers';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useMarkerCreatorOpener } from '../../hooks/useMarkerCreatorOpener';
import { useObjectTypes } from '../../hooks/useObjectTypes';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHighlightedFeature, clearSelectedFeature, setHighlightedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { clearSelectedObject, exportObjectsTk, fetchObjectList, resetObjectFilter, resetObjectMultiSelect, setCurrentPage, setManholeObjectFilter, setObjectActiveTabKey, setObjectClickedId, setObjectSelectedList, setObjectSorting, setSelectedObject, setStormDrainObjectFilter, setUseMapBounds, startObjectMultiSelect } from '../../state/slices/leftPanelObject';
import { setMarkerBulkCreatorPointSource } from '../../state/slices/markerBulkCreator';
import { Button } from '../../uikit/Button/Button';
const Object_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'track-control'
    }, isLoading: true }, idx)));
export function LeftPanelObjectContainer() {
    const panelContainerRef = useRef(null);
    const { addModal } = useModalContext();
    const { resultList, totalCount, filter, sort, selectedObjectList, currentPage, resultFetchStatus, useMapBounds, activeTabKey, firstThousandIds } = useAppSelector((state) => state.leftPanelObject);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const { teamAccount, userAccount } = useAppSelector((state) => state.auth);
    const { isMultiSelectOn, selectedObject } = useAppSelector((state) => state.leftPanelObject);
    const { manholeOptions, stormDrainOptions } = useObjectTypes();
    const dispatch = useAppDispatch();
    const { handleManholeTypeFilterClick, handleStormDrainTypeFilterClick } = useObjectFilterHandlers();
    const { handleAddMarkerFromPointButtonClick } = useMarkerCreatorOpener();
    const dateTimeFormatter = useDateTimeFormatter();
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const { isEditor, isViewer } = useUserRole();
    const filtersCount = filter.manholeType.length + filter.stormDrainType.length;
    function handleResetFilter() {
        dispatch(resetObjectFilter());
    }
    function handleResetSelection() {
        dispatch(resetObjectMultiSelect());
        dispatch(setObjectSelectedList([]));
        dispatch(clearSelectedFeature());
        dispatch(clearSelectedObject());
    }
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleManholeGroupClick() {
        dispatch(setManholeObjectFilter(Object.assign(Object.assign({}, filter), { manholeType: filter.manholeType.length === 0 ? manholeOptions.map((it) => it.name) : [] })));
    }
    function handleStormDrainGroupClick() {
        dispatch(setStormDrainObjectFilter(Object.assign(Object.assign({}, filter), { stormDrainType: filter.stormDrainType.length === 0 ? stormDrainOptions.map((it) => it.name) : [] })));
    }
    function handleSort(id) {
        dispatch(setObjectSorting(id));
        dispatch(setCurrentPage(PAGE_NUMBER_FIRST));
    }
    function handleCheckSelectAll() {
        if (selectedObjectList.length === firstThousandIds.length) {
            dispatch(setObjectSelectedList([]));
        }
        else {
            dispatch(setObjectSelectedList(firstThousandIds));
        }
    }
    function handleBulkCreateMarker() {
        if (selectedObjectList.length === 0) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.createTaskFromObject, {
            [MIXPANEL_EVENT_PROPERTY.taskCreatorSource]: MIXPANEL_EVENT_VALUE.multiselect
        });
        const idList = selectedObjectList.map((item) => item.point_id);
        dispatch(setMarkerBulkCreatorPointSource({ pointIds: idList }));
    }
    const objectEquals = (a, b) => {
        return (a.detected_object_type_id === b.detected_object_type_id &&
            a.point_id === b.point_id &&
            a.record_data_id === b.record_data_id);
    };
    function handleSelectObject(item, event) {
        if (!isMultiSelectOn) {
            dispatch(startObjectMultiSelect());
        }
        const selectedItem = {
            detected_object_type_id: item.detected_object_type_id,
            point_id: item.pointId,
            record_data_id: item.record_data_id
        };
        dispatch(setObjectSelectedList(toggleObjectInArray(selectedObjectList, selectedItem, objectEquals)));
        if (event === null || event === void 0 ? void 0 : event.nativeEvent.shiftKey) {
            dispatch(setObjectSelectedList(selectRangeObjects(selectedItem, resultList, selectedObjectList)));
        }
    }
    function handleClickObject(item) {
        dispatch(setObjectClickedId(item.pointId));
        dispatch(setSelectedObject(item));
        dispatch(setSelectedFeature({
            type: 'object',
            id: item.pointId
        }));
    }
    function handleExport() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const combinedKeys = isMultiSelectOn
                ? selectedObjectList.map((object) => ({
                    recordId: object.record_data_id,
                    detectedObjectTypeId: object.detected_object_type_id
                }))
                : undefined;
            addModal({
                id: 'ExportModal',
                props: {
                    onStartExportClick: () => dispatch(exportObjectsTk({ combinedKeys })),
                    requestList: [
                        {
                            type: 'object',
                            count: (_a = combinedKeys === null || combinedKeys === void 0 ? void 0 : combinedKeys.length) !== null && _a !== void 0 ? _a : totalCount
                        }
                    ]
                }
            });
        });
    }
    function handleMail() {
        // TODO: POLISHING
        // addModal({ id: 'MailModal' });
    }
    function handlePrint() {
        // window.open(
        // TODO: POLISHING
        //     `/${ROOT_PATH.print}/${LAYER_PATH.object}?key=${selectedIdList.join(',')}`,
        //     '_blank'
        // );
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setCurrentPage(toPage));
    }
    function handleClickTab(tabId) {
        dispatch(setObjectActiveTabKey(tabId));
        if (tabId === 'list') {
            dispatch(setCurrentPage(PAGE_NUMBER_FIRST));
        }
    }
    function handleImageEnlargeClick(item) {
        handleClickObject(item);
        addModal({ id: 'ImageViewerObject' });
    }
    function handleCheckMapView() {
        dispatch(setUseMapBounds(!useMapBounds));
        handleClickTab('list');
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    useEffect(() => {
        if (useMapBounds) {
            dispatch(fetchObjectList());
        }
    }, [
        dispatch,
        filter.manholeType,
        filter.stormDrainType,
        useMapBounds,
        sort,
        currentPage,
        bounds,
        selectedOption
    ]);
    useEffect(() => {
        if (!useMapBounds) {
            dispatch(fetchObjectList());
        }
    }, [
        dispatch,
        filter.manholeType,
        filter.stormDrainType,
        useMapBounds,
        sort,
        currentPage,
        selectedOption
    ]);
    useEffect(() => {
        if (selectedObjectList.length > 0 && !isMultiSelectOn) {
            dispatch(startObjectMultiSelect());
        }
        else if (selectedObjectList.length === 0 && isMultiSelectOn) {
            dispatch(resetObjectMultiSelect());
        }
    }, [dispatch, isMultiSelectOn, selectedObjectList.length]);
    useEffect(() => {
        if (activeTabKey === 'filter' && isMultiSelectOn) {
            dispatch(resetObjectMultiSelect());
        }
    }, [dispatch, activeTabKey, isMultiSelectOn]);
    const resultsCurrentPage = (() => {
        if (resultFetchStatus === 'loading') {
            return Object_LOADING_LIST;
        }
        if (resultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return resultList.map((item, index) => (_jsx(Result, { content: {
                type: 'object',
                value: objectDataToContent(item, dateTimeFormatter, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.manHoleStormDrainSpecifiedClassesEnabled, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode)
            }, isSelectable: isMultiSelectOn, isHighlighted: selectedObject === item, isActive: selectedObjectList.some((key) => key.point_id === item.pointId &&
                key.detected_object_type_id === item.detected_object_type_id), isEditor: isEditor, onSelect: (event) => handleSelectObject(item, event), onClick: () => handleClickObject(item), onImageEnlargeClick: () => handleImageEnlargeClick(item), onAddMarkerButtonClick: () => handleAddMarkerFromPointButtonClick(item.pointId, true), onMouseEnter: () => dispatch(setHighlightedFeature({ type: 'object', id: item.pointId })), onMouseLeave: () => dispatch(clearHighlightedFeature()) }, `obj-res-list-${index}`)));
    })();
    const manholeFilterOptions = () => {
        if (manholeOptions.length === 0) {
            return _jsx(_Fragment, {});
        }
        if (manholeOptions.length === 1) {
            const o = manholeOptions[0];
            return (_jsx(FilterSelectbox, { onClick: () => handleManholeTypeFilterClick(o.name), isChecked: filter.manholeType.includes(o.name), icon: _jsx(ObjectManhole, {}), content: _jsx(Ditto, { componentId: "filter.sewage.manholes" }), color: "black", trailingElement: o.count || 0 }, `manhole-filter`));
        }
        return (_jsx(FilterGroupWrapper, Object.assign({ icon: _jsx(ObjectManhole, {}), content: _jsx(Ditto, { componentId: "filter.sewage.manholes" }), checkState: getCheckboxCheckState(manholeOptions.length, filter.manholeType.length), onCheckboxClick: handleManholeGroupClick }, { children: manholeOptions.map((o, index) => (_jsx(FilterSelectbox, { onClick: () => handleManholeTypeFilterClick(o.name), isChecked: filter.manholeType.includes(o.name), icon: getLevelIcon(o.name), content: getObjectLevelLabel(o.name), color: "black", trailingIcon: ALERT_OBJECT_TYPES.includes(o.name) && (_jsx(Vignette, { color: "rgba(255, 62, 62, 1)", content: _jsx(Warning1, {}) })), trailingElement: o.count || 0 }, `${o.name}-${index}`))) })));
    };
    const stormDrainFilterOptions = () => {
        if (stormDrainOptions.length === 0) {
            return _jsx(_Fragment, {});
        }
        if (stormDrainOptions.length === 1) {
            const o = stormDrainOptions[0];
            return (_jsx(FilterSelectbox, { onClick: () => handleStormDrainTypeFilterClick(o.name), isChecked: filter.stormDrainType.includes(o.name), icon: _jsx(ObjectStormdrain, {}), content: _jsx(Ditto, { componentId: "filter.sewage.stormdrains" }), color: "black", trailingElement: o.count || 0 }, `stormDrain-filter`));
        }
        return (_jsx(FilterGroupWrapper, Object.assign({ icon: _jsx(ObjectStormdrain, {}), content: _jsx(Ditto, { componentId: "filter.sewage.stormdrains" }), checkState: getCheckboxCheckState(stormDrainOptions.length, filter.stormDrainType.length), onCheckboxClick: handleStormDrainGroupClick }, { children: stormDrainOptions.map((o, index) => (_jsx(FilterSelectbox, { onClick: () => handleStormDrainTypeFilterClick(o.name), isChecked: filter.stormDrainType.includes(o.name), icon: getLevelIcon(o.name), content: getObjectLevelLabel(o.name), color: "black", trailingIcon: ALERT_OBJECT_TYPES.includes(o.name) && (_jsx(Vignette, { color: "rgba(255, 62, 62, 1)", content: _jsx(Warning1, {}) })), trailingElement: o.count || 0 }, `${o.name}-${index}`))) })));
    };
    const objectFilter = (_jsx("div", { children: _jsxs(LayerFilter, { children: [manholeFilterOptions(), stormDrainFilterOptions()] }) }));
    const bottomMenu = (function () {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
        if (activeTabKey === 'list' && isMultiSelectOn) {
            return (_jsxs(SelectionBar, Object.assign({ leftButton: isViewer ? (_jsx(_Fragment, {})) : (_jsx(Button, Object.assign({ disabled: !selectedObjectList.length, icon: _jsx(Task, {}), color: "yellow", onClick: handleBulkCreateMarker, variant: "slender" }, { children: _jsx(TypedDitto, { componentId: "creatextasks", variables: {
                            SelectedResults: selectedObjectList.length
                        }, count: selectedObjectList.length }) }))) }, { children: [_jsx(ActionButton, { disabled: true, icon: _jsx(Mail, {}), onClick: handleMail }), _jsx(ActionButton, { disabled: true, icon: _jsx(Print, {}), onClick: handlePrint }), _jsx(ActionButton, { disabled: !isMultiSelectOn || !selectedObjectList.length || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email), icon: _jsx(Download, {}), onClick: handleExport })] })));
        }
    })();
    return (_jsx(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: _jsx(Ditto, { componentId: "layerpanel.maplayermodal.objects" }), resetFilter: {
                filtersCount,
                onReset: handleResetFilter,
                disabled: filtersCount === 0
            }, exportXLS: {
                onExport: handleExport,
                disabled: totalCount === 0 || !(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)
            }, multiselect: isMultiSelectOn
                ? {
                    text: (_jsx(TypedDitto, { componentId: "listpanel.multiselect.x_selected_variabel", variables: { Amount: selectedObjectList.length }, count: selectedObjectList.length })),
                    onCloseClick: handleResetSelection
                }
                : undefined, onCollapse: handleCollapse }), tabBar: _jsx(PanelButtonGroup, { tabs: TAB_LIST_2, activeKey: activeTabKey, onClickTab: handleClickTab }), ref: panelContainerRef, activeTabKey: activeTabKey, listHeader: _jsx(LeftPanelListHeader, { onFilterCurrentMapViewClick: handleCheckMapView, onSelectAllClick: handleCheckSelectAll, filterUseMapBounds: !!useMapBounds, selectedCount: selectedObjectList.length, resultsCount: totalCount, sort: {
                options: objectSortingOptions,
                onSort: handleSort,
                current: sort
            } }), pagination: activeTabKey === 'list' && resultList.length !== 0 ? (_jsx(Pagination, { totalItems: totalCount, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined, bottomMenu: bottomMenu }, { children: activeTabKey === 'list' ? resultsCurrentPage : objectFilter })));
}
