// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LjplufTBn1sRYZ_SkJHP{display:flex;height:40px;padding:9.5px var(--Gap-GapM, 4px) 9.5px var(--Padding-PaddingM, 12px);align-items:center;gap:3px;align-self:stretch;justify-content:space-between;border-radius:var(--CornerRadius-CornerS, 6px);background:var(--utility-transparent, rgba(0, 0, 0, 0))}.ftWR3O0UCTSqub60L8Go{font-size:14px;line-height:130%;opacity:.6}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/SectionDescription/SectionDescription.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,WAAA,CACA,sEAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CACA,6BAAA,CACA,8CAAA,CACA,uDAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".content-header {\n    display: flex;\n    height: 40px;\n    padding: 9.5px var(--Gap-GapM, 4px) 9.5px var(--Padding-PaddingM, 12px);\n    align-items: center;\n    gap: 3px;\n    align-self: stretch;\n    justify-content: space-between;\n    border-radius: var(--CornerRadius-CornerS, 6px);\n    background: var(--utility-transparent, rgba(0, 0, 0, 0));\n}\n\n.subtitle {\n    font-size: 14px;\n    line-height: 130%; /* 18.2px */\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-header": `LjplufTBn1sRYZ_SkJHP`,
	"subtitle": `ftWR3O0UCTSqub60L8Go`
};
export default ___CSS_LOADER_EXPORT___;
