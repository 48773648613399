// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RTKhxnjTLIM0NSvAxNxc{display:flex;gap:4px;width:100%;font-size:12px;font-style:normal;font-weight:400;line-height:18px}.BNJ_SsZxJZpKIYg9GaZr{flex:1}.CXGQxFf_4NBcvbkjBQmV{display:flex;justify-content:space-between;width:100%}.diQ3QpbFRvbVP0Bthjns{width:4px;height:12px;margin:3px 0;background:var(--primary-white)}.CGzRAcnG67AFDMW4SGKN,.sYWFyqRgKPBUTHxF7FDe{opacity:.4}`, "",{"version":3,"sources":["webpack://./src/components/BudgetTooltip/BudgetTooltipTreatmentItem/BudgetTooltipTreatmentItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA,CACA,UAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGJ,sBACI,MAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CACA,UAAA,CAGJ,sBACI,SAAA,CACA,WAAA,CACA,YAAA,CACA,+BAAA,CAGJ,4CAEI,UAAA","sourcesContent":[".container {\n    display: flex;\n    gap: 4px;\n    width: 100%;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 18px; /* 150% */\n}\n\n.content {\n    flex: 1;\n}\n\n.row {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.indicator {\n    width: 4px;\n    height: 12px;\n    margin: 3px 0;\n    background: var(--primary-white);\n}\n\n.percent,\n.value {\n    opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RTKhxnjTLIM0NSvAxNxc`,
	"content": `BNJ_SsZxJZpKIYg9GaZr`,
	"row": `CXGQxFf_4NBcvbkjBQmV`,
	"indicator": `diQ3QpbFRvbVP0Bthjns`,
	"percent": `CGzRAcnG67AFDMW4SGKN`,
	"value": `sYWFyqRgKPBUTHxF7FDe`
};
export default ___CSS_LOADER_EXPORT___;
