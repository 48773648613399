import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { AssigneePreview } from '../../../AppliedFilterBar/AssigneePreview/AssigneePreview';
import { FormInputMultiSelect } from '../../../FormInputMultiSelect/FormInputMultiSelect';
import { Unassigned } from '../../../icons';
import styles from './AssigneeSelectField.module.css';
export function AssigneeSelectField({ isDark = false, width = 332, options, selectedValues = [], variant, disabled, onClick, label }) {
    return (_jsx("div", Object.assign({ className: styles['container'], style: { width }, onClick: () => !disabled && (onClick === null || onClick === void 0 ? void 0 : onClick()), onKeyDown: (event) => {
            if (event.key === 'Enter') {
                !disabled && (onClick === null || onClick === void 0 ? void 0 : onClick());
            }
        } }, { children: _jsx(FormInputMultiSelect, { isDark: isDark, label: label || _jsx(Ditto, { componentId: "assignee" }), width: width, value: selectedValues.length > 0 ? (_jsx(AssigneePreview, { options: options, selectedValues: selectedValues })) : undefined, icon: _jsx(Unassigned, {}), variant: variant, disabled: disabled, hideMenu: true, "data-test": "task-select-assignee" }) })));
}
