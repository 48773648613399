import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { classes } from 'classifizer';
import { useOnClickWithinElementBounds } from '../../../hooks/useOnClickWithinElementBounds';
import styles from './ImageViewerModal.module.css';
export function ImageViewerModal({ imageViewer, creator, onClickOutside, isBanner }) {
    const ref = useRef(null);
    useOnClickWithinElementBounds(ref, (inside) => {
        if (!inside) {
            onClickOutside === null || onClickOutside === void 0 ? void 0 : onClickOutside();
        }
    });
    return (_jsx("div", Object.assign({ ref: ref, className: classes(styles['container'], isBanner && styles['container--banner']) }, { children: creator ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classes(styles['wrapper']) }, { children: imageViewer })), creator] })) : (_jsx(_Fragment, { children: imageViewer })) })));
}
