import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Close } from '../../icons';
import { LinkedTasksSelect } from '../../LinkedTasksSelect/LinkedTasksSelect';
import { Modal } from '../../Modal/Modal';
import styles from './LinkTasksModal.module.css';
export function LinkTasksModal({ status, results, selectedValues, markerIdsToLink, getAttachmentMarker, onInputChange, onSelect, onClose, onDone }) {
    const [values, setValues] = useState(selectedValues);
    function handleInputValueChange(value) {
        onInputChange(value);
    }
    function handleSelect(ids) {
        setValues(ids);
        onSelect(ids);
    }
    function handleCloseClick() {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    function handleDoneClick() {
        onDone === null || onDone === void 0 ? void 0 : onDone(values);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    const linkedTasks = (_jsx(LinkedTasksSelect, { status: status, results: results, selectedValues: selectedValues, markerIdsToLink: markerIdsToLink, onSelect: handleSelect, getAttachmentMarker: getAttachmentMarker, onInputValueChange: handleInputValueChange }));
    return (_jsx(Modal, { width: 480, title: _jsx(Ditto, { componentId: "tasks.linktask" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: linkedTasks, footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['footer-divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx("div", Object.assign({ className: styles['button-reset'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleCloseClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })), _jsx("div", Object.assign({ className: styles['button-confirm'] }, { children: _jsx(Button, Object.assign({ color: "dark", variant: 'fullwidth', onClick: handleDoneClick }, { children: _jsx(Ditto, { componentId: "done" }) })) }))] }))] })), onClickOutside: handleCloseClick }));
}
