// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B3NATLXkAEZ_evLgrAbY{width:244px;height:140px}.XbHULIAU8soiF7LyLWuc{height:100%;width:100%;object-fit:cover;border-radius:4px 4px 0 0;background:#f0f0f0}.yL1pRyJa0deeXqJsXZ5u{display:flex;align-items:center;justify-content:space-between;height:30px;flex-wrap:nowrap;max-width:244px}.yL1pRyJa0deeXqJsXZ5u span{font-size:14px;line-height:130%;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/Marker/Marker.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,yBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CAGJ,2BACI,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".image-wrapper {\n    width: 244px;\n    height: 140px;\n}\n\n.image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 4px 4px 0 0;\n    background: #f0f0f0;\n}\n\n.info {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 30px;\n    flex-wrap: nowrap;\n    max-width: 244px;\n}\n\n.info span {\n    font-size: 14px;\n    line-height: 130%;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-wrapper": `B3NATLXkAEZ_evLgrAbY`,
	"image": `XbHULIAU8soiF7LyLWuc`,
	"info": `yL1pRyJa0deeXqJsXZ5u`
};
export default ___CSS_LOADER_EXPORT___;
