import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { DetailsInfo } from '../../DetailsPanel/DetailsInfo/DetailsInfo';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Close, CoWorkerAdd, External } from '../../icons';
import { Loader } from '../../Loader/Loader';
import { Modal } from '../../Modal/Modal';
import { CoWorkerTable } from './CoWorkerTable/CoWorkerTable';
import styles from './CoWorkerModal.module.css';
export function CoWorkerModal({ listData, isAdmin, loading, roleList, onAddCoWorkerClick, onChangeUserRole, onCancelClick, onDeleteInvite, onResendInvite, onRoleAndPermissionsClick }) {
    return (_jsx(Modal, { animationVariant: "large", className: styles['container'], width: "100%", title: _jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "coworkermanagement.title" }) })), trailing: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ icon: _jsx(CoWorkerAdd, {}), color: "dark", onClick: onAddCoWorkerClick, "data-test": "add-coworker-button" }, { children: _jsx(Ditto, { componentId: "coworkermanagement.add" }) })), _jsx(Button, { icon: _jsx(Close, {}), variant: "square", color: "gray-2", onClick: () => onCancelClick && onCancelClick(), "data-test": "close-coworker-modal" })] }), content: _jsxs(_Fragment, { children: [_jsx(DetailsInfo, { className: styles['info'], linkText: _jsxs("span", Object.assign({ className: styles['info-link'] }, { children: [_jsx(Ditto, { componentId: "coworkermanagement.seerolesandpermissions" }), _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(External, {}) }))] })), linkOnClick: onRoleAndPermissionsClick, content: _jsx(Ditto, { componentId: "coworkermanagement.info" }) }), loading ? (_jsx("div", Object.assign({ className: styles['loading'] }, { children: _jsx(Loader, {}) }))) : (_jsx("div", Object.assign({ className: "table" }, { children: _jsx(CoWorkerTable, { roleList: roleList, listData: listData, isAdmin: isAdmin, onChangeUserRole: onChangeUserRole, onDeleteInvite: onDeleteInvite, onResendInvite: onResendInvite, onRoleAndPermissionsClick: onRoleAndPermissionsClick }) })))] }) }));
}
