import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { TypedDitto } from '../../components/TypedDitto';
import { TIME_PICKER_LATEST_ID, TIME_PICKER_TODAY_ID } from '../consts/timepicker';
export function getTextByOptionId(id) {
    if (id === TIME_PICKER_LATEST_ID) {
        return _jsx(Ditto, { componentId: "timepicker.latest" });
    }
    if (id === TIME_PICKER_TODAY_ID) {
        return _jsx(Ditto, { componentId: "timepicker.today" });
    }
    if (id.match(/^[0-9]{6}$/)) {
        return (_jsx(TypedDitto, { componentId: "timepicker.halfyear", 
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            variables: { Year: id.slice(0, 4), HalfYear: id.slice(5, 6) } }));
    }
    if (id.match(/^[0-9]{4}$/)) {
        return id;
    }
    const startingNumber = id.slice(0, -1);
    const lastChar = id.slice(-1);
    switch (lastChar) {
        case 'd':
            return (_jsx(TypedDitto, { componentId: "timepicker.lastxdays", variables: { Days: startingNumber } }));
        case 'm':
            return (_jsx(TypedDitto, { componentId: "timepicker.lastxmonths", variables: { Months: startingNumber } }));
        default:
            return id;
    }
}
