import { SIGN_DAMAGE_ID } from '../../consts/trafficSign';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
import { TRAFFIC_SIGNS_SOURCE_ID } from '../sources';
export const TRAFFIC_SIGNS_LAYER_ID = {
    default: `${LAYER_PREFIX}-traffic-signs`,
    hover: `${LAYER_PREFIX}-traffic-signs-hover`,
    highlight: `${LAYER_PREFIX}-traffic-signs-highlight`,
    selected: `${LAYER_PREFIX}-traffic-signs-selected`
};
const MIN_SCALE = 0.5;
const MAX_SCALE = 1;
const getSignIconExpression = (state) => {
    return [
        'case',
        ['==', ['get', 'icon'], SIGN_DAMAGE_ID.faded],
        `sign-faded-${state}`,
        ['==', ['get', 'icon'], SIGN_DAMAGE_ID.covered],
        `sign-covered-${state}`,
        ['==', ['get', 'icon'], SIGN_DAMAGE_ID.dirty],
        `sign-dirty-${state}`,
        ['==', ['get', 'icon'], SIGN_DAMAGE_ID.stickered],
        `sign-stickered-${state}`,
        `sign-stickered-${state}`
    ];
};
export const trafficSignsLayer = {
    id: TRAFFIC_SIGNS_LAYER_ID.default,
    source: TRAFFIC_SIGNS_SOURCE_ID,
    type: 'symbol',
    layout: {
        'icon-image': getSignIconExpression('default'),
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-anchor': 'center'
    }
};
export const trafficSignsHoverLayer = Object.assign(Object.assign({}, trafficSignsLayer), { id: TRAFFIC_SIGNS_LAYER_ID.hover, layout: Object.assign(Object.assign({}, trafficSignsLayer.layout), { 'icon-image': getSignIconExpression('hover') }) });
export const trafficSignsHighlightLayer = Object.assign(Object.assign({}, trafficSignsLayer), { id: TRAFFIC_SIGNS_LAYER_ID.highlight, layout: Object.assign(Object.assign({}, trafficSignsLayer.layout), { 'icon-image': getSignIconExpression('highlight') }) });
export const trafficSignsSelectedLayer = Object.assign(Object.assign({}, trafficSignsLayer), { id: TRAFFIC_SIGNS_LAYER_ID.selected, layout: Object.assign(Object.assign({}, trafficSignsLayer.layout), { 'icon-image': getSignIconExpression('selected') }) });
