import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ASSET_CHANGELOG_OPERATION, ASSET_SOURCE_ID } from '../consts/asset';
import { COMMON_ATTRIBUTE_TYPE } from '../consts/attribute';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_GROUP_KEY } from '../consts/mixpanel';
import { isDefined, uniqueV2 } from '../utils/array';
export function summarizeAssetDeletion(newList, oldList) {
    const summary = [];
    const events = [];
    oldList.forEach((oldItem) => {
        const newItem = newList.find((i) => i.id === oldItem.id);
        if (!newItem) {
            summary.push(oldItem.name);
            if (oldItem.defaultId) {
                events.push({
                    name: MIXPANEL_EVENT_NAME.deleteDefaultAssetType,
                    values: {
                        [MIXPANEL_EVENT_PROPERTY.assetTypeName]: oldItem.name,
                        [MIXPANEL_GROUP_KEY.assetTypeId]: oldItem.id
                    }
                });
            }
            return;
        }
        oldItem.attributes.forEach((oldAttribute) => {
            var _a;
            const newAttribute = newItem.attributes.find((i) => i.id === oldAttribute.id);
            if (!newAttribute) {
                summary.push([oldItem.name, oldAttribute.name].join('/'));
                if (oldAttribute.defaultId) {
                    events.push({
                        name: MIXPANEL_EVENT_NAME.deleteDefaultAssetProperty,
                        values: {
                            [MIXPANEL_EVENT_PROPERTY.assetPropertyName]: oldAttribute.name,
                            [MIXPANEL_GROUP_KEY.assetPropertyId]: oldAttribute.id
                        }
                    });
                }
                return;
            }
            (_a = oldAttribute.selectOptions) === null || _a === void 0 ? void 0 : _a.forEach((oldOption) => {
                var _a;
                const newOption = (_a = newAttribute.selectOptions) === null || _a === void 0 ? void 0 : _a.find((i) => i.id === oldOption.id);
                if (!newOption) {
                    summary.push([oldItem.name, oldAttribute.name, oldOption.name].join('/'));
                }
            });
        });
    });
    return { summary, events };
}
export function isIdenticalAssetSetting(newList, oldList) {
    var _a, _b;
    if (newList.length !== oldList.length) {
        return false;
    }
    for (let [i, newItem] of newList.entries()) {
        const oldItem = oldList[i];
        if (newItem.id !== oldItem.id ||
            newItem.name !== oldItem.name ||
            newItem.iconId !== oldItem.iconId ||
            newItem.attributes.length !== oldItem.attributes.length) {
            return false;
        }
        for (let [j, newAttribute] of newItem.attributes.entries()) {
            const oldAttribute = oldItem.attributes[j];
            if (newAttribute.id !== oldAttribute.id ||
                newAttribute.name !== oldAttribute.name ||
                newAttribute.unitId !== oldAttribute.unitId ||
                ((_a = newAttribute.selectOptions) === null || _a === void 0 ? void 0 : _a.length) !== ((_b = oldAttribute.selectOptions) === null || _b === void 0 ? void 0 : _b.length)) {
                return false;
            }
            if (oldAttribute.selectOptions && newAttribute.selectOptions) {
                for (let [k, newOption] of newAttribute.selectOptions.entries()) {
                    const oldOption = oldAttribute.selectOptions[k];
                    if (newOption.id !== oldOption.id || newOption.name !== oldOption.name) {
                        return false;
                    }
                }
            }
        }
    }
    return true;
}
export function renderSummary(summary) {
    return (_jsx("ul", { children: summary.map((i, idx) => (_jsx("li", { children: i }, idx))) }));
}
export function addAssetType(localTypeList, value) {
    return localTypeList.concat({ name: value, iconId: 1, attributes: [] });
}
export function addAttribute(assetType, type, value) {
    let attribute;
    switch (type) {
        case COMMON_ATTRIBUTE_TYPE.select:
            attribute = { type: COMMON_ATTRIBUTE_TYPE.select, selectOptions: [], name: value };
            break;
        case COMMON_ATTRIBUTE_TYPE.number:
            attribute = { type: COMMON_ATTRIBUTE_TYPE.number, unitId: 1, name: value };
            break;
        case COMMON_ATTRIBUTE_TYPE.date:
            attribute = { type: COMMON_ATTRIBUTE_TYPE.date, name: value };
            break;
        case COMMON_ATTRIBUTE_TYPE.text:
            attribute = { type: COMMON_ATTRIBUTE_TYPE.text, name: value };
            break;
        case COMMON_ATTRIBUTE_TYPE.multiselect:
            attribute = { type: COMMON_ATTRIBUTE_TYPE.multiselect, name: value };
    }
    return assetType.attributes.concat(attribute);
}
export function addSelectOption(selectOptions, value = '') {
    return selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions.concat({ name: value });
}
export function getAttributeFieldsWidth(list) {
    return list.map((item, i, list) => {
        if (item.type === 'number' && list[i + 1] && list[i + 1].type === 'number') {
            return item;
        }
        else if (item.type === 'number' && list[i - 1] && list[i - 1].type === 'number') {
            return item;
        }
        return Object.assign(Object.assign({}, item), { isFullWidth: true });
    });
}
export function getLookupMarkerIds(changelogList) {
    const markerIds = changelogList
        .filter((c) => [ASSET_CHANGELOG_OPERATION.linkMarker, ASSET_CHANGELOG_OPERATION.unlinkMarker].includes(c.operation))
        .map((c) => { var _a, _b; return ((_a = c.oldValue) === null || _a === void 0 ? void 0 : _a.markerId) || ((_b = c.newValue) === null || _b === void 0 ? void 0 : _b.markerId); });
    return uniqueV2(markerIds.filter(isDefined));
}
export function getAttributeInputValue(typeAttribute, assetInfo) {
    const initialValue = assetInfo === null || assetInfo === void 0 ? void 0 : assetInfo.attributes.find((i) => i.inventoryTypeAttributeId === typeAttribute.id);
    switch (typeAttribute.type) {
        case COMMON_ATTRIBUTE_TYPE.number:
            return initialValue === null || initialValue === void 0 ? void 0 : initialValue.numberValue;
        case COMMON_ATTRIBUTE_TYPE.select:
            return initialValue === null || initialValue === void 0 ? void 0 : initialValue.selectValue;
        case COMMON_ATTRIBUTE_TYPE.date:
            return initialValue === null || initialValue === void 0 ? void 0 : initialValue.dateValue;
        default:
            return initialValue === null || initialValue === void 0 ? void 0 : initialValue.textValue;
    }
}
export function getAssetTypeIdByAttributeId(types, attributeId) {
    for (let t of types) {
        if (t.attributes.find((a) => a.id === attributeId)) {
            return t.id;
        }
    }
}
export function getAssetSourceLabel(source) {
    switch (source) {
        case ASSET_SOURCE_ID.webApp:
            return _jsx(Ditto, { componentId: "changelog.createdinweb-app" });
        case ASSET_SOURCE_ID.flutterApp:
            return _jsx(Ditto, { componentId: "changelog.createdinvialyticstodoapp" });
        case ASSET_SOURCE_ID.importCli:
            return _jsx(Ditto, { componentId: "changelog.createdthroughimport" });
    }
    return _jsx(Ditto, { componentId: "changelog.createdinwebsystemorvialyticstodoapp" });
}
