import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CategorySelectModal } from '../../components/Modals/CategorySelectModal/CategorySelectModal';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
export function CategorySelectModalContainer({ onSelect }) {
    const { addModal, removeModal } = useModalContext();
    const { markerCategoryList } = useAppSelector((state) => state.shared);
    function handleCustomize() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'task-categories'
            }
        });
    }
    function handleAddNewCategory() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'task-categories',
                modalProps: {
                    type: 'task-categories',
                    shouldGoToAddNewCategory: true
                }
            }
        });
    }
    function handleCancel() {
        handleClose();
    }
    function handleClose() {
        removeModal('CategorySelectModalContainer');
    }
    return (_jsx(CategorySelectModal, { options: markerCategoryList, onSelect: onSelect, onCustomizeClick: handleCustomize, onAddNewCategoryClick: handleAddNewCategory, onCancel: handleCancel, onClose: handleClose }));
}
