import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { COLOR_NO_GRADE, EMI_GRADE, getColorForGrade } from '../../common/color';
import { round2Decimal } from '../../common/utils/number';
import styles from './DamageGrade.module.css';
export function DamageGrade({ title, grade, className }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], className) }, { children: [_jsx("div", Object.assign({ className: classes(styles['title']) }, { children: title })), _jsxs("div", Object.assign({ className: classes(styles['grade']) }, { children: [_jsx("div", { className: classes(styles['color']), style: {
                            backgroundColor: grade ? getColorForGrade(grade, EMI_GRADE) : COLOR_NO_GRADE
                        } }), grade ? round2Decimal(grade) : '-'] }))] })));
}
