// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Caeuqo123BqELKFgGdPc{height:24px;width:100%}.tAxLAXylWEofEY5uBjMa{margin-bottom:16px;width:100%}.QUa8M7IC_7HnLi_VsEBO{display:flex;flex-direction:column;align-items:stretch;align-self:stretch;padding-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/GlobalSettingsModal/TaskCategoriesSettingPanel/TaskCategoriesSettingPanel.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,UAAA,CAGJ,sBACI,kBAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,kBAAA,CACA,mBAAA","sourcesContent":[".content-bottom-anchor {\n    height: 24px;\n    width: 100%;\n}\n\n.info-box {\n    margin-bottom: 16px;\n    width: 100%;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    align-self: stretch;\n    padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-bottom-anchor": `Caeuqo123BqELKFgGdPc`,
	"info-box": `tAxLAXylWEofEY5uBjMa`,
	"content": `QUa8M7IC_7HnLi_VsEBO`
};
export default ___CSS_LOADER_EXPORT___;
