// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hgn_i11lbL31SPtLpQ2t{padding:6px;background:var(--primary-white);color:#222d39;box-shadow:var(--box-shadow-dropdown);border-radius:6px;overflow-y:auto;user-select:none}.czBo4j8XIAWG7osIKqlz{max-width:calc(100% - 12px)}.Qw6pebLHLP_WFhpnY2lK{overflow-y:visible}.A6dFFhzbrhgKF_lPwNXN{pointer-events:all}.Hgn_i11lbL31SPtLpQ2t>*{width:100%}.qToYYLJxOyuvvnWQAhGo{padding:0}.dLmh7SBy9th0h27T_YQY{border-radius:12px}`, "",{"version":3,"sources":["webpack://./src/components/ActionModal/ActionModal.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,+BAAA,CACA,aAAA,CAEA,qCAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGJ,sBAEI,2BAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,kBAAA,CAGJ,wBACI,UAAA,CAGJ,sBACI,SAAA,CAGJ,sBACI,kBAAA","sourcesContent":[".container {\n    padding: 6px;\n    background: var(--primary-white);\n    color: rgba(34, 45, 57, 1);\n    /* Elevated dropdown */\n    box-shadow: var(--box-shadow-dropdown);\n    border-radius: 6px;\n    overflow-y: auto;\n    user-select: none;\n}\n\n.container__max-width {\n    /* Subtract padding if container width is set to 100% */\n    max-width: calc(100% - 12px);\n}\n\n.container__overflow {\n    overflow-y: visible;\n}\n\n.container__clickable {\n    pointer-events: all;\n}\n\n.container > * {\n    width: 100%;\n}\n\n.container__no-padding {\n    padding: 0;\n}\n\n.container__extra-rounded {\n    border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Hgn_i11lbL31SPtLpQ2t`,
	"container__max-width": `czBo4j8XIAWG7osIKqlz`,
	"container__overflow": `Qw6pebLHLP_WFhpnY2lK`,
	"container__clickable": `A6dFFhzbrhgKF_lPwNXN`,
	"container__no-padding": `qToYYLJxOyuvvnWQAhGo`,
	"container__extra-rounded": `dLmh7SBy9th0h27T_YQY`
};
export default ___CSS_LOADER_EXPORT___;
