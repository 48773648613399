var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { selectImagesDialog } from '../../common/selectImages';
import { ROOT_PATH } from '../../common/url';
import { AppLayoutForm } from '../../components/AppLayoutForm/AppLayoutForm';
import { MissingUserDetail } from '../../components/Auth/MissingUserDetail/MissingUserDetail';
import { useDepartmentsData } from '../../hooks/useDepartmentsData';
import { updateProfileImage } from '../../services/accounts';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchCurrentUser, updateUser } from '../../state/slices/auth';
import { addNotificationMessage } from '../../state/slices/notification';
import { NotificationContainer } from '../NotificationContainer/NotificationContainer';
export function MissingUserDetailContainer() {
    const dispatch = useAppDispatch();
    const { departmentList, status } = useDepartmentsData();
    const { locale } = useAppSelector((state) => state.app);
    const { targetSchema, userAccount } = useAppSelector((state) => state.auth);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        image: undefined,
        titleId: null,
        departmentId: null,
        email: ''
    });
    useEffect(() => {
        dispatch(fetchCurrentUser());
    }, [dispatch]);
    useEffect(() => {
        if (userAccount) {
            if (userAccount.firstName &&
                userAccount.lastName &&
                userAccount.titleId &&
                userAccount.departmentId) {
                redirectToApp();
            }
            else {
                setValues({
                    firstName: userAccount.firstName || '',
                    lastName: userAccount.lastName || '',
                    image: userAccount.profileImageUrl || undefined,
                    titleId: userAccount.titleId || null,
                    departmentId: userAccount.departmentId || null,
                    email: userAccount.email || ''
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAccount]);
    function redirectToApp() {
        window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.main}?targetSchema=${targetSchema}`;
    }
    function onChange(name, value) {
        setValues(Object.assign(Object.assign({}, values), { [name]: value }));
    }
    function handleOnClickUpload() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const newImages = yield selectImagesDialog({ fileLimit: 1 });
                if (newImages.processedImages.length > 0)
                    onChange('image', newImages.processedImages[0].url);
            }
            catch (e) {
                console.error('UserAccountSettings/handleOnClickUpload:', e);
                if (Array.isArray(e) && ((_a = e[0]) === null || _a === void 0 ? void 0 : _a.type) === 'files_over_size') {
                    dispatch(addNotificationMessage({
                        type: 'error',
                        ditto: {
                            title: {
                                componentId: 'file.toolarge',
                                variables: { Size: 10 }
                            }
                        }
                    }));
                }
            }
        });
    }
    function handleSubmitClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (userAccount && userAccount.email) {
                try {
                    setIsSubmitted(true);
                    yield Promise.all([
                        values.image
                            ? yield fetch(values.image)
                                .then((response) => response.blob())
                                .then((blob) => __awaiter(this, void 0, void 0, function* () {
                                const formData = new FormData();
                                formData.append('uuid', userAccount.uuid);
                                formData.append('file', blob);
                                const res = yield updateProfileImage(formData);
                                if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
                                    dispatch(addNotificationMessage({
                                        type: 'error',
                                        ditto: res.errorCode === RESPONSE_ERROR_CODE.uploadError
                                            ? {
                                                title: {
                                                    componentId: 'toast.invalidimagefile'
                                                }
                                            }
                                            : undefined
                                    }));
                                }
                            }))
                            : {},
                        yield dispatch(updateUser(Object.assign({}, values))).unwrap()
                    ]);
                    redirectToApp();
                }
                catch (error) {
                    setIsSubmitted(false);
                    console.error(error);
                }
            }
        });
    }
    return (_jsx(AppLayoutForm, Object.assign({ notification: _jsx(NotificationContainer, {}) }, { children: _jsx(MissingUserDetail, { currentLocale: locale, departmentList: departmentList, loading: !userAccount || status === 'loading' || isSubmitted, values: values, onChange: onChange, onClickUpload: handleOnClickUpload, onLaterClick: redirectToApp, onSubmitClick: handleSubmitClick }) })));
}
