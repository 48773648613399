// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wp8HwMSei0bljaTmRWGf{display:flex;align-items:center;margin-bottom:6px}.Wp8HwMSei0bljaTmRWGf a{display:flex;flex:1;margin-top:0}.Wp8HwMSei0bljaTmRWGf>span:first-child{top:50%;transform:translateY(-50%)}.dWDJv8Au1UzRmFrt7WHo{display:flex;flex:1;align-items:center;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/components/Modals/CoWorkerModal/CoWorkerRoleSelect/CoWorkerRoleSelect.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,wBACI,YAAA,CACA,MAAA,CACA,YAAA,CAGJ,uCACI,OAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CACA,MAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":[".info {\n    display: flex;\n    align-items: center;\n    margin-bottom: 6px;\n}\n\n.info a {\n    display: flex;\n    flex: 1;\n    margin-top: 0;\n}\n\n.info > span:first-child {\n    top: 50%;\n    transform: translateY(-50%);\n}\n\n.info-link {\n    display: flex;\n    flex: 1;\n    align-items: center;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `Wp8HwMSei0bljaTmRWGf`,
	"info-link": `dWDJv8Au1UzRmFrt7WHo`
};
export default ___CSS_LOADER_EXPORT___;
