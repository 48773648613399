import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Marker } from 'react-map-gl';
import { MAP_MARKER_ICON_SIZE, MAP_VIA_LAT, MAP_VIA_LNG } from '../../common/consts/map';
import { IconResizer } from '../../components/IconResizer/IconResizer';
export function usePositioningMarker({ initialPosition, icon, offset = [0, 0], anchor, onDrag, onDragEnd }) {
    var _a, _b;
    const [position, setPosition] = useState(initialPosition !== null && initialPosition !== void 0 ? initialPosition : { lat: MAP_VIA_LAT, lng: MAP_VIA_LNG });
    function handleDragEnd(e) {
        onDragEnd && onDragEnd(e);
    }
    function handleDrag(e) {
        setPosition(e.lngLat);
        onDrag && onDrag(e);
    }
    const positioningMarker = (_jsx(Marker, Object.assign({ style: { cursor: 'move' }, draggable: true, latitude: (_a = position === null || position === void 0 ? void 0 : position.lat) !== null && _a !== void 0 ? _a : MAP_VIA_LAT, longitude: (_b = position === null || position === void 0 ? void 0 : position.lng) !== null && _b !== void 0 ? _b : MAP_VIA_LNG, onDragEnd: handleDragEnd, onDrag: handleDrag, anchor: anchor, offset: offset }, { children: _jsx(IconResizer, Object.assign({ size: MAP_MARKER_ICON_SIZE }, { children: icon })) })));
    return {
        positioningMarker: initialPosition ? positioningMarker : null
    };
}
