var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Button } from '../../uikit/Button/Button';
import styles from './LabelOnHoverButton.module.css';
const TRANSITION_DURATION = 0.3; // seconds
export function LabelOnHoverButton(_a) {
    var { className, icon, trailingIcon, variant } = _a, props = __rest(_a, ["className", "icon", "trailingIcon", "variant"]);
    const ref = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    useEffect(() => {
        const currentRef = ref.current;
        if (!currentRef)
            return;
        currentRef.addEventListener('transitionend', transitionEnd);
        return () => {
            currentRef.removeEventListener('transitionend', transitionEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);
    function transitionEnd(event) {
        if (!isExpanded && event.elapsedTime === TRANSITION_DURATION) {
            setIsExpanded(true);
        }
        else {
            setIsExpanded(false);
        }
    }
    return (_jsx(Button, Object.assign({ className: classes(styles['button__animated'], isExpanded && styles['button__animated--expanded'], variant && styles[`button__${variant}`], !icon && trailingIcon && styles['button__animated--trailing-icon'], className), icon: icon, trailingIcon: trailingIcon, variant: variant, ref: ref }, props)));
}
