var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { LAYER_PATH } from '../../common/layer';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { Assets, Plus } from '../../components/icons';
import { ImageGallery } from '../../components/ImageGallery/ImageGallery';
import { GalleryImage } from '../../components/ImageViewer/GalleryImage/GalleryImage';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useImageUploadModal } from '../../hooks/useImageUploadModal';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetAssetCreator, setAssetCreatorSource, setAssetCreatorType } from '../../state/slices/assetCreator';
import { addMarkerPhotosTk, fetchMarker, removeMarkerPhotoTk } from '../../state/slices/markerDetail';
import { Button } from '../../uikit/Button/Button';
import { AssetCreatorContainer } from '../AssetCreatorContainer/AssetCreatorContainer';
import { MarkerDetailContainer } from '../MarkerDetailContainer/MarkerDetailContainer';
export function ImageViewerMarkerContainer({ initialActiveIndex = 0 }) {
    var _a;
    const { addModal, removeModal, modals } = useModalContext();
    const { marker } = useAppSelector((state) => state.markerDetail);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { targetSchema } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const { selectConfirmPhotos } = useImageUploadModal();
    const dateTimeFormatter = useDateTimeFormatter();
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    useEffect(() => {
        if (!selectedFeature || selectedFeature.type !== 'marker') {
            return;
        }
        dispatch(fetchMarker(selectedFeature.id));
    }, [selectedFeature, dispatch]);
    if (!targetSchema || !marker) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    const detailPanel = (() => {
        if (assetCreatorSource) {
            return _jsx(AssetCreatorContainer, {});
        }
        return _jsx(MarkerDetailContainer, { variant: "image-viewer" });
    })();
    const topButtonsCreate = typeof marker.manualInventoryId === 'undefined' ? (_jsx(FloatingDropdownWrapper, { control: _jsx(Button, { color: "dark", variant: "square", icon: _jsx(Plus, {}) }), topOffset: 4, menu: _jsx(ActionModal, Object.assign({ extraRounded: true }, { children: _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "actionmodal.createinventory" }), leadingIcon: _jsx(Assets, {}), onClick: handleAddAssetButtonClick, size: "medium", extraRounded: true }) })) })) : (_jsx(_Fragment, {}));
    function handleAddAssetButtonClick() {
        if (!marker) {
            return;
        }
        mixpanel.track(MIXPANEL_EVENT_NAME.createAssetFromImageViewer, {
            [MIXPANEL_EVENT_PROPERTY.layerName]: LAYER_PATH.marker
        });
        dispatch(setAssetCreatorType('right-panel'));
        dispatch(setAssetCreatorSource({ marker }));
    }
    function handleBackButtonClick() {
        dispatch(resetAssetCreator());
    }
    function parseImages(images) {
        return images.map((image) => ({
            id: image.id,
            image: _jsx(GalleryImage, { src: image.mediumUrl }),
            thumb: _jsx(GalleryImage, { src: image.thumbUrl }),
            createdAt: dateTimeFormatter(image.createdAt)
        }));
    }
    function handleImageUploadClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!marker) {
                return;
            }
            selectConfirmPhotos((img) => dispatch(addMarkerPhotosTk({
                markerId: marker.id,
                photos: img.map((i) => i.file)
            })));
        });
    }
    function handleDeleteImage(photoId) {
        if (!marker) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(removeMarkerPhotoTk({ markerId: marker.id, photoId }));
                },
                title: _jsx(Ditto, { componentId: "promt.delete.image" }),
                description: _jsx(Ditto, { componentId: "promt.delete.image.text" })
            }
        });
    }
    function handleClickOutside() {
        if (modals.find((m) => m.id === 'DeleteModal')) {
            return;
        }
        removeModal('ImageViewerMarker');
    }
    const images = (_a = marker.photos) !== null && _a !== void 0 ? _a : [];
    return (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageGallery, { images: parseImages(images), initialActiveIndex: initialActiveIndex, details: detailPanel, onAddImage: handleImageUploadClick, onDeleteImage: handleDeleteImage, onDoneClick: handleClickOutside, onBackButtonClick: assetCreatorSource ? handleBackButtonClick : undefined, topButtonsCreate: topButtonsCreate }) }));
}
