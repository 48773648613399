import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NOTE_COLORS } from '../../common/consts/note';
import { NoteCreator } from '../../components/NoteCreator/NoteCreator';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearRightClickCoordinates, setCurrentRightClickAction } from '../../state/slices/mainMap';
import { saveNoteDraft } from '../../state/slices/note';
export function NoteCreatorContainer() {
    const dispatch = useAppDispatch();
    const { rightClickCoordinates } = useAppSelector((state) => state.mainMap);
    const dateTimeFormatter = useDateTimeFormatter();
    const [noteFormData, setNoteFormData] = useState({
        text: '',
        color: NOTE_COLORS[0]
    });
    const now = dateTimeFormatter(new Date());
    console.log(noteFormData);
    function handleSaveClick(data) {
        if (!rightClickCoordinates) {
            return;
        }
        const mappedColor = NOTE_COLORS.findIndex((c) => c === data.color);
        if (!data.text || !data.color || mappedColor === -1) {
            console.debug('NoteCreatorContainer: basic validation failed for data', data);
            return;
        }
        dispatch(saveNoteDraft({
            id: uuidv4(),
            color: mappedColor,
            text: data.text,
            geo: rightClickCoordinates
        }));
    }
    function handleRemoveClick() {
        dispatch(clearRightClickCoordinates());
        dispatch(setCurrentRightClickAction('none'));
    }
    function handleChangeFormData(data) {
        setNoteFormData(Object.assign(Object.assign({}, noteFormData), data));
    }
    return (_jsx(NoteCreator, { text: noteFormData.text, color: noteFormData.color, date: now, isEdit: true, onChangeFormData: handleChangeFormData, onSaveClick: handleSaveClick, onRemoveClick: handleRemoveClick }));
}
