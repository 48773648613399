import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { AnimatePresence, motion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { dropdownVariants } from '../../../../common/consts/animations';
import { MIXPANEL_EVENT_NAME } from '../../../../common/consts/mixpanel';
import useComponentWidth from '../../../../hooks/useComponentWidth';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { BudgetTooltip } from '../../../BudgetTooltip/BudgetTooltip';
import { IconCircleProgress } from '../../../IconCircleProgress/IconCircleProgress';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Warning1 } from '../../../icons';
import { Loader } from '../../../Loader/Loader';
import { TextOverflow } from '../../../TextOverflow/TextOverflow';
import styles from './DataGridTableFooterItemCosts.module.css';
const HIDE_DELAY = 1000; // ms
export function DataGridTableFooterItemCosts({ budgets, loading, selectedYears, stickyPosition, onEditBudgetClick, unitFormatter }) {
    var _a;
    const { ref, width } = useComponentWidth();
    const timeoutRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const isLoading = !budgets || loading;
    const isUnplannedDateSelected = selectedYears.includes(0);
    useOnClickOutside(ref, () => setIsOpen(false));
    function handleOpen() {
        if (!isOpen) {
            mixpanel.track(MIXPANEL_EVENT_NAME.openBudgetTooltip);
            setIsOpen(true);
        }
    }
    function handleClose() {
        if (isOpen) {
            setIsOpen(false);
        }
    }
    function handleClick() {
        if (!isOpen) {
            handleOpen();
        }
        else {
            handleClose();
        }
    }
    function handleMouseEnter() {
        clearTimeout(timeoutRef.current);
        handleOpen();
    }
    function handleMouseLeave() {
        timeoutRef.current = setTimeout(() => {
            setIsOpen(false);
        }, HIDE_DELAY);
    }
    function calculateSums() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        let result = {
            byTreatmentCategory: [],
            byBudgetType: [],
            maintenance: {
                availableBudget: 0,
                totalCosts: 0,
                balance: 0
            },
            capital: {
                availableBudget: 0,
                totalCosts: 0,
                balance: 0
            }
        };
        let treatmentCategorySums = {};
        let byBudgetTypeSums = {};
        if (!(budgets === null || budgets === void 0 ? void 0 : budgets.years)) {
            return result;
        }
        if (selectedYears.length === 0) {
            return {
                byTreatmentCategory: (_b = (_a = budgets.total) === null || _a === void 0 ? void 0 : _a.byTreatmentCategory) !== null && _b !== void 0 ? _b : [],
                byBudgetType: (_d = (_c = budgets.total) === null || _c === void 0 ? void 0 : _c.byBudgetType) !== null && _d !== void 0 ? _d : [],
                maintenance: (_e = budgets.total) === null || _e === void 0 ? void 0 : _e.maintenance,
                capital: (_f = budgets.total) === null || _f === void 0 ? void 0 : _f.capital
            };
        }
        if (isUnplannedDateSelected) {
            return {
                byTreatmentCategory: (_h = (_g = budgets.unplanned) === null || _g === void 0 ? void 0 : _g.byTreatmentCategory) !== null && _h !== void 0 ? _h : [],
                byBudgetType: (_k = (_j = budgets.unplanned) === null || _j === void 0 ? void 0 : _j.byBudgetType) !== null && _k !== void 0 ? _k : [],
                maintenance: (_l = budgets.unplanned) === null || _l === void 0 ? void 0 : _l.maintenance,
                capital: (_m = budgets.unplanned) === null || _m === void 0 ? void 0 : _m.capital
            };
        }
        selectedYears.forEach((year) => {
            if ((budgets === null || budgets === void 0 ? void 0 : budgets.years) && budgets.years[year]) {
                if ((budgets === null || budgets === void 0 ? void 0 : budgets.years) && budgets.years[year]) {
                    if (budgets.years[year].byTreatmentCategory) {
                        budgets.years[year].byTreatmentCategory.forEach((category) => {
                            if (!treatmentCategorySums[category.name]) {
                                treatmentCategorySums[category.name] = 0;
                            }
                            treatmentCategorySums[category.name] += category.costs;
                        });
                    }
                    if (budgets.years[year].byBudgetType) {
                        budgets.years[year].byBudgetType.forEach((category) => {
                            if (!byBudgetTypeSums[category.name]) {
                                byBudgetTypeSums[category.name] = 0;
                            }
                            byBudgetTypeSums[category.name] += category.costs;
                        });
                    }
                    if (budgets.years[year].maintenance) {
                        Object.keys(budgets.years[year].maintenance).forEach((category) => {
                            const budgetKey = category;
                            if (!result.maintenance[budgetKey]) {
                                result.maintenance[budgetKey] = 0;
                            }
                            result.maintenance[budgetKey] +=
                                budgets.years[year].maintenance[budgetKey];
                        });
                    }
                    if (budgets.years[year].capital) {
                        Object.keys(budgets.years[year].capital).forEach((category) => {
                            const budgetKey = category;
                            if (!result.capital[budgetKey]) {
                                result.capital[budgetKey] = 0;
                            }
                            result.capital[budgetKey] += budgets.years[year].capital[budgetKey];
                        });
                    }
                }
            }
        });
        Object.keys(treatmentCategorySums).forEach((key) => {
            result.byTreatmentCategory.push({
                name: key,
                costs: treatmentCategorySums[key],
                costsPercentage: (treatmentCategorySums[key] / totalCosts) * 100
            });
        });
        Object.keys(byBudgetTypeSums).forEach((key) => {
            result.byBudgetType.push({
                name: key,
                costs: byBudgetTypeSums[key],
                costsPercentage: (byBudgetTypeSums[key] / totalCosts) * 100
            });
        });
        return result;
    }
    const getNumericBudgetValue = (budgets, selectedYears, key) => {
        var _a;
        if (!(budgets === null || budgets === void 0 ? void 0 : budgets.years) || !(budgets === null || budgets === void 0 ? void 0 : budgets.total) || !budgets.unplanned)
            return 0;
        if (isUnplannedDateSelected) {
            return budgets.unplanned.maintenance[key] + budgets.unplanned.capital[key];
        }
        return selectedYears.length > 0
            ? selectedYears.reduce((acc, year) => { var _a, _b; return acc + ((_b = (budgets.years && ((_a = budgets.years[year]) === null || _a === void 0 ? void 0 : _a[key]))) !== null && _b !== void 0 ? _b : 0); }, 0)
            : (_a = budgets.total[key]) !== null && _a !== void 0 ? _a : 0;
    };
    const getBooleanBudgetValue = (budgets, selectedYears, key) => {
        var _a, _b;
        if (!(budgets === null || budgets === void 0 ? void 0 : budgets.years) || !(budgets === null || budgets === void 0 ? void 0 : budgets.total) || !budgets.unplanned)
            return true;
        if (isUnplannedDateSelected) {
            return (_a = budgets.unplanned[key]) !== null && _a !== void 0 ? _a : true;
        }
        return selectedYears.length > 0
            ? selectedYears.some((year) => { var _a; return budgets.years && ((_a = budgets.years[year]) === null || _a === void 0 ? void 0 : _a[key]) === true; })
            : (_b = budgets.total[key]) !== null && _b !== void 0 ? _b : true;
    };
    const availableBudget = useMemo(() => getNumericBudgetValue(budgets, selectedYears, 'availableBudget'), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgets, selectedYears]);
    const totalCosts = useMemo(() => getNumericBudgetValue(budgets, selectedYears, 'totalCosts'), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgets, selectedYears]);
    const balance = useMemo(() => getNumericBudgetValue(budgets, selectedYears, 'balance'), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgets, selectedYears]);
    const isMissingBudgets = useMemo(() => getBooleanBudgetValue(budgets, selectedYears, 'isMissingBudgets'), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgets, selectedYears]);
    const isMissingBudgetTypes = useMemo(() => getBooleanBudgetValue(budgets, selectedYears, 'isMissingBudgetTypes'), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgets, selectedYears]);
    const isMissingCosts = useMemo(() => getBooleanBudgetValue(budgets, selectedYears, 'isMissingCosts'), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgets, selectedYears]);
    const progressValue = Math.min(totalCosts / availableBudget, 1);
    const balanceValue = Math.min(Math.abs(balance / availableBudget), 1);
    const budgetYears = useMemo(() => {
        if (selectedYears.length === 0) {
            return budgets === null || budgets === void 0 ? void 0 : budgets.years;
        }
        else if (isUnplannedDateSelected) {
            return undefined;
        }
        const budgetYears = {};
        if (budgets === null || budgets === void 0 ? void 0 : budgets.years) {
            selectedYears.forEach((year) => {
                budgetYears[year] = budgets.years[year];
            });
        }
        return budgetYears;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgets, selectedYears]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sums = useMemo(() => calculateSums(), [budgets, selectedYears]);
    const isBudgetExceeded = sums.maintenance && sums.capital
        ? sums.maintenance.balance < 0 || ((_a = sums.capital) === null || _a === void 0 ? void 0 : _a.balance) < 0
        : false;
    const hasError = isBudgetExceeded || isMissingBudgets || isMissingBudgetTypes || isMissingCosts;
    return (_jsxs("td", Object.assign({ className: classes(styles['container'], styles['container--border'], stickyPosition !== undefined && styles['container--pinned']), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, style: { width, left: stickyPosition }, ref: ref }, { children: [_jsxs("div", Object.assign({ className: classes(styles['content']), onClick: handleClick }, { children: [isLoading && _jsx(Loader, {}), _jsxs(_Fragment, { children: [!isUnplannedDateSelected && (_jsx(IconCircleProgress, { error: balance < 0 ? balanceValue : undefined, value: progressValue })), _jsxs("div", Object.assign({ className: styles['text'] }, { children: [_jsx("div", Object.assign({ className: styles['costs'] }, { children: _jsx(TextOverflow, { text: unitFormatter({
                                                type: 'currency',
                                                value: totalCosts,
                                                trimTrailingZeros: true
                                            }).formattedText, maxLines: 1, tooltip: "small", tooltipPosition: "up", onWidthChange: () => { } }) })), _jsxs("div", Object.assign({ className: classes(styles['balance'], hasError && styles['warning']) }, { children: [hasError && (_jsx("div", Object.assign({ className: styles['warning'] }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(Warning1, {}) })) }))), _jsx(TextOverflow, { text: isUnplannedDateSelected
                                                    ? '-'
                                                    : `${balance > 0 ? '+' : ''}${unitFormatter({
                                                        type: 'currency',
                                                        value: balance,
                                                        trimTrailingZeros: true
                                                    }).formattedText}`, maxLines: 1, tooltip: "small", tooltipPosition: "up", onWidthChange: () => { } })] }))] }))] })] })), _jsx(AnimatePresence, { children: isOpen && budgets && (_jsx(motion.div, Object.assign({ className: styles['tooltip'], variants: dropdownVariants('up'), initial: "initial", animate: "visible", exit: "exit" }, { children: _jsx(BudgetTooltip, { treatmentCategories: sums.byTreatmentCategory, years: budgetYears, maintenance: sums.maintenance, capital: sums.capital, isBudgetExceeded: isBudgetExceeded, isMissingBudgets: isMissingBudgets, isMissingBudgetTypes: isMissingBudgetTypes, isMissingCosts: isMissingCosts, onEditBudgetClick: onEditBudgetClick, unitFormatter: unitFormatter }) }))) })] })));
}
