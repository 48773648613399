export const OBJECT_TYPE = {
    manhole: 'man_hole',
    stormDrain: 'storm_drain'
};
export const ALERT_OBJECT_TYPES = [
    'lowered_manhole',
    'outsticking_manhole',
    'lowered_storm_drain',
    'outsticking_storm_drain'
];
export const DEFAULT_MANHOLE_OPTIONS = ['man_hole'];
export const PREMIUM_MANHOLE_OPTIONS = [
    'even_manhole',
    'lowered_manhole',
    'outsticking_manhole'
];
export const DEFAULT_STORM_DRAIN_OPTIONS = ['storm_drain'];
export const PREMIUM_STORM_DRAIN_OPTIONS = [
    'even_storm_drain',
    'lowered_storm_drain',
    'outsticking_storm_drain'
];
