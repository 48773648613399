import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDittoWrapper } from '../../../../../hooks/useDittoWrapper';
import { Button } from '../../../../../uikit/Button/Button';
import { IconResizer } from '../../../../IconResizer/IconResizer';
import { Folder, Plus } from '../../../../icons';
import styles from './AttachmentFolder.module.css';
export function AttachmentFolder({ folder, handleAddClick }) {
    var _a, _b;
    let name = useDittoWrapper({
        componentId: (folder === null || folder === void 0 ? void 0 : folder.componentId) === '' ? 'tasks.files.folders.uploads' : (_a = folder.componentId) !== null && _a !== void 0 ? _a : ''
    });
    if (!(folder === null || folder === void 0 ? void 0 : folder.isSystem)) {
        name = (_b = folder.name) !== null && _b !== void 0 ? _b : '';
    }
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['title'] }, { children: [_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Folder, {}) })), name] })), handleAddClick && (_jsx("div", Object.assign({ className: styles['plus'] }, { children: _jsx(Button, { icon: _jsx(Plus, {}), variant: "square", onClick: () => handleAddClick(folder.id) }) })))] })));
}
