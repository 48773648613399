import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { ActionButton } from '../../ActionButton/ActionButton';
import { Check, DrawTool2, Reset, SegmentCustom } from '../../icons';
import { TooltipWrapper } from '../../TooltipWrapper/TooltipWrapper';
import styles from './SegmentCreator.module.css';
export function SegmentCreator({ content }) {
    switch (content.type) {
        case 'start-selection':
            return (_jsx(TooltipWrapper, Object.assign({ className: styles['tooltip-segment-creator'], text: _jsx(Ditto, { componentId: "tooltip.startselection" }), isAlwaysVisible: true }, { children: _jsx(Button, Object.assign({ className: styles['button'], color: "purple", disabled: true, "data-test": "btn-action-start-selection" }, { children: _jsxs("div", Object.assign({ className: styles['button-content'] }, { children: [_jsx(DrawTool2, {}), _jsx("span", { children: _jsx(Ditto, { componentId: "startselection" }) })] })) })) })));
        case 'confirm-selection':
        case 'add-plan-step-1':
        case 'add-plan-step-2':
        case 'add-plan-confirm':
            return (_jsxs(_Fragment, { children: [_jsx(TooltipWrapper, Object.assign({ className: classes(styles['tooltip-segment-creator'], styles['tooltip-segment-creator--with-button']), text: (() => {
                            switch (content.type) {
                                case 'add-plan-step-1':
                                    return _jsx(Ditto, { componentId: "tooltip.threecornerpolygon" });
                                case 'add-plan-step-2':
                                    return _jsx(Ditto, { componentId: "tooltip.includeonepoint" });
                                case 'add-plan-confirm':
                                    return _jsx(Ditto, { componentId: "tooltip.updatepolygon" });
                            }
                        })(), isAlwaysVisible: true }, { children: _jsx(Button, Object.assign({ className: styles['button'], color: "purple", disabled: !['add-plan-confirm', 'confirm-selection'].includes(content.type), onClick: () => {
                                if (content.onActionClick) {
                                    content.onActionClick();
                                }
                            }, "data-test": 'btn-action-add-new-segment' }, { children: _jsxs("div", Object.assign({ className: styles['button-content'] }, { children: [_jsx(SegmentCustom, {}), _jsx("span", { children: _jsx(Ditto, { componentId: "createplannedsegment" }) })] })) })) })), _jsx(ActionButton, { icon: _jsx(Reset, {}), tooltip: {
                            text: _jsx(Ditto, { componentId: "tooltip.resetseletion" })
                        }, onClick: content.onResetClick })] }));
        case 'create-plan-step-1':
        case 'create-plan-confirm':
            return (_jsxs(_Fragment, { children: [_jsx(TooltipWrapper, Object.assign({ className: classes(styles['tooltip-segment-creator'], styles['tooltip-segment-creator--with-button']), text: (() => {
                            switch (content.type) {
                                case 'create-plan-step-1':
                                    return _jsx(Ditto, { componentId: "tooltip.plannedsegmentdetails" });
                                case 'create-plan-confirm':
                                    return undefined;
                            }
                        })(), isAlwaysVisible: true }, { children: _jsx(Button, Object.assign({ className: styles['button'], color: "purple", disabled: content.type !== 'create-plan-confirm' }, { children: _jsxs("div", Object.assign({ className: styles['button-content'] }, { children: [_jsx(Check, {}), _jsx("span", { children: _jsx(Ditto, { componentId: "createplannedsegment" }) })] })) })) })), _jsx(ActionButton, { icon: _jsx(Reset, {}), tooltip: {
                            text: _jsx(Ditto, { componentId: "tooltip.resetseletion" })
                        } })] }));
    }
}
