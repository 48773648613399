import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { classes } from 'classifizer';
import { endOfDay, format, isAfter, isBefore, isSameDay, isSameMonth, isValid, parse } from 'date-fns';
import { getClosestDueDate, getDueDatesForCalendarView, isForever, parseRecurringFormula } from '../../common/recurringRules';
import { getCalendarViewEndDate, getCalendarViewStartDate } from '../../common/recurringRules/utils';
import { isValidRecurringFormula } from '../../common/recurringRules/validate';
import { getDateFnsLocale } from '../../common/utils/locale';
import { getDateFormat, getDatePickerDateFormat } from '../../common/utils/time';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Button } from '../../uikit/Button/Button';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { FormInputText } from '../FormInputText/FormInputText';
import { TypedDitto } from '../TypedDitto';
import { CustomDayWrapper } from './CustomDay';
import { CustomHeader } from './CustomHeader';
import { RepeatForm } from './RepeatForm';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePickerContent.module.css';
export function DatePickerContent({ onConfirm, onFormulaChange, onInputFocus, onInputBlur, recurringFormula, locale: localeSupported, countryCode, fixedHeight, minDate, maxDate, showDateInputs, showRepeatForm, showNeverLabel, closestDueDate, layout = 'vertical' }) {
    var _a;
    const dateFormat = getDateFormat(countryCode);
    const locale = getDateFnsLocale(localeSupported, countryCode);
    const dueDateLabel = useDittoWrapper({ componentId: 'duedate' });
    const startDateLabel = useDittoWrapper({ componentId: 'timepicker.customtimeframe.startdate' });
    const endDateLabel = useDittoWrapper({ componentId: 'timepicker.customtimeframe.enddate' });
    const invalidDateFormatError = useDittoWrapper({ componentId: 'invalidformat' });
    const endDateError = useDittoWrapper({ componentId: 'timepicker.enddatecantbeinthepast' });
    const neverLabel = useDittoWrapper({
        componentId: 'timepicker.customtimeframe.enddate.never'
    });
    const endDateEmptyValue = showNeverLabel ? neverLabel : '';
    const datePickerFormat = getDatePickerDateFormat(countryCode);
    const [isRenderingMonth, setIsRenderingMonth] = useState(false);
    const [isChoosingStartDate, setIsChoosingStartDate] = useState(true);
    const [formula, setFormula] = useState(parseRecurringFormula(recurringFormula));
    const [startDateStrValue, setStartDateStrValue] = useState('');
    const [isStartDateInputInFocus, setIsStartDateInputInFocus] = useState(false);
    const [isUntilDateInputInFocus, setIsUntilDateInputInFocus] = useState(false);
    useEffect(() => {
        if (closestDueDate) {
            setStartDateStrValue(format(closestDueDate, datePickerFormat));
        }
        else if (formula === null || formula === void 0 ? void 0 : formula.startDate) {
            setStartDateStrValue(format(formula === null || formula === void 0 ? void 0 : formula.startDate, datePickerFormat));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [startDateError, setStartDateError] = useState();
    const [untilDateError, setUntilDateError] = useState();
    const [calendarViewMonthDate, setCalendarViewMonthDate] = useState(null);
    const setCalendarViewDueDates = useCallback((formula, date) => {
        const monthViewStart = getCalendarViewStartDate(date, locale);
        const monthViewEnd = getCalendarViewEndDate(date, locale);
        setDueDates(getDueDatesForCalendarView(formula, monthViewStart, monthViewEnd));
    }, [locale]);
    useEffect(() => {
        onFormulaChange === null || onFormulaChange === void 0 ? void 0 : onFormulaChange(formula);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formula]);
    let untilDateInitialValue = '';
    if (formula) {
        if (formula.untilDate && !isForever(formula.untilDate)) {
            untilDateInitialValue = format(formula.untilDate, datePickerFormat);
        }
        else {
            untilDateInitialValue = endDateEmptyValue;
        }
    }
    const [untilDateStrValue, setUntilDateStrValue] = useState(untilDateInitialValue);
    const [dueDates, setDueDates] = useState(getDueDatesForCalendarView(formula, getCalendarViewStartDate(calendarViewMonthDate ? calendarViewMonthDate : formula === null || formula === void 0 ? void 0 : formula.startDate, locale), getCalendarViewEndDate(calendarViewMonthDate ? calendarViewMonthDate : formula === null || formula === void 0 ? void 0 : formula.startDate, locale)));
    function handleCalendarMonthChange(date) {
        setCalendarViewMonthDate(date);
    }
    function handleMonthSelect(date) {
        setIsRenderingMonth(false);
        setCalendarViewMonthDate(date);
    }
    function handleStartDateClick() {
        setIsChoosingStartDate(true);
    }
    function handleUntilDateClick() {
        setIsChoosingStartDate(false);
    }
    function handleStartDateFocus() {
        onInputFocus === null || onInputFocus === void 0 ? void 0 : onInputFocus();
        setIsStartDateInputInFocus(true);
    }
    function handleStartDateBlur(event) {
        if (event.target.value === '') {
            setStartDateStrValue('');
            return;
        }
        let inputStartDate = parse(event.target.value, datePickerFormat, new Date(), {
            locale
        });
        if (!isValid(inputStartDate)) {
            inputStartDate = new Date();
        }
        setStartDateStrValue(format(inputStartDate, datePickerFormat, { locale }));
        setStartDateError(undefined);
        handleStartDateCalendarChange(inputStartDate);
        onInputBlur === null || onInputBlur === void 0 ? void 0 : onInputBlur();
        setIsStartDateInputInFocus(false);
    }
    function handleUntilDateFocus(event) {
        if (event.target.value === endDateEmptyValue) {
            setUntilDateStrValue('');
        }
        onInputFocus === null || onInputFocus === void 0 ? void 0 : onInputFocus();
        setIsUntilDateInputInFocus(true);
    }
    function handleUntilDateBlur(event) {
        if (event.target.value === '') {
            setUntilDateStrValue(endDateEmptyValue);
            return;
        }
        let inputUntilDate = parse(event.target.value, datePickerFormat, new Date(), {
            locale
        });
        if (!isValid(inputUntilDate)) {
            inputUntilDate = new Date();
        }
        setUntilDateStrValue(format(inputUntilDate, datePickerFormat, { locale }));
        onInputBlur === null || onInputBlur === void 0 ? void 0 : onInputBlur();
        setIsUntilDateInputInFocus(false);
    }
    function handleCalendarDateSelect(date) {
        if (isChoosingStartDate) {
            handleStartDateCalendarChange(date);
        }
        else if (formula && formula.frequency.repeat === 'yearly') {
            handleStartDateCalendarChange(date);
        }
        else if (formula &&
            formula.frequency.repeat === 'custom' &&
            formula.frequency.value.repeat === 'yearly') {
            handleStartDateCalendarChange(date);
        }
        else if (!isChoosingStartDate && formula && formula.frequency.repeat !== 'never') {
            handleUntilDateCalendarChange(date);
        }
        else {
            handleStartDateCalendarChange(date);
        }
        setCalendarViewMonthDate(date);
    }
    function handleStartDateCalendarChange(date) {
        if (!date) {
            setStartDateStrValue('');
            setFormula(null);
            return;
        }
        setStartDateError(undefined);
        if (formula) {
            setFormula(Object.assign(Object.assign({}, formula), { startDate: date, untilDate: undefined }));
            setUntilDateStrValue('');
            if (!formula.untilDate || isBefore(date, formula.untilDate)) {
                setUntilDateError(undefined);
            }
        }
        else {
            setFormula({
                frequency: {
                    repeat: 'never'
                },
                startDate: date
            });
            setUntilDateError(undefined);
        }
        setStartDateStrValue(format(date, datePickerFormat, { locale }));
        if (formula && (formula === null || formula === void 0 ? void 0 : formula.frequency.repeat) !== 'never') {
            setIsChoosingStartDate(false);
        }
    }
    function handleUntilDateCalendarChange(date) {
        if (!date) {
            setUntilDateStrValue('');
            return;
        }
        if (formula) {
            if (isBefore(date, formula.startDate)) {
                handleStartDateCalendarChange(date);
                return;
            }
            else {
                setUntilDateError(undefined);
            }
            setFormula(Object.assign(Object.assign({}, formula), { untilDate: date }));
        }
        else {
            setFormula({
                frequency: {
                    repeat: 'never'
                },
                startDate: date,
                untilDate: date
            });
            setUntilDateError(undefined);
        }
        setUntilDateStrValue(format(date, datePickerFormat, { locale }));
        setIsChoosingStartDate(true);
    }
    function handleStartDateFieldChange(value) {
        let newStartDate = parse(value, datePickerFormat, new Date(), {
            locale
        });
        if (minDate && newStartDate < minDate) {
            newStartDate = minDate;
        }
        if (formula !== null && isValid(newStartDate)) {
            if (!formula.untilDate || isBefore(newStartDate, formula.untilDate)) {
                setStartDateError(undefined);
                setFormula(Object.assign(Object.assign({}, formula), { startDate: newStartDate }));
            }
            else {
                setStartDateError(endDateError);
            }
        }
        else if (formula === null && isValid(newStartDate)) {
            setStartDateError(undefined);
            setFormula({ startDate: newStartDate, frequency: { repeat: 'never' } });
        }
        else {
            setStartDateError(invalidDateFormatError);
        }
        setStartDateStrValue(value);
    }
    function handleUntilDateFieldChange(value) {
        let newUntilDate = parse(value, datePickerFormat, new Date(), {
            locale
        });
        if (maxDate && newUntilDate > maxDate) {
            newUntilDate = maxDate;
        }
        if (isValid(newUntilDate) && (formula === null || formula === void 0 ? void 0 : formula.startDate)) {
            const newUntilDateWithEndOfDay = endOfDay(newUntilDate);
            if (isAfter(newUntilDateWithEndOfDay, formula.startDate)) {
                setUntilDateError(undefined);
                setFormula(Object.assign(Object.assign({}, formula), { untilDate: newUntilDateWithEndOfDay }));
            }
            else {
                setUntilDateError(endDateError);
                setFormula(Object.assign(Object.assign({}, formula), { untilDate: undefined }));
            }
        }
        else if (value === '' && formula) {
            setUntilDateError(undefined);
            setFormula(Object.assign(Object.assign({}, formula), { untilDate: undefined }));
        }
        else if (formula) {
            setUntilDateError(invalidDateFormatError);
            setFormula(Object.assign(Object.assign({}, formula), { untilDate: undefined }));
        }
        else {
            setUntilDateError(invalidDateFormatError);
        }
        setUntilDateStrValue(value);
    }
    function handleRepeatOptionChange(frequency) {
        const newFormula = {
            frequency,
            startDate: (formula === null || formula === void 0 ? void 0 : formula.startDate) || new Date(),
            untilDate: frequency.repeat === 'never' ? undefined : formula === null || formula === void 0 ? void 0 : formula.untilDate
        };
        const startDateStr = format(newFormula.startDate, datePickerFormat, { locale });
        const untilDateStr = newFormula.untilDate
            ? format(newFormula.untilDate, datePickerFormat, { locale })
            : endDateEmptyValue;
        setFormula(newFormula);
        setStartDateStrValue(startDateStr);
        setUntilDateStrValue(untilDateStr);
    }
    function handleConfirmButtonClick() {
        if (!onConfirm) {
            return;
        }
        onConfirm(formula);
    }
    function handleClearButtonClick() {
        if (!onConfirm) {
            return;
        }
        onConfirm(null);
    }
    const selected = (() => {
        if (isRenderingMonth) {
            return undefined;
        }
        if (isStartDateInputInFocus) {
            return formula === null || formula === void 0 ? void 0 : formula.startDate;
        }
        if (isUntilDateInputInFocus && (formula === null || formula === void 0 ? void 0 : formula.untilDate) && !isForever(formula === null || formula === void 0 ? void 0 : formula.untilDate)) {
            return formula === null || formula === void 0 ? void 0 : formula.untilDate;
        }
        if (!(formula === null || formula === void 0 ? void 0 : formula.startDate) && calendarViewMonthDate) {
            return calendarViewMonthDate;
        }
        else if (!(formula === null || formula === void 0 ? void 0 : formula.startDate)) {
            return new Date();
        }
    })();
    if (selected && calendarViewMonthDate && !isSameDay(selected, calendarViewMonthDate)) {
        setCalendarViewMonthDate(selected);
    }
    else if (selected && !calendarViewMonthDate) {
        setCalendarViewMonthDate(selected);
    }
    useEffect(() => {
        setCalendarViewDueDates(formula, calendarViewMonthDate !== null && calendarViewMonthDate !== void 0 ? calendarViewMonthDate : undefined);
    }, [calendarViewMonthDate, formula, setCalendarViewDueDates]);
    const singleDateInputs = showDateInputs && (_jsx("div", Object.assign({ className: classes(styles['content'], layout === 'vertical' && styles['date-inputs'], layout === 'horizontal' && styles['content-horizontal']) }, { children: _jsx(FormInputText, { label: formula && formula.frequency.repeat !== 'never' ? startDateLabel : dueDateLabel, value: startDateStrValue, placeholder: dateFormat, error: startDateError, onValueChange: handleStartDateFieldChange, onClick: handleStartDateClick, onFocus: handleStartDateFocus, onBlur: handleStartDateBlur }) })));
    const singleDate = (_jsx("div", Object.assign({ className: classes(styles['datepicker-container'], fixedHeight && styles['datepicker-container--fixed-height']) }, { children: _jsx(CustomDatePicker, { showMonthYearPicker: isRenderingMonth, calendarClassName: styles['task-datepicker'], monthClassName: (date) => {
                if (calendarViewMonthDate && isSameMonth(date, calendarViewMonthDate)) {
                    return styles['month--selected'];
                }
                return null;
            }, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: formula === null || formula === void 0 ? void 0 : formula.startDate, selected: selected, minDate: minDate, maxDate: maxDate, onChange: isRenderingMonth ? handleMonthSelect : handleCalendarDateSelect, locale: locale, dateFormat: datePickerFormat, renderCustomHeader: (params) => (_jsx(CustomHeader, Object.assign({ locale: locale, isRenderingMonth: isRenderingMonth, toggleMonthYear: () => setIsRenderingMonth(!isRenderingMonth) }, params))), renderDayContents: (dayOfMonth, date) => (_jsx(CustomDayWrapper, { dayOfMonth: dayOfMonth, date: date, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: (formula === null || formula === void 0 ? void 0 : formula.untilDate) && isForever(formula.untilDate)
                    ? undefined
                    : formula === null || formula === void 0 ? void 0 : formula.untilDate, minDate: minDate, maxDate: maxDate, selectedDate: formula === null || formula === void 0 ? void 0 : formula.startDate, highlightedDates: dueDates })), inline: true, disabledKeyboardNavigation: true, onMonthChange: handleCalendarMonthChange }) })));
    const dateRangeInputs = showDateInputs && (_jsxs("div", Object.assign({ className: classes(styles['content'], layout === 'vertical' && styles['date-inputs'], layout === 'horizontal' && styles['content-horizontal']) }, { children: [_jsx("span", Object.assign({ className: styles['content-half'] }, { children: _jsx(FormInputText, { label: startDateLabel, value: startDateStrValue, placeholder: dateFormat, error: startDateError, onValueChange: handleStartDateFieldChange, onClick: handleStartDateClick, onFocus: handleStartDateFocus, onBlur: handleStartDateBlur, variant: "editor-field" }) })), _jsx("span", Object.assign({ className: styles['content-half'] }, { children: _jsx(FormInputText, { label: endDateLabel, value: untilDateStrValue, placeholder: dateFormat, onValueChange: handleUntilDateFieldChange, error: untilDateError, disabled: !startDateStrValue, onClick: handleUntilDateClick, onFocus: handleUntilDateFocus, onBlur: handleUntilDateBlur, variant: "editor-field" }) }))] })));
    const dateRange = (_jsx("div", Object.assign({ className: classes(styles['datepicker-container'], fixedHeight && styles['datepicker-container--fixed-height']) }, { children: _jsx(CustomDatePicker, { showMonthYearPicker: isRenderingMonth, calendarClassName: styles['task-datepicker'], monthClassName: (date) => {
                if (calendarViewMonthDate && isSameMonth(date, calendarViewMonthDate)) {
                    return styles['month--selected'];
                }
                return null;
            }, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: (formula === null || formula === void 0 ? void 0 : formula.untilDate) && isForever(formula.untilDate) ? null : formula === null || formula === void 0 ? void 0 : formula.untilDate, selected: selected, minDate: minDate, maxDate: maxDate, onChange: isRenderingMonth ? handleMonthSelect : handleCalendarDateSelect, locale: locale, dateFormat: datePickerFormat, renderCustomHeader: (params) => (_jsx(CustomHeader, Object.assign({ locale: locale, isRenderingMonth: isRenderingMonth, toggleMonthYear: () => setIsRenderingMonth(!isRenderingMonth) }, params, { "data-test": "current-year" }))), renderDayContents: (dayOfMonth, date) => (_jsx(CustomDayWrapper, { dayOfMonth: dayOfMonth, date: date, startDate: formula === null || formula === void 0 ? void 0 : formula.startDate, endDate: (formula === null || formula === void 0 ? void 0 : formula.untilDate) && isForever(formula.untilDate)
                    ? undefined
                    : formula === null || formula === void 0 ? void 0 : formula.untilDate, minDate: minDate, maxDate: maxDate, selectedDate: formula === null || formula === void 0 ? void 0 : formula.startDate, highlightedDates: showRepeatForm ? dueDates : [] })), inline: true, disabledKeyboardNavigation: true, onMonthChange: handleCalendarMonthChange, openToDate: (_a = formula === null || formula === void 0 ? void 0 : formula.untilDate) !== null && _a !== void 0 ? _a : new Date() }) })));
    const buttons = (_jsxs("div", Object.assign({ className: styles['content'] }, { children: [_jsx(Button, Object.assign({ color: "gray", onClick: handleClearButtonClick }, { children: _jsx(TypedDitto, { componentId: "timepicker.clear" }) })), _jsx(Button, Object.assign({ disabled: !!startDateError ||
                    !!untilDateError ||
                    (formula &&
                        formula.frequency.repeat !== 'never' &&
                        (!formula.startDate || !formula.untilDate)
                        ? true
                        : false), color: "dark", onClick: handleConfirmButtonClick }, { children: _jsx(TypedDitto, { componentId: "modal.confirm" }) }))] })));
    if (layout === 'vertical') {
        let calendar;
        if (!formula || formula.frequency.repeat === 'never') {
            calendar = (_jsxs(_Fragment, { children: [singleDateInputs, singleDate] }));
        }
        else {
            calendar = (_jsxs(_Fragment, { children: [dateRangeInputs, dateRange] }));
        }
        return (_jsxs("div", { children: [calendar, showRepeatForm && (_jsx(RepeatForm, { frequency: (formula === null || formula === void 0 ? void 0 : formula.frequency) || { repeat: 'never' }, onChange: handleRepeatOptionChange, locale: locale })), buttons] }));
    }
    else if (layout === 'horizontal') {
        let calendar;
        let dateInputs;
        if (!formula || formula.frequency.repeat === 'never') {
            calendar = singleDate;
            dateInputs = singleDateInputs;
        }
        else {
            calendar = dateRange;
            dateInputs = dateRangeInputs;
        }
        const closestDueDate = getClosestDueDate(formula);
        let nextRepeat = '';
        if ((formula === null || formula === void 0 ? void 0 : formula.frequency.repeat) !== 'never' &&
            isValidRecurringFormula(formula) &&
            closestDueDate) {
            nextRepeat = format(closestDueDate || 0, datePickerFormat, { locale });
        }
        return (_jsxs("div", Object.assign({ className: styles['container-horizontal'] }, { children: [_jsx("div", Object.assign({ className: classes(styles['column'], styles['column-calendar']) }, { children: calendar })), _jsx("div", { className: styles['column-divider'] }), _jsxs("div", Object.assign({ className: styles['column'] }, { children: [dateInputs, _jsx("div", { className: styles['row-divider'] }), showRepeatForm && (_jsx(RepeatForm, { frequency: (formula === null || formula === void 0 ? void 0 : formula.frequency) || { repeat: 'never' }, onChange: handleRepeatOptionChange, locale: locale })), nextRepeat && (_jsx("div", Object.assign({ className: styles['next-repeat-date'] }, { children: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.repeatnext", variables: {
                                    NextRepearingTaskDate: nextRepeat
                                } }) })))] }))] })));
    }
    return _jsx(_Fragment, {});
}
