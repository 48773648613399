var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { Map as MapBoxMap } from 'react-map-gl';
import { classes } from 'classifizer';
import { MAP_STYLE_URL } from '../../common/consts/map';
import config from '../../config/config.json';
import './controls.css';
import styles from './Minimap.module.css';
export const Minimap = forwardRef(function Minimap(_a, ref) {
    var { size, noOutline, control, onStyleToggle, children, hide, variant } = _a, props = __rest(_a, ["size", "noOutline", "control", "onStyleToggle", "children", "hide", "variant"]);
    return (_jsxs("div", Object.assign({ className: classes('minimap-container-global', styles['container'], size && styles[`container__${size}`], hide && styles['container__hide'], noOutline && styles['container__no-outline'], onStyleToggle && styles['container__pointer'], variant && styles[`container--${variant}`]), onClick: onStyleToggle }, { children: [_jsx(MapBoxMap, Object.assign({ ref: ref, mapStyle: MAP_STYLE_URL, interactive: false }, props, { mapboxAccessToken: config.mapbox.token, style: { height: '100%', width: '100%' }, onLoad: (e) => {
                    e.target.resize();
                    if (props.onLoad) {
                        props.onLoad(e);
                    }
                } }, { children: children })), _jsx("div", Object.assign({ className: styles['control'] }, { children: control }))] })));
});
