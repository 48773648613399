import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useFloatingDropdown } from '../../../../hooks/forms/useFloatingDropdown';
import { Button } from '../../../../uikit/Button/Button';
import { ActionModal } from '../../../ActionModal/ActionModal';
import { FloatingDropdown } from '../../../FloatingDropdown/FloatingDropdown';
import styles from './DataGridTableRowItem.module.css';
const RIGHT_PANEL_WIDTH = 356;
const DROPDOWN_OFFSET = 4;
export const DataGridTableRowItem = forwardRef(({ content, inlineEdit, stickyPosition, textAlign, noPadding, height, onClick, 'data-test': dataTest }, ref) => {
    var _a;
    const { content: inlineEditContent, contentDirection, onOpen: inlineEditOnOpen, onSubmit: inlineEditOnSubmit, inputRef, customSubmitButton, isRightPanelOpen, menuWidth = 300, menuMaxHeight = 375, padding = 0 } = inlineEdit || {};
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown({});
    const [isHover, setIsHover] = useState(false);
    const tableWidth = useMemo(() => window.innerWidth - (isRightPanelOpen ? RIGHT_PANEL_WIDTH : 0), [isRightPanelOpen]);
    const isOutOfScreen = useMemo(() => {
        if (!buttonRef.current)
            return false;
        return buttonRef.current.getBoundingClientRect().left + menuWidth > tableWidth;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFloatingOpen, buttonRef, menuWidth]);
    useImperativeHandle(ref, () => ({
        hide: closeDropdown
    }));
    useEffect(() => {
        const handleKeyboardKey = (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                handleSubmit();
            }
            else if (event.key === 'Escape') {
                closeDropdown();
            }
        };
        if (inlineEditContent && isFloatingOpen) {
            window.addEventListener('keydown', handleKeyboardKey);
        }
        return () => {
            if (inlineEditContent && isFloatingOpen) {
                window.removeEventListener('keydown', handleKeyboardKey);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inlineEditContent, isFloatingOpen]);
    useEffect(() => {
        if ((inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) && isFloatingOpen) {
            // fix textarea cursor position / move cursor to the end of the text if it is textarea
            if (inputRef.current.selectionStart === 0) {
                inputRef.current.focus();
                inputRef.current.selectionStart = inputRef.current.selectionEnd =
                    inputRef.current.value.length;
            }
            else {
                inputRef.current.select();
            }
        }
    }, [inputRef, isFloatingOpen]);
    useEffect(() => {
        // fix problem with hover after click on dropdown
        if (!isFloatingOpen) {
            setIsHover(false);
        }
    }, [isFloatingOpen]);
    function closeDropdown() {
        hide();
        setIsHover(false);
    }
    function handleClick(event) {
        if (onClick)
            return onClick(event);
        if (!inlineEditContent)
            return undefined;
        if (inlineEditOnSubmit || customSubmitButton) {
            if (!isFloatingOpen) {
                inlineEditOnOpen && inlineEditOnOpen();
                return toggle();
            }
        }
        else {
            return toggle();
        }
    }
    function handleMouseEnter() {
        setIsHover(true);
    }
    function handleMouseLeave() {
        setIsHover(false);
    }
    function handleSubmit() {
        inlineEditOnSubmit && inlineEditOnSubmit();
        closeDropdown();
    }
    function getLeftPosition() {
        if (!buttonRef.current)
            return position.left;
        if (isOutOfScreen) {
            return (position.left +
                (tableWidth - (buttonRef.current.getBoundingClientRect().left + menuWidth)));
        }
        if (textAlign === 'right') {
            return (position.left +
                DROPDOWN_OFFSET +
                (buttonRef.current.offsetWidth || 0) -
                menuWidth);
        }
        return position.left - DROPDOWN_OFFSET;
    }
    return (_jsxs("td", Object.assign({ ref: buttonRef, className: classes(styles['container'], stickyPosition !== undefined && styles['container--pinned'], noPadding && styles['container--no-padding'], isHover && styles['container--hover'], onClick && styles['container--clickable']), "data-active": isFloatingOpen ? '' : undefined, "data-editable": inlineEditContent ? '' : undefined, style: { textAlign, left: stickyPosition }, onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, "data-test": dataTest }, { children: [isFloatingOpen && (_jsx(FloatingDropdown, Object.assign({}, position, { 
                /* fix dropdown position if it doesn't fit the screen */
                left: getLeftPosition(), triggerHeight: (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight, content: _jsxs(ActionModal, Object.assign({ className: styles['modal'], maxHeight: menuMaxHeight, width: menuWidth }, { children: [_jsx("div", Object.assign({ className: classes(styles['modal-content'], contentDirection === 'row' &&
                                styles[`modal-content--${contentDirection}`]), style: { padding } }, { children: inlineEditContent })), inlineEditOnSubmit && !customSubmitButton && (_jsx("div", Object.assign({ className: styles['buttons-container'] }, { children: _jsx(Button, Object.assign({ color: "dark", onClick: handleSubmit }, { children: _jsx(Ditto, { componentId: "done" }) })) })))] })), onClickOutside: inlineEditOnSubmit || customSubmitButton ? handleSubmit : closeDropdown }))), _jsx("div", Object.assign({ className: styles['content'], style: { height } }, { children: content }))] })));
});
DataGridTableRowItem.displayName = 'DataGridTableRowItem';
