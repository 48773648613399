export var TITLE;
(function (TITLE) {
    TITLE[TITLE["mr"] = 1] = "mr";
    TITLE[TITLE["ms"] = 2] = "ms";
    TITLE[TITLE["mx"] = 3] = "mx";
    TITLE[TITLE["notitle"] = 4] = "notitle";
})(TITLE || (TITLE = {}));
export function getTitles() {
    return Object.entries(TITLE).filter((item) => {
        return isNaN(Number(item[0]));
    });
}
export var PRIVILEGE_LEVEL;
(function (PRIVILEGE_LEVEL) {
    PRIVILEGE_LEVEL[PRIVILEGE_LEVEL["admin"] = 10000] = "admin";
    PRIVILEGE_LEVEL[PRIVILEGE_LEVEL["editor"] = 5000] = "editor";
    PRIVILEGE_LEVEL[PRIVILEGE_LEVEL["viewer"] = 1000] = "viewer";
    PRIVILEGE_LEVEL[PRIVILEGE_LEVEL["disabled"] = 0] = "disabled";
})(PRIVILEGE_LEVEL || (PRIVILEGE_LEVEL = {}));
export var ACCOUNT_TYPE;
(function (ACCOUNT_TYPE) {
    ACCOUNT_TYPE[ACCOUNT_TYPE["customer"] = 1] = "customer";
    ACCOUNT_TYPE[ACCOUNT_TYPE["test"] = 2] = "test";
    ACCOUNT_TYPE[ACCOUNT_TYPE["demo"] = 3] = "demo";
    ACCOUNT_TYPE[ACCOUNT_TYPE["template"] = 4] = "template";
    ACCOUNT_TYPE[ACCOUNT_TYPE["obsolete"] = 5] = "obsolete";
    ACCOUNT_TYPE[ACCOUNT_TYPE["none"] = 6] = "none";
})(ACCOUNT_TYPE || (ACCOUNT_TYPE = {}));
