import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { AnimatePresence, motion } from 'framer-motion';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { CaretLeft, CaretRight } from '../../components/icons';
import { useMountDelay } from '../../hooks/useMountDelay';
import { APP_LAYOUT_DETAIL_PANEL_MOUNT_DELAY_MS } from '../../hooks/useTabBar';
import { NotificationBadge } from '../NotificationBadge/NotificationBadge';
import styles from './TabBar.module.css';
export function TabBar({ className, labelClassName, current, setCurrent, tabs, alignerWidth = 12, disableScrollTo, width, variant }) {
    const ref = React.useRef(null);
    const refs = Array(tabs.length)
        .fill(null)
        .map(() => React.createRef());
    const refTabsWrapper = React.useRef(null);
    const prevTab = useRef(refs[current].current);
    const { mounting } = useMountDelay(APP_LAYOUT_DETAIL_PANEL_MOUNT_DELAY_MS);
    const refStart = React.createRef();
    const refEnd = React.createRef();
    const [isStartVisible, setIstStartVisible] = useState(false);
    const [isEndVisible, setIsEndVisible] = useState(false);
    const checkStartAndEndVisibility = useCallback(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const rectContainer = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const rectStart = (_b = refStart.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        const rectEnd = (_c = refEnd.current) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect();
        const startDiff = ((_d = rectContainer === null || rectContainer === void 0 ? void 0 : rectContainer.x) !== null && _d !== void 0 ? _d : 0) - ((_e = rectStart === null || rectStart === void 0 ? void 0 : rectStart.x) !== null && _e !== void 0 ? _e : 0);
        const endDiff = ((_f = rectEnd === null || rectEnd === void 0 ? void 0 : rectEnd.x) !== null && _f !== void 0 ? _f : 0) +
            ((_g = rectEnd === null || rectEnd === void 0 ? void 0 : rectEnd.width) !== null && _g !== void 0 ? _g : 0) -
            (((_h = rectContainer === null || rectContainer === void 0 ? void 0 : rectContainer.x) !== null && _h !== void 0 ? _h : 0) + ((_j = rectContainer === null || rectContainer === void 0 ? void 0 : rectContainer.width) !== null && _j !== void 0 ? _j : 0));
        // The tabs themselves are still visible if the
        // difference does not exceed the width of the aligner.
        // In this case we don't show the arrow/caret.
        const startVisible = startDiff <= alignerWidth;
        const endVisible = endDiff <= alignerWidth;
        setIstStartVisible(startVisible);
        setIsEndVisible(endVisible);
    }, [alignerWidth, ref, refEnd, refStart]);
    useEffect(() => {
        const tab = refs[current].current;
        if (!tab || disableScrollTo) {
            return;
        }
        if (mounting.current) {
            prevTab.current = tab;
            return;
        }
        if (tab !== prevTab.current) {
            requestAnimationFrame(() => {
                tab.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center'
                });
            });
        }
        prevTab.current = tab;
    }, [refs, current, disableScrollTo, mounting, refTabsWrapper]);
    useEffect(() => {
        checkStartAndEndVisibility();
    }, [checkStartAndEndVisibility]);
    useEffect(() => {
        const listener = () => {
            checkStartAndEndVisibility();
        };
        const wrapper = refTabsWrapper.current;
        wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener('scroll', listener, { passive: true });
        return () => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.removeEventListener('scroll', listener);
        };
    }, [checkStartAndEndVisibility, refTabsWrapper]);
    function handleLeftCaretClick() {
        var _a, _b, _c;
        (_a = refTabsWrapper.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
            left: -((_c = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.clientWidth) !== null && _c !== void 0 ? _c : 1) / 3,
            behavior: 'smooth'
        });
    }
    function handleRightCaretClick() {
        var _a, _b, _c;
        (_a = refTabsWrapper.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
            left: ((_c = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.clientWidth) !== null && _c !== void 0 ? _c : 1) / 3,
            behavior: 'smooth'
        });
    }
    const tabElements = tabs.map((tab, index) => (_jsxs("div", Object.assign({ ref: refs[index], className: classes(styles['item'], current === index && styles['item__current']), onClick: () => setCurrent(index), style: { width: tab.width }, "data-test": "tab-bar-item" }, { children: [_jsxs("div", Object.assign({ className: styles['content'] }, { children: [_jsx("div", Object.assign({ className: classes(styles['label'], labelClassName) }, { children: tab.label })), tab.badgeContent && _jsx(NotificationBadge, { content: tab.badgeContent })] })), _jsx("div", { className: styles['indicator'] })] }), index)));
    return (_jsxs("div", Object.assign({ ref: ref, className: classes(styles['container'], styles[`container--${variant}`], className), style: { width } }, { children: [_jsxs("div", Object.assign({ ref: refTabsWrapper, className: styles['wrapper'] }, { children: [_jsx("div", { ref: refStart, className: styles['aligner'], style: { width: alignerWidth } }), tabElements, _jsx("div", { ref: refEnd, className: styles['aligner'], style: { width: alignerWidth } })] })), _jsx(AnimatePresence, { children: !isStartVisible && (_jsx(motion.div, Object.assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.15 }, className: styles['caret-left'], onClick: handleLeftCaretClick }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(CaretLeft, {}) })) }), 'caret-left')) }), _jsx(AnimatePresence, { children: !isEndVisible && (_jsx(motion.div, Object.assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.15 }, className: styles['caret-right'], onClick: handleRightCaretClick, "data-test": "caret-right" }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(CaretRight, {}) })) }), 'caret-right')) })] })));
}
