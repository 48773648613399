import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useState } from 'react';
import { classes } from 'classifizer';
import { Expander } from '../../../../uikit/Expander/Expander';
import { Grabber } from '../../../../uikit/Grabber/Grabber';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Bin } from '../../../icons';
import styles from './SettingRow.module.css';
const INDENT_PER_LEVEL = 40;
const ICON_PLACEHOLDER_WIDTH = 40;
export const SettingRow = forwardRef(function SettingRow({ icon, value = '', placeholder, onChange, count, indentLevel = 0, children, innerIcon, onDelete, trailingSelect, innerIconOpacity = 1, variant = 'input', autoFocus, onClick, 
// TODO: default value should be false by convention!!
initiallyExpanded = true, expandable = false, draggable }, ref) {
    const [isRowHovering, setIsRowHovering] = useState(false);
    const [isExpanded, setIsExpanded] = useState(expandable && initiallyExpanded);
    const isInputReadOnly = !onChange;
    const showLeadingIcon = icon !== 'hidden';
    return (_jsxs("div", Object.assign({ ref: ref, className: styles['wrapper'], "data-test": "settings-row" }, { children: [_jsxs("div", Object.assign({ className: classes(styles['container'], isRowHovering && styles['container__hover']), style: { paddingLeft: indentLevel * INDENT_PER_LEVEL }, onMouseEnter: () => setIsRowHovering(true), onMouseLeave: () => setIsRowHovering(false) }, { children: [_jsx("div", Object.assign({ className: classes(styles['drag-control-placeholder']), style: {
                            transform: `translateX(${icon ? 0 : ICON_PLACEHOLDER_WIDTH}px)`
                        }, "aria-disabled": !draggable }, { children: isRowHovering && draggable && _jsx(Grabber, { variant: "solid" }) })), showLeadingIcon && _jsx("div", Object.assign({ className: styles['icon-placeholder'] }, { children: icon })), _jsx("div", Object.assign({ className: styles['inner-icon'], style: { opacity: innerIconOpacity } }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: innerIcon })) })), variant === 'input' && (_jsx("input", { className: classes(styles['input'], trailingSelect && styles['input__half'], isInputReadOnly && styles[`input__read-only`]), style: { paddingLeft: innerIcon ? '40px' : '12px' }, type: "text", value: value, placeholder: placeholder, onChange: (e) => onChange && onChange(e.target.value), readOnly: isInputReadOnly, autoFocus: autoFocus, "data-test": "text-input" })), variant === 'button' && (_jsx("button", Object.assign({ className: styles['button'], onClick: onClick, style: { paddingLeft: innerIcon ? '40px' : '12px' } }, { children: value }))), trailingSelect && (_jsx("div", Object.assign({ className: styles['trailing-select'] }, { children: trailingSelect }))), _jsx("div", Object.assign({ className: styles['count'] }, { children: count })), _jsx("div", Object.assign({ className: styles['icon-placeholder'] }, { children: expandable && (_jsx("div", Object.assign({ className: styles['icon'], onClick: () => setIsExpanded(!isExpanded) }, { children: _jsx(Expander, { variant: isExpanded ? 'open' : 'default' }) }))) })), _jsx("div", Object.assign({ className: styles['icon-placeholder'] }, { children: isRowHovering && onDelete && (_jsx("div", Object.assign({ className: styles['icon'], onClick: onDelete }, { children: _jsx(Bin, {}) }))) }))] })), isExpanded && children] })));
});
