import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { PRINT_FIELD_WIDTH } from '../../common/consts/width';
import { MarkerPriorityIcon } from '../../common/convert';
import { getAttributeInputValue } from '../../common/convert/asset';
import { AssetIcon, Icon } from '../../common/icon';
import { getUniversalLinkForAsset } from '../../common/link';
import { uniqueV2 } from '../../common/utils/array';
import { CommonAttributeField } from '../../components/CommonAttributeField/CommonAttributeField';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../components/FormInputSelect/FormInputSelect';
import { FormInputTextarea } from '../../components/FormInputTextarea/FormInputTextarea';
import { IconText } from '../../components/IconText/IconText';
import { Loader } from '../../components/Loader/Loader';
import { MapboxStaticImage } from '../../components/PrintLayout/MapboxStaticImage/MapboxStaticImage';
import { PrintDetail } from '../../components/PrintLayout/PrintDetail/PrintDetail';
import { PrintField } from '../../components/PrintLayout/PrintField/PrintField';
import { PrintImage } from '../../components/PrintLayout/PrintImage/PrintImage';
import { PrintLayout } from '../../components/PrintLayout/PrintLayout';
import { PrintMeta } from '../../components/PrintLayout/PrintMeta/PrintMeta';
import { QRCodeHolder } from '../../components/PrintLayout/QRCodeHolder/QRCodeHolder';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { usePrint } from '../../hooks/usePrint';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchAssetTypes } from '../../state/slices/shared';
import { fetchAssetsTk, fetchLinkedMarkersTk } from '../../state/slices/sharedDataHolder';
export function PrintAssetContainer({ ids }) {
    const dispatch = useAppDispatch();
    const { assetList, linkedMarkerList } = useAppSelector((s) => s.sharedDataHolder);
    const { assetTypeMap, unitMap } = useAppSelector((s) => s.shared);
    const { onMapLoad, onImageLoad, setImageCount } = usePrint(ids.length);
    const formatter = useFormatter();
    const { linkAssetsTasksEnabled } = useLayerChecker();
    const taskdescription = useDittoWrapper({ componentId: 'taskdescription' });
    const notspecified = useDittoWrapper({ componentId: 'notspecified' });
    useEffect(() => {
        dispatch(fetchAssetsTk({ ids }));
        dispatch(fetchAssetTypes());
    }, [dispatch, ids]);
    useEffect(() => {
        if (!assetList) {
            return;
        }
        const allMarkerIds = assetList.flatMap((v) => v.markerIds);
        if (linkAssetsTasksEnabled && allMarkerIds.length > 0) {
            dispatch(fetchLinkedMarkersTk(uniqueV2(allMarkerIds)));
        }
        const totalImagesWithUrl = assetList
            .map((r) => r.photos.filter((p) => !!p.imageThumbUrl).length)
            .reduce((total, photoCount) => total + photoCount, 0);
        setImageCount(totalImagesWithUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetList]);
    if (assetList.length === 0 || Object.keys(assetTypeMap).length === 0) {
        return _jsx(Loader, { variant: "fullscreen" });
    }
    return (_jsx(_Fragment, { children: assetList.map((asset) => {
            const dateTime = formatter.formatDate(asset.createdAt);
            const assetType = assetTypeMap[asset.inventoryTypeId];
            if (!assetType) {
                return;
            }
            return (_jsx(PrintLayout, { meta: _jsx(PrintMeta, { layerName: _jsx(Ditto, { componentId: "rightpanel.titletype.inventory" }), icon: _jsx(AssetIcon, { iconId: assetType.iconId }), title: asset.title || _jsx(Ditto, { componentId: "tasktitle.notitle" }), date: dateTime.date, time: dateTime.time, id: asset.id, address: formatter.formatAddress(asset.address), location: {
                        lat: asset.coordinates[1],
                        lng: asset.coordinates[0]
                    } }), details: _jsxs(PrintDetail, Object.assign({ fullwidthField: _jsxs(_Fragment, { children: [linkAssetsTasksEnabled && (_jsx(PrintField, Object.assign({ label: _jsx(Ditto, { componentId: "tasks.linkedtasks" }) }, { children: linkedMarkerList
                                    .filter((i) => i.manualInventoryId === asset.id)
                                    .map((v) => (_jsx(IconText, { icon: _jsx(MarkerPriorityIcon, { value: v.priorityTypeId }), text: v.title || (_jsx(Ditto, { componentId: "tasktitle.notitle" })) }, v.id))) }))), _jsx(FormInputTextarea, { label: taskdescription, placeholder: notspecified, width: "100%", value: asset.notes, variant: "ghost", autosize: true, disabled: true })] }) }, { children: [_jsx(FormInputSelect, { variant: "ghost", width: PRINT_FIELD_WIDTH, disabled: true, label: _jsx(Ditto, { componentId: "assets.assettype" }), value: _jsx(FormInputCurrentSelectedValue, { text: assetType.name, icon: _jsx(AssetIcon, { iconId: assetType.iconId }) }) }), assetType.attributes.map((attribute) => {
                            var _a, _b;
                            return (_jsx(CommonAttributeField, { attribute: attribute, unit: attribute.unitId
                                    ? (_a = unitMap[attribute.unitId]) === null || _a === void 0 ? void 0 : _a.abbreviation
                                    : undefined, initialValue: (_b = getAttributeInputValue(attribute, asset)) === null || _b === void 0 ? void 0 : _b.toString(), variant: "ghost", disabled: true, width: PRINT_FIELD_WIDTH, formatter: formatter }, attribute.id));
                        })] })), qrCode: _jsx(QRCodeHolder, { instruction: _jsx(Ditto, { componentId: "print.qrcodecta" }), qrCodeList: [
                        {
                            url: getUniversalLinkForAsset(asset.id),
                            icon: _jsx(Icon, { name: "ToDo" }),
                            text: _jsx(Ditto, { componentId: "print.qrcodeopenintodoapp" }),
                            codePosition: 'left'
                        }
                    ] }), map: _jsx(MapboxStaticImage, { lon: asset.coordinates[0], lat: asset.coordinates[1], onLoad: onMapLoad, overlayElement: _jsx(AssetIcon, { iconId: assetType.iconId, format: "png", size: 30 }), overlayAnchor: "center" }), images: asset.photos
                    .filter((p) => !!p.imageThumbUrl)
                    .map((p) => (_jsx(PrintImage, { src: p.imageThumbUrl, onLoad: onImageLoad, caption: formatter.formatDate(p.createdAt).dateTime }, p.id))) }, asset.id));
        }) }));
}
