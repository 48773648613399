import { MapboxContextTypes } from '../services/mapbox';
/**
 * @deprecated, use useFormatter() instead
 */
export function referencingOrStreetAndHouseNumber({ geocoding, linearReferencing: ref } = {}, fallback = '', countryCode) {
    if (!geocoding)
        return fallback;
    if (ref === null || ref === void 0 ? void 0 : ref.found) {
        let parts = [ref.name, ref.node];
        if (ref.distance) {
            parts = [...parts, ref.distance.toFixed(0)];
        }
        return parts.filter((p) => !!p).join(', ');
    }
    return streetAndHouseNumber(geocoding, fallback, countryCode);
}
/**
 * @deprecated, use useFormatter() instead
 */
export function streetAndHouseNumber(geocoding, fallback, countryCode) {
    if (!geocoding)
        return fallback;
    const useUSFormat = countryCode === 'US';
    let parts = [geocoding.road, geocoding.streetNumber];
    if (useUSFormat) {
        parts = [geocoding.streetNumber, geocoding.road];
    }
    return parts.filter((p) => !!p).join(' ') || fallback;
}
/**
 * @deprecated, use useFormatter() instead
 */
export function getSuggestionTitle(feature, countryCode) {
    const useUSFormat = countryCode === 'US';
    let houseNumber = feature.address;
    if (houseNumber) {
        return `${feature.text} ${houseNumber}`;
    }
    for (const c of feature.context) {
        if (c.id.includes(MapboxContextTypes.address)) {
            houseNumber = c.text;
        }
    }
    if (houseNumber) {
        if (useUSFormat) {
            return `${houseNumber} ${feature.text}`;
        }
        return `${feature.text} ${houseNumber}`;
    }
    return feature.text;
}
/**
 * @deprecated, use useFormatter() instead
 */
export function getSuggestionDescription(feature, countryCode) {
    const useUSFormat = countryCode === 'US';
    let postcode = '';
    let city = '';
    let country = '';
    let region = '';
    for (const c of feature.context) {
        if (c.id.includes(MapboxContextTypes.postcode)) {
            postcode = c.text;
        }
        if (c.id.includes(MapboxContextTypes.place)) {
            city = c.text;
        }
        if (c.id.includes(MapboxContextTypes.country)) {
            country = c.text;
        }
        if (c.id.includes(MapboxContextTypes.region)) {
            region = c.text;
            if (useUSFormat && c.short_code) {
                region = c.short_code.replace('US-', '');
            }
        }
    }
    let addressArray = [`${postcode}${city && ` ${city}`}`, country];
    if (useUSFormat) {
        addressArray = [city, `${region}${postcode && ` ${postcode}`}`];
    }
    return addressArray.filter((p) => !!p).join(', ');
}
