// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nwzdcCiWo7fGdyF2pwgg{position:fixed;width:100vw;height:100vh;z-index:1060;cursor:default}.xjHSmuG9B3aTCUqrsiXQ{position:absolute;z-index:var(--zindex-dropdown)}.KLW2Gu6V0aHnnuR6HZHV{transform:translate(0, -117%)}`, "",{"version":3,"sources":["webpack://./src/components/FloatingDropdown/FloatingDropdown.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,WAAA,CACA,YAAA,CAEA,YAAA,CACA,cAAA,CAGJ,sBACI,iBAAA,CACA,8BAAA,CAGJ,sBACI,6BAAA","sourcesContent":[".container {\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    /* Higher than the convention for dropdowns opened in FileGallery to work */\n    z-index: 1060;\n    cursor: default;\n}\n\n.content-container {\n    position: absolute;\n    z-index: var(--zindex-dropdown);\n}\n\n.anchor__up-right {\n    transform: translate(0, calc(-117%));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nwzdcCiWo7fGdyF2pwgg`,
	"content-container": `xjHSmuG9B3aTCUqrsiXQ`,
	"anchor__up-right": `KLW2Gu6V0aHnnuR6HZHV`
};
export default ___CSS_LOADER_EXPORT___;
