export const SETTING_PAGE_ID = {
    notification: 'notification',
    assetType: 'asset-type',
    assetFolders: 'asset-folders',
    planBudgetAndNetworkView: 'plan-budget-and-network',
    planTableView: 'plan-table-view',
    planTreatments: 'plan-treatments',
    planProperties: 'plan-properties',
    signInventoryFiles: 'sign-inventory-files',
    signInventoryProperties: 'sign-inventory-properties'
};
/**
 * naming convention:
 * - camel case
 * - as exact as possible to avoid naming conflicts
 * - default should always be false/undefined, do not send default values to backend
 *
 * NOTE:
 * - please do NOT change existing keys, or the same popup will show again to the same user.
 * - you can add new keys here below, backend already supports it.
 */
export const POPUP_SETTINGS_FIELDNAME = {
    userClosedAbtmWebinarBanner: 'userClosedAbtmWebinarBanner',
    doNotShowAbtmIntroModalAgain: 'doNotShowAbtmIntroModalAgain',
    doNotShowSignInventoryIntroModalAgain: 'doNotShowSignInventoryIntroModalAgain',
    doNotShowDefaultTreatmentsIntroModalAgain: 'doNotShowDefaultTreatmentsIntroModalAgain',
    doNotShowRemainingServiceLifeIntroModalAgain: 'doNotShowRemainingServiceLifeIntroModalAgain'
};
export const CLIENT_SETTINGS_FIELDNAME = {
    activeLayers: 'activeLayers',
    improvedLayerSelector: 'improvedLayerSelector'
};
