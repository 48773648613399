import { useAppSelector } from '../state/hooks';
export function useLayoutChecker() {
    const { activeTabKey } = useAppSelector((state) => state.leftPanelPlan);
    const { selectedLayerId } = useAppSelector((state) => state.layer);
    const { teamAccount, userAccount } = useAppSelector((state) => state.auth);
    const showMainMap = !(selectedLayerId === 'plan' && activeTabKey === 'table');
    const showBanner = (function () {
        if (!teamAccount || !userAccount) {
            return false;
        }
        const showBannerCountryList = ['DE', 'FR', 'CH', 'AT'];
        // Should be shown at very top of web system (no matter which layer/preset selected) for team accounts with the following countries set in team account overview in EU environment (as we only will do webinars in DE and FR language)
        if (!showBannerCountryList.includes(teamAccount.countryCode)) {
            return false;
        }
        // Should only be shown until end of Fr, 17.05.2024 (as last webinar will take place on 16.05.2024 or latest 17.05.2024)
        if (new Date() > new Date('2024-05-17')) {
            return false;
        }
        // When a user (=user account) clicks on ‘X', it should really(!) never ever ever appear again for this user. We don’t wanna annoy them
        if (userAccount.popupSettings.userClosedAbtmWebinarBanner) {
            return false;
        }
        return true;
    })();
    return { showMainMap, showBanner };
}
