// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XfRFFI0sfiTMN2degxLh{cursor:grab}`, "",{"version":3,"sources":["webpack://./src/uikit/Grabber/Grabber.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA","sourcesContent":[".container {\n    cursor: grab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XfRFFI0sfiTMN2degxLh`
};
export default ___CSS_LOADER_EXPORT___;
