// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tzlMmFHPjPggLk735Vqm{height:100%;width:100%;object-fit:cover;object-position:center}`, "",{"version":3,"sources":["webpack://./src/components/ImagePreview/Image/Image.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":[".image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    object-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `tzlMmFHPjPggLk735Vqm`
};
export default ___CSS_LOADER_EXPORT___;
