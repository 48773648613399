import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Button } from '../../../uikit/Button/Button';
import { Close, SegmentCustom } from '../../icons';
import styles from './NoPlannedSegments.module.css';
export function NoPlannedSegments({ onClose, onButtonClick }) {
    const wikiLink = useDittoWrapper({ componentId: 'wikilink.planningtool' });
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['header'] }, { children: [_jsx(Ditto, { componentId: "layerpanel.maplayermodal.plannedsegments" }), _jsx(Button, { onClick: onClose, icon: _jsx(Close, {}), variant: "square" })] })), _jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "emptystate.noplannedsegments" }) })), _jsx("div", Object.assign({ className: styles['description'] }, { children: _jsx(Ditto, { componentId: "emptystate.plannedsegments.text" }) })), _jsx("div", Object.assign({ className: styles['wikilink'] }, { children: _jsx("a", Object.assign({ href: wikiLink, target: "_blank", rel: "noreferrer" }, { children: _jsx(Ditto, { componentId: "readmoreinourwiki" }) })) })), _jsx("div", Object.assign({ className: styles['image'] }, { children: _jsx("img", { src: "/create-planned-segment.png", alt: "Create Planned Segment" }) })), _jsx(Button, Object.assign({ className: styles['button'], color: "purple", icon: _jsx(SegmentCustom, {}), onClick: () => onButtonClick() }, { children: _jsx("span", { children: _jsx(Ditto, { componentId: "createplannedsegment" }) }) }))] })));
}
