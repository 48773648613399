import { createLogger } from 'redux-logger';
const ignoredActions = [
    'map/setHovered',
    'map/clearHovered',
    'map/setMapZoom',
    'map/setMapCenter',
    'map/setMapBound'
];
export const logger = createLogger({
    predicate: (_, action) => {
        return !(action && ignoredActions.includes(action.type));
    }
});
