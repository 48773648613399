import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { CategoryBadge } from '../../components/CategoryBadge/CategoryBadge';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Asterisk, Check, InProgress, OneTime, Recurring } from '../../components/icons';
import { Vignette } from '../../components/Vignette/Vignette';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { AvatarHover } from '../../uikit/AvatarHover/AvatarHover';
import { MARKER_PRIORITY_COLORS } from '../color';
import { MARKER_CHANGELOG_OPERATION, MARKER_INTERVAL, MARKER_PRIORITY, MARKER_PRIORITY_ID, MARKER_SOURCE_ID, MARKER_STATUS_ID } from '../consts/marker';
import { Icon } from '../icon';
export function getMarkerCluster(object) {
    const items = [];
    if (object === undefined) {
        return items;
    }
    if (object[MARKER_PRIORITY.low]) {
        items.push({
            color: MARKER_PRIORITY_COLORS.low,
            count: object[MARKER_PRIORITY.low]
        });
    }
    if (object[MARKER_PRIORITY.medium]) {
        items.push({
            color: MARKER_PRIORITY_COLORS.medium,
            count: object[MARKER_PRIORITY.medium]
        });
    }
    if (object[MARKER_PRIORITY.high]) {
        items.push({
            color: MARKER_PRIORITY_COLORS.high,
            count: object[MARKER_PRIORITY.high]
        });
    }
    if (object[MARKER_PRIORITY.noPriority]) {
        items.push({
            color: MARKER_PRIORITY_COLORS.noPriority,
            count: object[MARKER_PRIORITY.noPriority]
        });
    }
    return items;
}
export function getMarkerSourceLabel(source) {
    switch (source) {
        case MARKER_SOURCE_ID.webApp:
            return _jsx(Ditto, { componentId: "changelog.createdinweb-app" });
        case MARKER_SOURCE_ID.mobileApp:
            return (_jsx(Ditto, { componentId: "changelog.createdinvialyticsconditionapporvialyticstodoapp" }));
        case MARKER_SOURCE_ID.flutterApp:
            return _jsx(Ditto, { componentId: "changelog.createdinvialyticstodoapp" });
        case MARKER_SOURCE_ID.iosApp:
            return _jsx(Ditto, { componentId: "changelog.createdinvialyticsconditionapp" });
    }
}
export function MarkerStatusIcon({ value }) {
    switch (value) {
        case MARKER_STATUS_ID.open:
            return _jsx(Asterisk, {});
        case MARKER_STATUS_ID.inProgress:
            return _jsx(InProgress, {});
        case MARKER_STATUS_ID.closed:
            return _jsx(Check, {});
        default:
            return _jsx(_Fragment, {});
    }
}
export function MarkerStatusVignette({ value }) {
    return (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(MarkerStatusIcon, { value: value }) })), backgroundColor: getMarkerStatusColor(value), "data-test": `status-id-${value}` }));
}
function MarkerIntervalIcon({ value }) {
    switch (value) {
        case MARKER_INTERVAL.oneTime:
            return _jsx(OneTime, {});
        case MARKER_INTERVAL.recurring:
            return _jsx(Recurring, {});
        default:
            return _jsx(_Fragment, {});
    }
}
export function MarkerIntervalVignette({ value }) {
    return (_jsx(Vignette, { content: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(MarkerIntervalIcon, { value: value }) })), backgroundColor: "#EFF0F0" }));
}
export function getMarkerIntervalLabel(value) {
    switch (value) {
        case MARKER_INTERVAL.oneTime:
            return _jsx(Ditto, { componentId: "intervall.onetime" });
        case MARKER_INTERVAL.recurring:
            return _jsx(Ditto, { componentId: "intervall.recurring" });
        default:
            return _jsx(_Fragment, {});
    }
}
function getMarkerStatusColor(value) {
    switch (value) {
        case MARKER_STATUS_ID.open:
            return 'rgba(247, 229, 151, 1)';
        case MARKER_STATUS_ID.inProgress:
            return 'rgba(200, 200, 200, 1)';
        case MARKER_STATUS_ID.closed:
            return 'rgba(167, 227, 178, 1)';
        default:
            return '#ffffff';
    }
}
export function getMarkerStatusLabel(value) {
    switch (value) {
        case MARKER_STATUS_ID.open:
            return _jsx(Ditto, { componentId: "marker.status.open" });
        case MARKER_STATUS_ID.inProgress:
            return _jsx(Ditto, { componentId: "marker.status.inprogress" });
        case MARKER_STATUS_ID.closed:
            return _jsx(Ditto, { componentId: "marker.status.closed" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function MarkerPriorityIcon({ value }) {
    switch (value) {
        case MARKER_PRIORITY_ID.high:
            return _jsx(Icon, { name: "Highest", dataTest: "high-icon" });
        case MARKER_PRIORITY_ID.medium:
            return _jsx(Icon, { name: "Medium", dataTest: "medium-icon" });
        case MARKER_PRIORITY_ID.low:
            return _jsx(Icon, { name: "Lowest", dataTest: "low-icon" });
        case MARKER_PRIORITY_ID.noPriority:
            return _jsx(Icon, { name: "None", dataTest: "no-priority-icon" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getMarkerPriorityImageName(value) {
    switch (value) {
        case MARKER_PRIORITY_ID.high:
            return 'poi-red';
        case MARKER_PRIORITY_ID.medium:
            return 'poi-yellow';
        case MARKER_PRIORITY_ID.low:
            return 'poi-green';
        case MARKER_PRIORITY_ID.noPriority:
            return 'poi-grey';
        default:
            return '';
    }
}
export function getMarkerPriorityName(value) {
    switch (value) {
        case MARKER_PRIORITY_ID.high:
            return 'high';
        case MARKER_PRIORITY_ID.medium:
            return 'medium';
        case MARKER_PRIORITY_ID.low:
            return 'low';
        case MARKER_PRIORITY_ID.noPriority:
            return 'noPriority';
        default:
            return '';
    }
}
export function getMarkerPriorityLabel(value) {
    switch (value) {
        case MARKER_PRIORITY_ID.high:
            return _jsx(Ditto, { componentId: "marker.priority.high" });
        case MARKER_PRIORITY_ID.medium:
            return _jsx(Ditto, { componentId: "marker.priority.medium" });
        case MARKER_PRIORITY_ID.low:
            return _jsx(Ditto, { componentId: "marker.priority.low" });
        case MARKER_PRIORITY_ID.noPriority:
            return _jsx(Ditto, { componentId: "marker.priority.nopriority" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function useMarkerTranslations() {
    const highPriority = useDittoWrapper({ componentId: 'marker.priority.high' });
    const mediumPriority = useDittoWrapper({ componentId: 'marker.priority.medium' });
    const lowPriority = useDittoWrapper({ componentId: 'marker.priority.low' });
    const noPriority = useDittoWrapper({ componentId: 'marker.priority.nopriority' });
    const openStatus = useDittoWrapper({ componentId: 'marker.status.open' });
    const inProgressStatus = useDittoWrapper({ componentId: 'marker.status.inprogress' });
    const closedStatus = useDittoWrapper({ componentId: 'marker.status.closed' });
    function getPriorityLabel(priorityId) {
        switch (priorityId) {
            case MARKER_PRIORITY_ID.high:
                return highPriority;
            case MARKER_PRIORITY_ID.medium:
                return mediumPriority;
            case MARKER_PRIORITY_ID.low:
                return lowPriority;
            case MARKER_PRIORITY_ID.noPriority:
                return noPriority;
            default:
                return '';
        }
    }
    function getStatusLabel(statusId) {
        switch (statusId) {
            case MARKER_STATUS_ID.open:
                return openStatus;
            case MARKER_STATUS_ID.inProgress:
                return inProgressStatus;
            case MARKER_STATUS_ID.closed:
                return closedStatus;
            default:
                return '';
        }
    }
    return {
        getPriorityLabel,
        getStatusLabel
    };
}
export function getAssigneeNames(taskAssigneeMap, uuids) {
    const names = uuids === null || uuids === void 0 ? void 0 : uuids.map((uuid) => {
        const assignee = taskAssigneeMap[uuid];
        if (assignee) {
            const { firstName, lastName } = assignee;
            return firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || '';
        }
    });
    return (names === null || names === void 0 ? void 0 : names.filter((i) => !!i).join(', ')) || '';
}
export function getAssigneeIcon(taskAssigneeMap, uuid) {
    if (!uuid) {
        return;
    }
    const firstAssignee = taskAssigneeMap[uuid];
    if (!firstAssignee) {
        return;
    }
    return (_jsx(AvatarHover, { firstName: firstAssignee.firstName, lastName: firstAssignee.lastName, src: firstAssignee.profileURL, tooltipPosition: "left" }));
}
export function getUserIcon(userMap, uuid) {
    if (!uuid) {
        return;
    }
    const firstUser = userMap[uuid];
    if (!firstUser) {
        return;
    }
    return (_jsx(AvatarHover, { firstName: firstUser.firstName, lastName: firstUser.lastName, src: firstUser.profileImageUrl, tooltipPosition: "left" }));
}
export function getCategoryBadge(markerCategoryMap, id) {
    const category = id ? markerCategoryMap[id] : undefined;
    if (category) {
        return _jsx(CategoryBadge, { category: category.name, backgroundColor: category.color });
    }
}
export function getLookupAssetIds(changelogList) {
    const ids = changelogList
        .filter((c) => [MARKER_CHANGELOG_OPERATION.linkAsset, MARKER_CHANGELOG_OPERATION.unlinkAsset].includes(c.operation))
        .map((c) => { var _a, _b; return ((_a = c.oldVal) === null || _a === void 0 ? void 0 : _a.manualInventoryId) || ((_b = c.newVal) === null || _b === void 0 ? void 0 : _b.manualInventoryId); });
    return ids;
}
