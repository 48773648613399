import { ConditionSelectedFeatureType } from '../../common/consts/panelTab';
import { useAppSelector } from '../../state/hooks';
import { DEFAULT_GRADE_MAX, DEFAULT_GRADE_MIN, DEFAULT_SEGMENT_LENGTH, MAX_SEGMENT_LENGTH } from '../../state/slices/leftPanelCondition';
export function useSourceChecker() {
    var _a, _b;
    const { selectedFeatureType } = useAppSelector((state) => state.leftPanelCondition);
    const { filter: conditionFilter } = useAppSelector((state) => state.leftPanelCondition);
    const { conditionFilters } = useAppSelector((state) => state.shared);
    const conditionFiltersCount = conditionFilter.damageType.length +
        conditionFilter.streetType.length +
        conditionFilter.surfaceType.length +
        (conditionFilter.segmentLengthMin >
            ((_a = conditionFilters.segmentLength.min) !== null && _a !== void 0 ? _a : DEFAULT_SEGMENT_LENGTH)
            ? 1
            : 0) +
        (conditionFilter.segmentLengthMax <
            ((_b = conditionFilters.segmentLength.max) !== null && _b !== void 0 ? _b : MAX_SEGMENT_LENGTH)
            ? 1
            : 0) +
        (conditionFilter.grade.min > DEFAULT_GRADE_MIN ||
            conditionFilter.grade.max < DEFAULT_GRADE_MAX
            ? 1
            : 0);
    const isNodeEdgeVisible = selectedFeatureType === ConditionSelectedFeatureType.segment ||
        selectedFeatureType === ConditionSelectedFeatureType.both;
    const isPointVisible = selectedFeatureType === ConditionSelectedFeatureType.point ||
        selectedFeatureType === ConditionSelectedFeatureType.both;
    return {
        isNodeEdgeVisible,
        isPointVisible,
        conditionFiltersCount
    };
}
