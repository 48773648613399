import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useMatch } from '@tanstack/react-location';
import { LAYER_PATH } from '../../common/layer';
import { uniqueV2 } from '../../common/utils/array';
import { Loader } from '../../components/Loader/Loader';
import { PrintAssetContainer } from '../../containers/PrintAssetContainer/PrintAssetContainer';
import { PrintMarkerContainer } from '../../containers/PrintMarkerContainer/PrintMarkerContainer';
import { PrintPlanContainer } from '../../containers/PrintPlanContainer/PrintPlanContainer';
import { PrintPointContainer } from '../../containers/PrintPointContainer/PrintPointContainer';
import { PrintSegmentContainer } from '../../containers/PrintSegmentContainer/PrintSegmentContainer';
import { PrintSignContainer } from '../../containers/PrintSignContainer/PrintSignContainer';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchTeamAccount } from '../../state/slices/auth';
export function AppPrint() {
    const { params: { featureId, printType } } = useMatch();
    const dispatch = useAppDispatch();
    const { teamAccount } = useAppSelector((state) => state.auth);
    useEffect(() => {
        dispatch(fetchTeamAccount());
    }, [dispatch]);
    const ids = useMemo(() => uniqueV2(featureId.split(',').map((id) => parseInt(id))), [featureId]);
    if (!teamAccount)
        return _jsx(Loader, { variant: "fullscreen" });
    if (printType === 'point') {
        return _jsx(PrintPointContainer, { uuids: uniqueV2(featureId.split(',').map((uuid) => uuid)) });
    }
    if (printType === 'segment') {
        return (_jsx(PrintSegmentContainer, { ids: uniqueV2(featureId.split(',').map((id) => parseInt(id))) }));
    }
    if (printType === LAYER_PATH.plan) {
        return _jsx(PrintPlanContainer, { ids: ids });
    }
    if (printType === LAYER_PATH.marker) {
        return _jsx(PrintMarkerContainer, { ids: ids });
    }
    if (printType === LAYER_PATH.asset) {
        return _jsx(PrintAssetContainer, { ids: ids });
    }
    if (printType === LAYER_PATH.signInventory) {
        return _jsx(PrintSignContainer, { ids: ids });
    }
    return null;
}
