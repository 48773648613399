// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WT05qAdA1ME605UaPluQ{display:flex;flex-direction:column}.TTqbiZ9AHA6w74xZb2Io{pointer-events:none;opacity:.5}.LslBpa_MGqnBQIuhe9o6{padding-left:20px;padding-right:12px;display:flex;align-items:center}._0FrlGuP3ac9MFHa4Qq17{color:var(--primary-dark-blue);font-size:16px;font-weight:600;word-wrap:break-word}.rG_LfVcnaPREeihUGAGQ{margin-left:auto}.GcIr6bRN7qgj2PYeJZLi{display:flex;flex-direction:column;gap:8px;padding:0 12px;margin-top:4px}.ATZ6f5HsQ7Tym4FSHU_p{padding:10px 12px 10px 24px;color:var(--primary-dark-blue);opacity:.6;font-size:14px;font-weight:400;line-height:18.2px;word-wrap:break-word}`, "",{"version":3,"sources":["webpack://./src/components/WorkingHoursGroup/WorkingHoursGroup.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CAGJ,sBACI,mBAAA,CACA,UAAA,CAGJ,sBACI,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CAGJ,uBACI,8BAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,OAAA,CACA,cAAA,CACA,cAAA,CAGJ,sBACI,2BAAA,CACA,8BAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,oBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.disabled {\n    pointer-events: none;\n    opacity: 0.5;\n}\n\n.header {\n    padding-left: 20px;\n    padding-right: 12px;\n    display: flex;\n    align-items: center;\n}\n\n.header-title {\n    color: var(--primary-dark-blue);\n    font-size: 16px;\n    font-weight: 600;\n    word-wrap: break-word;\n}\n\n.header-button {\n    margin-left: auto;\n}\n\n.entries {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    padding: 0 12px;\n    margin-top: 4px;\n}\n\n.total {\n    padding: 10px 12px 10px 24px;\n    color: var(--primary-dark-blue);\n    opacity: 0.6;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 18.2px;\n    word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WT05qAdA1ME605UaPluQ`,
	"disabled": `TTqbiZ9AHA6w74xZb2Io`,
	"header": `LslBpa_MGqnBQIuhe9o6`,
	"header-title": `_0FrlGuP3ac9MFHa4Qq17`,
	"header-button": `rG_LfVcnaPREeihUGAGQ`,
	"entries": `GcIr6bRN7qgj2PYeJZLi`,
	"total": `ATZ6f5HsQ7Tym4FSHU_p`
};
export default ___CSS_LOADER_EXPORT___;
