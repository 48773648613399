import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { motion } from 'framer-motion';
import { getSignGroupName, getSignTypeAbbreviation, getSignTypeIcon } from '../../common/convert/signInventory';
import { IconResizer } from '../IconResizer/IconResizer';
import { CaretCloseSmall, CaretOpenSmall } from '../icons';
import styles from './TrafficSignCreatorFilterGroup.module.css';
export function TrafficSignCreatorFilterGroup({ id, items, onSelectType }) {
    const [collapsed, setCollapsed] = useState(true);
    const icon = collapsed ? _jsx(CaretCloseSmall, {}) : _jsx(CaretOpenSmall, {});
    const itemsHeight = collapsed ? 0 : 'auto';
    const handleTypeSelect = (type) => {
        onSelectType(type);
    };
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['group'], onClick: () => setCollapsed(!collapsed), "data-test": "sign-filter-group" }, { children: [_jsx("h2", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: getSignGroupName(id) }) })), _jsx("span", Object.assign({ className: styles['count'] }, { children: items.length })), _jsx("div", Object.assign({ className: classes(styles['icon'], collapsed && styles['icon--collapsed']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: icon })) }))] })), _jsx(motion.div, Object.assign({ className: styles['items'], initial: { height: 0 }, animate: { height: itemsHeight } }, { children: items.map((item) => (_jsxs("div", Object.assign({ className: styles['item'], onClick: () => handleTypeSelect(item.id), "data-test": "sign-filter-item" }, { children: [_jsx("div", Object.assign({ className: styles['item-icon'] }, { children: getSignTypeIcon(item.id) })), _jsxs("div", Object.assign({ className: styles['item-info'] }, { children: [_jsx("span", Object.assign({ className: styles['item-info-title'] }, { children: _jsx(Ditto, { componentId: getSignTypeAbbreviation(item.id) }) })), _jsx("span", Object.assign({ className: styles['item-info-id'] }, { children: item.bastId }))] }))] }), item.id))) }))] })));
}
