import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Close } from '../../icons';
import { Loader } from '../../Loader/Loader';
import { Modal } from '../../Modal/Modal';
import styles from './AssetConverterModal.module.css';
export function AssetConverterModal({ className, content, loading, selectedAmount, isSubmitDisabled, onCloseClick, onSubmitClick }) {
    return (_jsx(Modal, { className: classes(styles['container'], className), title: _jsx(Ditto, { componentId: "tasks.tasktoassetconverter.title", variables: { AmountOfTasksSelectedToConvertToAssets: selectedAmount }, count: selectedAmount }), trailing: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), onClick: () => onCloseClick && onCloseClick() }), content: _jsxs(_Fragment, { children: [loading && (_jsx("div", Object.assign({ className: styles['loading'] }, { children: _jsx(Loader, {}) }))), _jsx("div", { children: content })] }), footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx(Button, Object.assign({ variant: "modal-action", color: "gray", onClick: () => onCloseClick && onCloseClick(), "data-test": "cancel-btn" }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })), _jsxs(Button, Object.assign({ variant: "modal-action", color: "dark", disabled: isSubmitDisabled, onClick: () => onSubmitClick && onSubmitClick(), "data-test": "done-btn" }, { children: [_jsx(Ditto, { componentId: "tasks.tasktoassetconverter.converttoassets" }), ' ', `(${selectedAmount})`] }))] })), footerAlign: "space-between" }));
}
