import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ASSET_CREATOR_FORM_WIDTH } from '../../common/consts/width';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { Stepper } from '../../uikit/Stepper/Stepper';
import { Close } from '../icons';
import { Image } from '../ImagePreview/Image/Image';
import { ImagePreview } from '../ImagePreview/ImagePreview';
import styles from './AssetCreator.module.css';
const DEFAULT_IMAGE_HEIGHT = 192;
const DASHED_BORDERS_WIDTH = 4;
export function AssetCreator({ position = 'floating', images = [], fixedImage = [], assetLowerForm, disabled, onClickClose, onClickBack, onClickNext, onClickCreate, onImageUploadClick, linkedMarkerSection, step, children }) {
    const refContent = React.createRef();
    useEffect(() => {
        var _a;
        (_a = refContent.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);
    function handleOnClickUpload() {
        onImageUploadClick === null || onImageUploadClick === void 0 ? void 0 : onImageUploadClick();
    }
    const pictures = images
        .map((img) => _jsx(Image, { src: img.url, alt: "asset image preview" }, img.photoId))
        .concat(fixedImage.map((img, idx) => _jsx(Image, { src: img, alt: "asset image preview" }, idx)));
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles[`container__${position}`]), "data-test": "asset-creator-form" }, { children: [_jsx("div", Object.assign({ className: styles['headline-group'] }, { children: _jsxs("div", Object.assign({ className: styles['headline'] }, { children: [_jsx("div", Object.assign({ className: styles['title'] }, { children: _jsx(Ditto, { componentId: "assets.assetcreator" }) })), _jsx(Button, { onClick: () => onClickClose === null || onClickClose === void 0 ? void 0 : onClickClose(), color: "gray-2", variant: "square", icon: _jsx(Close, {}) })] })) })), _jsxs("div", Object.assign({ ref: refContent, className: classes(styles['content-group'], step === 0 && styles['content-no-padding']) }, { children: [linkedMarkerSection && (_jsxs("div", Object.assign({ className: styles['linked-section'] }, { children: [linkedMarkerSection, _jsx(Divider, { margin: "0" })] }))), (step === -1 || step === 1) && (_jsx("div", Object.assign({ className: classes(styles['image-preview'], !pictures.length && styles['image-preview__dashed']) }, { children: _jsx(ImagePreview, Object.assign({ height: DEFAULT_IMAGE_HEIGHT, width: pictures.length
                                ? ASSET_CREATOR_FORM_WIDTH
                                : ASSET_CREATOR_FORM_WIDTH - DASHED_BORDERS_WIDTH, uploadImage: _jsx(Ditto, { componentId: "uploadimage" }), onClickUpload: handleOnClickUpload }, { children: pictures })) }))), assetLowerForm, children] })), _jsxs("div", Object.assign({ className: classes(styles['button-group'], (step === 1 || step === 2) && styles['button-group--stepper']) }, { children: [step === -1 && (_jsx(Button, Object.assign({ disabled: disabled, color: "yellow", variant: "fullwidth", size: "large", onClick: () => onClickCreate === null || onClickCreate === void 0 ? void 0 : onClickCreate() }, { children: _jsx(Ditto, { componentId: "create" }) }))), step === 0 && (_jsx(Button, Object.assign({ disabled: true, color: "yellow", variant: "fullwidth", size: "large" }, { children: _jsx(Ditto, { componentId: "trafficsigns.selecttypetoproceed" }) }))), (step === 1 || step === 2) && (_jsx(Stepper, { width: "100%", current: step - 1, steps: 2, onCancel: () => onClickClose === null || onClickClose === void 0 ? void 0 : onClickClose(), onBack: onClickBack, onNext: onClickNext, onCreate: () => onClickCreate === null || onClickCreate === void 0 ? void 0 : onClickCreate(), nextColor: "yellow", createColor: "yellow", createLabel: _jsx(Ditto, { componentId: "create" }), disabled: disabled }))] }))] })));
}
