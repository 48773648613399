import { toggleItemInArray, xorify } from '../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setMarkerFilter } from '../../state/slices/leftPanelMarker';
export function useMarkerFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelMarker);
    const { markerFolders } = useAppSelector((state) => state.shared);
    const dispatch = useAppDispatch();
    function handleStatusFilterClick(value) {
        dispatch(setMarkerFilter({
            statusId: toggleItemInArray(filter.statusId, value)
        }));
    }
    function handleCategoryFilterClick(value) {
        dispatch(setMarkerFilter({
            category: toggleItemInArray(filter.category, value)
        }));
    }
    function handlePriorityFilterClick(value) {
        dispatch(setMarkerFilter({
            priorityId: toggleItemInArray(filter.priorityId, value)
        }));
    }
    function handleAssigneeFilterClick(value) {
        dispatch(setMarkerFilter({
            assignee: toggleItemInArray(filter.assignee, value)
        }));
    }
    function handleIntervalFilterClick(value) {
        dispatch(setMarkerFilter({
            interval: toggleItemInArray(filter.interval, value)
        }));
    }
    function handleDueDateFilterClick(value) {
        dispatch(setMarkerFilter({
            dueDateId: value
        }));
    }
    function handleFolderFilterClick(folderId) {
        const xorified = xorify(filter.filesAttached, filter.filesNotAttached, folderId);
        dispatch(setMarkerFilter({
            filesAttached: xorified.list1,
            filesNotAttached: xorified.list2
        }));
    }
    function handleFolderAttachedFilterClick(folderId) {
        dispatch(setMarkerFilter({
            filesAttached: toggleItemInArray(filter.filesAttached, folderId)
        }));
    }
    function handleFolderNotAttachedFilterClick(folderId) {
        dispatch(setMarkerFilter({
            filesNotAttached: toggleItemInArray(filter.filesNotAttached, folderId)
        }));
    }
    function handleAllFoldersFilterClick() {
        const filteredFoldersCount = filter.filesAttached.length + filter.filesNotAttached.length;
        const filteredFolders = filteredFoldersCount === markerFolders.length * 2 ? [] : markerFolders.map((f) => f.id);
        dispatch(setMarkerFilter({
            filesAttached: filteredFolders,
            filesNotAttached: filteredFolders
        }));
    }
    return {
        handleStatusFilterClick,
        handleCategoryFilterClick,
        handlePriorityFilterClick,
        handleAssigneeFilterClick,
        handleIntervalFilterClick,
        handleDueDateFilterClick,
        handleFolderFilterClick,
        handleFolderAttachedFilterClick,
        handleFolderNotAttachedFilterClick,
        handleAllFoldersFilterClick
    };
}
