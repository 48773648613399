import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SignTypeSelectModal } from '../../components/Modals/SignTypeSelectModal/SignTypeSelectModal';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { updateSignTypeTk } from '../../state/slices/signInventoryDetail';
export function SignTypeSelectModalContainer({ signId, onSelectedTypeChange }) {
    const { removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { signTree } = useAppSelector((state) => state.shared);
    const [matchingSignTree, setMatchingSignTree] = useState(signTree);
    const { reloadSignInventoryTilesData } = useTilesUpdator();
    function handleSearchValueChange(value) {
        if (!value) {
            setMatchingSignTree(signTree);
            return;
        }
        const matchingGroups = [];
        for (const group of signTree) {
            if (!group.items) {
                continue;
            }
            const matchingItems = [];
            for (const signType of group.items) {
                if (signType.abbreviation.toLowerCase().includes(value.toLowerCase()) ||
                    signType.name.toLowerCase().includes(value.toLowerCase()) ||
                    signType.bastId.toLowerCase().includes(value.toLowerCase())) {
                    matchingItems.push(signType);
                }
            }
            if (matchingItems.length > 0) {
                matchingGroups.push({
                    id: group.id,
                    name: group.name,
                    items: matchingItems
                });
            }
        }
        setMatchingSignTree(matchingGroups);
    }
    function handleSelectType(signType) {
        if (onSelectedTypeChange) {
            onSelectedTypeChange(signType);
            handleClose();
            return;
        }
        if (!signId) {
            return;
        }
        dispatch(updateSignTypeTk({
            id: signId,
            signType: signType
        })).then(() => {
            reloadSignInventoryTilesData();
        });
        handleClose();
    }
    function handleClose() {
        removeModal('SignTypeSelectModalContainer');
    }
    return (_jsx(SignTypeSelectModal, { onSearchValueChange: handleSearchValueChange, searchResults: matchingSignTree, onSelectType: handleSelectType, onClose: handleClose }));
}
