var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { getNoteList, removeNote, saveNote } from '../../services/note';
import { clearClickedFeature, clearHoveredFeature } from './feature';
import { clearRightClickCoordinates, fetchNoteGeoJSON, setCurrentRightClickAction } from './mainMap';
import { addNotificationMessage } from './notification';
const initialState = {};
export const fetchNoteList = createAppAsyncThunk('note/fetchNotes', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getNoteList();
    if (res.errorCode !== 0 || !res.result) {
        dispatch(addNotificationMessage({
            type: 'error'
        }));
        return;
    }
    return res.result;
}));
export const saveNoteDraft = createAppAsyncThunk('note/saveNote', (note, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const { noteGeoJSON } = getState().mainMap;
    const isUpdate = noteGeoJSON && noteGeoJSON.features.find((f) => f.properties.id === note.id);
    const res = yield saveNote(note);
    if (res.errorCode !== 0 || !res.result) {
        dispatch(addNotificationMessage({
            type: 'error'
        }));
        return;
    }
    if (!isUpdate) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.successfullycreated' }
            }
        }));
        dispatch(setCurrentRightClickAction('none'));
        dispatch(clearRightClickCoordinates());
    }
    dispatch(fetchNoteGeoJSON());
    dispatch(clearHoveredFeature());
    dispatch(clearClickedFeature());
    return res.result;
}));
export const deleteNote = createAppAsyncThunk('note/deleteNote', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield removeNote(id);
    if (res.errorCode !== 0 || !res.result) {
        dispatch(addNotificationMessage({
            type: 'error'
        }));
        return;
    }
    dispatch(fetchNoteGeoJSON());
    dispatch(clearHoveredFeature());
    dispatch(clearClickedFeature());
    return;
}));
export const noteSlice = createSlice({
    name: 'note',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(fetchNoteList.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            state.noteList = action.payload;
        })
            .addCase(deleteNote.fulfilled, (state) => {
            state.noteList = {};
        });
    }
});
export const { reset: resetNote } = noteSlice.actions;
export default noteSlice.reducer;
