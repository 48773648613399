import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { CalendarBlank, ID, Select, SignCovered, SignDirty, SignFaded, SignNoDamage, SignStickered, SparkleMagicApplied, Text, VerifiedApproved } from '../../components/icons';
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { SIGN_DAMAGE_ID, SIGN_PROPERTY_TYPE, SIGN_STATUS_CODE } from '../consts/trafficSign';
export function getSignIcon(damageId) {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return SignCovered();
        case SIGN_DAMAGE_ID.dirty:
            return SignDirty();
        case SIGN_DAMAGE_ID.faded:
            return SignFaded();
        case SIGN_DAMAGE_ID.stickered:
            return SignStickered();
        default:
            return SignNoDamage();
    }
}
export function getDamagedSignIcon(damageId) {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return SignCovered();
        case SIGN_DAMAGE_ID.dirty:
            return SignDirty();
        case SIGN_DAMAGE_ID.faded:
            return SignFaded();
        case SIGN_DAMAGE_ID.stickered:
            return SignStickered();
        default:
            return;
    }
}
export function getSignTypeIcon(id) {
    return _jsx("img", { src: `/${id}.png`, alt: id, width: 24, height: 24 });
}
export function getSignTypeAbbreviation(id) {
    return `sign.${id}.abbreviation`;
}
export function getSignGroupName(id) {
    return `signgroup.${id.toLowerCase()}.name`;
}
export function getSignFromSignTree(tree, id) {
    var _a;
    const found = tree.find((group) => { var _a; return (_a = group.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === id); });
    return (_a = found === null || found === void 0 ? void 0 : found.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === id);
}
export function getGroupFromSignTree(tree, subSpecifiedClass) {
    return tree.find((group) => { var _a; return (_a = group.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === subSpecifiedClass); });
}
export function getBastId(tree, id) {
    var _a, _b;
    const found = tree.find((group) => { var _a; return (_a = group.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === id); });
    return ((_b = (_a = found === null || found === void 0 ? void 0 : found.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === id)) === null || _b === void 0 ? void 0 : _b.bastId) || '';
}
export function getSignDamageName(damageId) {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return 'covered';
        case SIGN_DAMAGE_ID.dirty:
            return 'dirty';
        case SIGN_DAMAGE_ID.faded:
            return 'faded';
        case SIGN_DAMAGE_ID.stickered:
            return 'stickered';
        default:
            return 'noDamage';
    }
}
export const getSignTranslation = (damageId) => {
    switch (damageId) {
        case SIGN_DAMAGE_ID.covered:
            return _jsx(Ditto, { componentId: "signs.condition.covered" });
        case SIGN_DAMAGE_ID.dirty:
            return _jsx(Ditto, { componentId: "signs.condition.dirty" });
        case SIGN_DAMAGE_ID.faded:
            return _jsx(Ditto, { componentId: "signs.condition.faded" });
        case SIGN_DAMAGE_ID.stickered:
            return _jsx(Ditto, { componentId: "signs.condition.stickered" });
        default:
            return _jsx(Ditto, { componentId: "signs.condition.notdamaged" });
    }
};
export function useSignTranslations() {
    const coveredLabel = useDittoWrapper({ componentId: 'signs.condition.covered' });
    const dirtyLabel = useDittoWrapper({ componentId: 'signs.condition.dirty' });
    const fadedLabel = useDittoWrapper({ componentId: 'signs.condition.faded' });
    const stickeredLabel = useDittoWrapper({ componentId: 'signs.condition.stickered' });
    const notDamagedLabel = useDittoWrapper({ componentId: 'signs.condition.notdamaged' });
    function getSignLabelString(damageId) {
        switch (damageId) {
            case SIGN_DAMAGE_ID.covered:
                return coveredLabel;
            case SIGN_DAMAGE_ID.dirty:
                return dirtyLabel;
            case SIGN_DAMAGE_ID.faded:
                return fadedLabel;
            case SIGN_DAMAGE_ID.stickered:
                return stickeredLabel;
            case SIGN_DAMAGE_ID.not_damaged:
                return notDamagedLabel;
        }
    }
    return { getSignLabelString };
}
export function getSignDamageInitialValue(damageId) {
    return (_jsx(FormInputCurrentSelectedValue, { text: getSignTranslation(damageId), icon: getSignIcon(damageId) }));
}
export const getSignDropdownItems = (damageTypesArray) => {
    return damageTypesArray.map((damageType) => (_jsx(SelectboxEntry, { leadingIcon: getSignIcon(damageType.id), text: getSignTranslation(damageType.id) }, damageType.id)));
};
export const getSignStatusTranslation = (signStatus) => {
    switch (signStatus) {
        case SIGN_STATUS_CODE.confirmed:
            return _jsx(Ditto, { componentId: "trafficsigns.verifiedtrafficsigns" });
        case SIGN_STATUS_CODE.predicted:
            return _jsx(Ditto, { componentId: "trafficsigns.popover.ai-recommodation" });
        default:
            return null;
    }
};
export const getSignStatusPopoverTranslation = (signStatus) => {
    switch (signStatus) {
        case SIGN_STATUS_CODE.confirmed:
            return (_jsx(InfoTooltip, { tooltip: _jsx(Ditto, { componentId: "trafficsigns.popover.usergeneratedorverifiedts" }), arrow: "down" }));
        case SIGN_STATUS_CODE.predicted:
            return (_jsx(InfoTooltip, { tooltip: _jsx(Ditto, { componentId: "trafficsigns.popover.newlyaidetectedts" }), arrow: "down" }));
        default:
            return null;
    }
};
export function getSignStatusIcon(signStatus) {
    switch (signStatus) {
        case SIGN_STATUS_CODE.confirmed:
            return VerifiedApproved();
        case SIGN_STATUS_CODE.predicted:
            return SparkleMagicApplied();
        default:
            return null;
    }
}
export function getSignPropertyIcon(propertyType) {
    switch (propertyType) {
        case SIGN_PROPERTY_TYPE.text:
            return _jsx(Text, {});
        case SIGN_PROPERTY_TYPE.number:
            return _jsx(ID, {});
        case SIGN_PROPERTY_TYPE.select:
            return _jsx(Select, {});
        case SIGN_PROPERTY_TYPE.date:
            return _jsx(CalendarBlank, {});
        default:
            return _jsx(Text, {});
    }
}
export function getSignPropertyLabel(propertyType) {
    switch (propertyType) {
        case SIGN_PROPERTY_TYPE.text:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.text" });
        case SIGN_PROPERTY_TYPE.number:
            return _jsx(Ditto, { componentId: "globalsettings.datatype.numeric" });
        case SIGN_PROPERTY_TYPE.select:
            return _jsx(Ditto, { componentId: "assets.attributes.datatypes.list" });
        case SIGN_PROPERTY_TYPE.date:
            return _jsx(Ditto, { componentId: "globalsettings.datatype.date" });
        default:
            return _jsx(_Fragment, {});
    }
}
export function getSignPropertyFieldsWidth(list) {
    return list.map((item, i, list) => {
        if (item.type === 'number' && list[i + 1] && list[i + 1].type === 'number') {
            return item;
        }
        else if (item.type === 'number' && list[i - 1] && list[i - 1].type === 'number') {
            return item;
        }
        return Object.assign(Object.assign({}, item), { isFullWidth: true });
    });
}
