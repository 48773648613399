var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { MARKER_SOURCE_ID } from '../../common/consts/marker';
import { MARKER_CREATOR_FORM_WIDTH_EMBEDDED } from '../../common/consts/width';
import { parseRecurringFormula } from '../../common/recurringRules';
import { Attachment } from '../../components/DetailsPanel/Attachment/Attachment';
import { Asset } from '../../components/DetailsPanel/Attachment/AttachmentItem/Asset/Asset';
import { AttachmentItem } from '../../components/DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { DueDatePickerControl } from '../../components/DueDatePicker/DueDatePickerControl/DueDatePickerControl';
import { AssigneeSelectField } from '../../components/EditorForms/AssigneeSelect/AssigneeSelectField/AssigneeSelectField';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { SparkleMagicApplied } from '../../components/icons';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { Loader } from '../../components/Loader/Loader';
import { MarkerCreator } from '../../components/MarkerCreator/MarkerCreator';
import { MarkerLowerForm } from '../../components/MarkerLowerForm/MarkerLowerForm';
import { TypedDitto } from '../../components/TypedDitto';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useMarkerFields } from '../../hooks/forms/useMarkerFields';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useImageUploadModal } from '../../hooks/useImageUploadModal';
import { useUserFullname } from '../../hooks/useUserFullname';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addAssetCreatorMarkerIdsToLink } from '../../state/slices/assetCreator';
import { uploadCreateLinkMarkerBatchTk } from '../../state/slices/markerBulkCreator';
import { resetMarkerCreator } from '../../state/slices/markerCreator';
import { fetchPointList } from '../../state/slices/sharedDataHolder';
import { CategorySelectContainer } from '../CategorySelectContainer/CategorySelectContainer';
export function RightPanelMarkerCreatorContainer() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { createStatus, source } = useAppSelector((state) => state.markerCreator);
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    const { markerStatusList, markerPriorityList, taskAssigneeList, markerCategoryList, assetTypeMap } = useAppSelector((state) => state.shared);
    const { pointList } = useAppSelector((state) => state.sharedDataHolder);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { fullname } = useUserFullname();
    const { selectConfirmPhotos } = useImageUploadModal();
    const { reloadAssetTilesData } = useTilesUpdator();
    const { formatAddress } = useFormatter();
    const markerAddressLabel = useDittoWrapper({ componentId: 'marker.address' });
    const taskTitleLabel = useDittoWrapper({ componentId: 'tasks.tasktitle' });
    const notspecified = useDittoWrapper({ componentId: 'notspecified' });
    const maximum100charactersError = useDittoWrapper({ componentId: 'maximum100characters' });
    const { markerFields, updateMarkerFields, getValidatedMarkerDraft } = useMarkerFields({
        taskTitle: source === null || source === void 0 ? void 0 : source.title,
        taskDescription: source === null || source === void 0 ? void 0 : source.description,
        priority: markerPriorityList.find((i) => i.priorityId === (source === null || source === void 0 ? void 0 : source.priorityId)) ||
            markerPriorityList[0],
        status: markerStatusList.find((i) => i.statusId === (source === null || source === void 0 ? void 0 : source.statusId)) || markerStatusList[0],
        creator: fullname,
        sourceImages: (_a = source === null || source === void 0 ? void 0 : source.linkedAsset) === null || _a === void 0 ? void 0 : _a.photos.map((photo) => ({
            uuid: photo.photoUuid,
            url: photo.imageThumbUrl
        }))
    });
    const [selectedCategoryId, setSelectedCategoryId] = useState((_b = markerFields.category) === null || _b === void 0 ? void 0 : _b.categoryId);
    const [titleError, setTitleError] = useState('');
    const dateTimeFormatter = useDateTimeFormatter();
    const recurringFormula = parseRecurringFormula(markerFields.recurringFormula);
    useEffect(() => {
        // TODO: remove this fetch after condition layer is switched to the new endpoint,
        // which can directly give the address json.
        // then we can add address json directly in source.linkedPoint
        if (source === null || source === void 0 ? void 0 : source.linkedPoint) {
            dispatch(fetchPointList([source.linkedPoint.pointUuid]));
        }
    }, [dispatch, source]);
    useEffect(() => {
        const category = markerCategoryList.find((item) => item.categoryId === selectedCategoryId);
        updateMarkerFields({ category });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategoryId, markerCategoryList]);
    useEffect(() => {
        if (!selectedFeature) {
            handleClickClose();
        }
    }, [handleClickClose, selectedFeature]);
    if (!source) {
        return _jsx(Loader, {});
    }
    const point = source.linkedPoint && pointList ? pointList[0] : undefined;
    const address = source.address || (point === null || point === void 0 ? void 0 : point.address);
    function handleDueDatePickerControlClick() {
        addModal({
            id: 'DueDatePickerModalContainer',
            props: {
                recurringFormula: markerFields.recurringFormula,
                onConfirm: handleDueDateChange
            }
        });
    }
    function handleTaskTitleChange(value) {
        if (value.length > 100) {
            setTitleError(maximum100charactersError);
        }
        else {
            setTitleError('');
        }
        updateMarkerFields({ taskTitle: value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleClickClose() {
        dispatch(resetMarkerCreator());
    }
    function handleSelectCategory(categoryId) {
        setSelectedCategoryId(categoryId);
    }
    function handleAssigneeControlClick() {
        addModal({
            id: 'AssigneeSelectModalContainer',
            props: {
                initialValues: markerFields.assignees,
                onConfirm: handleAssigneeChange
            }
        });
    }
    function handleMarkerCreateClick() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const draft = getValidatedMarkerDraft();
            if (draft && source) {
                const draftWithAddress = Object.assign(Object.assign({}, draft), { address: formatAddress(address), addressJson: address, latitude: source.coordinates[1], longitude: source.coordinates[0], whereWasCreated: MARKER_SOURCE_ID.webApp, photos: (_a = source.linkedAsset) === null || _a === void 0 ? void 0 : _a.photos.map((p) => ({
                        uuid: p.photoUuid,
                        createdAt: p.createdAt
                    })) });
                dispatch(uploadCreateLinkMarkerBatchTk({
                    items: [draftWithAddress],
                    photosToLink: source.linkedPoint ? [source.linkedPoint] : undefined,
                    photosToUpload: markerFields.images.map((i) => i.file),
                    fromAssetIds: source.linkedAsset ? [source.linkedAsset.id] : undefined,
                    doNotNotify: source && !!assetCreatorSource
                }))
                    .unwrap()
                    .then((response) => {
                    if (source && !!assetCreatorSource) {
                        const markerIds = response === null || response === void 0 ? void 0 : response.markers.map((marker) => marker.id);
                        dispatch(addAssetCreatorMarkerIdsToLink(markerIds !== null && markerIds !== void 0 ? markerIds : []));
                    }
                    if (source.linkedAsset) {
                        reloadAssetTilesData();
                    }
                });
            }
        });
    }
    function handleImageUploadClick() {
        return __awaiter(this, void 0, void 0, function* () {
            selectConfirmPhotos((imgs) => updateMarkerFields({ images: markerFields.images.concat(imgs) }));
        });
    }
    function handleAssigneeChange(value) {
        updateMarkerFields({ assignees: value });
    }
    function handleDueDateChange(value) {
        updateMarkerFields({ recurringFormula: value });
    }
    const lowerForm = (_jsx(MarkerLowerForm, { title: _jsx(FormInputText, { label: taskTitleLabel, placeholder: notspecified, variant: "editor-field", width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, onValueChange: handleTaskTitleChange, maxLength: 101, error: titleError, value: markerFields.taskTitle, magicInput: ((_c = source.magicInputValues) === null || _c === void 0 ? void 0 : _c.title)
                ? {
                    value: (_d = source.magicInputValues) === null || _d === void 0 ? void 0 : _d.title
                }
                : undefined }), address: _jsx(FormInputText, { value: formatAddress(address), disabled: true, width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, label: markerAddressLabel, variant: 'ghost' }), width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, description: markerFields.description, magicDescription: (_e = source.magicInputValues) === null || _e === void 0 ? void 0 : _e.description, status: { items: markerStatusList, current: markerFields.status }, priority: { items: markerPriorityList, current: markerFields.priority }, magicPriorityIds: source.magicInputValues && source.magicInputValues.priorityId
            ? [source.magicInputValues.priorityId]
            : undefined, category: _jsx(CategorySelectContainer, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, onCategorySelect: handleSelectCategory, selectedId: selectedCategoryId }), assignee: _jsx(AssigneeSelectField, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, options: taskAssigneeList, selectedValues: markerFields.assignees, onClick: handleAssigneeControlClick }), taskDatePicker: _jsx(DueDatePickerControl, { frequency: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.frequency, value: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.startDate, dateTimeFormatter: dateTimeFormatter, onClick: handleDueDatePickerControlClick }), onChange: updateMarkerFields }));
    const linkedAssetSection = source.createdFrom === 'asset' && source.linkedAsset && (_jsx(Attachment, Object.assign({ title: _jsx(Ditto, { componentId: "assets.linkedassets" }), count: 1 }, { children: _jsx(AttachmentItem, { children: _jsx(Asset, { iconId: (_h = (((_f = source.linkedAsset) === null || _f === void 0 ? void 0 : _f.typeId)
                    ? assetTypeMap[(_g = source.linkedAsset) === null || _g === void 0 ? void 0 : _g.typeId]
                    : undefined)) === null || _h === void 0 ? void 0 : _h.iconId, title: source.linkedAsset.title, address: formatAddress(source.linkedAsset.address) }) }) })));
    return (_jsx(MarkerCreator, { position: "embedded", disabled: createStatus === 'loading', linkedAssetSection: linkedAssetSection, markerUpperFieldData: markerFields, markerLowerForm: lowerForm, fixedImage: (_j = source.linkedPoint) === null || _j === void 0 ? void 0 : _j.imageURL, onClickClose: handleClickClose, onClickCreate: handleMarkerCreateClick, onImageUploadClick: handleImageUploadClick, topInfobox: source.createdFrom === 'sign' &&
            source.magicInputValues && (_jsx(InfoBox, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, variant: "green", icon: _jsx(SparkleMagicApplied, {}), content: _jsx(TypedDitto, { componentId: "taskcreator.magicinputcopy" }) })), step: -1 }));
}
