import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { uploadCreateLinkMarkerBatchTk } from './markerBulkCreator';
const initialState = {
    createStatus: 'idle',
    step: 0,
    filesToAdd: []
};
export const markerCreatorSlice = createSlice({
    name: 'markerCreator',
    initialState,
    reducers: {
        reset: () => initialState,
        setPointSource: (state, action) => {
            state.source = action.payload;
        },
        clearPointSource: (state) => {
            state.source = undefined;
        },
        setCreateStatus: (state, action) => {
            state.createStatus = action.payload;
        },
        setMarkerCreatorSource: (state, action) => {
            state.source = action.payload;
        },
        setMarkerCreatorStep: (state, action) => {
            state.step = action.payload;
        },
        setMarkerCreatorAssetIdToLink: (state, action) => {
            state.assetIdToLink = action.payload;
        },
        addMarkerCreatorFiles: (state, action) => {
            state.filesToAdd = [...state.filesToAdd, ...action.payload];
        },
        removeMarkerCreatorFiles: (state, action) => {
            state.filesToAdd = state.filesToAdd.filter((item) => !action.payload.includes(item.uuid));
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(uploadCreateLinkMarkerBatchTk.pending), (state) => {
            state.createStatus = 'loading';
        })
            .addMatcher(isAnyOf(uploadCreateLinkMarkerBatchTk.rejected), (state) => {
            state.createStatus = 'failed';
        })
            .addMatcher(isAnyOf(uploadCreateLinkMarkerBatchTk.fulfilled), (state) => {
            state.createStatus = 'succeeded';
        });
    }
});
export const { reset: resetMarkerCreator, setPointSource: setMarkerCreatorPointSource, setMarkerCreatorSource, setMarkerCreatorStep, setMarkerCreatorAssetIdToLink, addMarkerCreatorFiles, removeMarkerCreatorFiles } = markerCreatorSlice.actions;
export default markerCreatorSlice.reducer;
