// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yrjqtOcdvgNCiW9GH1_L{width:24px;height:24px;background:var(--primary-grey);display:flex;justify-content:center;align-items:center;border-radius:6px;cursor:pointer}.nGseEkue10c8amAweHA1{font-size:14px;font-weight:600;overflow:hidden;max-width:20ch;height:16px;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1}`, "",{"version":3,"sources":["webpack://./src/components/Result/Marker/LinkedAsset/LinkedAsset.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,8BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,WAAA,CACA,mBAAA,CACA,2BAAA,CACA,oBAAA","sourcesContent":[".container {\n    width: 24px;\n    height: 24px;\n    background: var(--primary-grey);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 6px;\n    cursor: pointer;\n}\n\n.title {\n    font-size: 14px;\n    font-weight: 600;\n    overflow: hidden;\n    max-width: 20ch;\n    height: 16px;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `yrjqtOcdvgNCiW9GH1_L`,
	"title": `nGseEkue10c8amAweHA1`
};
export default ___CSS_LOADER_EXPORT___;
