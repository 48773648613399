import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { NoteDetailContainer } from '../NoteDetailContainer/NoteDetailContainer';
export const MapPopoverClickContainer = ({ direction }) => {
    const { clickData } = useAppSelector((state) => state.mapPopover);
    if (!clickData) {
        return null;
    }
    switch (clickData.type) {
        case 'note': {
            return _jsx(NoteDetailContainer, { direction: direction, variant: "click" });
        }
    }
    return null;
};
