import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { AssigneeName } from '../../components/EditorForms/AssigneeSelect/AssigneeName/AssigneeName';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Avatar } from '../Avatar/Avatar';
import styles from './AvatarHover.module.css';
export function AvatarHover({ firstName, lastName, src, tooltipPosition = 'right', anonymize }) {
    const [isHovering, setIsHovering] = useState(false);
    const anonymizedName = useDittoWrapper({ componentId: 'tasks.costs.anonymized' });
    const avatar = (_jsx(Avatar, { firstName: firstName, lastName: lastName, src: src, size: 24, color: "dark", anonymize: anonymize }));
    return (_jsxs("div", Object.assign({ className: classes(styles['container']), onMouseEnter: () => setIsHovering(true), onMouseLeave: () => setIsHovering(false) }, { children: [isHovering && (_jsxs("div", Object.assign({ className: classes(styles['tooltip'], styles[`tooltip__${tooltipPosition}`]) }, { children: [tooltipPosition === 'right' && avatar, (firstName || lastName) && !anonymize && (_jsx(AssigneeName, { firstName: firstName, lastName: lastName })), anonymize && _jsx(AssigneeName, { firstName: anonymizedName }), tooltipPosition === 'left' && avatar] }))), avatar] })));
}
