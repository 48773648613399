import { toggleItemInArray } from '../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setAlertFilter } from '../../state/slices/leftPanelAlert';
export function useAlertFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelAlert);
    const dispatch = useAppDispatch();
    function handleTypeFilterClick(value) {
        dispatch(setAlertFilter({
            typeId: toggleItemInArray(filter.typeId, value)
        }));
    }
    return { handleTypeFilterClick };
}
