import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { AssetIcon } from '../../../../common/icon';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { IconHover } from '../../../../uikit/IconHover/IconHover';
import { IconResizer } from '../../../IconResizer/IconResizer';
import styles from './LinkedAsset.module.css';
export function LinkedAsset({ id, iconId, title, onAssetClick }) {
    const noTitle = useDittoWrapper({ componentId: 'tasktitle.notitle' });
    return (_jsx("div", Object.assign({ className: styles['container'], onClick: (event) => {
            event.stopPropagation();
            onAssetClick(id);
        } }, { children: _jsx(IconHover, { icon: _jsx("div", Object.assign({ className: styles['container'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(AssetIcon, { iconId: iconId }) })) })), text: _jsx("div", Object.assign({ className: styles['title'] }, { children: title || noTitle })), clickable: true }) })));
}
