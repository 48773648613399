// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lmny8yErRXD0NuGc5UCs{width:100vw;height:100vh;display:flex;background:#222d39;align-items:center;justify-content:center}.bhzJGht1f5aGlsieioyM{position:absolute;width:410px;bottom:8px;right:8px;z-index:1070}`, "",{"version":3,"sources":["webpack://./src/components/AppLayoutForm/AppLayoutForm.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,iBAAA,CACA,WAAA,CACA,UAAA,CACA,SAAA,CACA,YAAA","sourcesContent":[".container {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    background: #222d39;\n    align-items: center;\n    justify-content: center;\n}\n\n.notification-overlay {\n    position: absolute;\n    width: 410px;\n    bottom: 8px;\n    right: 8px;\n    z-index: 1070;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Lmny8yErRXD0NuGc5UCs`,
	"notification-overlay": `bhzJGht1f5aGlsieioyM`
};
export default ___CSS_LOADER_EXPORT___;
