var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { DEBOUNCE_SHARED_FETCH } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getSignInfoById } from '../../services/sign';
import { addNotificationMessage } from './notification';
export const fetchSignInfoById = createDebouncedAsyncThunk('signDetail/fetchSignInfoById', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInfoById({ id });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SHARED_FETCH);
const initialState = {
    signInfo: null,
    isLoading: false
};
export const signDetailSlice = createSlice({
    name: 'signDetail',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSignInfoById.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.isLoading = false;
                state.signInfo = action.payload.result.sign;
            }
        })
            .addCase(fetchSignInfoById.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(fetchSignInfoById.rejected, (state, action) => {
            state.error = action.error;
        });
    }
});
export const { reset: resetSignDetail } = signDetailSlice.actions;
export default signDetailSlice.reducer;
