var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { UpdatePasswordModal } from '../../components/Modals/UpdatePasswordModal/UpdatePasswordModal';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { updatePassword } from '../../state/slices/auth';
export function UpdatePasswordModalContainer() {
    const dispatch = useAppDispatch();
    const { removeModal, modals } = useModalContext();
    const { status } = useAppSelector((state) => state.auth);
    const [values, setValues] = useState({
        password: '',
        passwordConfirm: '',
        oldPassword: ''
    });
    function handleCancelClick() {
        closeModal();
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'UpdatePasswordModal') {
            return;
        }
        handleCancelClick();
    }
    function closeModal() {
        removeModal('UpdatePasswordModal');
    }
    function handleChange(name, value) {
        setValues(Object.assign(Object.assign({}, values), { [name]: value }));
    }
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (values.oldPassword) {
                yield dispatch(updatePassword({
                    oldPassword: values.oldPassword,
                    newPassword: values.password
                })).unwrap();
                closeModal();
            }
        });
    }
    return (_jsx(UpdatePasswordModal, { loading: status === 'loading', onChange: handleChange, onSubmit: handleSubmit, onCancelClick: handleCancelClick, onClickOutside: handleClickOutside, values: values }));
}
