// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TWzBg9fzAuCwYRvzxogj{display:flex;flex-direction:row;align-items:center;padding:4px;width:345px;height:48px;background:#f1f2f4;border-radius:16px}.TWzBg9fzAuCwYRvzxogj>*{flex:1 1 0}.TWzBg9fzAuCwYRvzxogj>*:not(:last-child){margin-right:2px}`, "",{"version":3,"sources":["webpack://./src/components/PanelButtonGroup/PanelButtonGroup.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CAEA,WAAA,CACA,WAAA,CAGA,kBAAA,CAEA,kBAAA,CAGJ,wBACI,UAAA,CAGJ,yCACI,gBAAA","sourcesContent":[".button-group {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 4px;\n\n    width: 345px;\n    height: 48px;\n\n    /* Primary/Grey-light */\n    background: #f1f2f4;\n    /* Primary/Grey-light */\n    border-radius: 16px;\n}\n\n.button-group > * {\n    flex: 1 1 0;\n}\n\n.button-group > *:not(:last-child) {\n    margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-group": `TWzBg9fzAuCwYRvzxogj`
};
export default ___CSS_LOADER_EXPORT___;
