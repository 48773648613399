// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JwLh6wtY6bfzl5wFgAYU{width:244px;height:140px}.OxFlVkUDL6NO_L73bmzd{height:100%;width:100%;object-fit:cover;border-radius:4px 4px 0 0;background:#f0f0f0}.IxHDMaBEUtooXkrTL0oZ{display:flex;align-items:center;justify-content:space-between;height:30px;max-width:244px}`, "",{"version":3,"sources":["webpack://./src/components/MapPopover/Point/Point.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,yBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".image-wrapper {\n    width: 244px;\n    height: 140px;\n}\n\n.image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 4px 4px 0 0;\n    background: #f0f0f0;\n}\n\n.info {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 30px;\n    max-width: 244px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-wrapper": `JwLh6wtY6bfzl5wFgAYU`,
	"image": `OxFlVkUDL6NO_L73bmzd`,
	"info": `IxHDMaBEUtooXkrTL0oZ`
};
export default ___CSS_LOADER_EXPORT___;
