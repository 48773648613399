import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import styles from './MarkerCluster.module.css';
export function MarkerCluster({ items = [], popup, clearHoveredFeature }) {
    const sections = items.map((item) => ({
        amount: item.count,
        color: item.color
    }));
    const total = sections.reduce((acc, { amount }) => acc + amount, 0);
    let backgroundStyle = '';
    if (sections.length === 0) {
        backgroundStyle = 'rgba(129, 129, 129, 0.1)';
    }
    else if (sections.length === 1) {
        backgroundStyle = `${sections[0].color}`;
    }
    else if (sections.length === 2) {
        const firstColorPerc = Math.round((sections[0].amount / total) * 100);
        backgroundStyle = `conic-gradient(
      ${sections[0].color} ${firstColorPerc}%,
      ${sections[1].color} 0
    )`;
    }
    else if (sections.length === 3) {
        const firstColorPerc = Math.round((sections[0].amount / total) * 100);
        const secondColorPerc = Math.round((sections[1].amount / total) * 100);
        backgroundStyle = `conic-gradient(
      ${sections[0].color} ${firstColorPerc}%,
      ${sections[1].color} 0 ${firstColorPerc + secondColorPerc}%,
      ${sections[2].color} 0
    )`;
    }
    else if (sections.length === 4) {
        const firstColorPerc = Math.round((sections[0].amount / total) * 100);
        const secondColorPerc = Math.round((sections[1].amount / total) * 100);
        const thirdColorPerc = Math.round((sections[2].amount / total) * 100);
        backgroundStyle = `conic-gradient(
      ${sections[0].color} ${firstColorPerc}%,
      ${sections[1].color} 0 ${firstColorPerc + secondColorPerc}%,
      ${sections[2].color} 0 ${firstColorPerc + secondColorPerc + thirdColorPerc}%,
      ${sections[3].color} 0
    )`;
    }
    const [popupVisible, setPopupVisible] = useState(false);
    const onMouseEnter = (event) => {
        event.stopPropagation();
        setPopupVisible(true);
    };
    const onMouseLeave = (event) => {
        event.stopPropagation();
        setPopupVisible(false);
    };
    // Prevent other events. E.g. if there are points "underneath",
    // hovering over shows a MapPopover. This listener prevents that.
    // In addition, explicitly clear hovered feature to make the
    // popover disappear if there is any.
    const onMouseMoveCapture = (event) => {
        event.stopPropagation();
        clearHoveredFeature === null || clearHoveredFeature === void 0 ? void 0 : clearHoveredFeature();
    };
    return (_jsxs("div", Object.assign({ className: styles['container'], onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseMoveCapture: onMouseMoveCapture }, { children: [_jsx("div", Object.assign({ className: styles['popup'] }, { children: _jsx(AnimatePresence, { children: popupVisible && popup }) })), _jsx("div", Object.assign({ className: styles['chart-circle'], style: {
                    background: backgroundStyle
                } }, { children: _jsx("div", Object.assign({ className: styles['inner-circle'], style: {
                        backgroundColor: total === 0 ? 'transparent' : 'white'
                    } }, { children: _jsx("span", Object.assign({ className: styles['circle-text'] }, { children: total > 99 ? '99+' : total })) })) }))] })));
}
