import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { MarkerStatusVignette } from '../../../common/convert/marker';
import { useStatusLabel } from '../../../hooks/ditto/useStatusLabel';
import { FormInputCurrentSelectedValue } from '../../FormInputSelect/FormInputSelect';
import { Check } from '../../icons';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
export function MarkerStatusField({ status, isSelected, onClick }) {
    const label = useStatusLabel(status.statusName);
    return (_jsx(SelectboxEntry, { leadingIcon: _jsx(MarkerStatusVignette, { value: status.statusId }), text: label, trailingIcon: isSelected ? _jsx(Check, {}) : undefined, onClick: onClick && onClick, "data-test": `dropdown-status-${status.statusId}` }));
}
export function MarkerStatusSelectedValue({ status, onClick }) {
    const label = useStatusLabel(status.statusName);
    return (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(MarkerStatusVignette, { value: status.statusId }), text: label, onClick: onClick && onClick }));
}
