var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { downloadFile } from '../../common/utils/downloadFile';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { Attachment } from '../../components/DetailsPanel/Attachment/Attachment';
import { AttachmentFolder } from '../../components/DetailsPanel/Attachment/AttachmentItem/AttachmentFolder/AttachmentFolder';
import { AttachmentItem } from '../../components/DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { FileAttachment } from '../../components/DetailsPanel/Attachment/AttachmentItem/FileAttachment/FileAttachment';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { Bin, Open, Overflow } from '../../components/icons';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { Customize } from '../Customize/Customize';
import styles from './FilesSection.module.css';
export function FilesSection({ id, files, folders, selectedFileId, readonly, selectFilesForUpload, dateTimeFormatter, handleFileDeleteClick, handleFileClick, handleCustomizeClick }) {
    var _a;
    function handleFileAddClick(folderId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let id;
            if (typeof folderId === 'undefined') {
                id = (_b = (_a = folders === null || folders === void 0 ? void 0 : folders.find((item) => item.isSystem)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0;
            }
            else {
                id = folderId;
            }
            selectFilesForUpload(() => new Promise((resolve) => resolve({ folderId: id })));
        });
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], readonly && styles['container--padded-bottom']) }, { children: [_jsx(Attachment, Object.assign({ title: id === -1 && _jsx(Ditto, { componentId: "files.files" }), count: (_a = files === null || files === void 0 ? void 0 : files.length) !== null && _a !== void 0 ? _a : 0 }, { children: _jsx(FoldersWithFiles, { folders: folders, files: files, id: id, selectedFileId: selectedFileId, readonly: readonly, handleFileClick: handleFileClick, handleFileDelete: handleFileDeleteClick, handleFileAddClick: handleFileAddClick, dateTimeFormatter: dateTimeFormatter }) })), !readonly && _jsx(Customize, { onClick: handleCustomizeClick })] })));
}
function FoldersWithFiles({ folders, files, id, selectedFileId, readonly, handleFileClick, handleFileDelete, handleFileAddClick, dateTimeFormatter }) {
    return (_jsx(_Fragment, { children: folders === null || folders === void 0 ? void 0 : folders.map((folder) => {
            var _a, _b;
            return (_jsxs(Fragment, { children: [folders.length > 1 && (_jsx(AttachmentFolder, { folder: folder, handleAddClick: (((_b = (_a = files === null || files === void 0 ? void 0 : files.filter((file) => file.folderId === folder.id)) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0 &&
                            !readonly &&
                            (() => handleFileAddClick(folder.id))) ||
                            undefined }, folder.id)), files === null || files === void 0 ? void 0 : files.filter((file) => file.folderId === folder.id).map((file) => {
                        var _a;
                        return (_jsx(AttachmentItem, Object.assign({ onClick: () => {
                                if (id !== -1) {
                                    handleFileClick === null || handleFileClick === void 0 ? void 0 : handleFileClick(file.id);
                                }
                            }, variant: selectedFileId === file.id ? 'selected' : 'outline', trailingElement: _jsx(FloatingDropdownWrapper, { control: _jsx(ActionButton, { icon: _jsx(Overflow, {}) }), menu: _jsxs(ActionModal, Object.assign({ width: 200 }, { children: [id !== -1 && file.fileLinkToDownload && (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.downloadfile" }), leadingIcon: _jsx(Open, {}), onClick: () => downloadFile(file.fileLinkToDownload, file.name) })), !readonly && (_jsxs(_Fragment, { children: [id !== -1 && _jsx(Divider, {}), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.delete" }), leadingIcon: _jsx(Bin, {}), color: "red", onClick: () => handleFileDelete(file) })] }))] })) }) }, { children: _jsx(FileAttachment, { title: file.name, createdAt: dateTimeFormatter(file.createdAt).date, previewUrl: file.mimeType.startsWith('image/')
                                    ? file.fileLink
                                    : undefined }) }), (_a = file.uuid) !== null && _a !== void 0 ? _a : file.id));
                    }), ((files === null || files === void 0 ? void 0 : files.filter((file) => file.folderId === folder.id).length) === 0 ||
                        folders.length === 1) &&
                        !readonly && (_jsx(Button, Object.assign({ variant: "fullwidth", color: "gray-2", size: "large", onClick: () => handleFileAddClick(folder.id) }, { children: _jsx(Ditto, { componentId: "add" }) })))] }, folder.id));
        }) }));
}
