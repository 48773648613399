import { isBefore, isSameDay, isSameISOWeek, isSameMonth, isSameYear, isValid } from 'date-fns';
import { getFrequencyDays } from './utils';
/**
 * Check whether the given formula is valid.
 * If the given formula is not recurring the function simply checks if startDate is valid.
 */
export const isValidRecurringFormula = (formula) => {
    var _a;
    if (formula === null) {
        return true;
    }
    const repeat = formula.frequency.repeat;
    const days = (_a = getFrequencyDays(formula.frequency)) !== null && _a !== void 0 ? _a : [];
    const startDate = formula.startDate;
    const untilDate = formula.untilDate;
    if (repeat === 'never') {
        return isValid(startDate);
    }
    if (repeat === 'custom') {
        const customRepeat = formula.frequency.value.repeat;
        if (!validateRepeatRule(customRepeat, days, startDate, untilDate)) {
            return false;
        }
    }
    else {
        if (!validateRepeatRule(repeat, days, startDate, untilDate)) {
            return false;
        }
    }
    if (!untilDate) {
        return true;
    }
    if (isValid(untilDate)) {
        const startBeforeUntil = isBefore(formula.startDate, untilDate);
        return startBeforeUntil;
    }
    return false;
};
const validateRepeatRule = (repeat, days, startDate, untilDate) => {
    if (repeat === 'daily') {
        if (untilDate && isSameDay(startDate, untilDate)) {
            return false;
        }
    }
    else if (repeat === 'weekly') {
        if ((untilDate && isSameISOWeek(startDate, untilDate)) || days.length === 0) {
            return false;
        }
    }
    else if (repeat === 'monthly') {
        if ((untilDate && isSameMonth(startDate, untilDate)) || days.length === 0) {
            return false;
        }
    }
    else if (repeat === 'yearly') {
        if (untilDate && isSameYear(startDate, untilDate)) {
            return false;
        }
    }
    return true;
};
