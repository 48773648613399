import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchNoteGeoJSON } from '../../state/slices/mainMap';
export function useNoteMapData() {
    const { noteGeoJSON } = useAppSelector((state) => state.mainMap);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchNoteGeoJSON());
    }, [dispatch]);
    return { noteGeoJSON };
}
