import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { Ditto } from 'ditto-react';
import { INSPECTION_MODE_ID } from '../consts/inspection';
export function getInspectionTypeLabelV2(id) {
    switch (id) {
        case INSPECTION_MODE_ID.trackControl:
            return _jsx(Ditto, { componentId: "filter.inspectiontype.trackcontrol" });
        case INSPECTION_MODE_ID.winterService:
            return _jsx(Ditto, { componentId: "filter.inspectiontype.winterservice" });
        default:
            console.error('unknow inspection type id', id);
    }
}
