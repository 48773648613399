import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { REPEAT_OPTIONS } from '../../../common/recurringRules';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../FormInputSelect/FormInputSelect';
import { Check } from '../../icons';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import { TypedDitto } from '../../TypedDitto';
import { CustomRepeatForm } from './CustomRepeatForm';
import { MonthlyRepeatForm } from './MonthlyRepeatForm';
import { WeeklyRepeatForm } from './WeeklyRepeatForm';
import styles from './RepeatForm.module.css';
export function RepeatForm({ frequency, onChange, locale }) {
    const repeatOptions = {
        never: _jsx(TypedDitto, { componentId: "timepicker.customtimeframe.enddate.never" }),
        daily: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.daily" }),
        weekly: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.weekly" }),
        monthly: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.monthly" }),
        yearly: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.yearly" }),
        custom: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.custom" })
    };
    function handleRepeatOptionSelect(option) {
        switch (option) {
            case 'never':
                onChange({
                    repeat: 'never'
                });
                return;
            case 'daily':
                onChange({
                    repeat: 'daily'
                });
                return;
            case 'weekly':
                onChange({
                    repeat: 'weekly',
                    days: []
                });
                return;
            case 'monthly':
                onChange({
                    repeat: 'monthly',
                    days: []
                });
                return;
            case 'yearly':
                onChange({
                    repeat: 'yearly',
                    days: []
                });
                return;
            case 'custom':
                onChange({
                    repeat: 'custom',
                    interval: 2,
                    value: {
                        repeat: 'daily'
                    }
                });
                return;
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles['input-select'] }, { children: _jsx(FormInputSelect, { label: _jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat" }), value: _jsx(FormInputCurrentSelectedValue, { text: repeatOptions[frequency.repeat] }), menuItems: REPEAT_OPTIONS.map((option) => (_jsx("div", Object.assign({ className: styles['repeat-option'] }, { children: _jsx(SelectboxEntry, { text: repeatOptions[option], onClick: () => handleRepeatOptionSelect(option), trailingIcon: frequency.repeat === option ? _jsx(Check, {}) : undefined }) }), option))), canFloat: true }) })), frequency.repeat === 'weekly' && (_jsx(WeeklyRepeatForm, { frequency: frequency, locale: locale, onChange: onChange })), frequency.repeat === 'monthly' && (_jsx(MonthlyRepeatForm, { frequency: frequency, locale: locale, onChange: onChange })), frequency.repeat === 'custom' && (_jsx(CustomRepeatForm, { frequency: frequency, locale: locale, onChange: onChange }))] }));
}
