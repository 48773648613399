var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { MARKER_PRIORITY_ID, MARKER_SOURCE_ID, MARKER_STATUS_ID } from '../../common/consts/marker';
import { MARKER_CREATOR_FORM_WIDTH_EMBEDDED } from '../../common/consts/width';
import { getSignFromSignTree } from '../../common/convert/signInventory';
import { parseRecurringFormula } from '../../common/recurringRules';
import { DueDatePickerControl } from '../../components/DueDatePicker/DueDatePickerControl/DueDatePickerControl';
import { AssigneeSelectField } from '../../components/EditorForms/AssigneeSelect/AssigneeSelectField/AssigneeSelectField';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { SparkleMagicApplied } from '../../components/icons';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { Loader } from '../../components/Loader/Loader';
import { MarkerCreator } from '../../components/MarkerCreator/MarkerCreator';
import { MarkerLowerForm } from '../../components/MarkerLowerForm/MarkerLowerForm';
import { TypedDitto } from '../../components/TypedDitto';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useMarkerCreatorOpener } from '../../hooks/useMarkerCreatorOpener';
import { useUserFullname } from '../../hooks/useUserFullname';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetAssetMultiSelect } from '../../state/slices/leftPanelAsset';
import { resetObjectMultiSelect } from '../../state/slices/leftPanelObject';
import { resetSignMultiSelect } from '../../state/slices/leftPanelSign';
import { resetMarkerBulkCreator, uploadCreateLinkMarkerBatchTk } from '../../state/slices/markerBulkCreator';
import { fetchAssetsTk, fetchPointList, fetchSignInfoList, resetSharedDataHolder } from '../../state/slices/sharedDataHolder';
import { CategorySelectContainer } from '../CategorySelectContainer/CategorySelectContainer';
export function RightPanelMarkerBulkCreatorContainer() {
    var _a, _b, _c, _d;
    const { addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { createStatus, source } = useAppSelector((state) => state.markerBulkCreator);
    const { signInfoList, pointList, assetList } = useAppSelector((state) => state.sharedDataHolder);
    const { markerStatusList, markerPriorityList, markerCategoryList, taskAssigneeList, signTree } = useAppSelector((state) => state.shared);
    const { selectedLayerId } = useAppSelector((state) => state.layer);
    const { fullname } = useUserFullname();
    const { getMagicValuesForSignInventoryTask } = useMarkerCreatorOpener();
    const { reloadAssetTilesData } = useTilesUpdator();
    const magicInputValues = [];
    if (selectedLayerId === 'sign-inventory' && signInfoList) {
        magicInputValues.push(...signInfoList.map((signInfo) => {
            const { taskTitle, taskDescription, taskPriorityId } = getMagicValuesForSignInventoryTask(signInfo.damageType);
            const sign = getSignFromSignTree(signTree, signInfo.tag.subSpecifiedClass);
            const fullTaskDescription = `${taskDescription} "${sign === null || sign === void 0 ? void 0 : sign.name} (${sign === null || sign === void 0 ? void 0 : sign.bastId})"`;
            return {
                title: taskTitle,
                priorityId: taskPriorityId,
                description: fullTaskDescription
            };
        }));
    }
    const defaultDescription = magicInputValues.length > 0 ? magicInputValues.map((i) => i.description).join('; ') : '';
    const defaultTitle = magicInputValues.length > 0 ? magicInputValues.map((i) => i.title).join('; ') : '';
    const defaultPriority = (magicInputValues.length > 0 &&
        markerPriorityList.find((i) => i.priorityId === markerPriorityList[0].priorityId)) ||
        markerPriorityList.find((i) => i.priorityId === MARKER_PRIORITY_ID.medium) ||
        markerPriorityList[0];
    const status = markerStatusList.find((i) => i.statusId === MARKER_STATUS_ID.open) || markerStatusList[0];
    const [markerFields, setMarkerFields] = useState({
        description: defaultDescription,
        creator: fullname,
        title: defaultTitle,
        priority: defaultPriority,
        status,
        assignees: [],
        recurringFormula: ''
    });
    const dateTimeFormatter = useDateTimeFormatter();
    useEffect(() => {
        setMarkerFields({
            description: defaultDescription,
            creator: fullname,
            title: defaultTitle,
            priority: defaultPriority,
            status,
            assignees: [],
            recurringFormula: ''
        });
    }, [defaultDescription, defaultPriority, defaultTitle, fullname, status]);
    const [titleError, setTitleError] = useState('');
    const placeholderTaskTitle = useDittoWrapper({ componentId: 'tasks.tasktitle' });
    const maximum100charactersError = useDittoWrapper({ componentId: 'maximum100characters' });
    const notspecified = useDittoWrapper({ componentId: 'notspecified' });
    const [selectedCategoryId, setSelectedCategoryId] = useState((_a = markerFields.category) === null || _a === void 0 ? void 0 : _a.categoryId);
    const recurringFormula = parseRecurringFormula(markerFields.recurringFormula);
    useEffect(() => {
        const category = markerCategoryList.find((item) => item.categoryId === selectedCategoryId);
        updateMarkerFields({ category });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategoryId, markerCategoryList]);
    useEffect(() => {
        if (source && source.pointIds) {
            dispatch(fetchPointList(source.pointIds));
        }
        if (source && source.signIds) {
            dispatch(fetchSignInfoList(source.signIds));
        }
        if (source && source.assetIds) {
            dispatch(fetchAssetsTk({ ids: source.assetIds }));
        }
    }, [dispatch, source]);
    if (!source) {
        return _jsx(Loader, {});
    }
    function handleClickClose() {
        dispatch(resetMarkerBulkCreator());
        dispatch(resetSharedDataHolder());
    }
    function updateMarkerFields(update) {
        setMarkerFields(Object.assign(Object.assign({}, markerFields), update));
    }
    function handleDueDatePickerControlClick() {
        addModal({
            id: 'DueDatePickerModalContainer',
            props: {
                recurringFormula: markerFields.recurringFormula,
                onConfirm: handleDueDateChange
            }
        });
    }
    function batchCreateMarkerFromSign(signInfoList) {
        return __awaiter(this, void 0, void 0, function* () {
            dispatch(uploadCreateLinkMarkerBatchTk({
                photosToLink: signInfoList.map((item) => ({
                    pointUuid: item.pointUuid,
                    imageFolder: item.inspectionUuid.toUpperCase(),
                    imageName: item.imageName
                })),
                items: signInfoList.map((item, index) => {
                    var _a;
                    const title = defaultTitle === markerFields.title
                        ? magicInputValues[index].title
                        : markerFields.title;
                    const description = defaultDescription === markerFields.description
                        ? magicInputValues[index].description
                        : markerFields.title;
                    const priorityId = defaultPriority.priorityId === markerFields.priority.priorityId
                        ? magicInputValues[index].priorityId
                        : markerFields.priority.priorityId;
                    return {
                        // TODO: addressJson should be used instead of address
                        address: item.displayName,
                        categoryId: (_a = markerFields.category) === null || _a === void 0 ? void 0 : _a.categoryId,
                        latitude: item.offsetPosition[1],
                        longitude: item.offsetPosition[0],
                        description,
                        priorityId,
                        creator: markerFields.creator,
                        statusId: markerFields.status.statusId,
                        assignees: markerFields.assignees,
                        whereWasCreated: MARKER_SOURCE_ID.webApp,
                        title,
                        recurringFormula: markerFields.recurringFormula
                    };
                })
            }));
            dispatch(resetSignMultiSelect());
        });
    }
    function batchCreateMarkerFromAsset(assetList) {
        return __awaiter(this, void 0, void 0, function* () {
            dispatch(uploadCreateLinkMarkerBatchTk({
                fromAssetIds: assetList.map((i) => i.id),
                items: assetList.map((item) => {
                    var _a;
                    return ({
                        addressJson: item.address,
                        categoryId: (_a = markerFields.category) === null || _a === void 0 ? void 0 : _a.categoryId,
                        latitude: item.coordinates[1],
                        longitude: item.coordinates[0],
                        description: markerFields.description,
                        priorityId: markerFields.priority.priorityId,
                        creator: markerFields.creator,
                        statusId: markerFields.status.statusId,
                        assignees: markerFields.assignees,
                        whereWasCreated: MARKER_SOURCE_ID.webApp,
                        title: markerFields.title,
                        recurringFormula: markerFields.recurringFormula,
                        photos: item.photos.map((p) => ({ uuid: p.photoUuid, createdAt: p.createdAt }))
                    });
                })
            }))
                .unwrap()
                .then(() => {
                reloadAssetTilesData();
            });
            dispatch(resetAssetMultiSelect());
        });
    }
    function batchCreateMarkerFromObject(objectPointList) {
        return __awaiter(this, void 0, void 0, function* () {
            dispatch(uploadCreateLinkMarkerBatchTk({
                photosToLink: objectPointList.map((item) => ({
                    pointUuid: item.systemsPointId,
                    imageFolder: item.imgFolder,
                    imageName: item.imgName
                })),
                items: objectPointList.map((item) => {
                    var _a;
                    return ({
                        // TODO: addressJson should be used instead of address
                        address: item.displayName,
                        categoryId: (_a = markerFields.category) === null || _a === void 0 ? void 0 : _a.categoryId,
                        latitude: item.coordinates[1],
                        longitude: item.coordinates[0],
                        description: markerFields.description,
                        priorityId: markerFields.priority.priorityId,
                        creator: markerFields.creator,
                        statusId: markerFields.status.statusId,
                        assignees: markerFields.assignees,
                        whereWasCreated: MARKER_SOURCE_ID.webApp,
                        title: markerFields.title,
                        recurringFormula: markerFields.recurringFormula
                    });
                })
            }));
            dispatch(resetObjectMultiSelect());
        });
    }
    function handleMarkerCreateClick() {
        if ((source === null || source === void 0 ? void 0 : source.signIds) && signInfoList) {
            batchCreateMarkerFromSign(signInfoList);
        }
        if ((source === null || source === void 0 ? void 0 : source.assetIds) && assetList) {
            batchCreateMarkerFromAsset(assetList);
        }
        if ((source === null || source === void 0 ? void 0 : source.pointIds) && pointList) {
            batchCreateMarkerFromObject(pointList);
        }
    }
    function handleSelectCategory(categoryId) {
        setSelectedCategoryId(categoryId);
    }
    function handleAssigneeChange(value) {
        updateMarkerFields({ assignees: value });
    }
    function handleDueDateChange(value) {
        updateMarkerFields({ recurringFormula: value });
    }
    function handleTaskTitleChange(value) {
        if (value.length > 100) {
            setTitleError(maximum100charactersError);
        }
        else {
            setTitleError('');
        }
        updateMarkerFields({ title: value });
    }
    function handleAssigneeControlClick() {
        addModal({
            id: 'AssigneeSelectModalContainer',
            props: {
                initialValues: markerFields.assignees,
                onConfirm: handleAssigneeChange
            }
        });
    }
    const lowerForm = (_jsx(MarkerLowerForm, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, description: markerFields.description, magicDescription: defaultDescription, status: { items: markerStatusList, current: markerFields.status }, priority: { items: markerPriorityList, current: markerFields.priority }, magicPriorityIds: magicInputValues.map((i) => i.priorityId || 0), category: _jsx(CategorySelectContainer, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, onCategorySelect: handleSelectCategory, selectedId: selectedCategoryId }), title: _jsx(FormInputText, { maxLength: 101, placeholder: notspecified, variant: "editor-field", error: titleError, width: 332, value: markerFields.title, label: placeholderTaskTitle, onValueChange: handleTaskTitleChange, magicInput: defaultTitle
                ? {
                    value: defaultTitle
                }
                : undefined }), assignee: _jsx(AssigneeSelectField, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, options: taskAssigneeList, selectedValues: markerFields.assignees, onClick: handleAssigneeControlClick }), taskDatePicker: _jsx(DueDatePickerControl, { frequency: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.frequency, value: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.startDate, dateTimeFormatter: dateTimeFormatter, onClick: handleDueDatePickerControlClick }), onChange: updateMarkerFields }));
    const topInfoBox = [];
    if (source.signIds && magicInputValues.length > 0) {
        topInfoBox.push(_jsx(InfoBox, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, variant: "green", icon: _jsx(SparkleMagicApplied, {}), content: _jsx(TypedDitto, { componentId: "taskcreator.magicinputtrafficsignscopy" }) }));
    }
    const toCreateCount = ((_b = source.pointIds) === null || _b === void 0 ? void 0 : _b.length) || ((_c = source.signIds) === null || _c === void 0 ? void 0 : _c.length) || ((_d = source.assetIds) === null || _d === void 0 ? void 0 : _d.length) || 0;
    topInfoBox.push(_jsx(InfoBox, { width: MARKER_CREATOR_FORM_WIDTH_EMBEDDED, content: source.assetIds ? (_jsx(Ditto, { componentId: "tasks.youwillcreateoneindividualtaskperasset" })) : (_jsx(Ditto, { componentId: 'markercreator.multiple.description', variables: {
                SelectedResults: toCreateCount
            }, count: toCreateCount })) }));
    return (_jsx(MarkerCreator, { position: "embedded", variant: "bulk-create", disabled: createStatus === 'loading', markerUpperFieldData: markerFields, markerLowerForm: lowerForm, onClickClose: handleClickClose, onClickCreate: handleMarkerCreateClick, toCreateCount: toCreateCount, topInfobox: topInfoBox, step: -1 }));
}
