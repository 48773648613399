import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { FormInputCurrentSelectedValue } from '../FormInputSelect/FormInputSelect';
import { FormInputSelect } from '../FormInputSelect/FormInputSelect';
import { Check } from '../icons';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import { TextOverflow } from '../TextOverflow/TextOverflow';
export function UserDepartmentSelect({ onChange, list, required, showInfo = false, value, variant }) {
    var _a;
    const departmentNameId = (_a = list.find((department) => department.id === value)) === null || _a === void 0 ? void 0 : _a.name;
    return (_jsx(FormInputSelect, { canFloat: true, width: "100%", label: _jsx(Ditto, { componentId: "register.department" }), info: showInfo
            ? {
                tooltip: _jsx(Ditto, { componentId: "register.department.info" }),
                position: 'center'
            }
            : undefined, menuItems: list.map((department) => (_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: department.name }), onClick: () => onChange && onChange(department.id), trailingIcon: department.id === value ? _jsx(Check, {}) : undefined, "data-test": `department-${department.id}` }, `department-${department.id}`))), menuWidth: 300, menuMaxHeight: 320, required: required, size: variant, "data-test": "department-input", value: value ? (_jsx(FormInputCurrentSelectedValue, { text: departmentNameId ? (_jsx(TextOverflow, { text: _jsx(Ditto, { componentId: departmentNameId }), maxLines: 1, tooltip: "default" })) : ('') })) : undefined }));
}
