import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { IconResizer } from '../IconResizer/IconResizer';
import { MagicInputDot, MagicInputDotFilled, SparkleMagicAddFilled, SparkleMagicApplied } from '../icons';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { TypedDitto } from '../TypedDitto';
import styles from './FormInputMagicValueIcon.module.css';
export const FormInputMagicValueIcon = ({ className, isHover, value, magicValue, tooltip, status, onClick }) => {
    const isApplied = status ? status === 'applied' : value == magicValue;
    return (_jsx("div", Object.assign({ className: className }, { children: _jsx(TooltipWrapper, Object.assign({ size: "small", text: tooltip !== null && tooltip !== void 0 ? tooltip : (_jsx(TypedDitto, { componentId: "taskcreator.magicinputtooltipautofillbasedoncondition" })), isAlwaysVisible: true, onClick: (event) => {
                event.stopPropagation();
                onClick === null || onClick === void 0 ? void 0 : onClick();
            }, position: 'end', width: 220 }, { children: _jsxs(_Fragment, { children: [isHover && (_jsx(_Fragment, { children: isApplied ? (_jsx(IconResizer, Object.assign({ size: 20 }, { children: _jsx(SparkleMagicAddFilled, {}) }))) : (_jsx("div", Object.assign({ className: styles['icon-hover'] }, { children: _jsx(IconResizer, Object.assign({ size: 16 }, { children: _jsx(SparkleMagicApplied, {}) })) }))) })), !isHover && (isApplied ? _jsx(MagicInputDotFilled, {}) : _jsx(MagicInputDot, {}))] }) })) })));
};
