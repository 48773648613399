var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY } from '../../common/consts/mixpanel';
import { keycloakLogout } from '../../common/keycloak';
import { ROOT_PATH } from '../../common/url';
import { Account } from '../../components/icons';
import { LayerPanel } from '../../components/LayerPanel/LayerPanel';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useUserRole } from '../../hooks/useUserRole';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchAccessList, resetSettingsActiveLayersTk } from '../../state/slices/auth';
import { setMapLayers, setSelectedLayerId } from '../../state/slices/layer';
import { persistor } from '../../state/store';
export function LayerPanelContainer() {
    const { addModal } = useModalContext();
    const { layerList, selectedLayerId } = useAppSelector((state) => state.layer);
    const { accountsAccessList, targetSchema, userAccount } = useAppSelector((state) => state.auth);
    const { locale } = useAppSelector((state) => state.app);
    const { shouldShowInventoryTeaser } = useLayerChecker();
    const { isAdmin } = useUserRole();
    const dispatch = useAppDispatch();
    const isAnySignLayerAuthorized = layerList.find((item) => (item.id === 'sign-inventory' || item.id === 'sign') && item.isAuthorized);
    const layers = layerList.filter((l) => l.isAuthorized ||
        (shouldShowInventoryTeaser && l.id === 'sign-inventory' && !isAnySignLayerAuthorized));
    function enablePresets(layer) {
        let layers = [...layerList];
        // TODO @Juri clarify presets logic
        // if (layer.isEnabled) {
        //     const enableLayerIds = [layer.id, ...LAYERS_PRESETS[layer.id]];
        //     layers = layers.map((item) =>
        //         enableLayerIds.includes(item.id) ? { ...item, isEnabled: true } : item
        //     );
        // } else {
        //     layers = layers.map((item) => (item.id === layer.id ? layer : item));
        // }
        //
        // return layers;
        return layers.map((item) => (item.id === layer.id ? layer : item));
    }
    function handleLayerChange(layer) {
        if (!layer.isAuthorized) {
            addModal({ id: 'InventoryTeaserModal' });
            return;
        }
        dispatch(setMapLayers(enablePresets(layer)));
        // hide the left panel if the current layer is turned off
        if (!layer.isEnabled && selectedLayerId === layer.id) {
            dispatch(setSelectedLayerId(undefined));
        }
    }
    function handleSelectLayer(layerId, excludeOthers) {
        if (selectedLayerId === layerId && !excludeOthers) {
            dispatch(setSelectedLayerId(undefined));
        }
        else {
            dispatch(setSelectedLayerId(layerId));
            mixpanel.track(MIXPANEL_EVENT_NAME.selectLayer, {
                [MIXPANEL_EVENT_PROPERTY.layerName]: layerId
            });
        }
    }
    function handleSetLayers(layers) {
        dispatch(setMapLayers(layers));
    }
    function handleHomeClick() {
        return __awaiter(this, void 0, void 0, function* () {
            yield dispatch(resetSettingsActiveLayersTk());
            yield persistor.purge();
            window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.main}`;
        });
    }
    function handleLogout() {
        keycloakLogout();
    }
    function handleUserAccountClick() {
        addModal({ id: 'UserAccountModal' });
    }
    function handleGlobalSettingClick() {
        addModal({ id: 'GlobalSettingsModal' });
    }
    function handleCoWorkerSettingClick() {
        addModal({ id: 'CoWorkerModal' });
    }
    function handleSwitchAccount(targetSchema) {
        persistor.purge();
        window.location.href = `${window.location.origin.toString()}/${ROOT_PATH.main}?targetSchema=${targetSchema}`;
    }
    useEffect(() => {
        dispatch(fetchAccessList());
    }, [dispatch]);
    return (_jsx(LayerPanel, { layers: layers, onLayerChange: handleLayerChange, onSetLayers: handleSetLayers, customerLogo: _jsx(Account, {}), selectedLayer: selectedLayerId, onSelectLayer: handleSelectLayer, onResetClick: handleHomeClick, settingsProps: {
            currentAccountId: targetSchema,
            onGlobalSettingClick: handleGlobalSettingClick,
            onUserSettingClick: handleUserAccountClick,
            onCoWorkerSettingClick: isAdmin ? handleCoWorkerSettingClick : undefined,
            onLogoutClick: handleLogout,
            accountList: accountsAccessList.map((item) => ({
                id: item.username,
                name: item.displayName
            })),
            onAccountSwitch: handleSwitchAccount,
            userAccount: userAccount
                ? {
                    firstName: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.firstName) || undefined,
                    lastName: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.lastName) || undefined,
                    profileImageUrl: userAccount.profileImageUrl,
                    email: userAccount.email,
                    phoneNumber: userAccount.phoneNumber
                }
                : undefined,
            locale
        } }));
}
