export var SIGN_DAMAGE_ID;
(function (SIGN_DAMAGE_ID) {
    SIGN_DAMAGE_ID[SIGN_DAMAGE_ID["covered"] = 1] = "covered";
    SIGN_DAMAGE_ID[SIGN_DAMAGE_ID["dented"] = 2] = "dented";
    SIGN_DAMAGE_ID[SIGN_DAMAGE_ID["dirty"] = 3] = "dirty";
    SIGN_DAMAGE_ID[SIGN_DAMAGE_ID["faded"] = 4] = "faded";
    SIGN_DAMAGE_ID[SIGN_DAMAGE_ID["stickered"] = 5] = "stickered";
    SIGN_DAMAGE_ID[SIGN_DAMAGE_ID["not_damaged"] = 6] = "not_damaged";
})(SIGN_DAMAGE_ID || (SIGN_DAMAGE_ID = {}));
export var SIGN_DAMAGE_NAMES;
(function (SIGN_DAMAGE_NAMES) {
    SIGN_DAMAGE_NAMES["covered"] = "covered";
    SIGN_DAMAGE_NAMES["dented"] = "dented";
    SIGN_DAMAGE_NAMES["dirty"] = "dirty";
    SIGN_DAMAGE_NAMES["faded"] = "faded";
    SIGN_DAMAGE_NAMES["stickered"] = "stickered";
    SIGN_DAMAGE_NAMES["not_damaged"] = "not_damaged";
})(SIGN_DAMAGE_NAMES || (SIGN_DAMAGE_NAMES = {}));
export const SIGNS_TO_SHOW = [
    SIGN_DAMAGE_ID.covered,
    SIGN_DAMAGE_ID.dirty,
    SIGN_DAMAGE_ID.faded,
    SIGN_DAMAGE_ID.stickered
];
export const SIGN_INVENTORY_SIGNS_TO_SHOW = [
    SIGN_DAMAGE_ID.covered,
    SIGN_DAMAGE_ID.dirty,
    SIGN_DAMAGE_ID.faded,
    SIGN_DAMAGE_ID.stickered,
    SIGN_DAMAGE_ID.not_damaged
];
export var SIGN_STATUS_CODE;
(function (SIGN_STATUS_CODE) {
    SIGN_STATUS_CODE["predicted"] = "predicted";
    SIGN_STATUS_CODE["confirmed"] = "confirmed";
    SIGN_STATUS_CODE["deleted"] = "deleted";
})(SIGN_STATUS_CODE || (SIGN_STATUS_CODE = {}));
export const SIGN_STATUS_CODES_TO_SHOW = [SIGN_STATUS_CODE.predicted, SIGN_STATUS_CODE.confirmed];
export var SIGN_PROPERTY_TYPE;
(function (SIGN_PROPERTY_TYPE) {
    SIGN_PROPERTY_TYPE["text"] = "text";
    SIGN_PROPERTY_TYPE["number"] = "number";
    SIGN_PROPERTY_TYPE["date"] = "date";
    SIGN_PROPERTY_TYPE["select"] = "select";
})(SIGN_PROPERTY_TYPE || (SIGN_PROPERTY_TYPE = {}));
