import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './TextOverflow.module.css';
export function TextOverflow({ className, text, width, maxLines, onWidthChange, tooltip, tooltipPosition, tooltipAlign, tooltipMaxWidth, wordBreak = 'break-all' }) {
    const overflow = useRef(null);
    const [isOverflow, setIsOverflow] = useState(false);
    const [textWidth, setTextWidth] = useState(0);
    useEffect(() => {
        if (overflow.current) {
            const currentTextWidth = overflow.current.offsetWidth;
            if (textWidth !== currentTextWidth) {
                onWidthChange && onWidthChange(currentTextWidth);
                setTextWidth(currentTextWidth);
            }
            setIsOverflow(overflow.current.offsetHeight < overflow.current.scrollHeight);
        }
    }, [onWidthChange, textWidth]);
    return (_jsxs("div", Object.assign({ className: classes(styles['overflow-wrapper'], className), style: { width: width + 'px' } }, { children: [_jsx("div", Object.assign({ className: styles['text'], style: { WebkitLineClamp: maxLines, wordBreak: wordBreak }, ref: overflow }, { children: text })), tooltip && isOverflow && (_jsx("div", Object.assign({ className: classes(styles['anchor-tooltip'], tooltipPosition && styles[`anchor-tooltip__${tooltipPosition}`], tooltipAlign && styles[`anchor-tooltip__${tooltipAlign}`]) }, { children: tooltip === 'small' ? (_jsx(Tooltip, { text: text, size: "small", position: tooltipAlign, width: tooltipMaxWidth })) : (_jsx(Tooltip, { text: text, arrow: "up", position: tooltipAlign, width: 256 })) })))] })));
}
