import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { AnimatePresence } from 'framer-motion';
import { ASSET_ICON_TOTAL_COUNT } from '../../../../../common/consts/asset';
import { AssetIcon } from '../../../../../common/icon';
import { useFloatingDropdown } from '../../../../../hooks/forms/useFloatingDropdown';
import { Button } from '../../../../../uikit/Button/Button';
import { ActionModal } from '../../../../ActionModal/ActionModal';
import { FloatingDropdown } from '../../../../FloatingDropdown/FloatingDropdown';
import styles from './IconSelect.module.css';
const ICON_ID_LIST = Array(ASSET_ICON_TOTAL_COUNT)
    .fill(0)
    .map((_, idx) => idx + 1);
export function IconSelect({ iconId, onSelect }) {
    const { isOpen: isFloatingOpen, hide, toggle, buttonRef, position } = useFloatingDropdown({ offset: { top: 12, left: 0, right: 0 } });
    const isReadOnly = !onSelect;
    function handleSelect(v) {
        hide();
        onSelect && onSelect(v);
    }
    const control = (_jsx("div", Object.assign({ ref: buttonRef, onClick: isReadOnly ? undefined : toggle, className: classes(styles['icon'], !isReadOnly && styles[`icon__default`]) }, { children: _jsx(AssetIcon, { iconId: iconId }) })));
    return (_jsxs(_Fragment, { children: [control, _jsx(AnimatePresence, { children: isFloatingOpen && (_jsx(FloatingDropdown, Object.assign({}, position, { content: _jsx(ActionModal, Object.assign({ width: 252 }, { children: ICON_ID_LIST.map((iconId) => (_jsx(Button, { variant: "square", icon: _jsx(AssetIcon, { iconId: iconId }), onClick: () => handleSelect(iconId) }, iconId))) })), onClickOutside: () => hide() }), 'dropdown')) })] }));
}
