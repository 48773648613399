// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._BK8oCbIj3xnSFe0tEuJ{padding:0 12px;width:100%;display:flex;flex-direction:column;flex-grow:1}.DkEwz5M7vsRpqZSAnkLA{display:flex;align-items:center;padding:8px 16px 8px 8px}.OqNjEOl7GCTWYuyonMpw{display:flex;align-items:center;gap:4px;width:100%;font-size:16px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/Files/Files.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".container {\n    padding: 0 12px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.headline {\n    display: flex;\n    align-items: center;\n    padding: 8px 16px 8px 8px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    width: 100%;\n    font-size: 16px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_BK8oCbIj3xnSFe0tEuJ`,
	"headline": `DkEwz5M7vsRpqZSAnkLA`,
	"title": `OqNjEOl7GCTWYuyonMpw`
};
export default ___CSS_LOADER_EXPORT___;
