var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { StatusCodes } from 'http-status-codes';
import { FILE_MANAGER_LINK_SOURCE } from '../common/consts/config';
import { RESPONSE_ERROR_CODE } from '../common/fetch';
import { fileManagerClient, milkyWayApiClient } from '../openapi';
function getUploadUrl(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return fileManagerClient.fileManagerService.fileManagerServiceUploadSignedUrlByIdCommon(body);
    });
}
export function confirmUpload(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return fileManagerClient.fileManagerService.fileManagerServiceUploadConfirmationCommon(body);
    });
}
function getFileLink(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return milkyWayApiClient.milkyWayApiService.milkyWayApiServiceRoadSafetyLinkForUploadedFileRoadCondition(body);
    });
}
function uploadToAWS(signedURL, file) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch(signedURL, {
            method: 'PUT',
            body: file,
            headers: {
                'content-type': file.type || 'application/octet-stream'
            }
        });
    });
}
export function uploadFile(file, fileType) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        // Step 1: Get the upload URL
        const fileExtension = (_a = file.name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        const uploadUrlResponse = yield getUploadUrl({
            creator: FILE_MANAGER_LINK_SOURCE,
            fileExtension,
            fileType
        });
        if (uploadUrlResponse.errorCode !== RESPONSE_ERROR_CODE.success) {
            throw new Error(`uploadUrlResponse failed. ${uploadUrlResponse}`);
        }
        const uploadPath = uploadUrlResponse.result.url;
        // Step 2: Upload the file
        const uploadResponse = yield uploadToAWS(uploadPath, file);
        if (uploadResponse.status !== StatusCodes.OK) {
            throw new Error(`uploadToAWS failed. ${uploadResponse}`);
        }
        const confirmResponse = yield confirmUpload({
            fileLinkId: uploadUrlResponse.result.fileLinkId
        });
        if (confirmResponse.errorCode !== RESPONSE_ERROR_CODE.success) {
            throw new Error(`confirmResponse failed. ${confirmResponse}`);
        }
        return uploadUrlResponse.result.fileLinkId;
    });
}
export function tryUploadPhotos(photos, type) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const photoUuids = yield Promise.all(photos.map((photo) => __awaiter(this, void 0, void 0, function* () {
                const res = yield uploadFile(photo, type);
                return res;
            })));
            return photoUuids;
        }
        catch (error) {
            console.error('uploadPhotos Failed. ', error);
        }
    });
}
export function tryLinkFiles(items) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const photoUuids = yield Promise.all(items.map((item) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const res = yield getFileLink({
                    pointUuid: item.pointUuid
                });
                if (!((_a = res.result) === null || _a === void 0 ? void 0 : _a.fileLinkId)) {
                    throw new Error('getFileLinks Failed: no fileLinkId from file-manager');
                }
                return res.result.fileLinkId;
            })));
            return photoUuids;
        }
        catch (error) {
            console.error('getFileLinks Failed. ', error);
        }
    });
}
