var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { FormInputText } from '../../FormInputText/FormInputText';
export const PriceInput = forwardRef((_a, ref) => {
    var { label, value, onChange, unitFormatter, variant, textAlign } = _a, props = __rest(_a, ["label", "value", "onChange", "unitFormatter", "variant", "textAlign"]);
    const { unit, decimalSeparator, thousandSeparator } = unitFormatter({ type: 'currency' });
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    return (_jsx(NumericFormat, Object.assign({}, props, { customInput: FormInputText, onValueChange: (values) => {
            if (onChange) {
                onChange(values.value);
            }
        }, thousandSeparator: thousandSeparator, label: label, decimalSeparator: decimalSeparator, decimalScale: 2, fixedDecimalScale: true, allowNegative: false, suffix: ` ${unit}`, getInputRef: ref, placeholder: notSpecifiedLabel, value: value, valueIsNumericString: true, "data-test": "form-input-price-value", textAlign: textAlign, variant: variant })));
});
PriceInput.displayName = 'PriceInput';
