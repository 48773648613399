// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BkNTUwa_siwBK_RVw_Sl{position:sticky;top:0;z-index:10;height:40px;padding:0 4px;transition:top .3s ease-in-out}.BkNTUwa_siwBK_RVw_Sl::after{content:"";position:absolute;top:0;left:0;width:100%;min-width:calc(100vw - 72px);height:40px;background:var(--primary-white);border-top:1px solid var(--root-dark-blue-1);border-bottom:1px solid var(--root-dark-blue-1)}.ZZa9n_ql__lJ8UWwC9eM::after{min-width:calc(100vw - 72px - 356px)}`, "",{"version":3,"sources":["webpack://./src/components/DataGridTable/DataGridTableHeader/DataGridTableHeader.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,8BAAA,CAGJ,6BACI,UAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,4BAAA,CACA,WAAA,CACA,+BAAA,CACA,4CAAA,CACA,+CAAA,CAGJ,6BACI,oCAAA","sourcesContent":[".container {\n    position: sticky;\n    top: 0;\n    z-index: 10;\n    height: 40px;\n    padding: 0 4px;\n    transition: top 0.3s ease-in-out;\n}\n\n.container::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    min-width: calc(100vw - 72px);\n    height: 40px;\n    background: var(--primary-white);\n    border-top: 1px solid var(--root-dark-blue-1);\n    border-bottom: 1px solid var(--root-dark-blue-1);\n}\n\n.container--right-panel-open::after {\n    min-width: calc(100vw - 72px - 356px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BkNTUwa_siwBK_RVw_Sl`,
	"container--right-panel-open": `ZZa9n_ql__lJ8UWwC9eM`
};
export default ___CSS_LOADER_EXPORT___;
