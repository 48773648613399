var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { parse } from 'date-fns';
import { Ditto } from 'ditto-react';
import { DatePickerContent } from '../../components/DatePickerContent/DatePickerContent';
import { Close } from '../../components/icons';
import { Modal } from '../../components/Modal/Modal';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
import { Button } from '../../uikit/Button/Button';
// @Marcin, making this DatePickerModalContainer use DatePickerContent seems like overengineered
// maybe it make sense to split and create a new component with only the calender view?
export function DatePickerModalContainer(_a) {
    var { title, onConfirm, rangeSelector } = _a, props = __rest(_a, ["title", "onConfirm", "rangeSelector"]);
    const { removeModal } = useModalContext();
    const { locale } = useAppSelector((state) => state.app);
    const { teamAccount } = useAppSelector((state) => state.auth);
    const startDate = useMemo(() => {
        const date = props.date || props.startDate;
        return date ? parse(date, 'yyyy-MM-dd', new Date()) : undefined;
    }, [props.date, props.startDate]);
    const endDate = useMemo(() => {
        return props.endDate ? parse(props.endDate, 'yyyy-MM-dd', new Date()) : undefined;
    }, [props.endDate]);
    function getRecurringFormula() {
        let output = '';
        if (startDate) {
            output += `DTSTART:${startDate.getFullYear()}${(startDate.getMonth() + 1)
                .toString()
                .padStart(2, '0')}${startDate.getDate().toString().padStart(2, '0')}T000000Z;`;
        }
        output += `
        RRULE:FREQ=DAILY`;
        if (!rangeSelector) {
            output += ';COUNT=1';
        }
        if (rangeSelector && endDate) {
            output += `;UNTIL=${endDate.getFullYear()}${(endDate.getMonth() + 1)
                .toString()
                .padStart(2, '0')}${endDate.getDate().toString().padStart(2, '0')}T000000Z`;
        }
        return output;
    }
    function handleCloseClick() {
        removeModal('DatePickerModalContainer');
    }
    function handleConfirmClick(formula) {
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(formula);
        handleCloseClick();
    }
    return (_jsx(Modal, { title: title !== null && title !== void 0 ? title : _jsx(Ditto, { componentId: "timepicker.customtimeframe.pickdate" }), trailing: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), onClick: handleCloseClick }), "data-test": "month-picker", content: _jsx(DatePickerContent, Object.assign({ countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, locale: locale, onConfirm: handleConfirmClick, recurringFormula: getRecurringFormula(), fixedHeight: true }, props)), width: 480, onClickOutside: handleCloseClick }));
}
