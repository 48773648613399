// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uRujWI_k7p9LuMTNEyQg{display:flex;flex-direction:column;gap:4px;width:100%}.s4JzHL9eGDsRmESJtvQe{display:flex;width:100%;justify-content:space-between}.LdNNn5rX6j3qWQ6tv0Lc{position:relative;width:100%;height:4px;background:rgba(255,255,255,.15)}.gDsB8UV9PnNkHrusiRwf{position:absolute;z-index:0;top:0;left:0;max-width:100%;height:100%;background:var(--primary-white)}.nPDHh5gK6Uz0TxsCGEfo{z-index:1;top:-4px;left:auto;right:0;max-width:100%;height:calc(100% + 8px);background:rgba(255,85,85,.6);border-left:1px solid var(--status-error)}.isfLxj91m5wUubwSpdbf{color:var(--status-error)}`, "",{"version":3,"sources":["webpack://./src/components/BudgetTooltip/BudgetTooltipBalanceBar/BudgetTooltipBalanceBar.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,OAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,UAAA,CACA,6BAAA,CAGJ,sBACI,iBAAA,CACA,UAAA,CACA,UAAA,CACA,gCAAA,CAGJ,sBACI,iBAAA,CACA,SAAA,CACA,KAAA,CACA,MAAA,CACA,cAAA,CACA,WAAA,CACA,+BAAA,CAGJ,sBACI,SAAA,CACA,QAAA,CACA,SAAA,CACA,OAAA,CACA,cAAA,CACA,uBAAA,CACA,6BAAA,CACA,yCAAA,CAGJ,sBACI,yBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    width: 100%;\n}\n\n.row {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.bar {\n    position: relative;\n    width: 100%;\n    height: 4px;\n    background: rgba(255, 255, 255, 0.15);\n}\n\n.bar-fill {\n    position: absolute;\n    z-index: 0;\n    top: 0;\n    left: 0;\n    max-width: 100%;\n    height: 100%;\n    background: var(--primary-white);\n}\n\n.bar-fill--exceed {\n    z-index: 1;\n    top: -4px;\n    left: auto;\n    right: 0;\n    max-width: 100%;\n    height: calc(100% + 8px);\n    background: rgba(255, 85, 85, 0.6);\n    border-left: 1px solid var(--status-error);\n}\n\n.balance--exceed {\n    color: var(--status-error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `uRujWI_k7p9LuMTNEyQg`,
	"row": `s4JzHL9eGDsRmESJtvQe`,
	"bar": `LdNNn5rX6j3qWQ6tv0Lc`,
	"bar-fill": `gDsB8UV9PnNkHrusiRwf`,
	"bar-fill--exceed": `nPDHh5gK6Uz0TxsCGEfo`,
	"balance--exceed": `isfLxj91m5wUubwSpdbf`
};
export default ___CSS_LOADER_EXPORT___;
