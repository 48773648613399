var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Ditto } from 'ditto-react';
import { convertUnitInput, formatUnit } from '../../common/utils/units';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { AutoUpdateModal } from '../../components/Modals/AutoUpdateModal/AutoUpdateModal';
import { PriceInput } from '../../components/SegmentForm/PriceInput/PriceInput';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { getPlanPreview } from '../../services/plan';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
const initialFields = {
    costs: true,
    yearsOfUse: true,
    width: true
};
export function AutoUpdateModalContainer({ formData, previewData, fields, isBulk, onChange, onConfirm, onCancel }) {
    const { teamAccount } = useAppSelector((state) => state.auth);
    const costsInlineEditRef = useRef(null);
    const widthInlineEditRef = useRef(null);
    const yearsOfUseInlineEditRef = useRef(null);
    const { removeModal } = useModalContext();
    const unitFormatter = useUnitFormatter();
    const [newData, setNewData] = useState(previewData);
    const [inlineFormData, setInlineFormData] = useState();
    // needed due to width unit conversion
    const [widthValue, setWidthValue] = useState(previewData.width);
    const [hasCostsChanged, setHasCostsChanged] = useState(false);
    const availableFields = Object.assign(Object.assign({}, initialFields), fields);
    const notSpecifiedLabel = useDittoWrapper({ componentId: 'notspecified' });
    function closeModal() {
        removeModal('AutoUpdateModalContainer');
    }
    function handleSubmitClick() {
        if (!newData)
            return;
        if ('costsNotSpecified' in newData)
            delete newData['costsNotSpecified'];
        if ('yearsOfUseNotSpecified' in newData)
            delete newData['yearsOfUseNotSpecified'];
        let data = Object.assign(Object.assign({}, newData), { autoUpdateAction: 'accept' });
        if ('costs' in newData) {
            data.magicInputCosts = newData.costs == previewData.costs;
            onChange('costs', newData.costs);
            onChange('magicInputCosts', data.magicInputCosts);
        }
        if ('yearsOfUse' in newData) {
            data.magicInputYearsOfUse = newData.yearsOfUse == previewData.yearsOfUse;
            onChange('yearsOfUse', newData.yearsOfUse);
            onChange('magicInputYearsOfUse', data.magicInputYearsOfUse);
        }
        if ('width' in newData) {
            data.magicInputWidth = newData.width == previewData.width;
            onChange('width', newData.width);
        }
        if (isBulk) {
            data.magicInputCosts =
                'costsMultiple' in previewData &&
                    previewData.costsMultiple &&
                    !('costs' in newData);
        }
        Object.keys(newData).every((key) => {
            if (key in previewData) {
                if (newData[key] !==
                    previewData[key]) {
                    data.autoUpdateAction = 'decline';
                    onChange('autoUpdateAction', 'decline');
                }
            }
            return true;
        });
        closeModal();
        onConfirm && onConfirm(data);
    }
    function handleCancelClick() {
        closeModal();
        onCancel && onCancel();
    }
    function handleInlineChange(field, value) {
        setNewData(Object.assign(Object.assign({}, newData), { [field]: value && value !== '' ? parseFloat(value) : undefined }));
    }
    function recalculateCosts(width) {
        return __awaiter(this, void 0, void 0, function* () {
            const { result: previewData } = yield getPlanPreview({
                countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode,
                treatmentId: formData.treatmentId,
                priority: formData.priority,
                width: width || undefined,
                length: formData.length,
                magicInputCosts: formData.magicInputCosts || (formData.costs === undefined && true),
                magicInputWidth: formData.magicInputWidth || (formData.width === undefined && true),
                magicInputYearsOfUse: formData.magicInputYearsOfUse || (formData.yearsOfUse === undefined && true)
            });
            if (!previewData)
                return;
            if (previewData.costs !== newData.costs) {
                setNewData(Object.assign(Object.assign({}, newData), { width: width || undefined, costs: previewData.costs }));
            }
        });
    }
    const items = (() => {
        let items = [];
        if (!formData || !newData)
            return items;
        if (availableFields.width && 'width' in newData) {
            const widthFormat = unitFormatter({
                type: 'length',
                value: inlineFormData ? parseFloat(inlineFormData) : undefined
            });
            items = [
                ...items,
                {
                    label: _jsx(Ditto, { componentId: "plannedsegments.width" }),
                    oldValue: formData.width,
                    newValue: newData.width || undefined,
                    inlineEdit: (_jsx(NumericFormat, { customInput: FormInputText, onValueChange: (values) => {
                            if (values.value === '0') {
                                setWidthValue(undefined);
                                return;
                            }
                            setWidthValue(values.floatValue);
                        }, thousandSeparator: widthFormat.thousandSeparator, decimalSeparator: widthFormat.decimalSeparator, decimalScale: 2, height: "small", placeholder: notSpecifiedLabel, getInputRef: widthInlineEditRef, suffix: widthFormat.unit ? ` ${widthFormat.unit}` : '', textAlign: "right", value: widthFormat.value || '', variant: "table-inline" })),
                    inputRef: widthInlineEditRef,
                    onOpen: () => {
                        var _a;
                        setInlineFormData((_a = newData.width) === null || _a === void 0 ? void 0 : _a.toString());
                    },
                    onSubmit: () => {
                        handleInlineChange('width', widthValue !== undefined
                            ? convertUnitInput('length', teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, widthValue === null || widthValue === void 0 ? void 0 : widthValue.toString()).toString()
                            : undefined);
                        if (!hasCostsChanged) {
                            recalculateCosts(widthValue);
                        }
                    },
                    unitFormatter: (options) => formatUnit(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, Object.assign(Object.assign({}, options), { type: 'length' }))
                }
            ];
        }
        if (availableFields.costs &&
            ('costs' in newData || 'costsNotSpecified' in newData || 'costsMultiple' in previewData)) {
            items = [
                ...items,
                {
                    label: _jsx(Ditto, { componentId: "plannedsegments.price" }),
                    oldValue: formData.costs,
                    newValue: newData.costs,
                    newMultipleValue: 'costsMultiple' in previewData &&
                        previewData.costsMultiple &&
                        !('costs' in newData),
                    inlineEdit: (_jsx(PriceInput, { height: "small", placeholder: notSpecifiedLabel, value: inlineFormData !== null && inlineFormData !== void 0 ? inlineFormData : '', onChange: (value) => {
                            setInlineFormData(value);
                        }, ref: costsInlineEditRef, textAlign: "right", width: 120, variant: "table-inline", unitFormatter: (options) => formatUnit(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, Object.assign(Object.assign({}, options), { type: 'currency' })) })),
                    inputRef: costsInlineEditRef,
                    onOpen: () => {
                        var _a;
                        setInlineFormData((_a = newData.costs) === null || _a === void 0 ? void 0 : _a.toString());
                    },
                    onSubmit: () => {
                        handleInlineChange('costs', inlineFormData);
                        setHasCostsChanged(true);
                        if ('costsMultiple' in previewData) {
                            delete previewData.costsMultiple;
                        }
                    },
                    unitFormatter: (options) => formatUnit(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, Object.assign(Object.assign({}, options), { type: 'currency' }))
                }
            ];
        }
        if (availableFields.yearsOfUse &&
            ('yearsOfUse' in newData || 'yearsOfUseNotSpecified' in newData)) {
            items = [
                ...items,
                {
                    label: _jsx(Ditto, { componentId: "plannedsegments.year" }),
                    oldValue: formData.yearsOfUse,
                    newValue: newData.yearsOfUse,
                    inlineEdit: (_jsx(FormInputText, { type: "number", placeholder: notSpecifiedLabel, value: inlineFormData !== null && inlineFormData !== void 0 ? inlineFormData : '', onValueChange: (value) => {
                            setInlineFormData(value);
                        }, onKeyDown: (e) => ['e', 'E', '-', '.', ','].includes(e.key) && e.preventDefault(), ref: yearsOfUseInlineEditRef, height: "small", textAlign: "right", variant: "table-inline" })),
                    inputRef: yearsOfUseInlineEditRef,
                    onOpen: () => {
                        var _a;
                        setInlineFormData((_a = newData.yearsOfUse) === null || _a === void 0 ? void 0 : _a.toString());
                    },
                    onSubmit: () => {
                        handleInlineChange('yearsOfUse', inlineFormData);
                    }
                }
            ];
        }
        return items;
    })();
    return (_jsx(AutoUpdateModal, { items: items, isBulk: isBulk, onCancelClick: handleCancelClick, onSubmitClick: handleSubmitClick }));
}
