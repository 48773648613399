// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X7AyjlBn0iB0m3Lrp2cI{display:flex;align-items:center;color:rgba(0,0,0,.4);font-size:14px;gap:2px}.pdQVJ4Dnn7CypaTcjLL9{flex:0 0 100%}.gGmX54Ego6gy9707vOMf{width:24px}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,cAAA,CACA,OAAA,CAGJ,sBACI,aAAA,CAGJ,sBACI,UAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    color: rgba(0, 0, 0, 0.4);\n    font-size: 14px;\n    gap: 2px;\n}\n\n.container__full {\n    flex: 0 0 100%;\n}\n\n.icon {\n    width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `X7AyjlBn0iB0m3Lrp2cI`,
	"container__full": `pdQVJ4Dnn7CypaTcjLL9`,
	"icon": `gGmX54Ego6gy9707vOMf`
};
export default ___CSS_LOADER_EXPORT___;
