import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchDepartmentList } from '../state/slices/auth';
export function useDepartmentsData() {
    const { departmentList, status, targetSchema } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchDepartmentList());
    }, [dispatch, targetSchema]);
    return { departmentList, status };
}
