import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SizeEnlarge } from '../../icons';
import styles from './ImageEnlarge.module.css';
export function ImageEnlarge({ onClick }) {
    return (_jsx("div", Object.assign({ className: styles['container'], onClick: (e) => {
            e.stopPropagation();
            onClick && onClick();
        } }, { children: _jsx(SizeEnlarge, {}) })));
}
