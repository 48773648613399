import { getApiUrl } from '../../common/url';
export const CONDITION_EDGE_TILES_ENDPOINT = `${getApiUrl()}/v1/map/tiles/streets/{z}/{x}/{y}`;
export const CONDITION_EDGE_JUNCTIONS_TILES_ENDPOINT = `${getApiUrl()}/v1/map/tiles/streets-junctions/{z}/{x}/{y}`;
export const CONDITION_POINT_TILES_ENDPOINT = `${getApiUrl()}/v1/map/tiles/points/{z}/{x}/{y}`;
export const REFERENCING_TILES_ENDPOINT = `${getApiUrl()}/v1/map/tiles/referencingv2/{z}/{x}/{y}`;
export const MARKER_INSPECTION_TILES_ENDPOINT = `${getApiUrl()}/v1/map/tiles/road-safety-inspections/{z}/{x}/{y}`;
export const CONDITION_OBJECT_TILES_ENDPOINT = `${getApiUrl()}/v1/map/tiles/objects/{z}/{x}/{y}`;
export const createSignTileEndpoint = (customer) => `${getApiUrl(true)}/streetart/v1/traffic-signs/tile/${customer}/{z}/{x}/{y}`;
export const INSPECTIONS_V2_TILES_ENDPOINT = `${getApiUrl(true)}/road-safety/v2/inspections/tile/{z}/{x}/{y}`;
export const createSignInventoryTileEndpoint = (customer) => `${getApiUrl(true)}/streetart/v1/traffic-sign-inventory/tile/${customer}/{z}/{x}/{y}`;
export const ASSET_TILES_ENDPOINT = `${getApiUrl(true)}/streetart/v1/manual-inventory/tile/{z}/{x}/{y}`;
