import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Children } from 'react';
import { classes } from 'classifizer';
import { IconResizer } from '../IconResizer/IconResizer';
import { ImageAdd, SizeEnlarge } from '../icons';
import styles from './ImagePreview.module.css';
const IMAGE_PREVIEW_MAX_LIMIT = 4;
export function ImagePreview({ children, uploadImage, onClickUpload, onClickEnlarge, width, height }) {
    const amount = Children.count(children);
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles['layout-' + amount], amount && styles['grid']), style: { width, height }, "data-test": "div-image-preview" }, { children: [amount === 0 && onClickUpload && (_jsx("div", Object.assign({ className: classes(styles['upload']), onClick: onClickUpload }, { children: _jsxs("div", { children: [_jsx("div", Object.assign({ className: classes(styles['upload-icon']) }, { children: _jsx(ImageAdd, {}) })), _jsx("div", Object.assign({ className: classes(styles['upload-text']) }, { children: uploadImage }))] }) }))), Children.toArray(children)
                .slice(0, IMAGE_PREVIEW_MAX_LIMIT)
                .map((item, index) => (_jsx("div", Object.assign({ className: classes(styles['wrapper']), onClick: () => onClickEnlarge && onClickEnlarge(index) }, { children: item }), index))), amount > IMAGE_PREVIEW_MAX_LIMIT && (_jsxs("div", Object.assign({ className: classes(styles['overlay']) }, { children: ["+", amount - IMAGE_PREVIEW_MAX_LIMIT] }))), amount > 0 && (_jsxs("div", Object.assign({ className: classes(styles['buttons']) }, { children: [onClickUpload && (_jsx("div", Object.assign({ className: classes(styles['button-wrapper']), onClick: onClickUpload }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(ImageAdd, {}) })) }))), onClickEnlarge && (_jsx("div", Object.assign({ className: classes(styles['button-wrapper']), onClick: () => onClickEnlarge && onClickEnlarge(), "data-test": "button-enlarge" }, { children: _jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(SizeEnlarge, {}) })) })))] })))] })));
}
