// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r43oFBdCKqmov7UyqJsv{position:relative}._sWuhFUfjca8CC3eOAO6{position:absolute;top:50%;left:50%;z-index:10}.Y6epXLgxSwx0X56xxqap{transform:translate(-50%, -50%)}.wtZdxmmPcwI1kh4tPkh_{transform:translate(-50%, -100%)}.cxvhaQqxG3O80D9i8ZPw{height:100%;width:100%;object-fit:cover;border-radius:2px}`, "",{"version":3,"sources":["webpack://./src/components/PrintLayout/MapboxStaticImage/MapboxStaticImage.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CAGJ,sBACI,+BAAA,CAGJ,sBACI,gCAAA,CAGJ,sBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.overlay {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    z-index: 10;\n}\n\n.overlay__center {\n    transform: translate(-50%, -50%);\n}\n\n.overlay__bottom {\n    transform: translate(-50%, -100%);\n}\n\n.image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `r43oFBdCKqmov7UyqJsv`,
	"overlay": `_sWuhFUfjca8CC3eOAO6`,
	"overlay__center": `Y6epXLgxSwx0X56xxqap`,
	"overlay__bottom": `wtZdxmmPcwI1kh4tPkh_`,
	"image": `cxvhaQqxG3O80D9i8ZPw`
};
export default ___CSS_LOADER_EXPORT___;
