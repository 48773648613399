// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AGFMky8FLgUP5MjeM7UQ{display:flex;align-items:center;justify-content:center;border-radius:6px;overflow:hidden;object-fit:cover;color:#222d39}.AGFMky8FLgUP5MjeM7UQ img{display:block;width:100%;height:100%;object-fit:cover}.cdkbOaivIadAGxFTjmfO{font-size:10px;font-weight:700;text-transform:uppercase}.Ges02r2ZrAf5b21Fulzw{font-size:16px}.C3RNDbCweiXOzfEfs8tE{background:#fbd400}.z_9fcMjcQMIlXbGwpkXJ{background:#434d57;color:var(--primary-white)}.dTFNuRxGN4q0v5X_osyv{background:rgba(15,18,22,.05)}.Uy0loLUQeblSNACLQP0L{background:var(--primary-white)}`, "",{"version":3,"sources":["webpack://./src/uikit/Avatar/Avatar.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA,CAGJ,0BACI,aAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CACA,wBAAA,CAGJ,sBACI,cAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,kBAAA,CACA,0BAAA,CAGJ,sBACI,6BAAA,CAGJ,sBACI,+BAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 6px;\n    overflow: hidden;\n    object-fit: cover;\n    color: #222d39;\n}\n\n.container img {\n    display: block;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.text {\n    font-size: 10px;\n    font-weight: 700;\n    text-transform: uppercase;\n}\n\n.text--bigger {\n    font-size: 16px;\n}\n\n.container--yellow {\n    background: #fbd400;\n}\n\n.container--dark {\n    background: #434d57;\n    color: var(--primary-white);\n}\n\n.container--gray {\n    background: rgba(15, 18, 22, 0.05);\n}\n\n.container--white {\n    background: var(--primary-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AGFMky8FLgUP5MjeM7UQ`,
	"text": `cdkbOaivIadAGxFTjmfO`,
	"text--bigger": `Ges02r2ZrAf5b21Fulzw`,
	"container--yellow": `C3RNDbCweiXOzfEfs8tE`,
	"container--dark": `z_9fcMjcQMIlXbGwpkXJ`,
	"container--gray": `dTFNuRxGN4q0v5X_osyv`,
	"container--white": `Uy0loLUQeblSNACLQP0L`
};
export default ___CSS_LOADER_EXPORT___;
