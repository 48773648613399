var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { ASSET_SOURCE_ID } from '../../common/consts/asset';
import { MIXPANEL_EVENT_NAME } from '../../common/consts/mixpanel';
import { getAttributeFieldsWidth } from '../../common/convert/asset';
import { parseCommonAttributeValue } from '../../common/convert/attribute';
import { CommonAttributeField } from '../../components/CommonAttributeField/CommonAttributeField';
import { AssetConverterForm, FORM_WIDTH } from '../../components/Modals/AssetConverterModal/AssetConverterForm/AssetConverterForm';
import { AssetConverterModal } from '../../components/Modals/AssetConverterModal/AssetConverterModal';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useAssetFields } from '../../hooks/forms/useAssetFields';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { bulkCreateAssets, fetchMarkersForConverterTk } from '../../state/slices/assetConverter';
export function AssetConverterModalContainer() {
    const { removeModal, addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { selectedIdList } = useAppSelector((state) => state.leftPanelMarker);
    const { markersForConverterList } = useAppSelector((state) => state.assetConverter);
    const { assetTypeList, unitList, assetTypeMap } = useAppSelector((state) => state.shared);
    const { updateAssetFields, assetFields, updateAttributes } = useAssetFields();
    const formatter = useFormatter();
    const { reloadAssetTilesData } = useTilesUpdator();
    const [isDeleteChecked, setIsDeleteChecked] = useState(true);
    useEffect(() => {
        dispatch(fetchMarkersForConverterTk(selectedIdList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleAssetTypeChange(value) {
        updateAssetFields({ typeId: value.id });
    }
    function handleCloseClick() {
        removeModal('AssetConverterModal');
    }
    function getAssetData() {
        if (!markersForConverterList.length || !assetFields.typeId)
            return;
        const assetData = markersForConverterList.map((marker) => {
            const photos = marker.photos.map((p) => ({
                uuid: p.uuid,
                createdAt: p.createdAt
            }));
            return {
                title: marker.title,
                address: marker.addressJson,
                inventoryTypeId: assetFields.typeId,
                notes: marker.issueDescription,
                coordinates: [marker.point.longitude, marker.point.latitude],
                attributes: assetFields.attributes,
                photoUuids: photos,
                createdAt: marker.createdAt,
                sourceId: ASSET_SOURCE_ID.webApp
            };
        });
        return { manualInventories: assetData };
    }
    function handleSubmitClick() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = getAssetData();
            if (!data) {
                return;
            }
            yield dispatch(bulkCreateAssets({
                assets: data,
                deleteAfterwards: isDeleteChecked,
                markerIds: selectedIdList
            }));
            reloadAssetTilesData();
            removeModal('AssetConverterModal');
            mixpanel.track(MIXPANEL_EVENT_NAME.finishTaskToAssetConverter);
        });
    }
    function handleAddNewTypeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'asset-type',
                modalProps: {
                    type: 'asset-type',
                    shouldGoToAddNewType: true
                }
            }
        });
    }
    const currentAssetType = assetFields.typeId ? assetTypeMap[assetFields.typeId] : undefined;
    const attributes = (currentAssetType === null || currentAssetType === void 0 ? void 0 : currentAssetType.attributes) && getAttributeFieldsWidth(currentAssetType.attributes);
    const content = (_jsx(_Fragment, { children: _jsx(AssetConverterForm, { assetTypesList: assetTypeList, onAssetTypeChange: handleAssetTypeChange, isDeleteChecked: isDeleteChecked, onDeleteClick: () => setIsDeleteChecked(!isDeleteChecked), selectedAmount: selectedIdList.length, attributeFieds: attributes === null || attributes === void 0 ? void 0 : attributes.map((item) => {
                var _a;
                return (_jsx(CommonAttributeField, { attribute: item, dropMenuWidth: FORM_WIDTH, onChange: (value) => {
                        const v = parseCommonAttributeValue(value, item.type);
                        updateAttributes({
                            id: item.inventoryTypeId,
                            inventoryTypeAttributeId: item.id,
                            [`${item.type}Value`]: v
                        });
                    }, unit: (_a = unitList.find((u) => u.id === item.unitId)) === null || _a === void 0 ? void 0 : _a.abbreviation, isFullWidth: item.isFullWidth, variant: "editor-field", formatter: formatter }, item.id));
            }), onAddNewTypeClick: handleAddNewTypeClick }) }));
    return (_jsx(AssetConverterModal, { content: content, loading: false, selectedAmount: selectedIdList.length, isSubmitDisabled: (assetFields === null || assetFields === void 0 ? void 0 : assetFields.typeId) ? false : true, onSubmitClick: handleSubmitClick, onCloseClick: handleCloseClick }));
}
