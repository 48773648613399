// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BDQLKDWGYpXF4oxth92Q{position:relative;padding:12px 12px 12px 40px;color:var(--secondary-blue);font-weight:400;font-size:12px;line-height:18px;background:rgba(21,86,235,.1);border-radius:6px}.BDQLKDWGYpXF4oxth92Q a{text-decoration:underline;cursor:pointer;color:var(--secondary-blue)}.BDQLKDWGYpXF4oxth92Q.CwYzz9ILcwUckgRYzUHB{padding-left:12px}.xM982WGx8ZpaS3ufHsG9{background-color:var(--root-green-2);color:var(--root-green-8)}.BDQLKDWGYpXF4oxth92Q p{margin:0}.GznBPMJaXeTSCC2cXeGg{position:absolute;top:8px;left:8px}.bAg59u8PrYSY3NSKmGQc{display:flex;align-items:center;margin-top:8px;text-decoration:underline}.bAg59u8PrYSY3NSKmGQc:hover{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/InfoBox/InfoBox.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,2BAAA,CACA,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,6BAAA,CACA,iBAAA,CAGJ,wBACI,yBAAA,CACA,cAAA,CACA,2BAAA,CAGJ,2CACI,iBAAA,CAGJ,sBACI,oCAAA,CACA,yBAAA,CAGJ,wBACI,QAAA,CAGJ,sBACI,iBAAA,CACA,OAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,cAAA,CACA,yBAAA,CAGJ,4BACI,cAAA","sourcesContent":[".container {\n    position: relative;\n    padding: 12px 12px 12px 40px;\n    color: var(--secondary-blue);\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n    background: rgba(21, 86, 235, 0.1);\n    border-radius: 6px;\n}\n\n.container a {\n    text-decoration: underline;\n    cursor: pointer;\n    color: var(--secondary-blue);\n}\n\n.container.no-icon {\n    padding-left: 12px;\n}\n\n.green {\n    background-color: var(--root-green-2);\n    color: var(--root-green-8);\n}\n\n.container p {\n    margin: 0;\n}\n\n.icon {\n    position: absolute;\n    top: 8px;\n    left: 8px;\n}\n\n.link {\n    display: flex;\n    align-items: center;\n    margin-top: 8px;\n    text-decoration: underline;\n}\n\n.link:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BDQLKDWGYpXF4oxth92Q`,
	"no-icon": `CwYzz9ILcwUckgRYzUHB`,
	"green": `xM982WGx8ZpaS3ufHsG9`,
	"icon": `GznBPMJaXeTSCC2cXeGg`,
	"link": `bAg59u8PrYSY3NSKmGQc`
};
export default ___CSS_LOADER_EXPORT___;
