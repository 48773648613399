import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TypedDitto } from '../../TypedDitto';
export function SelectedFrequency({ frequency }) {
    const frequencyNeverLabel = (_jsx(TypedDitto, { componentId: "timepicker.customtimeframe.enddate.never" }));
    const frequencyDailyLabel = (_jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.daily" }));
    const frequencyWeeklyLabel = (_jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.weekly" }));
    const frequencyMonthlyLabel = (_jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.monthly" }));
    const frequencyYearlyLabel = (_jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.yearly" }));
    const frequencyCustomLabel = (_jsx(TypedDitto, { componentId: "tasks.recurringtasks.intervall.repeat.custom" }));
    switch (frequency.repeat) {
        case 'daily':
            return frequencyDailyLabel;
        case 'weekly':
            return frequencyWeeklyLabel;
        case 'monthly':
            return frequencyMonthlyLabel;
        case 'yearly':
            return frequencyYearlyLabel;
        case 'custom':
            return frequencyCustomLabel;
        default:
            return frequencyNeverLabel;
    }
}
