// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tKYLiwbMWK2aOFZn8UKy{font-weight:600;font-size:14px;line-height:16px;opacity:.6;padding:13px 12px;background:var(--primary-white);border-radius:4px;width:260px;height:32px}`, "",{"version":3,"sources":["webpack://./src/components/Actionbar/Search/SearchHeader/SearchHeader.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,iBAAA,CACA,+BAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".container {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    opacity: 0.6;\n    padding: 13px 12px;\n    background: var(--primary-white);\n    border-radius: 4px;\n    width: 260px;\n    height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tKYLiwbMWK2aOFZn8UKy`
};
export default ___CSS_LOADER_EXPORT___;
