import { useEffect, useState } from 'react';
import { PRINT_WINDOW_DELAY } from '../common/consts/time';
export function usePrint(mapCount) {
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [mapsLoaded, setMapsLoaded] = useState(0);
    const [imageCount, setImageCount] = useState();
    function onMapLoad() {
        setMapsLoaded((prevMapsLoaded) => prevMapsLoaded + 1);
    }
    function onImageLoad() {
        setImagesLoaded((prevImgsLoaded) => prevImgsLoaded + 1);
    }
    useEffect(() => {
        if (imageCount === undefined) {
            return;
        }
        if (mapsLoaded >= mapCount && imagesLoaded >= imageCount) {
            setTimeout(() => window.print(), PRINT_WINDOW_DELAY);
        }
    }, [mapCount, imagesLoaded, mapsLoaded, imageCount]);
    return { onMapLoad, onImageLoad, setImageCount };
}
