// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bggBb74FikBckWkEMQPw{display:flex;padding:var(--Padding-PaddingM, 12px);justify-content:flex-end;align-items:flex-start;gap:var(--Gap-GapM, 4px);align-self:stretch;border-top:1px solid var(--primary-grey, #eff0f0)}`, "",{"version":3,"sources":["webpack://./src/components/Modals/BulkEditModal/TextEditor/TextEditor.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qCAAA,CACA,wBAAA,CACA,sBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,iDAAA","sourcesContent":[".button-row {\n    display: flex;\n    padding: var(--Padding-PaddingM, 12px);\n    justify-content: flex-end;\n    align-items: flex-start;\n    gap: var(--Gap-GapM, 4px);\n    align-self: stretch;\n\n    border-top: 1px solid var(--primary-grey, #eff0f0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-row": `bggBb74FikBckWkEMQPw`
};
export default ___CSS_LOADER_EXPORT___;
