// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XgyOgbZefUNsi46p91wI{padding:12px;width:50%}.qvXrAyhsOP8D40syyMXL{color:var(--primary-white)}.Li41lVAftDMEf5VsYFrX{margin-bottom:8px;font-size:14px;font-weight:400;line-height:130%;opacity:.6}.wS8zrTWaKL53hlJQaZib{display:flex;flex-wrap:wrap;align-items:center;gap:4px;font-size:14px;font-weight:600;line-height:normal}.XKoNhVXC_FrzlsTYbHfS{display:flex;align-items:center;gap:4px}@media print{.XgyOgbZefUNsi46p91wI{padding-top:5px}}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsClassification/DetailsClassificationGroupItem/DetailsClassificationGroupItem.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,SAAA,CAGJ,sBACI,0BAAA,CAGJ,sBACI,iBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,cAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CAGJ,aACI,sBACI,eAAA,CAAA","sourcesContent":[".container {\n    padding: 12px;\n    width: 50%;\n}\n\n.container__dark {\n    color: var(--primary-white);\n}\n\n.headline {\n    margin-bottom: 8px;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 130%;\n    opacity: 0.6;\n}\n\n.content {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    gap: 4px;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: normal;\n}\n\n.item {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n\n@media print {\n    .container {\n        padding-top: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XgyOgbZefUNsi46p91wI`,
	"container__dark": `qvXrAyhsOP8D40syyMXL`,
	"headline": `Li41lVAftDMEf5VsYFrX`,
	"content": `wS8zrTWaKL53hlJQaZib`,
	"item": `XKoNhVXC_FrzlsTYbHfS`
};
export default ___CSS_LOADER_EXPORT___;
