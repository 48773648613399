import { useRef, useState } from 'react';
export function useFloatingDropdown({ offset = { top: 0, left: 0, right: 0 } }) {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0, right: 0 });
    const buttonRef = useRef(null);
    function show() {
        if (!buttonRef.current || isOpen) {
            return;
        }
        setIsOpen(true);
        setPosition({
            left: buttonRef.current.getBoundingClientRect().left + offset.left,
            right: buttonRef.current.getBoundingClientRect().right + offset.right,
            top: buttonRef.current.getBoundingClientRect().top +
                buttonRef.current.getBoundingClientRect().height +
                offset.top
        });
    }
    function hide() {
        setIsOpen(false);
    }
    function toggle() {
        if (isOpen) {
            hide();
        }
        else {
            show();
        }
    }
    return {
        isOpen,
        position,
        buttonRef,
        toggle,
        show,
        hide
    };
}
