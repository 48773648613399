import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { IconText } from '../../IconText/IconText';
export function AppliedFilterBarValues({ componentKey, limit = 2, filteredValues, sortBy, iconFunction, labelFunction, customType, customLabel }) {
    const filterArray = [...filteredValues];
    if (sortBy) {
        filterArray.sort((a, b) => {
            return sortBy.findIndex((item) => item == a) - sortBy.findIndex((item) => item == b);
        });
    }
    else {
        filterArray.sort((a, b) => a - b);
    }
    if (customLabel) {
        return (_jsx(_Fragment, { children: filterArray.map((type) => (_jsx(React.Fragment, { children: customLabel(type) }, `customLabel-${type}`))) }));
    }
    if (filterArray.length > limit) {
        return (_jsx(_Fragment, { children: filterArray.map((type) => {
                type = customType ? customType(type) : type;
                return _jsx("div", { children: iconFunction(type) }, `${componentKey}-${type}`);
            }) }));
    }
    return (_jsx(_Fragment, { children: filterArray.map((type) => {
            type = customType ? customType(type) : type;
            return (_jsx(IconText, { icon: iconFunction(type), text: labelFunction(type) }, `${componentKey}-${type}`));
        }) }));
}
