var _a, _b, _c, _d;
import { BrowserProfilingIntegration, BrowserTracing, Replay } from '@sentry/react';
import config from '../../config/config.json';
const integrations = [new BrowserProfilingIntegration(), new BrowserTracing()];
if (config.sentry.isReplayActive) {
    integrations.push(new Replay());
}
const sentryConfig = {
    environment: config.sentry.environment,
    dsn: 'https://1f7551e6189b497b891908c4f497c633@o314647.ingest.sentry.io/4504599976869888',
    integrations: integrations,
    ignoreErrors: ['AbortError'],
    tracesSampleRate: (_a = config.sentry.tracesSampleRate) !== null && _a !== void 0 ? _a : 0,
    profilesSampleRate: (_b = config.sentry.profilesSampleRate) !== null && _b !== void 0 ? _b : 0,
    // Recommended for redux
    normalizeDepth: 10,
    replaysSessionSampleRate: (_c = config.sentry.replaysSessionSampleRate) !== null && _c !== void 0 ? _c : 0,
    replaysOnErrorSampleRate: (_d = config.sentry.replaysOnErrorSampleRate) !== null && _d !== void 0 ? _d : 0
};
const sentryReduxConfig = {
    // NOTE: Filter actions to not overload allowed envelope size
    actionTransformer: (action) => {
        // Exclude fulfilled actions as these are mostly network responses which sentry already captures separately
        // They also include various actions with large payloads which sentry doesn't allow
        if (action.type.includes('fulfilled')) {
            return null;
        }
        return action;
    },
    // NOTE: Filter state to not overload allowed envelope size
    stateTransformer: (state) => {
        var _a, _b, _c, _d, _e;
        return Object.assign(Object.assign({}, state), { auth: Object.assign(Object.assign({}, state.auth), { 
                // Exclude large cityList states from sentry
                cityList: null, cityListCount: state.auth.accountsAccessList.length }), 
            // Exclude large map states from sentry
            mainMap: {
                viewState: state.mainMap.viewState,
                center: state.mainMap.center,
                bounds: state.mainMap.bounds,
                style: state.mainMap.style,
                currentRightClickAction: state.mainMap.currentRightClickAction,
                rightClickCoordinates: state.mainMap.rightClickCoordinates
            }, leftPanelPlan: Object.assign(Object.assign({}, state.leftPanelPlan), { resultList: null, resultListCount: (_a = state.leftPanelPlan.resultList) === null || _a === void 0 ? void 0 : _a.length }), leftPanelCondition: Object.assign(Object.assign({}, state.leftPanelCondition), { points: {
                    resultList: [],
                    resultListCount: (_c = (_b = state.leftPanelCondition.points) === null || _b === void 0 ? void 0 : _b.resultList) === null || _c === void 0 ? void 0 : _c.length
                }, segments: {
                    resultList: [],
                    resultListCount: (_e = (_d = state.leftPanelCondition.segments) === null || _d === void 0 ? void 0 : _d.resultList) === null || _e === void 0 ? void 0 : _e.length
                } }) });
    }
};
export { sentryConfig as SENTRY_CONFIG, sentryReduxConfig as SENTRY_REDUX_CONFIG };
