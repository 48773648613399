import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './Marker.module.css';
export function Marker({ priority, title, titleInfo, status, category, assignee, dueDate, closedDate }) {
    let assigneesWrap;
    if (assignee && assignee.length > 1) {
        assigneesWrap = _jsxs("div", Object.assign({ className: styles['assignees-more'] }, { children: ["+", assignee.length - 1] }));
    }
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['line'] }, { children: [priority, _jsx("span", Object.assign({ className: styles['title'] }, { children: title })), _jsx("span", Object.assign({ className: styles['title-info'] }, { children: titleInfo }))] })), _jsxs("div", Object.assign({ className: styles['line'] }, { children: [closedDate, status, category, assignee && assignee.length > 0 && (_jsxs("div", Object.assign({ className: styles['assignees'] }, { children: [assignee && assignee[0], assigneesWrap] }))), dueDate] }))] })));
}
