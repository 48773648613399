// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JL54KwmSRRcux3i_YZaG{display:inline-flex;align-items:center;background:var(--primary-grey);border-radius:6px;height:24px}.XRUjNU_hdvVxkqT8TkPQ{display:flex;padding:4px;justify-content:center;align-items:center;gap:2px;color:var(--badge-grey-dark, #222d39)}.rZRqo6ewl2d6JFstMhea{color:var(--status-error, #f55)}.AOBMokXf3vXf6xubFhGf{text-align:center;font-weight:600}.sG91nJSFexsBMrPOKrxd{width:1px;align-self:stretch;background:var(--root-dark-blue-2, #d3d5d7)}.FERHTqRUacLNbzkrDPgH{width:24px;height:16px;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/components/Result/Marker/DueDate/DueDate.module.css"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,kBAAA,CACA,8BAAA,CACA,iBAAA,CACA,WAAA,CAGJ,sBACI,YAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,OAAA,CAEA,qCAAA,CAGJ,sBACI,+BAAA,CAGJ,sBACI,iBAAA,CAEA,eAAA,CAGJ,sBACI,SAAA,CACA,kBAAA,CACA,2CAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".container {\n    display: inline-flex;\n    align-items: center;\n    background: var(--primary-grey);\n    border-radius: 6px;\n    height: 24px;\n}\n\n.due-date {\n    display: flex;\n    padding: 4px;\n    justify-content: center;\n    align-items: center;\n    gap: 2px;\n\n    color: var(--badge-grey-dark, #222d39);\n}\n\n.due-date__overdue {\n    color: var(--status-error, #f55);\n}\n\n.text {\n    text-align: center;\n\n    font-weight: 600;\n}\n\n.vertical-line {\n    width: 1px;\n    align-self: stretch;\n    background: var(--root-dark-blue-2, #d3d5d7);\n}\n\n.recurring-icon {\n    width: 24px;\n    height: 16px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JL54KwmSRRcux3i_YZaG`,
	"due-date": `XRUjNU_hdvVxkqT8TkPQ`,
	"due-date__overdue": `rZRqo6ewl2d6JFstMhea`,
	"text": `AOBMokXf3vXf6xubFhGf`,
	"vertical-line": `sG91nJSFexsBMrPOKrxd`,
	"recurring-icon": `FERHTqRUacLNbzkrDPgH`
};
export default ___CSS_LOADER_EXPORT___;
