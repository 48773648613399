// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b9yki6LR3Gy68U2bgoKZ{font-weight:600;padding:6px;height:40px}.ENJr4AGcVDbsv9WNwIXQ{display:flex;align-items:center;gap:8px}.D48wVq4cpchYfPa75Fuw{text-decoration:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/uikit/Link/Link.module.css"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,WAAA,CACA,WAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CAGJ,sBACI,yBAAA,CACA,cAAA","sourcesContent":[".container__button {\n    font-weight: 600;\n    padding: 6px;\n    height: 40px;\n}\n\n.container {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.link {\n    text-decoration: underline;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container__button": `b9yki6LR3Gy68U2bgoKZ`,
	"container": `ENJr4AGcVDbsv9WNwIXQ`,
	"link": `D48wVq4cpchYfPa75Fuw`
};
export default ___CSS_LOADER_EXPORT___;
