import { createIconWithAttachment } from './createIconWithAttachment';
import { parseIconId } from './parseIconId';
const emptyIcon = {
    data: new Uint8Array(),
    width: 0,
    height: 0,
    render: () => false
};
export const createDynamicIcon = (id) => {
    const iconData = parseIconId(id);
    if (!iconData) {
        console.error('createDynamicIcon failed to parse icon data');
        return () => {
            return emptyIcon;
        };
    }
    switch (iconData.type) {
        case 'icon-with-attachment': {
            return createIconWithAttachment(iconData.base, iconData.attachment, iconData.attachmentBackground);
        }
        default:
            console.error('createDynamicIcon unknown icon type', id);
            return () => {
                return emptyIcon;
            };
    }
};
