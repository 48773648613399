var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { classes } from 'classifizer';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { FloatingTooltip } from '../FloatingTooltip/FloatingTooltip';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './TooltipWrapper.module.css';
export function TooltipWrapper(_a) {
    var _b;
    var { children, className, isAlwaysVisible, onClick, text, arrow, width } = _a, props = __rest(_a, ["children", "className", "isAlwaysVisible", "onClick", "text", "arrow", "width"]);
    const { isOpen, hide, show, buttonRef, position } = useFloatingDropdown({});
    const ref = useRef(null);
    useEffect(() => {
        if (isAlwaysVisible) {
            show();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonRef.current]);
    if (!text) {
        return children;
    }
    const topAlignCenterPosition = position.top + (((_b = ref.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().height) || 0) / 2;
    return (_jsxs("div", Object.assign({ className: classes(styles['wrapper'], onClick && styles['wrapper__interactive']), onClick: onClick, ref: ref, onMouseEnter: !isAlwaysVisible ? show : undefined, onMouseLeave: !isAlwaysVisible ? hide : undefined }, { children: [_jsx("div", { ref: buttonRef, className: classes(styles['anchor-tooltip'], styles['tooltip-wrapper'], styles['tooltip-wrapper__position-' + position], arrow && styles['tooltip-wrapper__arrow-' + arrow], isAlwaysVisible && styles['anchor-tooltip__fixed'], className) }), children, isOpen && (_jsx(FloatingTooltip, Object.assign({}, position, { top: arrow === 'left' || arrow === 'right'
                    ? topAlignCenterPosition
                    : position.top, align: arrow === 'right' ? 'right' : 'left', content: _jsx(Tooltip, Object.assign({ arrow: arrow, className: classes(styles['tooltip']), text: text, width: width }, props)) })))] })));
}
