var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Ditto } from 'ditto-react';
import { getFilterLabel, getFilterLabels } from '../../common/conditionFilterTypes';
import { MAP_VIA_LAT, MAP_VIA_LNG, MINIMAP_DEFAULT_ZOOM } from '../../common/consts/map';
import { PLAN_PRIORITY_LIMIT } from '../../common/consts/plan';
import { getPlanPriorityIcon, getPlanPriorityLabel, getPlanPriorityName, getPlanThirdPartyIcon, getPlanThirdPartyLabel } from '../../common/convert';
import { LAYER_PATH } from '../../common/layer';
import { PlanMinimapController, PLANS_SOURCE_ID, plansFillLayer, plansOutlineLayer } from '../../common/mapbox';
import { ROOT_PATH } from '../../common/url';
import { isDeepEqual, isObjectEmpty } from '../../common/utils/object';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { AddressForm } from '../../components/AddressForm/AddressForm';
import { DamageGradingPanel } from '../../components/DamageGradingPanel';
import { DetailsCallToAction } from '../../components/DetailsPanel/DetailsCallToAction/DetailsCallToAction';
import { DetailsClassification } from '../../components/DetailsPanel/DetailsClassification/DetailsClassification';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { DetailsHeader } from '../../components/DetailsPanel/DetailsHeader/DetailsHeader';
import { DetailsInfo } from '../../components/DetailsPanel/DetailsInfo/DetailsInfo';
import { DetailsPanel } from '../../components/DetailsPanel/DetailsPanel';
import { DropMenuWrapper } from '../../components/DropMenuWrapper/DropMenuWrapper';
import { TreatmentSelectField } from '../../components/EditorForms/TreatmentSelect/TreatmentSelectField/TreatmentSelectField';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { GradeBadge } from '../../components/GradeBadge/GradeBadge';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { Bin, Close, InProgress, Mail, OverflowDotsThreeVertical, Print, SegmentCustom, SizeEnlarge } from '../../components/icons';
import { Loader } from '../../components/Loader/Loader';
import { Minimap } from '../../components/Minimap/Minimap';
import { PlanCustomPropertiesPanel } from '../../components/PlanCustomPropertiesPanel/PlanCustomPropertiesPanel';
import { CategorySelectItem, CategorySelectItemCurrentSelectedValue } from '../../components/SegmentForm/CategorySelectItem/CategorySelectItem';
import { SegmentForm } from '../../components/SegmentForm/SegmentForm';
import { StatusSelectItem, StatusSelectItemCurrentSelectedValue } from '../../components/SegmentForm/StatusSelectItem/StatusSelectItem';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useUnitInputConverter } from '../../hooks/formatting/useUnitInputConverter';
import { usePlanMapData } from '../../hooks/mainMap';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMapInit } from '../../hooks/useMapInit';
import { getPlanPreview } from '../../services/plan';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearSelectedFeature } from '../../state/slices/feature';
import { fetchBudgetSettings } from '../../state/slices/globalSetting';
import { fetchBudgetsBreakdown, fetchTableViewSettings, setPlanActiveTabKey } from '../../state/slices/leftPanelPlan';
import { fetchPlan, removePlan, updatePlan } from '../../state/slices/plan';
import { setFormData, setValue } from '../../state/slices/segmentForm';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { TabBar } from '../../uikit/TabBar/TabBar';
export function PlanDetailContainer() {
    const { addModal } = useModalContext();
    const { locale } = useAppSelector((state) => state.app);
    const { targetSchema, teamAccount } = useAppSelector((state) => state.auth);
    const { currentPlan, fetchStatus, updateStatus } = useAppSelector((state) => state.plan);
    const { budgetAndNetwork: { budgets } } = useAppSelector((state) => state.globalSettings);
    const { form: formData } = useAppSelector((state) => state.segmentForm);
    const { activeTabKey, table: { settings: tableSettings } } = useAppSelector((state) => state.leftPanelPlan);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { planCategoryList, planStatusList, planTreatmentsList, planUnitTypes, conditionFilters: { types: { streetTypes, surfaceTypes, roadDamages, roadDamageGroups } } } = useAppSelector((state) => state.shared);
    const { planGeoJSON } = usePlanMapData();
    const { shouldHideSurfaceTypes } = useLayerChecker();
    const formatter = useFormatter();
    const unitFormatter = useUnitFormatter();
    const inputConverter = useUnitInputConverter();
    const dispatch = useAppDispatch();
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const [viewState, setViewState] = useState({
        longitude: MAP_VIA_LNG,
        latitude: MAP_VIA_LAT,
        zoom: MINIMAP_DEFAULT_ZOOM
    });
    const customProperties = useMemo(() => (tableSettings === null || tableSettings === void 0 ? void 0 : tableSettings.filter((column) => !column.isDefault)) || [], [tableSettings]);
    useEffect(() => {
        if (selectedFeature && selectedFeature.type === 'plan') {
            dispatch(fetchPlan(selectedFeature.id));
        }
        if (!tableSettings) {
            dispatch(fetchTableViewSettings());
        }
    }, [dispatch, selectedFeature, tableSettings]);
    useEffect(() => {
        const submitData = () => __awaiter(this, void 0, void 0, function* () {
            setShouldSubmit(false);
            if (!shouldSubmit ||
                !currentPlan ||
                !(formData === null || formData === void 0 ? void 0 : formData.planId) ||
                isDeepEqual(formData, currentPlan)) {
                return;
            }
            yield dispatch(updatePlan(Object.assign(Object.assign({ planId: currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.planId }, formData), { 
                // if user address is the same as current address or is empty, set it to undefined
                userModifiedAddressStart: formData.userModifiedAddressStart ===
                    formatter.formatAddress(currentPlan.addressStart)
                    ? undefined
                    : formData.userModifiedAddressStart || undefined, userModifiedAddressEnd: formData.userModifiedAddressEnd ===
                    formatter.formatAddress(currentPlan.addressEnd)
                    ? undefined
                    : formData.userModifiedAddressEnd || undefined })));
            // fetch if data which affects budget tooltip has changed
            if (currentPlan.costs !== formData.costs ||
                currentPlan.categoryId !== formData.categoryId ||
                currentPlan.statusId !== formData.statusId ||
                currentPlan.completionMonth !== formData.completionMonth ||
                currentPlan.completionYear !== formData.completionYear) {
                dispatch(fetchBudgetsBreakdown());
            }
            // fetch budget settings if year which hasn't been defined before is created
            if (!(budgets === null || budgets === void 0 ? void 0 : budgets.find((budget) => budget.year === formData.completionYear))) {
                dispatch(fetchBudgetSettings());
            }
        });
        submitData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSubmit]);
    useEffect(() => {
        if (currentPlan) {
            dispatch(setFormData(currentPlan));
        }
    }, [currentPlan, dispatch]);
    // Show auto update modal when width is changed
    useEffect(() => {
        if (fetchStatus === 'succeeded' && (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.width) !== (formData === null || formData === void 0 ? void 0 : formData.width)) {
            handleAutoUpdateModal({ yearsOfUse: false, width: false }, { width: formData === null || formData === void 0 ? void 0 : formData.width });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData === null || formData === void 0 ? void 0 : formData.width]);
    const [currentTab, setCurrentTab] = useState(0);
    const category = useMemo(() => {
        if (!(formData === null || formData === void 0 ? void 0 : formData.planId)) {
            return undefined;
        }
        return planCategoryList.find((category) => formData.categoryId === category.id);
    }, [formData, planCategoryList]);
    const status = useMemo(() => {
        if (!(formData === null || formData === void 0 ? void 0 : formData.planId)) {
            return undefined;
        }
        return planStatusList.find((status) => formData.statusId === status.id);
    }, [formData, planStatusList]);
    const handleMapInit = useMapInit();
    function handleDeleteButtonClick() {
        if (!currentPlan) {
            return;
        }
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(removePlan(currentPlan.planId));
                },
                title: _jsx(Ditto, { componentId: "promt.delete.plannedsegment" }),
                description: _jsx(Ditto, { componentId: "promt.delete.plannedsegment.text" })
            }
        });
    }
    function handleMailButtonClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!currentPlan) {
                return;
            }
            addModal({
                id: 'MailModal',
                props: {
                    featureList: {
                        type: 'plan',
                        value: [currentPlan.planId]
                    }
                }
            });
        });
    }
    function handlePrintButtonClick() {
        if (!selectedFeature) {
            return;
        }
        window.open(`/${ROOT_PATH.print}/${LAYER_PATH.plan}/${selectedFeature.id}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleCloseButtonClick() {
        dispatch(clearSelectedFeature());
    }
    function handleChange(field, value) {
        return __awaiter(this, void 0, void 0, function* () {
            yield dispatch(setValue({ field, value }));
        });
    }
    function handleCheckUpdatedDataChanges() {
        if (!(formData === null || formData === void 0 ? void 0 : formData.planId))
            return;
        handleAutoUpdateModal();
    }
    function handleDeclineUpdateDataChanges() {
        handleChange('autoUpdateAction', 'decline');
        handleSubmit();
    }
    function handleTreatmentSelectClick() {
        if (!(formData === null || formData === void 0 ? void 0 : formData.planId))
            return;
        return addModal({
            id: 'TreatmentSelectModalContainer',
            props: {
                value: formData === null || formData === void 0 ? void 0 : formData.treatmentId,
                onChange: (id) => {
                    handleChange('treatmentId', id);
                    handleAutoUpdateModal({ width: !formData.width }, { treatmentId: id });
                }
            }
        });
    }
    function handleEditTreatmentsClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-treatments'
            }
        });
    }
    function handlePlannedForClick() {
        addModal({
            id: 'MonthPickerModalContainer',
            props: {
                month: formData === null || formData === void 0 ? void 0 : formData.completionMonth,
                year: formData === null || formData === void 0 ? void 0 : formData.completionYear,
                onConfirm: (value) => {
                    handleChange('completionMonth', value ? value.startDate.getMonth() + 1 : undefined);
                    handleChange('completionYear', value ? value.startDate.getFullYear() : undefined);
                    handleSubmit();
                }
            }
        });
    }
    function handleEditPropertiesClick(propertyKey) {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-properties',
                modalProps: {
                    type: 'plan-properties',
                    selectedCustomPropertyKey: propertyKey
                }
            }
        });
    }
    function handleAutoUpdateModal(fields, newFormData) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!(formData === null || formData === void 0 ? void 0 : formData.planId) || !currentPlan)
                return;
            let magicInputCosts = formData.magicInputCosts || (formData.costs === undefined && true);
            let magicInputWidth = formData.magicInputWidth || (formData.width === undefined && true);
            let magicInputYearsOfUse = formData.magicInputYearsOfUse || (formData.yearsOfUse === undefined && true);
            if (!formData.treatmentId && !(newFormData === null || newFormData === void 0 ? void 0 : newFormData.width)) {
                magicInputWidth = true;
            }
            const { result: previewData } = yield getPlanPreview({
                autoUpdateCosts: currentPlan.autoUpdateCosts,
                autoUpdateYearsOfUse: currentPlan.autoUpdateYearsOfUse,
                autoUpdateWidth: currentPlan.autoUpdateWidth,
                countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode,
                treatmentId: newFormData && 'treatmentId' in newFormData
                    ? newFormData === null || newFormData === void 0 ? void 0 : newFormData.treatmentId
                    : formData.treatmentId,
                priority: (_a = newFormData === null || newFormData === void 0 ? void 0 : newFormData.priority) !== null && _a !== void 0 ? _a : formData.priority,
                width: (newFormData === null || newFormData === void 0 ? void 0 : newFormData.width) || formData.width || undefined,
                length: (_b = newFormData === null || newFormData === void 0 ? void 0 : newFormData.length) !== null && _b !== void 0 ? _b : formData.length,
                magicInputCosts,
                magicInputWidth,
                magicInputYearsOfUse
            });
            if (!previewData)
                return;
            if (previewData.efficiency !== undefined) {
                delete previewData.efficiency;
            }
            // Remove fields from preview respond that are not selected
            if (fields) {
                Object.keys(fields).forEach((field) => {
                    const f = field;
                    if (fields[f] === false) {
                        delete previewData[f];
                    }
                });
            }
            // Remove fields from preview respond that are not changed
            Object.entries(previewData).forEach(([key, value]) => {
                let hasSameValue = false;
                let prevValue = formData[key];
                if (key === 'costsNotSpecified' && value && formData.costs === undefined)
                    hasSameValue = true;
                else if (key === 'yearsOfUseNotSpecified' && value && formData.yearsOfUse === undefined)
                    hasSameValue = true;
                else if (key === 'width' && (prevValue === undefined || !formData.magicInputWidth))
                    prevValue = 0;
                else if (key === 'costs' && !formData.magicInputCosts && value === prevValue)
                    prevValue = 0;
                else if (key === 'yearsOfUse' && !formData.magicInputYearsOfUse && value === prevValue)
                    prevValue = 0;
                if (typeof value === 'number' && typeof prevValue === 'number') {
                    if (value.toFixed(2) === prevValue.toFixed(2)) {
                        hasSameValue = true;
                    }
                }
                if (hasSameValue)
                    delete previewData[key];
            });
            if (isObjectEmpty(previewData)) {
                if (newFormData)
                    handleSubmit();
                return;
            }
            addModal({
                id: 'AutoUpdateModalContainer',
                props: {
                    fields,
                    formData,
                    previewData,
                    onChange: (field, value) => {
                        handleChange(field, value);
                    },
                    onConfirm: () => {
                        handleChange('magicInputWidth', magicInputWidth);
                        handleSubmit();
                    },
                    onCancel: () => {
                        handleChange('autoUpdateAction', 'decline');
                        // set magicInputCosts flag to false if user had default value and doesn't accept new default value
                        if (magicInputCosts && previewData.costs) {
                            handleChange('magicInputCosts', false);
                        }
                        if (magicInputYearsOfUse && previewData.yearsOfUse) {
                            handleChange('magicInputYearsOfUse', false);
                        }
                        handleSubmit();
                    }
                }
            });
        });
    }
    const isSubmitDisabled = !(formData === null || formData === void 0 ? void 0 : formData.name) ||
        !(formData === null || formData === void 0 ? void 0 : formData.categoryId) ||
        !(formData === null || formData === void 0 ? void 0 : formData.statusId) ||
        updateStatus === 'loading';
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isSubmitDisabled) {
                return;
            }
            setShouldSubmit(true);
        });
    }
    if (fetchStatus === 'loading' || !(formData === null || formData === void 0 ? void 0 : formData.planId) || !targetSchema || !teamAccount) {
        return _jsx(DetailsPanel, { loader: _jsx(Loader, {}) });
    }
    // TODO: add 'error' variable to this condition
    if (!currentPlan) {
        return (_jsx(DetailsPanel, { children: _jsx(DetailsInfo, { linkText: _jsx(Ditto, { componentId: "close" }), linkOnClick: handleCloseButtonClick, content: _jsx(Ditto, { componentId: "somethingwentwrongtext" }) }) }));
    }
    function getFormContent() {
        return {
            name: (formData === null || formData === void 0 ? void 0 : formData.name) || '',
            treatment: (_jsx(TreatmentSelectField, { width: 164, options: planTreatmentsList, value: formData === null || formData === void 0 ? void 0 : formData.treatmentId, onClick: handleTreatmentSelectClick, "data-test": "segment-form-select-treatment", variant: "ghost" })),
            thirdParty: {
                current: formData && (_jsx(FormInputCurrentSelectedValue, { text: getPlanThirdPartyLabel(formData === null || formData === void 0 ? void 0 : formData.thirdParty), icon: getPlanThirdPartyIcon(formData === null || formData === void 0 ? void 0 : formData.thirdParty) })),
                items: [
                    _jsx(SelectboxEntry, { onClick: () => {
                            handleChange('thirdParty', true);
                            handleSubmit();
                        }, isChecked: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) === true, text: getPlanThirdPartyLabel(true), secondaryText: _jsx(Ditto, { componentId: "tooltip.thirdparty" }), leadingIcon: getPlanThirdPartyIcon(true), "data-test": "fixed-treatment-yes" }, "fixed-measure-yes"),
                    _jsx(SelectboxEntry, { onClick: () => {
                            handleChange('thirdParty', false);
                            handleSubmit();
                        }, isChecked: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) === false, text: getPlanThirdPartyLabel(false), secondaryText: _jsx(Ditto, { componentId: "plannedsegments.internallyinitiated" }), leadingIcon: getPlanThirdPartyIcon(false), "data-test": "fixed-treatment-no" }, "fixed-measure-no")
                ]
            },
            category: {
                current: (formData === null || formData === void 0 ? void 0 : formData.categoryId) && category ? (_jsx(CategorySelectItemCurrentSelectedValue, { category: {
                        categoryId: category.id,
                        categoryName: category.name
                    } })) : undefined,
                items: planCategoryList.map(({ id, name }) => (_jsx(CategorySelectItem, { category: {
                        categoryId: id,
                        categoryName: name
                    }, onClick: () => {
                        handleChange('categoryId', id);
                        handleSubmit();
                    } }, `category-${id}`)))
            },
            width: formData === null || formData === void 0 ? void 0 : formData.width,
            length: formData === null || formData === void 0 ? void 0 : formData.length,
            area: formData === null || formData === void 0 ? void 0 : formData.area,
            costs: formData === null || formData === void 0 ? void 0 : formData.costs,
            magicInputValueCosts: formData === null || formData === void 0 ? void 0 : formData.magicInputValueCosts,
            magicInputCosts: formData === null || formData === void 0 ? void 0 : formData.magicInputCosts,
            magicInputWidth: formData === null || formData === void 0 ? void 0 : formData.magicInputWidth,
            magicInputYearsOfUse: formData === null || formData === void 0 ? void 0 : formData.magicInputYearsOfUse,
            status: {
                current: (formData === null || formData === void 0 ? void 0 : formData.statusId) && status ? (_jsx(StatusSelectItemCurrentSelectedValue, { status: {
                        statusId: status.id,
                        statusName: status.name
                    } })) : undefined,
                items: planStatusList.map(({ id, name }) => (_jsx(StatusSelectItem, { status: {
                        statusId: id,
                        statusName: name
                    }, onClick: () => {
                        handleChange('statusId', id);
                        handleSubmit();
                    } }, `status-${id}`)))
            },
            priority: {
                current: formData ? (_jsx(FormInputCurrentSelectedValue, { icon: getPlanPriorityIcon(getPlanPriorityName(formData.priority || 0)), text: getPlanPriorityLabel(getPlanPriorityName(formData.priority || 0)) })) : undefined,
                items: [
                    ...[
                        ...Object.entries(PLAN_PRIORITY_LIMIT),
                        ...((formData === null || formData === void 0 ? void 0 : formData.priority) !== undefined &&
                            !Object.values(PLAN_PRIORITY_LIMIT).includes(formData.priority)
                            ? Object.entries({
                                [getPlanPriorityName(formData.priority)]: formData.priority
                            })
                            : [])
                    ]
                        .sort((a, b) => b[1] - a[1])
                        .map(([name, priorityValue]) => (_jsx(SelectboxEntry, { onClick: () => {
                            handleChange('priority', priorityValue);
                            handleSubmit();
                        }, isChecked: (formData === null || formData === void 0 ? void 0 : formData.priority) === priorityValue, text: getPlanPriorityLabel(name), leadingIcon: getPlanPriorityIcon(name), "data-test": `dropdown-priority-${priorityValue}` }, `priority-${priorityValue}`)))
                ]
            },
            completionMonth: formData === null || formData === void 0 ? void 0 : formData.completionMonth,
            completionYear: formData === null || formData === void 0 ? void 0 : formData.completionYear,
            yearsOfUse: formData === null || formData === void 0 ? void 0 : formData.yearsOfUse,
            lifeExtensionKm: formData === null || formData === void 0 ? void 0 : formData.lifeExtensionKm,
            note: (formData === null || formData === void 0 ? void 0 : formData.note) || '',
            factor: formData === null || formData === void 0 ? void 0 : formData.factor
        };
    }
    const formContent = getFormContent();
    const mapContent = (_jsxs(Minimap, Object.assign({}, viewState, { ref: handleMapInit, id: "MinimapPlanContainer", size: "large", interactive: true, maxZoom: 18, noOutline: true, control: _jsx(Button, { color: "dark", variant: "square", icon: _jsx(SizeEnlarge, {}), onClick: () => dispatch(setPlanActiveTabKey('list')) }), onMove: (event) => setViewState(event.viewState), variant: "plain" }, { children: [_jsxs(Source, Object.assign({ id: PLANS_SOURCE_ID, type: "geojson", data: planGeoJSON }, { children: [_jsx(Layer, Object.assign({}, plansOutlineLayer)), _jsx(Layer, Object.assign({}, plansFillLayer))] })), _jsx(PlanMinimapController, {})] })));
    const classification = (_jsxs(DetailsClassification, Object.assign({ style: "no-underline", variant: "ghost" }, { children: [_jsx(AddressForm, { formData: formData, onChange: handleChange, onSubmit: handleSubmit, width: 332, formatter: formatter, variant: "ghost" }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "streetclassification.classification" }), content: getFilterLabel(streetTypes, currentPlan.classificationTypes[0]) }), !shouldHideSurfaceTypes && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: getFilterLabels(surfaceTypes, currentPlan.surfaceTypes) }))] })));
    const grading = (_jsx("div", { children: _jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: currentPlan.grade, damageStats: currentPlan.damageStats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser }) }));
    const customPropertiesPanel = (_jsx(PlanCustomPropertiesPanel, { locale: locale, countryCode: teamAccount.countryCode, data: formData.customData, properties: customProperties, planUnitTypes: planUnitTypes, onEditPropertiesClick: handleEditPropertiesClick, onChange: handleChange, onSubmit: handleSubmit, unitFormatter: unitFormatter, padding: true, variant: "ghost" }));
    const tabs = [
        {
            label: _jsx(Ditto, { componentId: "details" }),
            content: (_jsxs(_Fragment, { children: [activeTabKey === 'table' && planGeoJSON && (_jsx("div", Object.assign({ style: { marginBottom: 12 } }, { children: mapContent }))), activeTabKey !== 'table' && (_jsx("div", { style: { marginTop: 12, width: '100%', height: 0 } })), _jsx(SegmentForm, { locale: locale, countryCode: teamAccount.countryCode, content: formContent, onChange: handleChange, onSubmit: handleSubmit, onEditTreatmentsClick: handleEditTreatmentsClick, onPlannedForClick: handlePlannedForClick, inputConverter: inputConverter, unitFormatter: unitFormatter, width: 332, variant: "details" }), _jsx(Divider, {}), classification, _jsx(Divider, {}), customPropertiesPanel] }))
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "filter.grading" }), _jsx(GradeBadge, { grade: currentPlan.grade, size: "notification" })] })),
            content: grading
        }
        // { label: <Ditto componentId="activity" /> } // TODO: uncomment when changelog is implemented
    ];
    const header = (_jsx(DetailsHeader, { buttonsRight: _jsxs(_Fragment, { children: [_jsx(DropMenuWrapper, { control: _jsx(Button, Object.assign({ trailingIcon: _jsx(OverflowDotsThreeVertical, {}), "data-test": "three-dot-menu", variant: "slender" }, { children: _jsx(Ditto, { componentId: "hamburgermenu.more" }) })), menu: _jsxs(ActionModal, Object.assign({ width: 263 }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.printdetails" }), leadingIcon: _jsx(Print, {}), onClick: handlePrintButtonClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.sendbymail" }), leadingIcon: _jsx(Mail, {}), onClick: handleMailButtonClick, "data-test": "btn-share-email" }), _jsx(Divider, {}), currentPlan.autoUpdateAvailable && (_jsxs(_Fragment, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "update" }), leadingIcon: _jsx(InProgress, {}), onClick: handleCheckUpdatedDataChanges, "data-test": "btn-plan-update" }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "plannedsegments.declineupdate" }), leadingIcon: _jsx(Close, {}), onClick: handleDeclineUpdateDataChanges, "data-test": "btn-plan-update" })] })), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "overflowmenue.delete" }), leadingIcon: _jsx(Bin, {}), onClick: handleDeleteButtonClick, color: "red", "data-test": "btn-plan-delete" })] })), direction: "down-left" }), _jsx(Button, Object.assign({ variant: "square", color: "gray-2", onClick: handleCloseButtonClick }, { children: _jsx(IconResizer, Object.assign({ size: 24 }, { children: _jsx(Close, {}) })) }))] }), title: currentPlan.name, titleIcon: _jsx(SegmentCustom, {}), label: _jsx(Ditto, { componentId: "rightpanel.titletype.plannedsegment" }), tabBar: _jsx(TabBar, { tabs: tabs, current: currentTab, setCurrent: setCurrentTab }), id: currentPlan.planId }));
    let callToAction;
    if (currentPlan.autoUpdateAvailable) {
        callToAction = (_jsx(DetailsCallToAction, { icon: _jsx(InProgress, {}), title: _jsx(Ditto, { componentId: "plannedsegments.autoupdate.updateavailable" }), content: _jsx(Ditto, { componentId: "plannedsegments.autoupdate.info" }), buttons: _jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleCheckUpdatedDataChanges }, { children: _jsx(Ditto, { componentId: "plannedsegments.autoupdate.checkchanges" }) })), color: "var(--secondary-blue)", backgroundColor: "var(--root-blue-1)" }));
    }
    const content = tabs[currentTab].content;
    return (_jsx(DetailsPanel, Object.assign({ header: header, loader: updateStatus === 'loading' && _jsx(Loader, {}), callToAction: callToAction }, { children: content })));
}
