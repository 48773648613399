// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg7V7x16oDFvOtzMMPcj{border:2px solid rgba(0,0,0,0);display:flex;justify-content:center;align-items:center;height:40px;font-style:normal;font-weight:600;font-size:14px;line-height:14px;border-radius:12px;padding:0 16px;background:rgba(0,0,0,0);color:var(--primary-dark-blue, #222d39)}.o0zS79i9zIgLf6ZD0mpR{background:var(--primary-dark-blue);color:var(--primary-white)}.y54k0D0sA6RPAUCprffo{color:var(--primary-white);text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/uikit/ButtonLink/ButtonLink.module.css"],"names":[],"mappings":"AAAA,sBACI,8BAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,WAAA,CAEA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,kBAAA,CAEA,cAAA,CACA,wBAAA,CACA,uCAAA,CAGJ,sBACI,mCAAA,CACA,0BAAA,CAGJ,sBACI,0BAAA,CACA,oBAAA","sourcesContent":[".container {\n    border: 2px solid transparent;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    height: 40px;\n\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 14px;\n\n    border-radius: 12px;\n\n    padding: 0 16px;\n    background: transparent;\n    color: var(--primary-dark-blue, #222d39);\n}\n\n.container__dark {\n    background: var(--primary-dark-blue);\n    color: var(--primary-white);\n}\n\n.link {\n    color: var(--primary-white);\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bg7V7x16oDFvOtzMMPcj`,
	"container__dark": `o0zS79i9zIgLf6ZD0mpR`,
	"link": `y54k0D0sA6RPAUCprffo`
};
export default ___CSS_LOADER_EXPORT___;
