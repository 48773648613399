// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._d_Rby3NAclg1w2yt67a{display:flex;overflow:hidden;width:100%;height:calc(100vh - 72px);border-radius:24px 24px 0 0;position:absolute;top:72px;left:0;box-shadow:var(--box-shadow-large)}.x3PGvj8whay9trH0Jntz{height:calc(100vh - 120px);top:120px}.T8F5l7xZ0dbX7cVWdGXS{flex:auto}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ImageViewerModal/ImageViewerModal.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,eAAA,CACA,UAAA,CACA,yBAAA,CACA,2BAAA,CACA,iBAAA,CACA,QAAA,CACA,MAAA,CACA,kCAAA,CAGJ,sBACI,0BAAA,CACA,SAAA,CAGJ,sBACI,SAAA","sourcesContent":[".container {\n    display: flex;\n    overflow: hidden;\n    width: 100%;\n    height: calc(100vh - 72px);\n    border-radius: 24px 24px 0 0;\n    position: absolute;\n    top: 72px;\n    left: 0;\n    box-shadow: var(--box-shadow-large);\n}\n\n.container--banner {\n    height: calc(100vh - 120px);\n    top: 120px;\n}\n\n.wrapper {\n    flex: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_d_Rby3NAclg1w2yt67a`,
	"container--banner": `x3PGvj8whay9trH0Jntz`,
	"wrapper": `T8F5l7xZ0dbX7cVWdGXS`
};
export default ___CSS_LOADER_EXPORT___;
