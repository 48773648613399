import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
import { REFERENCING_SOURCE_ID } from '../sources';
export const REFERENCING_FLAG_LAYER_ID = {
    default: `${LAYER_PREFIX}-referencing-flag`,
    hover: `${LAYER_PREFIX}-referencing-flag-hover`,
    highlight: `${LAYER_PREFIX}-referencing-flag-highlight`,
    selected: `${LAYER_PREFIX}-referencing-flag-selected`
};
export const REFERENCING_FLAG_IMAGE_NAME = {
    default: 'referencing-flag-default',
    hover: 'referencing-flag-hover'
};
const MIN_SCALE = 0.5;
const MAX_SCALE = 1.0;
const HOVER_MULTIPLIER = 1.0;
export const referencingFlagLayer = {
    id: REFERENCING_FLAG_LAYER_ID.default,
    type: 'symbol',
    source: REFERENCING_SOURCE_ID,
    layout: {
        'icon-image': 'referencing-flag-default',
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': false,
        'icon-anchor': 'center'
    }
};
export const referencingFlagHoveredLayer = {
    id: REFERENCING_FLAG_LAYER_ID.hover,
    type: 'symbol',
    layout: Object.assign(Object.assign({}, referencingFlagLayer.layout), { 'icon-image': 'referencing-flag-hover', 
        // NOTE: Combination of icon-allow-overlap and icon-ignore-placement is important for hover behavior
        'icon-allow-overlap': true, 'icon-ignore-placement': true, 'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE * HOVER_MULTIPLIER,
            MAX_ZOOM,
            MAX_SCALE * HOVER_MULTIPLIER
        ] })
};
