// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SbTjSbL7rtPZNxWZ23qj{display:flex;min-width:24px;min-height:24px;font-size:14px;line-height:14px;font-weight:700;justify-content:center;align-items:center;border-radius:4px;border-width:1px}.aal85AumKNuqRHrs1wwY{min-width:18px;min-height:18px}`, "",{"version":3,"sources":["webpack://./src/components/Vignette/Vignette.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,gBAAA,CAGJ,sBACI,cAAA,CACA,eAAA","sourcesContent":[".container {\n    display: flex;\n    min-width: 24px;\n    min-height: 24px;\n    font-size: 14px;\n    line-height: 14px;\n    font-weight: 700;\n    justify-content: center;\n    align-items: center;\n    border-radius: 4px;\n    border-width: 1px;\n}\n\n.container--small {\n    min-width: 18px;\n    min-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SbTjSbL7rtPZNxWZ23qj`,
	"container--small": `aal85AumKNuqRHrs1wwY`
};
export default ___CSS_LOADER_EXPORT___;
