// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CYZFl5S89dLig4V7C_xt{background-color:var(--primary-grey);width:100%;height:100%;display:flex;align-items:center;justify-content:center;color:var(--utility-dark-blue-60-alpha);border-radius:4px;padding:8px;box-shadow:inset 0 0 0 1px rgba(0,0,0,.1)}.xfuN6VkaztoZf3UObhKq{display:flex;flex-direction:column;align-items:center;justify-content:center;gap:4px}.N8o63HFjyfB6aVJywJUE{font-size:12px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/uikit/ImagePlaceholder/ImagePlaceholder.module.css"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,uCAAA,CACA,iBAAA,CACA,WAAA,CACA,yCAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CAGJ,sBACI,cAAA,CACA,iBAAA","sourcesContent":[".container {\n    background-color: var(--primary-grey);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--utility-dark-blue-60-alpha);\n    border-radius: 4px;\n    padding: 8px;\n    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 4px;\n}\n\n.text {\n    font-size: 12px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CYZFl5S89dLig4V7C_xt`,
	"content": `xfuN6VkaztoZf3UObhKq`,
	"text": `N8o63HFjyfB6aVJywJUE`
};
export default ___CSS_LOADER_EXPORT___;
