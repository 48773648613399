import { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
export function useMapLoading() {
    const { main: map } = useMap();
    const [isMapInited, setIsMapInited] = useState(false);
    const [isMapLoading, setIsMapLoading] = useState(true);
    function onInit() {
        setIsMapInited(true);
    }
    function onSourceDataLoading() {
        if (isMapInited) {
            setIsMapLoading(true);
        }
    }
    function onIdle() {
        setIsMapLoading(false);
    }
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('load', onInit);
        map.on('sourcedataloading', onSourceDataLoading);
        map.on('idle', onIdle);
        return () => {
            map.off('load', onInit);
            map.off('sourcedataloading', onSourceDataLoading);
            map.off('idle', onIdle);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);
    return {
        isMapLoading
    };
}
