import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { Button } from '../Button/Button';
import styles from './Stepper.module.css';
export function Stepper({ width, current = 0, steps = 0, onCancel, onBack, onNext, onCreate, createLabel, createColor, nextColor, disabled }) {
    const stepMarkers = [];
    for (let i = 0; i < steps; i++) {
        stepMarkers.push(_jsx("div", { className: classes(styles['step-marker'], current === i && styles['step-marker__current']) }, i));
    }
    const hasSteps = steps > 1;
    const isFirstStep = !hasSteps || current === 0;
    const isLastStep = !hasSteps || current === steps - 1;
    return (_jsxs("div", Object.assign({ className: styles['container'], style: { width } }, { children: [hasSteps && _jsx("div", Object.assign({ className: styles['step-row'] }, { children: stepMarkers })), _jsxs("div", Object.assign({ className: styles['button-row'] }, { children: [isFirstStep ? (_jsx(Button, Object.assign({ variant: "fullwidth", size: "medium", color: "gray-2", onClick: () => onCancel(), disabled: disabled }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) }))) : (_jsx(Button, Object.assign({ variant: "fullwidth", size: "medium", color: "gray-2", onClick: () => onBack === null || onBack === void 0 ? void 0 : onBack(), disabled: disabled }, { children: _jsx(Ditto, { componentId: "modal.back" }) }))), isLastStep ? (_jsx(Button, Object.assign({ variant: "fullwidth", size: "medium", color: createColor, onClick: () => onCreate(), disabled: disabled }, { children: createLabel }))) : (_jsx(Button, Object.assign({ variant: "fullwidth", size: "medium", color: nextColor, onClick: () => onNext === null || onNext === void 0 ? void 0 : onNext(), disabled: disabled }, { children: _jsx(Ditto, { componentId: "tooltip.next" }) })))] }))] })));
}
