import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { Link } from '../../../uikit/Link/Link';
import { CategoryItem } from '../../EditorForms/CategoryItem/CategoryItem';
import { CategorySelect } from '../../EditorForms/CategorySelect/CategorySelect';
import { Close, Plus } from '../../icons';
import { Modal } from '../../Modal/Modal';
import { SelectboxEntry } from '../../SelectboxEntry/SelectboxEntry';
import styles from './CategorySelectModal.module.css';
export function CategorySelectModal({ options, onSelect, onCancel, onClose, onCustomizeClick, onAddNewCategoryClick }) {
    const [searchValue, setSearchValue] = useState('');
    function sortOptions(a, b) {
        return (a.categoryName.indexOf(searchValue.toLowerCase()) -
            b.categoryName.indexOf(searchValue.toLowerCase()));
    }
    const filteredItems = searchValue
        ? options
            .filter((item) => item.categoryName.toLowerCase().includes(searchValue.toLowerCase()))
            .sort(sortOptions)
        : options;
    const categoryItems = filteredItems.map((item) => (_jsx(CategoryItem, { category: item, onClick: () => {
            onSelect(item.categoryId);
            handleCloseClick();
        } }, item.categoryId)));
    categoryItems.push(_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "notspecified" }), onClick: () => {
            onSelect();
            handleCloseClick();
        } }, "not-specified"));
    categoryItems.push(_jsx(SelectboxEntry, { leadingIcon: _jsx(Plus, {}), withUnderline: true, text: _jsx(Ditto, { componentId: "tasks.addnewcategory" }), onClick: () => onAddNewCategoryClick() }, "add-new-category"));
    const categorySelect = (_jsx(CategorySelect, { items: categoryItems, searchValue: searchValue, setSearchValue: setSearchValue }));
    function handleCustomizeClick() {
        onCustomizeClick();
    }
    function handleCancelClick() {
        onCancel();
    }
    function handleCloseClick() {
        onClose();
    }
    return (_jsx(Modal, { width: 480, title: _jsx(Ditto, { componentId: "marker.category" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleCloseClick }), content: categorySelect, footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['footer-divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx(Link, Object.assign({ variant: 'button', onClick: handleCustomizeClick }, { children: _jsx(Ditto, { componentId: "tasks.editcategories" }) })), _jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleCancelClick }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) }))] }))] })), onClickOutside: handleCloseClick }));
}
