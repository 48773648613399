import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Ditto } from 'ditto-react';
import { addAssetType, addAttribute, addSelectOption } from '../../../../common/convert/asset';
import { move, remove, replacePartial } from '../../../../common/utils/array';
import { Button } from '../../../../uikit/Button/Button';
import { Assets, Close, Plus } from '../../../icons';
import { InfoBox } from '../../../InfoBox/InfoBox';
import { SettingPanelLayout } from '../SettingPanelLayout/SettingPanelLayout';
import { AssetTypeRow } from './AssetTypes/AssetTypeRow';
import styles from './AssetTypesSettingPanel.module.css';
const SCROLL_INTO_VIEW_DELAY = 500;
export function AssetTypesSettingPanel({ onSaveClick, onCancelClick, onAssetTypeDeleteClick, onAttributeDeleteClick, onSelectOptionDeleteClick, assetTypeList: list, setAssetTypeList: setList, unitList, scrollToAddNewType, scrollToSelectedType, isReadOnly }) {
    const refBottomAnchor = useRef(null);
    const currentTypeRef = useRef(null);
    useEffect(() => {
        if (scrollToAddNewType) {
            handleAssetTypeCreate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToAddNewType]);
    useEffect(() => {
        if (!scrollToSelectedType) {
            return;
        }
        const timeout = setTimeout(() => {
            if (currentTypeRef.current) {
                currentTypeRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, SCROLL_INTO_VIEW_DELAY);
        return () => {
            clearTimeout(timeout);
        };
    }, [scrollToSelectedType]);
    function scrollToBottom() {
        var _a;
        (_a = refBottomAnchor.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    }
    function setAttributes(asIdx, attributes) {
        setList(replacePartial(list, asIdx, { attributes }));
    }
    function setSelectOptions(asIdx, atIdx, selectOptions) {
        setAttributes(asIdx, replacePartial(list[asIdx].attributes, atIdx, { selectOptions }));
    }
    function handleAssetTypeDelete(asIdx) {
        const assetTypeId = list[asIdx].id;
        // extra delete confirmation
        if (assetTypeId) {
            onAssetTypeDeleteClick(assetTypeId);
            return;
        }
        setList(remove(list, asIdx));
    }
    function handleAssetTypeChange(asIdx, value) {
        setList(replacePartial(list, asIdx, { name: value }));
    }
    function handleAssetTypeCreate() {
        scrollToBottom();
        setTimeout(() => {
            setList(addAssetType(list, ''));
        }, SCROLL_INTO_VIEW_DELAY);
    }
    function handleAssetTypeIconChange(asIdx, value) {
        setList(replacePartial(list, asIdx, { iconId: value }));
    }
    function handleAttributeDelete(asIdx, atIdx) {
        const assetTypeId = list[asIdx].id;
        const attributeId = list[asIdx].attributes[atIdx].id;
        // extra delete confirmation
        if (assetTypeId && attributeId) {
            onAttributeDeleteClick(attributeId, assetTypeId);
            return;
        }
        setAttributes(asIdx, remove(list[asIdx].attributes, atIdx));
    }
    function handleAttributeChange(asIdx, atIdx, value) {
        setAttributes(asIdx, replacePartial(list[asIdx].attributes, atIdx, { name: value }));
    }
    function handleUnitChange(asIdx, atIdx, value) {
        setAttributes(asIdx, replacePartial(list[asIdx].attributes, atIdx, { unitId: value }));
    }
    function handleAttributeCreate(asIdx, type) {
        setAttributes(asIdx, addAttribute(list[asIdx], type, ''));
    }
    function handleOptionDelete(asIdx, atIdx, slIdx) {
        var _a;
        const assetTypeId = list[asIdx].id;
        const attributeId = list[asIdx].attributes[atIdx].id;
        const selectOptionId = (_a = list[asIdx].attributes[atIdx].selectOptions) === null || _a === void 0 ? void 0 : _a[slIdx].id;
        // extra delete confirmation
        if (assetTypeId && attributeId && selectOptionId) {
            onSelectOptionDeleteClick(selectOptionId, assetTypeId, attributeId);
            return;
        }
        setSelectOptions(asIdx, atIdx, remove(list[asIdx].attributes[atIdx].selectOptions, slIdx));
    }
    function handleOptionChange(asIdx, atIdx, slIdx, value) {
        setSelectOptions(asIdx, atIdx, replacePartial(list[asIdx].attributes[atIdx].selectOptions, slIdx, { name: value }));
    }
    function handleOptionCreate(asIdx, atIdx) {
        setSelectOptions(asIdx, atIdx, addSelectOption(list[asIdx].attributes[atIdx].selectOptions, ''));
    }
    const handleAssetTypeDragEnd = ({ destination, source }) => {
        if (!destination) {
            return;
        }
        setList(move(list, source.index, destination.index));
    };
    const handleAttributeDragEnd = (asIdx, { destination, source }) => {
        if (!destination) {
            return;
        }
        setAttributes(asIdx, move(list[asIdx].attributes, source.index, destination.index));
    };
    const handleOptionDragEnd = (asIdx, atIdx, { destination, source }) => {
        if (!destination) {
            return;
        }
        setSelectOptions(asIdx, atIdx, move(list[asIdx].attributes[atIdx].selectOptions, source.index, destination.index));
    };
    const context = {
        isReadOnly: isReadOnly,
        scrollToSelectedType: scrollToSelectedType,
        unitList: unitList,
        onAttributeDragEnd: handleAttributeDragEnd,
        onOptionDragEnd: handleOptionDragEnd,
        onAssetTypeDelete: handleAssetTypeDelete,
        onAssetTypeChange: handleAssetTypeChange,
        onAssetTypeIconChange: handleAssetTypeIconChange,
        onAttributeCreate: handleAttributeCreate,
        onAttributeChange: handleAttributeChange,
        onAttributeDelete: handleAttributeDelete,
        onUnitChange: handleUnitChange,
        onOptionDelete: handleOptionDelete,
        onOptionCreate: handleOptionCreate,
        onOptionChange: handleOptionChange
    };
    return (_jsxs(SettingPanelLayout, Object.assign({ overtitle: _jsx(Ditto, { componentId: "assets" }), title: _jsx(Ditto, { componentId: "assets.assettypes" }), icon: _jsx(Assets, {}), closeButton: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), color: "gray", onClick: () => onCancelClick && onCancelClick(list) }), bottomLeftButton: _jsx(Button, Object.assign({ variant: "modal-action", color: "gray", icon: _jsx(Plus, {}), onClick: handleAssetTypeCreate, disabled: isReadOnly }, { children: _jsx(Ditto, { componentId: "assets.addassettype" }) })), bottomRightButton: _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => onSaveClick && onSaveClick(list), disabled: isReadOnly }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }, { children: [isReadOnly && (_jsx(InfoBox, { className: styles['info-box'], content: _jsx(Ditto, { componentId: "settings.onlyadminscaneditthefollowingelements" }) })), _jsx(DragDropContext, Object.assign({ onDragEnd: handleAssetTypeDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "droppableAssets", isDropDisabled: isReadOnly }, { children: (provided) => (_jsxs("div", Object.assign({ ref: provided.innerRef, className: styles['content'] }, provided.droppableProps, { children: [list.map((assetType, asIdx) => (_jsx(AssetTypeRow, { index: asIdx, assetType: assetType, currentTypeRef: currentTypeRef, context: context }, assetType.id || `new-${asIdx}`))), provided.placeholder] }))) })) })), _jsx("div", { ref: refBottomAnchor, className: styles['content-bottom-anchor'] })] })));
}
