import { MARKER_PRIORITY } from '../../consts/marker';
import { DEFAULT_ZOOM, LAYER_PREFIX, MAX_ZOOM } from '../settings';
export const MARKERS_LAYER_ID = {
    default: `${LAYER_PREFIX}-markers`,
    hover: `${LAYER_PREFIX}-markers-hover`,
    highlight: `${LAYER_PREFIX}-markers-highlight`,
    selected: `${LAYER_PREFIX}-markers-selected`
};
export const MARKER_PROPERTY_ICON_ID = 'icon';
const MIN_SCALE = 0.4;
const MAX_SCALE = 0.7;
const HOVER_MULTIPLIER = 1.2;
export const redPoiFilter = ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-red'];
export const yellowPoiFilter = ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-yellow'];
export const greenPoiFilter = ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-green'];
export const greyPoiFilter = ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-grey'];
export const markersSourceOption = {
    cluster: true,
    clusterRadius: 60,
    clusterMaxZoom: 14,
    clusterMinPoints: 5,
    clusterProperties: {
        red: ['+', ['case', redPoiFilter, 1, 0]],
        yellow: ['+', ['case', yellowPoiFilter, 1, 0]],
        green: ['+', ['case', greenPoiFilter, 1, 0]],
        grey: ['+', ['case', greyPoiFilter, 1, 0]]
    }
};
const getMarkerIconExpression = (state) => {
    return [
        'case',
        ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-red'],
        `marker-high-${state}`,
        ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-yellow'],
        `marker-medium-${state}`,
        ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-green'],
        `marker-low-${state}`,
        ['==', ['get', MARKER_PROPERTY_ICON_ID], 'poi-grey'],
        `marker-noPriority-${state}`,
        'marker-noPriority-default'
    ];
};
export const MARKER_IMAGE_NAMES = ['default', 'hover', 'highlight', 'selected'].reduce((acc, state) => {
    Object.values(MARKER_PRIORITY).forEach((priority) => acc.push(`marker-${priority}-${state}`));
    return acc;
}, []);
export const markersLayer = {
    id: MARKERS_LAYER_ID.default,
    type: 'symbol',
    filter: ['!=', 'cluster', true],
    layout: {
        'icon-image': getMarkerIconExpression('default'),
        'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE,
            MAX_ZOOM,
            MAX_SCALE
        ],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-anchor': 'bottom'
    },
    paint: {
        'icon-opacity': ['case', ['boolean', ['feature-state', 'hidden'], false], 0, 1]
    }
};
export const markersHoveredLayer = {
    id: MARKERS_LAYER_ID.hover,
    type: 'symbol',
    filter: ['!=', 'cluster', true],
    layout: Object.assign(Object.assign({}, markersLayer.layout), { 'icon-image': getMarkerIconExpression('hover'), 'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            DEFAULT_ZOOM,
            MIN_SCALE * HOVER_MULTIPLIER,
            MAX_ZOOM,
            MAX_SCALE * HOVER_MULTIPLIER
        ] }),
    paint: {
        'icon-opacity': ['case', ['boolean', ['feature-state', 'hidden'], false], 0, 1]
    }
};
export const markersHighlightedLayer = {
    id: MARKERS_LAYER_ID.highlight,
    type: 'symbol',
    filter: ['!=', 'cluster', true],
    layout: Object.assign(Object.assign({}, markersHoveredLayer.layout), { 'icon-image': getMarkerIconExpression('highlight') }),
    paint: {
        'icon-opacity': ['case', ['boolean', ['feature-state', 'hidden'], false], 0, 1]
    }
};
export const markersSelectedLayer = {
    id: MARKERS_LAYER_ID.selected,
    type: 'symbol',
    filter: ['!=', 'cluster', true],
    layout: Object.assign(Object.assign({}, markersHoveredLayer.layout), { 'icon-image': getMarkerIconExpression('selected') }),
    paint: {
        'icon-opacity': ['case', ['boolean', ['feature-state', 'hidden'], false], 0, 1]
    }
};
