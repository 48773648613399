// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZpNB_7TKYwUcLtonw7ic{display:flex;align-items:center}.ZpNB_7TKYwUcLtonw7ic>*:not(:last-child){margin-right:4px}.AermaGlW0NOXiB5akCww{top:0 !important;transform:translate(0px, -60px)}.Hrles5IrjcJKKLSM2HrL{transform:translate(22px, -60px)}`, "",{"version":3,"sources":["webpack://./src/components/Actionbar/SegmentCreator/SegmentCreator.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CAGJ,yCACI,gBAAA,CAGJ,sBACI,gBAAA,CACA,+BAAA,CAGJ,sBACI,gCAAA","sourcesContent":[".button-content {\n    display: flex;\n    align-items: center;\n}\n\n.button-content > *:not(:last-child) {\n    margin-right: 4px;\n}\n\n.tooltip-segment-creator {\n    top: 0 !important;\n    transform: translate(0px, -60px);\n}\n\n.tooltip-segment-creator--with-button {\n    transform: translate(22px, -60px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-content": `ZpNB_7TKYwUcLtonw7ic`,
	"tooltip-segment-creator": `AermaGlW0NOXiB5akCww`,
	"tooltip-segment-creator--with-button": `Hrles5IrjcJKKLSM2HrL`
};
export default ___CSS_LOADER_EXPORT___;
