import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SaveModal } from '../../components/Modals/SaveModal/SaveModal';
import { useModalContext } from '../../state/context';
export function SaveModalContainer({ onConfirm, onCancel, description, title, yesLabel, yesColor }) {
    const { removeModal } = useModalContext();
    function closeModal() {
        removeModal('SaveModal');
    }
    function handleYesClick() {
        closeModal();
        onConfirm();
    }
    function handleNoClick() {
        closeModal();
        onCancel && onCancel();
    }
    return (_jsx(SaveModal, { title: title, description: description, yesLabel: yesLabel, yesColor: yesColor, onYesClick: handleYesClick, onNoClick: handleNoClick }));
}
