// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cUKfwc0UfjRijCcY37ng{height:100%;width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:14px;text-align:center;gap:4px}.cMYIQKJMcsMbq6ri3E9G{background-color:var(--primary-grey);color:var(--primary-dark-blue, #222d39)}.jAnZV4qyGyxFqWlcLNyv{background-color:var(--primary-dark-blue, #222d39);color:var(--primary-white, #ffffff)}`, "",{"version":3,"sources":["webpack://./src/uikit/ImageInProgress/ImageInProgress.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,iBAAA,CACA,OAAA,CAGJ,sBACI,oCAAA,CACA,uCAAA,CAGJ,sBACI,kDAAA,CACA,mCAAA","sourcesContent":[".container {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: 14px;\n    text-align: center;\n    gap: 4px;\n}\n\n.container__default {\n    background-color: var(--primary-grey);\n    color: var(--primary-dark-blue, #222d39);\n}\n\n.container__dark {\n    background-color: var(--primary-dark-blue, #222d39);\n    color: var(--primary-white, #ffffff);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cUKfwc0UfjRijCcY37ng`,
	"container__default": `cMYIQKJMcsMbq6ri3E9G`,
	"container__dark": `jAnZV4qyGyxFqWlcLNyv`
};
export default ___CSS_LOADER_EXPORT___;
