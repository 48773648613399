import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useAppSelector } from '../../../state/hooks';
import { toFeatureIdentifier } from '../convert';
import { safeRemoveFeatureState, safeSetFeatureState } from '../features';
import { highlightState, hoverState, selectedState } from '../featureStates';
import { DEFAULT_SOURCE_LAYER } from '../settings';
import { ALERTS_SOURCE_ID, ASSETS_SOURCE_ID, NOTE_SOURCE_ID, OBJECTS_SOURCE_ID, PLANS_SOURCE_ID, POINTS_SOURCE_ID, REFERENCING_SOURCE_ID, SEGMENTS_SOURCE_ID, TRACK_CONTROL_SOURCE_ID, TRAFFIC_SIGNS_SOURCE_ID, WINTER_SERVICE_SOURCE_ID } from '../sources';
/**
 * update map feature state base on redux
 */
export function MapFeatureStateController() {
    const { current: map } = useMap();
    const { selectedFeature, highlightedFeature, hoveredFeature, multiselectedFeatures } = useAppSelector((state) => state.feature);
    const isStyleLoaded = map === null || map === void 0 ? void 0 : map.isStyleLoaded();
    useEffect(() => {
        if (map === undefined || !isStyleLoaded) {
            return;
        }
        safeRemoveFeatureState(map, { source: PLANS_SOURCE_ID });
        safeRemoveFeatureState(map, {
            source: POINTS_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, {
            source: REFERENCING_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, {
            source: SEGMENTS_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, { source: ALERTS_SOURCE_ID });
        safeRemoveFeatureState(map, {
            source: OBJECTS_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, {
            source: TRACK_CONTROL_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, {
            source: NOTE_SOURCE_ID
        });
        safeRemoveFeatureState(map, {
            source: TRAFFIC_SIGNS_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, {
            source: WINTER_SERVICE_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        safeRemoveFeatureState(map, {
            source: ASSETS_SOURCE_ID,
            sourceLayer: DEFAULT_SOURCE_LAYER
        });
        if (hoveredFeature) {
            const featureIdentifier = toFeatureIdentifier(hoveredFeature.feature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, hoverState);
            }
        }
        if (highlightedFeature) {
            const featureIdentifier = toFeatureIdentifier(highlightedFeature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, highlightState);
            }
        }
        if (selectedFeature) {
            const featureIdentifier = toFeatureIdentifier(selectedFeature);
            if (featureIdentifier) {
                safeSetFeatureState(map, featureIdentifier, selectedState);
            }
        }
        if (multiselectedFeatures) {
            multiselectedFeatures.forEach((feature) => {
                const featureIdentifier = toFeatureIdentifier(feature);
                if (featureIdentifier) {
                    safeSetFeatureState(map, featureIdentifier, selectedState);
                }
            });
        }
    }, [
        selectedFeature,
        map,
        highlightedFeature,
        hoveredFeature,
        isStyleLoaded,
        multiselectedFeatures
    ]);
    return _jsx(_Fragment, {});
}
