import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { AnimatePresence } from 'framer-motion';
import { useFloatingDropdown } from '../../hooks/forms/useFloatingDropdown';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { Divider } from '../../uikit/Divider/Divider';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { ActionButton } from '../ActionButton/ActionButton';
import { ActionModal } from '../ActionModal/ActionModal';
import { FloatingDropdown } from '../FloatingDropdown/FloatingDropdown';
import { IconResizer } from '../IconResizer/IconResizer';
import { Check, Close, Search, Sort } from '../icons';
import { SelectboxEntry } from '../SelectboxEntry/SelectboxEntry';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import styles from './LeftPanelListHeader.module.css';
const DROP_MENU_MAX_HEIGHT = 250;
export function LeftPanelListHeader({ onFilterCurrentMapViewClick, onSelectAllClick, filterUseMapBounds, selectedCount, resultsCount, sort, search, tabs }) {
    var _a;
    const refSearchInput = useRef(null);
    const searchPlaceholder = useDittoWrapper({ componentId: 'search.searchfor' });
    const sortingModal = useFloatingDropdown({});
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [isFilterUseMapBoundsHover, setIsFilterUseMapBoundsHover] = useState(false);
    const [isFilterSelectAllHover, setIsFilterSelectAllHover] = useState(false);
    function handleSort(id) {
        var _a;
        (_a = sort.onSort) === null || _a === void 0 ? void 0 : _a.call(sort, id);
        sortingModal.hide();
    }
    function handleFilterUseMapBoundsMouseEnter() {
        setIsFilterUseMapBoundsHover(true);
    }
    function handleFilterUseMapBoundsMouseLeave() {
        setIsFilterUseMapBoundsHover(false);
    }
    function handleFilterSelectAllMouseEnter() {
        setIsFilterSelectAllHover(true);
    }
    function handleFilterSelectAllMouseLeave() {
        setIsFilterSelectAllHover(false);
    }
    function handleFilterUseMapBounds() {
        onFilterCurrentMapViewClick();
    }
    function handleSelectAllClick() {
        onSelectAllClick === null || onSelectAllClick === void 0 ? void 0 : onSelectAllClick();
    }
    function handleSelectAllFromSearchClick() {
        var _a;
        onSelectAllClick === null || onSelectAllClick === void 0 ? void 0 : onSelectAllClick();
        (_a = refSearchInput.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
    function handleSearchClick() {
        if (isSearchMode) {
            search === null || search === void 0 ? void 0 : search.onChange();
        }
        setIsSearchMode(!isSearchMode);
    }
    function handleSearchInput(event) {
        search === null || search === void 0 ? void 0 : search.onChange(event.target.value);
    }
    useEffect(() => {
        const listener = (event) => {
            if (isSearchMode && event.key === 'Escape') {
                setIsSearchMode(false);
            }
        };
        const ref = refSearchInput.current;
        ref === null || ref === void 0 ? void 0 : ref.addEventListener('keydown', listener, { passive: true });
        return () => {
            ref === null || ref === void 0 ? void 0 : ref.removeEventListener('keydown', listener);
        };
    }, [isSearchMode]);
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['row'] }, { children: [_jsx("div", Object.assign({ className: styles['row-lead'], onMouseEnter: handleFilterUseMapBoundsMouseEnter, onMouseLeave: handleFilterUseMapBoundsMouseLeave }, { children: _jsx(Checkbox, { color: "black", onClick: handleFilterUseMapBounds, isChecked: filterUseMapBounds, isHovering: isFilterUseMapBoundsHover }) })), _jsx("label", Object.assign({ className: styles['row-title'], onClick: handleFilterUseMapBounds, onMouseEnter: handleFilterUseMapBoundsMouseEnter, onMouseLeave: handleFilterUseMapBoundsMouseLeave, "data-test": "current-map-result-checkbox" }, { children: _jsx(Ditto, { componentId: "listpanel.resultsfromcurrentmapviewcheckbox" }) }))] })), tabs, !tabs && _jsx("div", { className: styles['gap'] }), _jsxs("div", Object.assign({ className: styles['row'] }, { children: [isSearchMode && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles['row-lead-search'] }, { children: _jsx(IconResizer, Object.assign({ size: 18, resizeContainer: true }, { children: _jsx(Search, {}) })) })), _jsx("input", { ref: refSearchInput, className: styles['row-input'], placeholder: searchPlaceholder, value: (search === null || search === void 0 ? void 0 : search.value) || '', onChange: handleSearchInput, type: "text", autoFocus: true, "data-test": "search-input" }), _jsxs("div", Object.assign({ className: styles['row-button-group'] }, { children: [!!onSelectAllClick &&
                                        typeof selectedCount !== 'undefined' &&
                                        resultsCount > 0 && (_jsx(TooltipWrapper, Object.assign({ text: _jsx(Ditto, { componentId: "listpanel.multiselect.selectfromactivepagecheckbox" }), position: "center", className: styles['row-select-results-tooltip'] }, { children: _jsxs("div", Object.assign({ className: styles['row-select-results'], onMouseEnter: handleFilterSelectAllMouseEnter, onMouseLeave: handleFilterSelectAllMouseLeave, onClick: handleSelectAllFromSearchClick }, { children: [_jsx(Checkbox, { color: "dark", isChecked: (selectedCount > 0 &&
                                                        selectedCount === resultsCount) ||
                                                        (selectedCount > 0 &&
                                                            selectedCount !== resultsCount), isIndeterminate: selectedCount > 0 &&
                                                        selectedCount !== resultsCount, isHovering: isFilterSelectAllHover }), _jsx("div", Object.assign({ className: styles['row-select-results-count'] }, { children: selectedCount === 0
                                                        ? resultsCount
                                                        : Math.min(selectedCount, resultsCount) }))] })) }))), _jsx(ActionButton, { icon: _jsx(Close, {}), onClick: handleSearchClick, "data-test": "close-search" })] }))] })), !isSearchMode && (_jsxs(_Fragment, { children: [!!onSelectAllClick && typeof selectedCount !== 'undefined' && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles['row-lead'], onMouseEnter: handleFilterSelectAllMouseEnter, onMouseLeave: handleFilterSelectAllMouseLeave }, { children: _jsx(Checkbox, { color: "black", onClick: handleSelectAllClick, isChecked: (selectedCount > 0 && selectedCount === resultsCount) ||
                                                (selectedCount > 0 && selectedCount !== resultsCount), isIndeterminate: selectedCount > 0 && selectedCount !== resultsCount, isHovering: isFilterSelectAllHover }) })), _jsx("label", Object.assign({ className: classes(styles['row-title'], styles['row-link']), onMouseEnter: handleFilterSelectAllMouseEnter, onMouseLeave: handleFilterSelectAllMouseLeave, onClick: handleSelectAllClick }, { children: _jsx(Ditto, { componentId: "listpanel.multiselect.selectfromactivepagecheckbox" }) })), _jsxs("span", { children: [selectedCount === 0 && resultsCount !== 0 && (_jsx(NotificationBadge, { className: styles['row-badge'], content: resultsCount })), selectedCount === resultsCount && (_jsx(NotificationBadge, { className: styles['row-badge'], content: selectedCount })), selectedCount !== 0 && selectedCount < resultsCount && (_jsx(NotificationBadge, { className: styles['row-badge'], content: _jsxs(_Fragment, { children: [_jsx("span", { children: selectedCount }), _jsxs("span", Object.assign({ className: styles['row-badge-total'] }, { children: [_jsx(_Fragment, { children: "\u00A0/\u00A0" }), resultsCount] }))] }) }))] })] })), !onSelectAllClick && (_jsx("div", Object.assign({ className: classes(styles['row-title'], styles['row-title--bold']), "data-test": "result-count" }, { children: _jsx(Ditto, { componentId: "xresults", variables: {
                                        Amount: resultsCount
                                    }, count: resultsCount }) }))), _jsxs("div", Object.assign({ className: styles['row-button-group'] }, { children: [_jsx("div", Object.assign({ ref: sortingModal.buttonRef }, { children: _jsx(ActionButton, { ref: sortingModal.buttonRef, icon: _jsx(Sort, {}), isHover: sortingModal.isOpen, tooltip: {
                                                text: _jsx(Ditto, { componentId: "tooltip.sorting" })
                                            }, onClick: () => sortingModal.toggle(), "data-test": "button-sorting" }) })), search && (_jsx(ActionButton, { icon: _jsx(Search, {}), tooltip: {
                                            text: _jsx(Ditto, { componentId: "tooltip.search" })
                                        }, onClick: handleSearchClick, "data-test": "button-search" }))] }))] }))] })), _jsx(AnimatePresence, { children: sortingModal.isOpen && (_createElement(FloatingDropdown, Object.assign({}, sortingModal.position, { key: 'sorting-dropdown', align: 'right', content: sortingModal.isOpen && (_jsx(ActionModal, Object.assign({ maxHeight: DROP_MENU_MAX_HEIGHT }, { children: (_a = sort.options) === null || _a === void 0 ? void 0 : _a.map((s) => (_jsxs(Fragment, { children: [_jsx(SelectboxEntry, { text: s.text, onClick: () => handleSort(s.id), trailingIcon: s.id === sort.current ? _jsx(Check, {}) : undefined, "data-test": `select-${s.id}` }), s.showDividerBelow && _jsx(Divider, {})] }, `sort-${s.id}`))) }))), onClickOutside: () => sortingModal.hide() }))) })] })));
}
