import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { PLAN_CATEGORY, PLAN_TREATMENT_CATEGORY_ID } from '../../common/consts/plan';
import { getPlanTreatmentCategoryColor, getPlanTreatmentCategoryLabel } from '../../common/convert';
import { useTabBar } from '../../hooks/useTabBar';
import { Button } from '../../uikit/Button/Button';
import { BudgetTooltipBudgetItem } from './BudgetTooltipBudgetItem/BudgetTooltipBudgetItem';
import { BudgetTooltipError } from './BudgetTooltipError/BudgetTooltipError';
import { BudgetTooltipTreatmentItem } from './BudgetTooltipTreatmentItem/BudgetTooltipTreatmentItem';
import styles from './BudgetTooltip.module.css';
var BudgetTab;
(function (BudgetTab) {
    BudgetTab[BudgetTab["budgetType"] = 0] = "budgetType";
    BudgetTab[BudgetTab["treatmentCategory"] = 1] = "treatmentCategory";
})(BudgetTab || (BudgetTab = {}));
const BUDGET_ITEM_HEIGHT = 80;
const BUDGET_ITEM_COUNT = 2;
const CATEGORY_ITEM_HEIGHT = 36;
const TAB_CONTENT_PADDING = 8;
export function BudgetTooltip({ treatmentCategories, years, isBudgetExceeded, isMissingBudgets, isMissingBudgetTypes, isMissingCosts, maintenance, capital, onEditBudgetClick, unitFormatter }) {
    const { currentTab, setCurrentTab } = useTabBar({
        tabsCount: 2
    });
    const tabs = [
        { label: _jsx(Ditto, { componentId: "plannedsegments.budget.type" }), width: 132 },
        { label: _jsx(Ditto, { componentId: "plannedsegments.treatment.category" }), width: 132 }
    ];
    const tabContentHeight = (() => {
        const budgetTypeHeight = BUDGET_ITEM_HEIGHT * BUDGET_ITEM_COUNT;
        const treatmentCategoryHeight = CATEGORY_ITEM_HEIGHT * treatmentCategories.length;
        if (budgetTypeHeight > treatmentCategoryHeight) {
            return budgetTypeHeight + TAB_CONTENT_PADDING * BUDGET_ITEM_COUNT;
        }
        else {
            return treatmentCategoryHeight + TAB_CONTENT_PADDING * treatmentCategories.length;
        }
    })();
    const treatmentCategoriesSortedbyCostsPercentage = [...treatmentCategories].sort((a, b) => b.costsPercentage - a.costsPercentage);
    const yearsArray = years ? Object.keys(years) : [];
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [years && (_jsx("div", Object.assign({ className: classes(styles['content'], styles['content--top'], styles['content--light']) }, { children: _jsxs("div", { children: [_jsxs("div", Object.assign({ className: classes(styles['row'], styles['row--header'], styles['content--light']) }, { children: [_jsx("div", Object.assign({ className: styles['caption'] }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.budget" }) })), _jsx("div", Object.assign({ className: classes(styles['caption'], styles['caption--right']) }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.total" }) }))] })), yearsArray.map((year) => {
                            const totalValueFormat = unitFormatter({
                                type: 'currency',
                                value: years[year].availableBudget
                            });
                            return (_jsxs("div", Object.assign({ className: styles['row'] }, { children: [_jsx("div", { children: year }), _jsx("div", Object.assign({ className: styles['total'] }, { children: totalValueFormat.formattedText }))] }), year));
                        })] }) }))), _jsx("div", Object.assign({ className: styles['tabbar'] }, { children: tabs.map((tab, index) => (_jsx(Button, Object.assign({ className: classes(styles['button'], currentTab === index && styles['button--active']), onClick: () => setCurrentTab(index) }, { children: tab.label }), `tab-${index}`))) })), _jsxs("div", Object.assign({ className: styles['tabbar-container'] }, { children: [currentTab === BudgetTab.budgetType && (_jsxs("div", Object.assign({ className: styles['tabbar-content'], style: { minHeight: tabContentHeight } }, { children: [maintenance && (_jsx(BudgetTooltipBudgetItem, { categoryId: PLAN_CATEGORY.shortTerm, balance: maintenance, unitFormatter: unitFormatter })), capital && (_jsx(BudgetTooltipBudgetItem, { categoryId: PLAN_CATEGORY.longTerm, balance: capital, unitFormatter: unitFormatter }))] }))), currentTab === BudgetTab.treatmentCategory && (_jsx("div", Object.assign({ className: styles['tabbar-content'], style: { minHeight: tabContentHeight } }, { children: treatmentCategoriesSortedbyCostsPercentage.map(({ name, costs, costsPercentage }) => {
                            const treatmentId = PLAN_TREATMENT_CATEGORY_ID[name];
                            return (_jsx(BudgetTooltipTreatmentItem, { name: getPlanTreatmentCategoryLabel(treatmentId), color: getPlanTreatmentCategoryColor(treatmentId), costs: costs, percentage: costsPercentage, unitFormatter: unitFormatter }, name));
                        }) })))] })), _jsxs("div", Object.assign({ className: classes(styles['content'], styles['content--light']) }, { children: [isBudgetExceeded && (_jsx(BudgetTooltipError, { text: _jsx(Ditto, { componentId: "tooltip.remainingservicelife.budgetexceeded" }) })), isMissingCosts && (_jsx(BudgetTooltipError, { text: _jsx(Ditto, { componentId: "plannedsegments.budget.missingcosts" }) })), isMissingBudgets && (_jsx(BudgetTooltipError, { text: _jsx(Ditto, { componentId: "plannedsegments.budget.budgetnotset" }) })), isMissingBudgetTypes && (_jsx(BudgetTooltipError, { text: _jsx(Ditto, { componentId: "plannedsegments.budget.missingbudgettype" }) })), _jsx(Button, Object.assign({ className: styles['button'], color: "dark-gray", onClick: onEditBudgetClick, variant: "fullwidth" }, { children: _jsx(Ditto, { componentId: "plannedsegments.budget.editbudget" }) }))] }))] })));
}
