var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { MIXPANEL_EVENT_NAME } from '../../common/consts/mixpanel';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { reportIssue } from '../../services/condition';
import { sendReportEmail, submitTrackingEvent } from '../../services/mail';
import { addNotificationMessage } from './notification';
const initialState = {
    isSending: false,
    isSent: false
};
export const reportSignIssue = createAppAsyncThunk('conditionReport/reportSignIssue', (report, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield sendReportEmail(report);
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: { componentId: 'toast.mailsend' }
            }
        }));
    }
    else {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    submitTrackingEvent(MIXPANEL_EVENT_NAME.reportTrafficSign);
    return res;
}));
export const reportConditionIssue = createAppAsyncThunk('conditionReport/reportConditionIssue', (report, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield reportIssue(report);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
        return res;
    }
    dispatch(addNotificationMessage({
        type: 'success',
        ditto: {
            title: { componentId: 'toast.mailsend' }
        }
    }));
    return res;
}));
export const conditionReportSlice = createSlice({
    name: 'conditionReport',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(reportConditionIssue.fulfilled, reportSignIssue.fulfilled), (state, action) => {
            state.isSending = false;
            if (action.payload.errorCode > 0) {
                state.error = action.payload.errorMessage;
                return;
            }
            state.isSent = true;
        })
            .addMatcher(isAnyOf(reportConditionIssue.rejected, reportSignIssue.rejected), (state, action) => {
            state.isSending = false;
            state.error = action.error;
        })
            .addMatcher(isAnyOf(reportConditionIssue.pending, reportSignIssue.pending), (state) => {
            state.isSending = true;
            state.error = undefined;
        });
    }
});
export const { reset: resetConditionReport } = conditionReportSlice.actions;
export default conditionReportSlice.reducer;
