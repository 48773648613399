var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Ditto } from 'ditto-react';
import { isDeepEqual } from '../../common/utils/object';
import { checkNetworkAutoUpdateAvailable } from '../../services/plan';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { deleteBudget, fetchBudgetSettings, fetchNetworkSettings, updateBudgetAndNetworkSettings } from '../../state/slices/globalSetting';
import { setSelectedLayerId } from '../../state/slices/layer';
import { fetchBudgetsBreakdown, fetchPlans, setPlanActiveTabKey } from '../../state/slices/leftPanelPlan';
import { fetchPlan } from '../../state/slices/plan';
import { fetchPlanFilters } from '../../state/slices/shared';
import { useUserRole } from '../useUserRole';
export const usePlanBudgetAndNetworkSettings = () => {
    const dispatch = useAppDispatch();
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { currentPlan } = useAppSelector((state) => state.plan);
    const { budgetAndNetwork } = useAppSelector((state) => state.globalSettings);
    const { addModal } = useModalContext();
    const { isAdmin } = useUserRole();
    const [planNetworkSettings, setPlanNetworkSettings] = useState(budgetAndNetwork.network);
    const [planBudgetSettings, setPlanBudgetSettings] = useState(budgetAndNetwork.budgets);
    useEffect(() => {
        if (!isAdmin)
            return;
        if (!budgetAndNetwork.network) {
            dispatch(fetchNetworkSettings());
        }
        else {
            setPlanNetworkSettings(budgetAndNetwork.network);
        }
        if (!budgetAndNetwork.budgets) {
            dispatch(fetchBudgetSettings());
        }
        else {
            setPlanBudgetSettings(budgetAndNetwork.budgets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, budgetAndNetwork]);
    function handlePlanNetworkSettingsChange(field, value) {
        setPlanNetworkSettings((prev) => {
            if (!prev)
                return prev;
            return Object.assign(Object.assign({}, prev), { [field]: value });
        });
    }
    function handlePlanBudgetAndNetworkSaveClick(networkSettings, budgetSettings, callback) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            function refreshPlans() {
                if (currentPlan) {
                    dispatch(fetchPlan(currentPlan.planId));
                }
                dispatch(fetchPlans());
                dispatch(fetchBudgetsBreakdown());
            }
            function saveSettings(newNetworkSettings) {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(updateBudgetAndNetworkSettings({
                        budgetSettings: {
                            items: budgetSettings
                        },
                        networkSettings: Object.assign(Object.assign({}, networkSettings), newNetworkSettings)
                    }));
                    dispatch(fetchPlanFilters());
                    callback();
                });
            }
            if (((_a = budgetAndNetwork.network) === null || _a === void 0 ? void 0 : _a.roadWidth) === networkSettings.roadWidth) {
                saveSettings();
                return;
            }
            // Check if auto update is available when roadWidth is changed
            const res = yield checkNetworkAutoUpdateAvailable(Object.assign({ countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode }, networkSettings));
            if ((_b = res.result) === null || _b === void 0 ? void 0 : _b.autoUpdateAvailable) {
                addModal({
                    id: 'DataUpdateModalContainer',
                    props: {
                        onDeclineClick: () => {
                            saveSettings({
                                autoUpdateAction: 'decline'
                            });
                            refreshPlans();
                        },
                        onUpdateAllClick: () => {
                            saveSettings({
                                autoUpdateAction: 'accept'
                            });
                            refreshPlans();
                        },
                        onLaterClick: () => {
                            saveSettings({
                                autoUpdateAction: 'postpone'
                            });
                            dispatch(setSelectedLayerId('plan'));
                            dispatch(setPlanActiveTabKey('table'));
                            refreshPlans();
                        }
                    }
                });
            }
            else {
                saveSettings();
                refreshPlans();
            }
        });
    }
    function handlePlanBudgetAndNetworkCancelClick(networkSettings, budgetSettings, callback) {
        if (!networkSettings ||
            !budgetAndNetwork.network ||
            !budgetSettings ||
            !budgetAndNetwork.budgets)
            return;
        const oldNetworkSettings = budgetAndNetwork.network;
        const isPlanNetworkSettingsChanged = !isDeepEqual(oldNetworkSettings, networkSettings);
        const oldBudgetsSettings = budgetAndNetwork.budgets;
        const isPlanBudgetsSettingsChanged = !isDeepEqual(oldBudgetsSettings, budgetSettings);
        if (isPlanNetworkSettingsChanged || isPlanBudgetsSettingsChanged) {
            addModal({
                id: 'SaveModal',
                props: {
                    onConfirm: () => {
                        handlePlanBudgetAndNetworkSaveClick(networkSettings, budgetSettings, callback);
                    },
                    onCancel: () => {
                        setPlanNetworkSettings(oldNetworkSettings);
                        setPlanBudgetSettings(oldBudgetsSettings);
                        callback();
                    },
                    description: _jsx(Ditto, { componentId: "prompt.savechanges.copy" })
                }
            });
            return;
        }
        callback();
    }
    function handlePlanBudgetAndNetworkDeleteClick(year, confirmCallback) {
        if (!planBudgetSettings)
            return;
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const budget = planBudgetSettings.find((budget) => budget.year === year);
                        if (budget === null || budget === void 0 ? void 0 : budget.id) {
                            yield dispatch(deleteBudget({ id: budget.id }));
                        }
                        setPlanBudgetSettings(planBudgetSettings.filter((budget) => budget.year !== year));
                        dispatch(fetchPlanFilters());
                        confirmCallback && confirmCallback();
                    }
                    catch (error) {
                        console.error(error);
                    }
                }),
                title: _jsx(Ditto, { componentId: "plannedsegments.budget.delete" }),
                description: _jsx(Ditto, { componentId: "plannedsegments.budget.delete.text" })
            }
        });
        return;
    }
    return {
        planNetworkSettings,
        handlePlanNetworkSettingsChange,
        planBudgetSettings,
        setPlanBudgetSettings,
        handlePlanBudgetAndNetworkSaveClick,
        handlePlanBudgetAndNetworkCancelClick,
        handlePlanBudgetAndNetworkDeleteClick
    };
};
