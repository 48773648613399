var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Ditto } from 'ditto-react';
import { PLAN_PRIORITY_LIMIT } from '../../common/consts/plan';
import { getPlanPriorityIcon, getPlanPriorityLabel, getPlanPriorityName, getPlanThirdPartyIcon, getPlanThirdPartyLabel } from '../../common/convert';
import { getTranslatedMonthByCorrectIndex } from '../../common/utils/time';
import { TreatmentSelectItemCurrentSelectedValue } from '../../components/EditorForms/TreatmentSelect/TreatmentSelectField/TreatmentSelectField';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { BulkEditModal } from '../../components/Modals/BulkEditModal/BulkEditModal';
import { MonthPickerContent } from '../../components/MonthPickerContent/MonthPickerContent';
import { CategorySelectItem, CategorySelectItemCurrentSelectedValue } from '../../components/SegmentForm/CategorySelectItem/CategorySelectItem';
import { StatusSelectItem, StatusSelectItemCurrentSelectedValue } from '../../components/SegmentForm/StatusSelectItem/StatusSelectItem';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { bulkUpdatePreview } from '../../services/plan';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { deletePlans, updateFormValue, updatePlans } from '../../state/slices/bulkEditor';
import { clearSelectedFeature } from '../../state/slices/feature';
import { resetPlanMultiSelect } from '../../state/slices/leftPanelPlan';
export function BulkEditPlanContainer({ onClose }) {
    const { addModal, removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector((state) => state.app);
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { selectedIdList } = useAppSelector((state) => state.leftPanelPlan);
    const { planCategoryList, planStatusList, planTreatmentsList } = useAppSelector((state) => state.shared);
    const { form: formData, status: bulkStatus } = useAppSelector((state) => state.bulkEditor);
    const category = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return planCategoryList.find((category) => formData.categoryId === category.id);
    }, [formData, planCategoryList]);
    const status = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return planStatusList.find((status) => formData.statusId === status.id);
    }, [formData, planStatusList]);
    const treatment = useMemo(() => {
        if (!formData) {
            return undefined;
        }
        return planTreatmentsList.find((treatment) => formData.treatmentId === treatment.id);
    }, [formData, planTreatmentsList]);
    function handleTreatmentSelectClick() {
        return addModal({
            id: 'TreatmentSelectModalContainer',
            props: {
                value: formData === null || formData === void 0 ? void 0 : formData.treatmentId,
                onChange: (id) => {
                    onChange('treatmentId', id);
                },
                hideNotSpecified: true
            }
        });
    }
    function handleDelete() {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    yield dispatch(deletePlans({ planIds: selectedIdList }));
                    removeModal('BulkEditPlanModal');
                    dispatch(resetPlanMultiSelect());
                    dispatch(clearSelectedFeature());
                    onClose && onClose();
                }),
                title: _jsx(Ditto, { componentId: "promt.delete.plannedsegment" }),
                description: _jsx(Ditto, { componentId: "promt.delete.plannedsegment.text" })
            }
        });
    }
    function onChange(field, value) {
        dispatch(updateFormValue({ field: field, value }));
    }
    function handleClose() {
        removeModal('BulkEditPlanModal');
        onClose && onClose();
    }
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!formData || selectedIdList.length === 0) {
                return;
            }
            const autoUpdateAvailable = yield handleAutoUpdateModal();
            if (autoUpdateAvailable === false) {
                yield dispatch(updatePlans(Object.assign({ planIds: selectedIdList }, formData)));
                handleClose();
            }
        });
    }
    function handleAutoUpdateModal() {
        return __awaiter(this, void 0, void 0, function* () {
            const { result: previewData } = yield bulkUpdatePreview(Object.assign({ countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, planIds: selectedIdList }, formData));
            if (!previewData || !previewData.autoUpdateAvailable)
                return false;
            addModal({
                id: 'AutoUpdateModalContainer',
                props: {
                    formData: formData,
                    previewData,
                    isBulk: selectedIdList.length > 1,
                    onChange,
                    onConfirm: (newData) => {
                        dispatch(updatePlans(Object.assign(Object.assign(Object.assign({ planIds: selectedIdList }, formData), newData), { autoUpdateAction: 'accept' })));
                        handleClose();
                    },
                    onCancel: () => {
                        dispatch(updatePlans(Object.assign({ planIds: selectedIdList }, formData)));
                        handleClose();
                    }
                }
            });
        });
    }
    useEffect(() => {
        if (selectedIdList.length === 0) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdList]);
    const content = [
        {
            key: 'category',
            label: _jsx(Ditto, { componentId: "plannedsegments.budget.type" }),
            items: planCategoryList.map(({ id, name }) => (_jsx(CategorySelectItem, { category: {
                    categoryId: id,
                    categoryName: name
                }, onClick: () => onChange('categoryId', id), "data-test": "select-category" }, `category-${id}`))),
            current: (formData === null || formData === void 0 ? void 0 : formData.categoryId) && category ? (_jsx(CategorySelectItemCurrentSelectedValue, { category: {
                    categoryId: category.id,
                    categoryName: category.name
                } })) : undefined
        },
        {
            key: 'status',
            label: _jsx(Ditto, { componentId: "plannedsegments.status" }),
            items: planStatusList.map(({ id, name }) => (_jsx(StatusSelectItem, { status: {
                    statusId: id,
                    statusName: name
                }, isChecked: (formData === null || formData === void 0 ? void 0 : formData.statusId) === id, onClick: () => onChange('statusId', id) }, `status-${id}`))),
            current: (formData === null || formData === void 0 ? void 0 : formData.statusId) && status ? (_jsx(StatusSelectItemCurrentSelectedValue, { status: {
                    statusId: status.id,
                    statusName: status.name
                } })) : undefined
        },
        {
            key: 'priority',
            label: _jsx(Ditto, { componentId: "plannedsegments.priority" }),
            items: [
                ...[
                    ...Object.entries(PLAN_PRIORITY_LIMIT),
                    ...((formData === null || formData === void 0 ? void 0 : formData.priority) !== undefined &&
                        !Object.values(PLAN_PRIORITY_LIMIT).includes(formData.priority)
                        ? Object.entries({
                            [getPlanPriorityName(formData.priority)]: formData.priority
                        })
                        : [])
                ]
                    .sort((a, b) => b[1] - a[1])
                    .map(([name, priorityValue]) => (_jsx(SelectboxEntry, { onClick: () => onChange('priority', priorityValue), isChecked: (formData === null || formData === void 0 ? void 0 : formData.priority) === priorityValue, text: getPlanPriorityLabel(name), leadingIcon: getPlanPriorityIcon(name), "data-test": `dropdown-priority-${priorityValue}` }, `priority-${priorityValue}`)))
            ],
            current: (formData === null || formData === void 0 ? void 0 : formData.priority) !== undefined ? (_jsx(FormInputCurrentSelectedValue, { icon: getPlanPriorityIcon(getPlanPriorityName(formData.priority)), text: getPlanPriorityLabel(getPlanPriorityName(formData.priority)) })) : undefined
        },
        {
            key: 'treatment',
            label: _jsx(Ditto, { componentId: "filter.measure" }),
            onClick: handleTreatmentSelectClick,
            current: (formData === null || formData === void 0 ? void 0 : formData.treatmentId) && treatment ? (_jsx(TreatmentSelectItemCurrentSelectedValue, { title: treatment.title, categoryTypeId: treatment.categoryTypeId })) : undefined
        },
        {
            key: 'fixedMeasure',
            label: _jsx(Ditto, { componentId: "plannedsegments.firmlyplanned" }),
            items: [
                _jsx(SelectboxEntry, { onClick: () => onChange('thirdParty', true), isChecked: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) === true, text: getPlanThirdPartyLabel(true), secondaryText: _jsx(Ditto, { componentId: "tooltip.thirdparty" }), leadingIcon: getPlanThirdPartyIcon(true), "data-test": "fixed-treatment-yes" }, "fixed-measure-yes"),
                _jsx(SelectboxEntry, { onClick: () => onChange('thirdParty', false), isChecked: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) === false, text: getPlanThirdPartyLabel(false), secondaryText: _jsx(Ditto, { componentId: "plannedsegments.internallyinitiated" }), leadingIcon: getPlanThirdPartyIcon(false), "data-test": "fixed-treatment-no" }, "fixed-measure-no")
            ],
            current: (formData === null || formData === void 0 ? void 0 : formData.thirdParty) != undefined ? (_jsx(FormInputCurrentSelectedValue, { icon: getPlanThirdPartyIcon(formData.thirdParty), text: getPlanThirdPartyLabel(formData.thirdParty) })) : undefined
        },
        {
            key: 'plannedFor',
            label: _jsx(Ditto, { componentId: "plannedsegments.plannedfor" }),
            height: 322,
            position: 'top',
            items: [
                _jsx(MonthPickerContent, { locale: locale, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, onConfirm: (value) => {
                        var _a;
                        onChange('completionMonth', (value === null || value === void 0 ? void 0 : value.startDate) ? (value === null || value === void 0 ? void 0 : value.startDate.getMonth()) + 1 : undefined);
                        onChange('completionYear', (_a = value === null || value === void 0 ? void 0 : value.startDate) === null || _a === void 0 ? void 0 : _a.getFullYear());
                    } }, "month-picker")
            ],
            current: (formData === null || formData === void 0 ? void 0 : formData.completionMonth) && (formData === null || formData === void 0 ? void 0 : formData.completionYear) ? (_jsx(FormInputCurrentSelectedValue, { text: `${getTranslatedMonthByCorrectIndex(locale, formData.completionMonth)} ${formData.completionYear.toString()}` })) : undefined
        }
    ];
    return (_jsx(BulkEditModal, { isChanged: !!formData, content: content, loading: bulkStatus === 'loading', onSubmitClick: handleSubmit, onCloseClick: handleClose, onDeleteClick: handleDelete }));
}
