// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BL1qIpSKPMKRGrirxfcQ{display:flex;flex-flow:wrap;gap:6px}.S7ewOYUCTmLQugPXQZKB{margin-top:12px;margin-bottom:2px;margin-left:20px}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/DetailsMetaInformation/DetailsMetaInformation.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,cAAA,CACA,OAAA,CAGJ,sBACI,eAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-flow: wrap;\n    gap: 6px;\n}\n\n.container--plan-details {\n    margin-top: 12px;\n    margin-bottom: 2px;\n    margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BL1qIpSKPMKRGrirxfcQ`,
	"container--plan-details": `S7ewOYUCTmLQugPXQZKB`
};
export default ___CSS_LOADER_EXPORT___;
