// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PtAm_te0Q7C0DOXCyrHi{opacity:.5}.NewJ_nryBJoPZvarhO4Q{width:100%}.pXDFx6UkQbhTHxahp8U7{width:100%;height:2px;background:var(--primary-black);opacity:.05;margin-bottom:24px}.YNa8Z6LM3uiSwSTpDss1{width:100%;display:flex;justify-content:flex-end;gap:12px}.vfoxnG_qlusyZZXNoAop{width:150px}.s1khcrKvGE3X9mxiiQxe{width:160px}`, "",{"version":3,"sources":["webpack://./src/containers/FileUploadModal/FileUploadModalContainer.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,UAAA,CACA,UAAA,CACA,+BAAA,CACA,WAAA,CACA,kBAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,WAAA","sourcesContent":[".folder-icon {\n    opacity: 0.5;\n}\n\n.footer {\n    width: 100%;\n}\n\n.divider {\n    width: 100%;\n    height: 2px;\n    background: var(--primary-black);\n    opacity: 0.05;\n    margin-bottom: 24px;\n}\n\n.buttons {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n}\n\n.button-cancel {\n    width: 150px;\n}\n\n.button-upload {\n    width: 160px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"folder-icon": `PtAm_te0Q7C0DOXCyrHi`,
	"footer": `NewJ_nryBJoPZvarhO4Q`,
	"divider": `pXDFx6UkQbhTHxahp8U7`,
	"buttons": `YNa8Z6LM3uiSwSTpDss1`,
	"button-cancel": `vfoxnG_qlusyZZXNoAop`,
	"button-upload": `s1khcrKvGE3X9mxiiQxe`
};
export default ___CSS_LOADER_EXPORT___;
