import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { ImagePlaceholder } from '../../../uikit/ImagePlaceholder/ImagePlaceholder';
import { Thumbnail } from '../Thumbnail/Thumbnail';
import { AssignedTasks } from './AssignedTasks/AssignedTasks';
import styles from './Asset.module.css';
export function Asset({ content, checkbox, onLinkedTaskClick }) {
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "item-asset" }, { children: [_jsxs("div", Object.assign({ className: styles['top'] }, { children: [_jsxs("div", Object.assign({ className: classes(styles['left']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['overtitle']), "data-test": "asset-type" }, { children: [checkbox, content.type] })), _jsxs("div", Object.assign({ className: styles['title'] }, { children: [content.icon, _jsx("span", Object.assign({ className: classes(styles['title-text'], !content.title && styles['title-text__empty']) }, { children: content.title || _jsx(Ditto, { componentId: "tasktitle.notitle" }) }))] })), _jsx("div", Object.assign({ className: classes(styles['address']) }, { children: content.address }))] })), _jsx("div", Object.assign({ className: classes(styles['right'], content.thumbnails.length && styles['right__no-background']) }, { children: content.thumbnails.length ? (_jsx(Thumbnail, { thumbnails: content.thumbnails })) : (_jsx(ImagePlaceholder, {})) }))] })), _jsxs("div", Object.assign({ className: styles['tags'] }, { children: [content.linkedTasks.length > 0 && (_jsx(AssignedTasks, { tasks: content.linkedTasks, onTaskClick: onLinkedTaskClick })), content.file] }))] })));
}
