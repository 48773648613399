// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AkDPLn7XOo0Wm7rY9pvT{display:flex;flex-direction:column;gap:12px;margin-bottom:12px;align-items:center}.KUAEFe1FOTYa6Xl4EvAx{gap:2px}.iTaf1DkQ2vmhol0LnSpe{display:flex;min-height:200px;justify-content:center}.jqZ5SB4Qyi0cvXbjxrbe{display:flex;gap:12px}.xJXRz4ipSrynxteq9407{width:332px}.EWKuY7nhA_61O8APuvlj{display:flex;width:100%;flex-direction:row;justify-content:flex-end;padding-right:12px;gap:4px}`, "",{"version":3,"sources":["webpack://./src/components/MarkerLowerForm/MarkerLowerForm.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACI,OAAA,CAGJ,sBACI,YAAA,CACA,gBAAA,CACA,sBAAA,CAGJ,sBACI,YAAA,CACA,QAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,YAAA,CACA,UAAA,CACA,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    margin-bottom: 12px;\n    align-items: center;\n}\n\n.container__ghost {\n    gap: 2px;\n}\n\n.loading {\n    display: flex;\n    min-height: 200px;\n    justify-content: center;\n}\n\n.row {\n    display: flex;\n    gap: 12px;\n}\n\n.row__ghost {\n    width: 332px;\n}\n\n.description-button-group {\n    display: flex;\n    width: 100%;\n    flex-direction: row;\n    justify-content: flex-end;\n    padding-right: 12px;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AkDPLn7XOo0Wm7rY9pvT`,
	"container__ghost": `KUAEFe1FOTYa6Xl4EvAx`,
	"loading": `iTaf1DkQ2vmhol0LnSpe`,
	"row": `jqZ5SB4Qyi0cvXbjxrbe`,
	"row__ghost": `xJXRz4ipSrynxteq9407`,
	"description-button-group": `EWKuY7nhA_61O8APuvlj`
};
export default ___CSS_LOADER_EXPORT___;
