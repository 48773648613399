var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getTimeParams } from '../../common/utils/params';
import { exportObjects, getObjectList, getObjectSortParams } from '../../services/object';
import { addNotificationMessage } from './notification';
const initialFilter = {
    manholeType: [],
    stormDrainType: []
};
const ObjectParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.object;
const initialState = {
    selectedObjectList: [],
    resultList: [],
    totalCount: 0,
    // TODO: (suggestion) switch to same method as tasks/assets to improve performance
    firstThousandIds: [],
    resultFetchStatus: 'idle',
    sort: (_b = ObjectParams === null || ObjectParams === void 0 ? void 0 : ObjectParams.sort) !== null && _b !== void 0 ? _b : 'date-desc',
    filter: Object.assign(Object.assign({}, initialFilter), ObjectParams === null || ObjectParams === void 0 ? void 0 : ObjectParams.filter),
    currentPage: PAGE_NUMBER_FIRST,
    activeTabKey: 'filter',
    isMultiSelectOn: false
};
export const fetchObjectList = createDebouncedAsyncThunk('leftPanelObject/list', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds, currentPage } = state.leftPanelObject;
    const { bounds } = state.mainMap;
    const mapBoundFilter = useMapBounds ? bounds : undefined;
    const timepicker = state.presetsPanel.timepicker.selectedOption;
    const res = yield getObjectList(Object.assign(Object.assign(Object.assign(Object.assign({}, getObjectSortParams(sort)), { type: [...filter.manholeType, ...filter.stormDrainType] }), mapBoundFilter), { from: timepicker.from, to: timepicker.to, limit: ITEMS_PER_PAGE, offset: (currentPage - 1) * ITEMS_PER_PAGE }));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const exportObjectsTk = createAppAsyncThunk('leftPanelObject/exportObjectsTk', (req, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { filter, useMapBounds, sort } = state.leftPanelObject;
    const { bounds } = state.mainMap;
    const { userAccount, teamAccount } = state.auth;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { locale } = state.app;
    if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)) {
        return;
    }
    const res = yield exportObjects({
        language: locale,
        useUsaFormat: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US',
        combinedKeys: req.combinedKeys,
        utcOffset: moment().utcOffset(),
        params: {
            bbox: getBBoxParams(bounds, useMapBounds),
            timepicker: getTimeParams(selectedOption),
            filter: {
                detectedObjectsNames: [...filter.manholeType, ...filter.stormDrainType]
            },
            sort: getObjectSortParams(sort)
        },
        email: userAccount === null || userAccount === void 0 ? void 0 : userAccount.email
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.processingexport' } }
        }));
    }
    return res;
}));
export const leftPanelObjectSlice = createSlice({
    name: 'leftPanelObject',
    initialState,
    reducers: {
        reset: () => initialState,
        resetObjectFilter: (state) => {
            state.filter = initialFilter;
        },
        setManholeObjectFilter: (state, action) => {
            state.filter.manholeType = action.payload.manholeType;
        },
        setStormDrainObjectFilter: (state, action) => {
            state.filter.stormDrainType = action.payload.stormDrainType;
        },
        setObjectSorting: (state, action) => {
            state.sort = action.payload;
        },
        setObjectClickedId: (state, action) => {
            state.clickedId = action.payload;
        },
        setSelectedObject: (state, action) => {
            state.selectedObject = action.payload;
        },
        clearSelectedObject: (state) => {
            state.selectedObject = undefined;
        },
        setObjectSelectedList: (state, action) => {
            state.selectedObjectList = action.payload;
        },
        setUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setObjectActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        startObjectMultiSelect: (state) => {
            state.isMultiSelectOn = true;
        },
        resetObjectMultiSelect: (state) => {
            state.isMultiSelectOn = false;
            state.selectedObjectList = [];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchObjectList.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchObjectList.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.list;
                state.totalCount = action.payload.result.totalCount;
                state.firstThousandIds = action.payload.result.firstThousandIds;
            }
        });
        builder;
    }
});
export const { reset: resetLeftPanelObject, resetObjectFilter, setManholeObjectFilter, setStormDrainObjectFilter, setObjectSorting, setObjectClickedId, setSelectedObject, clearSelectedObject, setObjectSelectedList, setUseMapBounds, setCurrentPage, setObjectActiveTabKey, startObjectMultiSelect, resetObjectMultiSelect } = leftPanelObjectSlice.actions;
export default leftPanelObjectSlice.reducer;
