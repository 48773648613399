// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErMSAwO4GPlZnzyCq6Rh{margin-bottom:4px;font-size:14px;line-height:19px}.xBwbUMqN10Axxi5Vn1d3{font-size:12px;line-height:13px}.UvwE4bAudICXwMH7Z2ow>div{opacity:.6}`, "",{"version":3,"sources":["webpack://./src/components/TitleSubtitle/TitleSubtitle.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CAGJ,0BACI,UAAA","sourcesContent":[".title {\n    margin-bottom: 4px;\n    font-size: 14px;\n    line-height: 19px;\n}\n\n.subtitle {\n    font-size: 12px;\n    line-height: 13px;\n}\n\n.subtitle-text > div {\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `ErMSAwO4GPlZnzyCq6Rh`,
	"subtitle": `xBwbUMqN10Axxi5Vn1d3`,
	"subtitle-text": `UvwE4bAudICXwMH7Z2ow`
};
export default ___CSS_LOADER_EXPORT___;
