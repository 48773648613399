import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import moment from 'moment';
import { PauseCircle, PlayCircle1 } from '../icons';
import styles from './AudioPlayer.module.css';
const ONE_SECOND = 1000;
export function AudioPlayer({ maxLength, src, variant = 'light' }) {
    const audioRef = useRef(new Audio(src));
    const [duration, setDuration] = useState((maxLength && moment.utc(maxLength, 'mm:ss').seconds()) || 0);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const currentTime = moment.utc(trackProgress * ONE_SECOND).format('mm:ss');
    const maxTime = moment.utc(duration * ONE_SECOND).format('mm:ss');
    const defaultColor = '#000000';
    const defaultBackground = 'rgba(0, 0, 0, 0.1)';
    const imageViewerColor = '#FFFFFF';
    const imageViewerBackground = 'rgba(255, 255, 255, 0.1)';
    const currentColor = variant === 'dark' ? imageViewerColor : defaultColor;
    const currentBackground = variant === 'dark' ? imageViewerBackground : defaultBackground;
    useEffect(() => {
        audioRef.current.pause();
        audioRef.current.remove();
        setIsPlaying(false);
        audioRef.current = new Audio(src);
        audioRef.current.preload = 'metadata';
        audioRef.current.addEventListener('loadedmetadata', () => {
            setDuration(audioRef.current.duration);
        });
        setTrackProgress(0);
        return () => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        };
    }, [src]);
    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            startTimer();
        }
        else {
            clearInterval(intervalRef.current);
            audioRef.current.pause();
        }
    }, [isPlaying]);
    const intervalRef = useRef();
    const getProgressBarStyle = useCallback(() => {
        if (audioRef.current) {
            const newPositionInPercent = (trackProgress * 100) / (duration || 1);
            return {
                background: `linear-gradient(to right, ${currentColor} 0%, ${currentColor} ` +
                    newPositionInPercent +
                    `%, transparent ` +
                    newPositionInPercent +
                    `%, transparent 100%)`
            };
        }
    }, [currentColor, duration, trackProgress]);
    function startTimer() {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                setTrackProgress(0);
                setIsPlaying(false);
            }
            else {
                setTrackProgress(Math.round(audioRef.current.currentTime));
            }
        }, ONE_SECOND);
    }
    function onScrub(value) {
        audioRef.current.currentTime = parseInt(value, 10);
        setTrackProgress(audioRef.current.currentTime);
    }
    function onScrubEnd() {
        if (!isPlaying) {
            setIsPlaying(true);
        }
    }
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], variant === 'dark' && styles['dark']) }, { children: [_jsx("button", Object.assign({ className: classes(styles['play-button']), onClick: () => setIsPlaying(!isPlaying) }, { children: isPlaying ? _jsx(PauseCircle, {}) : _jsx(PlayCircle1, {}) })), _jsxs("div", Object.assign({ className: classes(styles['time-control']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['label']) }, { children: currentTime })), _jsx("div", Object.assign({ className: classes(styles['time-track']), style: { background: currentBackground } }, { children: _jsx("input", { className: classes(styles['time-progress']), style: getProgressBarStyle(), value: trackProgress, type: "range", step: 1, min: 0, max: duration || `${duration}`, onChange: (event) => onScrub(event.target.value), onMouseDown: onScrubEnd, onKeyDown: onScrubEnd }) })), _jsx("div", Object.assign({ className: classes(styles['label']) }, { children: maxTime }))] }))] })));
}
