var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import saveAs from 'file-saver';
import { exportAlertList } from '../services/alert';
import { exportPlanList, exportPlanListByIds } from '../services/plan';
import { exportSignList } from '../services/sign';
import { RESPONSE_ERROR_CODE } from './fetch';
export function downloadPlanList(params, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield exportPlanList(params, abortSignalId);
        if (res.errorCode !== RESPONSE_ERROR_CODE.success || !res.result) {
            throw new Error(`export/downloadPlanList ${res.errorCode}`);
        }
        const data = yield res.result.blob();
        saveAs(data, 'plan-export.xlsx');
    });
}
export function downloadPlanListByIds(params, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield exportPlanListByIds(params, abortSignalId);
        if (res.errorCode !== RESPONSE_ERROR_CODE.success || !res.result) {
            throw new Error(`export/downloadPlanList ${res.errorCode}`);
        }
        const data = yield res.result.blob();
        saveAs(data, 'plan-export.xlsx');
    });
}
export function downloadAlertList(body, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield exportAlertList(body, abortSignalId);
        if (res.errorCode !== RESPONSE_ERROR_CODE.success || !res.result) {
            throw new Error(`export/downloadAlertList ${res.errorCode}`);
        }
        const data = yield res.result.blob();
        saveAs(data, 'rs-alert-export.xlsx');
    });
}
export function downloadTrafficSignList(params, abortSignalId) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield exportSignList(params, abortSignalId);
        if (res.errorCode !== RESPONSE_ERROR_CODE.success || !res.result) {
            throw new Error(`export/downloadTrafficSignList ${res.errorCode}`);
        }
        const data = yield res.result.blob();
        saveAs(data, 'traffic-sign-export.xlsx');
    });
}
