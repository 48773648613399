var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { MAP_CURSOR } from '../../common/consts/map';
import { MARKER_SOURCE_ID } from '../../common/consts/marker';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_EVENT_VALUE } from '../../common/consts/mixpanel';
import { MARKER_CREATOR_FORM_WIDTH } from '../../common/consts/width';
import { getClosestDueDate, isFormulaRecurring, parseRecurringFormula } from '../../common/recurringRules';
import { isDefined } from '../../common/utils/array';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { AddressSearch } from '../../components/AddressSearch/AddressSearch';
import { CreatorLinkedTasks } from '../../components/CreatorLinkedTasks/CreatorLinkedTasks';
import { Attachment } from '../../components/DetailsPanel/Attachment/Attachment';
import { Asset } from '../../components/DetailsPanel/Attachment/AttachmentItem/Asset/Asset';
import { AttachmentItem } from '../../components/DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { DueDatePickerControl } from '../../components/DueDatePicker/DueDatePickerControl/DueDatePickerControl';
import { AssigneeSelectField } from '../../components/EditorForms/AssigneeSelect/AssigneeSelectField/AssigneeSelectField';
import { FilesSection } from '../../components/FilesSection/FilesSection';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { LinkBreak, Overflow } from '../../components/icons';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { MarkerCreator } from '../../components/MarkerCreator/MarkerCreator';
import { MarkerLowerForm } from '../../components/MarkerLowerForm/MarkerLowerForm';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useMarkerFields } from '../../hooks/forms/useMarkerFields';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useImageUploadModal } from '../../hooks/useImageUploadModal';
import { useMarkerFiles } from '../../hooks/useMarkerFiles';
import { useUserFullname } from '../../hooks/useUserFullname';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { addAssetCreatorMarkerIdsToLink, addAssetCreatorTemporaryMarkerIds, resetAssetCreator, setAssetCreatorSource, setAssetCreatorType } from '../../state/slices/assetCreator';
import { clearAssetInfo, fetchAssetInfoById } from '../../state/slices/assetDetail';
import { clearSelectedFeature } from '../../state/slices/feature';
import { enableMapLayer } from '../../state/slices/layer';
import { clearDraftPin, setDraftPin, setDraftPinContent, setDraftPinInitialPosition, setMapCursor } from '../../state/slices/mainMap';
import { uploadCreateLinkMarkerBatchTk } from '../../state/slices/markerBulkCreator';
import { removeMarkerCreatorFiles, resetMarkerCreator, setMarkerCreatorAssetIdToLink, setMarkerCreatorStep } from '../../state/slices/markerCreator';
import { fetchMarker, resetMarkerDetail } from '../../state/slices/markerDetail';
import { addNotificationMessage } from '../../state/slices/notification';
import { resetSharedDataHolder } from '../../state/slices/sharedDataHolder';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
import { CategorySelectContainer } from '../CategorySelectContainer/CategorySelectContainer';
const FORM_FIELD_WIDTH = 342;
const FORM_FIELD_MARGIN = 12;
export function MarkerCreatorContainer() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { addModal, modals } = useModalContext();
    const { createStatus, source, step: markerCreatorStep, assetIdToLink, filesToAdd } = useAppSelector((state) => state.markerCreator);
    const { source: assetCreatorSource } = useAppSelector((state) => state.assetCreator);
    const titlePlaceholder = useDittoWrapper({ componentId: 'notspecified' });
    const { markerStatusList, markerPriorityList, markerCategoryList, markerFolders: foldersList, taskAssigneeList, assetTypeMap } = useAppSelector((state) => state.shared);
    const { main: map } = useMap();
    const dispatch = useAppDispatch();
    const { fullname } = useUserFullname();
    const { selectConfirmPhotos } = useImageUploadModal();
    const formatter = useFormatter();
    const { formatAddress, formatDistance } = formatter;
    const { handleAddressSearchChange, handleAddressSuggestionClick, fetchedAddress, fetchedSuggestions, draftPin, addressSearchStatus } = useAddressSearch();
    const { assetInfo } = useAppSelector((state) => state.assetDetail);
    const { reloadAssetTilesData } = useTilesUpdator();
    const { assetFields: sharedDataHolderAssetFields, markerFields: sharedDataHolderMarkerList } = useAppSelector((state) => state.sharedDataHolder);
    const { markerFields, updateMarkerFields, getValidatedMarkerDraft } = useMarkerFields(sharedDataHolderMarkerList
        ? Object.assign({}, sharedDataHolderMarkerList) : {
        priority: markerPriorityList[0],
        status: markerStatusList[0],
        creator: fullname
    });
    const dateTimeFormatter = useDateTimeFormatter();
    const { selectFilesForUpload } = useMarkerFiles();
    const [addressError, setAddressError] = useState('');
    const cannotBeEmptyLabel = useDittoWrapper({ componentId: 'errorpage.cantbeempty' });
    // TODO: source is resetted somewhere, needs to be investigated.
    // Using ref is a workaround keeping the initial set source.
    const sourceRef = useRef(source);
    const recurringFormula = parseRecurringFormula(markerFields.recurringFormula);
    const [selectedCategoryId, setSelectedCategoryId] = useState((_a = markerFields.category) === null || _a === void 0 ? void 0 : _a.categoryId);
    useEffect(() => {
        dispatch(clearSelectedFeature());
        dispatch(resetMarkerDetail());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!!draftPin || !map) {
            return;
        }
        const center = map.getCenter();
        map.easeTo({
            center: center,
            padding: { top: 0, right: FORM_FIELD_WIDTH, bottom: 0, left: 0 }
        });
        dispatch(setDraftPin({ lng: center.lng, lat: center.lat }));
        dispatch(setDraftPinInitialPosition({ lng: center.lng, lat: center.lat }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!draftPin || !map || !markerFields.priority) {
            return;
        }
        map.easeTo({
            center: draftPin,
            padding: { top: 0, right: FORM_FIELD_WIDTH, bottom: 0, left: 0 }
        });
        dispatch(setDraftPinContent({
            type: 'marker',
            priority: markerFields.priority.priorityId
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftPin, markerFields.priority]);
    useEffect(() => {
        if (typeof fetchedAddress !== 'undefined') {
            setAddressError('');
        }
    }, [fetchedAddress]);
    useEffect(() => {
        const category = markerCategoryList.find((item) => item.categoryId === selectedCategoryId);
        updateMarkerFields({ category });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategoryId, markerCategoryList]);
    useEffect(() => {
        if (assetIdToLink) {
            dispatch(fetchAssetInfoById(assetIdToLink));
            dispatch(setMarkerCreatorStep(1));
        }
    }, [assetIdToLink, dispatch]);
    useEffect(() => {
        var _a;
        updateMarkerFields({ images: (_a = sharedDataHolderAssetFields === null || sharedDataHolderAssetFields === void 0 ? void 0 : sharedDataHolderAssetFields.images) !== null && _a !== void 0 ? _a : [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedDataHolderAssetFields]);
    function handleMarkerCreateClick(doNotNotify = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const draft = getValidatedMarkerDraft();
            if (draft && draftPin) {
                let fromAssetIds = undefined;
                if (assetIdToLink) {
                    fromAssetIds = [];
                    fromAssetIds.push(assetIdToLink);
                }
                const response = yield dispatch(uploadCreateLinkMarkerBatchTk({
                    photosToUpload: markerFields.images.map((i) => i.file),
                    items: [
                        Object.assign(Object.assign({}, draft), { latitude: draftPin.lat, longitude: draftPin.lng, whereWasCreated: MARKER_SOURCE_ID.webApp, address: formatAddress(fetchedAddress), addressJson: fetchedAddress })
                    ],
                    fromAssetIds: fromAssetIds,
                    filesToLink: filesToAdd,
                    doNotNotify: !!sourceRef.current || !!assetCreatorSource || doNotNotify
                })).unwrap();
                const markerIds = response === null || response === void 0 ? void 0 : response.markers.map((marker) => marker.id);
                dispatch(addAssetCreatorMarkerIdsToLink(markerIds !== null && markerIds !== void 0 ? markerIds : []));
                dispatch(addAssetCreatorTemporaryMarkerIds(markerIds !== null && markerIds !== void 0 ? markerIds : []));
                if ((sourceRef.current && sourceRef.current.linkedAsset) || assetIdToLink) {
                    reloadAssetTilesData();
                    dispatch(setCurrentAction('asset-creator'));
                    dispatch(resetMarkerCreator());
                    dispatch(setMapCursor(MAP_CURSOR.default));
                    dispatch(clearAssetInfo());
                }
                dispatch(enableMapLayer('marker'));
                return markerIds;
            }
        });
    }
    function handleClickClose() {
        dispatch(resetMarkerCreator());
        dispatch(setMapCursor(MAP_CURSOR.default));
        dispatch(clearAssetInfo());
        dispatch(setCurrentAction('none'));
        dispatch(clearDraftPin());
        dispatch(resetSharedDataHolder());
        dispatch(resetAssetCreator());
    }
    function handleClickBack() {
        let step = (markerCreatorStep !== null && markerCreatorStep !== void 0 ? markerCreatorStep : 0) - 1;
        if (step < 0) {
            step = 0;
        }
        dispatch(setMarkerCreatorStep(step));
    }
    function handleClickNext() {
        if (markerCreatorStep === 0 && typeof fetchedAddress === 'undefined') {
            setAddressError(cannotBeEmptyLabel);
            return;
        }
        let step = (markerCreatorStep !== null && markerCreatorStep !== void 0 ? markerCreatorStep : 0) + 1;
        if (step > 1) {
            step = 1;
        }
        dispatch(setMarkerCreatorStep(step));
    }
    function handleSelectCategory(categoryId) {
        setSelectedCategoryId(categoryId);
    }
    function handleAssigneeChange(value) {
        updateMarkerFields({ assignees: value });
    }
    function handleDueDateChange(value) {
        updateMarkerFields({ recurringFormula: value });
    }
    const [titleError, setTitleError] = useState('');
    const placeholderTaskTitle = useDittoWrapper({ componentId: 'tasks.tasktitle' });
    const maximum100charactersError = useDittoWrapper({ componentId: 'maximum100characters' });
    function handleTaskTitleChange(value) {
        if (value.length > 100) {
            setTitleError(maximum100charactersError);
        }
        else {
            setTitleError('');
        }
        updateMarkerFields({ taskTitle: value });
    }
    function handleImageUploadClick() {
        return __awaiter(this, void 0, void 0, function* () {
            selectConfirmPhotos((imgs) => updateMarkerFields({ images: markerFields.images.concat(imgs) }));
        });
    }
    function handleDueDatePickerControlClick() {
        addModal({
            id: 'DueDatePickerModalContainer',
            props: {
                recurringFormula: markerFields.recurringFormula,
                onConfirm: handleDueDateChange
            }
        });
    }
    function getAttachmentAsset(asset) {
        const assetType = asset.inventoryTypeId ? assetTypeMap[asset.inventoryTypeId] : undefined;
        return (_jsx(Asset, { title: asset.title, titleInfo: asset.distance ? `(${formatDistance(asset.distance)})` : undefined, address: formatAddress(asset.address), iconId: assetType === null || assetType === void 0 ? void 0 : assetType.iconId }));
    }
    function handleCreateAssetClick() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function* () {
            mixpanel.track(MIXPANEL_EVENT_NAME.createAssetWhenCreatingTask, {
                [MIXPANEL_EVENT_PROPERTY.taskIntervall]: isFormulaRecurring(markerFields.recurringFormula)
                    ? MIXPANEL_EVENT_VALUE.recurring
                    : MIXPANEL_EVENT_VALUE.oneTime
            });
            dispatch(setAssetCreatorType('floating'));
            dispatch(setCurrentAction('asset-creator'));
            dispatch(setMapCursor(MAP_CURSOR.crosshair));
            dispatch(setAssetCreatorSource({
                // TODO: refactor this, it is just adding bunch of default values which does not really make sense. and i had to edit this every time when adding new fields to marker response
                marker: {
                    id: -1,
                    statusId: (_b = (_a = markerFields.status) === null || _a === void 0 ? void 0 : _a.statusId) !== null && _b !== void 0 ? _b : 0,
                    priorityTypeId: (_d = (_c = markerFields.priority) === null || _c === void 0 ? void 0 : _c.priorityId) !== null && _d !== void 0 ? _d : 0,
                    categoryId: (_e = markerFields.category) === null || _e === void 0 ? void 0 : _e.categoryId,
                    issueDescription: '',
                    address: '',
                    createdAt: '',
                    photos: [],
                    audioDuration: 0,
                    title: markerFields.taskTitle,
                    recurringFormula: (_f = markerFields.recurringFormula) !== null && _f !== void 0 ? _f : '',
                    assignees: markerFields.assignees
                        .map((id) => taskAssigneeList.find((item) => item.id === id))
                        .filter(isDefined)
                        .map((assignee) => (Object.assign(Object.assign({}, assignee), { uuid: assignee.id, createdAt: '' }))),
                    dueDate: markerFields.recurringFormula
                        ? {
                            id: -1,
                            statusId: -1,
                            dueDate: (_h = (_g = getClosestDueDate(parseRecurringFormula(markerFields.recurringFormula))) === null || _g === void 0 ? void 0 : _g.toISOString()) !== null && _h !== void 0 ? _h : '',
                            createdAt: ''
                        }
                        : undefined,
                    markerSource: '',
                    point: {
                        latitude: (_j = draftPin === null || draftPin === void 0 ? void 0 : draftPin.lat) !== null && _j !== void 0 ? _j : 0,
                        longitude: (_k = draftPin === null || draftPin === void 0 ? void 0 : draftPin.lng) !== null && _k !== void 0 ? _k : 0
                    },
                    audioFileUrl: '',
                    createdBy: '',
                    uuid: '',
                    decryptCloseParams: '',
                    v2Changelog: [],
                    files: [],
                    timelog: [],
                    timelogTotalDurationHours: 0,
                    attributes: []
                }
            }));
            const markerId = (_l = (yield handleMarkerCreateClick(true))) === null || _l === void 0 ? void 0 : _l.at(0);
            if (!markerId) {
                return;
            }
            const marker = (_m = (yield dispatch(fetchMarker(markerId)).unwrap()).result) === null || _m === void 0 ? void 0 : _m.marker;
            if (marker) {
                dispatch(setAssetCreatorSource({ marker }));
            }
            dispatch(enableMapLayer('asset'));
        });
    }
    function handleUnlinkAssetClick() {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(setMarkerCreatorAssetIdToLink(undefined));
                    dispatch(clearAssetInfo());
                },
                title: _jsx(Ditto, { componentId: "assets.unlinkasset" }),
                description: _jsx(Ditto, { componentId: "assets.unlinkassetcopy" }),
                yesLabel: _jsx(Ditto, { componentId: "tasks.unlink" })
            }
        });
    }
    function handleAssigneeControlClick() {
        addModal({
            id: 'AssigneeSelectModalContainer',
            props: {
                initialValues: markerFields.assignees,
                onConfirm: handleAssigneeChange
            }
        });
    }
    function handleFileDelete(file) {
        dispatch(removeMarkerCreatorFiles([file.uuid]));
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.filedeleted' } }
        }));
    }
    function handleFileClick() {
        const modal = modals.find((modal) => modal.id === 'FileGalleryTaskContainer');
        if (!modal) {
            addModal({ id: 'FileGalleryTaskContainer' });
        }
    }
    function handleCustomizeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: { initialPageId: 'task-folders' }
        });
    }
    const lowerForm = (_jsx(MarkerLowerForm, { width: FORM_FIELD_WIDTH, margin: FORM_FIELD_MARGIN, description: markerFields.description, title: _jsx(FormInputText, { label: placeholderTaskTitle, width: FORM_FIELD_WIDTH, onValueChange: handleTaskTitleChange, maxLength: 101, error: titleError, value: markerFields.taskTitle, placeholder: titlePlaceholder, variant: "editor-field", "data-test": "task-title" }), address: _jsx(AddressSearch, Object.assign({ value: formatAddress(fetchedAddress), width: MARKER_CREATOR_FORM_WIDTH, onValueChange: handleAddressSearchChange, status: addressSearchStatus, error: addressError }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatAddress(s.address, { isShort: true }), secondaryText: formatAddress(s.address, { cityAndCountry: true }), onClick: () => handleAddressSuggestionClick(s) }, `${s.position.lat}-${s.position.lng}`))) })), status: { items: markerStatusList, current: markerFields.status }, priority: { items: markerPriorityList, current: markerFields.priority }, category: _jsx(CategorySelectContainer, { width: FORM_FIELD_WIDTH, onCategorySelect: handleSelectCategory, selectedId: selectedCategoryId }), assignee: _jsx(AssigneeSelectField, { width: FORM_FIELD_WIDTH, options: taskAssigneeList, selectedValues: markerFields.assignees, onClick: handleAssigneeControlClick }), taskDatePicker: _jsx(DueDatePickerControl, { frequency: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.frequency, value: recurringFormula === null || recurringFormula === void 0 ? void 0 : recurringFormula.startDate, dateTimeFormatter: dateTimeFormatter, onClick: handleDueDatePickerControlClick }), variant: "editor-field", onChange: updateMarkerFields }));
    const linkedAsset = (_b = sourceRef === null || sourceRef === void 0 ? void 0 : sourceRef.current) === null || _b === void 0 ? void 0 : _b.linkedAsset;
    const linkedAssetSection = ((_c = sourceRef.current) === null || _c === void 0 ? void 0 : _c.createdFrom) === 'asset' && linkedAsset && (_jsx(Attachment, Object.assign({ title: _jsx(Ditto, { componentId: "assets.linkedassets" }), count: 1 }, { children: _jsx(AttachmentItem, { children: _jsx(Asset, { iconId: (_h = (((_e = (_d = sourceRef === null || sourceRef === void 0 ? void 0 : sourceRef.current) === null || _d === void 0 ? void 0 : _d.linkedAsset) === null || _e === void 0 ? void 0 : _e.typeId)
                    ? assetTypeMap[(_g = (_f = sourceRef === null || sourceRef === void 0 ? void 0 : sourceRef.current) === null || _f === void 0 ? void 0 : _f.linkedAsset) === null || _g === void 0 ? void 0 : _g.typeId]
                    : undefined)) === null || _h === void 0 ? void 0 : _h.iconId, title: linkedAsset.title, address: formatAddress(linkedAsset.address) }) }) })));
    const filesSection = (_jsx(FilesSection, { id: -1, files: filesToAdd, folders: foldersList, selectFilesForUpload: selectFilesForUpload, dateTimeFormatter: formatter.formatDate, handleFileDeleteClick: handleFileDelete, handleFileClick: handleFileClick, handleCustomizeClick: handleCustomizeClick }));
    if (markerCreatorStep === 0) {
        const localStep = (linkedAsset === null || linkedAsset === void 0 ? void 0 : linkedAsset.id) === -1 ? -1 : markerCreatorStep;
        return (_jsx(MarkerCreator, { disabled: createStatus === 'loading' || !draftPin, position: "floating", linkedAssetSection: linkedAssetSection, markerUpperFieldData: markerFields, markerLowerForm: lowerForm, onClickClose: handleClickClose, onClickCreate: handleMarkerCreateClick, onClickBack: handleClickBack, onClickNext: handleClickNext, onImageUploadClick: handleImageUploadClick, step: localStep }));
    }
    if (markerCreatorStep === 1) {
        return (_jsxs(MarkerCreator, Object.assign({ disabled: createStatus === 'loading' || !draftPin, position: "floating", onClickClose: handleClickClose, onClickCreate: () => __awaiter(this, void 0, void 0, function* () {
                yield handleMarkerCreateClick();
                handleClickClose();
            }), onClickBack: handleClickBack, onClickNext: handleClickNext, step: markerCreatorStep }, { children: [_jsx(CreatorLinkedTasks, Object.assign({ infoBox: _jsx(InfoBox, { content: _jsx(Ditto, { componentId: "assets.linkthistasktoanassettokeeptheoverview" }) }) }, { children: _jsx(Attachment, Object.assign({ title: _jsx(Ditto, { componentId: "inventory.inventory" }), count: Number(!!assetIdToLink), emptyButtons: !assetIdToLink && (_jsx(Button, Object.assign({ variant: "fullwidth", size: "large", color: "gray-2", extraRounded: true, onClick: handleCreateAssetClick }, { children: _jsx(Ditto, { componentId: "assets.createasset" }) }))) }, { children: assetIdToLink && assetInfo && (_jsx(AttachmentItem, Object.assign({ trailingElement: _jsx(FloatingDropdownWrapper, { control: _jsx(ActionButton, { icon: _jsx(Overflow, {}) }), menu: _jsx(ActionModal, Object.assign({ width: FORM_FIELD_WIDTH - FORM_FIELD_MARGIN }, { children: _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "assets.unlinkasset" }), leadingIcon: _jsx(LinkBreak, {}), color: "red", onClick: () => handleUnlinkAssetClick() }) })) }) }, { children: getAttachmentAsset(Object.assign({}, assetInfo)) }), assetInfo.id)) })) })), _jsx(Divider, { margin: "0" }), filesSection] })));
    }
    return _jsx(_Fragment, {});
}
