import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Loader } from '../components/Loader/Loader';
const DEBOUNCE_SHOW_LOADER_MS = 300;
// Avoid interface flickers for fast updates (99% of the time)
// Only show the loader after a certain delay
export const useDelayedLoader = ({ isLoading }) => {
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        let timeout = undefined;
        if (isLoading) {
            timeout = setTimeout(() => {
                setShowLoader(isLoading);
            }, DEBOUNCE_SHOW_LOADER_MS);
        }
        else {
            setShowLoader(false);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isLoading]);
    return (showLoader && _jsx(Loader, {})) || undefined;
};
