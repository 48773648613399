import { toggleItemInArray } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setSignFilter } from '../../state/slices/leftPanelSign';
export function useSignFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelSign);
    const dispatch = useAppDispatch();
    function handleDamageFilterClick(value) {
        dispatch(setSignFilter({
            damageTypeIds: toggleItemInArray(filter.damageTypeIds, value)
        }));
    }
    function handleSignTypeFilterClick(value) {
        dispatch(setSignFilter({
            signTypeIds: toggleItemInArray(filter.signTypeIds, value)
        }));
    }
    function handleSignTypeGroupClick(value) {
        var _a;
        if (!value.items) {
            return;
        }
        if (getCheckboxCheckState((_a = value.items.length) !== null && _a !== void 0 ? _a : 0, filter.signTypeIds.filter((t) => { var _a; return (_a = value.items) === null || _a === void 0 ? void 0 : _a.map((i) => i.id).includes(t); }).length) === 'checked') {
            const idsToRemove = value.items.map((t) => t.id);
            dispatch(setSignFilter({
                signTypeIds: [...filter.signTypeIds.filter((t) => !idsToRemove.includes(t))]
            }));
            return;
        }
        const updatedSignTypeIds = [...filter.signTypeIds];
        for (const signTypeId of value.items) {
            if (!signTypeId.id) {
                continue;
            }
            if (updatedSignTypeIds.includes(signTypeId.id)) {
                continue;
            }
            updatedSignTypeIds.push(signTypeId.id);
        }
        dispatch(setSignFilter({
            signTypeIds: updatedSignTypeIds
        }));
    }
    return { handleDamageFilterClick, handleSignTypeFilterClick, handleSignTypeGroupClick };
}
