var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { classes } from 'classifizer';
import styles from './ImageWithTag.module.css';
const OFFSET = 30;
export function ImageWithTag(_a) {
    var _b, _c;
    var { tag, tagLabel } = _a, props = __rest(_a, ["tag", "tagLabel"]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const { xmin, xmax, ymin, ymax, height: fullHeight, width: fullWidth } = tag;
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, [ref, (_b = ref.current) === null || _b === void 0 ? void 0 : _b.clientHeight, (_c = ref.current) === null || _c === void 0 ? void 0 : _c.clientWidth, width, height]);
    const currentXmax = Math.round((width * xmax) / fullWidth);
    const currentXmin = Math.round((width * xmin) / fullWidth);
    const currentYmax = Math.round((height * ymax) / fullHeight);
    const currentYmin = Math.round((height * ymin) / fullHeight);
    const tagPosition = {
        top: currentYmin - OFFSET / 2,
        left: currentXmin - OFFSET / 2
    };
    const frameSize = {
        height: currentYmax - currentYmin + OFFSET,
        width: currentXmax - currentXmin + OFFSET
    };
    return (_jsxs("div", Object.assign({ className: classes(styles['container']) }, { children: [_jsx("img", Object.assign({}, props, { className: classes(styles['image']), ref: ref })), _jsxs("div", Object.assign({ className: classes(styles['tag']), style: tagPosition }, { children: [_jsx("div", { className: classes(styles['frame']), style: frameSize }), tagLabel && _jsx("div", Object.assign({ className: classes(styles['tag-caption']) }, { children: tagLabel }))] }))] })));
}
