import moment from 'moment';
export const TIME_PICKER_RANGE_FORMAT = 'YYYY-MM-DD';
// First of January of current year
export const DEFAULT_DATE_SELECT_START = new Date(new Date().getFullYear(), 0, 1);
export const TIME_PICKER_RANGE_LATEST = {
    from: '2010-01-01',
    to: moment().add(1, 'days').format(TIME_PICKER_RANGE_FORMAT)
};
export const TIME_PICKER_LATEST_ID = 'latest';
export const TIME_PICKER_TODAY_ID = 'today';
export const TIME_PICKER_CUSTOM_ID = 'custom';
