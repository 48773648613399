// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z1LG0_TTRbW9Z26ICzpq{font-size:12px;line-height:18px;text-align:center}.hXihRSiACWppgZcqE526{display:flex;align-items:center;gap:12px;margin-left:auto}.GqWyL9YQeXAy0HVOHGPy{color:#1556eb;margin:0}.SUif6AabET_1PctKrb69{display:flex;align-items:center;justify-content:center;height:302px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/UserAccountModal/UserAccountModal.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,gBAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CAGJ,sBACI,aAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":[".copyright {\n    font-size: 12px;\n    line-height: 18px;\n    text-align: center;\n}\n\n.buttons {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n    margin-left: auto;\n}\n\n.reset-button {\n    color: #1556eb;\n    margin: 0;\n}\n\n.loader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 302px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyright": `z1LG0_TTRbW9Z26ICzpq`,
	"buttons": `hXihRSiACWppgZcqE526`,
	"reset-button": `GqWyL9YQeXAy0HVOHGPy`,
	"loader": `SUif6AabET_1PctKrb69`
};
export default ___CSS_LOADER_EXPORT___;
