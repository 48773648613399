var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { tryLinkFiles, tryUploadPhotos } from '../../services/fileManager';
import { createMarkerBatch, linkMarkersToAssets } from '../../services/marker';
import { setIsLoadingActionbar } from './actionbar';
import { setSelectedFeature } from './feature';
import { enableMapLayer } from './layer';
import { fetchAssetsTk } from './leftPanelAsset';
import { fetchMarkers } from './leftPanelMarker';
import { invalidateCachedLinkedMarkers } from './linkedMarkers';
import { fetchMarkerGeoJSON } from './mainMap';
import { resetMarkerCreator } from './markerCreator';
import { addNotificationMessage } from './notification';
const initialState = {
    createStatus: 'idle'
};
export const uploadCreateLinkMarkerBatchTk = createAppAsyncThunk('markerBulkCreator/createLinkMarkerBatchTk', (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    dispatch(setIsLoadingActionbar(true));
    const uploadedPhotoUuids = payload.photosToUpload
        ? yield tryUploadPhotos(payload.photosToUpload, 'road-safety')
        : undefined;
    const linkedPhotoUuids = payload.photosToLink
        ? yield tryLinkFiles(payload.photosToLink)
        : undefined;
    const createRes = yield createMarkerBatch({
        markers: payload.items.map((i, idx) => (Object.assign(Object.assign({}, i), { photos: (i.photos || [])
                .concat(payload.photosToLink && linkedPhotoUuids
                ? { uuid: linkedPhotoUuids[idx] }
                : [])
                .concat(payload.photosToUpload && uploadedPhotoUuids
                ? uploadedPhotoUuids.map((uuid) => ({ uuid }))
                : []), files: payload === null || payload === void 0 ? void 0 : payload.filesToLink })))
    });
    const result = createRes.result;
    if (createRes.errorCode !== RESPONSE_ERROR_CODE.success || !createRes.result) {
        dispatch(addNotificationMessage({ type: 'error' }));
        return;
    }
    if (payload.fromAssetIds) {
        if (payload.fromAssetIds.length !== createRes.result.markers.length) {
            dispatch(addNotificationMessage({ type: 'error' }));
            return;
        }
        const linkRes = yield linkMarkersToAssets({
            linkData: payload.fromAssetIds.map((assetId, idx) => ({
                manualInventoryId: assetId,
                markersIds: [createRes.result.markers[idx].id]
            }))
        });
        if (linkRes.errorCode !== RESPONSE_ERROR_CODE.success) {
            dispatch(addNotificationMessage({ type: 'error' }));
            return;
        }
        if (getState().layer.selectedLayerId === 'asset') {
            dispatch(fetchAssetsTk());
        }
    }
    if (typeof payload.doNotNotify === 'undefined' || !payload.doNotNotify) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: {
                    componentId: payload.fromAssetIds
                        ? 'tasks.newtaskscreatedandlinkedwithassets'
                        : 'toast.successfullycreated',
                    count: payload.items.length
                }
            },
            action: payload.items.length === 1
                ? {
                    type: 'dispatch',
                    label: {
                        componentId: 'toast.marker'
                    },
                    content: [
                        enableMapLayer('marker'),
                        setSelectedFeature({
                            type: 'marker',
                            id: ((_a = createRes.result) === null || _a === void 0 ? void 0 : _a.markers)
                                ? (_b = createRes.result) === null || _b === void 0 ? void 0 : _b.markers[0].id
                                : 0
                        })
                    ]
                }
                : undefined
        }));
    }
    dispatch(setIsLoadingActionbar(false));
    dispatch(resetMarkerCreator());
    dispatch(resetMarkerBulkCreator());
    dispatch(fetchMarkerGeoJSON());
    dispatch(fetchMarkers());
    dispatch(invalidateCachedLinkedMarkers());
    return result;
}));
export const markerBulkCreatorSlice = createSlice({
    name: 'markerBulkCreator',
    initialState,
    reducers: {
        reset: () => initialState,
        setPointSource: (state, action) => {
            state.source = action.payload;
        },
        clearPointSource: (state) => {
            state.source = undefined;
        },
        setCreateStatus: (state, action) => {
            state.createStatus = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(uploadCreateLinkMarkerBatchTk.pending, (state) => {
            state.createStatus = 'loading';
        })
            .addCase(uploadCreateLinkMarkerBatchTk.rejected, (state) => {
            state.createStatus = 'failed';
        })
            .addCase(uploadCreateLinkMarkerBatchTk.fulfilled, (state) => {
            state.createStatus = 'succeeded';
        });
    }
});
export const { reset: resetMarkerBulkCreator, setPointSource: setMarkerBulkCreatorPointSource } = markerBulkCreatorSlice.actions;
export default markerBulkCreatorSlice.reducer;
