import { addMinutes, format, parseISO } from 'date-fns';
import moment from 'moment';
export function sleep(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
export function timeAfter(seconds) {
    return new Date().getTime() + seconds * 1000;
}
const DATE_FORMATS = {
    default: 'DD.MM.YYYY',
    ['US']: 'MM.DD.YYYY'
};
const TIME_FORMATS = {
    default: 'HH:mm',
    ['US']: 'hh:mm\u00A0A'
};
export function stripIllegalDateCharacters(input) {
    // Allow only digits and dots
    return input.replace(/[^\d.]/g, '');
}
export function stripIllegalTimeCharacters(input, country = 'default') {
    if (country === 'US') {
        // Allow only digits, ":", space, and AM/PM
        return input.replace(/[^\d{1,2}:\s(AM|PM)]+/g, '');
    }
    return input.replace(/[^\d{1,2}:]+/g, '');
}
export function getDateFormat(country = 'default') {
    var _a;
    return (_a = DATE_FORMATS[country]) !== null && _a !== void 0 ? _a : DATE_FORMATS.default;
}
export function getTimeFormat(country = 'default') {
    var _a;
    return (_a = TIME_FORMATS[country]) !== null && _a !== void 0 ? _a : TIME_FORMATS.default;
}
export function getDatePickerDateFormat(country = 'default') {
    const dateFormat = getDateFormat(country);
    // NOTE: react-datepicker uses date-fns library internally, formatting symbols different than momentjs
    const datePickerFormat = dateFormat.replaceAll('Y', 'y').replaceAll('D', 'd');
    return datePickerFormat;
}
export function getDatePickerTimeFormat(country = 'default') {
    const timeFormat = getTimeFormat(country);
    // NOTE: react-datepicker uses date-fns library internally, formatting symbols different than momentjs
    // Replace non-breaking space with normal space, used for input validation.
    const datePickerFormat = timeFormat.replace('A', 'a').replace('\u00A0', ' ');
    return datePickerFormat;
}
export function getDateTimeFromTimestamp(timestamp, country = 'default', hideTime = false) {
    if (timestamp === undefined) {
        return { date: '', time: '', dateTime: '' };
    }
    const dateObject = moment(timestamp).toDate();
    const date = moment(dateObject).format(getDateFormat(country));
    const time = hideTime ? '' : moment(dateObject).format(getTimeFormat(country));
    return { date, time, dateTime: hideTime ? date : `${date}, ${time}` };
}
export function getDateInUTC(date) {
    const dateInUTC = addMinutes(date, -date.getTimezoneOffset());
    return dateInUTC;
}
export function parseDateInUTC(date) {
    const parsedDate = parseISO(date);
    const dateInUTC = addMinutes(parsedDate, parsedDate.getTimezoneOffset());
    return dateInUTC;
}
export function getTranslatedMonths(locale) {
    return moment.localeData(locale).months();
}
export function getTranslatedShortMonths(locale) {
    return moment.localeData(locale).monthsShort();
}
// NOTE: We count months 1-12, not 0-11!
export function getTranslatedMonthByCorrectIndex(locale, index, short) {
    var _a;
    const months = short ? getTranslatedShortMonths(locale) : getTranslatedMonths(locale);
    return (_a = months[index - 1]) !== null && _a !== void 0 ? _a : '';
}
export function getCompletionYearMonthString(locale, year, monthIndex, short) {
    if (!monthIndex && !year) {
        return '-';
    }
    return `${getTranslatedMonthByCorrectIndex(locale, monthIndex, short)} ${year || ''}`;
}
// copied from web-api. new v2 endpoints skips web-api
export function formatAudioDuration(duration) {
    if (!duration || duration <= 0) {
        return undefined;
    }
    const PADDING_THRESHOLD = 10;
    const SECONDS_IN_MINUTE = 60;
    const rounded = Math.round(duration);
    const minutes = Math.floor(duration / SECONDS_IN_MINUTE);
    const seconds = rounded - minutes * SECONDS_IN_MINUTE;
    const secondsPadding = seconds < PADDING_THRESHOLD ? '0' : '';
    return `${minutes}:${secondsPadding}${seconds}`;
}
export function isBeforeNow(date) {
    return moment(date) < moment();
}
// NOTE: handle date in UTC to eliminate date shifting from winter/summer time
export function toDateUTC(time) {
    if (!time) {
        return;
    }
    return format(parseDateInUTC(time), 'yyyy-MM-dd');
}
// NOTE: handle date in UTC to eliminate date shifting from winter/summer time
export function fromDateUTC(date) {
    if (!date) {
        return;
    }
    return getDateInUTC(date).toISOString();
}
export function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}
