import { toggleItemInArray } from '../../common/utils/array';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setTrackControlFilter } from '../../state/slices/leftPanelTrackControl';
export function useTrackControlFilterHandlers() {
    const { filter } = useAppSelector((state) => state.leftPanelTrackControl);
    const dispatch = useAppDispatch();
    function handleAgeFilterClick(value) {
        dispatch(setTrackControlFilter(Object.assign(Object.assign({}, filter), { ages: toggleItemInArray(filter.ages, value) })));
    }
    return { handleAgeFilterClick };
}
