import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export function Expander({ variant = 'default' }) {
    switch (variant) {
        case 'default':
            return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: [_jsx("rect", { width: "18", height: "18", x: "3", y: "3", fill: "#818181", fillOpacity: "0.1", rx: "9" }), _jsx("path", { fill: "#222D39", d: "M16.096 10.732a.375.375 0 00-.346-.232h-7.5a.375.375 0 00-.265.64l3.75 3.75a.375.375 0 00.53 0l3.75-3.75a.375.375 0 00.081-.409z", opacity: "0.6" })] })));
        case 'hover':
            return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: [_jsx("rect", { width: "18", height: "18", x: "3", y: "3", fill: "#818181", fillOpacity: "0.1", rx: "9" }), _jsx("path", { fill: "#222D39", d: "M16.096 10.732a.375.375 0 00-.346-.232h-7.5a.375.375 0 00-.265.64l3.75 3.75a.375.375 0 00.53 0l3.75-3.75a.375.375 0 00.081-.409z" })] })));
        case 'open':
            return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, { children: [_jsx("rect", { width: "18", height: "18", x: "3", y: "3", fill: "#818181", fillOpacity: "0.1", rx: "9" }), _jsx("path", { fill: "#222D39", d: "M16.096 13.268a.375.375 0 01-.346.232h-7.5a.375.375 0 01-.265-.64l3.75-3.75a.375.375 0 01.53 0l3.75 3.75c.107.107.14.268.081.409z" })] })));
    }
}
