import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { ActionButton } from '../../ActionButton/ActionButton';
import { Close } from '../../icons';
import { Loader } from '../../Loader/Loader';
import styles from './AccountSelectorLoader.module.css';
export function AccountSelectorLoader({ onLogoutClick }) {
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles['logout-icon'] }, { children: _jsx(ActionButton, { isActive: true, tooltip: {
                        text: _jsx(Ditto, { componentId: "layerpanel.accountmodal.logout" })
                    }, icon: _jsx(Close, {}), onClick: onLogoutClick }) })), _jsx(Loader, { color: "white", variant: "fullscreen" })] }));
}
