import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { TAB_INDEX_ASSET_TASK } from '../../common/consts/asset';
import { useCurrentTabScrollTop } from '../../hooks/useCurrentTabScrollTop';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { TabBar } from '../../uikit/TabBar/TabBar';
import styles from './AssetDetail.module.css';
export function AssetDetail({ header, imagePreview, metaInfo, lowerForm, markerSection, filesSection, filesCount, changelogSection, markerCount = 0, footer, currentTab, setCurrentTab }) {
    const refContent = React.createRef();
    useCurrentTabScrollTop({ refContent, currentTab });
    const tabs = [
        {
            label: _jsx(Ditto, { componentId: "details" }),
            content: (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles['image-preview'] }, { children: imagePreview })), _jsx("div", Object.assign({ className: styles['meta-info'] }, { children: metaInfo })), _jsx("div", Object.assign({ className: styles['lower-form'] }, { children: lowerForm }))] }))
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx("span", { children: _jsx(Ditto, { componentId: "files.files" }) }), _jsx(NotificationBadge, { content: filesCount })] })),
            content: _jsx("div", Object.assign({ className: styles['asset-files'] }, { children: filesSection }))
        },
        {
            label: _jsx(Ditto, { componentId: "activity" }),
            content: _jsx("div", Object.assign({ className: styles['changelog'] }, { children: changelogSection }))
        }
    ];
    if (markerSection) {
        tabs.splice(TAB_INDEX_ASSET_TASK, 0, {
            label: (_jsxs(_Fragment, { children: [_jsx("span", { children: _jsx(Ditto, { componentId: "layer.tasks" }) }), _jsx(NotificationBadge, { content: markerCount })] })),
            content: (_jsx("div", Object.assign({ className: classes(styles['linked-markers'], markerCount > 0 && styles['linked-markers-content']) }, { children: markerSection })))
        });
    }
    const content = tabs[currentTab].content;
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "asset-details" }, { children: [header, _jsx("div", { children: _jsx(TabBar, { tabs: tabs, current: currentTab, setCurrent: setCurrentTab }) }), _jsx("div", Object.assign({ ref: refContent, className: styles['content-group'] }, { children: content })), footer && _jsx("div", Object.assign({ className: styles['footer'] }, { children: footer }))] })));
}
