import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { CaretCloseSmall, CaretOpenSmall } from '../../../icons';
import styles from './SectionExpander.module.css';
export function SectionExpander({ collapsed, disabled }) {
    const icon = collapsed ? _jsx(CaretCloseSmall, {}) : _jsx(CaretOpenSmall, {});
    return (_jsx("div", Object.assign({ className: classes(styles['container'], collapsed && styles['container--collapsed'], disabled && styles['container--disabled']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: icon })) })));
}
