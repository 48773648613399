import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { ActionModal } from '../../../ActionModal/ActionModal';
import { DropMenu } from '../../../DropMenu/DropMenu';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { CaretCloseSmall } from '../../../icons';
import styles from './BulkEditContentItem.module.css';
export function BulkEditContentItem({ menuOpen, setMenuOpen, leadingIcon, position = 'bottom', height = 300, content, disabled, 'data-test': dataTest, noMenuPadding }) {
    const ref = useRef(null);
    useOnClickOutside(ref, (event) => {
        if (!event.target.contains(ref.current)) {
            return;
        }
        if (!ref.current.contains(event.target)) {
            event.stopPropagation();
            setMenuOpen(false);
        }
    });
    function toggleDropdown(event) {
        if (ref.current !== event.target) {
            return;
        }
        setMenuOpen(!menuOpen);
    }
    function handleClick() {
        if (content.onClick) {
            content.onClick();
        }
        else {
            setMenuOpen(!menuOpen);
        }
    }
    const control = (_jsxs("div", Object.assign({ className: classes(styles['label'], content.current !== undefined ? styles['current-label'] : styles['multiple-label']), onClickCapture: handleClick }, { children: [content.current !== undefined ? (content.current) : (_jsx(Ditto, { componentId: "bulkeditmodal.multiple" })), _jsx(CaretCloseSmall, {})] })));
    return (_jsxs("div", Object.assign({ ref: ref, className: classes(styles['container'], disabled && styles['container__disabled']), onClick: (event) => {
            if (content.onClick) {
                content.onClick();
            }
            else {
                toggleDropdown(event);
            }
        }, "data-test": dataTest }, { children: [leadingIcon && (_jsx("span", Object.assign({ className: styles['icon'] }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: leadingIcon })) }))), _jsx("div", { children: _jsx("span", Object.assign({ className: styles['title'] }, { children: content.label })) }), _jsxs("div", Object.assign({ className: styles['trailing'] }, { children: [content.items && (_jsx(DropMenu, { direction: position === 'top' ? 'up-right' : 'down-right', isOpen: menuOpen, setIsOpen: () => undefined, control: control, menu: _jsx(ActionModal, Object.assign({ className: styles['dropdown'], maxHeight: height, onItemClick: () => {
                                setMenuOpen(false);
                            }, noPadding: noMenuPadding }, { children: content.items })) })), content.onClick && control] }))] })));
}
