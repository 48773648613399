export class MilkyWayApiServiceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * for k8s tcp probes, prevents flooding logs with 404
     * @returns any A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceRoot() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/'
        });
    }
    /**
     * @param body
     * @returns v4EdgeAddressesByIDsResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceEdgeAddressesByIDs(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/edges/addresses-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4ExportResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/edges/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4ExportResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsExportByIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/edges/export-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4EdgesByIDsListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceEdgesByIDsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/edges/list-by-id',
            body: body
        });
    }
    /**
     * @param type because customer is in the url path, so
     * cannot remove customer for backward compatibility but do not read it in controller
     * @param customer
     * @param tileZ
     * @param tileX
     * @param tileY
     * @param query
     * @returns apiHttpBody A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceMapTiles(type, customer, tileZ, tileX, tileY, query) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v4/map/tiles/{type}/{customer}/{tile.z}/{tile.x}/{tile.y}',
            path: {
                type: type,
                customer: customer,
                'tile.z': tileZ,
                'tile.x': tileX,
                'tile.y': tileY
            },
            query: {
                query: query
            }
        });
    }
    /**
     * @param body
     * @returns v4ObjectTypesListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceObjectTypesList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/objects/types-list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PointAddressesByIDsResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointAddressesByIDs(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/addresses-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PointsCountResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointsCount(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/count',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4ExportResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointsExport(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/export',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4ExportResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointsExportByIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/export-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PointsListSimpleResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PointsListFullResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointsByIDsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/list-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PointsListFullResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointsByUuiDsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/list-by-uuid',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PointIdsByStreetIdsResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePointIdsByStreetIds(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/points/list-id-by-street-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4RoadConditionCountResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceRoadConditionCount(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/road-condition/count',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4ExportObjectsByEmailResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceExportObjectsByEmail(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/road-condition/export-gis-records-by-email',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4RoadConditionFiltersResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceRoadConditionFilters(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/road-condition/get-filters',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4RoadSafetyLinkForUploadedFileRoadConditionResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceRoadSafetyLinkForUploadedFileRoadCondition(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/road-condition/road-safety-link-uploaded-file',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SegmentByIDResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/segments/get-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SegmentsTableSumLineResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsTableSumLine(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/segments/get-table-sum-line',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SegmentsListV2Response A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsListV2(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/segments/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SegmentsByIDsListV2Response A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsByIDsListV2(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/segments/list-by-ids',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SegmentsUpdateBulkResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsUpdateBulk(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/segments/update-bulk',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SegmentsUpdateResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSegmentsUpdate(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/segments/update-single',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4StreetTypesDeleteByIdResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceStreetTypesDeleteById(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/street-types/delete-by-id',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4StreetTypesListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceStreetTypesList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/street-types/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4StreetTypesListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceStreetTypesSave(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/street-types/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SurfaceTypesListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSurfaceTypesList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/surface-types/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4TableSettingsDeleteResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceTableSettingsDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/delete',
            body: body
        });
    }
    /**
     * =========================SETTINGS=========================
     * @param body
     * @returns v4TableSettingsListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceTableSettingsList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4PropertyTypeListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServicePropertyTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/property-types/list',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4TableSettingsSaveResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceTableSettingsSave(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/save',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4SelectOptionDeleteResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceSelectOptionDelete(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/select-option/delete',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4TableUnitTypeAddResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceTableUnitTypeAdd(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/unit-types/add',
            body: body
        });
    }
    /**
     * @param body
     * @returns v4TableUnitTypeListResponse A successful response.
     * @throws ApiError
     */
    milkyWayApiServiceTableUnitTypeList(body) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v4/table-settings/unit-types/list',
            body: body
        });
    }
}
