var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useAppDispatch } from '../state/hooks';
import { addNotificationMessage } from '../state/slices/notification';
import { uploadFilesTk } from '../state/slices/signInventoryDetail';
import { useTilesUpdator } from './mainMap/useTilesUpdator';
import { useFileUpload } from './useFileUpload';
export const useSignInventoryFileUpload = () => {
    const dispatch = useAppDispatch();
    const { selectFilesDialog, uploadFile } = useFileUpload();
    const { reloadSignInventoryTilesData } = useTilesUpdator();
    const handleFileUpload = useCallback((index, totalFiles) => {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: {
                title: {
                    componentId: 'toast.uploadfile',
                    variables: {
                        Number_1: index,
                        count: totalFiles
                    }
                }
            }
        }));
    }, [dispatch]);
    const handleUploadError = useCallback(() => {
        dispatch(addNotificationMessage({ type: 'error' }));
    }, [dispatch]);
    const selectFilesForUpload = useCallback((selectFolder, signId, onGetUuids) => {
        uploadFiles({
            signId,
            selectFolder,
            selectFilesDialog,
            uploadFile,
            onFileUpload: handleFileUpload,
            onGetUuids,
            onError: handleUploadError,
            dispatch,
            reloadSignInventoryTilesData
        });
    }, [
        selectFilesDialog,
        uploadFile,
        handleFileUpload,
        handleUploadError,
        dispatch,
        reloadSignInventoryTilesData
    ]);
    return { selectFilesForUpload };
};
const uploadFiles = ({ signId, selectFolder, selectFilesDialog, uploadFile, onFileUpload, onGetUuids, onError, dispatch, reloadSignInventoryTilesData }) => {
    const ACCEPTED_FILE_TYPES = [
        'jpg',
        'jpeg',
        'png',
        'data',
        'mp4',
        'flac',
        'pdf',
        'doc',
        'docx',
        'rtf',
        'odt',
        'xls',
        'xlsx'
    ].map((ext) => `.${ext}`);
    (() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const files = yield selectFilesDialog({ accept: ACCEPTED_FILE_TYPES });
            if (!files || 'error' in files) {
                throw new Error();
            }
            const { folderId } = yield selectFolder();
            if (folderId === null) {
                // No folder selected => upload cancelled
                return;
            }
            const uuids = yield awaitInSequence(files.map((file, index) => {
                onFileUpload(index, files.length);
                return () => uploadFile(file, 'traffic-signs');
            }));
            const filesWithUuids = uuids.map((id, index) => ({
                uuid: id,
                name: files[index].name
            }));
            onGetUuids && onGetUuids(folderId, filesWithUuids);
            if (signId) {
                dispatch(uploadFilesTk({ signId, folderId, files: filesWithUuids })).then(() => {
                    reloadSignInventoryTilesData();
                });
            }
        }
        catch (error) {
            onError();
        }
    }))();
};
const awaitInSequence = (actions) => __awaiter(void 0, void 0, void 0, function* () {
    const results = [];
    for (const action of actions) {
        results.push(yield action());
    }
    return results;
});
