var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { closeMarker } from '../../services/marker';
import { addNotificationMessage } from './notification';
const initialState = {
    closeStatus: 'idle'
};
export const closeMarkerTk = createAppAsyncThunk('closeMarker/closeMarkerTk', (token, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield closeMarker(token);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        captureMessage('closeMarker/closeMarkerTk: Failed', 'warning');
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const closeMarkerSlice = createSlice({
    name: 'closeMarker',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(closeMarkerTk.rejected, (state) => {
            state.closeStatus = 'failed';
        })
            .addCase(closeMarkerTk.pending, (state) => {
            state.closeStatus = 'loading';
        })
            .addCase(closeMarkerTk.fulfilled, (state) => {
            state.closeStatus = 'succeeded';
        });
    }
});
export const { reset: resetCloseMarker } = closeMarkerSlice.actions;
export default closeMarkerSlice.reducer;
