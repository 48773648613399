// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DAK1THUEaFWqEVksBcJQ{width:100%;display:flex;justify-content:space-between}.qP_gD6IRu4DFkNB7B4ew{padding:6px 12px 12px 30px;height:62px;width:100%}.j1jvkLaKz9aYFWks6R9E{flex:0}`, "",{"version":3,"sources":["webpack://./src/components/DamageFilter/DamageFilter.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,YAAA,CACA,6BAAA,CAGJ,sBACI,0BAAA,CACA,WAAA,CACA,UAAA,CAGJ,sBACI,MAAA","sourcesContent":[".checkboxes-row {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.slider {\n    padding: 6px 12px 12px 30px;\n    height: 62px;\n    width: 100%;\n}\n\n.exclude-select {\n    flex: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxes-row": `DAK1THUEaFWqEVksBcJQ`,
	"slider": `qP_gD6IRu4DFkNB7B4ew`,
	"exclude-select": `j1jvkLaKz9aYFWks6R9E`
};
export default ___CSS_LOADER_EXPORT___;
