import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Ditto, useDittoComponent } from 'ditto-react';
import { Button } from '../../../uikit/Button/Button';
import { IconResizer } from '../../IconResizer/IconResizer';
import { Asterisk, InProgress } from '../../icons';
import { InfoBox } from '../../InfoBox/InfoBox';
import { Modal } from '../../Modal/Modal';
import { Vignette } from '../../Vignette/Vignette';
import styles from './DataUpdateModal.module.css';
const STAR_STRING = '(*)';
export function DataUpdateModal({ onDeclineClick, onUpdateAllClick, onLaterClick }) {
    const text = useDittoComponent({ componentId: 'plannedsegments.autoupdate.text' });
    const text1 = useDittoComponent({ componentId: 'plannedsegments.autoupdate.text1' });
    const text2 = useDittoComponent({ componentId: 'plannedsegments.autoupdate.text2' });
    const IconAsterisk = (_jsx(Vignette, { className: styles['star'], content: _jsx(IconResizer, Object.assign({ size: 13.5, resizeContainer: true }, { children: _jsx(Asterisk, {}) })), color: "rgba(86, 79, 47, 1)", backgroundColor: "rgba(247, 229, 151, 1)" }));
    return (_jsx(Modal, { className: styles['modal'], title: _jsx(Ditto, { componentId: "plannedsegments.treatment.autoupdate" }), "data-test": "data-update", content: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles['content-wrapper'] }, { children: [_jsx(InfoBox, { className: styles['infobox'], content: _jsx(Ditto, { componentId: "plannedsegments.autoupdate.info" }) }), _jsx("div", { children: reactStringReplace(text, STAR_STRING, () => IconAsterisk) }), _jsxs("ul", { children: [_jsx("li", { children: reactStringReplace(text1, STAR_STRING, () => IconAsterisk) }, "text1"), _jsx("li", { children: reactStringReplace(text2, STAR_STRING, () => IconAsterisk) }, "text2")] })] })), _jsxs("div", Object.assign({ className: styles['image'] }, { children: [_jsx("img", { src: "/default-treatments-intro.png" }), _jsx(Ditto, { componentId: "plannedsegments.treatment.newfeaturemodal.text1" })] }))] }), footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "slender", color: "gray", onClick: onDeclineClick }, { children: _jsx(Ditto, { componentId: "modal.decline" }) })), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx(Button, Object.assign({ variant: "slender", icon: _jsx(InProgress, {}), color: "gray", onClick: onUpdateAllClick }, { children: _jsx(Ditto, { componentId: "updateall" }) })), _jsx(Button, Object.assign({ variant: "slender", color: "dark", onClick: onLaterClick }, { children: _jsx(Ditto, { componentId: "later" }) }))] }))] }), footerAlign: "space-between", width: 480 }));
}
