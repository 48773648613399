// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RJmYJY7uTDhjXdEqo7od{padding:24px}.mSmh4YIShI0EZ5ypa5Jw{font-weight:600;font-size:16px;line-height:19px;margin-top:42px}.SD1GDZkIHxPQJwxp9r4w{font-weight:400;font-size:14px;line-height:140%;margin-top:16px;margin-bottom:26px}.G_nAoFFDeZprjiacsVwF{height:48px}`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/NoExactMatches/NoExactMatches.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sBACI,WAAA","sourcesContent":[".container {\n    padding: 24px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 19px;\n    margin-top: 42px;\n}\n\n.description {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    margin-top: 16px;\n    margin-bottom: 26px;\n}\n\n.button {\n    height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RJmYJY7uTDhjXdEqo7od`,
	"title": `mSmh4YIShI0EZ5ypa5Jw`,
	"description": `SD1GDZkIHxPQJwxp9r4w`,
	"button": `G_nAoFFDeZprjiacsVwF`
};
export default ___CSS_LOADER_EXPORT___;
