import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useRef } from 'react';
import { Ditto } from 'ditto-react';
import { MAP_RIGHT_CLICK_ACTION_ANCHOR } from '../../common/consts/map';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { Assets, SegmentCustom, Signs2 } from '../../components/icons';
import { Notes } from '../../components/icons';
import { Task } from '../../components/icons';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { NoteCreatorContainer } from '../../containers/NoteCreatorContainer/NoteCreatorContainer';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { clearSelectedFeature } from '../../state/slices/feature';
import { clearRightClickCoordinates, setCurrentRightClickAction, setDraftPin, setDraftPinInitialPosition } from '../../state/slices/mainMap';
import { resetSegmentForm } from '../../state/slices/segmentForm';
import { useLayerChecker } from '../useLayerChecker';
import { useOnClickOutside } from '../useOnClickOutside';
import { useUserRole } from '../useUserRole';
export function useMapActionModal() {
    const dispatch = useAppDispatch();
    const { rightClickCoordinates, currentRightClickAction } = useAppSelector((state) => state.mainMap);
    const { isEditor, isAdmin } = useUserRole();
    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        dispatch(clearRightClickCoordinates());
        dispatch(setCurrentRightClickAction('none'));
    });
    const { isSignInventoryAuthorized, isSignInventoryVisible, isEditableTrafficSignsEnabled } = useLayerChecker();
    const getPopupData = useCallback((children) => {
        var _a;
        if (!rightClickCoordinates) {
            return;
        }
        return {
            latitude: rightClickCoordinates.lat,
            longitude: rightClickCoordinates.lng,
            anchor: (_a = MAP_RIGHT_CLICK_ACTION_ANCHOR[currentRightClickAction]) !== null && _a !== void 0 ? _a : 'center',
            children: _jsx("div", Object.assign({ ref: ref }, { children: children })),
            closeButton: false
        };
    }, [currentRightClickAction, rightClickCoordinates]);
    const actionModalPopup = (() => {
        if (!rightClickCoordinates) {
            return;
        }
        switch (currentRightClickAction) {
            case 'none':
                return getPopupData(_jsxs(ActionModal, Object.assign({ width: 234, ensureClickable: true }, { children: [isEditor && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Task, {}), text: _jsx(Ditto, { componentId: "createmarker" }), onClick: () => {
                                dispatch(setDraftPin(rightClickCoordinates));
                                dispatch(setDraftPinInitialPosition(rightClickCoordinates));
                                dispatch(clearRightClickCoordinates());
                                dispatch(setCurrentRightClickAction('none'));
                                dispatch(setCurrentAction('marker-creator'));
                            }, "data-test": "btn-action-create-task" })), _jsx(SelectboxEntry, { leadingIcon: _jsx(Notes, {}), text: _jsx(Ditto, { componentId: "actionmodal.createnote" }), onClick: () => dispatch(setCurrentRightClickAction('note-creator')), "data-test": "btn-action-create-note" }), isAdmin && (_jsx(SelectboxEntry, { leadingIcon: _jsx(SegmentCustom, {}), text: _jsx(Ditto, { componentId: "createplannedsegment" }), onClick: () => {
                                dispatch(clearSelectedFeature());
                                dispatch(resetSegmentForm());
                                dispatch(clearRightClickCoordinates());
                                dispatch(setCurrentRightClickAction('none'));
                                dispatch(setCurrentAction('segment-creator'));
                            }, "data-test": 'btn-action-create-planned-segment' })), isEditor && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Assets, {}), text: _jsx(Ditto, { componentId: "assets.createasset" }), onClick: () => {
                                dispatch(setDraftPin(rightClickCoordinates));
                                dispatch(setDraftPinInitialPosition(rightClickCoordinates));
                                dispatch(clearRightClickCoordinates());
                                dispatch(setCurrentRightClickAction('none'));
                                dispatch(setCurrentAction('asset-creator'));
                            }, "data-test": "btn-action-create-asset" })), isSignInventoryAuthorized &&
                            isSignInventoryVisible &&
                            isEditableTrafficSignsEnabled &&
                            isEditor && (_jsx(SelectboxEntry, { leadingIcon: _jsx(Signs2, {}), text: _jsx(Ditto, { componentId: "trafficsigns.createtrafficsign" }), onClick: () => {
                                dispatch(setDraftPin(rightClickCoordinates));
                                dispatch(setDraftPinInitialPosition(rightClickCoordinates));
                                dispatch(clearRightClickCoordinates());
                                dispatch(setCurrentRightClickAction('none'));
                                dispatch(setCurrentAction('traffic-sign-creator'));
                            }, "data-test": "btn-action-create-sign" }))] })));
            case 'note-creator':
                return getPopupData(_jsx(NoteCreatorContainer, {}));
            default:
                return;
        }
    })();
    return {
        actionModalPopup
    };
}
