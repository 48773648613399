var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { Ditto } from 'ditto-react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { BULK_MAIL_LIMIT, BULK_PRINT_LIMIT, ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { TAB_LIST_2 } from '../../common/consts/panelTab';
import { alertDataToContent, getAlertIcon, getAlertTranslation } from '../../common/convert/alert';
import { downloadAlertList } from '../../common/export';
import { alertSortingOptions } from '../../common/sort';
import { ROOT_PATH } from '../../common/url';
import { toggleItemInArray } from '../../common/utils/array';
import { getCheckboxCheckState } from '../../common/utils/checkbox';
import { getBBoxParams, getTimeParams } from '../../common/utils/params';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FilterSelectbox } from '../../components/FilterSelectbox/FilterSelectbox';
import { Download, Mail, Print, Task } from '../../components/icons';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { LeftPanelListHeader } from '../../components/LeftPanelListHeader/LeftPanelListHeader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { SelectionBar } from '../../components/SelectionBar/SelectionBar';
import { useAlertFilterHandlers } from '../../hooks/filters/useAlertFilterHandlers';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useUserRole } from '../../hooks/useUserRole';
import { getFilterParams, getSortParams } from '../../services/alert';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearHighlightedFeature, setHighlightedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { fetchAlerts, resetAlertFilter, setAlertActiveTabKey, setAlertCurrentPage, setAlertFilter, setAlertSelectedIdList, setAlertSorting, setAlertUseMapBounds } from '../../state/slices/leftPanelAlert';
import { addNotificationMessage, removeNotificationMessage } from '../../state/slices/notification';
import { Button } from '../../uikit/Button/Button';
const ALERT_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'rs-alert'
    }, isLoading: true }, idx)));
export function LeftPanelAlertContainer() {
    const panelContainerRef = useRef(null);
    const { addModal } = useModalContext();
    const { resultList, totalCountList, resultFetchStatus, filter, sort, selectedIdList, currentPage, useMapBounds, activeTabKey } = useAppSelector((state) => state.leftPanelAlert);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { alertTypes } = useAppSelector((state) => state.shared);
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const { locale } = useAppSelector((state) => state.app);
    const { targetSchema, teamAccount } = useAppSelector((state) => state.auth);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const dispatch = useAppDispatch();
    const [isMultiSelectOn, setIsMultiSelectOn] = useState(false);
    const filtersCount = filter.typeId.length;
    const { handleTypeFilterClick } = useAlertFilterHandlers();
    const dateTimeFormatter = useDateTimeFormatter();
    const { isEditor } = useUserRole();
    function handleAlertTypeGroupClick() {
        dispatch(setAlertFilter({
            typeId: filter.typeId.length === 0 ? alertTypes.map((item) => item.typeId) : []
        }));
    }
    function handleResetFilter() {
        dispatch(resetAlertFilter());
    }
    function handleSort(id) {
        dispatch(setAlertSorting(id));
        dispatch(setAlertCurrentPage(PAGE_NUMBER_FIRST));
    }
    function handleSelectAlert(id) {
        if (!isMultiSelectOn) {
            setIsMultiSelectOn(true);
        }
        dispatch(setAlertSelectedIdList(toggleItemInArray(selectedIdList, id)));
    }
    function handleClickAlert(id, pointId) {
        dispatch(setSelectedFeature({
            type: 'alert',
            id: pointId
        }));
    }
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleExport() {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationId = uuidv4();
            const abortSignalId = notificationId;
            dispatch(addNotificationMessage({
                closeAfterSeconds: 0,
                id: notificationId,
                type: 'loading',
                ditto: {
                    title: { componentId: 'toast.prepareexport' },
                    description: { componentId: 'toast.largedata' }
                },
                action: {
                    type: 'fetch-abort',
                    signalId: abortSignalId,
                    label: { componentId: 'promt.cancel' },
                    color: 'dark-gray'
                }
            }));
            try {
                yield downloadAlertList({
                    language: locale,
                    useUsaFormat: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US',
                    utcOffset: moment().utcOffset(),
                    params: {
                        timepicker: getTimeParams(selectedOption),
                        bbox: getBBoxParams(bounds, useMapBounds),
                        sort: getSortParams(sort),
                        filter: getFilterParams(filter)
                    }
                }, abortSignalId);
            }
            catch (error) {
                const errorName = error instanceof Error ? error.name : undefined;
                if (errorName === 'AbortError') {
                    return;
                }
                else {
                    dispatch(addNotificationMessage({ type: 'error' }));
                }
            }
            finally {
                dispatch(removeNotificationMessage(notificationId));
            }
        });
    }
    function handleMail() {
        return __awaiter(this, void 0, void 0, function* () {
            const alertPoints = [];
            for (const id of selectedIdList) {
                const alert = resultList.find((a) => a.recordAlertId === id);
                if (!alert) {
                    return;
                }
                alertPoints.push({
                    type: 'point',
                    id: alert.pointId
                });
            }
            addModal({
                id: 'MailModal',
                props: {
                    featureList: {
                        type: 'point',
                        value: alertPoints
                    }
                }
            });
        });
    }
    function handlePrint() {
        const pointIds = selectedIdList.map((id) => { var _a; return (_a = resultList.find((item) => item.recordAlertId === id)) === null || _a === void 0 ? void 0 : _a.pointId; });
        window.open(`/${ROOT_PATH.print}/point/${pointIds.join(',')}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleCreateMarker() {
        // TODO: POLISHING add once bulk create marker exists
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setAlertCurrentPage(toPage));
        // dispatch(fetchAlerts());
    }
    function handleClickTab(tabId) {
        dispatch(setAlertActiveTabKey(tabId));
        if (tabId === 'list') {
            dispatch(setAlertCurrentPage(PAGE_NUMBER_FIRST));
        }
    }
    function handleImageEnlargeClick(id, pointId) {
        handleClickAlert(id, pointId);
        addModal({ id: 'ImageViewerCondition' });
    }
    function handleCheckMapView() {
        dispatch(setAlertUseMapBounds(!useMapBounds));
        handleClickTab('list');
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    useEffect(() => {
        if (useMapBounds) {
            dispatch(fetchAlerts());
        }
    }, [dispatch, filter, sort, currentPage, selectedOption, useMapBounds, bounds]);
    useEffect(() => {
        if (!useMapBounds) {
            dispatch(fetchAlerts());
        }
    }, [dispatch, filter, sort, currentPage, selectedOption, useMapBounds]);
    const resultsCurrentPage = (() => {
        if (resultFetchStatus === 'loading') {
            return ALERT_LOADING_LIST;
        }
        if (resultList.length === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return resultList.map((item) => (_jsx(Result, { content: {
                type: 'rs-alert',
                value: alertDataToContent(item, dateTimeFormatter, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode)
            }, 
            // TODO: POLISHING restore selectableResultItem === item.pointId || isMultiSelectOn when multi select is implemented
            isSelectable: false, isHighlighted: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.pointId, isActive: selectedIdList.includes(item.recordAlertId), isEditor: isEditor, onSelect: () => handleSelectAlert(item.recordAlertId), onClick: () => handleClickAlert(item.recordAlertId, item.pointId), onImageEnlargeClick: () => handleImageEnlargeClick(item.recordAlertId, item.pointId), onMouseEnter: () => dispatch(setHighlightedFeature({ type: 'alert', id: item.pointId })), onMouseLeave: () => dispatch(clearHighlightedFeature()) }, item.recordAlertId)));
    })();
    const bottomMenu = (() => {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
        if (activeTabKey === 'list' && isMultiSelectOn) {
            return (_jsxs(SelectionBar, Object.assign({ leftButton: _jsx(Button, Object.assign({ disabled: !selectedIdList.length, icon: _jsx(Task, {}), color: "yellow", onClick: handleCreateMarker }, { children: _jsx(Ditto, { componentId: "createmarker" }) })) }, { children: [_jsx(ActionButton, { disabled: !selectedIdList.length || selectedIdList.length > BULK_MAIL_LIMIT, icon: _jsx(Mail, {}), onClick: handleMail }), _jsx(ActionButton, { disabled: !selectedIdList.length || selectedIdList.length > BULK_PRINT_LIMIT, icon: _jsx(Print, {}), onClick: handlePrint }), _jsx(ActionButton, { disabled: true, icon: _jsx(Download, {}), onClick: handleExport })] })));
        }
    })();
    const alertsFilter = (_jsxs(LayerFilter, { children: [_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.damagetype" }), checkState: getCheckboxCheckState(alertTypes.length, filter.typeId.length), onCheckboxClick: handleAlertTypeGroupClick }, { children: alertTypes.map((alertType) => (_jsx(FilterSelectbox, { onClick: () => handleTypeFilterClick(alertType.typeId), isChecked: filter.typeId.includes(alertType.typeId), content: getAlertTranslation(alertType.typeName), icon: getAlertIcon(alertType.typeName), color: "black" }, alertType.typeId))) })), _jsx(_Fragment, {})] }));
    return (_jsx(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: _jsx(Ditto, { componentId: "layerpanel.maplayermodal.roadsafetyalerts" }), resetFilter: {
                filtersCount,
                onReset: handleResetFilter,
                disabled: filtersCount === 0
            }, exportXLS: {
                onExport: handleExport,
                disabled: totalCountList === 0
            }, onCollapse: handleCollapse }), tabBar: isMultiSelectOn ? undefined : (_jsx(PanelButtonGroup, { tabs: TAB_LIST_2, activeKey: activeTabKey, onClickTab: handleClickTab })), listHeader: _jsx(LeftPanelListHeader, { onFilterCurrentMapViewClick: handleCheckMapView, filterUseMapBounds: !!useMapBounds, resultsCount: totalCountList, sort: {
                options: alertSortingOptions,
                current: sort,
                onSort: handleSort
            } }), activeTabKey: activeTabKey, ref: panelContainerRef, pagination: activeTabKey === 'list' && resultList.length !== 0 ? (_jsx(Pagination, { totalItems: totalCountList, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined, bottomMenu: bottomMenu }, { children: activeTabKey === 'list' ? resultsCurrentPage : alertsFilter })));
}
