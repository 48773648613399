// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PUuHARUaGw0k4Y7vqDcu{color:var(--root-dark-blue-3)}.JLHxyyzLDHaAOTmR7WjS p{margin:0}`, "",{"version":3,"sources":["webpack://./src/components/TextSuffix/TextSuffix.module.css"],"names":[],"mappings":"AAAA,sBACI,6BAAA,CAGJ,wBACI,QAAA","sourcesContent":[".suffix {\n    color: var(--root-dark-blue-3);\n}\n\n.container p {\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suffix": `PUuHARUaGw0k4Y7vqDcu`,
	"container": `JLHxyyzLDHaAOTmR7WjS`
};
export default ___CSS_LOADER_EXPORT___;
