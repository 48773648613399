import { useAppDispatch } from '../state/hooks';
import { setSelectedFeature } from '../state/slices/feature';
import { fetchPointImgData } from '../state/slices/imageViewerCondition';
import { fetchConditionNavigation } from '../state/slices/navigation';
export function useImageViewerFetcher() {
    const dispatch = useAppDispatch();
    function selectImageViewerPoint(pointUUID) {
        dispatch(setSelectedFeature({
            type: 'point',
            id: pointUUID
        }));
        dispatch(fetchPointImgData(pointUUID));
        dispatch(fetchConditionNavigation(pointUUID));
    }
    return { selectImageViewerPoint };
}
