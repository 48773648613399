// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lmWDERY32ZNFBDStNFMw{display:flex;flex-direction:column;align-items:stretch;padding:24px;background:var(--primary-white);box-shadow:0 4px 10px rgba(0,0,0,.1);border-radius:16px;width:520px;gap:8px}.Y5v6gG9QjNYUOHckOp_t{padding:0 0 0 4px;min-height:48px;font-weight:600;font-size:20px;line-height:24px}`, "",{"version":3,"sources":["webpack://./src/components/CloseTaskForm/CloseTaskForm.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,YAAA,CAEA,+BAAA,CAGA,oCAAA,CACA,kBAAA,CACA,WAAA,CACA,OAAA,CAGJ,sBACI,iBAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    padding: 24px;\n\n    background: var(--primary-white);\n    /* Elevated 02 */\n\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 16px;\n    width: 520px;\n    gap: 8px;\n}\n\n.title {\n    padding: 0 0 0 4px;\n    min-height: 48px;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lmWDERY32ZNFBDStNFMw`,
	"title": `Y5v6gG9QjNYUOHckOp_t`
};
export default ___CSS_LOADER_EXPORT___;
