// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XJ8Lfp32rxKslnnmN0XA{border-radius:16px;background:var(--primary-dark-blue);display:flex;padding:var(--Gap-GapL, 8px) 6px;align-items:center;gap:2px;font-weight:600;color:var(--primary-white)}`, "",{"version":3,"sources":["webpack://./src/components/Actionbar/CreatorHintBox/CreatorHintBox.module.css"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CACA,mCAAA,CACA,YAAA,CACA,gCAAA,CACA,kBAAA,CACA,OAAA,CACA,eAAA,CACA,0BAAA","sourcesContent":[".container {\n    border-radius: 16px;\n    background: var(--primary-dark-blue);\n    display: flex;\n    padding: var(--Gap-GapL, 8px) 6px;\n    align-items: center;\n    gap: 2px;\n    font-weight: 600;\n    color: var(--primary-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XJ8Lfp32rxKslnnmN0XA`
};
export default ___CSS_LOADER_EXPORT___;
