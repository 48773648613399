// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ht2iwpGkAe4plefyxInm{width:100%;height:40px;padding:0 4px 0 12px;display:flex;flex-direction:row;gap:2px;align-items:center;margin-bottom:-4px}.PxV0jQw9MPwczOWpQngO{font-size:14px;font-weight:400;line-height:130%;word-wrap:break-word;opacity:.6;display:flex;flex-direction:row;align-items:center;gap:6px}.Olaqi_EyA7LK_OyfcNta{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/components/DetailsPanel/Attachment/AttachmentItem/AttachmentFolder/AttachmentFolder.module.css"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,oBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,kBAAA,CACA,kBAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAGJ,sBACI,gBAAA","sourcesContent":[".container {\n    width: 100%;\n    height: 40px;\n    padding: 0 4px 0 12px;\n    display: flex;\n    flex-direction: row;\n    gap: 2px;\n\n    align-items: center;\n    margin-bottom: -4px;\n}\n\n.title {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 130%;\n    word-wrap: break-word;\n    opacity: 0.6;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 6px;\n}\n\n.plus {\n    margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ht2iwpGkAe4plefyxInm`,
	"title": `PxV0jQw9MPwczOWpQngO`,
	"plus": `Olaqi_EyA7LK_OyfcNta`
};
export default ___CSS_LOADER_EXPORT___;
