var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CancelableRequest } from '../../common/cancelableRequest';
import { PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getBBoxParams, getPaginationParams, getTimeParams } from '../../common/utils/params';
import { exportByEmail, getSignInventoryFilterParams, getSignInventoryFoldersList, getSignsInventoryList, getSortParams, signInventoryConfirm, signInventoryDelete } from '../../services/signInventory';
import { clearSelectedFeature } from './feature';
import { addNotificationMessage } from './notification';
import { fetchSignInventoryStatuses } from './shared';
import { notifyAndReloadAfterUpdate } from './signInventoryDetail';
export const signInitialFilter = {
    damageTypeIds: [],
    signTypeIds: [],
    signStatuses: [],
    filesAttached: [],
    filesNotAttached: [],
    properties: []
};
const signsParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.signInventory;
const initialState = {
    resultList: [],
    totalCountList: 0,
    resultFetchStatus: 'idle',
    folderListFetchStatus: 'idle',
    folderList: [],
    filter: Object.assign(Object.assign({}, signInitialFilter), signsParams === null || signsParams === void 0 ? void 0 : signsParams.filter),
    sort: (signsParams === null || signsParams === void 0 ? void 0 : signsParams.sort) || 'date-desc',
    selectedIdList: [],
    currentPage: PAGE_NUMBER_FIRST,
    activeTabKey: 'filter',
    isMultiSelectOn: false
};
export const fetchSignInventoryList = createDebouncedAsyncThunk('leftPanelSignInventory/list', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { useMapBounds, currentPage, filter, sort } = state.leftPanelSignInventory;
    const { bounds } = state.mainMap;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const res = yield CancelableRequest.send(getSignsInventoryList, {
        params: {
            pagination: getPaginationParams(currentPage),
            filter: getSignInventoryFilterParams(filter),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            timepicker: getTimeParams(selectedOption)
        }
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchSignInventoryFoldersList = createDebouncedAsyncThunk('leftPanelSignInventory/folders/list', (_, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInventoryFoldersList({});
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const verifySigns = createDebouncedAsyncThunk('leftPanelSignInventory/sign/verify', (signIds, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield signInventoryConfirm({ signIds });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(resetSignInventoryMultiSelect());
    dispatch(fetchSignInventoryStatuses());
    signIds.map((signId) => {
        notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: signId });
    });
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const deleteSigns = createDebouncedAsyncThunk('leftPanelSignInventory/sign/delete', (signIds, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield signInventoryDelete({ signIds });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    dispatch(fetchSignInventoryStatuses());
    signIds.map((signId) => {
        notifyAndReloadAfterUpdate(dispatch, res, { list: true, signId: signId, tree: true });
    });
    dispatch(resetSignInventoryMultiSelect());
    dispatch(clearSelectedFeature());
    return res;
}), DEBOUNCE_FETCH_LIST_MS);
export const exportSignsByEmailTk = createAppAsyncThunk('leftPanelSignInventory/exportByEmail', (req, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { sort, filter, useMapBounds } = state.leftPanelSignInventory;
    const { locale } = state.app;
    const { userAccount } = state.auth;
    const { timepicker: { selectedOption } } = state.presetsPanel;
    const { bounds } = state.mainMap;
    if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email)) {
        return;
    }
    const res = yield exportByEmail({
        params: {
            timepicker: getTimeParams(selectedOption),
            bbox: getBBoxParams(bounds, useMapBounds),
            sort: getSortParams(sort),
            filter: getSignInventoryFilterParams(filter)
        },
        signIds: req.ids,
        language: locale,
        email: userAccount.email,
        utcOffset: moment().utcOffset()
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.processingexport' } }
        }));
    }
    return res;
}));
export const leftPanelSignInventorySlice = createSlice({
    name: 'leftPanelSignInventory',
    initialState,
    reducers: {
        reset: () => initialState,
        resetSignInventoryFilter: (state) => {
            state.filter = signInitialFilter;
        },
        setSignInventoryFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setSignInventorySelectedIdList: (state, action) => {
            state.selectedIdList = action.payload;
        },
        startSignInventoryMultiSelect: (state) => {
            state.isMultiSelectOn = true;
        },
        resetSignInventoryMultiSelect: (state) => {
            state.isMultiSelectOn = false;
            state.selectedIdList = [];
        },
        setSignInventoryUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        setSignInventoryActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setSignInventoryCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setSignInventorySorting: (state, action) => {
            state.sort = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSignInventoryList.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchSignInventoryList.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.resultList = action.payload.result.signs;
                state.totalCountList = action.payload.result.signCount;
            }
        })
            .addCase(fetchSignInventoryFoldersList.pending, (state) => {
            state.resultFetchStatus = 'loading';
        })
            .addCase(fetchSignInventoryFoldersList.fulfilled, (state, action) => {
            state.resultFetchStatus = 'completed';
            if (action.payload.result) {
                state.folderList = action.payload.result.folders;
            }
        });
    }
});
export const { reset: resetLeftPanelSign, resetSignInventoryFilter, setSignInventorySelectedIdList, setSignInventoryUseMapBounds, setSignInventoryActiveTabKey, setSignInventoryCurrentPage, setSignInventoryFilter, setSignInventorySorting, resetSignInventoryMultiSelect, startSignInventoryMultiSelect } = leftPanelSignInventorySlice.actions;
export default leftPanelSignInventorySlice.reducer;
