import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export function SelectedDays({ days, locale }) {
    if (days.length === 0) {
        return null;
    }
    return (_jsx(_Fragment, { children: days
            .sort((a, b) => a - b)
            .map((value) => { var _a; return ((_a = locale === null || locale === void 0 ? void 0 : locale.localize) === null || _a === void 0 ? void 0 : _a.ordinalNumber(value)) || value; })
            .join(', ') }));
}
