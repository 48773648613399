var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Ditto } from 'ditto-react';
import { COMMON_ATTRIBUTE_TYPE } from '../../common/consts/attribute';
import { getAttributeValue } from '../../common/convert/attribute';
import { AssetIcon } from '../../common/icon';
import { fromDateUTC, toDateUTC } from '../../common/utils/time';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { Check, Plus } from '../../components/icons';
import { BulkEditModal } from '../../components/Modals/BulkEditModal/BulkEditModal';
import { TextEditor } from '../../components/Modals/BulkEditModal/TextEditor/TextEditor';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchLightAssetTk, resetBulkEditor, updateAssets, updateFormValue } from '../../state/slices/bulkEditor';
export function BulkEditAssetContainer({ onClose }) {
    const { removeModal, addModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { selectedIdList } = useAppSelector((state) => state.leftPanelAsset);
    const { form: formData, status: bulkStatus } = useAppSelector((state) => state.bulkEditor);
    const { assetTypeList, assetTypeMap, unitMap } = useAppSelector((state) => state.shared);
    const formatter = useFormatter();
    const { reloadAssetTilesData } = useTilesUpdator();
    useEffect(() => {
        if (selectedIdList.length === 0) {
            handleCloseClick();
        }
        dispatch(fetchLightAssetTk(selectedIdList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdList]);
    function handleCloseClick() {
        dispatch(resetBulkEditor());
        removeModal('BulkEditAssetModal');
        onClose && onClose();
    }
    function handleSubmitClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!formData || !formData.inventoryTypeId || selectedIdList.length === 0) {
                return;
            }
            yield dispatch(updateAssets({
                manualInventoriesIds: selectedIdList,
                inventoryTypeId: formData.inventoryTypeId,
                attributes: formData.attributes || []
            }));
            reloadAssetTilesData();
            removeModal('BulkEditAssetModal');
            onClose && onClose();
        });
    }
    function handleAddNewTypeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'asset-type',
                modalProps: {
                    type: 'asset-type',
                    shouldGoToAddNewType: true
                }
            }
        });
    }
    function handleAssetTypeClick(inventoryTypeId) {
        dispatch(updateFormValue({ field: 'inventoryTypeId', value: inventoryTypeId }));
    }
    function updateFormData(attributeId, newAttribute) {
        const currentAttributes = (formData === null || formData === void 0 ? void 0 : formData.attributes) ? [...formData.attributes] : [];
        const idx = currentAttributes.findIndex((i) => i.inventoryTypeAttributeId === attributeId);
        if (idx !== -1) {
            currentAttributes[idx] = newAttribute;
        }
        else {
            currentAttributes.push(newAttribute);
        }
        dispatch(updateFormValue({ field: 'attributes', value: currentAttributes }));
    }
    function handleSelectOptionClick(attributeId, optionId) {
        updateFormData(attributeId, {
            inventoryTypeAttributeId: attributeId,
            selectValue: optionId
        });
    }
    function handleTextConfirm(attributeId, value) {
        updateFormData(attributeId, {
            inventoryTypeAttributeId: attributeId,
            textValue: value || undefined
        });
    }
    function handleNumberConfirm(attributeId, value) {
        updateFormData(attributeId, {
            inventoryTypeAttributeId: attributeId,
            numberValue: isNaN(parseFloat(value)) ? undefined : parseFloat(value)
        });
    }
    function handleDateConfirm(attributeId, value) {
        updateFormData(attributeId, {
            inventoryTypeAttributeId: attributeId,
            dateValue: value
        });
    }
    function handleAttributeDateClick(attributeId) {
        var _a, _b;
        addModal({
            id: 'DatePickerModalContainer',
            props: {
                date: toDateUTC((_b = (_a = formData === null || formData === void 0 ? void 0 : formData.attributes) === null || _a === void 0 ? void 0 : _a.find((a) => a.inventoryTypeAttributeId === attributeId)) === null || _b === void 0 ? void 0 : _b.dateValue),
                onConfirm: (value) => handleDateConfirm(attributeId, fromDateUTC(value === null || value === void 0 ? void 0 : value.startDate))
            }
        });
    }
    const assetTypesItems = assetTypeList.map((assetType) => (_jsx(SelectboxEntry, { text: assetType.name, leadingIcon: _jsx(AssetIcon, { iconId: assetType.iconId }), trailingIcon: (formData === null || formData === void 0 ? void 0 : formData.inventoryTypeId) === assetType.id && _jsx(Check, {}), onClick: () => handleAssetTypeClick(assetType.id) }, assetType.id)));
    const addAssetTypeItem = (_jsx(SelectboxEntry, { leadingIcon: _jsx(Plus, {}), text: _jsx(Ditto, { componentId: "assets.addassettype" }), onClick: handleAddNewTypeClick, withUnderline: true }, "add-asset-type"));
    const assetType = (formData === null || formData === void 0 ? void 0 : formData.inventoryTypeId)
        ? assetTypeMap[formData.inventoryTypeId]
        : undefined;
    const content = [
        {
            key: 'assetType',
            label: _jsx(Ditto, { componentId: "assets.assettype" }),
            items: [...assetTypesItems, addAssetTypeItem],
            current: assetType ? (_jsx(FormInputCurrentSelectedValue, { text: assetType.name, icon: _jsx(AssetIcon, { iconId: assetType.iconId }) })) : undefined
        }
    ];
    assetType === null || assetType === void 0 ? void 0 : assetType.attributes.forEach((attribute) => {
        var _a, _b;
        const currentAttribute = (_a = formData === null || formData === void 0 ? void 0 : formData.attributes) === null || _a === void 0 ? void 0 : _a.find((i) => i.inventoryTypeAttributeId === attribute.id);
        const unit = attribute.unitId ? (_b = unitMap[attribute.unitId]) === null || _b === void 0 ? void 0 : _b.abbreviation : undefined;
        content.push({
            key: attribute.id.toString(),
            label: attribute.name,
            items: (function () {
                var _a, _b;
                switch (attribute.type) {
                    case COMMON_ATTRIBUTE_TYPE.select:
                        return (_a = attribute.selectOptions) === null || _a === void 0 ? void 0 : _a.map((v, idx) => (_jsx(SelectboxEntry, { text: v.name, onClick: () => handleSelectOptionClick(attribute.id, v.id) }, `${v.id}-${idx}`)));
                    case COMMON_ATTRIBUTE_TYPE.number:
                        return (_jsx(TextEditor, { value: ((_b = currentAttribute === null || currentAttribute === void 0 ? void 0 : currentAttribute.numberValue) === null || _b === void 0 ? void 0 : _b.toString()) || '', onConfirm: (v) => handleNumberConfirm(attribute.id, v), decimalSeparator: formatter.decimalSeparator, unit: unit, isNumber: true }));
                    case COMMON_ATTRIBUTE_TYPE.text:
                        return (_jsx(TextEditor, { value: (currentAttribute === null || currentAttribute === void 0 ? void 0 : currentAttribute.textValue) || '', onConfirm: (v) => handleTextConfirm(attribute.id, v) }));
                    case COMMON_ATTRIBUTE_TYPE.date:
                        return;
                }
            })(),
            current: getAttributeValue(attribute, formatter, currentAttribute, unit),
            noMenuPadding: attribute.type !== COMMON_ATTRIBUTE_TYPE.select,
            onClick: attribute.type === COMMON_ATTRIBUTE_TYPE.date
                ? () => handleAttributeDateClick(attribute.id)
                : undefined
        });
    });
    return (_jsx(BulkEditModal, { isChanged: !!formData, content: content, loading: bulkStatus === 'loading', onSubmitClick: handleSubmitClick, onCloseClick: handleCloseClick }));
}
