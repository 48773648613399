export const ALERT_COLOR = '#FF3E3E';
export const COLORS = {
    1: '#3CCEBD',
    1.5: '#6DD07F',
    2: '#A4D65D',
    2.5: '#D2D556',
    3: '#FCD300',
    3.5: '#E8B840',
    4: '#FF8435',
    4.5: '#DE8053',
    5: '#FF3E3E'
};
export const COLOR_NO_GRADE = 'rgba(129, 129, 129, 0.1)';
export const EMI_GRADE = {
    max: 5,
    min: 1
};
export const PCI_GRADE = {
    max: 100,
    min: 1
};
export const PASER_GRADE = {
    max: 10,
    min: 1
};
export const WARNING_ICON_COLOR = '#FF3E3E';
export function getColorForGrade(grade, range) {
    if (range === EMI_GRADE) {
        const roundedGrade = Math.round(grade * 2) / 2;
        const defaultColor = COLOR_NO_GRADE;
        if (grade < EMI_GRADE.min || grade > EMI_GRADE.max) {
            return defaultColor;
        }
        return COLORS[roundedGrade] || defaultColor;
    }
    if (grade === range.max) {
        return COLORS[EMI_GRADE.min];
    }
    if (grade === range.min) {
        return COLORS[EMI_GRADE.max];
    }
    if (grade < range.min) {
        return COLOR_NO_GRADE;
    }
    // transform e.g. 0-100 to 1-5
    const x1 = range.min; // 0
    const y1 = EMI_GRADE.max; // 5;
    const x2 = range.max; // 100
    const y2 = EMI_GRADE.min; // 1;
    const m = (y2 - y1) / (x2 - x1);
    const c = y1 - m * x1;
    const gradeValue = m * grade + c;
    const roundedGrade = Math.round(gradeValue * 2) / 2;
    return COLORS[roundedGrade] || COLOR_NO_GRADE;
}
export const CATEGORY_COLORS = ['#C1A7E3', '#A7D8E3', '#A7E3B2', '#F7E597', '#E3A7A7', '#C8C8C8'];
const MAX_8_BIT = 255;
const HEX_BASE = 16;
const SHORT_HEX_COLOR_LENGTH = 3;
export function colorShade(color, steps) {
    color = color.replace(/^#/, '');
    if (color.length === SHORT_HEX_COLOR_LENGTH)
        color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
    const match = color.match(/.{2}/g);
    if (!match) {
        return undefined;
    }
    const [r, g, b] = match;
    const [parsedR, parsedG, parsedB] = [
        parseInt(r, HEX_BASE) + steps,
        parseInt(g, HEX_BASE) + steps,
        parseInt(b, HEX_BASE) + steps
    ];
    const R = Math.max(Math.min(MAX_8_BIT, parsedR), 0).toString(HEX_BASE);
    const G = Math.max(Math.min(MAX_8_BIT, parsedG), 0).toString(HEX_BASE);
    const B = Math.max(Math.min(MAX_8_BIT, parsedB), 0).toString(HEX_BASE);
    const RR = (r.length < 2 ? '0' : '') + R;
    const GG = (g.length < 2 ? '0' : '') + G;
    const BB = (b.length < 2 ? '0' : '') + B;
    return `#${RR}${GG}${BB}`;
}
export const WHITE_COLOR = '#FFFFFF';
export const MARKER_PRIORITY_COLORS = {
    low: '#68CBBD',
    medium: '#FCD300',
    high: '#EE6D6C',
    noPriority: '#484848'
};
