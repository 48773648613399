// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TSvh_SgTRlvrMTf4rRpb{font-size:14px;color:var(--primary-dark-blue);opacity:.3;font-weight:400}.Tmz3v0tGaRXC4nmUvFKU{grid-column:1/3}`, "",{"version":3,"sources":["webpack://./src/components/CommonAttributeField/CommonAttributeField.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,8BAAA,CACA,UAAA,CACA,eAAA,CAGJ,sBACI,eAAA","sourcesContent":[".placeholder-text {\n    font-size: 14px;\n    color: var(--primary-dark-blue);\n    opacity: 0.3;\n    font-weight: 400;\n}\n\n.full-width {\n    grid-column: 1/3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder-text": `TSvh_SgTRlvrMTf4rRpb`,
	"full-width": `Tmz3v0tGaRXC4nmUvFKU`
};
export default ___CSS_LOADER_EXPORT___;
