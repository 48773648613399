import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { useIsInMountTransition } from '../../../../hooks/useIsInMountTransition';
import { Button } from '../../../../uikit/Button/Button';
import { Divider } from '../../../../uikit/Divider/Divider';
import { NotificationBadge } from '../../../../uikit/NotificationBadge/NotificationBadge';
import { Close, Plus, Task } from '../../../icons';
import { InfoBox } from '../../../InfoBox/InfoBox';
import { Teaser } from '../../../Teaser/Teaser';
import { SettingPanelLayout } from '../SettingPanelLayout/SettingPanelLayout';
import { SettingRow } from '../SettingRow/SettingRow';
import { CategoryColorSelect } from './CategoryColorSelect/CategoryColorSelect';
import styles from './TaskCategoriesSettingPanel.module.css';
export function TaskCategoriesSettingPanel({ options, isReadOnly, scrollToAddNewCategory = false, isCustomizationEnabled, onCategorySelectColor, onCategoryNameChange, onCategoryDelete, onCategoryCreate, onTaskCategoriesCancelClick, onTaskCategoriesSaveClick }) {
    const categoryNamePlaceholder = useDittoWrapper({ componentId: 'category-name' });
    const newCategoryNamePlaceholder = useDittoWrapper({ componentId: 'newcategory' });
    const teaserLinkHref = useDittoWrapper({ componentId: 'tasks.categories.teaser.ctaurl' });
    const refBottomAnchor = useRef(null);
    const optionsReady = useRef(false);
    const isInTransition = useIsInMountTransition();
    function scrollToBottom() {
        requestAnimationFrame(() => {
            var _a;
            (_a = refBottomAnchor.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'end' });
        });
    }
    function newCategoryClick() {
        onCategoryCreate();
        scrollToBottom();
    }
    useEffect(() => {
        if (optionsReady.current || isInTransition) {
            return;
        }
        if (options.length > 0) {
            optionsReady.current = true;
        }
        if (scrollToAddNewCategory && optionsReady.current) {
            newCategoryClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInTransition, options, scrollToAddNewCategory]);
    return (_jsxs(SettingPanelLayout, Object.assign({ overtitle: _jsx(Ditto, { componentId: "layer.marker" }), title: _jsx(Ditto, { componentId: "tasks.categories" }), icon: _jsx(Task, {}), closeButton: _jsx(Button, { variant: "square", icon: _jsx(Close, {}), color: "gray", onClick: () => onTaskCategoriesCancelClick() }), bottomLeftButton: _jsx(Button, Object.assign({ variant: "modal-action", icon: _jsx(Plus, {}), color: "gray", disabled: isReadOnly, onClick: newCategoryClick }, { children: _jsx(Ditto, { componentId: "add" }) })), bottomRightButton: _jsx(Button, Object.assign({ variant: "modal-action", color: "dark", onClick: () => onTaskCategoriesSaveClick(), disabled: isReadOnly }, { children: _jsx(Ditto, { componentId: "modal.save" }) })) }, { children: [_jsx(InfoBox, { className: styles['info-box'], content: isReadOnly ? (_jsx(Ditto, { componentId: "settings.onlyadminscaneditthefollowingelements" })) : (_jsx(Ditto, { componentId: "tasks.categories.globalsettingsinfosimple" })) }), _jsxs("div", Object.assign({ className: styles['content'] }, { children: [options.map((category) => (_jsx(SettingRow, { icon: _jsx(CategoryColorSelect, { color: category.color, isReadOnly: isReadOnly, onSelect: (color) => onCategorySelectColor(category.categoryId, color) }), placeholder: category.categoryId > 0
                            ? categoryNamePlaceholder
                            : newCategoryNamePlaceholder, value: category.categoryName, onChange: (name) => isReadOnly ? undefined : onCategoryNameChange(category.categoryId, name), onDelete: isReadOnly ? undefined : () => onCategoryDelete(category.categoryId), autoFocus: category.categoryId < 0 && category.categoryName === '' }, category.categoryId))), !isCustomizationEnabled && (_jsxs(_Fragment, { children: [_jsx(Divider, { margin: "16px" }), _jsx(Teaser, { badge: _jsx(NotificationBadge, { content: _jsx(Ditto, { componentId: "comingsoon" }), variant: "dark", fontSize: 12 }), title: _jsx(Ditto, { componentId: "tasks.categories.teaser.title" }), content: _jsx(Ditto, { componentId: "tasks.categories.teaser.description" }), linkLabel: _jsx(Ditto, { componentId: "infobutton.learnmore" }), linkHref: teaserLinkHref, margin: '8px 104px 0 24px' })] }))] })), _jsx("div", { ref: refBottomAnchor, className: styles['content-bottom-anchor'] })] })));
}
