import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TreatmentSelectModal } from '../../components/Modals/TreatmentSelectModal/TreatmentSelectModal';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
export function TreatmentSelectModalContainer({ value, hideNotSpecified, onChange, onClose }) {
    const { addModal, removeModal } = useModalContext();
    const { teamAccount } = useAppSelector((state) => state.auth);
    const { planTreatmentsList, planTreatmentUnitTypes } = useAppSelector((state) => state.shared);
    function handleChange(id) {
        onChange(id);
        handleClose();
    }
    function handleEditTreatmentsClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-treatments'
            }
        });
        handleClose();
    }
    function handleClose() {
        removeModal('TreatmentSelectModalContainer');
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (_jsx(TreatmentSelectModal, { options: planTreatmentsList, unitTypes: planTreatmentUnitTypes, value: value, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, hideNotSpecified: hideNotSpecified, onChange: handleChange, onClose: handleClose, onEditTreatmentsClick: handleEditTreatmentsClick }));
}
