var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { FORM_UPDATE_NOTIFICATION_DURATION_SECONDS } from '../../common/consts/config';
import { DEBOUNCE_SEARCH, DEBOUNCE_USER_INPUT_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getAddressForPosition } from '../../services/address';
import { addManualInventoryPhotos, deleteManualInventoryPhotos, getAssetInfoById, saveManualInventoryAttribute, updateAddress, updateManualInventoryNotes, updateManualInventoryTitle, updateManualInventoryType } from '../../services/asset';
import { tryUploadPhotos } from '../../services/fileManager';
import { getLightMarkers, getLinkedClosedMarkers, getLinkedDefaultMarkers, getSearchMarkers, linkMarkersToAssets, unlinkMarkerFromAsset, updateMarkerStatus } from '../../services/marker';
import { setIsLoadingActionbar } from './actionbar';
import { fetchAssetsTk } from './leftPanelAsset';
import { invalidateCachedLinkedMarkers } from './linkedMarkers';
import { addNotificationMessage, setNotificationMessage } from './notification';
import { fetchAssetTypes } from './shared';
const initialState = {
    isLoading: false,
    markerList: [],
    closedMarkerList: [],
    searchedMarkerList: [],
    searchStatus: 'idle',
    lookupMarkerMap: {}
};
export const fetchAssetInfoById = createAppAsyncThunk('assetDetail/fetchAssetInfoById', (id, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield CancelableRequest.send(getAssetInfoById, id);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchLookupMarkers = createAppAsyncThunk('assetDetail/fetchLookupMarkers', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getLightMarkers({
        ids
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchDefaultLinkedMarkers = createAppAsyncThunk('assetDetail/fetchDefaultLinkedMarkers', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getLinkedDefaultMarkers({
        ids
    });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchClosedLinkedMarkers = createAppAsyncThunk('assetDetail/fetchClosedLinkedMarkers', (ids, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getLinkedClosedMarkers({ ids });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}));
export const fetchSearchMarkers = createDebouncedAsyncThunk('assetDetail/fetchSearchMarkers', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSearchMarkers(req);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    return res;
}), DEBOUNCE_SEARCH);
export const linkMarker = createAppAsyncThunk('assetDetail/linkMarker', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield linkMarkersToAssets({
        linkData: [
            {
                manualInventoryId: req.assetId,
                markersIds: req.markerId
            }
        ]
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: req.assetId }, {
        type: 'success',
        ditto: {
            title: {
                componentId: 'tasks.taskandassetlinked'
            }
        }
    });
    dispatch(invalidateCachedLinkedMarkers());
    return res;
}));
export const unlinkMarker = createAppAsyncThunk('assetDetail/unlinkMarker', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield unlinkMarkerFromAsset({
        manualInventoryId: req.assetId,
        markerId: [req.markerId]
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: req.assetId }, {
        type: 'success',
        ditto: {
            title: {
                componentId: 'tasks.taskunlinked'
            }
        }
    });
    dispatch(invalidateCachedLinkedMarkers());
    return res;
}));
export const updateAssetAddressTk = createAppAsyncThunk('markerDetail/updateAssetAddressTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateAddress({
        id: update.id,
        latitude: update.position.lat,
        longitude: update.position.lng,
        addressJson: update.address || (yield getAddressForPosition(update.position)).result
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, {
        list: true,
        assetId: update.id
    });
}));
export const updateMarkerStatusTk = createAppAsyncThunk('assetDetail/updateMarkerStatusTk', (update, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateMarkerStatus({
        markerId: update.markerId,
        statusId: update.statusId
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: update.assetId });
    dispatch(invalidateCachedLinkedMarkers());
}));
function notifyAndReloadAfterUpdate(dispatch, res, fetchOptions, successMessage = {
    closeAfterSeconds: FORM_UPDATE_NOTIFICATION_DURATION_SECONDS,
    type: 'success',
    ditto: {
        title: { componentId: 'toast.changessaved' }
    }
}) {
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(setNotificationMessage(successMessage));
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.assetId) {
            dispatch(fetchAssetInfoById(fetchOptions.assetId));
        }
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.list) {
            dispatch(fetchAssetsTk());
        }
        if (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.type) {
            dispatch(fetchAssetTypes());
        }
    }
}
export const updateAssetTitle = createAppAsyncThunk('assetDetail/updateAssetTitle', (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateManualInventoryTitle({
        id: payload.id,
        title: payload.title
    });
    dispatch(setIsLoadingActionbar(false));
    const { selectedFeature } = getState().feature;
    if ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === payload.id) {
        notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: payload.id });
    }
    else {
        notifyAndReloadAfterUpdate(dispatch, res, { list: true });
    }
}));
export const updateAssetNotes = createAppAsyncThunk('assetDetail/updateAssetNotes', (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateManualInventoryNotes({
        id: payload.id,
        notes: payload.notes
    });
    dispatch(setIsLoadingActionbar(false));
    const { selectedFeature } = getState().feature;
    if ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === payload.id) {
        notifyAndReloadAfterUpdate(dispatch, res, { assetId: payload.id });
    }
    else {
        notifyAndReloadAfterUpdate(dispatch, res);
    }
}));
export const updateAssetType = createAppAsyncThunk('assetDetail/updateAssetType', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield updateManualInventoryType({
        id: payload.id,
        inventoryTypeId: payload.inventoryTypeId
    });
    dispatch(setIsLoadingActionbar(false));
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: payload.id, type: true });
}));
export const saveAssetAttributeTk = createDebouncedAsyncThunk('assetDetail/saveAssetAttributeTk', (payload, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const res = yield saveManualInventoryAttribute(payload.body);
    dispatch(setIsLoadingActionbar(false));
    const { selectedFeature } = getState().feature;
    notifyAndReloadAfterUpdate(dispatch, res, {
        list: true,
        assetId: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === payload.assetId ? payload.assetId : undefined,
        type: true
    });
}), DEBOUNCE_USER_INPUT_MS);
export const addAssetPhotosTk = createAppAsyncThunk('assetDetail/addAssetPhotosTk', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setIsLoadingActionbar(true));
    const photoUuids = yield tryUploadPhotos(payload.photos, 'manual-inventory');
    if (photoUuids && photoUuids.length) {
        const res = yield addManualInventoryPhotos({
            id: payload.assetId,
            photoUuids
        });
        notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: payload.assetId });
    }
    dispatch(setIsLoadingActionbar(false));
}));
export const removeAssetPhotos = createAppAsyncThunk('assetDetail/removeAssetPhotos', (payload, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield deleteManualInventoryPhotos({
        id: payload.id,
        photoIds: payload.photoIds
    });
    notifyAndReloadAfterUpdate(dispatch, res, { list: true, assetId: payload.id });
}));
export const assetDetailSlice = createSlice({
    name: 'assetDetail',
    initialState,
    reducers: {
        reset: () => initialState,
        clearSearchMarkers: (state) => {
            state.searchedMarkerList = [];
        },
        clearHasClosedMarkers: (state) => {
            state.hasClosedMarkers = undefined;
        },
        clearAssetInfo: (state) => {
            state.assetInfo = undefined;
        },
        setAssetSelectedFileId: (state, action) => {
            state.selectedFileId = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAssetInfoById.fulfilled, (state, action) => {
            var _a;
            state.isLoading = false;
            if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.result) {
                state.assetInfo = action.payload.result.manualInventory;
                state.markerList = [];
                state.closedMarkerList = [];
            }
        })
            .addCase(fetchAssetInfoById.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(fetchSearchMarkers.pending, (state) => {
            state.searchStatus = 'loading';
        })
            .addCase(fetchSearchMarkers.fulfilled, (state, action) => {
            state.searchStatus = 'completed';
            if (action.payload.result) {
                state.searchedMarkerList = action.payload.result.markers;
            }
        })
            .addCase(fetchClosedLinkedMarkers.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.closedMarkerList = action.payload.result.markers;
            }
        })
            .addCase(fetchDefaultLinkedMarkers.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.markerList = action.payload.result.markers;
                state.hasClosedMarkers = action.payload.result.hasClosedMarkers;
            }
        })
            .addCase(fetchLookupMarkers.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.lookupMarkerMap = Object.fromEntries(action.payload.result.markers.map((t) => [t.id, t]));
            }
        })
            .addMatcher(isAnyOf(updateAssetType.fulfilled, updateAssetNotes.fulfilled, updateAssetTitle.fulfilled, saveAssetAttributeTk.fulfilled, addAssetPhotosTk.fulfilled, removeAssetPhotos.fulfilled, fetchDefaultLinkedMarkers.fulfilled, fetchClosedLinkedMarkers.fulfilled, linkMarker.fulfilled, unlinkMarker.fulfilled, updateMarkerStatusTk.fulfilled), (state) => {
            state.updateStatus = 'success';
        })
            .addMatcher(isAnyOf(updateAssetType.pending, updateAssetNotes.pending, updateAssetTitle.pending, saveAssetAttributeTk.pending, addAssetPhotosTk.pending, removeAssetPhotos.pending, fetchDefaultLinkedMarkers.pending, fetchClosedLinkedMarkers.pending, linkMarker.pending, unlinkMarker.pending, updateMarkerStatusTk.pending), (state) => {
            state.updateStatus = 'loading';
        })
            .addMatcher(isAnyOf(updateAssetType.rejected, updateAssetNotes.rejected, updateAssetTitle.rejected, saveAssetAttributeTk.rejected, addAssetPhotosTk.rejected, removeAssetPhotos.rejected, fetchDefaultLinkedMarkers.rejected, fetchClosedLinkedMarkers.rejected, linkMarker.rejected, unlinkMarker.rejected, updateMarkerStatusTk.rejected), (state) => {
            state.updateStatus = 'failed';
        });
    }
});
export const { reset: resetAssetDetail, clearSearchMarkers, clearHasClosedMarkers, clearAssetInfo, setAssetSelectedFileId } = assetDetailSlice.actions;
export default assetDetailSlice.reducer;
