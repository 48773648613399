// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MYfuvwQLNpaXZ4AvrwqF{position:relative}.INHB2puX4hwTitwS7tZf{object-fit:contain;height:100%;width:100%}.PBv_fldnzdfdE_RZD08N{position:absolute;bottom:4px;right:4px;display:flex;padding:2px 3px;border-radius:2px;background:rgba(34,45,57,.5);color:var(--primary-white, #fff);font-size:10px}@media print{.MYfuvwQLNpaXZ4AvrwqF,.INHB2puX4hwTitwS7tZf{page-break-inside:avoid;break-inside:avoid;display:block}}`, "",{"version":3,"sources":["webpack://./src/components/PrintLayout/PrintImage/PrintImage.module.css"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,kBAAA,CACA,WAAA,CACA,UAAA,CAGJ,sBACI,iBAAA,CACA,UAAA,CACA,SAAA,CACA,YAAA,CACA,eAAA,CAEA,iBAAA,CACA,4BAAA,CACA,gCAAA,CACA,cAAA,CAGJ,aACI,4CAEI,uBAAA,CACA,kBAAA,CACA,aAAA,CAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.image {\n    object-fit: contain;\n    height: 100%;\n    width: 100%;\n}\n\n.caption {\n    position: absolute;\n    bottom: 4px;\n    right: 4px;\n    display: flex;\n    padding: 2px 3px;\n\n    border-radius: 2px;\n    background: rgba(34, 45, 57, 0.5);\n    color: var(--primary-white, #fff);\n    font-size: 10px;\n}\n\n@media print {\n    .container,\n    .image {\n        page-break-inside: avoid;\n        break-inside: avoid;\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MYfuvwQLNpaXZ4AvrwqF`,
	"image": `INHB2puX4hwTitwS7tZf`,
	"caption": `PBv_fldnzdfdE_RZD08N`
};
export default ___CSS_LOADER_EXPORT___;
