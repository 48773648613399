// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qL2pl7K4JTHcrgJ194fr.DozijeDa3ZJr_6ugjLA0+.qL2pl7K4JTHcrgJ194fr{border-top:1px solid rgba(0,0,0,.05)}.jDp276lHmYToPD3fAtN3{display:flex;flex-direction:row;align-items:center;padding:0 12px;width:100%;height:48px;font-weight:400;font-size:14px;cursor:pointer;border-radius:4px}.m6DNXiSOwNiH_lPisHfD{margin-left:12px}.lTBQgKR70q8hN7QRKMl1{margin-left:30px}.JyTJk8tAm0dQvvKUp6ty{font-weight:400;font-size:14px;line-height:130%;padding:12px}`, "",{"version":3,"sources":["webpack://./src/components/FilterGroup/FilterGroup.module.css"],"names":[],"mappings":"AAAA,iEACI,oCAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA","sourcesContent":[".wrapper.wrapper--is-open + .wrapper {\n    border-top: 1px solid rgba(0, 0, 0, 5%);\n}\n\n.container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0 12px;\n    width: 100%;\n    height: 48px;\n    font-weight: 400;\n    font-size: 14px;\n    cursor: pointer;\n    border-radius: 4px;\n}\n\n.row {\n    margin-left: 12px;\n}\n\n.filters {\n    margin-left: 30px;\n}\n\n.description {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 130%;\n    padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `qL2pl7K4JTHcrgJ194fr`,
	"wrapper--is-open": `DozijeDa3ZJr_6ugjLA0`,
	"container": `jDp276lHmYToPD3fAtN3`,
	"row": `m6DNXiSOwNiH_lPisHfD`,
	"filters": `lTBQgKR70q8hN7QRKMl1`,
	"description": `JyTJk8tAm0dQvvKUp6ty`
};
export default ___CSS_LOADER_EXPORT___;
