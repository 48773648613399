var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { Ditto } from 'ditto-react';
import mixpanel from 'mixpanel-browser';
import { ASSET_SOURCE_ID } from '../../common/consts/asset';
import { MAP_CURSOR } from '../../common/consts/map';
import { MARKER_PRIORITY_ID } from '../../common/consts/marker';
import { MIXPANEL_EVENT_NAME, MIXPANEL_EVENT_PROPERTY, MIXPANEL_GROUP_KEY } from '../../common/consts/mixpanel';
import { ASSET_CREATOR_FORM_WIDTH } from '../../common/consts/width';
import { assetToMarkerSource, pointToMarkerSource, signInventoryToMarkerSource } from '../../common/convert';
import { getAttributeFieldsWidth } from '../../common/convert/asset';
import { parseCommonAttributeValue } from '../../common/convert/attribute';
import { imageViewers } from '../../common/modal';
import { isFormulaRecurring } from '../../common/recurringRules';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { AddressSearch } from '../../components/AddressSearch/AddressSearch';
import { AssetCreator } from '../../components/AssetCreator/AssetCreator';
import { AssetLowerForm } from '../../components/AssetLowerForm/AssetLowerForm';
import { AssetTextInput } from '../../components/AssetLowerForm/AssetTextInput/AssetTextInput';
import { AssetTypeSearch } from '../../components/AssetTypeSearch/AssetTypeSearch';
import { CommonAttributeField } from '../../components/CommonAttributeField/CommonAttributeField';
import { CreatorLinkedTasks } from '../../components/CreatorLinkedTasks/CreatorLinkedTasks';
import { Attachment } from '../../components/DetailsPanel/Attachment/Attachment';
import { AttachmentItem } from '../../components/DetailsPanel/Attachment/AttachmentItem/AttachmentItem';
import { SectionDescription } from '../../components/DetailsPanel/SectionDescription/SectionDescription';
import { FilesSection } from '../../components/FilesSection/FilesSection';
import { FloatingDropdownWrapper } from '../../components/FloatingDropdownWrapper/FloatingDropdownWrapper';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { FormInputTextarea } from '../../components/FormInputTextarea/FormInputTextarea';
import { Link, LinkBreak, Overflow, Plus } from '../../components/icons';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { SelectboxEntry } from '../../components/SelectboxEntry/SelectboxEntry';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useAssetFields } from '../../hooks/forms/useAssetFields';
import { useTilesUpdator } from '../../hooks/mainMap/useTilesUpdator';
import { useAttachmentMarker } from '../../hooks/render/useAttachmentMarker';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { useAssetFiles } from '../../hooks/useAssetFiles';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useImageUploadModal } from '../../hooks/useImageUploadModal';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { addAssetCreatorMarkerIdsToLink, removeAssetCreatorFiles, removeAssetCreatorMarkerIdsToLink, removeTemporaryMarkers, resetAssetCreator, setAssetCreatorStep, uploadCreateLinkAsset } from '../../state/slices/assetCreator';
import { clearAssetInfo } from '../../state/slices/assetDetail';
import { enableMapLayer } from '../../state/slices/layer';
import { clearDraftPin, setDraftPin, setDraftPinContent, setDraftPinInitialPosition, setMapCursor } from '../../state/slices/mainMap';
import { resetMarkerCreator, setMarkerCreatorPointSource } from '../../state/slices/markerCreator';
import { addNotificationMessage } from '../../state/slices/notification';
import { fetchAddress, fetchMarkersTk, resetSharedDataHolder, resetSharedDataHolderMarkerList, setSharedDataHolderAssetFields } from '../../state/slices/sharedDataHolder';
import { Button } from '../../uikit/Button/Button';
import { Divider } from '../../uikit/Divider/Divider';
const FORM_FIELD_WIDTH = 342;
export function AssetCreatorContainer() {
    var _a;
    const { addModal, modals, removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { assetTypeList, unitList, assetTypeMap, assetFolders: foldersList } = useAppSelector((state) => state.shared);
    const { createStatus, source, step: assetCreatorStep, type: assetCreatorType, markerIdsToLink, filesToAdd } = useAppSelector((state) => state.assetCreator);
    const { markerList, assetFields: sharedDataHolderAssetFields } = useAppSelector((state) => state.sharedDataHolder);
    const { selectFilesForUpload } = useAssetFiles();
    const addressLabel = useDittoWrapper({ componentId: 'address' });
    const titleLabel = useDittoWrapper({ componentId: 'assets.assettitle' });
    const titlePlaceholder = useDittoWrapper({ componentId: 'notspecified' });
    const maximum100charactersError = useDittoWrapper({ componentId: 'maximum100characters' });
    const notesLabel = useDittoWrapper({ componentId: 'taskdescription' });
    const notesPlaceholder = useDittoWrapper({ componentId: 'assets.notes.demotext' });
    const addressErrorLabel = useDittoWrapper({
        componentId: 'address.addressisrequiredtocontinue'
    });
    const { updateAssetFields, assetFields, getAssetDraft, updateAttributes, isReadyToSubmit } = useAssetFields(Object.assign({}, sharedDataHolderAssetFields));
    const formatter = useFormatter();
    const { formatAddress } = formatter;
    const { getAttachmentMarker } = useAttachmentMarker();
    const { selectConfirmPhotos } = useImageUploadModal();
    const { reloadAssetTilesData } = useTilesUpdator();
    const { handleAddressSearchChange, handleAddressSuggestionClick, fetchedAddress, fetchedSuggestions, draftPin, addressSearchStatus } = useAddressSearch();
    const { main: map } = useMap();
    const [titleError, setTitleError] = useState('');
    const [addressError, setAddressError] = useState('');
    const addressInputRef = useRef(null);
    const step = assetCreatorStep !== null && assetCreatorStep !== void 0 ? assetCreatorStep : 0;
    const currentAssetType = assetFields.typeId ? assetTypeMap[assetFields.typeId] : undefined;
    const attributes = (currentAssetType === null || currentAssetType === void 0 ? void 0 : currentAssetType.attributes) && getAttributeFieldsWidth(currentAssetType.attributes);
    useEffect(() => {
        if (!!draftPin || !map) {
            return;
        }
        const center = map.getCenter();
        map.easeTo({
            center: center,
            padding: { top: 0, right: FORM_FIELD_WIDTH, bottom: 0, left: 0 }
        });
        dispatch(setDraftPin({ lng: center.lng, lat: center.lat }));
        dispatch(setDraftPinInitialPosition({ lng: center.lng, lat: center.lat }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!draftPin || !map) {
            return;
        }
        map.easeTo({
            center: draftPin,
            padding: { top: 0, right: FORM_FIELD_WIDTH, bottom: 0, left: 0 }
        });
        if (currentAssetType) {
            dispatch(setDraftPinContent({
                type: 'asset',
                iconId: currentAssetType.iconId
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftPin, assetFields.typeId]);
    useEffect(() => {
        const coordinates = getCoordinates();
        const mapPoint = { lng: coordinates[0], lat: coordinates[1] };
        if (mapPoint.lat > 0 && mapPoint.lng > 0) {
            dispatch(fetchAddress(mapPoint));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);
    useEffect(() => {
        var _a;
        updateAssetFields({ images: (_a = sharedDataHolderAssetFields === null || sharedDataHolderAssetFields === void 0 ? void 0 : sharedDataHolderAssetFields.images) !== null && _a !== void 0 ? _a : [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedDataHolderAssetFields]);
    useEffect(() => {
        if (!markerIdsToLink || markerIdsToLink.length === 0) {
            dispatch(resetSharedDataHolderMarkerList());
            return;
        }
        dispatch(fetchMarkersTk({ ids: markerIdsToLink }));
    }, [dispatch, markerIdsToLink]);
    useEffect(() => {
        if (draftPin && draftPin.lng > 0 && draftPin.lat > 0) {
            dispatch(fetchAddress({ lng: draftPin.lng, lat: draftPin.lat }));
        }
    }, [dispatch, draftPin]);
    useEffect(() => {
        if (draftPin && fetchedAddress) {
            setAddressError('');
        }
    }, [draftPin, fetchedAddress]);
    function getCoordinates() {
        var _a, _b;
        if (source) {
            let longitude;
            let latitude;
            if ('marker' in source) {
                longitude = source.marker.point.longitude;
                latitude = source.marker.point.latitude;
            }
            else if ('point' in source) {
                longitude = source.point.coordinates[0];
                latitude = source.point.coordinates[1];
            }
            else if ('signInventory' in source) {
                longitude = source.signInventory.offsetPosition[0];
                latitude = source.signInventory.offsetPosition[1];
            }
            return [longitude !== null && longitude !== void 0 ? longitude : 0, latitude !== null && latitude !== void 0 ? latitude : 0];
        }
        return [(_a = draftPin === null || draftPin === void 0 ? void 0 : draftPin.lng) !== null && _a !== void 0 ? _a : 0, (_b = draftPin === null || draftPin === void 0 ? void 0 : draftPin.lat) !== null && _b !== void 0 ? _b : 0];
    }
    function getThumbs() {
        let thumbs;
        if (source) {
            if ('marker' in source) {
                thumbs = source.marker.photos.map((p) => p.thumbUrl);
            }
            else if ('point' in source) {
                thumbs = [source.point.image_thumbnail_url];
            }
            else if ('signInventory' in source) {
                thumbs = [source.signInventory.imageUrl];
            }
        }
        return thumbs;
    }
    function handleClickAssetCreate(doNotNotify = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const draft = getAssetDraft();
            if (!draft) {
                return;
            }
            yield dispatch(uploadCreateLinkAsset({
                source,
                draft: Object.assign(Object.assign({}, draft), { address: fetchedAddress, coordinates: getCoordinates(), sourceId: ASSET_SOURCE_ID.webApp }),
                photosToUpload: assetFields.images.map((i) => i.file),
                fromMarkerId: source && 'marker' in source ? source.marker.id : undefined,
                markerIdsToLink: markerIdsToLink,
                filesToLink: filesToAdd,
                doNotNotify: doNotNotify
            }));
            dispatch(enableMapLayer('asset'));
            handleClickClose();
            reloadAssetTilesData();
            const imageViewer = modals.find((item) => imageViewers.includes(item.id));
            if (imageViewer) {
                removeModal(imageViewer.id);
            }
        });
    }
    function handleTitleChange(value) {
        if (value.length > 100) {
            setTitleError(maximum100charactersError);
        }
        else {
            setTitleError('');
        }
        updateAssetFields({ title: value });
    }
    function handleAssetTypeChange(value) {
        updateAssetFields({ typeId: value.id });
        dispatch(setAssetCreatorStep(1));
    }
    function handleNotesChange(value) {
        updateAssetFields({ notes: value });
    }
    function handleClickCancel() {
        dispatch(removeTemporaryMarkers()).then(() => {
            handleClickClose();
        });
    }
    function handleClickClose() {
        dispatch(resetAssetCreator());
        dispatch(setMapCursor(MAP_CURSOR.default));
        dispatch(clearAssetInfo());
        dispatch(setCurrentAction('none'));
        dispatch(clearDraftPin());
        dispatch(resetSharedDataHolder());
        dispatch(resetMarkerCreator());
    }
    function handleAddNewTypeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'asset-type',
                modalProps: {
                    type: 'asset-type',
                    shouldGoToAddNewType: true
                }
            }
        });
    }
    function handleOpenSettings() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'asset-type',
                modalProps: {
                    type: 'asset-type',
                    selectedAssetType: assetFields.typeId
                }
            }
        });
    }
    function handleImageUploadClick() {
        selectConfirmPhotos((imgs) => updateAssetFields({ images: assetFields.images.concat(imgs) }));
    }
    function handleCreateTaskClick() {
        var _a, _b;
        mixpanel.track(MIXPANEL_EVENT_NAME.createTaskWhenCreatingAsset, {
            [MIXPANEL_EVENT_PROPERTY.assetTypeName]: currentAssetType === null || currentAssetType === void 0 ? void 0 : currentAssetType.name,
            [MIXPANEL_GROUP_KEY.assetTypeId]: assetFields.typeId
        });
        if (source) {
            let markerCreatorSource = undefined;
            if ('marker' in source) {
                markerCreatorSource = {
                    coordinates: [source.marker.point.longitude, source.marker.point.latitude],
                    priorityId: source.marker.priorityTypeId,
                    statusId: source.marker.statusId
                };
            }
            else if ('point' in source) {
                markerCreatorSource = pointToMarkerSource(source.point);
            }
            else if ('signInventory' in source) {
                markerCreatorSource = signInventoryToMarkerSource(source.signInventory, source.signInventory.displayName, '', MARKER_PRIORITY_ID.noPriority);
            }
            if (markerCreatorSource) {
                dispatch(setMarkerCreatorPointSource(markerCreatorSource));
            }
        }
        else {
            const draft = getAssetDraft();
            dispatch(setMarkerCreatorPointSource(assetToMarkerSource({
                id: -1,
                title: (_a = draft === null || draft === void 0 ? void 0 : draft.title) !== null && _a !== void 0 ? _a : '',
                address: fetchedAddress !== null && fetchedAddress !== void 0 ? fetchedAddress : {},
                inventoryTypeId: (_b = draft === null || draft === void 0 ? void 0 : draft.inventoryTypeId) !== null && _b !== void 0 ? _b : 0,
                coordinates: getCoordinates(),
                createdAt: new Date().toISOString(),
                attributes: [],
                photos: [],
                markerIds: [],
                changelog: [],
                files: [],
                assetId: -1
            })));
            dispatch(setCurrentAction('marker-creator'));
        }
        dispatch(setSharedDataHolderAssetFields(assetFields));
        dispatch(enableMapLayer('marker'));
    }
    function handleLinkTaskClick() {
        addModal({
            id: 'LinkTasksModalContainer',
            props: {
                onDone: (ids) => {
                    dispatch(addAssetCreatorMarkerIdsToLink(ids));
                }
            }
        });
    }
    function handleUnlinkTaskClick(markerId) {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    dispatch(removeAssetCreatorMarkerIdsToLink([markerId]));
                },
                title: _jsx(Ditto, { componentId: "tasks.unlinktask" }),
                description: _jsx(Ditto, { componentId: "tasks.unlinktaskcopy" }),
                yesLabel: _jsx(Ditto, { componentId: "tasks.unlink" })
            }
        });
    }
    function handleFileDelete(file) {
        dispatch(removeAssetCreatorFiles([file.uuid]));
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.filedeleted' } }
        }));
    }
    function handleCustomizeClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: { initialPageId: 'asset-folders' }
        });
    }
    function handleClickNextStep(step) {
        if (typeof fetchedAddress === 'undefined') {
            setAddressError(addressErrorLabel);
            return;
        }
        setAddressError('');
        dispatch(setAssetCreatorStep(step));
    }
    function handleAddressClick(suggestion) {
        handleAddressSuggestionClick(suggestion);
        setAddressError('');
    }
    const linkedMarkerSection = source && 'marker' in source && (_jsx(Attachment, Object.assign({ title: _jsx(Ditto, { componentId: "tasks.linkedtasks" }) }, { children: _jsx(AttachmentItem, { children: getAttachmentMarker(Object.assign(Object.assign({}, source.marker), { isRecurring: isFormulaRecurring(source.marker.recurringFormula), dueDate: (_a = source.marker.dueDate) === null || _a === void 0 ? void 0 : _a.dueDate })) }) })));
    const filesSection = (_jsx(FilesSection, { id: -1, files: filesToAdd, folders: foldersList, selectFilesForUpload: selectFilesForUpload, dateTimeFormatter: formatter.formatDate, handleFileDeleteClick: handleFileDelete, handleCustomizeClick: handleCustomizeClick }));
    if (step === 0) {
        return (_jsx(AssetCreator, Object.assign({ position: assetCreatorType, onClickClose: handleClickCancel, step: step, disabled: true }, { children: _jsx(AssetTypeSearch, { assetTypeList: assetTypeList, onAssetTypeSelect: handleAssetTypeChange, onAddNewTypeClick: handleAddNewTypeClick }) })));
    }
    if (step === 1) {
        const localStep = linkedMarkerSection ? -1 : step;
        return (_jsx(AssetCreator, { position: assetCreatorType, onClickClose: handleClickCancel, onClickCreate: handleClickAssetCreate, onClickBack: () => dispatch(setAssetCreatorStep(0)), onClickNext: () => handleClickNextStep(2), onImageUploadClick: handleImageUploadClick, disabled: createStatus === 'loading' || !isReadyToSubmit, images: assetFields.images, fixedImage: getThumbs(), linkedMarkerSection: linkedMarkerSection, assetLowerForm: _jsx(AssetLowerForm, { title: _jsx(AssetTextInput, { label: titleLabel, width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleTitleChange, value: assetFields.title, maxLength: 101, error: titleError, placeholder: titlePlaceholder }), address: source ? (_jsx(FormInputText, { ref: addressInputRef, value: formatAddress(fetchedAddress), disabled: !!source, required: !source, width: ASSET_CREATOR_FORM_WIDTH, label: addressLabel, variant: "editor-field", bold: true, error: addressError })) : (_jsx(AddressSearch, Object.assign({ value: formatAddress(fetchedAddress), width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleAddressSearchChange, disabled: !!source, required: !source, status: addressSearchStatus, bold: true, error: addressError, variant: "editor-field" }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatAddress(s.address, { isShort: true }), secondaryText: formatAddress(s.address, {
                            cityAndCountry: true
                        }), onClick: () => handleAddressClick(s) }, `${s.position.lat}-${s.position.lng}`))) }))), assetTypeValue: currentAssetType, assetTypeDisabled: true, onCustomizeAttributesClick: handleOpenSettings, attributeForm: attributes &&
                    attributes.map((item) => {
                        var _a, _b, _c;
                        const field = assetFields.attributes.find((attr) => attr.inventoryTypeAttributeId === item.id);
                        const initial = (_b = (_a = field === null || field === void 0 ? void 0 : field.numberValue) !== null && _a !== void 0 ? _a : field === null || field === void 0 ? void 0 : field.selectValue) !== null && _b !== void 0 ? _b : field === null || field === void 0 ? void 0 : field.textValue;
                        return (_jsx(CommonAttributeField, { variant: "editor-field", attribute: item, initialValue: initial ? String(initial) : undefined, onChange: (value) => {
                                const v = parseCommonAttributeValue(value, item.type);
                                updateAttributes({
                                    id: item.inventoryTypeId,
                                    inventoryTypeAttributeId: item.id,
                                    [`${item.type}Value`]: v
                                });
                            }, dropMenuWidth: ASSET_CREATOR_FORM_WIDTH, unit: (_c = unitList.find((u) => u.id === item.unitId)) === null || _c === void 0 ? void 0 : _c.abbreviation, isFullWidth: item.isFullWidth, "data-test": "asset-attribute-field", formatter: formatter }, item.id));
                    }), description: _jsx(FormInputTextarea, { label: notesLabel, width: ASSET_CREATOR_FORM_WIDTH, value: assetFields.notes, onValueChange: handleNotesChange, placeholder: notesPlaceholder, variant: "editor-field", autosize: true, "data-test": "asset-notes" }), width: ASSET_CREATOR_FORM_WIDTH }), step: localStep }));
    }
    if (step === 2) {
        return (_jsxs(AssetCreator, Object.assign({ position: assetCreatorType, onClickClose: handleClickCancel, onClickCreate: () => __awaiter(this, void 0, void 0, function* () {
                yield handleClickAssetCreate();
            }), onClickBack: () => dispatch(setAssetCreatorStep(1)), step: step, disabled: createStatus === 'loading' || !isReadyToSubmit }, { children: [_jsx(CreatorLinkedTasks, Object.assign({ infoBox: _jsx(InfoBox, { content: _jsx(Ditto, { componentId: "tasks.linktasksthatneedtobedoneonthisasset" }) }) }, { children: _jsx(Attachment, Object.assign({ title: _jsx(Ditto, { componentId: "layer.tasks" }), count: markerList.length, emptyButtons: markerList.length === 0 && (_jsx(Button, Object.assign({ variant: "fullwidth", size: "large", color: "gray-2", extraRounded: true, onClick: handleCreateTaskClick }, { children: _jsx(Ditto, { componentId: "createmarker" }) }))), description: markerList.length > 0 && (_jsx(SectionDescription, { subtitle: _jsx(Ditto, { componentId: "assets.opentasks" }), plusButton: _jsx(FloatingDropdownWrapper, { control: _jsx(ActionButton, { icon: _jsx(Plus, {}) }), menu: _jsxs(ActionModal, Object.assign({ width: 332 }, { children: [_jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.createnewtask" }), leadingIcon: _jsx(Plus, {}), onClick: handleCreateTaskClick }), _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.linkexistingtask" }), leadingIcon: _jsx(Link, {}), onClick: handleLinkTaskClick })] })) }) })) }, { children: markerList.map((marker) => {
                            var _a;
                            return (_jsx(AttachmentItem, Object.assign({ variant: "outline", trailingElement: _jsx(FloatingDropdownWrapper, { control: _jsx(ActionButton, { icon: _jsx(Overflow, {}) }), menu: _jsx(ActionModal, Object.assign({ width: 324 }, { children: _jsx(SelectboxEntry, { text: _jsx(Ditto, { componentId: "tasks.unlinktask" }), leadingIcon: _jsx(LinkBreak, {}), color: "red", onClick: () => handleUnlinkTaskClick(marker.id) }) })) }) }, { children: getAttachmentMarker(Object.assign(Object.assign({}, marker), { isRecurring: isFormulaRecurring(marker.recurringFormula), dueDate: (_a = marker.dueDate) === null || _a === void 0 ? void 0 : _a.dueDate })) }), marker.id));
                        }) })) })), _jsx(Divider, {}), filesSection] })));
    }
    return (_jsx(AssetCreator, { position: assetCreatorType, onClickClose: handleClickClose, onClickCreate: handleClickAssetCreate, onImageUploadClick: handleImageUploadClick, disabled: createStatus === 'loading' || !isReadyToSubmit, images: assetFields.images, fixedImage: getThumbs(), assetLowerForm: _jsx(AssetLowerForm, { title: _jsx(AssetTextInput, { label: titleLabel, width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleTitleChange, value: assetFields.title, maxLength: 101, error: titleError, placeholder: titlePlaceholder }), address: source ? (_jsx(FormInputText, { value: formatAddress(fetchedAddress), disabled: true, width: ASSET_CREATOR_FORM_WIDTH, label: addressLabel, variant: "ghost" })) : (_jsx(AddressSearch, Object.assign({ value: formatAddress(fetchedAddress), width: ASSET_CREATOR_FORM_WIDTH, onValueChange: handleAddressSearchChange, required: true, status: addressSearchStatus }, { children: fetchedSuggestions.map((s) => (_jsx(SelectboxEntry, { text: formatAddress(s.address, { isShort: true }), secondaryText: formatAddress(s.address, {
                        cityAndCountry: true
                    }), onClick: () => handleAddressClick(s) }, `${s.position.lat}-${s.position.lng}`))) }))), assetTypeValue: currentAssetType, assetTypesList: assetTypeList, onAssetTypeChange: handleAssetTypeChange, onAddNewTypeClick: handleAddNewTypeClick, onCustomizeAttributesClick: handleOpenSettings, attributeForm: attributes === null || attributes === void 0 ? void 0 : attributes.map((item) => {
                var _a;
                return (_jsx(CommonAttributeField, { variant: "editor-field", attribute: item, onChange: (value) => {
                        const v = parseCommonAttributeValue(value, item.type);
                        updateAttributes({
                            id: item.inventoryTypeId,
                            inventoryTypeAttributeId: item.id,
                            [`${item.type}Value`]: v
                        });
                    }, dropMenuWidth: ASSET_CREATOR_FORM_WIDTH, unit: (_a = unitList.find((u) => u.id === item.unitId)) === null || _a === void 0 ? void 0 : _a.abbreviation, isFullWidth: item.isFullWidth, formatter: formatter }, item.id));
            }), description: _jsx(FormInputTextarea, { label: notesLabel, width: ASSET_CREATOR_FORM_WIDTH, value: assetFields.notes, onValueChange: handleNotesChange, placeholder: notesPlaceholder, variant: "editor-field", autosize: true }), width: ASSET_CREATOR_FORM_WIDTH }) }));
}
