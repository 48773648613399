var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef } from 'react';
import { Ditto } from 'ditto-react';
import { v4 as uuidv4 } from 'uuid';
import { BULK_MAIL_LIMIT, BULK_PRINT_LIMIT, ITEMS_PER_PAGE, PAGE_NUMBER_FIRST } from '../../common/consts/config';
import { TAB_LIST_3 } from '../../common/consts/panelTab';
import { PLAN_PROPERTY_ID } from '../../common/consts/plan';
import { planDataToContent } from '../../common/convert';
import { downloadPlanList, downloadPlanListByIds } from '../../common/export';
import { LAYER_PATH } from '../../common/layer';
import { selectRangeIds } from '../../common/multiselect';
import { planSortingOptions } from '../../common/sort';
import { ROOT_PATH } from '../../common/url';
import { toggleItemInArray } from '../../common/utils/array';
import { getCheckboxCheckState, getCheckboxCheckStateFromObject } from '../../common/utils/checkbox';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { DataGridTableActionbar } from '../../components/DataGridTable/DataGridTableActionbar/DataGridTableActionbar';
import { DataGridTableBudgetTabBar } from '../../components/DataGridTable/DataGridTableBudgetTabBar/DataGridTableBudgetTabBar';
import { DataGridTableTotalCount } from '../../components/DataGridTable/DataGridTableTotalCount/DataGridTableTotalCount';
import { FilterGroupWrapper } from '../../components/FilterGroupWrapper/FilterGroupWrapper';
import { FormInputText } from '../../components/FormInputText/FormInputText';
import { Close, Download, Edit, Mail, Print } from '../../components/icons';
import { LayerFilter } from '../../components/LayerFilter/LayerFilter';
import { LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { NoExactMatches } from '../../components/LeftPanel/NoExactMatches/NoExactMatches';
import { NoPlannedSegments } from '../../components/LeftPanel/NoPlannedSegments/NoPlannedSegments';
import { LeftPanelListHeader } from '../../components/LeftPanelListHeader/LeftPanelListHeader';
import { Pagination } from '../../components/Pagination/Pagination';
import { PanelButtonGroup } from '../../components/PanelButtonGroup/PanelButtonGroup';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Result } from '../../components/Result/Result';
import { SelectionBar } from '../../components/SelectionBar/SelectionBar';
import { TypedDitto } from '../../components/TypedDitto';
import { useFilterWrapperHandlers } from '../../hooks/filters/useFilterWrapperHandlers';
import { usePlanFilterHandlers } from '../../hooks/filters/usePlanFilterHandlers';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { usePlanFilters } from '../../hooks/render/usePlanFilters';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { usePlanTableView } from '../../hooks/usePlanTableView';
import { useRemainingServiceLifeIntroModal } from '../../hooks/useRemainingServiceLifeIntroModal';
import { useTabBar } from '../../hooks/useTabBar';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentAction } from '../../state/slices/app';
import { clearHighlightedFeature, clearSelectedFeature, setHighlightedFeature, setSelectedFeature } from '../../state/slices/feature';
import { setSelectedLayerId } from '../../state/slices/layer';
import { fetchPlans, resetPlanFilter, resetPlanMultiSelect, resetPlanSearchBar, setFirstPage, setPlanActiveTabKey, setPlanCurrentPage, setPlanFilter, setPlanSelectedIdList, setPlanSorting, setTableBudgetTabYear, setUseMapBounds, startPlanMultiSelect, startPlanSearchBar } from '../../state/slices/leftPanelPlan';
import { addNotificationMessage, removeNotificationMessage } from '../../state/slices/notification';
import { Button } from '../../uikit/Button/Button';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { AppliedFilterBarContainer } from '../AppliedFilterBarContainer/AppliedFilterBarContainer';
const PLAN_LOADING_LIST = Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, idx) => (_jsx(Result, { content: {
        type: 'planned-segment'
    }, isLoading: true }, idx)));
export function LeftPanelPlanContainer() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const panelContainerRef = useRef(null);
    const searchInputRef = useRef(null);
    useRemainingServiceLifeIntroModal();
    const { addModal } = useModalContext();
    const { resultList, totalCount, filter, sort, selectedIdList, firstThousandIds, resultFetchStatus, activeTabKey, currentPage, useMapBounds, isMultiSelectOn, isSearchBarOn, table: { budgetTabYear } } = useAppSelector((state) => state.leftPanelPlan);
    const { targetSchema, teamAccount } = useAppSelector((state) => state.auth);
    const { selectedFeature } = useAppSelector((state) => state.feature);
    const { planTreatmentsList, planFilters } = useAppSelector((state) => state.shared);
    const { bounds } = useAppSelector((state) => state.mainMap);
    const dispatch = useAppDispatch();
    const { getPlanPriorityFilterOptions, getPlanStatusFilterOptions, getPlanCategoryFilterOptions, getPlanBudgetFilterOptions, getPlanTreatmentFilterOptions, getPlanFirmlyPlannedFilterOptions, getPlanPlannedForFilterOptions, getPlanAreaFilterOptions, getPlanCostsFilterOptions, getPlanYearsOfUseFilterOptions, getPlanEfficiencyFilterOptions, getPlanCustomDataFilterOptions } = usePlanFilters();
    const { handleStatusGroupClick, handleCategoryGroupClick, handlePriorityGroupClick, handleBudgetGroupClick, handleTreatmentGroupClick, handleFirmlyPlannedGroupClick, handlePlannedForGroupClick, handleAreaGroupClick, handleCostsGroupClick, handleYearsOfUseGroupClick, handleEfficiencyGroupClick, handleCustomDataGroupClick } = usePlanFilterHandlers();
    const { handleFilterGroupWrapperToggle } = useFilterWrapperHandlers();
    const formatter = useFormatter();
    const unitFormatter = useUnitFormatter();
    const { locale } = useAppSelector((state) => state.app);
    const { dataTable } = usePlanTableView({
        handleCheckSelectAll,
        handleClickPlan,
        handlePageChange,
        handlePlanSelect,
        panelContainerRef
    });
    const { timepicker: { selectedOption } } = useAppSelector((state) => state.presetsPanel);
    const filtersCount = (((_a = filter.categoryIds) === null || _a === void 0 ? void 0 : _a.length) || 0) +
        (((_b = filter.treatmentIds) === null || _b === void 0 ? void 0 : _b.length) || 0) +
        (((_c = filter.priorityIds) === null || _c === void 0 ? void 0 : _c.length) || 0) +
        (((_d = filter.statusIds) === null || _d === void 0 ? void 0 : _d.length) || 0) +
        (filter.thirdParty !== undefined ? 1 : 0) +
        (filter.completionYearAndMonth !== undefined ? 1 : 0) +
        (filter.areaMin || filter.areaMax ? 1 : 0) +
        (filter.areaIncludeNotSet !== undefined ? 1 : 0) +
        (filter.costsMin || filter.costsMax ? 1 : 0) +
        (filter.costsIncludeNotSet !== undefined ? 1 : 0) +
        (filter.yearsOfUseMin || filter.yearsOfUseMax ? 1 : 0) +
        (filter.yearsOfUseIncludeNotSet !== undefined ? 1 : 0) +
        (((_e = filter.efficiencyIds) === null || _e === void 0 ? void 0 : _e.length) || 0) +
        (((_f = filter.budgetIds) === null || _f === void 0 ? void 0 : _f.length) || 0) +
        Object.keys(filter.customData || []).length;
    const { currentTab, setCurrentTab } = useTabBar({
        tabsCount: 2
    });
    const budgetYears = useMemo(() => planFilters.budget.filter((item) => {
        var _a;
        return filter.budgetIds &&
            (filter.budgetIds.length === 0 ||
                (filter.budgetIds.length > 0 && ((_a = filter.budgetIds) === null || _a === void 0 ? void 0 : _a.includes(item.id))));
    }), [planFilters.budget, filter.budgetIds]);
    const tabs = useMemo(() => [
        {
            id: undefined,
            label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "plannedsegments.tableview.all" }), _jsx(NotificationBadge, { content: budgetYears.length > 0
                            ? budgetYears.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
                            : totalCount })] }))
        },
        ...budgetYears
            .filter((budget) => budget.count > 0)
            .map((budget) => ({
            id: budget.id,
            label: (_jsxs(_Fragment, { children: [budget.name, _jsx(NotificationBadge, { content: budget.count })] }))
        }))
    ], [totalCount, budgetYears]);
    const searchForLabel = useDittoWrapper({ componentId: 'search.searchfor' });
    function handleClickPlan(id) {
        dispatch(setSelectedFeature({ type: 'plan', id }));
    }
    function handleSearch() {
        if (!isSearchBarOn) {
            dispatch(startPlanSearchBar());
            if (activeTabKey === 'filter') {
                dispatch(setPlanActiveTabKey('list'));
            }
        }
        else {
            dispatch(resetPlanSearchBar());
        }
    }
    function handleResetFilter() {
        dispatch(resetPlanFilter());
        dispatch(resetPlanSearchBar());
    }
    function handleResetSelection() {
        dispatch(resetPlanMultiSelect());
        dispatch(clearSelectedFeature());
    }
    function handlePlanSelect(id, event) {
        if (!isMultiSelectOn) {
            dispatch(startPlanMultiSelect());
        }
        dispatch(setPlanSelectedIdList(toggleItemInArray(selectedIdList, id)));
        if (event === null || event === void 0 ? void 0 : event.nativeEvent.shiftKey) {
            if (!resultList)
                return;
            dispatch(setPlanSelectedIdList(selectRangeIds(id, (resultList === null || resultList === void 0 ? void 0 : resultList.map((plan) => (Object.assign({ id: plan.planId }, plan)))) || [], selectedIdList)));
        }
    }
    function handleSort(id) {
        let newSort = {};
        switch (id) {
            case 'created_at-asc':
                newSort = { sortBy: 'created_at', sortOrder: 'asc' };
                break;
            case 'created_at-desc':
                newSort = { sortBy: 'created_at', sortOrder: 'desc' };
                break;
            case 'completion_year_and_month-asc':
                newSort = { sortBy: 'completion_year_and_month', sortOrder: 'asc' };
                break;
            case 'completion_year_and_month-desc':
                newSort = { sortBy: 'completion_year_and_month', sortOrder: 'desc' };
                break;
            case 'costs-asc':
                newSort = { sortBy: 'costs', sortOrder: 'asc' };
                break;
            case 'costs-desc':
                newSort = { sortBy: 'costs', sortOrder: 'desc' };
                break;
            case 'grade-asc':
                newSort = { sortBy: 'grade', sortOrder: 'asc' };
                break;
            case 'grade-desc':
                newSort = { sortBy: 'grade', sortOrder: 'desc' };
                break;
            case 'priority-asc':
                newSort = { sortBy: 'priority', sortOrder: 'asc' };
                break;
            case 'priority-desc':
                newSort = { sortBy: 'priority', sortOrder: 'desc' };
                break;
            case 'area-asc':
                newSort = { sortBy: 'area', sortOrder: 'asc' };
                break;
            case 'area-desc':
                newSort = { sortBy: 'area', sortOrder: 'desc' };
                break;
            case 'name-asc':
                newSort = { sortBy: 'name', sortOrder: 'asc' };
                break;
            case 'name-desc':
                newSort = { sortBy: 'name', sortOrder: 'desc' };
                break;
            default:
                console.error('unknown node edges sort id', id);
        }
        dispatch(setPlanSorting(newSort));
        dispatch(setFirstPage());
    }
    function handleCollapse() {
        dispatch(setSelectedLayerId());
    }
    function handleExport() {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationId = uuidv4();
            const abortSignalId = notificationId;
            dispatch(addNotificationMessage({
                closeAfterSeconds: 0,
                id: notificationId,
                type: 'loading',
                ditto: {
                    title: { componentId: 'toast.prepareexport' },
                    description: { componentId: 'toast.largedata' }
                },
                action: {
                    type: 'fetch-abort',
                    signalId: abortSignalId,
                    label: { componentId: 'promt.cancel' },
                    color: 'dark-gray'
                }
            }));
            try {
                yield downloadPlanList(Object.assign(Object.assign(Object.assign({}, filter), sort), { language: locale, countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode }), abortSignalId);
            }
            catch (error) {
                const errorName = error instanceof Error ? error.name : undefined;
                if (errorName === 'AbortError') {
                    return;
                }
                else {
                    dispatch(addNotificationMessage({ type: 'error' }));
                }
            }
            finally {
                dispatch(removeNotificationMessage(notificationId));
            }
        });
    }
    function handleExportList() {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationId = uuidv4();
            const abortSignalId = notificationId;
            dispatch(addNotificationMessage({
                closeAfterSeconds: 0,
                id: notificationId,
                type: 'loading',
                ditto: {
                    title: { componentId: 'toast.prepareexport' },
                    description: { componentId: 'toast.largedata' }
                },
                action: {
                    type: 'fetch-abort',
                    signalId: abortSignalId,
                    label: { componentId: 'promt.cancel' },
                    color: 'dark-gray'
                }
            }));
            try {
                yield downloadPlanListByIds({
                    planIds: selectedIdList,
                    language: locale,
                    countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode
                }, abortSignalId);
            }
            catch (error) {
                const errorName = error instanceof Error ? error.name : undefined;
                if (errorName === 'CancelError') {
                    return;
                }
                else {
                    dispatch(addNotificationMessage({ type: 'error' }));
                }
            }
            finally {
                dispatch(removeNotificationMessage(notificationId));
            }
        });
    }
    function handleMail() {
        return __awaiter(this, void 0, void 0, function* () {
            addModal({
                id: 'MailModal',
                props: {
                    featureList: {
                        type: 'plan',
                        value: selectedIdList
                    }
                }
            });
        });
    }
    function handlePrint() {
        window.open(`/${ROOT_PATH.print}/${LAYER_PATH.plan}/${selectedIdList.join(',')}?targetSchema=${targetSchema}`, '_blank');
    }
    function handleEdit() {
        if (selectedIdList.length === 1) {
            handleClickPlan(selectedIdList[0]);
            return;
        }
        addModal({
            id: 'BulkEditPlanModal',
            props: {}
        });
    }
    function handlePageChange(toPage) {
        var _a;
        (_a = panelContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setPlanCurrentPage(toPage));
    }
    function handleClickTab(tabId) {
        dispatch(setPlanActiveTabKey(tabId));
        if (tabId === 'list' || tabId === 'table') {
            dispatch(setPlanCurrentPage(PAGE_NUMBER_FIRST));
        }
    }
    function handleCheckMapView() {
        // TODO: probably filters doesn't work with map bounds
        dispatch(setUseMapBounds(!useMapBounds));
        handleClickTab('list');
    }
    function handleCheckSelectAll() {
        if (!isMultiSelectOn) {
            dispatch(startPlanMultiSelect());
        }
        dispatch(setPlanSelectedIdList(firstThousandIds.length !== selectedIdList.length ? firstThousandIds : []));
    }
    function handleClickDone() {
        handleClickTab('list');
    }
    function handleCreatePlannedSegmentClick() {
        dispatch(setCurrentAction('segment-creator'));
    }
    function onBudgetEditClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-budget-and-network'
            }
        });
    }
    function onTreatmentsEditClick() {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-treatments'
            }
        });
    }
    function onCustomPropertyEditClick(columnKey) {
        addModal({
            id: 'GlobalSettingsModal',
            props: {
                initialPageId: 'plan-properties',
                modalProps: {
                    type: 'plan-properties',
                    selectedCustomPropertyKey: columnKey
                }
            }
        });
    }
    useEffect(() => {
        dispatch(fetchPlans());
    }, [dispatch, filter, useMapBounds, sort, bounds, selectedOption]);
    useEffect(() => {
        dispatch(fetchPlans({ fetchSumLine: false }));
    }, [dispatch, currentPage]);
    useEffect(() => {
        if (resultList === null) {
            dispatch(setPlanActiveTabKey('list'));
        }
    }, [dispatch, resultList]);
    useEffect(() => {
        dispatch(fetchPlans());
        return () => {
            dispatch(resetPlanSearchBar());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    // reset budget tab filter when user don't use table view
    useEffect(() => {
        if (activeTabKey !== 'table' && budgetTabYear !== undefined) {
            dispatch(setTableBudgetTabYear(undefined));
            dispatch(fetchPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey]);
    useEffect(() => {
        var _a;
        if (isSearchBarOn) {
            (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isSearchBarOn]);
    useEffect(() => {
        dispatch(fetchPlans());
        setCurrentTab(budgetTabYear !== undefined ? tabs.findIndex((tab) => tab.id === budgetTabYear) : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, setCurrentTab, budgetTabYear]);
    useEffect(() => {
        if (selectedIdList.length === 0 && isMultiSelectOn) {
            dispatch(resetPlanMultiSelect());
        }
    }, [dispatch, isMultiSelectOn, selectedIdList.length]);
    useEffect(() => {
        if (activeTabKey === 'filter' && isMultiSelectOn) {
            dispatch(resetPlanMultiSelect());
        }
    }, [dispatch, activeTabKey, isMultiSelectOn]);
    function handleTabClick(index) {
        if (index === 0) {
            dispatch(setTableBudgetTabYear(undefined));
        }
        else {
            dispatch(setTableBudgetTabYear(tabs[index].id));
        }
    }
    const resultsCurrentPage = (() => {
        if (resultFetchStatus === 'loading') {
            return PLAN_LOADING_LIST;
        }
        if ((resultList === null || resultList === void 0 ? void 0 : resultList.length) === 0) {
            return _jsx(NoExactMatches, { onResetClick: handleResetFilter });
        }
        return resultList === null || resultList === void 0 ? void 0 : resultList.slice(0, ITEMS_PER_PAGE).map((item) => (_jsx(Result, { content: {
                type: 'planned-segment',
                value: planDataToContent(locale, item, planTreatmentsList, formatter, unitFormatter)
            }, isSelectable: isMultiSelectOn, isHighlighted: (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.id) === item.planId, isActive: selectedIdList.includes(item.planId), onSelect: (event) => handlePlanSelect(item.planId, event), onClick: () => handleClickPlan(item.planId), onMouseEnter: () => dispatch(setHighlightedFeature({ type: 'plan', id: item.planId })), onMouseLeave: () => dispatch(clearHighlightedFeature()) }, item.planId)));
    })();
    const hasAnyPlannedForValues = planFilters.plannedFor.some((value) => value.id > 0 && value.count > 0);
    const planFilter = (_jsxs(LayerFilter, { children: [planFilters.budget.length > 0 && (_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.budget.budget" }), link: {
                    text: _jsx(Ditto, { componentId: "edit" }),
                    onClick: () => onBudgetEditClick()
                }, checkState: getCheckboxCheckState(planFilters.budget.length, (_g = filter.budgetIds) === null || _g === void 0 ? void 0 : _g.length), onCheckboxClick: handleBudgetGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef) }, { children: getPlanBudgetFilterOptions() }))), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.measure" }), checkState: getCheckboxCheckState(planFilters.treatment.length, (_h = filter.treatmentIds) === null || _h === void 0 ? void 0 : _h.length), onCheckboxClick: handleTreatmentGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), link: {
                    text: _jsx(Ditto, { componentId: "edit" }),
                    onClick: () => onTreatmentsEditClick()
                } }, { children: getPlanTreatmentFilterOptions() })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.priority" }), checkState: getCheckboxCheckState(planFilters.priority.length, (_j = filter.priorityIds) === null || _j === void 0 ? void 0 : _j.length), onCheckboxClick: handlePriorityGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !((_k = filter.priorityIds) === null || _k === void 0 ? void 0 : _k.length) }, { children: getPlanPriorityFilterOptions() })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.status" }), checkState: getCheckboxCheckState(planFilters.status.length, (_l = filter.statusIds) === null || _l === void 0 ? void 0 : _l.length), onCheckboxClick: handleStatusGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !((_m = filter.statusIds) === null || _m === void 0 ? void 0 : _m.length) }, { children: getPlanStatusFilterOptions() })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.year" }), checkState: getCheckboxCheckStateFromObject(filter, [
                    'yearsOfUseMin',
                    'yearsOfUseMax',
                    'yearsOfUseIncludeNotSet'
                ]), onCheckboxClick: handleYearsOfUseGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: filter.yearsOfUseMin === undefined &&
                    filter.yearsOfUseMax === undefined &&
                    !filter.yearsOfUseIncludeNotSet }, { children: getPlanYearsOfUseFilterOptions({ panelContainerRef }) })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "filter.costs" }), checkState: getCheckboxCheckStateFromObject(filter, [
                    'costsMin',
                    'costsMax',
                    'costsIncludeNotSet'
                ]), onCheckboxClick: handleCostsGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: filter.costsMin === undefined &&
                    filter.costsMax === undefined &&
                    !filter.costsIncludeNotSet }, { children: getPlanCostsFilterOptions({ panelContainerRef }) })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.profitability" }), checkState: getCheckboxCheckState(planFilters.efficiency.length, (_o = filter.efficiencyIds) === null || _o === void 0 ? void 0 : _o.length), onCheckboxClick: handleEfficiencyGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !((_p = filter.efficiencyIds) === null || _p === void 0 ? void 0 : _p.length) }, { children: getPlanEfficiencyFilterOptions() })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.firmlyplanned" }), checkState: filter.thirdParty !== undefined ? 'checked' : 'not-checked', onCheckboxClick: handleFirmlyPlannedGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: filter.thirdParty === undefined }, { children: getPlanFirmlyPlannedFilterOptions() })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.budget.type" }), checkState: getCheckboxCheckState(planFilters.category.length, (_q = filter.categoryIds) === null || _q === void 0 ? void 0 : _q.length), onCheckboxClick: handleCategoryGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !((_r = filter.categoryIds) === null || _r === void 0 ? void 0 : _r.length) }, { children: getPlanCategoryFilterOptions() })), _jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.area" }), checkState: getCheckboxCheckStateFromObject(filter, [
                    'areaMin',
                    'areaMax',
                    'areaIncludeNotSet'
                ]), onCheckboxClick: handleAreaGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: filter.areaMin === undefined &&
                    filter.areaMax === undefined &&
                    !filter.areaIncludeNotSet }, { children: getPlanAreaFilterOptions({ panelContainerRef }) })), hasAnyPlannedForValues && (_jsx(FilterGroupWrapper, Object.assign({ content: _jsx(Ditto, { componentId: "plannedsegments.plannedfor" }), checkState: filter.completionYearAndMonth !== undefined ? 'checked' : 'not-checked', onCheckboxClick: handlePlannedForGroupClick, onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: filter.completionYearAndMonth === undefined }, { children: getPlanPlannedForFilterOptions() }))), (_s = planFilters.customData) === null || _s === void 0 ? void 0 : _s.map((customData) => {
                var _a, _b;
                const customFilterData = filter.customData && filter.customData[customData.columnKey];
                switch (customData.propertyTypeId) {
                    case PLAN_PROPERTY_ID.text:
                        return (_jsx(FilterGroupWrapper, Object.assign({ content: customData.title, link: {
                                text: _jsx(Ditto, { componentId: "edit" }),
                                onClick: () => onCustomPropertyEditClick(customData.columnKey)
                            }, checkState: getCheckboxCheckStateFromObject(customFilterData, [
                                'isSet',
                                'isNotSet'
                            ]), onCheckboxClick: () => handleCustomDataGroupClick(customData, ['isSet', 'isNotSet']), onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !customFilterData }, { children: getPlanCustomDataFilterOptions({ customData }) }), `custom-property-${customData.columnKey}`));
                    case PLAN_PROPERTY_ID.numeric:
                        return (_jsx(FilterGroupWrapper, Object.assign({ content: customData.title, link: {
                                text: _jsx(Ditto, { componentId: "edit" }),
                                onClick: () => onCustomPropertyEditClick(customData.columnKey)
                            }, checkState: getCheckboxCheckStateFromObject(customFilterData, [
                                'min',
                                'max',
                                'isNotSet'
                            ]), onCheckboxClick: () => handleCustomDataGroupClick(customData, [
                                'min',
                                'max',
                                'isNotSet'
                            ]), onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !customFilterData }, { children: getPlanCustomDataFilterOptions({
                                customData,
                                panelContainerRef
                            }) }), `custom-property-${customData.columnKey}`));
                    case PLAN_PROPERTY_ID.list:
                        return (_jsx(FilterGroupWrapper, Object.assign({ content: customData.title, link: {
                                text: _jsx(Ditto, { componentId: "edit" }),
                                onClick: () => onCustomPropertyEditClick(customData.columnKey)
                            }, checkState: getCheckboxCheckState((((_a = customData.options) === null || _a === void 0 ? void 0 : _a.length) || 0) + 1, ((_b = customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.options) === null || _b === void 0 ? void 0 : _b.length) || 0), onCheckboxClick: () => handleCustomDataGroupClick(customData, ['options']), onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !customFilterData }, { children: getPlanCustomDataFilterOptions({ customData }) }), `custom-property-${customData.columnKey}`));
                    case PLAN_PROPERTY_ID.date:
                        return (_jsx(FilterGroupWrapper, Object.assign({ content: customData.title, link: {
                                text: _jsx(Ditto, { componentId: "edit" }),
                                onClick: () => onCustomPropertyEditClick(customData.columnKey)
                            }, checkState: (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.from) ||
                                (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.to) ||
                                (customFilterData === null || customFilterData === void 0 ? void 0 : customFilterData.isNotSet) !== undefined
                                ? 'checked'
                                : 'not-checked', onCheckboxClick: () => handleCustomDataGroupClick(customData, [
                                'from',
                                'to',
                                'isNotSet'
                            ]), onToggle: (state, ref) => handleFilterGroupWrapperToggle(state, ref, panelContainerRef), isInitiallyCollapsed: !customFilterData }, { children: getPlanCustomDataFilterOptions({ customData }) }), `custom-property-${customData.columnKey}`));
                    default:
                        return _jsx(_Fragment, {});
                }
            })] }));
    if (!resultList) {
        return (_jsx(LeftPanel, { children: _jsx(NoPlannedSegments, { onClose: handleCollapse, onButtonClick: handleCreatePlannedSegmentClick }) }));
    }
    const multiSelectActions = (function () {
        return (_jsxs(SelectionBar, Object.assign({ leftButton: _jsx(Button, Object.assign({ disabled: !selectedIdList.length, icon: _jsx(Edit, {}), color: "yellow", onClick: handleEdit, "data-test": "edit-properties-btn" }, { children: _jsx(Ditto, { componentId: "listpanel.multiselect.editpropertiesbutton" }) })) }, { children: [_jsx(ActionButton, { disabled: !selectedIdList.length || selectedIdList.length > BULK_MAIL_LIMIT, icon: _jsx(Mail, {}), onClick: handleMail }), _jsx(ActionButton, { disabled: !selectedIdList.length || selectedIdList.length > BULK_PRINT_LIMIT, icon: _jsx(Print, {}), onClick: handlePrint }), _jsx(ActionButton, { disabled: !selectedIdList.length, icon: _jsx(Download, {}), onClick: handleExportList })] })));
    })();
    const bottomMenu = (function () {
        if (activeTabKey === 'filter') {
            return (_jsx(Button, Object.assign({ color: "dark", variant: "fullwidth", onClick: handleClickDone }, { children: _jsx(Ditto, { componentId: "done" }) })));
        }
        if (activeTabKey === 'list' && isMultiSelectOn) {
            return multiSelectActions;
        }
    })();
    return (_jsxs(LeftPanel, Object.assign({ header: _jsx(PanelHeader, { title: _jsx(Ditto, { componentId: "layerpanel.maplayermodal.plannedsegments" }), resetFilter: {
                filtersCount,
                onReset: handleResetFilter,
                disabled: filtersCount === 0
            }, exportXLS: {
                onExport: handleExport,
                disabled: totalCount === 0
            }, multiselect: isMultiSelectOn
                ? {
                    text: (_jsx(TypedDitto, { componentId: "listpanel.multiselect.x_selected_variabel", variables: { Amount: selectedIdList.length }, count: selectedIdList.length })),
                    onCloseClick: handleResetSelection
                }
                : undefined, search: activeTabKey === 'table'
                ? {
                    onClick: handleSearch
                }
                : undefined, sort: activeTabKey === 'table'
                ? {
                    onSort: handleSort,
                    options: planSortingOptions,
                    current: sort.sortBy &&
                        sort.sortOrder &&
                        `${sort.sortBy}-${sort.sortOrder}`
                }
                : undefined, onCollapse: handleCollapse, variant: activeTabKey === 'table' ? 'fullwidth' : 'normal' }), fullWidthHeaderContent: _jsx(AppliedFilterBarContainer, {}), footer: activeTabKey === 'table' &&
            ((_t = filter.budgetIds) === null || _t === void 0 ? void 0 : _t.length) !== 1 && (_jsx(DataGridTableBudgetTabBar, { tabs: tabs, current: currentTab, setCurrent: handleTabClick })), ref: panelContainerRef, tabBar: _jsxs(_Fragment, { children: [!isSearchBarOn && (_jsx(PanelButtonGroup, { tabs: TAB_LIST_3, activeKey: activeTabKey, onClickTab: handleClickTab })), isSearchBarOn && (_jsx(FormInputText, { height: 48, width: 345, placeholder: searchForLabel, value: filter.search || '', trailingIcon: _jsx("span", Object.assign({ onClick: handleSearch, "data-test": "close-search" }, { children: _jsx(Close, {}) })), onChange: (event) => {
                        dispatch(setPlanFilter({ search: event.target.value }));
                        if (currentPage !== PAGE_NUMBER_FIRST) {
                            dispatch(setPlanCurrentPage(PAGE_NUMBER_FIRST));
                        }
                    }, ref: searchInputRef, "data-test": "search-input" }))] }), activeTabKey: activeTabKey, listHeader: _jsx(LeftPanelListHeader, { onFilterCurrentMapViewClick: handleCheckMapView, onSelectAllClick: handleCheckSelectAll, filterUseMapBounds: !!useMapBounds, selectedCount: selectedIdList.length, resultsCount: totalCount, sort: {
                onSort: handleSort,
                options: planSortingOptions,
                current: sort.sortBy && sort.sortOrder && `${sort.sortBy}-${sort.sortOrder}`
            }, search: {
                onChange: (value) => {
                    if (value !== filter.search) {
                        dispatch(setPlanFilter({ search: value }));
                        if (currentPage !== PAGE_NUMBER_FIRST) {
                            dispatch(setPlanCurrentPage(PAGE_NUMBER_FIRST));
                        }
                    }
                },
                value: filter.search
            } }), pagination: activeTabKey === 'list' && (resultList === null || resultList === void 0 ? void 0 : resultList.length) !== 0 ? (_jsx(Pagination, { totalItems: totalCount, itemsPerPage: ITEMS_PER_PAGE, currentPage: currentPage, onPageChange: handlePageChange })) : undefined, bottomMenu: bottomMenu, onMouseLeave: () => dispatch(clearHighlightedFeature()), variant: activeTabKey === 'table' ? 'table' : 'normal' }, { children: [activeTabKey === 'filter' && planFilter, activeTabKey === 'list' && resultsCurrentPage, activeTabKey === 'table' && dataTable, activeTabKey === 'table' && isMultiSelectOn && (_jsx(DataGridTableActionbar, { children: multiSelectActions })), activeTabKey === 'table' && !isMultiSelectOn && (_jsx(DataGridTableTotalCount, { count: totalCount }))] })));
}
