import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { Ditto } from 'ditto-react';
import { Close } from '../../components/icons';
import { Modal } from '../../components/Modal/Modal';
import { WorkingHoursPicker } from '../../components/WorkingHoursPicker/WorkingHoursPicker';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useModalContext } from '../../state/context';
import { useAppSelector } from '../../state/hooks';
import { Button } from '../../uikit/Button/Button';
import styles from './WorkingHoursPickerModalContainer.module.css';
export function WorkingHoursPickerModalContainer({ onConfirm, onDelete, workerIds, start, finish, readonly, readonlyWorker }) {
    const { locale } = useAppSelector((state) => state.app);
    const countryCode = useAppSelector((state) => { var _a; return (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode; });
    const formatter = useFormatter();
    const { addModal, removeModal } = useModalContext();
    const { taskAssigneeList } = useAppSelector((state) => state.shared);
    const [selectedWorkerIds, setSelectedWorkerIds] = useState(workerIds !== null && workerIds !== void 0 ? workerIds : []);
    const [isDatesValid, setIsDatesValid] = useState(false);
    const startDate = useRef(start);
    const finishDate = useRef(finish);
    function handleClose() {
        removeModal('WorkingHoursPickerModalContainer');
    }
    function handleDelete() {
        addModal({
            id: 'DeleteModal',
            props: {
                onConfirm: () => {
                    onDelete === null || onDelete === void 0 ? void 0 : onDelete();
                },
                title: _jsx(Ditto, { componentId: "tasks.costs.workinghours.deleteworkinghoursentry" }),
                description: (_jsx(Ditto, { componentId: "tasks.costs.workinghours.deleteworkinghoursentrydescription" }))
            }
        });
    }
    function handleWorkersChange(ids) {
        setSelectedWorkerIds(ids);
    }
    function handleWorkersFieldClick() {
        addModal({
            id: 'AssigneeSelectModalContainer',
            props: {
                initialValues: selectedWorkerIds,
                onConfirm: handleWorkersChange,
                title: _jsx(Ditto, { componentId: "tasks.costs.workinghours.worker" }),
                showReset: true
            }
        });
    }
    function handleDatesChange(start, finish, valid) {
        startDate.current = start;
        finishDate.current = finish;
        setIsDatesValid(valid);
    }
    function handleConfirmClick() {
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(selectedWorkerIds, startDate.current, finishDate.current);
    }
    return (_jsx(Modal, { width: 760, title: _jsx(Ditto, { componentId: "tasks.costs.workinghours" }), trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: handleClose }), content: _jsx(WorkingHoursPicker, { locale: locale, countryCode: countryCode, workersList: taskAssigneeList, selectedWorkerIds: selectedWorkerIds, onWorkersFieldClick: handleWorkersFieldClick, formatter: formatter, onDatesChange: handleDatesChange, start: startDate.current, finish: finishDate.current, readonly: readonly, readonlyWorker: readonlyWorker }), footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx("div", { className: styles['divider'] }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [!!onDelete && (_jsx("div", Object.assign({ className: styles['button-delete'] }, { children: _jsx(Button, Object.assign({ color: "red", variant: 'fullwidth', onClick: handleDelete }, { children: _jsx(Ditto, { componentId: "delete" }) })) }))), _jsx("div", Object.assign({ className: styles['button-cancel'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: handleClose }, { children: _jsx(Ditto, { componentId: "promt.cancel" }) })) })), !readonly && (_jsx("div", Object.assign({ className: styles['button-confirm'] }, { children: _jsx(Button, Object.assign({ color: "dark", variant: 'fullwidth', onClick: handleConfirmClick, disabled: !isDatesValid || selectedWorkerIds.length === 0 }, { children: _jsx(Ditto, { componentId: "modal.confirm" }) })) })))] }))] })), onClickOutside: handleClose }));
}
