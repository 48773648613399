import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { AnimatePresence, motion } from 'framer-motion';
import { layerSelectorTitleTransition } from '../../common/consts/animations';
import { SECONDARY_LAYERS } from '../../common/consts/map';
import { biFilter } from '../../common/utils/array';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Divider } from '../../uikit/Divider/Divider';
import { BetaBadge } from '../BetaBadge/BetaBadge';
import { DropMenu } from '../DropMenu/DropMenu';
import { Close, Help, Layer, Reset } from '../icons';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { MapLayerSettings } from './MapLayerSettings/MapLayerSettings';
import { SidebarBottomButton } from './SidebarBottomButton/SidebarBottomButton';
import { SidebarLayerButton } from './SidebarLayerButton/SidebarLayerButton';
import styles from './LayerPanel.module.css';
export function LayerPanel({ layers, onLayerChange, onSetLayers, customerLogo, selectedLayer, onSelectLayer, onResetClick, settingsProps }) {
    const wikiLink = useDittoWrapper({ componentId: 'wikilink.main' });
    const wikiTitle = useDittoWrapper({ componentId: 'layerpanel.maplayersettings.wiki' });
    const accountTitle = useDittoWrapper({ componentId: 'account' });
    const [isAccountModalOpen, setAccountModalOpen] = useState(false);
    const [isOverlay, setIsOverlay] = useState(false);
    const [isMenuButtonClicked, setIsMenuButtonClicked] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const { match: secondary, rest: primary } = biFilter(layers, (item) => SECONDARY_LAYERS.includes(item.id));
    function handleMouseEnter() {
        if (isMenuButtonClicked) {
            return;
        }
        setIsHover(true);
    }
    function handleMouseLeave() {
        setIsMenuButtonClicked(false);
        setIsOverlay(false);
        setIsHover(false);
    }
    function handleLayerClick(layer) {
        if (!layer.isAuthorized) {
            onLayerChange(Object.assign(Object.assign({}, layer), { isEnabled: false }));
            return;
        }
        // 3 states: disabled, active, selected
        // click on selected layer should disable it
        if (selectedLayer === layer.id) {
            onLayerChange(Object.assign(Object.assign({}, layer), { isEnabled: false }));
            return;
        }
        // click on active layer should select it
        if (layer.isEnabled) {
            onSelectLayer(layer.id);
            return;
        }
        // click on disabled layer should select it
        if (!layer.isEnabled) {
            onLayerChange(Object.assign(Object.assign({}, layer), { isEnabled: true }));
            onSelectLayer(layer.id);
            return;
        }
    }
    function handleLayerToggle(layer) {
        onLayerChange(Object.assign(Object.assign({}, layer), { isEnabled: !layer.isEnabled }));
    }
    function handleExcludeOtherLayersClick(layer) {
        onSetLayers(layers.map((l) => (Object.assign(Object.assign({}, l), { isEnabled: l.id === layer.id }))));
        onSelectLayer(layer.id, true);
    }
    function getLayerLabel(layer) {
        if (!layer.isAuthorized) {
            return _jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "badgenewlocked" }), color: "yellow" });
        }
        if (layer.isBeta) {
            return (_jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), color: selectedLayer === layer.id ? 'dark' : 'default' }));
        }
        if (layer.isNew) {
            return (_jsx(BetaBadge, { content: _jsx(Ditto, { componentId: "badgenew" }), color: selectedLayer === layer.id ? 'dark' : 'default' }));
        }
    }
    return (_jsxs(motion.div, Object.assign({ className: classes(styles['container'], isOverlay && styles['overlay']), transition: layerSelectorTitleTransition, variants: {
            overlay: {
                width: 332
            },
            initial: {
                width: 72
            }
        }, initial: "initial", animate: isOverlay ? 'overlay' : 'initial', onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, { children: [_jsxs("div", Object.assign({ className: classes(styles['main-column']) }, { children: [_jsxs(_Fragment, { children: [_jsx(motion.div, Object.assign({ className: styles['menu-button'], onClick: () => {
                                    setIsMenuButtonClicked(true);
                                    setIsOverlay(!isOverlay);
                                }, onMouseEnter: () => {
                                    setIsHover(true);
                                }, "data-test": "layer-hamburger-menu" }, { children: _jsxs(AnimatePresence, { children: [!isOverlay && !isHover && (_jsx(motion.div, Object.assign({ transition: { ease: 'easeOut', duration: 0.1 }, initial: { scale: 0.2 }, animate: { scale: 1.0 }, exit: { scale: 0.2 }, style: {
                                                position: 'absolute'
                                            } }, { children: _jsx(VialyticsLogo, {}) }), 'logo')), !isOverlay && isHover && (_jsx(TooltipWrapper, Object.assign({ text: _jsx(Ditto, { componentId: "layerpanel.expandlayerselector" }), size: "small", position: "start", className: styles['menu-layer-selector-button'] }, { children: _jsx(motion.div, Object.assign({ transition: { ease: 'easeOut', duration: 0.1 }, initial: { scale: 0.2 }, animate: { scale: 1.0 }, exit: { scale: 0.2 }, style: {
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: 48,
                                                    height: 48
                                                } }, { children: _jsx(Layer, {}) }), 'layer') }))), isOverlay && (_jsx(motion.div, Object.assign({ transition: { ease: 'easeOut', duration: 0.1 }, initial: { scale: 0.2 }, animate: { scale: 1.0 }, exit: { scale: 0.2 }, style: {
                                                position: 'absolute',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            } }, { children: _jsx(Close, {}) }), 'close'))] }) })), _jsx(Divider, { className: styles['divider'], margin: "8px", width: "100%" }), _jsxs("div", Object.assign({ className: styles['layer-switch-container'] }, { children: [primary.map((layer) => (_jsx(SidebarLayerButton, { layer: layer, isActive: selectedLayer === layer.id, isOverlay: isOverlay, onClick: () => handleLayerClick(layer), onCheckboxClick: () => handleLayerToggle(layer), onClickExcludeOtherLayers: () => handleExcludeOtherLayersClick(layer), label: getLayerLabel(layer), "data-test": `layer-${layer.id}${layer.isEnabled ? '-enabled' : ''}` }, layer.id))), _jsx(Divider, { className: styles['divider'], margin: "4px", width: "100%" }), secondary.map((layer) => (_jsx(SidebarLayerButton, { layer: layer, isActive: selectedLayer === layer.id, isOverlay: isOverlay, onClick: () => handleLayerClick(layer), onCheckboxClick: () => handleLayerToggle(layer), onClickExcludeOtherLayers: () => handleExcludeOtherLayersClick(layer), "data-test": `${layer.id}` }, layer.id)))] }))] }), _jsx(Divider, { margin: "8px", width: "100%" }), _jsxs("div", Object.assign({ className: styles['bottom-section'] }, { children: [_jsx(SidebarBottomButton, { icon: _jsx(Help, {}), title: wikiTitle, tooltip: !isOverlay && wikiTitle, onClick: () => window.open(wikiLink, '_blank'), isOverlay: isOverlay }), _jsx(DropMenu, { direction: "right-up", horizontal: true, control: _jsx(SidebarBottomButton, { icon: customerLogo, title: accountTitle, tooltip: !isOverlay && accountTitle, isHover: isAccountModalOpen, isOverlay: isOverlay, "data-test": "btn-account-modal" }), menu: _jsx(MapLayerSettings, Object.assign({}, settingsProps, { onItemSelect: () => {
                                        setAccountModalOpen(false);
                                    } })), isOpen: isAccountModalOpen, setIsOpen: (isOpen) => setAccountModalOpen(isOpen) })] }))] })), isOverlay && (_jsxs("div", Object.assign({ className: styles['secondary-column'] }, { children: [_jsx("div", Object.assign({ className: classes(styles['title']) }, { children: _jsx(Ditto, { componentId: "layerpanel.maplayermodal.titel" }) })), _jsx("div", Object.assign({ className: styles['reset-button'], onClick: onResetClick }, { children: _jsx(TooltipWrapper, Object.assign({ text: _jsx(Ditto, { componentId: "appliedfilter.reset" }), size: "small", position: "center", className: styles['reset-button-tooltip'] }, { children: _jsx("div", Object.assign({ className: styles['reset-button-inner'] }, { children: _jsx(Reset, {}) })) })) }))] })))] })));
}
function VialyticsLogo() {
    return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "48", height: "48", fill: "none", viewBox: "0 0 48 48" }, { children: [_jsx("path", { fill: "#222D39", d: "M30.295 35.6l-5.977-10.364L18.405 15H6.452l11.89 20.6h11.953z" }), _jsx("path", { fill: "#fff", d: "M13.065 16.335l1.589 2.797h1.526l-1.59-2.797h-1.525zM16.816 22.757l1.59 2.797h1.525l-1.59-2.797h-1.525zM20.313 28.86l1.653 2.798h1.525l-1.653-2.798h-1.526z" }), _jsx("path", { fill: "#fff", d: "M30.295 15l-11.89 20.6h11.89L42.248 15H30.295z" }), _jsx("path", { fill: "#010102", d: "M23.3 23.52l-5.976 10.236 1.017 1.844 5.977-10.3-1.017-1.78z", opacity: "0.3" })] })));
}
