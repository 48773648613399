import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { ImageViewerModal } from '../../components/Modals/ImageViewerModal/ImageViewerModal';
import { useSignInfoFetcher } from '../../hooks/useSignInfoFetcher';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetMarkerCreator } from '../../state/slices/markerCreator';
import { RightPanelMarkerCreatorContainer } from '../RightPanelMarkerCreatorContainer/RightPanelMarkerCreatorContainer';
import { SignDetailContainer } from '../SignDetailContainer/SignDetailContainer';
export function ImageViewerSignContainer() {
    const dispatch = useAppDispatch();
    const { removeModal, modals } = useModalContext();
    const { targetSchema } = useAppSelector((state) => state.auth);
    const { source: markerCreatorSource } = useAppSelector((state) => state.markerCreator);
    const { signInfo } = useAppSelector((state) => state.signDetail);
    useSignInfoFetcher();
    function handleClickClose() {
        removeModal('ImageViewerSign');
    }
    function handleClickOutside() {
        if (modals[modals.length - 1].id !== 'ImageViewerSign') {
            return;
        }
        handleClickClose();
    }
    function handleBackButtonClick() {
        dispatch(resetMarkerCreator());
    }
    if (!targetSchema || !signInfo) {
        return _jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Loading" }) });
    }
    return (signInfo && (_jsx(ImageViewerModal, { imageViewer: markerCreatorSource ? (_jsx(ImageViewerModal, { imageViewer: _jsx(ImageViewer, { variant: "Sign", singleImage: _jsx("img", { src: signInfo.imageUrl }), onBackButtonClick: handleBackButtonClick, onDoneClick: handleClickOutside, sign: signInfo }), creator: _jsx(RightPanelMarkerCreatorContainer, {}) })) : (_jsx(ImageViewer, { variant: "Sign", onDoneClick: handleClickOutside, singleImage: _jsx("img", { src: signInfo.imageUrl }), sign: signInfo, details: _jsx(SignDetailContainer, { variant: "image-viewer" }) })) })));
}
