var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { DEBOUNCE_MAIL_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { getNodeEdgeGeoJSON } from '../../services/condition';
import { getNodeEdgesTemplate, getPlanTemplate, getPointTemplates, getTaskTemplateBulk, getTrafficSignTemplateBulk, postMail, sendTaskTemplateBulk, sendTrafficSignTemplateBulk } from '../../services/mail';
import { getAssetTemplateBulk, sendAssetTemplateBulk } from '../../services/mail/asset';
import { getSignInventoryTemplateBulk, sendSignInventoryTemplateBulk } from '../../services/mail/signInventory';
import { saveMarkerMailChangelog } from '../../services/marker';
import { getPlanDetailsByIdList, getPlanMapGeoJSONByPlanIds } from '../../services/plan';
import { fetchMarker } from './markerDetail';
import { addNotificationMessage } from './notification';
const initialState = {
    featureGeoJSONs: [],
    mailContents: [],
    isLoadingTemplates: false,
    isLoadingGeoJSON: false,
    isSending: false,
    isSent: false
};
function notifyAfterSent(dispatch, res) {
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'error'
        }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            ditto: {
                title: { componentId: 'toast.mailsend' }
            },
            type: 'success'
        }));
    }
}
export const getMailPointTemplates = createDebouncedAsyncThunk('mail/getMailPointTemplates', (params) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getPointTemplates(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success || !res.result) {
        throw new Error(res.errorMessage);
    }
    return res.result;
}), DEBOUNCE_MAIL_MS);
export const bulkGetMailTrafficSign = createDebouncedAsyncThunk('mail/getMailTrafficSignTemplates', (params) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getTrafficSignTemplateBulk(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(res.errorMessage);
    }
    return res;
}), DEBOUNCE_MAIL_MS);
export const bulkGetMailSignInventory = createDebouncedAsyncThunk('mail/bulkGetMailSignInventory', (params) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getSignInventoryTemplateBulk(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(res.errorMessage);
    }
    return res;
}), DEBOUNCE_MAIL_MS);
export const bulkSendMailTrafficSign = createAppAsyncThunk('mail/sendMailTrafficSignTemplates', (params, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield sendTrafficSignTemplateBulk(params);
    notifyAfterSent(dispatch, res);
    return res;
}));
export const bulkGetMailTask = createDebouncedAsyncThunk('mail/getMailTaskTemplates', (params) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getTaskTemplateBulk(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(res.errorMessage);
    }
    return res;
}), DEBOUNCE_MAIL_MS);
export const bulkSendMailTask = createAppAsyncThunk('mail/sendMailTaskTemplates', (params, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield sendTaskTemplateBulk(params);
    notifyAfterSent(dispatch, res);
    return res;
}));
export const saveMarkerMailChangelogTk = createAppAsyncThunk('mail/saveMarkerMailChangelogTk', (req, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield saveMarkerMailChangelog(req.body);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if (res.errorCode === RESPONSE_ERROR_CODE.success) {
        if (req.markerId) {
            dispatch(fetchMarker(req.markerId));
        }
    }
}));
export const bulkGetMailAsset = createDebouncedAsyncThunk('mail/bulkGetMailAsset', (params) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getAssetTemplateBulk(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(res.errorMessage);
    }
    return res;
}), DEBOUNCE_MAIL_MS);
export const bulkSendMailAsset = createAppAsyncThunk('mail/bulkSendMailAsset', (params, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield sendAssetTemplateBulk(params);
    notifyAfterSent(dispatch, res);
    return res;
}));
export const bulkSendMailSignInventory = createAppAsyncThunk('mail/bulkSendMailSignInventory', (params, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield sendSignInventoryTemplateBulk(params);
    notifyAfterSent(dispatch, res);
    return res;
}));
export const getMailSegmentTemplates = createDebouncedAsyncThunk('mail/getMailSegmentTemplates', (params) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield getNodeEdgesTemplate(params);
    if (res.errorCode !== RESPONSE_ERROR_CODE.success || !res.result) {
        throw new Error(res.errorMessage);
    }
    return res.result;
}), DEBOUNCE_MAIL_MS);
export const getMailPlanTemplates = createDebouncedAsyncThunk('mail/getPlanTemplate', (params, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const state = getState();
    const countryCode = (_a = state.auth.teamAccount) === null || _a === void 0 ? void 0 : _a.countryCode;
    const planDetailsRespond = yield getPlanDetailsByIdList({
        countryCode,
        planIds: params.planIds
    });
    if (planDetailsRespond.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(planDetailsRespond.errorMessage);
    }
    const res = yield getPlanTemplate(Object.assign(Object.assign({}, params), { plans: ((_b = planDetailsRespond.result) === null || _b === void 0 ? void 0 : _b.plans.map((plan, index) => (Object.assign({ mapSnapshot: params.mapSnapshots[index] }, plan)))) || [] }));
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(res.errorMessage);
    }
    return res.result;
}), DEBOUNCE_MAIL_MS);
export const getPlanGeoJSON = createAppAsyncThunk('mail/getPlanGeoJSON', (planIds) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const res = yield getPlanMapGeoJSONByPlanIds({ planIds });
    if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
        throw new Error(res.errorMessage);
    }
    return ((_c = res.result) === null || _c === void 0 ? void 0 : _c.geojson.features) || [];
}));
export const getEdgeGeoJSON = createAppAsyncThunk('mail/getEdgeGeoJSON', (ids) => __awaiter(void 0, void 0, void 0, function* () {
    const geojson = [];
    for (const id of ids) {
        const res = yield getNodeEdgeGeoJSON(id);
        if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
            throw new Error(res.errorMessage);
        }
        if (!res.result) {
            continue;
        }
        geojson.push(res.result);
    }
    return geojson;
}));
export const sendMail = createAppAsyncThunk('mail/sendMail', (params, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const errors = [];
    for (const p of params) {
        const res = yield postMail(p);
        if (res.errorCode !== RESPONSE_ERROR_CODE.success) {
            errors.push(res.errorMessage);
        }
    }
    if (errors.length > 0) {
        dispatch(addNotificationMessage({
            type: 'error'
        }));
    }
    else {
        dispatch(addNotificationMessage({
            ditto: {
                title: { componentId: 'toast.mailsend' }
            },
            type: 'success'
        }));
    }
    return errors;
}));
export const mailSlice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        reset: () => initialState,
        setFeatureGeoJSONs: (state, action) => {
            state.featureGeoJSONs = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(sendMail.fulfilled, (state, action) => {
            state.isSending = false;
            if (action.payload.length) {
                state.errors = action.payload;
                return;
            }
            state.isSent = true;
        })
            .addCase(sendMail.rejected, (state, action) => {
            state.isSending = false;
            state.errors = [action.error.toString()];
        })
            .addCase(sendMail.pending, (state) => {
            state.isSending = true;
            state.errors = undefined;
        })
            .addMatcher(isAnyOf(bulkGetMailSignInventory.fulfilled, bulkGetMailTrafficSign.fulfilled, bulkGetMailTask.fulfilled, bulkGetMailAsset.fulfilled), (state, action) => {
            state.isLoadingTemplates = false;
            if (action.payload.result.html) {
                state.mailContents = action.payload.result.html;
            }
        })
            .addMatcher(isAnyOf(getMailSegmentTemplates.fulfilled, getMailPointTemplates.fulfilled), (state, action) => {
            state.isLoadingTemplates = false;
            state.mailContents = action.payload.templates;
        })
            .addMatcher(isAnyOf(getMailPlanTemplates.fulfilled), (state, action) => {
            state.isLoadingTemplates = false;
            if (action.payload) {
                state.mailContents = [action.payload.template];
            }
        })
            .addMatcher(isAnyOf(getMailPointTemplates.pending, getMailSegmentTemplates.pending, getMailPlanTemplates.pending, bulkGetMailTrafficSign.pending, bulkGetMailTask.pending, bulkGetMailAsset.pending), (state) => {
            state.isLoadingTemplates = true;
            state.mailContents = [];
            state.errors = undefined;
        })
            .addMatcher(isAnyOf(getMailPointTemplates.rejected, getMailSegmentTemplates.rejected, getMailPlanTemplates.rejected, bulkGetMailTrafficSign.rejected, bulkGetMailTask.rejected, bulkGetMailAsset.rejected), (state, action) => {
            state.isLoadingTemplates = false;
            state.errors = [action.error.toString()];
        })
            .addMatcher(isAnyOf(getPlanGeoJSON.pending, getEdgeGeoJSON.pending), (state) => {
            state.isLoadingGeoJSON = true;
        })
            .addMatcher(isAnyOf(getPlanGeoJSON.rejected, getEdgeGeoJSON.rejected), (state, action) => {
            state.isLoadingGeoJSON = false;
            state.errors = [action.error.toString()];
        })
            .addMatcher(isAnyOf(getPlanGeoJSON.fulfilled, getEdgeGeoJSON.fulfilled), (state, action) => {
            state.isLoadingGeoJSON = false;
            state.featureGeoJSONs = action.payload;
        });
    }
});
export const { reset: resetMail, setFeatureGeoJSONs } = mailSlice.actions;
export default mailSlice.reducer;
