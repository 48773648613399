import { addBreadcrumb } from '@sentry/react';
import { DEFAULT_SOURCE_LAYER } from './settings';
import { ALERTS_SOURCE_ID, ASSETS_SOURCE_ID, MARKERS_SOURCE_ID, NOTE_SOURCE_ID, OBJECTS_SOURCE_ID, PLANS_SOURCE_ID, POINTS_SOURCE_ID, REFERENCING_SOURCE_ID, SEGMENTS_SOURCE_ID, SIGN_INVENTORY_SOURCE_ID, TRACK_CONTROL_SOURCE_ID, TRAFFIC_SIGNS_SOURCE_ID, WINTER_SERVICE_SOURCE_ID } from './sources';
export const toFeatureIdentifier = (selectedFeature) => {
    const sourceLayer = DEFAULT_SOURCE_LAYER;
    switch (selectedFeature.type) {
        case 'point':
            return {
                id: selectedFeature.id,
                source: POINTS_SOURCE_ID,
                sourceLayer
            };
        case 'referencing-flag':
            return {
                id: selectedFeature.id,
                source: REFERENCING_SOURCE_ID,
                sourceLayer
            };
        case 'segment':
            return {
                id: selectedFeature.id,
                source: SEGMENTS_SOURCE_ID,
                sourceLayer
            };
        case 'plan':
            return {
                id: selectedFeature.id,
                source: PLANS_SOURCE_ID
            };
        case 'alert':
            return {
                id: selectedFeature.id,
                source: ALERTS_SOURCE_ID
            };
        case 'object':
            return {
                id: selectedFeature.id,
                source: OBJECTS_SOURCE_ID,
                sourceLayer
            };
        case 'track-control':
            return {
                id: selectedFeature.id,
                source: TRACK_CONTROL_SOURCE_ID,
                sourceLayer
            };
        case 'marker':
            return {
                id: selectedFeature.id,
                source: MARKERS_SOURCE_ID
            };
        case 'note':
            return {
                id: selectedFeature.id,
                source: NOTE_SOURCE_ID
            };
        case 'sign':
            return {
                id: selectedFeature.id,
                source: TRAFFIC_SIGNS_SOURCE_ID,
                sourceLayer
            };
        case 'winter-service':
            return {
                id: selectedFeature.id,
                source: WINTER_SERVICE_SOURCE_ID,
                sourceLayer
            };
        case 'sign-inventory':
            return {
                id: selectedFeature.id,
                source: SIGN_INVENTORY_SOURCE_ID,
                sourceLayer
            };
        case 'asset':
            return {
                id: selectedFeature.id,
                source: ASSETS_SOURCE_ID,
                sourceLayer
            };
    }
};
export const toSelectedFeature = (featureIdentifier) => {
    addBreadcrumb({
        category: 'selectedFeature',
        message: 'toSelectedFeature',
        level: 'info',
        data: featureIdentifier
    });
    if (!featureIdentifier.id) {
        throw new Error(`toSelectedFeature(): missing id`);
    }
    switch (featureIdentifier.source) {
        case POINTS_SOURCE_ID:
            if (typeof featureIdentifier.id === 'number') {
                throw new Error(`toSelectedFeature(): point id must be a string`);
            }
            return {
                id: featureIdentifier.id,
                type: 'point'
            };
        case REFERENCING_SOURCE_ID: {
            if (typeof featureIdentifier.id === 'number') {
                return {
                    id: featureIdentifier.id,
                    type: 'referencing-flag'
                };
            }
            const id = parseInt(featureIdentifier.id, 10);
            if (isNaN(id)) {
                throw new Error(`toSelectedFeature(): referencing-flag id must be a number or a parseable to a number`);
            }
            return {
                id,
                type: 'referencing-flag'
            };
        }
        case SEGMENTS_SOURCE_ID:
            if (typeof featureIdentifier.id === 'string') {
                throw new Error(`toSelectedFeature(): segment id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'segment'
            };
        case PLANS_SOURCE_ID:
            if (typeof featureIdentifier.id === 'string') {
                throw new Error(`toSelectedFeature(): plan id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'plan'
            };
        case ALERTS_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'string') {
                throw new Error(`toSelectedFeature(): alert id must be a string`);
            }
            return {
                id: featureIdentifier.id,
                type: 'alert'
            };
        case OBJECTS_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'string') {
                throw new Error(`toSelectedFeature(): object id must be a string`);
            }
            return {
                id: featureIdentifier.id,
                type: 'object'
            };
        case TRACK_CONTROL_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'number') {
                throw new Error(`toSelectedFeature(): track control id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'track-control'
            };
        case MARKERS_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'number') {
                throw new Error(`toSelectedFeature(): marker id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'marker'
            };
        case NOTE_SOURCE_ID: {
            if (typeof featureIdentifier.id !== 'string') {
                throw new Error(`toSelectedFeature(): note id must be a string`);
            }
            return {
                id: featureIdentifier.id,
                type: 'note'
            };
        }
        case TRAFFIC_SIGNS_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'number') {
                throw new Error(`toSelectedFeature(): sign id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'sign'
            };
        case WINTER_SERVICE_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'number') {
                throw new Error(`toSelectedFeature(): winter service id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'winter-service'
            };
        case SIGN_INVENTORY_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'number') {
                throw new Error(`toSelectedFeature(): sign id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'sign-inventory'
            };
        case ASSETS_SOURCE_ID:
            if (typeof featureIdentifier.id !== 'number') {
                throw new Error(`toSelectedFeature(): asset id must be a number`);
            }
            return {
                id: featureIdentifier.id,
                type: 'asset'
            };
    }
    throw new Error(`toSelectedFeature(): unknown source: ${featureIdentifier.source}`);
};
