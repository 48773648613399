var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { post } from '../common/fetch';
import { milkyWayApiClient } from '../openapi';
const OBJECT_LIST_ENDPOINT = 'v1/object/list';
export function getObjectSortParams(id) {
    switch (id) {
        case 'date-asc':
            return { sortBy: 'created_at', sortOrder: 'asc' };
        case 'date-desc':
            return { sortBy: 'created_at', sortOrder: 'desc' };
        default:
            return { sortBy: 'created_at', sortOrder: 'desc' };
    }
}
export function getObjectList(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield post(OBJECT_LIST_ENDPOINT, params, undefined);
    });
}
export function getObjectTypes() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServiceObjectTypesList({});
    });
}
export function exportObjects(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield milkyWayApiClient.milkyWayApiService.milkyWayApiServiceExportObjectsByEmail(body);
    });
}
