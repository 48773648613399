import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import config from '../../config/config.json';
import { TrafficSignRotator } from '../TrafficSignRotator';
import styles from './TrafficSignMarker.module.css';
export const TrafficSignMarker = (props) => {
    const selectedPrefix = props.variant !== 'print' ? '-selected' : '';
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], props.variant && styles[props.variant]) }, { children: [_jsx("img", { className: styles['sign-image'], src: `/${props.subSpecifiedClass}${selectedPrefix}.png` }), _jsx("img", { className: styles['pole-image'], src: `/sign-pin${selectedPrefix}.png` }), props.damage && _jsx("div", Object.assign({ className: styles['damage'] }, { children: props.damage })), config.enableOrientation &&
                (props.variant === 'with-rotator' || props.variant === 'print') &&
                props.orientation !== undefined && (_jsx("div", Object.assign({ className: styles['rotator'] }, { children: _jsx(TrafficSignRotator, { isRotatable: props.isRotatable || false, orientation: props.orientation, variant: props.variant === 'print' ? 'print' : 'create', onOrientationChange: props.onOrientationChange }) })))] })));
};
