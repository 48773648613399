// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PL6eqD_eJWlw56bKyeuU{cursor:pointer;height:24px;width:24px}.UPS8PEzwYkVblx1Le1pA{opacity:.7}`, "",{"version":3,"sources":["webpack://./src/uikit/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,WAAA,CACA,UAAA,CAGJ,sBACI,UAAA","sourcesContent":[".container {\n    cursor: pointer;\n    height: 24px;\n    width: 24px;\n}\n\n.container__hover {\n    opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PL6eqD_eJWlw56bKyeuU`,
	"container__hover": `UPS8PEzwYkVblx1Le1pA`
};
export default ___CSS_LOADER_EXPORT___;
