var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addBreadcrumb } from '@sentry/react';
import Keycloak from 'keycloak-js';
import { ROOT_PATH } from '../common/url';
import config from '../config/config.json';
import { setLocale } from '../state/slices/app';
import { setIsAuthenticated, setIsAuthProcessing, setIsKeycloakReady } from '../state/slices/auth';
import { persistor, store } from '../state/store';
import { LOCALE } from './consts/locale';
import { getLocalStorageAll, setLocalStoragePartial } from './localStorage';
const KEYCLOAK_MIN_VALIDITY = 5;
const keycloak = new Keycloak(Object.assign({}, config.keycloak));
// application auth helper functions, redux state
function loginApp() {
    var _a;
    const locale = (_a = keycloak.tokenParsed) === null || _a === void 0 ? void 0 : _a.locale;
    if (Object.keys(LOCALE).includes(locale)) {
        // only set locale from keycloak if it is not set in app
        if (!getLocalStorageAll().locale) {
            store.dispatch(setLocale(locale));
            setLocalStoragePartial({ locale });
        }
    }
    else {
        console.error(`keycloak/loginApp: Returned locale ${locale} is not supported.`);
    }
    const keycloakData = {
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken
    };
    console.debug('keycloak/loginApp: Setting authenticated to true');
    store.dispatch(setIsAuthenticated(true));
    store.dispatch(setIsAuthProcessing(false));
    setLocalStoragePartial(Object.assign({}, keycloakData));
}
function refreshAppToken() {
    const keycloakData = {
        token: keycloak.token,
        refreshToken: keycloak.refreshToken
    };
    console.debug('keycloak/refreshAppToken');
    setLocalStoragePartial(keycloakData);
}
function logoutApp() {
    console.debug('keycloak/logoutApp: Clearing storage');
    persistor.purge();
    window.localStorage.clear();
    window.sessionStorage.clear();
}
// Callback Events
// The adapter supports setting callback listeners for certain events.
// Keep in mind that these have to be set before the call to the init function.
keycloak.onReady = function () {
    console.debug('keycloak/onReady');
    store.dispatch(setIsKeycloakReady(true));
};
keycloak.onAuthError = function () {
    console.error('keycloak/onAuthError');
    addBreadcrumb({
        category: 'keycloak',
        message: 'keycloak/onAuthError',
        level: 'warning'
    });
};
keycloak.onAuthRefreshSuccess = function () {
    console.debug('keycloak/onAuthRefreshSuccess');
    refreshAppToken();
};
keycloak.onAuthRefreshError = function () {
    console.error('keycloak/onAuthRefreshError');
    addBreadcrumb({
        category: 'keycloak',
        message: 'keycloak/onAuthRefreshError',
        level: 'warning'
    });
    keycloakLogout();
};
keycloak.onAuthLogout = function () {
    console.debug('keycloak/onAuthLogout');
    addBreadcrumb({
        category: 'keycloak',
        message: 'keycloak/onAuthLogout',
        level: 'info'
    });
};
keycloak.onTokenExpired = function () {
    console.debug('keycloak/onTokenExpired');
    addBreadcrumb({
        category: 'keycloak',
        message: 'keycloak/onTokenExpired',
        level: 'info'
    });
    keycloak
        .updateToken(KEYCLOAK_MIN_VALIDITY)
        .then((refreshed) => {
        if (refreshed) {
            refreshAppToken();
        }
    })
        .catch((err) => {
        addBreadcrumb({
            category: 'keycloak',
            message: `keycloak/onTokenExpired: updateToken error`,
            data: err,
            level: 'warning'
        });
    });
};
// init keycloak
const { token, idToken, refreshToken } = getLocalStorageAll();
console.debug('localStorage token exists', !!token);
console.debug('localStorage idToken exists', !!idToken);
console.debug('localStorage refreshToken exists', !!refreshToken);
addBreadcrumb({
    category: 'keycloak',
    message: 'keycloak/init',
    level: 'info',
    data: {
        tokenPresent: !!token,
        idTokenPresent: !!idToken,
        refreshTokenPresent: !!refreshToken
    }
});
const initOptions = {
    enableLogging: true,
    checkLoginIframe: false
};
if (!!token && !!idToken && !!refreshToken) {
    initOptions.token = token;
    initOptions.idToken = idToken;
    initOptions.refreshToken = refreshToken;
}
keycloak
    .init(initOptions)
    .then((authenticated) => {
    if (!authenticated) {
        console.debug(`keycloakInit/then: user is not auth :${authenticated}`);
    }
    console.debug(`keycloakInit/then: user is auth :${authenticated}`);
})
    .catch(function (err) {
    addBreadcrumb({
        category: 'keycloak',
        message: 'keycloakInit/catch',
        level: 'info'
    });
    console.error('keycloak: failed to initialize', err);
    keycloakLogout();
});
// token should be indirectly accessed through local storage
// remove direct access to keycloak instance
// export default keycloak;
// exported keycloak functions
export function keycloakLogin(redirectUri, loginHint) {
    store.dispatch(setIsAuthProcessing(true));
    if (keycloak.authenticated) {
        loginApp();
    }
    else {
        keycloak
            .login({
            redirectUri,
            loginHint
        })
            .then(() => {
            console.debug('keycloak/keycloakLogin: success');
            addBreadcrumb({
                category: 'keycloak',
                message: 'keycloak/keycloakLogin: success',
                level: 'info'
            });
            loginApp();
        })
            .catch(function (err) {
            addBreadcrumb({
                category: 'keycloak',
                message: `keycloak/keycloakLogin: login error`,
                data: err,
                level: 'warning'
            });
            console.error('keycloak/keycloakLogin: error', err);
        });
    }
}
export function keycloakLogout() {
    return __awaiter(this, void 0, void 0, function* () {
        addBreadcrumb({
            category: 'keycloak',
            message: 'keycloak/keycloakLogout',
            level: 'info'
        });
        if (!keycloak.idToken) {
            if (idToken)
                keycloak.idToken = idToken;
        }
        if (keycloak.idToken) {
            logoutApp();
            keycloak
                .logout({
                redirectUri: `${window.location.origin.toString()}`
            })
                .catch((err) => console.error('keycloak/keycloakLogout: error', err))
                .then(() => {
                console.debug('keycloak/keycloakLogout: success');
            });
        }
        else {
            logoutApp();
            console.error('keycloak/keycloakLogout: missing id token, redirecting...');
            window.location.href = `${ROOT_PATH.main}`;
        }
    });
}
